import { memo } from "react";
import { Skeleton } from "@mui/material";
import _ from "lodash";

import Stack from "components/Stack";

const FormSkeleton = () => (
  <Stack spacing={4}>
    <Stack spacing={2}>
      <Skeleton height={40} width={200} variant="text" />
      {_.range(5).map((index) => (
        <Skeleton key={index} variant="rectangular" height={56} />
      ))}
    </Stack>
    {_.range(5).map((index) => (
      <Stack key={index} spacing={2}>
        <Skeleton height={40} width={200} variant="text" />
        <Skeleton variant="rectangular" height={56} />
        <Skeleton variant="rectangular" height={38} width={130} />
      </Stack>
    ))}
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      <Skeleton variant="rectangular" height={38} width={130} />
      <Skeleton variant="rectangular" height={38} width={130} />
    </Stack>
  </Stack>
);

export default memo(FormSkeleton);

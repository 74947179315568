import _ from "lodash";

import { CONSTRUCTION_TYPES, PROPERTY_TYPES } from "./constants";

export const getConstructionTypeLabelByValue = ({ value }: { value: string }) =>
  _.find(CONSTRUCTION_TYPES, ["value", value])?.label || value;

export const getConstructionTypeValueByLabel = ({ label }: { label: string }) =>
  _.find(CONSTRUCTION_TYPES, ["label", label])?.value || label;

export const getPropertyTypeLabelByValue = ({ value }: { value: string }) =>
  _.find(PROPERTY_TYPES, ["value", value])?.label || value;

export const getPropertyTypeValueByLabel = ({ label }: { label: string }) =>
  _.find(PROPERTY_TYPES, ["label", label])?.value || label;

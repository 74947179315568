import React, { useCallback } from "react";

import { ArrowBackIcon, EditIcon } from "icons";
import theme from "theme";
import { FORMATS, parse } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";

import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import SidepanelHeader from "components/SidepanelHeader";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import TaskForm from "entities/Task/components/Form";
import { TASK_SIDEPANEL_WIDTH } from "entities/Task/constants";
import {
  INewTaskChannel,
  ITaskChannel,
  updateTaskChannel,
} from "entities/Task/sdk";

export interface IEditTaskForm {
  open: boolean;
  dealId: number;
  taskChannel: ITaskChannel;
  onUpdated?: () => void;
  handleCancel?: () => void;
}

const EditTaskForm: React.FC<IEditTaskForm> = ({
  open,
  dealId,
  taskChannel,
  onUpdated,
  handleCancel,
}) => {
  const handleSubmit = useCallback(
    (
      data: Partial<
        Omit<INewTaskChannel, "deadline"> & {
          deadline: Date | null;
        }
      >
    ) =>
      updateTaskChannel(dealId, taskChannel.task.id, data)
        .then(() => {
          onUpdated && onUpdated();
          toast.successMessage("The task has been updated successfully!");
        })
        .catch(handleInvalidRequest),
    [onUpdated, dealId, taskChannel]
  );

  return (
    <Drawer
      hideBackdrop
      open={open}
      anchor="right"
      PaperProps={{ elevation: 0 }}
    >
      <Stack sx={{ width: TASK_SIDEPANEL_WIDTH, height: "100%" }}>
        <SidepanelHeader>
          <Stack direction="row" alignItems="center" spacing={1}>
            <EditIcon />
            <Text variant="h3">Edit task</Text>
          </Stack>
          <IconButton onClick={handleCancel}>
            <ArrowBackIcon />
          </IconButton>
        </SidepanelHeader>
        <Stack
          sx={{
            flex: 1,
            padding: theme.spacing(2, 6, 2, 4),
          }}
        >
          <TaskForm
            dealId={dealId}
            onSubmit={handleSubmit}
            handleCancel={handleCancel}
            submitLabel="Update Task"
            initialValues={{
              title: taskChannel.task.title,
              assignee_id: taskChannel.task.assignee_id?.pk || null,
              assignee_type:
                taskChannel.task.assignee_type === "Individual"
                  ? taskChannel.task.assignee_id?.pk.toString()
                  : taskChannel.task.assignee_type,
              description: taskChannel.task.description,
              deadline: taskChannel.task.deadline
                ? parse(
                    taskChannel.task.deadline,
                    FORMATS.BACKEND.DATE,
                    new Date()
                  )
                : undefined,
              priority_id: taskChannel.task.priority_id.toString(),
              visible_to_team: taskChannel.task.visible_to_team,
              completed: taskChannel.task.completed,
              tags: taskChannel.tag_data.map((tag) => tag.id),
            }}
          />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default EditTaskForm;

import React, { useCallback } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import Loading from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import FormCities from "entities/AnalyticsFilterGroup/components/FormCities";
import FormConstructionType from "entities/AnalyticsFilterGroup/components/FormConstructionType";
import FormProjectPhase from "entities/AnalyticsFilterGroup/components/FormProjectPhase";
import FormProjectSize from "entities/AnalyticsFilterGroup/components/FormProjectSize";
import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";

const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("The name is required!"),
});

const initialValues: Partial<IFilterGroup> = {
  name: "",
  project_phase: [],
  construction_type: [],
  project_size: [],
  cities: [],
};
export interface IForm {
  initialValues?: Partial<Omit<IFilterGroup, "id">>;
  onSubmit: (data: Partial<IFilterGroup>) => Promise<any>;
  onCancel?: () => void;
  submitLabel?: string;
}

const Form: React.FC<IForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  onCancel,
  submitLabel = "Save group",
}) => {
  const handleSubmit = useCallback(
    (
      values: Partial<IFilterGroup>,
      formikHelpers: FormikHelpers<Partial<IFilterGroup>>
    ) =>
      onSubmit({ ...initialValues, ...values }).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
      <Stack justifyContent="space-between" sx={{ height: "100%" }}>
        <Loading open={formik.isSubmitting} />
        <Stack
          spacing={4}
          sx={{
            padding: theme.spacing(2, 6, 2, 4),
          }}
        >
          <Stack spacing={2}>
            <Text variant="text1" sx={{ fontWeight: 700 }}>
              1. Name your group
            </Text>
            <TextField
              size="small"
              fullWidth
              label="Filter group"
              InputLabelProps={{ shrink: true }}
              {...formik.getFieldProps("name")}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Stack>
          <Stack spacing={2}>
            <Text variant="text1" sx={{ fontWeight: 700 }}>
              2. Basic filters
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Box sx={{ flex: "30%", marginBottom: theme.spacing(4) }}>
                <FormProjectPhase formik={formik} />
              </Box>
              <Box sx={{ flex: "50%", marginBottom: theme.spacing(4) }}>
                <FormConstructionType formik={formik} />
              </Box>
              <Box sx={{ flex: "30%", marginBottom: theme.spacing(4) }}>
                <FormProjectSize formik={formik} />
              </Box>
              <Box sx={{ flex: "50%", marginBottom: theme.spacing(4) }}>
                <FormCities formik={formik} />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          sx={{
            padding: theme.spacing(2, 8),
            backgroundColor: colors.white,
            position: "sticky",
            bottom: 0,
            zIndex: 1,
          }}
          spacing={1}
        >
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">{submitLabel}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;

import React from "react";

import theme from "theme";

import Box from "components/Box";
import Skeleton from "components/Skeleton";

const fieldsList = [1, 2, 3];

const SectionSkeleton = () => {
  return (
    <Box sx={{ paddingBottom: theme.spacing(4) }}>
      <Box
        sx={{
          padding: theme.spacing(0.5, 1),
          marginBottom: theme.spacing(1),
        }}
      >
        <Skeleton variant="text" />
      </Box>
      <Box sx={{ paddingLeft: theme.spacing(6) }}>
        {fieldsList.map((index) => (
          <Skeleton variant="text" key={index} />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(SectionSkeleton);

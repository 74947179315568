import React from "react";
import { createTheme } from "@mui/material/styles";

import { colors } from "./palette";
import { typography } from "./typography";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
    labelSmall: true;
    text1: true;
    text2: true;
    text3: true;
    text4: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    label: React.CSSProperties;
    labelSmall: React.CSSProperties;
    text1: React.CSSProperties;
    text2: React.CSSProperties;
    text3: React.CSSProperties;
    text4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    labelSmall?: React.CSSProperties;
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    text3?: React.CSSProperties;
    text4?: React.CSSProperties;
  }

  interface Theme {}
}

const theme = createTheme({
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      light: colors.blue80,
      main: colors.blue100,
      dark: colors.blue120,
    },
    secondary: {
      light: colors.blue10,
      main: colors.blue20,
      dark: colors.blue40,
    },
    error: {
      light: colors.red80,
      main: colors.red100,
      dark: colors.red120,
    },
    success: {
      main: colors.green120,
    },
  },
  typography,
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontWeight: 500,
          borderRadius: "4px",
          textTransform: "none",
          padding: "0 16px",
          fontSize: "16px",
          lineHeight: "140%",
          height: "38px",
        },
        sizeSmall: {
          padding: "2px 12px",
          margin: "auto 2px",
          height: "24px",
        },
        textSizeSmall: {
          fontSize: "13px",
        },
        outlinedSizeSmall: {
          fontSize: "13px",
        },
        containedSizeSmall: {
          fontSize: "13px",
        },
        textInherit: {
          color: colors.gray60,
        },
        textPrimary: {
          color: colors.gray60,
        },
        textSecondary: {
          color: colors.gray60,
          "&:hover": {
            color: colors.gray100,
          },
        },
        containedSecondary: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlined: {
          border: `1px solid ${colors.blue60}`,
          backgroundColor: colors.white,
          "&:hover": {
            border: `1px solid ${colors.blue60}`,
            backgroundColor: colors.white,
            filter: "brightness(95%)",
          },
        },
        outlinedSecondary: {
          color: colors.black,
          border: `1px solid ${colors.blue60}`,
          "&:hover": {
            border: `1px solid ${colors.blue60}`,
          },
        },
        sizeLarge: {
          height: "3em",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputSizeSmall: {
          height: "38px",
          boxSizing: "border-box",
        },
        notchedOutline: {
          borderColor: colors.blue60,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        rounded: {
          borderRadius: "8px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "1em",
          height: "auto",
        },
        label: {
          ...typography.text3,
          fontWeight: 500,
        },
        sizeSmall: {
          borderRadius: "0",
          padding: "2px 4px",
          fontSize: "0.6em",
          textDecoration: "uppercase",
          color: colors.blue100,
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          height: "38px",
          minWidth: "38px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          "&.Mui-expanded": {
            margin: "12px 0",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "4px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "10px 20px",
          "&:hover": {
            backgroundColor: colors.blue20,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: colors.pink100,
          "&.Mui-checked": {
            color: colors.pink100,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          ".MuiSvgIcon-root": {
            width: 16,
            height: 16,
            marginRight: 8,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          opacity: 0.3,
          color: colors.black,
        },
        sizeSmall: {
          height: "24px",
          width: "24px",
        },
        sizeMedium: {
          height: "32px",
          width: "32px",
        },
        sizeLarge: {
          height: "40px",
          width: "40px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          height: "38px",
        },
      },
    },
  },
});

export default theme;

import { useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { stripHTML } from "utils/common";
import { reverse } from "utils/urls";

import Autocomplete, {
  AutocompletePaperComponent,
} from "components/Autocomplete";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import { RENDERED_FIELD_TYPES } from "pages/Deal/DueDiligence/constants";

import { FieldType } from "entities/Field/sdk";
import { formatFieldValue } from "entities/Field/utils";
import { usePackageDealDetails } from "entities/Package/sdk";

export interface IItemSearch {}

const ItemSearch = () => {
  const [, setSearchParams] = useSearchParams();

  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
    categoryId?: string;
    sectionId?: string;
    fieldId?: string;
  }>();
  const navigate = useNavigate();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);

  const { data: checklist } = usePackageDealDetails({
    dealId,
    packageId,
  });

  const searchOptions = useMemo(() => {
    if (_.isNil(checklist?.tabs)) {
      return [];
    }

    return checklist.tabs.flatMap((tab) => {
      const categoryOptions =
        tab.categories.map((category) => ({
          key: `category-${category.category_id}-${category.name}`,
          label: category.name,
          subLabel: tab.name,
          onClick: () =>
            navigate(
              `${reverse(URLS.DEAL_PACKAGE, {
                dealId,
                packageId,
                tabId: tab.tab_id.toString(),
              })}?${createSearchParams({
                categoryId: category.category_id.toString(),
              })}`
            ),
          type: "category",
          value: category.name,
        })) || [];

      const sectionOptions =
        tab.categories.flatMap((category) =>
          category.sections.map((section) => ({
            key: `section-${section.section_id}-${section.name}-${category.category_id}`,
            label: section.name,
            subLabel: `${tab.name} > ${category.name}`,
            onClick: () =>
              navigate(
                `${reverse(URLS.DEAL_PACKAGE, {
                  dealId,
                  packageId,
                  tabId: tab.tab_id.toString(),
                })}?${createSearchParams({
                  sectionId: section.section_id.toString(),
                })}`
              ),
            type: "section",
            value: section.name,
          }))
        ) || [];

      const fieldOptions =
        tab.categories.flatMap((category) =>
          category.sections.flatMap((section) =>
            section.fields
              .filter((field) =>
                RENDERED_FIELD_TYPES.includes(field.field_type)
              )
              .map((field) => ({
                key: `field-${field.field_id}-${field.name}-${section.section_id}-${category.category_id}`,
                label: field.name,
                subLabel: `${tab.name} > ${category.name} > ${section.name}`,
                onClick: () =>
                  navigate(
                    `${reverse(URLS.DEAL_PACKAGE, {
                      dealId,
                      packageId,
                      tabId: tab.tab_id.toString(),
                    })}?${createSearchParams({
                      fieldId: field.field_id.toString(),
                    })}`
                  ),
                type: "field",
                value: field.name,
              }))
          )
        ) || [];

      const fieldValueOptions =
        tab.categories
          .flatMap((category) =>
            category.sections.flatMap((section) =>
              section.fields
                .filter(
                  (field) =>
                    field.field_type !== FieldType.FILE &&
                    RENDERED_FIELD_TYPES.includes(field.field_type)
                )
                .flatMap((field) => {
                  const keysToOmit = ["key"];

                  if (field.field_type === FieldType.FIXED_TABLE) {
                    keysToOmit.push(field.columns[0]);
                  }

                  return field.values.flatMap((fieldValue) => {
                    if (
                      !_.isEmpty(fieldValue.column_value) &&
                      _.isArray(fieldValue.column_value)
                    ) {
                      let columnValue = fieldValue.column_value.map((item) =>
                        _.omit(item, keysToOmit)
                      );

                      return columnValue?.flatMap(
                        (
                          columnValue: { [key: string]: string },
                          columnIndex: number
                        ) =>
                          Object.keys(columnValue).map((cellValueKey) => ({
                            key: `fieldValue-${fieldValue.value_id}-${cellValueKey}-${columnIndex}-${field.field_id}-${section.section_id}-${category.category_id}`,
                            label: columnValue[cellValueKey],
                            subLabel: `${tab.name} > ${category.name} > ${section.name} > ${field.name} > ${cellValueKey}`,
                            onClick: () =>
                              navigate(
                                `${reverse(URLS.DEAL_PACKAGE, {
                                  dealId,
                                  packageId,
                                  tabId: tab.tab_id.toString(),
                                })}?${createSearchParams({
                                  fieldId: field.field_id.toString(),
                                })}`
                              ),
                            type: "input value",
                            value: columnValue[cellValueKey],
                          }))
                      );
                    }

                    let value: string | number | null | undefined =
                      fieldValue.default_value ||
                      fieldValue.selected_value ||
                      stripHTML({ html: fieldValue.rich_value });

                    if (field.field_type === FieldType.DROPDOWN) {
                      value = field.options.find(
                        (item) => item.value === fieldValue.selected_value
                      )?.label;
                    } else {
                      value = formatFieldValue({
                        fieldValue: value,
                        fieldFormatType: field.field_format_type,
                      });
                    }

                    if (_.isNil(value) || value === "null" || value === "") {
                      return null;
                    }

                    return [
                      {
                        key: `fieldValue-${fieldValue.value_id}-${value}-${field.field_id}-${section.section_id}-${category.category_id}`,
                        label: value,
                        subLabel: `${tab.name} > ${category.name} > ${section.name} > ${field.name}`,
                        onClick: () =>
                          navigate(
                            `${reverse(URLS.DEAL_PACKAGE, {
                              dealId,
                              packageId,
                              tabId: tab.tab_id.toString(),
                            })}?${createSearchParams({
                              fieldId: field.field_id.toString(),
                            })}`
                          ),
                        type: "input value",
                        value,
                      },
                    ];
                  });
                })
            )
          )
          .filter((item) => item !== null) || [];

      const fileOptions =
        tab.categories.flatMap((category) =>
          category.sections.flatMap((section) =>
            section.fields
              .filter((field) => field.field_type === "file")
              .flatMap((field) =>
                field.values.map((fieldValue) => ({
                  key: `file-${fieldValue.file?.id}-of-field-${field.field_id}-of-section-${section.section_id}-of-category-${category.category_id}`,
                  label: fieldValue.file?.original_file_name,
                  subLabel: `${tab.name} > ${category.name} > ${section.name} > ${field.name}`,
                  onClick: () =>
                    navigate(
                      `${reverse(URLS.DEAL_PACKAGE, {
                        dealId,
                        packageId,
                        tabId: tab.tab_id.toString(),
                      })}?${createSearchParams({
                        fieldId: field.field_id.toString(),
                      })}`
                    ),
                  type: "uploaded file",
                  value: fieldValue.file?.original_file_name,
                }))
              )
          )
        ) || [];

      return [
        ...categoryOptions,
        ...sectionOptions,
        ...fieldOptions,
        ...fieldValueOptions,
        ...fileOptions,
      ];
    });
  }, [checklist, dealId, packageId, navigate]);

  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      options={searchOptions}
      PaperComponent={AutocompletePaperComponent}
      sx={{
        marginBottom: 2,
      }}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) =>
          _.toString(option?.value)
            ?.toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      renderInput={(params) => (
        <TextField {...params} label="Search across this checklist" />
      )}
      onChange={(_, value, reason) => {
        if (typeof value === "string") {
          return;
        }

        if (reason === "clear") {
          setSearchParams({});
          return;
        }

        value?.onClick();
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          <Stack
            width="100%"
            spacing={1}
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Stack sx={{ maxWidth: "80%" }}>
              <Text noWrap>{option.label}</Text>
              <Text variant="text4" color={colors.gray60}>
                {option.subLabel}
              </Text>
            </Stack>

            <Text
              variant="labelSmall"
              color={colors.white}
              fontWeight={500}
              sx={{
                backgroundColor: colors.gray80,
                padding: theme.spacing(0.25, 0.5),
              }}
            >
              {option.type}
            </Text>
          </Stack>
        </li>
      )}
    />
  );
};

export default ItemSearch;

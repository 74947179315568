import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import _ from "lodash";

import { notImplementedError } from "utils/common";

import Sidepanel from "entities/Deal/components/Sidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { IField } from "entities/Field/sdk";
import { IPackageCategory, IPackageSection } from "entities/Package/sdk";
import { IInitialValues as ITaskInitialValues } from "entities/Task/components/Form";
import { ITaskChannel } from "entities/Task/sdk";

export const DealSidepanelContext = React.createContext({
  open: notImplementedError as any,
  setOpen: notImplementedError as Dispatch<SetStateAction<boolean>>,
  meta: notImplementedError as any as IMeta | null,
  setMeta: notImplementedError as Dispatch<SetStateAction<IMeta | null>>,
});

export interface IDealSidepanelContextProvider {
  isInDealContext: boolean;
  children?: React.ReactNode;
}

export interface IMeta {
  tab: TABS;
  updateParent?: () => void;
  dealId?: number;
  subTab?: TASKS_TAB_SUBTABS;
  taskChannel?: ITaskChannel;
  taskCategory?: IPackageCategory;
  taskSection?: IPackageSection;
  taskField?: IField;
  taskInitialData?: ITaskInitialValues;
}
export const DealSidepanelContextProvider = ({
  isInDealContext,
  children,
}: IDealSidepanelContextProvider) => {
  const [open, setOpen] = useState<boolean>(false);
  const [meta, setMeta] = useState<IMeta | null>(null);

  return (
    <DealSidepanelContext.Provider
      value={{
        open,
        setOpen,
        meta,
        setMeta,
      }}
    >
      {children}
      <Sidepanel isInDealContext={isInDealContext} />
    </DealSidepanelContext.Provider>
  );
};

export const useDealSidepanel = () => {
  const { open, setOpen, meta, setMeta } = useContext(DealSidepanelContext);

  const show = useCallback(
    ({ meta: metaArg }: { meta: IMeta }) => {
      setOpen(true);
      setMeta(metaArg);
    },
    [setOpen, setMeta]
  );

  const resetMeta = useCallback(() => setMeta(null), [setMeta]);

  const setSubTab = useCallback(
    ({ tabKey }: { tabKey: TASKS_TAB_SUBTABS }) => {
      if (!_.isNil(meta)) {
        setMeta({ ...meta, subTab: tabKey });
      }
    },
    [meta, setMeta]
  );

  const openCreateNewTaskSubTab = useCallback(
    ({
      updateParent,
      taskSection,
      taskCategory,
      taskField,
      initialValues,
    }: {
      updateParent: () => void;
      taskSection?: IPackageSection;
      taskCategory?: IPackageCategory;
      taskField?: IField;
      initialValues?: ITaskInitialValues;
    }) => {
      show({
        meta: {
          tab: TABS.TASKS,
          subTab: TASKS_TAB_SUBTABS.ADD_TASK,
          taskInitialData: initialValues,
          taskField: taskField,
          taskSection: taskSection,
          taskCategory: taskCategory,
          updateParent: updateParent,
        },
      });
    },
    [show]
  );

  return useMemo(
    () => ({
      show,
      open,
      setOpen,
      meta,
      setMeta,
      resetMeta,
      setSubTab,
      openCreateNewTaskSubTab,
    }),
    [
      show,
      open,
      setOpen,
      meta,
      setMeta,
      resetMeta,
      setSubTab,
      openCreateNewTaskSubTab,
    ]
  );
};

import { useCallback, useState } from "react";

import { DeleteOutlineOutlinedIcon, InfoIcon } from "icons";

import ClickAwayListener from "components/ClickAwayListener";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IValueCellEditIcon {
  aggregated: boolean | undefined;
  onDeleteClick: () => void;
  deleteButtonDataTestid?: string;
}

const ValueCellEditIcon = ({
  aggregated,
  onDeleteClick,
  deleteButtonDataTestid,
}: IValueCellEditIcon) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return aggregated ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={
          <Text>
            This item is aggregated from monthly or quarterly data, therefore it
            cannot be deleted.
          </Text>
        }
        arrow
        placement="top"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleTooltipClose}
        open={open}
      >
        <IconButton size="small" component="span" onClick={handleTooltipOpen}>
          <InfoIcon sx={{ width: 20, height: 20 }} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <IconButton
      size="small"
      component="span"
      title="Delete"
      onClick={onDeleteClick}
      dataTestid={deleteButtonDataTestid}
    >
      <DeleteOutlineOutlinedIcon sx={{ width: 20, height: 20 }} />
    </IconButton>
  );
};

export default ValueCellEditIcon;

import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import { EMPTY_VALUE_REGEX } from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import Stack from "components/Stack";

import FormSettingsTree from "entities/Deal/components/OverviewSections/FormSettingTree";
import {
  IDealCommunityAmenities,
  useGetCommunityAmenitiesSettings,
} from "entities/Deal/sdk";

const VALIDATION_SCHEMA = yup.object({});

const initialValues: Partial<IDealCommunityAmenities> = {
  description: "",
  front_desk_and_security: [],
  storage: [],
  recreational_spaces: [],
  energy: [],
  other: [],
  accreditations: [],
};

interface ICommunityAmenitiesEditForm {
  initialValues: Partial<IDealCommunityAmenities>;
  onSubmit: (data: Partial<IDealCommunityAmenities>) => Promise<any>;
  handleCancel?: () => void;
}
const CommunityAmenitiesEditForm: React.FC<ICommunityAmenitiesEditForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
}) => {
  const { data: unitAmenitiesSettings } = useGetCommunityAmenitiesSettings();

  const FRONT_DESK_AND_SECURITY_CHECKBOXES =
    unitAmenitiesSettings?.front_desk_and_security?.map((data) => ({
      value: data,
      label: data,
    }));

  const RECREATIONAL_SPACES_CHECKBOXES =
    unitAmenitiesSettings?.recreational_spaces?.map((data) => ({
      value: data,
      label: data,
    }));

  const STORAGE_CHECKBOXES = unitAmenitiesSettings?.storage?.map((data) => ({
    value: data,
    label: data,
  }));

  const ENERGY_CHECKBOXES = unitAmenitiesSettings?.energy?.map((data) => ({
    value: data,
    label: data,
  }));

  const OTHER_CHECKBOXES = unitAmenitiesSettings?.other?.map((data) => ({
    value: data,
    label: data,
  }));

  const ACCREDITATIONS_CHECKBOXES = unitAmenitiesSettings?.accreditations?.map(
    (data) => ({
      value: data,
      label: data,
    })
  );

  const handleSubmit = useCallback(
    (data: Partial<IDealCommunityAmenities>) => {
      if (data.description && EMPTY_VALUE_REGEX.test(data.description)) {
        return onSubmit({ ...data, description: "" });
      }
      return onSubmit(data);
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleChangeDescription = useCallback(
    (newDescription: string) =>
      formik.setFieldValue("description", newDescription),
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack spacing={2}>
            {FRONT_DESK_AND_SECURITY_CHECKBOXES && (
              <FormSettingsTree
                label="Security &amp; services"
                options={FRONT_DESK_AND_SECURITY_CHECKBOXES}
                formik={formik}
                formValueKey="front_desk_and_security"
              />
            )}
            {STORAGE_CHECKBOXES && (
              <FormSettingsTree
                label="Parking &amp; storage"
                options={STORAGE_CHECKBOXES}
                formik={formik}
                formValueKey="storage"
              />
            )}
            {ACCREDITATIONS_CHECKBOXES && (
              <FormSettingsTree
                label="Accreditations"
                options={ACCREDITATIONS_CHECKBOXES}
                formik={formik}
                formValueKey="accreditations"
              />
            )}
            {OTHER_CHECKBOXES && (
              <FormSettingsTree
                label="Other"
                options={OTHER_CHECKBOXES}
                formik={formik}
                formValueKey="other"
              />
            )}
          </Stack>
          <Stack spacing={2}>
            {RECREATIONAL_SPACES_CHECKBOXES && (
              <FormSettingsTree
                label="Community spaces"
                options={RECREATIONAL_SPACES_CHECKBOXES}
                formik={formik}
                formValueKey="recreational_spaces"
              />
            )}
            {ENERGY_CHECKBOXES && (
              <FormSettingsTree
                label="Energy"
                options={ENERGY_CHECKBOXES}
                formik={formik}
                formValueKey="energy"
              />
            )}
          </Stack>
        </Stack>
        <Stack data-testid="community-amenities-rtf-description">
          <RichTextEditorField
            inputLabel="Description"
            defaultValue={formik.getFieldProps("description").value}
            onChange={handleChangeDescription}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            onClick={handleCancel}
            data-testid="close-community-amenities-dialog-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-community-amenities-form-button"
        >
          Update
        </Button>
      </Stack>
    </form>
  );
};

export default CommunityAmenitiesEditForm;

import React from "react";
import MuiTypography, { TypographyProps } from "@mui/material/Typography";

export type IText = TypographyProps<"div", { component?: "div" | "span" }>;

const Text: React.FC<IText> = React.forwardRef((props, ref) => {
  return <MuiTypography ref={ref} component="div" {...props} />;
});

export default Text;

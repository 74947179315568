import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import { fieldOptionRetrieve } from "utils/common";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Select from "components/Select";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import { IApplicationField } from "entities/Application/sdk";
import { IFieldOption } from "entities/Package/sdk";

export interface IDropdownfield {
  field: IApplicationField;
  editable: boolean;
  onUpdate: ({
    field,
    value,
  }: {
    field: IApplicationField;
    value: string | null | undefined;
  }) => void;
}

const Dropdownfield = ({ field, editable, onUpdate }: IDropdownfield) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<IFieldOption | null>(
    fieldOptionRetrieve({
      options: field.options,
      value: _.get(field.values, "[0].value"),
    })
  );

  const handleEditButtonClick = useCallback(() => setIsEditing(true), []);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(
        fieldOptionRetrieve({
          options: field.options,
          value: event.target.value,
        })
      );
    },
    [setValue, field.options]
  );
  const handleSave = useCallback(() => {
    onUpdate({ field, value: value ? value.value : null });
    setIsEditing(false);
  }, [field, value, onUpdate]);

  const handleCancel = useCallback(() => {
    setValue(
      fieldOptionRetrieve({
        options: field.options,
        value: _.get(field.values, "[0].value"),
      })
    );
    setIsEditing(false);
  }, [setValue, field]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const showFieldValue = useMemo(() => !isEditing && value, [isEditing, value]);
  const showEditIconButton = useMemo(
    () => editable && !isEditing,
    [isEditing, editable]
  );
  const showRequiredTag = useMemo(
    () => editable && !value && field.is_required,
    [editable, value, field.is_required]
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {showRequiredTag && <Tag label="required" variant="red" />}
      {showFieldValue && (
        <Text variant="text2" maxWidth="50vw">
          {value?.label}
        </Text>
      )}
      {showEditIconButton && (
        <IconButton size="small" onClick={handleEditButtonClick}>
          <EditIcon />
        </IconButton>
      )}
      {isEditing && (
        <Stack spacing={1} direction="row" alignItems="center">
          <Select
            options={field.options}
            size="small"
            value={value?.value}
            onChange={handleChange}
            placeholder="Select data"
            selectProps={{ sx: { maxWidth: "300px" } }}
          />
          <Button onClick={handleSave}>Save</Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleReset}
            data-testid="reset-button"
          >
            Reset
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Dropdownfield;

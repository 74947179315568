import { useParams } from "react-router-dom";

import { URLS } from "config/urls";
import theme from "theme";
import { useRedirectUponFetchFailure } from "utils/common";
import { BOX_MAX_WIDTH } from "utils/constants";

import Box from "components/Box";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import { usePageTitle } from "components/usePageTitle";

import Category from "entities/Checklist/components/Category";
import ShareButton from "entities/Checklist/components/ShareButton";
import { FieldWrapperType } from "entities/Checklist/constants";
import { usePackageDetailsForSharing } from "entities/Checklist/sdk";

const ChecklistDetails = () => {
  const params = useParams<{ checklistId: string }>();
  const checklistId = Number(params.checklistId);

  usePageTitle("Builders Patch: Checklist Details");

  const {
    data: checklist,
    loading,
    error,
  } = usePackageDetailsForSharing({
    packageId: checklistId,
  });

  useRedirectUponFetchFailure({ error, loading });

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Box
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <Stack spacing={2}>
            <PageHeader
              backLink={URLS.CHECKLIST_TEMPLATE_LIST}
              backTitle="Intake form templates"
              title={checklist?.name || <Skeleton />}
              actions={<ShareButton packageId={checklistId} />}
            />
            <Stack spacing={1} data-testid="intake-template-content">
              {checklist?.tabs
                .filter((tab) => !tab.is_deleted)
                .map((tab) => (
                  <Stack key={`tab-${tab.tab_id}`} spacing={1}>
                    {tab.categories
                      .filter((category) => !category.is_deleted)
                      .map((category) => (
                        <Category
                          key={`tab-${tab.tab_id}-category-${category.category_id}`}
                          category={category}
                          fieldWrapperMeta={{}}
                          fieldWrapperType={FieldWrapperType.CHECKLIST}
                        />
                      ))}
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default ChecklistDetails;

import _ from "lodash";

import ContentSeparator from "components/ContentSeparator";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Text from "components/Text";

import ScheduleEditButton from "entities/Deal/components/OverviewSchedule/ScheduleEditButton";
import ScheduleTable from "entities/Deal/components/OverviewSchedule/ScheduleTable";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import { IMemoSection } from "entities/Memo/sdk";
import { useDealScheduleManager } from "entities/Memo/utils";

interface IOverviewSchedule {
  dealId: number;
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}

const OverviewSchedule: React.FC<IOverviewSchedule> = ({
  dealId,
  externalToken,
  section,
  onUpdate,
}) => {
  const { data: schedule } = useDealScheduleManager({
    dealId,
    token: externalToken,
  });

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Text variant="h2" data-testid="section-header-name">
          Schedule
        </Text>
        {_.isUndefined(externalToken) && (
          <NoPrint>
            <Stack direction="row" spacing={1} alignItems="center">
              {!section?.is_na && <ScheduleEditButton />}
              {section && (
                <MemoSectionContextButton
                  dealId={dealId}
                  section={section}
                  onUpdate={onUpdate}
                />
              )}
            </Stack>
          </NoPrint>
        )}
      </Stack>

      <ContentSeparator label="Schedule" />
      {section?.is_na && <NASection />}
      {schedule && !section?.is_na && <ScheduleTable schedule={schedule} />}
    </Stack>
  );
};

export default OverviewSchedule;

import React from "react";
import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import CommunityAmenitiesEditDialog from "entities/Deal/components/OverviewCommunityAmenities/CommunityAmenitiesEditDialog";

export interface ICommunityAmenitiesEditButton {}

const CommunityAmenitiesEditButton: React.FC<
  ICommunityAmenitiesEditButton
> = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        dataTestid="edit-community-amenities-section-button"
      >
        <EditIcon />
      </IconButton>
      <CommunityAmenitiesEditDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default CommunityAmenitiesEditButton;

import React, { useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { colors } from "theme/palette";

import Autocomplete from "components/Autocomplete";
import InputLabel from "components/InputLabel";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import { ICompanyAutocompleteOption, useCompanies } from "entities/Company/sdk";

export interface IFormValues {
  first_name: string;
  last_name: string;
  company_name: string;
}

export interface IShareAcceptanceForm {
  inviteeEmail: string;
  handleSubmit: ({ values }: { values: IFormValues }) => void;
}

const ShareAcceptanceForm = ({
  inviteeEmail,
  handleSubmit,
}: IShareAcceptanceForm) => {
  const [companySearchParam, setCompanySearchParam] = useState("");
  const formik = useFormik<IFormValues>({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: "",
    },
    onSubmit: (values) => handleSubmit({ values }),
  });

  const { data: companyOptions } = useCompanies({ name: companySearchParam });

  const handleCompanySearchParamChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setCompanySearchParam(event.target.value);
  };

  return (
    <>
      <Text variant="text1" fontWeight={700} marginBottom={2}>
        User profile
      </Text>
      <Stack direction="row" spacing={3} marginBottom={2}>
        <Stack width="100%">
          <InputLabel>First name</InputLabel>
          <TextField
            variant="outlined"
            {...formik.getFieldProps("first_name")}
          />
        </Stack>
        <Stack width="100%">
          <InputLabel>Last name</InputLabel>
          <TextField
            variant="outlined"
            {...formik.getFieldProps("last_name")}
          />
        </Stack>
      </Stack>
      <Text variant="text2" color={colors.gray80} marginBottom={3}>
        Your account will be created using your email{" "}
        <Text fontWeight={700} sx={{ display: "inline" }}>
          {inviteeEmail}
        </Text>
      </Text>
      <Text variant="text1" fontWeight={700} marginBottom={2}>
        Company profile
      </Text>
      <InputLabel>Company name</InputLabel>
      <Autocomplete
        filterOptions={(x) => x}
        value={
          _.find(companyOptions?.results || [], formik.values.company_name) as
            | ICompanyAutocompleteOption
            | undefined
        }
        options={companyOptions?.results || []}
        onChange={(event, value: ICompanyAutocompleteOption | null) => {
          if (!_.isNil(value)) {
            formik.setFieldValue("company_name", value.name);
          }
        }}
        renderInput={(params) => (
          <TextField {...params} onChange={handleCompanySearchParamChange} />
        )}
        getOptionLabel={(option: ICompanyAutocompleteOption) => option.name}
      />
      <Text marginTop={2} variant="text2" color={colors.gray80}>
        Please provide the full name of the organization you belong to if it
        isn’t already in our system. You can finish setting up your company
        profile later.
      </Text>
    </>
  );
};

export default ShareAcceptanceForm;

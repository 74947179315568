export enum TABS {
  TASKS = "tasks",
  NOTES = "notes",
}

export enum TASKS_TAB_SUBTABS {
  DETAILS = "taskDetails",
  CHAT = "chat",
  ADD_TASK = "addTask",
}

export enum ACTIONS {
  NEW_TASK = "newTask",
  OPEN_TASK_CHAT = "openTaskChat",
  OPEN_TASK_DETAIL = "openTaskDetail",
}

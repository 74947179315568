import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import { MoreHorizIcon } from "icons";
import theme from "theme";
import { format, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import ButtonDropdown from "components/ButtonDropdown";
import Link from "components/Link";
import { MenuItem } from "components/Menu";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import ChipsGroup from "entities/AnalyticsFilterGroup/components/ChipsGroup";
import EditFilterSidebar from "entities/AnalyticsFilterGroup/components/EditFilterSidebar";
import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";
import {
  ANALYTICS_TYPES,
  deleteFilter,
  useAnalyticsFilterGroups,
} from "entities/AnalyticsFilterGroup/sdk";
import { getConstructionTypeLabelByValue } from "entities/Deal/utils";

import { getModifiedByText } from "./utils";

interface IAnalyticsFilterGroupListBox {
  filterGroup: IFilterGroup;
  analyticsType: ANALYTICS_TYPES;
}

const AnalyticsFilterGroupListBox: React.FC<IAnalyticsFilterGroupListBox> = ({
  filterGroup,
  analyticsType,
}) => {
  const chips = useMemo(() => {
    if (!filterGroup) {
      return [];
    }

    const construction_types = _.compact(
      filterGroup.construction_type.map((x) =>
        getConstructionTypeLabelByValue({ value: x })
      )
    );

    return [
      ...filterGroup.cities,
      ...filterGroup.project_size,
      ...filterGroup.project_phase,
      ...construction_types,
    ];
  }, [filterGroup]);
  // TODO
  const { mutate: updateFilterGroupsList } =
    useAnalyticsFilterGroups(analyticsType);
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const [openEditSidebar, setOpenEditSidebar] = useState(false);

  const handleOpenEditFilter = useCallback(() => {
    setOpenEditSidebar(true);
  }, []);

  const handleCloseEditFilter = useCallback(() => {
    setOpenEditSidebar(false);
  }, []);

  const deleteFilterGroup = useCallback(
    () =>
      deleteFilter(filterGroup.id).then(() => {
        updateFilterGroupsList((groupsList: IFilterGroup[] | undefined) =>
          groupsList?.filter(({ id }) => filterGroup.id !== id)
        );
      }),
    [filterGroup.id, updateFilterGroupsList]
  );

  const handleDeleteFilterGroup = useCallback(() => {
    showConfirmationDialog({
      onConfirm: deleteFilterGroup,
      message: "Are you sure you want to delete this record?",
    });
  }, [showConfirmationDialog, deleteFilterGroup]);

  const linkUrl = useMemo(() => {
    if (analyticsType === ANALYTICS_TYPES.DEVELOPMENT_BUDGET) {
      return reverse(URLS.ANALYTICS_DEV_BUDGET_DETAILS, {
        id: filterGroup.id,
      });
    }
    return reverse(URLS.ANALYTICS_INCOME_AND_OPEX_DETAILS, {
      id: filterGroup.id,
    });
  }, [analyticsType, filterGroup]);

  return (
    <>
      <Paper
        sx={{
          border: "1px solid #eee",
          borderRadius: "6px",
          padding: theme.spacing(2, 3),
        }}
      >
        <Stack spacing={0.5}>
          <Box sx={{ display: "grid", gridTemplateColumns: "240px 1fr 160px" }}>
            <Box>
              <Text variant="text1">{filterGroup.name}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ChipsGroup chips={chips} />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="flex-end"
            >
              <ButtonDropdown
                icon={<MoreHorizIcon />}
                label=""
                variant="text"
                width="auto"
              >
                <MenuItem onClick={handleOpenEditFilter}>Edit</MenuItem>
                <MenuItem onClick={handleDeleteFilterGroup}>Delete</MenuItem>
              </ButtonDropdown>
              <Link to={linkUrl}>
                <Button>Show</Button>
              </Link>
            </Stack>
          </Box>
          <Text variant="text3" sx={{ opacity: 0.6 }}>
            Last modified by {getModifiedByText(filterGroup)} •{" "}
            {format(parseJSON(filterGroup.updated_at), "MMM dd, yyyy")}
          </Text>
        </Stack>
      </Paper>
      <EditFilterSidebar
        isOpen={openEditSidebar}
        onClose={handleCloseEditFilter}
        filterGroup={filterGroup}
        analyticsType={analyticsType}
      />
    </>
  );
};

export default AnalyticsFilterGroupListBox;

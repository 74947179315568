import { useMemo } from "react";
import _ from "lodash";

import { ConnectedFieldsDisabledIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Breadcrumbs from "components/Breadcrumbs";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface ILinkedFieldInfoBox {
  title: string;
  subTitle: string | string[]; // when the subTitle is an array, it will be displayed as breadcrumbs
  showConnectionsDisabledIcon?: boolean;
}

const LinkedFieldInfoBox = ({
  title,
  subTitle,
  showConnectionsDisabledIcon = false,
}: ILinkedFieldInfoBox) => {
  const subTitleTooltip = useMemo(() => {
    if (_.isArray(subTitle)) {
      return subTitle.join(" > ");
    }
    return subTitle;
  }, [subTitle]);

  const formattedSubTitle = useMemo(() => {
    if (_.isArray(subTitle)) {
      return subTitle;
    }
    return [subTitle];
  }, [subTitle]);

  return (
    <Paper
      sx={{
        border: `1px solid ${colors.blue80}`,
        padding: theme.spacing(1, 2),
      }}
    >
      <Stack
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip title={title} placement="top">
            <Text
              variant="text2"
              fontWeight={700}
              color={colors.black}
              sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              {title}
            </Text>
          </Tooltip>
          {showConnectionsDisabledIcon && (
            <Tooltip
              title="Connections disabled: navigate to this field's connections settings to re-establish synching"
              placement="top"
            >
              <span>
                <ConnectedFieldsDisabledIcon
                  style={{
                    height: "16px",
                    width: "16px",
                    cursor: "pointer",
                    alignSelf: "center",
                    display: "flex",
                  }}
                />
              </span>
            </Tooltip>
          )}
        </Stack>
        <Tooltip title={subTitleTooltip} placement="top">
          <Stack direction="row" sx={{ flexWrap: "nowrap" }}>
            <Breadcrumbs
              separator=">"
              sx={{
                maxWidth: "100%",
                ".MuiBreadcrumbs-ol": {
                  flexWrap: "nowrap",
                },
                ".MuiBreadcrumbs-li": {
                  maxWidth: "100%",
                  display: "inline",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              }}
            >
              {formattedSubTitle.map((subTitleItem, index) => (
                <Text
                  key={index}
                  component="span"
                  variant="text4"
                  color={colors.gray80}
                  sx={{
                    maxWidth: "100%",
                    display: "inline",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {subTitleItem}
                </Text>
              ))}
            </Breadcrumbs>
          </Stack>
        </Tooltip>
      </Stack>
    </Paper>
  );
};

export default LinkedFieldInfoBox;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { MoreVertIcon } from "icons";
import { useCaching } from "utils/common";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import Text from "components/Text";

import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import { useDealChecklistPermission } from "entities/Deal/sdk";
import FieldHistoryDialog from "entities/Field/components/FieldHistoryDialog";
import { IField } from "entities/Field/sdk";
import ShareWithButton from "entities/Package/components/ShareWithButton";
import { fieldUpdateNAStatusBulk } from "entities/Package/sdk";
import DueDiligenceFieldNewTaskButton from "entities/Task/components/DueDiligenceFieldNewTaskButton";

interface IFieldDropdownMenu {
  field: IField;
  packageId: number;
  dealId: number;
  categoryId: number;
  sectionId: number;
  tabId: number;
  onUpdate: ({ message }: { message: string }) => void;
  showHistoryButton?: boolean;
  menuButtonDataTestid?: string;
}

const FieldDropdownMenu: React.FC<IFieldDropdownMenu> = ({
  dealId,
  packageId,
  field,
  categoryId,
  sectionId,
  tabId,
  onUpdate,
  showHistoryButton = true,
  menuButtonDataTestid,
}) => {
  const [showValuesHistoryDialog, setShowValuesHistoryDialog] =
    useState<boolean>(false);

  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });
  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });
  const hasFullAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  const [status, setStatus] = useCaching(field.n_a_status || false);

  const handleMarkAsNA = useCallback(() => {
    fieldUpdateNAStatusBulk({
      dealId,
      fieldIds: [field.field_id],
      naStatus: !field.n_a_status,
    }).then(() => {
      setStatus(!field.n_a_status);
      onUpdate({
        message: `The field was successfully ${
          !field.n_a_status ? "disabled" : "enabled"
        }!`,
      });
    });
  }, [dealId, field, setStatus, onUpdate]);

  if (!hasFullAccess) {
    return null;
  }

  return (
    <>
      <ButtonDropdown
        variant="text"
        label={<MoreVertIcon />}
        width="auto"
        size="small"
        sx={{ padding: "2px" }}
        keepMenuMounted
        buttonDataTestid={menuButtonDataTestid}
      >
        {showHistoryButton && (
          <span>
            <GroupLabel label="history" />
            <MenuItem
              onClick={() => setShowValuesHistoryDialog(true)}
              dataTestid="action-menu-option"
            >
              <Text variant="text2" data-testid="action-menu-option-name">
                Show field values history
              </Text>
            </MenuItem>
          </span>
        )}
        <GroupLabel label="status" />
        <MenuItem onClick={handleMarkAsNA} dataTestid="action-menu-option">
          <Text variant="text2" data-testid="action-menu-option-name">
            {status ? "Revoke N/A status" : "Mark as N/A"}
          </Text>
        </MenuItem>

        {loggedUserIsDealAdmin && (
          <span>
            <GroupLabel label="share settings" />
            <ShareWithButton initiallySelectedItemIds={[field.field_id]} />
          </span>
        )}

        <GroupLabel label="tasks" />
        <DueDiligenceFieldNewTaskButton
          dealId={dealId}
          companyField={field}
          packageId={packageId}
          sectionId={sectionId}
          categoryId={categoryId}
          tabId={tabId}
          menuOptionDataTestid="action-menu-option"
        />
      </ButtonDropdown>

      {showValuesHistoryDialog && (
        <FieldHistoryDialog
          dealId={dealId}
          field={field}
          onClose={() => setShowValuesHistoryDialog(false)}
        />
      )}
    </>
  );
};

export default FieldDropdownMenu;

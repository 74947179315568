import React from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Chip from "components/Chip";

interface IChipsGroup {
  chips: string[];
}

const ChipsGroup: React.FC<IChipsGroup> = ({ chips }) => {
  return (
    <Box
      sx={{ flexWrap: "wrap", "& > *": { margin: theme.spacing(0, 1, 1, 0) } }}
    >
      {chips.length === 0 && <Chip label="no filters set" />}
      {_.take(chips, 6).map((chip) => (
        <Chip
          key={chip}
          label={chip}
          sx={{
            height: theme.spacing(3),
            border: `1px solid ${colors.blue40}`,
            backgroundColor: colors.white,
          }}
        />
      ))}
      {chips.length > 6 && (
        <Chip
          label={`+${chips.length - 6}`}
          sx={{
            height: theme.spacing(3),
            border: `1px solid ${colors.blue40}`,
            backgroundColor: colors.white,
          }}
        />
      )}
    </Box>
  );
};

export default ChipsGroup;

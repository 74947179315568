import { useCallback } from "react";

import { MessageOutlinedIcon } from "icons";
import { colors } from "theme/palette";

import Badge from "components/Badge";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { useTaskChannels } from "entities/Task/sdk";

interface IWorkspaceTaskChatButton {
  messageCount: number;
  taskChannelId: number;
  dealId: number;
}

const WorkspaceTaskChatButton: React.FC<IWorkspaceTaskChatButton> = ({
  messageCount,
  taskChannelId,
  dealId,
}) => {
  const { data: taskChannels } = useTaskChannels({ dealId });
  const { show } = useDealSidepanel();

  const handleOpenTaskChat = useCallback(() => {
    const taskChannel = taskChannels?.find(
      (taskChannel) => taskChannel.id === taskChannelId
    );
    show({
      meta: {
        tab: TABS.TASKS,
        subTab: TASKS_TAB_SUBTABS.CHAT,
        taskChannel: taskChannel,
        dealId,
      },
    });
  }, [dealId, show, taskChannelId, taskChannels]);

  return (
    <Badge
      badgeContent={messageCount}
      color="primary"
      onClick={handleOpenTaskChat}
      sx={{ cursor: "pointer" }}
    >
      <MessageOutlinedIcon
        sx={{ color: colors.gray60, width: "20px", height: "20px" }}
      />
    </Badge>
  );
};

export default WorkspaceTaskChatButton;

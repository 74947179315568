import { memo } from "react";
import _ from "lodash";

import Stack from "components/Stack";
import Tag from "components/Tag";

import { INotification } from "entities/Notification/sdk";

export interface ITags {
  notification: INotification;
}

const Tags = ({ notification }: ITags) => (
  <Stack direction="row" spacing={0.5}>
    <Tag label={notification.category} variant="blue80" />
    {!_.isNil(notification.deal) && (
      <Tag
        variant="blue10"
        label={notification.deal.name}
        sx={{
          textTransform: "unset",
          fontWeight: 700,
          maxWidth: "160px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      />
    )}
  </Stack>
);

export default memo(Tags, (prev, next) =>
  _.isEqual(prev.notification, next.notification)
);

import { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { EMPTY_LIST } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Alert from "components/Alert";
import Box from "components/Box";
import FixedTable from "components/FixedTable";
import Stack from "components/Stack";
import Text from "components/Text";

import FieldDropdownMenu from "entities/Field/components/FieldDropdownMenu";
import EditButton from "entities/Field/components/FixedTableField/EditButton";
import { getTableVerticalHeaders } from "entities/Field/components/FixedTableField/utils";
import FieldTitle from "entities/Field/components/Title";
import { fieldValueCreate, IField } from "entities/Field/sdk";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

export interface IFixedTableField {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  dealId: number;
  packageId: number;
  categoryId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  isFocused?: boolean;
}

const FixedTableField = ({
  editable,
  dealId,
  taskChannels,
  updatePackage,
  field,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
  isFocused = false,
}: IFixedTableField) => {
  /*
    With the constant EMPTY_LIST we don't create a new array on every rerender and
    avoid the error of type `Maximum update depth exceeded`
   */
  const [values, setValues] = useCaching(
    _.get(field.custom_values, "[0].column_value", EMPTY_LIST)
  ) as Array<any>;
  const [showFieldError, setShowFieldError] = useState<boolean>(false);

  useEffect(() => {
    if (values && !_.isArray(values)) {
      Sentry.captureMessage("Table field value is not array!", (scope) => {
        scope.setTags({
          deal: dealId,
          package: packageId,
          tab: tabId,
          category: categoryId,
          section: sectionId,
          field: field.field_id,
        });
        scope.setExtra("values", values);
        return scope;
      });
      setShowFieldError(true);
    }
  }, [
    setValues,
    dealId,
    packageId,
    tabId,
    categoryId,
    sectionId,
    field,
    values,
  ]);

  const handleSave = useCallback(
    ({ newValue }: { newValue: any }) => {
      if (_.isNil(newValue)) {
        return;
      }
      setValues(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.field_id,
        value: JSON.stringify(newValue),
      })
        .then(() => {
          onUpdate({ message: `${field.display_name} successfully updated!` });
        })
        .catch(handleInvalidRequest);
    },
    [field, dealId, setValues, onUpdate]
  );

  const showEditButton = useMemo(
    () => !field.n_a_status && !showFieldError && editable,
    [field, showFieldError, editable]
  );

  useEffect(() => {
    if (_.isEmpty(values) && field.rows && field.columns) {
      setValues(
        getTableVerticalHeaders({
          firstColumn: field.columns[0],
          rows: field.rows,
        })
      );
    }
  }, [values, field.rows, field.columns, setValues]);

  if (!field.header_name) {
    return null;
  }

  return (
    <>
      <Stack
        id={field.field_id.toString()}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
          backgroundColor: isFocused ? colors.green10 : "initial",
        }}
        data-testid="dd-field"
      >
        <FieldTitle
          field={field}
          dealId={dealId}
          packageId={packageId}
          categoryId={categoryId}
          tabId={tabId}
          sectionId={sectionId}
          onUpdate={onUpdate}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          {taskChannels.length > 0 && (
            <DueDiligenceFieldOpenTaskButton
              taskChannels={taskChannels}
              company_field={field}
              dealId={dealId}
              tabId={tabId}
              sectionId={sectionId}
              categoryId={categoryId}
              packageId={packageId}
              updatePackage={updatePackage}
              taskButtonDataTestid="dd-open-task-button"
              taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
            />
          )}
          {showEditButton && (
            <EditButton
              field={{
                display_name: field.display_name,
                header_name: field.header_name,
              }}
              initialValue={values}
              onSave={handleSave}
              buttonDataTestid="field-edit-button"
            />
          )}
          {field.n_a_status && (
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          )}

          <FieldDropdownMenu
            field={field}
            dealId={dealId}
            packageId={packageId}
            categoryId={categoryId}
            tabId={tabId}
            sectionId={sectionId}
            onUpdate={onUpdate}
            menuButtonDataTestid="dd-field-actions-button"
          />
        </Stack>
      </Stack>
      {!field.n_a_status && (
        <Box
          sx={{ paddingLeft: theme.spacing(6), marginY: theme.spacing(3) }}
          data-testid="dd-table-field-table"
        >
          <FixedTable
            rows={values}
            headers={field.header_name.map(({ headerName, field }) => ({
              label: headerName,
              key: field,
            }))}
            tableDataTestid={field.display_name}
          />
          {showFieldError && (
            <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
              There was an error displaying table values. Our team has been
              notified about this and is working on a fix.
            </Alert>
          )}
        </Box>
      )}
    </>
  );
};

export default FixedTableField;

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import NoPrint from "components/NoPrint";

export interface IEmptyFieldValue {
  fieldName: string;
}

const EmptyFieldValue = ({ fieldName }: IEmptyFieldValue) => (
  <>
    <NoPrint sx={{ display: "inline" }}>
      <span
        style={{
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.25, 1),
          background: colors.gray20,
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
        }}
        data-testid="linked-field-name"
      >
        {fieldName}
      </span>
    </NoPrint>
    <Box
      sx={{
        "@media screen": { display: "none" },
        "@media print": {
          display: "inline-block",
        },
        textAlign: "center",
        borderBottom: `1px solid ${colors.gray40}`,
        padding: theme.spacing(0.25, 2),
        fontStyle: "italic",
        color: colors.gray20,
      }}
    >
      {fieldName}
    </Box>
  </>
);

export default EmptyFieldValue;

import theme from "theme";
import { colors } from "theme/palette";

export const LOCALE_STRING_ZONE = "en-US";
export const BOX_MAX_WIDTH = "1370px";
export const ENTER_CODE = 13;
export const EMPTY_LIST: any[] = [];

export const ENV_NAME = import.meta.env.VITE_ENV_NAME;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN;

export const PDF_MIME_TYPE = "application/pdf";
export const DOCX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const EXCEL_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const MACRO_ENABLED_EXCEL_MIME_TYPE =
  "application/vnd.ms-excel.sheet.macroenabled.12";

export const AI_SUMMARY_ALLOWED_FILE_TYPES = [
  PDF_MIME_TYPE,
  DOCX_MIME_TYPE,
  EXCEL_MIME_TYPE,
];

export enum Environment {
  Local = "local",
  UAT = "uat",
  Automation = "automation",
  Development = "development",
  Production = "production",
}

export const TABLE_CELL_STYLES = {
  padding: theme.spacing(0.5, 1),
};

export const TABLE_HEADER_STYLES = {
  padding: theme.spacing(0.5, 1),
  color: colors.black,
  backgroundColor: colors.blue20,
};

export const SUPPORT_EMAIL = "support@builderspatch.com";

export const IS_USER_FACING_TABLE_IMPORT_ENABLED =
  import.meta.env.VITE_IS_USER_FACING_TABLE_IMPORT_ENABLED === "true";

import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString } from "utils/common";

import Box from "components/Box";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IProforma } from "entities/Proforma/sdk";

export interface IProformaAcquisitionCosts {
  proforma: IProforma;
}

const headerAcquisitionCosts: Array<{ key: string; name: string }> = [
  { key: "costs", name: "costs" },
  { key: "total", name: "total" },
  { key: "perunit", name: "per unit" },
  { key: "persqf", name: "per sqf" },
  { key: "percentgroup", name: "% group" },
  { key: "percenttotal", name: "% total" },
];

const ProformaAcquisitionCosts: React.FC<IProformaAcquisitionCosts> = ({
  proforma,
}) => {
  const acquisitionCostsRows = useMemo(() => {
    const objectToList = _.map(
      proforma.budget_data.acquisition_of_land,
      (value, key) => ({ key, value })
    );

    const filteredRows = objectToList.filter(({ value }) => value.value !== 0);

    const orderedRows = _.orderBy(filteredRows, "value.position") as Array<{
      key: string;
      value: any;
    }>;

    const mappedRows = orderedRows.map(({ key, value }) => ({
      costs: key,
      total: formatNumberToString({ number: value.value }),
      perunit: formatNumberToString({
        number: value.value / proforma.core_data.total_units,
      }),
      persqf: formatNumberToString({
        number: value.value / proforma.core_data.total_s_f,
      }),
      percentgroup: `${formatNumberToString({
        number: value.percentage_of_group,
      })}%`,
      percenttotal: `${formatNumberToString({
        number: value.percentage_of_total,
      })}%`,
    }));

    return mappedRows;
  }, [proforma]);

  const tableTotals = useMemo(
    () => ({
      costs: "Total Acquisition Costs",
      total: formatNumberToString({
        number: proforma.budget_data.total_acquisition_cost.value,
      }),
      perunit: formatNumberToString({
        number:
          proforma.budget_data.total_acquisition_cost.value /
          proforma.core_data.total_units,
      }),
      persqf: formatNumberToString({
        number:
          proforma.budget_data.total_acquisition_cost.value /
          proforma.core_data.total_s_f,
      }),
      percentgroup: `${formatNumberToString({
        number: proforma.budget_data.total_acquisition_cost.percentage_of_group,
      })}%`,
      percenttotal: `${formatNumberToString({
        number: proforma.budget_data.total_acquisition_cost.percentage_of_total,
      })}%`,
    }),
    [proforma.budget_data, proforma.core_data]
  );

  const subTitle = `${formatNumberToString({
    number: proforma.budget_data.total_acquisition_cost.percentage_of_total,
  })}% of TDC`;

  const totals = useMemo(() => {
    const totalHeader = proforma.budget_data.total_acquisition_cost.value;

    return [
      {
        label: "Total",
        value: totalHeader,
        type: ProformaHeaderTypeTotal.PRICE,
      },
      {
        label: "Per Unit",
        value: totalHeader / proforma.core_data.total_units,
        type: ProformaHeaderTypeTotal.PRICE,
      },
      {
        label: "Per Sqf",
        value: totalHeader / proforma.core_data.total_s_f,
        type: ProformaHeaderTypeTotal.PRICE,
      },
    ];
  }, [proforma.core_data, proforma.budget_data]);

  return (
    <ProformaPaper
      title="Acquisition Costs"
      subTitle={subTitle}
      totals={totals}
    >
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          rows={acquisitionCostsRows}
          headers={headerAcquisitionCosts}
          totals={tableTotals}
        />
      </Box>
    </ProformaPaper>
  );
};

export default ProformaAcquisitionCosts;

import { memo, useCallback } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { TABLE_CELL_STYLES } from "utils/constants";

import Box from "components/Box";
import Checkbox from "components/Checkbox";
import Text from "components/Text";

import { ECellType } from "entities/TableStorage/components/Table/Cell/constants";
import { ITable } from "entities/TableStorage/sdk";

import Cell from "./Cell";

export interface ITableProps {
  tableData: ITable["rows"];
  showFilterCheckboxes?: boolean;
  disabledIndexRows?: Array<number>;
  disabledIndexColumns?: Array<number>;
  setDisabledIndexRows?: React.Dispatch<React.SetStateAction<Array<number>>>;
  setDisabledIndexColumns?: React.Dispatch<React.SetStateAction<Array<number>>>;
  updateTableData?: ({
    key,
    currentValue,
  }: {
    key: string;
    currentValue: string | null;
  }) => void;
  editMode?: boolean;
  resetTableData?: boolean;
}

const Table = ({
  tableData,
  updateTableData,
  disabledIndexRows,
  disabledIndexColumns,
  setDisabledIndexRows,
  setDisabledIndexColumns,
  showFilterCheckboxes = false,
  editMode = false,
  resetTableData = false,
}: ITableProps) => {
  const [headers, ...rows] = tableData;

  const changeRows = useCallback(
    ({ index }: { index: number }) => {
      const newDisabledIndexRows = _.xor(disabledIndexRows, [index]);
      setDisabledIndexRows && setDisabledIndexRows(newDisabledIndexRows);
    },
    [disabledIndexRows, setDisabledIndexRows]
  );

  const changeColumns = useCallback(
    ({ index }: { index: number }) => {
      const newDisabledIndexColumns = _.xor(disabledIndexColumns, [index]);
      setDisabledIndexColumns &&
        setDisabledIndexColumns(newDisabledIndexColumns);
    },
    [disabledIndexColumns, setDisabledIndexColumns]
  );

  const handleUpdateCell = useCallback(
    ({ value, cellIndex, rowIndex }) => {
      updateTableData &&
        updateTableData({
          key: `${rowIndex}-${cellIndex}`,
          currentValue: value,
        });
    },
    [updateTableData]
  );

  if (_.isEmpty(tableData)) {
    return <Text variant="h6">No table data yet.</Text>;
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        "table, th, td": {
          border: `1px solid ${colors.blue20}`,
          borderCollapse: "collapse",
        },
        overflowX: "auto",
      }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          {showFilterCheckboxes && (
            <tr>
              <td style={{ border: "none", textAlign: "center" }}></td>
              {headers.cells.map(({ value }, index) => (
                <td style={{ border: "none", textAlign: "center" }}>
                  <Checkbox
                    defaultChecked
                    checked={!_.includes(disabledIndexColumns, index)}
                    onClick={() => changeColumns({ index })}
                  />
                </td>
              ))}
            </tr>
          )}
          <tr>
            {showFilterCheckboxes && <td style={{ border: "none" }}></td>}
            {headers.cells.map(({ value }, index) => (
              <Cell
                cellType={ECellType.HEADER}
                key={index}
                index={index}
                editMode={editMode}
                initialValue={value}
                disabled={_.includes(disabledIndexColumns, index)}
                onChange={({ value, cellIndex }) =>
                  handleUpdateCell({ value, cellIndex, rowIndex: 0 })
                }
                resetCellValue={resetTableData}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 ? (
            <tr>
              <td style={TABLE_CELL_STYLES} colSpan={headers.cells.length}>
                <Text variant="text2" textAlign="center">
                  ...
                </Text>
              </td>
            </tr>
          ) : (
            rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {showFilterCheckboxes && (
                  <td style={{ border: "none", textAlign: "center" }}>
                    <Checkbox
                      defaultChecked
                      checked={!_.includes(disabledIndexRows, rowIndex)}
                      onClick={() => changeRows({ index: rowIndex })}
                    />
                  </td>
                )}
                {row.cells.map(({ value }, cellIndex) => (
                  <Cell
                    cellType={ECellType.CELL}
                    key={cellIndex}
                    index={cellIndex}
                    disabled={
                      _.includes(disabledIndexRows, rowIndex) ||
                      _.includes(disabledIndexColumns, cellIndex)
                    }
                    editMode={editMode}
                    initialValue={value}
                    onChange={({ value }) =>
                      handleUpdateCell({
                        value,
                        cellIndex,
                        rowIndex: rowIndex + 1,
                      })
                    }
                    resetCellValue={resetTableData}
                  />
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </Box>
  );
};

export default memo(Table, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import _ from "lodash";

import { URLS } from "config/urls";
import {
  DeleteOutlineOutlinedIcon,
  EditIconSvg,
  FilterNoneIcon,
  SettingsOutlinedIcon,
} from "icons";
import { colors } from "theme/palette";
import { useRedirectUponFetchFailure } from "utils/common";
import { BOX_MAX_WIDTH } from "utils/constants";

import Box from "components/Box";
import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Tooltip from "components/Tooltip";
import { usePageTitle } from "components/usePageTitle";

import { useSignedUser } from "entities/Auth/sdk";
import DeleteChecklistDialog from "entities/CompanyChecklist/components/DeleteChecklistDialog";
import DuplicateChecklistDialog from "entities/CompanyChecklist/components/DuplicateChecklistDialog";
import NestedChecklistPreview from "entities/CompanyChecklist/components/NestedChecklistPreview";
import RenameChecklistDialog from "entities/CompanyChecklist/components/RenameChecklistDialog";
import UnderConstructionDialog from "entities/CompanyChecklist/components/UnderConstructionDialog";
import { useCompanyChecklistPreview } from "entities/Package/sdk";

const ChecklistPreview = () => {
  const params = useParams<{
    checklistId: string;
  }>();
  const [editNameDialogIsOpen, setEditNameDialogIsOpen] =
    useState<boolean>(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] =
    useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

  const [settingsDialogIsOpen, setSettingsDialogIsOpen] =
    useState<boolean>(false);

  const { data: user } = useSignedUser();

  const packageId = Number(params.checklistId);
  const {
    data: checklist,
    mutate: refreshChecklist,
    error,
    loading,
  } = useCompanyChecklistPreview({
    packageId,
  });

  useRedirectUponFetchFailure({
    error,
    loading,
  });

  const handleEditNameClick = useCallback(
    () => setEditNameDialogIsOpen(true),
    []
  );

  const handleDuplicateClick = useCallback(
    () => setDuplicateDialogIsOpen(true),
    []
  );

  const handleDeleteClick = useCallback(() => setDeleteDialogIsOpen(true), []);

  const handleUnderConstructionClick = useCallback(
    () => setSettingsDialogIsOpen(true),
    []
  );

  const userIsAdmin = useMemo(() => !_.isNil(user) && user.is_admin, [user]);

  const checklistIsFromUsersCompany = useMemo(
    () => checklist?.is_owned,
    [checklist]
  );

  usePageTitle(
    !_.isNil(checklist)
      ? `Builders Patch: ${checklist?.name} - Checklist template preview`
      : "Builders Patch: Checklist template preview"
  );

  const pageHeader = useMemo(() => {
    if (_.isNil(checklist)) {
      return <Skeleton variant="text" width={200} />;
    }
    return checklist.name;
  }, [checklist]);

  return (
    <Stack padding={2} spacing={4}>
      <PageHeader
        backLink={URLS.COMPANY_CHECKLISTS}
        backTitle="Checklist templates"
        title={pageHeader}
        actions={
          userIsAdmin && (
            <Stack spacing={1} direction="row">
              {checklistIsFromUsersCompany && (
                <Tooltip title="Delete template" placement="top">
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 40, width: 40, height: 40 }}
                    onClick={handleDeleteClick}
                  >
                    <DeleteOutlineOutlinedIcon
                      sx={{ width: 20, height: 20, color: colors.gray60 }}
                    />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Duplicate" placement="top">
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ minWidth: 40, width: 40, height: 40 }}
                  onClick={handleDuplicateClick}
                >
                  <FilterNoneIcon
                    sx={{ width: 20, height: 20, color: colors.gray60 }}
                  />
                </Button>
              </Tooltip>
              {checklistIsFromUsersCompany && (
                <>
                  <Tooltip title="Edit name" placement="top">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ minWidth: 40, width: 40, height: 40 }}
                      onClick={handleEditNameClick}
                    >
                      <Stack style={{ color: colors.gray60 }}>
                        <EditIconSvg />
                      </Stack>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit template" placement="top">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ minWidth: 40, width: 40, height: 40 }}
                      onClick={handleUnderConstructionClick}
                    >
                      <SettingsOutlinedIcon
                        sx={{ width: 20, height: 20, color: colors.gray60 }}
                      />
                    </Button>
                  </Tooltip>
                </>
              )}
            </Stack>
          )
        }
      />
      {!_.isNil(checklist) && (
        <Box
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <NestedChecklistPreview checklist={checklist} />
        </Box>
      )}
      <RenameChecklistDialog
        isOpen={editNameDialogIsOpen}
        onClose={() => setEditNameDialogIsOpen(false)}
        refreshChecklist={refreshChecklist}
        packageId={packageId}
        checklistName={checklist?.name || ""}
      />
      <DuplicateChecklistDialog
        isOpen={duplicateDialogIsOpen}
        onClose={() => setDuplicateDialogIsOpen(false)}
        refreshChecklist={refreshChecklist}
        packageId={packageId}
        checklistName={checklist?.name || ""}
      />
      <DeleteChecklistDialog
        isOpen={deleteDialogIsOpen}
        onClose={() => setDeleteDialogIsOpen(false)}
        packageId={packageId}
      />
      <UnderConstructionDialog
        isOpen={settingsDialogIsOpen}
        onClose={() => setSettingsDialogIsOpen(false)}
        onClick={() => setSettingsDialogIsOpen(false)}
        title="Edit template"
        description="We are working on making this feature available to you. In the meantime, just reach out to our Customer Success Team to request modifications to this checklist template."
      />
    </Stack>
  );
};

export default ChecklistPreview;

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";

import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import { usePageTitle } from "components/usePageTitle";

import OverviewCommunityAmenities from "entities/Deal/components/OverviewCommunityAmenities";
import OverviewLocation from "entities/Deal/components/OverviewLocation";
import OverviewProject from "entities/Deal/components/OverviewProject";
import OverviewProjectNarrative from "entities/Deal/components/OverviewProjectNarrative";
import OverviewSchedule from "entities/Deal/components/OverviewSchedule";
import OverviewUnitAmenities from "entities/Deal/components/OverviewUnitAmenities";
import { useDealDetails, useDealPermission } from "entities/Deal/sdk";

export interface IOverview {}

const Overview: React.FC<IOverview> = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });
  const { data: userPermission } = useDealPermission({ dealId });

  const hasEditAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  usePageTitle(
    deal?.name
      ? `Builders Patch: ${deal.name} - Overview`
      : "Builders Patch: Overview"
  );

  if (!deal) {
    return null;
  }

  return (
    <Paper
      sx={{
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack
        spacing={7}
        sx={{
          marginX: "auto",
          maxWidth: "960px",
        }}
        data-testid="deal-overview-content"
      >
        <PageHeader title="Deal overview" />

        <OverviewProject deal={deal} editable={hasEditAccess} />
        {userPermission?.is_full_access && (
          <>
            <OverviewProjectNarrative dealId={dealId} />
            <OverviewLocation deal={deal} />
            <OverviewSchedule dealId={dealId} />
            <OverviewUnitAmenities dealId={dealId} />
            <OverviewCommunityAmenities dealId={dealId} />
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default Overview;

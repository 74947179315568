import React from "react";

import theme from "theme";

import Box from "components/Box";
import Divider from "components/Divider";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

interface IPLProjectionsSkeleton {}

const PLProjectionsSkeleton: React.FC<IPLProjectionsSkeleton> = () => {
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <Paper sx={{ flex: 4 }}>
          <Stack spacing={2}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Divider />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Divider />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Divider />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Stack>
        </Paper>
        <Paper sx={{ flex: 8, padding: theme.spacing(2, 4) }}>
          <Stack spacing={2}>
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={350} />
            <Stack spacing={4}>
              {[1, 2].map((key) => (
                <Stack
                  key={key}
                  spacing={3}
                  direction="row"
                  justifyContent="space-between"
                >
                  {[1, 2, 3].map((key) => (
                    <Box key={key}>
                      <Skeleton variant="text" width="90px" />
                      <Skeleton variant="text" />
                    </Box>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      <Stack spacing={3}>
        {[1, 2, 3].map((key) => (
          <Paper key={key}>
            <Skeleton variant="text" width="100px" />
            <Skeleton variant="rectangular" height={350} />
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
};

export default PLProjectionsSkeleton;

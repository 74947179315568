import React, { useRef } from "react";

import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";

import EditSectionForm from "entities/MemoTemplate/components/EditSectionForm";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

const EditSectionDialog = ({
  section,
  open,
  onClose,
  onSectionEditted,
}: {
  section: IMemoTemplateSection;
  open: boolean;
  onClose: () => void;
  onSectionEditted: () => void;
}) => {
  const formikRef = useRef<any>();

  const handleSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ borderBottom: `1px solid ${colors.blue20}` }}>
        <Text variant="h3">Edit Section</Text>
      </DialogTitle>
      <DialogContent>
        <EditSectionForm
          section={section}
          onCancel={onClose}
          onSectionEditted={onSectionEditted}
          formikRef={formikRef}
        />
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${colors.blue20}` }}>
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button variant="text" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" size="large" onClick={handleSubmit}>
            Update
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default EditSectionDialog;

import React from "react";

import theme from "theme";

import Text from "components/Text";

interface IMemoTemplateSectionHeader {
  title: string;
}

const MemoTemplateSectionHeader: React.FC<IMemoTemplateSectionHeader> = ({
  title,
}) => (
  <Text variant="h2" sx={{ padding: theme.spacing(4, 0) }}>
    {title}
  </Text>
);

export default MemoTemplateSectionHeader;

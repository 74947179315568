import { useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";

export interface ISignaturefield {
  field: IPackageDetailsField;
  isNASection: boolean;
}

const Signaturefield = ({ field, isNASection }: ISignaturefield) => {
  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          paddingBottom: theme.spacing(1),
          borderBottom: `1px solid ${colors.blue40}`,
        }}
      >
        <FieldTitle field={field} onUpdate={() => {}} disabled={isNAField} />
        {isNAField ? (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        ) : (
          <Box
            sx={{
              height: "60px",
              flex: 1,
              border: "1px solid #c5c5ee",
              position: "relative",
            }}
          >
            <Text
              variant="labelSmall"
              sx={{
                position: "absolute",
                right: theme.spacing(0.5),
                bottom: theme.spacing(0.5),
              }}
            >
              Signature
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Signaturefield;

import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import { LinearProgress } from "components/Loading";

export interface ITag {
  label: string;
  variant?:
    | "default"
    | "blue80"
    | "blue20"
    | "blue10"
    | "gray100"
    | "gray80"
    | "gray60"
    | "gray20"
    | "red";
  sx?: SxProps<Theme>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  loading?: boolean;
  dataTestid?: string;
}

const colorByVariant = {
  default: colors.blue120,
  gray100: colors.white,
  gray80: colors.white,
  gray60: colors.white,
  gray20: colors.gray100,
  blue80: colors.white,
  blue20: colors.blue100,
  blue10: colors.gray100,
  red: colors.pink120,
};

const backgroundColorByVariant = {
  default: colors.blue20,
  gray100: colors.gray100,
  gray80: colors.gray80,
  gray60: colors.gray60,
  gray20: colors.gray20,
  blue80: colors.blue80,
  blue20: colors.blue20,
  blue10: colors.blue10,
  red: colors.pink20,
};

const Tag = ({
  variant = "default",
  label,
  sx,
  onClick,
  loading = false,
  dataTestid,
}: ITag) => (
  <Box
    onClick={!loading ? onClick : undefined}
    sx={{
      fontSize: "11px",
      lineHeight: "90%",
      textTransform: "uppercase",
      fontWeight: 700,
      padding: theme.spacing(0.25, 0.5),
      color: colorByVariant[variant],
      backgroundColor: backgroundColorByVariant[variant],
      position: loading ? "relative" : "static",
      ...sx,
    }}
    data-testid={dataTestid}
  >
    {label}
    {loading && (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          backgroundColor: "rgba(0,0,0,0.4)",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <LinearProgress color="success" sx={{ width: "100%" }} />
      </Box>
    )}
  </Box>
);

export default Tag;

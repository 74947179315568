import { memo, useCallback, useState } from "react";

import Box from "components/Box";
import CellWrapper from "components/ScrollableTable/CellWrapper";
import { ScrollableTableCellVariant } from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import { getRowStyles } from "components/ScrollableTable/utils";
import Stack from "components/Stack";

export interface IRow {
  row: {
    [key: string]: any;
  };
  headers: IScrollableTableHeader;
  rowDataTestid?: string;
  rowCellDataTestid?: string;
  childRowCellDataTestid?: string;
}

interface IRowTemplate {
  row: IRow["row"];
  isChild?: boolean;
  headers: IScrollableTableHeader;
  isExpanded?: boolean;
  onExpand?: (state: boolean) => void;
  rowDataTestid?: string;
  rowCellDataTestid?: string;
}

const RowTemplate = ({
  row,
  isChild = false,
  headers,
  isExpanded,
  onExpand = () => {},
  rowDataTestid,
  rowCellDataTestid,
}: IRowTemplate) => {
  const handleChangeExpanded = useCallback(
    () => onExpand(!isExpanded),
    [isExpanded, onExpand]
  );

  return (
    <Box
      sx={{
        display: "flex",
        zIndex: 1,
      }}
      data-testid={rowDataTestid}
    >
      {headers.map((header, index) => (
        <Stack
          key={index}
          direction="row"
          marginRight={
            index === headers.length - 1 ? 0 : header.sticky ? 2.5 : 5
          }
          sx={{
            left: header.sticky ? 0 : "initial",
            position: header.sticky ? "sticky" : "initial",
            zIndex: header.sticky ? 1 : 0,
          }}
        >
          {header.children?.map(
            (
              {
                left,
                width,
                render,
                key,
                variant,
                justifyContent,
                noCellPadding,
              },
              index
            ) => {
              const hasChildren = row.children?.length > 0;

              return (
                <Box
                  key={`${key}-${index}`}
                  sx={{
                    ...getRowStyles({
                      left,
                      width,
                      isChild,
                      hasChildren,
                      justifyContent,
                      sticky: header.sticky,
                      noCellPadding,
                    }),
                    "& .MuiTypography-root": {
                      fontWeight: hasChildren ? 700 : "initial",
                    },
                  }}
                  data-testid={`${rowCellDataTestid}-${key
                    .toLowerCase()
                    .replaceAll(" ", "-")
                    .replaceAll("_", "-")}`}
                  onClick={() => header.sticky && handleChangeExpanded()}
                >
                  {render ? (
                    render({ row, key, isExpanded })
                  ) : (
                    <CellWrapper
                      variant={variant || ScrollableTableCellVariant.TEXT}
                      row={row}
                      cellKey={key}
                    />
                  )}
                </Box>
              );
            }
          )}
        </Stack>
      ))}
    </Box>
  );
};

const Row = ({
  row,
  headers,
  rowDataTestid,
  rowCellDataTestid,
  childRowCellDataTestid,
}: IRow) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <RowTemplate
        row={row}
        headers={headers}
        isExpanded={isExpanded}
        onExpand={setIsExpanded}
        rowDataTestid={rowDataTestid}
        rowCellDataTestid={rowCellDataTestid}
      />
      {isExpanded &&
        row.children?.map((rowChildren: IRow["row"], index: number) => (
          <RowTemplate
            key={`child-${index}`}
            row={rowChildren}
            headers={headers}
            isChild={true}
            rowDataTestid={rowDataTestid}
            rowCellDataTestid={childRowCellDataTestid}
          />
        ))}
    </>
  );
};

export default memo(
  Row,
  (prevProps, nextProps) =>
    prevProps.row === nextProps.row && prevProps.headers === nextProps.headers
);

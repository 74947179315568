import { memo, useCallback } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";

import image from "./assets/default.jpg";

export interface IUnderConstructionDialog {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  title: string;
  description: string;
  actionButtonLabel?: string;
}

const UnderConstructionDialog = ({
  isOpen,
  onClose,
  onClick,
  title,
  description,
  actionButtonLabel = "OK",
}: IUnderConstructionDialog) => {
  const handleActionClick = useCallback(() => {
    onClose();
    onClick();
  }, [onClose, onClick]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <Stack padding={2} spacing={2}>
        <DialogTitle
          sx={{
            marginTop: theme.spacing(2),
            borderRadius: theme.spacing(1),
            backgroundColor: colors.blue10,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Text
              variant="h2"
              marginBottom={theme.spacing(0.5)}
              color={colors.gray100}
            >
              {title}
            </Text>
          </Stack>
        </DialogTitle>
        <Stack direction="row" spacing={5} alignItems="center" maxWidth="640px">
          <img src={image} alt="construction illustration" />
          <Text variant="text2" color={colors.gray80} maxWidth="313px">
            {description}
          </Text>
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          sx={{ padding: theme.spacing(3, 0, 0, 0) }}
          data-testid="form-action-buttons"
        >
          <Button
            variant="text"
            onClick={onClose}
            data-testid="close-button"
            sx={{ marginRight: theme.spacing(1) }}
          >
            Cancel
          </Button>
          <Button onClick={handleActionClick} data-testid="submit-button">
            {actionButtonLabel}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(UnderConstructionDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Link from "components/Link";
import { LinearProgress } from "components/Loading";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { useDealDetails } from "entities/Deal/sdk";
import Category from "entities/Package/components/Category";
import { DueDiligenceShareableType } from "entities/Package/constants";
import { usePackageDealDetails } from "entities/Package/sdk";

interface IDueDiligenceTask {}

const DueDiligenceTask: React.FC<IDueDiligenceTask> = () => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
    categoryId?: string;
    sectionId?: string;
    fieldId?: string;
  }>();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId = Number(params.tabId);
  const queryParams = Object.assign(
    {},
    params.categoryId ? { category_id: params.categoryId } : null,
    params.sectionId ? { section_id: params.sectionId } : null,
    params.fieldId ? { field_id: params.fieldId } : null
  );

  const shareablePageType: DueDiligenceShareableType | undefined =
    useMemo(() => {
      if (_.has(queryParams, "field_id")) {
        return DueDiligenceShareableType.FIELD;
      } else if (_.has(queryParams, "section_id")) {
        return DueDiligenceShareableType.SECTION;
      } else if (_.has(queryParams, "category_id")) {
        return DueDiligenceShareableType.CATEGORY;
      }

      return undefined;
    }, [queryParams]);

  const { data: deal } = useDealDetails({ dealId });
  const {
    data: checklist,
    loading,
    isValidating,
  } = usePackageDealDetails({
    packageId,
    dealId,
    queryParams,
  });

  const selectedTab = useMemo(
    () =>
      tabId
        ? _.find(checklist?.tabs, ["tab_id", tabId])
        : _.first(checklist?.tabs),
    [checklist, tabId]
  );

  usePageTitle(`Builders Patch: ${deal?.name} Task Details`);

  return (
    <Paper
      square={true}
      sx={{
        padding: theme.spacing(8, 4),
        backgroundColor: colors.blue5,
        flex: 1,
      }}
    >
      {!loading && isValidating && <LinearProgress />}

      <Stack
        sx={{
          marginX: "auto",
          maxWidth: "960px",
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="h2">Details</Text>
          <Link
            to={reverse(URLS.DEAL_PACKAGE, {
              dealId,
              packageId,
              tabId,
            })}
          >
            <Button size="small" color="primary">
              Back to Checklist
            </Button>
          </Link>
        </Stack>
        <Paper>
          {selectedTab?.categories.map((category) => (
            <Category
              key={category.category_id}
              category={category}
              isExpanded={true}
              shareablePageType={shareablePageType}
            />
          ))}
        </Paper>
      </Stack>
    </Paper>
  );
};

export default DueDiligenceTask;

import { useCallback, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import ChecklistSelectionStep from "entities/Package/components/ShareDialog/components/ChecklistSelectionStep";
import { ACCESS_TYPE_OPTIONS } from "entities/Package/constants";
import {
  EPartialUserAccessType,
  IShareTokenList,
  shareTokenUpdate,
} from "entities/Package/sdk";

export interface IShareTokenUpdateDialog<T> {
  handleClose: () => void;
  onUpdate: () => void;
  shareToken: IShareTokenList;
  checklistName: string;
  items: Array<T>;
  initiallySelectedItemIds?: Array<number>;
}

const ShareTokenUpdateDialog = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  shareToken,
  handleClose,
  onUpdate,
  items,
  checklistName,
  initiallySelectedItemIds = [],
}: IShareTokenUpdateDialog<T>) => {
  const [selectedItemIds, setSelectedItemIds] = useState<Array<number>>(
    initiallySelectedItemIds
  );
  const [openItemIds, setOpenItemIds] = useState<Array<number>>(
    items.map((item) => item.id)
  );

  const [selectedAccessType, setSelectedAccessType] =
    useState<EPartialUserAccessType>(shareToken.access_type);

  const handleAccessTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSelectedAccessType(event.target.value as EPartialUserAccessType),
    []
  );

  const handleSubmit = useCallback(() => {
    shareTokenUpdate({
      shareTokenId: shareToken.id,
      data: {
        field_ids: selectedItemIds,
        access_type: selectedAccessType,
      },
    })
      .then(() => {
        toast.successMessage("User access updated successfully!");
        handleClose();
        onUpdate();
      })
      .catch((error) => {
        toast.errorMessage(error.message || DEFAULT_ERROR_MESSAGE);
      });
  }, [selectedItemIds, selectedAccessType, handleClose, onUpdate, shareToken]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      data-testid="modify-invited-user-access-dialog"
    >
      <DialogTitle
        sx={{
          marginTop: theme.spacing(2),
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(3),
          marginLeft: theme.spacing(2),
          borderRadius: theme.spacing(1),
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Text
          variant="h2"
          marginBottom={theme.spacing(0.5)}
          color={colors.gray100}
          data-testid="dialog-header"
        >
          Individual share settings
        </Text>
        <Text
          variant="text2"
          color={colors.gray80}
          data-testid="dialog-subheader"
        >
          Review, modify and revoke an individual user's access rights to items
          in this checklist.
        </Text>
      </DialogTitle>
      <Stack
        paddingRight={2}
        paddingLeft={2}
        paddingBottom={2}
        spacing={2}
        data-testid="dialog-content-section"
      >
        <Stack direction="row" spacing={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack data-testid="invited-user-details-section">
              <Text
                variant="label"
                color={colors.gray60}
                fontWeight="bold"
                data-testid="label"
              >
                EMAIL
              </Text>
              <Text
                variant="text1"
                fontWeight="bold"
                data-testid="invited-user-email"
              >
                {shareToken.email}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="column" data-testid="invited-user-access-section">
            <Text
              variant="label"
              color={colors.gray60}
              fontWeight="bold"
              data-testid="label"
            >
              ACCESS TYPE
            </Text>
            <Select
              sx={{ width: "192px" }}
              options={ACCESS_TYPE_OPTIONS}
              value={selectedAccessType}
              onChange={handleAccessTypeChange}
              data-testid="access-type-dropdown"
              inputProps={{ "data-testid": "access-type-input" }}
              dropdownOptionsDataTestid="access-type-option"
            />
          </Stack>
        </Stack>

        <ChecklistSelectionStep
          items={items}
          openItemIds={openItemIds}
          setOpenItemIds={setOpenItemIds}
          selectedItemIds={selectedItemIds}
          setSelectedItemIds={setSelectedItemIds}
          checklistName={checklistName}
        />
      </Stack>

      <DialogActions
        sx={{ padding: theme.spacing(3, 2, 2, 2) }}
        data-testid="form-action-button"
      >
        <Button
          variant="text"
          onClick={handleClose}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          disabled={_.isEmpty(selectedItemIds)}
          onClick={handleSubmit}
          data-testid="submit-button"
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareTokenUpdateDialog;

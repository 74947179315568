import { memo, useMemo } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";

import { IField } from "entities/Field/sdk";
import { IFieldValueHistoryItem } from "entities/Package/sdk";

export interface IDropdownFieldHistoryElement {
  value: IFieldValueHistoryItem;
  field: IField;
}

const DropdownFieldHistoryElement = ({
  value,
  field,
}: IDropdownFieldHistoryElement) => {
  const timestamp = useMemo(
    () => format(parseJSON(value.uploaded_at), FORMATS.FRONTEND.DATETIME),
    [value]
  );

  const valueIsPresent = useMemo(() => !_.isNil(value.selected_value), [value]);
  const creatorText = useMemo(() => {
    if (!_.isNil(value.creator)) {
      return `${value.creator.first_name} ${value.creator.last_name}`;
    }
    return "Past proforma upload";
  }, [value]);

  return (
    <Stack data-testid="field-history-entity">
      <Text
        variant="text2"
        sx={{ color: colors.gray60, width: 600 }}
        data-testid="entity-title"
      >
        <b>{creatorText}</b> {valueIsPresent ? "updated" : "deleted"} value at{" "}
        {timestamp}
      </Text>
      {valueIsPresent ? (
        <Select
          options={field.options}
          size="small"
          value={value.selected_value}
          data-testid="entity-value"
        />
      ) : (
        <Text variant="h6" sx={{ width: 600 }} data-testid="entity-value">
          (deleted)
        </Text>
      )}
    </Stack>
  );
};

export default memo(DropdownFieldHistoryElement);

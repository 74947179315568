export const colors = {
  white: "#fff",
  black: "#000",

  gray120: "#000",
  gray100: "#222",
  gray80: "#555",
  gray60: "#888",
  gray40: "#aaa",
  gray20: "#ddd",
  gray10: "#f7f7f7",
  gray5: "#fafafa",

  blue120: "#00b",
  blue100: "#44f",
  blue80: "#9797e0",
  blue60: "#c5c5ee",
  blue40: "#d6d6f0",
  blue20: "#e4e4f0",
  blue10: "#f3f3f7",
  blue5: "#f9f9fa",

  teal120: "#00444E",
  teal100: "#078",
  teal80: "#2790A0",
  teal60: "#4FA1AD",
  teal40: "#7CBDC7",
  teal20: "#B4D9DF",
  teal10: "#DBF0F3",
  teal5: "#EEF8FA",

  green120: "#390",
  green100: "#48C20A",
  green80: "#8EEC5F",
  green60: "#BEFD9E",
  green40: "#D8FDBD",
  green20: "#ECFFDE",
  green10: "#F5FFEE",
  green5: "#FAFFF6",

  yellow120: "#e7b500",
  yellow100: "#ffd000",
  yellow80: "#ffdf50",
  yellow60: "#FFE77B",
  yellow40: "#fdefaf",
  yellow20: "#FDF4CC",
  yellow10: "#FFFAE6",
  yellow5: "#FFFDF5",

  red120: "#D20",
  red100: "#FF4A22",
  red80: "#F64",
  red60: "#F86",
  red40: "#FBA",
  red20: "#FFE4DD",
  red10: "#FAF0EE",
  red5: "#FAF5F3",

  pink120: "#917",
  pink100: "#c71699",
  pink80: "#df59bc",
  pink60: "#f890dd",
  pink40: "#fcc4ed",
  pink20: "#fddbf5",
  pink10: "#fff0fb",
  pink5: "#faf3f8",

  violet120: "#400066",
  violet100: "#619",
  violet80: "#8035AD",
  violet60: "#9358B6",
  violet40: "#A8C",
  violet20: "#C3ADDA",
  violet10: "#E0D3EC",
  violet5: "#F8F1FF",
};

import { ArrowBackIosIcon, ArrowForwardIosIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import ButtonGroup from "components/ButtonGroup";
import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";

export interface IScrollableButtons {
  onScroll: (scrollOffset: number) => void;
}

const ScrollableButtons = ({ onScroll }: IScrollableButtons) => {
  return (
    <Box
      sx={{
        alignSelf: "flex-end",
        position: "sticky",
        bottom: theme.spacing(2),
      }}
    >
      <Tooltip
        disableInteractive
        placement="top"
        title="Scroll inside this table!"
      >
        <ButtonGroup
          variant="contained"
          size="small"
          sx={{
            borderRadius: theme.spacing(5),
            backgroundColor: colors.blue20,
            paddingX: theme.spacing(1),
          }}
          data-testid="scroll-table-buttons"
        >
          <IconButton
            sx={{
              opacity: 1,
              borderTopLeftRadius: theme.spacing(5),
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: theme.spacing(5),
            }}
            onClick={() => onScroll(-100)}
            size="small"
            dataTestid="scroll-back-button"
          >
            <ArrowBackIosIcon sx={{ width: "auto", height: "12px" }} />
          </IconButton>
          <IconButton
            sx={{
              opacity: 1,
              borderTopLeftRadius: 0,
              borderTopRightRadius: theme.spacing(5),
              borderBottomRightRadius: theme.spacing(5),
              borderBottomLeftRadius: 0,
              textAlign: "center",
            }}
            onClick={() => onScroll(100)}
            size="small"
            dataTestid="scroll-forward-button"
          >
            <ArrowForwardIosIcon sx={{ width: "auto", height: "12px" }} />
          </IconButton>
        </ButtonGroup>
      </Tooltip>
    </Box>
  );
};

export default ScrollableButtons;

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { areInstructionsEmpty } from "utils/common";
import { scrollToSelectedAnchor, useCaching } from "utils/common";

import Box from "components/Box";
import Collapse from "components/Collapse";
import { ID as SUB_NAVIGATION_ID } from "components/DealPageLayout/SubNavigation";
import IconButton from "components/IconButton";
import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { useDealChecklistPermission } from "entities/Deal/sdk";
import ApproveCheckbox from "entities/Package/components/ApproveCheckbox";
import MenuButton from "entities/Package/components/Category/MenuButton";
import Section from "entities/Package/components/Section";
import { DueDiligenceShareableType } from "entities/Package/constants";
import {
  IPackageCategory,
  toggleCategoryApproval,
  usePackageDealDetails,
} from "entities/Package/sdk";

export interface ICategory {
  shareablePageType?: DueDiligenceShareableType;
  category: IPackageCategory;
  isExpanded: boolean;
  dataTestid?: string;
  isFocused?: boolean;
}

const ANCHORS = [MAIN_NAVIGATION_ID, SUB_NAVIGATION_ID];

const Category: React.FC<ICategory> = ({
  shareablePageType,
  category,
  isExpanded,
  dataTestid,
  isFocused = false,
}) => {
  const [updateCheckboxLoading, setUpdateCheckboxLoading] = useState(false);
  const [open, setOpen] = useCaching(isExpanded);

  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId?: string;
    categoryId?: string;
    sectionId?: string;
    fieldId?: string;
  }>();
  const [searchParams] = useSearchParams();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const queryParams = Object.assign(
    {},
    params.categoryId ? { category_id: params.categoryId } : null,
    params.sectionId ? { section_id: params.sectionId } : null,
    params.fieldId ? { field_id: params.fieldId } : null,
    params.tabId ? { tab_id: params.tabId } : null
  );

  const selectedSectionId = useMemo(() => {
    const sectionId = searchParams.get("sectionId");
    return !_.isNil(sectionId) ? Number(sectionId) : null;
  }, [searchParams]);

  const selectedFieldId = useMemo(() => {
    const fieldId = searchParams.get("fieldId");
    return !_.isNil(fieldId) ? Number(fieldId) : null;
  }, [searchParams]);

  useLayoutEffect(() => {
    // If we have the same sections in different categories, we will scroll to the first.
    if (
      !_.isNil(selectedSectionId) &&
      category.sections
        .map((section) => section.section_id)
        .includes(selectedSectionId)
    ) {
      requestAnimationFrame(() => {
        scrollToSelectedAnchor({
          anchor: selectedSectionId.toString(),
          navigationIds: ANCHORS,
        });
      });
    }
  }, [selectedSectionId, category.sections]);

  useEffect(() => {
    if (!_.isNil(selectedSectionId) && !open) {
      const sectionIsInCategory = category.sections.some(
        (section) => section.section_id === selectedSectionId
      );

      setOpen(sectionIsInCategory);
    }
  }, [selectedSectionId, category, open, setOpen]);

  useEffect(() => {
    if (!_.isNil(selectedFieldId) && !open) {
      const fieldIsInCategory = category.sections.some((section) =>
        section.fields.some((field) => field.field_id === selectedFieldId)
      );

      setOpen(fieldIsInCategory);
    }
  }, [selectedFieldId, category, open, setOpen]);

  const { mutate: mutatePackage } = usePackageDealDetails({
    packageId,
    dealId,
    queryParams,
  });

  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  const hasFullAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  const hasEditAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.has_write_access,
    [userPermission]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdateCheckboxLoading(true);
      toggleCategoryApproval({
        dealId,
        categoryId: category.category_id,
        isCompleted: event.target.checked,
      })
        .then(({ is_approved }) => {
          mutatePackage().then(() => {
            toast.successMessage(
              `${category.name} was successfully ${
                is_approved ? "completed" : "uncompleted"
              }!`
            );
          });
        })
        .catch(() => {
          toast.errorMessage(DEFAULT_ERROR_MESSAGE);
        })
        .finally(() => {
          setUpdateCheckboxLoading(false);
        });
    },
    [dealId, category, mutatePackage]
  );

  // This should be done by BE once we use the new API
  const areAllApproved = useMemo(
    () =>
      category?.is_approved &&
      _.every(
        _.map(category.sections, (section) => {
          return (
            section.is_approved &&
            _.every(
              section?.fields?.filter(
                (field: any) => field.n_a_status === false
              ),
              { is_completed: true }
            )
          );
        })
      ),
    [category]
  );

  const areAllNA = useMemo(
    () =>
      _.every(
        _.map(category?.sections, (section) => {
          return _.every(section?.fields, { n_a_status: true });
        })
      ),
    [category]
  );

  const checkboxLoadingStyle = useMemo(() => {
    if (updateCheckboxLoading)
      return {
        animation: "rotating 0.4s linear infinite",
        "@keyframes rotating": {
          from: {
            transform: "rotate(0deg)",
          },
          to: {
            transform: "rotate(360deg)",
          },
        },
      };
    return {};
  }, [updateCheckboxLoading]);

  const handleUpdatePackage = useCallback(
    (message?: string) => {
      mutatePackage().then(() => {
        if (message) {
          toast.successMessage(message);
        }
      });
    },
    [mutatePackage]
  );

  const shouldSeeApproveCheckbox = useMemo(
    () =>
      (!shareablePageType ||
        (shareablePageType &&
          shareablePageType !== DueDiligenceShareableType.FIELD)) &&
      hasEditAccess,
    [shareablePageType, hasEditAccess]
  );

  const showInstructions = useMemo(
    () => !areInstructionsEmpty({ instructions: category?.instruction }),
    [category?.instruction]
  );

  return (
    <Box id={category.category_id.toString()}>
      <Box
        sx={{
          padding: theme.spacing(0.5, 1),
          backgroundColor: isFocused ? colors.green10 : colors.blue20,
          marginBottom: theme.spacing(1),
          borderRadius: "4px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          data-testid={dataTestid}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton
              onClick={() => setOpen(!open)}
              size="small"
              dataTestid="dd-category-expand-button"
            >
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            {category && shouldSeeApproveCheckbox && (
              <ApproveCheckbox
                areAllNA={areAllNA}
                disabled={updateCheckboxLoading}
                checkboxLoadingStyle={checkboxLoadingStyle}
                onChange={handleCheckboxClick}
                checked={category.is_approved}
                itemDataTestid="category"
              />
            )}
            <Text
              variant="text2"
              fontWeight="500"
              sx={{
                opacity: areAllNA ? 0.5 : 1,
              }}
              data-testid="dd-category-name"
            >
              {category.name}
            </Text>
          </Stack>
          {category && (
            <MenuButton
              category={category}
              packageId={packageId}
              dealId={dealId}
              isShareablePage={
                !_.isUndefined(shareablePageType) &&
                shareablePageType !== DueDiligenceShareableType.CATEGORY
              }
              areAllApproved={areAllApproved}
              areAllNA={areAllNA}
              updatePackage={handleUpdatePackage}
              menuButtonDataTestid="dd-category-actions-button"
              hasFullAccess={hasFullAccess}
            />
          )}
        </Stack>
        {showInstructions && (
          <Text
            variant="text4"
            sx={{ marginLeft: theme.spacing(4), color: colors.gray80 }}
          >
            <SanitizeHtml html={category?.instruction || ""} />
          </Text>
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack
          sx={{
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(1),
          }}
          spacing={1}
        >
          {category.sections.map((section: any) => (
            <Section
              key={section.section_id}
              isFocused={selectedSectionId === section.section_id}
              section={section}
              categoryId={category.category_id}
              isExpanded={isExpanded}
              shareablePageType={shareablePageType}
              updatePackage={handleUpdatePackage}
              dataTestid="dd-section"
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default Category;

import { useCallback, useEffect } from "react";
import _ from "lodash";

import { AddIcon, LockIcon, MessageOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import PusherManager from "utils/pusher-manager";

import Badge from "components/Badge";
import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import { TASKS_TAB_SUBTABS } from "entities/Deal/components/Sidepanel/utils";
import { useDealPermission } from "entities/Deal/sdk";
import TaskListSkeleton from "entities/Task/components/TaskListSkeleton";
import { ITaskChannel, useTaskChannels } from "entities/Task/sdk";

interface ITaskList {
  dealId: number;
  taskChannels?: Array<ITaskChannel>;
}

const TaskList = ({ dealId, taskChannels }: ITaskList) => {
  const { meta, setMeta } = useDealSidepanel();
  const { data: user } = useSignedUser();

  const { mutate: updateTaskChannels, loading } = useTaskChannels({ dealId });
  const { data: userPermission } = useDealPermission({ dealId });

  const openChannelDetail = useCallback(
    (taskChannel: ITaskChannel) => {
      if (!_.isNil(meta)) {
        setMeta({
          ...meta,
          subTab: TASKS_TAB_SUBTABS.DETAILS,
          taskChannel: taskChannel,
        });
      }
    },
    [meta, setMeta]
  );

  const openNewTaskChannel = useCallback(() => {
    if (!_.isNil(meta)) {
      setMeta({
        ...meta,
        subTab: TASKS_TAB_SUBTABS.ADD_TASK,
        taskChannel: undefined,
      });
    }
  }, [meta, setMeta]);

  const eventHandler = useCallback(
    (receivedMsg: any) => {
      if (receivedMsg.deal_id === dealId) {
        updateTaskChannels();
      }
    },
    [updateTaskChannels, dealId]
  );

  useEffect(() => {
    if (user) {
      return PusherManager.bindToTaskChat({ userId: user.id, eventHandler });
    }
  }, [eventHandler, user]);

  const canCreateTask = userPermission?.is_full_access;

  return (
    <>
      <Stack alignItems="flex-end" sx={{ marginBottom: theme.spacing(4) }}>
        {canCreateTask && (
          <Button startIcon={<AddIcon />} onClick={openNewTaskChannel}>
            Add New Task
          </Button>
        )}
      </Stack>

      {_.isEmpty(taskChannels) && (
        <Stack spacing={3} margin={5}>
          <Text variant="text2" fontWeight={700}>
            No tasks found...
          </Text>
          <Text variant="text2">We couldn't find any tasks for this deal.</Text>
        </Stack>
      )}

      {taskChannels && !_.isEmpty(taskChannels) && (
        <>
          {loading && <TaskListSkeleton />}
          {!loading && (
            <>
              <Text
                variant="text3"
                sx={{ color: colors.gray60, marginBottom: theme.spacing(2) }}
              >
                {taskChannels.length} tasks listed
              </Text>

              {taskChannels.map((taskChannel) => (
                <Stack
                  key={taskChannel.id}
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    cursor: "pointer",
                    padding: theme.spacing(1, 2),
                    borderRadius: theme.spacing(0.5),
                    "&:hover": {
                      backgroundColor: colors.blue5,
                    },
                  }}
                  onClick={() => openChannelDetail(taskChannel)}
                >
                  <Stack direction="row" spacing={1}>
                    {!["", null].includes(taskChannel.task.visible_to_team) && (
                      <LockIcon />
                    )}
                    <Text>{taskChannel.task.title}</Text>
                  </Stack>
                  {taskChannel.message_count !== 0 && (
                    <Badge
                      badgeContent={taskChannel.unread_count}
                      color="primary"
                    >
                      <MessageOutlinedIcon
                        height="20px"
                        sx={{ color: colors.gray60 }}
                      />
                    </Badge>
                  )}
                </Stack>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TaskList;

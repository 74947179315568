import { useCallback, useMemo, useRef, useState } from "react";
import _ from "lodash";

import { AddIcon, DeleteOutlineOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";
import { format, FORMATS, parseISO } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import IconButton from "components/IconButton";
import RichTextEditor from "components/RichTextEditor";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import CopyToClipboardButton from "entities/AISummaries/components/CopyToClipboardButton";
import {
  IOpenAICustomPromptResponse,
  openAICustomPromptResponseCheckHasMemoSections,
  openAICustomPromptResponseDelete,
  openAIPromptCustomResponseCreateOrUpdate,
} from "entities/AISummaries/sdk";

export interface ICustomResponse {
  initialCustomResponse: IOpenAICustomPromptResponse | undefined;
  openAIDocumentAssistantId: number;
  promptId: number;
  onUpdated: () => void;
}

const CustomResponse = ({
  initialCustomResponse,
  openAIDocumentAssistantId,
  promptId,
  onUpdated,
}: ICustomResponse) => {
  const inputRef = useRef<HTMLInputElement>();

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const [isEditable, setIsEditable] = useState(
    !_.isUndefined(initialCustomResponse)
  );
  const [customResponse, setCustomResponse] = useState(
    initialCustomResponse?.response || ""
  );

  const handleSubmitCustomResponse = useCallback(() => {
    openAIPromptCustomResponseCreateOrUpdate({
      openAIDocumentAssistantId,
      promptId,
      response: customResponse,
    })
      .then(() => {
        toast.successMessage("Your custom answer has been saved.");
        onUpdated();
      })
      .catch(handleInvalidRequest);
  }, [openAIDocumentAssistantId, promptId, customResponse, onUpdated]);

  const onDelete = useCallback(() => {
    if (!_.isNil(initialCustomResponse)) {
      openAICustomPromptResponseDelete({
        openAIDocumentAssistantId,
        customPromptResponseId: initialCustomResponse.id,
      })
        .then(() => {
          if (!_.isUndefined(inputRef.current)) {
            inputRef.current.value = "";
          }

          setIsEditable(false);
          setCustomResponse("");
          onUpdated();
          toast.successMessage(
            "The custom answer has been deleted successfully."
          );
        })
        .catch(handleInvalidRequest);
    }
  }, [openAIDocumentAssistantId, initialCustomResponse, onUpdated]);

  const checkIsDeletable = useCallback(() => {
    if (!_.isNil(initialCustomResponse)) {
      openAICustomPromptResponseCheckHasMemoSections({
        openAIDocumentAssistantId,
        customPromptResponseId: initialCustomResponse.id,
      })
        .then(({ has_memo_sections: hasMemoSections }) => {
          if (hasMemoSections) {
            showConfirmationDialog({
              onConfirm: onDelete,
              confirmButtonText: "Delete",
              message:
                "This custom answer is being used in memos. Are you sure you want to delete the custom answer?",
            });
          } else {
            onDelete();
          }
        })
        .catch(handleInvalidRequest);
    }
  }, [
    openAIDocumentAssistantId,
    initialCustomResponse,
    onDelete,
    showConfirmationDialog,
  ]);

  const handleDelete = useCallback(() => {
    if (!_.isNil(initialCustomResponse)) {
      showConfirmationDialog({
        onConfirm: checkIsDeletable,
        confirmButtonText: "Delete",
        message: "Are you sure you want to delete the custom answer?",
      });
    }
  }, [initialCustomResponse, checkIsDeletable, showConfirmationDialog]);

  const responseElementId = useMemo(
    () => `custom-response-div-${initialCustomResponse?.id}`,
    [initialCustomResponse]
  );

  if (!isEditable) {
    return (
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={() => setIsEditable(true)}
        style={{ alignSelf: "flex-start", marginBottom: theme.spacing(2) }}
      >
        Create my own answer
      </Button>
    );
  }

  return (
    <Stack spacing={1} paddingTop={3} paddingBottom={2}>
      <Text variant="label" color={colors.gray60} fontWeight={600}>
        My edited answer
      </Text>
      <div id={responseElementId}>
        <RichTextEditor
          placeholder="Edit your final answers in this section. Memo that was set up to include the answers to this prompt from this document will automatically pull in your answers written and finalized here."
          defaultValue={customResponse}
          onChange={setCustomResponse}
        />
      </div>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          {isNotUndefined(initialCustomResponse) && (
            <Text variant="text2" sx={{ color: colors.gray60 }}>
              Last edited by {initialCustomResponse.updated_by.first_name}{" "}
              {initialCustomResponse.updated_by.last_name} on{" "}
              {format(
                parseISO(initialCustomResponse.updated_at),
                FORMATS.FRONTEND.DATETIME
              )}
            </Text>
          )}
        </div>
        <Stack spacing={1} direction="row">
          {isNotUndefined(initialCustomResponse) && (
            <>
              <CopyToClipboardButton containerId={responseElementId} />
              <Tooltip title="Delete answer">
                <IconButton onClick={handleDelete}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Button color="secondary" onClick={handleSubmitCustomResponse}>
            Save my answer
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default CustomResponse;

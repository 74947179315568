import { useCallback, useMemo, useState } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import ShareTokenUpdateDialog from "entities/Package/components/ShareTokenUpdateDialog";
import { IShareTokenList } from "entities/Package/sdk";

interface IAccessUpdateButton<T> {
  checklistItems: Array<T>;
  shareToken: IShareTokenList;
  checklistName: string;
  onUpdate: () => void;
}

const ShareTokenUpdateButton = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  shareToken,
  checklistItems,
  checklistName,
  onUpdate,
}: IAccessUpdateButton<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const initiallySelectedItemIds = useMemo(
    () => shareToken.fields.map((field) => field.id),
    [shareToken.fields]
  );

  return (
    <>
      <MenuItem onClick={open} data-testid="action-option">
        <Text variant="text2">Modify access</Text>
      </MenuItem>

      {isOpen && (
        <ShareTokenUpdateDialog
          items={checklistItems}
          handleClose={close}
          onUpdate={onUpdate}
          checklistName={checklistName}
          initiallySelectedItemIds={initiallySelectedItemIds}
          shareToken={shareToken}
        />
      )}
    </>
  );
};

export default ShareTokenUpdateButton;

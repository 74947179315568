import React, { useCallback } from "react";

import { useSignedUser } from "entities/Auth/sdk";
import { useCurrentCompany } from "entities/Company/sdk";
import UserForm from "entities/User/components/Form";
import { IUser, updateSignedUser, uploadUserAvatar } from "entities/User/sdk";

export interface IEditSignedUserForm {
  handleCancel?: () => void;
  onUpdated?: () => void;
}

const EditSignedUserForm: React.FC<IEditSignedUserForm> = ({
  handleCancel,
  onUpdated,
}) => {
  const { data: user, mutate: mutateUser } = useSignedUser();
  const { data: company, mutate: mutateCompany } = useCurrentCompany(
    user?.company_id
  );

  const handleSubmit = useCallback(
    async (data: Partial<Omit<IUser, "avatar"> & { avatar: File }>) => {
      if (data.avatar && user) {
        // The upload avatar API is currently separate
        const updatedAvatar = await uploadUserAvatar(user.id, data.avatar);

        if (company) {
          const employees =
            company.employees.map((employee) => {
              if (employee.id === user.id) {
                return { ...employee, updatedAvatar };
              }
              return employee;
            }) || [];

          mutateCompany({ ...company, employees });
        }
      }

      return updateSignedUser(data).then((updatedUser) => {
        if (updatedUser) {
          mutateUser(updatedUser);
          if (company) {
            mutateCompany();
          }

          onUpdated && onUpdated();
        }
      });
    },
    [user, mutateUser, company, mutateCompany, onUpdated]
  );

  return (
    <>
      {user && (
        <UserForm
          submitLabel="Update Profile"
          onSubmit={handleSubmit}
          handleCancel={handleCancel}
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            job_title: user.job_title,
            contact: user.contact,
          }}
        />
      )}
    </>
  );
};

export default EditSignedUserForm;

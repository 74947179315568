import React, { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import IconButton from "components/IconButton";
import Image from "components/Image";
import { IScrollableTable } from "components/ScrollableTable";
import {
  CELL_DEFAULT_WIDTH,
  CellAlignType,
} from "components/ScrollableTable/constants";
import {
  getSortIcon,
  isColumnBeingSorted,
  toNumber,
} from "components/ScrollableTable/TableHeaders/utils";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface ISubHeaderCell {
  subHeader: IScrollableTable["headers"][number]["children"][number];
  currentSort: string | undefined;
  onSort?: (key: string | undefined) => void;
}
const SubHeaderCell = ({ subHeader, currentSort, onSort }: ISubHeaderCell) => {
  const isSortableAndAlignRight = useMemo(
    () =>
      subHeader.sortable && subHeader.justifyContent === CellAlignType.FLEX_END,
    [subHeader]
  );

  const handleSort = useCallback(
    (header: IScrollableTable["headers"][number]["children"][number]) => {
      if (!onSort) return;

      if (currentSort === header.key) {
        onSort(`-${header.key}`);
        return;
      }

      if (currentSort === `-${header.key}`) {
        onSort(undefined);
        return;
      }
      onSort(header.key);
    },
    [currentSort, onSort]
  );

  const opacity = useMemo(
    () => (isColumnBeingSorted({ subHeader, currentSort }) ? 1 : 0),
    [subHeader, currentSort]
  );

  const justifyContent = useMemo(
    () =>
      isSortableAndAlignRight || _.isUndefined(subHeader.justifyContent)
        ? CellAlignType.SPACE_BETWEEN
        : subHeader.justifyContent,
    [isSortableAndAlignRight, subHeader.justifyContent]
  );

  const tooltipLabel = useMemo(() => subHeader.info, [subHeader.info]);

  return (
    <Box
      key={subHeader.label}
      sx={{
        flexShrink: 0,
        flexBasis: subHeader.width,
        width: subHeader.width || toNumber(CELL_DEFAULT_WIDTH),
        padding: theme.spacing(0.5, 1.5),
        boxSizing: "border-box",
        borderBottom: `1px solid ${colors.gray100}`,
        display: "flex",
        alignItems: "center",
        flexDirection: isSortableAndAlignRight ? "row-reverse" : "row",
        justifyContent,
      }}
    >
      <Tooltip title={tooltipLabel} disableInteractive placement="top-start">
        <Text
          variant="text4"
          sx={{
            fontWeight: 700,
            wordBreak: "break-word",
          }}
          data-testid="table-section-field-header"
        >
          {subHeader.label}
        </Text>
      </Tooltip>
      {subHeader.sortable && (
        <IconButton
          size="small"
          style={{
            flexGrow: 0 /* This needs to be inline in order to override table styles */,
          }}
          sx={{
            opacity,
            transition: "0.5s opacity ease",
          }}
          className="showOnHover"
          onClick={() => handleSort(subHeader)}
          dataTestid="sort-field-column-button"
        >
          <Image
            src={getSortIcon({ subHeader, currentSort })}
            sx={{ width: 16, height: 16 }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default React.memo(SubHeaderCell);

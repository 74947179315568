import { useCallback, useState } from "react";

import { AddIcon } from "icons";

import Button from "components/Button";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import AddDealDialog from "entities/Deal/components/AddDealDialog";

export interface IAddDealButton {
  onCreated?: () => void;
}

const AddDealButton: React.FC<IAddDealButton> = ({ onCreated }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCheckFeature({
    feature: ECompanySettingsFeatureType.MAX_DEAL_COUNT,
    callback: () => setOpen(true),
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnCreated = useCallback(() => {
    onCreated && onCreated();
    handleClose();
  }, [handleClose, onCreated]);

  return (
    <>
      <Button
        onClick={handleOpen}
        data-testid="create-deal-button"
        startIcon={<AddIcon />}
      >
        New deal
      </Button>
      <AddDealDialog
        open={open}
        handleClose={handleClose}
        onCreated={handleOnCreated}
      />
    </>
  );
};

export default AddDealButton;

import { FieldType } from "entities/Field/sdk";

export const RENDERED_FIELD_TYPES = [
  FieldType.TEXT_FIELD,
  FieldType.FORM_TEXT_FIELD,
  FieldType.FILE,
  FieldType.DROPDOWN,
  FieldType.CHECKBOX,
  FieldType.RADIO_BUTTON,
  FieldType.RICH_TEXT_FIELD,
  FieldType.TABLE,
  FieldType.FIXED_TABLE,
];

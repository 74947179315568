import {
  AIIcon,
  ApartmentOutlinedIcon,
  ArchitectureIcon,
  AssessmentIcon,
  BarChartIcon,
  BedIcon,
  CalendarToday,
  ChatBubbleOutlineIcon,
  DeveloperBoardIcon,
  EngineeringIcon,
  HistoryEduIcon,
  ImageIcon,
  LocationOnIcon,
  ManageAccountsIcon,
  MultilineChartIcon,
  ParkIcon,
  RequestQuoteIcon,
  StraightenIcon,
  SummarizeIcon,
  TableViewOutlinedIcon,
  TaskOutlinedIcon,
  TitleIcon,
} from "icons";

import {
  EMemoSectionLabel,
  EMemoSectionType,
} from "entities/MemoTemplate/constants";

export const MEMO_SECTION_GROUPS = {
  Custom: [
    {
      label: EMemoSectionLabel.HEADER,
      value: EMemoSectionType.HEADER,
      icon: <TitleIcon fontSize="small" />,
    },

    {
      label: EMemoSectionLabel.DUE_DILIGENCE,
      value: EMemoSectionType.DUE_DILIGENCE,
      icon: <TaskOutlinedIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.IMAGE,
      value: EMemoSectionType.IMAGE,
      icon: <ImageIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.COMMENT,
      value: EMemoSectionType.COMMENT,
      icon: <ChatBubbleOutlineIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.EXCEL_TABLE,
      value: EMemoSectionType.EXCEL_TABLE,
      icon: <TableViewOutlinedIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.AI_SUMMARY_RESPONSE,
      value: EMemoSectionType.AI_SUMMARY_RESPONSE,
      icon: <AIIcon fontSize="small" />,
    },
  ],
  Deal: [
    {
      label: EMemoSectionLabel.LOCATION,
      value: EMemoSectionType.LOCATION,
      icon: <LocationOnIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.PROJECT_DETAILS,
      value: EMemoSectionType.PROJECT_DETAILS,
      icon: <ApartmentOutlinedIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.PROJECT_NARRATIVE,
      value: EMemoSectionType.PROJECT_NARRATIVE,
      icon: <HistoryEduIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.SCHEDULE,
      value: EMemoSectionType.SCHEDULE,
      icon: <CalendarToday fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.UNIT_AMENITIES,
      value: EMemoSectionType.UNIT_AMENITIES,
      icon: <BedIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.COMMUNITY_AMENITIES,
      value: EMemoSectionType.COMMUNITY_AMENITIES,
      icon: <ParkIcon fontSize="small" />,
    },
  ],
  "Pro-forma": [
    {
      label: EMemoSectionLabel.SOURCES_AND_USES,
      value: EMemoSectionType.SOURCE_AND_USES,
      icon: <BarChartIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.DEVELOPMENT_BUDGET,
      value: EMemoSectionType.DEVELOPMENT_BUDGET,
      icon: <RequestQuoteIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.UNIT_MIX_AND_RENT,
      value: EMemoSectionType.UNIT_MIX_AND_RENT,
      icon: <AssessmentIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.INCOME_AND_OPEX,
      value: EMemoSectionType.INCOME_AND_OPEX,
      icon: <MultilineChartIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.FLOOR_AREA,
      value: EMemoSectionType.FLOOR_AREA,
      icon: <StraightenIcon fontSize="small" />,
    },
  ],
  Team: [
    {
      label: EMemoSectionLabel.DEVELOPER,
      value: EMemoSectionType.DEVELOPER,
      icon: <DeveloperBoardIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.ARCHITECT,
      value: EMemoSectionType.ARCHITECT,
      icon: <ArchitectureIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.GENERAL_CONTRACTOR,
      value: EMemoSectionType.GENERAL_CONTRACTOR,
      icon: <EngineeringIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.PROPERTY_MANAGER,
      value: EMemoSectionType.PROPERTY_MANAGER,
      icon: <ManageAccountsIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.DEVELOPER_PARTNER,
      value: EMemoSectionType.DEVELOPER_PARTNER,
      icon: <DeveloperBoardIcon fontSize="small" />,
    },
  ],
  Other: [
    {
      label: EMemoSectionLabel.EXECUTIVE_SUMMARY,
      value: EMemoSectionType.EXECUTIVE_SUMMARY,
      icon: <SummarizeIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.RISKS_AND_MITIGATIONS,
      value: EMemoSectionType.RISKS_AND_MITIGATIONS,
      icon: <SummarizeIcon fontSize="small" />,
    },
    {
      label: EMemoSectionLabel.FINAL_RECOMMENDATIONS,
      value: EMemoSectionType.FINAL_RECOMMENDATIONS,
      icon: <SummarizeIcon fontSize="small" />,
    },
  ],
};

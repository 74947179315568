import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { colors } from "theme/palette";
import { useCaching } from "utils/common";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import EditButton from "entities/Field/components/Richtextfield/EditButton";
import { fieldValueCreate } from "entities/Field/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";
import { handleInvalidMemoFieldRequest } from "entities/Memo/components/Fields/utils/utils";

interface IRichtextfield {
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
  adornment?: React.ReactNode;
}

const Richtextfield = ({
  field,
  dealId,
  externalToken,
  isNASection,
  onUpdate,
  adornment = null,
}: IRichtextfield) => {
  const [value, setValue] = useCaching<string | null>(
    _.first(field.values)?.rich_value!
  );

  const lastSavedAt = useMemo(
    () => _.first(field.values)?.updated_at || null,
    [field]
  );

  const navigate = useNavigate();

  const handleSave = useCallback(
    ({ newValue }: { newValue: string | null }) => {
      setValue(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.company_custom_field_id,
        value: newValue,
      }).catch((error) =>
        handleInvalidMemoFieldRequest({ error, dealId, navigate })
      );
      onUpdate();
    },
    [field, dealId, setValue, onUpdate, navigate]
  );

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showEditButton = useMemo(
    () => !isNAField && _.isUndefined(externalToken),
    [isNAField, externalToken]
  );

  return (
    <Box sx={{ borderBottom: `1px solid ${colors.blue40}` }}>
      <Stack direction="row" alignItems="center">
        <FieldTitle
          field={field}
          onUpdate={onUpdate}
          externalToken={externalToken}
          disabled={isNAField}
        />
        {showEditButton && (
          <NoPrint>
            <EditButton
              name={field.name}
              initialValue={value}
              onSave={handleSave}
              lastSavedAt={lastSavedAt}
              onUpdate={toast.successMessage}
              buttonDataTestid="field-edit-button"
            />
          </NoPrint>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
        {adornment}
      </Stack>
      <Box>
        {!isNAField && (
          <SanitizeHtml html={value || "N/A"} dataTestid="field-value" />
        )}
      </Box>
    </Box>
  );
};

export default Richtextfield;

import React, { useCallback } from "react";

import { handleInvalidRequest } from "utils/sdk";

import toast from "components/Toast";

import TaskForm, { IInitialValues } from "entities/Task/components/Form";
import { createTaskChannel, INewTaskChannel_legacy } from "entities/Task/sdk";

export interface IAddTaskForm {
  dealId: number;
  initialValues?: Partial<IInitialValues>;
  onCreated?: () => void;
  handleCancel?: () => void;
}

const AddTaskForm: React.FC<IAddTaskForm> = ({
  dealId,
  initialValues,
  onCreated,
  handleCancel,
}) => {
  const handleSubmit = useCallback(
    (
      data: Partial<
        Omit<INewTaskChannel_legacy, "deadline"> & {
          deadline: Date | null;
        }
      >
    ) =>
      createTaskChannel(dealId, data)
        .then(() => {
          onCreated && onCreated();
          toast.successMessage("The task has been created successfully!");
        })
        .catch(handleInvalidRequest),
    [onCreated, dealId]
  );

  return (
    <TaskForm
      dealId={dealId}
      onSubmit={handleSubmit}
      handleCancel={handleCancel}
      submitLabel="Create Task"
      initialValues={initialValues}
    />
  );
};

export default AddTaskForm;

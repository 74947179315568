import { useCallback } from "react";
import { FormikProps, getIn } from "formik";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS } from "utils/datetime";

import DatePicker from "components/DatePicker";
import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";

import { IFormikParticipant } from "entities/CompanyItegrations/components/Downhome/BusinessForm";
import ListFieldActions from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListFieldActions";
import {
  EBusinessOwnerSelectOptionTypes,
  EthnicityOptions,
  GenderOptions,
  VeteranStatusOptions,
} from "entities/CompanyItegrations/components/Downhome/constants";

import { getFormattedDateOfBirth } from "./utils";

export interface IBusinessOwner {
  formik: FormikProps<IFormikParticipant>;
  onRemove: ({ index }: { index: number }) => void;
  onUpdatePrimary: ({ index }: { index: number }) => void;
}

const BusinessOwner = ({
  formik,
  onRemove,
  onUpdatePrimary,
}: IBusinessOwner) => {
  const handleChangeDateOfBirth = useCallback(
    ({ date, index }: { date: Date | null; index: number }) => {
      const formattedDate = date ? format(date, FORMATS.BACKEND.DATE) : null;

      const currentOwners = formik.values.owners?.map((owner, i) => {
        if (i === index) {
          return {
            ...owner,
            date_of_birth: formattedDate,
          };
        }

        return owner;
      });

      formik.setFieldValue("owners", currentOwners);
    },
    [formik]
  );

  const handleChangeSelectOption = useCallback(
    ({
      event,
      index,
      type,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      index: number;
      type: EBusinessOwnerSelectOptionTypes;
    }) => {
      const newValue = event.target.value === "" ? null : event.target.value;
      const currentOwners = formik.values.owners?.map((owner, i) => {
        if (i === index) {
          return {
            ...owner,
            [type]: newValue,
          };
        }

        return owner;
      });
      formik.setFieldValue("owners", currentOwners);
    },
    [formik]
  );

  return (
    <Stack spacing={4} alignItems="start" width="100%">
      {formik.values.owners?.map((owner, index) => {
        const firstNameFieldName = `owners.${index}.first_name`;
        const lastNameFieldName = `owners.${index}.last_name`;
        const dateOfBirthFieldName = `owners.${index}.date_of_birth`;
        const veteranStatusFieldName = `owners.${index}.veteran_status`;
        const genderFieldName = `owners.${index}.gender`;
        const ethnicityFieldName = `owners.${index}.ethnic_group`;

        return (
          <Stack
            key={index}
            direction="row"
            spacing={2}
            alignItems="start"
            width="100%"
            sx={{
              paddingBottom: theme.spacing(3),
              borderBottom: `1px solid ${colors.gray20}`,
            }}
          >
            <Stack flex={1} spacing={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  sx={{ width: "100%" }}
                  label="First name"
                  InputLabelProps={{ shrink: true }}
                  {...formik.getFieldProps(firstNameFieldName)}
                  helperText={
                    getIn(formik.touched, firstNameFieldName) &&
                    !_.isEmpty(getIn(formik.errors, firstNameFieldName))
                  }
                  error={
                    getIn(formik.touched, firstNameFieldName) &&
                    !_.isEmpty(getIn(formik.errors, firstNameFieldName))
                  }
                />
                <TextField
                  sx={{ width: "100%" }}
                  label="Last name"
                  InputLabelProps={{ shrink: true }}
                  {...formik.getFieldProps(lastNameFieldName)}
                  helperText={
                    getIn(formik.touched, lastNameFieldName) &&
                    getIn(formik.errors, lastNameFieldName)
                  }
                  error={
                    getIn(formik.touched, lastNameFieldName) &&
                    !_.isEmpty(getIn(formik.errors, lastNameFieldName))
                  }
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Select
                  sx={{ width: "100%" }}
                  label="Gender"
                  options={GenderOptions}
                  {...formik.getFieldProps(genderFieldName)}
                  onChange={(event) =>
                    handleChangeSelectOption({
                      event,
                      index,
                      type: EBusinessOwnerSelectOptionTypes.GENDER,
                    })
                  }
                />
                <DatePicker
                  sx={{ width: "30%" }}
                  disableFuture
                  slotProps={{
                    textField: {
                      onKeyDown: (e) => {
                        e.preventDefault(); // We don't want to allow to type date manually
                      },
                      error:
                        getIn(formik.touched, dateOfBirthFieldName) &&
                        !_.isEmpty(getIn(formik.errors, dateOfBirthFieldName)),
                      helperText:
                        getIn(formik.touched, dateOfBirthFieldName) &&
                        getIn(formik.errors, dateOfBirthFieldName),
                    },
                    field: { clearable: true },
                  }}
                  label="Date of birth"
                  onChange={(date) => handleChangeDateOfBirth({ date, index })}
                  value={getFormattedDateOfBirth({
                    dateOfBirth:
                      formik.getFieldProps(dateOfBirthFieldName).value,
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Select
                  sx={{ width: "100%" }}
                  label="Ethnicity"
                  {...formik.getFieldProps(ethnicityFieldName)}
                  options={EthnicityOptions}
                  onChange={(event) =>
                    handleChangeSelectOption({
                      event,
                      index,
                      type: EBusinessOwnerSelectOptionTypes.ETHNIC_GROUP,
                    })
                  }
                />
                <Select
                  sx={{ width: "100%" }}
                  label="Veteran status"
                  {...formik.getFieldProps(veteranStatusFieldName)}
                  options={VeteranStatusOptions}
                  onChange={(event) =>
                    handleChangeSelectOption({
                      event,
                      index,
                      type: EBusinessOwnerSelectOptionTypes.VETERAN_STATUS,
                    })
                  }
                />
              </Stack>
            </Stack>
            <ListFieldActions
              key={index}
              checked={owner.primary}
              onPrimaryElementUpdate={() => onUpdatePrimary({ index })}
              onElementRemove={() => onRemove({ index })}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default BusinessOwner;

import React from "react";

import { useSignedUser } from "entities/Auth/sdk";

export interface IVisibleIfSuperuser {
  children: React.ReactNode;
}

const VisibleIfSuperuser = ({ children }: IVisibleIfSuperuser) => {
  const { data: user } = useSignedUser();

  if (!user) {
    return null;
  }

  if (user.is_superuser) {
    return <>{children}</>;
  }

  return null;
};

export default VisibleIfSuperuser;

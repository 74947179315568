import { useCallback } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import PromptPackageSelect from "entities/AISummaries/components/PromptPackageSelect";
import {
  openAIDocumentAssistantPromptPackageUpdate,
  useOpenAIPromptPackages,
} from "entities/AISummaries/sdk";

export interface IUpdatePromptPackageDialog {
  isOpen: boolean;
  initialPromptPackageId: number | null;
  openAIDocumentAssistantId: number;
  onUpdate: () => void;
  onClose: () => void;
}

const UpdatePromptPackageDialog = ({
  isOpen: initialIsOpen,
  initialPromptPackageId,
  openAIDocumentAssistantId,
  onUpdate,
  onClose,
}: IUpdatePromptPackageDialog) => {
  const [isOpen, setIsOpen] = useCaching(initialIsOpen);
  const [selectedPromptPackageId, setSelectedPromptPackageId] = useCaching<
    number | null
  >(initialPromptPackageId);

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const { data: promptPackages, isLoading: promptPackagesAreLoading } =
    useOpenAIPromptPackages();

  const handleClose = useCallback(() => {
    setSelectedPromptPackageId(initialPromptPackageId);
    setIsOpen(false);
    onClose();
  }, [initialPromptPackageId, setIsOpen, onClose, setSelectedPromptPackageId]);

  const onUpdatePromptPackage = useCallback(() => {
    if (!_.isNil(selectedPromptPackageId)) {
      openAIDocumentAssistantPromptPackageUpdate({
        openAIDocumentAssistantId,
        openAIPromptPackageId: selectedPromptPackageId,
      })
        .then(() => {
          toast.successMessage(
            "The prompt package has been successfully updated!"
          );
          onUpdate();
          setIsOpen(false);
        })
        .catch((errors) => {
          const errorMessage = errors?.message || errors?.detail;

          if (!_.isNil(errorMessage)) {
            toast.errorMessage(errorMessage);
          } else {
            errors?.forEach?.((error: unknown) => {
              if (_.isString(error)) {
                toast.errorMessage(error);
              }
            });
          }
        });
    }
  }, [openAIDocumentAssistantId, selectedPromptPackageId, setIsOpen, onUpdate]);

  const handleChangePromptPackage = useCallback(
    ({ newPromptPackageId }) => {
      setSelectedPromptPackageId(newPromptPackageId);
    },
    [setSelectedPromptPackageId]
  );

  const handleCreate = useCallback(() => {
    if (
      _.isNil(initialPromptPackageId) ||
      initialPromptPackageId === selectedPromptPackageId
    ) {
      onUpdatePromptPackage();
    } else {
      showConfirmationDialog({
        onConfirm: onUpdatePromptPackage,
        confirmButtonText: "Yes, modify it",
        message: (
          <>
            <Text variant="h2" marginBottom={1}>
              Modifying AI summary
            </Text>
            <Text variant="text2">
              Changing the prompt package will delete all previously generated
              answers under the current prompt package. Are you sure you want to
              proceed?
            </Text>
          </>
        ),
      });
    }
  }, [
    selectedPromptPackageId,
    initialPromptPackageId,
    onUpdatePromptPackage,
    showConfirmationDialog,
  ]);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ padding: 2 }}>
        <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
          {/* 520px because we want to be with the same width as CreateAISummariesDialog */}
          <Text variant="h2" sx={{ width: "520px" }}>
            Modify AI summary
          </Text>
        </Paper>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Box>
          {promptPackagesAreLoading || _.isNil(promptPackages) ? (
            <Skeleton height="56px" />
          ) : (
            <PromptPackageSelect
              promptPackages={promptPackages}
              value={selectedPromptPackageId?.toString()}
              onChange={handleChangePromptPackage}
              showConfirmation={false}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleCreate}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePromptPackageDialog;

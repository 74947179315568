import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import Button from "components/Button";
import { CircularProgress } from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import {
  EShareTokenStatus,
  shareTokenAccept,
  shareTokenReject,
  useShareToken,
} from "entities/Package/sdk";

const PartialAccessInvitationAccept = () => {
  const navigate = useNavigate();

  const params = useParams<{ token: string }>();
  const token = params.token as string;

  const { data: shareToken, error } = useShareToken({ token });
  const { show: showConfirmationDialog } = useConfirmationDialog();

  useEffect(() => {
    if (error?.detail === "Not found.") {
      navigate(URLS.NOT_FOUND);
    }
  }, [error, navigate]);

  const handleReject = useCallback(() => {
    if (!shareToken) {
      return;
    }

    showConfirmationDialog({
      onConfirm: () =>
        shareTokenReject({ token: shareToken.token }).then(() => {
          toast.successMessage("Invitation has been rejected successfully");
          navigate(reverse(URLS.PROJECTS_LIST, { phase: "All" }));
        }),
      message:
        "Are you sure you want to reject this invitation? This action cannot be reverted.",
      confirmButtonText: "Reject invitation",
    });
  }, [showConfirmationDialog, navigate, shareToken]);

  const handleAccept = useCallback(() => {
    if (!shareToken) {
      return;
    }

    shareTokenAccept({ token: shareToken.token }).then(() => {
      toast.successMessage("Invitation has been accepted successfully");
      navigate(
        reverse(URLS.DEAL_PACKAGE_NO_TAB, {
          dealId: shareToken.deal.id,
          packageId: shareToken.company_package.id,
        })
      );
    });
  }, [navigate, shareToken]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ flexGrow: 1 }}
      data-testid="deal-onboarding-content"
    >
      {_.isNil(shareToken) && <CircularProgress />}

      {shareToken?.status === EShareTokenStatus.PENDING && (
        <>
          <Text
            variant="h1"
            textAlign="center"
            paddingTop={20}
            paddingLeft={20}
            paddingRight={20}
            data-testid="invitation-message"
          >
            You have been invited to join "
            <span data-testid="checklist-name">
              {shareToken.company_package.name}
            </span>
            " in deal "
            <span data-testid="deal-name">{shareToken.deal.name}</span>".
          </Text>

          <Stack
            direction="row"
            marginTop={6}
            spacing={2}
            data-testid="invitation-action-buttons"
          >
            <Button
              variant="text"
              onClick={handleReject}
              data-testid="reject-button"
            >
              Reject invitation
            </Button>
            <Button onClick={handleAccept} data-testid="accept-button">
              Accept invitation
            </Button>
          </Stack>
        </>
      )}

      {shareToken?.status === EShareTokenStatus.ACCEPTED && (
        <Text variant="h1" data-testid="invitation-accepted-message">
          This invitation has already been accepted!
        </Text>
      )}

      {shareToken?.status === EShareTokenStatus.REJECTED && (
        <Text variant="h1" data-testid="invitation-rejected-message">
          This invitation has already been rejected!
        </Text>
      )}

      {(shareToken?.status === EShareTokenStatus.CANCELED ||
        shareToken?.status === EShareTokenStatus.INVALIDATED) && (
        <Text variant="h1" data-testid="invitation-canceled-message">
          This invitation has been canceled!
        </Text>
      )}
    </Stack>
  );
};

export default PartialAccessInvitationAccept;

import { colors } from "theme/palette";
import { SUPPORT_EMAIL } from "utils/constants";

import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { logout } from "entities/Auth/sdk";

const PleaseVerifyYourEmail = () => {
  usePageTitle("Builders Patch: Email Verification");

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Stack
      sx={{
        textAlign: "center",
        flexGrow: 1,
        height: "100vh",
        justifyContent: "center",
      }}
      data-testid="page-content"
    >
      <Text variant="h1" data-testid="page-header">
        Please verify your email address.
      </Text>
      <Text
        variant="h3"
        marginTop={2}
        marginBottom={3}
        data-testid="page-sub-header"
      >
        We have sent you a verification email.
      </Text>

      <Text
        variant="body2"
        marginTop={2}
        marginBottom={1}
        data-testid="page-body"
      >
        In case you have already verified your email, you can go{" "}
        <Link
          to={"/"}
          style={{ color: colors.blue100 }}
          data-testid="home-page-link"
        >
          Back to the home page
        </Link>{" "}
        or{" "}
        <Text
          variant="body2"
          color="primary"
          display="inline"
          sx={{
            cursor: "pointer",
          }}
          onClick={handleLogoutClick}
          data-testid="log-out-link"
        >
          Log out
        </Text>
        .
      </Text>
      <Text variant="body2" data-testid="contact-support">
        If you are having trouble verifying your email address, please contact
        us at{" "}
        <Link
          to={`mailto:${SUPPORT_EMAIL}`}
          style={{ color: colors.blue100 }}
          data-testid="contact-support-link"
        >
          {SUPPORT_EMAIL}
        </Link>
      </Text>
    </Stack>
  );
};

export default PleaseVerifyYourEmail;

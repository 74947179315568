import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { CloseIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import UploadButton from "components/UploadButton";
import { defaultProformaUploadButton } from "components/UploadButton/utils";

import { upload } from "entities/File/sdk";
import { uploadProformaData } from "entities/Proforma/sdk";

interface IUploadExcelForm {
  directUpload?: boolean;
  dealId: number;
  onUpload: () => void;
  onClose?: () => void;
  uploadButtonTestid?: string;
}

const SUPPORTED_FORMATS = ["xls", "xlsx", "xlsm"];

const VALIDATION_SCHEMA = yup.object({
  file: yup
    .mixed()
    .test(
      "format",
      `Supported file types are: ${SUPPORTED_FORMATS.map(
        (format) => "." + format
      ).join(", ")}`,
      (value) =>
        !(
          value &&
          !SUPPORTED_FORMATS.includes(
            ((value as File).name.split(".").pop() as string).toLowerCase()
          )
        )
    ),
});

interface IFormValues {
  file?: File;
}

const UploadExcelForm: React.FC<IUploadExcelForm> = ({
  directUpload = true,
  dealId,
  onUpload,
  onClose,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleFormSubmit = useCallback(
    (values: IFormValues) => {
      setSubmitting(true);

      if (values.file) {
        upload({ file: values.file, isPrivate: false })
          .then((fileId: number) =>
            uploadProformaData({ dealId, fileId })
              .then(() => {
                onUpload();
              })
              .catch(handleInvalidRequest)
          )
          .finally(() => setSubmitting(false));
      }
    },
    [dealId, onUpload]
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleFormSubmit,
  });

  const handleFileUpload = useCallback(
    ({ files }: { files: Array<File> }) => {
      formik.setFieldValue("file", files[0]);

      if (directUpload) {
        handleFormSubmit({ file: files[0] });
      }
    },
    [formik, directUpload, handleFormSubmit]
  );

  const resetAndClose = () => {
    formik.resetForm();
    onClose && onClose();
  };

  const clearFile = useCallback(() => {
    formik.setFieldValue("file", undefined);
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        }}
      >
        <Stack>
          <UploadButton
            maxFiles={1}
            onChange={handleFileUpload}
            accept={SUPPORTED_FORMATS.join(", ")}
            renderComponent={(state) =>
              defaultProformaUploadButton({
                state,
                errors: formik.errors.file && String(formik.errors.file),
              })
            }
            dataTestid="upload-proforma-input"
          />
        </Stack>
        <Stack spacing={2} direction="column">
          <Text variant="h5" data-testid="upload-helper-title">
            Upload Excel Sheet
          </Text>
          <Text
            variant="text2"
            color={colors.gray60}
            sx={{
              maxWidth: "300px",
            }}
            data-testid="upload-helper-text"
          >
            Upload your own Excel sheet with all the basic information and
            finance specifics of your deal, or get a template first. Let us do
            the calculations for you!
          </Text>
          {formik.values.file && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Text variant="text2" data-testid="uploaded-proforma-file">
                {formik.values.file.name}
              </Text>
              <IconButton onClick={clearFile} dataTestid="remove-file-button">
                <CloseIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
      {!directUpload && (
        <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="text"
            onClick={resetAndClose}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: 2, cursor: submitting ? "loading" : "initial" }}
            type="submit"
            disabled={!formik.values.file || submitting}
            data-testid="submit-button"
          >
            Submit
          </Button>
        </Stack>
      )}
    </form>
  );
};

export default UploadExcelForm;

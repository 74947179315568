import React, { useCallback, useEffect, useState } from "react";

import {
  CancelIcon,
  CheckCircleIcon,
  DownloadIcon,
  DownloadingIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, parseJSON } from "utils/datetime";
import { downloadFileWithFileName } from "utils/file";

import IconButton from "components/IconButton";
import Pagination from "components/Pagination";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { getZipDetail, IZipList, useZipList } from "entities/DDPackageZip/sdk";

const LIMIT = 10;

interface IZipListParams {
  dealId: number;
  packageId: number;
}

const ZipList: React.FC<IZipListParams> = ({ dealId, packageId }) => {
  const [offset, setOffset] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, value: number) => {
      setOffset(LIMIT * value - LIMIT);
    },
    []
  );

  const { data: zipList } = useZipList({
    dealId,
    packageId,
    offset,
    limit: LIMIT,
  });

  useEffect(() => {
    if (zipList) {
      setPagesCount(Math.ceil(zipList.count / zipList.limit));
    }
  }, [zipList]);

  const handleDownloadSpeficZip = (zipId: number) => {
    getZipDetail({ zipId }).then((response) =>
      downloadFileWithFileName({
        fileUrl: response.url,
        fileName: response.name,
      })
    );
  };

  return (
    <Paper
      sx={{
        padding: theme.spacing(2),
        backgroundColor: colors.blue10,
        flex: 1,
      }}
      data-testid="checklist-zip-files-section"
    >
      <Stack>
        {zipList?.results.map((zip: IZipList, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={2}
            sx={{
              marginBottom: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              borderBottom:
                index < zipList.results.length - 1
                  ? `1px solid ${colors.gray20}`
                  : "",
            }}
            data-testid="zip-file-entity"
          >
            {zip.finished_at && !zip.failed && (
              <CheckCircleIcon
                sx={{ color: colors.green100 }}
                data-testid="zip-create-success-icon"
              />
            )}
            {!zip.finished_at && zip.failed && (
              <CancelIcon
                sx={{ color: colors.red120 }}
                data-testid="zip-create-fail-icon"
              />
            )}
            {!zip.finished_at && !zip.failed && (
              <DownloadingIcon
                sx={{ color: colors.yellow120 }}
                data-testid="zip-create-inprogress-icon"
              />
            )}
            <Stack flex={1}>
              <Text variant="text2" data-testid="zip-created-datetime">
                Started at:{" "}
                {format(parseJSON(zip.created_at), "MMM dd, yyyy, hh:mm a")}
              </Text>
              <Text variant="text2" data-testid="zip-created-by-user-name">
                Created by: {zip.created_by.first_name}{" "}
                {zip.created_by.last_name}
              </Text>
              <Text variant="text2" data-testid="zip-phase">
                Phase: {zip.phase}
              </Text>
            </Stack>
            {zip.finished_at && !zip.failed && (
              <Tooltip title="Download" disableInteractive>
                <IconButton
                  onClick={() => handleDownloadSpeficZip(zip.id)}
                  dataTestid="download-zip-button"
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ))}
        <Stack alignItems="center">
          <Pagination
            variant="outlined"
            shape="rounded"
            count={pagesCount}
            onChange={handlePageChange}
            data-testid="pagination"
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ZipList;

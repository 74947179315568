import { useParams } from "react-router-dom";

import theme from "theme";
import { colors } from "theme/palette";

import Paper from "components/Paper";
import Stack from "components/Stack";

import { useDealDetails } from "entities/Deal/sdk";

import DealInfo from "./DealInfo";
import MenuList from "./MenuList";

// Used for anchoring
export const ID = "sub-navigation-menu";

const SubNavigationMenu = () => {
  const params = useParams<{ dealId: string }>();
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });
  return (
    <Paper
      square={true}
      id={ID}
      sx={{
        borderBottom: `1px solid ${colors.blue20}`,
        padding: theme.spacing(1, 4),
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DealInfo deal={deal} />
        <MenuList />
      </Stack>
    </Paper>
  );
};

export default SubNavigationMenu;

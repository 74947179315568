import React from "react";
import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";
import toast from "components/Toast";

import EditSignedUserDialog from "entities/User/components/EditSignedUserDialog";

export interface IEditSignedUserButton {}

const EditSignedUserButton: React.FC<IEditSignedUserButton> = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleUpdated = useCallback(() => {
    toast.successMessage("Profile successfully updated!");
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen} data-testid="edit-user-profile-button">
        <EditIcon />
      </IconButton>
      <EditSignedUserDialog
        open={open}
        handleClose={handleClose}
        onUpdated={handleUpdated}
      />
    </>
  );
};

export default EditSignedUserButton;

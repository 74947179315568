import { BASE_URL } from "config/urls";
import { get, getText, post, useFetch, usePaginatedFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { Phase } from "entities/Deal/constants";
import { IUploadedFile } from "entities/File/sdk";

const headerUnix: Array<{ key: string; name: string }> = [
  { key: "empty", name: "" },
  { key: "sro/studio", name: "SRO/Studio" },
  { key: "1bdr", name: "1 BDR" },
  { key: "2bdr", name: "2 BDR" },
  { key: "3bdr", name: "3 BDR" },
  { key: "4bdr", name: "4 BDR" },
  { key: "total", name: "Total" },
];

export const getHeaderUnix = () => headerUnix;

export interface ISnapshot {
  description: string;
  created_at: string;
  created_by: {
    first_name: string;
    last_name: string;
  };
  phase_name: Phase;
}
export interface ICoreData {
  total_units: number;
  total_s_f: number;
  uses_permanent_data: any;
  sources_permanent_data: any;
  uses_at_construction_data: any;
  sources_at_construction_data: any;
  total_sources_at_construction_data: number;
  total_uses_at_construction_data: number;
  total_sources_permanent_data: number;
  total_uses_permanent_data: number;
}

export interface IBudgetData {
  hard_cost: any;
  hard_cost_contingency: any;
  miscellaneous_items: any;
  total_hard_cost: any;
  total_trade_cost_breakdown: any;
  acquisition_of_land: any;
  total_acquisition_cost: any;
  soft_cost_contingency: any;
  total_soft_cost: any;
  soft_cost: any;
  total_developer_fees: any;
  developer_fees: any;
  total_development_budget: any;
}

export interface IRentalIncomeData {
  rental_data: any;
}

export interface IIncomeOperatingCost {
  rental_income: any;
  total_other_income: any;
  total_effective_gross_income: any;
  total_expenses: any;
  noi: any;
  debt_service: any;
  net_cash_flow: any;
  breakeven_occupany_ration: any;
  operating_expense_ratio: any;
  debt_service_coverage_ratio: any;
  less_income: any;
  other_income: any;
  net_rental_income: any;
  total_gross_expenses: any;
  total_management_cost: any;
  management_cost: any;
  total_administration_cost: any;
  administration_cost: any;
  total_repair_and_management: any;
  repair_and_management_cost: any;
  total_utilities_cost: any;
  utilities_cost: any;
  total_tax_cost: any;
  tax_cost: any;
  total_insurance_cost: any;
  insurance_cost: any;
  toal_replacement_reserves: any;
}

export interface IProforma {
  core_data: ICoreData;
  budget_data: IBudgetData;
  rental_data: IRentalIncomeData;
  income_operating_cost: IIncomeOperatingCost;
  error: string;
}

export const useProformaForDeal = ({
  dealId,
}: {
  dealId: number | undefined;
}) => useFetch<IProforma>(dealId ? `v2/deal/${dealId}/proforma/` : undefined);

export const useRentalData = ({ dealId }: { dealId: number | undefined }) =>
  useFetch<IRentalIncomeData>(
    dealId ? `v2/deal/${dealId}/rental-data/retrieve/` : undefined
  );

export const getProformaDealDetails = (dealId: number) =>
  get(`${BASE_URL}deal/${dealId}/project-data/excel-export/`);

export const proformaDealDetailsdownloadCsv = ({
  dealId,
}: {
  dealId: number;
}) => getText(`${BASE_URL}deal/${dealId}/project-data/csv-export/`);

export const uploadProformaData = ({
  dealId,
  fileId,
}: {
  dealId: number;
  fileId: number;
}) => post(`${BASE_URL}deal/${dealId}/upload_data`, { file_id: fileId });

export interface IPLProjection {
  p_and_l: {
    expenses: {
      data: {
        administration: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
        insurance: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
        management_fees: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
        repair_and_maintenance: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
        taxes: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
        utilities: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
      };
      total_expenses: { [key: number]: number };
      total_gross_expenses: { [key: number]: number };
      total_replacement_reserves: { [key: number]: number };
    };
    metrics: {
      dscr: { [key: number]: number };
      noi: { [key: number]: number };
      ds: { [key: number]: number };
      oer: { [key: number]: number };
      bor: { [key: number]: number };
      net_cash_flow: { [key: number]: number };
    };
    revenues: {
      data: {
        net_rental_income: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
          rental_dataset: { [key: string]: { [key: number]: number } };
          vacancy_dataset: { [key: string]: { [key: number]: number } };
          total_vacancy_dataset: { [key: number]: number };
        };
        all_other_income: {
          data: { [key: string]: { [key: number]: number } };
          value: { [key: number]: number };
        };
      };
      total_effective_gross_income: { [key: number]: number };
    };
    years: number[];
  };
}

export const getPLProjectionsData = ({
  dealId,
  startYear,
  timespan,
  rateOfIncome,
  rateOfExpenses,
  rateFixedAt,
  firstSelector,
  secondSelector,
  firstSelectorValue,
  secondSelectorValue,
}: {
  dealId: number;
  startYear: number;
  timespan: number;
  rateOfIncome: number;
  rateOfExpenses: number;
  rateFixedAt: number;
  firstSelector?: string;
  secondSelector?: string;
  firstSelectorValue: number;
  secondSelectorValue: number;
}): Promise<IPLProjection> => {
  let params: { [key: string]: string | number } = {
    start_year: startYear,
    projection: timespan,
    rate_of_income: rateOfIncome,
    rate_of_expense: rateOfExpenses,
    vacancy_value: rateFixedAt,
  };

  if (firstSelector) {
    params[firstSelector] = firstSelectorValue;
  }
  if (secondSelector) {
    params[secondSelector] = secondSelectorValue;
  }

  return get(
    `${BASE_URL}v2/deal/${dealId}/profit-and-loss/retrieve/${
      params ? formatGetParams(params) : ""
    }`
  );
};

export interface IProformaFilesListFilters {
  only_snapshot: boolean;
}

export const useProformaFilesList = ({
  dealId,
  limit = 10,
  enabled = true,
  filters,
}: {
  dealId: number;
  limit?: number;
  enabled?: boolean;
  filters?: IProformaFilesListFilters;
}) =>
  usePaginatedFetch<IUploadedFile>({
    url: enabled ? `v2/deal/deal/${dealId}/proforma/files/list/` : undefined,
    params: { limit, ...filters },
  });

export const proformaSnapshotCreate = ({
  dealId,
  description,
}: {
  dealId: number;
  description: string;
}) =>
  post(`${BASE_URL}v2/deal/deal/${dealId}/proforma/snapshot/create/`, {
    description,
  });

import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import _ from "lodash";

import { URLS } from "config/urls";
import { colors } from "theme/palette";
import { format, FORMATS, parseISO } from "utils/datetime";
import { reverse } from "utils/urls";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Loading from "components/Loading";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import ListField from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListField";
import { EListFieldType } from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListField/constants";
import { BusinessLegalFormOptions } from "entities/CompanyItegrations/components/Downhome/constants";
import {
  IDownhomeAddress,
  IDownhomeBusinessParticipant,
  IDownhomeEmail,
  IDownhomeIndustryGroup,
  IDownhomePersonParticipant,
  IDownhomePhone,
  IDownhomeWebsite,
} from "entities/CompanyItegrations/sdk";

import { IDownhomeOwnerProperties } from "./AddOwnerButton/constants";
import { VALIDATION_SCHEMA } from "./constants";

export interface IFormikParticipant {
  name: string;
  addresses: Array<IDownhomeAddress> | null;
  emails: Array<IDownhomeEmail> | null;
  phones: Array<IDownhomePhone> | null;
  websites: Array<IDownhomeWebsite> | null;
  established_on: string | null;
  business_legal_form: string | null;
  naics: string | null;
  industry_group: {
    nickname: string;
  } | null;
  owners: Array<IDownhomePersonParticipant> | null;
}

const initialValues: IFormikParticipant = {
  name: "",
  addresses: null,
  emails: null,
  phones: null,
  websites: null,
  established_on: null,
  business_legal_form: null,
  industry_group: null,
  naics: null,
  owners: null,
};

export interface IBusinessForm {
  isSubmitting: boolean;
  onSubmit: ({ data }: { data: IFormikParticipant }) => void;
  industryGroups: Array<IDownhomeIndustryGroup>;
  initialParticipantData?: IDownhomeBusinessParticipant;
  initialOwners?: IDownhomeOwnerProperties[] | null;
}

const BusinessForm = ({
  isSubmitting,
  onSubmit,
  industryGroups,
  initialParticipantData,
  initialOwners,
}: IBusinessForm) => {
  const navigate = useNavigate();
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const industryGroupsOptions = useMemo(() => {
    return industryGroups.map((industryGroup) => ({
      value: industryGroup.nickname,
      label: _.capitalize(industryGroup.name),
    }));
  }, [industryGroups]);

  const handleSubmit = useCallback(
    (values: IFormikParticipant) => {
      const data = {
        ...values,
        naics: values.naics || null,
      };
      onSubmit({ data });
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...initialParticipantData },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const establishedOnFormattedValue = useMemo(() => {
    const establishedOn = formik.values.established_on;

    if (_.isNil(establishedOn)) {
      return null;
    }

    return parseISO(establishedOn as string);
  }, [formik]);

  const handleClose = useCallback(
    () => navigate(reverse(URLS.COMPANY_INTEGRATIONS_LIST, { dealId })),
    [navigate, dealId]
  );

  const submitButtonLabel = useMemo(() => {
    if (!_.isUndefined(initialParticipantData)) {
      return "Save & update data";
    }
    return "Save & activate integration";
  }, [initialParticipantData]);

  const handleChangeEstablishedOn = useCallback(
    (date: Date | null) => {
      const formattedDate = !_.isNull(date)
        ? format(date, FORMATS.BACKEND.DATE)
        : null;
      formik.setFieldValue("established_on", formattedDate);
    },
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={isSubmitting} />
      <Stack spacing={2} sx={{ overflowY: "hidden" }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Text variant="text1" color={colors.black} fontWeight={700}>
              Loan applicant details
            </Text>

            <Stack spacing={2}>
              <TextField
                size="medium"
                InputLabelProps={{ shrink: true }}
                label="Business Name"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && !_.isEmpty(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <DatePicker
                slotProps={{
                  textField: {
                    onKeyDown: (e) => {
                      e.preventDefault(); // We don't want to allow to type date manually
                    },
                    error:
                      formik.touched.established_on &&
                      !_.isEmpty(formik.errors.established_on),
                    helperText:
                      formik.touched.established_on &&
                      formik.errors.established_on,
                  },
                  field: { clearable: true },
                }}
                label="Established on"
                onChange={handleChangeEstablishedOn}
                value={establishedOnFormattedValue}
              />

              <Select
                label="Industry"
                {...formik.getFieldProps("industry_group.nickname")}
                options={industryGroupsOptions}
              />

              <Select
                label="Business legal form"
                {...formik.getFieldProps("business_legal_form")}
                options={BusinessLegalFormOptions}
              />

              <TextField
                label="NAICS"
                InputLabelProps={{ shrink: true }}
                {...formik.getFieldProps("naics")}
                error={formik.touched.naics && !_.isEmpty(formik.errors.naics)}
                helperText={formik.touched.naics && formik.errors.naics}
              />
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Text variant="text1" color={colors.black} fontWeight={700}>
              Addresses
            </Text>
            <ListField formik={formik} fieldType={EListFieldType.ADDRESSES} />
          </Stack>

          <Stack spacing={2}>
            <Text variant="text1" color={colors.black} fontWeight={700}>
              Emails
            </Text>
            <ListField formik={formik} fieldType={EListFieldType.EMAILS} />
          </Stack>

          <Stack spacing={2}>
            <Text variant="text1" color={colors.black} fontWeight={700}>
              Phones
            </Text>
            <ListField formik={formik} fieldType={EListFieldType.PHONES} />
          </Stack>

          <Stack spacing={2}>
            <Text variant="text1" color={colors.black} fontWeight={700}>
              Websites
            </Text>
            <ListField formik={formik} fieldType={EListFieldType.WEBSITES} />
          </Stack>

          <Stack spacing={2}>
            <Text variant="text1" color={colors.black} fontWeight={700}>
              Owners
            </Text>
            <ListField
              formik={formik}
              fieldType={EListFieldType.OWNERS}
              initialOwners={initialOwners}
            />
          </Stack>

          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              {submitButtonLabel}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default BusinessForm;

import { DisabledByDefaultOutlinedIcon } from "icons";

import Checkbox from "components/Checkbox";
interface IApproveCheckbox {
  areAllNA: boolean;
  disabled: boolean;
  checkboxLoadingStyle:
    | {
        animation: string;
        "@keyframes rotating": {
          from: { transform: string };
          to: { transform: string };
        };
      }
    | { animation?: undefined; "@keyframes rotating"?: undefined };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  itemDataTestid?: string;
}

const ApproveCheckbox = ({
  areAllNA,
  disabled,
  checkboxLoadingStyle,
  onChange,
  checked,
  itemDataTestid,
}: IApproveCheckbox) => {
  return areAllNA ? (
    <DisabledByDefaultOutlinedIcon
      sx={{ opacity: 0.5 }}
      data-testid={`${itemDataTestid}-na-checkbox`}
    />
  ) : (
    <Checkbox
      disabled={disabled}
      sx={{ padding: 0, ...checkboxLoadingStyle }}
      onChange={onChange}
      checked={checked}
      data-testid={`dd-${itemDataTestid}-checkbox`}
    />
  );
};

export default ApproveCheckbox;

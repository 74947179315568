// This file is a copy of entities/Fields/Filefield, but without all the functionality
import { useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";

import Box from "components/Box";
import Stack from "components/Stack";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { checkFilePermission } from "entities/Field/sdk";
import ImportPdfDataFilesTable from "entities/Package/components/PdfImport/Fields/Filefield/FilesTable";
import FieldTitle from "entities/Package/components/PdfImport/Fields/Title";
import { IPackageField } from "entities/Package/sdk";

export interface IFilefield {
  field: IPackageField;
}

const Filefield = ({ field }: IFilefield) => {
  const handleDownloadFile = useCallback(
    ({ fileId }: { fileId: number }) =>
      checkFilePermission({ fileId, fieldId: field.field_id })
        .then(({ url }) => openURLInNewTab({ url }))
        .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE)),
    [field]
  );

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <FieldTitle field={field} editable={false} />
      </Stack>
      {field.values.length > 0 && (
        <ImportPdfDataFilesTable
          customValues={field.values}
          onDownload={handleDownloadFile}
        />
      )}
    </Box>
  );
};

export default Filefield;

import _ from "lodash";

import { DEFAULT_CELL_WIDTH } from "components/StyledTable/utils/common";
import { IHeader } from "components/StyledTable/utils/header";

type IDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Wrapper =
  ({ key, width }: { key: number | string; width: string | undefined }) =>
  (props: IDivProps) => (
    <div
      key={key}
      {...props}
      style={
        props.style
          ? props.style
          : { width: width || DEFAULT_CELL_WIDTH + "px" }
      }
    />
  );

const defaultCellRender: IHeader<{
  itemKey: string;
  values: { [key: string]: { value: string } };
}>["render"] = ({ itemKey, row, Wrapper }) => {
  const defaultValue = _.get(row.values, [
    `${row.itemKey}|${itemKey}`,
    "value",
  ]);
  const newValue = _.get(row.values, [
    `${row.itemKey}|${itemKey}`,
    "new_value",
  ]);

  let value = newValue;

  if (_.isUndefined(value)) {
    value = defaultValue;
  }

  return <Wrapper key={`${row.itemKey}|${itemKey}`}>{value}</Wrapper>;
};

export const renderRow = <
  T extends { itemKey: string; values: { [key: string]: { value: string } } },
>({
  header: { itemKey, width, render = defaultCellRender },
  row,
  index,
}: {
  header: { itemKey: string; width?: string; render?: IHeader<T>["render"] };
  row: T;
  index: number;
}) =>
  render({
    itemKey,
    row,
    index,
    Wrapper: Wrapper({ key: itemKey, width }),
  });

import React from "react";

import Box from "components/Box";
import { IScrollableTable } from "components/ScrollableTable";
import HeaderCell from "components/ScrollableTable/Cells/HeaderCell";

export type IScrollableTableHeader = IScrollableTable["headers"];

export interface ITableHeaders {
  headers: IScrollableTableHeader;
  currentSort: string | undefined;
  lastElementWidth: number;
  onSort?: (key: string | undefined) => void;
}

const TableHeaders = ({
  headers,
  lastElementWidth,
  currentSort,
  onSort,
}: ITableHeaders) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "max-content", // This is used so the sticky bar remains in place. But it also breaks the table when there are only a few columns
        zIndex: 1,
      }}
    >
      {headers.map((header, index) => (
        <HeaderCell
          key={index}
          header={header}
          currentSort={currentSort}
          lastElementWidth={lastElementWidth}
          onSort={onSort}
        />
      ))}
    </Box>
  );
};

export default React.memo(TableHeaders);

import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useLoadingBackdrop } from "components/useLoadingBackdrop";

import SaveComparedTablesButton from "entities/TableStorage/components/SaveComparedTablesButton";
import SelectedTable from "entities/TableStorage/components/SelectedTable";
import Table from "entities/TableStorage/components/Table";
import TablesAutocomplete from "entities/TableStorage/components/TablesAutocomplete";
import {
  compareTables,
  getGroupedColumns,
  ITableList,
  ITableRow,
  useTableForCompare,
} from "entities/TableStorage/sdk";

const TablesCompare = () => {
  const navigate = useNavigate();

  const [showLoading, hideLoading] = useLoadingBackdrop();

  const [groupedColumns, setGroupedColumns] = useState<Array<Array<string>>>(
    []
  );
  const [selectedTables, setSelectedTables] = useState<Array<ITableList>>([]);
  const [tableData, setTableData] = useState<Array<ITableRow>>([]);
  const [disabledIndexRows, setDisabledIndexRows] = useState<Array<number>>([]);
  const [disabledIndexColumns, setDisabledIndexColumns] = useState<
    Array<number>
  >([]);
  const [changedCells, setChangedCells] = useState<{
    [key: string]: string | null;
  }>({});

  const params = useParams<{
    dealId?: string;
    tableId?: string;
  }>();

  const dealId = useMemo(
    () => (_.isUndefined(params.dealId) ? null : Number(params.dealId)),
    [params]
  );
  const tableId = useMemo(
    () => (_.isUndefined(params.tableId) ? null : Number(params.tableId)),
    [params]
  );

  const { data: table } = useTableForCompare({
    tableId,
    enabled: !_.isNil(tableId),
  });

  const handleSaveTable = useCallback(
    ({ tableId: newTableId }) => {
      toast.successMessage("Table created successfully");

      if (!_.isNil(dealId)) {
        navigate(
          reverse(URLS.DEAL_TABLE_STORAGE_DETAILS, {
            dealId,
            tableId: newTableId,
          })
        );
      } else {
        navigate(reverse(URLS.TABLE_STORAGE_DETAILS, { tableId: newTableId }));
      }
    },
    [dealId, navigate]
  );

  const handleRemoveSelectedTable = useCallback(
    ({ tableId }: { tableId: number }) => {
      setSelectedTables((prevSelectedTables) =>
        prevSelectedTables.filter((table) => table.id !== tableId)
      );
    },
    []
  );

  const uniqueTableIds = useMemo(() => {
    if (_.isNil(tableId)) {
      return _.uniq(selectedTables.map((table) => table.id));
    }

    return _.uniq([tableId, ...selectedTables.map((table) => table.id)]);
  }, [tableId, selectedTables]);

  const handleGroupColumnsForMultiTables = useCallback(() => {
    showLoading();
    getGroupedColumns({
      tableIds: uniqueTableIds,
    })
      .then(({ table_headers }: { table_headers: Array<Array<string>> }) =>
        setGroupedColumns(table_headers)
      )
      .catch(handleInvalidRequest)
      .finally(hideLoading);
  }, [uniqueTableIds, showLoading, hideLoading]);

  const handleCompareTables = useCallback(() => {
    showLoading();

    compareTables({
      tableIds: uniqueTableIds,
      groupedColumns,
    })
      .then(({ rows, headers }: any) => {
        const tableData = [headers, ...rows].map((row) => ({
          cells: row.map((cell: any) => ({ value: cell })),
        }));
        setTableData(tableData);
      })
      .catch(handleInvalidRequest)
      .finally(hideLoading);
  }, [uniqueTableIds, groupedColumns, showLoading, hideLoading]);

  const groupedColumnsIsEmpty = useMemo(
    () => _.isEmpty(groupedColumns),
    [groupedColumns]
  );

  const backLink = useMemo(() => {
    if (_.isNil(tableId)) {
      return URLS.TABLE_STORAGE_LIST;
    }

    if (!_.isNil(dealId) && !_.isNil(tableId)) {
      return reverse(URLS.DEAL_TABLE_STORAGE_DETAILS, { dealId, tableId });
    }

    return reverse(URLS.TABLE_STORAGE_DETAILS, { tableId });
  }, [dealId, tableId]);

  const backTitle = useMemo(() => {
    if (!_.isNil(tableId) && !_.isUndefined(table)) {
      return "Table details";
    }

    return "Company-level data tables";
  }, [tableId, table]);

  const onCancelCompareTables = useCallback(
    () => navigate(backLink),
    [navigate, backLink]
  );

  const pageTitle = useMemo(() => {
    if (!_.isNil(tableId) && !_.isUndefined(table)) {
      return `${table.name} - Compare`;
    }

    return "Compare tables";
  }, [tableId, table]);

  const handleUpdateTableData = useCallback(
    ({ key, currentValue }: { key: string; currentValue: string | null }) =>
      setChangedCells((prev) => ({
        ...prev,
        [key]: currentValue,
      })),
    []
  );

  if (!_.isNil(tableId) && _.isUndefined(table)) {
    return null;
  }

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          spacing={4}
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader
            title={pageTitle}
            backTitle={backTitle}
            backLink={backLink}
          />
          <Stack spacing={2}>
            <Text variant="text1" color={colors.gray80} fontWeight={700}>
              Selected tables
            </Text>
            {!_.isNil(tableId) && !_.isUndefined(table) && (
              <>
                <Box>
                  <Text variant="text1">{table.name}</Text>
                  {!_.isNil(table.deal) && (
                    <Text variant="text2">Deal: {table.deal?.name}</Text>
                  )}
                </Box>
                <Table tableData={table.rows} />
              </>
            )}
            <Stack spacing={2}>
              <Box sx={{ width: "300px" }}>
                {groupedColumnsIsEmpty && (
                  <TablesAutocomplete
                    currentSelectedTables={selectedTables}
                    setSelectedTables={({ tables }) =>
                      setSelectedTables(tables)
                    }
                  />
                )}
              </Box>
              {selectedTables.map((table) => (
                <SelectedTable
                  key={table.id}
                  table={table}
                  onRemoveSelectedTable={handleRemoveSelectedTable}
                  showRemoveButton={groupedColumnsIsEmpty}
                />
              ))}
              {!_.isEmpty(selectedTables) && (
                <Box>
                  {_.isEmpty(groupedColumns) ? (
                    <Button
                      onClick={handleGroupColumnsForMultiTables}
                      sx={{ alignSelf: "flex-start" }}
                    >
                      Ok, get grouped columns
                    </Button>
                  ) : (
                    <Box>
                      <Text
                        variant="text1"
                        color={colors.gray80}
                        fontWeight={700}
                      >
                        Grouped columns are
                      </Text>
                      <ul>
                        {groupedColumns.map((group, index) => (
                          <li key={index}>{group.join(", ")}</li>
                        ))}
                      </ul>
                      {_.isEmpty(tableData) && (
                        <Stack direction="row" spacing={1}>
                          <Button
                            onClick={onCancelCompareTables}
                            sx={{ alignSelf: "flex-start" }}
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleCompareTables}
                            sx={{ alignSelf: "flex-start" }}
                          >
                            Ok, compare these tables
                          </Button>
                        </Stack>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Stack>
            {!_.isEmpty(tableData) && (
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Text variant="text1" color={colors.gray80} fontWeight={700}>
                  Compared table
                </Text>
                <Table
                  editMode
                  showFilterCheckboxes
                  disabledIndexRows={disabledIndexRows}
                  disabledIndexColumns={disabledIndexColumns}
                  setDisabledIndexRows={setDisabledIndexRows}
                  setDisabledIndexColumns={setDisabledIndexColumns}
                  tableData={tableData}
                  updateTableData={handleUpdateTableData}
                />
                <Stack direction="row" spacing={1} alignSelf="flex-end">
                  <Button
                    onClick={onCancelCompareTables}
                    sx={{ alignSelf: "flex-start" }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <SaveComparedTablesButton
                    onSave={handleSaveTable}
                    dealId={dealId}
                    tableData={tableData}
                    disabledIndexRows={disabledIndexRows}
                    disabledIndexColumns={disabledIndexColumns}
                    changedCells={changedCells}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TablesCompare;

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

interface IDueDiligenceTaskReroute {}

const DueDiligenceTaskReroute: React.FC<IDueDiligenceTaskReroute> = () => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
    categoryId: string;
  }>();
  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId = Number(params.tabId);
  const categoryId = Number(params.categoryId);

  const navigate = useNavigate();

  useEffect(
    () =>
      navigate(
        reverse(URLS.DEAL_PACKAGE_TASK, {
          dealId,
          packageId,
          tabId,
          categoryId,
        })
      ),
    [dealId, packageId, tabId, categoryId, navigate]
  );

  return null;
};

export default DueDiligenceTaskReroute;

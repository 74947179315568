import Arc from "components/DonutChart/Arc";
import Legend from "components/DonutChart/Legend";

interface IDonutChart {
  width: number;
  borderWidth?: number;
  data: Array<{
    value: number;
    color: string;
  }>;
}

const DonutChart = ({ width, borderWidth = 50, data }: IDonutChart) => {
  const height = width;

  const total = data.reduce((res, current) => res + current.value, 0);

  const { dataWithArc } = data.reduce(
    ({ angle, dataWithArc }, currentItem) => ({
      angle: angle + (currentItem.value / total) * 360,
      dataWithArc: [...dataWithArc, { angle, ...currentItem }],
    }),
    {
      angle: -90,
      dataWithArc: [] as Array<{ angle: number; value: number; color: string }>,
    }
  );

  return (
    <svg style={{ height, width }} viewBox={`0 0 ${width} ${height}`}>
      {dataWithArc.map(({ angle, value, color }, index) => (
        <Arc
          key={index}
          angle={angle}
          graphWidth={width}
          borderWidth={borderWidth}
          total={total}
          value={value}
          color={color}
        />
      ))}
    </svg>
  );
};

export { Legend };

export default DonutChart;

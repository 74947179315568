import _ from "lodash";

import Paper from "components/Paper";
import Stack from "components/Stack";

import ProformaFloorArea from "entities/Proforma/components/ProformaFloorArea";
import ProformaNotEnoughDataPresent from "entities/Proforma/components/ProformaNotEnoughDataPresent";
import ProformaRentalIncome from "entities/Proforma/components/ProformaRentalIncome";
import ProformaRentalIncomeUnitMix from "entities/Proforma/components/ProformaRentalIncomeUnitMix";
import ProformaRentComparisons from "entities/Proforma/components/ProformaRentComparisons";
import ProformaUnitTypeFloorArea from "entities/Proforma/components/ProformaUnitTypeFloorArea";
import ProfromaUnitMixAndRent from "entities/Proforma/components/ProfromaUnitMixAndRent";
import { useRentalData } from "entities/Proforma/sdk";

interface IProformaRentalIncomeWrapper {
  dealId: number;
  totalUnits: number;
}

const ProformaRentalIncomeWrapper: React.FC<IProformaRentalIncomeWrapper> = ({
  dealId,
  totalUnits,
}) => {
  const { data: rentalIncomeData } = useRentalData({ dealId });

  if (
    _.isNil(rentalIncomeData) ||
    _.isNil(rentalIncomeData.rental_data.unit_type_and_floor_area) ||
    _.isNil(
      rentalIncomeData.rental_data?.new_unit_mix_and_rent_and_floor_area?.data
    )
  ) {
    return <ProformaNotEnoughDataPresent />;
  }

  return (
    <Stack spacing={4}>
      <Paper data-testid="unit-mix-and-rent-section">
        <ProfromaUnitMixAndRent rentalIncomeData={rentalIncomeData} />
      </Paper>
      <Paper data-testid="rental-income-unit-mix-section">
        <ProformaRentalIncomeUnitMix
          rentalIncomeData={rentalIncomeData}
          totalUnits={totalUnits}
        />
      </Paper>
      <Paper data-testid="rental-income-section">
        <ProformaRentalIncome rentalIncomeData={rentalIncomeData} />
      </Paper>
      <Paper data-testid="floor-area-section">
        <ProformaFloorArea rentalIncomeData={rentalIncomeData} />
      </Paper>
      <Paper data-testid="rental-comparisons-section">
        <ProformaRentComparisons rentalIncomeData={rentalIncomeData} />
      </Paper>
      <Paper data-testid="unit-type-floor-area-section">
        <ProformaUnitTypeFloorArea rentalIncomeData={rentalIncomeData} />
      </Paper>
    </Stack>
  );
};

export default ProformaRentalIncomeWrapper;

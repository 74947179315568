import { useCallback } from "react";

import { useCaching } from "utils/common";

import { MenuItem } from "components/Menu";
import Text from "components/Text";
import toast from "components/Toast";

import { IMemoSection, memoSectionNAStatusUpdate } from "entities/Memo/sdk";

export interface INAStatusMenuItem {
  section: IMemoSection;
  dealId: number;
  onUpdateNAStatus?: () => void;
}

const NAStatusMenuItem = ({
  section,
  dealId,
  onUpdateNAStatus,
}: INAStatusMenuItem) => {
  const [status, setStatus] = useCaching(section.is_na || false);

  const handleMarkAsNA = useCallback(() => {
    memoSectionNAStatusUpdate({
      dealId,
      memoSectionId: section.section_id,
      naStatus: !section.is_na,
    }).then(() => {
      setStatus(!section.is_na);
      toast.successMessage(
        `The section was successfully ${
          !section.is_na ? "disabled" : "enabled"
        }!`
      );
      onUpdateNAStatus && onUpdateNAStatus();
    });
  }, [dealId, section, setStatus, onUpdateNAStatus]);

  return (
    <MenuItem onClick={handleMarkAsNA}>
      <Text variant="text2">
        {status ? "Revoke N/A status" : "Mark as N/A"}
      </Text>
    </MenuItem>
  );
};

export default NAStatusMenuItem;

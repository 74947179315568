import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

import Illustration from "./assets/image.svg";

export interface IConnectionModificationBanner {}

const ConnectionModificationBanner = (_: IConnectionModificationBanner) => (
  <Stack
    padding={2}
    spacing={4.5}
    direction="row"
    borderRadius="8px"
    alignItems="center"
    sx={{ backgroundColor: colors.gray10 }}
  >
    <img src={Illustration} alt="" />
    <Stack>
      <Text variant="text2" color={colors.gray120} fontWeight={700}>
        How to edit connections
      </Text>
      <Text variant="text4" color={colors.gray100}>
        We are working on making this feature available to you. In the meantime,
        please reach out to our Customer Success Team to request the
        modifications you need to this checklist template.
      </Text>
    </Stack>
  </Stack>
);

export default ConnectionModificationBanner;

import React from "react";

import SectionContentLabelValue from "entities/Memo/components/Sections/SectionContentLabelValue";

interface ITextfield {
  label: string;
  value: string;
}

const Textfield = ({ label, value }: ITextfield) => {
  return <SectionContentLabelValue label={label} value={value} />;
};

export default Textfield;

import React from "react";

import { ENTER_CODE } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import TextField from "components/TextField";

interface IChatInputField {
  onSubmit: (text: string) => Promise<any>;
}

const ChatInputField: React.FC<IChatInputField> = ({ onSubmit }) => {
  const handleKeyPress = (e: any) => {
    if (!e.shiftKey && e.which === ENTER_CODE) {
      if (e.target.value) {
        onSubmit(e.target.value)
          .then(() => (e.target.value = ""))
          .catch(handleInvalidRequest);
      }
      e.preventDefault();
    }
  };

  return (
    <TextField
      autoFocus
      multiline
      placeholder="Type a message here..."
      rows={4}
      sx={{
        width: "100%",
      }}
      onKeyPress={handleKeyPress}
    />
  );
};

export default ChatInputField;

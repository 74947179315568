import React, { useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { formatNumberToString } from "utils/common";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IProforma } from "entities/Proforma/sdk";

export interface IProformaDevelopmentBudget {
  proforma: IProforma;
}

const costsHeaders: Array<{ key: string; name: string }> = [
  { key: "costs", name: "costs" },
  { key: "total", name: "total" },
  { key: "perunit", name: "per unit" },
  { key: "persqf", name: "per sqf" },
];

const ProformaDevelopmentBudget: React.FC<IProformaDevelopmentBudget> = ({
  proforma,
}) => {
  const costsRows = useMemo(() => {
    return proforma.budget_data
      ? [
          {
            costs: "Acquisition Costs",
            total:
              proforma.budget_data?.total_acquisition_cost?.value.toLocaleString(),
            perunit: formatNumberToString({
              number:
                proforma.budget_data?.total_acquisition_cost?.value /
                proforma.core_data.total_units,
            }),
            persqf: formatNumberToString({
              number:
                proforma.budget_data?.total_acquisition_cost?.value /
                proforma.core_data.total_s_f,
            }),
          },
          {
            costs: "Hard Costs",
            total:
              proforma.budget_data?.total_hard_cost?.value.toLocaleString(),
            perunit: formatNumberToString({
              number:
                proforma.budget_data?.total_hard_cost?.value /
                proforma.core_data.total_units,
            }),
            persqf: formatNumberToString({
              number:
                proforma.budget_data?.total_hard_cost?.value /
                proforma.core_data.total_s_f,
            }),
          },
          {
            costs: "Hard Cost Contingency",
            total:
              proforma.budget_data.hard_cost_contingency.value.toLocaleString(),
            perunit: formatNumberToString({
              number:
                proforma.budget_data.hard_cost_contingency.value /
                proforma.core_data.total_units,
            }),
            persqf: formatNumberToString({
              number:
                proforma.budget_data.hard_cost_contingency.value /
                proforma.core_data.total_s_f,
            }),
          },
          {
            costs: "Soft Costs",
            total: proforma.budget_data.total_soft_cost.value.toLocaleString(),
            perunit: formatNumberToString({
              number:
                proforma.budget_data.total_soft_cost.value /
                proforma.core_data.total_units,
            }),
            persqf: formatNumberToString({
              number:
                proforma.budget_data.total_soft_cost.value /
                proforma.core_data.total_s_f,
            }),
          },
          {
            costs: "Soft Cost Contingency",
            total:
              proforma.budget_data.soft_cost_contingency.value.toLocaleString(),
            perunit: formatNumberToString({
              number:
                proforma.budget_data.soft_cost_contingency.value /
                proforma.core_data.total_units,
            }),
            persqf: formatNumberToString({
              number:
                proforma.budget_data.soft_cost_contingency.value /
                proforma.core_data.total_s_f,
            }),
          },
          {
            costs: "Developer Fees",
            total:
              proforma.budget_data.total_developer_fees.value.toLocaleString(),
            perunit: formatNumberToString({
              number:
                proforma.budget_data.total_developer_fees.value /
                proforma.core_data.total_units,
            }),
            persqf: formatNumberToString({
              number:
                proforma.budget_data.total_developer_fees.value /
                proforma.core_data.total_s_f,
            }),
          },
        ]
      : [];
  }, [proforma.budget_data, proforma.core_data]);

  const tableTotals = proforma.core_data
    ? {
        costs: "Total Development Costs (TDC)",
        total: formatNumberToString({
          number: Number(proforma.budget_data?.total_development_budget),
        }), // this is a string from the backend
        perunit: formatNumberToString({
          number:
            proforma.budget_data?.total_development_budget /
            proforma.core_data.total_units,
        }),
        persqf: formatNumberToString({
          number:
            proforma.budget_data?.total_development_budget /
            proforma.core_data.total_s_f,
        }),
      }
    : { costs: "N/A", total: "N/A", perunit: "N/A", persqf: "N/A" };

  const chartData = useMemo(
    () =>
      proforma.budget_data
        ? [
            {
              value: formatNumberToString({
                number:
                  proforma.budget_data.total_acquisition_cost
                    .percentage_of_total,
              }),
              color: colors.blue10,
            },
            {
              value: formatNumberToString({
                number:
                  proforma.budget_data.total_hard_cost.percentage_of_total,
              }),
              color: colors.blue20,
            },
            {
              value: formatNumberToString({
                number:
                  proforma.budget_data.hard_cost_contingency
                    .percentage_of_total,
              }),
              color: colors.blue40,
            },
            {
              value: formatNumberToString({
                number:
                  proforma.budget_data.total_soft_cost.percentage_of_total,
              }),
              color: colors.blue60,
            },
            {
              value: formatNumberToString({
                number:
                  proforma.budget_data.soft_cost_contingency
                    .percentage_of_total,
              }),
              color: colors.blue80,
            },
            {
              value: formatNumberToString({
                number:
                  proforma.budget_data.total_developer_fees.percentage_of_total,
              }),
              color: colors.blue100,
            },
          ]
        : [],
    [proforma.budget_data]
  );

  const totals = useMemo(
    () =>
      proforma.budget_data
        ? [
            {
              label: "Total",
              value: proforma.budget_data.total_development_budget,
              type: ProformaHeaderTypeTotal.PRICE,
            },
            {
              label: "Per Unit",
              value:
                proforma.budget_data.total_development_budget /
                proforma.core_data.total_units,
              type: ProformaHeaderTypeTotal.PRICE,
            },
            {
              label: "Per Sqf",
              value:
                proforma.budget_data.total_development_budget /
                proforma.core_data.total_s_f,
              type: ProformaHeaderTypeTotal.PRICE,
            },
          ]
        : [],
    [proforma.budget_data, proforma.core_data]
  );

  return (
    <ProformaPaper
      title="Development Budget"
      subTitle="Total Development Costs (TDC)"
      totals={totals}
    >
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Stack alignItems="end" flex={1} spacing={1} justifyContent="center">
          {chartData.map(({ value, color }, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={1.5}
              sx={{ width: "100%" }}
              justifyContent="flex-end"
            >
              <Text variant="label" fontWeight="700" alignSelf="center">
                {value}%
              </Text>
              <Box
                sx={{
                  width: `${value}%`,
                  height: 25,
                  backgroundColor: color,
                }}
              />
            </Stack>
          ))}
        </Stack>
        <Stack flex={2}>
          <Box
            sx={{
              margin: theme.spacing(3, 0),
            }}
          >
            <ProformaTable
              rows={costsRows}
              headers={costsHeaders}
              totals={tableTotals}
              firstCellWidth="140px"
              cellsWidth="90px"
              lastCellWidth="74px"
            />
          </Box>
        </Stack>
      </Stack>
    </ProformaPaper>
  );
};

export default ProformaDevelopmentBudget;

import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import Stack from "components/Stack";
import TextField from "components/TextField";

const VALIDATION_SCHEMA = yup.object({
  email: yup.string().email().required("Email is required!"),
});

const initialValues = {
  email: "",
};

interface IFormValues {
  email: string;
}

interface IAddNewTeamMemberForm {
  onSubmit: (data: IFormValues) => void;
  onCancel: () => void;
}

const AddNewTeamMemberForm = ({
  onSubmit,
  onCancel,
}: IAddNewTeamMemberForm) => {
  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />

      <Stack spacing={3}>
        <TextField
          label="Member's email"
          InputLabelProps={{ shrink: true }}
          {...formik.getFieldProps("email")}
          error={formik.touched.email && !!formik.errors.email}
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""
          }
          data-testid="user-email"
          inputProps={{ "data-testid": "user-email-input" }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        <Button
          variant="text"
          size="large"
          onClick={onCancel}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button type="submit" size="large" data-testid="submit-button">
          Add New Team Member
        </Button>
      </Stack>
    </form>
  );
};

export default AddNewTeamMemberForm;

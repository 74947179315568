import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import {
  ArrowForwardIosIcon,
  CancelIcon,
  CheckCircleIcon,
  DownloadingIcon,
  InsertDriveFileOutlinedIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS } from "utils/datetime";
import { isPdfByFileName } from "utils/file";
import { reverse } from "utils/urls";

import { LinearProgress } from "components/Loading";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { useAssetManagementFileProcessingStatusUpdates } from "entities/AssetManagement/hooks";
import { EImportStatus, IPDF, useAMPdfs } from "entities/AssetManagement/sdk";

const MapStatusColor = {
  [EImportStatus.COMPLETED]: colors.green100,
  [EImportStatus.FAILED]: colors.red120,
  [EImportStatus.IN_PROGRESS]: colors.yellow120,
};

const AssetManagementPdfs = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const navigate = useNavigate();
  usePageTitle("Builders Patch: Asset Management PDF List");

  const {
    data: pdfs,
    isValidating,
    mutate: refetchPDFs,
  } = useAMPdfs({ dealId });

  useAssetManagementFileProcessingStatusUpdates({
    successEventHandler: () => refetchPDFs(),
    failureEventHandler: refetchPDFs,
  });

  const canNavigateToDiff = useCallback(
    ({ pdf }: { pdf: IPDF }) =>
      isPdfByFileName({ filename: pdf.file.name }) &&
      pdf.status === EImportStatus.COMPLETED,
    []
  );

  const navigateToDiff = useCallback(
    ({ pdf }: { pdf: IPDF }) => {
      if (canNavigateToDiff({ pdf })) {
        navigate(
          reverse(URLS.DEAL_ASSET_MANAGEMENT_PDF_DIFF, {
            pdfId: pdf.id,
            dealId,
          })
        );
      }
    },
    [dealId, navigate, canNavigateToDiff]
  );

  return (
    <Paper
      sx={{
        padding: theme.spacing(3, 4),
      }}
    >
      <PageHeader
        backLink={reverse(URLS.DEAL_ASSET_MANAGEMENT, { dealId })}
        backTitle="Back to Asset Management"
        title="Asset management PDF List"
      />
      <Table
        sx={{ position: "relative", marginTop: 3 }}
        data-testid="am-pdf-files-list-table"
      >
        <TableHead data-testid="table-headers-section">
          <TableCell data-testid="table-header">File name</TableCell>
          <TableCell data-testid="table-header">Processing status</TableCell>
          <TableCell data-testid="table-header">Uploaded at</TableCell>
          <TableCell data-testid="table-header">Uploaded by</TableCell>
          <TableCell data-testid="table-header-view"></TableCell>
        </TableHead>

        {isValidating && (
          <LinearProgress sx={{ position: "absolute", width: "100%" }} />
        )}

        <TableBody data-testid="table-body">
          {_.isEmpty(pdfs) || _.isNil(pdfs) ? (
            <TableRow data-testid="am-empty-list-state">
              <TableCell colSpan={5}>
                <Text
                  variant="text1"
                  align="center"
                  data-testid="empty-files-list-message"
                >
                  No uploaded files yet.
                </Text>
              </TableCell>
            </TableRow>
          ) : (
            pdfs.map((pdf) => (
              <TableRow
                hover
                key={pdf.id}
                onClick={() => navigateToDiff({ pdf })}
                sx={{
                  cursor: canNavigateToDiff({ pdf }) ? "pointer" : "auto",
                }}
                data-testid="am-pdf-entity"
              >
                <TableCell>
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <InsertDriveFileOutlinedIcon data-testid="file-icon" />

                    <Text
                      variant="text2"
                      fontWeight={500}
                      data-testid="pdf-name"
                    >
                      {pdf.file.name}
                    </Text>
                  </Stack>
                </TableCell>

                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    data-testid="pdf-import-status"
                  >
                    {pdf.status === EImportStatus.COMPLETED && (
                      <CheckCircleIcon
                        sx={{ color: colors.green100 }}
                        fontSize="small"
                        data-testid="completed-status-icon"
                      />
                    )}
                    {pdf.status === EImportStatus.FAILED && (
                      <CancelIcon
                        sx={{ color: colors.red120 }}
                        fontSize="small"
                        data-testid="failed-status-icon"
                      />
                    )}
                    {pdf.status === EImportStatus.IN_PROGRESS && (
                      <DownloadingIcon
                        sx={{ color: colors.yellow120 }}
                        fontSize="small"
                        data-testid="in-progress-status-icon"
                      />
                    )}

                    <Text
                      variant="text3"
                      color={MapStatusColor[pdf.status]}
                      fontWeight={500}
                      data-testid="status-text"
                    >
                      {pdf.status}
                    </Text>
                  </Stack>
                </TableCell>

                <TableCell data-testid="pdf-import-datetime">
                  {format(new Date(pdf.created_at), FORMATS.FRONTEND.DATETIME)}
                </TableCell>

                <TableCell data-testid="uploaded-by-user-name">
                  {pdf.created_by.first_name} {pdf.created_by.last_name}
                </TableCell>

                <TableCell align="right" data-testid="preview-pdf-import-cell">
                  {canNavigateToDiff({ pdf }) && (
                    <ArrowForwardIosIcon
                      sx={{ fontSize: "15px" }}
                      data-testid="preview-pdf-import-button"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default AssetManagementPdfs;

import React, { useCallback, useEffect, useMemo, useState } from "react";

import ScrollableTable from "components/ScrollableTable";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import {
  dateInfoToString,
  getAMTableHeaders,
  getTableRows,
} from "entities/AssetManagement/components/AssetManagementTable/utils";
import {
  AMColumn,
  AMResolution,
  AssetManagementTabItems,
} from "entities/AssetManagement/constants";
import {
  IAMDateInfo,
  IAssetManagementFetchData,
  IDiff,
} from "entities/AssetManagement/sdk";

interface IAssetManagementTable {
  selectedTab: AssetManagementTabItems;
  columns: Array<AMColumn>;
  showSubItems: boolean;
  loading: boolean;
  data: IAssetManagementFetchData;
  rowDelete: (code: string, name: string) => void;
  itemDelete: (itemId: number) => void;
  editMode: boolean;
  resolution: AMResolution;
  diff?: IDiff;
}

const AssetManagementTable: React.FC<IAssetManagementTable> = ({
  selectedTab,
  columns,
  showSubItems,
  loading,
  data,
  rowDelete,
  itemDelete,
  editMode,
  resolution,
  diff,
}) => {
  const [hiddenItems, setHiddenItems] = useState<Array<string | null>>([]);

  const { show: showConfirmationDialog } = useConfirmationDialog();

  useEffect(() => {
    if (showSubItems) {
      setHiddenItems([]);
    } else {
      setHiddenItems(
        data.data[selectedTab].map((subCategory) => subCategory.key)
      );
    }
  }, [data, showSubItems, setHiddenItems, selectedTab]);

  const deleteRow = useCallback(
    ({ itemCode, name }: { itemCode: string; name: string }) => {
      showConfirmationDialog({
        onConfirm: () => rowDelete(itemCode, name),
        message: `Are you sure you want to delete "${name}" row?`,
      });
    },
    [rowDelete, showConfirmationDialog]
  );

  const deleteItem = useCallback(
    ({
      itemCode,
      type,
      dateInfo,
      itemId,
    }: {
      itemCode: string;
      type: string;
      dateInfo: IAMDateInfo;
      itemId: number;
    }) => {
      showConfirmationDialog({
        onConfirm: () => itemDelete(itemId),
        message: `Are you sure you want to delete ${itemCode} ${type} for ${dateInfoToString(
          dateInfo
        )}?`,
      });
    },
    [itemDelete, showConfirmationDialog]
  );

  const headers = useMemo(
    () =>
      getAMTableHeaders({
        headers: data.headers,
        columns,
        selectedTab,
        editMode,
        resolution,
        deleteRow,
        deleteItem,
        diff,
      }),
    [
      data,
      columns,
      selectedTab,
      editMode,
      resolution,
      deleteRow,
      deleteItem,
      diff,
    ]
  );

  const rows = useMemo(
    () =>
      getTableRows({
        data: data.data,
        key: selectedTab,
        showSubItems,
        hiddenItems,
        columns,
      }),
    [data, columns, selectedTab, hiddenItems, showSubItems]
  );

  if (!rows || !headers) {
    return null;
  }

  return (
    <ScrollableTable
      dynamicHyphen
      showLoading={loading}
      headers={headers}
      rows={rows}
      tableDataTestid="am-table"
      rowDataTestid="table-row"
      rowCellDataTestid="total-subcategory-cell"
      childRowCellDataTestid="item-subcategory-cell"
    />
  );
};

export default AssetManagementTable;

import React, { useCallback } from "react";
import { useFormik } from "formik";

import CheckboxTree from "components/CheckboxTree";

import { ConstructionType } from "entities/Deal/constants";

const CONSTRUCTION_TYPE_CHECKBOXES = [
  {
    label: "New Construction",
    value: ConstructionType.NEW_CONSTRUCTION,
  },
  {
    label: "Moderate Rehabilitation",
    value: ConstructionType.MODERATE_REHABILITATION,
  },
  {
    label: "Substantial Rehabilitation",
    value: ConstructionType.SUBSTANTIAL_REHABILITATION,
  },
];

interface IFormConstructionType {
  formik: ReturnType<typeof useFormik>;
}

const FORM_VALUE_KEY = "construction_type";

const FormConstructionType: React.FC<IFormConstructionType> = ({ formik }) => {
  const handleChange = useCallback(
    (value: Array<string | number>) =>
      formik.setFieldValue(FORM_VALUE_KEY, value),
    [formik]
  );

  return (
    <CheckboxTree
      label="Construction type"
      options={CONSTRUCTION_TYPE_CHECKBOXES}
      fieldInputProps={formik.getFieldProps(FORM_VALUE_KEY)}
      onChange={handleChange}
      value={formik.values[FORM_VALUE_KEY]}
    />
  );
};

export default FormConstructionType;

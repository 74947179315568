import { MoreHorizIcon } from "icons";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import { TableCell } from "components/Table";

import AllTasksDetailButton from "entities/Task/components/AllTasksDetailButton";
import WorkspaceTaskDetailButton from "entities/Task/components/WorkspaceTaskDetailButton";

interface IMenuCell {
  isInDealContext: boolean;
  taskChannelId: number;
  dealId: number;
  refreshTasksList: () => void;
}

const MenuCell = ({
  isInDealContext,
  taskChannelId,
  dealId,
  refreshTasksList,
}: IMenuCell) => {
  return (
    <TableCell
      align="center"
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      <ButtonDropdown
        icon={<MoreHorizIcon />}
        label=""
        variant="text"
        width="auto"
        buttonDataTestid="user-admin-rights-edit-button"
      >
        {isInDealContext ? (
          <WorkspaceTaskDetailButton
            refreshTasksList={refreshTasksList}
            taskChannelId={taskChannelId}
            dealId={dealId}
          />
        ) : (
          <AllTasksDetailButton
            refreshTasksList={refreshTasksList}
            taskChannelId={taskChannelId}
            dealId={dealId}
          />
        )}
      </ButtonDropdown>
    </TableCell>
  );
};

export default MenuCell;

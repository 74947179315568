import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { googleMapsGetStaticMapUrl } from "utils/maps";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";

import LocationEditButton from "entities/Deal/components/OverviewLocation/LocationEditButton";
import LocationTable from "entities/Deal/components/OverviewLocation/LocationTable";
import { getGeoLocation, IDeal, IMarker } from "entities/Deal/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";

const SectionLocation = ({
  deal,
  sectionName = "",
  section,
  externalToken,
  onUpdate,
}: {
  deal: IDeal;
  sectionName?: string;
  section?: IMemoSection;
  externalToken?: string;
  onUpdate?: () => void;
}) => {
  const [markers, setMarkers] = useState<IMarker[]>([]);

  useEffect(() => {
    getGeoLocation(
      `${deal.address.street_address}, ${deal.address.city}, ${deal.address.zipcode}`
    ).then(({ results }: any) => {
      const resultMarkers: IMarker[] = results.map((res: any) => ({
        center: res.geometry.location,
        contentdata: {
          project_name: deal.name,
          address: res.formatted_address,
          thumbnail: deal.thumbnail,
        },
      }));

      setMarkers(resultMarkers);
    });
  }, [deal]);

  const mapUrl = useMemo(
    () => (_.isEmpty(markers) ? "" : googleMapsGetStaticMapUrl({ markers })),
    [markers]
  );

  const isNASection = useMemo(() => section?.is_na, [section]);

  return (
    <Box>
      <SectionHeader
        label={section?.title || sectionName}
        alignActionButton={section ? "right" : "left"}
        actionButton={
          _.isUndefined(externalToken) && (
            <NoPrint>
              <Stack direction="row" spacing={1} alignItems="center">
                {!isNASection && <LocationEditButton />}
                {section && (
                  <MemoSectionContextButton
                    dealId={deal.id}
                    section={section}
                    onUpdate={onUpdate}
                  />
                )}
              </Stack>
            </NoPrint>
          )
        }
      />
      <SectionContent breakInside="avoid">
        {isNASection ? (
          <NASection />
        ) : (
          <>
            {deal.address && <LocationTable address={deal.address} />}
            <Box
              id="map-image"
              sx={{
                height: "400px",
                breakInside: "avoid",
                marginTop: theme.spacing(1),
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${mapUrl})`,
              }}
            />
          </>
        )}
      </SectionContent>
    </Box>
  );
};

export default SectionLocation;

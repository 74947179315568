import React from "react";
import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import ProjectEditDialog from "entities/Deal/components/OverviewProject/ProjectEditDialog";

export interface IProjectEditButton {}

const ProjectEditButton: React.FC<IProjectEditButton> = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        data-testid="edit-project-section-button"
      >
        <EditIcon />
      </IconButton>
      <ProjectEditDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default ProjectEditButton;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { CheckIcon, EditIcon } from "icons";
import { colors } from "theme/palette";

import Box from "components/Box";
import IconButton from "components/IconButton";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Text from "components/Text";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import FormTextInput from "entities/Field/components/FormTextField/FormText";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";

interface ITextfield {
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
}

const MemoFormTextField = ({
  field,
  dealId,
  onUpdate,
  isNASection,
  externalToken,
}: ITextfield) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleEditButtonClick = useCallback(
    () => setIsEdit((prev) => !prev),
    []
  );

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showEditButton = useMemo(
    () => !isNAField && _.isUndefined(externalToken),
    [isNAField, externalToken]
  );

  return (
    <Box sx={{ borderBottom: `1px solid ${colors.blue20}` }}>
      <Stack direction="row" alignItems="center" data-testid="dd-field">
        <FieldTitle
          field={field}
          onUpdate={onUpdate}
          externalToken={externalToken}
          disabled={isNAField}
        />
        {showEditButton && (
          <NoPrint>
            <IconButton size="small" onClick={handleEditButtonClick}>
              {isEdit ? <CheckIcon /> : <EditIcon />}
            </IconButton>
          </NoPrint>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
      </Stack>
      {!isNAField && field.template_meta && field.template && (
        <Box paddingY={1}>
          <FormTextInput
            templateMeta={field.template_meta}
            template={field.template}
            edit={isEdit}
            dealId={dealId}
            onUpdate={onUpdate}
          />
        </Box>
      )}
    </Box>
  );
};

export default MemoFormTextField;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";

export interface ISidepanelFooter {
  children?: React.ReactNode;
}

const SidepanelFooter = ({ children }: ISidepanelFooter) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      padding: theme.spacing(4, 16, 4, 7),
      backgroundColor: colors.blue10,
      position: "sticky",
      top: 0,
      zIndex: 2,
    }}
  >
    {children}
  </Stack>
);

export default SidepanelFooter;

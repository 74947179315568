import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";

import Button from "components/Button";
import Paper from "components/Paper";
import Stack from "components/Stack";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import { usePageTitle } from "components/usePageTitle";

import AssetManagementTabs from "entities/AssetManagement/components/AssetManagementTabs";
import PageHeader from "entities/AssetManagement/components/PageHeader";
import AssetManagementTable from "entities/AssetManagement/components/Table";
import {
  AMColumn,
  AMResolution,
  AssetManagementTabItems,
} from "entities/AssetManagement/constants";
import { useAssetManagementExcelProcessingStatusUpdates } from "entities/AssetManagement/hooks";
import {
  updateAssetManagement,
  useAssetManagementData,
} from "entities/AssetManagement/sdk";
import { constructUpdatedDict } from "entities/AssetManagement/utils";

const TAB = "tab";

const AssetManagement = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  usePageTitle("Builders Patch: Asset Management");

  const [resolution, setResolution] = useState<AMResolution>(
    AMResolution.YEARLY
  );

  const [columns, setColumns] = useState<Array<AMColumn>>([
    AMColumn.BUDGETED,
    AMColumn.ACTUAL,
    AMColumn.AUDITED,
  ]);

  const [selectedTab, setSelectedTab] = useState<AssetManagementTabItems>(
    AssetManagementTabItems.INCOME
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showSubItems, setShowSubItems] = useState<boolean>(true);
  const [changedCells, setChangedCells] = useState<{
    [key: string]: {
      current_value: number | null;
      previous_value: number | null;
    };
  }>({});
  const [deletedRowKeys, setDeletedRowKeys] = useState<Array<string>>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data,
    isLoading: dataIsLoading,
    isValidating: dataIsValidating,
    mutate: updateAssetManagementData,
  } = useAssetManagementData({
    dealId,
  });

  const { show: showConfirmationDialog } = useConfirmationDialog();

  useEffect(() => {
    if (!editMode) {
      setChangedCells({});
      setDeletedRowKeys([]);
    }
  }, [editMode]);

  const handleChangeEditMode = useCallback(() => {
    if (editMode && !_.isEmpty(changedCells)) {
      showConfirmationDialog({
        onConfirm: () => {
          setEditMode(false);
        },
        confirmButtonText: "Discard",
        message: "Discard changes?",
      });
    } else {
      setEditMode((prev) => !prev);
    }
  }, [setEditMode, changedCells, showConfirmationDialog, editMode]);

  const handleCellChange = useCallback(
    ({
      itemKey,
      value,
      prevValue,
    }: {
      itemKey: string;
      value: number | null;
      prevValue: string | null;
    }) => {
      setChangedCells((prev) => ({
        ...prev,
        [itemKey]: {
          current_value: value,
          previous_value: prevValue ? parseFloat(prevValue || "") : null,
        },
      }));
    },
    [setChangedCells]
  );

  const handleSave = useCallback(() => {
    if (_.isUndefined(data)) {
      return;
    }

    const calculatedDiff = constructUpdatedDict({
      tableData: data,
      changedCells,
      deletedRowKeys,
      yearMappings: {},
    });

    updateAssetManagement({
      dealId,
      diff: calculatedDiff,
    })
      .then(() => {
        setEditMode(false);
        setChangedCells({});
        updateAssetManagementData();
        toast.successMessage("Data saved successfully");
      })
      .catch((errors) => {
        const errorMessage = errors?.message || errors?.detail;

        if (!_.isNil(errorMessage)) {
          toast.errorMessage(errorMessage);
        } else {
          errors?.forEach?.((error: unknown) => {
            if (_.isString(error)) {
              toast.errorMessage(error);
            }
          });
        }
      });
  }, [data, changedCells, dealId, deletedRowKeys, updateAssetManagementData]);

  const changeTab = useCallback(
    (tab: AssetManagementTabItems) => {
      const urlParameters: URLSearchParams = new URLSearchParams();
      urlParameters.set(TAB, tab);
      setSearchParams(urlParameters);
      setSelectedTab(tab);
    },
    [setSearchParams]
  );

  const handleTabSelect = useCallback(
    (tab: AssetManagementTabItems) => {
      if (editMode && !_.isEmpty(changedCells)) {
        showConfirmationDialog({
          onConfirm: () => {
            setChangedCells({});
            changeTab(tab);
          },
          confirmButtonText: "Discard",
          message: "Discard changes?",
        });
      } else {
        changeTab(tab);
      }
    },
    [editMode, changedCells, changeTab, showConfirmationDialog]
  );

  const isOfTypeTabs = useCallback(
    (keyInput: any): keyInput is AssetManagementTabItems => {
      return Object.values(AssetManagementTabItems).indexOf(keyInput) !== -1;
    },
    []
  );

  useEffect(() => {
    const tabInUrl = searchParams.get(TAB);
    if (tabInUrl && isOfTypeTabs(tabInUrl)) {
      setSelectedTab(tabInUrl);
    } else if (tabInUrl && !isOfTypeTabs(tabInUrl)) {
      setSelectedTab(AssetManagementTabItems.INCOME);
    }
  }, [searchParams, isOfTypeTabs]);

  const deleteRow = ({ rowKey }: { rowKey: string }) => {
    setDeletedRowKeys((prev) => [...prev, rowKey]);
  };

  // If the data is not undefined and data.rows and data.headers are not empty, then we have a proforma
  const hasProforma = useMemo(
    () =>
      !_.isUndefined(data) && !_.isEmpty(data.rows) && !_.isEmpty(data.headers),
    [data]
  );

  const handleSuccessUploadEvent = useCallback(() => {
    updateAssetManagementData();
    toast.successMessage("File processing has been completed successfully!");
  }, [updateAssetManagementData]);

  const handleFailedUploadEvent = useCallback(
    ({ detail }: { detail: string }) => toast.errorMessage(detail),
    []
  );

  useAssetManagementExcelProcessingStatusUpdates({
    successEventHandler: handleSuccessUploadEvent,
    failureEventHandler: handleFailedUploadEvent,
  });

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack spacing={3} direction="column">
        <PageHeader
          editMode={editMode}
          columns={columns}
          resolution={resolution}
          hasProforma={hasProforma}
          isLoading={dataIsLoading}
          onChangeEditMode={handleChangeEditMode}
          onChangeShowSubItems={() => setShowSubItems((prev) => !prev)}
          setColumns={setColumns}
          setResolution={setResolution}
        />
        <AssetManagementTabs
          selectedTab={selectedTab}
          onChange={handleTabSelect}
        />
      </Stack>
      {!_.isUndefined(data) && (
        <AssetManagementTable
          data={data}
          selectedTab={selectedTab}
          editMode={editMode}
          resolution={resolution}
          showSubItems={showSubItems}
          columns={columns}
          isLoading={dataIsValidating}
          onChange={handleCellChange}
          changedCells={changedCells}
          deletedRowKeys={deletedRowKeys}
          deleteRow={deleteRow}
          yearMappings={{}}
          updateYearMapping={() => {}}
        />
      )}
      {editMode && (
        <Button
          color="primary"
          onClick={handleSave}
          sx={{ marginTop: theme.spacing(2), alignSelf: "flex-end" }}
          data-testid="save-table-values-button"
        >
          Save
        </Button>
      )}
    </Paper>
  );
};

export default AssetManagement;

// This file is a copy of entities/Fields/Checkboxfield, but without all the functionality
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

import EditButton from "entities/Package/components/PdfImport/Fields/Checkboxfield/EditButton";
import FieldTitle from "entities/Package/components/PdfImport/Fields/Title";
import { IPackageField } from "entities/Package/sdk";

export interface ICheckboxfield {
  field: IPackageField;
  editable: boolean;
  parsedValue: string | undefined | null;
  onUpdate: ({
    fieldId,
    value,
  }: {
    fieldId: number;
    value: string | undefined | null;
  }) => void;
}
const Checkboxfield = ({
  field,
  parsedValue,
  editable,
  onUpdate,
}: ICheckboxfield) => {
  const fieldValue = useMemo(
    () => _.get(field.values, "[0].selected_value"),
    [field]
  );

  const [value, setValue] = useState<string[] | undefined | null>(null);

  useEffect(() => {
    if (!_.isNil(parsedValue)) {
      setValue(parsedValue.split(",").map((v) => v.trim()));
    }
  }, [parsedValue]);

  const handleSave = useCallback(
    (newValue: string[] | undefined | null) => {
      setValue(newValue);
      onUpdate({
        fieldId: field.field_id,
        value: _.isNil(newValue) ? newValue : newValue.join(", "),
      });
    },
    [field, setValue, onUpdate]
  );

  const handleCancel = useCallback(() => {
    if (!_.isNil(parsedValue)) {
      setValue(parsedValue.split(",").map((v) => v.trim()));
    } else {
      setValue(null);
    }
  }, [parsedValue, setValue]);

  const handleReset = useCallback(() => {
    setValue(null);
    onUpdate({
      fieldId: field.field_id,
      value: null,
    });
  }, [field, setValue, onUpdate]);

  const showFieldValue = useMemo(
    () => fieldValue && !value,
    [fieldValue, value]
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${colors.blue20}`,
      }}
      data-testid="dd-field"
    >
      <FieldTitle field={field} editable={editable} />
      <Stack direction="row" spacing={1} alignItems="center">
        {field.n_a_status ? (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        ) : (
          <>
            {showFieldValue && (
              <Text variant="text2" textAlign="right" data-testid="field-value">
                {fieldValue}
              </Text>
            )}
            {value && (
              <Text variant="text2" textAlign="right" data-testid="field-value">
                {value.join(", ")}
              </Text>
            )}
            {editable && (
              <EditButton
                field={field}
                initialValue={value}
                onSave={handleSave}
                onCancel={handleCancel}
                onReset={handleReset}
                buttonDataTestid="field-edit-button"
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(Checkboxfield);

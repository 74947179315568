import type { ITag } from "components/Tag";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";

export const COMPANY_SETTINGS_OPTIONS = [
  {
    value: ECompanySettingsFeatureType.AI_SUMMARY,
    label: "AI Summary",
  },
  {
    value: ECompanySettingsFeatureType.ASSET_MANAGEMENT_PROFORMA,
    label: "Asset Management Proforma",
  },
  {
    value: ECompanySettingsFeatureType.UNDERWRITING_PROFORMA,
    label: "Underwriting Proforma",
  },
  {
    value: ECompanySettingsFeatureType.CUSTOM_REPORTS,
    label: "Custom Reports",
  },
  {
    value: ECompanySettingsFeatureType.MEMO,
    label: "Memo",
  },
  {
    value: ECompanySettingsFeatureType.INTAKE_APPLICANT,
    label: "Intake Applicant",
  },
  {
    value: ECompanySettingsFeatureType.INTAKE_LENDER,
    label: "Intake Reviewer",
  },
  {
    value: ECompanySettingsFeatureType.ANALYTICS_DEVELOPMENT_BUDGET,
    label: "Analytics - Development Budget",
  },
  {
    value: ECompanySettingsFeatureType.ANALYTICS_INCOME_AND_OPEX,
    label: "Analytics - Income and OPEX",
  },
  {
    value: ECompanySettingsFeatureType.TABLES,
    label: "Company-level Data Tables",
  },
  {
    value: ECompanySettingsFeatureType.DEAL_TABLES,
    label: "Deal-level Data Tables",
  },
];

export enum ECompanySettingsFeatureVisibleType {
  DISABLED_HIDDEN = "disabled_hidden",
  DISABLED_VISIBLE = "disabled_visible",
  ENABLED = "enabled",
}

export const COMPANY_SETTINGS_FEATURE_VISIBLE_TYPES_OPTIONS = [
  {
    value: ECompanySettingsFeatureVisibleType.DISABLED_HIDDEN,
    label: "Disabled, Hidden",
  },
  {
    value: ECompanySettingsFeatureVisibleType.DISABLED_VISIBLE,
    label: "Disabled, Showcased",
  },
  {
    value: ECompanySettingsFeatureVisibleType.ENABLED,
    label: "Enabled",
  },
];

export const FEATURE_VISIBILITY_TO_TAG_PROPS_MAPPING: {
  [key: string]: {
    label: string;
    variant: ITag["variant"];
  };
} = {
  [ECompanySettingsFeatureVisibleType.DISABLED_HIDDEN]: {
    label: "Disabled: Hidden",
    variant: "gray20",
  },
  [ECompanySettingsFeatureVisibleType.DISABLED_VISIBLE]: {
    label: "Disabled: Shown",
    variant: "gray20",
  },
  [ECompanySettingsFeatureVisibleType.ENABLED]: {
    label: "Enabled",
    variant: "blue20",
  },
};

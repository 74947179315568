import { useMemo } from "react";
import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

import { useCompanySettings } from "entities/Company/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";

import EditDialogButton from "./EditDialogButton";
import UseAISummaryDialogButton from "./UseAISummaryDialogButton";

interface ISectionAISummaryResponse {
  section: IMemoSection;
  dealId: number;
  onUpdate: () => void;
  externalToken?: string;
}

const SectionAISummaryResponse = ({
  section,
  dealId,
  externalToken,
  onUpdate,
}: ISectionAISummaryResponse) => {
  const { data: userCompanySettings, isLoading: userCompanySettingsIsLoading } =
    useCompanySettings({});

  const openai_custom_prompt_response = useMemo(
    () => _.first(section.memo_section_openai_responses),
    [section]
  );

  const isShareableMemo = useMemo(
    () => !_.isUndefined(externalToken),
    [externalToken]
  );

  const showAISummaryButton = useMemo(
    () => userCompanySettings?.ai_summary_shown,
    [userCompanySettings]
  );

  return (
    <Box>
      <SectionHeader
        label={section.title}
        alignActionButton="right"
        actionButton={
          !isShareableMemo && (
            <NoPrint>
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )
        }
      />
      <SectionContent breakInside="avoid">
        <Box
          sx={{
            breakInside: "avoid",
          }}
        >
          {section?.is_na ? (
            <NASection />
          ) : (
            <Stack spacing={2}>
              {!_.isNil(openai_custom_prompt_response) && (
                <SanitizeHtml html={openai_custom_prompt_response.response} />
              )}
              {!isShareableMemo && (
                <NoPrint>
                  <Stack spacing={1} direction="row" alignItems="center">
                    {showAISummaryButton && (
                      <>
                        {userCompanySettingsIsLoading ? (
                          <Skeleton width={200} height={63} />
                        ) : (
                          <UseAISummaryDialogButton
                            section={section}
                            dealId={dealId}
                            onSelectAISummary={onUpdate}
                            onDisconnect={onUpdate}
                          />
                        )}
                      </>
                    )}
                    <EditDialogButton
                      dealId={dealId}
                      section={section}
                      onCreate={onUpdate}
                      buttonLabel={
                        _.isNil(openai_custom_prompt_response?.response)
                          ? "Write a new answer"
                          : "Update answer"
                      }
                    />
                  </Stack>
                </NoPrint>
              )}
            </Stack>
          )}
        </Box>
      </SectionContent>
    </Box>
  );
};

export default SectionAISummaryResponse;

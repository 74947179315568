import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import {
  CloseIcon,
  ConnectedFieldsDisabledIcon,
  ConnectedFieldsIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import SidepanelHeader from "components/SidepanelHeader";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { useLinkedCustomFieldList } from "entities/Field/sdk";

import IConnection from "./assets/image.svg";
import ConnectionModificationBanner from "./ConnectionModificationBanner";
import Connections, { ILinkedFieldRow } from "./Connections";
import CurrentItem, { ICurrentItem } from "./CurrentItem";
import UpdateConnectionFields from "./UpdateConnectionFields";
import { getLinkedFields } from "./utils";

export interface ILinkedFieldsDrawerButton {
  dealId?: number;
  fieldId: number;
  packageId: number;
  isOverridden: boolean;
  onUpdate: () => void;
}

const LinkedFieldsDrawerButton = ({
  dealId,
  fieldId,
  packageId,
  isOverridden,
  onUpdate,
}: ILinkedFieldsDrawerButton) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading, isValidating } = useLinkedCustomFieldList({
    fieldId,
    dealId,
    packageId,
    disabled: !isOpen,
  });

  const buttonTooltip = useMemo(() => {
    if (isOverridden) {
      return "Connections disabled";
    }

    return "Connections active";
  }, [isOverridden]);

  const showLoader = useMemo(
    () => isLoading || isValidating,
    [isLoading, isValidating]
  );

  const linkedFields: {
    connections: Array<ILinkedFieldRow>;
    currentField: ICurrentItem | null;
  } = useMemo(() => {
    if (_.isUndefined(data) || _.isEmpty(data)) {
      return { connections: [], currentField: null };
    }

    return getLinkedFields({ checklists: data, dealId, fieldId });
  }, [data, dealId, fieldId]);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleUpdate = useCallback(
    ({ message }: { message?: string }) => {
      onUpdate();
    },
    [onUpdate]
  );

  return (
    <>
      <Tooltip title={buttonTooltip} placement="top">
        <span>
          {isOverridden ? (
            <ConnectedFieldsDisabledIcon
              style={{
                height: "16px",
                width: "16px",
                cursor: "pointer",
                alignSelf: "center",
                display: "flex",
              }}
              onClick={handleOpen}
            />
          ) : (
            <ConnectedFieldsIcon
              style={{
                height: "16px",
                width: "16px",
                cursor: "pointer",
                alignSelf: "center",
                display: "flex",
              }}
              onClick={handleOpen}
            />
          )}
        </span>
      </Tooltip>
      <Drawer
        open={isOpen}
        anchor="right"
        PaperProps={{ elevation: 0 }}
        sx={{
          width: "780px",
          height: "100%",
          "& .MuiDrawer-paper": {
            width: "780px",
            display: "flex",
          },
        }}
        onClose={handleClose}
      >
        <Stack>
          <SidepanelHeader>
            <Stack direction="row" alignItems="start">
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img src={IConnection} alt="" width={20} height={20} />
                  <Text variant="h3">Connected field</Text>
                </Stack>
                <Text variant="text4" color={colors.gray80}>
                  This field has connections established with other fields.
                  Within this deal, all values filled out here in this current
                  field will be copied over to all other connected fields. As
                  long as this field remains connected, the values remain
                  synched: any edits or modifications to the values will be
                  reflected across all locations in this deal.
                </Text>
              </Stack>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </SidepanelHeader>
        </Stack>
        <Stack
          spacing={1}
          sx={{
            height: "100%",
            overflow: "hidden",
            padding: theme.spacing(2, 6, 2, 4),
          }}
        >
          <Stack direction="row" spacing={2}>
            {!_.isNil(linkedFields.currentField) && (
              <CurrentItem
                packageName={linkedFields.currentField.packageName}
                tabName={linkedFields.currentField.tabName}
                categoryName={linkedFields.currentField.categoryName}
                sectionName={linkedFields.currentField.sectionName}
                fieldName={linkedFields.currentField.fieldName}
              />
            )}
            {!_.isNil(dealId) && (
              <UpdateConnectionFields
                dealId={dealId}
                isOverridden={isOverridden}
                fieldId={fieldId}
                onUpdate={handleUpdate}
              />
            )}
          </Stack>
          <Stack
            spacing={2}
            sx={{
              overflowY: "auto",
            }}
          >
            <Connections
              isOverridden={isOverridden}
              connections={linkedFields.connections}
              showLoader={showLoader}
            />
            <ConnectionModificationBanner />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default LinkedFieldsDrawerButton;

import { colors } from "theme/palette";
import { ENTER_CODE } from "utils/constants";

import TextField from "components/TextField";

const CommentInputField = ({
  disabled,
  onSubmit,
}: {
  disabled: boolean;
  onSubmit: (text: string) => Promise<any>;
}) => {
  const handleKeyPress = (e: any) => {
    if (!disabled && !e.shiftKey && e.which === ENTER_CODE) {
      if (e.target.value) {
        onSubmit(e.target.value)
          .then(() => (e.target.value = ""))
          .catch();
      }
      e.preventDefault();
    }
  };

  return (
    <TextField
      autoFocus
      multiline
      fullWidth
      disabled={disabled}
      placeholder="Type your message here..."
      rows={4}
      sx={{ backgroundColor: colors.white }}
      onKeyPress={handleKeyPress}
    />
  );
};

export default CommentInputField;

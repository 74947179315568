import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";

import Box from "components/Box";
import Collapse from "components/Collapse";
import { LinearProgress } from "components/Loading";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import UploadButton from "components/UploadButton";
import { defaultSmallFileUploadButton } from "components/UploadButton/utils";

import FilesTable from "entities/Application/components/Fields/Filefield/FilesTable";
import {
  checkFilePermission,
  deleteApplicationFieldValue,
  IApplicationField,
  updateApplicationFieldValue,
} from "entities/Application/sdk";
import ExpandedButton from "entities/Field/components/Filefield/ExpandedButton";
import { IFilefieldCustomValue } from "entities/Field/components/Filefield/FilesTable";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import { upload } from "entities/File/sdk";

export interface IFilefield {
  field: IApplicationField;
  editable: boolean;
  applicationId?: number;
  onUpdate?: () => void;
}

const Filefield = ({
  field,
  editable,
  applicationId,
  onUpdate,
}: IFilefield) => {
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleFileUpload = useCallback(
    ({ files }: { files: Array<File> }) => {
      setShowLoading(true);

      if (applicationId) {
        return Promise.all(
          files.map((file) => upload({ file, isPrivate: true }))
        )
          .then((fileIds) =>
            Promise.all(
              fileIds.map((fileId) =>
                updateApplicationFieldValue({
                  applicationId,
                  fieldId: field.company_custom_field_id,
                  data: {
                    file_id: fileId,
                  },
                }).then(() => {
                  toast.successMessage(`The file(s) successfully uploaded!`);
                  onUpdate && onUpdate();
                })
              )
            )
          )
          .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE))
          .finally(() => setShowLoading(false));
      }
    },
    [applicationId, field.company_custom_field_id, onUpdate]
  );

  const handleDownloadFile = useCallback(
    ({ fileId }: { fileId: number }) =>
      checkFilePermission({ fileId })
        .then(({ url }) => openURLInNewTab({ url }))
        .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE)),
    []
  );

  const handleDeletedFile = useCallback(
    ({ customValue }: { customValue: IFilefieldCustomValue }) => {
      if (applicationId) {
        deleteApplicationFieldValue({
          applicationId,
          fieldValueId: customValue.value_id,
        })
          .then(() => {
            toast.successMessage(
              `The file ${customValue.file?.original_file_name} has been deleted successfully!`
            );
            onUpdate && onUpdate();
          })
          .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE));
      }
    },
    [applicationId, onUpdate]
  );

  const uploadedLabel = useMemo(() => {
    if (field.values.length === 1) {
      return `${field.values.length} file uploaded`;
    }
    return `${field.values.length} files uploaded`;
  }, [field.values]);

  const showRequiredTag = useMemo(
    () => editable && _.isEmpty(field.values) && field.is_required,
    [editable, field]
  );

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Text
            variant="text2"
            sx={{
              fontWeight: 300,
              paddingRight: theme.spacing(2),
            }}
          >
            {field.name}
          </Text>
          <InstructionsDialogButton instructions={field?.instruction} />
        </Stack>
        {editable && (
          <Stack direction="row" spacing={1} alignItems="center">
            {field.values.length > 0 && (
              <ExpandedButton
                label={uploadedLabel}
                isOpen={isOpenFiles}
                onClick={() => setIsOpenFiles(!isOpenFiles)}
              />
            )}
            {showRequiredTag && <Tag label="required" variant="red" />}
            <UploadButton
              onChange={handleFileUpload}
              accept="file/*"
              renderComponent={defaultSmallFileUploadButton}
            />
          </Stack>
        )}
      </Stack>
      {showLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}

      {editable && (
        <Collapse in={isOpenFiles} timeout="auto" unmountOnExit>
          {field.values.length > 0 && (
            <FilesTable
              customValues={field.values}
              onDownload={handleDownloadFile}
              onDelete={handleDeletedFile}
            />
          )}
        </Collapse>
      )}
      {!editable && field.values.length > 0 && (
        <FilesTable
          customValues={field.values}
          onDownload={handleDownloadFile}
        />
      )}
    </Box>
  );
};

export default Filefield;

import { useCallback } from "react";

import { CheckIcon, CloseIcon, MoreHorizIcon } from "icons";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import UserAvatar from "components/UserAvatar";

import {
  addUserToAllDeal,
  IEmployee,
  removeUserFromAllDeal,
  updateUserIsActive,
  updateUserIsAdmin,
} from "entities/Employee/sdk";
import { IUser } from "entities/User/sdk";

interface Employee
  extends Pick<
    IEmployee,
    | "id"
    | "avatar"
    | "is_admin"
    | "is_active"
    | "first_name"
    | "last_name"
    | "job_title"
    | "email"
    | "contact"
  > {}

export interface ICompanyTeamTable {
  employees: Employee[];
  onUpdated?: (employee: Employee) => void;
  user: IUser;
  active: boolean;
  tableDataTestid?: string;
}

const CompanyTeamTable = ({
  employees,
  user,
  onUpdated,
  active,
  tableDataTestid,
}: ICompanyTeamTable) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const updateEmployee = useCallback(
    (employee: Employee, isAdmin: boolean) =>
      updateUserIsAdmin(employee.id, isAdmin).then((updatedEmployee) => {
        onUpdated && onUpdated(updatedEmployee);
      }),
    [onUpdated]
  );

  const deactivateUser = useCallback(
    ({ employee }: { employee: Employee }) =>
      showConfirmationDialog({
        onConfirm: () =>
          updateUserIsActive({ userId: employee.id, isActive: false }).then(
            (updatedEmployee) => {
              if (onUpdated) {
                onUpdated(updatedEmployee);
              }
            }
          ),
        confirmButtonText: "Deactivate",
        message: "Are you sure you want to deactivate this user?",
      }),
    [onUpdated, showConfirmationDialog]
  );

  const reactivateUser = useCallback(
    ({ employee }: { employee: Employee }) =>
      showConfirmationDialog({
        onConfirm: () =>
          updateUserIsActive({ userId: employee.id, isActive: true }).then(
            (updatedEmployee) => {
              if (onUpdated) {
                onUpdated(updatedEmployee);
              }
            }
          ),
        confirmButtonText: "Reactivate",
        message: "Are you sure you want to reactivate this user?",
      }),
    [onUpdated, showConfirmationDialog]
  );

  const addToAllDeal = useCallback((employee: { id: Employee["id"] }) => {
    addUserToAllDeal(employee.id).then(() => {
      toast.successMessage("Added to all deals!");
    });
  }, []);

  const removeFromAllDeal = useCallback((employee: { id: Employee["id"] }) => {
    removeUserFromAllDeal(employee.id).then(() => {
      toast.successMessage("Removed from all deals!");
    });
  }, []);

  return (
    <Table data-testid={tableDataTestid}>
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell>
            <Text variant="labelSmall" sx={{ color: colors.gray40 }}>
              Id
            </Text>
          </TableCell>
          <TableCell>
            <Text variant="labelSmall" sx={{ color: colors.gray40 }}>
              Name &amp; Job title
            </Text>
          </TableCell>
          <TableCell>
            <Text variant="labelSmall" sx={{ color: colors.gray40 }}>
              Email &amp; Phone No.
            </Text>
          </TableCell>
          <TableCell>
            <Text variant="labelSmall" sx={{ color: colors.gray40 }}>
              Admin rights
            </Text>
          </TableCell>
          {user?.is_admin && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody data-testid="company-team-members-table">
        {employees.map((employee) => (
          <TableRow
            key={employee.id}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
            data-testid={`company-team-data-row-for-user-id-${employee.id}`}
            data-test-id="company-team-member-data-row"
          >
            <TableCell>
              <UserAvatar
                src={employee.avatar ? employee.avatar : undefined}
                imgix
                imgixWidth={40}
                imgixHeight={40}
                variant="rounded"
                sx={{ width: 40, height: 40 }}
                dataTestid={
                  employee.avatar
                    ? "custom-team-member-avatar"
                    : "default-team-member-avatar"
                }
              />
            </TableCell>
            <TableCell>
              <Stack>
                <Text variant="text2" data-testid="company-member-full-name">
                  {employee.first_name} {employee.last_name}
                </Text>
                <Text
                  variant="labelSmall"
                  sx={{ color: colors.gray40 }}
                  data-testid="company-member-job"
                >
                  {employee.job_title}
                </Text>
              </Stack>
            </TableCell>
            <TableCell>
              <Text variant="text2" data-testid="company-member-email">
                {employee.email}
              </Text>
              <Text
                variant="labelSmall"
                sx={{ color: colors.gray40 }}
                data-testid="company-member-phone"
              >
                {employee.contact}
              </Text>
            </TableCell>
            <TableCell>
              <Text
                variant="labelSmall"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                data-testid={
                  employee.is_admin ? "admin-user-icon" : "regular-user-icon"
                }
              >
                {employee.is_admin ? <CheckIcon /> : <CloseIcon />}
              </Text>
            </TableCell>
            {user?.is_admin && (
              <TableCell>
                <ButtonDropdown
                  icon={<MoreHorizIcon />}
                  label=""
                  variant="text"
                  width="auto"
                  buttonDataTestid="user-actions-button"
                >
                  {active ? (
                    <span>
                      {employee.is_admin && (
                        <MenuItem
                          onClick={() => updateEmployee(employee, false)}
                        >
                          <Text variant="text2">Revoke as Admin</Text>
                        </MenuItem>
                      )}
                      {!employee.is_admin && (
                        <MenuItem
                          onClick={() => updateEmployee(employee, true)}
                        >
                          <Text variant="text2">Add as Admin</Text>
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => addToAllDeal(employee)}>
                        <Text variant="text2">Add to all deals</Text>
                      </MenuItem>
                      <MenuItem onClick={() => removeFromAllDeal(employee)}>
                        <Text variant="text2">Remove from all deals</Text>
                      </MenuItem>
                      {user.id !== employee.id && (
                        <MenuItem
                          onClick={() =>
                            deactivateUser({
                              employee,
                            })
                          }
                        >
                          <Text variant="text2">Deactivate user</Text>
                        </MenuItem>
                      )}
                    </span>
                  ) : (
                    <MenuItem onClick={() => reactivateUser({ employee })}>
                      <Text variant="text2">Reactivate user</Text>
                    </MenuItem>
                  )}
                </ButtonDropdown>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CompanyTeamTable;

import { colors } from "theme/palette";
import { humanReadableNumber } from "utils/common";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

export enum ProformaHeaderTypeTotal {
  SQF = "sqf",
  PRICE = "price",
  NUMBER = "number",
}

export interface IProformaContentHeader {
  title: string;
  subTitle?: string;
  totals?: Array<{
    label: string;
    value: number | string;
    type: ProformaHeaderTypeTotal;
  }>;
}

const ProformaContentHeader = ({
  title,
  subTitle,
  totals = [],
}: IProformaContentHeader) => (
  <Stack
    sx={{
      borderBottom: `1px solid ${colors.blue20}`,
    }}
    direction="row"
    justifyContent="space-between"
  >
    <Box>
      <Text variant="h3" lineHeight="150%">
        {title}
      </Text>
      <Text variant="text2" sx={{ color: colors.gray60 }}>
        {subTitle}
      </Text>
    </Box>
    <Stack direction="row" spacing={8}>
      {totals?.map(({ label, value, type }) => (
        <Box key={`${title}-${label}`}>
          {type === ProformaHeaderTypeTotal.PRICE && (
            <Text variant="h3" lineHeight="150%" textAlign="right">
              ${humanReadableNumber(Number(value))}
            </Text>
          )}
          {type === ProformaHeaderTypeTotal.SQF && (
            <Text variant="h3" lineHeight="150%" textAlign="right">
              {Number(Number(value).toFixed(2)).toLocaleString()} sqf
            </Text>
          )}
          {type === ProformaHeaderTypeTotal.NUMBER && (
            <Text variant="h3" lineHeight="150%" textAlign="right">
              {Number(Number(value).toFixed(2)).toLocaleString()}
            </Text>
          )}
          <Text variant="text2" textAlign="right" sx={{ color: colors.gray60 }}>
            {label}
          </Text>
        </Box>
      ))}
    </Stack>
  </Stack>
);

export default ProformaContentHeader;

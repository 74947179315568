import { format, FORMATS, parse } from "utils/datetime";

import { TaskHeaderKeys } from "entities/Task/components/TasksTables/constants";
import { ITasksProperties } from "entities/Task/sdk";

export const formatTableData = (data: ITasksProperties) => {
  return {
    [TaskHeaderKeys.COMPLETED]: data.completed,
    [TaskHeaderKeys.TASK_TITLE]: data.title,
    [TaskHeaderKeys.PRIORITY]: data.priority,
    [TaskHeaderKeys.DUE_DATE]: data.deadline
      ? format(
          parse(data.deadline, FORMATS.BACKEND.DATE, new Date()),
          FORMATS.FRONTEND.DATE
        )
      : "",
    [TaskHeaderKeys.TAGS]: data?.tags?.join(", "),
    [TaskHeaderKeys.ASSIGNED_TO]: data.assignee
      ? `${data.assignee.first_name} ${data.assignee.last_name}`
      : data.assignee_type,
    [TaskHeaderKeys.UNREAD_COUNT]: data.unread_count,
    [TaskHeaderKeys.DEAL_TASK_ID]: data.deal_task_id,
    [TaskHeaderKeys.TASK_CHANNEL_ID]: data.task_channel_id,
    [TaskHeaderKeys.IS_PRIVATE]: data.is_private,
    [TaskHeaderKeys.DEAL_NAME]: data.deal.name,
    [TaskHeaderKeys.DEAL_AVATAR]: data.deal.thumbnail,
    [TaskHeaderKeys.DEAL_ID]: data.deal.deal_id,
    [TaskHeaderKeys.COMPANY_PACKAGE]: data.company_package,
    [TaskHeaderKeys.COMPANY_TAB]: data.company_tab,
    [TaskHeaderKeys.COMPANY_CATEGORY]: data.company_category,
    [TaskHeaderKeys.COMPANY_SECTION]: data.company_section,
    [TaskHeaderKeys.COMPANY_FIELD]: data.company_field,
  };
};

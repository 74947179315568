import React from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import Text from "components/Text";

interface IMetricsTitleValue {
  title: string;
  value: string;
}

const MetricsTitleValue: React.FC<IMetricsTitleValue> = ({ title, value }) => {
  return (
    <Box>
      <Text variant="text4" sx={{ color: colors.blue120 }}>
        {title}
      </Text>
      <Text variant="text1">{value}</Text>
    </Box>
  );
};

export default MetricsTitleValue;

import { useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import MainNavDropdown from "components/MainNavigation/MainNavDropdown";
import { MenuItem } from "components/Menu";
import Skeleton from "components/Skeleton";
import Text from "components/Text";

import { APPLICATION_STATUS } from "entities/Application/constants";
import { useApplicationCount } from "entities/Application/sdk";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import { useCompanySettings } from "entities/Company/sdk";

const ApplicationMenu = () => {
  const navigate = useNavigate();

  const { data: companySettings, isLoading: companySettingsIsLoading } =
    useCompanySettings({});

  const { data: applications } = useApplicationCount();

  const isApplicationsActive = Boolean(useMatch("/applications/*"));

  const showApplicantMenu = useMemo(
    () =>
      companySettings?.intake_applicant_shown &&
      applications &&
      applications.applications_count_submitted_by_me > 0,
    [companySettings, applications]
  );
  const showReviewerMenu = useMemo(
    () =>
      companySettings?.intake_lender_shown &&
      applications &&
      applications.applications_count_submitted_to_me > 0,
    [companySettings, applications]
  );

  const handleApplicantDashboardClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.INTAKE_APPLICANT,
    callback: () =>
      navigate(reverse(URLS.APPLICATIONS_LIST, { status: "All" })),
  });

  const handleReviewerDashboardClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.INTAKE_LENDER,
    callback: () =>
      navigate(
        reverse(URLS.APPLICATIONS_LENDER_LIST, {
          status: APPLICATION_STATUS.SUBMITTED,
        })
      ),
  });

  if (companySettingsIsLoading) {
    return (
      <Skeleton
        width={100}
        height={30}
        sx={{ backgroundColor: colors.gray80 }}
      />
    );
  }

  if (showApplicantMenu && showReviewerMenu) {
    return (
      <MainNavDropdown
        label="Intake"
        activeMenu={Boolean(isApplicationsActive)}
        buttonDataTestid="applications"
      >
        <Box onClick={handleApplicantDashboardClick}>
          <MenuItem>Applicant dashboard</MenuItem>
        </Box>
        <Box onClick={handleReviewerDashboardClick}>
          <MenuItem>Reviewer dashboard</MenuItem>
        </Box>
      </MainNavDropdown>
    );
  }

  if (showApplicantMenu && !showReviewerMenu) {
    return (
      <Button
        onClick={handleApplicantDashboardClick}
        variant="text"
        color="secondary"
        size="small"
        sx={{ "&:hover *": { opacity: 1 } }}
      >
        <Text
          variant="text3"
          sx={{
            color: colors.white,
            opacity: isApplicationsActive ? 1 : 0.6,
            fontWeight: 700,
            transition: "opacity 200ms ease-out",
          }}
        >
          Intake
        </Text>
      </Button>
    );
  }

  if (!showApplicantMenu && showReviewerMenu) {
    return (
      <Button
        onClick={handleReviewerDashboardClick}
        variant="text"
        color="secondary"
        size="small"
        sx={{ "&:hover *": { opacity: 1 } }}
      >
        <Text
          variant="text3"
          sx={{
            color: colors.white,
            opacity: isApplicationsActive ? 1 : 0.6,
            fontWeight: 700,
            transition: "opacity 200ms ease-out",
          }}
        >
          Intake
        </Text>
      </Button>
    );
  }

  return null;
};

export default ApplicationMenu;

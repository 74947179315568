import MemoTemplateAISummaryResponse from "entities/MemoTemplate/components/Sections/AISummaryResponse";
import MemoTemplateCommentSection from "entities/MemoTemplate/components/Sections/Comment";
import MemoTemplateCommunityAmenities from "entities/MemoTemplate/components/Sections/CommunityAmenities";
import MemoTemplateDDSection from "entities/MemoTemplate/components/Sections/DDSection";
import MemoTemplateDevelopmentBudget from "entities/MemoTemplate/components/Sections/DevelopmentBudget";
import MemoTemplateExcelTableSection from "entities/MemoTemplate/components/Sections/ExcelTable";
import MemoTemplateFloorArea from "entities/MemoTemplate/components/Sections/FloorArea";
import MemoTemplateSectionHeader from "entities/MemoTemplate/components/Sections/Header";
import MemoTemplateImageSection from "entities/MemoTemplate/components/Sections/Image";
import MemoTemplateIncomeAndOpex from "entities/MemoTemplate/components/Sections/IncomeAndOpex";
import MemoTemplateLocationSection from "entities/MemoTemplate/components/Sections/Location";
import MemoTemplateProjectDetails from "entities/MemoTemplate/components/Sections/ProjectDetails";
import MemoTemplateSchedule from "entities/MemoTemplate/components/Sections/Schedule";
import MemoTemplateSectionSummary from "entities/MemoTemplate/components/Sections/SectionSummary";
import MemoTemplateSectionSourcesAndUses from "entities/MemoTemplate/components/Sections/SourcesAndUses";
import MemoTemplateSectionTeam from "entities/MemoTemplate/components/Sections/Team";
import MemoTemplateUnitAmenities from "entities/MemoTemplate/components/Sections/UnitAmenities";
import MemoTemplateUnitMixAndRent from "entities/MemoTemplate/components/Sections/UnitMixAndRent";
import { EMemoSectionType } from "entities/MemoTemplate/constants";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

interface IMemoTemplateSectionsWrapper {
  section: IMemoTemplateSection;
}

const Section = ({ section }: IMemoTemplateSectionsWrapper) => {
  switch (section.section_type) {
    case EMemoSectionType.HEADER:
      return <MemoTemplateSectionHeader title={section.title} />;
    case EMemoSectionType.DUE_DILIGENCE:
      return <MemoTemplateDDSection section={section} />;
    case EMemoSectionType.PROJECT_DETAILS:
      return <MemoTemplateProjectDetails label={section.title} />;
    case EMemoSectionType.LOCATION:
      return <MemoTemplateLocationSection label={section.title} />;
    case EMemoSectionType.EXECUTIVE_SUMMARY:
      return <MemoTemplateSectionSummary title={section.title} />;
    case EMemoSectionType.RISKS_AND_MITIGATIONS:
      return <MemoTemplateSectionSummary title={section.title} />;
    case EMemoSectionType.FINAL_RECOMMENDATIONS:
      return <MemoTemplateSectionSummary title={section.title} />;
    case EMemoSectionType.PROJECT_NARRATIVE:
      return <MemoTemplateSectionSummary title={section.title} />;
    case EMemoSectionType.SOURCE_AND_USES:
      return <MemoTemplateSectionSourcesAndUses />;
    case EMemoSectionType.UNIT_MIX_AND_RENT:
      return <MemoTemplateUnitMixAndRent />;
    case EMemoSectionType.FLOOR_AREA:
      return <MemoTemplateFloorArea />;
    case EMemoSectionType.INCOME_AND_OPEX:
      return <MemoTemplateIncomeAndOpex />;
    case EMemoSectionType.SCHEDULE:
      return <MemoTemplateSchedule title={section.title} />;
    case EMemoSectionType.UNIT_AMENITIES:
      return <MemoTemplateUnitAmenities />;
    case EMemoSectionType.COMMUNITY_AMENITIES:
      return <MemoTemplateCommunityAmenities />;
    case EMemoSectionType.DEVELOPMENT_BUDGET:
      return <MemoTemplateDevelopmentBudget />;
    case EMemoSectionType.DEVELOPER:
      return <MemoTemplateSectionTeam groupName="Developer" />;
    case EMemoSectionType.ARCHITECT:
      return <MemoTemplateSectionTeam groupName="Architect" />;
    case EMemoSectionType.GENERAL_CONTRACTOR:
      return <MemoTemplateSectionTeam groupName="General Contractor" />;
    case EMemoSectionType.PROPERTY_MANAGER:
      return <MemoTemplateSectionTeam groupName="Property Manager" />;
    case EMemoSectionType.DEVELOPER_PARTNER:
      return <MemoTemplateSectionTeam groupName="Developer Partner" />;
    case EMemoSectionType.IMAGE:
      return <MemoTemplateImageSection title={section.title} />;
    case EMemoSectionType.COMMENT:
      return <MemoTemplateCommentSection />;
    case EMemoSectionType.EXCEL_TABLE:
      return <MemoTemplateExcelTableSection title={section.title} />;
    case EMemoSectionType.AI_SUMMARY_RESPONSE:
      return <MemoTemplateAISummaryResponse title={section.title} />;
  }
  return null;
};

export default Section;

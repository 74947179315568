import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Collapse from "components/Collapse";
import { LinearProgress } from "components/Loading";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import UploadButton from "components/UploadButton";
import { defaultSmallFileUploadButton } from "components/UploadButton/utils";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import ExpandedButton from "entities/Field/components/Filefield/ExpandedButton";
import FilesTable, {
  IFilefieldCustomValue,
} from "entities/Field/components/Filefield/FilesTable";
import {
  checkFilePermission,
  deleteDueDiligenceFile,
  uploadFileToCustomField,
} from "entities/Field/sdk";
import { upload } from "entities/File/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";

const MemoFilesTable = ({
  fieldId,
  customValues,
  onDelete,
  externalToken,
  onUpdate,
}: {
  fieldId: number;
  customValues: IFilefieldCustomValue[];
  onDelete: ({ customValue }: { customValue: IFilefieldCustomValue }) => void;
  onUpdate?: () => void;
  externalToken?: string;
}) => {
  const handleDownload = useCallback(
    ({ fileId }: { fileId: number }) =>
      checkFilePermission({ fileId, fieldId })
        .then(({ url }) => openURLInNewTab({ url }))
        .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE)),
    [fieldId]
  );

  const isShareableMemo = useMemo(
    () => !_.isUndefined(externalToken),
    [externalToken]
  );

  return (
    <Box sx={{ marginTop: theme.spacing(1), paddingLeft: theme.spacing(6) }}>
      <Tag
        label="file uploads"
        variant="gray20"
        sx={{ display: "inline-block", marginBottom: theme.spacing(1) }}
      />
      <FilesTable
        onUpdate={onUpdate}
        customFieldValues={customValues}
        onDownload={handleDownload}
        onDelete={isShareableMemo ? undefined : onDelete}
        isShareableMemo={isShareableMemo}
      />
    </Box>
  );
};

interface IFilefield {
  field: IPackageDetailsField;
  dealId: number;
  isNASection: boolean;
  onUpdate: () => void;
  externalToken?: string;
}

const MemoFilefield = ({
  field,
  dealId,
  externalToken,
  isNASection,
  onUpdate,
}: IFilefield) => {
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const customValues = useMemo(
    () => (field.values as Array<IFilefieldCustomValue>) || [],
    [field]
  );

  const handleFileUpload = useCallback(
    ({ files }: { files: Array<File> }) => {
      setShowLoading(true);

      Promise.all(files.map((file) => upload({ file, isPrivate: true })))
        .then((fileIds) =>
          Promise.all(
            fileIds.map((fileId) =>
              uploadFileToCustomField({
                dealId,
                customFieldId: field.company_custom_field_id,
                fileId,
              })
            )
          ).then(() => {
            toast.successMessage("The file(s) successfully uploaded!");
            onUpdate();
          })
        )
        .catch((e) => {
          if (!_.isUndefined(e)) {
            handleInvalidRequest(e);
          } else {
            toast.errorMessage(DEFAULT_ERROR_MESSAGE);
          }
        })
        .finally(() => setShowLoading(false));
    },
    [dealId, field, onUpdate]
  );

  const uploadedLabel = useMemo(() => {
    if (customValues.length === 1) {
      return `${customValues.length} file uploaded`;
    }
    return `${customValues.length} files uploaded`;
  }, [customValues]);

  const handleDelete = useCallback(
    ({ customValue }: { customValue: IFilefieldCustomValue }) =>
      deleteDueDiligenceFile({
        dealId,
        customFieldId: field.company_custom_field_id,
        customValueId: customValue.value_id,
      }).then(() => {
        toast.successMessage(
          `${customValue.file?.original_file_name} file successfully deleted!`
        );
        onUpdate();
      }),
    [dealId, field, onUpdate]
  );

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          paddingBottom: theme.spacing(1),
          borderBottom: `1px solid ${colors.blue40}`,
        }}
      >
        <FieldTitle
          field={field}
          onUpdate={onUpdate}
          externalToken={externalToken}
          disabled={isNAField}
        />
        <NoPrint>
          {!isNAField && (
            <Stack direction="row" spacing={1} alignItems="center">
              {customValues.length > 0 && (
                <ExpandedButton
                  label={uploadedLabel}
                  isOpen={isOpenFiles}
                  onClick={() => setIsOpenFiles(!isOpenFiles)}
                />
              )}
              {customValues.length === 0 && (
                <Tag label="missing" variant="red" />
              )}
              {_.isUndefined(externalToken) && (
                <UploadButton
                  accept="file/*"
                  onChange={handleFileUpload}
                  renderComponent={defaultSmallFileUploadButton}
                />
              )}
            </Stack>
          )}
          {isNAField && (
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          )}
        </NoPrint>
        {isNAField && (
          <Box sx={{ "@media screen": { display: "none" } }}>
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          </Box>
        )}
      </Stack>
      {showLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}

      <NoPrint>
        <Collapse in={isOpenFiles} timeout="auto" unmountOnExit>
          {!isNAField && customValues.length > 0 && (
            <MemoFilesTable
              fieldId={field.company_custom_field_id}
              customValues={customValues}
              onDelete={handleDelete}
              externalToken={externalToken}
              onUpdate={onUpdate}
            />
          )}
        </Collapse>
      </NoPrint>

      {!isNAField && customValues.length > 0 && (
        <Box sx={{ "@media screen": { display: "none" } }}>
          <MemoFilesTable
            customValues={customValues}
            onDelete={handleDelete}
            fieldId={field.company_custom_field_id}
            externalToken={externalToken}
          />
        </Box>
      )}
    </Box>
  );
};

export default MemoFilefield;

import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";

import EditMemoTemplateDialog from "entities/MemoTemplate/components/EditMemoTemplateDialog";

interface IEditMemoTemplateButton {
  memoId: number;
  onUpdated?: () => void;
}

const EditMemoTemplateButton: React.FC<IEditMemoTemplateButton> = ({
  memoId,
  onUpdated,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleUpdated = useCallback(() => {
    handleClose();
    onUpdated && onUpdated();
  }, [onUpdated, handleClose]);

  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={handleOpen} data-testid="edit-template-button">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <EditMemoTemplateDialog
        open={open}
        memoId={memoId}
        onClose={handleClose}
        onUpdated={handleUpdated}
      />
    </>
  );
};

export default EditMemoTemplateButton;

import { Theme as MuiThemeType } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { colors } from "theme/palette";

import {
  ScrollableTableCellVariant,
  SPACING_AFTER_STICKY_COLUMN,
  SPACING_BETWEEN_COLUMNS,
} from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";

import ArrowSortIcon from "./assets/arrows_sort_icon.svg";
import ArrowSortDownIcon from "./assets/arrows_sort_icon_down.svg";
import ArrowSortUpIcon from "./assets/arrows_sort_icon_up.svg";

export const getHeaderStyles = ({
  header,
  lastElementWidth,
}: {
  header: IScrollableTableHeader[number];
  lastElementWidth: number;
}): SxProps<MuiThemeType> => {
  if (header.sticky) {
    return {
      display: "flex",
      flexDirection: "column",
      flexBasis: header.width,
      flexShrink: 0,
      flexGrow: 0,
      position: "sticky",
      backgroundColor: colors.gray10,
      zIndex: 1,
      marginRight: SPACING_AFTER_STICKY_COLUMN,
      left: 0, // This will probably not work well for more than 1 fixed column
      "&:hover .showOnHover": {
        opacity: 1,
      },
    };
  }
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: header.width,
    flexShrink: 0,
    flexGrow: 0,
    marginRight: SPACING_BETWEEN_COLUMNS,
    "&:hover .showOnHover": {
      opacity: 1,
    },
    "&:last-child": {
      flexGrow: 1,
      marginRight: 0,
      "& > *": {
        width: lastElementWidth > 0 ? lastElementWidth : "auto",
      },
      "& > *:last-child": {
        flexGrow: 1,
        minHeight: "22.4px", // The header line-height is 140%, so the minHeight for the last column (dynamicHyphen) has to be 22.4, because is the empty space
      },
    },
  };
};

export const getVariantSortedColumn = ({
  currentSort,
  headers,
}: {
  currentSort: string | undefined;
  headers: IScrollableTableHeader;
}) => {
  let sortVariant: ScrollableTableCellVariant | undefined;

  headers.forEach((header) =>
    header.children.forEach((child) => {
      if (
        isColumnBeingSorted({ subHeader: child, currentSort }) &&
        child.variant
      ) {
        sortVariant = child.variant;
      }
    })
  );

  return sortVariant;
};

export const isColumnBeingSorted = ({
  currentSort,
  subHeader,
}: {
  currentSort: string | undefined;
  subHeader: IScrollableTableHeader[number]["children"][number];
}) => {
  return currentSort === subHeader.key || currentSort === `-${subHeader.key}`;
};

export const getSortIcon = ({
  currentSort,
  subHeader,
}: {
  currentSort: string | undefined;
  subHeader: IScrollableTableHeader[number]["children"][number];
}) => {
  if (currentSort === subHeader.key) {
    return ArrowSortUpIcon;
  }
  if (currentSort === `-${subHeader.key}`) {
    return ArrowSortDownIcon;
  }
  return ArrowSortIcon;
};

export const toNumber = (width: number | string | undefined): number => {
  if (!isNaN(Number(width))) {
    return Number(width);
  }
  if (typeof width === "string") {
    if (width.endsWith("px")) {
      return Number(width.slice(0, -2));
    }
    return Number(width);
  }
  return 110; // default 110 px width for cells
};

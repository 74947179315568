import React, { useCallback } from "react";
import { SelectProps } from "@mui/material/Select";
import _ from "lodash";

import { MenuItem } from "components/Menu";
import TextField, { ITextField } from "components/TextField";

export interface ISelect extends Omit<ITextField, "variant"> {
  selectProps?: Omit<SelectProps, "variant">;
  inputLabel?: string;
  options: { value: string; label: string; meta?: any }[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderOption?: (option: {
    value: string;
    label: string;
    meta?: any;
  }) => React.ReactNode;
  dropdownOptionsDataTestid?: string;
  multiple?: boolean;
}

const Select: React.FC<ISelect> = ({
  inputLabel,
  options,
  onChange,
  placeholder,
  value = " ",
  defaultValue,
  renderOption,
  dropdownOptionsDataTestid,
  multiple,
  selectProps,
  ...formikProps
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return onChange && onChange(event);
    },
    [onChange]
  );

  return (
    <TextField
      select
      label={inputLabel}
      SelectProps={{
        ...selectProps,
        multiple,
        renderValue: (option) => (
          <>
            {_.isArray(option)
              ? option.join(", ")
              : _.find(options, (opt) => opt.value === String(option))?.label}
          </>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      defaultValue={defaultValue}
      value={value}
      {...formikProps}
    >
      {placeholder && (
        <MenuItem disabled value=" " selected>
          {placeholder}
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={dropdownOptionsDataTestid}
          disabled={option.meta?.disabled}
          sx={{ whiteSpace: "normal" }}
        >
          {renderOption ? renderOption(option) : option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;

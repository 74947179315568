import React, { useCallback } from "react";

import { format, FORMATS, parseJSON } from "utils/datetime";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import Stack from "components/Stack";

import DealSidepanelTable, {
  IDealSidepanelTable,
} from "entities/Deal/components/DealSidepanel/DealSidepanelTable";
import { DealStatus, Phase } from "entities/Deal/constants";
import { editSidepanel, ISidePanel } from "entities/Deal/sdk";

export const TAB_KEY = "phaseAndStatus";

const PHASE_OPTIONS = [
  { label: "Application", value: Phase.APPLICATION },
  { label: "Underwriting", value: Phase.UNDERWRITING },
  { label: "Construction", value: Phase.CONSTRUCTION },
  { label: "Conversion", value: Phase.CONVERSION },
  {
    label: "Asset-Management",
    value: Phase.ASSET_MANAGEMENT,
  },
];
const STATUS_OPTIONS = [
  { label: "Active", value: DealStatus.ACTIVE },
  { label: "Prospect", value: DealStatus.PROSPECT },
  { label: "Archived", value: DealStatus.ARCHIVED },
  { label: "Dormant", value: DealStatus.DORMANT },
  { label: "Closed", value: DealStatus.CLOSED },
];

interface IPhaseAndStatusTabContent {
  dealId: number;
  phaseAndStatus: ISidePanel["phase_status"] | undefined;
  refetchSidePanel: () => void;
}

const PhaseAndStatusTabContent: React.FC<IPhaseAndStatusTabContent> = ({
  dealId,
  phaseAndStatus,
  refetchSidePanel,
}) => {
  const handleChange = useCallback(
    (selectedValue: string, rowKey: string) => {
      editSidepanel(dealId, { [rowKey]: selectedValue }).then(() =>
        refetchSidePanel()
      );
    },
    [dealId, refetchSidePanel]
  );

  if (!phaseAndStatus) {
    return null;
  }

  const phaseAndStatusData: IDealSidepanelTable["data"] = [
    {
      title: "Project phase",
      value: phaseAndStatus.project_phase,
      options: PHASE_OPTIONS,
      rowKey: "project_phase",
      selectFieldPlaceholder: "Select current project phase..",
    },
    {
      title: "Project status",
      value: phaseAndStatus.project_status,
      options: STATUS_OPTIONS,
      rowKey: "project_status",
      selectFieldPlaceholder: "Select current project status..",
    },
  ];

  const editAndUpdatesData = [
    {
      title: "Last updated",
      value:
        format(
          parseJSON(phaseAndStatus.last_updated),
          FORMATS.FRONTEND.DATETIME
        ) || "",
    },
    { title: "Updated by", value: phaseAndStatus.updated_by },
  ];

  return (
    <Stack spacing={2}>
      <Box data-testid="phase-and-status-section">
        <ContentSeparator label="Phase &amp; status" />
        <DealSidepanelTable
          data={phaseAndStatusData}
          onChange={(selectedValue, rowKey) =>
            handleChange(selectedValue, rowKey)
          }
        />
      </Box>
      <Box data-testid="edit-and-updates-section">
        <ContentSeparator label="Edit &amp; updates" />
        <DealSidepanelTable data={editAndUpdatesData} />
      </Box>
    </Stack>
  );
};

export default PhaseAndStatusTabContent;

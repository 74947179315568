import React, { useCallback } from "react";

import toast from "components/Toast";
import { IMAGE_ERROR_NOTIFICATION } from "components/UploadButton/constants";

import DealForm from "entities/Deal/components/Form";
import { createDeal, IDealForCreation } from "entities/Deal/sdk";
import { upload } from "entities/File/sdk";

export interface IAddDealForm {
  onCreated?: () => void;
  handleCancel?: () => void;
}

const AddDealForm: React.FC<IAddDealForm> = ({ onCreated, handleCancel }) => {
  const handleSubmit = useCallback(
    (data: Partial<IDealForCreation>, image?: File) => {
      if (image) {
        return upload({
          file: image,
          isPrivate: false,
        })
          .then((imageId) => {
            createDeal({ ...data, image_id: imageId })
              .then(() => onCreated && onCreated())
              .catch((error) => {
                if (error.message) {
                  toast.errorMessage(error?.message);
                }
              });
          })
          .catch(({ image }) => {
            if (image?.length) {
              toast.errorMessage(IMAGE_ERROR_NOTIFICATION);
            }
          });
      } else {
        return createDeal(data)
          .then(() => {
            onCreated && onCreated();
          })
          .catch((error) => {
            if (error.message) {
              toast.errorMessage(error?.message);
            }
          });
      }
    },
    [onCreated]
  );

  return (
    <DealForm
      onSubmit={handleSubmit}
      handleCancel={handleCancel}
      submitLabel="Create Deal"
    />
  );
};

export default AddDealForm;

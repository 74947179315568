import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Divider from "components/Divider";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import StickyMenu from "components/StickyMenu";
import Switch from "components/Switch";
import Text from "components/Text";

import { useCompanySettings } from "entities/Company/sdk";
import LeftMenuLink from "entities/Package/components/LeftMenu/LeftMenuLink";
import { useCompanyPackageWithTabsInformation } from "entities/Package/sdk";

export interface ILeftMenu {
  onChangeExpanded?: (isExpanded: boolean) => void;
  onChangeShowOnlyAISummaryFiles?: (params: { checked: boolean }) => void;
  isAllFilesSelected?: boolean;
  showLeftSidebarNavigation?: boolean;
}

const LeftMenu = ({
  onChangeExpanded,
  onChangeShowOnlyAISummaryFiles,
  isAllFilesSelected,
  showLeftSidebarNavigation = true,
}: ILeftMenu) => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId?: string;
  }>();
  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId: number | undefined = params.tabId
    ? Number(params.tabId)
    : undefined;
  const { data: checklist } = useCompanyPackageWithTabsInformation({
    dealId,
    packageId,
  });
  const { data: userCompanySettings } = useCompanySettings({});

  const handleChangeExpandedCategories = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeExpanded?.(event.target.checked);
    },
    [onChangeExpanded]
  );

  const handleChangeShowOnlyAISummaryFiles = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeShowOnlyAISummaryFiles?.({ checked: event.target.checked });
    },
    [onChangeShowOnlyAISummaryFiles]
  );

  const showAISummaryMenu = useMemo(
    () => userCompanySettings?.ai_summary_shown,
    [userCompanySettings]
  );

  const selectedTab = useMemo(
    () => _.find(checklist?.tabs, (tab) => tab.id === tabId),
    [tabId, checklist]
  );

  return (
    <StickyMenu top={theme.spacing(13.75)}>
      <Paper
        sx={{
          width: "280px",
          padding: theme.spacing(2),
          border: `1px solid ${colors.blue40}`,
        }}
        data-testid="dd-left-side-menu"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Text
            variant="labelSmall"
            fontWeight={500}
            color={colors.gray40}
            data-testid="dd-package-id"
          >{`ID# ${packageId}`}</Text>
        </Stack>
        <Text
          variant="text2"
          style={{ fontWeight: "bold" }}
          data-testid="dd-package-name"
        >
          {checklist?.name || <Skeleton width={75} />}
        </Text>
        <Text
          variant="text4"
          sx={{ marginBottom: theme.spacing(1), color: colors.gray60 }}
          data-testid="dd-package-investor-company-name"
        >
          {checklist?.company.name || <Skeleton width={100} />}
        </Text>
        {showLeftSidebarNavigation && (
          <>
            {!_.isEmpty(checklist?.tabs) && (
              <>
                <Stack spacing={1}>
                  <Divider sx={{ borderColor: colors.blue20 }} />
                  <Text
                    variant="labelSmall"
                    color={colors.gray40}
                    fontWeight={500}
                  >
                    Tabs
                  </Text>
                  <Box>
                    {checklist?.tabs.map((tab) => (
                      <LeftMenuLink
                        key={tab.id}
                        dealId={dealId}
                        packageId={packageId}
                        tab={tab}
                        isSelected={selectedTab?.id === tab.id}
                        buttonDataTestid="dd-tab-name-button"
                        linkDataTestid="dd-tab-link"
                      />
                    ))}
                  </Box>

                  <Divider sx={{ borderColor: colors.blue20 }} />
                  <Text
                    variant="labelSmall"
                    color={colors.gray40}
                    fontWeight={500}
                  >
                    display (OPEN/COLLAPSE)
                  </Text>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    data-testid="dd-filter-settings"
                  >
                    <Text variant="text3" data-testis="filter-name">
                      Open all items
                    </Text>
                    <Switch
                      defaultChecked
                      disabled={isAllFilesSelected}
                      onChange={handleChangeExpandedCategories}
                      data-testid="show-hide-details-toggle"
                    />
                  </Stack>

                  {showAISummaryMenu && (
                    <>
                      <Divider sx={{ borderColor: colors.blue20 }} />
                      <Text
                        variant="labelSmall"
                        color={colors.gray40}
                        fontWeight={500}
                      >
                        filters
                      </Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        data-testid="dd-filter-settings"
                      >
                        <Text variant="text3" data-testis="filter-name">
                          AI summary files
                        </Text>
                        <Switch
                          defaultChecked={false}
                          onChange={handleChangeShowOnlyAISummaryFiles}
                          data-testid="show-hide-only-ai-summary-files-toggle"
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
              </>
            )}
          </>
        )}
      </Paper>
    </StickyMenu>
  );
};

export default LeftMenu;

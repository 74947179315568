import React from "react";
import { useCallback, useState } from "react";

import Button from "components/Button";

import AddCompanyMemberDialog from "entities/Employee/components/AddCompanyMemberDialog";
import { IUser } from "entities/User/sdk";

export interface ICompanyMemberAddButton {
  onCreated?: ({ employee }: { employee: IUser }) => void;
}

const CompanyMemberAddButton: React.FC<ICompanyMemberAddButton> = ({
  onCreated,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnCreated = useCallback(
    ({ employee }: { employee: IUser }) => {
      onCreated && onCreated({ employee });
      handleClose();
    },
    [handleClose, onCreated]
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="secondary"
        size="large"
        data-testid="add-new-company-member-button"
      >
        + Add Member
      </Button>
      <AddCompanyMemberDialog
        open={open}
        onClose={handleClose}
        onCreated={handleOnCreated}
      />
    </>
  );
};

export default CompanyMemberAddButton;

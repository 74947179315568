import React from "react";
import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import UnitAmenitiesEditDialog from "entities/Deal/components/OverviewUnitAmenities/UnitAmenitiesEditDialog";

export interface IUnitAmenitiesEditButton {}

const UnitAmenitiesEditButton: React.FC<IUnitAmenitiesEditButton> = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        data-testid="edit-unit-amenities-section-button"
      >
        <EditIcon />
      </IconButton>
      <UnitAmenitiesEditDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default UnitAmenitiesEditButton;

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { resendInvitationShareToken } from "entities/Package/sdk";
interface IShareTokenResendInvitationButton {
  shareTokenId: number;
  email: string;
  onUpdate: () => void;
}

const ShareTokenResendInvitationButton = ({
  email,
  shareTokenId,
  onUpdate,
}: IShareTokenResendInvitationButton) => {
  const handleResendInvitation = useCallback(
    () =>
      resendInvitationShareToken({ shareTokenId, email })
        .then(() => {
          onUpdate();
          toast.successMessage("Invitation resent!");
        })
        .catch((error) =>
          toast.errorMessage(error.message || DEFAULT_ERROR_MESSAGE)
        ),
    [email, shareTokenId, onUpdate]
  );

  return (
    <MenuItem onClick={handleResendInvitation} data-testid="action-option">
      Resend invite
    </MenuItem>
  );
};

export default ShareTokenResendInvitationButton;

interface Environment {
  VITE_BASE_BACKEND_URL?: string;
  VITE_ENV_NAME?: string;
  VITE_GOOGLE_API_KEY?: string;
  VITE_PUSHER_KEY?: string;
  VITE_PUSHER_CLUSTER?: string;
  VITE_IMGIX_URL?: string;
  VITE_S3_URL?: string;
}

export const getEnvironmentVariables = (): Environment => {
  return import.meta.env as Environment;
};

import React, { useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import EditSectionDialog from "entities/MemoTemplate/components/EditSectionDialog";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

interface IEditSectionButton {
  section: IMemoTemplateSection;
  children?: React.ReactNode;
}

const EditSectionButton = React.forwardRef<
  HTMLButtonElement,
  IEditSectionButton
>(({ children, section, ...props }: IEditSectionButton, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        {...props}
        ref={ref}
        sx={{ borderRadius: 0 }}
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </IconButton>
      <EditSectionDialog
        section={section}
        open={open}
        onClose={() => setOpen(false)}
        onSectionEditted={() => {
          setOpen(false);
        }}
      />
    </>
  );
});

export default EditSectionButton;

import theme from "theme";

import Box from "components/Box";

export interface ISectionContent {
  breakInside?: string;
  children?: React.ReactNode;
  opacity?: number;
}

const SectionContent = ({
  children,
  breakInside = "auto",
  opacity = 1,
}: ISectionContent) => (
  <Box
    sx={{ breakInside, padding: theme.spacing(2), opacity }}
    data-testid="section-content"
  >
    {children}
  </Box>
);

export default SectionContent;

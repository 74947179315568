import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import Box from "components/Box";
import NavLink from "components/NavLink";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import LenderPreviewButtons from "entities/Application/components/LenderPreviewButtons";
import { APPLICATION_STATUS } from "entities/Application/constants";
import { useApplicationLenderDetails } from "entities/Application/sdk";
import Category from "entities/Checklist/components/Category";
import { FieldWrapperType } from "entities/Checklist/constants";

const ApplicationLenderPreview = () => {
  const params = useParams<{ applicationId: string }>();
  const applicationId = Number(params.applicationId);
  const { data: application, mutate: updateApplicationLenderDetails } =
    useApplicationLenderDetails({
      applicationId,
    });

  const subHeader = useMemo(
    () =>
      application && application.submitted_at
        ? `Submitted on ${format(
            parseJSON(application.submitted_at),
            FORMATS.FRONTEND.DATETIME
          )}`
        : "",
    [application]
  );

  usePageTitle("Builders Patch: Intake - Preview");

  if (!application) {
    return null;
  }

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Box
          sx={{
            maxWidth: BOX_MAX_WIDTH,
            alignSelf: "center",
            width: "100%",
          }}
        >
          <Stack spacing={3}>
            <Text variant="text2" color={colors.gray80} fontWeight={500}>
              <NavLink
                to={reverse(URLS.APPLICATIONS_LENDER_LIST, {
                  status: APPLICATION_STATUS.SUBMITTED,
                })}
              >
                Intake
              </NavLink>{" "}
              &gt; {application.name}
            </Text>
            <PageHeader
              title={application.token.name}
              subTitle={subHeader}
              actions={
                [
                  APPLICATION_STATUS.SUBMITTED,
                  APPLICATION_STATUS.ARCHIVED,
                  APPLICATION_STATUS.REJECTED,
                ].includes(application.status) && (
                  <LenderPreviewButtons
                    applicationId={applicationId}
                    applicationName={application.name}
                    applicationStatus={
                      application.status as
                        | APPLICATION_STATUS.SUBMITTED
                        | APPLICATION_STATUS.REJECTED
                        | APPLICATION_STATUS.ARCHIVED
                    }
                    projectName={application.project_name}
                    numberOfUnits={application.number_of_units}
                    onUpdate={updateApplicationLenderDetails}
                  />
                )
              }
            />
            <Stack spacing={1}>
              {application.company_package?.tabs.map((tab) => (
                <Stack spacing={1} key={`tab-${tab.tab_id}`}>
                  {tab.categories.map((category) => (
                    <Category
                      key={`tab-${tab.tab_id}-category-${category.category_id}`}
                      category={category as any} // TODO: fix this and remove any
                      fieldWrapperMeta={{
                        applicationId,
                        editable: false,
                      }}
                      fieldWrapperType={FieldWrapperType.APPLICATION}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>

            {[
              APPLICATION_STATUS.SUBMITTED,
              APPLICATION_STATUS.ARCHIVED,
              APPLICATION_STATUS.REJECTED,
            ].includes(application.status) && (
              <LenderPreviewButtons
                applicationId={applicationId}
                applicationName={application.name}
                applicationStatus={
                  application.status as
                    | APPLICATION_STATUS.SUBMITTED
                    | APPLICATION_STATUS.REJECTED
                    | APPLICATION_STATUS.ARCHIVED
                }
                projectName={application.project_name}
                numberOfUnits={application.number_of_units}
                onUpdate={updateApplicationLenderDetails}
              />
            )}
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default ApplicationLenderPreview;

import { useMemo } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import { MoreVertIcon } from "icons";
import { IS_USER_FACING_TABLE_IMPORT_ENABLED } from "utils/constants";
import { reverse } from "utils/urls";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import Link from "components/Link";
import { MenuItem } from "components/Menu";

import DeleteButton from "entities/TableStorage/components/DeleteButton";
import TableRenameDialogMenuItem from "entities/TableStorage/components/TableRenameDialogMenuItem";

export interface IActionButtons {
  tableId: number;
  initialValue: string;
  onUpdate: ({ values }: { values: { name: string } }) => void;
  onDelete: () => void;
  onImportDelete?: () => void;
  onImportUpdate?: () => void;
  dealId?: number;
}

const ActionButtons = ({
  tableId,
  initialValue,
  onUpdate,
  onDelete,
  dealId,
  onImportUpdate,
  onImportDelete,
}: IActionButtons) => {
  const compareLink = useMemo(() => {
    if (!_.isNil(dealId)) {
      return reverse(URLS.DEAL_TABLES_COMPARE, { dealId, tableId });
    }

    return reverse(URLS.TABLES_COMPARE_WITH_INITIAL, { tableId });
  }, [tableId, dealId]);

  return (
    <ButtonDropdown
      variant="text"
      size="small"
      label={<MoreVertIcon />}
      width="auto"
      sx={{ padding: "2px" }}
      keepMenuMounted
    >
      <GroupLabel label="Actions" />
      <TableRenameDialogMenuItem
        initialValue={initialValue}
        onUpdate={onUpdate}
      />
      <DeleteButton onDelete={onDelete} buttonType="menuItem" />
      <Link to={compareLink}>
        <MenuItem>Compare</MenuItem>
      </Link>
      {IS_USER_FACING_TABLE_IMPORT_ENABLED && (
        <>
          {!_.isNil(onImportUpdate) && (
            <MenuItem onClick={onImportUpdate}>Modify range</MenuItem>
          )}
          {!_.isNil(onImportDelete) && (
            <MenuItem onClick={onImportDelete}>Delete range</MenuItem>
          )}
        </>
      )}
    </ButtonDropdown>
  );
};

export default ActionButtons;

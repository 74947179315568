import * as yup from "yup";

import { colors } from "theme/palette";

export const VALIDATION_SCHEMA = yup.object({
  name: yup
    .string()
    .required("This field is required!")
    .max(255, "Table name must be less than 255 characters!"),
  rows: yup.array(),
});

export enum ETableReportType {
  TYPE_1 = "type-1",
  TYPE_2 = "type-2",
  TYPE_3 = "type-3",
}

export enum ETableResponseStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export const MapTableResponseStatusColor = {
  [ETableResponseStatus.SUCCEEDED]: colors.green100,
  [ETableResponseStatus.FAILED]: colors.red120,
  [ETableResponseStatus.IN_PROGRESS]: colors.yellow120,
};

export const TableResponseStatusLabel = {
  [ETableResponseStatus.SUCCEEDED]: "Succeeded",
  [ETableResponseStatus.FAILED]: "Failed",
  [ETableResponseStatus.IN_PROGRESS]: "In Progress",
};

import { useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { MoreHorizIcon } from "icons";
import { colors } from "theme/palette";
import { format, FORMATS } from "utils/datetime";

import ButtonDropdown from "components/ButtonDropdown";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import UserAvatar from "components/UserAvatar";

import {
  addNewTeam,
  IDealTeamMemberInvitation,
  invitationCancel,
  useDealTeamMemberInvitations,
} from "entities/Team/sdk";

export interface IDealTeamMemberInvitationsTable {
  invitations: Array<IDealTeamMemberInvitation>;
  loggedUserIsDealAdmin: boolean;
}

const DealTeamMemberInvitationsTable = ({
  invitations,
  loggedUserIsDealAdmin,
}: IDealTeamMemberInvitationsTable) => {
  const params = useParams<{ dealId: string }>();
  const dealId = Number(params.dealId);

  const { show: showConfirmationDialog } = useConfirmationDialog();
  const { mutate: mutateDealTeamMemberInvitations } =
    useDealTeamMemberInvitations({
      dealId,
    });

  const handleInvitationResend = useCallback(
    ({ invitation }: { invitation: IDealTeamMemberInvitation }) => {
      addNewTeam({
        dealId,
        data: {
          email: invitation.email,
          persona: invitation.persona,
        },
      }).then(() => {
        toast.successMessage("Invitation resent successfully.");
        mutateDealTeamMemberInvitations();
      });
    },
    [dealId, mutateDealTeamMemberInvitations]
  );

  const handleInvitationCancel = useCallback(
    ({ invitation }: { invitation: IDealTeamMemberInvitation }) => {
      showConfirmationDialog({
        confirmButtonText: "Cancel invitation",
        message: "Are you sure you want to cancel this invitation?",
        onConfirm: () =>
          invitationCancel({ invitationId: invitation.id }).then(() => {
            toast.successMessage("Invitation cancelled successfully.");
            mutateDealTeamMemberInvitations();
          }),
      });
    },
    [mutateDealTeamMemberInvitations, showConfirmationDialog]
  );

  return (
    <Table
      size="small"
      sx={{ border: `1px solid ${colors.blue20}` }}
      id="Invitations"
      data-testid="invitations-table"
    >
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
              backgroundColor: colors.blue20,
              color: colors.black,
            },
          }}
          data-testid="invitations-table-headers"
        >
          <TableCell width={450}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-email"
            >
              Email
            </Text>
          </TableCell>
          <TableCell width={200}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-persona"
            >
              Persona
            </Text>
          </TableCell>
          <TableCell width={300}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-invited-by"
            >
              Invited by
            </Text>
          </TableCell>
          <TableCell width={400}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-invited-at"
            >
              Invited at
            </Text>
          </TableCell>
          {loggedUserIsDealAdmin && (
            <TableCell
              width="55"
              data-testid="table-header-invitation-actions-button"
            ></TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody data-testid="invitations-table-body">
        {invitations.map((invitation) => (
          <TableRow
            key={invitation.id}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
            data-testid="invited-member-data-row"
          >
            <TableCell data-testid="email-cell-data">
              <Tooltip title={invitation.email} placement="top-start">
                <Text variant="text2" data-testid="user-email">
                  {_.truncate(invitation.email, { length: 30 })}
                </Text>
              </Tooltip>
            </TableCell>

            <TableCell data-testid="persona-cell-data">
              <Tooltip title={invitation.persona} placement="top-start">
                <Text variant="labelSmall" data-testid="user-persona">
                  {invitation.persona}
                </Text>
              </Tooltip>
            </TableCell>

            <TableCell data-testid="invited-by-cell-data">
              <Tooltip
                title={`${invitation.created_by.first_name} ${invitation.created_by.last_name}`}
                placement="top-start"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <UserAvatar
                    src={
                      invitation.created_by.avatar
                        ? invitation.created_by.avatar
                        : undefined
                    }
                    imgix
                    imgixWidth={40}
                    imgixHeight={40}
                    variant="rounded"
                    sx={{ width: 30, height: 30 }}
                    dataTestid={
                      invitation.created_by.avatar
                        ? "custom-team-member-avatar"
                        : "default-team-member-avatar"
                    }
                  />
                  <Text variant="text2" data-testid="company-member-full-name">
                    {_.truncate(
                      `${invitation.created_by.first_name} ${invitation.created_by.last_name}`,
                      {
                        length: 15,
                      }
                    )}
                  </Text>
                </Stack>
              </Tooltip>
            </TableCell>

            <TableCell data-testid="invited-at-cell-data">
              <Tooltip
                title={format(
                  new Date(invitation.created_at),
                  FORMATS.FRONTEND.DATETIME
                )}
                placement="top-start"
              >
                <Text variant="text2" data-testid="invited-at-datetime">
                  {format(
                    new Date(invitation.created_at),
                    FORMATS.FRONTEND.DATETIME
                  )}
                </Text>
              </Tooltip>
            </TableCell>

            {loggedUserIsDealAdmin && (
              <TableCell data-testid="inviation-actions-cell-data">
                <ButtonDropdown
                  icon={<MoreHorizIcon />}
                  label=""
                  variant="text"
                  width="auto"
                  buttonDataTestid="invitation-actions-dropdown-button"
                >
                  <MenuItem dataTestid="action-option">
                    <Text
                      variant="text2"
                      onClick={() => handleInvitationResend({ invitation })}
                      data-testid="action-label"
                    >
                      Resend invitation
                    </Text>
                  </MenuItem>
                  <MenuItem dataTestid="action-option">
                    <Text
                      variant="text2"
                      onClick={() => handleInvitationCancel({ invitation })}
                      data-testid="action-label"
                    >
                      Cancel invitation
                    </Text>
                  </MenuItem>
                </ButtonDropdown>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default DealTeamMemberInvitationsTable;

import { useCallback, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";

import Backdrop from "components/Backdrop";
import Box from "components/Box";
import SubNavigationMenu from "components/DealPageLayout/SubNavigation";
import Footer from "components/Footer";
import MainNavigation from "components/MainNavigation";
import NoPrint from "components/NoPrint";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import { DealSidepanelContextProvider } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import { DEAL_ACCESS_DENIED_MESSAGE } from "entities/Deal/constants";
import { useDealPropertyUpdate } from "entities/Deal/hooks";
import { useDealDetails } from "entities/Deal/sdk";

const SmallScreenBackdrop = () => {
  const matches = useMediaQuery("(max-width:1023px)");

  return (
    <Backdrop
      open={matches}
      sx={{
        alignItems: "flex-start",
        zIndex: 1000,
        backgroundColor: "white",
        opacity: 0.9,
        "@media print and (max-width: 1023px)": {
          display: "none",
        },
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(4),
          margin: theme.spacing(15, 2, 0, 2),
        }}
        elevation={4}
      >
        <Text variant="text1">
          You need to view this app on a Desktop computer or a Laptop.
        </Text>
      </Paper>
    </Backdrop>
  );
};

const DealLayout = () => {
  const navigate = useNavigate();
  const params = useParams<{ dealId: string }>();
  const dealIdParam = Number(params.dealId);

  const { mutate: mutateDeal, error } = useDealDetails({ dealId: dealIdParam });

  const updateDeal = useCallback(
    ({ dealId }: { dealId: number }) => {
      if (dealIdParam === dealId) {
        mutateDeal();
      }
    },
    [dealIdParam, mutateDeal]
  );

  useEffect(() => {
    if (!_.isEmpty(error) && error.detail === DEAL_ACCESS_DENIED_MESSAGE) {
      navigate(URLS.DEAL_ACCESS_DENIED, { replace: true });
    }
  }, [error, navigate]);

  useDealPropertyUpdate({
    updateEventHandler: updateDeal,
  });

  return (
    <DealSidepanelContextProvider isInDealContext={true}>
      <Stack
        sx={{
          minHeight: "100vh",
          padding: theme.spacing(0, 8, 0, 0),
          "@media print": {
            padding: "0",
          },
        }}
      >
        <NoPrint>
          <Box
            sx={{
              position: "fixed",
              zIndex: 999,
              width: "100%",
              paddingRight: theme.spacing(8),
              flexGrow: 0,
              flexShrink: 0,
              "@media print and (max-width: 1023px)": {
                display: "none",
                paddingRight: 0,
              },
            }}
          >
            <MainNavigation />
            <SubNavigationMenu />
          </Box>
        </NoPrint>
        <Box
          sx={{
            paddingTop: theme.spacing(12.25), // This is the height of MainNavigation + SubNavigationMenu
            flex: 1,
            "@media print": {
              padding: 0,
            },
          }}
        >
          <Outlet />
        </Box>
        <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
          <Footer />
        </Box>
      </Stack>
      <SmallScreenBackdrop />
    </DealSidepanelContextProvider>
  );
};

export default DealLayout;

import React, { useEffect, useRef } from "react";

import { ENTER_CODE } from "utils/constants";

import TextField from "components/TextField";

interface INoteInputField {
  initialValue?: string;
  onSubmit: (text: string) => Promise<void>;
}

const NoteInputField: React.FC<INoteInputField> = ({
  initialValue = "",
  onSubmit,
}) => {
  const ref = useRef<any>();

  const handleKeyPress = (e: any) => {
    if (!e.shiftKey && e.which === ENTER_CODE && e.target.value.trim() !== "") {
      onSubmit(e.target.value)
        .then(() => (e.target.value = ""))
        .catch();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (ref) {
      const input = ref.current;

      const inputLength = input.value.length;

      input.setSelectionRange(inputLength, inputLength);
      input.focus();
    }
  }, [ref]);

  return (
    <TextField
      inputRef={ref}
      multiline
      fullWidth
      defaultValue={initialValue}
      size="small"
      placeholder="Type new note here..."
      onKeyPress={handleKeyPress}
    />
  );
};

export default NoteInputField;

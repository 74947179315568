import { memo, useCallback } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import {
  CancelIcon,
  CheckCircleIcon,
  DescriptionOutlinedIcon,
  DownloadingIcon,
  DownloadOutlinedIcon,
  LaunchIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import IconButton from "components/IconButton";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { ImportPdfStatus } from "entities/Package/constants";
import {
  getPdfImportFileSignedURLRetrieve,
  IDueDiligencePdfImport,
} from "entities/Package/sdk";

const MapStatusColor = {
  [ImportPdfStatus.COMPLETED]: colors.green100,
  [ImportPdfStatus.FAILED]: colors.red120,
  [ImportPdfStatus.IN_PROGRESS]: colors.yellow120,
};

export interface IPdfImportFile {
  pdfImport: IDueDiligencePdfImport;
  dealId: number;
  packageId: number;
}

const PdfImportFile = ({ pdfImport, dealId, packageId }: IPdfImportFile) => {
  const handleDownloadFile = useCallback(
    () =>
      getPdfImportFileSignedURLRetrieve({ pdfImportId: pdfImport.id }).then(
        ({ signed_url }) => openURLInNewTab({ url: signed_url })
      ),
    [pdfImport]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${colors.blue40}`,
      }}
      data-testid="pdf-file-uploaded-entity"
    >
      <Stack direction="row" alignItems="start" spacing={2}>
        <DescriptionOutlinedIcon />
        <Stack flex={1}>
          <Text
            variant="text3"
            title={pdfImport.file.original_file_name}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 450,
              whiteSpace: "nowrap",
            }}
            data-testid="pdf-file-name"
          >
            {pdfImport.file.original_file_name}
          </Text>
          <Text
            variant="text4"
            sx={{ color: colors.gray60 }}
            data-testid="uploaded-file-data"
          >
            Uploaded on{" "}
            <span data-testid="file-upload-datetime-data">
              {format(
                parseJSON(pdfImport.created_at),
                FORMATS.FRONTEND.DATETIME
              )}
            </span>{" "}
            by{" "}
            <span data-testid="user-full-name">
              {pdfImport.created_by.first_name} {pdfImport.created_by.last_name}
            </span>
          </Text>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          data-testid="file-upload-status-section"
          alignSelf="center"
        >
          {pdfImport.status === ImportPdfStatus.COMPLETED && (
            <CheckCircleIcon
              sx={{ color: colors.green100 }}
              data-testid="success-status-icon"
            />
          )}
          {pdfImport.status === ImportPdfStatus.FAILED && (
            <CancelIcon
              sx={{ color: colors.red120 }}
              data-testid="failed-status-icon"
            />
          )}
          {pdfImport.status === ImportPdfStatus.IN_PROGRESS && (
            <DownloadingIcon
              sx={{ color: colors.yellow120 }}
              data-testid="in-progress-status-icon"
            />
          )}
          <Text
            variant="text2"
            color={MapStatusColor[pdfImport.status]}
            fontWeight={500}
            data-testid="status-name"
          >
            {pdfImport.status}
          </Text>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        {pdfImport.status === ImportPdfStatus.COMPLETED && (
          <Link
            to={reverse(URLS.DEAL_PACKAGE_EDIT_PREVIEW, {
              dealId,
              packageId,
              pdfImportId: pdfImport.id,
            })}
            linkDataTestid="preview-button"
          >
            <Tooltip title="Preview the parsed data">
              <IconButton>
                <LaunchIcon />
              </IconButton>
            </Tooltip>
          </Link>
        )}
        <Tooltip title="Download the PDF">
          <IconButton
            onClick={handleDownloadFile}
            dataTestid="download-uploaded-pdf-button"
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default memo(PdfImportFile, (prevProps, nextProps) =>
  _.isEqual(prevProps.pdfImport, nextProps.pdfImport)
);

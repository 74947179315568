export enum CompanyFieldFormatType {
  INTEGER = "integer",
  STRING = "string",
  CURRENCY_USD = "currency_usd",
  DATE = "date",
  FLOAT_2 = "float_2",
}

export enum CompanyCustomFieldSyncingType {
  SYNC_CURRENT_VALUE_TO_REST = "sync_current_value_to_rest",
  SYNC_REST_VALUES_TO_CURRENT_VALUE = "sync_rest_values_to_current_value",
  ERASE_ALL_VALUES = "erase_all_values",
  OVERRIDE_CURRENT_VALUE = "override_current_value",
}

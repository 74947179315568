import { useMemo } from "react";
import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import ProjectNarrativeEditButton from "entities/Deal/components/OverviewProjectNarrative/ProjectNarrativeEditButton";
import NoContent from "entities/Deal/components/OverviewSections/NoContent";
import { IDeal } from "entities/Deal/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";
import { useDealSummaryByCategoryManager } from "entities/Memo/utils";

const SectionProjectNarrative = ({
  deal,
  sectionName = "",
  section,
  externalToken,
  onUpdate,
}: {
  deal: IDeal;
  sectionName?: string;
  section?: IMemoSection;
  externalToken?: string;
  onUpdate?: () => void;
}) => {
  const { data } = useDealSummaryByCategoryManager({
    token: externalToken,
    dealId: deal.id,
    category: "executive_summary",
  });

  const showEmptyState = useMemo(
    () => !_.isUndefined(data) && data?.summary === "",
    [data]
  );

  return (
    <Box>
      <SectionHeader
        label={section?.title || sectionName}
        alignActionButton="right"
        actionButton={
          _.isUndefined(externalToken) &&
          section && (
            <NoPrint>
              <MemoSectionContextButton
                dealId={deal.id}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )
        }
      />
      <SectionContent>
        <Box>
          {section?.is_na ? (
            <NASection />
          ) : (
            <>
              <NoPrint>
                <Stack spacing={2} alignItems="center" direction="row">
                  <Text>Description:</Text>
                  {_.isUndefined(externalToken) && (
                    <ProjectNarrativeEditButton buttonSize="small" />
                  )}
                </Stack>
              </NoPrint>
              {showEmptyState ? (
                <NoContent />
              ) : (
                <SanitizeHtml html={data?.summary || ""} />
              )}
            </>
          )}
        </Box>
      </SectionContent>
    </Box>
  );
};

export default SectionProjectNarrative;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import Button from "components/Button";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { IPackageCategory } from "entities/Package/sdk";
import { ITaskChannel } from "entities/Task/sdk";

interface IDueDiligenceCategoryOpenTaskButton {
  category: IPackageCategory;
  taskChannels: Array<ITaskChannel>;
  dealId: number;
  tabId: number;
  packageId: number;
  updatePackage: (message?: string) => void;
  taskButtonDataTestid?: string;
  taskMessagesButtonDataTestid?: string;
}

const DueDiligenceCategoryOpenTaskButton = ({
  category,
  taskChannels,
  dealId,
  tabId,
  packageId,
  updatePackage,
  taskButtonDataTestid,
  taskMessagesButtonDataTestid,
}: IDueDiligenceCategoryOpenTaskButton) => {
  const { show } = useDealSidepanel();

  const unreadLabel = useMemo(
    () => _.sumBy(taskChannels, "unread_count"),
    [taskChannels]
  );

  const handleOpenTaskChat = useCallback(() => {
    const taskInitialData = {
      title: category.name,
      company_category: category.category_id,
      company_package: packageId,
      company_tab: tabId,
    };

    const taskMessagesCountWithUnreadMessages = _.filter(
      taskChannels,
      (taskChannel) => taskChannel.unread_count > 0
    ).length;

    if (taskMessagesCountWithUnreadMessages === 1) {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          subTab: TASKS_TAB_SUBTABS.CHAT,
          taskChannel: taskChannels[0],
          dealId,
          taskCategory: category,
          taskInitialData,
        },
      });
    } else {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          dealId,
          taskCategory: category,
          taskInitialData,
        },
      });
    }
  }, [show, dealId, taskChannels, updatePackage, category, packageId, tabId]);

  const handleOpenTask = useCallback(() => {
    const taskInitialData = {
      title: category.name,
      company_category: category.category_id,
      company_package: packageId,
      company_tab: tabId,
    };
    if (taskChannels.length === 1) {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          dealId,
          taskCategory: category,
          subTab: TASKS_TAB_SUBTABS.DETAILS,
          taskChannel: taskChannels[0],
          taskInitialData,
        },
      });
    } else {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          dealId,
          taskCategory: category,
          taskInitialData,
        },
      });
    }
  }, [show, dealId, updatePackage, taskChannels, category, packageId, tabId]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleOpenTask}
        data-testid={taskButtonDataTestid}
      >
        {`${taskChannels.length} task${taskChannels.length > 1 ? "s" : ""}`}
      </Button>
      {unreadLabel > 0 && (
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpenTaskChat}
          data-testid={taskMessagesButtonDataTestid}
        >
          {unreadLabel} message{unreadLabel > 1 ? "s" : ""}
        </Button>
      )}
    </>
  );
};

export default DueDiligenceCategoryOpenTaskButton;

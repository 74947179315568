import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import Text from "components/Text";
import toast from "components/Toast";

import { useOpenAIPromptResponseReady } from "entities/AISummaries/hooks";
import { useAssetManagementFileProcessingStatusUpdates } from "entities/AssetManagement/hooks";
import { useDueDiligenceFileProcessingStatusUpdates } from "entities/Package/hooks";
import {
  useDealCompanyImportTableSheetNameNotFound,
  useDealCompanyImportTableUpdateFailed,
  useDealTableUpdateSucceeded,
  useTableOrDealTableUpdateFailed,
  useTableResponseReady,
  useTableUpdateSucceeded,
} from "entities/TableStorage/hooks";

const GlobalNotifications = () => {
  const navigate = useNavigate();

  // Butler - DueDiligence notifications
  const dueDiligenceFileProcessingSuccessEventHandler = useCallback(
    ({
      dealId,
      packageId,
      pdfImportId,
    }: {
      dealId: number;
      packageId: number;
      pdfImportId: number;
    }) => {
      toast.successMessage(
        <>
          <Text variant="text2" component="span">
            Processing has been completed! Click{" "}
          </Text>
          <Text
            component="span"
            variant="subtitle1"
            color="primary"
            onClick={() =>
              navigate(
                reverse(URLS.DEAL_PACKAGE_EDIT_PREVIEW, {
                  dealId,
                  packageId,
                  pdfImportId,
                })
              )
            }
          >
            here
          </Text>{" "}
          <Text variant="text2" component="span">
            to view the results.
          </Text>
        </>,
        { autoClose: false }
      );
    },
    [navigate]
  );

  const dueDiligenceFileProcessingFailureEventHandler = useCallback(() => {
    toast.errorMessage("Processing failed. Please try again.");
  }, []);

  useDueDiligenceFileProcessingStatusUpdates({
    successEventHandler: dueDiligenceFileProcessingSuccessEventHandler,
    failureEventHandler: dueDiligenceFileProcessingFailureEventHandler,
  });

  // Butler - AssetManagement notifications
  const assetManagementFileProcessingSuccessEventHandler = useCallback(
    ({ dealId, importId }: { dealId: number; importId: number }) => {
      toast.successMessage(
        <>
          <Text variant="text2" component="span">
            Processing has been completed! Click{" "}
          </Text>
          <Text
            component="span"
            variant="subtitle1"
            color="primary"
            onClick={() =>
              navigate(
                reverse(URLS.DEAL_ASSET_MANAGEMENT_PDF_DIFF, {
                  dealId,
                  pdfId: importId,
                })
              )
            }
          >
            here
          </Text>{" "}
          <Text variant="text2" component="span">
            to view the results.
          </Text>
        </>,
        { autoClose: false }
      );
    },
    [navigate]
  );

  const assetManagementFileProcessingFailureEventHandler = useCallback(() => {
    toast.errorMessage("Processing failed. Please try again.");
  }, []);

  useAssetManagementFileProcessingStatusUpdates({
    successEventHandler: assetManagementFileProcessingSuccessEventHandler,
    failureEventHandler: assetManagementFileProcessingFailureEventHandler,
  });

  // openAI prompt - response
  const openAIPromptResponseReadyEventHandler = useCallback(
    ({
      dealId,
      openAIDocumentAssistantId,
      promptResponseId,
    }: {
      dealId: number;
      openAIDocumentAssistantId: number;
      promptResponseId: number;
    }) => {
      toast.successMessage(
        <>
          <Text variant="text2" component="span">
            Prompt response has been generated successfully! Click{" "}
          </Text>
          <Text
            component="span"
            variant="text2"
            color="primary"
            onClick={() =>
              navigate(
                `${reverse(URLS.DEAL_AI_SUMMARIES_EDITOR, {
                  dealId,
                  openAIDocumentAssistantId,
                })}?prompt-response-id=${promptResponseId}`
              )
            }
          >
            here
          </Text>{" "}
          <Text variant="text2" component="span">
            to view the answer.
          </Text>
        </>,
        { autoClose: false }
      );
    },
    [navigate]
  );

  useOpenAIPromptResponseReady({
    eventHandler: openAIPromptResponseReadyEventHandler,
  });

  const tableResponseSuccessEventHandler = useCallback(
    ({ tableResponseId }: { tableResponseId: number }) => {
      toast.successMessage(
        <>
          <Text variant="text2" component="span">
            Table report has been generated successfully! Click{" "}
          </Text>
          <Text
            component="span"
            variant="text2"
            color="primary"
            onClick={() =>
              navigate(
                reverse(URLS.TABLES_RESPONSE_DETAIL, {
                  tableResponseId,
                })
              )
            }
          >
            here
          </Text>{" "}
          <Text variant="text2" component="span">
            to view the report.
          </Text>
        </>,
        { autoClose: false }
      );
    },
    [navigate]
  );

  const tableResponseFailedEventHandler = useCallback(
    () => toast.errorMessage("Table report failed. Please try again."),
    []
  );

  useTableResponseReady({
    successEventHandler: tableResponseSuccessEventHandler,
    failureEventHandler: tableResponseFailedEventHandler,
  });

  const tableOrDealTableUpdateFailedEventHandler = useCallback(
    ({ details }) => {
      return toast.errorMessage(details, { autoClose: false });
    },
    []
  );

  const dealTableUpdateSucceededEventHandler = useCallback(
    ({ dealId, tableId, tableName }) => {
      toast.successMessage(
        <>
          <Text variant="text2" component="span">
            Table "{tableName}" has been updated successfully! Click{" "}
          </Text>
          <Text
            component="span"
            variant="text2"
            color="primary"
            onClick={() =>
              navigate(
                reverse(URLS.DEAL_TABLE_STORAGE_DETAILS, {
                  dealId,
                  tableId,
                })
              )
            }
          >
            here
          </Text>{" "}
          <Text variant="text2" component="span">
            to view the changes.
          </Text>
        </>,
        { autoClose: false }
      );
    },
    [navigate]
  );

  useDealTableUpdateSucceeded({
    successEventHandler: dealTableUpdateSucceededEventHandler,
  });

  const tableUpdateSucceededEventHandler = useCallback(
    ({ tableId, tableName }) => {
      toast.successMessage(
        <>
          <Text variant="text2" component="span">
            Table "{tableName}" has been updated successfully! Click{" "}
          </Text>
          <Text
            component="span"
            variant="text2"
            color="primary"
            onClick={() =>
              navigate(
                reverse(URLS.TABLE_STORAGE_DETAILS, {
                  tableId,
                })
              )
            }
          >
            here
          </Text>{" "}
          <Text variant="text2" component="span">
            to view the changes.
          </Text>
        </>,
        { autoClose: false }
      );
    },
    [navigate]
  );

  useTableUpdateSucceeded({
    successEventHandler: tableUpdateSucceededEventHandler,
  });

  useTableOrDealTableUpdateFailed({
    failureEventHandler: tableOrDealTableUpdateFailedEventHandler,
  });

  useDealCompanyImportTableSheetNameNotFound({
    eventHandler: ({ details }) =>
      toast.loading(details, {
        type: "info",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
        closeButton: true,
      }),
  });

  useDealCompanyImportTableUpdateFailed({
    eventHandler: ({ details }) =>
      toast.errorMessage(details, { autoClose: false }),
  });

  return null;
};

export default GlobalNotifications;

import { BASE_URL } from "config/urls";
import { patch, post } from "utils/sdk";

import { IUser } from "entities/User/sdk";

export type IEmployee = Omit<
  IUser,
  "company_id" | "unseen_notifications_count"
> & {
  is_active: boolean;
};

export const createCompanyUser = (user: Partial<IUser>): Promise<IUser> =>
  post<IUser>(`${BASE_URL}v2/users/create-company-user/`, user);

export const updateUserIsAdmin = (userId: number, isAdmin: boolean) =>
  patch<IEmployee>(`${BASE_URL}v2/users/update-admin/${userId}/`, {
    is_admin: isAdmin,
  });

export const addUserToAllDeal = (userId: number) =>
  post(`${BASE_URL}v2/users/user/${userId}/add-to-all-deals/`);

export const removeUserFromAllDeal = (userId: number) =>
  post(`${BASE_URL}v2/users/user/${userId}/remove-from-all-deals/`);

export const updateUserIsActive = ({
  userId,
  isActive,
}: {
  userId: number;
  isActive: boolean;
}) =>
  patch<IEmployee>(`${BASE_URL}v2/users/update-is-active/${userId}/`, {
    is_active: isActive,
  });

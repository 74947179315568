import {
  format as fnsFormat,
  formatISO as fnsFormatIso,
  isAfter as fnsIsAfter,
  isBefore as fnsIsBefore,
  isMatch as fnsIsMatch,
  isSameDay as fnsIsSameDay,
  isValid as fnsIsValid,
  parse as fnsParse,
  parseISO as fnsParseISO,
  parseJSON as fnsParseJSON,
} from "date-fns";
import { fromZonedTime as fnsFromZonedTime } from "date-fns-tz";
import _ from "lodash";

export const FORMATS = {
  BACKEND: {
    DATETIME: "yyyy-MM-dd'T'HH:mm:ssXXX",
    DATE: "yyyy-MM-dd",
    HOUR: "HH:mm:ss",
  },
  FRONTEND: {
    DATETIME: "h:mmaaa MMM dd, yyyy",
    DATE: "MMM dd, yyyy",
    HOUR: "h:mmaaa",
  },
};

export type IDate = Date;

export const getNow = () => new Date();

export const parseJSON = (dateStr: string) => {
  return fnsParseJSON(dateStr);
};

export const format = (date: IDate, format: string): string | undefined => {
  try {
    return fnsFormat(date, format);
  } catch (err: any) {
    console.error(date, format, err);
    return undefined;
  }
};

export const formatISO = fnsFormatIso;
export const isValid = fnsIsValid;

export const isDatetime = ({
  object,
  format = FORMATS.BACKEND.DATETIME,
}: {
  object: any;
  format?: string;
}) => {
  if (!_.isString(object)) {
    return false;
  }

  return fnsIsMatch(object, format);
};

export const parseISO = fnsParseISO;

const defaultTransformFunc = (dateString: string, format: string) =>
  parseISO(dateString);

export const deepTransformDates = ({
  data,
  transformFunc = defaultTransformFunc,
  format = FORMATS.BACKEND.DATETIME,
}: {
  data: any;
  transformFunc?: (dateString: string, format: string) => any;
  format?: string;
}): any => {
  if (_.isArray(data)) {
    return data.map((el) =>
      deepTransformDates({ data: el, format, transformFunc })
    );
  }

  if (_.isObject(data)) {
    return _.mapValues(data, (el) =>
      deepTransformDates({ data: el, format, transformFunc })
    );
  }

  if (isDatetime({ object: data, format })) {
    return transformFunc(data, format);
  }

  return data;
};

export const parse = fnsParse;

export const zonedTimeToUtc = fnsFromZonedTime;

export const isBefore = fnsIsBefore;

export const isAfter = fnsIsAfter;

export const isSameDay = fnsIsSameDay;

import React, { useCallback, useState } from "react";

import { colors } from "theme/palette";

import Tabs, { Tab } from "components/Tabs";

import { TAB_KEY as ASSIGNED_TEAM_TAB_KEY } from "entities/Deal/components/DealSidepanel/AssignedTeamTabContent";
import { TAB_KEY as PHASE_AND_STATUS_TAB_KEY } from "entities/Deal/components/DealSidepanel/PhaseAndStatusTabContent";
import { TAB_KEY as PROJECT_DETAILS_TAB_KEY } from "entities/Deal/components/DealSidepanel/ProjectDetailsTabContent";
const tabs = [
  { label: "Project Details", key: PROJECT_DETAILS_TAB_KEY },
  { label: "Assigned Team", key: ASSIGNED_TEAM_TAB_KEY },
  { label: "Phase & Status", key: PHASE_AND_STATUS_TAB_KEY },
];

interface IDealSidepanelTabs {
  onChange: (tab: string) => void;
}

const DealSidepanelTabs: React.FC<IDealSidepanelTabs> = ({ onChange }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  const handleChange = useCallback(
    (e: any, tabKey: string) => {
      setSelectedTab(tabKey);
      onChange(tabKey);
    },
    [onChange]
  );

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      sx={{ borderBottom: `1px solid ${colors.blue100}` }}
      TabIndicatorProps={{ sx: { height: "4px" } } as any}
      data-testid="lp-side-panel-tabs"
    >
      {tabs.map(({ label, key }) => (
        <Tab
          key={key}
          sx={{
            textTransform: "none",
            color: colors.gray100,
            padding: "9px 1.5em",
            fontSize: "16px",
            "&.Mui-selected": {
              color: colors.gray100,
              backgroundColor: colors.blue20,
            },
          }}
          label={label}
          value={key}
          data-testid={`${key}-tab`}
        />
      ))}
    </Tabs>
  );
};

export default DealSidepanelTabs;

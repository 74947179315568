import { useCallback, useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import ky from "ky";
import _ from "lodash";
import useSWR from "swr";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";

import { BASE_URL, URLS } from "config/urls";
import { getToken, removeToken } from "utils/storage";

import toast from "components/Toast";

import { formatGetParams } from "./urls";

export type IConfig = RequestInit & {
  isFileUpload?: boolean;
  useSharableToken?: boolean;
  timeout?: false | number;
};

export interface PaginatedResponse<T> {
  count: number;
  offset: number;
  limit: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
}

// Assuming headers should be of this type
type IHeaders = Record<string, string>;

const getSharableMemoAuthorizationHeader = (options?: IConfig): IHeaders => {
  const token = getToken();

  if (options?.useSharableToken && !token) {
    const match = matchPath<"token", string>(
      URLS.SHAREABLE_MEMO,
      window.location.pathname
    );

    return { Authorization: "MemoToken " + match?.params.token };
  }

  return {};
};

const getAuthorizationHeader = () => {
  const token = getToken();

  if (!token) {
    return {};
  }

  return { Authorization: `jwt ${token}` };
};

export const getConfig = (params?: IConfig) => {
  const { headers = {}, isFileUpload = false, ...options } = params || {};

  const contentType = isFileUpload ? undefined : "application/json";

  const config: IConfig = {
    headers: {
      "Content-Type": contentType,
      ...getAuthorizationHeader(),
      ...getSharableMemoAuthorizationHeader(params),
      ...headers,
    } as IHeaders,
    credentials: "include",
    timeout: false,
    ...options,
  };

  return config;
};

const handleInvalidToken = async (error: any) => {
  let errorData;

  if (!_.isUndefined(error.response?.json)) {
    errorData = await error.response.json();
  }

  if (error.response?.status === 401) {
    if (
      [
        "Signature has expired.",
        "Token has expired.",
        "Error decoding token.",
      ].includes(errorData.detail)
    ) {
      removeToken();
    }
  }

  return Promise.reject({
    status: error.response?.status || 503,
    ...(errorData || {
      detail: "Something went wrong",
      message: "Something went wrong",
    }),
  });
};

export const handleInvalidRequest = (
  error:
    | {
        [key: string]: Array<string>;
      }
    | null
    | undefined
) => {
  if (_.isNil(error)) {
    return;
  }

  let errorMessage: string | Array<string> = error?.message || error?.detail;

  if (_.isUndefined(errorMessage) && (_.isString(error) || _.isArray(error))) {
    errorMessage = error;
  }

  if (_.isString(errorMessage)) {
    toast.errorMessage(errorMessage);
  } else if (_.isArray(errorMessage)) {
    _.map(errorMessage, (fieldError: string) => toast.errorMessage(fieldError));
  } else {
    Object.values(error).forEach((fieldErrors: Array<string>) => {
      _.map(
        fieldErrors,
        (fieldError: string | { [key: string]: Array<string> }) => {
          if (_.isString(fieldError)) {
            toast.errorMessage(fieldError);
          } else {
            handleInvalidRequest(fieldError);
          }
        }
      );
    });
  }
};

export const get = async <R>(url: string, params?: IConfig): Promise<R> => {
  try {
    const response = await ky.get(url, getConfig(params));

    return await response.json<R>();
  } catch (error) {
    return handleInvalidToken(error);
  }
};

export const getText = async (
  url: string,
  params?: IConfig
): Promise<string> => {
  try {
    const response = await ky.get(url, getConfig(params));

    return await response.text();
  } catch (error) {
    return handleInvalidToken(error);
  }
};

export const post = async <R>(
  url: string,
  data?: any,
  params?: IConfig
): Promise<R> => {
  try {
    const response = await ky
      .post(url, {
        ...getConfig(params),
        json: data,
      })
      .json<R>();
    return response;
  } catch (error: any) {
    return handleInvalidToken(error);
  }
};

export const put = async <R>(
  url: string,
  data?: any,
  params?: IConfig
): Promise<R> => {
  try {
    const response = await ky
      .put(url, {
        ...getConfig(params),
        json: data,
      })
      .json<R>();
    return response;
  } catch (error: any) {
    return handleInvalidToken(error);
  }
};

export const patch = async <R>(
  url: string,
  data?: any,
  params?: IConfig
): Promise<R> => {
  try {
    const response = await ky
      .patch(url, {
        ...getConfig(params),
        json: data,
      })
      .json<R>();
    return response;
  } catch (error: any) {
    return handleInvalidToken(error);
  }
};

export const deleteCall = async <R>(
  url: string,
  params?: IConfig
): Promise<R> => {
  try {
    const response = await ky.delete(url, getConfig(params)).json<R>();
    return response;
  } catch (error: any) {
    return handleInvalidToken(error);
  }
};

export const useFetch = <D>(
  url: string | undefined,
  options?: {
    revalidateOnMount?: boolean;
    revalidateIfStale: boolean;
    revalidateOnFocus: boolean;
    revalidateOnReconnect: boolean;
  }
) => {
  const result = useSWR<D>(url && BASE_URL + url, get, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    ...options,
  });

  return { ...result, loading: !result.error && !result.data };
};

const publicGet = <D>(url: string) =>
  ky.get(url, getConfig()).then((res) => res.json() as D);

const publicGetText = <D>(url: string) =>
  ky.get(url, getConfig()).then((res) => res.text() as D);

export const usePublicFetch = <D>(url: string | undefined) => {
  const result = useSWR<D>(url && ((BASE_URL + url) as any), publicGet, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  return { ...result, loading: !result.error && !result.data };
};

export const usePublicFetchText = <D>(url: string | undefined) => {
  const result = useSWR<D>(url && ((BASE_URL + url) as any), publicGetText, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  return { ...result, loading: !result.error && !result.data };
};

export const usePaginatedFetch = <T>({
  url,
  params,
}: {
  url: string | undefined;
  params: {
    [key: string]: string | number | boolean | undefined | null;
    limit: number;
  };
}) => {
  const limit = params.limit;
  const [offset, setOffset] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchResult = useFetch<PaginatedResponse<T>>(
    !_.isNil(url)
      ? `${url}${formatGetParams({
          offset,
          ...params,
        })}`
      : undefined
  );

  const setPage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      setOffset(limit * (page - 1));
    },
    [limit]
  );

  useEffect(() => {
    if (!_.isUndefined(fetchResult.data)) {
      setPagesCount(Math.ceil(fetchResult.data.count / fetchResult.data.limit));
    }
  }, [fetchResult.data]);

  return {
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    pagesCount,
    setPagesCount,
    setPage,
    ...fetchResult,
  };
};

export interface IInfiniteFetchResult<T>
  extends Omit<SWRInfiniteResponse<PaginatedResponse<T>>, "data"> {
  total: number;
  data: Array<T>;
}

export const useInfiniteFetch = <T>({
  url,
}: {
  url: string;
}): IInfiniteFetchResult<T> => {
  const fetcher = (url: string) => get(url).then((res: any) => res);

  const getKey = (pageIndex: number) => {
    return `${url}${formatGetParams({
      offset: pageIndex * 10,
      limit: 10,
    })}`;
  };

  const { data, size, setSize, ...other } = useSWRInfinite<
    PaginatedResponse<T>
  >(getKey, fetcher, { revalidateAll: true, revalidateFirstPage: false });

  return {
    data: _.flatten(data?.map((d) => d.results) || []),
    total: data?.[0]?.count || 0,
    size,
    setSize,
    ...other,
  };
};

import { useCallback, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseISO } from "utils/datetime";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Loading from "components/Loading";
import {
  COMMON_EDITOR_CONTAINER_STYLES,
  COMMON_EDITOR_STYLES,
  EMPTY_VALUE_REGEX,
} from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import SmallAlert from "components/SmallAlert";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { IDealSummary } from "entities/Memo/sdk";

export type ISummaryValues = Pick<IDealSummary, "summary">;

export interface IEditFormDialog {
  open: boolean;
  title: string;
  summaryData: IDealSummary;
  onSave: (data: ISummaryValues) => Promise<any>;
  onClose: () => void;
}

const EditFormDialog = ({
  open,
  title,
  summaryData,
  onSave,
  onClose,
}: IEditFormDialog) => {
  const [saveTimer, setSaveTimer] = useState<NodeJS.Timeout | null>(null);

  const handleClose = useCallback(() => {
    if (!_.isNull(saveTimer)) {
      clearTimeout(saveTimer);
    }

    onClose();
  }, [onClose, saveTimer]);

  const handleSubmit = useCallback(
    (data: ISummaryValues) => {
      let submitData = data;

      if (EMPTY_VALUE_REGEX.test(data.summary)) {
        submitData = { ...data, summary: "" };
      }

      onSave(submitData).then(() => {
        handleClose();
        toast.successMessage(`${title} successfully updated!`);
      });
    },
    [onSave, handleClose, title]
  );

  const formik = useFormik({
    initialValues: {
      summary: summaryData.summary || "",
    },
    enableReinitialize: true,

    onSubmit: handleSubmit,
  });

  const handleChangeValue = useCallback(
    (newValue: string) => {
      formik.setFieldValue("summary", newValue);

      // Save the data every 3 seconds of inactivity.
      const newTimer = setTimeout(() => {
        const formattedData = EMPTY_VALUE_REGEX.test(newValue)
          ? { summary: "" }
          : { summary: newValue };

        onSave(formattedData);
      }, 3000);

      setSaveTimer((prevTimer) => {
        if (!_.isNull(prevTimer)) {
          clearTimeout(prevTimer);
        }

        return newTimer;
      });
    },
    [formik, onSave]
  );

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          sx={{
            margin: theme.spacing(2, 2, 0, 2),
            padding: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <EditIcon
              style={{
                height: "24px",
                width: "24px",
              }}
            />
            <Text variant="h2">{title}</Text>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            margin: theme.spacing(2, 2, 0, 2),
            padding: 0,
            overflowY: "hidden",
          }}
        >
          <Loading open={formik.isSubmitting} />
          <Box sx={COMMON_EDITOR_CONTAINER_STYLES}>
            <RichTextEditorField
              defaultValue={formik.getFieldProps("summary").value}
              onChange={handleChangeValue}
              helperText={
                formik.touched.summary && formik.errors.summary
                  ? formik.errors.summary
                  : ""
              }
              sx={COMMON_EDITOR_STYLES}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <SmallAlert title="Don’t forget to save your changes after editing!" />
            {!_.isNull(summaryData.updated_at) && (
              <Text
                variant="text3"
                component="span"
                sx={{ color: colors.gray80 }}
              >
                Last saved at{" "}
                {format(
                  parseISO(summaryData.updated_at),
                  FORMATS.FRONTEND.DATETIME
                )}
              </Text>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit">Save changes</Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditFormDialog;

import { NavigateFunction } from "react-router";
import _ from "lodash";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import Text from "components/Text";
import toast from "components/Toast";

export const handleInvalidMemoFieldRequest = ({
  error,
  dealId,
  navigate,
}: {
  error:
    | {
        [key: string]: Array<string>;
      }
    | null
    | undefined;
  dealId: Number;
  navigate: NavigateFunction;
}) => {
  if (_.isNil(error)) {
    return;
  }

  let errorMessage: string | Array<string> = error?.message || error?.detail;

  if (_.isUndefined(errorMessage) && (_.isString(error) || _.isArray(error))) {
    errorMessage = error;
  }

  if (_.isString(errorMessage)) {
    if (errorMessage.startsWith("MemoField error. ")) {
      const detail = errorMessage.replace("MemoField error. ", "");
      // As we expect a message formatted like "MemoField error. {package_name}" we want to use a CustomErrorMessage instead
      // We are using a JSX format in order to enable links inside of the custom message
      // Therefore we need to extract a new utils that has .tsx extension
      toast.errorMessage(
        <>
          <Text>
            The deal does not include {detail}. To edit this memo, you must add
            the checklist{" "}
            <Text
              component="span"
              variant="subtitle1"
              color="primary"
              onClick={() =>
                navigate(
                  reverse(URLS.DEAL_CHECKISTS_OVERVIEW, {
                    dealId: dealId.toString(),
                  })
                )
              }
            >
              here.
            </Text>
          </Text>
        </>
      );
    } else {
      toast.errorMessage(errorMessage);
    }
  }
};

import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { ArrowDownwardIcon, ArrowUpwardIcon, DeleteIcon } from "icons";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";

import Box from "components/Box";
import ButtonGroup from "components/ButtonGroup";
import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import EditSectionButton from "entities/MemoTemplate/components/EditSectionButton";
import {
  deleteMemoTemplateSection,
  IMemoTemplateSection,
  updateMemoTemplateSectionsOrder,
  useMemoTemplateDetails,
} from "entities/MemoTemplate/sdk";

interface IEditorRightsideMenu {
  section: IMemoTemplateSection;
}

const EditorRightsideMenu = ({ section }: IEditorRightsideMenu) => {
  const params = useParams<{ memoId: string }>();
  const memoId = Number(params.memoId);
  const { data: memoTemplate, mutate: mutateMemo } = useMemoTemplateDetails({
    memo_id: memoId,
  });

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleDelete = useCallback(() => {
    showConfirmationDialog({
      onConfirm: () =>
        deleteMemoTemplateSection({
          memoSectionId: section.memo_section_id,
        }).then(() => {
          mutateMemo();
        }),
      message: "Are you sure you want to delete this section?",
    });
  }, [mutateMemo, section.memo_section_id, showConfirmationDialog]);

  const handleReorderUp = useCallback(() => {
    const sections_order = memoTemplate
      ? memoTemplate.sections.map((section) => section.memo_section_id)
      : [];

    const before = _.takeWhile(
      sections_order,
      (section_id) => section_id !== section.memo_section_id
    );

    const after = _.dropWhile(
      sections_order,
      (section_id) => section_id !== section.memo_section_id
    );

    const sectionsOrder = [
      ..._.dropRight(before),
      ...[_.first(after), _.last(before)],
      ..._.drop(after),
    ].filter(isNotUndefined);

    const sections = memoTemplate
      ? memoTemplate.sections.reduce(
          (prev, curr) => ({ [curr.memo_section_id]: curr, ...prev }),
          {}
        )
      : {};

    mutateMemo(
      (memo) =>
        memo
          ? {
              ...memo,
              sections: sectionsOrder.map(
                (id) => _.get(sections, id, {}) as IMemoTemplateSection
              ),
            }
          : undefined,
      { revalidate: false }
    );

    updateMemoTemplateSectionsOrder({
      memoId: memoId,
      sectionsOrder,
    }).then(() => {
      // mutateMemo();
    });
  }, [section, memoTemplate, memoId, mutateMemo]);

  const handleReorderDown = useCallback(() => {
    const sections_order = memoTemplate
      ? memoTemplate.sections.map((section) => section.memo_section_id)
      : [];

    const before = _.takeWhile(
      sections_order,
      (section_id) => section_id !== section.memo_section_id
    );

    const after = _.dropWhile(
      sections_order,
      (section_id) => section_id !== section.memo_section_id
    );

    const sectionsOrder = [
      ...before,
      ...[_.first(_.tail(after)), _.first(after)],
      ..._.drop(after, 2),
    ].filter(isNotUndefined);

    const sections = memoTemplate
      ? memoTemplate.sections.reduce(
          (prev, curr) => ({ [curr.memo_section_id]: curr, ...prev }),
          {}
        )
      : {};

    mutateMemo(
      (memo) =>
        memo
          ? {
              ...memo,
              sections: sectionsOrder.map(
                (id) => _.get(sections, id, {}) as IMemoTemplateSection
              ),
            }
          : undefined,
      { revalidate: false }
    );

    updateMemoTemplateSectionsOrder({
      memoId: memoId,
      sectionsOrder,
    }).then(() => {
      // mutateMemo();
    });
  }, [section, memoTemplate, memoId, mutateMemo]);

  return (
    <Box
      className="editableSectionToolset"
      sx={{
        opacity: 0.4,
        position: "absolute",
        right: "-45px",
        height: "100%",
      }}
    >
      <Box
        className="editableSectionToolsetBracket"
        sx={{
          opacity: 0,
          position: "absolute",
          height: "calc(100% + 20px)",
          top: "-10px",
          width: "20px",
          left: "-20px",
          borderTop: `2px solid ${colors.blue20}`,
          borderRight: `2px solid ${colors.blue20}`,
          borderBottom: `2px solid ${colors.blue20}`,
        }}
      ></Box>

      <ButtonGroup orientation="vertical" variant="contained">
        <Tooltip disableInteractive placement="left" title="Move up">
          <IconButton sx={{ borderRadius: 0 }} onClick={handleReorderUp}>
            <ArrowUpwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip disableInteractive placement="left" title="Move down">
          <IconButton sx={{ borderRadius: 0 }} onClick={handleReorderDown}>
            <ArrowDownwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip disableInteractive placement="left" title="Edit">
          <EditSectionButton section={section} />
        </Tooltip>
        <Tooltip disableInteractive placement="left" title="Delete">
          <IconButton
            onClick={handleDelete}
            sx={{
              borderRadius: 0,
              "&:hover": { opacity: 1, backgroundColor: colors.red120 },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
};

export default EditorRightsideMenu;

import { useParams } from "react-router";

import { colors } from "theme/palette";

import Text from "components/Text";

import DownhomeCard from "entities/CompanyItegrations/components/Downhome/Card";
import { ICompanyIntegration } from "entities/CompanyItegrations/sdk";

interface IIntegrationsList {
  integrations: Array<ICompanyIntegration>;
}

const IntegrationsList = ({ integrations }: IIntegrationsList) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  if (integrations.length === 0) {
    return (
      <Text variant="text3" color={colors.gray80}>
        Your organization doesn’t have any integrations with 3rd party softwares
        set up.
      </Text>
    );
  }

  return (
    <>
      {integrations.map((integration, index) => (
        <DownhomeCard key={index} dealId={dealId} integration={integration} />
      ))}
    </>
  );
};

export default IntegrationsList;

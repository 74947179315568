import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { PDF_MIME_TYPE } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import UploadButton from "components/UploadButton";
import { defaultPDFUploadButton } from "components/UploadButton/utils";

import { ButlerQueueType } from "entities/AssetManagement/constants";
import {
  assetManagemnetProcessPdf,
  useButlerQueuesList,
} from "entities/AssetManagement/sdk";
import { upload } from "entities/File/sdk";

export interface IUploadAndParseDialog {
  open: boolean;
  dealId: number;
  onClose: () => void;
}

const UploadAndParseDialog = ({
  open = false,
  dealId,
  onClose,
}: IUploadAndParseDialog) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFileError, setShowFileError] = useState("");
  const [selectedFile, setSelectedFile] = useState<undefined | File>(undefined);
  const [selectedQueue, setSelectedQueue] = useState<string | undefined>();

  const { data: queuesList } = useButlerQueuesList({
    queueType: ButlerQueueType.ASSET_MANAGEMENT,
  });

  useEffect(() => {
    setSelectedQueue(
      _.first(queuesList?.map((queue) => String(queue.id)) || [])
    );
  }, [queuesList]);

  const handleSelectFile = useCallback((file: File) => {
    if (PDF_MIME_TYPE.includes(file.type)) {
      setShowFileError("");
      setSelectedFile(file);
    } else {
      setShowFileError(`Supported file type is: ${PDF_MIME_TYPE}`);
    }
  }, []);

  const handleFileUpload = useCallback(() => {
    if (_.isNil(selectedQueue) || _.isNil(selectedFile)) {
      return;
    }

    setIsSubmitting(true);

    const toastId = toast.loading("Uploading file...");
    const file = selectedFile;

    upload({ file, isPrivate: true })
      .then((fileId) => {
        assetManagemnetProcessPdf({
          dealId,
          fileId,
          queueId: Number(selectedQueue),
        })
          .then(() => {
            setSelectedFile(undefined);
            onClose();

            toast.update(toastId, {
              render: "You will be notified once the processing has finished.",
              type: "success",
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              isLoading: false,
              closeButton: true,
            });
          })
          .catch((error) => {
            toast.update(toastId, {
              render: _.get(
                error,
                "message",
                "Something went wrong. Please try again later."
              ),
              type: "error",
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              isLoading: false,
              closeButton: true,
            });
          });
      })
      .catch(handleInvalidRequest)
      .finally(() => setIsSubmitting(false));
  }, [dealId, onClose, selectedQueue, selectedFile]);

  const handleClose = useCallback(() => {
    setSelectedFile(undefined);
    setShowFileError("");
    onClose();
  }, [onClose]);

  const queueOptions = useMemo(() => {
    if (_.isNil(queuesList)) {
      return [];
    }

    return queuesList.map((queue) => ({
      value: String(queue.id),
      label: queue.name,
    }));
  }, [queuesList]);

  const isSubmitButtonDisabled = useMemo(
    () => _.isNil(selectedQueue) || _.isNil(selectedFile) || isSubmitting,
    [selectedQueue, selectedFile, isSubmitting]
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      data-testid="asset-mgmt-upload-file-dialog"
    >
      <DialogTitle
        sx={{
          margin: theme.spacing(2, 2, 3, 2),
          borderRadius: theme.spacing(1),
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Text variant="h1" data-testid="dialog-title">
          Upload new data
        </Text>
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={4}
          alignItems="center"
          sx={{ marginTop: theme.spacing(1) }}
        >
          {!_.isNil(queuesList) && (
            <Select
              fullWidth
              label="Document type"
              onChange={(event) => {
                setSelectedQueue(event.target.value);
              }}
              value={selectedQueue}
              options={queueOptions}
              data-testid="am-queue-dropdown"
              inputProps={{ "data-testid": "am-queue-input" }}
              dropdownOptionsDataTestid="am-queue-dropdown-option"
            />
          )}
          <UploadButton
            maxFiles={1}
            onChange={({ files }) => {
              handleSelectFile(files[0]);
            }}
            accept={PDF_MIME_TYPE}
            renderComponent={(state) =>
              defaultPDFUploadButton({
                state,
                errors: showFileError,
              })
            }
            dataTestid="upload-asset-mgmt-file-input"
          />
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ height: "23px" }} // We want to keep the height of the section when there is no file selected
          >
            {!_.isUndefined(selectedFile) && (
              <Text
                title={selectedFile.name}
                variant="text2"
                data-testid="uploaded-file-name"
                noWrap
              >
                {selectedFile.name}
              </Text>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
        data-testid="dialog-actions-buttons"
      >
        <Button
          color="secondary"
          onClick={handleClose}
          data-testid="close-dialog-button"
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitButtonDisabled}
          onClick={handleFileUpload}
          data-testid="submit-dialog-button"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadAndParseDialog;

import { memo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { TABLE_CELL_STYLES, TABLE_HEADER_STYLES } from "utils/constants";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import { HEADERS } from "entities/Superuser/components/FieldValueTable/constants";

const FieldValueTableSkeleton = () => {
  return (
    <Stack
      spacing={1}
      sx={{
        padding: theme.spacing(2, 0),
        overflowX: "auto",
        maxWidth: "100%",
        "table, th, td": {
          border: `1px solid ${colors.blue20}`,
          borderCollapse: "collapse",
        },
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Skeleton variant="text" width={200} height={30} />
        <Skeleton variant="text" width={50} />
        <Skeleton variant="text" width={50} />
      </Stack>
      <table>
        <thead>
          <tr>
            {HEADERS.map((header, index) => (
              <td
                key={index}
                style={{
                  ...TABLE_HEADER_STYLES,
                  position: "sticky",
                  left: 0,
                }}
              >
                <Text
                  variant="labelSmall"
                  fontWeight={500}
                  sx={{ color: colors.gray60 }}
                >
                  {header}
                </Text>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {_.range(HEADERS.length).map((index) => (
              <td style={TABLE_CELL_STYLES} key={index}>
                <Skeleton variant="text" width={50} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Stack>
  );
};

export default memo(FieldValueTableSkeleton);

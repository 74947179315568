import React, { useCallback } from "react";

import {
  CloseIcon,
  DeleteIcon,
  EditIcon,
  RadioButtonUncheckedIcon,
} from "icons";
import theme from "theme";

import IconButton from "components/IconButton";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import NoteInputField from "entities/Note/components/NoteInputField";
import { INote, updateNote, useNoteList } from "entities/Note/sdk";

interface IUncompletedNote {
  isEditable: boolean;
  dealId: number;
  note: INote;
  changeCompletedStatus: (note: INote) => void;
  onDelete: (note: INote) => void;
  changeEditable: (state: boolean) => void;
}

const UncompletedNote: React.FC<IUncompletedNote> = ({
  isEditable = false,
  dealId,
  note,
  changeCompletedStatus,
  onDelete,
  changeEditable,
}) => {
  const { mutate: updateNotes } = useNoteList(dealId);

  const handleSubmit = useCallback(
    (noteTitle: string) =>
      updateNote(note.id, { title: noteTitle, is_completed: note.is_completed })
        .then(() => {
          changeEditable(false);
          updateNotes();
          toast.successMessage("The note has been updated successfully!");
        })
        .catch(),
    [note, updateNotes, changeEditable]
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        margin: 0,
        padding: theme.spacing(1, 2),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-betweeen"
        spacing={1}
      >
        <Stack direction="row" alignItems="flex-start" spacing={1} flex={1}>
          <RadioButtonUncheckedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => changeCompletedStatus(note)}
          />
          {!isEditable && (
            <Text variant="text2" sx={{ flex: 1 }}>
              {note.title}
            </Text>
          )}
          {isEditable && (
            <NoteInputField initialValue={note.title} onSubmit={handleSubmit} />
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          {!isEditable && (
            <IconButton onClick={() => changeEditable(true)}>
              <EditIcon />
            </IconButton>
          )}
          {isEditable && (
            <IconButton onClick={() => changeEditable(false)}>
              <CloseIcon />
            </IconButton>
          )}
          <IconButton onClick={() => onDelete(note)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default UncompletedNote;

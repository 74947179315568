import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Button from "components/Button";
import { CircularProgress } from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { EInvitationStatus, useDealInvitation } from "entities/Deal/sdk";
import { invitationAccept, invitationReject } from "entities/Team/sdk";

const InvitationAccept = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const params = useParams<{ invitationId: string }>();
  const invitationId = params.invitationId as string;

  const { data: invitation, error } = useDealInvitation({ invitationId });
  const { show: showConfirmationDialog } = useConfirmationDialog();

  useEffect(() => {
    if (error?.detail === "Not found.") {
      navigate(URLS.NOT_FOUND);
    }
  }, [error, navigate]);

  const handleReject = useCallback(() => {
    if (!invitation) {
      return;
    }

    showConfirmationDialog({
      onConfirm: () => {
        setIsSubmitting(true);

        invitationReject({ invitationId })
          .then(() => {
            toast.successMessage("Invitation has been rejected successfully");
            navigate(reverse(URLS.PROJECTS_LIST, { phase: "All" }));
          })
          .catch(handleInvalidRequest)
          .finally(() => setIsSubmitting(false));
      },
      message:
        "Are you sure you want to reject this invitation? This action cannot be reverted.",
      confirmButtonText: "Reject invitation",
    });
  }, [showConfirmationDialog, invitation, navigate, invitationId]);

  const handleAccept = useCallback(() => {
    if (!invitation) {
      return;
    }

    setIsSubmitting(true);
    invitationAccept({ invitationId })
      .then(() => {
        toast.successMessage("Invitation has been accepted successfully");
        navigate(reverse(URLS.DEAL_OVERVIEW, { dealId: invitation.deal.id }));
      })
      .catch((errors) => {
        const errorMessage = errors?.message || errors?.detail;

        if (!_.isNil(errorMessage)) {
          toast.errorMessage(errorMessage);
        } else {
          errors?.forEach?.((error: unknown) => {
            if (_.isString(error)) {
              toast.errorMessage(error);
            }
          });
        }
      })
      .finally(() => setIsSubmitting(false));
  }, [invitation, navigate, invitationId]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ flexGrow: 1 }}
      data-testid="deal-onboarding-content"
    >
      {_.isNil(invitation) && <CircularProgress />}

      {invitation?.status === EInvitationStatus.PENDING && (
        <>
          <Text
            variant="h1"
            textAlign="center"
            paddingTop={20}
            paddingLeft={20}
            paddingRight={20}
            data-testid="invitation-message"
          >
            You have been invited to join "
            <span data-testid="deal-name">{invitation.deal.name}</span>".
          </Text>

          <Stack
            direction="row"
            marginTop={6}
            spacing={2}
            data-testid="invitation-action-buttons"
          >
            <Button
              variant="text"
              onClick={handleReject}
              data-testid="reject-button"
              disabled={isSubmitting}
            >
              Reject invitation
            </Button>
            <Button
              onClick={handleAccept}
              data-testid="accept-button"
              disabled={isSubmitting}
            >
              Accept invitation
            </Button>
          </Stack>
        </>
      )}

      {invitation?.status === EInvitationStatus.ACCEPTED && (
        <Text variant="h1" data-testid="invitation-accepted-message">
          This invitation has already been accepted!
        </Text>
      )}

      {invitation?.status === EInvitationStatus.REJECTED && (
        <Text variant="h1" data-testid="invitation-rejected-message">
          This invitation has already been rejected!
        </Text>
      )}

      {(invitation?.status === EInvitationStatus.CANCELED ||
        invitation?.status === EInvitationStatus.INVALIDATED) && (
        <Text variant="h1" data-testid="invitation-canceled-message">
          This invitation has been canceled!
        </Text>
      )}
    </Stack>
  );
};

export default InvitationAccept;

import { IProforma } from "entities/Proforma/sdk";

export const proformaDemoData: IProforma = {
  error: "",
  core_data: {
    total_units: 254,
    total_s_f: 167350,
    uses_permanent_data: {
      Developer_Fees: {
        unit: "USD",
        value: 10890995,
        position: 5,
        percentage: 13.8145366588832,
      },
      Acquisition_Cost: {
        unit: "USD",
        value: 4397288,
        position: 0,
        percentage: 5.577681036091486,
      },
      Total_Hard_Costs: {
        unit: "USD",
        value: 50188334,
        position: 1,
        percentage: 63.66071969469036,
      },
      Total_Soft_Costs: {
        unit: "USD",
        value: 10945669,
        position: 3,
        percentage: 13.883887161503742,
      },
      Total_Hard_Cost_Contingency: {
        unit: "USD",
        value: 2074308,
        position: 2,
        percentage: 2.6311281850569848,
      },
      Total_Soft_Cost_Contingency: {
        unit: "USD",
        value: 340614,
        position: 4,
        percentage: 0.432047263774232,
      },
    },
    total_uses_permanent_data: 78837208.0,
    sources_permanent_data: {
      FSNNY_Equity: {
        unit: "USD",
        value: 1062238,
        position: 6,
        percentage: 1.3473815561809341,
      },
      LIHTC_Equity: {
        unit: "USD",
        value: 23595008,
        position: 4,
        percentage: 29.928771703838112,
      },
      "Deferred_Developer's_Fee": {
        unit: "USD",
        value: 1187804,
        position: 5,
        percentage: 1.506654066186616,
      },
      "First_Mortgage_(Waumandee_State_Bank)": {
        unit: "USD",
        value: 30798076,
        position: 0,
        percentage: 39.065406781021466,
      },
      "Third_Mortgage_(Dalton_Whitfield_Bank)": {
        unit: "USD",
        value: 6897465,
        position: 2,
        percentage: 8.74899704718107,
      },
      "Fourth_Mortgage_(Doolin_Security_Savings_Bank)": {
        unit: "USD",
        value: 2142761,
        position: 3,
        percentage: 2.717956475576862,
      },
      "Second_Mortgage_(Bank_Of_HighwoodsymdashFort_Sheridan)": {
        unit: "USD",
        value: 13153856,
        position: 1,
        percentage: 16.68483237001493,
      },
    },
    total_sources_permanent_data: 78837208.0,
    uses_at_construction_data: {
      Acquisition_Cost: {
        unit: "USD",
        value: 4397288,
        position: 0,
        percentage: 5.577681036091486,
      },
      Total_Hard_Costs: {
        unit: "USD",
        value: 50188334,
        position: 1,
        percentage: 63.66071969469036,
      },
      Total_Soft_Costs: {
        unit: "USD",
        value: 10945669,
        position: 3,
        percentage: 13.883887161503742,
      },
      Developer_FeesymbyProfit: {
        unit: "USD",
        value: 10890995,
        position: 5,
        percentage: 13.8145366588832,
      },
      Total_Hard_Cost_Contingency: {
        unit: "USD",
        value: 2074308,
        position: 2,
        percentage: 2.6311281850569848,
      },
      Total_Soft_Cost_Contingency: {
        unit: "USD",
        value: 340614,
        position: 4,
        percentage: 0.432047263774232,
      },
    },
    total_uses_at_construction_data: 78837208.0,
    sources_at_construction_data: {
      FSNNY_Equity: {
        unit: "USD",
        value: 1857550,
        position: 6,
        percentage: 2.356184404703931,
      },
      LIHTC_Equity: {
        unit: "USD",
        value: 2558724,
        position: 4,
        percentage: 3.245579168658535,
      },
      Deferred_Reserves: {
        unit: "USD",
        value: 31160935,
        position: 7,
        percentage: 39.52567041694323,
      },
      "Deferred_Developer's_Fee": {
        unit: "USD",
        value: 4324600,
        position: 5,
        percentage: 5.485480916574316,
      },
      "First_Mortgage_(Waumandee_State_Bank)": {
        unit: "USD",
        value: 28110482,
        position: 0,
        percentage: 35.656364187833745,
      },
      "Third_Mortgage_(Dalton_Whitfield_Bank)": {
        unit: "USD",
        value: 7386569,
        position: 2,
        percentage: 9.369394461559319,
      },
      "Fourth_Mortgage_(Doolin_Security_Savings_Bank)": {
        unit: "USD",
        value: 2537799,
        position: 3,
        percentage: 3.2190371328218523,
      },
      "Second_Mortgage_(Bank_Of_HighwoodsymdashFort_Sheridan)": {
        unit: "USD",
        value: 900549,
        position: 1,
        percentage: 1.1422893109050742,
      },
    },
    total_sources_at_construction_data: 78837208.0,
  },
  rental_data: {
    rental_data: {
      summary: {
        total_monthly_income: 180933,
        total_annual_income: 2171196,
        rent_range: {
          min_rent: 0,
          max_rent: 933,
        },
        total_units: 254,
      },
      other_income: {
        data: {},
        total: {
          monthly: 0,
          annualy: 0,
        },
      },
      unit_type_and_floor_area: {
        data: [
          {
            position: 1,
            bed: 0,
            bath: 1,
            numberofunits: 21,
            sqf_area: 500,
            total_sqf: 10500,
            rent_cap: "30% AMI",
            name: "Studio (30% AMI) ",
            rent: 1.184,
          },
          {
            position: 2,
            bed: 0,
            bath: 1,
            numberofunits: 21,
            sqf_area: 500,
            total_sqf: 10500,
            rent_cap: "40% AMI",
            name: "Studio (40% AMI) ",
            rent: 1.066,
          },
          {
            position: 3,
            bed: 0,
            bath: 1,
            numberofunits: 10,
            sqf_area: 500,
            total_sqf: 5000,
            rent_cap: "50% AMI",
            name: "Studio (50% AMI) ",
            rent: 1.606,
          },
          {
            position: 4,
            bed: 0,
            bath: 1,
            numberofunits: 11,
            sqf_area: 500,
            total_sqf: 5500,
            rent_cap: "60% AMI",
            name: "Studio (60% AMI) ",
            rent: 1.372,
          },
          {
            position: 14,
            bed: 0,
            bath: 1,
            numberofunits: 23,
            sqf_area: 500,
            total_sqf: 11500,
            rent_cap: "Market rate",
            name: "Studio (Market rate)",
            rent: 1.396,
          },
          {
            position: 5,
            bed: 1,
            bath: 1,
            numberofunits: 11,
            sqf_area: 650,
            total_sqf: 7150,
            rent_cap: "30% AMI",
            name: "1 Bedroom (30% AMI) ",
            rent: 1.2353846153846153,
          },
          {
            position: 6,
            bed: 1,
            bath: 1,
            numberofunits: 15,
            sqf_area: 650,
            total_sqf: 9750,
            rent_cap: "40% AMI",
            name: "1 Bedroom (40% AMI) ",
            rent: 0.823076923076923,
          },
          {
            position: 7,
            bed: 1,
            bath: 1,
            numberofunits: 13,
            sqf_area: 650,
            total_sqf: 8450,
            rent_cap: "50% AMI",
            name: "1 Bedroom (50% AMI) ",
            rent: 0.8984615384615384,
          },
          {
            position: 8,
            bed: 1,
            bath: 1,
            numberofunits: 18,
            sqf_area: 650,
            total_sqf: 11700,
            rent_cap: "60% AMI",
            name: "1 Bedroom (60% AMI) ",
            rent: 1.0907692307692307,
          },
          {
            position: 15,
            bed: 1,
            bath: 1,
            numberofunits: 10,
            sqf_area: 650,
            total_sqf: 6500,
            rent_cap: "Market rate",
            name: "1 Bedroom (Market rate)",
            rent: 1.0353846153846153,
          },
          {
            position: 9,
            bed: 2,
            bath: 1,
            numberofunits: 25,
            sqf_area: 800,
            total_sqf: 20000,
            rent_cap: "30% AMI",
            name: "2 Bedroom (30% AMI) ",
            rent: 1.01875,
          },
          {
            position: 10,
            bed: 2,
            bath: 1,
            numberofunits: 25,
            sqf_area: 800,
            total_sqf: 20000,
            rent_cap: "40% AMI",
            name: "2 Bedroom (40% AMI) ",
            rent: 1.16625,
          },
          {
            position: 11,
            bed: 2,
            bath: 1,
            numberofunits: 18,
            sqf_area: 800,
            total_sqf: 14400,
            rent_cap: "50% AMI",
            name: "2 Bedroom (50% AMI) ",
            rent: 0.8775,
          },
          {
            position: 12,
            bed: 2,
            bath: 1,
            numberofunits: 10,
            sqf_area: 800,
            total_sqf: 8000,
            rent_cap: "60% AMI",
            name: "2 Bedroom (60% AMI) ",
            rent: 0.9175,
          },
          {
            position: 13,
            bed: 2,
            bath: 1,
            numberofunits: 2,
            sqf_area: 800,
            total_sqf: 1600,
            rent_cap: "Super's unit",
            name: "Super Unit (30% AMI)",
            rent: 0,
          },
          {
            position: 16,
            bed: 2,
            bath: 1,
            numberofunits: 21,
            sqf_area: 800,
            total_sqf: 16800,
            rent_cap: "Market rate",
            name: "2 Bedroom (Market rate)",
            rent: 1.075,
          },
        ],
        total: {
          units: {
            type: "total",
            value: 254,
          },
          sqf_area: {
            type: "average",
            value: "-",
          },
          total_sqf: {
            type: "total",
            value: 167350,
          },
          rent: {
            type: "average",
            value: "-",
          },
          rent_cap: {
            type: "average",
            value: "-",
          },
        },
      },
      new_unit_mix_data: {
        data: {
          rent_type: {
            "30% AMI": 57,
            "40% AMI": 61,
            "50% AMI": 41,
            "60% AMI": 39,
            "Market rate": 54,
            "Super's unit": 2,
          },
          unit_type: {
            studio: 86,
            bedroom_1: 67,
            bedroom_2: 101,
          },
          affordable: 198,
          market_rate: 54,
          total_units: 254,
        },
      },
      rental_income: {
        data: [
          {
            position: 1,
            bed: 0,
            bath: 1,
            numberofunits: 21,
            gross_monthly_rent: 662,
            utility_allowance: 70,
            net_rent_per_month: 592,
            total_annual_rent: 149184,
            rent_cap: "30% AMI",
            name: "Studio (30% AMI) ",
          },
          {
            position: 2,
            bed: 0,
            bath: 1,
            numberofunits: 21,
            gross_monthly_rent: 603,
            utility_allowance: 70,
            net_rent_per_month: 533,
            total_annual_rent: 134316,
            rent_cap: "40% AMI",
            name: "Studio (40% AMI) ",
          },
          {
            position: 3,
            bed: 0,
            bath: 1,
            numberofunits: 10,
            gross_monthly_rent: 871,
            utility_allowance: 68,
            net_rent_per_month: 803,
            total_annual_rent: 96360,
            rent_cap: "50% AMI",
            name: "Studio (50% AMI) ",
          },
          {
            position: 4,
            bed: 0,
            bath: 1,
            numberofunits: 11,
            gross_monthly_rent: 749,
            utility_allowance: 63,
            net_rent_per_month: 686,
            total_annual_rent: 90552,
            rent_cap: "60% AMI",
            name: "Studio (60% AMI) ",
          },
          {
            position: 5,
            bed: 1,
            bath: 1,
            numberofunits: 11,
            gross_monthly_rent: 870,
            utility_allowance: 67,
            net_rent_per_month: 803,
            total_annual_rent: 105996,
            rent_cap: "30% AMI",
            name: "1 Bedroom (30% AMI) ",
          },
          {
            position: 6,
            bed: 1,
            bath: 1,
            numberofunits: 15,
            gross_monthly_rent: 605,
            utility_allowance: 70,
            net_rent_per_month: 535,
            total_annual_rent: 96300,
            rent_cap: "40% AMI",
            name: "1 Bedroom (40% AMI) ",
          },
          {
            position: 7,
            bed: 1,
            bath: 1,
            numberofunits: 13,
            gross_monthly_rent: 656,
            utility_allowance: 72,
            net_rent_per_month: 584,
            total_annual_rent: 91104,
            rent_cap: "50% AMI",
            name: "1 Bedroom (50% AMI) ",
          },
          {
            position: 8,
            bed: 1,
            bath: 1,
            numberofunits: 18,
            gross_monthly_rent: 776,
            utility_allowance: 67,
            net_rent_per_month: 709,
            total_annual_rent: 153144,
            rent_cap: "60% AMI",
            name: "1 Bedroom (60% AMI) ",
          },
          {
            position: 9,
            bed: 2,
            bath: 1,
            numberofunits: 25,
            gross_monthly_rent: 889,
            utility_allowance: 74,
            net_rent_per_month: 815,
            total_annual_rent: 244500,
            rent_cap: "30% AMI",
            name: "2 Bedroom (30% AMI) ",
          },
          {
            position: 10,
            bed: 2,
            bath: 1,
            numberofunits: 25,
            gross_monthly_rent: 1013,
            utility_allowance: 80,
            net_rent_per_month: 933,
            total_annual_rent: 279900,
            rent_cap: "40% AMI",
            name: "2 Bedroom (40% AMI) ",
          },
          {
            position: 11,
            bed: 2,
            bath: 1,
            numberofunits: 18,
            gross_monthly_rent: 775,
            utility_allowance: 73,
            net_rent_per_month: 702,
            total_annual_rent: 151632,
            rent_cap: "50% AMI",
            name: "2 Bedroom (50% AMI) ",
          },
          {
            position: 12,
            bed: 2,
            bath: 1,
            numberofunits: 10,
            gross_monthly_rent: 805,
            utility_allowance: 71,
            net_rent_per_month: 734,
            total_annual_rent: 88080,
            rent_cap: "60% AMI",
            name: "2 Bedroom (60% AMI) ",
          },
          {
            position: 14,
            bed: 0,
            bath: 1,
            numberofunits: 23,
            gross_monthly_rent: 698,
            utility_allowance: 0,
            net_rent_per_month: 698,
            total_annual_rent: 192648,
            rent_cap: "Market rate",
            name: "Studio (Market rate)",
          },
          {
            position: 13,
            bed: 2,
            bath: 1,
            numberofunits: 2,
            gross_monthly_rent: 0,
            utility_allowance: 0,
            net_rent_per_month: 0,
            total_annual_rent: 0,
            rent_cap: "Super's unit",
            name: "Super Unit (30% AMI)",
          },
          {
            position: 15,
            bed: 1,
            bath: 1,
            numberofunits: 10,
            gross_monthly_rent: 673,
            utility_allowance: 0,
            net_rent_per_month: 673,
            total_annual_rent: 80760,
            rent_cap: "Market rate",
            name: "1 Bedroom (Market rate)",
          },
          {
            position: 16,
            bed: 2,
            bath: 1,
            numberofunits: 21,
            gross_monthly_rent: 860,
            utility_allowance: 0,
            net_rent_per_month: 860,
            total_annual_rent: 216720,
            rent_cap: "Market rate",
            name: "2 Bedroom (Market rate)",
          },
        ],
        total: {
          units: {
            type: "total",
            value: 254,
          },
          gross_monthly_rent: {
            type: "average",
            value: "-",
          },
          utility_allowance: {
            type: "average",
            value: "-",
          },
          net_rent_per_month: {
            type: "average",
            value: "-",
          },
          total_annual_rent: {
            type: "total",
            value: 2171196,
          },
        },
      },
      new_unit_mix_and_rent_and_floor_area: {
        data: {
          "30% AMI": {
            studio: {
              position: 1,
              numberofunits: 21,
              gross_monthly_rent: 662,
              sqf_area: 500,
            },
            total: {
              count: 57,
              gross_monthly_rent: 45697,
              sqf_area: 37650,
            },
            bedroom_1: {
              position: 5,
              numberofunits: 11,
              gross_monthly_rent: 870,
              sqf_area: 650,
            },
            bedroom_2: {
              position: 9,
              numberofunits: 25,
              gross_monthly_rent: 889,
              sqf_area: 800,
            },
          },
          "40% AMI": {
            studio: {
              position: 2,
              numberofunits: 21,
              gross_monthly_rent: 603,
              sqf_area: 500,
            },
            total: {
              count: 61,
              gross_monthly_rent: 47063,
              sqf_area: 40250,
            },
            bedroom_1: {
              position: 6,
              numberofunits: 15,
              gross_monthly_rent: 605,
              sqf_area: 650,
            },
            bedroom_2: {
              position: 10,
              numberofunits: 25,
              gross_monthly_rent: 1013,
              sqf_area: 800,
            },
          },
          "50% AMI": {
            studio: {
              position: 3,
              numberofunits: 10,
              gross_monthly_rent: 871,
              sqf_area: 500,
            },
            total: {
              count: 41,
              gross_monthly_rent: 31188,
              sqf_area: 27850,
            },
            bedroom_1: {
              position: 7,
              numberofunits: 13,
              gross_monthly_rent: 656,
              sqf_area: 650,
            },
            bedroom_2: {
              position: 11,
              numberofunits: 18,
              gross_monthly_rent: 775,
              sqf_area: 800,
            },
          },
          "60% AMI": {
            studio: {
              position: 4,
              numberofunits: 11,
              gross_monthly_rent: 749,
              sqf_area: 500,
            },
            total: {
              count: 39,
              gross_monthly_rent: 30257,
              sqf_area: 25200,
            },
            bedroom_1: {
              position: 8,
              numberofunits: 18,
              gross_monthly_rent: 776,
              sqf_area: 650,
            },
            bedroom_2: {
              position: 12,
              numberofunits: 10,
              gross_monthly_rent: 805,
              sqf_area: 800,
            },
          },
          "Market rate": {
            studio: {
              position: 14,
              numberofunits: 23,
              gross_monthly_rent: 698,
              sqf_area: 500,
            },
            total: {
              count: 54,
              gross_monthly_rent: 40844,
              sqf_area: 34800,
            },
            bedroom_1: {
              position: 15,
              numberofunits: 10,
              gross_monthly_rent: 673,
              sqf_area: 650,
            },
            bedroom_2: {
              position: 16,
              numberofunits: 21,
              gross_monthly_rent: 860,
              sqf_area: 800,
            },
          },
          "Super's unit": {
            bedroom_2: {
              position: 13,
              numberofunits: 2,
              gross_monthly_rent: 0,
              sqf_area: 800,
            },
            total: {
              count: 2,
              gross_monthly_rent: 0,
              sqf_area: 1600,
            },
          },
        },
        misc: {
          total: {
            studio: 86,
            affordable_unit_count: 198,
            market_rate_unit_count: 54,
            affordable_total_area: 130950,
            market_rate_total_area: 34800,
            affordable_monthly_rent: 154205,
            market_rate_monthly_rent: 40844,
            super_unit_count: 2,
            super_unit_monthly_rent: 0,
            super_unit_total_area: 1600,
            bedroom_1: 67,
            bedroom_2: 101,
          },
        },
      },
      lihtc_specifications: {
        data: [
          {
            position: 1,
            bed: 0,
            bath: 1,
            numberofunits: 21,
            max_lihtc: 670,
            fair_market_rent: 668,
            monthly_net_lihtc: 592,
            percentage_below_market: 0.11377245508982035,
            percentage_below_max_lihtc: 0.11641791044776119,
            rent_cap: "30% AMI",
            name: "Studio (30% AMI) ",
          },
          {
            position: 2,
            bed: 0,
            bath: 1,
            numberofunits: 21,
            max_lihtc: 670,
            fair_market_rent: 668,
            monthly_net_lihtc: 533,
            percentage_below_market: 0.20209580838323354,
            percentage_below_max_lihtc: 0.2044776119402985,
            rent_cap: "40% AMI",
            name: "Studio (40% AMI) ",
          },
          {
            position: 3,
            bed: 0,
            bath: 1,
            numberofunits: 10,
            max_lihtc: 670,
            fair_market_rent: 668,
            monthly_net_lihtc: 803,
            percentage_below_market: 0.20209580838323354,
            percentage_below_max_lihtc: 0.19850746268656716,
            rent_cap: "50% AMI",
            name: "Studio (50% AMI) ",
          },
          {
            position: 4,
            bed: 0,
            bath: 1,
            numberofunits: 11,
            max_lihtc: 670,
            fair_market_rent: 668,
            monthly_net_lihtc: 686,
            percentage_below_market: 0.02694610778443114,
            percentage_below_max_lihtc: 0.023880597014925373,
            rent_cap: "60% AMI",
            name: "Studio (60% AMI) ",
          },
          {
            position: 14,
            bed: 0,
            bath: 1,
            numberofunits: 23,
            max_lihtc: 670,
            fair_market_rent: 670,
            monthly_net_lihtc: 698,
            percentage_below_market: 0.041791044776119404,
            percentage_below_max_lihtc: 0.041791044776119404,
            rent_cap: "Market rate",
            name: "Studio (Market rate)",
          },
          {
            position: 5,
            bed: 1,
            bath: 1,
            numberofunits: 11,
            max_lihtc: 850,
            fair_market_rent: 848,
            monthly_net_lihtc: 803,
            percentage_below_market: 0.05306603773584906,
            percentage_below_max_lihtc: 0.05529411764705883,
            rent_cap: "30% AMI",
            name: "1 Bedroom (30% AMI) ",
          },
          {
            position: 6,
            bed: 1,
            bath: 1,
            numberofunits: 15,
            max_lihtc: 850,
            fair_market_rent: 848,
            monthly_net_lihtc: 535,
            percentage_below_market: 0.36910377358490565,
            percentage_below_max_lihtc: 0.37058823529411766,
            rent_cap: "40% AMI",
            name: "1 Bedroom (40% AMI) ",
          },
          {
            position: 7,
            bed: 1,
            bath: 1,
            numberofunits: 13,
            max_lihtc: 850,
            fair_market_rent: 848,
            monthly_net_lihtc: 584,
            percentage_below_market: 0.3113207547169811,
            percentage_below_max_lihtc: 0.3129411764705882,
            rent_cap: "50% AMI",
            name: "1 Bedroom (50% AMI) ",
          },
          {
            position: 8,
            bed: 1,
            bath: 1,
            numberofunits: 18,
            max_lihtc: 850,
            fair_market_rent: 848,
            monthly_net_lihtc: 709,
            percentage_below_market: 0.16391509433962265,
            percentage_below_max_lihtc: 0.16588235294117648,
            rent_cap: "60% AMI",
            name: "1 Bedroom (60% AMI) ",
          },
          {
            position: 15,
            bed: 1,
            bath: 1,
            numberofunits: 10,
            max_lihtc: 850,
            fair_market_rent: 730,
            monthly_net_lihtc: 673,
            percentage_below_market: 0.07808219178082192,
            percentage_below_max_lihtc: 0.20823529411764705,
            rent_cap: "Market rate",
            name: "1 Bedroom (Market rate)",
          },
          {
            position: 9,
            bed: 2,
            bath: 1,
            numberofunits: 25,
            max_lihtc: 1030,
            fair_market_rent: 1027,
            monthly_net_lihtc: 815,
            percentage_below_market: 0.20642648490749757,
            percentage_below_max_lihtc: 0.2087378640776699,
            rent_cap: "30% AMI",
            name: "2 Bedroom (30% AMI) ",
          },
          {
            position: 10,
            bed: 2,
            bath: 1,
            numberofunits: 25,
            max_lihtc: 1030,
            fair_market_rent: 1027,
            monthly_net_lihtc: 933,
            percentage_below_market: 0.09152872444011685,
            percentage_below_max_lihtc: 0.0941747572815534,
            rent_cap: "40% AMI",
            name: "2 Bedroom (40% AMI) ",
          },
          {
            position: 11,
            bed: 2,
            bath: 1,
            numberofunits: 18,
            max_lihtc: 1030,
            fair_market_rent: 1027,
            monthly_net_lihtc: 702,
            percentage_below_market: 0.31645569620253167,
            percentage_below_max_lihtc: 0.31844660194174756,
            rent_cap: "50% AMI",
            name: "2 Bedroom (50% AMI) ",
          },
          {
            position: 12,
            bed: 2,
            bath: 1,
            numberofunits: 10,
            max_lihtc: 1030,
            fair_market_rent: 1027,
            monthly_net_lihtc: 734,
            percentage_below_market: 0.28529698149951316,
            percentage_below_max_lihtc: 0.287378640776699,
            rent_cap: "60% AMI",
            name: "2 Bedroom (60% AMI) ",
          },
          {
            position: 13,
            bed: 2,
            bath: 1,
            numberofunits: 2,
            max_lihtc: 670,
            fair_market_rent: 0,
            monthly_net_lihtc: 0,
            percentage_below_market: 0,
            percentage_below_max_lihtc: 1,
            rent_cap: "Super's unit",
            name: "Super Unit (30% AMI)",
          },
          {
            position: 16,
            bed: 2,
            bath: 1,
            numberofunits: 21,
            max_lihtc: 1030,
            fair_market_rent: 900,
            monthly_net_lihtc: 860,
            percentage_below_market: 0.044444444444444446,
            percentage_below_max_lihtc: 0.1650485436893204,
            rent_cap: "Market rate",
            name: "2 Bedroom (Market rate)",
          },
        ],
        total: {
          units: {
            type: "total",
            value: 254,
          },
          max_lihtc: {
            type: "average",
            value: "-",
          },
          fair_market_rent: {
            type: "average",
            value: "-",
          },
          monthly_net_lihtc: {
            type: "average",
            value: "-",
          },
          percentage_below_market: {
            type: "average",
            value: "-",
          },
          percentage_below_max_lihtc: {
            type: "average",
            value: "-",
          },
        },
      },
    },
  },
  budget_data: {
    acquisition_of_land: {
      "Land Acquisition": {
        unit: "USD",
        value: 751640,
        position: 1,
        percentage_of_group: 17.093262938429323,
        percentage_of_total: 0.9534076853660267,
      },
      "Building Acquisition": {
        unit: "USD",
        value: 3645648,
        position: 2,
        percentage_of_group: 82.90673706157068,
        percentage_of_total: 4.62427335072546,
      },
    },
    hard_cost: {
      Metals: {
        unit: "USD",
        value: 597793,
        position: 5,
        percentage_of_group: 1.1910995093003087,
        percentage_of_total: 0.7582625199005018,
      },
      Masonry: {
        unit: "USD",
        value: 987597,
        position: 4,
        percentage_of_group: 1.967781994915392,
        percentage_of_total: 1.2527041799856737,
      },
      Concrete: {
        unit: "USD",
        value: 914652,
        position: 3,
        percentage_of_group: 1.8224394537583177,
        percentage_of_total: 1.1601780722625286,
      },
      Electric: {
        unit: "USD",
        value: 3070670,
        position: 16,
        percentage_of_group: 6.1182943430638685,
        percentage_of_total: 3.8949502118339856,
      },
      Finishes: {
        unit: "USD",
        value: 2403183,
        position: 9,
        percentage_of_group: 4.788329893556538,
        percentage_of_total: 3.048285271594093,
      },
      Carpentry: {
        unit: "USD",
        value: 2761789,
        position: 6,
        percentage_of_group: 5.502850522992056,
        percentage_of_total: 3.503154246659775,
      },
      Equipment: {
        unit: "USD",
        value: 1704588,
        position: 11,
        percentage_of_group: 3.396382912411478,
        percentage_of_total: 2.1621618056286316,
      },
      "Site Work": {
        unit: "USD",
        value: 2768371,
        position: 2,
        percentage_of_group: 5.5159651244849055,
        percentage_of_total: 3.5115030963552134,
      },
      Furnishings: {
        unit: "USD",
        value: 3255452,
        position: 12,
        percentage_of_group: 6.486471537389545,
        percentage_of_total: 4.12933446349343,
      },
      Specialties: {
        unit: "USD",
        value: 165613,
        position: 10,
        percentage_of_group: 0.32998305940978234,
        percentage_of_total: 0.2100695904908251,
      },
      "Conveying System": {
        unit: "USD",
        value: 286612,
        position: 14,
        percentage_of_group: 0.571072950937164,
        percentage_of_total: 0.36354915054830456,
      },
      "Doors and Windows": {
        unit: "USD",
        value: 742038,
        position: 8,
        percentage_of_group: 1.4785069374887,
        percentage_of_total: 0.9412281571412321,
      },
      "General Requirements": {
        unit: "USD",
        value: 25942689,
        position: 1,
        percentage_of_group: 51.69067576540795,
        percentage_of_total: 32.9066562073076,
      },
      "Special Construction": {
        unit: "USD",
        value: 0,
        position: 13,
        percentage_of_group: 0,
        percentage_of_total: 0,
      },
      "Plumbing HVAC Fire Suppression": {
        unit: "USD",
        value: 3562645,
        position: 15,
        percentage_of_group: 7.098552026054501,
        percentage_of_total: 4.518989307688319,
      },
      "Thermal and Moisture Protection": {
        unit: "USD",
        value: 1024642,
        position: 7,
        percentage_of_group: 2.0415939688294893,
        percentage_of_total: 1.2996934138002452,
      },
    },
    soft_cost: {
      soft_cost_3rd_party_data: {
        Survey: {
          unit: "USD",
          value: 23418,
          position: 2,
          percentage_of_group: 0.21394763536152975,
          percentage_of_total: 0.029704248278300267,
        },
        "Permit fees": {
          unit: "USD",
          value: 146418,
          position: 22,
          percentage_of_group: 1.3376797708755856,
          percentage_of_total: 0.18572194997062808,
        },
        "Market Study": {
          unit: "USD",
          value: 0,
          position: 14,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Zoning Report": {
          unit: "USD",
          value: 0,
          position: 5,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Appraisal - Lender": {
          unit: "USD",
          value: 20934,
          position: 12,
          percentage_of_group: 0.19125372784431907,
          percentage_of_total: 0.02655345176607472,
        },
        "CEQR / SEQR / NEPA": {
          unit: "USD",
          value: 30845,
          position: 16,
          percentage_of_group: 0.28180095707261016,
          percentage_of_total: 0.039124926899998795,
        },
        "Cost certification": {
          unit: "USD",
          value: 0,
          position: 18,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Flood Certification": {
          unit: "USD",
          value: 0,
          position: 4,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Appraisal - Borrower": {
          unit: "USD",
          value: 0,
          position: 13,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Geotech Soils Report": {
          unit: "USD",
          value: 72135,
          position: 1,
          percentage_of_group: 0.6590277853276945,
          percentage_of_total: 0.0914986740778542,
        },
        "Lease up + Marketing": {
          unit: "USD",
          value: 197942,
          position: 23,
          percentage_of_group: 1.808404767218888,
          percentage_of_total: 0.2510768773039248,
        },
        "Environmental - other": {
          unit: "USD",
          value: 74096,
          position: 11,
          percentage_of_group: 0.6769435472605649,
          percentage_of_total: 0.09398607824873757,
        },
        "Environmental Phase I": {
          unit: "USD",
          value: 41407,
          position: 6,
          percentage_of_group: 0.3782957441888659,
          percentage_of_total: 0.05252215425995299,
        },
        "Controlled Inspections": {
          unit: "USD",
          value: 223853,
          position: 25,
          percentage_of_group: 2.0451285344002272,
          percentage_of_total: 0.28394333802384275,
        },
        "Environmental Phase II": {
          unit: "USD",
          value: 0,
          position: 7,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Title + Recording Fees": {
          unit: "USD",
          value: 372305,
          position: 17,
          percentage_of_group: 3.401390997663094,
          percentage_of_total: 0.4722452880370903,
        },
        "Borrower Credit Reports": {
          unit: "USD",
          value: 0,
          position: 15,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Environmental Monitoring": {
          unit: "USD",
          value: 0,
          position: 9,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Environmental consultant": {
          unit: "USD",
          value: 0,
          position: 10,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Other - Relocation Costs": {
          unit: "USD",
          value: 0,
          position: 24,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Security - construction + lease up": {
          unit: "USD",
          value: 0,
          position: 20,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Engineering Reports (PNA+ PNC + Seismic)": {
          unit: "USD",
          value: 35137,
          position: 3,
          percentage_of_group: 0.3210128133785153,
          percentage_of_total: 0.04456905678344165,
        },
        "Real Estate Taxes - construction + lease up": {
          unit: "USD",
          value: 0,
          position: 19,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Enviromental Phase III - Testing + Remediation": {
          unit: "USD",
          value: 0,
          position: 8,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Insurance - (Builders Risk+ Hazard+ Liability)": {
          unit: "USD",
          value: 203622,
          position: 21,
          percentage_of_group: 1.8602974381922202,
          percentage_of_total: 0.25828159718695265,
        },
      },
      soft_cost_professional_data: {
        "Legal - Bank": {
          unit: "USD",
          value: 71878,
          position: 7,
          percentage_of_group: 0.6566798246868236,
          percentage_of_total: 0.09117268587187918,
        },
        "Legal -Other": {
          unit: "USD",
          value: 0,
          position: 10,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "HUD Consultant": {
          unit: "USD",
          value: 0,
          position: 14,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        Sustainability: {
          unit: "USD",
          value: 99576,
          position: 16,
          percentage_of_group: 0.9097296839507937,
          percentage_of_total: 0.1263058427944328,
        },
        "Energy modeling": {
          unit: "USD",
          value: 0,
          position: 13,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Legal - Borrower": {
          unit: "USD",
          value: 919228,
          position: 6,
          percentage_of_group: 8.39809791434402,
          percentage_of_total: 1.165982438140123,
        },
        "Architect -Design": {
          unit: "USD",
          value: 136188,
          position: 1,
          percentage_of_group: 1.2442181469218556,
          percentage_of_total: 0.17274584356158326,
        },
        "Legal - Conversion": {
          unit: "USD",
          value: 0,
          position: 11,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Legal - Bond Counsel": {
          unit: "USD",
          value: 0,
          position: 9,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Engineering - Testing": {
          unit: "USD",
          value: 0,
          position: 4,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Construction Monitoring": {
          unit: "USD",
          value: 42484,
          position: 17,
          percentage_of_group: 0.3881352523998305,
          percentage_of_total: 0.0538882604772102,
        },
        "LIHTC Equity Consultant": {
          unit: "USD",
          value: 0,
          position: 15,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Legal - equity/tax credit": {
          unit: "USD",
          value: 42486,
          position: 8,
          percentage_of_group: 0.38815352446707463,
          percentage_of_total: 0.05389079735040845,
        },
        "Architect - Construction Admin": {
          unit: "USD",
          value: 0,
          position: 2,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Architect+ Engineering - other": {
          unit: "USD",
          value: 1174297,
          position: 5,
          percentage_of_group: 10.728416874290645,
          percentage_of_total: 1.4895212930422397,
        },
        "Engineering - Civil-site/Structural": {
          unit: "USD",
          value: 0,
          position: 3,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Accounting - (Audit+ Tax returns +others)": {
          unit: "USD",
          value: 54746,
          position: 12,
          percentage_of_group: 0.5001612966735975,
          percentage_of_total: 0.06944183005567625,
        },
      },
      soft_cost_fee_and_interest_data: {
        "Non-profit fees": {
          unit: "USD",
          value: 90784,
          position: 20,
          percentage_of_group: 0.8294056763455938,
          percentage_of_total: 0.11515374821492917,
        },
        "Bond - Issuer fee": {
          unit: "USD",
          value: 0,
          position: 10,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Bond - Other fees": {
          unit: "USD",
          value: 0,
          position: 15,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Bond - Trustee fee": {
          unit: "USD",
          value: 0,
          position: 14,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Bond - council fee": {
          unit: "USD",
          value: 0,
          position: 12,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Tax consultant fees": {
          unit: "USD",
          value: 0,
          position: 22,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "LIHTC allocation fees": {
          unit: "USD",
          value: 162011,
          position: 19,
          percentage_of_group: 1.4801379431444528,
          percentage_of_total: 0.20550068186077822,
        },
        "LIHTC application fees": {
          unit: "USD",
          value: 2136,
          position: 18,
          percentage_of_group: 0.019514567816731897,
          percentage_of_total: 0.0027093805757301805,
        },
        "Bond - Underwriting fee": {
          unit: "USD",
          value: 0,
          position: 13,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Origination fee - other": {
          unit: "USD",
          value: 0,
          position: 5,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Bond - Issuer Annual fee": {
          unit: "USD",
          value: 0,
          position: 11,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Interest Reserve - other": {
          unit: "USD",
          value: 0,
          position: 2,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Lender - Underwriting fee": {
          unit: "USD",
          value: 0,
          position: 6,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Agency fees - construction": {
          unit: "USD",
          value: 0,
          position: 21,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Mortgage Insurance Premium": {
          unit: "USD",
          value: 36163,
          position: 17,
          percentage_of_group: 0.3303863838747545,
          percentage_of_total: 0.045870472734143505,
        },
        "Interest Reserve - construction": {
          unit: "USD",
          value: 4369034,
          position: 1,
          percentage_of_group: 39.91564151994729,
          percentage_of_total: 5.541842628419819,
        },
        "Origination fee - Permanent Loan": {
          unit: "USD",
          value: 124343,
          position: 4,
          percentage_of_group: 1.1360018286684899,
          percentage_of_total: 0.15772121204495218,
        },
        "Lender - Monthly inspection costs": {
          unit: "USD",
          value: 0,
          position: 8,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Mortgage Insurance Application fees": {
          unit: "USD",
          value: 3708,
          position: 16,
          percentage_of_group: 0.033876412670618854,
          percentage_of_total: 0.004703362909554078,
        },
        "Origination fee - construction loan": {
          unit: "USD",
          value: 768067,
          position: 3,
          percentage_of_group: 7.017085935998979,
          percentage_of_total: 0.9742442933798467,
        },
        "Lender - Environmental Reliance letter": {
          unit: "USD",
          value: 0,
          position: 9,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Lender - Underwriting Inspection costs": {
          unit: "USD",
          value: 0,
          position: 7,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
      },
      soft_cost_operating_reserve_data: {
        "Rent-up Reserve": {
          unit: "USD",
          value: 115170,
          position: 3,
          percentage_of_group: 1.052196992253283,
          percentage_of_total: 0.14608584312118206,
        },
        "Replacement Reserve": {
          unit: "USD",
          value: 0,
          position: 4,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Social Service Reserve": {
          unit: "USD",
          value: 349004,
          position: 6,
          percentage_of_group: 3.1885122782353457,
          percentage_of_total: 0.4426894468408876,
        },
        "Tax and Insurance Escrows": {
          unit: "USD",
          value: 0,
          position: 5,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Capitalized Operating Reserve": {
          unit: "USD",
          value: 52213,
          position: 1,
          percentage_of_group: 0.47701972350890565,
          percentage_of_total: 0.0662288801500936,
        },
        "Debt Service Reserved+MO Reserve": {
          unit: "USD",
          value: 890041,
          position: 2,
          percentage_of_group: 8.131444501016794,
          percentage_of_total: 1.1289605791214727,
        },
      },
      soft_cost_conversion_and_lease_data: {
        "Conversion fees": {
          unit: "USD",
          value: 0,
          position: 2,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Construction Loan repayment": {
          unit: "USD",
          value: 0,
          position: 1,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
        "Tax credit syndication/8608 fees": {
          unit: "USD",
          value: 0,
          position: 3,
          percentage_of_group: 0,
          percentage_of_total: 0,
        },
      },
    },
    total_development_budget: "78837208",
    total_acquisition_cost: {
      unit: "USD",
      value: 4397288,
      percentage_of_group: 100,
      percentage_of_total: 5.577681036091486,
    },
    total_trade_cost_breakdown: {
      unit: "USD",
      value: 50188334,
      percentage_of_group: 100,
      percentage_of_total: 63.66071969469036,
    },
    hard_cost_contingency: {
      unit: "USD",
      value: 2074308,
      position: 1,
      percentage: 2.6311281850569848,
      percentage_of_group: 4.13304813026868,
      percentage_of_total: 2.6311281850569848,
    },
    miscellaneous_items: {
      name: "Miscellaneous Items",
      unit: "USD",
      value: 0,
      position: 1,
      percentage_of_group: 0,
      percentage_of_total: 0,
    },
    total_hard_cost: {
      unit: "USD",
      value: 50188334,
      percentage_of_group: 100,
      percentage_of_total: 63.66071969469036,
    },
    soft_cost_contingency: {
      unit: "USD",
      value: 340614,
      position: 1,
      percentage: 0.432047263774232,
      percentage_of_group: 3.1118609561462165,
      percentage_of_total: 0.4320472637742321,
    },
    total_soft_cost: {
      unit: "USD",
      value: 10945669,
      percentage_of_group: 100,
      percentage_of_total: 13.883887161503742,
    },
    developer_fees: {
      "Developer fee - deferred": {
        unit: "USD",
        value: 5310591,
        position: 3,
        percentage_of_group: 48.76130234198069,
        percentage_of_total: 6.736147987381796,
      },
      "Developer fees - at closing": {
        unit: "USD",
        value: 0,
        position: 1,
        percentage_of_group: 0,
        percentage_of_total: 0,
      },
      "Developer fees - at construction lease up": {
        unit: "USD",
        value: 5580404,
        position: 2,
        percentage_of_group: 51.23869765801931,
        percentage_of_total: 7.078388671501406,
      },
    },
    total_developer_fees: {
      value: 10890995,
      percentage_of_group: 100,
      percentage_of_total: 13.8145366588832,
    },
  },
  income_operating_cost: {
    rental_income: {
      "Gross Potential Rent": {
        unit: "USD",
        value: 2171196,
        position: 1,
        percentage_of_total: 102.94426106540648,
        per_unit: 8548.015748031496,
      },
      "Gross Potential Rent1": {
        unit: "USD",
        value: 0,
        position: 2,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    less_income: {
      "Less Vacancies": {
        unit: "USD",
        value: 69098.8698,
        position: 1,
        percentage_of_total: 3.2762275225340005,
        per_unit: 272.042794488189,
      },
      "Less Concessions": {
        unit: "USD",
        value: 0,
        position: 2,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Less Collection Loss": {
        unit: "USD",
        value: 0,
        position: 3,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    net_rental_income: {
      unit: "USD",
      value: 2102097.1302,
      percentage_of_total: 99.66803354287248,
      per_unit: 8275.972953543307,
    },
    other_income: {
      Retail: {
        unit: "USD",
        value: 0,
        position: 5,
        percentage_of_total: 0,
        per_unit: 0,
      },
      Laundry: {
        unit: "USD",
        value: 0,
        position: 1,
        percentage_of_total: 0,
        per_unit: 0,
      },
      Parking: {
        unit: "USD",
        value: 7001.500000000001,
        position: 2,
        percentage_of_total: 0.3319664571275203,
        per_unit: 27.564960629921263,
      },
      "Cable TV": {
        unit: "USD",
        value: 0,
        position: 4,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Other Income": {
        unit: "USD",
        value: 0,
        position: 3,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    total_other_income: {
      value: 7001.500000000001,
      percentage_of_total: 0.3319664571275203,
      per_unit: 27.564960629921263,
    },
    total_effective_gross_income: {
      unit: "USD",
      value: 2109098.6302,
      percentage_of_total: 100,
      per_unit: 8303.537914173228,
    },
    management_cost: {
      "Management Fee": {
        unit: "USD",
        value: 87360,
        position: 1,
        percentage_of_total: 11.4907979175349,
        per_unit: 343.93700787401576,
      },
      "Management Fee1": {
        unit: "USD",
        value: 0,
        position: 2,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    total_management_cost: {
      unit: "USD",
      value: 87360,
      percentage_of_total: 11.4907979175349,
      per_unit: 343.93700787401576,
    },
    administration_cost: {
      Salaries: {
        unit: "USD",
        value: 119952,
        position: 6,
        percentage_of_total: 15.777749448307535,
        per_unit: 472.251968503937,
      },
      Advertising: {
        unit: "USD",
        value: 1872,
        position: 3,
        percentage_of_total: 0.24623138394717642,
        per_unit: 7.3700787401574805,
      },
      Benchmarking: {
        unit: "USD",
        value: 0,
        position: 7,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Legal & Audit": {
        unit: "USD",
        value: 30360,
        position: 2,
        percentage_of_total: 3.9933679576048484,
        per_unit: 119.5275590551181,
      },
      "License & Permits": {
        unit: "USD",
        value: 0,
        position: 4,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Tax credit monitoring": {
        unit: "USD",
        value: 5769.12,
        position: 5,
        percentage_of_total: 0.7588346163233624,
        per_unit: 22.71307086614173,
      },
      "General Administration": {
        unit: "USD",
        value: 2893.8,
        position: 1,
        percentage_of_total: 0.3806326810183435,
        per_unit: 11.392913385826771,
      },
    },
    total_administration_cost: {
      unit: "USD",
      value: 160846.92,
      percentage_of_total: 21.156816087201268,
      per_unit: 633.2555905511812,
    },
    repair_and_management_cost: {
      "Pest Control": {
        unit: "USD",
        value: 1606.8,
        position: 5,
        percentage_of_total: 0.21134860455465976,
        per_unit: 6.325984251968504,
      },
      "Elevator Maintenance": {
        unit: "USD",
        value: 11054.400000000001,
        position: 4,
        percentage_of_total: 1.454027890334224,
        per_unit: 43.52125984251969,
      },
      "Maintenance & Repair": {
        unit: "USD",
        value: 65296.000000000015,
        position: 1,
        percentage_of_total: 8.58863485374724,
        per_unit: 257.0708661417323,
      },
      "Parking Lot Maintenance": {
        unit: "USD",
        value: 0,
        position: 6,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Interior & Exterior Painting": {
        unit: "USD",
        value: 2732.4,
        position: 3,
        percentage_of_total: 0.3594031161844363,
        per_unit: 10.757480314960631,
      },
      "Site & Landscape Maintenance": {
        unit: "USD",
        value: 0,
        position: 7,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Supplies & Cleaning Expenses": {
        unit: "USD",
        value: 35164.8,
        position: 2,
        percentage_of_total: 4.6253618430692685,
        per_unit: 138.444094488189,
      },
    },
    total_repair_and_management: {
      unit: "USD",
      value: 115854.40000000001,
      percentage_of_total: 15.238776307889827,
      per_unit: 456.1196850393701,
    },
    utilities_cost: {
      Sewer: {
        unit: "USD",
        value: 6409.200000000001,
        position: 2,
        percentage_of_total: 0.8430268087576086,
        per_unit: 25.233070866141734,
      },
      Water: {
        unit: "USD",
        value: 56160,
        position: 1,
        percentage_of_total: 7.386941518415292,
        per_unit: 221.10236220472441,
      },
      "Cable TV": {
        unit: "USD",
        value: 0,
        position: 6,
        percentage_of_total: 0,
        per_unit: 0,
      },
      Security: {
        unit: "USD",
        value: 134235,
        position: 8,
        percentage_of_total: 17.65644755563527,
        per_unit: 528.4842519685039,
      },
      Telephone: {
        unit: "USD",
        value: 0,
        position: 7,
        percentage_of_total: 0,
        per_unit: 0,
      },
      Electricity: {
        unit: "USD",
        value: 43632,
        position: 3,
        percentage_of_total: 5.739085333538035,
        per_unit: 171.7795275590551,
      },
      "Gas - Heating": {
        unit: "USD",
        value: 80325,
        position: 4,
        percentage_of_total: 10.565457219848795,
        per_unit: 316.24015748031496,
      },
      "Trash Collection": {
        unit: "USD",
        value: 0,
        position: 5,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    total_utilities_cost: {
      unit: "USD",
      value: 320761.2,
      percentage_of_total: 42.190958436195004,
      per_unit: 1262.83937007874,
    },
    tax_cost: {
      "Real Estate Taxes": {
        unit: "USD",
        value: 0,
        position: 1,
        percentage_of_total: 0,
        per_unit: 0,
      },
      "Other Taxes & Assessments": {
        unit: "USD",
        value: 0,
        position: 2,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    total_tax_cost: {
      unit: "USD",
      value: 0,
      percentage_of_total: 0,
      per_unit: 0,
    },
    insurance_cost: {
      "Insurance - Fire and Liability": {
        unit: "USD",
        value: 51480,
        position: 1,
        percentage_of_total: 6.771363058547352,
        per_unit: 202.6771653543307,
      },
    },
    total_insurance_cost: {
      unit: "USD",
      value: 51480,
      percentage_of_total: 6.771363058547352,
      per_unit: 202.6771653543307,
    },
    total_expenses: {
      unit: "USD",
      value: 736302.52,
      percentage_of_total: 96.84871180736835,
      per_unit: 2898.828818897638,
    },
    toal_replacement_reserves: {
      "Replacement Reserve 1": {
        unit: "USD",
        value: 23958.000000000004,
        position: 1,
        percentage_of_total: 3.1512881926316525,
        per_unit: 94.3228346456693,
      },
      "Replacement Reserve 2": {
        unit: "USD",
        value: 0,
        position: 2,
        percentage_of_total: 0,
        per_unit: 0,
      },
    },
    net_cash_flow: {
      unit: "USD",
      value: 155738.1102,
      percentage_of_total: "-",
      per_unit: 613.1421661417322,
    },
    total_gross_expenses: {
      unit: "USD",
      value: 760260.52,
      percentage_of_total: 100,
      per_unit: 2993.151653543307,
    },
    debt_service: {
      value: 1193100,
      per_unit: 4697.244094488189,
    },
    noi: {
      value: 1348838.1102,
      per_unit: 5310.386260629921,
    },
    debt_service_coverage_ratio: {
      value: 1.1305323193361831,
    },
    operating_expense_ratio: {
      value: 0.3491076754102194,
    },
    breakeven_occupany_ration: {
      value: 0.9147995700025845,
    },
  },
};

import { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import ChipsGroup from "components/ChipsGroup";
import Stack from "components/Stack";
import Text from "components/Text";

import { useTaskChannels } from "entities/Task/sdk";

export interface IChecklistUpdates {
  dealId: number;
  packageId: number;
}
const ChecklistUpdates = ({ dealId, packageId }: IChecklistUpdates) => {
  const { data: taskChannels } = useTaskChannels({ dealId });
  const tasksForPackage = useMemo(
    () =>
      taskChannels?.filter(
        (taskChannel) =>
          !taskChannel.task.completed &&
          taskChannel.task.company_package?.id === packageId
      ) || [],
    [packageId, taskChannels]
  );

  const tasksCountLabel = useMemo(
    () =>
      `${tasksForPackage.length} open ${
        tasksForPackage.length === 1 ? "task" : "tasks"
      }`,
    [tasksForPackage]
  );

  const unreadLabel = useMemo(() => {
    const unreadCount = _.sumBy(tasksForPackage, "unread_count");
    return `${unreadCount} new ${unreadCount === 1 ? "message" : "messages"}`;
  }, [tasksForPackage]);

  const chips = useMemo(
    () => [tasksCountLabel, unreadLabel],
    [tasksCountLabel, unreadLabel]
  );

  return (
    <Box width="360px" data-testid="checklist-updates">
      <Text
        variant="labelSmall"
        color={colors.gray60}
        fontWeight={500}
        marginBottom={theme.spacing(0.5)}
        data-testid="section-label"
      >
        Updates
      </Text>
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
          "& > *": { margin: theme.spacing(0, 1, 1, 0) },
        }}
        data-testid="tasks-and-messages-data-chips"
      >
        <ChipsGroup chips={chips} dataTestid="data-chip" />
      </Stack>
    </Box>
  );
};

export default ChecklistUpdates;

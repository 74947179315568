import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import { notImplementedError } from "utils/common";

import { SEARCH_STRING_FILTER_KEY } from "entities/Reporting/constants";

import { ORDER_BY_COLUMN_FILTER_KEY } from "./constants";

export type IFilters = {
  [key: string]: string[];
} & {
  [SEARCH_STRING_FILTER_KEY]?: string;
  [ORDER_BY_COLUMN_FILTER_KEY]?: string;
};

export const FiltersContext = React.createContext({
  filters: {} as IFilters,
  setFilters: notImplementedError as Dispatch<SetStateAction<any>>,
});

export interface IFilterContextProvider {
  children: React.ReactNode;
}

export const FiltersContextProvider = ({
  children,
}: IFilterContextProvider) => {
  const [searchParams] = useSearchParams();

  const initialFilters = useMemo(() => {
    const filters: IFilters = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      if (
        param === SEARCH_STRING_FILTER_KEY ||
        param === ORDER_BY_COLUMN_FILTER_KEY
      ) {
        filters[param] = value;
      } else {
        filters[param] = value ? value.split(",") : [];
      }
    }

    return filters;
  }, [searchParams]);

  const [filters, setFilters] = useState<IFilters>(initialFilters);

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = (
  {
    initialFilters,
  }: {
    initialFilters?: IFilters;
  } = { initialFilters: {} }
) => {
  const { filters, setFilters } = useContext(FiltersContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // TODO: Handle magic filters.
    if (!_.isEmpty(initialFilters) && _.isEmpty(filters)) {
      setFilters(initialFilters);
    }
  }, [initialFilters, setFilters, filters]);

  useEffect(() => {
    const urlParameters: URLSearchParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (
        key === SEARCH_STRING_FILTER_KEY ||
        key === ORDER_BY_COLUMN_FILTER_KEY
      ) {
        urlParameters.set(key, _.get(filters, key, ""));
      } else {
        urlParameters.set(key, filters[key].join(","));
      }
    });

    if (urlParameters.toString() !== searchParams.toString()) {
      setSearchParams(urlParameters);
    }
  }, [filters, setSearchParams, searchParams]);

  const reset = useCallback(() => {
    setFilters(initialFilters);
  }, [initialFilters, setFilters]);

  const deleteFilter = useCallback(
    ({ key }: { key: string }) =>
      setFilters((prevFilters: IFilters) => _.omit(prevFilters, key)),
    [setFilters]
  );

  const updateFilter = useCallback(
    ({ key, value }: { key: string; value: Array<string> | string }) => {
      setFilters((prevFilters: IFilters) => ({
        ...prevFilters,
        [key]: value,
      }));
    },
    [setFilters]
  );

  const updateFilters = useCallback(
    (newFilters: IFilters) => setFilters(newFilters),
    [setFilters]
  );

  return {
    filters,
    reset,
    updateFilter,
    updateFilters,
    deleteFilter,
  };
};

import _ from "lodash";

import { colors } from "theme/palette";
import { LOCALE_STRING_ZONE } from "utils/constants";

import { IHeader } from "components/StyledTable";

import Cell from "entities/AssetManagement/components/Table/components/Cell";
import { removeNonDigits } from "entities/AssetManagement/components/Table/components/Cell/utils";
import NameCell from "entities/AssetManagement/components/Table/components/NameCell";
import { CELL_DEFAULT_WIDTH } from "entities/AssetManagement/components/Table/constants";
import { AMColumn } from "entities/AssetManagement/constants";
import { IAssetManagementDataV2 } from "entities/AssetManagement/sdk";
import { subCategoryToCategory } from "entities/AssetManagement/utils";

import YearCell from "./components/YearCell";

interface ITableData {
  itemKey: string;
  label: string;
  itemCode: string;
  isTotalRow: boolean;
  isAdded: boolean;
  isDeleted: boolean;
  values: Array<{
    id: number | null;
    itemKey: string;
    value: string;
    newValue?: string;
    tooltipTitle?: string;
  }>;
}
export const extractRowKey = ({ itemKey }: { itemKey: string }) => {
  const [itemCode, subCategory] = itemKey.split("|");
  return { itemCode, subCategory };
};

export const extractCellKey = ({ itemKey }: { itemKey: string }) => {
  const [itemCode, subCategory, year, quarter, dataType] = itemKey.split("|");
  return { itemCode, subCategory, year, quarter, dataType };
};

export const generateRowItemKey = ({
  row,
}: {
  row: IAssetManagementDataV2["rows"][number];
}) => `${row.item_code}|${row.sub_category || ""}`;

export const generateCellItemKey = ({
  row,
  value,
}: {
  row: IAssetManagementDataV2["rows"][number];
  value: IAssetManagementDataV2["rows"][number]["values"][number];
}) =>
  `${generateRowItemKey({ row })}|${value.date_info.year}|${
    value.date_info.quarter || ""
  }|${value.data_type}`;

const formatValue = ({
  value,
}: {
  value: IAssetManagementDataV2["rows"][number]["values"][number];
}) => {
  if (value.data_type === AMColumn.VARIANCE) {
    return `${value.value?.toFixed(2)}`;
  }
  return String(value.value?.toLocaleString(LOCALE_STRING_ZONE) || "");
};

export const apiToTableData = (
  row: IAssetManagementDataV2["rows"][number]
): ITableData => ({
  itemKey: generateRowItemKey({ row }),
  label: row.label,
  itemCode: row.item_code,
  isTotalRow: false,
  isAdded: _.isNull(row.id),
  isDeleted: false,
  values: row.values.map((value) => ({
    id: value.id,
    itemKey: generateCellItemKey({ row, value }),
    value: formatValue({ value }),
  })),
});

export const addTooltipTitleToValues = ({
  row,
  columns,
}: {
  row: ITableData;
  columns: string[];
}) => {
  if (columns.length === 2) {
    const tooltipTitle = `((${columns[0]} - ${columns[1]}) / ${columns[1]}) * 100`;

    return {
      ...row,
      values: row.values.map((value) => ({
        ...value,
        tooltipTitle: _.isNull(value.id) ? tooltipTitle : "", // For the variance column, the id is null. We want to show the tooltip only for it.
      })),
    };
  }

  return row;
};

const addVarianceDataToValues = ({
  row,
  columns,
}: {
  row: IAssetManagementDataV2["rows"][number];
  columns: string[];
}) => {
  if (columns.length === 2) {
    const rowWithVarianceData = _.cloneDeep(row);
    const groupedValues = _.groupBy(row.values, (value) => {
      const itemCode = row.item_code;
      const subCategory = row.sub_category || "";
      const year = value.date_info.year;
      const quarter = value.date_info.quarter || "";

      return `${itemCode}|${subCategory}|${year}|${quarter}`;
    });

    Object.keys(groupedValues).forEach((groupKey) => {
      const { year, quarter } = extractCellKey({ itemKey: groupKey });

      const values = groupedValues[groupKey];

      const varianceValue = calculateVariance({
        values,
        columns,
      });

      // If the variance is null, we don't want to add it to the row values
      if (!_.isNull(varianceValue)) {
        rowWithVarianceData.values.push({
          id: null,
          value: varianceValue,
          data_type: AMColumn.VARIANCE,
          date_info: {
            year: Number(year),
            quarter: quarter ? Number(quarter) : null,
          },
        });
      }
    });

    return rowWithVarianceData;
  }

  return row;
};

export const calculateTotalVariance = ({
  totalValues,
  columns,
}: {
  totalValues: { [key: string]: number };
  columns: string[];
}) => {
  Object.keys(totalValues).map((key) => {
    const { subCategory, year, quarter, dataType } = extractCellKey({
      itemKey: `|${key}`, // total key does not have itemCode
    });

    if (dataType === AMColumn.VARIANCE) {
      const firstKey = `${subCategory}|${year}|${quarter}|${columns[0]}`;
      const secondKey = `${subCategory}|${year}|${quarter}|${columns[1]}`;

      const firstNumber = _.get(totalValues, firstKey);
      const secondNumber = _.get(totalValues, secondKey);

      if (_.isNil(firstNumber) || _.isNil(secondNumber) || secondNumber === 0) {
        return null;
      }

      return (totalValues[key] =
        ((firstNumber - secondNumber) / secondNumber) * 100);
    }

    return totalValues[key];
  });

  return totalValues;
};

const calculateVariance = ({
  values,
  columns,
}: {
  values: IAssetManagementDataV2["rows"][number]["values"];
  columns: string[];
}) => {
  const firstNumber = _.find(
    values,
    (value) => value.data_type === columns[0]
  )?.value;
  const secondNumber = _.find(
    values,
    (value) => value.data_type === columns[1]
  )?.value;

  if (_.isNil(firstNumber) || _.isNil(secondNumber) || secondNumber === 0) {
    return null;
  }

  return ((firstNumber - secondNumber) / secondNumber) * 100;
};

export const nameColumn = ({
  selectedTab,
  setCollapsedSubCategories,
  collapsedSubCategories,
  editMode,
  handleRowDelete,
  deletedRowKeys,
}: any): IHeader<{
  label: string;
  itemCode: string;
  isTotalRow: boolean;
  isAdded: boolean;
}> => ({
  itemKey: "-",
  label: selectedTab,
  width: "350px",
  sticky: true,
  renderHeader: ({ label, Wrapper }) => (
    <Wrapper
      style={{
        height: "49px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        borderBottom: `1px solid ${colors.gray100}`,
      }}
    >
      {label}
    </Wrapper>
  ),
  style: {
    zIndex: 2,
  },
  children: [
    {
      itemKey: "name",
      label: "Item code & name",
      width: "350px",
      render: ({ itemKey, row, index, Wrapper }) => (
        <NameCell
          itemKey={itemKey}
          row={row}
          index={index}
          Wrapper={Wrapper}
          setCollapsedSubCategories={setCollapsedSubCategories}
          collapsedSubCategories={collapsedSubCategories}
          editMode={editMode}
          handleRowDelete={handleRowDelete}
          isDeleted={deletedRowKeys.includes(row.itemKey)}
        />
      ),
      renderHeader: ({ itemKey, label, Wrapper }) => <Wrapper>{label}</Wrapper>,
    },
  ],
});

export const transformApiHeaderToTableHeader = ({
  header,
  editMode,
  editHeaders,
  columns,
  onChange,
  allSelectedHeaders,
  yearMappings,
  updateYearMapping,
}: {
  header: IAssetManagementDataV2["headers"][number];
  editMode: boolean;
  editHeaders: boolean;
  columns: string[];
  onChange: (args: {
    itemKey: string;
    value: number | null;
    prevValue: string | null;
  }) => void;
  allSelectedHeaders: IAssetManagementDataV2["headers"];
  yearMappings: { [key: string]: string };
  updateYearMapping: (args: {
    oldYear: string | number;
    newYear: string;
  }) => void;
}): IHeader<{
  isTotalRow: boolean;
  isDeleted: boolean;
  isAdded: boolean;
}> => {
  const currentSelectedYears = allSelectedHeaders.map(({ year }) =>
    _.get(yearMappings, year, year)
  );

  const headers: IHeader<{
    isTotalRow: boolean;
    isDeleted: boolean;
    isAdded: boolean;
  }> = {
    itemKey: `${_.get(yearMappings, header.year, header.year)}|${
      header.quarter || ""
    }`,
    label: `${_.get(yearMappings, header.year, header.year)}${
      header.quarter ? " Q" + header.quarter : ""
    }`,
    width: columns.length * CELL_DEFAULT_WIDTH + "px",
    renderHeader: ({ itemKey, label, Wrapper }) => (
      <YearCell
        label={label}
        itemKey={itemKey}
        Wrapper={(props) => (
          <Wrapper
            {...props}
            style={{
              ...props.style,
              height: "49px",
              display: "flex",
              alignItems: "center",
            }}
          />
        )}
        editMode={editHeaders}
        currentSelectedYears={currentSelectedYears}
        onChange={({ newYear }: { newYear: string }) =>
          updateYearMapping({ oldYear: header.year, newYear })
        }
      />
    ),
    children:
      columns.map((dataType) => ({
        itemKey: `${_.get(yearMappings, header.year, header.year)}|${
          header.quarter || ""
        }|${dataType}`,
        label: dataType,
        renderHeader: ({ label, Wrapper }) => (
          <Wrapper style={{ textAlign: "right" }}>{label}</Wrapper>
        ),
        render: ({ itemKey, row, index, Wrapper }) => (
          <Cell
            itemKey={itemKey}
            row={row}
            index={index}
            Wrapper={Wrapper}
            editMode={editMode}
            onChange={onChange}
          />
        ),
      })) || [],
  };

  if (!editMode && columns.length === 2 && !_.isUndefined(headers.children)) {
    headers.width = (columns.length + 1) * CELL_DEFAULT_WIDTH + "px";
    headers.children.push({
      itemKey: `${_.get(yearMappings, header.year, header.year)}|${
        header.quarter || ""
      }|${AMColumn.VARIANCE}`,
      label: AMColumn.VARIANCE,
      renderHeader: ({ label, Wrapper }) => (
        <Wrapper style={{ textAlign: "right" }}>{label}</Wrapper>
      ),
      render: ({ itemKey, row, index, Wrapper }) => (
        <Cell
          itemKey={itemKey}
          row={row}
          index={index}
          Wrapper={Wrapper}
          editMode={false}
          onChange={() => {}}
        />
      ),
    });
  }

  return headers;
};

const calculateTotals = ({
  result,
  collapsedSubCategories,
  columns,
}: {
  result: ITableData[];
  collapsedSubCategories: string[];
  columns: string[];
}) => {
  let resultWithTotals: ITableData[] = [];
  const subCategoriesGrouped = _.groupBy(result, (row) => {
    const { subCategory } = extractRowKey({ itemKey: row.itemKey });
    return subCategory;
  });

  Object.keys(subCategoriesGrouped).forEach((subCategoryName) => {
    const subCategories = subCategoriesGrouped[subCategoryName];

    const totalValues: { [key: string]: number } = {};

    subCategories.forEach((row) => {
      row.values.forEach(
        (value: {
          id: number | null;
          itemKey: string;
          value: string;
          newValue?: string;
        }) => {
          const { subCategory, year, quarter, dataType } = extractCellKey({
            itemKey: value.itemKey,
          });
          const key = `${subCategory}|${year}|${quarter}|${dataType}`;

          // newValue and value are strings
          const cellValue =
            removeNonDigits(_.get(value, "newValue", value.value)) || 0;
          const valueTotal = Number(cellValue);
          totalValues[key] = _.get(totalValues, key, 0) + valueTotal;
        }
      );
    });

    const totalValuesWithVariance = calculateTotalVariance({
      totalValues,
      columns,
    });

    const total = {
      itemKey: subCategoryName,
      label: subCategoryName === "null" ? "-" : subCategoryName, // the subcategory 'Debt Service' don't have a category
      itemCode: "",
      isTotalRow: true,
      isDeleted: false,
      isAdded: false,
      values: Object.keys(totalValuesWithVariance).map((key) => ({
        id: null,
        itemKey: key,
        value: totalValuesWithVariance[key].toLocaleString(LOCALE_STRING_ZONE),
      })),
    };

    if (collapsedSubCategories.indexOf(subCategoryName) === -1) {
      resultWithTotals = [...resultWithTotals, total, ...subCategories];
    } else {
      resultWithTotals = [...resultWithTotals, total];
    }
  });

  return resultWithTotals;
};

export const transformTableData = ({
  rows,
  selectedTab,
  collapsedSubCategories,
  changedCells,
  deletedRowKeys,
  columns,
  yearMappings,
}: {
  rows: IAssetManagementDataV2["rows"];
  selectedTab: string;
  collapsedSubCategories: string[];
  changedCells: {
    [key: string]: {
      current_value: number | null;
      previous_value: number | null;
    };
  };
  deletedRowKeys: Array<string>;
  columns: Array<string>;
  yearMappings: { [key: string]: string };
}) => {
  let result: ITableData[] = [];

  const subCategoriesGrouped = _.groupBy(rows, "sub_category");
  Object.keys(subCategoriesGrouped).forEach((subCategoryName) => {
    if (subCategoryToCategory(subCategoryName) === selectedTab) {
      const subCategories = subCategoriesGrouped[subCategoryName];
      subCategories.forEach((row) => {
        const rowWithVarianceData = addVarianceDataToValues({
          row,
          columns,
        });

        const rowWithVarianceDataAndMappedYear = {
          ...rowWithVarianceData,
          values: _.map(rowWithVarianceData.values, (value) => ({
            ...value,
            date_info: {
              ...value.date_info,
              year: _.toNumber(
                _.get(
                  yearMappings,
                  value.date_info.year.toString(),
                  value.date_info.year
                )
              ),
            },
          })),
        };

        const currentRowTableData = apiToTableData(
          rowWithVarianceDataAndMappedYear
        );

        const currentRowItem = addTooltipTitleToValues({
          row: currentRowTableData,
          columns,
        });

        result.push(currentRowItem);
      });
    }
  });

  const updatedCellItemKeys: Array<string> = [];

  // Update values that already exist
  result = result.map((row) => ({
    ...row,
    isDeleted: deletedRowKeys.includes(row.itemKey),
    values: row.values.map((value: any) => {
      const changedCell = changedCells[value.itemKey];

      if (!_.isUndefined(changedCell)) {
        updatedCellItemKeys.push(value.itemKey);

        return {
          ...value,
          newValue:
            changedCell?.current_value?.toLocaleString(LOCALE_STRING_ZONE),
        };
      }

      return value;
    }),
  }));

  const newCells = _.omit(changedCells, updatedCellItemKeys);

  result = result.map((row) => {
    const keysOfCellsForRow = Object.keys(newCells).filter((key) =>
      key.includes(row.itemKey)
    );
    const cellsForRow: {
      [key: string]: any;
    } = _.pick(newCells, keysOfCellsForRow);

    const newValues = Object.keys(cellsForRow).map((key) => ({
      id: null,
      itemKey: key,
      value: "",
      newValue:
        cellsForRow[key].current_value.toLocaleString(LOCALE_STRING_ZONE),
    }));

    return {
      ...row,
      values: [...row.values, ...newValues],
    };
  });

  // calculate totals for values
  return calculateTotals({ result, collapsedSubCategories, columns });
};

// value is event.target.value
export const getUpdatedCellValue = ({ value }: { value: any }) => {
  let newValue = value;

  // This will remove any non-numbers like "000"
  if (newValue !== "") {
    newValue = parseFloat(newValue);
  }

  return !_.isNaN(newValue) ? newValue.toString() : "";
};

import { useParams } from "react-router-dom";

import { LogoIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parse } from "utils/datetime";

import Box from "components/Box";
import DealAvatar from "components/DealAvatar";
import Divider from "components/Divider";
import Link from "components/Link";
import NoPrint from "components/NoPrint";
import Paper from "components/Paper";
import PrintableLayout from "components/PrintableLayout";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import ExpiredShareToken from "entities/Memo/components/ExpiredShareToken";
import PageHeader from "entities/Memo/components/PageHeader";
import MemoSectionWrapper from "entities/Memo/components/SectionWrapper";
import {
  usePublicDealDetails,
  usePublicMemoDealDetails,
  useVerifyShareToken,
} from "entities/Memo/publicSdk";
import { IMemoSection } from "entities/Memo/sdk";

import "./shareableMemo.css";

const ShareableMemo: React.FC<{}> = () => {
  const params = useParams<{
    token: string;
  }>();
  const token = params.token;

  // First we have to verify the token
  const { isValidating, error: errorToken } = useVerifyShareToken({
    token,
  });

  /**
   * If the token is expired (useVerifyShareToken is return the error)
   * we don't want to send requests for deal and memo
   */
  const { data: deal } = usePublicDealDetails({
    token: !isValidating && !errorToken ? token : undefined,
  });
  const { data: memo } = usePublicMemoDealDetails({
    token: !isValidating && !errorToken ? token : undefined,
  });

  usePageTitle(
    deal?.name && memo?.name
      ? `Builders Patch: ${deal.name} - ${memo?.name} - Memo`
      : "Builders Patch: Memo"
  );

  // If the token is expired we want to show the dialog with a message
  if (errorToken) {
    return <ExpiredShareToken />;
  } else if (!memo || !deal) {
    return null;
  }

  return (
    <Paper
      square={true}
      sx={{
        overflowX: "auto",
        padding: theme.spacing(2, 4),
        "@media print": {
          overflow: "hidden",
          padding: "0",
          flexGrow: 1,
          marginLeft: "0 !important",
          width: "100%",
        },
      }}
    >
      <Stack>
        <Paper sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center" }}>
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <PageHeader
              dealId={deal.id}
              memoId={memo.id}
              memoName={memo?.name}
              dealName={deal?.name}
              isShareableLink={true}
            />
          </Box>
          <PrintableLayout
            header={
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Text variant="text4" sx={{ fontWeight: "bold" }}>
                      {deal.name}
                    </Text>
                    <Text variant="text4">{memo.name}</Text>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {!memo.memo_date && (
                        <Text variant="text4">-- meeting date --</Text>
                      )}
                      {memo.memo_date && (
                        <Text variant="text4">
                          {format(
                            parse(
                              memo.memo_date,
                              FORMATS.BACKEND.DATE,
                              new Date()
                            ),
                            FORMATS.FRONTEND.DATE
                          )}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Divider sx={{ marginBottom: theme.spacing(2) }} />
              </>
            }
            footer={
              <>
                <Divider
                  sx={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(1),
                  }}
                />
                <Stack direction="row" spacing={1}>
                  <LogoIcon
                    style={{
                      height: "40px",
                      width: "auto",
                      borderRadius: theme.spacing(0.5),
                    }}
                  />
                  <Box>
                    <Text sx={{ lineHeight: "144%" }}>
                      This document was created using Builders Patch
                    </Text>
                    <Link to="https://builderspatch.com/" external>
                      <Text
                        fontWeight="700"
                        sx={{ color: colors.blue100, lineHeight: "100%" }}
                      >
                        builderspatch.com
                      </Text>
                    </Link>
                  </Box>
                </Stack>
              </>
            }
          >
            <Paper
              sx={{
                backgroundColor: colors.blue10,
                borderRadius: 0,
              }}
            >
              <Stack direction="row" spacing={2}>
                <DealAvatar
                  src={deal.thumbnail}
                  imgix
                  imgixWidth={480}
                  imgixHeight={240}
                  sx={{ width: "240px", height: "120px", objectFit: "cover" }}
                />
                <Stack>
                  <Text variant="label" sx={{ color: colors.blue100 }}>
                    {memo.name}
                  </Text>
                  <Text variant="h1">{deal.name}</Text>
                </Stack>
              </Stack>
            </Paper>
            <Box
              sx={{
                marginTop: theme.spacing(4),
                "& > *": { marginBottom: theme.spacing(2) },
              }}
            >
              {memo.sections.map((section: IMemoSection) => (
                <>
                  {section.is_na ? (
                    <NoPrint>
                      <MemoSectionWrapper
                        key={section.section_id}
                        section={section}
                        deal={deal}
                        externalToken={token}
                        onUpdate={() => {}}
                      />
                    </NoPrint>
                  ) : (
                    <MemoSectionWrapper
                      key={section.section_id}
                      section={section}
                      deal={deal}
                      externalToken={token}
                      onUpdate={() => {}}
                    />
                  )}
                </>
              ))}
            </Box>
          </PrintableLayout>
        </Paper>
      </Stack>
    </Paper>
  );
};

export default ShareableMemo;

import _ from "lodash";

import { BASE_URL } from "config/urls";
import { get, post, useFetch, usePaginatedFetch } from "utils/sdk";

import { ButlerQueueType } from "entities/AssetManagement/constants";
import { IUploadedFile } from "entities/File/sdk";

export interface IAMPusherResponse {
  success: boolean;
  detail: string;
  error?: string;
}

interface IDateInfo {
  year: Date;
  month: number;
  quarter: number | null;
}
interface IProcessAssetsData {
  file_id: number;
  date_info: Partial<IDateInfo>;
}
export interface IAMDateInfo {
  year: number | string;
  quarter?: number | null;
  month?: number;
}

export type IAMDateValueInfo =
  | { id: null; value: number; aggregated: true }
  | { id: number; value: number; aggregated: false };

export interface IAMDateValue {
  Audited?: IAMDateValueInfo;
  Budgeted?: IAMDateValueInfo;
  Actual?: IAMDateValueInfo;
  date_info: IAMDateInfo;
}

export interface IAMDataPoint {
  name: string;
  code: string;
  is_deleted?: boolean;
  date_values: Array<IAMDateValue>;
}

export interface IAMSubCategory {
  key: string | null;
  data_points: Array<IAMDataPoint>;
  total_values: Array<IAMDateValue>;
}

export type AMDataCategory =
  | "Expenses"
  | "Income"
  | "Debt Service"
  | "Other Data";

export interface IAssetManagementData {
  [key: string]: Array<IAMSubCategory>;
}

export interface IAssetManagementHeadersData {
  year: number | string;
  quarter?: number | null;
}

export interface IAssetManagementFetchData {
  data: IAssetManagementData;
  headers: Array<IAssetManagementHeadersData>;
}

export interface IAssetManagementFile {
  id: number;
  original_file_name: string;
  created_at: string;
}

interface IAssetManagementFileDetail {
  id: number;
  url: string;
  original_file_name: string;
  created_at: string;
}

export const processAssetsData = (dealId: number, data: IProcessAssetsData) => {
  const formatData = {
    file_id: data.file_id,
    date_info: {
      year: data.date_info.year?.getFullYear(),
      month: data.date_info.month ?? null,
      quarter: data.date_info.quarter ?? null,
    },
  };
  post(
    `${BASE_URL}v2/asset-management/deal/${dealId}/process-assets-data/`,
    formatData
  );
};

export interface IAssetManagementDataV2 {
  headers: Array<{ year: number | string; quarter: number | null }>;
  rows: Array<{
    id: number | null;
    label: string;
    item_code: string;
    sub_category: string;
    is_sub_category: boolean;
    values: Array<{
      id: number | null;
      value: number | null;
      data_type: string;
      date_info: IAMDateInfo;
    }>;
  }>;
}

export const useAssetManagementData = ({ dealId }: { dealId: number }) =>
  useFetch<IAssetManagementDataV2>(
    `v2/asset-management/deal/${dealId}/fetch-assets-data/`
  );

export const useAssetManagementFileList = ({
  dealId,
  limit = 10,
  enabled = true,
}: {
  dealId: number;
  limit?: number;
  enabled?: boolean;
}) =>
  usePaginatedFetch<IUploadedFile>({
    url: enabled ? `v2/asset-management/deal/${dealId}/files/list/` : undefined,
    params: { limit },
  });

export const getAssetManagementFile = (
  dealId: number,
  fileId: number
): Promise<IAssetManagementFileDetail> =>
  get(`${BASE_URL}v2/asset-management/deal/${dealId}/file/${fileId}`);

export const deleteAssetManagementRow = ({
  dealId,
  code,
  name,
}: {
  dealId: number;
  code: string;
  name: string;
}): Promise<any> => {
  const body = {
    item_code: code,
    name: name,
  };
  return post(
    `${BASE_URL}v2/asset-management/deal/${dealId}/delete-row/`,
    body
  );
};

export const assetManagemnetProcessPdf = ({
  dealId,
  fileId,
  queueId,
}: {
  dealId: number;
  fileId: number;
  queueId: number;
}) =>
  post(
    `${BASE_URL}v2/integrations/butler/deal/${dealId}/process-asset-management/`,
    {
      file_id: fileId,
      queue_id: queueId,
    }
  );

export interface IDiff {
  rows_diff: {
    [key: string]: {
      is_deleted: boolean;
      order: number | null;
      previous_label: string | null;
      current_label: string | null;
    };
  };
  cells_diff: {
    [key: string]: {
      previous_value: number | null;
      current_value: number | null;
    };
  };
}

export const useVersionsDiff = ({
  prevVersionId,
  newVersionId,
}: {
  prevVersionId: number | undefined;
  newVersionId: number | undefined;
}) =>
  useFetch<IDiff>(
    !_.isUndefined(prevVersionId) && !_.isUndefined(newVersionId)
      ? `v2/asset-management/am-versions/${prevVersionId}/${newVersionId}/diff/`
      : undefined
  );

interface IVersion {
  id: number;
  version_number: number;
  created_by: {
    first_name: string;
    last_name: string;
  };
  created_at: string;
}

export const useVersions = ({ dealId }: { dealId: number }) =>
  useFetch<Array<IVersion>>(
    `v2/asset-management/deal/${dealId}/am-versions/list/`
  );

export interface IPDF {
  id: number;
  created_at: string;
  file: {
    name: string;
  };
  created_by: {
    first_name: string;
    last_name: string;
  };
  status: EImportStatus;
}

export const useAMPdfs = ({ dealId }: { dealId: number }) =>
  useFetch<Array<IPDF>>(`v2/asset-management/deal/${dealId}/pdfs/list/`);

export const usePDFDiff = ({
  pdfId,
  dealId,
}: {
  pdfId: number | undefined;
  dealId: number | undefined;
}) =>
  useFetch<IDiff>(
    !_.isNil(pdfId) && !_.isNil(dealId)
      ? `v2/asset-management/deal/${dealId}/pdf/${pdfId}/diff/`
      : undefined
  );

export const usePdf = ({ pdfId }: { pdfId: number | undefined }) =>
  useFetch<{ id: number; file: { url: string } }>(
    pdfId ? `v2/asset-management/pdf/${pdfId}/details/` : undefined,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export enum EImportStatus {
  COMPLETED = "Completed",
  FAILED = "Failed",
  IN_PROGRESS = "In Progress",
}

export const useButlerQueuesList = ({
  queueType,
}: {
  queueType: ButlerQueueType;
}) =>
  useFetch<Array<{ id: number; name: string }>>(
    `v2/integrations/butler/queues/${queueType}/list/`
  );

export const updateAssetManagement = ({
  dealId,
  diff,
}: {
  dealId: number;
  diff: Array<{
    key: string;
    label: string;
    values: Array<{
      key: string;
      value: number | null; //string;
    }>;
  }>;
}) =>
  post(`${BASE_URL}v2/asset-management/deal/${dealId}/proforma-update/`, diff);

import { CompanyCustomFieldSyncingType } from "entities/Field/constants";

export const SYNCING_OPTIONS = [
  {
    label: "Current location",
    subTitle: "use current field's values as the source",
    value: CompanyCustomFieldSyncingType.SYNC_CURRENT_VALUE_TO_REST,
  },
  {
    label: "Other locations",
    subTitle:
      "use values present in other connected locations to override current field's value",
    value: CompanyCustomFieldSyncingType.SYNC_REST_VALUES_TO_CURRENT_VALUE,
  },
  {
    label: "Erase & start from blank",
    subTitle: "erase all values across all connections to this field",
    value: CompanyCustomFieldSyncingType.ERASE_ALL_VALUES,
  },
];

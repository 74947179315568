import { useCallback, useState } from "react";

import { AddIcon } from "icons";

import Button from "components/Button";

import AddNewTeamDialog from "entities/Team/components/AddNewTeamDialog";

interface IAddNewTeamButton {
  dealId: number;
  onSave: () => void;
}

const AddNewTeamButton: React.FC<IAddNewTeamButton> = ({ dealId, onSave }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);

  const handleSubmit = useCallback(() => {
    setOpen(false);
    onSave();
  }, [onSave]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={handleOpen}
        data-testid="new-team-button"
      >
        New team
      </Button>
      <AddNewTeamDialog
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        dealId={dealId}
      />
    </>
  );
};

export default AddNewTeamButton;

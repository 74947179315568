import { memo } from "react";
import { DialogTitle } from "@mui/material";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogActions } from "components/Dialog";
import Paper from "components/Paper";
import Text from "components/Text";

export interface IDealFeatureDisabledDialog {
  isOpen: boolean;
  companyName: string;
  onClose: () => void;
}

const DealFeatureDisabledDialog = ({
  isOpen,
  companyName,
  onClose,
}: IDealFeatureDisabledDialog) => (
  <Dialog
    open={isOpen}
    maxWidth="sm"
    onClose={onClose}
    data-testid="feature-disabled-dialog"
  >
    <DialogTitle sx={{ padding: theme.spacing(2) }}>
      <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
        <Text variant="h2" data-testid="feature-disabled-dialog-header">
          Product feature unavailable within this deal
        </Text>
        <Text
          variant="text2"
          color={colors.gray80}
          marginTop={1}
          data-testid="feature-disabled-dialog-message"
        >
          You have been invited to collaborate on this deal by its owner{" "}
          <span style={{ fontWeight: 700 }}>{companyName}</span>, however they
          don't have this feature enabled in their subscription.
        </Text>
      </Paper>
    </DialogTitle>
    <DialogActions sx={{ padding: theme.spacing(2) }}>
      <Button onClick={onClose} data-testid="confirm-action-button">
        OK, got it
      </Button>
    </DialogActions>
  </Dialog>
);

export default memo(DealFeatureDisabledDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import React, { useCallback } from "react";

import { CloseIcon } from "icons";

import Box from "components/Box";
import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import SidepanelHeader from "components/SidepanelHeader";
import Text from "components/Text";

import AddForm from "entities/AnalyticsFilterGroup/components/AddForm";
import { ANALYTICS_TYPES } from "entities/AnalyticsFilterGroup/sdk";

export interface IAddFilterSidebar {
  isOpen: boolean;
  onClose: () => void;
  analyticsType: ANALYTICS_TYPES;
}

const AddFilterSidebar: React.FC<IAddFilterSidebar> = ({
  isOpen,
  onClose,
  analyticsType,
}) => {
  const onSave = useCallback(() => onClose(), [onClose]);

  return (
    <Drawer open={isOpen} anchor="right">
      <SidepanelHeader>
        <Text variant="h3">+ New filtered group</Text>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </SidepanelHeader>
      <Box sx={{ position: "relative", flex: 1 }}>
        <AddForm
          onCreated={onSave}
          onCancel={onClose}
          analyticsType={analyticsType}
        />
      </Box>
    </Drawer>
  );
};

export default AddFilterSidebar;

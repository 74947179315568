import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { generateAMTableCellDataTestId } from "utils/automation/utils";

import { IRenderCell } from "components/StyledTable/utils/header";

import { removeNonDigits } from "entities/AssetManagement/components/Table/components/Cell/utils";
import {
  extractCellKey,
  getUpdatedCellValue,
} from "entities/AssetManagement/components/Table/utils";
import { AMColumn } from "entities/AssetManagement/constants";

const CellEditMode = ({
  itemKey,
  row,
  onChange,
}: {
  itemKey: string;
  row: {
    itemKey: string;
    values: { [key: string]: { value: string } };
  };
  onChange: (args: {
    itemKey: string;
    value: number | null;
    prevValue: string | null;
  }) => void;
}) => {
  const defaultValue = useMemo(
    () => _.get(row.values, [`${row.itemKey}|${itemKey}`, "value"], ""),
    [row.values, row.itemKey, itemKey]
  );

  const newValue = useMemo(
    () => _.get(row.values, [`${row.itemKey}|${itemKey}`, "newValue"]),
    [row.values, row.itemKey, itemKey]
  );

  const initialValue = useMemo(() => {
    if (_.isUndefined(newValue)) {
      return removeNonDigits(defaultValue);
    }

    if (_.isString(newValue)) {
      return removeNonDigits(newValue);
    }

    return newValue;
  }, [defaultValue, newValue]);

  const [inputValue, setInputValue] = useState(initialValue);

  const color = useMemo(() => {
    if (removeNonDigits(initialValue) !== removeNonDigits(defaultValue)) {
      if (initialValue === "") {
        return colors.red40;
      } else {
        return colors.green40;
      }
    }

    return "transparent";
  }, [initialValue, defaultValue]);

  const handleBlur = useCallback(
    (e: any) => {
      onChange({
        itemKey: `${row.itemKey}|${itemKey}`,
        value: getUpdatedCellValue({ value: e.target.value }),
        prevValue: defaultValue,
      });
    },
    [onChange, row.itemKey, itemKey, defaultValue]
  );

  const dataTestId = useMemo(
    () => `cell-${generateAMTableCellDataTestId({ itemKey })}-input`,
    [itemKey]
  );

  return (
    <input
      data-testid={dataTestId}
      style={{
        border: 0,
        padding: "5px 12px",
        fontSize: "15px",
        textAlign: "right",
        width: "100%",
        height: "100%",
        backgroundColor: color,
      }}
      value={inputValue}
      onBlur={handleBlur}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
};

const RegularCell = ({ row, itemKey }: { row: any; itemKey: string }) => {
  const dataTestId = useMemo(
    () => `cell-${generateAMTableCellDataTestId({ itemKey })}`,
    [itemKey]
  );

  const tooltipTitle = useMemo(
    () => _.get(row.values, [`${row.itemKey}|${itemKey}`, "tooltipTitle"]),
    [row, itemKey]
  );

  const value = useMemo(() => {
    const { dataType } = extractCellKey({
      itemKey: `${row.itemKey}|${itemKey}`,
    });
    const cellValue = _.get(row.values, [`${row.itemKey}|${itemKey}`, "value"]);
    if (dataType === AMColumn.VARIANCE) {
      return !_.isUndefined(cellValue) ? `${cellValue} %` : "-";
    }

    return cellValue || "-";
  }, [row, itemKey]);

  return (
    <div
      title={tooltipTitle}
      data-testid={dataTestId}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        padding: "5px 12px",
        fontSize: "15px",
        backgroundColor: row.isDeleted ? colors.red40 : "transparent",
      }}
    >
      {value}
    </div>
  );
};

const TotalCell = ({ row, itemKey }: { row: any; itemKey: string }) => {
  const dataTestId = useMemo(
    () => `total-cell-${generateAMTableCellDataTestId({ itemKey })}`,
    [itemKey]
  );

  const value = useMemo(() => {
    const { dataType } = extractCellKey({
      itemKey: `|${row.itemKey}|${itemKey}`, // We add | in the beginning because the total row has no itemCode in row.itemKey
    });
    const cellValue = _.get(row.values, [`${row.itemKey}|${itemKey}`, "value"]);

    if (dataType === AMColumn.VARIANCE) {
      return !_.isUndefined(cellValue) && `${cellValue} %`;
    }

    return cellValue;
  }, [row, itemKey]);

  return (
    <div
      data-testid={dataTestId}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        fontWeight: 700,
        padding: "5px 12px",
      }}
    >
      {value}
    </div>
  );
};

const Cell: IRenderCell<
  {
    isTotalRow: boolean;
    isDeleted: boolean;
  },
  {
    editMode: boolean;
    onChange: (args: {
      itemKey: string;
      value: number | null;
      prevValue: string | null;
    }) => void;
  }
> = ({ itemKey, row, index, editMode, Wrapper, onChange }) => (
  <Wrapper>
    {row.isTotalRow && <TotalCell row={row} itemKey={itemKey} />}
    {!row.isTotalRow &&
      (editMode && !row.isDeleted ? (
        <CellEditMode itemKey={itemKey} row={row} onChange={onChange} />
      ) : (
        <RegularCell itemKey={itemKey} row={row} />
      ))}
  </Wrapper>
);

export default Cell;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast from "components/Toast";

import { IApplicationField } from "entities/Application/sdk";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import EditButton from "entities/Field/components/Richtextfield/EditButton";

export interface IRichtextfield {
  field: IApplicationField;
  editable: boolean;
  onUpdate: ({
    field,
    value,
  }: {
    field: IApplicationField;
    value: string | null | undefined;
  }) => void;
}
const Richtextfield = ({ field, editable, onUpdate }: IRichtextfield) => {
  const [value, setValue] = useState<string | null | undefined>(
    _.get(field.values, "[0].value")
  );

  const lastSavedAt = useMemo(
    () => _.first(field.values)?.updated_at || null,
    [field]
  );

  const handleSave = useCallback(
    ({ newValue }: { newValue: string | null | undefined }) => {
      onUpdate({ field, value: newValue });
      setValue(newValue);
    },
    [field, setValue, onUpdate]
  );

  const showRequiredTag = useMemo(
    () => editable && !value && field.is_required,
    [editable, value, field.is_required]
  );

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Text
            variant="text2"
            sx={{
              fontWeight: 300,
              paddingRight: theme.spacing(2),
            }}
          >
            {field.name}
          </Text>
          <InstructionsDialogButton instructions={field?.instruction} />
        </Stack>
        {editable && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ flexGrow: 0 }}
          >
            {showRequiredTag && <Tag label="required" variant="red" />}
            <EditButton
              name={field.name}
              initialValue={value || ""}
              onSave={handleSave}
              lastSavedAt={lastSavedAt}
              onUpdate={() =>
                toast.successMessage(`${field.name} successfully updated!`)
              }
            />
          </Stack>
        )}
      </Stack>
      {value && (
        <Box
          sx={{
            flex: 1,
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(1, 14, 3, 1),
            marginY: theme.spacing(2),
            backgroundColor: colors.blue10,
          }}
        >
          <SanitizeHtml html={value} />
        </Box>
      )}
    </Box>
  );
};

export default Richtextfield;

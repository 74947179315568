import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { OverridableStringUnion } from "@mui/types";

import { toImgixUrl } from "utils/urls";

import Avatar from "components/Avatar";

export interface IUserAvatar
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  imgix?: boolean;
  sx?: SxProps<Theme>;
  imgixWidth?: number;
  imgixHeight?: number;
  variant?: OverridableStringUnion<"circular" | "rounded" | "square">;
  dataTestid?: string;
}

const UserAvatar = ({
  src,
  imgix = false,
  imgixWidth,
  imgixHeight,
  sx,
  variant,
  dataTestid,
}: IUserAvatar) => {
  const imgixUrl = useMemo(() => {
    if (imgix) {
      return src ? toImgixUrl(src) + `?&w=${imgixWidth}&h=${imgixHeight}` : src;
    }
    return src;
  }, [src, imgix, imgixWidth, imgixHeight]);

  return (
    <Avatar src={imgixUrl} variant={variant} sx={sx} data-testid={dataTestid} />
  );
};

export default UserAvatar;

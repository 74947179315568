import React from "react";

import theme from "theme";

import Box from "components/Box";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

import SectionSkeleton from "entities/Package/components/SectionSkeleton";

const sectionsList = [1, 2];

const CategorySkeleton = () => {
  return (
    <>
      <Box
        sx={{
          padding: theme.spacing(0.5, 1),
          marginBottom: theme.spacing(1),
        }}
      >
        <Skeleton variant="text" width="100%" />
      </Box>
      <Stack
        spacing={1}
        sx={{
          marginLeft: theme.spacing(3),
          marginBottom: theme.spacing(2),
        }}
      >
        {sectionsList.map((index) => (
          <SectionSkeleton key={index} />
        ))}
      </Stack>
    </>
  );
};

export default React.memo(CategorySkeleton);

import _ from "lodash";

import { FieldType } from "entities/Field/sdk";
import { ICompanyCustomFieldParsedValue, IPackage } from "entities/Package/sdk";

export const getValueByFieldIdFromParsedValues = ({
  fieldId,
  values,
}: {
  fieldId: number;
  values: Array<ICompanyCustomFieldParsedValue>;
}): string | undefined | null =>
  _.find(values, (mappedValue) => mappedValue.field_id === fieldId)?.value;

export const getValueFromParsedValues = ({
  parsedValues,
  checklist: { tabs },
}: {
  parsedValues: Array<{
    id: number;
    value: string;
    company_custom_field: { id: number };
  }>;
  checklist: IPackage;
}) => {
  const newValues: ICompanyCustomFieldParsedValue[] = [];

  tabs.flatMap((tab) =>
    tab.categories.flatMap((category) =>
      category.sections.flatMap((section) =>
        section.fields.forEach(({ field_id, field_type, options }) => {
          const parsedValue = parsedValues.find(
            (value) => value.company_custom_field.id === field_id
          )?.value;

          if (!_.isUndefined(parsedValue)) {
            /*
              If the fieldType is TEXT_FIELD, RICH_TEXT_FIELD, or TABLE, we use the parsedValue in FieldEditPreviewFieldWrapper. 
              For DROPDOWN, RADIO_BUTTON, or CHECKBOX fields, we check whether the parsedValue is included in field.options.
            */
            switch (field_type) {
              case FieldType.TEXT_FIELD:
              case FieldType.RICH_TEXT_FIELD:
              case FieldType.TABLE:
                newValues.push({ field_id, value: parsedValue });
                break;
              case FieldType.DROPDOWN:
                const ddvalue =
                  options.find((option) => option.value === parsedValue)
                    ?.value || null;
                newValues.push({ field_id, value: ddvalue });
                break;
              case FieldType.RADIO_BUTTON:
                const radiobuttonValue =
                  options.find((option) => option.value === parsedValue)
                    ?.value || null;
                newValues.push({ field_id, value: radiobuttonValue });
                break;
              case FieldType.CHECKBOX:
                const checkboxValue =
                  options.find((option) => option.value === parsedValue)
                    ?.value || null;
                newValues.push({ field_id, value: checkboxValue });
                break;
            }
          }
        })
      )
    )
  );

  return newValues;
};

import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Box from "components/Box";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import NavItem from "components/DealPageLayout/SubNavigation/MenuList/NavItem";
import { MenuItem } from "components/Menu";
import { useFeatureDisabledDialog } from "components/useFeatureDisabledDialog";

import { useSignedUser } from "entities/Auth/sdk";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { companySettingsFeatureLimitCheck } from "entities/Company/sdk";
import { useDealDetails } from "entities/Deal/sdk";
import { useMemoTemplates } from "entities/MemoTemplate/sdk";

export interface IMemoMenu {
  dealId: number;
  memoId: number;
}

const MemoMenu = ({ dealId, memoId }: IMemoMenu) => {
  const { show: showFeatureDisabledDialog } = useFeatureDisabledDialog();

  const { data: deal } = useDealDetails({ dealId });
  const { data: user } = useSignedUser();

  const isDealOwner = useMemo(
    () => deal?.company.id === user?.company_id,
    [deal, user]
  );

  const location = useLocation();
  const navigate = useNavigate();

  const { data: memoTemplates = [] } = useMemoTemplates();
  const orderedMemos = useMemo(
    () => _.orderBy(memoTemplates, ["name"]),
    [memoTemplates]
  );

  const checkFeatureAndRedirectOrShowFeatureDisabledDialog = useCallback(
    ({
      feature,
      url,
    }: {
      feature: ECompanySettingsFeatureType;
      url: string;
    }) => {
      companySettingsFeatureLimitCheck({ featureType: feature, dealId })
        .then(({ has_access }) => {
          if (has_access) {
            navigate(url);
          } else if (isDealOwner) {
            showFeatureDisabledDialog({});
          } else {
            showFeatureDisabledDialog({
              isDealFeature: true,
              dealCompanyName: deal?.company.name || "",
            });
          }
        })
        .catch(handleInvalidRequest);
    },
    [dealId, navigate, showFeatureDisabledDialog, deal, isDealOwner]
  );

  return (
    <NavItem
      buttonDataTestid="deal-navigation-memos"
      isActive={location.pathname.indexOf("memo/") > 0}
      label="Memos"
    >
      <GroupLabel label="Memos" />
      {orderedMemos?.map((memo) => (
        <Box
          data-testid="deal-memo-link"
          key={memo.memo_id}
          onClick={() =>
            checkFeatureAndRedirectOrShowFeatureDisabledDialog({
              feature: ECompanySettingsFeatureType.MEMO,
              url: reverse(URLS.DEAL_MEMO, {
                dealId,
                memoId: memo.memo_id,
              }),
            })
          }
        >
          <MenuItem
            selected={memo.memo_id === memoId}
            data-testid="memo-package-name"
          >
            {memo.name}
          </MenuItem>
        </Box>
      ))}

      {_.isEmpty(orderedMemos) && (
        <MenuItem disabled>No memo templates</MenuItem>
      )}
    </NavItem>
  );
};

export default MemoMenu;

import { ID as COMPANY_SETTINGS_SUBSCRIPTION_DETAILS_ID } from "entities/Company/components/CompanySettings/SubscriptionDetails";
import { ID as COMPANY_PROFILE_SECTION_ID } from "entities/Company/components/ProfileInfoBox";
import { ID as COMPANY_TEAM_SECTION_ID } from "entities/Company/components/TeamInfoBox";
import { ID as USER_INFO_BOX_ID } from "entities/User/components/InfoBox";

export const ANCHORS = [
  USER_INFO_BOX_ID,
  COMPANY_PROFILE_SECTION_ID,
  COMPANY_TEAM_SECTION_ID,
  COMPANY_SETTINGS_SUBSCRIPTION_DETAILS_ID,
];

export enum UserProfileMenuChoices {
  USER_INFO = USER_INFO_BOX_ID,
  COMPANY_PROFILE = COMPANY_TEAM_SECTION_ID,
  COMPANY_TEAM = COMPANY_TEAM_SECTION_ID,
  COMPANY_SETTINGS = COMPANY_SETTINGS_SUBSCRIPTION_DETAILS_ID,
}

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { CheckCircleOutlineIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogActions, DialogContent } from "components/Dialog";
import Text from "components/Text";

export interface ISubmittedSuccessfullyDialog {
  isOpen: boolean;
  applicationName: string;
}

const SubmittedSuccessfullyDialog = ({
  isOpen,
  applicationName,
}: ISubmittedSuccessfullyDialog) => {
  const navigate = useNavigate();

  const navigateToApplicationsList = useCallback(() => {
    navigate(reverse(URLS.APPLICATIONS_LIST, { status: "All" }));
  }, [navigate]);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={navigateToApplicationsList}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(4, 12),
        }}
      >
        <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 68 }} />

        <Text variant="h1" marginTop={3} marginBottom={1}>
          Intake submitted!
        </Text>

        <Text variant="text1" color={colors.gray80}>
          Intake name: <b>{applicationName}</b>
        </Text>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", marginBottom: 4 }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={navigateToApplicationsList}
        >
          Intake dashboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmittedSuccessfullyDialog;

export enum ECompanySettingsFeatureType {
  MAX_DEAL_COUNT = "max_deal_count",
  MAX_CHECKLIST_COUNT = "max_checklist_count",
  MAX_INTAKE_CHECKLIST_COUNT = "max_intake_checklist_count",
  MAX_AI_SUMMARY_DOCUMENT_COUNT = "max_ai_summary_document_count",
  AI_SUMMARY = "ai_summary",
  ASSET_MANAGEMENT_PROFORMA = "asset_management_proforma",
  UNDERWRITING_PROFORMA = "underwriting_proforma",
  CUSTOM_REPORTS = "custom_reports",
  MEMO = "memo",
  INTAKE_APPLICANT = "intake_applicant",
  INTAKE_LENDER = "intake_lender",
  ANALYTICS_DEVELOPMENT_BUDGET = "analytics_development_budget",
  ANALYTICS_INCOME_AND_OPEX = "analytics_income_and_opex",
  TABLES = "tables",
  DEAL_TABLES = "deal_tables",
}

export const DEAL_COMPANY_SETTINGS_FEATURES = [
  ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT,
  ECompanySettingsFeatureType.ASSET_MANAGEMENT_PROFORMA,
  ECompanySettingsFeatureType.UNDERWRITING_PROFORMA,
  ECompanySettingsFeatureType.MEMO,
  ECompanySettingsFeatureType.DEAL_TABLES,
];

import { useMemo } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";

import Loading from "components/Loading";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCompanySettingsFeatureCheck } from "entities/Company/sdk";

export interface ICompanySettingGuard {
  featureType: ECompanySettingsFeatureType;
}

// This guard is used to wrap all routes for a specific feature and check if the company has access to it
// Some features are paid and some are free
// We want to show all features but disable access for unpaid, only to show a message and option to upgrade
const CompanySettingGuard = ({ featureType }: ICompanySettingGuard) => {
  const params = useParams<{
    dealId?: string;
  }>();

  const dealId = useMemo(
    () => (_.isUndefined(params.dealId) ? undefined : Number(params.dealId)),
    [params.dealId]
  );

  const { data, isLoading, error } = useCompanySettingsFeatureCheck({
    featureType,
    dealId,
  });

  if (isLoading) {
    return <Loading open />;
  }

  if (!_.isUndefined(error) && error.status === 403) {
    return <Navigate to={URLS.ACCESS_DENIED} replace />;
  }

  if (!_.isUndefined(data) && !data.has_access) {
    return <Navigate to={URLS.FEATURE_ACCESS_DENIED} replace />;
  }

  return <Outlet />;
};

export default CompanySettingGuard;

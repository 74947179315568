import React from "react";
import _ from "lodash";

import { FileUploadIcon, PublishOutlinedIcon } from "icons";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import { DropzoneState } from "components/Dropzone";
import IconButton from "components/IconButton";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export const defaultUploadButton = (
  state: Partial<DropzoneState>,
  label: string,
  buttonDataTestid?: string
) => {
  return (
    <Button
      variant={state.isDragActive ? "contained" : "outlined"}
      color="secondary"
      size="large"
      component="span"
      data-testid={buttonDataTestid}
    >
      {label}
    </Button>
  );
};

export const defaultDragAndDropZone = (
  state: Partial<DropzoneState>,
  label: string
) => {
  return (
    <Paper
      sx={{
        border: `1px dotted ${
          state.isDragActive ? colors.pink100 : colors.gray40
        }`,
        height: "100px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        color: state.isDragActive ? colors.gray60 : colors.black,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <FileUploadIcon />
        <Text variant="h3">{label}</Text>
      </Stack>
    </Paper>
  );
};

export const defaultSmallFileUploadButton = ({
  onClick = undefined,
  buttonDataTestid,
}: {
  onClick?: () => void;
  buttonDataTestid?: string;
}) => {
  return (
    <Tooltip title="Upload" placement="top">
      <IconButton
        size="small"
        component="span"
        onClick={onClick}
        dataTestid={buttonDataTestid}
      >
        <PublishOutlinedIcon sx={{ width: "20px", height: "20px" }} />
      </IconButton>
    </Tooltip>
  );
};

export const defaultProformaUploadButton = ({
  state,
  errors,
}: {
  state: Partial<DropzoneState>;
  errors?: string;
}) => {
  return (
    <Box
      sx={{
        border: `2px dashed ${
          state.isDragActive ? colors.pink100 : colors.gray40
        }`,
        width: 300,
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <FileUploadIcon
        sx={{ height: "80px", width: "80px", color: colors.gray60 }}
      />
      <Button variant="text" data-testid="upload-file-button">
        Upload files
      </Button>
      {!_.isUndefined(errors) && (
        <Text
          variant="caption"
          color={colors.red120}
          data-testid="file-format-error"
        >
          {errors}
        </Text>
      )}
    </Box>
  );
};

export const defaultPDFUploadButton = ({
  state,
  errors,
}: {
  state: Partial<DropzoneState>;
  errors?: string;
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        border: `2px dashed ${
          state.isDragActive ? colors.pink100 : colors.gray40
        }`,
        padding: "20px",
      }}
    >
      <FileUploadIcon
        sx={{ height: "80px", width: "80px", color: colors.gray60 }}
      />
      <Button variant="text" data-testid="upload-file-button">
        Upload files
      </Button>
      {!_.isUndefined(errors) && (
        <Text
          variant="caption"
          color={colors.red120}
          data-testid="file-format-error"
        >
          {errors}
        </Text>
      )}
    </Stack>
  );
};

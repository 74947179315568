import React, { useCallback } from "react";

import { AddIcon } from "icons";
import theme from "theme";

import Dialog, { DialogTitle } from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import MemoTemplateForm, {
  MemoTemplateValuesType,
} from "entities/MemoTemplate/components/MemoTemplateForm";
import { createMemoTemplate } from "entities/MemoTemplate/sdk";

interface IAddMemoTemplateDialog {
  open: boolean;
  onClose: () => void;
  onMemoTemplateCreated: () => void;
}

const AddMemoTemplateDialog: React.FC<IAddMemoTemplateDialog> = ({
  open,
  onClose,
  onMemoTemplateCreated,
}) => {
  const handleSubmit = useCallback(
    (data: MemoTemplateValuesType) =>
      createMemoTemplate(data).then(() => {
        onMemoTemplateCreated();
      }),
    [onMemoTemplateCreated]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Paper
        sx={{
          paddingTop: theme.spacing(8),
          paddingBottom: theme.spacing(8),
        }}
        data-testid="new-template-dialog"
      >
        <DialogTitle data-testid="dialog-title">
          <Stack direction="row" spacing={1}>
            <AddIcon
              style={{
                height: theme.spacing(5),
                width: theme.spacing(5),
              }}
            />
            <Text variant="h1">Add New Memo Template</Text>
          </Stack>
        </DialogTitle>
        <MemoTemplateForm onCancel={onClose} onSubmit={handleSubmit} />
      </Paper>
    </Dialog>
  );
};

export default AddMemoTemplateDialog;

import _ from "lodash";

import { isNotUndefined } from "utils/common";

import {
  extractCellKey,
  generateCellItemKey,
  generateRowItemKey,
} from "entities/AssetManagement/components/Table/utils";
import { AssetManagementTabItems } from "entities/AssetManagement/constants";
import { IAssetManagementDataV2, IDiff } from "entities/AssetManagement/sdk";

export const getTypeAndDateInfoByColumnKey = (
  columnKey: string
): { year: number; type: string; quarter?: number } => {
  const split = columnKey.split("-");
  return split.length === 2
    ? { year: Number(split[0]), type: split[1] }
    : { year: Number(split[0]), quarter: Number(split[1]), type: split[2] };
};

const SUB_CATEGORY_TO_CATEGORY = {
  "01. Rental income": AssetManagementTabItems.INCOME,
  "02. Income losses": AssetManagementTabItems.INCOME,
  "03. Other income": AssetManagementTabItems.INCOME,
  Management: AssetManagementTabItems.EXPENSES,
  Administration: AssetManagementTabItems.EXPENSES,
  "Repair and maintenance": AssetManagementTabItems.EXPENSES,
  Utilities: AssetManagementTabItems.EXPENSES,
  Insurance: AssetManagementTabItems.EXPENSES,
  Taxes: AssetManagementTabItems.EXPENSES,
  Reserves: AssetManagementTabItems.EXPENSES,
  "Other expenses": AssetManagementTabItems.EXPENSES,
  "Below the line data": AssetManagementTabItems.OTHER_DATA,
};

export const subCategoryToCategory = (
  subCategory: string
): AssetManagementTabItems => {
  return _.get(
    SUB_CATEGORY_TO_CATEGORY,
    subCategory,
    AssetManagementTabItems.DEBT_SERVICE
  );
};

export const constructUpdatedDict = ({
  tableData,
  changedCells,
  diffRows,
  deletedRowKeys,
  yearMappings,
}: {
  tableData: IAssetManagementDataV2;
  changedCells: {
    [key: string]: {
      previous_value: number | null;
      current_value: number | null;
    };
  };
  diffRows?: {
    [key: string]: {
      is_deleted: boolean;
      order: number | null;
      previous_label: string | null;
      current_label: string | null;
    };
  };
  deletedRowKeys: Array<string>;
  yearMappings: {
    [key: string]: string;
  };
}): Array<{
  key: string;
  label: string;
  values: Array<{
    key: string;
    value: number | null;
  }>;
}> => {
  let newCells = changedCells; // We'll remove the updated ones later on.

  // Construct the diff object containing only the changed cells.
  let diff = _.map(tableData.rows, (row) => {
    const rowKey = generateRowItemKey({ row });

    return {
      key: rowKey,
      label: row.label,
      values: _.map(row.values, (value) => {
        const valueKey = generateCellItemKey({ row, value });
        let cellValue = value.value;

        if (!_.isUndefined(changedCells[valueKey])) {
          cellValue = changedCells[valueKey].current_value;
          newCells = _.omit(newCells, [valueKey]);
        }

        return {
          key: valueKey,
          value: cellValue,
        };
      }),
    };
  });

  // Construct the object containing only the new rows with changed cells.
  let newRows = _.map(diffRows, (row, rowKey) => {
    // We want to iterate only the rows that are new and have changed cells.

    // TODO: upgrade this code
    if (_.isUndefined(_.find(diff, (tableRow) => tableRow.key === rowKey))) {
      return {
        key: rowKey,
        label: row.current_label || "",
        values: _.map(changedCells, (value, key) => {
          if (key.startsWith(rowKey)) {
            let cellValue = value.current_value;

            if (!_.isUndefined(newCells) && !_.isUndefined(newCells[key])) {
              cellValue = newCells[key].current_value;
              newCells = _.omit(newCells, [key]);
              return {
                key,
                value: cellValue,
              };
            }
          }
        }).filter(isNotUndefined),
      };
    }
  }).filter(isNotUndefined);

  // Add the new cells to the diff.
  _.forEach(newCells, (newCell, key) => {
    const [itemCode, subCategory] = key.split("|");

    diff = diff.map((row) =>
      row.key === `${itemCode}|${subCategory}`
        ? {
            ...row,
            values: [
              ...row.values,
              {
                key,
                value: newCell.current_value,
              },
            ],
          }
        : row
    );
  });

  diff = [...diff, ...newRows];
  // Remove the deleted rows from the diff.
  diff = diff.filter((row) => !deletedRowKeys.includes(row.key));

  // Apply year mappings over the final diff.
  diff = diff.map((row) => ({
    ...row,
    values: row.values.map(({ value, key }) => {
      const [itemCode, subCategory, year, quarter, dataType] = key.split("|");

      const newYear = yearMappings[year] || year;
      const newKey = `${itemCode}|${subCategory}|${newYear}|${quarter}|${dataType}`;

      return { value, key: newKey };
    }),
  }));

  return diff;
};

export const applyDiffToNewApiType = ({
  data: versionData,
  diff,
}: {
  data: IAssetManagementDataV2;
  diff: IDiff;
}) => {
  const diffRows = _.map(diff?.rows_diff, (value, key) => {
    const [code, subCategory] = key.split("|");

    return { subCategory, code, key, object: value };
  });

  const mappedDiffRows = _.map(diffRows, (row) => ({
    id: null,
    item_code: row.code,
    label: row.object.current_label || "",
    sub_category: row.subCategory,
    is_sub_category: true,
    values: (() => {
      const filteredCellsForRow = _.map(diff?.cells_diff, (value, key) => {
        const [, , year, quarter, dataType] = key.split("|");
        return [key, { ...value, year, quarter, dataType }] as const;
      })
        .filter(([cellKey, _]) => cellKey.startsWith(row.key))
        .map(([, val]) => val);

      return _.map(filteredCellsForRow, (cell) => ({
        id: null,
        data_type: cell.dataType,
        value: cell.previous_value,
        newValue: cell.current_value,
        date_info: {
          year: cell.year,
          quarter: cell.quarter ? Number(cell.quarter) : null,
        },
      }));
    })(),
  }));

  const getDiffHeaders = (diff: IDiff) => {
    return _.uniqBy(
      Object.keys(diff?.cells_diff || {}).map((key) => {
        const { year } = extractCellKey({ itemKey: key });
        return { year, quarter: null };
      }),
      "year" // Note: PDF data does not bring in quarter information. PDFs update only year data.
    );
  };

  const dataHeaders = versionData.headers
    .filter((header) => _.isNull(header.quarter)) // Note: PDF data does not bring in quarter information. PDFs update only year data.
    .map((header) => ({
      year: header.year.toString(),
      quarter: null,
    }));

  const headers = _.sortBy(
    _.uniqBy([...getDiffHeaders(diff), ...dataHeaders], "year"),
    "year"
  );

  const rows = _.concat(versionData.rows, mappedDiffRows);

  return {
    rows,
    headers,
  };
};

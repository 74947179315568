// This file is a copy of entities/Fields/Tablefield, but without all the functionality

import { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import theme from "theme";
import { colors } from "theme/palette";
import { EMPTY_LIST } from "utils/constants";

import Alert from "components/Alert";
import Box from "components/Box";
import SimpleTable from "components/SimpleTable";
import Stack from "components/Stack";

import EditButton from "entities/Package/components/PdfImport/Fields/Tablefield/EditButton";
import FieldTitle from "entities/Package/components/PdfImport/Fields/Title";
import { IPackageField } from "entities/Package/sdk";

export interface ITablefield {
  field: IPackageField;
  editable: boolean;
  parsedValue: any;
  dealId: number;
  packageId: number;
  categoryId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({
    fieldId,
    value,
  }: {
    fieldId: number;
    value: string | undefined | null;
  }) => void;
}

const Tablefield = ({
  field,
  editable,
  parsedValue,
  dealId,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
}: ITablefield) => {
  /*
    With the constant EMPTY_LIST we don't create a new array on every rerender and
    avoid the error of type `Maximum update depth exceeded`
   */
  const fieldValue = useMemo(
    () =>
      _.get(field.values, "0.column_value", EMPTY_LIST) as Array<{
        [key: string]: string;
      }>,
    [field]
  );

  const tableValues = useMemo(
    () => (parsedValue ? JSON.parse(parsedValue) : fieldValue),
    [parsedValue, fieldValue]
  );

  const [values, setValues] = useState<any[]>(EMPTY_LIST);
  const [showFieldError, setShowFieldError] = useState<boolean>(false);

  const fieldColumns = useMemo(
    () =>
      field.columns
        ? field.columns.map((x) => ({ field: x, headerName: x }))
        : [],
    [field]
  );

  useEffect(() => {
    if (tableValues) {
      if (_.isArray(tableValues)) {
        setValues(tableValues.map((x) => ({ ...x, key: uuidv4() })));
      } else {
        Sentry.captureMessage("Table field value is not array!", (scope) => {
          scope.setTags({
            deal: dealId,
            package: packageId,
            tab: tabId,
            category: categoryId,
            section: sectionId,
            field: field.field_id,
          });
          scope.setExtra("values", values);
          return scope;
        });
        setShowFieldError(true);
      }
    }
    // eslint-disable-next-line
  }, [setValues]);

  const handleSave = useCallback(
    (newValue: Array<{ [key: string]: string }>) => {
      if (_.isNil(newValue)) {
        return;
      }

      setValues(newValue);
      onUpdate({ fieldId: field.field_id, value: JSON.stringify(newValue) });
    },
    [field, setValues, onUpdate]
  );

  if (!field.columns) {
    return null;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
        data-testid="dd-field"
      >
        <FieldTitle field={field} editable={editable} />
        <Stack direction="row" spacing={1} alignItems="center">
          {!field.n_a_status && !showFieldError && editable && (
            <EditButton
              field={{
                display_name: field.name,
                header_name: fieldColumns,
              }}
              initialValue={values}
              onSave={handleSave}
              buttonDataTestid="field-edit-button"
            />
          )}
        </Stack>
      </Stack>
      {!field.n_a_status && (
        <Box
          sx={{ paddingLeft: theme.spacing(6), marginY: theme.spacing(3) }}
          data-testid="dd-table-field-table"
        >
          <SimpleTable
            rows={fieldValue && !values ? fieldValue : values}
            headers={fieldColumns.map(({ headerName, field }) => ({
              label: headerName,
              key: field,
            }))}
            tableDataTestid={field.name}
          />
          {showFieldError && (
            <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
              There was an error displaying table values. Our team has been
              notified about this and is working on a fix.
            </Alert>
          )}
        </Box>
      )}
    </>
  );
};

export default Tablefield;

import { useCallback, useMemo, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { DeleteOutlineOutlinedIcon } from "icons";
import theme from "theme";
import { useCaching } from "utils/common";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import IconButton from "components/IconButton";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { IFieldTemplateMeta } from "entities/Checklist/sdk";
import FieldValue from "entities/Field/components/FormTextField/FieldValue";
import EditableField from "entities/Field/components/Textfield/EditableField";
import { CompanyFieldFormatType } from "entities/Field/constants";
import { FieldType } from "entities/Field/sdk";

export interface ILinkedField {
  formik: ReturnType<typeof useFormik>;
  templateMeta: IFieldTemplateMeta;
  onChange: ({
    updatedValue,
    fieldName,
  }: {
    updatedValue: string | number | null | undefined;
    fieldName: string;
  }) => void;
}
const LinkedField = ({ formik, templateMeta, onChange }: ILinkedField) => {
  const formikKey = useMemo(
    () => templateMeta.field_id.toString(),
    [templateMeta]
  );

  const [value, setValue] = useCaching<string | null | number | undefined>(
    templateMeta.value
  );

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(false);

  const fieldName = useMemo(
    () => templateMeta.field_name || "",
    [templateMeta]
  );

  const selectedCheckboxValue: string[] = useMemo(
    () => (_.isNil(value) || value === "" ? [] : value.toString().split(",")),
    [value]
  );

  const handleReset = useCallback(() => setValue(null), [setValue]);

  const handleChangeCheckboxes = useCallback(
    (event: any) => setValue(event.target.value.join(",")),
    [setValue]
  );

  const handleChangeSingleOptions = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(event.target.value),
    [setValue]
  );

  const handleSave = useCallback(
    () =>
      onChange({
        updatedValue: value,
        fieldName,
      }),
    [fieldName, onChange, value]
  );

  const isEditable = useMemo(() => templateMeta.is_editable, [templateMeta]);

  const fieldComponent = useMemo(() => {
    let component: React.ReactNode;

    if (isEditable) {
      switch (templateMeta.field_type) {
        case FieldType.TEXT_FIELD:
          component = (
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="start"
              sx={{
                marginBottom: theme.spacing(0.5),
                minWidth: "150px",
              }}
            >
              <EditableField
                {...formik.getFieldProps(formikKey)}
                value={value}
                disabled={!isEditable}
                fieldFormatType={templateMeta.field_format_type}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                onChangeValue={setValue}
              />
              <Button
                onClick={handleSave}
                data-testid="submit-button"
                disabled={isSaveButtonDisabled}
              >
                Save
              </Button>
              {templateMeta.field_format_type ===
                CompanyFieldFormatType.DATE && (
                <IconButton
                  title="Reset the value"
                  size="small"
                  onClick={handleReset}
                  style={{ marginTop: "7px" }}
                  // We want to center the button and field, but the field could have an error message.
                  // The EditableField is height 38px (without the error message) and the IconButton is height 24px, so we need to offset the IconButton by 7px to center it.
                  dataTestid="reset-value-button"
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              )}
            </Stack>
          );
          break;

        case FieldType.CHECKBOX:
          component = (
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{
                marginBottom: theme.spacing(0.5),
                minWidth: "150px",
              }}
            >
              <Select
                multiple
                options={templateMeta.options || []}
                size="small"
                {...formik.getFieldProps(formikKey)}
                value={selectedCheckboxValue}
                onChange={handleChangeCheckboxes}
                placeholder="Select multiple data"
                data-testid="edit-dropdown-field"
                inputProps={{ "data-testid": "edit-dropdown-input" }}
                dropdownOptionsDataTestid="dropdown-select-option"
                renderOption={({ label }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(),
                    }}
                  >
                    <Checkbox
                      checked={selectedCheckboxValue.includes(label)}
                      sx={{ padding: 0 }}
                      data-testid="option-checkbox"
                    />
                    <Text variant="text2" data-testid="option-label">
                      {label}
                    </Text>
                  </div>
                )}
              />
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
              <IconButton
                title="Reset the value"
                size="small"
                onClick={handleReset}
                dataTestid="reset-value-button"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Stack>
          );
          break;

        case FieldType.RADIO_BUTTON:
        case FieldType.DROPDOWN:
          component = (
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{
                marginBottom: theme.spacing(0.5),
                minWidth: "150px",
              }}
            >
              <Select
                options={templateMeta.options || []}
                size="small"
                {...formik.getFieldProps(formikKey)}
                value={value}
                onChange={handleChangeSingleOptions}
                placeholder="Select data"
                data-testid="edit-dropdown-field"
                inputProps={{ "data-testid": "edit-dropdown-input" }}
                dropdownOptionsDataTestid="dropdown-select-option"
                sx={{ flexGrow: 1 }}
              />
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
              <IconButton
                title="Reset the value"
                size="small"
                onClick={handleReset}
                dataTestid="reset-value-button"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Stack>
          );
          break;
      }
    } else {
      component = (
        <Tooltip
          title="You don't have permission to edit this field."
          data-testid="read-only-tooltip-label"
        >
          <span>
            <FieldValue templateMeta={templateMeta} />
          </span>
        </Tooltip>
      );
    }

    return component;
  }, [
    value,
    formik,
    formikKey,
    isEditable,
    selectedCheckboxValue,
    templateMeta,
    handleChangeCheckboxes,
    handleChangeSingleOptions,
    handleReset,
    handleSave,
    setValue,
    isSaveButtonDisabled,
  ]);

  return (
    <div
      style={{
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      }}
      data-testid="edit-linked-field-value-component"
      data-test-id={`linked-field-id-${formikKey}`}
    >
      {fieldComponent}
    </div>
  );
};

export default LinkedField;

export const creationDateRetrieveReadable = ({
  creationDate,
}: {
  creationDate: string;
}) => {
  const creationDateObject = new Date(creationDate);
  const now = new Date();

  const elapsedMilliseconds = now.getTime() - creationDateObject.getTime();

  // Convert milliseconds to seconds
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

  // Define time intervals in seconds
  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;

  if (elapsedSeconds < minute) {
    return `${elapsedSeconds} second${elapsedSeconds !== 1 ? "s" : ""} ago`;
  } else if (elapsedSeconds < hour) {
    const minutesAgo = Math.floor(elapsedSeconds / minute);
    return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
  } else if (elapsedSeconds < day) {
    const hoursAgo = Math.floor(elapsedSeconds / hour);
    return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
  } else {
    const daysAgo = Math.floor(elapsedSeconds / day);
    return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
  }
};

export enum AnalyticsDevBudgetTabs {
  TOTAL_COST = "total_cost",
  SOFT_COSTT = "soft_cost",
  HARD_COST = "hard_cost",
}

export enum AnalyticsIncomeAndOpexTabs {
  INCOME = "income",
  EXPENSES = "expenses",
  METRICS = "metrics",
}

import { memo } from "react";
import {
  ActiveElement,
  BarController,
  BarElement,
  CategoryScale,
  ChartDataset,
  ChartEvent,
  LinearScale,
  Tooltip,
} from "chart.js";
import { ChartOptions } from "chart.js";
import { ReactChart } from "chartjs-react";
import _ from "lodash";

ReactChart.register(
  BarController,
  LinearScale,
  BarElement,
  Tooltip,
  CategoryScale
);

export const options: ChartOptions = {
  aspectRatio: 4,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

interface IChart<TData, TLabel> {
  datasets: TData;
  labels: TLabel;
  onBarHover: (index: number) => void;
}

const Chart = <TData extends ChartDataset[], TLabel extends any[]>({
  datasets,
  labels,
  onBarHover,
}: IChart<TData, TLabel>) => {
  const handleBarHover = (
    event: ChartEvent,
    elements: Array<ActiveElement>
  ) => {
    if (elements.length > 0) {
      onBarHover(elements[0].index);
    }
  };

  return (
    <ReactChart
      type="bar"
      width={100}
      height={40}
      options={{
        ...options,
        onHover: handleBarHover,
      }}
      data={{ labels, datasets }}
    />
  );
};

export default memo(
  Chart,
  (prevProps, nextProps) =>
    _.isEqual(prevProps.datasets, nextProps.datasets) &&
    prevProps.labels === nextProps.labels
);

import _ from "lodash";

import { URLS } from "config/urls";
import { LaunchIcon } from "icons";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import IconButton from "components/IconButton";
import { Link } from "components/Link";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Stack from "components/Stack";
import Text from "components/Text";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { getCompanySettingsFeatureLabel } from "entities/Superuser/utils";

export const CUSTOMER_LIST_HEADERS: IScrollableTableHeader = [
  {
    label: "Customer",
    sticky: true,
    children: [
      {
        label: "Name",
        key: "name",
        width: "500px",
        render: ({ row, key }) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            flex={1}
          >
            <Text variant="text3" fontWeight={700}>
              {row[key]}
            </Text>
            <Link
              to={reverse(URLS.SUPERUSER_CUSTOMER_DETAILS, {
                companyId: row["id"],
              })}
            >
              <IconButton
                sx={{
                  borderRadius: "2px",
                  backgroundColor: colors.blue10,
                  width: "20px",
                  height: "20px",
                }}
              >
                <LaunchIcon style={{ width: 12, height: 12 }} />
              </IconButton>
            </Link>
          </Stack>
        ),
      },
    ],
  },
  {
    label: "Product usage & settings",
    children: [
      {
        label: "Deals",
        key: "settings.max_deal_count",
        render: ({ row, key }) => (
          <Text variant="text2">
            <span style={{ fontWeight: 700 }}>{row.deal_count}</span>/
            {_.get(row, key)}
          </Text>
        ),
      },
      {
        label: "Seats",
        key: "company_user_count",
      },
      {
        label: "AI Summaries",
        key: "settings.max_ai_summary_document_count",
      },
      {
        label: "AI Summary",
        key: "settings.ai_summary_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.AI_SUMMARY,
            companySettings: row.settings,
          });

          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Checklists",
        key: "settings.max_checklist_count",
      },
      {
        label: "Checklists (Intake)",
        key: "settings.max_intake_checklist_count",
      },
      {
        label: "Draft Intake",
        key: "draft_application_count",
      },
      {
        label: "Asset Management",
        key: "settings.asset_management_proforma_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.ASSET_MANAGEMENT_PROFORMA,
            companySettings: row.settings,
          });

          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Pro forma",
        key: "settings.underwriting_proforma_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.UNDERWRITING_PROFORMA,
            companySettings: row.settings,
          });

          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Custom reports",
        key: "settings.custom_reports_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.CUSTOM_REPORTS,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Memo",
        key: "settings.custom_reports_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.MEMO,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Intake Reviewer",
        key: "settings.intake_lender_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.INTAKE_LENDER,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Intake Applicant",
        key: "settings.intake_applicant_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.INTAKE_APPLICANT,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Analytics - Development Budget",
        key: "settings.analytics_development_budget_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.ANALYTICS_DEVELOPMENT_BUDGET,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Analytics - Income and OPEX",
        key: "settings.analytics_income_and_opex_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.ANALYTICS_INCOME_AND_OPEX,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Company-level Data Tables",
        key: "settings.tables_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.TABLES,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
      {
        label: "Deal-level Data Tables",
        key: "settings.deal_tables_enabled",
        width: "200px",
        render: ({ row, key }) => {
          const label = getCompanySettingsFeatureLabel({
            feature: ECompanySettingsFeatureType.DEAL_TABLES,
            companySettings: row.settings,
          });
          return <Text variant="text2">{label}</Text>;
        },
      },
    ],
  },
];

import React, { ForwardedRef } from "react";
import {
  TreeItem as MuiTreeItem,
  TreeItemProps,
} from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";

import theme from "theme";

interface ITreeItem extends TreeItemProps {
  dataTestid?: string;
}

export const TreeItem = React.forwardRef(
  (
    { dataTestid, ...rest }: ITreeItem,
    ref: ForwardedRef<HTMLLIElement> | null
  ) => (
    <MuiTreeItem
      sx={{
        "& .MuiTreeItem-content": {
          borderRadius: theme.spacing(0.5),
        },
      }}
      data-testid={dataTestid}
      {...rest}
      ref={ref}
    />
  )
);

export default TreeView;

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { URLS } from "config/urls";

import { IFieldOption } from "entities/Package/sdk";

export function useCaching<T extends any>(initialData: T) {
  const [data, setData] = useState<T>(initialData);
  useEffect(() => setData(initialData), [initialData]);

  return [data, setData] as [T, Dispatch<SetStateAction<T>>];
}

/**
 * Call this function as a placeholder whenever there's a point in the code that should not be reached.
 *
 * The Type is set to () => void so it doesn't clash with overriding implementations
 *
 * Example usage: when defining the types for a Context provider.
 *
 */
export const notImplementedError = (): void => {
  throw new Error("Not Implemented.");
};

/**
 * Type guard for Typescript to find out if an object is a Promise
 */
export const isPromise = <T>(
  promiseOrOther: Promise<T> | any
): promiseOrOther is Promise<T> => {
  return typeof promiseOrOther?.then === "function";
};

export const columnIsSortedByNumbers = ({
  selectedSortOption,
  data,
}: {
  selectedSortOption: string | undefined;
  data: Array<{ [key: string]: string | number | null | undefined }>;
}) => {
  if (_.isUndefined(selectedSortOption)) {
    return false;
  }

  const isReverse = selectedSortOption.startsWith("-");
  const cleanKey = isReverse ? selectedSortOption.slice(1) : selectedSortOption;

  return data.filter((x) => Number(_.get(x, cleanKey)) || 0).length > 0;
};

export const sortListBy = <T extends { [key: string]: number | string | null }>(
  list: T[],
  key: string | undefined,
  isNumber: boolean = false
) => {
  // TODO add proper sorting for number values
  if (typeof key === "undefined") {
    return list;
  }

  const isReverse = key.startsWith("-");
  const cleanKey = isReverse ? key.slice(1) : key;

  const f = (o: T) => {
    if (_.isNil(o[cleanKey]) || _.isNaN(o[cleanKey])) {
      return isNumber ? -Infinity : "";
    }

    if (isNumber) {
      return Number(o[cleanKey] || -Infinity);
    }

    return o[cleanKey]?.toString().toLowerCase();
  };

  if (isReverse) {
    return _.reverse(_.orderBy(list, [f]));
  }

  return _.orderBy(list, [f]);
};

export const sortListByV2 = <
  T extends { [key: string]: number | string | null },
>({
  list,
  key,
  isNumber = false,
  isDateTime = false,
}: {
  list: T[];
  key: string | undefined;
  isNumber: boolean;
  isDateTime: boolean;
}) => {
  // TODO add proper sorting for number values
  if (typeof key === "undefined") {
    return list;
  }

  const isReverse = key.startsWith("-");
  const cleanKey = isReverse ? key.slice(1) : key;

  const f = (o: T) => {
    const value = o[cleanKey];

    if (isNumber) {
      return Number(value);
    }

    if (isDateTime && value !== null) {
      return new Date(value);
    }

    return value?.toString().toLowerCase();
  };

  if (isReverse) {
    return _.reverse(_.orderBy(list, [f]));
  }

  return _.orderBy(list, [f]);
};

export const useUuid = () => {
  return useMemo(() => uuidv4(), []);
};

export const humanReadableNumber = (num: number): string => {
  if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  }
  if (num > 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return Number(num.toFixed(2)).toLocaleString();
};

/**
 * A type guard that checks if the value T is anything different from `undefined`
 *
 * This function is the reverse of _.isUndefined from lodash.
 *
 * the isNotUndefined function is useful when applying it to an Array of values that may be undefined
 * Like so:
 *
 *   const values = [1,2,undefined,3,5,undefined];
 *
 *   const res1 = values.filter(x => x)
 *   // Even tho the values are only numbers now, the type of "res1" is still `number | undefined`
 *   console.log(res1);
 *
 *   const res2 = values.filter(isNotUndefined)
 *   // The type of "res2" is `number`. We've removed the undefined using this type guard.
 *   console.log(res2);
 */
export const isNotUndefined = <T extends any>(
  value: T | undefined
): value is T => {
  return !(typeof value === "undefined");
};

export const formatNumberToString = ({
  number,
  locales,
  options,
}: {
  number: number;
  locales?: string;
  options?: {
    maximumFractionDigits: number;
  };
}) => {
  if (number > 100 || number < -100) {
    return _.round(number).toLocaleString(locales, options);
  }

  return _.round(number, 2).toFixed(2);
};

export const openURLInNewTab = ({ url }: { url: string }) =>
  window.open(url, "_blank");

export const emailValidate = ({ email }: { email: string }) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const formatExcelValue = ({
  value,
}: {
  value: string | undefined;
}): string | number => {
  // If it's an empty value - return undefined.
  if (Number.isNaN(value) || _.isNil(value)) {
    return "";
  }

  // Numbers usually have commas in them. We'll be removing the commas.
  const valueWithoutNumberCommas = _.toNumber(
    _.toString(value).replace(/,/g, "")
  );

  // If the value without the commas is not a valid number or the value initially was an empty string - return the original value.
  if (Number.isNaN(valueWithoutNumberCommas) || value === "") {
    return value;
  }

  // If the value without the commas is a valid number - return it.
  return valueWithoutNumberCommas;
};

export const toFixedWithoutRounding = ({
  number,
  fixed,
}: {
  number: number;
  fixed: number;
}) => {
  // This regex matches a string that represents a number with a fixed number of decimal places.
  const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed}})?`);
  return _.toNumber(_.toString(number).match(reg)?.[0]);
};

/**
 * If we have navigations which hide the direct anchor scroll to the element,
 * this function gets navigations height by the ids of the navigations and skips them.
 *
 * We have to pass the anchor element and an array with the ids of the navigation items which has fixed position.
 *
 */
export const scrollToSelectedAnchor = ({
  anchor,
  navigationIds,
}: {
  anchor: string;
  navigationIds: string[];
}): void => {
  let fixedHeadersHeight = 0;

  navigationIds.forEach((id) => {
    const navHeight = window.document.getElementById(id)?.offsetHeight;
    if (navHeight) {
      fixedHeadersHeight += navHeight;
    }
  });

  const el = window.document.getElementById(anchor);

  if (el) {
    let topOfElement = el.offsetTop - fixedHeadersHeight;

    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
};

export const fieldOptionRetrieve = ({
  options,
  value,
}: {
  options: Array<IFieldOption>;
  value: string | null | undefined;
}) => {
  if (!_.isNil(value)) {
    return options.find((option) => option.value === value) || null;
  }

  return null;
};

export const fieldOptionMultipleRetrieve = ({
  options,
  value,
}: {
  options: Array<IFieldOption>;
  value: string | null | undefined;
}) => {
  if (!_.isNil(value)) {
    const values = value.split(",");

    return options.filter((option) => values.includes(option.value)) || null;
  }

  return null;
};

export const copyTextToClipboard = ({ text }: { text: string }) => {
  navigator.clipboard.writeText(text);
};

export const stringConvertToBoolean = ({
  value,
}: {
  value: string | boolean;
}) =>
  value === true ||
  (_.isString(value) && value.toLowerCase() === "true") ||
  (_.isString(value) && value.toLowerCase() === "yes") ||
  value === "1";

export const randomCapitalize = ({ text }: { text: string }) => {
  return text
    .split("")
    .map((char, index) => {
      return index % 2 === 0 ? char.toUpperCase() : char.toLowerCase();
    })
    .join("");
};

export const useRedirectUponFetchFailure = ({
  error,
  loading,
}: {
  error: any;
  loading: boolean;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !_.isNil(error)) {
      navigate(URLS.NOT_FOUND, { replace: true });
    }
  }, [loading, error, navigate]);
};

export const filterErrorsWithoutResponseStatus = ({
  errors,
}: {
  errors: { [key: string]: any };
}) => _.omit(errors, ["status"]);

export const areInstructionsEmpty = ({
  instructions,
}: {
  instructions: string | undefined | null;
}) =>
  _.isNil(instructions) ||
  _.isEmpty(instructions) ||
  instructions === "<p>&nbsp;</p>";

export const tableToTwoDimensionalArray = ({
  table,
}: {
  table: HTMLTableElement;
}) => {
  const rows = table.querySelectorAll("tr");

  const data: Array<Array<{ value: string }>> = [];

  rows.forEach((row) => {
    const cells = row.querySelectorAll("td");
    const rowData: Array<{ value: string }> = [];

    cells.forEach((cell) => {
      // @ts-ignore
      rowData.push({ value: cell.textContent.trim() });
    });

    data.push(rowData);
  });

  return data;
};

export const stripHTML = ({ html }: { html: string }) =>
  new DOMParser().parseFromString(html, "text/html").body.textContent || "";

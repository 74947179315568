import { BASE_URL } from "config/urls";
import { deleteCall, get, post, useFetch, usePaginatedFetch } from "utils/sdk";

import { IPackageDetails } from "entities/Checklist/sdk";
import {
  ConstructionType,
  DealStatus,
  PropertyType,
} from "entities/Deal/constants";
import { CompanyFieldFormatType } from "entities/Field/constants";
import { FieldType } from "entities/Field/sdk";
import { IFile } from "entities/File/sdk";
import { IFieldOption } from "entities/Package/sdk";

import { APPLICATION_STATUS, APPLICATIONS_LIST_LIMIT } from "./constants";

export interface IApplication {
  id: number;
  code: string;
  name: string;
  status: APPLICATION_STATUS.DRAFT | APPLICATION_STATUS.SUBMITTED;
  company_package: IPackageDetails & { id: number };
}

export interface IApplicationWithRefetch extends IApplication {
  refetch: () => void;
}

export interface ILenderApplication {
  id: number;
  code: string;
  name: string;
  status:
    | APPLICATION_STATUS.ARCHIVED
    | APPLICATION_STATUS.SUBMITTED
    | APPLICATION_STATUS.REJECTED
    | APPLICATION_STATUS.APPROVED;
  deal: {
    id: number;
    name: string;
    created: string;
  };
  company: {
    name: string;
  };
  submitted_at: string;
  submitted_by: {
    first_name: string;
    last_name: string;
  };
  checklist: {
    name: string;
  };
  address: {
    city: string;
    state: string;
    zipcode: string;
    address: string;
  };
  project_name: string | null;
  number_of_units: string | null;
}

export interface ILenderApplicationWithRefetch extends ILenderApplication {
  refetch: () => void;
}

export const useApplications = ({
  status,
  limit = APPLICATIONS_LIST_LIMIT,
}: {
  status: string;
  limit?: number;
}) =>
  usePaginatedFetch<IApplication>({
    url: "v2/applications/list/",
    params: { limit, status },
  });

export const useLenderApplications = ({
  status,
  limit = APPLICATIONS_LIST_LIMIT,
}: {
  status: string;
  limit?: number;
}) =>
  usePaginatedFetch<ILenderApplication>({
    url: "v2/applications/lender/list",
    params: { limit, status },
  });

export const useDraftApplicationsList = ({
  checklistToken,
}: {
  checklistToken: string | undefined;
}) =>
  useFetch<IApplication[]>(
    checklistToken
      ? `v2/applications/checklist/${checklistToken}/list-draft/`
      : undefined
  );

export interface IApplicationFieldValue {
  value_id: number;
  value: string;
  table_value: { [key: string]: string }[];
  file: IFile | null;
  field_format_type: CompanyFieldFormatType;
  updated_at: string;
}

export interface IApplicationField {
  company_custom_field_id: number;
  name: string;
  field_type: FieldType;
  columns?: string[];
  values: IApplicationFieldValue[];
  instruction?: string | null;
  is_required: boolean;
  field_format_type: CompanyFieldFormatType;

  rows: Array<string>;
  options: Array<IFieldOption>;
}
// This interface is temporary while we update all fields to be with the same structure
export interface IApplicationDetail {
  code: string;
  name: string;
  status: APPLICATION_STATUS;
  updated_at: string;
  updated_by: {
    first_name: string;
    last_name: string;
  };
  submitted_at: string | null;
  submitted_by: {
    first_name: string;
    last_name: string;
  };
  token: {
    name: string;
  };
  project_name: string | null;
  number_of_units: string | null;
  company_package: {
    id: number;
    tabs: Array<{
      tab_id: number;
      name: string;
      categories: Array<{
        category_id: number;
        name: string;
        sections: Array<{
          section_id: number;
          name: string;
          fields: Array<IApplicationField>;
        }>;
      }>;
    }>;
  };
}

export const useApplicationDetails = ({
  applicationId,
}: {
  applicationId: number;
}) => {
  return useFetch<IApplicationDetail>(
    `v2/applications/${applicationId}/retrieve/`
  );
};

export const createApplication = ({
  checklistToken,
  name,
}: {
  name: string;
  checklistToken: string;
}) =>
  post<{ id: number }>(
    `${BASE_URL}v2/applications/checklist/${checklistToken}/create/`,
    { name }
  );

interface IValue {
  value: string | number | null;
}
interface ITableValue {
  table_value: string | null;
}
interface IFileValue {
  file_id: number | null;
}

export type IValueOrTableValueOrFileValue = IValue | ITableValue | IFileValue;

export const updateApplicationFieldValue = ({
  applicationId,
  fieldId,
  data,
}: {
  applicationId: number;
  fieldId: number;
  data: IValueOrTableValueOrFileValue;
}) =>
  post(
    `${BASE_URL}v2/applications/${applicationId}/field/${fieldId}/value-create/`,
    data
  );

export const deleteApplicationFieldValue = ({
  applicationId,
  fieldValueId,
}: {
  applicationId: number;
  fieldValueId: number;
}) =>
  deleteCall(
    `${BASE_URL}v2/applications/${applicationId}/field/${fieldValueId}/value-delete/`
  );

export const submitApplication = ({
  applicationId,
}: {
  applicationId: number;
}) => post(`${BASE_URL}v2/applications/${applicationId}/submit/`);

export const checkFilePermission = ({
  fileId,
}: {
  fileId: number;
}): Promise<{ url: string }> =>
  get(`${BASE_URL}v2/files/${fileId}/application-details/`);

export const moveToUnderwriting = ({
  applicationId,
  data,
}: {
  applicationId: number;
  data: {
    deal_name: string;
    project_manager_id: number;
    number_of_units: number;
  };
}) =>
  post<{ id: number; name: string }>(
    `${BASE_URL}v2/applications/${applicationId}/move-to-underwriting/`,
    data
  );

export const updateApplicationStatus = ({
  applicationId,
  status,
}: {
  applicationId: number;
  status:
    | APPLICATION_STATUS.ARCHIVED
    | APPLICATION_STATUS.SUBMITTED
    | APPLICATION_STATUS.REJECTED;
}) =>
  post(`${BASE_URL}v2/applications/${applicationId}/status-update/`, {
    status,
  });

export const useApplicationLenderDetails = ({
  applicationId,
}: {
  applicationId: number;
}) =>
  useFetch<IApplicationDetail>(
    `v2/applications/lender/${applicationId}/retrieve/`
  );

export const useApplicationCount = () =>
  useFetch<{
    applications_count_submitted_by_me: number;
    applications_count_submitted_to_me: number;
  }>(`v2/applications/count/`);

export const applicationDiscard = ({
  applicationId,
}: {
  applicationId: number;
}) => post(`${BASE_URL}v2/applications/${applicationId}/discard/`);

export const applicationRename = ({
  applicationId,
  applicationName,
}: {
  applicationId: number;
  applicationName: string;
}) =>
  post(`${BASE_URL}v2/applications/${applicationId}/name-update/`, {
    name: applicationName,
  });

export interface IApplicationDealData {
  project_name: string;
  deal_status: DealStatus | null;
  property_type: PropertyType | null;
  construction_type: ConstructionType | null;
  number_of_units: number | null;
}

export const useDealPropertesRetrieve = ({
  applicationId,
  enabled,
}: {
  applicationId: number;
  enabled: boolean;
}) =>
  useFetch<IApplicationDealData>(
    enabled
      ? `v2/applications/${applicationId}/deal-properties/retrieve/`
      : undefined
  );

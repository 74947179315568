import { useMemo, useState } from "react";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { areInstructionsEmpty } from "utils/common";

import Box from "components/Box";
import Collapse from "components/Collapse";
import IconButton from "components/IconButton";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import FieldWrapperManager, {
  IFieldWrapperMeta,
} from "entities/Checklist/components/FieldWrapperManager";
import { IPackageDetailsSection } from "entities/Checklist/sdk";

type ISection = { section: IPackageDetailsSection } & IFieldWrapperMeta;

const Section = ({ section, ...fieldProps }: ISection) => {
  const [open, setOpen] = useState(true);

  const showInstructions = useMemo(
    () => !areInstructionsEmpty({ instructions: section?.instruction }),
    [section?.instruction]
  );

  return (
    <Box data-testid="dd-section-content">
      <Stack
        sx={{
          padding: theme.spacing(0.5, 1),
          backgroundColor: colors.blue10,
          borderRadius: "4px",
        }}
        data-testid="dd-section"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
            dataTestid="collapsible-button"
          >
            {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          <Text variant="text2" fontWeight="500" data-testid="dd-section-name">
            {section.name} {section.is_deleted ? "(deleted)" : ""}
          </Text>
        </Stack>
        {showInstructions && (
          <Text
            variant="text4"
            sx={{ color: colors.gray80 }}
            data-testid="section-instructions"
          >
            <SanitizeHtml html={section?.instruction || ""} />
          </Text>
        )}
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            padding: theme.spacing(1, 0, 1, 5),
          }}
          data-testid="section-fields-content"
        >
          {section.fields
            .filter((field) => !field.is_deleted)
            .map((field, fieldIndex) => (
              <FieldWrapperManager
                key={`section-${section.section_id}-field-${fieldIndex}`}
                field={field}
                sectionId={section.section_id}
                {...fieldProps}
              />
            ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Section;

import { memo, useMemo } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import Stack from "components/Stack";
import Text from "components/Text";

import { IField } from "entities/Field/sdk";
import { IFieldValueHistoryItem } from "entities/Package/sdk";

export interface ICheckboxFieldHistoryElement {
  value: IFieldValueHistoryItem;
  field: IField;
}

const CheckboxFieldHistoryElement = ({
  value,
  field,
}: ICheckboxFieldHistoryElement) => {
  const timestamp = useMemo(
    () => format(parseJSON(value.uploaded_at), FORMATS.FRONTEND.DATETIME),
    [value]
  );

  const creatorText = useMemo(() => {
    if (!_.isNil(value.creator)) {
      return `${value.creator.first_name} ${value.creator.last_name}`;
    }
    return "Past proforma upload";
  }, [value]);

  const selectedValues = useMemo(
    () => value.selected_value?.split(",") || [],
    [value]
  );

  return (
    <Stack data-testid="field-history-entity">
      <Text
        variant="text2"
        sx={{ color: colors.gray60, width: 600 }}
        data-testid="entity-title"
      >
        <b>{creatorText}</b> {value.selected_value ? "updated" : "deleted"}{" "}
        value at {timestamp}
      </Text>
      {!_.isNil(value.selected_value) ? (
        <Stack data-testid="entity-value">
          {field.options.map((option, index) => (
            <FormControlLabel
              key={index}
              label={
                <Text variant="label" data-testid="option-value">
                  {option.label}
                </Text>
              }
              control={
                <Checkbox checked={selectedValues.includes(option.value)} />
              }
              data-testid="checkbox-option"
            />
          ))}
        </Stack>
      ) : (
        <Text variant="h6" sx={{ width: 600 }} data-testid="entity-value">
          (deleted)
        </Text>
      )}
    </Stack>
  );
};

export default memo(CheckboxFieldHistoryElement);

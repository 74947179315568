import React from "react";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";

interface NavLinkProporties extends NavLinkProps {
  to: string;
  linkDataTestid?: string;
}

export const NavLink: React.FC<NavLinkProporties> = ({
  to,
  children,
  linkDataTestid,
  ...rest
}) => {
  return (
    <RouterLink
      style={{
        color: "inherit",
        textDecoration: "none",
      }}
      to={to}
      data-testid={linkDataTestid}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};

export default NavLink;

import Stack from "components/Stack";
import Text from "components/Text";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import Feature from "entities/Company/components/CompanySettings/Feature";
import { ICompanySettings } from "entities/Company/sdk";

import EditableFeature from "./EditableFeature";

export interface IFeaturesLimit {
  companySettings: ICompanySettings;
  editable?: boolean;
  onUpdate?: ({
    feature,
    limit,
  }: {
    feature:
      | ECompanySettingsFeatureType.MAX_DEAL_COUNT
      | ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT
      | ECompanySettingsFeatureType.MAX_CHECKLIST_COUNT
      | ECompanySettingsFeatureType.MAX_INTAKE_CHECKLIST_COUNT;
    limit: number;
  }) => void;
}

const FeaturesLimit = ({
  companySettings,
  editable = false,
  onUpdate,
}: IFeaturesLimit) => {
  return (
    <Stack>
      <Feature label="Deals created">
        {editable ? (
          <EditableFeature
            limit={companySettings.max_deal_count}
            feature={ECompanySettingsFeatureType.MAX_DEAL_COUNT}
            onUpdate={(props) => onUpdate && onUpdate(props)}
          />
        ) : (
          <Text variant="text2">{companySettings.max_deal_count}</Text>
        )}
      </Feature>

      <Feature label="Checklists">
        {editable ? (
          <EditableFeature
            limit={companySettings.max_checklist_count}
            feature={ECompanySettingsFeatureType.MAX_CHECKLIST_COUNT}
            onUpdate={(props) => onUpdate && onUpdate(props)}
          />
        ) : (
          <Text variant="text2">{companySettings.max_checklist_count}</Text>
        )}
      </Feature>

      <Feature label="Checklists (Intake)">
        {editable ? (
          <EditableFeature
            limit={companySettings.max_intake_checklist_count}
            feature={ECompanySettingsFeatureType.MAX_INTAKE_CHECKLIST_COUNT}
            onUpdate={(props) => onUpdate && onUpdate(props)}
          />
        ) : (
          <Text variant="text2">
            {companySettings.max_intake_checklist_count}
          </Text>
        )}
      </Feature>

      <Feature label="AI summaries">
        {editable && companySettings.ai_summary_enabled ? (
          <EditableFeature
            limit={companySettings.max_ai_summary_document_count}
            feature={ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT}
            onUpdate={(props) => onUpdate && onUpdate(props)}
          />
        ) : (
          <Text variant="text2">
            {companySettings.max_ai_summary_document_count}
          </Text>
        )}
      </Feature>
    </Stack>
  );
};

export default FeaturesLimit;

import React, { useCallback, useState } from "react";

import { SettingsIcon } from "icons";

import IconButton from "components/IconButton";

import EditFilterSidebar from "entities/AnalyticsFilterGroup/components/EditFilterSidebar";
import {
  ANALYTICS_TYPES,
  IFilterGroup,
} from "entities/AnalyticsFilterGroup/sdk";

interface IEditFilterGroupButton {
  filterGroup: IFilterGroup;
  onSave?: (filterGroup: IFilterGroup) => void;
  analyticsType: ANALYTICS_TYPES;
}

const EditFilterGroupButton: React.FC<IEditFilterGroupButton> = ({
  filterGroup,
  onSave = () => {},
  analyticsType,
}) => {
  const [openEditSidebar, setOpenEditSidebar] = useState(false);

  const handleOpenFilter = useCallback(() => {
    setOpenEditSidebar(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setOpenEditSidebar(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpenFilter}>
        <SettingsIcon />
      </IconButton>
      <EditFilterSidebar
        isOpen={openEditSidebar}
        onClose={handleCloseFilter}
        onSave={onSave}
        filterGroup={filterGroup}
        analyticsType={analyticsType}
      />
    </>
  );
};

export default EditFilterGroupButton;

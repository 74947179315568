import { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions, DialogContent } from "components/Dialog";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import {
  moveToUnderwriting,
  useDealPropertesRetrieve,
} from "entities/Application/sdk";
import {
  DEAL_PHASES,
  DEAL_STATUSES,
} from "entities/Deal/components/Form/constants";
import DealStatusButton from "entities/Deal/components/Form/DealStatusButton";
import {
  CONSTRUCTION_TYPES,
  ConstructionType,
  DealStatus,
  Phase,
  PROPERTY_TYPES,
  PropertyType,
} from "entities/Deal/constants";
import { useUserTeamMembers } from "entities/User/sdk";

import { VALIDATION_SCHEMA } from "./constants";

export interface IMoveToUnderwritingDialog {
  applicationId: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: ({ dealId }: { dealId: number }) => void;
}

export interface IMoveToUnderwritingValues {
  deal_name: string;
  project_manager_id: string | null;
  deal_status: DealStatus;
  number_of_units: string | number;
  deal_phase: Phase;
  property_type: PropertyType;
  construction_type: ConstructionType;
}

const MoveToUnderwritingDialog = ({
  applicationId,
  isOpen,
  onClose,
  onSubmit,
}: IMoveToUnderwritingDialog) => {
  const { data: teamMembers } = useUserTeamMembers();

  const { data: dealData } = useDealPropertesRetrieve({
    applicationId: applicationId,
    enabled: isOpen,
  });

  const teamMemberOptions = useMemo(
    () =>
      teamMembers?.map(
        ({ id, first_name: firstName, last_name: lastName }) => ({
          value: id.toString(),
          label: `${firstName} ${lastName}`,
        })
      ) || [],
    [teamMembers]
  );

  const handleSubmit = useCallback(
    (values: IMoveToUnderwritingValues) => {
      moveToUnderwriting({
        applicationId,
        data: {
          ...values,
          project_manager_id: Number(values.project_manager_id),
          number_of_units: Number(values.number_of_units),
        },
      })
        .then(({ id }) => {
          onSubmit({ dealId: id });
        })
        .catch(({ message: errorMessages }) => {
          handleInvalidRequest(errorMessages);
        });
    },
    [applicationId, onSubmit]
  );

  const formInitialValues = useMemo(() => {
    return {
      deal_name: dealData?.project_name || "",
      project_manager_id: null,
      deal_status: dealData?.deal_status || DealStatus.ACTIVE,
      deal_phase: _.head(DEAL_PHASES)?.value || Phase.APPLICATION,
      number_of_units: dealData?.number_of_units || 0,
      property_type:
        dealData?.property_type || PropertyType.MULTIFAMILY_RESIDENTIAL,
      construction_type:
        dealData?.construction_type || ConstructionType.NEW_CONSTRUCTION,
    };
  }, [dealData]);

  const formik = useFormik<IMoveToUnderwritingValues>({
    initialValues: formInitialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogContent sx={{ padding: 2 }}>
        <Box
          sx={{
            padding: 2,
            marginBottom: 4,
            borderRadius: 1,
            backgroundColor: colors.blue10,
          }}
          fontWeight={700}
        >
          <Text variant="h3">Move to deal pipeline</Text>
          <Text variant="text3" marginTop={0.5}>
            Make this intake into a deal in your portfolio and start the
            underwriting process. Invite the borrower to start the due diligence
            collection process.
          </Text>
        </Box>
        <Text variant="text3" fontWeight={500} marginBottom={0.5}>
          Project name
        </Text>
        <TextField
          sx={{
            width: "100%",
            marginBottom: 2,
          }}
          {...formik.getFieldProps("deal_name")}
          error={formik.touched.deal_name && !!formik.errors.deal_name}
          helperText={
            formik.touched.deal_name && formik.errors.deal_name
              ? formik.errors.deal_name
              : ""
          }
        />

        <Text variant="text3" fontWeight={500} marginBottom={0.5}>
          Deal status
        </Text>
        <Stack direction="row" spacing={1} marginBottom={2}>
          {DEAL_STATUSES.map(({ value, label }) => (
            <DealStatusButton
              key={value}
              variant={
                formik.values.deal_status === value ? "contained" : "outlined"
              }
              label={label}
              onClick={() => formik.setFieldValue("deal_status", value)}
              dataTestid={`deal-status-${value.toLowerCase()}`}
            />
          ))}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Stack width="50%">
            <Text variant="text3" fontWeight={500} marginBottom={0.5}>
              Deal phase
            </Text>
            <Select
              options={DEAL_PHASES}
              sx={{
                width: "100%",
                marginBottom: 2,
              }}
              {...formik.getFieldProps("deal_phase")}
              error={formik.touched.deal_phase && !!formik.errors.deal_phase}
              helperText={formik.touched.deal_phase && formik.errors.deal_phase}
              inputProps={{ "data-testid": "deal-phase-dropdown-input" }}
              data-testid="deal-phase-dropdown"
              dropdownOptionsDataTestid="deal-phase-dropdown-option"
            />
          </Stack>
          <Stack width="50%">
            <Text variant="text3" fontWeight={500} marginBottom={0.5}>
              Number of units
            </Text>
            <TextField
              sx={{
                width: "100%",
                marginBottom: 2,
              }}
              {...formik.getFieldProps("number_of_units")}
              error={
                formik.touched.number_of_units &&
                !!formik.errors.number_of_units
              }
              helperText={
                formik.touched.number_of_units && formik.errors.number_of_units
                  ? formik.errors.number_of_units
                  : ""
              }
            />
          </Stack>
        </Stack>

        <Text variant="text3" fontWeight={500} marginBottom={0.5}>
          Property type
        </Text>
        <Select
          options={PROPERTY_TYPES}
          sx={{
            width: "100%",
            marginBottom: 2,
          }}
          {...formik.getFieldProps("property_type")}
          error={formik.touched.property_type && !!formik.errors.property_type}
          helperText={
            formik.touched.property_type && formik.errors.property_type
          }
          inputProps={{ "data-testid": "property-type-dropdown-input" }}
          data-testid="property-type-dropdown"
          dropdownOptionsDataTestid="property-type-dropdown-option"
        />

        <Text variant="text3" fontWeight={500} marginBottom={0.5}>
          Construction type
        </Text>
        <Select
          options={CONSTRUCTION_TYPES}
          sx={{
            width: "100%",
            marginBottom: 2,
          }}
          {...formik.getFieldProps("construction_type")}
          error={
            formik.touched.construction_type &&
            !!formik.errors.construction_type
          }
          helperText={
            formik.touched.construction_type && formik.errors.construction_type
          }
          inputProps={{ "data-testid": "construction-type-dropdown-input" }}
          data-testid="construction-type-dropdown"
          dropdownOptionsDataTestid="construction-type-dropdown-option"
        />

        <Text variant="text3" fontWeight={500}>
          Project manager
        </Text>
        <Text
          variant="text4"
          fontWeight={400}
          color={colors.gray60}
          marginBottom={0.5}
        >
          Assign this deal to yourself or someone on your team. They will have
          deal admin rights.
        </Text>
        <Select
          options={teamMemberOptions}
          sx={{
            width: "100%",
            marginBottom: 2,
          }}
          {...formik.getFieldProps("project_manager_id")}
          error={
            formik.touched.project_manager_id &&
            !!formik.errors.project_manager_id
          }
          helperText={
            formik.touched.project_manager_id &&
            formik.errors.project_manager_id
              ? formik.errors.project_manager_id
              : ""
          }
        />
        <DialogActions sx={{ paddingTop: 6 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={formik.submitForm}>Move to deal pipeline</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default MoveToUnderwritingDialog;

import { useCallback, useState } from "react";

import { MoreVertIcon } from "icons";
import theme from "theme";

import Button from "components/Button";
import ButtonDropdown from "components/ButtonDropdown";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Text from "components/Text";

import CommentsDrawer from "entities/Memo/components/Comments/Drawer";
import { IMemoSection } from "entities/Memo/sdk";

import NAStatusMenuItem from "./NAStatusMenuItem";

export interface IMemoSectionContextButton {
  section: IMemoSection;
  dealId: number;
  onUpdate?: () => void;
}

const MemoSectionContextButton = ({
  section,
  dealId,
  onUpdate,
}: IMemoSectionContextButton) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenCommentsDrawer = useCallback(() => setIsOpen(true), []);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    onUpdate && onUpdate();
  }, [onUpdate]);

  return (
    <>
      <Stack direction="row" spacing={1}>
        {section.comment_count > 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenCommentsDrawer}
          >
            {section.comment_count}{" "}
            {section.comment_count > 1 ? "comments" : "comment"}
          </Button>
        )}
        <ButtonDropdown
          icon={<MoreVertIcon />}
          label=""
          variant="text"
          size="small"
          width="auto"
          sx={{
            padding: theme.spacing(0.5, 0.75),
          }}
        >
          <MenuItem onClick={handleOpenCommentsDrawer}>
            <Text variant="text2">Add comments</Text>
          </MenuItem>
          <NAStatusMenuItem
            section={section}
            dealId={dealId}
            onUpdateNAStatus={onUpdate}
          />
        </ButtonDropdown>
      </Stack>
      <CommentsDrawer
        dealId={dealId}
        memoSectionId={section.section_id}
        open={isOpen}
        onCancel={handleCancel}
      />
    </>
  );
};

export default MemoSectionContextButton;

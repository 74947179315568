import * as yup from "yup";

import { Faction, Persona } from "entities/Company/sdk";
import { DealStatus, Phase, States } from "entities/Deal/constants";

export const STATES_OPTIONS = [
  { value: States.ALABAMA, label: States.ALABAMA },
  { value: States.ALASKA, label: States.ALASKA },
  { value: States.ARIZONA, label: States.ARIZONA },
  { value: States.ARKANSAS, label: States.ARKANSAS },
  { value: States.CALIFORNIA, label: States.CALIFORNIA },
  { value: States.COLORADO, label: States.COLORADO },
  { value: States.CONNECTICUT, label: States.CONNECTICUT },
  { value: States.DELAWARE, label: States.DELAWARE },
  { value: States.FLORIDA, label: States.FLORIDA },
  { value: States.GEORGIA, label: States.GEORGIA },
  { value: States.HAWAII, label: States.HAWAII },
  { value: States.IDAHO, label: States.IDAHO },
  { value: States.ILLINOIS, label: States.ILLINOIS },
  { value: States.INDIANA, label: States.INDIANA },
  { value: States.IOWA, label: States.IOWA },
  { value: States.KANSAS, label: States.KANSAS },
  { value: States.KENTUCKY, label: States.KENTUCKY },
  { value: States.LOUISIANA, label: States.LOUISIANA },
  { value: States.MAINE, label: States.MAINE },
  { value: States.MARYLAND, label: States.MARYLAND },
  { value: States.MASSACHUSETTS, label: States.MASSACHUSETTS },
  { value: States.MICHIGAN, label: States.MICHIGAN },
  { value: States.MINNESOTA, label: States.MINNESOTA },
  { value: States.MISSISSIPPI, label: States.MISSISSIPPI },
  { value: States.MISSOURI, label: States.MISSOURI },
  { value: States.MONTANA, label: States.MONTANA },
  { value: States.NEBRASKA, label: States.NEBRASKA },
  { value: States.NEVADA, label: States.NEVADA },
  { value: States.NEW_HAMPSHIRE, label: States.NEW_HAMPSHIRE },
  { value: States.NEW_JERSEY, label: States.NEW_JERSEY },
  { value: States.NEW_MEXICO, label: States.NEW_MEXICO },
  { value: States.NEW_YORK, label: States.NEW_YORK },
  { value: States.NORTH_CAROLINA, label: States.NORTH_CAROLINA },
  { value: States.NORTH_DAKOTA, label: States.NORTH_DAKOTA },
  { value: States.OHIO, label: States.OHIO },
  { value: States.OKLAHOMA, label: States.OKLAHOMA },
  { value: States.OREGON, label: States.OREGON },
  { value: States.PENNSYLVANIA, label: States.PENNSYLVANIA },
  { value: States.RHODE_ISLAND, label: States.RHODE_ISLAND },
  { value: States.SOUTH_CAROLINA, label: States.SOUTH_CAROLINA },
  { value: States.SOUTH_DAKOTA, label: States.SOUTH_DAKOTA },
  { value: States.TENNESSE, label: States.TENNESSE },
  { value: States.TEXAS, label: States.TEXAS },
  { value: States.UTAH, label: States.UTAH },
  { value: States.VERMONT, label: States.VERMONT },
  { value: States.VIRGINIA, label: States.VIRGINIA },
  { value: States.WASHINGTON, label: States.WASHINGTON },
  { value: States.WEST_VIRGINIA, label: States.WEST_VIRGINIA },
  { value: States.WISCONSIN, label: States.WISCONSIN },
  { value: States.WYOMING, label: States.WYOMING },
  { value: States.AMERICAN_SAMOA, label: States.AMERICAN_SAMOA },
  { value: States.DISTRICT_OF_COLUMBIA, label: States.DISTRICT_OF_COLUMBIA },
  {
    value: States.FEDERATED_STATES_OF_MICRONESIA,
    label: States.FEDERATED_STATES_OF_MICRONESIA,
  },
  { value: States.GUAM, label: States.GUAM },
  { value: States.MARSHALL_ISLANDS, label: States.MARSHALL_ISLANDS },
  {
    value: States.NORTHERN_MARIANA_ISLANDS,
    label: States.NORTHERN_MARIANA_ISLANDS,
  },
  { value: States.PALAU, label: States.PALAU },
  { value: States.PUERTO_RICO, label: States.PUERTO_RICO },
  { value: States.VIRGIN_ISLANDS, label: States.VIRGIN_ISLANDS },
];

export const DEAL_STATUSES = [
  { value: DealStatus.PROSPECT, label: DealStatus.PROSPECT },
  { value: DealStatus.ACTIVE, label: DealStatus.ACTIVE },
  { value: DealStatus.ARCHIVED, label: DealStatus.ARCHIVED },
  { value: DealStatus.DORMANT, label: DealStatus.DORMANT },
  { value: DealStatus.CLOSED, label: DealStatus.CLOSED },
];

export const DEAL_PHASES = [
  { label: Phase.APPLICATION, value: Phase.APPLICATION },
  { label: Phase.UNDERWRITING, value: Phase.UNDERWRITING },
  { label: Phase.CONSTRUCTION, value: Phase.CONSTRUCTION },
  { label: Phase.CONVERSION, value: Phase.CONVERSION },
  {
    label: Phase.ASSET_MANAGEMENT,
    value: Phase.ASSET_MANAGEMENT,
  },
];

export const FACTION_OPTIONS = [
  { label: Faction.BORROWER, value: Faction.BORROWER },
  { label: Faction.LENDER, value: Faction.LENDER },
];

export const PERSONA_OPTIONS = [
  { label: Persona.DEVELOPER, value: Persona.DEVELOPER },
  { label: Persona.LENDER, value: Persona.LENDER },
  { label: Persona.ARCHITECT, value: Persona.ARCHITECT },
  { label: Persona.CONTRACTOR, value: Persona.CONTRACTOR },
  { label: Persona.ASSET_MANAGER, value: Persona.ASSET_MANAGER },
  { label: Persona.GENERAL_CONTRACTOR, value: Persona.GENERAL_CONTRACTOR },
  { label: Persona.PROPERTY_MANAGER, value: Persona.PROPERTY_MANAGER },
  { label: Persona.LAWYER, value: Persona.LAWYER },
];

export const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("This field is required!"),
  persona: yup.string().required("This field is required!"),
  property_type: yup.string().required("This field is required!"),
  number_of_units: yup.number().required("This field is required!"),
  deal_status: yup.string().required("This field is required!"),
  deal_phase: yup.string().required("This field is required!"),
  street: yup.string().required("This field is required!"),
  city: yup.string().required("This field is required!"),
  state: yup.string().required("This field is required!"),
  zipcode: yup.string().required("This field is required!"),
  construction_type: yup.string().required("This field is required!"),
});

import React from "react";

import Stack from "components/Stack";

import AmenityTable from "entities/Deal/components/OverviewSections/AmenityTable";
import { IDealUnitAmenities } from "entities/Deal/sdk";

interface IUnitAmenitiesTable {
  unit_amenities_data: IDealUnitAmenities;
  tableDataTestid?: string;
}

const UnitAmenitiesTable: React.FC<IUnitAmenitiesTable> = ({
  unit_amenities_data,
  tableDataTestid,
}) => (
  <Stack spacing={3} data-testid={tableDataTestid}>
    <Stack direction="row" spacing={3} alignItems="flex-start">
      <AmenityTable
        header="Kitchen &amp; electric appliances"
        data={unit_amenities_data.kitchen}
        categoryTableDataTestid="unit-amenities-category-table"
      />
      <AmenityTable
        header="HVAC &amp; fitting"
        data={unit_amenities_data.fittings}
        categoryTableDataTestid="unit-amenities-category-table"
      />
    </Stack>
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <AmenityTable
        header="Storage &amp; furnishings"
        data={unit_amenities_data.storage}
        categoryTableDataTestid="unit-amenities-category-table"
      />
      <AmenityTable
        header="Laundry facilities"
        data={unit_amenities_data.laundry}
        categoryTableDataTestid="unit-amenities-category-table"
      />
    </Stack>
  </Stack>
);

export default UnitAmenitiesTable;

import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Autocomplete, {
  AutocompleteGroupHeader,
  AutocompleteGroupItems,
  AutocompletePaperComponent,
  createFilterOptions,
} from "components/Autocomplete";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import TextField from "components/TextField";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import BusinessForm, {
  IFormikParticipant,
} from "entities/CompanyItegrations/components/Downhome/BusinessForm";
import { IDownhomeOwnerProperties } from "entities/CompanyItegrations/components/Downhome/BusinessForm/AddOwnerButton/constants";
import { EListFieldType } from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListField/constants";
import { convertBusinessParticipantDataToFormData } from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListField/utils";
import { EDownhomeBusinessParticipantOptionGroup } from "entities/CompanyItegrations/components/Downhome/constants";
import FormSkeleton from "entities/CompanyItegrations/components/Downhome/FormSkeleton";
import {
  downhomeBusinessParticipantCreate,
  fetchBusinessParticipantData,
  IDownhomeBusinessParticipant,
  ISimpleDownhomeBusinessParticipant,
  useDownhomeBusinessesParticipants,
  useDownhomeIndustryGroups,
  useDownhomePropertiesRetrieve,
} from "entities/CompanyItegrations/sdk";

interface IBusinessParticipantOption
  extends ISimpleDownhomeBusinessParticipant {
  label: string;
  _groupKey: EDownhomeBusinessParticipantOptionGroup;
}

const filter = createFilterOptions<IBusinessParticipantOption>();

const DownhomeBusinessCreate = () => {
  const [businessData, setBusinessData] =
    useState<IDownhomeBusinessParticipant>();
  const [ownersFromChecklist, setOwnersFromChecklist] = useState<
    IDownhomeOwnerProperties[] | null | undefined
  >(undefined);
  usePageTitle(
    "Builders Patch: Company Integrations - Downhome Business create"
  );

  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingBusinessData, setIsFetchingBusinessData] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { data: businessParticipantData } = useDownhomePropertiesRetrieve({
    dealId,
  });

  const { data: businessParticipants = [], isLoading } =
    useDownhomeBusinessesParticipants();

  const { data: industryGroups = [], isLoading: isFetchingIndustryGroups } =
    useDownhomeIndustryGroups();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    ({ data }: { data: IFormikParticipant }) => {
      setIsSubmitting(true);

      downhomeBusinessParticipantCreate({ dealId, data })
        .then(() => {
          toast.successMessage("Business participant data has been created!");
          navigate(
            reverse(URLS.COMPANY_INTEGRATIONS_LIST, {
              dealId,
            })
          );
        })
        .catch(handleInvalidRequest)
        .finally(() => setIsSubmitting(false));
    },
    [dealId, navigate]
  );

  const businessParticipantOptions = useMemo(
    () =>
      businessParticipants.map((businessParticipant) => {
        return {
          label: businessParticipant.name,
          name: businessParticipant.name,
          external_identifier: businessParticipant.external_identifier,
          _groupKey: EDownhomeBusinessParticipantOptionGroup.EXISTING,
        };
      }),
    [businessParticipants]
  );

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: IBusinessParticipantOption | null | string
    ) => {
      if (!_.isString(value) && !_.isNil(value)) {
        if (value.external_identifier !== "") {
          setIsFetchingBusinessData(true);
          fetchBusinessParticipantData({
            externalIdentifier: value.external_identifier,
          })
            .then((data) => {
              setBusinessData(data);
              setShowForm(true);
            })
            .catch(handleInvalidRequest)
            .finally(() => setIsFetchingBusinessData(false));
        } else {
          if (!_.isUndefined(businessParticipantData)) {
            setBusinessData({
              ..._.pick(businessParticipantData, [
                "established_on",
                "business_legal_form",
                "naics",
              ]),
              name: businessParticipantData.name || "",
              industry_group: !_.isNil(businessParticipantData.industry_group)
                ? { nickname: businessParticipantData.industry_group }
                : null,
              addresses: convertBusinessParticipantDataToFormData({
                data: businessParticipantData.addresses,
                type: EListFieldType.ADDRESSES,
              }) as IDownhomeBusinessParticipant["addresses"],
              emails: convertBusinessParticipantDataToFormData({
                data: businessParticipantData.emails,
                type: EListFieldType.EMAILS,
              }) as IDownhomeBusinessParticipant["emails"],
              phones: convertBusinessParticipantDataToFormData({
                data: businessParticipantData.phones,
                type: EListFieldType.PHONES,
              }) as IDownhomeBusinessParticipant["phones"],
              websites: convertBusinessParticipantDataToFormData({
                data: businessParticipantData.websites,
                type: EListFieldType.WEBSITES,
              }) as IDownhomeBusinessParticipant["websites"],
              owners: null,
            });
            setOwnersFromChecklist(
              convertBusinessParticipantDataToFormData({
                data: businessParticipantData.owners,
                type: EListFieldType.OWNERS,
              }) as IDownhomeOwnerProperties[]
            );
          }
          setShowForm(true);
        }
      }
    },
    [businessParticipantData]
  );

  const handleFilterOptions = useCallback(
    (options: Array<IBusinessParticipantOption>, params) => {
      const filtered = filter(options, params).map((option) => ({
        ...option,
        _groupKey: EDownhomeBusinessParticipantOptionGroup.EXISTING,
      }));

      const { inputValue } = params;
      const isExisting = options.some((option) => inputValue === option.name);

      if (inputValue !== "" && !isExisting) {
        filtered.push({
          label: businessParticipantData?.name || inputValue,
          name: inputValue,
          external_identifier: "",
          _groupKey:
            EDownhomeBusinessParticipantOptionGroup.IMPORT_FROM_CHECKLIST,
        });
      }

      return filtered;
    },
    [businessParticipantData]
  );

  const isFormLoading = useMemo(
    () => isFetchingBusinessData || isFetchingIndustryGroups,
    [isFetchingBusinessData, isFetchingIndustryGroups]
  );

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          sx={{ width: "100%", maxWidth: BOX_MAX_WIDTH, alignSelf: "center" }}
          spacing={2}
        >
          <PageHeader
            title="DownHome Loan Manager: Business Loans"
            backLink={reverse(URLS.COMPANY_INTEGRATIONS_LIST, {
              dealId,
            })}
            backTitle="Back to Integrations"
          />
          {isLoading ? (
            <Skeleton height={50} sx={{ transform: "none" }} />
          ) : (
            <>
              {!showForm && (
                <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  PaperComponent={AutocompletePaperComponent}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an existing business or add a new one."
                    />
                  )}
                  onChange={handleChange}
                  renderOption={(props, option: IBusinessParticipantOption) => (
                    <li
                      {...props}
                      key={
                        _.isString(option) ? option : option.external_identifier
                      }
                    >
                      {_.isString(option) ? option : option.label}
                    </li>
                  )}
                  groupBy={(option) => option._groupKey}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <AutocompleteGroupHeader>
                        {params.group}
                      </AutocompleteGroupHeader>
                      <AutocompleteGroupItems>
                        {params.children}
                      </AutocompleteGroupItems>
                    </li>
                  )}
                  options={businessParticipantOptions}
                  getOptionLabel={(option) =>
                    _.isString(option) ? option : option.label
                  }
                  filterOptions={handleFilterOptions}
                />
              )}
              {isFormLoading && <FormSkeleton />}
              {showForm && (
                <BusinessForm
                  isSubmitting={isSubmitting}
                  onSubmit={handleSubmit}
                  industryGroups={industryGroups}
                  initialParticipantData={businessData}
                  initialOwners={ownersFromChecklist}
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default DownhomeBusinessCreate;

import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Popover } from "@mui/material";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import SettingsCategory from "entities/AssetManagement/components/SettingsCategory";
import { AMColumn, AMResolution } from "entities/AssetManagement/constants";

interface IAssetManagementTableSettings {
  columns: Array<AMColumn>;
  setColumns: Dispatch<SetStateAction<Array<AMColumn>>>;
  resolution: AMResolution;
  setResolution: Dispatch<SetStateAction<AMResolution>>;
}

const AssetManagementTableSettings = ({
  columns,
  setColumns,
  resolution,
  setResolution,
}: IAssetManagementTableSettings) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResolutionChange = useCallback(
    (resolutionString: AMResolution) => {
      setResolution(resolutionString);
    },
    [setResolution]
  );

  const handleColumnsToggle = useCallback(
    (columnString: AMColumn) => {
      if (!(columns.length === 1 && columns.includes(columnString))) {
        setColumns((currentColumns) => _.xor(currentColumns, [columnString]));
      }
    },
    [columns, setColumns]
  );

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        data-testid="table-settings-button"
      >
        Table settings
      </Button>
      <Popover
        elevation={0}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: theme.spacing(1) }}
        onClose={handleClose}
      >
        <Paper
          sx={{ border: `1px solid ${colors.blue100}` }}
          data-testid="table-settings-modal"
        >
          <Stack spacing={2}>
            <Text variant="h3" data-testid="settings-modal-header">
              Table settings
            </Text>
            <Stack
              direction="row"
              spacing={2}
              data-testid="table-settings-sections"
            >
              <SettingsCategory
                label="Time format"
                helper="Show yearly or quarterly data."
                options={[
                  { label: AMResolution.YEARLY, value: AMResolution.YEARLY },
                  {
                    label: AMResolution.QUARTERLY,
                    value: AMResolution.QUARTERLY,
                  },
                ]}
                value={resolution}
                onChange={handleResolutionChange}
                dataTestid="time-format"
              />
              <SettingsCategory
                multiple
                label="Columns"
                helper="Select two columns to be compared."
                options={[
                  { label: AMColumn.BUDGETED, value: AMColumn.BUDGETED },
                  { label: AMColumn.ACTUAL, value: AMColumn.ACTUAL },
                  { label: AMColumn.AUDITED, value: AMColumn.AUDITED },
                ]}
                onChange={handleColumnsToggle}
                value={columns}
                dataTestid="columns-comparison"
              />
            </Stack>
          </Stack>
        </Paper>
      </Popover>
    </Box>
  );
};

export default AssetManagementTableSettings;

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import _ from "lodash";
import Quill from "quill";

import { tableToTwoDimensionalArray } from "utils/common";

import Box from "components/Box";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import { useCompanySettings } from "entities/Company/sdk";
import { ITableCell } from "entities/TableStorage/sdk";

import TableCreateDialog from "./components/TableCreateDialog";
import { MODULES } from "./constants";

import "quill/dist/quill.snow.css";
import "./editor.css";

export interface IRichTextEditor {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  sx?: SxProps<Theme>;
  placeholder?: string;
}

const RichTextEditor = ({
  sx,
  placeholder,
  defaultValue,
  onChange,
}: IRichTextEditor) => {
  const [tableCreationDialogData, setTableCreationDialogData] = useState<Array<
    Array<ITableCell>
  > | null>(null);
  const { data: companySettings } = useCompanySettings({});

  const containerRef = useRef<HTMLElement | null>(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onChange;
  });

  const showTableStorageTableImport = useMemo(
    () => companySettings?.tables_shown || false,
    [companySettings]
  );

  const handleTableImportClick = useCallback(
    ({ table }: { table: HTMLTableElement }) => {
      const tableData = tableToTwoDimensionalArray({
        // @ts-ignore
        table,
      });

      setTableCreationDialogData(tableData);
    },
    []
  );

  const tableStorageTableImportHandler = useCheckFeature({
    feature: ECompanySettingsFeatureType.TABLES,
    callback: handleTableImportClick,
  });

  useEffect(() => {
    const container = containerRef.current;

    if (_.isNil(container)) {
      return;
    }

    const editorContainer = container.appendChild(
      container.ownerDocument.createElement("div")
    );
    const quill = new Quill(editorContainer, {
      theme: "snow",
      modules: MODULES({
        tableStorageTableImportHandler,
        showTableStorageTableImport,
      }),
      placeholder,
      bounds: container,
    });

    if (defaultValueRef.current) {
      quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      onTextChangeRef.current?.(quill.root.innerHTML);

      const table = quill.root.querySelector("table");

      if (!_.isNil(table) && table.rows.length === 0) {
        quill.clipboard.dangerouslyPasteHTML("");
      }
    });

    return () => {
      container.innerHTML = "";
    };
  }, [
    placeholder,
    showTableStorageTableImport,
    tableStorageTableImportHandler,
  ]);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "210px",
          display: "flex",
          flexDirection: "column",
          ...sx,
        }}
        className="ql-editorContainer"
      />
      <TableCreateDialog
        initialData={tableCreationDialogData}
        close={() => setTableCreationDialogData(null)}
      />
    </>
  );
};

export default RichTextEditor;

import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import ListPagination from "components/ListPagination";
import PageHeader from "components/PageHeader";
import ScrollableTable from "components/ScrollableTable";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import ExportButton from "entities/Application/components/ExportButton";
import { APPLICATION_STATUS, HEADERS } from "entities/Application/constants";
import {
  IApplicationWithRefetch,
  useApplications,
} from "entities/Application/sdk";

const APPLICATION_STATUSES = [
  { value: "All", label: "All" },
  { value: APPLICATION_STATUS.DRAFT, label: "Draft" },
  { value: APPLICATION_STATUS.SUBMITTED, label: "Submitted" },
];

const ApplicationList = () => {
  const navigate = useNavigate();

  const { status } = useParams<{ status: string }>();

  usePageTitle("Builders Patch: Intake - Applicant dashboard");

  const handlechangeStatus = useCallback(
    (event: any) => {
      navigate(reverse(URLS.APPLICATIONS_LIST, { status: event.target.value }));
    },
    [navigate]
  );

  const {
    data: applicationsData,
    pagesCount,
    setPage,
    mutate: applicationsRefetch,
  } = useApplications({ status: status as string });

  const applications: Array<IApplicationWithRefetch> = useMemo(() => {
    return (
      applicationsData?.results?.map((application) => ({
        ...application,
        refetch: applicationsRefetch,
      })) || []
    );
  }, [applicationsData, applicationsRefetch]);

  return (
    <Stack spacing={2} sx={{ padding: theme.spacing(4, 3, 0), flex: 1 }}>
      <PageHeader
        title="Intake: Applicant dashboard"
        actions={
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            sx={{ flex: 1 }}
          >
            <Select
              size="small"
              sx={{ width: "154px" }}
              inputProps={{
                sx: {
                  backgroundColor: "white",
                },
              }}
              label="STATUS"
              options={APPLICATION_STATUSES}
              onChange={handlechangeStatus}
              value={status}
            />
            <ExportButton applications={applications} />
          </Stack>
        }
      />

      <Text
        variant="text3"
        sx={{
          color: colors.gray60,
        }}
      >
        Showing {applications.length} of {applicationsData?.count} intakes.
      </Text>

      <Box sx={{ width: "100%" }}>
        <ScrollableTable
          headers={HEADERS}
          rows={applications}
          expectedResultsCount={10}
        />
      </Box>
      <ListPagination setPage={setPage} pagesCount={pagesCount} />
    </Stack>
  );
};

export default ApplicationList;

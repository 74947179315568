import React from "react";
import { useCallback } from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import CompanyProfileEditButton from "entities/Company/components/EditButton";
import CompanyProfileTable from "entities/Company/components/ProfileInfoBox/ProfileTable";
import { ICompany, useCurrentCompany } from "entities/Company/sdk";
import { IUser } from "entities/User/sdk";

export interface ICompanyProfileInfoBox {
  company: ICompany;
  user: IUser;
}

// Used for anchoring
export const ID = "company_profile_section";

const CompanyProfileInfoBox: React.FC<ICompanyProfileInfoBox> = ({
  company,
  user,
}) => {
  const { mutate: mutateCompany } = useCurrentCompany(user?.company_id);

  const handleOnUpdated = useCallback(() => {
    mutateCompany();
  }, [mutateCompany]);

  return (
    <Paper id={ID}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colors.gray100}` }}
        >
          <Text variant="text1" fontWeight={700}>
            Company profile
          </Text>
          {user?.is_admin && (
            <CompanyProfileEditButton
              company={company}
              onUpdated={handleOnUpdated}
            />
          )}
        </Stack>
        <Stack spacing={3}>
          <Box>
            <Text variant="text1" fontWeight={700} data-testid="company-name">
              {company.name}
            </Text>
            <Text
              variant="labelSmall"
              data-testid="company-group"
              sx={{ color: colors.gray40 }}
            >
              {company.default_group}
            </Text>
          </Box>
          <CompanyProfileTable company={company} />
        </Stack>
      </Stack>
    </Paper>
  );
};
export default CompanyProfileInfoBox;

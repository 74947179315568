import { useCallback, useState } from "react";
import _ from "lodash";

import { DeleteIcon } from "icons";
import { isNotUndefined } from "utils/common";

import IconButton from "components/IconButton";
import Stack from "components/Stack";
import TextField from "components/TextField";
import Tooltip from "components/Tooltip";

import Table from "entities/TableStorage/components/Table";
import { ITableRow } from "entities/TableStorage/sdk";

import {
  convertHTMLTableInRowTableData,
  convertPastedDataToTableRows,
  isHTMLTableData,
} from "./utils";

export interface IPasteTableField {
  showFieldLabel: boolean;
  onPaste: ({ rows }: { rows: Array<ITableRow> }) => void;
  showFieldError: boolean | undefined;
  fieldError: string | string[] | undefined;
}

const PasteTableField = ({
  showFieldLabel,
  onPaste,
  showFieldError,
  fieldError,
}: IPasteTableField) => {
  const [tableData, setTableData] = useState<Array<ITableRow> | undefined>(
    undefined
  );

  const handlePasteTableData = useCallback(
    (e) => {
      const pasteData = e.clipboardData.getData("text");

      let tableDataForParsing = pasteData;

      // Check if the table is with table, tr and td tags
      if (isHTMLTableData({ data: pasteData })) {
        tableDataForParsing = convertHTMLTableInRowTableData({
          data: pasteData,
        });
      }

      if (_.isNull(tableDataForParsing)) {
        return;
      }

      const tableDataRows: Array<ITableRow> = convertPastedDataToTableRows({
        data: tableDataForParsing,
      });

      setTableData(tableDataRows);
      onPaste({ rows: tableDataRows });
    },
    [onPaste]
  );

  const handleResetTable = useCallback(() => {
    setTableData(undefined);
    onPaste({ rows: [] });
  }, [onPaste]);

  return (
    <Stack>
      {isNotUndefined(tableData) ? (
        <Stack spacing={1}>
          <Table tableData={tableData} />
          <Tooltip title="Reset the table">
            <IconButton
              onClick={handleResetTable}
              sx={{ alignSelf: "flex-end" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <TextField
          rows={2}
          label={showFieldLabel ? "Table data" : ""}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          multiline
          sx={{ width: "100%" }}
          placeholder="Paste table..."
          onPaste={handlePasteTableData}
          error={showFieldError}
          helperText={fieldError}
        />
      )}
    </Stack>
  );
};

export default PasteTableField;

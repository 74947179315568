import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import ProjectNarrativeFormDialog from "entities/Deal/components/OverviewProjectNarrative/ProjectNarrativeFormDialog";

export interface IProjectNarrativeEditButton {
  buttonSize?: "small" | "medium" | "large";
}

const ProjectNarrativeEditButton = ({
  buttonSize,
}: IProjectNarrativeEditButton) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        size={buttonSize}
        onClick={handleOpen}
        data-testid="edit-project-narrative-section-button"
      >
        <EditIcon />
      </IconButton>
      {open && (
        <ProjectNarrativeFormDialog open={open} handleClose={handleClose} />
      )}
    </>
  );
};

export default ProjectNarrativeEditButton;

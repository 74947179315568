import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  CellAlignType,
  DYNAMIC_HYPHEN_COLUMN,
} from "components/ScrollableTable/constants";

import NameCell, {
  INameCell,
} from "entities/AssetManagement/components/TableCells/NameCell";
import ValueCell, {
  IValueCell,
} from "entities/AssetManagement/components/TableCells/ValueCell";
import {
  AM_VARIANCE_COLUMN,
  AMColumn,
  AMResolution,
} from "entities/AssetManagement/constants";
import {
  IAMDataPoint,
  IAMDateInfo,
  IAMDateValue,
  IAMSubCategory,
  IAssetManagementData,
} from "entities/AssetManagement/sdk";

interface IRowItem {
  [key: string]: string | number | undefined | boolean | object | null;
}

const getOrderedSubCategories = (
  data: IAssetManagementData,
  key: keyof IAssetManagementData
) => {
  const subCategoryListForCategory = {
    Expenses: [
      "Management",
      "Administration",
      "Repair and maintenance",
      "Utilities",
      "Taxes",
      "Insurance",
      "Reserves",
      "Other expenses",
    ],
    Income: ["01. Rental income", "02. Income losses", "03. Other income"],
  };

  const subCategoryList = _.get(subCategoryListForCategory, key);

  if (_.isUndefined(subCategoryList)) {
    return data[key];
  }

  return _.sortBy(data[key], (data) => subCategoryList.indexOf(data.key));
};

const getOrderedItems = (
  items: Array<IAMDataPoint>,
  subCategory: string | null
) => {
  const itemOrdering = {
    Management: ["management fee"],
    Administration: [
      "benchmarking",
      "salaries",
      "tax credit monitoring",
      "license & permits",
      "advertising",
      "legal & audit",
      "general administration",
    ],
    "Repair and maintenance": [
      "site & landscape maintenance",
      "parking lot maintenance",
      "pest control",
      "elevator maintenance",
      "interior & exterior painting",
      "supplies & cleaning expenses",
      "maintenance & repair",
    ],
    Utilities: [
      "security",
      "telephone",
      "cable tv",
      "trash collection",
      "gas - heating",
      "electricity",
      "sewer",
      "water",
    ],
    Taxes: ["other taxes & assessments", "real estate taxes"],
    Insurance: ["insurance - fire and liability"],
    Reserves: ["total replacement reserves"],
    "01. Rental income": ["gross potential rent"],
    "02. Income losses": [
      "less collection loss",
      "less concessions",
      "less vacancies",
    ],
    "03. Other income": [
      "retail",
      "cable tv",
      "other income",
      "parking",
      "laundry",
    ],
  };

  const orderedItemList: Array<string> = _.get(
    itemOrdering,
    String(subCategory),
    []
  );

  // The itemOrdering is reversed and we use .reverse() so the items not included
  // in orderedItemList are at the bottom
  const orderedItems = _.sortBy(items, (a) => {
    return orderedItemList.indexOf(a.name.toLowerCase());
  });

  return orderedItems;
};

export const getTableRows = ({
  data,
  key,
  showSubItems,
  hiddenItems,
  columns,
}: {
  data: IAssetManagementData;
  key: keyof IAssetManagementData;
  showSubItems: boolean;
  hiddenItems: Array<string | null>;
  columns: string[];
}) => {
  const rows: IRowItem[] = [];

  const orderedSubCategories = getOrderedSubCategories(data, key);

  let row: IRowItem;
  orderedSubCategories.forEach((subCategory) => {
    if (showSubItems) {
      // Total values
      row = getRowDataForTotalValues(subCategory, columns);
      // Sub Values
      if (!hiddenItems.includes(subCategory.key)) {
        const orderedItems = getOrderedItems(
          subCategory.data_points,
          subCategory.key
        );

        const children: IRowItem[] = orderedItems.map((dataPoint) =>
          getRowDataForDataPoint(dataPoint, subCategory.key, columns)
        );

        row["children"] = children;
      }
    } else {
      row = getRowDataForTotalValues(subCategory, columns);
    }

    rows.push(row);
  });

  return rows;
};

export const dateInfoToString = (date_info: IAMDateInfo) => {
  return `${date_info.year}${
    date_info.quarter ? ` / Q${date_info.quarter}` : ""
  }`;
};

export const getColumnKey = (
  o: { year: number | string; quarter?: number | null },
  name: string
) => {
  return `${o.year}${o.quarter ? `-${o.quarter}` : ""}-${name}`;
};

const getDateValueProperty = (
  dateValue: IAMDateValue,
  propertyString: string
) => {
  switch (propertyString) {
    case "Budgeted":
      return dateValue.Budgeted?.value;
    case "Actual":
      return dateValue.Actual?.value;
    case "Audited":
      return dateValue.Audited?.value;
  }
};

const calculateVariance = (columns: string[], dateValue: IAMDateValue) => {
  const firstNumber = getDateValueProperty(dateValue, columns[0]) || 0;
  const secondNumber = getDateValueProperty(dateValue, columns[1]) || 0;

  if (!secondNumber || !firstNumber) {
    return undefined;
  }

  const variance = ((firstNumber - secondNumber) / secondNumber) * 100;
  return { value: `${variance.toFixed(2)}%` };
};
const getRowData = (
  currentRowItem: IRowItem,
  dateValue: IAMDateValue,
  columns: string[]
) => {
  currentRowItem[getColumnKey(dateValue.date_info, AMColumn.BUDGETED)] =
    dateValue.Budgeted;
  currentRowItem[getColumnKey(dateValue.date_info, AMColumn.ACTUAL)] =
    dateValue.Actual;
  currentRowItem[getColumnKey(dateValue.date_info, AMColumn.AUDITED)] =
    dateValue.Audited;
  currentRowItem["key"] = uuidv4();
  if (columns.length === 2) {
    currentRowItem[getColumnKey(dateValue.date_info, AM_VARIANCE_COLUMN)] =
      calculateVariance(columns, dateValue);
  }
};

const getRowDataForDataPoint = (
  dataPoint: IAMDataPoint,
  subCategory: string | null,
  columns: string[]
) => {
  const currentRowItem: IRowItem = {
    name: dataPoint.name,
    code: dataPoint.code,
    sub: true,
    subCategory: subCategory,
  };
  dataPoint.date_values.forEach((date) => {
    getRowData(currentRowItem, date, columns);
  });

  return currentRowItem;
};

const getRowDataForTotalValues = (
  subCategory: IAMSubCategory,
  columns: string[]
) => {
  const currentRowItem: IRowItem = {
    name: `${subCategory.key ? subCategory.key : "-"}`,
  };
  subCategory.total_values.forEach((value) => {
    getRowData(currentRowItem, value, columns);
  });
  return currentRowItem;
};

export const getAMTableHeaders = ({
  headers,
  columns,
  selectedTab,
  editMode,
  resolution,
  deleteRow,
  deleteItem,
  diff,
}: {
  headers: {
    year: number | string;
    quarter?: number | null;
  }[];
  columns: AMColumn[];
  selectedTab: string;
  editMode: boolean;
  resolution: AMResolution;
  deleteRow: ({ itemCode, name }: { itemCode: string; name: string }) => void;
  diff?: any;
  deleteItem?: ({
    itemCode,
    type,
    dateInfo,
    itemId,
  }: {
    itemCode: string;
    type: string;
    dateInfo: IAMDateInfo;
    itemId: number;
  }) => void;
}) => {
  const mapHeaders = headers.map((header) => {
    let children = columns.map((column) => ({
      label: column,
      key: getColumnKey(header, column),
      width: "130px",
      noCellPadding: true,
      justifyContent: CellAlignType.FLEX_END,
      render: ({ row, key }: { row: IValueCell["row"]; key: string }) => {
        return (
          <ValueCell
            row={row}
            columnKey={key}
            editMode={editMode}
            itemCode={row.code}
            deleteItem={deleteItem}
            deleteButtonDataTestid="delete-cell-value-button"
            /*
            When the `row.subCategory` is `null`, we want to update it to "",
            because the key from BE is {item_code}|{sub_category}.
            We have data without subCategory and then the key is "{item_code}|".
            */
            diff={_.get(diff, [
              "cells_diff",
              `${row.code}|${
                _.isNull(row.subCategory) ? "" : row.subCategory
              }|${header.year}|${
                header.quarter ? header.quarter : ""
              }|${column}`,
            ])}
          />
        );
      },
    }));
    if (columns.length === 2) {
      children = [
        ...children,
        {
          label: AMColumn.VARIANCE,
          key: getColumnKey(header, AM_VARIANCE_COLUMN),
          width: "130px",
          justifyContent: CellAlignType.FLEX_END,
          noCellPadding: true,
          render: ({ row, key }: { row: IValueCell["row"]; key: string }) => {
            return (
              <ValueCell
                row={row}
                columnKey={key}
                editMode={editMode}
                itemCode={row.code}
              />
            );
          },
        },
      ];
    }

    const headerLabel =
      resolution === AMResolution.YEARLY
        ? header.year.toString()
        : `${header.year.toString()} / Q${header.quarter?.toString()}`;

    return { label: headerLabel, children };
  });

  return [
    {
      label: selectedTab,
      sticky: true,
      children: [
        {
          label: "Item code & name",
          key: "name",
          width: "350px",
          noCellPadding: true,
          render: ({
            row,
            key,
            isExpanded,
          }: {
            row: INameCell["row"];
            key: string;
            isExpanded?: boolean;
          }) => (
            <NameCell
              row={row}
              columnKey={key}
              isExpanded={isExpanded}
              editMode={editMode}
              deleteRow={deleteRow}
              diff={_.get(diff, [
                "rows_diff",
                `${row.code}|${row.subCategory}`,
              ])}
            />
          ),
        },
      ],
    },
    ...mapHeaders,
    DYNAMIC_HYPHEN_COLUMN,
  ];
};

export const insertNotificationDataIntoColumns = ([
  basicDetails,
  ...columns
]: any[]) => {
  return [
    basicDetails,
    {
      name: "Notifications",
      report_fields: [{ name: "Notifications", field_type: "notifications" }],
    },
    ...columns,
  ];
};

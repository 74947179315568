import React from "react";

import { ForumOutlinedIcon } from "icons";

import Stack from "components/Stack";
import Text from "components/Text";

const EmptyChat: React.FC<{}> = () => {
  return (
    <Stack spacing={5} direction="row" alignItems="center" margin={5}>
      <ForumOutlinedIcon sx={{ width: "50px", height: "50px" }} />
      <Stack spacing={3}>
        <Text variant="text2" fontWeight={700}>
          Start a conversation here!
        </Text>
        <Text variant="text2">
          This channel is between your team and the team you selected. Directly
          start a thread by typing in your first message.
        </Text>
      </Stack>
    </Stack>
  );
};

export default EmptyChat;

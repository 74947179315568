import { memo, useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import FormControlLabel from "components/FormControlLabel";
import ListPagination from "components/ListPagination";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Switch from "components/Switch";
import Text from "components/Text";

import { IUploadedFile } from "entities/File/sdk";

import File from "./File";

export interface IFilesListDialog {
  isOpen: boolean;
  data: Array<IUploadedFile>;
  currentPage: number;
  pagesCount: number;
  isLoading: boolean;
  onSetPage: (page: number) => void;
  onClose: () => void;
  onDownload: ({ file }: { file: IUploadedFile }) => void;
  showSnapshotFilter?: boolean;
  onChangeSnapshotFilter?: ({ value }: { value: boolean }) => void;
}

const FilesListDialog = ({
  isOpen,
  data,
  currentPage,
  pagesCount,
  isLoading,
  onSetPage,
  onClose,
  onDownload,
  showSnapshotFilter,
  onChangeSnapshotFilter,
}: IFilesListDialog) => {
  const showFilesWithPagination = useMemo(
    () => !isLoading && data.length > 0,
    [isLoading, data]
  );

  const handleChangeFilter = useCallback(
    (_, value: boolean) => onChangeSnapshotFilter?.({ value }),
    [onChangeSnapshotFilter]
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      data-testid="files-history-dialog"
    >
      <DialogTitle
        sx={{
          margin: 2,
          borderRadius: "8px",
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Text
              variant="h2"
              marginBottom={0.5}
              color={colors.gray100}
              data-testid="dialog-title"
            >
              View uploaded files
            </Text>
            <Text
              variant="text2"
              color={colors.gray80}
              data-testid="dialog-sub-title"
            >
              Here you can see all the Excel file uploads.
            </Text>
          </Stack>
          {showSnapshotFilter && (
            <FormControlLabel
              label={
                <Text variant="text2" sx={{ fontWeight: 500 }}>
                  Show only snapshots
                </Text>
              }
              control={<Switch onChange={handleChangeFilter} />}
              labelPlacement="start"
            />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        {isLoading && (
          <Stack spacing={1} width="100%">
            {_.range(3).map((index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                  padding: theme.spacing(1, 0),
                  borderBottom: `1px solid ${colors.blue40}`,
                }}
              >
                <Stack direction="row" spacing={2} flex={1}>
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Stack spacing={0.5}>
                    <Skeleton variant="rectangular" width={450} height={20} />
                    <Skeleton variant="rectangular" width={450} height={20} />
                  </Stack>
                </Stack>
                <Skeleton variant="rectangular" width={20} height={20} />
              </Stack>
            ))}
          </Stack>
        )}
        {!isLoading && data.length === 0 && (
          <Text
            variant="h6"
            sx={{ marginBottom: 4 }}
            data-testid="empty-files-list-state"
          >
            No uploaded files yet.
          </Text>
        )}
        {showFilesWithPagination && (
          <Stack
            alignItems="center"
            sx={{ maxHeight: "410px" }}
            data-testid="uploaded-files-list"
          >
            {data.map((file: IUploadedFile, index: number) => (
              <File
                key={index}
                isLatest={file.is_current}
                uploadedFile={file}
                onDownload={onDownload}
              />
            ))}
          </Stack>
        )}
      </DialogContent>
      {showFilesWithPagination && (
        <DialogActions sx={{ alignItems: "center", width: "100%" }}>
          <Box width="100%">
            <ListPagination
              setPage={onSetPage}
              pagesCount={pagesCount}
              currentPage={currentPage}
            />
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(FilesListDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useCallback } from "react";
import _ from "lodash";

import { DeleteIcon } from "icons";

import Box from "components/Box";
import Button from "components/Button";
import ContentSeparator from "components/ContentSeparator";
import Image from "components/Image";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import CommunityAmenitiesEditButton from "entities/Deal/components/OverviewCommunityAmenities/CommunityAmenitiesEditButton";
import CommunityAmenitiesTable from "entities/Deal/components/OverviewCommunityAmenities/CommunityAmenitiesTable";
import NoContent from "entities/Deal/components/OverviewSections/NoContent";
import UploadImage from "entities/Deal/components/OverviewSections/UploadImage";
import {
  deleteCommunityAmenitiesImage,
  updateCommunityAmenities,
} from "entities/Deal/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import { IMemoSection } from "entities/Memo/sdk";
import { useDealCommunityAmenitiesManager } from "entities/Memo/utils";

interface IOverviewCommunityAmenities {
  dealId: number;
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}

const OverviewCommunityAmenities: React.FC<IOverviewCommunityAmenities> = ({
  dealId,
  externalToken,
  section,
  onUpdate,
}) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();
  const { data: communityAmenities, mutate: mutateCommunityAmmenities } =
    useDealCommunityAmenitiesManager({ dealId, token: externalToken });

  const handleUploadImage = useCallback(
    ({ imageId }: { imageId: number }) =>
      updateCommunityAmenities({ dealId, imageId }).then(() => {
        mutateCommunityAmmenities();
        toast.successMessage(
          "Community amenities image successfully uploaded!"
        );
      }),
    [mutateCommunityAmmenities, dealId]
  );

  const onDeleteImage = useCallback(
    () =>
      deleteCommunityAmenitiesImage(dealId).then(() => {
        toast.successMessage("Community amenities image successfully deleted!");
        mutateCommunityAmmenities();
      }),
    [dealId, mutateCommunityAmmenities]
  );

  const handleDeleteImage = useCallback(
    () =>
      showConfirmationDialog({
        onConfirm: () => onDeleteImage(),
        message: "Are you sure you want to delete this image?",
      }),
    [showConfirmationDialog, onDeleteImage]
  );

  if (!communityAmenities) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Text variant="h2" data-testid="section-header-name">
          Community Amenities
        </Text>
        {_.isUndefined(externalToken) && (
          <NoPrint>
            <Stack direction="row" spacing={1} alignItems="center">
              {!section?.is_na && <CommunityAmenitiesEditButton />}
              {section && (
                <MemoSectionContextButton
                  dealId={dealId}
                  section={section}
                  onUpdate={onUpdate}
                />
              )}
            </Stack>
          </NoPrint>
        )}
      </Stack>
      {section?.is_na ? (
        <NASection />
      ) : (
        <>
          <ContentSeparator label="Community Amenities" />
          <CommunityAmenitiesTable
            community_amenities_data={communityAmenities}
            tableDataTestid="community-amenities-categories-tables"
          />
          {!communityAmenities.image && _.isUndefined(externalToken) && (
            <NoPrint>
              <UploadImage
                onChange={handleUploadImage}
                dataTestid="upload-community-amenities-input"
              />
            </NoPrint>
          )}
          {communityAmenities.image && (
            <Stack spacing={2}>
              <Image
                src={communityAmenities.image}
                imgix
                imgixHeight={920}
                sx={{ height: "460px", width: "100%", objectFit: "cover" }}
                imageDataTestid="uploaded-community-amenities-image"
              />
              {_.isUndefined(externalToken) && (
                <NoPrint
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    sx={{ alignSelf: "flex-end" }}
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteImage}
                    data-testid="delete-community-amenities-image-button"
                  >
                    Delete image
                  </Button>
                </NoPrint>
              )}
            </Stack>
          )}
          <ContentSeparator label="Community Amenities Description" />
          <Box
            sx={{
              paddingLeft: 1,
            }}
            data-testid="community-amenities-description"
          >
            {communityAmenities.description && (
              <SanitizeHtml
                html={communityAmenities.description}
                dataTestid="community-amenities-description-added"
              />
            )}
            {!communityAmenities.description && <NoContent />}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default OverviewCommunityAmenities;

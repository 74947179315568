import React from "react";
import { useCallback } from "react";
import _ from "lodash";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Checkbox from "components/Checkbox";
import Collapse from "components/Collapse";
import Stack from "components/Stack";
import Text from "components/Text";

export interface ITreeSelectItem {
  id: number;
  name: string;
  children: Array<ITreeSelectItem>;
  isField: boolean;
}

export interface ITreeSelect {
  items: Array<ITreeSelectItem>;
  selectedItemIds: Array<number>;
  setSelectedItemIds: (arg0: any) => void;
  openItemIds: Array<number>;
  setOpenItemIds: (arg0: any) => void;
  level?: number;
}

const TreeSelect = ({
  items,
  selectedItemIds,
  setSelectedItemIds,
  openItemIds,
  setOpenItemIds,
  level = 0,
}: ITreeSelect) => {
  const hasFieldsChildren = useCallback(
    ({ item }: { item: ITreeSelectItem }): boolean => {
      if (_.isEmpty(item.children) && item.isField) {
        return true;
      }
      return item.children.some((child: ITreeSelectItem) =>
        hasFieldsChildren({ item: child })
      );
    },
    []
  );

  const selectItem = useCallback(
    ({ item }: { item: ITreeSelectItem }) => {
      if (_.isEmpty(item.children)) {
        // If it's a leaf node - push it in the selected items.
        setSelectedItemIds((prevItems: Array<number>) =>
          prevItems.includes(item.id) ? prevItems : [...prevItems, item.id]
        );
      } else {
        item.children.forEach((child) => {
          if (hasFieldsChildren({ item: child })) {
            selectItem({ item: child });
          }
        });
      }
    },
    [setSelectedItemIds, hasFieldsChildren]
  );

  const deselectItem = useCallback(
    ({ item }: { item: ITreeSelectItem }) => {
      if (_.isEmpty(item.children)) {
        // If it's a leaf node - remove it from the selected items.
        setSelectedItemIds((prevItems: Array<number>) =>
          prevItems.filter((id) => id !== item.id)
        );
      } else {
        item.children.forEach((child: ITreeSelectItem) =>
          deselectItem({ item: child })
        );
      }
    },
    [setSelectedItemIds]
  );

  const isItemInterminate = useCallback(
    ({ item }: { item: ITreeSelectItem }): boolean => {
      if (item.isField) {
        // If it's a leaf node - check if it is selected.
        return selectedItemIds.includes(item.id);
      }
      // Otherwise check if all the children are already selected.
      return item.children
        .filter((child) => hasFieldsChildren({ item: child }))
        .some((child) => isItemInterminate({ item: child }));
    },
    [selectedItemIds, hasFieldsChildren]
  );

  const toggleItemSelect = useCallback(
    ({ item, isChecked }: { isChecked: boolean; item: ITreeSelectItem }) => {
      if (isChecked) {
        deselectItem({ item });
      } else {
        selectItem({ item });
      }
    },
    [selectItem, deselectItem]
  );

  const isItemChecked = useCallback(
    ({ item }: { item: ITreeSelectItem }): boolean => {
      if (_.isEmpty(item.children)) {
        // If it's a leaf node - check if it is selected.
        return selectedItemIds.includes(item.id);
      }
      // Otherwise check if all the children are already selected.
      const filteredItems = item.children.filter((child) =>
        hasFieldsChildren({ item: child })
      );

      if (filteredItems.length) {
        return filteredItems.every((child) => isItemChecked({ item: child }));
      }
      return false;
    },
    [selectedItemIds, hasFieldsChildren]
  );

  const getChildrenCount = useCallback(
    ({ item }: { item: ITreeSelectItem }): number => {
      if (_.isEmpty(item.children)) {
        return 1;
      } else {
        return item.children
          .map((child: ITreeSelectItem) => getChildrenCount({ item: child }))
          .reduce((a: number, b: number) => a + b, 0);
      }
    },
    []
  );

  const toggleChildrenExpand = useCallback(
    ({ item }: { item: ITreeSelectItem }) => {
      setOpenItemIds((prevValue: Array<number>) => _.xor(prevValue, [item.id]));
    },
    [setOpenItemIds]
  );

  return (
    <>
      {items.map((item) => {
        const { id, name, children } = item;

        const isOpen = openItemIds.includes(id);
        const isChecked = isItemChecked({ item });
        const isIndeterminate = !isChecked && isItemInterminate({ item });
        const childrenCount = getChildrenCount({ item });
        const hasChildren = !_.isEmpty(children);
        const containsFields = hasFieldsChildren({ item });

        return (
          <React.Fragment key={id}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                cursor: "pointer",
                borderRadius: "4px",
                paddingLeft: theme.spacing(4 * level),
                "&:hover": { backgroundColor: colors.blue10 },
              }}
              data-testid="checklist-item"
            >
              <Stack sx={{ width: 24 }} data-testid="collapsible-button">
                {hasChildren &&
                  (isOpen ? (
                    <ArrowDropDownIcon
                      onClick={() => toggleChildrenExpand({ item })}
                      data-testid="hide-items-button"
                    />
                  ) : (
                    <ArrowRightIcon
                      onClick={() => toggleChildrenExpand({ item })}
                      data-testid="expand-items-button"
                    />
                  ))}
              </Stack>
              <Checkbox
                disabled={!containsFields}
                checked={isChecked}
                indeterminate={isIndeterminate}
                sx={{ backgrouncColor: colors.white }}
                onClick={() => toggleItemSelect({ isChecked, item })}
                data-testid="item-checkbox"
              />
              <Text
                variant="text2"
                fontWeight={500}
                color={containsFields ? colors.black : colors.gray40}
                data-testid="item-name"
              >
                {name}
              </Text>
              {childrenCount > 0 && containsFields && (
                <Text
                  sx={{ marginLeft: theme.spacing(1) }}
                  data-testid="total-fields-in-item"
                >
                  {childrenCount} items
                </Text>
              )}
            </Stack>
            <Collapse
              in={isOpen}
              timeout="auto"
              unmountOnExit
              data-testid="checklist-item-inner-items"
            >
              <TreeSelect
                items={children}
                level={level + 1}
                openItemIds={openItemIds}
                setOpenItemIds={setOpenItemIds}
                selectedItemIds={selectedItemIds}
                setSelectedItemIds={setSelectedItemIds}
              />
            </Collapse>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TreeSelect;

import React, { useCallback, useState } from "react";

import Button from "components/Button";
import Stack from "components/Stack";

import EditSelectedDealsDialog from "entities/AnalyticsFilterGroup/components/EditSelectedDealsDialog";

interface IEditSelectedDealsButton {
  deals: any[];
  onExcludedDealsUpdate: (dealIds: Array<number>) => void;
}

const EditSelectedDealsButton: React.FC<IEditSelectedDealsButton> = ({
  deals,
  onExcludedDealsUpdate,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Stack direction="row" spacing={2}>
      <Button color="primary" onClick={handleOpen}>
        Edit Deals
      </Button>
      <EditSelectedDealsDialog
        open={open}
        handleClose={handleClose}
        deals={deals}
        onExcludedDealsUpdate={onExcludedDealsUpdate}
      />
    </Stack>
  );
};

export default EditSelectedDealsButton;

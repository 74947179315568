import React, { useMemo } from "react";
import _ from "lodash";

import { formatNumberToString } from "utils/common";

import Stack from "components/Stack";

import SourcesAndUsesSection from "entities/Proforma/components/ProformaSourcesAndUses/SourcesAndUsesSection";
import { ICoreData } from "entities/Proforma/sdk";

export interface ISourcesAndUsesConstruction {
  coreData: ICoreData;
}

const sourcesHeaders = [
  { key: "sources", name: "sources" },
  { key: "amount", name: "amount" },
  { key: "percentage", name: "%" },
];

const usesHeaders = [
  { key: "uses", name: "uses" },
  { key: "amount", name: "amount" },
  { key: "percentage", name: "%" },
];

const SourcesAndUsesConstruction: React.FC<ISourcesAndUsesConstruction> = ({
  coreData,
}) => {
  const sourcesConstructionRows = useMemo(
    () =>
      _.orderBy(
        _.map(coreData.sources_at_construction_data, (value, key) => ({
          sources: key
            .replaceAll("_", " ")
            .replaceAll("symby", "/")
            .replaceAll("symdash", "-"),
          amount: formatNumberToString({ number: value.value }),
          percentage: `${formatNumberToString({
            number: value.percentage,
          })}%`,
          position: value.position,
        })),
        "position"
      ),
    [coreData]
  );

  const usesConstructionRows = useMemo(
    () =>
      _.orderBy(
        _.map(coreData.uses_at_construction_data, (value, key) => ({
          uses: key
            .replaceAll("_", " ")
            .replaceAll("symby", "/")
            .replaceAll("symdash", "-"),
          amount: formatNumberToString({ number: value.value }),
          percentage: `${formatNumberToString({
            number: value.percentage,
          })}%`,
          position: value.position,
        })),
        "position"
      ),
    [coreData]
  );

  const sourcesTableTotals: { [key: string]: string } = {
    sources: "Total Sources",
    amount: formatNumberToString({
      number: coreData.total_sources_at_construction_data,
    }),
    percentage: "100%",
  };

  const usesTableTotals: { [key: string]: string } = {
    uses: "Total Uses",
    amount: formatNumberToString({
      number: coreData.total_uses_at_construction_data,
    }),
    percentage: "100%",
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={4}
      data-testid="section-content"
    >
      {/* TODO: the totals of the tables have to be on a one row */}
      <SourcesAndUsesSection
        title="Sources"
        subTitle="@Construction"
        rows={sourcesConstructionRows}
        headers={sourcesHeaders}
        tableTotals={sourcesTableTotals}
        total={coreData.total_sources_at_construction_data}
        sectionDataTestid="sources-section"
      />
      <SourcesAndUsesSection
        title="Uses"
        subTitle="@Construction"
        rows={usesConstructionRows}
        headers={usesHeaders}
        tableTotals={usesTableTotals}
        total={coreData.total_uses_at_construction_data}
        sectionDataTestid="uses-section"
      />
    </Stack>
  );
};

export default SourcesAndUsesConstruction;

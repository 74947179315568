import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { ConnectedFieldsDisabledIcon, ConnectedFieldsIcon } from "icons";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import { useDealPermission } from "entities/Deal/sdk";
import SyncingConfirmationDialog from "entities/Field/components/LinkedFieldsDrawerButton/SyncingConfirmationDialog";
import { CompanyCustomFieldSyncingType } from "entities/Field/constants";
import { updateFieldOverriddenStatus } from "entities/Field/sdk";

export interface IUpdateConnectionFields {
  dealId: number;
  fieldId: number;
  isOverridden: boolean;
  onUpdate: ({ message }: { message?: string }) => void;
}

const UpdateConnectionFields = ({
  dealId,
  fieldId,
  isOverridden,
  onUpdate,
}: IUpdateConnectionFields) => {
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const { data: userPermission } = useDealPermission({
    dealId,
  });

  const isDealAdmin = useMemo(
    () =>
      !_.isNil(userPermission) &&
      userPermission.is_full_access &&
      userPermission.is_deal_admin,
    [userPermission]
  );

  const updateOverriddenStatus = useCallback(
    ({ syncingType }: { syncingType: CompanyCustomFieldSyncingType }) =>
      updateFieldOverriddenStatus({
        dealId,
        fieldId,
        syncingType,
      })
        .then(() => {
          onUpdate({}); //TODO add message
        })
        .catch(handleInvalidRequest),
    [dealId, fieldId, onUpdate]
  );

  const handleConfirmConfirmation = useCallback(
    ({ syncingType }: { syncingType: CompanyCustomFieldSyncingType }) => {
      setIsOpenConfirmation(false);
      updateOverriddenStatus({
        syncingType,
      });
    },
    [updateOverriddenStatus, setIsOpenConfirmation]
  );

  const handleChangeConnectedFields = useCallback(() => {
    if (isOverridden) {
      setIsOpenConfirmation(true);
    } else {
      updateOverriddenStatus({
        syncingType: CompanyCustomFieldSyncingType.OVERRIDE_CURRENT_VALUE,
      });
    }
  }, [isOverridden, updateOverriddenStatus, setIsOpenConfirmation]);

  const handleCloseConfirmation = useCallback(() => {
    setIsOpenConfirmation(false);
  }, []);

  return (
    <>
      <Stack spacing={1} alignItems="flex-start" width="25%">
        <Text
          variant="label"
          color={colors.gray80}
          fontWeight={500}
          sx={{ opacity: 0.5 }}
        >
          Status
        </Text>
        {isOverridden ? (
          <Stack direction="row" spacing={1}>
            <Tag label="Connections disabled" variant="gray80" />
            <ConnectedFieldsDisabledIcon
              style={{
                height: "16px",
                width: "16px",
                cursor: "pointer",
                alignSelf: "center",
                display: "flex",
              }}
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <Tag label="Connections enabled" />
            <ConnectedFieldsIcon
              style={{
                height: "16px",
                width: "16px",
                cursor: "pointer",
                alignSelf: "center",
                display: "flex",
              }}
            />
          </Stack>
        )}
        <Text variant="text4" color={colors.gray80}>
          All connections to this field within this deal are{" "}
          {isOverridden ? "disabled" : "active"}.
        </Text>
        {isDealAdmin && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleChangeConnectedFields}
          >
            {isOverridden ? "Re-enable" : "Disable"} connections
          </Button>
        )}
      </Stack>
      <SyncingConfirmationDialog
        isOpen={isOpenConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmConfirmation}
      />
    </>
  );
};

export default UpdateConnectionFields;

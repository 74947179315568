export enum ERoomType {
  STUDIO = "studio",
  BEDROOM_1 = "bedroom_1",
  BEDROOM_2 = "bedroom_2",
  BEDROOM_3 = "bedroom_3",
  BEDROOM_4 = "bedroom_4",
}

export const MapRoomType = {
  [ERoomType.STUDIO]: "Studio",
  [ERoomType.BEDROOM_1]: "1 Bedroom",
  [ERoomType.BEDROOM_2]: "2 Bedroom",
  [ERoomType.BEDROOM_3]: "3 Bedroom",
  [ERoomType.BEDROOM_4]: "4 Bedroom",
};

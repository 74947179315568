import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";
import { useRedirectUponFetchFailure } from "utils/common";

import Stack from "components/Stack";
import { FiltersContextProvider } from "components/useFilters";
import { usePageTitle } from "components/usePageTitle";

import CustomViewContent from "pages/Reporting/CustomReport/CustomViewContent";
import CustomReportPageHeader from "pages/Reporting/CustomReport/PageHeader";

import {
  useCustomReportCompanyDeals,
  useReportPackageDetails,
} from "entities/Reporting/sdk";

const CustomReport = () => {
  const params = useParams<{ customReportId: string }>();
  const customReportId = Number(params.customReportId);

  const {
    data: reportPackage,
    error: reportPackageError,
    loading: isLoadingReportPackageDetails,
  } = useReportPackageDetails(customReportId);

  useRedirectUponFetchFailure({
    error: reportPackageError,
    loading: isLoadingReportPackageDetails,
  });

  usePageTitle(`Builders Patch: Reports - ${_.startCase(reportPackage?.name)}`);

  const {
    columns = [],
    rows = [],
    loading: isLoadingCustomReportCompanyDeals,
    isValidating,
    error: customReportCompanyDealsError,
  } = useCustomReportCompanyDeals(customReportId);

  useRedirectUponFetchFailure({
    error: customReportCompanyDealsError,
    loading: isLoadingCustomReportCompanyDeals,
  });

  if (_.isUndefined(reportPackage)) {
    return null;
  }

  return (
    <FiltersContextProvider key={customReportId}>
      <Stack spacing={2} sx={{ padding: theme.spacing(4, 3, 0), flex: 1 }}>
        <CustomReportPageHeader
          reportPackage={reportPackage}
          rows={rows}
          columns={columns}
          loading={isLoadingCustomReportCompanyDeals}
        />
        <CustomViewContent
          rows={rows}
          columns={columns}
          loading={isLoadingCustomReportCompanyDeals}
          isValidating={isValidating}
          reportPackage={reportPackage}
        />
      </Stack>
    </FiltersContextProvider>
  );
};

export default CustomReport;

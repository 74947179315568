import { useEffect } from "react";
import _ from "lodash";

import PusherManager from "utils/pusher-manager";

import { useSignedUser } from "entities/Auth/sdk";

export const useAssetManagementFileProcessingStatusUpdates = ({
  successEventHandler,
  failureEventHandler,
}: {
  successEventHandler: ({
    dealId,
    importId,
  }: {
    dealId: number;
    importId: number;
  }) => void;
  failureEventHandler: () => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToAssetManagementFileProcessingSucceededEvent({
        userId: user.id,
        eventHandler: ({ deal_id, import_id }) =>
          successEventHandler({ dealId: deal_id, importId: import_id }),
      });
    }
  }, [user, successEventHandler]);

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToAssetManagementFileProcessingFailedEvent({
        userId: user.id,
        eventHandler: failureEventHandler,
      });
    }
  }, [user, failureEventHandler]);
};

export const useAssetManagementExcelProcessingStatusUpdates = ({
  successEventHandler,
  failureEventHandler,
}: {
  successEventHandler: () => void;
  failureEventHandler: ({ detail }: { detail: string }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToAssetManagementExcelProcessingSucceededEvent({
        userId: user.id,
        eventHandler: successEventHandler,
      });
    }
  }, [user, successEventHandler]);

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToAssetManagementExcelProcessingFailedEvent({
        userId: user.id,
        eventHandler: failureEventHandler,
      });
    }
  }, [user, failureEventHandler]);
};

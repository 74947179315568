import theme from "theme";

import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import { FiltersContextProvider } from "components/useFilters";
import { usePageTitle } from "components/usePageTitle";

import CustomerSearchField from "entities/Superuser/components/CustomerSearchField";
import CustomerTable from "entities/Superuser/components/CustomerTable";

const Customers = () => {
  usePageTitle("Builders Patch: Customer list");

  return (
    <FiltersContextProvider>
      <Stack
        sx={{
          padding: theme.spacing(4, 3, 0),
        }}
        spacing={3}
      >
        <PageHeader title="Customer list" actions={<CustomerSearchField />} />
        <CustomerTable />
      </Stack>
    </FiltersContextProvider>
  );
};

export default Customers;

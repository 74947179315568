import { memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { DeleteOutlineOutlinedIcon } from "icons";
import { colors } from "theme/palette";
import { scrollToSelectedAnchor } from "utils/common";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import { ID as SUB_NAVIGATION_ID } from "components/DealPageLayout/SubNavigation";
import IconButton from "components/IconButton";
import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import CopyToClipboardButton from "entities/AISummaries/components/CopyToClipboardButton";
import {
  IOpenAIPromptResponse,
  promptResponseDelete,
} from "entities/AISummaries/sdk";
import { useCompanySettings } from "entities/Company/sdk";
import { isHTMLTableData } from "entities/TableStorage/components/PasteTableField/utils";
import SaveHTMLTableButton from "entities/TableStorage/components/SaveHTMLTableButton";

const ANCHORS = [MAIN_NAVIGATION_ID, SUB_NAVIGATION_ID];

export interface IPromptResponse {
  openAIDocumentAssistantId: number;
  promptResponse: IOpenAIPromptResponse;
  onDelete: () => void;
}

const PromptResponse = ({
  openAIDocumentAssistantId,
  promptResponse,
  onDelete,
}: IPromptResponse) => {
  const navigate = useNavigate();

  const params = useParams<{
    dealId: string;
  }>();
  const [searchParams] = useSearchParams();

  const dealId = Number(params.dealId);

  const { data: companySettings } = useCompanySettings({ dealId });

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleDelete = useCallback(() => {
    showConfirmationDialog({
      onConfirm: () =>
        promptResponseDelete({
          openAIDocumentAssistantId,
          promptResponseId: promptResponse.id,
        })
          .then(() => {
            onDelete();
            toast.successMessage("Answer has been deleted successfully.");
          })
          .catch((error) => {
            const errorMessage =
              error?.message || error?.detail || DEFAULT_ERROR_MESSAGE;

            toast.errorMessage(errorMessage);
          }),
      confirmButtonText: "Delete",
      message: "Are you sure you want to delete this answer?",
    });
  }, [
    openAIDocumentAssistantId,
    promptResponse,
    onDelete,
    showConfirmationDialog,
  ]);

  const showLoading = useMemo(
    () => _.isNil(promptResponse.readable_answer),
    [promptResponse]
  );

  const formatedDate = useMemo(
    () =>
      format(parseJSON(promptResponse.created_at), FORMATS.FRONTEND.DATETIME),
    [promptResponse]
  );

  const showSaveTableButton = useMemo(
    () =>
      companySettings?.deal_tables_shown &&
      isHTMLTableData({ data: promptResponse.readable_answer }),
    [companySettings, promptResponse]
  );

  const handleOnSaveTable = useCallback(
    ({ tableId: newTableId }) => {
      toast.successMessage("Table created successfully");

      navigate(
        reverse(URLS.DEAL_TABLE_STORAGE_DETAILS, {
          dealId,
          tableId: newTableId,
        })
      );
    },
    [dealId, navigate]
  );

  useEffect(() => {
    const promptResponseId = searchParams.get("prompt-response-id");

    if (promptResponse.id.toString() !== promptResponseId) {
      return;
    }

    scrollToSelectedAnchor({
      anchor: `prompt-response-${promptResponseId}`,
      navigationIds: ANCHORS,
    });
  }, [promptResponse, searchParams]);

  const responseElementId = useMemo(
    () => `prompt-response-readable-answer-${promptResponse.id}`,
    [promptResponse]
  );

  return (
    <Paper
      key={promptResponse.id}
      sx={{ backgroundColor: colors.gray10 }}
      id={`prompt-response-${promptResponse.id}`}
    >
      {showLoading ? (
        <Stack spacing={3}>
          <Skeleton variant="rectangular" height="200px" />
          <Stack direction="row" justifyContent="space-between">
            <Skeleton variant="text" width="400px" />
            <Stack direction="row" spacing={1}>
              <Skeleton variant="rounded" width="30px" />
              <Skeleton variant="rounded" width="30px" />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={3}>
          <Text
            sx={{ whiteSpace: "pre-wrap" }}
            id={responseElementId}
            dangerouslySetInnerHTML={{ __html: promptResponse.readable_answer }}
          />

          <Stack
            spacing={1}
            direction="row"
            sx={{ justifyContent: "space-between" }}
          >
            <Text variant="text2" sx={{ color: colors.gray60 }}>
              Answer generated at {formatedDate}
            </Text>
            <Stack spacing={1} direction="row" sx={{ alignSelf: "end" }}>
              {showSaveTableButton && (
                <SaveHTMLTableButton
                  dealId={dealId}
                  tableData={promptResponse.readable_answer}
                  onSave={handleOnSaveTable}
                />
              )}
              <CopyToClipboardButton containerId={responseElementId} />
              <Tooltip title="Delete answer">
                <IconButton onClick={handleDelete}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};

export default memo(PromptResponse, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

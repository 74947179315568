import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { LogoIcon } from "icons";
import { colors } from "theme/palette";

import Container from "components/Container";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import OnboardingForm from "entities/User/components/OnboardingForm";
import { userOnboard } from "entities/User/sdk";

const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const email = searchParams.get("email");
  const nextUrl = searchParams.get("next");

  const preselectedCompany = useMemo(() => {
    const companyId = searchParams.get("company_id");
    const companyName = searchParams.get("company_name");
    const companyDomain = searchParams.get("company_domain");

    if (companyId === null || companyName === null || companyDomain === null) {
      return null;
    }

    return {
      id: _.toNumber(companyId),
      name: companyName,
      domain: companyDomain,
    };
  }, [searchParams]);

  useEffect(() => {
    if (_.isNil(email)) {
      navigate(URLS.NOT_FOUND, { replace: true });
    }
  }, [email, navigate]);

  const handleOnboardingFormSubmit = ({
    values,
  }: {
    values: {
      first_name: string;
      last_name: string;
      company_name: string;
    };
  }) => {
    userOnboard({ values: { ...values, email: email as string } })
      .then(() => {
        if (_.isEmpty(nextUrl)) {
          navigate("/");
        } else {
          window.location.href = nextUrl as string;
        }
      })
      .catch((errors) => {
        const errorMessage = errors?.message || errors?.detail;

        if (!_.isNil(errorMessage)) {
          toast.errorMessage(errorMessage);
        } else {
          errors?.forEach?.((error: unknown) => {
            if (_.isString(error)) {
              toast.errorMessage(error);
            }
          });
        }
      });
  };

  return _.isNil(email) ? null : (
    <div>
      <Stack
        height={64}
        sx={{ backgroundColor: colors.blue10 }}
        marginTop={8}
        marginBottom={8}
      >
        <Container maxWidth="md">
          <Stack direction="row" alignItems="center">
            <LogoIcon height={64} />
            <Text variant="text1" fontWeight={700} marginLeft={2}>
              builders patch
            </Text>
          </Stack>
        </Container>
      </Stack>

      <Container maxWidth="md">
        <Text variant="h1" data-testid="onboarding-page-header">
          Welcome to Builders Patch
        </Text>

        <Text
          marginTop={1}
          marginBottom={3}
          color={colors.gray80}
          data-testid="onboarding-page-subheader"
        >
          Before accessing the application form, set up your profile.
        </Text>

        <OnboardingForm
          handleSubmit={handleOnboardingFormSubmit}
          selectedCompany={preselectedCompany}
        />
      </Container>
    </div>
  );
};

export default Onboarding;

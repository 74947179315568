import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import IconButton from "components/IconButton";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import { FIELD_WIDTH } from "entities/Field/components/Textfield/constants";
import EditableField from "entities/Field/components/Textfield/EditableField";
import { CompanyFieldFormatType } from "entities/Field/constants";
import { fieldValueCreate } from "entities/Field/sdk";
import { formatFieldValue } from "entities/Field/utils";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";
import { handleInvalidMemoFieldRequest } from "entities/Memo/components/Fields/utils/utils";

interface ITextfield {
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
}

const Textfield = ({
  field,
  dealId,
  isNASection,
  externalToken,
  onUpdate,
}: ITextfield) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useCaching<string | number | null | undefined>(
    _.first(field.values)?.default_value
  );

  const navigate = useNavigate();

  const handleEditButtonClick = useCallback(() => setIsEdit(true), []);

  const handleChange = useCallback(
    (newValue: string | number | null | undefined) => {
      setValue(newValue);
    },
    [setValue]
  );

  const handleSave = useCallback(() => {
    fieldValueCreate({
      dealId,
      companyCustomFieldId: field.company_custom_field_id,
      value: _.isUndefined(value) ? "" : value,
    })
      .then(() => {
        onUpdate();
        setIsEdit(false);
        toast.successMessage(`${field.name} successfully updated!`);
      })
      .catch((error) =>
        handleInvalidMemoFieldRequest({ error, dealId, navigate })
      );
  }, [value, field, dealId, onUpdate, navigate]);

  const handleCancel = useCallback(() => {
    setValue(_.first(field.values)?.default_value);
    setIsSaveButtonDisabled(false);
    setIsEdit(false);
  }, [setValue, field.values]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showResetButton = useMemo(
    () => field.field_format_type === CompanyFieldFormatType.DATE,
    [field]
  );

  const showMissingTag = useMemo(
    () => !isNAField && !value && !isEdit,
    [isNAField, value, isEdit]
  );

  const showFieldValue = useMemo(
    () => !isNAField && !isEdit && value,
    [isNAField, value, isEdit]
  );

  const showEditButton = useMemo(
    () => !isNAField && !isEdit && _.isUndefined(externalToken),
    [isNAField, isEdit, externalToken]
  );

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{ borderBottom: `1px solid ${colors.blue40}` }}
    >
      <FieldTitle
        field={field}
        onUpdate={onUpdate}
        externalToken={externalToken}
        disabled={isNAField}
      />
      <Stack direction="row" spacing={1} alignItems="center" sx={{ flex: 1 }}>
        {showMissingTag && (
          <NoPrint>
            <Tag label="missing" variant="red" dataTestid="empty-field-value" />
          </NoPrint>
        )}
        {showFieldValue && (
          <Tooltip title={value}>
            <Text variant="text2" data-testid="field-value">
              {formatFieldValue({
                fieldValue: value,
                fieldFormatType: field.field_format_type,
              })}
            </Text>
          </Tooltip>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
        {showEditButton && (
          <NoPrint>
            <IconButton
              size="small"
              onClick={handleEditButtonClick}
              data-testid="field-edit-button"
            >
              <EditIcon />
            </IconButton>
          </NoPrint>
        )}
        {isEdit && (
          <Stack
            spacing={1}
            direction="row"
            alignItems="start"
            sx={{ paddingBottom: theme.spacing(0.5) }}
          >
            <Box sx={{ width: FIELD_WIDTH }}>
              <EditableField
                fieldFormatType={field.field_format_type}
                value={value}
                onChangeValue={handleChange}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              />
            </Box>
            <Button
              onClick={handleSave}
              data-testid="submit-button"
              disabled={isSaveButtonDisabled}
            >
              Save
            </Button>
            {showResetButton && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleReset}
                data-testid="reset-button"
              >
                Reset
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Textfield;

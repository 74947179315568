import { useCallback } from "react";
import _ from "lodash";

import { MoreVertIcon } from "icons";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import { MenuItem } from "components/Menu";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Tag from "components/Tag";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import AccessUpdateButton from "entities/Package/components/AccessUpdateButton";
import {
  deactivatePartialUserAccess,
  EPartialUserAccessType,
  IPartialUserAccess,
} from "entities/Package/sdk";

export interface IPartialUserAccessesTable<T> {
  partialUserAccesses: Array<IPartialUserAccess>;
  itemsCount: number;
  checklistName: string;
  onUpdate: () => void;
  checklistItems: Array<T>;
}

const PartialUserAccessesTable = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  checklistItems,
  itemsCount,
  checklistName,
  partialUserAccesses,
  onUpdate,
}: IPartialUserAccessesTable<T>) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleDeactivateAccess = useCallback(
    ({ userAccessId }: { userAccessId: number }) =>
      showConfirmationDialog({
        onConfirm: () =>
          deactivatePartialUserAccess({ userAccessId })
            .then(() => {
              toast.successMessage("User access deactivated.");
              onUpdate();
            })
            .catch((error) => {
              toast.errorMessage(error.message || DEFAULT_ERROR_MESSAGE);
            }),
        message: "Are you sure you want to deactivate this user's access?",
        confirmButtonText: "Deactivate",
      }),
    [onUpdate, showConfirmationDialog]
  );

  if (partialUserAccesses.length === 0) {
    return (
      <Text variant="labelSmall" data-testid="empty-state-message">
        There are no users with partial access to this checklist.
      </Text>
    );
  }

  return (
    <Table
      size="small"
      sx={{ border: `1px solid ${colors.blue20}` }}
      data-testid="table"
    >
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
              backgroundColor: colors.blue20,
              color: colors.black,
            },
          }}
          data-testid="table-headers-section"
        >
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Name
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Email
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Company
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Items Accessed
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Access Type
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Status
            </Text>
          </TableCell>
          <TableCell width={10} data-testid="header-actions-cell"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody data-testid="table-body-section">
        {partialUserAccesses.map((userAccess) => (
          <TableRow
            key={userAccess.id}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
            data-testid="partial-access-user-row"
          >
            <TableCell>
              <Tooltip
                title={`${userAccess.user.first_name} ${userAccess.user.last_name}`}
              >
                <Text variant="text2" data-testid="user-full-name">
                  {_.truncate(
                    `${userAccess.user.first_name} ${userAccess.user.last_name}`,
                    {
                      length: 18,
                    }
                  )}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={userAccess.user.email}>
                <Text variant="text2" data-testid="user-email">
                  {_.truncate(userAccess.user.email, {
                    length: 18,
                  })}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={userAccess.user.company.name}>
                <Text variant="text2" data-testid="user-company">
                  {_.truncate(userAccess.user.company.name, {
                    length: 18,
                  })}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Text variant="text2" data-testid="user-access-items">
                {userAccess.company_custom_fields.length} / {itemsCount}
              </Text>
            </TableCell>
            <TableCell>
              <Tag
                label={
                  userAccess.access_type === EPartialUserAccessType.READ_ONLY
                    ? "Read only"
                    : "Edit access"
                }
                sx={{ whiteSpace: "nowrap", display: "inline-block" }}
                dataTestid="user-access-type"
              />
            </TableCell>
            <TableCell>
              <Text variant="text3" data-testid="user-access-status">
                {userAccess.is_active ? "Active" : "Deactivated"}
              </Text>
            </TableCell>
            <TableCell>
              {userAccess.is_active && (
                <ButtonDropdown
                  icon={<MoreVertIcon />}
                  label=""
                  variant="text"
                  width="auto"
                  buttonDataTestid="user-actions-button"
                  keepMenuMounted
                >
                  <AccessUpdateButton
                    partialUserAccess={userAccess}
                    checklistName={checklistName}
                    checklistItems={checklistItems}
                    onUpdate={onUpdate}
                  />
                  <MenuItem
                    onClick={() =>
                      handleDeactivateAccess({ userAccessId: userAccess.id })
                    }
                    data-testid="action-option"
                  >
                    <Text variant="text2">Revoke access</Text>
                  </MenuItem>
                </ButtonDropdown>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PartialUserAccessesTable;

import React from "react";
import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton";

import { colors } from "theme/palette";

export interface IIconButton extends IconButtonProps {
  component?: React.ElementType;
  dataTestid?: string;
}

const IconButton: React.FC<IIconButton> = React.forwardRef(
  ({ sx, dataTestid, ...props }: IIconButton, ref) => (
    <MuiIconButton
      ref={ref}
      sx={{
        borderRadius: "6px",
        "&:hover": { opacity: 1, backgroundColor: colors.blue20 },
        ...sx,
      }}
      data-testid={dataTestid}
      {...props}
    />
  )
);

export default IconButton;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { ConnectedFieldsIcon } from "icons";
import theme from "theme";
import { handleInvalidRequest } from "utils/sdk";

import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
import Stack from "components/Stack";
import TextField from "components/TextField";
import { useLoadingBackdrop } from "components/useLoadingBackdrop";

import { FieldSelectionMethod } from "pages/Superuser/LinkedFields/constants";

import {
  getDealsAndLinkedFields,
  IDealLinkedPackage,
  useSuperuserCategoryList,
  useSuperuserFieldList,
  useSuperuserLinkIds,
  useSuperuserPackageList,
  useSuperuserSectionList,
  useSuperuserTabList,
} from "entities/Superuser/sdk";

import { getAutocompleteProps } from "./utils";

export interface IAddLinkedFieldFilters {
  fieldSelectionMethod: FieldSelectionMethod;
  onCheck: ({
    fieldId,
    linkId,
    dealLinkedPackages,
  }: {
    fieldId: number | null;
    linkId: string;
    dealLinkedPackages: IDealLinkedPackage[];
  }) => void;
  onRaiseError: () => void;
}

const AddLinkedFieldFilters = ({
  onCheck,
  onRaiseError,
  fieldSelectionMethod,
}: IAddLinkedFieldFilters) => {
  const [showLoading, hideLoading] = useLoadingBackdrop();
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(
    null
  );
  const [selectedTabId, setSelectedTabId] = useState<number | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
  const [linkId, setLinkId] = useState<string | null>(null);

  const { data: allLinkIds } = useSuperuserLinkIds();
  const { data: packages } = useSuperuserPackageList();
  const { data: tabs } = useSuperuserTabList({ packageId: selectedPackageId });
  const { data: categories } = useSuperuserCategoryList({
    packageId: selectedPackageId,
    tabId: selectedTabId,
  });
  const { data: sections } = useSuperuserSectionList({
    packageId: selectedPackageId,
    categoryId: selectedCategoryId,
  });
  const { data: fields } = useSuperuserFieldList({
    packageId: selectedPackageId,
    sectionId: selectedSectionId,
  });

  const searchPackageOptions = useMemo(
    () =>
      packages?.map((pack) => ({
        label: pack.name,
        id: pack.id,
        key: `package-${pack.id}-${pack.name}`,
      })) || [],
    [packages]
  );

  const searchTabOptions = useMemo(
    () =>
      tabs?.map((tab) => ({
        id: tab.id,
        label: tab.name,
        key: `tab-${tab.id}-${tab.name}`,
      })) || [],
    [tabs]
  );

  const searchCategoryOptions = useMemo(
    () =>
      categories?.map((category) => ({
        id: category.id,
        label: category.name,
        key: `category-${category.id}-${category.name}`,
      })) || [],
    [categories]
  );

  const searchSectionOptions = useMemo(
    () =>
      sections?.map((section) => ({
        id: section.id,
        label: section.name,
        key: `section-${section.id}-${section.name}`,
      })) || [],
    [sections]
  );

  const searchFieldOptions = useMemo(
    () =>
      fields?.map((field) => ({
        id: field.id,
        label: field.name,
        key: `field-${field.id}-${field.name}`,
        isLinked: field.is_linked,
      })) || [],
    [fields]
  );

  const handleRemoveField = useCallback(() => {
    setSelectedFieldId(null);
    setLinkId(null);
  }, []);

  const handleRemoveSection = useCallback(() => {
    setSelectedSectionId(null);
    handleRemoveField();
  }, [handleRemoveField]);

  const handleRemoveCategory = useCallback(() => {
    setSelectedCategoryId(null);
    handleRemoveSection();
  }, [handleRemoveSection]);

  const handleRemoveTab = useCallback(() => {
    setSelectedTabId(null);
    handleRemoveCategory();
  }, [handleRemoveCategory]);

  const handleRemovePackage = useCallback(() => {
    setSelectedPackageId(null);
    handleRemoveTab();
  }, [handleRemoveTab]);

  const handleSelectPackage = useCallback(
    ({ value }) => {
      setSelectedPackageId(value.id);
      handleRemoveTab();
    },
    [handleRemoveTab]
  );

  const handleSelectTab = useCallback(
    ({ value }) => {
      setSelectedTabId(value.id);
      handleRemoveCategory();
    },
    [handleRemoveCategory]
  );

  const handleSelectCategory = useCallback(
    ({ value }) => {
      setSelectedCategoryId(value.id);
      handleRemoveSection();
    },
    [handleRemoveSection]
  );

  const handleSelectSection = useCallback(
    ({ value }) => {
      setSelectedSectionId(value.id);
      handleRemoveField();
    },
    [handleRemoveField]
  );

  const handleSelectField = useCallback(({ value }) => {
    setSelectedFieldId(value.id);
    setLinkId(null);
  }, []);

  const handleGetLinkedFields = useCallback(() => {
    if (!_.isNull(selectedFieldId) && !_.isNull(linkId)) {
      showLoading();
      getDealsAndLinkedFields({
        fieldId: selectedFieldId,
        linkId,
      })
        .then((response) => {
          onCheck({
            dealLinkedPackages: response,
            fieldId: selectedFieldId,
            linkId,
          });
        })
        .catch((e) => {
          handleInvalidRequest(e);
          onRaiseError();
        })
        .finally(() => {
          hideLoading();
        });
    }
  }, [
    selectedFieldId,
    linkId,
    onCheck,
    onRaiseError,
    showLoading,
    hideLoading,
  ]);

  const tabAutocompleteIsDisabled = useMemo(
    () => _.isNull(selectedPackageId),
    [selectedPackageId]
  );

  const categoryAutocompleteIsDisabled = useMemo(
    () => _.isNull(selectedTabId),
    [selectedTabId]
  );

  const sectionAutocompleteIsDisabled = useMemo(
    () => _.isNull(selectedCategoryId),
    [selectedCategoryId]
  );

  const fieldAutocompleteIsDisabled = useMemo(
    () => _.isNull(selectedSectionId),
    [selectedSectionId]
  );

  const linkIdFieldIsDisabled = useMemo(
    () => _.isNull(selectedFieldId),
    [selectedFieldId]
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {fieldSelectionMethod === FieldSelectionMethod.FULL_FIELD_PATH ? (
        <>
          <Autocomplete
            {...getAutocompleteProps({
              options: searchPackageOptions,
              fieldLabel: "Checklist",
              onRemove: handleRemovePackage,
              onSelect: handleSelectPackage,
            })}
            value={
              searchPackageOptions.find(
                (option) => option.id === selectedPackageId
              ) || null
            }
          />
          <Autocomplete
            {...getAutocompleteProps({
              options: searchTabOptions,
              fieldLabel: "Tab",
              onRemove: handleRemoveTab,
              onSelect: handleSelectTab,
              disabled: tabAutocompleteIsDisabled,
            })}
            value={
              searchTabOptions.find((option) => option.id === selectedTabId) ||
              null
            }
          />
          <Autocomplete
            {...getAutocompleteProps({
              options: searchCategoryOptions,
              fieldLabel: "Category",
              onRemove: handleRemoveCategory,
              onSelect: handleSelectCategory,
              disabled: categoryAutocompleteIsDisabled,
            })}
            value={
              searchCategoryOptions.find(
                (option) => option.id === selectedCategoryId
              ) || null
            }
          />
          <Autocomplete
            {...getAutocompleteProps({
              options: searchSectionOptions,
              fieldLabel: "Section",
              onRemove: handleRemoveSection,
              onSelect: handleSelectSection,
              disabled: sectionAutocompleteIsDisabled,
            })}
            value={
              searchSectionOptions.find(
                (option) => option.id === selectedSectionId
              ) || null
            }
          />
          <Autocomplete
            {...getAutocompleteProps({
              options: searchFieldOptions,
              fieldLabel: "Field",
              onRemove: handleRemoveField,
              onSelect: handleSelectField,
              disabled: fieldAutocompleteIsDisabled,
            })}
            value={
              searchFieldOptions.find(
                (option) => option.id === selectedFieldId
              ) || null
            }
            renderOption={(props, option) => (
              <li {...props} key={option.key}>
                {option.label}
                {option.isLinked && (
                  <ConnectedFieldsIcon
                    style={{
                      height: "16px",
                      width: "16px",
                      cursor: "pointer",
                      alignSelf: "center",
                      display: "flex",
                      marginLeft: theme.spacing(1),
                    }}
                  />
                )}
              </li>
            )}
          />
        </>
      ) : (
        <TextField
          label="Field ID"
          onChange={(event) =>
            setSelectedFieldId(_.toNumber(event.target.value))
          }
        />
      )}
      <Autocomplete
        {...getAutocompleteProps({
          options:
            allLinkIds?.map((linkId) => ({
              id: linkId,
              label: linkId,
              key: linkId,
            })) || [],
          fieldLabel: "Link id",
          onRemove: () => setLinkId(null),
          onSelect: ({ value }) =>
            setLinkId(_.isNil(value) ? null : (value.id as string)),
          disabled: linkIdFieldIsDisabled,
        })}
        freeSolo
        filterOptions={(options, params) => {
          const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(params.inputValue.toLowerCase())
          );

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting) {
            filteredOptions.push({
              id: inputValue,
              key: inputValue,
              label: `Add "${inputValue}"`,
            });
          }

          return filteredOptions;
        }}
      />
      <Button onClick={handleGetLinkedFields} sx={{ width: "200px" }}>
        Get fields
      </Button>
    </Stack>
  );
};

export default AddLinkedFieldFilters;

import { ArrowDropDownIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import Text from "components/Text";

const MainNavDropdown = ({
  label,
  activeMenu,
  children,
  buttonDataTestid,
}: {
  label: string;
  activeMenu: boolean;
  children: React.ReactNode;
  buttonDataTestid?: string;
}) => {
  return (
    <ButtonDropdown
      variant="text"
      size="small"
      buttonDataTestid={buttonDataTestid}
      label={
        <Text
          variant="text3"
          sx={{
            color: colors.white,
            opacity: activeMenu ? 1 : 0.6,
            fontWeight: 700,
            transition: "opacity 200ms ease-out",
          }}
          data-testid="dropdown-label"
        >
          {label}
        </Text>
      }
      icon={
        <ArrowDropDownIcon
          fontSize="large"
          sx={{
            color: colors.white,
            opacity: activeMenu ? 1 : 0.6,
          }}
          data-testid="dropdown-arrow-icon"
        />
      }
      color="secondary"
      width="auto"
      sx={{
        padding: theme.spacing(0.5, 0.75, 0.5, 1.5),
        "& .MuiMenu-paper": {
          border: `1px solid ${colors.blue20}`,
        },
        "&:hover * ": { opacity: 1 },
      }}
    >
      {children}
    </ButtonDropdown>
  );
};

export default MainNavDropdown;

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

export interface IContentSeparator {
  label: string;
  alignActionButton?: "left" | "right";
  actionButton?: React.ReactNode | undefined;
  dataTestid?: string;
}

const ContentSeparator = ({
  label,
  alignActionButton = "left",
  actionButton = undefined,
  dataTestid = "section-name",
}: IContentSeparator) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={
        alignActionButton === "left" ? "flex-start" : "space-between"
      }
      sx={{
        backgroundColor: colors.blue10,
        padding: theme.spacing(0.5, 1),
        breakInside: "avoid",
      }}
    >
      <Text
        variant="text1"
        sx={{ fontWeight: "bold", display: "inline" }}
        data-testid={dataTestid}
      >
        {label}
      </Text>
      {actionButton && <Box sx={{ display: "inline" }}>{actionButton}</Box>}
    </Stack>
  );
};

export default ContentSeparator;

import { useCallback } from "react";

import { MessageOutlinedIcon } from "icons";
import { colors } from "theme/palette";

import Badge from "components/Badge";
import toast from "components/Toast";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { fetchTaskChannels } from "entities/Task/sdk";

export interface IAllTasksChatButton {
  messageCount: number;
  taskChannelId: number;
  dealId: number;
  refreshTasksList: () => void;
}

const AllTasksChatButton = ({
  messageCount,
  taskChannelId,
  dealId,
  refreshTasksList,
}: IAllTasksChatButton) => {
  const { show } = useDealSidepanel();

  const handleOpenTaskChat = useCallback(() => {
    fetchTaskChannels({ dealId })
      .then((response) => {
        const taskChannel = response.find(
          (taskChannel) => taskChannel.id === taskChannelId
        );
        show({
          meta: {
            tab: TABS.TASKS,
            subTab: TASKS_TAB_SUBTABS.CHAT,
            taskChannel: taskChannel,
            dealId,
            updateParent: refreshTasksList,
          },
        });
      })
      .catch(() => {
        toast.errorMessage(
          "There was an error fetching messages for this task."
        );
      });
  }, [taskChannelId, dealId, show, refreshTasksList]);

  return (
    <Badge
      badgeContent={messageCount}
      color="primary"
      onClick={handleOpenTaskChat}
      sx={{ cursor: "pointer" }}
    >
      <MessageOutlinedIcon
        sx={{ color: colors.gray60, width: "20px", height: "20px" }}
      />
    </Badge>
  );
};

export default AllTasksChatButton;

import { useCallback, useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Grid from "components/Grid";
import Stack from "components/Stack";
import Text from "components/Text";
import TreeSelect from "components/TreeSelect";

import {
  extractItemsWithChildren,
  extractItemsWithoutChildren,
  getItemsWithoutChildrenCount,
} from "./utils";

interface IChecklistSelectionStep<T> {
  items: Array<T>;
  selectedItemIds: Array<number>;
  setSelectedItemIds: (arg0: any) => void;
  openItemIds: Array<number>;
  setOpenItemIds: (arg0: any) => void;
  checklistName: string | React.ReactNode;
}

const ChecklistSelectionStep = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  items,
  openItemIds,
  setOpenItemIds,
  selectedItemIds,
  setSelectedItemIds,
  checklistName,
}: IChecklistSelectionStep<T>) => {
  const itemsWithoutChildrenCount = useMemo(
    () => getItemsWithoutChildrenCount({ items }),
    [items]
  );

  const handleSelectAll = useCallback(() => {
    const children = items.flatMap((item: T) =>
      extractItemsWithoutChildren<T>({ item })
    );

    const childrenIds: Array<number> = children.map((child) => child.id);

    setSelectedItemIds(childrenIds);
  }, [items, setSelectedItemIds]);

  const handleDeselectAll = useCallback(() => {
    setSelectedItemIds([]);
  }, [setSelectedItemIds]);

  const handleOpenAll = useCallback(() => {
    const itemsWithChildren = items.flatMap((item: T) =>
      extractItemsWithChildren<T>({ item })
    );

    setOpenItemIds(itemsWithChildren.map((item) => item.id));
  }, [items, setOpenItemIds]);

  const handleCollapseAll = useCallback(() => {
    setOpenItemIds([]);
  }, [setOpenItemIds]);

  return (
    <Grid container data-testid="checklist-items-tab-content">
      <Grid
        item
        md={8}
        height={370}
        maxHeight={370}
        padding={2}
        borderRadius={2}
        sx={{
          overflowY: "auto",
          backgroundColor: colors.blue5,
        }}
        data-testid="checklist-items-tree-section"
      >
        <Text variant="text1" fontWeight={700} data-testid="checklist-name">
          {checklistName}
        </Text>
        <Text
          variant="text3"
          color={colors.gray80}
          marginBottom={3}
          data-testid="checklist-total-fields-to-share"
        >
          Total {itemsWithoutChildrenCount} items in this checklist
        </Text>

        <TreeSelect
          items={items}
          openItemIds={openItemIds}
          setOpenItemIds={setOpenItemIds}
          selectedItemIds={selectedItemIds}
          setSelectedItemIds={setSelectedItemIds}
        />
      </Grid>

      <Grid item md={4} paddingLeft={3} height={370}>
        <Stack justifyContent="space-between" height="100%">
          <Stack spacing={3} data-testid="checklist-items-quick-action-section">
            <Stack
              spacing={0.5}
              alignItems="flex-start"
              data-testid="select-items-buttons"
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSelectAll}
                data-testid="select-all-button"
              >
                Select all
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDeselectAll}
                data-testid="deselect-all-button"
              >
                De-select all
              </Button>
            </Stack>

            <Stack
              spacing={0.5}
              alignItems="flex-start"
              data-testid="checklist-tree-action-buttons"
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleOpenAll}
                data-testid="open-all-button"
              >
                Open all
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCollapseAll}
                data-testid="collapse-all-button"
              >
                Collapse all
              </Button>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={theme.spacing(0.4)}
            data-testid="checklist-fields-selected-info-section"
          >
            <Text
              variant="text3"
              fontWeight={700}
              data-testid="fields-selected-count"
            >
              {selectedItemIds.length} items
            </Text>
            <Text
              variant="text3"
              color={colors.gray60}
              data-testid="fields-total-count"
            >
              out of {itemsWithoutChildrenCount} selected
            </Text>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ChecklistSelectionStep;

import { ForumOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

const EmptyComment = () => {
  return (
    <Stack
      spacing={4}
      direction="row"
      alignItems="center"
      sx={{ paddingTop: theme.spacing(2) }}
    >
      <ForumOutlinedIcon
        sx={{ width: "140px", height: "140px", color: colors.gray40 }}
      />
      <Stack spacing={1}>
        <Text variant="text1" fontWeight={700}>
          Start a comment thread here!
        </Text>
        <Text variant="text2" color={colors.gray80}>
          Anyone with edit access to this document can leave comments here. You
          and your team mates will get notified of all new comments added.
        </Text>
      </Stack>
    </Stack>
  );
};

export default EmptyComment;

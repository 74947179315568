import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";

import { notImplementedError } from "utils/common";

import FeatureLimitDialog from "components/FeatureLimitDialog";
import { EFeatureLimitItemsType } from "components/FeatureLimitDialog/constants";

const FeatureLimitContext = React.createContext({
  show: notImplementedError,
  hide: notImplementedError,
  setItems: notImplementedError as Dispatch<
    SetStateAction<EFeatureLimitItemsType>
  >,
  setIsDealOwner: notImplementedError as Dispatch<SetStateAction<boolean>>,
  setOnClose: notImplementedError as Dispatch<SetStateAction<() => void>>,
  setDealCompanyName: notImplementedError as Dispatch<
    SetStateAction<string | undefined>
  >,
});

interface IuseFeatureLimitArgs {
  isDealOwner: boolean;
  items: EFeatureLimitItemsType;
  dealCompanyName?: string;
  onClose?: () => void;
}

export interface IFeatureLimitContextProvider {
  children: React.ReactNode;
}

export const FeatureLimitContextProvider = ({
  children,
}: IFeatureLimitContextProvider) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState<EFeatureLimitItemsType>(
    EFeatureLimitItemsType.DEALS
  );
  const [isDealOwner, setIsDealOwner] = useState(true);
  const [dealCompanyName, setDealCompanyName] = useState<string | undefined>(
    undefined
  );

  const [onClose, setOnClose] = useState(() => () => {});

  const show = useCallback(() => {
    setIsOpen(true);
  }, []);

  const hide = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <FeatureLimitContext.Provider
      value={{
        show,
        hide,
        setItems,
        setIsDealOwner,
        setDealCompanyName,
        setOnClose,
      }}
    >
      {children}
      <FeatureLimitDialog
        isDealOwner={isDealOwner}
        dealCompanyName={dealCompanyName}
        isOpen={isOpen}
        onClose={onClose}
        items={items}
      />
    </FeatureLimitContext.Provider>
  );
};

export const useFeatureLimitDialog = () => {
  const {
    show: showDialog,
    hide,
    setItems,
    setIsDealOwner,
    setDealCompanyName,
    setOnClose,
  } = useContext(FeatureLimitContext);

  const show = useCallback(
    ({
      isDealOwner,
      dealCompanyName,
      items = EFeatureLimitItemsType.DEALS,
      onClose = hide,
    }: IuseFeatureLimitArgs) => {
      setIsDealOwner(isDealOwner);
      setDealCompanyName(dealCompanyName);
      setItems(items);
      setOnClose(() => onClose);
      showDialog();
    },
    [hide, setItems, setIsDealOwner, setDealCompanyName, setOnClose, showDialog]
  );

  return { show, hide };
};

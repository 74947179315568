import { useCallback } from "react";

import { colors } from "theme/palette";

import { TableCell } from "components/Table";
import Text from "components/Text";
import TextField from "components/TextField";

interface ICell {
  cellKey: string;
  value: string;
  colSpan?: number;
  editable?: boolean;
  textAlign?: "left" | "right" | "center";
  onChangeCellValue?: ({ value, key }: { value: string; key: string }) => void;
  dataTestid?: string;
  textStyle?: { [key: string]: string | number } | null;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTableCellElement>) => void;
  isCurrentlyActive?: boolean;
  manageCellActiveStatus?: ({ activate }: { activate: boolean }) => void;
}

const Cell = ({
  cellKey: key,
  value,
  editable,
  colSpan,
  textAlign = "left",
  onChangeCellValue,
  dataTestid,
  textStyle,
  onKeyDown,
  isCurrentlyActive,
  manageCellActiveStatus,
}: ICell) => {
  const handleDoubleClick = useCallback(() => {
    if (editable) {
      manageCellActiveStatus &&
        manageCellActiveStatus({
          activate: true,
        });
    }
  }, [editable, manageCellActiveStatus]);

  const handleSave = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChangeCellValue &&
        onChangeCellValue({ value: event.target.value, key });
    },
    [onChangeCellValue, key]
  );

  const onTabClick = useCallback(
    (event: React.KeyboardEvent<HTMLTableCellElement>) => {
      if (event.key === "Tab") {
        event.preventDefault();
        onKeyDown && onKeyDown(event);
      }
    },
    [onKeyDown]
  );

  return (
    <TableCell
      key={key}
      colSpan={colSpan}
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
      align={textAlign}
      onDoubleClick={handleDoubleClick}
      onKeyDown={onTabClick}
      onBlur={() =>
        manageCellActiveStatus && manageCellActiveStatus({ activate: false })
      }
      data-testid="table-column-cell"
      data-test-id={`${key.toLowerCase().replaceAll(" ", "-")}-cell`}
    >
      {!isCurrentlyActive && (
        <Text variant="text2" data-testid="cell-value" sx={textStyle}>
          {value}
        </Text>
      )}
      {editable && isCurrentlyActive && (
        <TextField
          size="small"
          autoFocus
          defaultValue={value}
          onChange={handleSave}
          data-testid="edit-data-cell-value-field"
          sx={{ minWidth: 300 }}
        />
      )}
    </TableCell>
  );
};

export default Cell;

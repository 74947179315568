import Box from "components/Box";

import { IDeal } from "entities/Deal/sdk";
import DDSection from "entities/Memo/components/DDSection";
import SectionAISummaryResponse from "entities/Memo/components/Sections/AISummaryResponse";
import SectionComment from "entities/Memo/components/Sections/Comment";
import SectionCommunityAmenities from "entities/Memo/components/Sections/CommunityAmenities";
import SectionExcelTable from "entities/Memo/components/Sections/ExcelTable";
import SectionHeader from "entities/Memo/components/Sections/Header";
import SectionImage from "entities/Memo/components/Sections/Image";
import SectionSchedule from "entities/Memo/components/Sections/Schedule";
import SectionDevelopmentBudget from "entities/Memo/components/Sections/SectionDevelopmentBudget";
import SectionFloorArea from "entities/Memo/components/Sections/SectionFloorArea";
import SectionIncomeAndOpex from "entities/Memo/components/Sections/SectionIncomeAndOpex";
import SectionLocation from "entities/Memo/components/Sections/SectionLocation";
import SectionProjectDetails from "entities/Memo/components/Sections/SectionProjectDetails";
import SectionProjectNarrative from "entities/Memo/components/Sections/SectionProjectNarrative";
import SectionUnitMixAndRent from "entities/Memo/components/Sections/SectionUnitMixAndRent";
import SectionSourceAndUses from "entities/Memo/components/Sections/SourcesAndUses";
import SectionSummary from "entities/Memo/components/Sections/Summary";
import SectionTeam from "entities/Memo/components/Sections/Team";
import SectionUnitAmenities from "entities/Memo/components/Sections/UnitAmenities";
import { IMemoSection } from "entities/Memo/sdk";
import { EMemoSectionType } from "entities/MemoTemplate/constants";

interface IMemoSectionWrapper {
  section: IMemoSection;
  deal: IDeal;
  externalToken?: string;
  onUpdate: () => void;
}

const Section = ({
  section,
  deal,
  externalToken,
  onUpdate,
}: IMemoSectionWrapper) => {
  switch (section.section_type) {
    case EMemoSectionType.HEADER:
      return (
        <SectionHeader
          dealId={deal.id}
          section={section}
          onUpdate={onUpdate}
          externalToken={externalToken}
        />
      );
    case EMemoSectionType.DUE_DILIGENCE:
      return (
        <DDSection
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
          dealId={deal.id}
        />
      );
    case EMemoSectionType.PROJECT_DETAILS:
      return (
        <SectionProjectDetails
          deal={deal}
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.LOCATION:
      return (
        <SectionLocation
          deal={deal}
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.EXECUTIVE_SUMMARY:
      return (
        <SectionSummary
          category="Location Descriptions"
          dealId={deal.id}
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.RISKS_AND_MITIGATIONS:
      return (
        <SectionSummary
          category="Risks and Mitigating Factors"
          dealId={deal.id}
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.FINAL_RECOMMENDATIONS:
      return (
        <SectionSummary
          category="Final Recommendation"
          dealId={deal.id}
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.PROJECT_NARRATIVE:
      return (
        <SectionProjectNarrative
          deal={deal}
          section={section}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.SOURCE_AND_USES:
      return (
        <SectionSourceAndUses
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.UNIT_MIX_AND_RENT:
      return (
        <SectionUnitMixAndRent
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.FLOOR_AREA:
      return (
        <SectionFloorArea
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.INCOME_AND_OPEX:
      return (
        <SectionIncomeAndOpex
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.SCHEDULE:
      return (
        <SectionSchedule
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.UNIT_AMENITIES:
      return (
        <SectionUnitAmenities
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.COMMUNITY_AMENITIES:
      return (
        <SectionCommunityAmenities
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.DEVELOPMENT_BUDGET:
      return (
        <SectionDevelopmentBudget
          dealId={deal.id}
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.DEVELOPER:
      return (
        <SectionTeam
          dealId={deal.id}
          groupName="Developer"
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.ARCHITECT:
      return (
        <SectionTeam
          dealId={deal.id}
          groupName="Architect"
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.GENERAL_CONTRACTOR:
      return (
        <SectionTeam
          dealId={deal.id}
          groupName="General Contractor"
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.PROPERTY_MANAGER:
      return (
        <SectionTeam
          dealId={deal.id}
          groupName="Property Manager"
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.DEVELOPER_PARTNER:
      return (
        <SectionTeam
          dealId={deal.id}
          groupName="Developer Partner"
          externalToken={externalToken}
          section={section}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.IMAGE:
      return (
        <SectionImage
          section={section}
          dealId={deal.id}
          onUpdate={onUpdate}
          externalToken={externalToken}
        />
      );
    case EMemoSectionType.COMMENT:
      return (
        <SectionComment
          section={section}
          dealId={deal.id}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.EXCEL_TABLE:
      return (
        <SectionExcelTable
          section={section}
          dealId={deal.id}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
    case EMemoSectionType.AI_SUMMARY_RESPONSE:
      return (
        <SectionAISummaryResponse
          section={section}
          dealId={deal.id}
          externalToken={externalToken}
          onUpdate={onUpdate}
        />
      );
  }
  return null;
};

const MemoSectionWrapper = ({
  section,
  deal,
  externalToken,
  onUpdate,
}: IMemoSectionWrapper) => (
  <Box
    sx={{
      breakBefore: section.break_before ? "page" : "auto",
      "& .MuiIconButton-root": { visibility: "hidden" },
      "&:hover .MuiIconButton-root": {
        visibility: "visible",
      },
    }}
    data-testid="memo-section"
  >
    <Section
      section={section}
      deal={deal}
      onUpdate={onUpdate}
      externalToken={externalToken}
    />
  </Box>
);

export default MemoSectionWrapper;

import OverviewUnitAmenities from "entities/Deal/components/OverviewUnitAmenities";
import { IMemoSection } from "entities/Memo/sdk";

const SectionUnitAmenities = ({
  dealId,
  externalToken,
  section,
  onUpdate,
}: {
  dealId: number;
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}) => (
  <OverviewUnitAmenities
    dealId={dealId}
    externalToken={externalToken}
    section={section}
    onUpdate={onUpdate}
  />
);

export default SectionUnitAmenities;

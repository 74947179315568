import _ from "lodash";

import { URLS } from "config/urls";
import { ArticleOutlinedIcon, LaunchIcon, MoreVertIcon } from "icons";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import ButtonDropdown from "components/ButtonDropdown";
import IconButton from "components/IconButton";
import { MenuItem } from "components/Menu";
import NavLink from "components/NavLink";
import { ScrollableTableCellVariant } from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import ChangeStatusButton, {
  EChangeStatusButtonVariant,
} from "entities/Application/components/ChangeStatusButton";
import MoveToUnderwritingMenuItem from "entities/Application/components/MoveToUnderwritingMenuItem";

import DiscardFromListButton from "./components/DiscardFromListButton";

export const APPLICATIONS_LIST_LIMIT = 50;

export enum APPLICATION_STATUS {
  DRAFT = "Draft",
  ARCHIVED = "Archived",
  SUBMITTED = "Submitted",
  REJECTED = "Rejected",
  APPROVED = "Approved",
}

export const HEADERS: IScrollableTableHeader = [
  {
    label: "Intake",
    sticky: true,
    children: [
      {
        label: "Intake name",
        key: "name",
        width: "500px",
        render: ({ row, key }) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            flex={1}
          >
            <Text variant="text3" fontWeight={700}>
              {row[key]}
            </Text>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Text variant="labelSmall" whiteSpace="nowrap" height="10px">
                {row.code}
              </Text>

              <NavLink
                to={reverse(URLS.APPLICATION_EDIT, { applicationId: row.id })}
              >
                <Tooltip title="Intake page" placement="top">
                  <IconButton
                    sx={{
                      borderRadius: "2px",
                      backgroundColor: colors.blue10,
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <ArticleOutlinedIcon style={{ width: 15, height: 15 }} />
                  </IconButton>
                </Tooltip>
              </NavLink>

              <DiscardFromListButton application={row} />
            </Stack>
          </Stack>
        ),
      },
    ],
  },
  {
    label: "Status",
    children: [
      {
        label: "Status",
        key: "status",
        sortable: true,
        variant: ScrollableTableCellVariant.TEXT,
      },
      {
        label: "Last saved",
        key: "updated_at",
        width: "150px",
        variant: ScrollableTableCellVariant.DATE_TIME,
      },
      {
        label: "Submitted on",
        key: "submitted_at",
        width: "150px",
        variant: ScrollableTableCellVariant.DATE_TIME,
      },
    ],
  },
];

export const LENDER_HEADERS: IScrollableTableHeader = [
  {
    label: "Intake",
    sticky: true,
    children: [
      {
        label: "Intake name",
        key: "name",
        width: "500px",
        render: ({ row, key }) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            flex={1}
          >
            <Text variant="text3" fontWeight={700}>
              {row[key]}
            </Text>

            <Stack direction="row" spacing={1} alignItems="center">
              <Text variant="labelSmall" whiteSpace="nowrap" height="10px">
                {row.code}
              </Text>

              <NavLink
                to={reverse(URLS.APPLICATION_PREVIEW, {
                  applicationId: row.id,
                })}
              >
                <Tooltip title="Intake page" placement="top">
                  <IconButton
                    sx={{
                      borderRadius: "2px",
                      backgroundColor: colors.blue10,
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <ArticleOutlinedIcon style={{ width: 15, height: 15 }} />
                  </IconButton>
                </Tooltip>
              </NavLink>
              {row.status === APPLICATION_STATUS.APPROVED &&
                !_.isNull(row.deal) && (
                  <NavLink
                    to={reverse(URLS.DEAL_OVERVIEW, {
                      dealId: row.deal.id,
                    })}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "2px",
                        backgroundColor: colors.blue10,
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <LaunchIcon style={{ width: 15, height: 15 }} />
                    </IconButton>
                  </NavLink>
                )}
              {[
                APPLICATION_STATUS.SUBMITTED,
                APPLICATION_STATUS.ARCHIVED,
                APPLICATION_STATUS.REJECTED,
              ].includes(row.status) && (
                <ButtonDropdown
                  variant="text"
                  label={<MoreVertIcon sx={{ width: 15, height: 15 }} />}
                  size="small"
                  sx={{ padding: 0, width: "20px", height: "20px" }}
                  keepMenuMounted
                >
                  <MoveToUnderwritingMenuItem
                    applicationRefetch={row.refetch}
                    applicationId={row.id}
                    projectName={row.project_name || ""}
                    numberOfUnits={row.number_of_units}
                    buttonRender={(props) => (
                      <MenuItem {...props}>Move to deal pipeline</MenuItem>
                    )}
                  />
                  {row.status === APPLICATION_STATUS.SUBMITTED && (
                    <span>
                      <ChangeStatusButton
                        applicationName={row.name}
                        applicationId={row.id}
                        currentStatus={row.status}
                        newStatus={APPLICATION_STATUS.ARCHIVED}
                        label="Archive"
                        variant={EChangeStatusButtonVariant.MENU_ITEM}
                      />
                      <ChangeStatusButton
                        applicationName={row.name}
                        applicationId={row.id}
                        currentStatus={row.status}
                        newStatus={APPLICATION_STATUS.REJECTED}
                        label="Inactivate"
                        variant={EChangeStatusButtonVariant.MENU_ITEM}
                      />
                    </span>
                  )}
                  {row.status === APPLICATION_STATUS.ARCHIVED && (
                    <span>
                      <ChangeStatusButton
                        applicationName={row.name}
                        applicationId={row.id}
                        currentStatus={row.status}
                        newStatus={APPLICATION_STATUS.SUBMITTED}
                        label="Unarchive"
                        variant={EChangeStatusButtonVariant.MENU_ITEM}
                      />
                      <ChangeStatusButton
                        applicationName={row.name}
                        applicationId={row.id}
                        currentStatus={row.status}
                        newStatus={APPLICATION_STATUS.REJECTED}
                        label="Inactivate"
                        variant={EChangeStatusButtonVariant.MENU_ITEM}
                      />
                    </span>
                  )}
                  {row.status === APPLICATION_STATUS.REJECTED && (
                    <>
                      <ChangeStatusButton
                        applicationName={row.name}
                        applicationId={row.id}
                        currentStatus={row.status}
                        newStatus={APPLICATION_STATUS.ARCHIVED}
                        label="Archive"
                        variant={EChangeStatusButtonVariant.MENU_ITEM}
                      />
                      <ChangeStatusButton
                        applicationName={row.name}
                        applicationId={row.id}
                        currentStatus={row.status}
                        newStatus={APPLICATION_STATUS.SUBMITTED}
                        label="Move to active"
                        variant={EChangeStatusButtonVariant.MENU_ITEM}
                      />
                    </>
                  )}
                </ButtonDropdown>
              )}
            </Stack>
          </Stack>
        ),
      },
    ],
  },
  {
    label: "Borrower",
    children: [
      {
        label: "Submitted by",
        key: "submitted_by",
        width: "130px",
        render: ({ row, key }) => (
          <Tooltip title={`${row[key].first_name} ${row[key].last_name}`}>
            <Text variant="text3" noWrap>
              {row[key].first_name} {row[key].last_name}
            </Text>
          </Tooltip>
        ),
      },
      {
        label: "Company",
        key: "company.name",
        width: "130px",
        variant: ScrollableTableCellVariant.TEXT,
      },
    ],
  },
  {
    label: "Intake details",
    children: [
      {
        label: "Project name",
        key: "project_name",
        width: "160px",
        render: ({ row, key }) => (
          <Tooltip title={row[key] || "-"}>
            <Text variant="text3" noWrap>
              {row[key] || "-"}
            </Text>
          </Tooltip>
        ),
        variant: ScrollableTableCellVariant.TEXT,
      },
      {
        label: "Loan program",
        key: "token.name",
        width: "160px",
        variant: ScrollableTableCellVariant.TEXT,
      },
      {
        label: "Submitted on",
        key: "submitted_at",
        width: "130px",
        sortable: true,
        variant: ScrollableTableCellVariant.DATE_TIME,
      },
    ],
  },
  {
    label: "Address",
    children: [
      {
        label: "Address",
        key: "address.address",
        width: "160px",
        variant: ScrollableTableCellVariant.TEXT,
      },
      {
        label: "City",
        key: "address.city",
        width: "160px",
        variant: ScrollableTableCellVariant.TEXT,
      },
      {
        label: "State",
        key: "address.state",
        width: "160px",
        variant: ScrollableTableCellVariant.TEXT,
      },
      {
        label: "Zipcode",
        key: "address.zipcode",
        width: "160px",
        variant: ScrollableTableCellVariant.TEXT,
      },
    ],
  },
];

export const EXPORT_HEADERS = [
  { label: "Intake name", key: "name" },
  { label: "Intake code", key: "code" },
  { label: "Status", key: "status" },
  { label: "Last saved", key: "updated_at" },
  { label: "Submitted on", key: "submitted_at" },
];

export const LENDER_EXPORT_HEADERS = [
  { label: "Intake name", key: "name" },
  { label: "Intake code", key: "code" },
  { label: "Submitted by", key: "submitted_by_full_name" },
  { label: "Company", key: "company.name" },
  { label: "Project name", key: "project_name" },
  { label: "Loan program", key: "token.name" },
  { label: "Submitted on", key: "submitted_at" },
  { label: "Address", key: "address.address" },
  { label: "City", key: "address.city" },
  { label: "State", key: "address.state" },
  { label: "Zipcode", key: "address.zipcode" },
];

import { useEffect } from "react";
import _ from "lodash";

import { ENV_NAME, Environment } from "utils/constants";

import { useSignedUser } from "entities/Auth/sdk";

const PendoWidget = () => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (ENV_NAME === Environment.Production && !_.isNil(user)) {
      window.initPendo("4d4b2cef-8fc9-40f3-775c-d3b92783581e");
      window.pendo.initialize({
        visitor: {
          id: `prod_${user.id}`,
          email: user.email,
          full_name: user.first_name,
        },
        account: {
          id: user.company_id,
        },
      });
    }
  }, [user]);

  return null;
};

export default PendoWidget;

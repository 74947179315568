import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { MoreVertIcon } from "icons";
import { useCaching } from "utils/common";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import Text from "components/Text";

import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import { useDealChecklistPermission } from "entities/Deal/sdk";
import FieldHistoryDialog from "entities/Field/components/FieldHistoryDialog";
import FilesDialog from "entities/Field/components/Filefield/FilesDialog";
import { IField } from "entities/Field/sdk";
import ShareWithButton from "entities/Package/components/ShareWithButton";
import { fieldUpdateNAStatusBulk } from "entities/Package/sdk";
import DueDiligenceFieldNewTaskButton from "entities/Task/components/DueDiligenceFieldNewTaskButton";

interface IFilesMenuButton {
  field: IField;
  packageId: number;
  dealId: number;
  categoryId: number;
  sectionId: number;
  tabId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  menuButtonDataTestid?: string;
}

const FilesMenuButton: React.FC<IFilesMenuButton> = ({
  dealId,
  packageId,
  field,
  categoryId,
  sectionId,
  tabId,
  onUpdate,
  menuButtonDataTestid,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useCaching(field.n_a_status || false);

  const [showValuesHistoryDialog, setShowValuesHistoryDialog] =
    useState<boolean>(false);

  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });
  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });
  const hasFullAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  const handleMarkAsNA = useCallback(
    () =>
      fieldUpdateNAStatusBulk({
        dealId,
        fieldIds: [field.field_id],
        naStatus: !field.n_a_status,
      }).then(() => {
        setStatus(!field.n_a_status);
        onUpdate({
          message: `The field was successfully ${
            !field.n_a_status ? "disabled" : "enabled"
          }!`,
        });
      }),
    [dealId, field, setStatus, onUpdate]
  );

  const handleOpenSettings = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onUpdate({});
  }, [setIsOpen, onUpdate]);

  if (!hasFullAccess) {
    return null;
  }

  return (
    <>
      <ButtonDropdown
        variant="text"
        label={<MoreVertIcon />}
        width="auto"
        size="small"
        keepMenuMounted
        sx={{ padding: "2px" }}
        buttonDataTestid={menuButtonDataTestid}
      >
        <GroupLabel label="history" />
        <MenuItem
          onClick={() => setShowValuesHistoryDialog(true)}
          dataTestid="action-menu-option"
        >
          <Text variant="text2" data-testid="action-menu-option-name">
            Show field values history
          </Text>
        </MenuItem>
        <GroupLabel label="status" />
        <MenuItem onClick={handleMarkAsNA} dataTestid="action-menu-option">
          <Text variant="text2" data-testid="action-menu-option-name">
            {status ? "Revoke N/A status" : "Mark as N/A"}
          </Text>
        </MenuItem>
        {loggedUserIsDealAdmin && (
          <span>
            <GroupLabel label="share settings" />
            <ShareWithButton initiallySelectedItemIds={[field.field_id]} />
          </span>
        )}
        <GroupLabel label="tasks" />
        <DueDiligenceFieldNewTaskButton
          dealId={dealId}
          companyField={field}
          packageId={packageId}
          tabId={tabId}
          categoryId={categoryId}
          sectionId={sectionId}
          menuOptionDataTestid="action-menu-option"
        />
        <GroupLabel label="settings" />
        <MenuItem onClick={handleOpenSettings} dataTestid="action-menu-option">
          <Text variant="text2" data-testid="action-menu-option-name">
            Settings & instructions
          </Text>
        </MenuItem>
      </ButtonDropdown>
      {isOpen && (
        <FilesDialog
          open={isOpen}
          handleClose={handleClose}
          dealId={dealId}
          packageId={packageId}
          fieldId={field.field_id}
        />
      )}
      {showValuesHistoryDialog && (
        <FieldHistoryDialog
          dealId={dealId}
          field={field}
          onClose={() => setShowValuesHistoryDialog(false)}
        />
      )}
    </>
  );
};

export default FilesMenuButton;

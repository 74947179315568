import _ from "lodash";

import { MoreVertIcon } from "icons";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Tag from "components/Tag";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import ShareTokenCancelInvitationButton from "entities/Package/components/ShareTokenCancelInvitationButton";
import ShareTokenResendInvitationButton from "entities/Package/components/ShareTokenResendInvitationButton";
import ShareTokenUpdateButton from "entities/Package/components/ShareTokenUpdateButton";
import {
  EPartialUserAccessType,
  EShareTokenStatus,
  IShareTokenList,
} from "entities/Package/sdk";

export interface IPartialUserAccessesTable<T> {
  checklistItems: Array<{
    id: number;
    name: string;
    children: Array<T>;
    isField: boolean;
  }>;
  itemsCount: number;
  shareTokens: Array<IShareTokenList>;
  checklistName: string;
  onUpdate: () => void;
}

const ShareTokensTable = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  shareTokens,
  itemsCount,
  checklistItems,
  checklistName,
  onUpdate,
}: IPartialUserAccessesTable<T>) => {
  if (shareTokens.length === 0) {
    return (
      <Text variant="labelSmall" data-testid="empty-state-message">
        No users have been invited.
      </Text>
    );
  }
  return (
    <Table
      size="small"
      sx={{ border: `1px solid ${colors.blue20}` }}
      data-testid="table"
    >
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
              backgroundColor: colors.blue20,
              color: colors.black,
            },
          }}
          data-testid="table-headers-section"
        >
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Email
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Items Accessed
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Access Type
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Status
            </Text>
          </TableCell>
          <TableCell width={10} data-testid="header-actions-cell"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody data-testid="table-body-section">
        {shareTokens.map((shareToken) => (
          <TableRow
            key={shareToken.id}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
            data-testid="invited-user-row"
          >
            <TableCell>
              <Tooltip title={shareToken.email}>
                <Text variant="text2" data-testid="user-email">
                  {_.truncate(shareToken.email, {
                    length: 18,
                  })}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Text variant="text3" data-testid="user-shared-items">
                {shareToken.fields.length} / {itemsCount}
              </Text>
            </TableCell>
            <TableCell>
              <Tag
                label={
                  shareToken.access_type === EPartialUserAccessType.READ_ONLY
                    ? "Read only"
                    : "Edit access"
                }
                sx={{ whiteSpace: "nowrap", display: "inline-block" }}
                dataTestid="user-access-type"
              />
            </TableCell>
            <TableCell>
              <Text variant="text3" data-testid="invitation-status">
                {shareToken.status}
              </Text>
            </TableCell>
            <TableCell>
              {shareToken.status !== EShareTokenStatus.REJECTED && (
                <ButtonDropdown
                  icon={<MoreVertIcon />}
                  label=""
                  variant="text"
                  width="auto"
                  buttonDataTestid="invitation-actions-button"
                  keepMenuMounted
                >
                  <ShareTokenUpdateButton
                    checklistItems={checklistItems}
                    checklistName={checklistName}
                    onUpdate={onUpdate}
                    shareToken={shareToken}
                  />
                  {shareToken.status === EShareTokenStatus.PENDING && (
                    <>
                      <ShareTokenResendInvitationButton
                        email={shareToken.email}
                        shareTokenId={shareToken.id}
                        onUpdate={onUpdate}
                      />
                      <ShareTokenCancelInvitationButton
                        shareTokenId={shareToken.id}
                        onUpdate={onUpdate}
                      />
                    </>
                  )}
                </ButtonDropdown>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ShareTokensTable;

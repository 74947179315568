import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { EditIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import CommunityAmenitiesEditForm from "entities/Deal/components/OverviewCommunityAmenities/CommunityAmenitiesEditForm";
import {
  IDealCommunityAmenities,
  updateCommunityAmenities,
  useDealCommunityAmenities,
} from "entities/Deal/sdk";

export interface ICommunityAmenitiesEditDialog {
  open: boolean;
  handleClose: () => void;
}

const CommunityAmenitiesEditDialog: React.FC<ICommunityAmenitiesEditDialog> = ({
  open,
  handleClose,
}) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: dealCommunityAmenities, mutate: mutateCommunityAmenities } =
    useDealCommunityAmenities({ dealId });

  const handleSubmit = useCallback(
    (data: Partial<IDealCommunityAmenities>) =>
      updateCommunityAmenities({ dealId, data }).then(() => {
        mutateCommunityAmenities();
        toast.successMessage("Community amenities successfully updated!");
        handleClose();
      }),
    [dealId, mutateCommunityAmenities, handleClose]
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <Box>
        <Stack
          alignItems="center"
          data-testid="edit-community-amenities-dialog"
        >
          <Paper
            sx={{
              padding: theme.spacing(8),
              width: "100%",
            }}
          >
            <Stack spacing={4}>
              <Stack direction="row" spacing={1}>
                <EditIcon
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
                <Text variant="h1" style={{ display: "flex" }}>
                  Community amenities
                </Text>
              </Stack>
              {dealCommunityAmenities && (
                <CommunityAmenitiesEditForm
                  onSubmit={handleSubmit}
                  handleCancel={handleClose}
                  initialValues={{
                    description: dealCommunityAmenities.description,
                    front_desk_and_security:
                      dealCommunityAmenities.front_desk_and_security,
                    storage: dealCommunityAmenities.storage,
                    recreational_spaces:
                      dealCommunityAmenities.recreational_spaces,
                    energy: dealCommunityAmenities.energy,
                    other: dealCommunityAmenities.other,
                    accreditations: dealCommunityAmenities.accreditations,
                  }}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default CommunityAmenitiesEditDialog;

import React from "react";

import Stack from "components/Stack";

import SectionSummary from "entities/MemoTemplate/components/Sections/SectionSummary";
import { proformaDemoData } from "entities/MemoTemplate/components/Sections/utils";
import IncomeAndOpexTable from "entities/Proforma/components/ProformaIncomeAndOpex/IncomeAndOpexTable";

const MemoTemplateIncomeAndOpex = () => {
  return (
    <Stack justifyContent="space-between" spacing={4}>
      <IncomeAndOpexTable
        incomeOperatingCost={proformaDemoData.income_operating_cost}
        totalUnits={1}
        showChart={false}
      />

      <SectionSummary title="Income &amp; OPEX description" />
    </Stack>
  );
};

export default MemoTemplateIncomeAndOpex;

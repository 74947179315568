import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import {
  BookmarkAddOutlinedIcon,
  BookmarkBorderOutlinedIcon,
  SettingsOutlinedIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Fade from "components/Fade";
import IconButton from "components/IconButton";
import Link from "components/Link";
import Popover from "components/Popover";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import Bookmark from "entities/Bookmark/Bookmark";
import FormDialog from "entities/Bookmark/FormDialog";
import { useBookmarks } from "entities/Bookmark/sdk";

const BookmarksPopover = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const open = useMemo(() => !_.isNil(anchorEl), [anchorEl]);

  const navigate = useNavigate();
  const { data, isLoading } = useBookmarks({ enabled: open });

  const handleOpen = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCreateDialogOpen = useCallback(() => {
    setIsCreateDialogOpen(true);
    handleClose();
  }, [handleClose]);

  const handleSettingsClick = useCallback(() => {
    navigate(URLS.BOOKMARKS);
    handleClose();
  }, [handleClose, navigate]);

  return (
    <>
      <Tooltip title="Bookmarks" enterDelay={500}>
        <BookmarkBorderOutlinedIcon
          onClick={handleOpen}
          sx={{
            cursor: "pointer",
            color: colors.gray5,
            fontSize: "24px",
            transition: "opacity 0.2s ease-in-out",
            opacity: 0.6,
            "&:hover": { opacity: 1 },
          }}
        />
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "320px",
              overflowY: "auto",
              maxHeight: "calc(100vh - 300px)",
            },
          },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            top: 0,
            zIndex: 2,
            position: "sticky",
            padding: 2,
            backgroundColor: colors.white,
            alignItems: "center",
            borderBottom: `1px solid ${colors.gray20}`,
          }}
        >
          <Text variant="h3">Bookmarks</Text>

          <Tooltip title="Settings" enterDelay={500}>
            <IconButton onClick={handleSettingsClick}>
              <SettingsOutlinedIcon
                sx={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Bookmark current page" enterDelay={500}>
            <IconButton onClick={handleCreateDialogOpen}>
              <BookmarkAddOutlinedIcon
                sx={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>

        {data?.map((bookmark) => (
          <Bookmark key={bookmark.id} bookmark={bookmark} />
        ))}

        {!isLoading && data?.length === 0 && (
          <Text variant="text2" padding={2} color={colors.gray80}>
            Bookmarks are shortcuts that take you directly to the pages you need
            to focus on. Create and manage your bookmarks under ‘Settings’, they
            will be saved in your personal account.
          </Text>
        )}

        {isLoading &&
          _.range(10).map((index) => (
            <Skeleton
              variant="rectangular"
              key={index}
              height={40}
              width="100%"
            />
          ))}

        {_.get(data, "length", 0) > 0 && (
          <Link to={URLS.BOOKMARKS}>
            <Text
              variant="text3"
              sx={{
                bottom: 0,
                marginTop: "-1px",
                position: "sticky",
                backgroundColor: colors.white,
                padding: theme.spacing(1, 2),
                color: colors.blue100,
                fontWeight: 700,
                borderTop: `1px solid ${colors.gray20}`,
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: colors.blue10,
                },
              }}
            >
              View all
            </Text>
          </Link>
        )}
      </Popover>

      <FormDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </>
  );
};

export default BookmarksPopover;

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { MoreVertIcon } from "icons";
import { reverse } from "utils/urls";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";

import { useSignedUser } from "entities/Auth/sdk";
import RemoveChecklistMenuItem from "entities/Deal/components/DealChecklists/RemoveChecklistMenuItem";
import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import { useDealChecklistPermission } from "entities/Deal/sdk";
import PdfImportHistoryMenuItem from "entities/Package/components/PdfImport/PdfImportHistoryMenuItem";
import PdfUploadAndParseMenuItem from "entities/Package/components/PdfImport/PdfUploadAndParseMenuItem";
import ShareWithButton from "entities/Package/components/ShareWithButton";
import { DueDiligenceTabChoices } from "entities/Package/components/Tabs/constants";
import {
  IPackageTab,
  useDealPackagesList,
  usePartialAccessUsersList,
  useShareTokenList,
} from "entities/Package/sdk";

export interface IActionButton {
  isOwned: boolean;
  dealId: number;
  companyPackageId: number;
  selectedTab: IPackageTab | undefined;
  navigationTab: DueDiligenceTabChoices;
}

const ActionButton = ({
  isOwned,
  dealId,
  companyPackageId,
  selectedTab,
  navigationTab,
}: IActionButton) => {
  const navigate = useNavigate();

  const { data: user } = useSignedUser();
  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId: companyPackageId,
  });

  const { mutate: updatePartialAccesses } = usePartialAccessUsersList({
    companyPackageId,
    dealId,
    disabled: navigationTab !== DueDiligenceTabChoices.SHARE_SETTINGS,
  });

  const { mutate: updateShareTokens } = useShareTokenList({
    companyPackageId,
    dealId,
    disabled: navigationTab !== DueDiligenceTabChoices.SHARE_SETTINGS,
  });
  const { mutate: refetchDealPackagesList } = useDealPackagesList({ dealId });

  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });

  const selectedItemIdsForShare = useMemo(
    () => selectedTab?.categories.map((category) => category.category_id) || [],
    [selectedTab]
  );

  const hasFullAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  const handleShareDialogClose = useCallback(() => {
    updatePartialAccesses();
    updateShareTokens();
  }, [updatePartialAccesses, updateShareTokens]);

  const handleRemoveChecklist = useCallback(() => {
    refetchDealPackagesList();
    navigate(
      reverse(URLS.DEAL_OVERVIEW, {
        dealId,
      })
    );
  }, [dealId, navigate, refetchDealPackagesList]);

  const showRemoveMenuItem = useMemo(
    () => loggedUserIsDealAdmin && isOwned,
    [loggedUserIsDealAdmin, isOwned]
  );

  // When user is not deal admin and doesn't have full access,
  // the button don't have options and we don't need to render it
  if (
    !loggedUserIsDealAdmin &&
    (!user?.experimental_features || !hasFullAccess)
  ) {
    return null;
  }

  return (
    <ButtonDropdown
      variant="outlined"
      label="Actions"
      icon={<MoreVertIcon />}
      keepMenuMounted
      buttonDataTestid="checklist-actions-button"
    >
      {loggedUserIsDealAdmin && (
        <span>
          <GroupLabel label="share settings" />

          <ShareWithButton
            initiallySelectedItemIds={selectedItemIdsForShare}
            additionalOnCloseAction={handleShareDialogClose}
          />
        </span>
      )}
      {user?.experimental_features && hasFullAccess && (
        <span>
          <GroupLabel label="data extraction (AI)" />
          <PdfUploadAndParseMenuItem
            packageId={companyPackageId}
            dealId={dealId}
          />
          <PdfImportHistoryMenuItem />
        </span>
      )}

      {showRemoveMenuItem && (
        <span>
          <GroupLabel label="management" />
          <RemoveChecklistMenuItem
            dealId={dealId}
            companyPackageId={companyPackageId}
            onRemove={handleRemoveChecklist}
          />
        </span>
      )}
    </ButtonDropdown>
  );
};

export default ActionButton;

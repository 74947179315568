import React, { useCallback, useState } from "react";

import { colors } from "theme/palette";

import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";

interface INumberButtonGroup {
  initialValue: number;
  lowerBound?: number;
  upperBound?: number;
  sufix?: string;
  onChange: (num: number) => void;
}

const NumberButtonGroup: React.FC<INumberButtonGroup> = ({
  initialValue,
  lowerBound,
  upperBound,
  sufix,
  onChange,
}) => {
  const [num, setNum] = useState(initialValue);

  const handleIncrease = useCallback(() => {
    setNum(num + 1);
    onChange(num + 1);
  }, [num, onChange]);

  const handleDecrease = useCallback(() => {
    setNum(num - 1);
    onChange(num - 1);
  }, [num, onChange]);

  return (
    <Stack direction="row">
      <Button
        disabled={lowerBound ? num <= lowerBound : false}
        variant="text"
        size="small"
        sx={{
          padding: 0,
          minWidth: "24px",
          border: "1px solid #f6f7fb",
          lineHeight: "100%",
          borderRadius: "4px",
          color: "black",
          margin: 0,
          height: "32px",
          fontWeight: 700,
        }}
        onClick={handleDecrease}
      >
        -
      </Button>
      <Stack
        sx={{
          padding: 0,
          width: "44px",
          height: "32px",
          border: "1px solid #f6f7fb",
          backgroundColor: "#f6f7fb",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Text variant="text3" sx={{ color: colors.blue120 }}>
          {num}
          {sufix}
        </Text>
      </Stack>
      <Button
        disabled={upperBound ? num >= upperBound : false}
        variant="text"
        size="small"
        sx={{
          padding: 0,
          minWidth: "24px",
          border: "1px solid #f6f7fb",
          lineHeight: "100%",
          borderRadius: "4px",
          color: "black",
          margin: 0,
          height: "32px",
          fontWeight: 700,
        }}
        onClick={handleIncrease}
      >
        +
      </Button>
    </Stack>
  );
};

export default NumberButtonGroup;

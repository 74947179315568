import _ from "lodash";

import { LOCALE_STRING_ZONE } from "utils/constants";
import { format, FORMATS, parseISO, parseJSON } from "utils/datetime";

import { IFieldValueHistoryItem } from "entities/Package/sdk";

import { CompanyFieldFormatType } from "./constants";

export const getFilesValuesHistory = (
  values: Array<IFieldValueHistoryItem> | undefined
) => {
  const filesActions = values?.flatMap((value) => {
    const fileActions = [];
    if (value.deleted_at) {
      fileActions.push({
        user: `${value.deleted_by?.first_name} ${value.deleted_by?.last_name}`,
        action: "deleted",
        timestamp: value.deleted_at,
        file: value.file?.original_file_name,
      });
    }

    let creatorName = "Unknown";

    if (!_.isNull(value.creator)) {
      creatorName = `${value.creator.first_name} ${value.creator.last_name}`;
    }

    fileActions.push({
      user: creatorName,
      action: "uploaded",
      timestamp: value.uploaded_at,
      file: value.file?.original_file_name,
    });
    return fileActions;
  });

  if (!_.isUndefined(filesActions)) {
    const sortedActions = filesActions.sort((a, b) =>
      new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1
    );
    return sortedActions.map((action) => {
      return {
        ...action,
        timestamp: format(
          parseJSON(action.timestamp),
          FORMATS.FRONTEND.DATETIME
        ),
      };
    });
  }

  return [];
};

export const formatFieldValue = ({
  fieldValue,
  fieldFormatType,
}: {
  fieldValue: string | number | null | undefined;
  fieldFormatType: CompanyFieldFormatType;
}): string | number | null | undefined => {
  if (
    !_.isNil(fieldValue) &&
    fieldFormatType === CompanyFieldFormatType.INTEGER &&
    !isNaN(Number(fieldValue))
  ) {
    return Number(fieldValue).toLocaleString(LOCALE_STRING_ZONE, {
      maximumFractionDigits: 0,
    });
  }

  if (
    !_.isNil(fieldValue) &&
    fieldFormatType === CompanyFieldFormatType.DATE &&
    typeof fieldValue === "string"
  ) {
    return format(parseISO(fieldValue), FORMATS.FRONTEND.DATE);
  }

  if (
    !_.isNil(fieldValue) &&
    fieldFormatType === CompanyFieldFormatType.CURRENCY_USD
  ) {
    const isNegative = Number(fieldValue) < 0;
    const clearValue = Number(fieldValue) * (isNegative ? -1 : 1);
    const formattedValue = clearValue.toLocaleString(LOCALE_STRING_ZONE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${isNegative ? `$(${formattedValue})` : `$${formattedValue}`}`;
  }

  if (
    !_.isNil(fieldValue) &&
    fieldFormatType === CompanyFieldFormatType.FLOAT_2 &&
    !isNaN(Number(fieldValue))
  ) {
    return Number(fieldValue).toLocaleString(LOCALE_STRING_ZONE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return fieldValue;
};

import React from "react";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Link from "components/Link";

interface ILeftMenuLink {
  tab: {
    id: number;
    name: string;
  };
  packageId: number;
  dealId: number;
  isSelected: boolean;
  buttonDataTestid?: string;
  linkDataTestid?: string;
}

const LeftMenuLink: React.FC<ILeftMenuLink> = ({
  tab,
  dealId,
  packageId,
  isSelected,
  buttonDataTestid,
  linkDataTestid,
}) => {
  return (
    <Link
      replace
      to={reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId,
        tabId: tab.id,
      })}
      linkDataTestid={linkDataTestid}
    >
      <Button
        variant={isSelected ? "contained" : "text"}
        color="secondary"
        sx={{
          display: "block",
          width: "100%",
          textAlign: "left",
          paddingLeft: theme.spacing(1.5),
          borderLeft: isSelected ? `6px solid ${colors.blue100}` : "0",
        }}
        data-testid={buttonDataTestid}
      >
        {tab.name}
      </Button>
    </Link>
  );
};

export default LeftMenuLink;

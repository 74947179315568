import { memo } from "react";

import Box from "components/Box";
import Tooltip from "components/Tooltip";

import { FieldType } from "entities/Field/sdk";
import Checkboxfield from "entities/Package/components/PdfImport/Fields/Checkboxfield";
import Dropdownfield from "entities/Package/components/PdfImport/Fields/Dropdownfield";
import Filefield from "entities/Package/components/PdfImport/Fields/Filefield";
import Radiobuttonfield from "entities/Package/components/PdfImport/Fields/Radiobuttonfield";
import Richtextfield from "entities/Package/components/PdfImport/Fields/Richtextfield";
import Tablefield from "entities/Package/components/PdfImport/Fields/Tablefield";
import Textfield from "entities/Package/components/PdfImport/Fields/Textfield";
import {
  DUE_DILIGENCE_SHAREABLE_TYPE_OPTIONS,
  FIELD_MESSAGE_INFO_NOT_EDITABLE,
} from "entities/Package/constants";
import { IPackageField } from "entities/Package/sdk";

export interface IFieldEditPreviewFieldWrapper {
  editable: boolean;
  field: IPackageField;
  parsedValue: string | undefined | null;
  dealId: number;
  packageId: number;
  categoryId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({
    fieldId,
    value,
  }: {
    fieldId: number;
    value: string | undefined | null;
  }) => void;
}

const FieldEditPreviewFieldWrapper = ({
  editable,
  field,
  parsedValue,
  dealId,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
}: IFieldEditPreviewFieldWrapper) => {
  // We don't want to show the fields that have a type image, signature, date time or button
  if (!DUE_DILIGENCE_SHAREABLE_TYPE_OPTIONS.includes(field.field_type)) {
    return null;
  }

  return (
    <Tooltip arrow title={editable ? "" : FIELD_MESSAGE_INFO_NOT_EDITABLE}>
      <Box sx={{ opacity: editable ? 1 : 0.5 }}>
        {field.field_type === FieldType.TEXT_FIELD && (
          <Textfield
            editable={editable}
            field={field}
            parsedValue={parsedValue}
            onUpdate={onUpdate}
          />
        )}
        {field.field_type === FieldType.RADIO_BUTTON && (
          <Radiobuttonfield
            editable={editable}
            field={field}
            parsedValue={parsedValue}
            onUpdate={onUpdate}
          />
        )}
        {field.field_type === FieldType.RICH_TEXT_FIELD && (
          <Richtextfield
            field={field}
            editable={editable}
            parsedValue={parsedValue}
            onUpdate={onUpdate}
          />
        )}
        {field.field_type === FieldType.DROPDOWN && (
          <Dropdownfield
            field={field}
            editable={editable}
            parsedValue={parsedValue}
            onUpdate={onUpdate}
          />
        )}
        {field.field_type === FieldType.CHECKBOX && (
          <Checkboxfield
            field={field}
            editable={editable}
            parsedValue={parsedValue}
            onUpdate={onUpdate}
          />
        )}
        {field.field_type === FieldType.FILE && <Filefield field={field} />}
        {field.field_type === FieldType.TABLE && (
          <Tablefield
            field={field}
            editable={editable}
            parsedValue={parsedValue}
            dealId={dealId}
            packageId={packageId}
            tabId={tabId}
            categoryId={categoryId}
            sectionId={sectionId}
            onUpdate={onUpdate}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default memo(FieldEditPreviewFieldWrapper);

import React from "react";

import NewPageMarker from "entities/MemoTemplate/components/NewPageMarker";
import SectionWrapper from "entities/MemoTemplate/components/SectionWrapper";
import WithEditorRightsideMenu from "entities/MemoTemplate/components/WithEditorRightsideMenu";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

interface ISectionWrapper {
  section: IMemoTemplateSection;
}

const SectionsWrapperWithRightsideMenu = ({ section }: ISectionWrapper) => {
  return (
    <WithEditorRightsideMenu section={section}>
      {section.break_before && <NewPageMarker />}
      <SectionWrapper section={section} />
    </WithEditorRightsideMenu>
  );
};

export default React.memo(SectionsWrapperWithRightsideMenu);

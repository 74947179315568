import React, { useCallback } from "react";

import { colors } from "theme/palette";

import Tabs, { Tab } from "components/Tabs";

import { TASKS_TAB_SUBTABS } from "entities/Deal/components/Sidepanel/utils";

interface ITaskChannelTabs {
  tabs: { label: string; key: TASKS_TAB_SUBTABS }[];
  selectedTab: TASKS_TAB_SUBTABS;
  onChange: ({ tabKey }: { tabKey: TASKS_TAB_SUBTABS }) => void;
}

const TaskChannelTabs: React.FC<ITaskChannelTabs> = ({
  tabs,
  selectedTab,
  onChange,
}) => {
  const handleOnChange = useCallback(
    (_: any, selectedTab: TASKS_TAB_SUBTABS) =>
      onChange({ tabKey: selectedTab }),
    [onChange]
  );
  return (
    <Tabs
      value={selectedTab}
      onChange={handleOnChange}
      sx={{ borderBottom: `1px solid ${colors.blue100}` }}
      TabIndicatorProps={{ sx: { height: "4px" } } as any}
    >
      {tabs.map(({ label, key }) => (
        <Tab
          key={key}
          sx={{
            textTransform: "none",
            color: colors.gray100,
            padding: "9px 1.5em",
            fontSize: "16px",
            "&.Mui-selected": {
              color: colors.gray100,
              backgroundColor: colors.blue20,
            },
          }}
          label={label}
          value={key}
        />
      ))}
    </Tabs>
  );
};

export default TaskChannelTabs;

import _ from "lodash";

import { BASE_URL } from "config/urls";
import { deleteCall, get, post, useFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

export interface IDetailOpenAIDocumentAssistant {
  id: number;

  deal_id: number;
  openai_files: Array<{
    id: number;
    file: {
      id: number;
      url: string;
      original_file_name: string;
      created_at: string;
      uploaded_by: { first_name: string; last_name: string };
    };
  }>;
  openai_prompt_package: {
    id: number;
    name: string;
    category: string;
    instructions: string;
  };
}

export interface IListOpenAIDocumentAssistant
  extends Omit<
    IDetailOpenAIDocumentAssistant,
    "openai_prompt_package" | "openai_files"
  > {
  openai_files: Array<{
    id: number;
    file: {
      id: number;
      original_file_name: string;
    };
  }>;
  total_prompt_count: number;
  unique_prompt_response_count: number;
  openai_prompt_package: { id: number; name: string; category: string };
}

export interface IOpenAICustomPromptResponse {
  id: number;
  response: string;
  updated_at: string;
  updated_by: {
    first_name: string;
    last_name: string;
  };
}

export interface IOpenAIPrompt {
  question: string;
}

export interface IOpenAIPromptResponse {
  id: number;
  readable_answer: string;
  created_at: string;
}

export interface IOpenAIPrompt {
  id: number;
  question: string;
  prompt_responses: Array<IOpenAIPromptResponse>;
  custom_responses: Array<IOpenAICustomPromptResponse>;
}

export interface IOpenAICustomPromptResponseForMemoSection {
  id: number;
  prompt: {
    id: number;
    readable_question: string;
  };
}

export const openAIDocumentAssistantCreate = ({
  fileIds,
  dealId,
  promptPackageId,
}: {
  fileIds: Array<number>;
  dealId: number;
  promptPackageId: number;
}) =>
  post<{ id: number }>(
    `${BASE_URL}v2/integrations/openai/document-assistant/create/`,
    {
      file_ids: fileIds,
      deal_id: dealId,
      openai_prompt_package: promptPackageId,
    }
  );

export const openAIDocumentAssistantDelete = ({
  assistantId,
}: {
  assistantId: number;
}) =>
  deleteCall(
    `${BASE_URL}v2/integrations/openai/document-assistant/${assistantId}/delete/`
  );

export const useOpenAIDocumentAssistantList = ({
  dealId,
  enabled = true,
}: {
  dealId: number;
  enabled?: boolean;
}) =>
  useFetch<Array<IListOpenAIDocumentAssistant>>(
    enabled
      ? `v2/integrations/openai/deal/${dealId}/document-assistant/list/`
      : undefined
  );

export const useOpenAIDocumentAssistant = ({
  openAIDocumentAssistantId,
}: {
  openAIDocumentAssistantId: number | null;
}) =>
  useFetch<IDetailOpenAIDocumentAssistant>(
    _.isNil(openAIDocumentAssistantId)
      ? undefined
      : `v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/retrieve/`
  );

export interface IPromptPackage {
  id: number;
  name: string;
  category: string;
  total_prompt_count: number;
}

export const useOpenAIPromptPackages = ({
  category,
}: { category?: string } = {}) =>
  useFetch<Array<IPromptPackage>>(
    `v2/integrations/openai/prompt-package/list/${_.isNil(category) ? "" : formatGetParams({ category })}`
  );

export const generateAnswer = ({
  openAIDocumentAssistantId,
  promptId,
}: {
  openAIDocumentAssistantId: number;
  promptId: number;
}) =>
  post(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/generate-answer/`,
    {
      prompt: promptId,
    }
  );

export const promptResponseDelete = ({
  openAIDocumentAssistantId,
  promptResponseId,
}: {
  openAIDocumentAssistantId: number;
  promptResponseId: number;
}) =>
  deleteCall(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/prompt-response/${promptResponseId}/delete/`
  );

export const usePromptList = ({
  openAIDocumentAssistantId,
}: {
  openAIDocumentAssistantId: number | null;
}) =>
  useFetch<Array<IOpenAIPrompt>>(
    _.isNil(openAIDocumentAssistantId)
      ? undefined
      : `v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/prompt/list/`
  );

export const openAIDocumentAssistantPromptPackageUpdate = ({
  openAIDocumentAssistantId,
  openAIPromptPackageId,
}: {
  openAIDocumentAssistantId: number;
  openAIPromptPackageId: number;
}) =>
  post(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/prompt-package/update/`,
    { openai_prompt_package: openAIPromptPackageId }
  );

export const openAIPromptCustomResponseCreateOrUpdate = ({
  openAIDocumentAssistantId,
  promptId,
  response,
}: {
  openAIDocumentAssistantId: number;
  promptId: number;
  response: string;
}) =>
  post(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/prompt/${promptId}/custom-prompt-response/create-or-update/`,
    { response }
  );

export const openAICustomPromptResponseDelete = ({
  openAIDocumentAssistantId,
  customPromptResponseId,
}: {
  openAIDocumentAssistantId: number;
  customPromptResponseId: number;
}) =>
  deleteCall(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/custom-prompt-response/${customPromptResponseId}/delete/`
  );

export const useCustomPromptResponseListForMemoSection = ({
  openAIDocumentAssistantId,
  enabled = true,
}: {
  openAIDocumentAssistantId: number | null;
  enabled?: boolean;
}) =>
  useFetch<Array<IOpenAICustomPromptResponseForMemoSection>>(
    _.isNil(openAIDocumentAssistantId) || !enabled
      ? undefined
      : `v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/custom-prompt-response-for-memo-section/list/`
  );

export const openAICustomPromptResponseCheckHasMemoSections = ({
  openAIDocumentAssistantId,
  customPromptResponseId,
}: {
  openAIDocumentAssistantId: number;
  customPromptResponseId: number;
}) =>
  get<{ has_memo_sections: boolean }>(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/custom-prompt-response/${customPromptResponseId}/check-has-memo-sections/`
  );

export const openAIDocumentAssistantAddFiles = ({
  openAIDocumentAssistantId,
  fileIds,
}: {
  openAIDocumentAssistantId: number;
  fileIds: Array<number>;
}) =>
  post(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/prompt-package/add-files/`,
    { file_ids: fileIds }
  );

export const openAIDocumentAssistantRemoveFile = ({
  openAIDocumentAssistantId,
  openAIFileId,
}: {
  openAIDocumentAssistantId: number;
  openAIFileId: number;
}) =>
  post(
    `${BASE_URL}v2/integrations/openai/document-assistant/${openAIDocumentAssistantId}/prompt-package/remove-file/`,
    { file_id: openAIFileId }
  );

import { useCallback, useState } from "react";

import { EditIcon } from "icons";
import theme from "theme";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import IconButton from "components/IconButton";
import { EMPTY_VALUE_REGEX } from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";

export interface IRichtextfieldEditButton {
  field: { name: string };
  initialValue: string | null;
  onSave: (newValue: string | null) => void;
  onCancel?: () => void;
  buttonDataTestid?: string;
}

const RichtextfieldEditButton = ({
  field,
  initialValue,
  onSave,
  onCancel,
  buttonDataTestid,
}: IRichtextfieldEditButton) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleEditButtonClick = useCallback(() => setIsOpen(true), []);

  const handleSave = useCallback(() => {
    onSave(EMPTY_VALUE_REGEX.test(value || "") ? "" : value);
    setIsOpen(false);
  }, [setIsOpen, onSave, value]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    onCancel && onCancel();
  }, [setIsOpen, onCancel]);

  const handleChange = useCallback(
    (newValue: string) => setValue(newValue),
    []
  );

  return (
    <>
      <IconButton
        size="small"
        onClick={handleEditButtonClick}
        dataTestid={buttonDataTestid}
      >
        <EditIcon />
      </IconButton>
      {isOpen && (
        <Dialog
          open={isOpen}
          maxWidth="sm"
          fullWidth
          PaperProps={{ sx: { padding: theme.spacing(1) } }}
          data-testid="dd-rtf-edit-dialog"
          onClose={handleCancel}
        >
          <DialogTitle data-testid="edit-dialog-title">
            {field.name}
          </DialogTitle>
          <DialogContent>
            <RichTextEditorField
              defaultValue={initialValue || ""}
              onChange={handleChange}
              sx={{ height: "auto" }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} data-testid="submit-button">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RichtextfieldEditButton;

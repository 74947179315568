import { memo, useMemo } from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import MapElement from "components/Map";
import Stack from "components/Stack";
import Text from "components/Text";
import { useFilters } from "components/useFilters";

import DealsGrid from "entities/Deal/components/Grid";
import ListTable from "entities/Deal/components/ListTable";
import { ProjectsViewType, SEARCH_KEYS } from "entities/Deal/constants";
import { IMarker, IPipelineColumns } from "entities/Deal/sdk";
import { useReportPackageFilterList } from "entities/Reporting/sdk";
import { getFilteredDeals } from "entities/Reporting/utils";

const ProjectsViewContent = ({
  selectedView,
  rows,
  columns,
  loading,
  isValidating,
  markers,
}: {
  selectedView: ProjectsViewType;
  rows: { [key: string]: string | null }[];
  columns: Array<IPipelineColumns>;
  loading: boolean;
  isValidating: boolean;
  markers: IMarker[];
}) => {
  const { filters } = useFilters();
  const { data: filterDict } = useReportPackageFilterList({
    report_package_id: null,
  });

  const filteredDeals = useMemo(
    () =>
      getFilteredDeals({
        rows,
        selectedFilters: filters,
        filterDict,
        searchKeys: SEARCH_KEYS,
      }),
    [rows, filters, filterDict]
  );

  return (
    <Stack spacing={4} sx={{ flex: 1 }}>
      <Text
        variant="text3"
        sx={{
          color: colors.gray60,
        }}
        data-testid="total-deal-results"
      >
        Showing {filteredDeals.length} out of {rows.length} deals.
      </Text>

      {selectedView === ProjectsViewType.grid && (
        <DealsGrid
          rows={filteredDeals}
          isValidating={isValidating}
          showLoading={loading}
        />
      )}

      {selectedView === ProjectsViewType.list && columns && (
        <ListTable
          rows={filteredDeals}
          columns={columns}
          showLoading={loading || isValidating}
        />
      )}

      {selectedView === ProjectsViewType.map && (
        <Box sx={{ flex: 1, display: "flex", backgroundColor: colors.gray20 }}>
          {markers && (
            <MapElement
              markers={markers || []}
              showMarkersButton={true}
              options={markers.length === 1 ? { zoom: 15 } : undefined}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

export default memo(ProjectsViewContent, (prev, next) => {
  return (
    prev.selectedView === next.selectedView &&
    prev.loading === next.loading &&
    prev.isValidating === next.isValidating &&
    prev.rows === next.rows &&
    prev.columns === next.columns &&
    prev.markers === next.markers
  );
});

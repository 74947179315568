import theme from "theme";

import Box from "components/Box";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

interface IRichtextfield {
  label: string;
  value: string;
}

const Richtextfield = ({ label, value }: IRichtextfield) => {
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <Text
          variant="text2"
          sx={{
            flexBasis: "400px",
            fontWeight: 300,
            paddingRight: theme.spacing(4),
          }}
        >
          {label}
        </Text>
      </Stack>
      <Box>
        <SanitizeHtml html={value} />
      </Box>
    </Box>
  );
};

export default Richtextfield;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { formatNumberToString } from "utils/common";

import Box from "components/Box";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";

export interface IProformaUnixTable {
  rows: Array<{ title: string; data: Array<{ [key: string]: any }> }>;
  headers: Array<any>;
  totals: { title: string; data: Array<{ [key: string]: string | number }> };
}

const ProformaUnixTable: React.FC<IProformaUnixTable> = ({
  rows,
  headers,
  totals,
}) => {
  return (
    <Table
      size="small"
      sx={{
        "& .MuiTableCell-root:first-of-type": {
          textAlign: "left",
          paddingLeft: 0,
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Text variant="text3"></Text>
          </TableCell>
          {headers.map((header, index) => (
            <TableCell key={index}>
              <Text variant="subtitle1" fontWeight="700" textAlign="right">
                {header.name}
              </Text>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow sx={{ height: "34px" }} key={index}>
            <TableCell>
              <Text variant="text3">{row.title}</Text>
            </TableCell>
            {row.data &&
              row.data.map((element, index) => (
                <TableCell padding="none" align="right" key={index}>
                  <Box
                    sx={{
                      display: "inline-block",
                      padding: theme.spacing(0.75, 2, 0.75),
                    }}
                  >
                    <Text variant="text3" textAlign="right">
                      {element.value.gross_monthly_rent &&
                        `$${formatNumberToString({
                          number: element.value.gross_monthly_rent,
                        })}`}
                      {element.value.sqf_area &&
                        formatNumberToString({
                          number: element.value.sqf_area,
                        })}
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      borderLeft: "1px solid #000",
                      width: theme.spacing(6),
                      backgroundColor: colors.blue10,
                      padding: theme.spacing(0.75, 2, 0.75),
                    }}
                  >
                    <Text variant="text3" textAlign="right">
                      {element.value.count}
                    </Text>
                  </Box>
                </TableCell>
              ))}
          </TableRow>
        ))}

        <TableRow sx={{ height: "34px" }}>
          <TableCell>
            <Text variant="text2" fontWeight="700">
              {totals.title}
            </Text>
          </TableCell>
          {totals.data &&
            totals.data.map((element, index) => (
              <TableCell padding="none" align="right" key={index}>
                <Box
                  sx={{
                    display: "inline-block",
                    padding: theme.spacing(0.75, 2, 0.75),
                  }}
                >
                  <Text
                    textAlign="right"
                    variant="text2"
                    fontWeight="700"
                  ></Text>
                </Box>
                <Box
                  sx={{
                    display: "inline-block",
                    borderLeft: "1px solid #000",
                    width: theme.spacing(6),
                    backgroundColor: colors.blue10,
                    padding: theme.spacing(0.75, 2, 0.75),
                  }}
                >
                  <Text variant="text3" fontWeight="700">
                    {element.value}
                  </Text>
                </Box>
              </TableCell>
            ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ProformaUnixTable;

import React from "react";

import theme from "theme";

import Stack from "components/Stack";
import Text from "components/Text";

const SectionContentLabelValue = ({
  label,
  value,
  EditButton,
}: {
  label: string;
  value: string;
  EditButton?: React.ReactNode;
}) => {
  return (
    <Stack direction="row" alignItems="flex-start">
      <Text
        variant="text2"
        sx={{
          flexBasis: "400px",
          fontWeight: 300,
          paddingRight: theme.spacing(4),
        }}
      >
        {label}
      </Text>
      <Text variant="text2" sx={{ flex: 1 }}>
        {value}
      </Text>
      {EditButton}
    </Stack>
  );
};
export default SectionContentLabelValue;

import { useCallback, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { fieldOptionRetrieve, useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Select from "components/Select";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import FieldDropdownMenu from "entities/Field/components/FieldDropdownMenu";
import FieldTitle from "entities/Field/components/Title";
import { fieldValueCreate, IField } from "entities/Field/sdk";
import { IFieldOption } from "entities/Package/sdk";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

export interface IDropdownfield {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  dealId: number;
  categoryId: number;
  packageId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  isFocused?: boolean;
}

const Dropdownfield = ({
  editable,
  dealId,
  taskChannels,
  updatePackage,
  field,
  categoryId,
  packageId,
  tabId,
  sectionId,
  onUpdate,
  isFocused = false,
}: IDropdownfield) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useCaching<IFieldOption | null>(
    fieldOptionRetrieve({
      options: field.options,
      value: _.get(field.custom_values, "[0].selected_value"),
    })
  );

  const handleEditButtonClick = useCallback(() => setIsEdit(true), []);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(
        fieldOptionRetrieve({
          options: field.options,
          value: event.target.value,
        })
      );
    },
    [setValue, field.options]
  );

  const handleSave = useCallback(() => {
    fieldValueCreate({
      dealId,
      companyCustomFieldId: field.field_id,
      value: value?.value || null,
    })
      .then(() => {
        setIsEdit(false);
        onUpdate({ message: `${field.display_name} successfully updated!` });
      })
      .catch(handleInvalidRequest);
  }, [value, field, dealId, onUpdate]);

  const handleCancel = useCallback(() => {
    setValue(
      fieldOptionRetrieve({
        options: field.options,
        value: _.get(field.custom_values, "[0].selected_value"),
      })
    );
    setIsEdit(false);
  }, [setValue, field]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const hasTasksAndNotEditing = taskChannels.length > 0 && !isEdit;

  const showEditButton = !field.n_a_status && editable;

  return (
    <Stack
      id={field.field_id.toString()}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${colors.blue20}`,
        backgroundColor: isFocused ? colors.green10 : "initial",
      }}
      data-testid="dd-field"
    >
      <FieldTitle
        field={field}
        dealId={dealId}
        packageId={packageId}
        categoryId={categoryId}
        tabId={tabId}
        sectionId={sectionId}
        onUpdate={onUpdate}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        {hasTasksAndNotEditing && (
          <DueDiligenceFieldOpenTaskButton
            taskChannels={taskChannels}
            company_field={field}
            dealId={dealId}
            tabId={tabId}
            sectionId={sectionId}
            categoryId={categoryId}
            packageId={packageId}
            updatePackage={updatePackage}
            taskButtonDataTestid="dd-open-category-task-button"
            taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
          />
        )}
        {!field.n_a_status && !isEdit && !value && (
          <Tag label="missing" variant="red" dataTestid="empty-field-value" />
        )}
        {!field.n_a_status && !isEdit && value && (
          <Text variant="text2" data-testid="field-value" maxWidth="50vw">
            {value.label}
          </Text>
        )}
        {field.n_a_status && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
        {!isEdit && (
          <>
            {showEditButton && (
              <IconButton
                size="small"
                onClick={handleEditButtonClick}
                dataTestid="field-edit-button"
              >
                <EditIcon />
              </IconButton>
            )}
            <FieldDropdownMenu
              field={field}
              dealId={dealId}
              packageId={packageId}
              categoryId={categoryId}
              tabId={tabId}
              sectionId={sectionId}
              onUpdate={onUpdate}
              menuButtonDataTestid="dd-field-actions-button"
            />
          </>
        )}
        {isEdit && (
          <Stack spacing={1} direction="row" alignItems="center">
            <Select
              options={field.options}
              size="small"
              value={value?.value}
              onChange={handleChange}
              placeholder="Select data"
              data-testid="edit-dropdown-field"
              inputProps={{ "data-testid": "edit-dropdown-input" }}
              dropdownOptionsDataTestid="dropdown-select-option"
              selectProps={{ sx: { maxWidth: "30vw" } }}
            />
            <Button onClick={handleSave} data-testid="submit-button">
              Save
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleReset}
              data-testid="reset-button"
            >
              Reset
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Dropdownfield;

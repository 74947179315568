import Cell from "components/FixedTable/Cell";
import { TableRow } from "components/Table";

interface IEmptyRow {
  colSpan: number;
}

const EmptyRow = ({ colSpan }: IEmptyRow) => {
  return (
    <TableRow key="empty=row">
      <Cell
        cellKey="empty-cell"
        value="..."
        colSpan={colSpan}
        textAlign="center"
      />
    </TableRow>
  );
};

export default EmptyRow;

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { useTaskChannels } from "entities/Task/sdk";

interface IWorkspaceTaskDetailButton {
  taskChannelId: number;
  dealId: number;
  refreshTasksList: () => void;
}

const WorkspaceTaskDetailButton = ({
  taskChannelId,
  dealId,
  refreshTasksList,
}: IWorkspaceTaskDetailButton) => {
  const { data: taskChannels } = useTaskChannels({
    dealId,
  });
  const { show } = useDealSidepanel();

  const handleOpenTaskDetail = useCallback(() => {
    const taskChannel = taskChannels?.find(
      (taskChannel) => taskChannel.id === taskChannelId
    );
    show({
      meta: {
        tab: TABS.TASKS,
        subTab: TASKS_TAB_SUBTABS.DETAILS,
        taskChannel: taskChannel,
        updateParent: refreshTasksList,
        dealId,
      },
    });
  }, [dealId, show, taskChannelId, taskChannels, refreshTasksList]);

  return (
    <MenuItem onClick={handleOpenTaskDetail}>
      <Text variant="text2">Task details</Text>
    </MenuItem>
  );
};

export default WorkspaceTaskDetailButton;

import { BASE_URL } from "config/urls";
import { get, post, useFetch, useInfiniteFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { IFieldTemplateMeta, IPackageDetails } from "entities/Checklist/sdk";
import { CompanyFieldFormatType } from "entities/Field/constants";
import { FieldType } from "entities/Field/sdk";
import { IFile } from "entities/File/sdk";
import { ImportPdfStatus } from "entities/Package/constants";
import { ITypeOfFilter } from "entities/Task/sdk";

export enum EShareTokenStatus {
  PENDING = "Pending",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  CANCELED = "Canceled",
  INVALIDATED = "Invalidated",
}

export enum EPartialUserAccessType {
  READ_ONLY = "Read only access",
  READ_WRITE_ACCESS = "Read/Write access",
}

export interface IShareToken {
  token: string;
  created_by: {
    first_name: string;
    last_name: string;
    company: {
      name: string;
    };
  };
  deal: {
    id: number;
    name: string;
  };
  company_package: {
    id: number;
    name: string;
  };
  email: string;
  status: EShareTokenStatus;
}

export interface IPackageDealValue {
  value_id: number;
  default_value: string;
  selected_value: string;
  column_value: {} | Array<{ [key: string]: string }>; // Api is inconsistent here
  rich_value: string;
  file: IFile | null;
  updated_at: string;
}

export interface IFieldOption {
  label: string;
  value: string;
}

export interface IPackageField {
  field_id: number;
  name: string;
  field_type: FieldType;
  columns: string[];
  options: Array<IFieldOption>;
  n_a_status: boolean;
  has_new: boolean;
  remark_value: { shared_remark: string; private_remark: string };
  is_completed: boolean;
  has_remark: boolean;
  single_persona: boolean;
  instruction?: string | null;

  is_linked: boolean;
  is_overridden: boolean;

  values: IPackageDealValue[];
  template?: string;
  template_meta?: Array<IFieldTemplateMeta>;
  field_format_type: CompanyFieldFormatType;
}

export interface IPackageSection {
  section_id: number;
  name: string;
  fields: IPackageField[];
  is_approved: boolean;
  instruction?: string | null;
}

export interface IPackageCategory {
  category_id: number;
  name: string;
  is_approved: boolean;
  sections: IPackageSection[];
  instruction?: string | null;
}

export interface IPackageTab {
  tab_id: number;
  name: string;
  categories: IPackageCategory[];
}

export interface IPackage {
  name: string;
  company: { id: number; name: string } | null;
  tabs: Array<IPackageTab>;
  is_owned: boolean;
}

export interface IDueDiligencePdfImport {
  created_at: string;
  status: ImportPdfStatus;
  id: number;
  file: IFile;
  created_by: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export interface IFieldValueHistoryItem {
  id: number;
  default_value: string | null;
  selected_value: string | null;
  field_format_type: CompanyFieldFormatType;
  deleted_at: string | null;
  deleted_by: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  } | null;
  column_value: Array<{ [key: string]: string }> | null;
  rich_value: string | null;
  file: IFile | null;
  uploaded_at: string;
  creator: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  } | null;
}

export interface ICompanyCustomFieldParsedValue {
  value: string | undefined | null;
  field_id: number;
}

export interface IDealPackage {
  package_id: number;
  name: string;
  company?: {
    name: string;
  };
}

export const useDealPackagesList = ({ dealId }: { dealId: number }) =>
  useFetch<Array<IDealPackage>>(
    `v2/due-diligence/deal/${dealId}/package/list/`
  );

export const sectionApproveBulk = ({
  dealId,
  sectionIds,
  isApproved,
}: {
  dealId: number;
  sectionIds: number[];
  isApproved: boolean;
}) =>
  post(`${BASE_URL}v2/due-diligence/deal/${dealId}/section-multiple-approve/`, {
    section_ids: sectionIds,
    is_approved: isApproved,
  });

export const toggleCategoryApproval = ({
  isCompleted,
  dealId,
  categoryId,
}: {
  isCompleted: boolean;
  dealId: number;
  categoryId: number;
}) =>
  post<{ category_id: number; is_approved: boolean }>(
    `${BASE_URL}v2/due-diligence/deal/${dealId}/category/${categoryId}/approve/`,
    {
      is_approved: isCompleted,
    }
  );

export const fieldApproveBulk = ({
  dealId,
  fieldIds,
  isApproved,
}: {
  dealId: number;
  fieldIds: number[];
  isApproved: boolean;
}) =>
  post(`${BASE_URL}v2/due-diligence/deal/${dealId}/field-multiple-approve/`, {
    field_ids: fieldIds,
    is_approved: isApproved,
  });

export const fieldUpdateNAStatusBulk = ({
  dealId,
  fieldIds,
  naStatus,
}: {
  dealId: number;
  fieldIds: number[];
  naStatus: boolean;
}) =>
  post(
    `${BASE_URL}v2/due-diligence/deal/${dealId}/field-multiple-n-a-status/`,
    {
      field_ids: fieldIds,
      n_a_status: naStatus,
    }
  );

export const usePackageDealDetails = ({
  packageId,
  dealId,
  queryParams,
}: {
  packageId: number;
  dealId: number;
  queryParams?: ITypeOfFilter;
}) =>
  useFetch<IPackage>(
    `v2/due-diligence/package/${packageId}/deal/${dealId}/details/${
      queryParams ? formatGetParams(queryParams) : ""
    }`
  );

export const packageShare = ({
  fieldIds,
  invitations,
  message,
  packageId,
  dealId,
}: {
  fieldIds: Array<number>;
  invitations: Array<{ email: string; access_type: string }>;
  message: string;
  packageId: number;
  dealId: number;
}): Promise<{}> =>
  post(
    `${BASE_URL}v2/due-diligence/deal/${dealId}/package/${packageId}/share/`,
    {
      field_ids: fieldIds,
      invitations,
      message,
    }
  );

export const useShareToken = ({ token }: { token: string }) =>
  useFetch<IShareToken>(`v2/due-diligence/share-token/${token}/retrieve/`);

export const shareTokenAccept = ({ token }: { token: string }) =>
  post<IShareToken>(`${BASE_URL}v2/due-diligence/share-token/${token}/accept/`);

export const shareTokenReject = ({ token }: { token: string }) =>
  post<IShareToken>(`${BASE_URL}v2/due-diligence/share-token/${token}/reject/`);

export const shareTokenUpdate = ({
  shareTokenId,
  data,
}: {
  shareTokenId: number;
  data: { field_ids: Array<number>; access_type: EPartialUserAccessType };
}) =>
  post(`${BASE_URL}v2/due-diligence/share-token/${shareTokenId}/update/`, data);

export const lenderShareChecklist = ({
  packageId,
  data,
}: {
  packageId: number;
  data: { name: string; notification_emails: string[] };
}): Promise<{ token: string }> =>
  post(`${BASE_URL}v2/due-diligence/package/${packageId}/share/`, data);

export const useFieldValuesHistory = ({
  dealId,
  fieldId,
}: {
  dealId: number;
  fieldId: number;
}) =>
  useFetch<Array<IFieldValueHistoryItem>>(
    `v2/due-diligence/deal/${dealId}/field/${fieldId}/value/list`
  );

export const checklistProcessPdf = ({
  dealId,
  packageId,
  fileId,
  queueId,
}: {
  dealId: number;
  packageId: number;
  fileId: number;
  queueId: string;
}) =>
  post<{ mapped_data_id: number }>(
    `${BASE_URL}v2/integrations/butler/deal/${dealId}/company-package/${packageId}/process-due-diligence/`,
    {
      file_id: fileId,
      queue_id: queueId,
    }
  );

export interface IDueDiligenceMappedData {
  id: number;
  value: string;
  company_custom_field: { id: number };
}

export const useDueDiligenceMappedData = ({
  pdfImportId,
}: {
  pdfImportId: number;
}) =>
  useFetch<Array<IDueDiligenceMappedData>>(
    `v2/integrations/butler/mapped-data/${pdfImportId}/mapping/list/`
  );

export const useDataImport = ({ pdfImportId }: { pdfImportId: number }) =>
  useFetch<{
    id: number;
    file: string;
  }>(`v2/integrations/butler/mapped-data/${pdfImportId}/retrieve/`);

export const createFieldValuesBulk = ({
  dealId,
  values,
}: {
  dealId: number;
  values: Array<ICompanyCustomFieldParsedValue>;
}) =>
  post(`${BASE_URL}v2/deal/${dealId}/custom-field/value/bulk-create/`, {
    values,
  });

export const useDueDiligencePdfImportList = ({
  dealId,
  packageId,
  enabled,
}: {
  dealId: number;
  packageId: number;
  enabled: boolean;
}) =>
  useFetch<Array<IDueDiligencePdfImport>>(
    enabled
      ? `v2/integrations/butler/deal/${dealId}/company-package/${packageId}/mapped-data/list/`
      : undefined
  );

export const getPdfImportFileSignedURLRetrieve = ({
  pdfImportId,
}: {
  pdfImportId: number;
}) =>
  get<{ signed_url: string }>(
    `${BASE_URL}v2/integrations/butler/mapped-data/${pdfImportId}/file/signed-url/retrieve/`
  );

export interface IShareTokenList {
  id: number;
  access_type: EPartialUserAccessType;
  email: string;
  persona: string;
  status: EShareTokenStatus;
  fields: Array<{ id: number }>;
}

export const useShareTokenList = ({
  dealId,
  companyPackageId,
  disabled,
}: {
  dealId: number | null;
  companyPackageId: number | null;
  disabled: boolean;
}) =>
  useFetch<Array<IShareTokenList>>(
    !disabled
      ? `v2/due-diligence/deal/${dealId}/company-package/${companyPackageId}/share-token/list/`
      : undefined
  );

export interface IPartialUserAccess {
  id: number;
  access_type: EPartialUserAccessType;
  persona: string;
  is_active: boolean;
  user: {
    first_name: string;
    last_name: string;
    email: string;
    avatar: string | null;
    company: {
      name: string;
    };
  };
  company_custom_fields: Array<{ id: number }>;
}
export const usePartialAccessUsersList = ({
  dealId,
  companyPackageId,
  disabled,
}: {
  dealId: number | null;
  companyPackageId: number | null;
  disabled: boolean;
}) =>
  useFetch<Array<IPartialUserAccess>>(
    !disabled
      ? `v2/due-diligence/deal/${dealId}/company-package/${companyPackageId}/company-custom-field-user-access/list/`
      : undefined
  );

export const updatePartialUserAccess = ({
  userAccessId,
  data,
}: {
  userAccessId: number;
  data: {
    company_custom_field_ids: Array<number>;
    access_type: EPartialUserAccessType;
  };
}) =>
  post(
    `${BASE_URL}v2/due-diligence/company-custom-field-user-access/${userAccessId}/update/`,
    data
  );

export const deactivatePartialUserAccess = ({
  userAccessId,
}: {
  userAccessId: number;
}) =>
  post(
    `${BASE_URL}v2/due-diligence/company-custom-field-user-access/${userAccessId}/deactivate/`
  );

export const resendInvitationShareToken = ({
  shareTokenId,
  email,
}: {
  shareTokenId: number;
  email: string;
}) =>
  post(`${BASE_URL}v2/due-diligence/share-token/${shareTokenId}/resend/`, {
    email,
  });

export const cancelShareToken = ({ shareTokenId }: { shareTokenId: number }) =>
  post(`${BASE_URL}v2/due-diligence/share-token/${shareTokenId}/cancel/`);

export interface ICompanyChecklist {
  id: number;
  name: string;
  deals_count: number;
  fields_count: number;
  linked_fields_count: number;
  source: {
    id: number;
    name: string;
  };
}

export const useCompanyChecklists = () =>
  useInfiniteFetch<ICompanyChecklist>({
    url: `${BASE_URL}v2/due-diligence/company-package/list-for-company/`,
  });

export const useDuplicatedCompanyChecklists = () =>
  useInfiniteFetch<ICompanyChecklist>({
    url: `${BASE_URL}v2/due-diligence/company-package/list-duplicated-for-company/`,
  });

export const useSharedCompanyChecklists = () =>
  useInfiniteFetch<ICompanyChecklist>({
    url: `${BASE_URL}v2/due-diligence/company-package/list/shared/`,
  });

export interface IPublicChecklist {
  id: number;
  name: string;
  fields_count: string;
  company: {
    id: number;
    name: string;
  };
}

export const usePublicChecklists = () =>
  useFetch<Array<IPublicChecklist>>(
    `v2/due-diligence/company-package/list/public`
  );

export const cloneCompanyChecklist = ({
  checklistId,
  newChecklistName,
}: {
  checklistId: number;
  newChecklistName: string;
}) =>
  post<{ id: number }>(
    `${BASE_URL}v2/due-diligence/company-package/${checklistId}/duplicate/`,
    {
      name: newChecklistName,
    }
  );

export const renameCompanyChecklist = ({
  name,
  packageId,
}: {
  name: string;
  packageId: number;
}) =>
  post(`${BASE_URL}v2/due-diligence/company-package/${packageId}/rename/`, {
    name,
  });

export const deleteCompanyChecklist = ({ packageId }: { packageId: number }) =>
  post(`${BASE_URL}v2/due-diligence/company-package/${packageId}/delete/`);

export interface ICompanyPackageDetails extends IPackageDetails {
  is_owned: boolean;
}

export const useCompanyChecklistPreview = ({
  packageId,
}: {
  packageId: number;
}) =>
  useFetch<ICompanyPackageDetails>(
    `v2/due-diligence/company-package/${packageId}/details-retrieve/`
  );

export const useCompanyPackageCheckIsDeletable = ({
  packageId,
  disabled,
}: {
  packageId: number;
  disabled: boolean;
}) =>
  useFetch<{ is_deletable: boolean }>(
    disabled
      ? undefined
      : `v2/due-diligence/company-package/${packageId}/check-is-deletable/`
  );

export interface ICompanyPackageLeftMenuData {
  id: number;
  name: string;
  company: {
    name: string;
  };
  tabs: Array<{
    id: number;
    name: string;
  }>;
}

export const useCompanyPackageWithTabsInformation = ({
  packageId,
  dealId,
}: {
  packageId: number;
  dealId: number;
}) =>
  useFetch<ICompanyPackageLeftMenuData>(
    `v2/due-diligence/package/${packageId}/deal/${dealId}/name-company-tabs/retrieve/`
  );

export const fetchCompanyPackageWithTabsInformation = ({
  packageId,
  dealId,
}: {
  packageId: number;
  dealId: number;
}): Promise<ICompanyPackageLeftMenuData> =>
  get(
    `${BASE_URL}v2/due-diligence/package/${packageId}/deal/${dealId}/name-company-tabs/retrieve/`
  );

export const useCompanyPackageStructure = ({
  packageId,
  dealId,
}: {
  packageId: number;
  dealId: number;
}) =>
  useFetch<IPackage>(
    `v2/due-diligence/package/${packageId}/deal/${dealId}/structure/retrieve/`
  );

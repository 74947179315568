import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";

import {
  DEAL_PHASES,
  DEAL_STATUSES,
} from "entities/Deal/components/Form/constants";
import {
  CONSTRUCTION_TYPES,
  PROPERTY_TYPES,
  RENT_TYPES,
} from "entities/Deal/constants";
import { IDeal, IDealCoreData } from "entities/Deal/sdk";

const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("This field is required!"),
  total_units: yup.number().required("This field is required!"),
});

const initialValues: Partial<
  (Omit<IDeal, "phase"> & { phase_name: string }) & IDealCoreData
> = {
  name: "",
  legal_name: "",
  project_type: "",
  rent_type: null,
  construction_type: "",
  status: "",
  phase_name: "",
  total_units: 0,
};

interface IProjectEditForm {
  initialValues?: Partial<
    (Omit<IDeal, "phase"> & { phase_name: string }) & IDealCoreData
  >;
  onSubmit: (
    data: Partial<
      (Omit<IDeal, "phase"> & { phase_name: string }) & IDealCoreData
    >
  ) => Promise<any>;
  handleCancel?: () => void;
}
const ProjectEditForm: React.FC<IProjectEditForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
}) => {
  const handleSubmit = useCallback(
    (
      data: Partial<
        (Omit<IDeal, "phase"> & { phase_name: string }) & IDealCoreData
      >
    ) => onSubmit(data),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Project name"
          {...formik.getFieldProps("name")}
          error={formik.touched.name && !!formik.errors.name}
          helperText={
            formik.touched.name && formik.errors.name ? formik.errors.name : ""
          }
          data-testid="project-name"
          inputProps={{ "data-testid": "project-name-input" }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Project's Legal Name"
          {...formik.getFieldProps("legal_name")}
          error={formik.touched.legal_name && !!formik.errors.legal_name}
          helperText={
            formik.touched.legal_name && formik.errors.legal_name
              ? formik.errors.legal_name
              : ""
          }
          data-testid="project-legal-name"
          inputProps={{ "data-testid": "project-legal-name-input" }}
        />
        <Select
          inputLabel="Project phase"
          options={DEAL_PHASES}
          {...formik.getFieldProps("phase_name")}
          error={formik.touched.phase_name && !!formik.errors.phase_name}
          helperText={formik.touched.phase_name && formik.errors.phase_name}
          data-testid="project-phase-dropdown"
          inputProps={{ "data-testid": "project-phase-dropdown-input" }}
          dropdownOptionsDataTestid="project-phase-dropdown-option"
        />
        <Select
          inputLabel="Project status"
          options={DEAL_STATUSES}
          {...formik.getFieldProps("status")}
          error={formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          data-testid="project-status-dropdown"
          inputProps={{ "data-testid": "project-status-dropdown-input" }}
          dropdownOptionsDataTestid="project-status-dropdown-option"
        />
        <Select
          inputLabel="Property type"
          options={PROPERTY_TYPES}
          {...formik.getFieldProps("project_type")}
          error={formik.touched.project_type && !!formik.errors.project_type}
          helperText={formik.touched.project_type && formik.errors.project_type}
          data-testid="property-type-dropdown"
          inputProps={{ "data-testid": "property-type-dropdown-input" }}
          dropdownOptionsDataTestid="property-type-dropdown-option"
        />
        <Select
          inputLabel="Construction type"
          options={CONSTRUCTION_TYPES}
          {...formik.getFieldProps("construction_type")}
          error={
            formik.touched.construction_type &&
            !!formik.errors.construction_type
          }
          helperText={
            formik.touched.construction_type && formik.errors.construction_type
          }
          data-testid="construction-type-dropdown"
          inputProps={{ "data-testid": "construction-type-dropdown-input" }}
          dropdownOptionsDataTestid="construction-type-dropdown-option"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Total units"
          {...formik.getFieldProps("total_units")}
          error={formik.touched.total_units && !!formik.errors.total_units}
          helperText={
            formik.touched.total_units && formik.errors.total_units
              ? formik.errors.total_units
              : ""
          }
          data-testid="project-total-units"
          inputProps={{ "data-testid": "project-total-units-input" }}
        />
        <Select
          inputLabel="Rent type"
          options={RENT_TYPES}
          {...formik.getFieldProps("rent_type")}
          error={formik.touched.rent_type && !!formik.errors.rent_type}
          helperText={formik.touched.rent_type && formik.errors.rent_type}
          data-testid="rent-type-dropdown"
          inputProps={{ "data-testid": "rent-type-dropdown-input" }}
          dropdownOptionsDataTestid="rent-type-dropdown-option"
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            onClick={handleCancel}
            data-testid="close-project-details-dialog-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-project-details-form-button"
        >
          Update
        </Button>
      </Stack>
    </form>
  );
};

export default ProjectEditForm;

import { useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";
import { useFilters } from "components/useFilters";

import DefaultReportPageHeader from "entities/Reporting/components/DefaultReportPageHeader";
import ReportTable from "entities/Reporting/components/ReportTable";
import { getFilteredColumns } from "entities/Reporting/components/ReportTable/utils";
import {
  DEFAULT_REPORT_SEARCH_KEYS,
  TABLE_SETTINGS_FILTER_KEY,
} from "entities/Reporting/constants";
import { INonPipelineColumns } from "entities/Reporting/sdk";
import {
  getFilterDictForDefaultReports,
  getFilteredDeals,
} from "entities/Reporting/utils";

const ProjectsViewContent = ({
  rows,
  columns,
  loading,
  isValidating,
  reportTitle,
}: {
  rows: { [key: string]: string | null }[];
  columns: INonPipelineColumns[];
  loading: boolean;
  isValidating: boolean;
  reportTitle: string;
}) => {
  const { filters } = useFilters();
  const filterDict = useMemo(() => getFilterDictForDefaultReports(), []);

  const filteredDeals = useMemo(
    () =>
      getFilteredDeals({
        rows,
        selectedFilters: filters,
        filterDict,
        searchKeys: DEFAULT_REPORT_SEARCH_KEYS,
      }),
    [filters, filterDict, rows]
  );

  const filteredColumns = useMemo(
    () =>
      getFilteredColumns({
        columns,
        tableSettingsFilter: filters[TABLE_SETTINGS_FILTER_KEY],
      }),
    [columns, filters]
  );

  return (
    <Stack spacing={2} sx={{ padding: theme.spacing(4, 3, 0), flex: 1 }}>
      <DefaultReportPageHeader
        reportTitle={reportTitle}
        rows={rows}
        columns={columns}
        loading={loading}
      />
      <Stack spacing={4} sx={{ flex: 1 }}>
        <Text
          variant="text3"
          sx={{
            color: colors.gray60,
          }}
          data-testid="total-deal-results"
        >
          Showing {filteredDeals.length} out of {rows.length} deals.
        </Text>
        {columns && (
          <ReportTable
            rows={filteredDeals}
            columns={filteredColumns}
            showLoading={loading || isValidating}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ProjectsViewContent;

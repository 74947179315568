import { useCallback, useEffect } from "react";

import { URLS } from "config/urls";
import { MoreVertIcon } from "icons";
import theme from "theme";
import { reverse } from "utils/urls";

import Button from "components/Button";
import ButtonDropdown from "components/ButtonDropdown";
import Link from "components/Link";
import { MenuItem } from "components/Menu";
import NoPrint from "components/NoPrint";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import ShareButton from "entities/Memo/components/ShareButton";

interface IMemoPageHeader {
  dealId: number;
  memoId: number;
  memoName: string;
  dealName: string;
  isShareableLink?: boolean;
}

const MemoPageHeader = ({
  dealId,
  memoId,
  memoName,
  dealName,
  isShareableLink,
}: IMemoPageHeader) => {
  const { data: user } = useSignedUser();

  const setPrintableTitle = useCallback(
    () =>
      (window.document.title = `Builders Patch - ${dealName} - ${memoName} - Memo`),
    [dealName, memoName]
  );

  const setDefaultTitle = useCallback(
    () =>
      (window.document.title = `Builders Patch: ${dealName} - ${memoName} - Memo`),
    [dealName, memoName]
  );

  useEffect(() => {
    window.addEventListener("beforeprint", setPrintableTitle);
    window.addEventListener("afterprint", setDefaultTitle);

    return () => {
      window.removeEventListener("beforeprint", setPrintableTitle);
      window.removeEventListener("afterprint", setDefaultTitle);
    };
  }, [setPrintableTitle, setDefaultTitle]);

  return (
    <NoPrint>
      <PageHeader
        title={memoName}
        actions={
          <Stack direction="row" spacing={1}>
            {!isShareableLink && user?.is_superuser && (
              <ButtonDropdown
                icon={<MoreVertIcon />}
                label=""
                variant="outlined"
                color="secondary"
                width="auto"
                sx={{
                  padding: theme.spacing(0.5, 0.75),
                  "& .MuiButton-endIcon": {
                    margin: 0,
                  },
                }}
              >
                <Link
                  to={reverse(URLS.MEMO_TEMPLATE_EDITOR, {
                    memoId: memoId,
                  })}
                >
                  <MenuItem>
                    <Text variant="text2">Edit template</Text>
                  </MenuItem>
                </Link>
              </ButtonDropdown>
            )}
            {!isShareableLink && (
              <ShareButton dealId={dealId} memoId={memoId} />
            )}
            <Button onClick={() => window.print()}>Download as PDF</Button>
          </Stack>
        }
      />
    </NoPrint>
  );
};

export default MemoPageHeader;

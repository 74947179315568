import * as yup from "yup";

import { AccessType } from "entities/Package/constants";

import { getInitialInvitation } from "./utils";

export const INITIAL_VALUES = {
  invitations: [getInitialInvitation()],
  message: "",
};

export const VALIDATION_SCHEMA = yup.object({
  message: yup.string(),
  invitations: yup.array().of(
    yup.object({
      email: yup.string().email("Must be a valid email.").required("Required."),
      access_type: yup.string().oneOf(Object.values(AccessType)).required(),
    })
  ),
});

export const ACCESS_TYPE_OPTIONS = [
  {
    label: "Read only",
    value: AccessType.READ_ONLY_ACCESS,
  },
  {
    label: "Edit access",
    value: AccessType.READ_WRITE_ACCESS,
  },
];

import _ from "lodash";

import { downloadAsXLSXWithMultipleSheets } from "utils/xlsx";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { COMPANY_SETTINGS_FEATURE_VISIBLE_TYPES_OPTIONS } from "entities/Company/components/CompanySettings/FeaturesList/constants";
import { getCompanySettingsFeatureVisibleType } from "entities/Company/components/CompanySettings/FeaturesList/utils";
import { ICompanySettings } from "entities/Company/sdk";
import {
  ISuperuserDashboardDeal,
  ISuperuserDashboardDealsCreatedByMonth,
} from "entities/Superuser/sdk";

export const exportTableAsXLSX = ({
  data,
  dealsCreatedByMonth,
  allDealsCount,
  totalUnitsCount,
}: {
  allDealsCount: number;
  data: Array<ISuperuserDashboardDeal>;
  dealsCreatedByMonth: Array<ISuperuserDashboardDealsCreatedByMonth>;
  totalUnitsCount: number;
}) => {
  const firstSheet = [
    ["Deals Count", allDealsCount],
    ["Units Count", totalUnitsCount],
    [],
    [
      "Date",
      ...dealsCreatedByMonth.map((dealsPerMonth) => dealsPerMonth.month),
    ],
    [
      "Total Deals",
      ...dealsCreatedByMonth.map((dealsPerMonth) => dealsPerMonth.deal_count),
    ],
  ];

  const secondSheet = [
    Object.keys(_.omit(data[0], "is_demo_deal")),
    ..._.map(data, (row) => {
      return Object.values(_.omit(row, "is_demo_deal"));
    }),
  ];

  downloadAsXLSXWithMultipleSheets(
    [
      {
        sheetName: "Overall deal info",
        data: firstSheet,
      },
      {
        sheetName: "Deal details",
        data: secondSheet,
      },
    ],
    `superuser_dashboard.xlsx`
  );
};

export const dealCountsFillInEmptyMonths = ({
  dealsCreatedByMonth,
}: {
  dealsCreatedByMonth: Array<{ month: string; deal_count: number }>;
}) => {
  if (_.isUndefined(dealsCreatedByMonth)) {
    return [];
  }

  const earliestMonthKey = _.last(dealsCreatedByMonth)?.month; // The objects are ordered by the BE.

  if (_.isUndefined(earliestMonthKey)) {
    return [];
  }

  // Extract the earliest year and month from the earliest month key
  const earliestYear = parseInt(earliestMonthKey.split("-")[0]);
  const earliestMonth = parseInt(earliestMonthKey.split("-")[1]);

  // Get the current year and month
  const currentDate = new Date();
  const latestYear = currentDate.getFullYear();
  const latestMonth = currentDate.getMonth() + 1;

  // Generate an array of month keys between the earliest and latest months
  const monthKeys = _.range(earliestYear, latestYear + 1)
    .flatMap((year) => {
      const startMonth = year === earliestYear ? earliestMonth : 1;
      const endMonth = year === latestYear ? latestMonth : 12;

      return _.range(startMonth, endMonth + 1).map(
        (month) => `${year}-${month.toString().padStart(2, "0")}-01`
      );
    })
    .reverse(); // Reverse the array so that the most recent months are first

  // Map each month key to an object representing the deal count for that month
  return monthKeys.map((monthKey) => {
    // Find the object in the data array that matches the month key
    const itemMatchingTheMonth = _.find(
      dealsCreatedByMonth,
      (item) => item.month === monthKey
    );

    // If no object is found, return an object with a deal count of 0
    if (_.isUndefined(itemMatchingTheMonth)) {
      return { month: monthKey, deal_count: 0 };
    }

    // Otherwise, return the object from the data array
    return itemMatchingTheMonth;
  });
};

export const getCompanySettingsFeatureLabel = ({
  feature,
  companySettings,
}: {
  feature: ECompanySettingsFeatureType;
  companySettings: ICompanySettings;
}) => {
  const featureType = getCompanySettingsFeatureVisibleType({
    feature,
    companySettings,
  });

  const label = _.find(
    COMPANY_SETTINGS_FEATURE_VISIBLE_TYPES_OPTIONS,
    (option) => option.value === featureType
  )?.label;

  return label;
};

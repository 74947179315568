import theme from "theme";
import { colors } from "theme/palette";
import { humanReadableNumber } from "utils/common";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

import ProformaTable from "entities/Proforma/components/ProformaTable";

export interface ISourcesAndUsesSection {
  title: string;
  subTitle: string;
  tableTotals: { [key: string]: string };
  total: number;
  rows: Array<{ [key: string]: string }>;
  headers: Array<{ [key: string]: string }>;
  sectionDataTestid?: string;
}

const SourcesAndUsesSection: React.FC<ISourcesAndUsesSection> = ({
  title,
  subTitle,
  total,
  tableTotals,
  rows,
  headers,
  sectionDataTestid,
}) => {
  return (
    <Box data-testid={sectionDataTestid}>
      <Stack
        sx={{
          borderBottom: `1px solid ${colors.blue20}`,
        }}
        direction="row"
        justifyContent="space-between"
      >
        <Box>
          <Text variant="h3" lineHeight="150%">
            {title}
          </Text>
          <Text variant="text2" textAlign="right" sx={{ color: colors.gray60 }}>
            {subTitle}
          </Text>
        </Box>
        <Box>
          <Text variant="h3" lineHeight="150%" align="right">
            ${humanReadableNumber(total)}
          </Text>
          <Text variant="text2" textAlign="right" sx={{ color: colors.gray60 }}>
            Total {title}
          </Text>
        </Box>
      </Stack>
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          rows={rows}
          headers={headers}
          totals={tableTotals}
          cellsWidth="50px"
          firstCellWidth="100px"
          lastCellWidth="30px"
        />
      </Box>
    </Box>
  );
};

export default SourcesAndUsesSection;

import React, { useCallback } from "react";

import { DeleteOutlineOutlinedIcon } from "icons";
import { colors } from "theme/palette";

import IconButton from "components/IconButton";
import { TableCell } from "components/Table";
import { useConfirmationDialog } from "components/useConfirmationDialog";

interface IDeleteCell {
  onDelete: () => void;
}

const DeleteCell: React.FC<IDeleteCell> = ({ onDelete }) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const onDeleteRow = useCallback(() => onDelete(), [onDelete]);

  const handleDelete = useCallback(() => {
    showConfirmationDialog({
      onConfirm: onDeleteRow,
      message: "Are you sure you want to delete this row?",
    });
  }, [onDeleteRow, showConfirmationDialog]);

  return (
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
      data-testid="table-cell-delete-row"
    >
      <IconButton
        size="small"
        onClick={handleDelete}
        title="Delete row"
        dataTestid="delete-table-row-button"
      >
        <DeleteOutlineOutlinedIcon sx={{ width: "20px", height: "20px" }} />
      </IconButton>
    </TableCell>
  );
};

export default DeleteCell;

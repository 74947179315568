import React from "react";

import Box from "components/Box";

import EditorRightsideMenu from "entities/MemoTemplate/components/EditorRightsideMenu";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

const WithEditorRightsideMenu: React.FC<{
  children: React.ReactNode;
  section: IMemoTemplateSection;
}> = ({ children, section }) => {
  return (
    <Box
      sx={{
        position: "relative",
        "&:hover ": {
          "& > .editableSectionToolset": { opacity: 1 },
          "& .editableSectionToolsetBracket": { opacity: 1 },
        },
      }}
    >
      <EditorRightsideMenu section={section} />
      {children}
    </Box>
  );
};

export default React.memo(WithEditorRightsideMenu);

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { CheckCircleOutlineIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogActions, DialogContent } from "components/Dialog";
import Text from "components/Text";

export interface IDraftSavedSuccessfullyDialogButton {
  applicationName: string;
}

const DraftSavedSuccessfullyDialogButton = ({
  applicationName,
}: IDraftSavedSuccessfullyDialogButton) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setIsOpen(true)}
      >
        Save draft
      </Button>
      <Dialog open={isOpen} maxWidth="sm" onClose={() => setIsOpen(false)}>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(4, 12),
          }}
        >
          <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 68 }} />

          <Text variant="h1" marginTop={3} marginBottom={1}>
            Draft successfully saved!
          </Text>

          <Text variant="text1" color={colors.gray80}>
            Intake name: <b>{applicationName}</b>
          </Text>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", marginBottom: 4 }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() =>
              navigate(reverse(URLS.APPLICATIONS_LIST, { status: "All" }))
            }
          >
            Intake dashboard
          </Button>
          <Button onClick={() => setIsOpen(false)}>Continue editing</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DraftSavedSuccessfullyDialogButton;

import _ from "lodash";

import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Text from "components/Text";

import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import { IMemoSection } from "entities/Memo/sdk";

interface IMemoSectionHeader {
  section: IMemoSection;
  dealId: number;
  onUpdate: () => void;
  externalToken?: string;
}

const MemoSectionHeader = ({
  section,
  dealId,
  onUpdate,
  externalToken,
}: IMemoSectionHeader) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Text
      variant="h2"
      sx={{
        opacity: section.is_na ? 0.5 : 1,
      }}
    >
      {section.title}
    </Text>

    {_.isUndefined(externalToken) && (
      <NoPrint sx={{ marginRight: 1 }}>
        <MemoSectionContextButton
          dealId={dealId}
          section={section}
          onUpdate={onUpdate}
        />
      </NoPrint>
    )}
  </Stack>
);

export default MemoSectionHeader;

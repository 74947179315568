import React from "react";

import ContentSeparator from "components/ContentSeparator";
import Stack from "components/Stack";
import Text from "components/Text";

import ScheduleTable from "entities/Deal/components/OverviewSchedule/ScheduleTable";
import { Phase } from "entities/Deal/constants";

interface IMemoTemplateSchedule {
  title: string;
}

const scheduleData = [
  {
    phase: {
      id: 1,
      name: Phase.APPLICATION,
    },
    start_date: new Date(),
    end_date: new Date(),
  },
  {
    phase: {
      id: 2,
      name: Phase.UNDERWRITING,
    },
    start_date: new Date(),
    end_date: new Date(),
  },
  {
    phase: {
      id: 3,
      name: Phase.ASSET_MANAGEMENT,
    },
    start_date: new Date(),
    end_date: new Date(),
  },
  {
    phase: {
      id: 4,
      name: Phase.CONSTRUCTION,
    },
    start_date: new Date(),
    end_date: new Date(),
  },
  {
    phase: {
      id: 5,
      name: Phase.CONVERSION,
    },
    start_date: new Date(),
    end_date: new Date(),
  },
];

const MemoTemplateSchedule: React.FC<IMemoTemplateSchedule> = ({ title }) => {
  return (
    <Stack spacing={2}>
      <Text variant="h2">Schedule</Text>
      <ContentSeparator label="Schedule" />
      <ScheduleTable schedule={scheduleData} />
    </Stack>
  );
};

export default MemoTemplateSchedule;

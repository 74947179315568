import * as Sentry from "@sentry/react";
import _ from "lodash";

import { BASE_URL } from "config/urls";
import { get, post, useFetch } from "utils/sdk";
import { clear as clearStorage } from "utils/storage";

import { IUser } from "entities/User/sdk";

export const useSignedUser = () => {
  const response = useFetch<IUser>("v2/users/me/");

  if (!_.isNil(response?.data)) {
    Sentry.setUser({
      id: response.data.id.toString(),
      email: response.data.email,
    });
  }

  return response;
};

export const logout = () =>
  get<{ url: string }>(`${BASE_URL}logout-redirect/`).then(({ url }) => {
    Sentry.setUser(null);
    clearStorage();
    window.location.href = url;
  });

export const getTokenBySession = () =>
  post<{ token: string }>(`${BASE_URL}api-token-auth`);

export const getUserFreshdeskToken = () =>
  get<{ freshdesk_token: string }>(`${BASE_URL}v2/users/my-freshdesk-token/`);

import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import Loading from "components/Loading";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import NotFound from "pages/404";

import { useSignedUser } from "entities/Auth/sdk";
import CompanyDealsTable from "entities/Superuser/components/CompanyDealsTable";
import KPIDealTable from "entities/Superuser/components/KPIDealTable";
import KPITable from "entities/Superuser/components/KPITable";
import {
  useSuperuserCompanyDeals,
  useSuperuserDashboardData,
  useSuperuserDashboardDealsCreatedByMonth,
  useSuperuserDashboardDemoDeals,
  useSuperuserDashboardTotalUnitsCount,
} from "entities/Superuser/sdk";
import { exportTableAsXLSX } from "entities/Superuser/utils";
import { dealCountsFillInEmptyMonths } from "entities/Superuser/utils";

const SuperuserDashboard = () => {
  const { data: user } = useSignedUser();

  const { data: dashboardData, isValidating: isValidatingDashboardData } =
    useSuperuserDashboardData();

  const { data: companyDeals, isLoading: isFetchingCompanyDeals } =
    useSuperuserCompanyDeals();
  const {
    data: dealsCreatedByMonth,
    isLoading: isFetchingDealsCreatedByMonth,
  } = useSuperuserDashboardDealsCreatedByMonth();
  const { data: demoDeals, isValidating: isValidatingDemoDeals } =
    useSuperuserDashboardDemoDeals();
  const { data: totalUnitsCount, isLoading: isFetchingTotalUnitsCount } =
    useSuperuserDashboardTotalUnitsCount();

  usePageTitle(`Builders Patch: Superuser Dashboard`);

  const allDealsCount = useMemo(() => {
    if (_.isUndefined(dealsCreatedByMonth)) {
      return 0;
    }

    return _.sumBy(dealsCreatedByMonth, "deal_count");
  }, [dealsCreatedByMonth]);

  const handleExportClick = useCallback(() => {
    if (
      _.isUndefined(dashboardData) ||
      _.isUndefined(dealsCreatedByMonth) ||
      _.isUndefined(totalUnitsCount)
    ) {
      return;
    }

    exportTableAsXLSX({
      data: dashboardData,
      dealsCreatedByMonth,
      allDealsCount,
      totalUnitsCount,
    });
  }, [dashboardData, dealsCreatedByMonth, allDealsCount, totalUnitsCount]);

  const dealsCreatedByMonthWithEmptyMonthsFilledIn = useMemo(
    () =>
      dealCountsFillInEmptyMonths({
        dealsCreatedByMonth: dealsCreatedByMonth || [],
      }),
    [dealsCreatedByMonth]
  );

  const isFetchingDashboardDataCompanyDealsDealsByMonthUser =
    isFetchingCompanyDeals ||
    isFetchingDealsCreatedByMonth ||
    isFetchingTotalUnitsCount ||
    _.isUndefined(companyDeals) ||
    _.isUndefined(dealsCreatedByMonth) ||
    _.isUndefined(totalUnitsCount) ||
    _.isUndefined(user);

  if (isFetchingDashboardDataCompanyDealsDealsByMonthUser) {
    return <Loading open />;
  }

  if (!user.is_superuser) {
    return <NotFound />;
  }

  return (
    <Box
      sx={{
        padding: theme.spacing(4, 3, 0),
      }}
    >
      <Stack spacing={2}>
        <PageHeader
          title="Superuser Dashboard"
          subTitle={
            <Text variant="text2">
              Welcome {user.first_name} {user.last_name} ({user.email})
            </Text>
          }
          actions={<Button onClick={handleExportClick}>Export</Button>}
        />

        <Stack spacing={3}>
          <Box>
            <Stack spacing={2} direction="row">
              <Text variant="text2">Deals Count</Text>
              <Text variant="text2" sx={{ fontWeight: "bold" }}>
                {allDealsCount.toLocaleString()}
              </Text>
            </Stack>

            <Stack spacing={2} direction="row">
              <Text variant="text2">Units Count</Text>
              <Text variant="text2" sx={{ fontWeight: "bold" }}>
                {totalUnitsCount.toLocaleString()}
              </Text>
            </Stack>
          </Box>

          <Stack
            spacing={2}
            direction="row"
            sx={{
              padding: "20px 0 20px 0",
              overflowX: "auto",
              maxWidth: "100%",
              "table, th, td": {
                border: `1px solid ${colors.blue20}`,
                borderCollapse: "collapse",
              },
            }}
          >
            <KPITable
              dealsCreatedByMonth={dealsCreatedByMonthWithEmptyMonthsFilledIn}
            />
          </Stack>

          <Text variant="h3">Deals</Text>
          <KPIDealTable
            dataIsDemo={false}
            deals={dashboardData || []}
            showLoading={isValidatingDashboardData}
          />

          {!_.isUndefined(demoDeals) && !_.isEmpty(demoDeals) && (
            <>
              <Text variant="h3">Demo Deals</Text>
              <KPIDealTable
                dataIsDemo={true}
                deals={demoDeals}
                showLoading={isValidatingDemoDeals}
              />
            </>
          )}

          {companyDeals.length > 0 && (
            <Stack
              sx={{
                "table, th, td": {
                  border: `1px solid ${colors.blue20}`,
                  borderCollapse: "collapse",
                },
              }}
            >
              <Text variant="h3">Company deals</Text>
              <CompanyDealsTable companyDeals={companyDeals} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SuperuserDashboard;

import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { AddIcon, LockOutlinedIcon, OpenInNewIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Link from "components/Link";
import ListPagination from "components/ListPagination";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { usePageTitle } from "components/usePageTitle";

import {
  dealTableDelete,
  useDealDetails,
  useDealTables,
} from "entities/Deal/sdk";
import DeleteButton from "entities/TableStorage/components/DeleteButton";
import InfoBanner from "entities/TableStorage/components/InfoBanner";
import TableCountMessage from "entities/TableStorage/components/TableCountMessage";
import TableImportCreateDialogButton from "entities/TableStorage/components/TableImportCreateDialogButton";

const TableStorage = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });

  usePageTitle(
    deal?.name
      ? `Builders Patch: ${deal.name} - Table storage list`
      : "Builders Patch: Table storage list"
  );

  const {
    data,
    pagesCount,
    setPage,
    currentPage,
    isLoading,
    mutate: mutateTables,
  } = useDealTables({ dealId });
  const navigate = useNavigate();

  const showPagination = useMemo(
    () => !isLoading && !_.isUndefined(data) && data.count > 0,
    [isLoading, data]
  );

  const currentPageData = useMemo(() => _.get(data, "results", []), [data]);

  const tableCountMessage = useMemo(
    () => `${data?.count} table${data?.count !== 1 ? "s" : ""} created`,
    [data]
  );

  const handleTableDelete = useCallback(
    ({ tableId, tableName }) =>
      dealTableDelete({ tableId, dealId })
        .then(() => {
          toast.successMessage(`Table "${tableName}" deleted successfully`);
          mutateTables();
        })
        .catch(handleInvalidRequest),
    [dealId, mutateTables]
  );

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
          spacing={4}
        >
          <PageHeader
            title="Saved data tables"
            actions={
              <Stack direction="row" spacing={1}>
                <TableImportCreateDialogButton
                  dealId={dealId}
                  createCallback={() => {
                    mutateTables();
                  }}
                />
                <Button
                  onClick={() =>
                    navigate(
                      reverse(URLS.DEAL_TABLE_STORAGE_CREATE, { dealId })
                    )
                  }
                  startIcon={<AddIcon />}
                >
                  Create new table
                </Button>
              </Stack>
            }
          />

          {!isLoading && currentPageData.length === 0 && (
            <Box marginTop={4}>
              <InfoBanner />
            </Box>
          )}

          {_.get(data, "count", 0) > 0 && (
            <Stack>
              <Box
                sx={{
                  paddingBottom: 1,
                  borderBottom: `1px solid ${colors.gray20}`,
                }}
              >
                <TableCountMessage countMessage={tableCountMessage} />
              </Box>
              {currentPageData.map((table) => (
                <Stack
                  key={table.id}
                  spacing={6}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    padding: theme.spacing(3, 3, 3, 2),
                    borderBottom: `1px solid ${colors.gray20}`,
                  }}
                >
                  <Stack flex={1}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      flexBasis="45%"
                    >
                      <Text variant="text1" fontWeight={700} noWrap>
                        {table.name}
                      </Text>
                      {table.is_locked && (
                        <Tooltip title="This table is locked. The data is coming from the underwriting proforma upload.">
                          <LockOutlinedIcon
                            sx={{ width: "20px", height: "20px" }}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                    {_.isNil(table.updated_by) ? (
                      <Text variant="text4" sx={{ color: colors.gray60 }}>
                        Last edited{" "}
                        {format(
                          parseJSON(table.created_at),
                          FORMATS.FRONTEND.DATETIME
                        )}{" "}
                        by {table.created_by.first_name}{" "}
                        {table.created_by.last_name}
                      </Text>
                    ) : (
                      <Text variant="text4" sx={{ color: colors.gray60 }}>
                        Last edited{" "}
                        {format(
                          parseJSON(table.updated_at),
                          FORMATS.FRONTEND.DATETIME
                        )}{" "}
                        by {table.updated_by.first_name}{" "}
                        {table.updated_by.last_name}
                      </Text>
                    )}
                  </Stack>
                  <Stack
                    flexBasis="20%"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <DeleteButton
                      onDelete={() =>
                        handleTableDelete({
                          tableId: table.id,
                          tableName: table.name,
                        })
                      }
                    />
                    <Link
                      to={reverse(URLS.DEAL_TABLE_STORAGE_DETAILS, {
                        dealId,
                        tableId: table.id,
                      })}
                    >
                      <Tooltip title="Open this table">
                        <IconButton>
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Stack>
                </Stack>
              ))}
              {showPagination && (
                <Box marginTop={3}>
                  <ListPagination
                    setPage={setPage}
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                  />
                </Box>
              )}
              <Box marginTop={4}>
                <InfoBanner />
              </Box>
            </Stack>
          )}

          {isLoading && (
            <Box>
              {_.range(5).map((i) => (
                <Stack
                  key={i}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: `1px solid ${colors.gray20}`,
                  }}
                >
                  <Skeleton height={95} width={300} />
                  <Stack spacing={1} direction="row">
                    <Skeleton height={50} width={30} />
                    <Skeleton height={50} width={30} />
                  </Stack>
                </Stack>
              ))}
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TableStorage;

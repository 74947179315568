export const HEADERS = [
  "Intake name",
  "Deal name",
  "Checklist name",
  "Tab",
  "Category",
  "Section",
  "Field",
  "Field format type",
  "Previous value",
  "New value",
  "Can be migrated",
];

export const getErrorMessageforField = ({ fieldId }: { fieldId: number }) =>
  `Field with id "${fieldId}" does not exists.`;

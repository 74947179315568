import theme from "theme";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import BackLink from "./BackLink";

export interface IPageHeader {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  actions?: React.ReactNode;
  dataTestid?: string;
  backLink?: string;
  backTitle?: string;
}

const PageHeader = ({
  title,
  subTitle,
  actions,
  dataTestid,
  backLink,
  backTitle,
}: IPageHeader) => (
  <Paper
    sx={{ background: colors.blue10, padding: theme.spacing(2) }}
    data-testid="page-header-section"
  >
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-testid={dataTestid}
    >
      <Box>
        {isNotUndefined(backLink) && isNotUndefined(backTitle) && (
          <BackLink
            to={backLink}
            linkDataTestid="back-button-link"
            buttonLabelDataTestid="back-button-label"
          >
            {backTitle}
          </BackLink>
        )}

        <Text variant="h2" data-testid="page-header-name">
          {title}
        </Text>
        {subTitle && (
          <Text variant="text3" data-testid="page-sub-header-name">
            {subTitle}
          </Text>
        )}
      </Box>
      {actions}
    </Stack>
  </Paper>
);

export default PageHeader;

import Tag from "components/Tag";
import Tooltip from "components/Tooltip";

export interface ITagCell {
  cellData: string | number;
  title: string;
}

const TagCell = ({ cellData, title }: ITagCell) => {
  return (
    <Tooltip title={title}>
      <Tag label={cellData.toString()} />
    </Tooltip>
  );
};
export default TagCell;

import React, { useState } from "react";

import Box from "components/Box";
import Button from "components/Button";

import AddSectionDialog from "entities/MemoTemplate/components/AddSectionDialog";

export interface IAddSectionButton {
  children?: React.ReactNode;
  afterMemoSectionId: number | null;
}

const AddSectionButton = ({
  children,
  afterMemoSectionId,
}: IAddSectionButton) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {children ? (
        <Box onClick={() => setOpen(true)}>{children}</Box>
      ) : (
        <Button onClick={() => setOpen(true)}>Add Section</Button>
      )}
      <AddSectionDialog
        open={open}
        afterMemoSectionId={afterMemoSectionId}
        onClose={() => setOpen(false)}
        onSectionCreated={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default AddSectionButton;

import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined } from "utils/common";

import Box from "components/Box";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IRentalIncomeData } from "entities/Proforma/sdk";

export interface IProformaFloorArea {
  rentalIncomeData: IRentalIncomeData;
}

const headerFloorArea: Array<{ key: string; name: string }> = [
  { key: "unittype", name: "Unit Type" },
  { key: "renttype", name: "Rent Type" },
  { key: "units", name: "Units" },
  { key: "sqfunit", name: "SQF/Unit" },
  { key: "totalsqf", name: "Total SQF" },
  { key: "rentsqf", name: "Rent/SQF" },
];

const ProformaFloorArea: React.FC<IProformaFloorArea> = ({
  rentalIncomeData,
}) => {
  const floorAreaRows = useMemo(
    () =>
      _.orderBy(
        _.map(
          rentalIncomeData.rental_data.unit_type_and_floor_area.data,
          (value) => {
            if (value.value !== 0)
              return {
                unittype: value.name,
                renttype: value.rent_cap,
                units: value.numberofunits,
                sqfunit: formatNumberToString({ number: value.sqf_area }),
                totalsqf: formatNumberToString({ number: value.total_sqf }),
                rentsqf: formatNumberToString({ number: value.rent }),
                position: value.position,
              };
          }
        ).filter(isNotUndefined),
        "position"
      ),
    [rentalIncomeData]
  );

  const tableTotals = useMemo(() => {
    if (_.isNil(rentalIncomeData.rental_data.unit_type_and_floor_area.total)) {
      return {
        unittype: "Total",
        renttype: "-",
        units: "-",
        sqfunit: "-",
        totalsqf: "-",
        rentsqf: "-",
      };
    }

    return {
      unittype: "Total",
      renttype: "-",
      units:
        rentalIncomeData.rental_data.unit_type_and_floor_area.total.units.value,
      sqfunit: isNaN(
        rentalIncomeData.rental_data.unit_type_and_floor_area.total.sqf_area
          .value
      )
        ? rentalIncomeData.rental_data.unit_type_and_floor_area.total.sqf_area
            .value
        : formatNumberToString({
            number:
              rentalIncomeData.rental_data.unit_type_and_floor_area.total
                .sqf_area.value,
          }),
      totalsqf: isNaN(
        rentalIncomeData.rental_data.unit_type_and_floor_area.total.total_sqf
          .value
      )
        ? rentalIncomeData.rental_data.unit_type_and_floor_area.total.total_sqf
            .value
        : formatNumberToString({
            number:
              rentalIncomeData.rental_data.unit_type_and_floor_area.total
                .total_sqf.value,
          }),
      rentsqf: isNaN(
        rentalIncomeData.rental_data.unit_type_and_floor_area.total.rent.value
      )
        ? rentalIncomeData.rental_data.unit_type_and_floor_area.total.rent.value
        : formatNumberToString({
            number:
              rentalIncomeData.rental_data.unit_type_and_floor_area.total.rent
                .value,
          }),
    };
  }, [rentalIncomeData]);

  const totalSqfArea: number = useMemo(() => {
    if (_.isNil(rentalIncomeData.rental_data.unit_type_and_floor_area.total)) {
      return 0;
    }

    return rentalIncomeData.rental_data.unit_type_and_floor_area.total.total_sqf
      .value;
  }, [rentalIncomeData]);

  const totals = [
    {
      label: "Total SQF Area",
      value: totalSqfArea,
      type: ProformaHeaderTypeTotal.SQF,
    },
  ];

  return (
    <ProformaPaper
      title="Floor Area"
      subTitle="Residential SQF Area"
      totals={totals}
    >
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          rows={floorAreaRows}
          headers={headerFloorArea}
          totals={tableTotals}
        />
      </Box>
    </ProformaPaper>
  );
};

export default ProformaFloorArea;

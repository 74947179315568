import React from "react";

import Box from "components/Box";
import placeholderIcon from "components/DealAvatar/assets/default.png";
import Image from "components/Image";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface IMemoTemplateImageSection {
  title: string;
}

const MemoTemplateImageSection: React.FC<IMemoTemplateImageSection> = ({
  title,
}) => {
  return (
    <Box>
      <SectionHeader label={title} />
      <SectionContent breakInside="avoid">
        <Box
          sx={{
            breakInside: "avoid",
          }}
        >
          <Image
            src={placeholderIcon}
            sx={{ height: "460px", width: "100%", objectFit: "cover" }}
          />
        </Box>
      </SectionContent>
    </Box>
  );
};

export default MemoTemplateImageSection;

import React from "react";
import { useCallback, useState } from "react";

import theme from "theme";

import Button, { IButton } from "components/Button";
import Menu from "components/Menu";

export interface IButtonDropdown extends IButton {
  label: string | React.ReactNode;
  width?: string;
  closeOnClick?: boolean;
  icon?: React.ReactNode;
  buttonDataTestid?: string;
  keepMenuMounted?: boolean;
}

const ButtonDropdown: React.FC<IButtonDropdown> = ({
  label,
  width = "120px",
  icon,
  children,
  closeOnClick = true,
  keepMenuMounted = false,
  buttonDataTestid,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-dropdown" : undefined;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Button
        {...props}
        sx={{
          padding: icon ? theme.spacing(1, 2) : "",
          width: width,
          justifyContent: icon ? "space-between" : "center",
          whiteSpace: "nowrap",
          minWidth: "max-content",
          "& .MuiButton-endIcon": {
            margin: icon && label === "" ? "0px" : "0px -4px 0px 8px",
          },
          ...props.sx,
        }}
        endIcon={icon}
        onClick={handleClick}
        data-testid={buttonDataTestid}
        aria-describedby={id}
      >
        {label}
      </Button>
      {children ? (
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={closeOnClick ? handleClose : undefined}
          sx={{
            "& .MuiMenu-paper": {
              maxHeight: "400px",
            },
          }}
          keepMounted={keepMenuMounted}
        >
          {children}
        </Menu>
      ) : null}
    </>
  );
};

export default ButtonDropdown;

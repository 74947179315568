import { memo, useCallback } from "react";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import {
  deleteCompanyChecklist,
  useCompanyPackageCheckIsDeletable,
} from "entities/Package/sdk";

const DialogSkeleton = () => (
  <Stack padding={2} spacing={4} width={640}>
    <DialogTitle
      sx={{
        marginTop: theme.spacing(2),
        borderRadius: 2,
        backgroundColor: colors.blue10,
      }}
    >
      <Skeleton variant="text" height={40} width={120} />
      <Skeleton height={100} />
    </DialogTitle>
    <Stack
      spacing={1}
      direction="row"
      justifyContent="end"
      data-testid="form-action-buttons"
    >
      <Skeleton height={60} width={80} />
      <Skeleton height={60} width={80} />
    </Stack>
  </Stack>
);

export interface IDeleteChecklistDialog {
  isOpen: boolean;
  onClose: () => void;
  packageId: number;
}

const DeleteChecklistDialog = ({
  isOpen,
  onClose,
  packageId,
}: IDeleteChecklistDialog) => {
  const { data, isLoading } = useCompanyPackageCheckIsDeletable({
    packageId,
    disabled: !isOpen,
  });

  const navigate = useNavigate();

  const handleDeleteClick = useCallback(() => {
    deleteCompanyChecklist({ packageId })
      .then(() => {
        navigate(reverse(URLS.COMPANY_CHECKLISTS, {}));
        toast.successMessage("Checklist deleted successfully!");
      })
      .catch(handleInvalidRequest);
  }, [navigate, packageId]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      {_.isUndefined(data) || isLoading ? (
        <DialogSkeleton />
      ) : (
        <>
          {data.is_deletable ? (
            <Stack padding={2} spacing={4} width={640}>
              <DialogTitle
                sx={{
                  marginTop: theme.spacing(2),
                  borderRadius: 2,
                  backgroundColor: colors.blue10,
                }}
              >
                <Stack spacing={1}>
                  <Text variant="h2" color={colors.gray100}>
                    Delete template
                  </Text>
                  <Text variant="text2" color={colors.gray80}>
                    Deleting this template will also remove it from all your
                    deals it has been added to! Are you sure you want to delete
                    it?
                  </Text>
                </Stack>
              </DialogTitle>
              <Stack
                direction="row"
                justifyContent="end"
                data-testid="form-action-buttons"
                spacing={1}
              >
                <Button
                  variant="text"
                  onClick={onClose}
                  data-testid="close-button"
                >
                  Cancel
                </Button>
                <Button
                  data-testid="submit-button"
                  onClick={handleDeleteClick}
                  color="error"
                >
                  Yes, delete
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack padding={2} spacing={4} width={640}>
              <DialogTitle
                sx={{
                  marginTop: theme.spacing(2),
                  borderRadius: 2,
                  backgroundColor: colors.blue10,
                }}
              >
                <Stack spacing={1}>
                  <Text variant="h2" color={colors.gray100}>
                    Deleting not allowed...
                  </Text>
                  <Text variant="text2" color={colors.gray80}>
                    This template is connected to other templates within your
                    organization. It cannot be deleted unless these connections
                    are removed. Please contact our Customer Success Team if you
                    want to remove these connections, or want the connected
                    templates deleted.
                  </Text>
                </Stack>
              </DialogTitle>
              <Stack
                direction="row"
                justifyContent="end"
                spacing={1}
                data-testid="form-action-buttons"
              >
                <Button
                  variant="text"
                  onClick={onClose}
                  data-testid="close-button"
                >
                  Cancel
                </Button>
                <Button onClick={onClose} color="primary">
                  Ok
                </Button>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </Dialog>
  );
};

export default memo(DeleteChecklistDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import React, { useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { formatNumberToString, useCaching } from "utils/common";

import Box from "components/Box";
import Chart from "components/Chart";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import MetricsTitleValue from "entities/Proforma/components/ProformaPLProjections/Metrics/TitleValue";
import { IPLProjection } from "entities/Proforma/sdk";

interface IMetrics {
  startYear: number;
  plProjectionData: IPLProjection;
}

const Metrics: React.FC<IMetrics> = ({ startYear, plProjectionData }) => {
  const [visibleYear, setVisibleYear] = useCaching(startYear);

  const titles: Array<{ title: string; value: string }> = useMemo(
    () => [
      {
        title: "Debt Service",
        value: formatNumberToString({
          number: plProjectionData.p_and_l.metrics.ds[visibleYear],
        }),
      },
      {
        title: "NOI",
        value: formatNumberToString({
          number: plProjectionData.p_and_l.metrics.noi[visibleYear],
        }),
      },
      {
        title: "DSCR",
        value: `1:${formatNumberToString({
          number: plProjectionData.p_and_l.metrics.dscr[visibleYear],
        })}`,
      },
      {
        title: "Total Effective Gross Income (EGI)",
        value: formatNumberToString({
          number:
            plProjectionData.p_and_l.revenues.total_effective_gross_income[
              visibleYear
            ],
        }),
      },
      {
        title: "Total Gross Expenses",
        value: formatNumberToString({
          number:
            plProjectionData.p_and_l.expenses.total_gross_expenses[visibleYear],
        }),
      },
      {
        title: "OER",
        value: `${formatNumberToString({
          number: plProjectionData.p_and_l.metrics.oer[visibleYear],
        })}%`,
      },
    ],
    [plProjectionData.p_and_l, visibleYear]
  );

  return (
    <Paper
      sx={{ flex: 8, padding: theme.spacing(2, 4) }}
      data-testid="metrics-chart-section"
    >
      <Text variant="h2">Metrics</Text>
      <Stack sx={{ height: "100%" }}>
        <Chart
          onBarHover={(barIndex) => setVisibleYear(startYear + barIndex)}
          labels={plProjectionData.p_and_l.years}
          datasets={[
            {
              label: "NOI",
              data: plProjectionData.p_and_l.years.map(
                (label) => plProjectionData.p_and_l.metrics.noi[label]
              ),
              backgroundColor: "#8383ff",
            },
            {
              label: "Debt Service",
              data: plProjectionData.p_and_l.years.map(
                (label) => plProjectionData.p_and_l.metrics.ds[label]
              ),
              backgroundColor: "#c9c9ff",
            },
          ]}
        />
        <Stack spacing={4}>
          <Stack alignItems="center" justifyContent="center">
            <Box
              sx={{
                padding: theme.spacing(1, 2),
                border: "1px solid #f6f7fb",
                backgroundColor: "#f6f7fb",
              }}
            >
              <Text
                variant="text1"
                fontWeight="700"
                sx={{ color: colors.blue120 }}
              >
                {visibleYear}
              </Text>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              rowGap: theme.spacing(3),
              columnGap: "28%",
            }}
          >
            {titles.map(({ title, value }, index) => (
              <MetricsTitleValue key={index} title={title} value={value} />
            ))}
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Metrics;

import { useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { copyTextToClipboard } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions } from "components/Dialog";
import { Link } from "components/Link";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

export interface IShareableLinkPopup {
  open: boolean;
  onClose: () => void;
  shareableLink: string;
}

const ShareableLinkPopup = ({
  open,
  onClose,
  shareableLink,
}: IShareableLinkPopup) => {
  const handleCopyLink = useCallback(() => {
    copyTextToClipboard({ text: shareableLink });
    toast.successMessage("URL has been copied to your clipboard.");
    onClose();
  }, [shareableLink, onClose]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <Box
        sx={{ padding: theme.spacing(2) }}
        data-testid="shared-intake-url-dialog"
      >
        <Stack
          gap="6px"
          sx={{
            backgroundColor: colors.blue10,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
          }}
          data-testid="dialog-header-section"
        >
          <Text variant="h3" data-testid="dialog-header-name">
            Share via URL link
          </Text>
        </Stack>
        <Stack sx={{ padding: theme.spacing(2, 2, 3) }}>
          {shareableLink ? (
            <Link
              style={{
                textDecoration: "underline",
                color: colors.blue100,
                fontWeight: 500,
                wordBreak: "break-all",
              }}
              to={shareableLink}
              external
              target="_blank"
              linkDataTestid="intake-share-url-link"
            >
              {shareableLink}
            </Link>
          ) : (
            <Skeleton />
          )}
        </Stack>
        <DialogActions sx={{ padding: 0 }} data-testid="dialog-action-buttons">
          <Button
            onClick={() => onClose()}
            variant="text"
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          {shareableLink && (
            <Button onClick={handleCopyLink} data-testid="submit-button">
              Copy link to clipboard
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ShareableLinkPopup;

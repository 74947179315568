import React, { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import ScheduleEditDialog from "entities/Deal/components/OverviewSchedule/ScheduleEditDialog";

export interface IScheduleEditButton {}

const ScheduleEditButton: React.FC<IScheduleEditButton> = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        data-testid="edit-schedule-section-button"
      >
        <EditIcon />
      </IconButton>
      <ScheduleEditDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default ScheduleEditButton;

import _ from "lodash";

import { formatExcelValue } from "utils/common";
import { isAfter, isBefore, isSameDay } from "utils/datetime";
import {
  downloadListOfListsAsXLSX,
  IColumnFormatsForXLSXExport,
} from "utils/xlsx";

import { IFilters } from "components/useFilters";

import { getPropertyTypeLabelByValue } from "entities/Deal/utils";
import { HARDCODED_REPORT_FILTERS_KEYS } from "entities/Reporting/components/FiltersButton/constants";
import { getCorrectHeaderValue } from "entities/Reporting/components/ReportTable/utils";
import {
  ASSET_TYPE_COLUMN_KEY,
  EFieldFormatType,
  PROPERTY_TYPE_COLUMN_KEY,
  SEARCH_STRING_FILTER_KEY,
} from "entities/Reporting/constants";
import { IReportPackageFilter } from "entities/Reporting/sdk";

export enum EReportType {
  CUSTOM_REPORT = "custom-report",
  DEFAULT_REPORT = "default-report",
}

export const exportReportPackageAsCSV = ({
  columns,
  rows,
  reportType,
  reportPackageName,
}: {
  columns: Array<{
    children: {
      name: string;
      field_type: string;
      field_format_type: string;
    }[];
    name: string;
  }>;
  rows: any[];
  reportType: EReportType;
  reportPackageName: string | undefined;
}) => {
  const headersData = columns.flatMap((column) =>
    column.children.map((subColumn) => getCorrectHeaderValue(subColumn.name))
  );

  const subColumns = columns.flatMap((column) => column.children);

  const columnFormats: IColumnFormatsForXLSXExport =
    getListOfListsWithColumnFormats({
      columns: subColumns,
    });

  const rowsData = rows.map((data) => {
    return subColumns.map((subColumn) => {
      // Retrieve the original value.
      const cellData = _.isNil(_.get(data, subColumn.field_type, undefined))
        ? _.get(data, subColumn.name, undefined)
        : _.get(data, subColumn.field_type, undefined); // This is for a predefined field.

      if (
        !_.isNil(_.get(data, subColumn.field_type)) &&
        (subColumn.field_type.toLocaleLowerCase() ===
          PROPERTY_TYPE_COLUMN_KEY ||
          subColumn.field_type === ASSET_TYPE_COLUMN_KEY)
      ) {
        return formatExcelValue({
          value: getPropertyTypeLabelByValue({
            value: cellData.trim(),
          }),
        });
      }

      return formatExcelValue({ value: cellData });
    });
  });

  const preparedData = [headersData, ...rowsData];
  downloadListOfListsAsXLSX(
    preparedData,
    `${reportType}-${_.kebabCase(reportPackageName)}.xlsx`,
    columnFormats
  );
};

export const getFilteredDeals = ({
  rows,
  selectedFilters,
  filterDict,
  searchKeys,
}: {
  rows: { [key: string]: string | null }[];
  selectedFilters: IFilters;
  filterDict:
    | { name: string; field_format_type: EFieldFormatType }[]
    | undefined;
  searchKeys: string[];
}) => {
  const searchString = !_.isEmpty(selectedFilters[SEARCH_STRING_FILTER_KEY])
    ? selectedFilters[SEARCH_STRING_FILTER_KEY]?.toString()
    : "";

  const deals = searchString
    ? rows.filter((row) =>
        searchKeys
          .map((key) => {
            const value = _.get(
              _.mapKeys(row, (value, rowKey) => rowKey.toLowerCase()),
              key
            );
            return (
              value &&
              value.toLowerCase().indexOf(searchString?.toLowerCase()) >= 0
            );
          })
          .reduce((res, current) => res || current)
      )
    : rows;

  if (_.isEmpty(selectedFilters) || _.isUndefined(filterDict)) {
    return deals;
  }

  return deals.filter((deal) =>
    filterDict.every(({ name: key, field_format_type: fieldFormatType }) => {
      const dealPropertyObject = _.pickBy(
        deal,
        (_: unknown, dealKey: string) => {
          return dealKey.toLowerCase() === key.toLowerCase();
        }
      );

      if (_.isEmpty(dealPropertyObject)) {
        return true;
      }

      const dealPropertyObjectKey = Object.keys(dealPropertyObject)[0];

      if (_.isNull(dealPropertyObjectKey)) {
        return false;
      }

      const rawValue = dealPropertyObject[dealPropertyObjectKey];

      const value = rawValue ? rawValue.toString() : null;

      const selectedFilterValue = selectedFilters[dealPropertyObjectKey];

      if (
        selectedFilterValue &&
        _.includes(
          [EFieldFormatType.DATE, EFieldFormatType.DATETIME],
          fieldFormatType
        )
      ) {
        const min = _.get(selectedFilterValue, "0") || null;
        const max = _.get(selectedFilterValue, "1") || null;

        const formattedMinDate =
          min && typeof min === "string" ? new Date(min) : null;

        const formattedMaxDate =
          max && typeof max === "string" ? new Date(max) : null;

        if (_.isNull(formattedMinDate) && _.isNull(formattedMaxDate)) {
          return true;
        }

        if (_.isNil(value)) {
          return false;
        }

        const valueAsDate = new Date(value);

        if (_.isNull(formattedMinDate) && !_.isNull(formattedMaxDate)) {
          return (
            isBefore(valueAsDate, formattedMaxDate) ||
            isSameDay(valueAsDate, formattedMaxDate)
          );
        }

        if (_.isNull(formattedMaxDate) && !_.isNull(formattedMinDate)) {
          return (
            isAfter(valueAsDate, formattedMinDate) ||
            isSameDay(valueAsDate, formattedMinDate)
          );
        }

        if (!_.isNull(formattedMaxDate) && !_.isNull(formattedMinDate)) {
          return (
            isSameDay(valueAsDate, formattedMinDate) ||
            isSameDay(valueAsDate, formattedMaxDate) ||
            (isAfter(valueAsDate, formattedMinDate) &&
              isBefore(valueAsDate, formattedMaxDate))
          );
        }
      } else if (
        selectedFilterValue &&
        _.includes(
          [
            EFieldFormatType.INTEGER,
            EFieldFormatType.FLOAT_2,
            EFieldFormatType.CURRENCY_USD,
          ],
          fieldFormatType
        )
      ) {
        const min = _.get(selectedFilterValue, "0") || null;
        const max = _.get(selectedFilterValue, "1") || null;

        if (_.isNil(min) && _.isNil(max)) {
          return true;
        }

        if (_.isNil(value)) {
          return false;
        }

        const valueAsNumber = Number(value);

        if (_.isNil(min) && !_.isNil(max)) {
          return valueAsNumber <= Number(max);
        }

        if (!_.isNil(min) && _.isNil(max)) {
          return valueAsNumber >= Number(min);
        }

        if (!_.isNil(min) && !_.isNil(max)) {
          return valueAsNumber >= Number(min) && valueAsNumber <= Number(max);
        }
      } else if (selectedFilterValue && selectedFilterValue.length > 0) {
        if (_.isNil(value)) {
          return false;
        }

        return _.includes(selectedFilterValue, value);
      }

      return true;
    })
  );
};

export const getFilterDictForDefaultReports = (): Array<IReportPackageFilter> =>
  HARDCODED_REPORT_FILTERS_KEYS.map((key: string) => ({
    name: key,
    field_format_type: EFieldFormatType.STRING,
  }));

const getListOfListsWithColumnFormats = ({
  columns,
}: {
  columns: Array<{
    name: string;
    field_type: string;
    field_format_type: string;
  }>;
}): IColumnFormatsForXLSXExport => {
  const columnFormats: IColumnFormatsForXLSXExport = {
    dateColumns: [],
    dateTimeColumns: [],
    currencyColumns: [],
    integerColumns: [],
    floatColumns: [],
  };

  columns.forEach((subColumn, subColIndex) => {
    // Collect formats for each column.
    switch (subColumn.field_format_type) {
      case EFieldFormatType.CURRENCY_USD:
        columnFormats.currencyColumns.push(subColIndex);
        break;
      case EFieldFormatType.INTEGER:
        columnFormats.integerColumns.push(subColIndex);
        break;
      case EFieldFormatType.FLOAT_2:
        columnFormats.floatColumns.push(subColIndex);
        break;
      case EFieldFormatType.DATE:
        columnFormats.dateColumns.push(subColIndex);
        break;
      case EFieldFormatType.DATETIME:
        columnFormats.dateTimeColumns.push(subColIndex);
        break;
    }
  });

  return columnFormats;
};

import _ from "lodash";

/**
 * This method is used to convert the data that is pasted from Excel into a 2D array of strings.
 * Each row in the array represents a row in the table, and each element in the row represents a cell in the table.
 * It's possible to have a new line in some cell, so we need to handle that case. Otherwise, we are using the new line to separate the rows.
 */
export const convertPastedDataToTableRows = ({
  data,
}: {
  data: string;
}): Array<Array<string>> => {
  const tableDataRows: Array<Array<string>> = [];
  let currentRow: Array<string> = [];
  let withinQuotes = false;
  let currentCell = "";

  for (let i = 0; i < data.length; i++) {
    const char = data[i];

    if (char === '"') {
      withinQuotes = !withinQuotes;
    } else if (char === "\n" && !withinQuotes) {
      currentRow.push(currentCell.trim());
      tableDataRows.push(currentRow);
      currentRow = [];
      currentCell = "";
    } else if (char === "\t" && !withinQuotes) {
      currentRow.push(currentCell.trim());
      currentCell = "";
    } else {
      currentCell += char;
    }
  }

  // Add the last cell to the current row
  currentRow.push(currentCell.trim());
  tableDataRows.push(currentRow);

  return tableDataRows;
};

/**
 * This method is used to convert the data that is used to represent SimpleTable into the format that is used to store the data in the database.
 * In the database we want to save as field value Array<{ order: number; columns: Array<string> }>.
 */
export const convertSimpleTableDataToFieldValue = ({
  tableDataRows,
}: {
  tableDataRows: Array<Array<string>>;
}): Array<{ order: number; columns: Array<string> }> => {
  let newValue: Array<{ order: number; columns: Array<string> }> = [];

  tableDataRows.forEach((row: Array<string>, index) => {
    const value = {
      order: index,
      columns: row,
    };
    newValue.push(value);
  });

  return newValue;
};

export const formatHeaders = ({
  headers,
}: {
  headers: Array<string>;
}): Array<{ label: string; key: string }> => {
  return headers.map((label: string) => ({
    label: label.trim(),
    key: label.trim(),
  }));
};

export const formatRows = ({
  rows,
  headers,
}: {
  rows: Array<Array<string>>;
  headers: Array<{ label: string; key: string }>;
}): Array<{ [key: string]: string }> => {
  return rows.map((row: Array<string>) =>
    headers.reduce(
      (acc: any, header: { label: string; key: string }, index: number) => {
        if (!_.isUndefined(row[index])) {
          acc[header.key.trim()] = row[index].trim();
        }
        return acc;
      },
      {}
    )
  );
};

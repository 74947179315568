import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import PusherManager from "utils/pusher-manager";
import { handleInvalidRequest } from "utils/sdk";
import { addQueryParams, reverse } from "utils/urls";

import { useSignedUser } from "entities/Auth/sdk";

import { fetchCompanyPackageWithTabsInformation } from "./sdk";

export const useDueDiligenceFileProcessingStatusUpdates = ({
  successEventHandler,
  failureEventHandler,
}: {
  successEventHandler: ({
    dealId,
    packageId,
    pdfImportId,
  }: {
    dealId: number;
    packageId: number;
    pdfImportId: number;
  }) => void;
  failureEventHandler: () => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToDueDiligenceFileProcessingSucceededEvent({
        userId: user.id,
        eventHandler: ({
          deal_id: dealId,
          company_package_id: packageId,
          butler_data_import_id: pdfImportId,
        }: {
          deal_id: number;
          company_package_id: number;
          butler_data_import_id: number;
        }) => successEventHandler({ dealId, packageId, pdfImportId }),
      });
    }
  }, [user, successEventHandler]);

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToDueDiligenceFileProcessingFailedEvent({
        userId: user.id,
        eventHandler: failureEventHandler,
      });
    }
  }, [user, failureEventHandler]);
};

interface IDueDiligenceRerouteToTab {
  dealId: number;
  packageId: number;
  tabId: number | null;
}
export const useDueDiligenceRerouteToTab = ({
  dealId,
  packageId,
  tabId,
}: IDueDiligenceRerouteToTab) => {
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const searchQueryParams = useMemo(() => {
    const queryParams: { [key: string]: string } = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      queryParams[param] = value;
    }

    return queryParams;
  }, [searchParams]);

  useEffect(() => {
    if (_.isNil(tabId) && !_.isNil(packageId)) {
      fetchCompanyPackageWithTabsInformation({
        dealId,
        packageId,
      })
        .then(({ tabs }) => {
          if (tabs.length > 0) {
            const tabId = _.get(tabs, "[0].id");

            if (_.isUndefined(tabId)) {
              return;
            }

            navigate(
              addQueryParams(
                reverse(URLS.DEAL_PACKAGE, {
                  dealId,
                  packageId,
                  tabId,
                }),
                searchQueryParams
              )
            );
          } else {
            setShowError(true);
          }
        })
        .catch(handleInvalidRequest);
    }
  }, [dealId, packageId, tabId, searchQueryParams, navigate]);

  return { showError };
};

import ListPagination from "components/ListPagination";
import ScrollableTable from "components/ScrollableTable";
import Stack from "components/Stack";
import { useFilters } from "components/useFilters";

import { useSuperuserCompanyList } from "entities/Superuser/sdk";

import { CUSTOMER_LIST_HEADERS } from "./constants";

const CustomerTable = () => {
  const { filters } = useFilters();
  const {
    data: companies,
    pagesCount,
    setPage,
    isLoading,
  } = useSuperuserCompanyList({ filters });

  return (
    <Stack spacing={3}>
      <ScrollableTable
        showLoading={isLoading}
        headers={CUSTOMER_LIST_HEADERS}
        rows={companies?.results || []}
        expectedResultsCount={10}
      />
      <ListPagination setPage={setPage} pagesCount={pagesCount} />
    </Stack>
  );
};

export default CustomerTable;

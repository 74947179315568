import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";

import EditFormDialog, {
  ISummaryValues,
} from "entities/Memo/components/Sections/Summary/EditFormDialog";
import { IDealSummary, updateSummary } from "entities/Memo/sdk";

interface IEditButton {
  title: string;
  category: string;
  summaryData: IDealSummary;
  onUpdated: () => void;
}

const EditButton = ({
  title,
  category,
  summaryData,
  onUpdated,
}: IEditButton) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSave = useCallback(
    (data: ISummaryValues) =>
      updateSummary({
        dealId,
        category,
        data: {
          summary: data.summary || "",
        },
      }).then(() => {
        onUpdated();
      }),

    [category, dealId, onUpdated]
  );

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <EditIcon />
      </IconButton>
      {open && (
        <EditFormDialog
          open={open}
          title={title}
          summaryData={summaryData}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditButton;

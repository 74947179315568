import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import {
  AddIcon,
  AIIcon,
  DeleteOutlineOutlinedIcon,
  DescriptionOutlinedIcon,
  VisibilityOutlinedIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { AI_SUMMARY_ALLOWED_FILE_TYPES } from "utils/constants";
import { format, parseJSON } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import IconButton from "components/IconButton";
import { ListSubheader } from "components/List";
import Menu, { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import { useFeatureDisabledDialog } from "components/useFeatureDisabledDialog";

import CreateAISummariesButton from "entities/AISummaries/components/CreateAISummariesButton";
import { IPackageDetailsFieldValue } from "entities/Checklist/sdk";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import {
  companySettingsFeatureLimitCheck,
  useCompanySettings,
} from "entities/Company/sdk";
import AISummariesDrawer from "entities/Field/components/Filefield/AISummariesDrawer";
import { IFile } from "entities/File/sdk";

export type IFilefieldCustomValue = Pick<
  IPackageDetailsFieldValue,
  "value_id" | "file"
> & {
  uploaded_at?: string;
  uploaded_by?: { email: string };
};
interface IFilesTable {
  customFieldValues: IFilefieldCustomValue[];
  onDownload?: ({ fileId }: { fileId: number }) => void;
  onUpdate?: () => void;
  onDelete?: ({ customValue }: { customValue: IFilefieldCustomValue }) => void;
  isShareableMemo?: boolean;
  showAISummaries?: boolean;
}

const FilesTable = ({
  customFieldValues,
  onDownload,
  onDelete,
  onUpdate = () => {},
  showAISummaries = true,
  isShareableMemo,
}: IFilesTable) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [AISummaryMenuFile, setAISummaryMenuFile] = useState<IFile | null>(
    null
  );
  const [
    documentAssistantIdForAISummariesDrawer,
    setDocumentAssistantIdForAISummariesDrawer,
  ] = useState<number | null>(null);
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { show: showConfirmationDialog } = useConfirmationDialog();
  const { show: showFeatureDisabledDialog } = useFeatureDisabledDialog();

  const { data: companySettings } = useCompanySettings({});

  const onDeleteFile = useCallback(
    (customValue: IFilefieldCustomValue) => {
      onDelete && onDelete({ customValue });
    },
    [onDelete]
  );

  const handleDeleteFile = useCallback(
    (customValue: IFilefieldCustomValue) => {
      let confirmationDialogProperties = {
        confirmButtonText: "Delete",
        message: "Are you sure you want to delete this file?",
      };

      if (
        !_.isNil(customValue.file) &&
        !_.isEmpty(customValue.file.openai_files)
      ) {
        confirmationDialogProperties = {
          confirmButtonText: "Delete file anyway",
          message:
            "Deleting or replacing a file attachment that has been added as source material to an AI summary does not automatically removes or updates the file in the AI summary. If you are sure you no longer need this file as a source document, don’t forget to navigate to the AI summaries page to delete it or add the new updated file into the AI summary. ",
        };
      }

      showConfirmationDialog({
        onConfirm: () => onDeleteFile(customValue),
        ...confirmationDialogProperties,
      });
    },
    [onDeleteFile, showConfirmationDialog]
  );

  const handleDownloadClick = useCallback(
    ({ file }: { file: IFile }) => {
      onDownload && onDownload({ fileId: file.id });
    },
    [onDownload]
  );

  const handleAISummaryCreate = useCallback(
    ({ openAIDocumentAssistantId }) => {
      onUpdate();
      setDocumentAssistantIdForAISummariesDrawer(openAIDocumentAssistantId);
    },
    [onUpdate]
  );

  const onCreateAISummaryButtonClick = useCallback(
    ({ eventHandler }) =>
      companySettingsFeatureLimitCheck({
        featureType: ECompanySettingsFeatureType.AI_SUMMARY,
      })
        .then(({ has_access }) => {
          if (has_access) {
            eventHandler();
          } else {
            showFeatureDisabledDialog({});
          }
        })
        .catch(handleInvalidRequest),
    [showFeatureDisabledDialog]
  );

  const showAISummariesIconButton = useMemo(
    () =>
      showAISummaries && !isShareableMemo && companySettings?.ai_summary_shown,
    [isShareableMemo, companySettings, showAISummaries]
  );

  const handleSummaryDelete = useCallback(() => {
    onUpdate();
    setDocumentAssistantIdForAISummariesDrawer(null);
  }, [onUpdate]);

  const handleAISummaryMenuOpen = useCallback(
    ({
      event,
      file,
    }: {
      event: React.MouseEvent<HTMLButtonElement>;
      file: IFile;
    }) => {
      setAnchorEl(event.currentTarget);
      setAISummaryMenuFile(file);
    },
    []
  );

  const handleAISummaryMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAISummaryMenuItemClick = useCallback(
    ({ openAIFile }) => {
      companySettingsFeatureLimitCheck({
        featureType: ECompanySettingsFeatureType.AI_SUMMARY,
      })
        .then(({ has_access }) => {
          if (has_access) {
            setDocumentAssistantIdForAISummariesDrawer(
              openAIFile.openai_document_assistant.id
            );
          } else {
            showFeatureDisabledDialog({});
          }
        })
        .catch(handleInvalidRequest);
    },
    [showFeatureDisabledDialog]
  );

  const handleAISummaryDrawerClose = useCallback(() => {
    onUpdate();
    setDocumentAssistantIdForAISummariesDrawer(null);
  }, [onUpdate]);

  return (
    <>
      <AISummariesDrawer
        close={handleAISummaryDrawerClose}
        deleteCallback={handleSummaryDelete}
        documentAssistantId={documentAssistantIdForAISummariesDrawer}
      />
      {customFieldValues.map(({ file, uploaded_at, value_id, uploaded_by }) => {
        const fileCanBeUsedForAISummary =
          !_.isNil(file) &&
          AI_SUMMARY_ALLOWED_FILE_TYPES.includes(file.file_type);
        const hasAISummary = !_.isEmpty(file?.openai_files);

        return (
          <React.Fragment key={value_id}>
            {!_.isNil(file) && (
              <Stack
                key={value_id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  padding: theme.spacing(0.5),
                  borderBottom: `1px solid ${colors.blue20}`,
                }}
                data-testid="uploaded-file"
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "75%",
                  }}
                >
                  <DescriptionOutlinedIcon
                    sx={{ width: "16px", height: "16px" }}
                  />
                  <Tooltip title={file?.original_file_name}>
                    <Text
                      noWrap
                      variant="text4"
                      sx={{ flex: 1 }}
                      data-testid="uploaded-file-name"
                    >
                      {file?.original_file_name}
                    </Text>
                  </Tooltip>
                </Stack>
                <Stack direction="row" spacing={3} alignItems="center">
                  {uploaded_by && (
                    <Tooltip title={uploaded_by.email}>
                      <Text variant="text4" data-testid="file-uploaded-by">
                        {_.truncate(uploaded_by.email, { length: 20 })}
                      </Text>
                    </Tooltip>
                  )}
                  <Text
                    variant="text4"
                    data-testid="file-uploaded-datetime"
                    noWrap
                  >
                    {uploaded_at &&
                      format(parseJSON(uploaded_at), "MMM dd, yyyy, hh:mm a")}
                  </Text>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    data-testid="file-action-buttons"
                  >
                    {showAISummariesIconButton && fileCanBeUsedForAISummary ? (
                      <IconButton
                        size="small"
                        sx={
                          hasAISummary
                            ? { color: colors.blue100, opacity: 1 }
                            : {}
                        }
                        onClick={(event) =>
                          handleAISummaryMenuOpen({ event, file })
                        }
                      >
                        <AIIcon />
                      </IconButton>
                    ) : (
                      <span style={{ width: "24px" }}></span>
                    )}
                    {onDownload && (
                      <IconButton
                        size="small"
                        title="Preview"
                        onClick={() => handleDownloadClick({ file })}
                        dataTestid="view-file-button"
                      >
                        <VisibilityOutlinedIcon
                          sx={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    )}
                    {onDelete && (
                      <IconButton
                        size="small"
                        title="Delete"
                        onClick={() =>
                          handleDeleteFile({ file, uploaded_at, value_id })
                        }
                        dataTestid="delete-file-button"
                      >
                        <DeleteOutlineOutlinedIcon
                          sx={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </React.Fragment>
        );
      })}

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={!_.isNil(anchorEl) && !_.isNil(AISummaryMenuFile)}
        onClose={handleAISummaryMenuClose}
        disableScrollLock={true}
        onClick={handleAISummaryMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!_.isNil(AISummaryMenuFile) && [
          <ListSubheader disableSticky>
            <Text variant="label">AI Summaries</Text>
          </ListSubheader>,
          ...(AISummaryMenuFile.openai_files?.map((openAIFile) => (
            <MenuItem
              key={openAIFile.openai_document_assistant.id}
              onClick={() => handleAISummaryMenuItemClick({ openAIFile })}
              sx={{ marginLeft: 1 }}
            >
              {openAIFile.openai_document_assistant.openai_prompt_package}
            </MenuItem>
          )) || []),
          <MenuItem>
            <CreateAISummariesButton
              dealId={dealId}
              file={AISummaryMenuFile}
              createCallback={handleAISummaryCreate}
              buttonRender={({ onClick }) => (
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  sx={{ alignSelf: "flex-start" }}
                  onClick={() =>
                    onCreateAISummaryButtonClick({
                      eventHandler: onClick,
                    })
                  }
                >
                  Add AI Summary
                </Button>
              )}
            />
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default FilesTable;

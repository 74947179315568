import { useMemo } from "react";
import { useParams } from "react-router";

import theme from "theme";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";

import Box from "components/Box";
import FixedTable from "components/FixedTable";
import SimpleTable from "components/SimpleTable";
import Stack from "components/Stack";
import Text from "components/Text";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import { getTableVerticalHeaders } from "entities/Field/components/FixedTableField/utils";
import LinkedFieldsDrawerButton from "entities/Field/components/LinkedFieldsDrawerButton";
import { FieldType } from "entities/Field/sdk";
import { DUE_DILIGENCE_INTAKE_TYPE_OPTIONS } from "entities/Package/constants";

export interface IFieldWrapper {
  field: IPackageDetailsField;
}

const FieldWrapper = ({ field }: IFieldWrapper) => {
  const params = useParams<{
    checklistId: string;
  }>();

  const showLinkedFieldsDrawerButton = useMemo(
    () => field.is_linked,
    [field.is_linked]
  );

  // We don't want to show the fields that have a type image, signature, date time or button
  if (!DUE_DILIGENCE_INTAKE_TYPE_OPTIONS.includes(field.field_type)) {
    return null;
  }

  return (
    <Box data-testid="dd-field">
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <Text
          variant="text2"
          sx={{
            fontWeight: 300,
            paddingRight: theme.spacing(1),
          }}
          data-testid="field-name"
        >
          {field.name} {field.is_deleted ? "(deleted)" : ""}
        </Text>
        {showLinkedFieldsDrawerButton && (
          <LinkedFieldsDrawerButton
            isOverridden={field?.is_overridden}
            packageId={Number(params.checklistId)}
            fieldId={field.company_custom_field_id}
            onUpdate={() => {}}
          />
        )}
      </Stack>

      {field.field_type === FieldType.FORM_TEXT_FIELD &&
        isNotUndefined(field.template) && (
          <div dangerouslySetInnerHTML={{ __html: field.template }} />
        )}

      {field.field_type === FieldType.TABLE && (
        <Box sx={{ marginY: theme.spacing(3) }} data-testid="field-table">
          <SimpleTable
            headers={
              field.columns?.map((column) => ({
                label: column.trim(),
                key: column.trim(),
              })) || []
            }
            rows={[]}
            size="small"
            showEmptyRow={true}
          />
        </Box>
      )}
      {field.field_type === FieldType.FIXED_TABLE && (
        <Box sx={{ marginY: theme.spacing(3) }} data-testid="field-table">
          <FixedTable
            headers={
              field.columns?.map((column) => ({
                label: column.trim(),
                key: column.trim(),
              })) || []
            }
            rows={
              field.columns
                ? getTableVerticalHeaders({
                    firstColumn: field.columns[0],
                    rows: field.rows,
                  })
                : []
            }
            showEmptyRow={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default FieldWrapper;

import React, { useCallback, useState } from "react";

import theme from "theme";

import Paper from "components/Paper";
import { usePageTitle } from "components/usePageTitle";

import { DealSidepanelContextProvider } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import PageTitle from "entities/Task/components/AllDealTasksPageTitle";
import PageTitleSkeleton from "entities/Task/components/AllDealTasksPageTitleSkeleton";
import SelectedFilters from "entities/Task/components/SelectedFilters";
import TasksTables from "entities/Task/components/TasksTables";
import TasksTableSkeleton from "entities/Task/components/TasksTableSkeleton";
import { TaskTypes } from "entities/Task/constants";
import { ITypeOfFilter, useTasksData } from "entities/Task/sdk";

interface IAllDealTasks {}

const AllDealTasks: React.FC<IAllDealTasks> = () => {
  const [taskType, setTaskType] = useState<TaskTypes>(TaskTypes.ALL_TASKS);
  const [selectedFilters, setSelectedFilters] = useState<ITypeOfFilter>({});

  usePageTitle("Builders Patch: All deal tasks");

  const { data: tasksData, mutate: mutateTasksData } = useTasksData({
    filters: { ...selectedFilters, task_type: taskType },
  });

  const handleChangeSelector = useCallback(
    (selectedTaskType: TaskTypes) => {
      setTaskType(selectedTaskType);
    },
    [setTaskType]
  );

  const handleChangeFilters = useCallback((selectedFilters: ITypeOfFilter) => {
    setSelectedFilters(selectedFilters);
  }, []);

  const refreshTasksList = useCallback(
    () => mutateTasksData(),
    [mutateTasksData]
  );

  return (
    <DealSidepanelContextProvider isInDealContext={false}>
      <Paper sx={{ paddingTop: theme.spacing(4) }}>
        {tasksData ? (
          <>
            <PageTitle
              taskType={taskType}
              initialFilters={selectedFilters}
              onChangeTaskType={handleChangeSelector}
              onChangeFilters={handleChangeFilters}
            />
            <SelectedFilters
              selectedFilters={selectedFilters}
              onUpdateFilters={setSelectedFilters}
            />
            <TasksTables
              tasksData={tasksData}
              refreshTasksList={refreshTasksList}
              isInDealContext={false}
            />
          </>
        ) : (
          <>
            <PageTitleSkeleton />
            <TasksTableSkeleton />
          </>
        )}
      </Paper>
    </DealSidepanelContextProvider>
  );
};

export default AllDealTasks;

import { useEffect, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Stack from "components/Stack";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import TaskTable from "entities/Task/components/TasksTables/Table";
import { formatTableData } from "entities/Task/components/TasksTables/utils";
import { ITasks } from "entities/Task/sdk";

interface ITasksTables {
  tasksData: ITasks;
  refreshTasksList: () => void;
  isInDealContext?: boolean;
}

const TasksTables: React.FC<ITasksTables> = ({
  tasksData,
  refreshTasksList,
  isInDealContext = true,
}) => {
  const { open } = useDealSidepanel();

  useEffect(() => {
    // TODO: check for the initial state

    /**
     * If the side panel is closed, we have to update the lists.
     * It's possible to make changes - send a new message, update the task or remove the task
     */

    if (!open) {
      refreshTasksList();
    }
  }, [open, refreshTasksList]);

  const rowsOverdueTasks = useMemo(
    () => _.map(tasksData?.overdue, (value) => formatTableData(value)),
    [tasksData]
  );

  const rowsUpcomingTasks = useMemo(
    () => _.map(tasksData?.upcoming, (value) => formatTableData(value)),
    [tasksData]
  );

  const rowsDoneTasks = useMemo(
    () => _.map(tasksData?.completed, (value) => formatTableData(value)),
    [tasksData]
  );

  return (
    <Box sx={{ marginTop: theme.spacing(4) }}>
      <Stack spacing={4}>
        <TaskTable
          tasks={rowsOverdueTasks}
          title="Overdue tasks"
          backgroundColor={colors.red20}
          isInDealContext={isInDealContext}
          refreshTasksList={refreshTasksList}
        />
        <TaskTable
          tasks={rowsUpcomingTasks}
          title="Upcoming tasks"
          backgroundColor={colors.yellow20}
          isInDealContext={isInDealContext}
          refreshTasksList={refreshTasksList}
        />
        <TaskTable
          tasks={rowsDoneTasks}
          title="Done tasks"
          backgroundColor={colors.teal20}
          isInDealContext={isInDealContext}
          refreshTasksList={refreshTasksList}
        />
      </Stack>
    </Box>
  );
};

export default TasksTables;

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { AIIcon } from "icons";
import { colors } from "theme/palette";

import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Text from "components/Text";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import LinkedFieldsDrawerButton from "entities/Field/components/LinkedFieldsDrawerButton";
import { FieldType } from "entities/Field/sdk";

export interface IFieldTitle {
  field: IPackageDetailsField;
  onUpdate: () => void;
  externalToken?: string;
  disabled?: boolean;
}

const FieldTitle = ({
  field,
  onUpdate,
  externalToken,
  disabled = false,
}: IFieldTitle) => {
  const params = useParams<{ dealId: string }>();
  const dealId = Number(params.dealId);

  const showLinkedFieldsDrawerButton = useMemo(
    () => field.is_linked && !_.isNil(dealId) && _.isUndefined(externalToken),
    [field, dealId, externalToken]
  );

  const showAIIcon = useMemo(
    () =>
      field.field_type === FieldType.FILE &&
      field.values.some((item) => !_.isEmpty(item.file?.openai_files)),
    [field]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      marginRight={4}
      flexBasis="400px"
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        flexGrow: 1,
      }}
    >
      <Text
        variant="text2"
        sx={{
          fontWeight: 300,
          opacity: field.n_a_status || disabled ? 0.5 : 1,
        }}
        noWrap
        data-testid="field-name"
      >
        {field.name}
      </Text>
      <NoPrint>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <InstructionsDialogButton instructions={field?.instruction} />
          {showLinkedFieldsDrawerButton && (
            <LinkedFieldsDrawerButton
              isOverridden={field?.is_overridden}
              dealId={dealId}
              packageId={field.company_package_id}
              fieldId={field.company_custom_field_id}
              onUpdate={onUpdate}
            />
          )}
          {showAIIcon && <AIIcon style={{ color: colors.blue100 }} />}
        </Stack>
      </NoPrint>
    </Stack>
  );
};

export default FieldTitle;

import React, { useCallback } from "react";

import { colors } from "theme/palette";

import Checkbox from "components/Checkbox";
import { TableCell } from "components/Table";
import toast from "components/Toast";

import { updateDealTaskCompleted } from "entities/Task/sdk";

interface ICompleteCell {
  completed: boolean;
  dealTaskId: number;
  refreshTasksList: () => void;
}

const CompleteCell: React.FC<ICompleteCell> = ({
  completed,
  dealTaskId,
  refreshTasksList,
}) => {
  const handleChangeCompleted = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      updateDealTaskCompleted({
        dealTaskId,
        data: { completed: event.target.checked },
      })
        .then(() => {
          toast.successMessage(
            `The task was successfully ${
              event.target.checked ? "uncompleted" : "completed"
            }!`
          );
          refreshTasksList();
        })
        .catch(({ detail }) => {
          return toast.errorMessage(detail);
        }),
    [dealTaskId, refreshTasksList]
  );

  return (
    <TableCell
      align="center"
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      <Checkbox
        checked={completed}
        color="success"
        sx={{ padding: 0 }}
        onChange={handleChangeCompleted}
      />
    </TableCell>
  );
};

export default CompleteCell;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { format, parseJSON } from "utils/datetime";

import Avatar from "components/Avatar";
import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import { IMessage } from "entities/Task/sdk";

interface IChatMessage {
  message: IMessage;
}
const ChatMessage: React.FC<IChatMessage> = ({ message }) => {
  const { data: user } = useSignedUser();

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: 24,
            height: 24,
            backgroundColor:
              user?.id === message.msg_from.pk ? colors.blue100 : colors.gray60,
          }}
        >
          <Text variant="labelSmall" sx={{ fontWeight: 700 }}>
            {message.msg_from.first_name.charAt(0)}
            {message.msg_from.last_name.charAt(0)}
          </Text>
        </Avatar>
        <Text variant="text2" sx={{ fontWeight: 700 }}>
          {message.msg_from.first_name} {message.msg_from.last_name}
        </Text>
        <Text variant="text4" color={colors.gray60}>
          {format(parseJSON(message.created_at), "hh:mm aaa, MMM dd, yyyy")}
        </Text>
      </Stack>
      <Box
        sx={{
          display: "inline-block",
          backgroundColor:
            user?.id === message.msg_from.pk ? colors.blue10 : colors.gray10,
          padding: theme.spacing(2, 3),
          margin: theme.spacing(1, 1, 1, 3),
          borderRadius: theme.spacing(3),
          borderTopLeftRadius: 0,
          "& p": {
            margin: 0,
          },
        }}
      >
        {message.message}
      </Box>
    </Box>
  );
};

export default ChatMessage;

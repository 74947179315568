import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import _ from "lodash";
import Quill from "quill";
// @ts-ignore
import QuillBetterTable from "quill-better-table";

export const EMPTY_VALUE_REGEX =
  /^<[a-zA-Z0-9]+>(<br>|&nbsp;)<\/[a-zA-Z0-9]+>$/;

const EDITOR_TOOLBAR_HEIGHT = 44;
const EDITOR_HEIGHT = 400;
const EDITOR_HEIGHT_MOBILE = 265;

// When the editor is in a dialog, the height of the editor should be customized based on the dialog height and screen height
export const COMMON_EDITOR_CONTAINER_STYLES: SxProps<Theme> = {
  height: `${EDITOR_HEIGHT + EDITOR_TOOLBAR_HEIGHT}px`,
  "@media screen and (max-height: 864px)": {
    height: `${EDITOR_HEIGHT_MOBILE + EDITOR_TOOLBAR_HEIGHT}px`,
  },
  transition: "height 0.3s",
};

export const COMMON_EDITOR_STYLES: SxProps<Theme> = {
  height: `${EDITOR_HEIGHT}px`,
  "@media screen and (max-height: 864px)": {
    height: `${EDITOR_HEIGHT_MOBILE}px`,
  },
  transition: "height 0.3s",
};

const Delta = Quill.import("delta");

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["link"],
  [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
];

Quill.register("modules/better-table", QuillBetterTable, true);

export const MODULES = ({
  tableStorageTableImportHandler,
  showTableStorageTableImport,
}: {
  tableStorageTableImportHandler: ({
    table,
  }: {
    table: HTMLTableElement;
  }) => void;
  showTableStorageTableImport: boolean;
}) => ({
  toolbar: TOOLBAR_OPTIONS,
  table: false,
  "better-table": {
    operationMenu: {
      items: {
        insertColumnRight: false,
        insertColumnLeft: false,
        insertRowUp: false,
        insertRowDown: false,
        mergeCells: false,
        unmergeCells: false,
        deleteColumn: false,
        deleteRow: false,
        deleteTable: {
          iconSrc: null,
        },
        copyToStorage: showTableStorageTableImport
          ? {
              text: "Copy to Data tables",
              iconSrc: null,
              handler() {
                // @ts-ignore
                const tableContainer = Quill.find(this.table);

                tableStorageTableImportHandler({
                  // @ts-ignore
                  table: tableContainer?.domNode as HTMLTableElement,
                });
              },
            }
          : false,
      },
    },
  },
  clipboard: {
    matchers: [
      [
        Node.TEXT_NODE,
        (node: Node) => {
          // Get plain text from the node while preserving new lines
          const nodeData = _.get(node, "data", "");
          const text = nodeData.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
          // Split the text by new lines to create Quill Delta
          const lines = text.split("\n");

          const ops = new Delta();

          lines.forEach((line, index: number) => {
            if (index > 0) {
              ops.push({ insert: "\n" });
            }
            ops.push({ insert: line });
          });
          return ops;
        },
      ],
    ],
  },
  keyboard: {
    bindings: {
      handleEnter: {
        key: "Enter",
        handler: function (range: any) {
          const quill: any = _.get(this, "quill", null);

          if (_.isNil(quill)) {
            return true;
          }

          const [line] = quill.getLine(range.index);

          if (line && line.domNode.parentNode.nodeName === "TD") {
            const blot: any = Quill.find(line.domNode.parentNode);
            const index = quill.getIndex(blot) + blot.length();

            if (range.index === index - 1) {
              quill.insertText(index, "\n", Quill.sources.USER);
              quill.setSelection(index + 1, Quill.sources.SILENT);

              return false; // Prevent default behavior
            }
          }
          return true; // Continue with default behavior
        },
      },
      ...QuillBetterTable.keyboardBindings,
    },
  },
});

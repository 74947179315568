import PageHeader from "components/PageHeader";
import Stack from "components/Stack";

import ExportButton from "entities/Reporting/components/DefaultReportPageHeader/ExportButton";
import FiltersButton from "entities/Reporting/components/FiltersButton";
import ReportSearchField from "entities/Reporting/components/ReportSearchField";
import SelectedFilters from "entities/Reporting/components/SelectedFilters";
import { INonPipelineColumns } from "entities/Reporting/sdk";

export interface IDefaultReportPageHeader {
  reportTitle: string;
  rows: { [key: string]: string | null }[];
  columns: INonPipelineColumns[];
  loading: boolean;
}

const DefaultReportPageHeader = ({
  reportTitle,
  rows,
  columns,
  loading,
}: IDefaultReportPageHeader) => (
  <>
    <PageHeader
      title={reportTitle}
      actions={
        <Stack
          spacing={2}
          direction="row"
          sx={{ flex: 1 }}
          justifyContent="flex-end"
        >
          <ReportSearchField placeholder="Search by deal name, city, or state" />
          <FiltersButton
            isHardcodedReport
            columns={columns}
            disabled={rows.length === 0 && loading}
            rows={rows}
            reportPackageId={undefined}
            filtersButtonDataTestid="filter-deals-button"
          />
          <ExportButton
            reportTitle={reportTitle}
            columns={columns}
            rows={rows}
          />
        </Stack>
      }
    />
    <SelectedFilters
      columns={columns}
      reportPackageId={undefined}
      isHardcodedReport={true}
    />
  </>
);

export default DefaultReportPageHeader;

import { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Tabs, { Tab } from "components/Tabs";

import { useCompanySettings } from "entities/Company/sdk";
import { Persona } from "entities/Company/sdk";
import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import { useDealChecklistPermission } from "entities/Deal/sdk";

import { DueDiligenceTabChoices, TABS } from "./constants";

interface IDueDiligenceTabs<T extends string> {
  dealId: number;
  packageId: number;
  selectedTab: string;
  onChange: (event: any, tabIndex: T) => void;
}

const DueDiligenceTabs = <T extends string>({
  dealId,
  packageId,
  selectedTab,
  onChange,
}: IDueDiligenceTabs<T>) => {
  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });

  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });
  const { data: companySettings, isLoading: companySettingsIsLoading } =
    useCompanySettings({});

  const showAISummaryMenu = useMemo(
    () => companySettings?.ai_summary_shown,
    [companySettings]
  );

  const userIsLenderOrDeveloper = _.includes(
    [Persona.LENDER, Persona.DEVELOPER],
    userPermission?.persona
  );

  const hasFullAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  const filteredTabs = useMemo(() => {
    let tabs = [...TABS];

    if (!userIsLenderOrDeveloper || !hasFullAccess) {
      tabs = _.filter(
        tabs,
        (tab) => tab.value !== DueDiligenceTabChoices.ALL_FILES
      );
    }

    if (!hasFullAccess || !showAISummaryMenu || companySettingsIsLoading) {
      tabs = _.filter(
        tabs,
        (tab) => tab.value !== DueDiligenceTabChoices.AI_SUMMARIES
      );
    }

    if (!loggedUserIsDealAdmin) {
      tabs = _.filter(
        tabs,
        (tab) => tab.value !== DueDiligenceTabChoices.SHARE_SETTINGS
      );
    }

    return tabs;
  }, [
    hasFullAccess,
    loggedUserIsDealAdmin,
    userIsLenderOrDeveloper,
    showAISummaryMenu,
    companySettingsIsLoading,
  ]);

  return (
    <Tabs
      value={selectedTab}
      onChange={onChange}
      TabIndicatorProps={{ sx: { height: "4px" } } as any}
      data-testid="checklist-tabs"
      sx={{
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid ${colors.blue100}`,
      }}
    >
      {filteredTabs.map(({ label, value }) => (
        <Tab
          key={value}
          sx={{
            textTransform: "none",
            color: colors.gray100,
            padding: "9px 1.5em",
            fontSize: "16px",
            "&.Mui-selected": {
              color: colors.gray100,
              backgroundColor: colors.blue20,
            },
          }}
          label={label}
          value={value}
          data-testid="checklist-tab"
        />
      ))}
    </Tabs>
  );
};

export default DueDiligenceTabs;

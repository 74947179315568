import React, { useMemo } from "react";

import { formatCellData } from "components/ScrollableTable/Cells/TextCell/utils";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { EFieldFormatType } from "entities/Reporting/constants";

interface ITextCell {
  cellData: string | number | undefined;
  title: string | undefined;
  fieldFormatType?: EFieldFormatType;
}

const TextCell = ({
  cellData,
  title: initialTitle,
  fieldFormatType = EFieldFormatType.STRING,
}: ITextCell) => {
  const { value, title } = useMemo(
    () => formatCellData({ cellData, fieldFormatType, title: initialTitle }),
    [cellData, fieldFormatType, initialTitle]
  );

  return (
    <Tooltip title={title}>
      <Text
        noWrap
        variant="text3"
        sx={{
          paddingY: 0.5,
        }}
      >
        {value}
      </Text>
    </Tooltip>
  );
};

export default React.memo(TextCell);

import { useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import FormControlLabel from "components/FormControlLabel";
import Radio, { RadioGroup } from "components/Radio";
import Stack from "components/Stack";
import Text from "components/Text";

import { CompanyCustomFieldSyncingType } from "entities/Field/constants";
import { IDealLinkedPackage } from "entities/Superuser/sdk";

import { SYNCING_OPTIONS } from "./constants";
import Field from "./Field";

export interface IDealPackage {
  dealPackage: IDealLinkedPackage;
  currentFieldId: number;
  value: CompanyCustomFieldSyncingType | null;
  onSelectSyncingOption: ({
    syncingType,
  }: {
    syncingType: CompanyCustomFieldSyncingType;
  }) => void;
}

const DealPackage = ({
  dealPackage,
  currentFieldId,
  onSelectSyncingOption,
  value,
}: IDealPackage) => {
  const handleRadioClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const syncingType = event.target.value as CompanyCustomFieldSyncingType;
      onSelectSyncingOption({ syncingType });
    },
    [onSelectSyncingOption]
  );

  return (
    <Stack spacing={1}>
      <div>
        <Text variant="label" color={colors.gray80}>
          Deal
        </Text>
        <Text variant="h3">{dealPackage.deal_name}</Text>
      </div>
      <Stack spacing={1} paddingLeft={3}>
        {dealPackage.packages.flatMap((pack) =>
          pack.tabs.flatMap((tab) =>
            tab.categories.flatMap((category) =>
              category.sections.flatMap((section) =>
                section.fields.map((field) => (
                  <Field
                    key={`${pack.package_id}-${tab.tab_id}-${category.category_id}-${section.section_id}-${field.field_id}`}
                    label={`${pack.name} > ${tab.name} > ${category.name} > ${section.name} > ${field.name}`}
                    isCurrentField={field.field_id === currentFieldId}
                    field={field}
                  />
                ))
              )
            )
          )
        )}
      </Stack>
      <Stack
        spacing={1}
        sx={{ paddingTop: theme.spacing(2), paddingLeft: theme.spacing(3) }}
      >
        <Text variant="label" color={colors.gray80}>
          Enabling options
        </Text>
        <RadioGroup
          value={value}
          onChange={handleRadioClick}
          sx={{ paddingLeft: theme.spacing(2) }}
        >
          {SYNCING_OPTIONS.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={
                <Text variant="text2">
                  <b>{option.label}:</b> {option.subTitle}
                </Text>
              }
              sx={{ marginBottom: theme.spacing(1) }}
            />
          ))}
        </RadioGroup>
      </Stack>
    </Stack>
  );
};

export default DealPackage;

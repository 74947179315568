import React, { useMemo, useState } from "react";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";

import Collapse from "components/Collapse";
import ScrollableTable from "components/ScrollableTable";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Stack from "components/Stack";
import Text from "components/Text";

import { getExpensesRows } from "entities/Proforma/components/ProformaPLProjections/Tables/utils";
import { IPLProjection } from "entities/Proforma/sdk";

interface IExpensesTable {
  headers: IScrollableTableHeader;
  expensesData: IPLProjection["p_and_l"]["expenses"];
}

const ExpensesTable = ({ headers, expensesData }: IExpensesTable) => {
  const [isOpen, setIsOpen] = useState(true);

  const expenses = useMemo(
    () => getExpensesRows({ expensesData }),
    [expensesData]
  );

  return (
    <Stack spacing={2} data-testid="expenses-table">
      <Text
        variant="h2"
        onClick={() => setIsOpen((open) => !open)}
        sx={{ cursor: "pointer" }}
      >
        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />} Expenses
      </Text>
      <Collapse in={isOpen} timeout="auto">
        <ScrollableTable headers={headers} rows={expenses} dynamicHyphen />
      </Collapse>
    </Stack>
  );
};

export default React.memo(ExpensesTable);

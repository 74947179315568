import React from "react";

import { CheckIcon } from "icons";

import Box from "components/Box";
import Chip from "components/Chip";

const NewPageMarker = () => {
  return (
    <Box>
      <Chip icon={<CheckIcon />} label="Start on a new page" />
    </Box>
  );
};

export default NewPageMarker;

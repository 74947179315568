import { useCallback, useMemo } from "react";
// Imported from MUI on purpose as the custom one is too custom for our case.
import Select from "@mui/material/Select";
import _ from "lodash";

import { colors } from "theme/palette";

import { ListSubheader } from "components/List";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Text from "components/Text";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { PromptPackageCategory } from "pages/Deal/AISummaries/List/constants";

import { IPromptPackage } from "entities/AISummaries/sdk";

export interface IPromptPackageSelect {
  promptPackages: Array<IPromptPackage>;
  onChange: ({ newPromptPackageId }: { newPromptPackageId: number }) => void;
  value?: string;
  disabled?: boolean;
  showConfirmation?: boolean;
}

const PromptPackageSelect = ({
  promptPackages,
  onChange,
  value,
  disabled = false,
  showConfirmation = true,
}: IPromptPackageSelect) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handlePromptPackageChange = useCallback(
    ({ promptPackageId }) => {
      if (_.isNil(value)) {
        onChange({ newPromptPackageId: promptPackageId });
      } else if (showConfirmation) {
        showConfirmationDialog({
          onConfirm: () => onChange({ newPromptPackageId: promptPackageId }),
          confirmButtonText: "Yes, modify it",
          message: (
            <>
              <Text variant="h2" marginBottom={1}>
                Modifying AI summary
              </Text>
              <Text variant="text2">
                Changing AI summary package will delete all previously generated
                answers under the current AI summary. Are you sure you want to
                proceed?
              </Text>
            </>
          ),
        });
      } else {
        onChange({ newPromptPackageId: promptPackageId });
      }
    },
    [value, showConfirmation, onChange, showConfirmationDialog]
  );

  const promptPackagesGroupedByCategory = useMemo(
    () => _.groupBy(promptPackages, "category"),
    [promptPackages]
  );

  const orderedPromptPackageCategories = useMemo(() => {
    const categories = Object.keys(PromptPackageCategory);

    return Object.keys(promptPackagesGroupedByCategory).sort(
      (a, b) => categories.indexOf(a) - categories.indexOf(b)
    );
  }, [promptPackagesGroupedByCategory]);

  return (
    <Stack spacing={0.5}>
      <Text variant="label" color={colors.gray60} fontWeight={600}>
        AI Summary
      </Text>
      <Select fullWidth value={value || ""} disabled={disabled}>
        {orderedPromptPackageCategories.map((category) => [
          <ListSubheader key={category} disableSticky>
            <Text variant="label">{category}</Text>
          </ListSubheader>,
          ...promptPackagesGroupedByCategory[category].map((promptPackage) => (
            <MenuItem
              key={promptPackage.id}
              value={promptPackage.id}
              onClick={() =>
                handlePromptPackageChange({ promptPackageId: promptPackage.id })
              }
              sx={{ marginLeft: 1 }}
            >
              {promptPackage.name}
            </MenuItem>
          )),
        ])}
      </Select>
    </Stack>
  );
};

export default PromptPackageSelect;

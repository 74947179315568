import theme from "theme";
import { colors } from "theme/palette";

import Backdrop from "components/Backdrop";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

const ExpiredShareToken = () => {
  return (
    <Backdrop
      open
      sx={{
        alignItems: "flex-start",
        zIndex: 1500,
        backgroundColor: colors.white,
        opacity: 0.9,
      }}
    >
      <Paper
        sx={{ padding: theme.spacing(4), marginTop: theme.spacing(15) }}
        elevation={4}
      >
        <Stack alignItems="center" spacing={1}>
          <Text variant="h3">Your token has expired!</Text>
        </Stack>
      </Paper>
    </Backdrop>
  );
};

export default ExpiredShareToken;

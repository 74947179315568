import React, { CSSProperties } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

interface ExternalLinkProps {
  to: string;
  external: true;
  target?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  linkDataTestid?: string;
  style?: CSSProperties | undefined;
}

interface InternalLinkProps extends LinkProps {
  to: string;
  target?: string;
  external?: false;
  children?: React.ReactNode;
  onClick?: () => void;
  linkDataTestid?: string;
  style?: CSSProperties | undefined;
}

type ILink = ExternalLinkProps | InternalLinkProps;

export const Link = ({
  to,
  external,
  children,
  linkDataTestid,
  style,
  ...rest
}: ILink) => {
  if ((external && typeof to === "string") || rest.target === "_blank") {
    return (
      <a
        href={to}
        style={{
          color: "inherit",
          textDecoration: "none",
          ...style,
        }}
        data-testid={linkDataTestid}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      style={{
        color: "inherit",
        textDecoration: "none",
        ...style,
      }}
      to={to}
      data-testid={linkDataTestid}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};

export default Link;

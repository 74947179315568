import { useCallback, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { CachedIcon } from "icons";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Text from "components/Text";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import PasteTableField from "entities/TableStorage/components/PasteTableField";
import { ITableCell, ITableRow } from "entities/TableStorage/sdk";

import { VALIDATION_SCHEMA } from "./constants";

export interface ITableUpdateDialogButton {
  onUpdate: ({
    values,
  }: {
    values: { rows: Array<Array<ITableCell>> };
  }) => void;
  disabled: boolean;
}

const initailRows: Array<Array<ITableCell>> = [];

const TableUpdateDialogButton = ({
  onUpdate,
  disabled,
}: ITableUpdateDialogButton) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onSave = useCallback(
    ({ rows }: { rows: Array<Array<ITableCell>> }) => {
      setIsDialogOpen(false);
      onUpdate({ values: { rows } });
    },
    [onUpdate]
  );
  const handleSubmit = useCallback(
    (values) =>
      showConfirmationDialog({
        onConfirm: () => onSave({ rows: values.rows }),
        confirmButtonText: "Yes",
        message: "Are you sure you want to replace the table data?",
      }),
    [showConfirmationDialog, onSave]
  );

  const formik = useFormik({
    initialValues: { rows: initailRows },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
    formik.resetForm();
  }, [formik]);

  const handlePasteTableData = useCallback(
    ({ rows }: { rows: Array<ITableRow> }) =>
      formik.setFieldValue(
        "rows",
        rows.map((row) => row.cells)
      ),
    [formik]
  );

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        startIcon={
          <CachedIcon
            sx={{
              width: 24,
              height: 24,
              color: disabled ? colors.gray20 : colors.blue100,
              transition: "color 0.3s ease",
            }}
          />
        }
        sx={{ color: colors.gray100 }}
        onClick={() => setIsDialogOpen(true)}
      >
        Replace table
      </Button>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={isDialogOpen}
        onClose={handleCancel}
      >
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Update the table
          </Text>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ padding: 2 }}>
            <PasteTableField
              showFieldError={
                formik.touched.rows && !_.isEmpty(formik.errors.rows)
              }
              fieldError={
                formik.touched.rows && !_.isUndefined(formik.errors.rows)
                  ? String(formik.errors.rows)
                  : undefined
              }
              showFieldLabel={false}
              onPaste={handlePasteTableData}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TableUpdateDialogButton;

import { useCallback } from "react";

import { handleInvalidRequest } from "utils/sdk";

import { MenuItem } from "components/Menu";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { companyPackageRemoveFromDeal } from "entities/Deal/sdk";

export interface IRemoveChecklistMenuItem {
  dealId: number;
  companyPackageId: number;
  onRemove: () => void;
}

const RemoveChecklistMenuItem = ({
  dealId,
  companyPackageId,
  onRemove,
}: IRemoveChecklistMenuItem) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const onDeleteChecklist = useCallback(
    () =>
      companyPackageRemoveFromDeal({
        dealId,
        companyPackageId,
      })
        .then(() => {
          onRemove();
          toast.successMessage(
            "The checklist has been removed successfully from this deal!"
          );
        })
        .catch(handleInvalidRequest),
    [companyPackageId, dealId, onRemove]
  );

  const handleRemove = useCallback(
    () =>
      showConfirmationDialog({
        onConfirm: onDeleteChecklist,
        confirmButtonText: "Remove",
        message:
          "Are you sure you want to remove the checklist from this deal?",
      }),
    [onDeleteChecklist, showConfirmationDialog]
  );

  return (
    <MenuItem onClick={handleRemove} dataTestid="action-menu-option">
      Remove from this deal
    </MenuItem>
  );
};

export default RemoveChecklistMenuItem;

import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import {
  createApplication,
  useApplicationCount,
} from "entities/Application/sdk";

export interface ICreateDialog {
  token: string;
  open: boolean;
  close: () => void;
}

const CreateDialog = ({ token, open, close }: ICreateDialog) => {
  const navigate = useNavigate();
  const [applicationName, setApplicationName] = useState("");

  const { mutate: updateApplicationCount } = useApplicationCount();

  const handleCreateApplication = useCallback(() => {
    if (applicationName.length > 255) {
      toast.errorMessage("Application name must be less than 255 characters");
      return;
    }

    return createApplication({ checklistToken: token, name: applicationName })
      .then(({ id }) => {
        updateApplicationCount();
        navigate(
          reverse(URLS.APPLICATION_EDIT, {
            applicationId: id,
          })
        );
      })
      .catch((errors) => {
        const errorMessage = errors?.message || errors?.detail;

        if (!_.isNil(errorMessage)) {
          toast.errorMessage(errorMessage);
        } else {
          errors?.forEach?.((error: unknown) => {
            if (_.isString(error)) {
              toast.errorMessage(error);
            }
          });
        }
      });
  }, [token, navigate, applicationName, updateApplicationCount]);

  const handleApplicationNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setApplicationName(event.target.value);
    },
    []
  );

  const isApplicationNameEmpty = useMemo(
    () => applicationName === "",
    [applicationName]
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={close}>
      <DialogTitle
        sx={{
          marginTop: theme.spacing(2),
          marginRight: theme.spacing(2),
          marginLeft: theme.spacing(2),
          borderRadius: theme.spacing(1),
          backgroundColor: colors.blue10,
        }}
      >
        <Text
          variant="h2"
          marginBottom={theme.spacing(0.5)}
          color={colors.gray100}
        >
          Start new intake
        </Text>
      </DialogTitle>

      <Stack padding={theme.spacing(3, 2, 2, 2)}>
        <Text variant="text3" fontWeight="bold">
          Intake name
        </Text>
        <Text
          marginTop={0.5}
          marginBottom={2}
          variant="text4"
          color={colors.gray40}
        >
          Give this application a well-identifiable name that relates to your
          project.
        </Text>

        <TextField
          onChange={handleApplicationNameChange}
          placeholder="e.g. Oaktree Apartments (housing) / Merlin Pet Supplies (small business)"
        />

        <DialogActions sx={{ paddingTop: theme.spacing(5) }}>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Tooltip
            title={isApplicationNameEmpty ? "Intake name is required." : ""}
            placement="top"
          >
            <span>
              <Button
                color="primary"
                onClick={handleCreateApplication}
                disabled={isApplicationNameEmpty}
              >
                Start
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default CreateDialog;

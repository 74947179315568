import React from "react";

import theme from "theme";

import Box from "components/Box";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

const ListBoxSkeleton: React.FC<{}> = () => {
  return (
    <Paper
      sx={{
        border: "1px solid #eee",
        borderRadius: "6px",
        padding: theme.spacing(2, 3),
      }}
    >
      <Stack spacing={0.5}>
        <Box sx={{ display: "grid", gridTemplateColumns: "240px 1fr 160px" }}>
          <Box>
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={100} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="flex-end"
          >
            <Skeleton variant="rectangular" width={36} height={36} />
            <Skeleton variant="rectangular" width={88} height={36} />
          </Stack>
        </Box>
        <Skeleton variant="text" width={300} />
      </Stack>
    </Paper>
  );
};

export default ListBoxSkeleton;

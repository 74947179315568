import React, { useCallback } from "react";

import { DropzoneState } from "components/Dropzone";
import UploadButton from "components/UploadButton";
import { defaultDragAndDropZone } from "components/UploadButton/utils";

import { upload } from "entities/File/sdk";

const UploadImage: React.FC<{
  onChange: (id: number) => void;
  dataTestid?: string;
}> = ({ onChange, dataTestid }) => {
  const handleChange = useCallback(
    ({ files }: { files: Array<File> }) => {
      upload({
        file: files[0],
        isPrivate: true,
      }).then((fileId) => {
        onChange(fileId);
      });
    },
    [onChange]
  );

  return (
    <UploadButton
      maxFiles={1}
      accept="image/*"
      dataTestid={dataTestid}
      onChange={handleChange}
      renderComponent={(state: Partial<DropzoneState>) =>
        defaultDragAndDropZone(
          state,
          "Drag and drop here, or click to select image"
        )
      }
    />
  );
};

export default UploadImage;

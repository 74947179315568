import { useCallback, useState } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useLoadingBackdrop } from "components/useLoadingBackdrop";

import PromptPackageSelect from "entities/AISummaries/components/PromptPackageSelect";
import {
  openAIDocumentAssistantCreate,
  useOpenAIPromptPackages,
} from "entities/AISummaries/sdk";
import { IFile } from "entities/File/sdk";

export interface ICreateAISummariesDialog {
  isOpen: boolean;
  dealId: number;
  onClose: () => void;
  onCreate: ({
    openAIDocumentAssistantId,
  }: {
    openAIDocumentAssistantId: number;
  }) => void;
  file?: IFile;
}

const CreateAISummariesDialog = ({
  isOpen: initialIsOpen,
  dealId,
  onClose,
  onCreate,
  file,
}: ICreateAISummariesDialog) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useCaching(initialIsOpen);
  const [selectedPromptPackageId, setSelectedPromptPackageId] = useState<
    number | undefined
  >(undefined);
  const [showLoading, hideLoading] = useLoadingBackdrop();

  const { data: promptPackages, isLoading: promptPackagesAreLoading } =
    useOpenAIPromptPackages();

  const handleChangePromptPackage = useCallback(
    ({ newPromptPackageId }) => setSelectedPromptPackageId(newPromptPackageId),
    []
  );

  const handleCreate = useCallback(() => {
    if (!_.isUndefined(selectedPromptPackageId)) {
      showLoading();
      const toastId = toast.loading("Creating the AI Summary...");

      let fileIds: Array<number> = [];

      if (!_.isUndefined(file)) {
        fileIds = [file.id];
      }

      openAIDocumentAssistantCreate({
        fileIds,
        dealId,
        promptPackageId: selectedPromptPackageId,
      })
        .then(({ id: openAIDocumentAssistantId }: { id: number }) => {
          toast.update(toastId, {
            render: "AI Summary has been created successfully!",
            type: "success",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            closeButton: true,
          });
          setIsSubmitting(false);
          hideLoading();
          onCreate({ openAIDocumentAssistantId });
          setSelectedPromptPackageId(undefined);
        })
        .catch((error) => {
          setIsSubmitting(false);
          hideLoading();
          toast.update(toastId, {
            autoClose: 1,
            hideProgressBar: true,
            isLoading: false,
          });
          handleInvalidRequest(error);
        });
    }
  }, [
    selectedPromptPackageId,
    file,
    dealId,
    onCreate,
    showLoading,
    hideLoading,
  ]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
    setSelectedPromptPackageId(undefined);
  }, [onClose, setIsOpen]);

  return _.isNil(file) ? (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ padding: 2 }}>
        <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
          <Text variant="h2">Create AI summary</Text>
          <Text variant="text2" color={colors.gray80} marginTop={1}>
            Analyze your documents using carefully pre-engineered prompt
            questions.
          </Text>
        </Paper>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        {promptPackagesAreLoading || _.isNil(promptPackages) ? (
          <Skeleton height="56px" />
        ) : (
          <PromptPackageSelect
            promptPackages={promptPackages}
            value={selectedPromptPackageId?.toString()}
            onChange={handleChangePromptPackage}
            showConfirmation={false}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          disabled={isSubmitting || _.isNil(selectedPromptPackageId)}
        >
          Create AI summary
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleClose} fullWidth>
      <DialogTitle sx={{ padding: 2 }}>
        <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
          <Text variant="h2">+ Add to new AI summary</Text>
          <Text variant="text2" color={colors.gray80} marginTop={1}>
            Add this file as a source material for AI docoument analysis
          </Text>
        </Paper>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Stack spacing={4}>
          {promptPackagesAreLoading || _.isNil(promptPackages) ? (
            <Skeleton height="56px" />
          ) : (
            <PromptPackageSelect
              promptPackages={promptPackages}
              value={selectedPromptPackageId?.toString()}
              onChange={handleChangePromptPackage}
              showConfirmation={false}
            />
          )}

          {!_.isEmpty(file.openai_files) && (
            <Stack spacing={2}>
              <Text variant="labelSmall">
                this file has already been added to:
              </Text>
              {file.openai_files?.map((openai_file) => (
                <Text
                  variant="text2"
                  key={openai_file.openai_document_assistant.id}
                >
                  {openai_file.openai_document_assistant.openai_prompt_package}
                </Text>
              ))}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          disabled={isSubmitting || _.isNil(selectedPromptPackageId)}
        >
          Add to AI summary
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAISummariesDialog;

import React from "react";
import _ from "lodash";

import Paper from "components/Paper";

import ExpensesTable from "entities/Proforma/components/ProformaIncomeAndOpex/ExpensesTable";
import IncomeAndOpexTable from "entities/Proforma/components/ProformaIncomeAndOpex/IncomeAndOpexTable";
import IncomeTable from "entities/Proforma/components/ProformaIncomeAndOpex/IncomeTable";
import ProformaNotEnoughDataPresent from "entities/Proforma/components/ProformaNotEnoughDataPresent";
import { IProforma } from "entities/Proforma/sdk";

interface IProformaIncomeAndOpex {
  incomeOperatingCost: IProforma["income_operating_cost"] | undefined;
  totalUnits: number | undefined;
}

const ProformaIncomeAndOpex: React.FC<IProformaIncomeAndOpex> = ({
  incomeOperatingCost,
  totalUnits,
}) =>
  _.isNil(incomeOperatingCost) || _.isNil(totalUnits) ? (
    <ProformaNotEnoughDataPresent />
  ) : (
    <>
      <Paper data-testid="income-opex-summary-section">
        <IncomeAndOpexTable
          incomeOperatingCost={incomeOperatingCost}
          totalUnits={totalUnits}
        />
      </Paper>
      <Paper data-testid="income-section">
        <IncomeTable
          incomeOperatingCost={incomeOperatingCost}
          totalUnits={totalUnits}
        />
      </Paper>
      <Paper data-testid="expenses-section">
        <ExpensesTable
          incomeOperatingCost={incomeOperatingCost}
          totalUnits={totalUnits}
        />
      </Paper>
    </>
  );

export default ProformaIncomeAndOpex;

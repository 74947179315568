import theme from "theme";

import Alert from "components/Alert";

const ProformaNotEnoughtDataPresent = () => (
  <Alert
    severity="error"
    sx={{ marginBottom: theme.spacing(2) }}
    data-testid="proforma-upload-error-alert"
  >
    Not enough data present to display this tab.
  </Alert>
);

export default ProformaNotEnoughtDataPresent;

import theme from "theme";
import { colors } from "theme/palette";

import Text from "components/Text";

export interface IButtonDropdownGroupLabel {
  label: string;
}

const ButtonDropdownGroupLabel = ({ label }: IButtonDropdownGroupLabel) => (
  <Text
    variant="labelSmall"
    sx={{ padding: theme.spacing(1, 1, 0.5, 1), color: colors.gray40 }}
    data-testid="dropdown-label"
  >
    {label}
  </Text>
);

export default ButtonDropdownGroupLabel;

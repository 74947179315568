import { useCallback, useMemo } from "react";

import Button from "components/Button";
import { MenuItem } from "components/Menu";
import toast from "components/Toast";

import { APPLICATION_STATUS } from "entities/Application/constants";
import {
  updateApplicationStatus,
  useLenderApplications,
} from "entities/Application/sdk";

export enum EChangeStatusButtonVariant {
  MENU_ITEM = "menu item",
  BUTTON = "button",
}

export interface IChangeStatusButton {
  applicationId: number;
  applicationName: string;
  currentStatus:
    | APPLICATION_STATUS.SUBMITTED
    | APPLICATION_STATUS.REJECTED
    | APPLICATION_STATUS.ARCHIVED;
  newStatus:
    | APPLICATION_STATUS.SUBMITTED
    | APPLICATION_STATUS.REJECTED
    | APPLICATION_STATUS.ARCHIVED;
  label: string;
  variant: EChangeStatusButtonVariant;
  updateCallback?: () => void;
}

const ChangeStatusButton = ({
  applicationId,
  applicationName,
  currentStatus,
  newStatus,
  label,
  variant,
  updateCallback,
}: IChangeStatusButton) => {
  const { mutate: updateCurrentStatusList } = useLenderApplications({
    status: currentStatus,
  });

  const newStatusReadableValue = useMemo(() => {
    if (newStatus === APPLICATION_STATUS.SUBMITTED) {
      return "Active";
    }
    if (newStatus === APPLICATION_STATUS.REJECTED) {
      return "Inactive";
    }

    return newStatus;
  }, [newStatus]);

  const handleChangeStatus = useCallback(
    () =>
      updateApplicationStatus({
        applicationId,
        status: newStatus,
      }).then(() => {
        updateCurrentStatusList();
        toast.successMessage(
          `Successfully moved intake ${applicationName} to ${newStatusReadableValue}.`
        );
        updateCallback?.();
      }),

    [
      applicationId,
      updateCurrentStatusList,
      newStatus,
      applicationName,
      updateCallback,
      newStatusReadableValue,
    ]
  );

  return variant === EChangeStatusButtonVariant.MENU_ITEM ? (
    <MenuItem onClick={handleChangeStatus}>{label}</MenuItem>
  ) : (
    <Button onClick={handleChangeStatus} variant="outlined">
      {label}
    </Button>
  );
};

export default ChangeStatusButton;

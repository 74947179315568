import { colors } from "theme/palette";

import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";

export interface IIconNavItem {
  isActive: boolean;
  icon: React.ReactNode;
  tooltipTitle: string;
  buttonDataTestid?: string;
}

const IconNavItem = ({
  icon,
  isActive,
  tooltipTitle,
  buttonDataTestid,
}: IIconNavItem) => (
  <Tooltip title={tooltipTitle}>
    <IconButton
      data-testid={buttonDataTestid}
      sx={{
        border: isActive ? `1px solid ${colors.blue60}` : "none",
        backgroundColor: isActive ? colors.blue40 : "transparent",
        opacity: isActive ? 1 : 0.6,
        transition: "opacity 200ms ease-out",
      }}
    >
      {icon}
    </IconButton>
  </Tooltip>
);

export default IconNavItem;

import { useCallback, useState } from "react";
import _ from "lodash";

import { AddIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Chip from "components/Chip";
import Dialog, { DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";

import ChecklistCloneForm from "entities/CompanyChecklist/components/ChecklistCloneForm";
import CloneSelectedChecklist from "entities/CompanyChecklist/components/CloneSelectedChecklist";
import { IPublicChecklist } from "entities/Package/sdk";

export interface ICatalogDialog {
  isOpen: boolean;
  onClose: () => void;
  closeAndRefreshChecklists: ({ checklistId }: { checklistId: number }) => void;
}

const CatalogDialog = ({
  isOpen,
  onClose,
  closeAndRefreshChecklists,
}: ICatalogDialog) => {
  const [activeStep, setActiveStep] = useState<0 | 1>(0);
  const [selectedPackage, setSelectedPackage] =
    useState<IPublicChecklist | null>(null);

  const handleClone = useCallback(
    ({ checklistId }: { checklistId: number }) => {
      closeAndRefreshChecklists({ checklistId });
      setActiveStep(0);
      setSelectedPackage(null);
    },
    [closeAndRefreshChecklists]
  );

  const handleClose = useCallback(() => {
    onClose();
    setActiveStep(0);
    setSelectedPackage(null);
  }, [onClose]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      {activeStep === 0 && (
        <Stack padding={2} spacing={2} sx={{ overflowY: "hidden" }}>
          <DialogTitle
            sx={{
              paddingX: theme.spacing(2),
              marginBottom: theme.spacing(3),
              borderRadius: 2,
              backgroundColor: colors.blue10,
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <AddIcon
                style={{
                  height: "24px",
                  width: "24px",
                }}
              />
              <Text
                variant="h2"
                marginBottom={theme.spacing(0.5)}
                color={colors.gray100}
              >
                Add new template
              </Text>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              paddingTop={1}
            >
              <Chip
                label="Builders Patch catalog"
                sx={{
                  border: `1px solid ${colors.gray40}`,
                  backgroundColor: colors.gray10,
                  borderRadius: 4,
                  height: 24,
                }}
              />
              <Text variant="text2" color={colors.gray80}>
                Select from our library of available checklists.
              </Text>
            </Stack>
          </DialogTitle>
          <ChecklistCloneForm
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
            onSelect={() => setActiveStep(1)}
            onClose={handleClose}
          />
        </Stack>
      )}
      {activeStep === 1 && !_.isNil(selectedPackage) && (
        <CloneSelectedChecklist
          checklist={selectedPackage}
          onBack={() => setActiveStep(0)}
          onClone={handleClone}
        />
      )}
    </Dialog>
  );
};

export default CatalogDialog;

import _ from "lodash";

import { downloadListOfListsAsXLSX } from "utils/xlsx";

import {
  EXPORT_HEADERS,
  LENDER_EXPORT_HEADERS,
} from "entities/Application/constants";
import {
  IApplicationWithRefetch,
  ILenderApplicationWithRefetch,
} from "entities/Application/sdk";

export enum EExportName {
  APPLICANT_DASHBOARD = "applicant-dashboard",
  REVIEWER_DASHBOARD = "reviewer-dashboard",
}

export const exportTableAsXLSX = ({
  headers,
  applications,
  exportName,
}: {
  headers: typeof EXPORT_HEADERS | typeof LENDER_EXPORT_HEADERS;
  applications: Array<IApplicationWithRefetch | ILenderApplicationWithRefetch>;
  exportName: EExportName;
}) => {
  const headersData = headers.map((header) => header.label);

  const rowsData = applications.map((data) => {
    return headers.flatMap((header) => {
      // Retrieve the original value.
      const value = _.get(data, header.key) || "";

      return value;
    });
  });

  const preparedData = [headersData, ...rowsData];
  downloadListOfListsAsXLSX(preparedData, `${exportName}.xlsx`);
};

import { useCallback, useMemo } from "react";
import { FormikProps } from "formik";
import _ from "lodash";

import { AddIcon } from "icons";

import Button from "components/Button";
import FormHelperText from "components/FormHelperText";
import Stack from "components/Stack";

import { IFormikParticipant } from "entities/CompanyItegrations/components/Downhome/BusinessForm";
import AddOwnerButton from "entities/CompanyItegrations/components/Downhome/BusinessForm/AddOwnerButton";
import { IDownhomeOwnerProperties } from "entities/CompanyItegrations/components/Downhome/BusinessForm/AddOwnerButton/constants";
import Address from "entities/CompanyItegrations/components/Downhome/BusinessForm/Address";
import BusinessOwner from "entities/CompanyItegrations/components/Downhome/BusinessForm/BusinessOwner";
import Email from "entities/CompanyItegrations/components/Downhome/BusinessForm/Email";
import Phone from "entities/CompanyItegrations/components/Downhome/BusinessForm/Phone";
import Website from "entities/CompanyItegrations/components/Downhome/BusinessForm/Website";
import { IDownhomePersonParticipant } from "entities/CompanyItegrations/sdk";

import { EListFieldType } from "./constants";
import { getButtonLabel, getEmptyFieldValues } from "./utils";

export interface IListField {
  formik: FormikProps<IFormikParticipant>;
  fieldType: EListFieldType;
  initialOwners?: IDownhomeOwnerProperties[] | null;
}

const ListField = ({ formik, fieldType, initialOwners }: IListField) => {
  const formikValues = useMemo(
    () => formik.values[fieldType],
    [formik, fieldType]
  );

  const formikFieldError = useMemo(
    () => formik.errors[fieldType],
    [formik, fieldType]
  );

  const buttonLabel = useMemo(
    () => getButtonLabel({ type: fieldType }),
    [fieldType]
  );

  const handleAddNewElement = useCallback(() => {
    const elements = !_.isNil(formikValues) ? [...formikValues] : [];

    const isPrimary = elements.length === 0;

    elements.push({
      ...getEmptyFieldValues({ type: fieldType }),
      primary: isPrimary,
    });

    formik.setFieldValue(fieldType, elements);
  }, [formikValues, formik, fieldType]);

  const handleAddOwner = useCallback(
    ({
      owner,
      setPrimaryOnAdding,
    }: {
      owner: IDownhomePersonParticipant | null;
      setPrimaryOnAdding: boolean;
    }) => {
      const elements = !_.isNil(formikValues) ? [...formikValues] : [];

      const isPrimary = elements.length === 0;

      if (!_.isNil(owner)) {
        elements.push({
          ...owner,
          primary: setPrimaryOnAdding ? isPrimary : owner.primary,
        });
      }

      formik.setFieldValue(fieldType, elements);
    },
    [formikValues, formik, fieldType]
  );

  const removeElement = useCallback(
    ({ index }: { index: number }) => {
      let elements = !_.isNil(formikValues) ? _.cloneDeep(formikValues) : [];

      if (elements.length === 1) {
        formik.setFieldValue(fieldType, null);
      } else if (elements.length > 1) {
        const updatedElements = elements.filter(
          (_, itemIndex) => index !== itemIndex
        );

        if (!updatedElements.some((element) => element.primary)) {
          updatedElements[0].primary = true;
        }

        formik.setFieldValue(fieldType, updatedElements);
      }
    },
    [formik, formikValues, fieldType]
  );

  const updatePrimaryElement = useCallback(
    ({ index }: { index: number }) => {
      const elements = !_.isNil(formikValues) ? [...formikValues] : [];

      const newElements = elements.map((element, i) => ({
        ...element,
        primary: i === index,
      }));

      formik.setFieldValue(fieldType, newElements);
    },
    [formik, formikValues, fieldType]
  );

  return (
    <Stack spacing={2} alignItems="start">
      {fieldType === EListFieldType.ADDRESSES && (
        <Address
          formik={formik}
          onRemove={removeElement}
          onUpdatePrimary={updatePrimaryElement}
        />
      )}
      {fieldType === EListFieldType.EMAILS && (
        <Email
          formik={formik}
          onRemove={removeElement}
          onUpdatePrimary={updatePrimaryElement}
        />
      )}
      {fieldType === EListFieldType.PHONES && (
        <Phone
          formik={formik}
          onRemove={removeElement}
          onUpdatePrimary={updatePrimaryElement}
        />
      )}
      {fieldType === EListFieldType.WEBSITES && (
        <Website
          formik={formik}
          onRemove={removeElement}
          onUpdatePrimary={updatePrimaryElement}
        />
      )}
      {fieldType === EListFieldType.OWNERS && (
        <BusinessOwner
          formik={formik}
          onRemove={removeElement}
          onUpdatePrimary={updatePrimaryElement}
        />
      )}

      {formikFieldError && _.isString(formikFieldError) && (
        <FormHelperText error>{formikFieldError}</FormHelperText>
      )}

      {fieldType === EListFieldType.OWNERS ? (
        <AddOwnerButton
          handleAddOwner={handleAddOwner}
          currentOwners={formik.values["owners"]}
          initialOwners={initialOwners}
        />
      ) : (
        <Button
          onClick={handleAddNewElement}
          variant="outlined"
          color="secondary"
          startIcon={<AddIcon />}
        >
          {buttonLabel}
        </Button>
      )}
    </Stack>
  );
};

export default ListField;

import _ from "lodash";

import { isNotUndefined } from "utils/common";

import { ITableCell, ITableRow } from "entities/TableStorage/sdk";

/**
 * This method is used to convert the data that is pasted into an expected format Array<Array<ITableCell>>.
 * Each row in the array represents a row in the table, and each element in the row represents a cell in the table.
 * It's possible to have a new line in some cell, so we need to handle that case. Otherwise, we are using the new line to separate the rows.
 */
export const convertPastedDataToTableRows = ({
  data,
}: {
  data: string;
}): Array<ITableRow> => {
  const tableDataRows: Array<ITableRow> = [];
  let currentRowCells: Array<ITableCell> = [];
  let withinQuotes = false;
  let currentCell = "";

  [...data].forEach((char) => {
    if (char === '"') {
      withinQuotes = !withinQuotes;
    } else if (char === "\n" && !withinQuotes) {
      currentRowCells.push({ value: currentCell.trim() });
      tableDataRows.push({ cells: currentRowCells });
      currentRowCells = [];
      currentCell = "";
    } else if (char === "\t" && !withinQuotes) {
      currentRowCells.push({ value: currentCell.trim() });
      currentCell = "";
    } else {
      currentCell += char;
    }
  });

  // Add the last cell to the current row
  currentRowCells.push({ value: currentCell.trim() });
  tableDataRows.push({ cells: currentRowCells });

  return tableDataRows;
};

// Check if the string contains <table>
export const isHTMLTableData = ({ data }: { data: string }) =>
  _.includes(data, "<table");

export const convertHTMLTableInRowTableData = ({
  data,
}: {
  data: string;
}): string => {
  // Extract rows from the table data string
  const rows = data.split("<tr");

  // Get the row where cell data are in <th> tag. This is the header row.
  const headersData = rows
    .map((row: string) => {
      const headerCellsMatches = row.match(/<th[^>]*>([^<]*)<\/th>/g);

      return headerCellsMatches?.map((cellMatch) =>
        cellMatch.match(/<th[^>]*>([^<]*)<\/th>/)?.[1].trim()
      );
    })
    .filter(isNotUndefined);

  // Get the rows where cell data are in <td> tag. This is the table's content.
  const rowsData = rows
    .map((row: string) => {
      const cellsMatches = row.match(/<td[^>]*>([^<]*)<\/td>/g);

      return cellsMatches?.map((cellMatch) =>
        cellMatch.match(/<td[^>]*>([^<]*)<\/td>/)?.[1].trim()
      );
    })
    .filter(isNotUndefined);

  const tableRows = [...headersData, ...rowsData];

  // Format the data as separate rows with \n and columns with \t
  return tableRows.map((row: any) => row.join("\t")).join("\n");
};

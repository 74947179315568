import { Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import theme from "theme";

import Backdrop from "components/Backdrop";
import Box from "components/Box";
import Footer from "components/Footer";
import MainNavigation from "components/MainNavigation";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

const PageLayout = () => {
  const matches = useMediaQuery("(max-width:1023px)");

  return (
    <Paper square={true} sx={{ padding: 0 }}>
      <Stack sx={{ minHeight: "100vh" }}>
        <Box sx={{ position: "fixed", zIndex: 999, width: "100%" }}>
          <MainNavigation />
        </Box>
        <Stack
          sx={{
            padding: theme.spacing(5, 1, 2, 1),
            flex: 1,
          }}
        >
          <Outlet />
        </Stack>
        <Footer />
      </Stack>
      <Backdrop
        open={matches}
        sx={{
          alignItems: "flex-start",
          zIndex: 1000,
          backgroundColor: "white",
          opacity: 0.9,
        }}
      >
        <Paper
          sx={{ padding: theme.spacing(4), margin: theme.spacing(15, 2, 0, 2) }}
          elevation={4}
        >
          <Text variant="text1">
            You need to view this app on a Desktop computer or a Laptop.
          </Text>
        </Paper>
      </Backdrop>
    </Paper>
  );
};
export default PageLayout;

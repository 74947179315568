import { useCallback } from "react";

import { URLS } from "config/urls";
import theme from "theme";
import { format, FORMATS, parseISO } from "utils/datetime";
import { reverse } from "utils/urls";

import Card, { CardActionArea, CardContent } from "components/Card";
import Container from "components/Container";
import Divider from "components/Divider";
import Grid from "components/Grid";
import Link from "components/Link";
import { LinearProgress } from "components/Loading";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";
import UserAvatar from "components/UserAvatar";

import AddMemoTemplateButton from "entities/MemoTemplate/components/AddMemoTemplateButton";
import DeleteMemoTemplateIconButton from "entities/MemoTemplate/components/DeleteIconButton";
import EditMemoTemplateIconButton from "entities/MemoTemplate/components/EditIconButton";
import { useMemoTemplates } from "entities/MemoTemplate/sdk";

const MemoTemplateList = () => {
  const { data: memos, mutate: mutateMemos, isValidating } = useMemoTemplates();

  const refetchMemos = useCallback(() => mutateMemos(), [mutateMemos]);

  usePageTitle("Builders Patch: Memo templates");

  return (
    <Paper
      sx={{ padding: theme.spacing(4, 3) }}
      data-testid="memo-templates-content"
    >
      <PageHeader
        title="Memo templates"
        actions={<AddMemoTemplateButton onMemoTemplateCreated={refetchMemos} />}
      />
      <Container sx={{ paddingTop: theme.spacing(4) }}>
        {isValidating && (
          <LinearProgress sx={{ marginBottom: theme.spacing(2) }} />
        )}
        <Grid container gap={4} data-testid="memo-templates-list">
          {memos?.map((memo) => (
            <Grid item lg={4} key={memo.memo_id}>
              <Card
                elevation={2}
                sx={{ position: "relative" }}
                data-testid="memo-template-card"
              >
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    position: "absolute",
                    right: theme.spacing(2),
                    top: theme.spacing(2),
                    zIndex: 1,
                  }}
                >
                  <EditMemoTemplateIconButton
                    memoId={memo.memo_id}
                    onUpdated={refetchMemos}
                  />
                  <DeleteMemoTemplateIconButton
                    memoId={memo.memo_id}
                    onDeleted={refetchMemos}
                  />
                </Stack>
                <Link
                  to={reverse(URLS.MEMO_TEMPLATE_EDITOR, {
                    memoId: memo.memo_id,
                  })}
                  data-testid="template-edit-link"
                >
                  <CardActionArea>
                    <CardContent>
                      <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                          <Text variant="h1" data-testid="memo-template-name">
                            {memo.name}
                          </Text>

                          {/* acts as a placeholder for the buttons which are displayed above it(which are placed absolutely so their dialogs wouldn't interfere with the card actions) */}
                          <div style={{ width: "80px" }} />
                        </Stack>
                        <Divider />
                        <Stack data-testid="created-by">
                          <Text variant="label">Created By:</Text>
                          <Stack direction="row" alignItems="center">
                            <UserAvatar
                              alt="avatar"
                              src={
                                memo.created_by.avatar
                                  ? memo.created_by.avatar
                                  : undefined
                              }
                              imgix
                              imgixWidth={24}
                              imgixHeight={24}
                              sx={{
                                width: 24,
                                height: 24,
                                mr: theme.spacing(1),
                              }}
                            />
                            <Text variant="text3" sx={{ fontWeight: 700 }}>
                              {memo.created_by.first_name}{" "}
                              {memo.created_by.last_name}
                            </Text>
                          </Stack>
                        </Stack>
                        <Divider />
                        {memo.updated_by && (
                          <Stack data-testid="updated-by">
                            <Text variant="label">Updated By:</Text>
                            <Stack direction="row" alignItems="center">
                              <UserAvatar
                                alt="avatar"
                                src={
                                  memo.updated_by.avatar
                                    ? memo.updated_by.avatar
                                    : undefined
                                }
                                imgix
                                imgixWidth={24}
                                imgixHeight={24}
                                sx={{
                                  width: 24,
                                  height: 24,
                                  mr: theme.spacing(1),
                                }}
                              />
                              <Text variant="text3">
                                {memo.updated_by.first_name}{" "}
                                {memo.updated_by.last_name}
                              </Text>
                            </Stack>
                          </Stack>
                        )}
                        <Stack data-testid="last-update">
                          <Text variant="label">Last Update:</Text>
                          <Text variant="labelSmall">
                            {format(
                              parseISO(memo.updated_at),
                              FORMATS.FRONTEND.DATETIME
                            )}
                          </Text>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Paper>
  );
};

export default MemoTemplateList;

import React from "react";

import { AddIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import AddDealForm from "entities/Deal/components/AddForm";

export interface IAddDealDialog {
  open: boolean;
  handleClose: () => void;
  onCreated?: () => void;
}
const AddDealDialog: React.FC<IAddDealDialog> = ({
  open,
  handleClose,
  onCreated,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box
        sx={{ padding: theme.spacing(0, 10) }}
        data-testid="create-deal-dialog"
      >
        <Paper
          sx={{
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
          }}
        >
          <Stack spacing={4}>
            <Stack direction="row" spacing={1}>
              <AddIcon
                style={{
                  height: theme.spacing(5),
                  width: theme.spacing(5),
                }}
              />
              <Text variant="h1">Create New Deal</Text>
            </Stack>
            <AddDealForm onCreated={onCreated} handleCancel={handleClose} />
          </Stack>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default AddDealDialog;

import React from "react";

import Box from "components/Box";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface ISectionSummary {
  title: string;
}

// This is a shared component for showing the Executive summary, risks and mitigations and final recommendations sections
const SectionSummary: React.FC<ISectionSummary> = ({ title }) => {
  return (
    <Box>
      <SectionHeader label={title} />
      <SectionContent breakInside="avoid">
        <Box
          sx={{
            breakInside: "avoid",
          }}
        >
          <p>This is an example {title} text.</p>
        </Box>
      </SectionContent>
    </Box>
  );
};

export default SectionSummary;

import React from "react";

import Text from "components/Text";

const NoContent = () => (
  <Text
    variant="body2"
    sx={{
      fontStyle: "italic",
      margin:
        "16px 0" /* Like the <p> tags surrounding entered text. This helps with making the style consistent regardless of entered data or not.*/,
    }}
    data-testid="empty-value-data"
  >
    Content not added...
  </Text>
);

export default NoContent;

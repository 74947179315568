import _ from "lodash";

import {
  getConstructionTypeLabelByValue,
  getPropertyTypeLabelByValue,
} from "entities/Deal/utils";
import {
  ASSET_TYPE_COLUMN_KEY,
  CONSTRUCTION_TYPE_COLUMN_KEY,
  EFieldFormatType,
  PROPERTY_TYPE_COLUMN_KEY,
} from "entities/Reporting/constants";
import { IReportPackageFilter } from "entities/Reporting/sdk";

export const generateFilterOptions = ({
  rows,
  filterDict,
  filterKeys,
}: {
  rows: Array<{ [key: string]: string | null }>;
  filterDict: IReportPackageFilter[] | undefined;
  filterKeys: string[] | undefined;
}) =>
  Object.keys(rows.length > 0 ? rows[0] : {})
    .filter((key) => filterKeys && filterKeys.indexOf(key.toLowerCase()) >= 0)
    .map((key) => {
      const type =
        filterDict?.find((filter) => filter.name === key.toLowerCase())
          ?.field_format_type || EFieldFormatType.STRING;

      const values = _.uniq(
        rows
          .map((row) => {
            let rowValue = _.get(row, key) || "";

            rowValue = rowValue.toString();

            return rowValue.trim();
          })
          .filter((value) => value)
      );
      const options = _.orderBy(
        Array.from(values).map((value) => ({
          label: getOptionLabel({ key, value }),
          value,
        })),
        "value"
      );

      return { label: key, options, type };
    })
    .filter((filter) => filter.options.length > 0);

export const getOptionLabel = ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  if (key.toLowerCase() === CONSTRUCTION_TYPE_COLUMN_KEY) {
    return getConstructionTypeLabelByValue({ value });
  } else if (
    key.toLocaleLowerCase() === PROPERTY_TYPE_COLUMN_KEY ||
    key === ASSET_TYPE_COLUMN_KEY
  ) {
    return getPropertyTypeLabelByValue({ value });
  }

  return value;
};

import { useCallback, useMemo } from "react";
import _ from "lodash";

import { OpenInNewIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";

import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IChecklistInfoBox {
  title: string;
  subTitle: string | React.ReactNode;
  url?: string;
  width?: string;
  disabled?: boolean;
}

const ChecklistInfoBox = ({
  title,
  subTitle,
  url,
  width,
  disabled,
}: IChecklistInfoBox) => {
  const hasLink = useMemo(() => !_.isUndefined(url), [url]);

  const handleClick = useCallback(() => {
    if (!_.isUndefined(url)) {
      openURLInNewTab({
        url,
      });
    }
  }, [url]);

  return (
    <Stack
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleClick}
      sx={{
        height: "100%",
        width: !_.isUndefined(width) ? width : "100%",
        borderRadius: "8px",
        backgroundColor: disabled ? colors.gray20 : colors.blue20,
        border: `1px solid ${disabled ? colors.gray40 : colors.blue80}`,
        padding: theme.spacing(1, 2),
        cursor: hasLink ? "pointer" : "default",
        transition: "color 0.3s ease",
        "&:hover": {
          backgroundColor: hasLink ? colors.blue10 : colors.blue20,
        },
      }}
      data-testid="checklist-info"
    >
      <Stack
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Tooltip title={title} placement="top">
          <Text
            variant="text2"
            fontWeight={700}
            color={colors.black}
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            data-testid="checklist-name"
          >
            {title}
          </Text>
        </Tooltip>
        <Tooltip title={subTitle} placement="top">
          <Text
            variant="text4"
            color={colors.gray80}
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            data-testid="checklist-owner-company"
          >
            {subTitle}
          </Text>
        </Tooltip>
      </Stack>
      {hasLink && (
        <Tooltip title="Open in a new tab" placement="top">
          <OpenInNewIcon
            sx={{ color: colors.gray60 }}
            data-testid="checklist-page-link-icon"
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default ChecklistInfoBox;

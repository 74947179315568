import { memo, useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import { COMPANY_PACKAGE_NAME_MAX_LENGTH } from "entities/CompanyChecklist/constants";
import { cloneCompanyChecklist, IPublicChecklist } from "entities/Package/sdk";

export interface ICloneSelectedChecklist {
  checklist: IPublicChecklist;
  onBack: () => void;
  onClone: ({ checklistId }: { checklistId: number }) => void;
}

const CloneSelectedChecklist = ({
  checklist,
  onBack,
  onClone,
}: ICloneSelectedChecklist) => {
  const [checklistName, setChecklistName] = useCaching<string>(checklist.name);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const handleClone = useCallback(() => {
    cloneCompanyChecklist({
      checklistId: checklist.id,
      newChecklistName: checklistName,
    })
      .then(({ id }) => {
        onClone({ checklistId: id });
      })
      .catch((error) => handleInvalidRequest(error));
  }, [checklistName, checklist.id, onClone]);

  const saveIsDisabled = useMemo(
    () => checklistName === "" || showErrorMessage,
    [checklistName, showErrorMessage]
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setChecklistName(value);

      if (value.length > COMPANY_PACKAGE_NAME_MAX_LENGTH) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    },
    [setChecklistName]
  );

  const errorMessage = useMemo(
    () =>
      showErrorMessage
        ? `The name of the checklist cannot be longer than ${COMPANY_PACKAGE_NAME_MAX_LENGTH} characters.`
        : "",
    [showErrorMessage]
  );

  return (
    <Stack spacing={3}>
      <Stack padding={4} spacing={3}>
        <Stack>
          <Text variant="label" fontWeight={500} color={colors.gray80}>
            Selected catalog checklist
          </Text>
          <Stack
            direction="row"
            spacing={2.5}
            sx={{
              backgroundColor: colors.blue40,
              borderRadius: theme.spacing(0.5),
            }}
          >
            <Stack paddingX={2} paddingY={1}>
              <Text variant="text2" fontWeight={700}>
                {checklist.name}
              </Text>
              <Text variant="text4" color={colors.gray80}>
                by <b>{checklist.company.name}</b>
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Text variant="text2" fontWeight={400} color={colors.gray80}>
          A copy of this template will be added to your own custom checklists.
          You can rename it, modify it.
        </Text>
        <Stack>
          <Text variant="text3" fontWeight={500} color={colors.gray80}>
            Re-name your checklist:
          </Text>
          <TextField
            variant="outlined"
            value={checklistName}
            onChange={handleNameChange}
            helperText={errorMessage}
            error={showErrorMessage}
          />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2} padding={2}>
        <Button variant="text" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          disabled={saveIsDisabled}
          variant="contained"
          onClick={handleClone}
        >
          Add to templates
        </Button>
      </Stack>
    </Stack>
  );
};

export default memo(CloneSelectedChecklist, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useCallback, useMemo, useState } from "react";

import { InstructionsIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { areInstructionsEmpty } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Paper from "components/Paper";
import SanitizeHtml from "components/SanitizeHtml";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IInstructionsDialogButton {
  instructions: string | null | undefined;
  dialogTitle?: string;
}
const InstructionsDialogButton = ({
  instructions,
  dialogTitle = "Instructions",
}: IInstructionsDialogButton) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const showInstructions = useMemo(
    () => !areInstructionsEmpty({ instructions }),
    [instructions]
  );

  return showInstructions ? (
    <>
      <Tooltip title="Instructions" placement="top">
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          data-testid="instructions-icon"
        >
          <InstructionsIcon
            sx={{
              width: "16px",
              height: "16px",
            }}
            onClick={handleOpen}
          />
        </Box>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ padding: theme.spacing(2) }}>
          <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
            <Text variant="h2">{dialogTitle}</Text>
          </Paper>
        </DialogTitle>
        <DialogContent sx={{ padding: theme.spacing(1, 3) }}>
          <SanitizeHtml html={instructions || ""} />
        </DialogContent>
        <DialogActions sx={{ padding: theme.spacing(2) }}>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default InstructionsDialogButton;

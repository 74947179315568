import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { NoteIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Box from "components/Box";
import Dialog, { DialogContent, DialogTitle } from "components/Dialog";
import Divider from "components/Divider";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { useFileFieldRemarks } from "entities/Field/sdk";

interface IRemarkLabel {
  sharedRemark: string;
  dealId: number;
  packageId: number;
  fieldId: number;
}

const RemarkLabel = ({
  sharedRemark,
  dealId,
  packageId,
  fieldId,
}: IRemarkLabel) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: remarkData } = useFileFieldRemarks({
    dealId,
    packageId,
    fieldId,
    enabled: isOpen,
  });

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const showSharedRemark = useMemo(
    () =>
      !_.isEmpty(remarkData?.shared_remark) &&
      remarkData?.shared_remark !== "null",
    [remarkData]
  );

  const showPrivateRemark = useMemo(
    () =>
      !_.isEmpty(remarkData?.private_remark) &&
      remarkData?.private_remark !== "null",
    [remarkData]
  );

  const showDivider = useMemo(
    () => showSharedRemark && showPrivateRemark,
    [showSharedRemark, showPrivateRemark]
  );

  const isSharedRemarkCreated = useMemo(
    () =>
      !_.isNil(remarkData?.shared_remark_updated_at) &&
      _.isNil(remarkData?.shared_remark_updated_by),
    [remarkData]
  );

  const isPrivateRemarkCreated = useMemo(
    () =>
      !_.isNil(remarkData?.private_remark_updated_at) &&
      _.isNil(remarkData?.private_remark_updated_by),
    [remarkData]
  );

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Open remarks" placement="top">
          <Box
            onClick={handleOpen}
            sx={{
              width: "16px",
              height: "16px",
              textAlign: "center",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            data-testid="shared-remarks-view-button"
          >
            <NoteIcon
              style={{ width: "16px", height: "16px" }}
              data-testid="remarks-icon"
            />
          </Box>
        </Tooltip>
        <Text title={sharedRemark} data-test="field-shared-remarks-short-text">
          {_.truncate(sharedRemark, { length: 30 })}
        </Text>
      </Stack>
      {!_.isUndefined(remarkData) && (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle
            sx={{ padding: theme.spacing(2) }}
            data-testid="remarks-view-dialog-title"
          >
            <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
              <Text variant="h2">Remarks</Text>
            </Paper>
          </DialogTitle>
          <DialogContent sx={{ padding: theme.spacing(1, 3) }}>
            {showSharedRemark && (
              <Stack
                spacing={2}
                sx={{
                  paddingX: theme.spacing(3),
                  marginBottom: theme.spacing(2),
                }}
                data-testid="remarks-text-value"
              >
                <Text variant="h3">Shared remark</Text>
                <span>{remarkData.shared_remark}</span>
                {isSharedRemarkCreated ? (
                  <Text variant="text4" sx={{ color: colors.gray60 }}>
                    Created on{" "}
                    {format(
                      parseJSON(remarkData.shared_remark_updated_at),
                      FORMATS.FRONTEND.DATE
                    )}{" "}
                    by {remarkData.shared_remark_created_by}
                  </Text>
                ) : (
                  <Text variant="text4" sx={{ color: colors.gray60 }}>
                    Last edited on{" "}
                    {format(
                      parseJSON(remarkData.shared_remark_updated_at),
                      FORMATS.FRONTEND.DATE
                    )}{" "}
                    by {remarkData.shared_remark_updated_by}
                  </Text>
                )}
              </Stack>
            )}
            {showDivider && <Divider sx={{ marginY: theme.spacing(2) }} />}
            {showPrivateRemark && (
              <Stack
                spacing={2}
                sx={{
                  paddingX: theme.spacing(3),
                  marginBottom: theme.spacing(2),
                }}
                data-testid="remarks-text-value"
              >
                <Text variant="h3">Private remark</Text>
                <span>{remarkData.private_remark}</span>
                {isPrivateRemarkCreated ? (
                  <Text variant="text4" sx={{ color: colors.gray60 }}>
                    Created on{" "}
                    {format(
                      parseJSON(remarkData.private_remark_updated_at),
                      FORMATS.FRONTEND.DATE
                    )}{" "}
                    by {remarkData.private_remark_created_by}
                  </Text>
                ) : (
                  <Text variant="text4" sx={{ color: colors.gray60 }}>
                    Last edited on{" "}
                    {format(
                      parseJSON(remarkData.private_remark_updated_at),
                      FORMATS.FRONTEND.DATE
                    )}{" "}
                    by {remarkData.private_remark_updated_by}
                  </Text>
                )}
              </Stack>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RemarkLabel;

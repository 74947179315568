import React, { useCallback } from "react";

import { AddIcon } from "icons";

import Button from "components/Button";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";

interface IWorkspaceNewTaskButton {
  onSubmit: () => void;
}

const WorkspaceNewTaskButton: React.FC<IWorkspaceNewTaskButton> = ({
  onSubmit,
}) => {
  const { openCreateNewTaskSubTab } = useDealSidepanel();

  const handleCreateNewTask = useCallback(() => {
    openCreateNewTaskSubTab({ updateParent: onSubmit });
  }, [openCreateNewTaskSubTab, onSubmit]);

  return (
    <Button startIcon={<AddIcon />} onClick={handleCreateNewTask}>
      New task
    </Button>
  );
};

export default WorkspaceNewTaskButton;

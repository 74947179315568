import { memo, useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { generateAnswer, IOpenAIPrompt } from "entities/AISummaries/sdk";

export interface IPrompt {
  prompt: IOpenAIPrompt;
  openAIDocumentAssistantId: number;
  isSubmitButtonDisabled: boolean;
  onClickGenerateAnswer: () => void;
  onGenerateAnswer: () => void;
}

const Prompt = ({
  prompt,
  openAIDocumentAssistantId,
  isSubmitButtonDisabled,
  onClickGenerateAnswer,
  onGenerateAnswer,
}: IPrompt) => {
  const handleSubmit = useCallback(() => {
    onClickGenerateAnswer();
    generateAnswer({
      openAIDocumentAssistantId,
      promptId: prompt.id,
    }).then(() => {
      onGenerateAnswer();
      toast.successMessage(
        "You will be notified once the processing has finished."
      );
    });
  }, [
    prompt,
    openAIDocumentAssistantId,
    onClickGenerateAnswer,
    onGenerateAnswer,
  ]);

  const reasonForButtonBeingDisabled = useMemo(() => {
    if (isSubmitButtonDisabled) {
      return "Please wait for the current answer to be generated.";
    }

    return "";
  }, [isSubmitButtonDisabled]);

  return (
    <Stack direction="row" spacing={1}>
      <Box
        flex={1}
        sx={{
          padding: theme.spacing(1),
          backgroundColor: colors.blue20,
          borderRadius: "4px",
        }}
      >
        <Text variant="text2" fontWeight={700}>
          {prompt.question}
        </Text>
      </Box>
      <Tooltip title={reasonForButtonBeingDisabled}>
        <span>
          <Button disabled={isSubmitButtonDisabled} onClick={handleSubmit}>
            Generate answer
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default memo(Prompt, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Table, { TableBody, TableCell, TableRow } from "components/Table";
import Text from "components/Text";

import { IDeal } from "entities/Deal/sdk";

interface ILocationTable {
  address: IDeal["address"];
}

const LocationTable: React.FC<ILocationTable> = ({ address }) => (
  <Table size="small">
    <TableBody>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            Primary address
          </Text>
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 0 }}>
          <Text variant="text2" data-testid="deal-street-address">
            {address.street_address}
          </Text>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            City + ZIP
          </Text>
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 0 }}>
          <Text variant="text2" data-testid="deal-city-and-zipcode">
            {[address.city, address.zipcode].join(", ")}
          </Text>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            State
          </Text>
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 0 }}>
          <Text variant="text2" data-testid="deal-state">
            {address.state}
          </Text>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            Longitude
          </Text>
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 0 }}>
          <Text variant="text2">{address.longitude}</Text>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            Latitude
          </Text>
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 0 }}>
          <Text variant="text2">{address.latitude}</Text>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default LocationTable;

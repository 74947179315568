import React from "react";

import Stack from "components/Stack";

import SectionSummary from "entities/MemoTemplate/components/Sections/SectionSummary";
import { proformaDemoData } from "entities/MemoTemplate/components/Sections/utils";
import ProformaDevelopmentBudget from "entities/Proforma/components/ProformaDevelopmentBudget";

const MemoTemplateDevelopmentBudget = () => {
  return (
    <Stack justifyContent="space-between" spacing={4}>
      <ProformaDevelopmentBudget proforma={proformaDemoData} />
      <SectionSummary title="Development budget description" />
    </Stack>
  );
};

export default MemoTemplateDevelopmentBudget;

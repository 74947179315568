import _ from "lodash";

import { parseISO } from "utils/datetime";

export const getFormattedDateOfBirth = ({
  dateOfBirth,
}: {
  dateOfBirth: string | null;
}) => {
  if (_.isNil(dateOfBirth)) {
    return null;
  }

  return parseISO(dateOfBirth);
};

import Box from "components/Box";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface IAISummaryResponseSection {
  title: string;
}

const AISummaryResponseSection = ({ title }: IAISummaryResponseSection) => (
  <Box>
    <SectionHeader label={title} />
    <SectionContent breakInside="avoid">
      <Box
        sx={{
          breakInside: "avoid",
        }}
      >
        <p>This is an example {title} text.</p>
      </Box>
    </SectionContent>
  </Box>
);

export default AISummaryResponseSection;

import React, { useCallback } from "react";
import _ from "lodash";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import { CircularProgress } from "components/Loading";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import TreeView, { TreeItem } from "components/TreeView";

import TreeItemLabel from "entities/DDPackageZip/components/TreeItemLabel";
import { useZipStructure } from "entities/DDPackageZip/sdk";

interface IFileTree {
  dealId: number;
  packageId: number;
}

const FileTree: React.FC<IFileTree> = ({ dealId, packageId }) => {
  const { data: structure, loading } = useZipStructure({
    dealId,
    packageId,
  });

  // TODO: Replace with actual type.
  const calculateFilesCount = useCallback((object: any) => {
    if (object.files) {
      return object.files.length;
    } else {
      let sum = 0;
      if (object.sections) {
        object.sections.forEach((element: any) => {
          sum = sum + calculateFilesCount(element);
        });
      } else {
        object.forEach((element: any) => {
          sum = sum + calculateFilesCount(element);
        });
      }
      return sum;
    }
  }, []);

  return (
    <Paper
      sx={{
        padding: theme.spacing(2),
        backgroundColor: colors.blue10,
        flex: 1,
        maxWidth: "50%",
      }}
      data-testid="checklist-structure-tree-section"
    >
      {structure && (
        <TreeView
          slots={{
            collapseIcon: ArrowDropDownIcon,
            expandIcon: ArrowRightIcon,
          }}
          data-testid="checklist-tree"
        >
          {structure?.tabs?.map(({ name, categories }, tabIndex) => (
            <TreeItem
              key={`${tabIndex}-${name}`}
              itemId={`${tabIndex}-${name}`}
              label={
                <TreeItemLabel
                  text={name}
                  filesCount={calculateFilesCount(categories)}
                  dataTestid="tab"
                />
              }
              dataTestid="checklist-tab"
            >
              {categories.map(({ name: catName, sections }, categoryIndex) => (
                <TreeItem
                  key={`${tabIndex}-${categoryIndex}-${catName}`}
                  itemId={`${tabIndex}-${categoryIndex}-${catName}`}
                  label={
                    <TreeItemLabel
                      text={catName}
                      filesCount={calculateFilesCount(sections)}
                      dataTestid="category"
                    />
                  }
                  dataTestid="checklist-category"
                >
                  {sections.map(({ name: secName, files }, sectionIndex) => (
                    <TreeItem
                      key={`${tabIndex}-${categoryIndex}-${sectionIndex}-${secName}`}
                      itemId={`${tabIndex}-${categoryIndex}-${sectionIndex}-${secName}`}
                      label={
                        <TreeItemLabel
                          text={secName}
                          filesCount={files.length}
                          dataTestid="section"
                        />
                      }
                      dataTestid="checklist-section"
                    >
                      {_.orderBy(files, [(o) => o.name.toLowerCase()]).map(
                        ({ name: fileName }) => (
                          <Text
                            key={`${name}-${fileName}`}
                            variant="text2"
                            noWrap
                            title={fileName}
                            sx={{
                              marginLeft: theme.spacing(2),
                              overflow: "ellipsis",
                            }}
                            data-testid="file-name"
                          >
                            {fileName}
                          </Text>
                        )
                      )}
                      {files.length === 0 && (
                        <Text
                          variant="text2"
                          sx={{ marginLeft: theme.spacing(2) }}
                          data-testid="section-with-no-files"
                        >
                          Empty
                        </Text>
                      )}
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
            </TreeItem>
          ))}
        </TreeView>
      )}

      {loading && (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
    </Paper>
  );
};

export default FileTree;

import React, { useCallback, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Divider from "components/Divider";
import Paper from "components/Paper";
import Stack from "components/Stack";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import CompletedNote from "entities/Note/components/CompletedNote";
import NoteInputField from "entities/Note/components/NoteInputField";
import UncompletedNote from "entities/Note/components/UncompletedNote";
import {
  addNote,
  deleteNote,
  INote,
  updateNote,
  useNoteList,
} from "entities/Note/sdk";

interface INoteList {
  dealId: number;
}

const NoteList = ({ dealId }: INoteList) => {
  const [editableNoteId, setEditableNoteId] = useState<number | null>(null);

  const { data: notes, mutate: updateNotes } = useNoteList(dealId);

  const uncompletedNotes = notes?.filter((n) => !n.is_completed);
  const completedNotes = notes?.filter((n) => n.is_completed);

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleChangeEditable = useCallback((state: boolean, note: INote) => {
    setEditableNoteId(state ? note.id : null);
  }, []);

  const handleCompleteNote = useCallback(
    (note: INote) => {
      updateNote(note.id, {
        title: note.title,
        is_completed: !note.is_completed,
      }).then(() => {
        updateNotes();
        toast.successMessage(
          `The note marked as ${
            !note.is_completed ? "comleted" : "incompleted"
          } successfully!`
        );
      });
    },
    [updateNotes]
  );

  const onDeleteNote = useCallback(
    (note: INote) =>
      deleteNote(note.id).then(() => {
        updateNotes();
        toast.successMessage("The note has been deleted successfully!");
      }),
    [updateNotes]
  );

  const handleDeleteNote = useCallback(
    (note: INote) =>
      showConfirmationDialog({
        onConfirm: () => onDeleteNote(note),
        message: "Are you sure you want to delete this note?",
      }),
    [onDeleteNote, showConfirmationDialog]
  );

  const handleSubmit = useCallback(
    (noteTitle: string) =>
      addNote(dealId, noteTitle)
        .then(() => {
          updateNotes();
          toast.successMessage("The note has been created successfully!");
        })
        .catch(),
    [dealId, updateNotes]
  );

  return (
    <Stack spacing={1.5}>
      <Paper
        variant="outlined"
        sx={{
          cursor: "pointer",
          padding: theme.spacing(2),
        }}
      >
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <NoteInputField onSubmit={handleSubmit} />
        </Stack>
      </Paper>
      {uncompletedNotes &&
        uncompletedNotes.map((note) => (
          <UncompletedNote
            key={note.id}
            isEditable={editableNoteId === note.id}
            note={note}
            dealId={dealId}
            changeCompletedStatus={handleCompleteNote}
            onDelete={handleDeleteNote}
            changeEditable={(state) => handleChangeEditable(state, note)}
          />
        ))}
      {!_.isEmpty(completedNotes) && !_.isEmpty(uncompletedNotes) && (
        <Divider sx={{ borderColor: colors.blue20 }} />
      )}
      {completedNotes &&
        completedNotes.map((note) => (
          <CompletedNote
            key={note.id}
            note={note}
            changeCompletedStatus={handleCompleteNote}
            onDelete={handleDeleteNote}
          />
        ))}
    </Stack>
  );
};

export default NoteList;

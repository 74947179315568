import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import {
  DeleteOutlineOutlinedIcon,
  DescriptionOutlinedIcon,
  DownloadIcon,
  MoreVertIcon,
  VisibilityOutlinedIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";
import { AI_SUMMARY_ALLOWED_FILE_TYPES } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { downloadFileWithFileName } from "utils/file";
import { handleInvalidRequest } from "utils/sdk";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import IconButton from "components/IconButton";
import { MenuItem } from "components/Menu";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import { EMPTY_VALUE_REGEX } from "components/RichTextEditor/constants";
import SanitizeHtml from "components/SanitizeHtml";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import UploadButton from "components/UploadButton";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import { useLoadingBackdrop } from "components/useLoadingBackdrop";

import {
  openAIDocumentAssistantAddFiles,
  openAIDocumentAssistantDelete,
  openAIDocumentAssistantRemoveFile,
  useOpenAIDocumentAssistant,
  usePromptList,
} from "entities/AISummaries/sdk";
import UpdatePromptPackageDialog from "entities/Field/components/Filefield/AISummariesDrawer/UpdatePromptPackageDialog";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import { upload } from "entities/File/sdk";

import { UPLOADED_DOCUMENTS_INSTRUCTIONS } from "./constants";

export interface IAISummaryPageHeader {
  openAIDocumentAssistantId: number | null;
  deleteCallback: () => void;
  backLink?: string;
  backTitle?: string;
}

const AISummaryPageHeader = ({
  openAIDocumentAssistantId,
  deleteCallback,
  backLink,
  backTitle,
}: IAISummaryPageHeader) => {
  const [showLoading, hideLoading] = useLoadingBackdrop();
  const [isUpdatePromptPackageDialogOpen, setIsUpdatePromptPackageDialogOpen] =
    useState(false);

  const {
    data: documentAssistant,
    isLoading: documentAssistantIsLoading,
    mutate: mutateDocumentAssistant,
  } = useOpenAIDocumentAssistant({
    openAIDocumentAssistantId,
  });

  const { data: prompts, mutate: mutatePrompts } = usePromptList({
    openAIDocumentAssistantId,
  });

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleSummaryDelete = useCallback(() => {
    if (!_.isNull(openAIDocumentAssistantId)) {
      showConfirmationDialog({
        onConfirm: () =>
          openAIDocumentAssistantDelete({
            assistantId: openAIDocumentAssistantId,
          })
            .then(() => {
              deleteCallback();
              toast.successMessage("AI summary has been deleted successfully!");
            })
            .catch(handleInvalidRequest),
        confirmButtonText: "Delete",
        message: "Are you sure you want to delete this AI summary?",
      });
    }
  }, [openAIDocumentAssistantId, deleteCallback, showConfirmationDialog]);

  const hasOpenaAIAnswerInProcess = useMemo(() => {
    if (!_.isNil(prompts)) {
      return prompts.some((prompt) =>
        prompt.prompt_responses.some((response) =>
          _.isNil(response.readable_answer)
        )
      );
    }

    return false;
  }, [prompts]);

  const reasonForButtonBeingDisabled = useMemo(() => {
    if (hasOpenaAIAnswerInProcess) {
      return "Please wait for the current answer to be generated.";
    }

    return "";
  }, [hasOpenaAIAnswerInProcess]);

  const handleUpdatePromptPackage = useCallback(() => {
    setIsUpdatePromptPackageDialogOpen(false);
    mutateDocumentAssistant();
    mutatePrompts();
  }, [mutateDocumentAssistant, mutatePrompts]);

  const handleUploadFile = useCallback(
    ({ files }: { files: Array<File> }) => {
      if (_.isNil(openAIDocumentAssistantId)) {
        return;
      }

      showLoading();
      const toastId = toast.loading("Uploading files...");

      const allowedFiles = files.filter((item) =>
        AI_SUMMARY_ALLOWED_FILE_TYPES.includes(item.type)
      );

      files
        .filter((item) => !AI_SUMMARY_ALLOWED_FILE_TYPES.includes(item.type))
        .forEach((item) => {
          toast.errorMessage(
            `File ${item?.name} is not a PDF, XLSX or DOCX file!`
          );
        }, []);

      if (_.isEmpty(allowedFiles)) {
        hideLoading();
        toast.update(toastId, {
          autoClose: 1,
          hideProgressBar: true,
          isLoading: false,
        });
        return;
      }

      const fileUploads = allowedFiles.map((selectedFile) =>
        upload({ file: selectedFile, isPrivate: false })
      );

      Promise.all(fileUploads)
        .then((fileIds) =>
          openAIDocumentAssistantAddFiles({
            fileIds,
            openAIDocumentAssistantId,
          }).then(() => {
            mutateDocumentAssistant();
            toast.update(toastId, {
              render: "Files have been uploaded successfully!",
              type: "success",
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              isLoading: false,
              closeButton: true,
            });
          })
        )
        .catch((error) => {
          hideLoading();
          toast.update(toastId, {
            autoClose: 1,
            hideProgressBar: true,
            isLoading: false,
          });
          handleInvalidRequest(error);
        })
        .finally(() => {
          hideLoading();
        });
    },
    [
      showLoading,
      hideLoading,
      openAIDocumentAssistantId,
      mutateDocumentAssistant,
    ]
  );

  const handleFilePreview = useCallback(
    ({ openAIFile }: { openAIFile: { file: { url: string } } }) => {
      openURLInNewTab({ url: openAIFile.file.url });
    },
    []
  );

  const handleFileDownload = useCallback(
    ({
      openAIFile,
    }: {
      openAIFile: { file: { original_file_name: string; url: string } };
    }) => {
      downloadFileWithFileName({
        fileUrl: openAIFile.file.url,
        fileName: openAIFile.file.original_file_name,
      });
    },
    []
  );

  const handleFileRemove = useCallback(
    ({ openAIFile }: { openAIFile: { id: number } }) => {
      if (_.isNil(openAIDocumentAssistantId)) {
        return;
      }

      showLoading();

      openAIDocumentAssistantRemoveFile({
        openAIDocumentAssistantId: openAIDocumentAssistantId,
        openAIFileId: openAIFile.id,
      })
        .then(() => {
          mutateDocumentAssistant();
          toast.successMessage("File has been removed successfully!");
        })
        .catch(handleInvalidRequest)
        .finally(() => {
          hideLoading();
        });
    },
    [
      openAIDocumentAssistantId,
      mutateDocumentAssistant,
      showLoading,
      hideLoading,
    ]
  );
  const handleFileRemoveWithConfirmation = useCallback(
    ({ openAIFile }: { openAIFile: { id: number } }) => {
      showConfirmationDialog({
        onConfirm: () => handleFileRemove({ openAIFile }),
        confirmButtonText: "Remove",
        message: "Are you sure you want to remove this file?",
      });
    },
    [showConfirmationDialog, handleFileRemove]
  );

  const showInstructions = useMemo(
    () =>
      !_.isNil(documentAssistant) &&
      !_.isEmpty(documentAssistant.openai_prompt_package.instructions) &&
      !EMPTY_VALUE_REGEX.test(
        documentAssistant.openai_prompt_package.instructions
      ),
    [documentAssistant]
  );

  return (
    <>
      {!_.isUndefined(documentAssistant) && (
        <UpdatePromptPackageDialog
          isOpen={isUpdatePromptPackageDialogOpen}
          initialPromptPackageId={documentAssistant.openai_prompt_package.id}
          openAIDocumentAssistantId={documentAssistant.id}
          onUpdate={handleUpdatePromptPackage}
          onClose={() => setIsUpdatePromptPackageDialogOpen(false)}
        />
      )}
      <Stack spacing={2}>
        <PageHeader
          backLink={backLink}
          backTitle={backTitle}
          title={_.get(
            documentAssistant,
            "openai_prompt_package.name",
            <Skeleton width={150} />
          )}
          actions={
            <ButtonDropdown
              icon={<MoreVertIcon />}
              label=""
              variant="text"
              size="small"
              width="auto"
              sx={{
                padding: theme.spacing(0.5, 0.75),
              }}
            >
              <GroupLabel label="Settings" />
              <Tooltip title={reasonForButtonBeingDisabled}>
                <span>
                  <MenuItem
                    disabled={hasOpenaAIAnswerInProcess}
                    onClick={() => setIsUpdatePromptPackageDialogOpen(true)}
                  >
                    <Text variant="text2">Modify AI summary</Text>
                  </MenuItem>
                </span>
              </Tooltip>
              <Tooltip title={reasonForButtonBeingDisabled}>
                <span>
                  <MenuItem
                    disabled={hasOpenaAIAnswerInProcess}
                    onClick={handleSummaryDelete}
                  >
                    Delete summary
                  </MenuItem>
                </span>
              </Tooltip>
            </ButtonDropdown>
          }
        />
        {showInstructions && (
          <Paper sx={{ background: colors.blue10, padding: theme.spacing(2) }}>
            <SanitizeHtml
              html={documentAssistant?.openai_prompt_package.instructions || ""}
            />
          </Paper>
        )}

        <Stack direction="column" spacing={1} paddingTop={2}>
          <Stack direction="row" spacing={1}>
            <Text variant="label" color={colors.gray60} fontWeight={600}>
              Uploaded documents
            </Text>
            <InstructionsDialogButton
              dialogTitle="Uploaded documents"
              instructions={UPLOADED_DOCUMENTS_INSTRUCTIONS}
            />
          </Stack>
          <Stack>
            {documentAssistantIsLoading || _.isNil(documentAssistant) ? (
              <Skeleton height="20px" />
            ) : (
              documentAssistant.openai_files.map((openAIFile) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    padding: theme.spacing(1, 0.5),
                    borderBottom: `1px solid ${colors.blue40}`,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <DescriptionOutlinedIcon sx={{ height: "20px" }} />
                    <Text variant="text2">
                      {openAIFile.file.original_file_name}
                    </Text>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Text color={colors.gray80}>
                      Uploaded by {openAIFile.file.uploaded_by.first_name}{" "}
                      {openAIFile.file.uploaded_by.last_name},{" "}
                      {format(
                        parseJSON(openAIFile.file.created_at),
                        FORMATS.FRONTEND.DATETIME
                      )}
                    </Text>

                    <Stack direction="row" spacing={1}>
                      <Tooltip title="View File">
                        <IconButton
                          size="small"
                          onClick={() => handleFilePreview({ openAIFile })}
                        >
                          <VisibilityOutlinedIcon sx={{ height: "16px" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download File">
                        <IconButton
                          size="small"
                          onClick={() => handleFileDownload({ openAIFile })}
                        >
                          <DownloadIcon sx={{ height: "16px" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Remove File">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleFileRemoveWithConfirmation({ openAIFile })
                          }
                        >
                          <DeleteOutlineOutlinedIcon sx={{ height: "16px" }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Stack>
              ))
            )}
          </Stack>

          <UploadButton
            maxFiles={20}
            accept={AI_SUMMARY_ALLOWED_FILE_TYPES.join(",")}
            onChange={({ files }: { files: Array<File> }) =>
              handleUploadFile({ files })
            }
            dataTestid="upload-deal-cover-image-button"
            style={{ width: "unset", alignSelf: "flex-start" }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AISummaryPageHeader;

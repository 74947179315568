import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import AutomaticLogout from "components/AutomaticLogout";
import CompanySettingGuard from "components/CompanySettingGuard";
import DealPageLayout from "components/DealPageLayout";
import DownhomeIntegrationCreatedGuard from "components/DownhomeIntegrationCreatedGuard";
import PageLayout from "components/PageLayout";
import PrivateRoutesGuard from "components/PrivateRoutesGuard";
import ScrollRestoration from "components/ScrollRestoration";
import SuperUserGuard from "components/SuperUserGuard";

import NotFound from "pages/404";
import AccessDenied from "pages/AccessDenied";
import AllDealTasks from "pages/AllDealTasks";
import AnalyticsDevBudgetDetails from "pages/Analytics/DevelopmentBudget/Details";
import AnalyticsDevBudgetList from "pages/Analytics/DevelopmentBudget/List";
import AnalyticsIncomeAndOpexDetails from "pages/Analytics/IncomeAndOpex/Details";
import AnalyticsIncomeAndOpexList from "pages/Analytics/IncomeAndOpex/List";
import ApplicationDirectLink from "pages/Application/DirectLink";
import ApplicationEdit from "pages/Application/Edit";
import ApplicationLenderList from "pages/Application/LenderList";
import ApplicationLenderPreview from "pages/Application/LenderPreview";
import ApplicationList from "pages/Application/List";
import AssetManagement from "pages/AssetManagement";
import AssetManagementPdfDiff from "pages/AssetManagementPdfDiff";
import AssetManagementPdfs from "pages/AssetManagementPdfs";
import AssetManagementVersions from "pages/AssetManagementVersions";
import Bookmarks from "pages/Bookmarks";
import ChecklistDetails from "pages/Checklists/Details";
import ChecklistList from "pages/Checklists/List";
import CompanyChecklists from "pages/CompanyChecklists";
import ChecklistPreview from "pages/CompanyChecklists/ChecklistPreview";
import AISummariesEditor from "pages/Deal/AISummaries/Editor";
import AISummariesList from "pages/Deal/AISummaries/List";
import ChecklistsOverview from "pages/Deal/ChecklistsOverview";
import CompanyIntegrationDownhomeBusinessCreate from "pages/Deal/CompanyIntegrations/DownhomeBusinessCreate";
import CompanyIntegrationDownhomeBusinessUpdate from "pages/Deal/CompanyIntegrations/DownhomeBusinessUpdate";
import CompanyIntegrationsList from "pages/Deal/CompanyIntegrations/List";
import DueDiligence from "pages/Deal/DueDiligence";
import DueDiligenceEditPreview from "pages/Deal/DueDiligenceEditPreview";
import DueDiligenceRerouteToTab from "pages/Deal/DueDiligenceRerouteToTab";
import DueDiligenceShareable from "pages/Deal/DueDiligenceShareable";
import DueDiligenceTaskReroute from "pages/Deal/DueDiligenceTaskReroute";
import InvitationAccept from "pages/Deal/InvitationAccept";
import Memo from "pages/Deal/Memo";
import MemoRerouteToPackage from "pages/Deal/MemoRerouteToPackage";
import Overview from "pages/Deal/Overview";
import PartialAccessInvitationAccept from "pages/Deal/PartialAccessInvitationAccept";
import Proforma from "pages/Deal/ProForma";
import ShareableMemo from "pages/Deal/ShareableMemo";
import DealTableStorage from "pages/Deal/TableStorage";
import DealTableStorageCreate from "pages/Deal/TableStorage/Create";
import DealTableStorageDetails from "pages/Deal/TableStorage/Details";
import Workspace from "pages/Deal/Workspace";
import WorkspaceTasksReroute from "pages/Deal/WorkspaceTasksReroute";
import DealAccessDenied from "pages/DealAccessDenied";
import EmailVerification from "pages/EmailVerification";
import FeatureAccessDenied from "pages/FeatureAccessDenied";
import MemoTemplateEditor from "pages/MemoTemplate/Editor";
import MemoTemplateList from "pages/MemoTemplate/List";
import Notifications from "pages/Notifications";
import Onboarding from "pages/Onboarding";
import PleaseVerifyYourEmail from "pages/PleaseVerifyYourEmail";
import UserProfile from "pages/Profile";
import Projects from "pages/Projects";
import ProxyLogin from "pages/ProxyLogin";
import CustomReport from "pages/Reporting/CustomReport";
import DealCoreDataReporting from "pages/Reporting/DealCoreData";
import IncomeAndOpexReporting from "pages/Reporting/IncomeAndOpex";
import SharedChecklistClaim from "pages/SharedChecklistClaim";
import SuperuserCustomerList from "pages/Superuser/Customers";
import SuperuserCustomerDetails from "pages/Superuser/Customers/Details";
import SuperuserDashboard from "pages/Superuser/Dashboard";
import SuperuserDataTypesMigration from "pages/Superuser/DataTypesMigration";
import SuperuserLinkedFields from "pages/Superuser/LinkedFields";
import TableGeneratedAttemptsList from "pages/TableGeneratedAttempts";
import TableGeneratedAttemptsDetails from "pages/TableGeneratedAttempts/Details";
import TableGeneratedAttemptsReportCreate from "pages/TableGeneratedAttempts/ReportCreate";
import TablesCompare from "pages/TablesCompare";
import TableStorage from "pages/TableStorage";
import TableStorageCreate from "pages/TableStorage/Create";
import TableStorageDetails from "pages/TableStorage/Details";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";

const LocalRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<ScrollRestoration />}>
          <Route path={URLS.PROXY_LOGIN} element={<ProxyLogin />} />
          <Route
            path={URLS.EMAIL_VERIFICATION}
            element={<EmailVerification />}
          />
          <Route path={URLS.SHAREABLE_MEMO} element={<ShareableMemo />} />
          <Route
            path={URLS.SHARED_CHECKLIST_CLAIM}
            element={<SharedChecklistClaim />}
          />
          <Route path={URLS.ONBOARDING} element={<Onboarding />} />
          <Route
            path={URLS.PLEASE_VERIFY_YOUR_EMAIL}
            element={<PleaseVerifyYourEmail />}
          />
          <Route path="/" element={<PrivateRoutesGuard />}>
            <Route path="/" element={<AutomaticLogout />}>
              <Route path="/deals/:dealId" element={<DealPageLayout />}>
                <Route path={URLS.DEAL_OVERVIEW} element={<Overview />} />
                {/* due-diligence-v2 */}
                <Route path={URLS.DEAL_PACKAGE} element={<DueDiligence />} />
                <Route
                  path={URLS.DEAL_PACKAGE_NO_TAB}
                  element={<DueDiligenceRerouteToTab />}
                />
                <Route
                  path={URLS.DEAL_PACKAGE_TASK}
                  element={<DueDiligenceShareable />}
                />
                <Route
                  path={URLS.DEAL_PACKAGE_TASK_SECTION}
                  element={<DueDiligenceShareable />}
                />
                <Route
                  path={URLS.DEAL_PACKAGE_TASK_FIELD}
                  element={<DueDiligenceShareable />}
                />
                <Route
                  path={URLS.DEAL_PACKAGE_EDIT_PREVIEW}
                  element={<DueDiligenceEditPreview />}
                />
                {/* on this way we could redirect to the new url and interface */}
                <Route
                  path={URLS.DEAL_CHECKLISTS_TASK_OLD}
                  element={<DueDiligenceTaskReroute />}
                />
                {/* on this way we could redirect to the new url and interface */}
                <Route
                  path={URLS.DEAL_WORKSPACE_TASKS}
                  element={<WorkspaceTasksReroute />}
                />
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={
                        ECompanySettingsFeatureType.ASSET_MANAGEMENT_PROFORMA
                      }
                    />
                  }
                >
                  <Route
                    path={URLS.DEAL_ASSET_MANAGEMENT}
                    element={<AssetManagement />}
                  />
                  <Route
                    path={URLS.DEAL_ASSET_MANAGEMENT_VERSIONS}
                    element={<AssetManagementVersions />}
                  />
                  <Route
                    path={URLS.DEAL_ASSET_MANAGEMENT_PDFS}
                    element={<AssetManagementPdfs />}
                  />
                  <Route
                    path={URLS.DEAL_ASSET_MANAGEMENT_PDF_DIFF}
                    element={<AssetManagementPdfDiff />}
                  />
                </Route>
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={
                        ECompanySettingsFeatureType.UNDERWRITING_PROFORMA
                      }
                    />
                  }
                >
                  <Route path={URLS.DEAL_PROFORMA} element={<Proforma />} />
                  <Route path={URLS.DEAL_PROFORMA_TAB} element={<Proforma />} />
                </Route>
                <Route path={URLS.DEAL_WORKSPACE} element={<Workspace />} />
                <Route path={URLS.DEAL_WORKSPACE_TAB} element={<Workspace />} />
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.MEMO}
                    />
                  }
                >
                  <Route
                    path={URLS.DEAL_MEMO_NO_PACKAGE}
                    element={<MemoRerouteToPackage />}
                  />
                  <Route path={URLS.DEAL_MEMO} element={<Memo />} />
                </Route>
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.AI_SUMMARY}
                    />
                  }
                >
                  <Route
                    path={URLS.DEAL_AI_SUMMARIES}
                    element={<AISummariesList />}
                  />
                  <Route
                    path={URLS.DEAL_AI_SUMMARIES_EDITOR}
                    element={<AISummariesEditor />}
                  />
                </Route>
                <Route
                  path={URLS.DEAL_CHECKISTS_OVERVIEW}
                  element={<ChecklistsOverview />}
                />
                <Route
                  path={URLS.COMPANY_INTEGRATIONS_LIST}
                  element={<CompanyIntegrationsList />}
                />
                <Route element={<DownhomeIntegrationCreatedGuard />}>
                  <Route
                    path={URLS.COMPANY_INTEGRATION_DOWNHOME_BUSINESS_CREATE}
                    element={<CompanyIntegrationDownhomeBusinessCreate />}
                  />
                </Route>

                <Route
                  path={URLS.COMPANY_INTEGRATION_DOWNHOME_BUSINESS_UPDATE}
                  element={<CompanyIntegrationDownhomeBusinessUpdate />}
                />
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.DEAL_TABLES}
                    />
                  }
                >
                  <Route
                    path={URLS.DEAL_TABLE_STORAGE_LIST}
                    element={<DealTableStorage />}
                  />
                  <Route
                    path={URLS.DEAL_TABLE_STORAGE_DETAILS}
                    element={<DealTableStorageDetails />}
                  />
                  <Route
                    path={URLS.DEAL_TABLE_STORAGE_CREATE}
                    element={<DealTableStorageCreate />}
                  />
                  <Route
                    path={URLS.DEAL_TABLES_COMPARE}
                    element={<TablesCompare />}
                  />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="/" element={<PageLayout />}>
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.INTAKE_APPLICANT}
                    />
                  }
                >
                  <Route
                    path={URLS.APPLICATION_DIRECT_LINK}
                    element={<ApplicationDirectLink />}
                  />
                  <Route
                    path={URLS.APPLICATIONS_LIST}
                    element={<ApplicationList />}
                  />
                  <Route
                    path={URLS.APPLICATION_EDIT}
                    element={<ApplicationEdit />}
                  />
                </Route>
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.INTAKE_LENDER}
                    />
                  }
                >
                  <Route
                    path={URLS.APPLICATIONS_LENDER_LIST}
                    element={<ApplicationLenderList />}
                  />
                  <Route
                    path={URLS.APPLICATION_PREVIEW}
                    element={<ApplicationLenderPreview />}
                  />
                </Route>
                <Route path={URLS.PROFILE} element={<UserProfile />} />
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={
                        ECompanySettingsFeatureType.ANALYTICS_DEVELOPMENT_BUDGET
                      }
                    />
                  }
                >
                  <Route
                    path={URLS.ANALYTICS_DEV_BUDGET_LIST}
                    element={<AnalyticsDevBudgetList />}
                  />
                  <Route
                    path={URLS.ANALYTICS_DEV_BUDGET_DETAILS}
                    element={<AnalyticsDevBudgetDetails />}
                  />
                </Route>
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={
                        ECompanySettingsFeatureType.ANALYTICS_INCOME_AND_OPEX
                      }
                    />
                  }
                >
                  <Route
                    path={URLS.ANALYTICS_INCOME_AND_OPEX_LIST}
                    element={<AnalyticsIncomeAndOpexList />}
                  />
                  <Route
                    path={URLS.ANALYTICS_INCOME_AND_OPEX_DETAILS}
                    element={<AnalyticsIncomeAndOpexDetails />}
                  />
                </Route>
                <Route path={URLS.PROJECTS_LIST} element={<Projects />} />
                <Route path={URLS.PROJECTS_LIST_VIEW} element={<Projects />} />
                <Route
                  path="/"
                  element={
                    <Navigate
                      to={reverse(URLS.PROJECTS_LIST, {
                        phase: "All",
                      })}
                    />
                  }
                />
                <Route path="/" element={<SuperUserGuard />}>
                  <Route
                    path={URLS.SUPERUSER_DASHBOARD}
                    element={<SuperuserDashboard />}
                  />
                  <Route
                    path={URLS.SUPERUSER_DATA_TYPES_MIGRATION}
                    element={<SuperuserDataTypesMigration />}
                  />
                  <Route
                    path={URLS.SUPERUSER_CUSTOMER_LIST}
                    element={<SuperuserCustomerList />}
                  />
                  <Route
                    path={URLS.SUPERUSER_CUSTOMER_DETAILS}
                    element={<SuperuserCustomerDetails />}
                  />
                  <Route
                    path={URLS.SUPERUSER_LINKED_FIELDS}
                    element={<SuperuserLinkedFields />}
                  />
                </Route>
                <Route
                  path={URLS.REPORTING_INCOME_AND_OPEX}
                  element={<IncomeAndOpexReporting />}
                />
                <Route
                  path={URLS.REPORTING_DEAL_CORE_DATA}
                  element={<DealCoreDataReporting />}
                />
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.CUSTOM_REPORTS}
                    />
                  }
                >
                  <Route
                    path={URLS.REPORTING_CUSTOM}
                    element={<CustomReport />}
                  />
                </Route>
                <Route path={URLS.ALL_DEAL_TASKS} element={<AllDealTasks />} />

                <Route
                  path={URLS.MEMO_TEMPLATE_LIST}
                  element={<SuperUserGuard />}
                >
                  <Route path="" element={<MemoTemplateList />} />
                </Route>

                <Route
                  path={URLS.MEMO_TEMPLATE_EDITOR}
                  element={<SuperUserGuard />}
                >
                  <Route path="" element={<MemoTemplateEditor />} />
                </Route>

                <Route
                  path={URLS.CHECKLIST_TEMPLATE_LIST}
                  element={<ChecklistList />}
                />
                <Route path={URLS.NOTIFICATIONS} element={<Notifications />} />
                <Route path={URLS.BOOKMARKS} element={<Bookmarks />} />
                <Route
                  path={URLS.CHECKLIST_TEMPLATE_DETAILS}
                  element={<ChecklistDetails />}
                />
                <Route
                  path={URLS.DEAL_TEAM_INVITATION_ACCEPT}
                  element={<InvitationAccept />}
                />
                <Route
                  path={URLS.CHECKLIST_SHARE_TOKEN}
                  element={<PartialAccessInvitationAccept />}
                />
                <Route
                  path={URLS.COMPANY_CHECKLISTS}
                  element={<CompanyChecklists />}
                />
                <Route
                  path={URLS.COMPANY_CHECKLISTS_PREVIEW}
                  element={<ChecklistPreview />}
                />
                <Route
                  path={URLS.DEAL_ACCESS_DENIED}
                  element={<DealAccessDenied />}
                />
                <Route
                  path={URLS.FEATURE_ACCESS_DENIED}
                  element={<FeatureAccessDenied />}
                />
                <Route
                  element={
                    <CompanySettingGuard
                      featureType={ECompanySettingsFeatureType.TABLES}
                    />
                  }
                >
                  <Route
                    path={URLS.TABLE_STORAGE_LIST}
                    element={<TableStorage />}
                  />
                  <Route
                    path={URLS.TABLE_STORAGE_DETAILS}
                    element={<TableStorageDetails />}
                  />
                  <Route
                    path={URLS.TABLE_STORAGE_CREATE}
                    element={<TableStorageCreate />}
                  />
                  <Route
                    path={URLS.TABLES_COMPARE_WITH_INITIAL}
                    element={<TablesCompare />}
                  />
                  <Route
                    path={URLS.TABLES_COMPARE}
                    element={<TablesCompare />}
                  />
                  <Route
                    path={URLS.TABLES_RESPONSE_REPORT_CREATE}
                    element={<TableGeneratedAttemptsReportCreate />}
                  />
                  <Route
                    path={URLS.TABLES_RESPONSE_LIST}
                    element={<TableGeneratedAttemptsList />}
                  />
                  <Route
                    path={URLS.TABLES_RESPONSE_DETAIL}
                    element={<TableGeneratedAttemptsDetails />}
                  />
                </Route>
                <Route path={URLS.ACCESS_DENIED} element={<AccessDenied />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default LocalRouter;

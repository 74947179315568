import React from "react";

import { FolderOpenIcon } from "icons";

import Stack from "components/Stack";
import Text from "components/Text";

interface ITreeItemLabel {
  text: string;
  filesCount: number;
  dataTestid?: string;
}

const TreeItemLabel: React.FC<ITreeItemLabel> = ({
  text,
  filesCount,
  dataTestid,
}) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <FolderOpenIcon />
    <Text variant="text3" data-testid={`${dataTestid}-name`}>
      {text}
    </Text>
    <Text variant="text3" data-testid={`${dataTestid}-files-count`}>
      {`(${filesCount} files)`}
    </Text>
  </Stack>
);

export default TreeItemLabel;

import { memo, useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import { COMPANY_PACKAGE_NAME_MAX_LENGTH } from "entities/CompanyChecklist/constants";
import { renameCompanyChecklist } from "entities/Package/sdk";

export interface IUnderConstructionDialog {
  isOpen: boolean;
  onClose: () => void;
  refreshChecklist: () => void;
  packageId: number;
  checklistName: string;
}

const RenameChecklistDialog = ({
  isOpen,
  onClose,
  refreshChecklist,
  packageId,
  checklistName,
}: IUnderConstructionDialog) => {
  const [newName, setNewName] = useCaching<string>(checklistName);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const saveIsDisabled = useMemo(
    () => newName === "" || showErrorMessage,
    [newName, showErrorMessage]
  );

  const handleClose = useCallback(() => {
    setNewName(checklistName);
    onClose();
  }, [checklistName, onClose, setNewName]);

  const handleSave = useCallback(() => {
    renameCompanyChecklist({
      name: newName,
      packageId,
    })
      .then(() => {
        handleClose();
        refreshChecklist();
        toast.successMessage("Checklist name updated successfully!");
      })
      .catch((error) => {
        handleInvalidRequest(error);
      });
  }, [handleClose, refreshChecklist, newName, packageId]);

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNewName(value);

      if (value.length > COMPANY_PACKAGE_NAME_MAX_LENGTH) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    },
    [setNewName]
  );

  const errorMessage = useMemo(
    () =>
      showErrorMessage
        ? `The name of the checklist cannot be longer than ${COMPANY_PACKAGE_NAME_MAX_LENGTH} characters.`
        : "",
    [showErrorMessage]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <Stack padding={3} spacing={3} minWidth={640}>
        <Text
          variant="h2"
          marginBottom={theme.spacing(0.5)}
          color={colors.gray100}
        >
          Edit checklist name
        </Text>
        <Stack>
          <Text variant="text3" fontWeight={500} color={colors.gray80}>
            New checklist name
          </Text>
          <TextField
            variant="outlined"
            value={newName}
            onChange={handleNameChange}
            helperText={errorMessage}
            error={showErrorMessage}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          data-testid="form-action-buttons"
        >
          <Button
            variant="text"
            onClick={handleClose}
            data-testid="close-button"
            sx={{ marginRight: theme.spacing(1) }}
          >
            Cancel
          </Button>
          <Button
            data-testid="submit-button"
            disabled={saveIsDisabled}
            onClick={handleSave}
          >
            Save changes
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(RenameChecklistDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { EMPTY_LIST } from "utils/constants";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import SimpleTable from "components/SimpleTable";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import EditButton from "entities/Field/components/Tablefield/EditButton";
import { fieldValueCreate } from "entities/Field/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";
import { handleInvalidMemoFieldRequest } from "entities/Memo/components/Fields/utils/utils";

interface ITablefield {
  breakInside?: string;
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
}

const Tablefield = ({
  field,
  dealId,
  breakInside = "auto",
  externalToken,
  isNASection,
  onUpdate,
}: ITablefield) => {
  const [value, setValue] = useCaching(
    _.get(field.values, "[0].column_value", EMPTY_LIST)
  );
  const navigate = useNavigate();

  const handleSave = useCallback(
    ({ newValue }: { newValue: any }) => {
      setValue(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.company_custom_field_id,
        value: JSON.stringify(newValue),
      })
        .then(() => {
          toast.successMessage(`${field.name} successfully updated!`);
        })
        .catch((error) =>
          handleInvalidMemoFieldRequest({ error, dealId, navigate })
        );
    },
    [field, dealId, setValue, navigate]
  );

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showEditButton = useMemo(
    () => !isNAField && _.isUndefined(externalToken),
    [isNAField, externalToken]
  );

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ flexGrow: 0, borderBottom: `1px solid ${colors.blue40}` }}
      >
        <FieldTitle
          field={field}
          onUpdate={onUpdate}
          externalToken={externalToken}
          disabled={isNAField}
        />
        {showEditButton && (
          <NoPrint>
            <EditButton
              field={{
                display_name: field.name,
                header_name: field.column_name?.split(",").map((column) => ({
                  headerName: column.trim(),
                  field: column.trim(),
                })),
              }}
              initialValue={value}
              onSave={handleSave}
              buttonDataTestid="field-edit-button"
            />
          </NoPrint>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
      </Stack>
      {!isNAField && (
        <Box
          sx={{
            paddingTop: theme.spacing(1),
            breakInside: breakInside,
            width: "100%",
          }}
          data-testid="dd-table-field-table"
        >
          <SimpleTable
            headers={
              field.column_name?.split(",").map((column) => ({
                label: column.trim(),
                key: column.trim(),
              })) || []
            }
            rows={value}
            size="small"
            showEmptyRow={true}
            tableDataTestid={field.name}
          />
        </Box>
      )}
    </Box>
  );
};

export default Tablefield;

import React, { useCallback, useState } from "react";
import _ from "lodash";

import { AddIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Dialog, { DialogActions } from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

interface IEditSelectedDealsDialog {
  open: boolean;
  deals: any[];
  handleClose: () => void;
  onExcludedDealsUpdate: (dealIds: Array<number>) => void;
}

const EditSelectedDealsDialog: React.FC<IEditSelectedDealsDialog> = ({
  open,
  deals,
  handleClose,
  onExcludedDealsUpdate,
}) => {
  const [excludedDeals, setExcludedDeals] = useState(
    _.map(
      deals.filter((deal) => {
        return deal.is_excluded;
      }),
      (deal) => {
        return deal.id;
      }
    )
  );
  const handleChange = (id: any) => {
    setExcludedDeals(_.xor([id], excludedDeals));
  };
  const handleUpdate = useCallback(() => {
    onExcludedDealsUpdate(excludedDeals);
    handleClose();
  }, [onExcludedDealsUpdate, excludedDeals, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box sx={{ padding: theme.spacing(0, 10) }}>
        <Paper
          sx={{
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
          }}
        >
          <Stack spacing={4}>
            <Stack direction="row" spacing={1}>
              <AddIcon
                style={{
                  height: theme.spacing(5),
                  width: theme.spacing(5),
                }}
              />
              <Text variant="h1">Edit Deals</Text>
            </Stack>
            <Stack spacing={2}>
              {deals.map((deal: any, index) => (
                <Stack key={deal.id} flexDirection="row" alignItems="center">
                  <Checkbox
                    checked={!excludedDeals.includes(deal.id)}
                    sx={{ padding: 0, marginRight: theme.spacing(1) }}
                    onChange={() => handleChange(deal.id)}
                  />
                  <Text variant="text2">{deal.name}</Text>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <DialogActions sx={{ marginTop: theme.spacing(2) }}>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleUpdate}>Save</Button>
          </DialogActions>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default EditSelectedDealsDialog;

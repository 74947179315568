import Autocomplete, {
  AutocompleteChangeReason as MuiAutocompleteChangeReason,
  AutocompleteRenderInputParams as MuiAutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { styled } from "@mui/system";

import theme from "theme";
import { colors } from "theme/palette";

import Paper from "components/Paper";

export { createFilterOptions };

export default Autocomplete;

export interface AutocompleteRenderInputParams
  extends MuiAutocompleteRenderInputParams {}

export type AutocompleteChangeReason = MuiAutocompleteChangeReason;

export const AutocompletePaperComponent = (props: any) => {
  return <Paper elevation={9} sx={{ padding: 0 }} {...props} />;
};

export const AutocompleteGroupHeader = styled("div")({
  position: "sticky",
  top: "-8px",
  padding: theme.spacing(1, 1, 0.5, 1),
  color: colors.gray40,
  ...theme.typography.labelSmall,
});

export const AutocompleteGroupItems = styled("ul")({
  padding: 0,
});

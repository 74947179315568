import React from "react";

import { AddIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import AddNewTeamForm from "entities/Team/components/AddNewTeamForm";

interface IAddNewTeamDialog {
  dealId: number;
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const AddNewTeamDialog: React.FC<IAddNewTeamDialog> = ({
  dealId,
  open,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box>
        <Stack alignItems="center" data-testid="add-new-team-dialog">
          <Paper
            sx={{
              paddingTop: theme.spacing(8),
              paddingBottom: theme.spacing(8),
            }}
          >
            <Stack spacing={4}>
              <Stack direction="row" spacing={1}>
                <AddIcon
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
                <Text
                  variant="h1"
                  style={{ display: "flex" }}
                  data-testid="dialog-title"
                >
                  Add New Team
                </Text>
              </Stack>
              <Text data-testid="users-helper-text">
                To add a new team, you need to provide some basic information
                and invite a team member by using his or her company email.
              </Text>
              <AddNewTeamForm
                dealId={dealId}
                onSubmit={onSubmit}
                onCancel={onClose}
              />
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default AddNewTeamDialog;

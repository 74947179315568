import React from "react";
import MuiPaper, { PaperProps } from "@mui/material/Paper";

import theme from "theme";

const Paper: React.FC<PaperProps> = ({ sx, ...props }) => (
  <MuiPaper sx={{ padding: theme.spacing(2, 3), ...sx }} {...props} />
);

export default Paper;

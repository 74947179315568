import { colors } from "theme/palette";

import { TableCell } from "components/Table";

import AllTasksChatButton from "entities/Task/components/AllTasksChatButton";
import WorkspaceTaskChatButton from "entities/Task/components/WorkspaceTaskChatButton";

interface IMessageCountCell {
  isInDealContext: boolean;
  count: number;
  taskChannelId: number;
  dealId: number;
  refreshTasksList: () => void;
}

const MessageCountCell = ({
  isInDealContext,
  count,
  taskChannelId,
  dealId,
  refreshTasksList,
}: IMessageCountCell) => (
  <TableCell
    align="center"
    sx={{
      borderRight: `1px solid ${colors.blue40}`,
      borderBottom: `1px solid ${colors.blue40}`,
    }}
  >
    {isInDealContext ? (
      <WorkspaceTaskChatButton
        messageCount={count}
        taskChannelId={taskChannelId}
        dealId={dealId}
      />
    ) : (
      <AllTasksChatButton
        refreshTasksList={refreshTasksList}
        messageCount={count}
        taskChannelId={taskChannelId}
        dealId={dealId}
      />
    )}
  </TableCell>
);

export default MessageCountCell;

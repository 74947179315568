import { useMemo } from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IColoredCell {
  value: number;
  label: string;
  title: string;
  backgroundColor: string;
}

const ColoredCell = ({
  value,
  label,
  backgroundColor,
  title,
}: IColoredCell) => {
  const opacity = useMemo(() => {
    if (Math.abs(value) < 10) {
      return 0;
    }

    if (Math.abs(value) > 75) {
      return 1;
    }

    return Math.abs(value) / 100;
  }, [value]);

  const textColor = opacity > 0.5 ? colors.white : colors.gray100;

  return (
    <Tooltip title={title}>
      <Box
        sx={{
          paddingX: 0.5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundColor,
            opacity,
          }}
        />
        <Text
          noWrap
          variant="text3"
          sx={{
            position: "relative",
            color: textColor,
          }}
        >
          {label}
        </Text>
      </Box>
    </Tooltip>
  );
};

export default ColoredCell;

import React from "react";

import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

export interface IDealFormSeparator {
  title: string;
}

const DealFormSeparator: React.FC<IDealFormSeparator> = ({ title }) => (
  <Stack direction="row" alignItems="center" spacing={2}>
    <Text
      sx={{
        color: colors.gray40,
      }}
      variant="label"
    >
      {title}
    </Text>
    <hr style={{ flex: 1, border: `1px solid ${colors.gray20}` }} />
  </Stack>
);

export default DealFormSeparator;

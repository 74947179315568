import React from "react";

import theme from "theme";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import placeholderIcon from "components/DealAvatar/assets/default.png";
import Image from "components/Image";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import UnitAmenitiesTable from "entities/Deal/components/OverviewUnitAmenities/UnitAmenitiesTable";
import { IDealUnitAmenities } from "entities/Deal/sdk";

const unitAmenitiesData: IDealUnitAmenities = {
  image: placeholderIcon,
  description: "<p>This is an example unit amenities description.</p>",
  kitchen: ["...", "...", "..."],
  storage: ["...", "..."],
  laundry: ["...", "...", "...", "..."],
  fittings: ["...", "..."],
};

const MemoTemplateUnitAmenities = () => {
  return (
    <Stack spacing={2}>
      <Text variant="h2">Unit Amenities</Text>
      <ContentSeparator label="Unit Amenities" />
      <UnitAmenitiesTable unit_amenities_data={unitAmenitiesData} />
      <Image
        src={unitAmenitiesData.image}
        sx={{
          height: "460px",
          width: "100%",
          objectFit: "cover",
          marginBottom: theme.spacing(2),
        }}
      />
      <ContentSeparator label="Unit Amenities Description" />
      <Box
        sx={{
          paddingLeft: theme.spacing(1),
        }}
      >
        <SanitizeHtml html={unitAmenitiesData.description} />
      </Box>
    </Stack>
  );
};

export default MemoTemplateUnitAmenities;

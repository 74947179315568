import _ from "lodash";

import { IField } from "entities/Field/sdk";
import { IPackageField } from "entities/Package/sdk";

export const convertPackageFieldToField = ({
  field,
}: {
  field: IPackageField;
}): IField => ({
  ...field,
  custom_values: field.values.map((value) => ({
    ...value,
    column_value:
      value.column_value &&
      _.isObject(value.column_value) &&
      !_.isArray(value.column_value)
        ? undefined
        : value.column_value,
    file: value.file ?? undefined,
  })),
  display_name: field.name,
  header_name: field.columns
    ? field.columns.map((x) => ({ field: x, headerName: x }))
    : [],
});

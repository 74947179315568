import { useCallback, useMemo } from "react";
import _ from "lodash";

import { BASE_URL } from "config/urls";
import { format, FORMATS, IDate } from "utils/datetime";
import { getEnvironmentVariables } from "utils/environment";
import { get } from "utils/sdk";
import { deleteCall, post, put, useFetch, usePaginatedFetch } from "utils/sdk";

import { Persona } from "entities/Company/sdk";
import { IPhaseForm } from "entities/Deal/components/OverviewSchedule/ScheduleEditForm";
import { Phase, RentTypes } from "entities/Deal/constants";
import { IIncomeOperatingCost, ISnapshot } from "entities/Proforma/sdk";
import { EFieldFormatType } from "entities/Reporting/constants";
import {
  ITable,
  ITableCell,
  ITableForList,
  TableUpdateData,
} from "entities/TableStorage/sdk";

const GOOGLE_MAPS_API_KEY = getEnvironmentVariables().VITE_GOOGLE_API_KEY;

export interface IDealForCreation {
  id: number;
  name: string;
  persona: Persona;
  number_of_units: string;
  property_type: string;
  deal_status: string;
  deal_phase: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  construction_type: string;
  image_id: number | null;
}

export interface IDeal_old {
  id: number;
  status: string;
  name: string;
  legal_name: string;
  project_type: string;
  construction_type: string;
  rent_type: RentTypes | null;
  thumbnail: string;
  thumbnail_id: number;
  address: {
    state: string;
    city: string;
    zipcode: string;
    street_address: string;
    longitude: string;
    latitude: string;
  };
  core_data?: {
    total_units: number;
  };
  phase?: {
    name: string;
    id: number;
  };
  executive_summary: string;
  project_narrative: string;
  schedule: {
    [key: number]: {
      end_date: IDate | null; // This comes in as string | null
      phase_id: number;
      phase_name: string;
      start_date: IDate | null; // This comes in as string | null
    };
  };
  unit_amenities_data: {
    image: string;
    description: string;
    kitchen: string[];
    storage: string[];
    laundry: string[];
    fittings: string[];
  };
  community_amenities_data: {
    image: string;
    description: string;
    front_desk_and_security: string[];
    storage: string[];
    recreational_spaces: string[];
    energy: string[];
    other: string[];
    accreditations: string[];
  };
  income_operating_cost: IIncomeOperatingCost;
  file_url: string;
  last_updated_data: {
    update_at: string;
    updated_by: string;
  };
}

// const someID = 1; // I don't think this is used by the Backend
// export const useDealsList = () =>
//   useFetch<{ deals: IDeal_old[] }>(`company/paginated_deals/${someID}/?page=1`);

interface IPipelineArguments {
  phase: string;
}

export interface IPipelineColumns {
  report_fields: {
    name: string;
    field_type: string;
    field_width: number;
    field_format_type: EFieldFormatType;
  }[];
  name: string;
}

export interface IDeal {
  id: number;
  name: string;
  status: string;
  thumbnail_id: number;
  thumbnail: string | undefined;
  phase: { id: number; name: string };
  address: {
    id?: number;
    state: string;
    city: string;
    zipcode: string;
    street_address: string;
    country?: string;
    longitude: string;
    latitude: string;
  };
  legal_name: string;
  project_type: string;
  construction_type: string;
  rent_type: RentTypes | null;
  is_partially_accessible: boolean;
  company: {
    id: number;
    name: string;
  };
}

export const useDealDetails = ({ dealId }: { dealId: number | undefined }) =>
  useFetch<IDeal>(dealId ? `v2/deal/deal/${dealId}/details/` : undefined);

// Extend if needed
export interface IDealCoreData {
  total_units: number;
}
export const useDealCoreData = ({ dealId }: { dealId: number | undefined }) =>
  useFetch<IDealCoreData>(
    dealId ? `v2/deal/deal/${dealId}/core-data/` : undefined
  );

export interface IDealUnitAmenities {
  id?: number;
  kitchen: string[];
  storage: string[];
  laundry: string[];
  fittings: string[];
  description: string;
  image: string;
}
export const useDealUnitAmenities = ({
  dealId,
}: {
  dealId: number | undefined;
}) =>
  useFetch<IDealUnitAmenities>(
    dealId ? `v2/deal/deal/${dealId}/unit-amenities/` : undefined
  );

export interface IDealCommunityAmenities {
  id?: number;
  image: string;
  description: string;
  front_desk_and_security: string[];
  storage: string[];
  recreational_spaces: string[];
  energy: string[];
  other: string[];
  accreditations: string[];
}
export const useDealCommunityAmenities = ({
  dealId,
}: {
  dealId: number | undefined;
}) =>
  useFetch<IDealCommunityAmenities>(
    dealId ? `v2/deal/deal/${dealId}/community-amenities/` : undefined
  );

export interface IDealSchedule {
  phase: {
    id: number;
    name: Phase;
  };
  start_date: IDate | null;
  end_date: IDate | null;
}

export const useDealSchedule = ({ dealId }: { dealId: number | undefined }) => {
  const { data, ...rest } = useFetch<Array<IDealSchedule>>(
    dealId ? `v2/deal/deal/${dealId}/deal-schedule/` : undefined
  );

  const phaseOrder = [
    Phase.APPLICATION,
    Phase.UNDERWRITING,
    Phase.CONSTRUCTION,
    Phase.CONVERSION,
    Phase.ASSET_MANAGEMENT,
  ];

  const orderedData = data?.sort(
    (a, b) =>
      phaseOrder.indexOf(a.phase.name) - phaseOrder.indexOf(b.phase.name)
  );

  return { data: orderedData, ...rest };
};

export interface IDealDataFileUrl {
  file_url: string;
  original_file_name: string | null;
  created: string;
  warning_messages: Array<string>;
  uploaded_by: {
    first_name: string;
    last_name: string;
  };
  snapshot: ISnapshot | null;
}
export const useDealDataFileUrl = (dealId: number) =>
  useFetch<IDealDataFileUrl>(`v2/deal/deal/${dealId}/deal-data/`);

export const createDeal = (
  data: Partial<Omit<IDealForCreation, "id">>
): Promise<{ id: number }> => post(`${BASE_URL}v2/deal/create/`, data);

export const uploadDealCoverImage = ({
  dealId,
  imageId,
}: {
  dealId: number;
  imageId: number;
}): Promise<{ image: string; id: number }> => {
  return post(`${BASE_URL}v2/deal/${dealId}/image/update/`, {
    image_id: imageId,
  });
};

export const deleteDealCoverImage = (dealId: number, imageId: number) =>
  deleteCall(`${BASE_URL}v2/deal/${dealId}/images/${imageId}/delete/`);

export const usePipelineQickRows = ({ phase }: IPipelineArguments) =>
  useFetch<{ [key: string]: string | null }[]>(
    `v2/reporting/landing-page/quick-details/${phase}`,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export const usePipelineRows = ({ phase }: { phase: undefined | string }) =>
  useFetch<{ [key: string]: string | null }[]>(
    phase ? `v2/reporting/landing-page/details/${phase}` : undefined,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export const usePipelineColumns = ({
  shouldFetch = true,
}: {
  shouldFetch?: boolean;
}) =>
  useFetch<IPipelineColumns[]>(
    shouldFetch ? `v2/reporting/landing-page/sections/` : undefined,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export interface IMarker {
  center: { lat: number; lng: number };
  contentdata: {
    project_name: string;
    address: string;
    deal_id?: number;
    thumbnail: string | undefined;
    units?: number;
  };
}

export const usePipelineMapCoordinates = ({ phase }: IPipelineArguments) =>
  useFetch<{
    [dealId: number]: IMarker;
  }>(`v2/reporting/landing-page/map/${phase}`, {
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

export const useDealsListData = (pipelineArgs: IPipelineArguments) => {
  const { data: mapCoordinates, mutate: refetchMapCoordinates } =
    usePipelineMapCoordinates(pipelineArgs);

  const { data: quickRows, loading: quickRowsLoading } =
    usePipelineQickRows(pipelineArgs);

  const {
    data: rows,
    loading: rowsLoading,
    isValidating: isValidatingRows,
    mutate: refetchRows,
  } = usePipelineRows({
    // Start fetching phase only after quick fetch is done
    phase: quickRows ? pipelineArgs.phase : undefined,
  });

  const {
    data: columns,
    loading: columnsLoading,
    isValidating: isValidatingColumns,
    mutate: refetchColumns,
  } = usePipelineColumns({
    // Start fetching columns only after quick fetch is done
    shouldFetch: !_.isNil(quickRows),
  });

  const mutate = useCallback(() => {
    refetchMapCoordinates();
    refetchRows();
    refetchColumns();
  }, [refetchMapCoordinates, refetchRows, refetchColumns]);

  return useMemo(
    () => ({
      mapCoordinates,
      rows: _.isNil(rows) ? quickRows : rows,
      columns: columns,
      loading: rowsLoading || quickRowsLoading || columnsLoading,
      isValidating:
        isValidatingRows ||
        isValidatingColumns ||
        rowsLoading ||
        quickRowsLoading ||
        columnsLoading,
      mutate,
    }),
    [
      mapCoordinates,
      rows,
      quickRows,
      quickRowsLoading,
      columns,
      rowsLoading,
      columnsLoading,
      isValidatingRows,
      isValidatingColumns,
      mutate,
    ]
  );
};

export interface IDeal_legacy {
  deal_id: number;
  deal_name: string;
  deal_photo: string | false;
  project_details: {
    id: number;
    display_name: string;
    children: {
      display_name: string;
      field_value: string;
      is_image: boolean;
    }[];
  }[];
}

export interface IAssignedTeams_legacy {
  is_deal_admin: boolean;
  assigned_team: {
    display_name: string;
    phase: string;
    phase_id: number;
    role_fields: {
      display_name: string;
      user_name: string;
      user_id: string;
      role_id: number;
      role_name: string;
      phase: string;
      section_name: string;
    }[];
  }[];
}

interface IAssignedTeamMember {
  id: number;
  is_deal_member: boolean;
  email: string;
  first_name: string;
  last_name: string;
}

export const useDealAssignedTeamMembers = (dealId: number) =>
  useFetch<IAssignedTeamMember[] | undefined>(
    `v2/deal/deal/${dealId}/role/member/list/`
  );

export interface IPhaseAndStatus {
  project_phase: string;
  project_status: string;
  last_updated: string;
  updated_by: string;
}

export const assignUser = (
  selectedUserId: number,
  dealId: number,
  roleId: number
) =>
  post(
    `${BASE_URL}v2/deal/${dealId}/user/${selectedUserId}/role/${roleId}/assign/`
  );

export const editSidepanel = (dealId: number, data: {}) =>
  post(`${BASE_URL}v2/deal/${dealId}/pipeline/side-panel/edit/`, data);

export const updateProjectDetails = (
  dealId: number,
  data: Partial<
    (Omit<IDeal_old, "phase"> & { phase_name: string }) &
      (Omit<IDeal_old, "core_data"> & { total_units: number })
  >
): Promise<Partial<IDeal_old>> =>
  // TODO: use the v2 api for update deal
  post(`${BASE_URL}v2/deal/${dealId}/project-details/update/`, data);

export const updateProjectAddress = (
  dealId: number,
  data: Partial<IDeal_old["address"]>
) => put(`${BASE_URL}v2/deal/${dealId}/update-deal-address/`, data);

export const updateProjectSchedule = (
  dealId: number,
  data: Partial<IPhaseForm>
) => {
  const formatData: any = { phase_id: data.phase_id };
  data.phases &&
    data.phases.forEach(
      (x: IDealSchedule) =>
        (formatData[x.phase.id] = {
          phase_name: x.phase.name,
          phase_id: x.phase.id,
          start_date: x.start_date
            ? format(new Date(x.start_date), FORMATS.BACKEND.DATETIME)
            : "",
          end_date: x.end_date
            ? format(new Date(x.end_date), FORMATS.BACKEND.DATETIME)
            : "",
        })
    );

  return put(`${BASE_URL}deal/${dealId}/update_project_schedule`, formatData);
};

export const updateUnitAmenities = ({
  dealId,
  data,
  imageId,
}: {
  dealId: number;
  data?: Partial<IDeal_old["unit_amenities_data"]>;
  imageId?: number;
}): Promise<Partial<IDeal_old>> => {
  const fullData = {
    ...data,
    image_id: imageId,
  };
  return post(
    `${BASE_URL}v2/deal/${dealId}/unit-amenities/create-or-update/`,
    fullData
  );
};

export const deleteUnitAmenitiesImage = (dealId: number) =>
  deleteCall(`${BASE_URL}v2/deal/${dealId}/unit-amenities-image/delete/`);

export interface IUnitAmenitiesData {
  id: number;
  kitchen: string[];
  storage: string[];
  laundry: string[];
  fittings: string[];
}

export const useGetUnitAmenitiesSettings = () =>
  useFetch<IUnitAmenitiesData>("v2/deal/unit-amenities-settings/retrieve/");

export const updateCommunityAmenities = ({
  dealId,
  data,
  imageId,
}: {
  dealId: number;
  data?: Partial<IDeal_old["community_amenities_data"]>;
  imageId?: number;
}): Promise<Partial<IDeal_old>> => {
  const fullData = { ...data, image_id: imageId };
  return post(
    `${BASE_URL}v2/deal/${dealId}/community-amenities/create-or-update/`,
    fullData
  );
};

export const deleteCommunityAmenitiesImage = (dealId: number) =>
  deleteCall(`${BASE_URL}v2/deal/${dealId}/community-amenities-image/delete/`);

export interface ICommunityAmenitiesData {
  front_desk_and_security: string[];
  storage: string[];
  recreational_spaces: string[];
  energy: string[];
  other: string[];
  accreditations: string[];
}

export const useGetCommunityAmenitiesSettings = () =>
  useFetch<ICommunityAmenitiesData>(
    "v2/deal/community-amenities-settings/retrieve/"
  );

export const getGeoLocation = (address: string) =>
  get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_MAPS_API_KEY}`,
    {
      headers: {
        Authorization: undefined,
        "Content-Type": undefined,
      } as unknown as HeadersInit,
      credentials: "omit",
    }
  );

export const useGetDealCities = () => {
  const { data: response } = useFetch<{ cities: string[] }>("v2/deal/cities/");

  return { data: response?.cities };
};

export interface ISidePanel {
  project_details: {
    "Project Avatar": string;
    "Project Name": string;
    Notification: string;
    [name: string]: string;
  };
  assigned_teams: {
    name: string;
    position: string;
    role_id: number;
    user_id: number;
  }[];
  phase_status: {
    last_updated: string;
    project_phase: string;
    project_status: string;
    updated_by: string;
  };
  is_deal_admin: boolean;
}

export const useSidePanel = (dealId: number) =>
  useFetch<ISidePanel>(`v2/reporting/side-panel/deal/${dealId}/details/`);
export const updateDealAsDemo = (dealId: number, is_demo_deal: boolean) =>
  post(`${BASE_URL}v2/deal/${dealId}/mark-as-demo`, { is_demo_deal });

export const updateDeal = ({
  dealId,
  is_demo_deal,
}: {
  dealId: number;
  is_demo_deal: boolean;
}) =>
  post<{ is_demo_deal: boolean; deal_id: number }>(
    `${BASE_URL}v2/deal/deal/${dealId}/update/`,
    {
      is_demo_deal,
    }
  );

export interface IDealPersona {
  persona?: Persona;
  is_deal_admin?: boolean;
  has_write_access: boolean;
  is_full_access: boolean;
}

export const useDealPermission = ({ dealId }: { dealId: number }) =>
  useFetch<IDealPersona>(
    dealId ? `v2/deal/deal/${dealId}/permission-retrieve/` : undefined
  );

export const useDealChecklistPermission = ({
  dealId,
  packageId,
}: {
  dealId: number | undefined;
  packageId: number | undefined;
}) =>
  useFetch<IDealPersona>(
    dealId && packageId
      ? `v2/deal/deal/${dealId}/checklist/${packageId}/permission-retrieve/`
      : undefined
  );

export enum EInvitationStatus {
  PENDING = "Pending",
  ACCEPTED = "Accepted",
  CANCELED = "Canceled",
  REJECTED = "Rejected",
  INVALIDATED = "Invalidated",
}

export const useDealInvitation = ({ invitationId }: { invitationId: string }) =>
  useFetch<{ deal: { name: string; id: number }; status: EInvitationStatus }>(
    `v2/deal/invitation/${invitationId}/retrieve/`
  );

export interface IDealCompanyPackage {
  package_id: number;
  name: string;
  completed_items_count: number;
  items_count: number;
  is_owned: boolean;
  company: {
    name: string;
  };
}

export const useDealPackageList = ({ dealId }: { dealId: number }) =>
  useFetch<Array<IDealCompanyPackage>>(
    `v2/deal/${dealId}/deal-company-package/list/`
  );

export interface ICompanyPackageAccess {
  id: number;
  name: string;
  has_access: boolean;
  items_count: number;
}

export const useDealCompanyPackageList = ({ dealId }: { dealId: number }) =>
  useFetch<Array<ICompanyPackageAccess>>(
    `v2/deal/${dealId}/company-package/list/`
  );

// TODO: Rename this as it is not a company access.
export interface ICompanyPackageCompanyAccess {
  name: string;
  company_id: number;
  has_access: boolean;
  is_owner: boolean;
}

export const useDealPackageCompanyList = ({
  dealId,
  companyPackageId,
}: {
  dealId: number;
  companyPackageId: number;
}) =>
  useFetch<Array<ICompanyPackageCompanyAccess>>(
    `v2/deal/${dealId}/company-package/${companyPackageId}/company/list/`
  );

export interface ICompanyWithAccessToDealPackage {
  id: number;
  name: string;
  user_access_count: number;
  is_owner: boolean;
}

export const useDealPackageCompanyWithAccess = ({
  dealId,
  companyPackageId,
}: {
  dealId: number;
  companyPackageId: number;
}) =>
  useFetch<Array<ICompanyWithAccessToDealPackage>>(
    `v2/deal/${dealId}/company-package/${companyPackageId}/company-with-access/list/`
  );

export const companyPackageAddToDeal = ({
  dealId,
  companyId,
  packageId,
}: {
  dealId: number;
  companyId: number;
  packageId: number;
}) =>
  post(
    `${BASE_URL}v2/deal/${dealId}/company/${companyId}/company-package/${packageId}/add/`
  );

export const companyPackageUpdateCompanies = ({
  dealId,
  companyPackageId,
  companyIds,
}: {
  dealId: number;
  companyPackageId: number;
  companyIds: number[];
}) =>
  post(
    `${BASE_URL}v2/deal/${dealId}/company-package/${companyPackageId}/company/update/`,
    { company_ids: companyIds }
  );

export const companyPackageRemoveFromDeal = ({
  dealId,
  companyPackageId,
}: {
  dealId: number;
  companyPackageId: number;
}) =>
  post(
    `${BASE_URL}v2/deal/${dealId}/company-package/${companyPackageId}/remove-from-deal/`
  );

export const useDealTables = ({
  dealId,
  limit = 10,
}: {
  dealId: number;
  limit?: number;
}) =>
  usePaginatedFetch<ITableForList>({
    url: `v2/tables/deal/${dealId}/list/`,
    params: { limit },
  });

export const dealTableCreate = ({
  dealId,
  name,
  rows,
}: {
  dealId: number;
  name: string;
  rows: Array<Array<ITableCell>>;
}) =>
  post<{ id: number }>(`${BASE_URL}v2/tables/deal/${dealId}/create/`, {
    name,
    rows,
  });

export const useDealTable = ({
  tableId,
  dealId,
  enabled = true,
}: {
  tableId: number;
  dealId: number | null;
  enabled?: boolean;
}) =>
  useFetch<ITable>(
    enabled ? `v2/tables/deal/${dealId}/table/${tableId}/retrieve/` : undefined
  );

export const dealTableDelete = ({
  tableId,
  dealId,
}: {
  tableId: number;
  dealId: number;
}) =>
  deleteCall(`${BASE_URL}v2/tables/deal/${dealId}/table/${tableId}/delete/`);

export const dealTableUpdate = ({
  tableId,
  dealId,
  data,
}: {
  tableId: number;
  dealId: number;
  data: TableUpdateData;
}) =>
  post(`${BASE_URL}v2/tables/deal/${dealId}/table/${tableId}/update/`, data);

import React, { useCallback, useMemo } from "react";
import { FormikProps, getIn } from "formik";
import _ from "lodash";

import { FORMATS, parseISO } from "utils/datetime";

import ContentSeparator from "components/ContentSeparator";
import DatePicker from "components/DatePicker";
import Stack from "components/Stack";

import { IPhaseForm } from "entities/Deal/components/OverviewSchedule/ScheduleEditForm";

interface IPhaseScheduleForm {
  phaseSchedule: IPhaseForm["phases"][0];
  index: number;
  formik: FormikProps<any>; // TODO: Replace with actual type.
}

const PhaseScheduleForm: React.FC<IPhaseScheduleForm> = ({
  phaseSchedule,
  index,
  formik,
}) => {
  const startDateFormikKey = useMemo(
    () => `phases.${index}.start_date`,
    [index]
  );
  const endDateFormikKey = useMemo(() => `phases.${index}.end_date`, [index]);

  const handleChange = useCallback(
    ({ key, date }: { key: string; date: Date | null }) => {
      const newValue = {
        ...phaseSchedule,
        [key]: date,
      };

      const newPhases = formik.values.phases.map((phase: any) => {
        if (phase.phase.id === phaseSchedule.phase.id) {
          return newValue;
        }
        return phase;
      });

      formik.setFieldValue("phases", newPhases);
    },
    [formik, phaseSchedule]
  );

  const startDate = useMemo(() => {
    const formikValue = getIn(formik.values, startDateFormikKey);

    if (_.isNil(formikValue)) {
      return null;
    }

    return _.isDate(formikValue) ? formikValue : parseISO(formikValue);
  }, [formik, startDateFormikKey]);

  const errorsStartDate = useMemo(
    () => getIn(formik.errors, startDateFormikKey),
    [formik, startDateFormikKey]
  );

  const touchedStartDate = useMemo(
    () => getIn(formik.touched, startDateFormikKey),
    [formik, startDateFormikKey]
  );

  const endDate = useMemo(() => {
    const formikValue = getIn(formik.values, endDateFormikKey);

    if (_.isNil(formikValue)) {
      return null;
    }

    return _.isDate(formikValue) ? formikValue : parseISO(formikValue);
  }, [formik, endDateFormikKey]);

  const errorsEndDate = useMemo(
    () => getIn(formik.errors, endDateFormikKey),
    [formik, endDateFormikKey]
  );

  const touchedEndDate = useMemo(
    () => getIn(formik.touched, endDateFormikKey),
    [formik, endDateFormikKey]
  );

  return (
    <Stack spacing={3} data-testid="phase-edit-dates">
      <ContentSeparator label={phaseSchedule.phase.name} />
      <Stack direction="row" spacing={3}>
        <div
          data-testid={`${phaseSchedule.phase.name.toLowerCase()}-start-date`}
          data-test-id="start-date"
        >
          <DatePicker
            label="Start date"
            value={startDate}
            format={FORMATS.FRONTEND.DATE}
            onChange={(value) =>
              handleChange({ key: "start_date", date: value })
            }
            slotProps={{
              textField: {
                error: touchedStartDate && !_.isEmpty(errorsStartDate),
                helperText: touchedStartDate && errorsStartDate,
                inputProps: {
                  "data-testid": `${phaseSchedule.phase.name.toLowerCase()}-start-date-input`,
                },
              },
              field: { clearable: true },
            }}
          />
        </div>
        <div
          data-testid={`${phaseSchedule.phase.name.toLowerCase()}-end-date`}
          data-test-id="end-date"
        >
          <DatePicker
            label="End date"
            value={endDate}
            format={FORMATS.FRONTEND.DATE}
            onChange={(value) => handleChange({ key: "end_date", date: value })}
            slotProps={{
              textField: {
                error: touchedEndDate && !_.isEmpty(errorsEndDate),
                helperText: touchedEndDate && errorsEndDate,
                inputProps: {
                  "data-testid": `${phaseSchedule.phase.name.toLowerCase()}-end-date-input`,
                },
              },
              field: { clearable: true },
            }}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default PhaseScheduleForm;

import React, { useCallback } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import Stack from "components/Stack";
import TextField from "components/TextField";

const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("The name is required!"),
});

export interface MemoTemplateValuesType {
  name: string;
}

interface IMemoTemplateForm {
  initialValues?: Partial<MemoTemplateValuesType>;
  onSubmit: (data: MemoTemplateValuesType) => Promise<any>;
  onCancel?: () => void;
}

const initialValues = {
  name: "",
};

const MemoTemplateForm: React.FC<IMemoTemplateForm> = ({
  onSubmit,
  onCancel,
  initialValues: propsInitialValues = {},
}) => {
  const handleSubmit = useCallback(
    (
      values: MemoTemplateValuesType,
      formikHelpers: FormikHelpers<MemoTemplateValuesType>
    ) =>
      onSubmit({ ...initialValues, ...values }).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
      <Stack spacing={2} justifyContent="space-between" sx={{ height: "100%" }}>
        <Loading open={formik.isSubmitting} />

        <TextField
          fullWidth
          label="Name"
          {...formik.getFieldProps("name")}
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          inputProps={{ "data-testid": "template-name-input" }}
        />
        <Stack
          direction="row"
          justifyContent="end"
          spacing={2}
          style={{ marginTop: theme.spacing(6) }}
        >
          <Button
            variant="text"
            size="large"
            onClick={onCancel}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button type="submit" size="large" data-testid="submit-button">
            Submit
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default MemoTemplateForm;

import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import ChipsGroup from "entities/AnalyticsFilterGroup/components/ChipsGroup";
import AnalyticsTable from "entities/AnalyticsFilterGroup/components/DevBudgetTable";
import EditFilterGroupButton from "entities/AnalyticsFilterGroup/components/EditFilterGroupButton";
import AnalyticsDetailTabs from "entities/AnalyticsFilterGroup/components/Tabs";
import { AnalyticsDevBudgetTabs } from "entities/AnalyticsFilterGroup/components/Tabs/constants";
import {
  ANALYTICS_TYPES,
  useFilterGroup,
} from "entities/AnalyticsFilterGroup/sdk";
import { updateFilter } from "entities/AnalyticsFilterGroup/sdk";
import { getConstructionTypeLabelByValue } from "entities/Deal/utils";

const tabs = [
  { label: "Total costs", value: AnalyticsDevBudgetTabs.TOTAL_COST },
  { label: "Hard costs", value: AnalyticsDevBudgetTabs.HARD_COST },
  { label: "Soft costs", value: AnalyticsDevBudgetTabs.SOFT_COSTT },
];

const AnalyticsDevBudgetDetails: React.FC<{}> = () => {
  usePageTitle("Builders Patch: Analytics - Development Budget");

  const { id } = useParams<{ id: string }>();

  // Here we call `v2/analytics/filter-group/${id}/development-budget/details/`
  const {
    data: filterGroup,
    mutate: updateFilterGroup,
    loading,
    isValidating,
  } = useFilterGroup(id, ANALYTICS_TYPES.DEVELOPMENT_BUDGET);

  const [selectedTab, setSelectedTab] = useState<AnalyticsDevBudgetTabs>(
    AnalyticsDevBudgetTabs.TOTAL_COST
  );

  const handleTabChange = useCallback(
    (_: any, tabIndex: AnalyticsDevBudgetTabs) => {
      setSelectedTab(tabIndex);
    },
    []
  );

  const handleEdit = useCallback(() => {
    // just refetch it, because of the calculation of table data
    updateFilterGroup();
  }, [updateFilterGroup]);

  const chips = useMemo(() => {
    if (!filterGroup) {
      return [];
    }

    const construction_types = _.compact(
      filterGroup.construction_type.map((x) =>
        getConstructionTypeLabelByValue({ value: x })
      )
    );

    return [
      ...filterGroup.cities,
      ...filterGroup.project_size,
      ...filterGroup.project_phase,
      ...construction_types,
    ];
  }, [filterGroup]);

  const handleExcludedDealsUpdate = useCallback(
    (excludedDeals: number[]) => {
      if (filterGroup)
        updateFilter(filterGroup?.id, {
          excluded_deals: excludedDeals,
        }).then(() => updateFilterGroup());
    },
    [filterGroup, updateFilterGroup]
  );

  const pageHeader = useMemo(() => {
    if (_.isNil(filterGroup)) {
      return <Skeleton variant="text" width={200} />;
    }
    return filterGroup.name;
  }, [filterGroup]);

  return (
    <Paper sx={{ marginTop: theme.spacing(2) }}>
      <Stack
        spacing={2}
        sx={{
          backgroundColor: colors.blue10,
          borderRadius: "4px",
          marginBottom: theme.spacing(4),
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ backgroundColor: colors.blue10 }}
        >
          <PageHeader
            title={pageHeader}
            backLink={URLS.ANALYTICS_DEV_BUDGET_LIST}
            backTitle="Development budget"
          />

          {filterGroup ? (
            <Stack marginTop={2} marginRight={2}>
              <EditFilterGroupButton
                filterGroup={filterGroup}
                onSave={handleEdit}
                analyticsType={ANALYTICS_TYPES.DEVELOPMENT_BUDGET}
              />
            </Stack>
          ) : (
            <Skeleton variant="rectangular" width={40} height={40} />
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            padding: theme.spacing(0, 2, 2, 2),
          }}
        >
          <Text
            variant="text2"
            sx={{ marginRight: theme.spacing(3), fontWeight: 700 }}
          >
            {filterGroup ? (
              <>{filterGroup.deals.length} projects</>
            ) : (
              <Skeleton variant="text" width={60} />
            )}
          </Text>
          {filterGroup ? (
            <ChipsGroup chips={chips} />
          ) : (
            <>
              {[1, 2, 3].map((x) => (
                <Skeleton
                  key={x}
                  variant="text"
                  width={100}
                  sx={{ marginLeft: theme.spacing(2) }}
                />
              ))}
            </>
          )}
        </Stack>
      </Stack>
      <Box>
        <AnalyticsDetailTabs
          selectedTab={selectedTab}
          onChange={handleTabChange}
          tabs={tabs}
        />
        {filterGroup ? (
          <AnalyticsTable
            deals={filterGroup.deals}
            dealSumData={filterGroup.deal_sum_data}
            dealAvgData={filterGroup.deal_avg_data}
            selectedTab={selectedTab}
            loading={loading || isValidating}
            excludedDeals={filterGroup.excluded_deals}
            onExcludedDealsUpdate={handleExcludedDealsUpdate}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ marginTop: theme.spacing(2) }}
            height={300}
          />
        )}
      </Box>
    </Paper>
  );
};

export default AnalyticsDevBudgetDetails;

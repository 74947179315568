import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import ScrollableTable from "components/ScrollableTable";
import Select from "components/Select";
import Stack from "components/Stack";
import Switch from "components/Switch";
import Text from "components/Text";

import EditSelectedDealsButton from "entities/AnalyticsFilterGroup/components/EditSelectedDealsButton";
import { IncomeAndOpexCalculationMethods } from "entities/AnalyticsFilterGroup/components/IncomeAndOpexTable/constants";
import {
  exportTableAsXLSX,
  getFormatCalculatedDealsDataForCells,
  getSortedDealsRows,
  getTableHeaders,
} from "entities/AnalyticsFilterGroup/components/IncomeAndOpexTable/utils";
import { AnalyticsIncomeAndOpexTabs } from "entities/AnalyticsFilterGroup/components/Tabs/constants";
import {
  ANALYTICS_TYPES,
  IFilterGroup,
  QUARTER_OPTIONS,
} from "entities/AnalyticsFilterGroup/sdk";
import { combineDealsForFilterGroup } from "entities/AnalyticsFilterGroup/utils";

interface IAnalyticsIncomeAndOpexTable {
  deals: IFilterGroup["deals"];
  excludedDeals: IFilterGroup["excluded_deals"];
  dealAvgData: IFilterGroup["deal_avg_data"];
  selectedTab: AnalyticsIncomeAndOpexTabs;
  selectedYear: string;
  onChangeYear: (year: string) => void;
  selectedQuarter: QUARTER_OPTIONS;
  onChangeQuarter: (quarter: QUARTER_OPTIONS) => void;
  selectedAnalyticsType: ANALYTICS_TYPES;
  onChangeAnalyticsType: (dataType: ANALYTICS_TYPES) => void;
  loading: boolean;
  onExcludedDealsUpdate: (dealIds: Array<number>) => void;
}

const quarterOptions = [
  { label: "Entire year", value: "full_year" },
  { label: "Q1", value: "Q1" },
  { label: "Q2", value: "Q2" },
  { label: "Q3", value: "Q3" },
  { label: "Q4", value: "Q4" },
];

const dataTypes = [
  { value: ANALYTICS_TYPES.INCOME_AND_OPEX, label: "underwritten" },
  { value: ANALYTICS_TYPES.ASSET_MANAGEMENT, label: "audited" },
];
const calculationOptions = Object.values(IncomeAndOpexCalculationMethods);

const yearsOptions = _.range(new Date().getFullYear(), 2012, -1);

const AnalyticsIncomeAndOpexTable: React.FC<IAnalyticsIncomeAndOpexTable> = ({
  deals,
  excludedDeals,
  dealAvgData,
  selectedTab,
  selectedYear,
  onChangeYear,
  selectedQuarter,
  onChangeQuarter,
  selectedAnalyticsType,
  onChangeAnalyticsType,
  loading,
  onExcludedDealsUpdate,
}) => {
  const [selectedCalculation, setSelectedCalculation] =
    useState<IncomeAndOpexCalculationMethods>(
      IncomeAndOpexCalculationMethods.total
    );
  const [selectedSortOption, setSelectedSortOption] = useState<
    string | undefined
  >("total_units");
  const [hideEmptyRows, setHideEmptyRows] = useState(false);

  useEffect(() => {
    if (selectedTab === AnalyticsIncomeAndOpexTabs.METRICS) {
      setSelectedCalculation(IncomeAndOpexCalculationMethods.total);
    }
  }, [selectedTab]);

  const sortedDeals = useMemo(
    () =>
      getSortedDealsRows({
        deals,
        dealAvgData,
        hideEmptyRows,
        selectedTab,
        selectedCalculation,
        selectedSortOption,
      }),
    [
      deals,
      dealAvgData,
      hideEmptyRows,
      selectedTab,
      selectedCalculation,
      selectedSortOption,
    ]
  );

  const calculatedDealsDataWithKeys = useMemo(
    () =>
      getFormatCalculatedDealsDataForCells({
        sortedDeals,
        selectedTab,
        selectedCalculation,
        dealAvgData,
      }),
    [sortedDeals, selectedTab, selectedCalculation, dealAvgData]
  );

  const headers = useMemo(
    () => getTableHeaders({ selectedTab }),
    [selectedTab]
  );

  const handleCalculationChange = useCallback((event: any) => {
    setSelectedCalculation(event.target.value);
  }, []);

  const handleDataTypeChange = useCallback(
    (event: any) => {
      onChangeAnalyticsType(event.target.value);
    },
    [onChangeAnalyticsType]
  );

  const handleYearChange = useCallback(
    (event: any) => {
      onChangeYear(event.target.value);
    },
    [onChangeYear]
  );

  const handleQuarterChange = useCallback(
    (event: any) => {
      onChangeQuarter(event.target.value);
    },
    [onChangeQuarter]
  );

  const handleHideEmptyRowsSwitchChange = useCallback(
    (event: any, value: boolean) => {
      setHideEmptyRows(value);
    },
    []
  );

  const handleSetSortBy = useCallback(
    ({ key }: { key: string | undefined }) => setSelectedSortOption(key),
    []
  );

  const handleExportClick = useCallback(() => {
    exportTableAsXLSX({ headers, rows: sortedDeals, selectedTab });
  }, [headers, sortedDeals, selectedTab]);

  const allDeals = combineDealsForFilterGroup({ deals, excludedDeals });

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: theme.spacing(2.5) }}
      >
        <EditSelectedDealsButton
          deals={allDeals}
          onExcludedDealsUpdate={onExcludedDealsUpdate}
        />
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          flex={1}
        >
          <Stack direction="row" alignItems="center">
            <Text>Hide empty rows:</Text>
            <Switch onChange={handleHideEmptyRowsSwitchChange}></Switch>
          </Stack>
          <Select
            value={selectedAnalyticsType}
            label="Data type"
            options={dataTypes}
            onChange={handleDataTypeChange}
            color="primary"
            sx={{
              flex: "190px",
              flexGrow: 0,
              backgroundColor: colors.white,
              "& .MuiOutlinedInput-root": {
                height: "2.25em",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.blue100,
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: "12px",
                color: colors.blue100,
              },
              "& .MuiInputLabel-root": {
                textTransform: "uppercase",
                fontWeight: 600,
                transform: "translate(14px, -5px) scale(0.75);",
                fontSize: "12px",
                color: colors.blue100,
              },
            }}
            size="small"
          />
          <Select
            disabled={selectedTab === "metrics"}
            value={selectedCalculation}
            label="Calculation"
            options={calculationOptions.map((x) => ({ value: x, label: x }))}
            onChange={handleCalculationChange}
            color="primary"
            sx={{
              flex: "130px",
              flexGrow: 0,
              backgroundColor: colors.white,
              "& .MuiOutlinedInput-root": {
                height: "2.25em",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.blue100,
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: "12px",
                color: colors.blue100,
              },
              "& .MuiInputLabel-root": {
                textTransform: "uppercase",
                fontWeight: 600,
                transform: "translate(14px, -5px) scale(0.75);",
                fontSize: "12px",
                color: colors.blue100,
              },
            }}
            size="small"
          />
          {selectedAnalyticsType === ANALYTICS_TYPES.ASSET_MANAGEMENT && (
            <Select
              value={selectedYear}
              label="Year"
              options={yearsOptions.map((x) => ({
                value: String(x),
                label: String(x),
              }))}
              onChange={handleYearChange}
              color="primary"
              sx={{
                flex: "130px",
                flexGrow: 0,
                backgroundColor: colors.white,
                "& .MuiOutlinedInput-root": {
                  height: "2.25em",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.blue100,
                  textTransform: "uppercase",
                  fontWeight: 600,
                  fontSize: "12px",
                  color: colors.blue100,
                },
                "& .MuiInputLabel-root": {
                  textTransform: "uppercase",
                  fontWeight: 600,
                  transform: "translate(14px, -5px) scale(0.75);",
                  fontSize: "12px",
                  color: colors.blue100,
                },
              }}
              size="small"
            />
          )}
          {selectedAnalyticsType === ANALYTICS_TYPES.ASSET_MANAGEMENT && (
            <Select
              value={selectedQuarter}
              label="Quarter"
              options={quarterOptions.map((quarter) => ({
                value: quarter.value,
                label: quarter.label,
              }))}
              onChange={handleQuarterChange}
              color="primary"
              sx={{
                flex: "130px",
                flexGrow: 0,
                backgroundColor: colors.white,
                "& .MuiOutlinedInput-root": {
                  height: "2.25em",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: colors.blue100,
                  textTransform: "uppercase",
                  fontWeight: 600,
                  fontSize: "12px",
                  color: colors.blue100,
                },
                "& .MuiInputLabel-root": {
                  textTransform: "uppercase",
                  fontWeight: 600,
                  transform: "translate(14px, -5px) scale(0.75);",
                  fontSize: "12px",
                  color: colors.blue100,
                },
              }}
              size="small"
            />
          )}
          <Button color="primary" onClick={handleExportClick}>
            Export
          </Button>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        sx={{
          marginTop: theme.spacing(3),
        }}
      >
        <Stack direction="row" spacing={1}>
          {/* There's an "Averages line" */}
          <Text variant="text3" color={colors.gray60} fontWeight={700}>
            {calculatedDealsDataWithKeys.length - 1} deals shown
          </Text>
        </Stack>
        <ScrollableTable
          dynamicHyphen
          rows={calculatedDealsDataWithKeys}
          headers={headers}
          sortBy={selectedSortOption}
          setSortBy={handleSetSortBy}
          showLoading={loading}
        />
      </Stack>
    </>
  );
};

export default AnalyticsIncomeAndOpexTable;

// @ts-nocheck
// TODO: Drop ts-nocheck when we have time to fix the types
import React from "react";
import { useCallback } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { DeleteIcon } from "icons";

import IconButton from "components/IconButton";
import Stack from "components/Stack";
import TextField from "components/TextField";

import { ICompany } from "entities/Company/sdk";

type ICompanyLocations = Omit<ICompany["locations"][0], "id"> & {
  id?: number;
  uuid?: string;
};

export interface ICompanyAddressForm {
  companyAddress: ICompanyLocations;
  onRemove: (address: ICompanyLocations) => void;
  index: number;
  formik: ReturnType<typeof useFormik>;
}

const CompanyAddressForm: React.FC<ICompanyAddressForm> = ({
  companyAddress,
  onRemove,
  index,
  formik,
}) => {
  const handleRemoveAddress = useCallback(
    () => onRemove(companyAddress),
    [onRemove, companyAddress]
  );

  const handleChange = useCallback(
    (key: string) => (event: any) => {
      const value = event.target.value;
      const newValue = { ...companyAddress, [key]: value };

      const newLocations = formik.values.locations.map(
        (location: ICompanyLocations) => {
          if (
            (!location.id &&
              !companyAddress.id &&
              location.uuid === companyAddress.uuid) ||
            (!location.uuid &&
              !companyAddress.uuid &&
              location.id === companyAddress.id)
          ) {
            return newValue;
          }
          return location;
        }
      );
      formik.setFieldValue("locations", newLocations);
    },
    [formik, companyAddress]
  );

  const errors = _.get(formik, `errors.locations[${index}]`, {});
  const touched = _.get(formik, `touched.locations[${index}]`, {});

  return (
    <Stack
      spacing={3}
      alignSelf="stretch"
      data-testid={`company-office-address-group-${index + 1}`}
    >
      <IconButton
        style={{ alignSelf: "end" }}
        data-testid="delete-address-button"
        onClick={handleRemoveAddress}
      >
        <DeleteIcon />
      </IconButton>
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Office address"
        inputProps={{ "data-testid": "street-address-input" }}
        data-testid="street-address"
        defaultValue={companyAddress.street}
        onChange={handleChange("street")}
        error={touched.street && !!errors.street}
        helperText={touched.street && errors.street}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        label="City"
        inputProps={{ "data-testid": "city-input" }}
        data-testid="city"
        defaultValue={companyAddress.city}
        onChange={handleChange("city")}
        error={touched.city && !!errors.city}
        helperText={touched.city && errors.city}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="State"
          inputProps={{ "data-testid": "state-input" }}
          data-testid="state"
          defaultValue={companyAddress.state}
          onChange={handleChange("state")}
          error={touched.state && !!errors.state}
          helperText={touched.state && errors.state}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Zip code"
          inputProps={{ "data-testid": "zip-code-input" }}
          data-testid="zip-code"
          defaultValue={companyAddress.zipcode}
          onChange={handleChange("zipcode")}
          error={touched.zipcode && !!errors.zipcode}
          helperText={touched.zipcode && errors.zipcode}
        />
      </Stack>
    </Stack>
  );
};

export default CompanyAddressForm;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";

import Dialog, { DialogTitle } from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import MemoTemplateForm, {
  MemoTemplateValuesType,
} from "entities/MemoTemplate/components/MemoTemplateForm";
import {
  updateMemoTemplate,
  useMemoTemplates,
} from "entities/MemoTemplate/sdk";

interface IEditMemoTemplateDialog {
  open: boolean;
  memoId: number;
  onClose: () => void;
  onUpdated: () => void;
}

const EditMemoTemplateDialog = ({
  open,
  memoId,
  onClose,
  onUpdated,
}: IEditMemoTemplateDialog) => {
  const { data: memoTemplates } = useMemoTemplates();

  const memoTemplate = useMemo(
    () => _.first(memoTemplates?.filter((memo) => memo.memo_id === memoId)),
    [memoId, memoTemplates]
  );

  const handleSubmit = useCallback(
    ({ name }: MemoTemplateValuesType) =>
      updateMemoTemplate({ memoTemplateId: memoId, name }).then(() => {
        onUpdated();
      }),
    [memoId, onUpdated]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Paper
        sx={{
          paddingTop: theme.spacing(8),
          paddingBottom: theme.spacing(8),
        }}
        data-testid="edit-template-dialog"
      >
        <DialogTitle data-testid="dialog-title">
          <Stack direction="row" spacing={1}>
            <EditIcon
              style={{
                height: theme.spacing(5),
                width: theme.spacing(5),
              }}
            />
            <Text variant="h1">Edit Memo Template</Text>
          </Stack>
        </DialogTitle>
        <MemoTemplateForm
          onCancel={onClose}
          onSubmit={handleSubmit}
          initialValues={{ name: memoTemplate?.name }}
        />
      </Paper>
    </Dialog>
  );
};

export default EditMemoTemplateDialog;

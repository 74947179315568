import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Chip from "components/Chip";

interface IChipsGroup {
  chips: string[];
  onDelete?: (chip: string) => void;
  dataTestid?: string;
}

const ChipsGroup: React.FC<IChipsGroup> = ({ chips, dataTestid, onDelete }) => {
  return (
    <>
      {chips.map((chip) => (
        <Box key={chip}>
          <Chip
            label={chip}
            sx={{
              height: theme.spacing(3),
              paddingRight: 0,
              border: `1px solid ${colors.blue40}`,
              backgroundColor: colors.white,
            }}
            onDelete={onDelete && (() => onDelete(chip))}
            data-testid={dataTestid}
          />
        </Box>
      ))}
    </>
  );
};

export default ChipsGroup;

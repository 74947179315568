import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined } from "utils/common";

import Box from "components/Box";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IRentalIncomeData } from "entities/Proforma/sdk";

export interface IProformaRentalIncome {
  rentalIncomeData: IRentalIncomeData;
}

const headerRentalIncome: Array<{ key: string; name: string }> = [
  { key: "unittype", name: "Unit Type" },
  { key: "renttype", name: "Rent Type" },
  { key: "units", name: "Units" },
  { key: "grossrent", name: "Gross Rent" },
  { key: "utility", name: "Utility" },
  { key: "netrent", name: "Net Rent" },
  { key: "netannualrent", name: "Net Annual Rent" },
];

const ProformaRentalIncome: React.FC<IProformaRentalIncome> = ({
  rentalIncomeData,
}) => {
  const rentalIncomeRows = useMemo(
    () =>
      _.orderBy(
        _.map(rentalIncomeData.rental_data.rental_income.data, (value) => {
          if (value.value !== 0)
            return {
              unittype: value.name,
              renttype: value.rent_cap,
              units: value.numberofunits,
              grossrent: formatNumberToString({
                number: value.gross_monthly_rent,
              }),
              utility: formatNumberToString({
                number: value.utility_allowance,
              }),
              netrent: formatNumberToString({
                number: value.net_rent_per_month,
              }),
              netannualrent: formatNumberToString({
                number: value.total_annual_rent,
              }),
              position: value.position,
            };
        }).filter(isNotUndefined),
        "position"
      ),
    [rentalIncomeData]
  );

  const tableTotals = {
    unittype: "Total",
    renttype: "-",
    units: rentalIncomeData.rental_data.rental_income.total.units.value,
    grossrent: isNaN(
      rentalIncomeData.rental_data.rental_income.total.gross_monthly_rent.value
    )
      ? "-"
      : formatNumberToString({
          number:
            rentalIncomeData.rental_data.rental_income.total.gross_monthly_rent
              .value,
        }),
    utility: isNaN(
      rentalIncomeData.rental_data.rental_income.total.utility_allowance.value
    )
      ? "-"
      : formatNumberToString({
          number:
            rentalIncomeData.rental_data.rental_income.total.utility_allowance
              .value,
        }),
    netrent: isNaN(
      rentalIncomeData.rental_data.rental_income.total.net_rent_per_month.value
    )
      ? "-"
      : formatNumberToString({
          number:
            rentalIncomeData.rental_data.rental_income.total.net_rent_per_month
              .value,
        }),
    netannualrent: isNaN(
      rentalIncomeData.rental_data.rental_income.total.total_annual_rent.value
    )
      ? "-"
      : formatNumberToString({
          number:
            rentalIncomeData.rental_data.rental_income.total.total_annual_rent
              .value,
        }),
  };
  const netMonthlyIncome =
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc.total
      .affordable_monthly_rent +
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc.total
      .market_rate_monthly_rent;

  const netAnnualIncome =
    (rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
      .total.market_rate_monthly_rent +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.affordable_monthly_rent) *
    12;

  const totals = [
    {
      label: "Net Monthly Income",
      value: netMonthlyIncome,
      type: ProformaHeaderTypeTotal.PRICE,
    },
    {
      label: "Net Annual Income",
      value: netAnnualIncome,
      type: ProformaHeaderTypeTotal.PRICE,
    },
  ];

  return (
    <ProformaPaper
      title="Rental Income"
      subTitle="Rental Income per Unit Type"
      totals={totals}
    >
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          rows={rentalIncomeRows}
          headers={headerRentalIncome}
          totals={tableTotals}
          cellsWidth="110px"
          lastCellWidth="94px"
        />
      </Box>
    </ProformaPaper>
  );
};

export default ProformaRentalIncome;

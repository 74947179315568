// @ts-nocheck
import { useEffect } from "react";

import { getUserFreshdeskToken } from "entities/Auth/sdk";

const FreshworksWidgetWrapper = () => {
  useEffect(() => {
    getUserFreshdeskToken().then(({ freshdesk_token }) => {
      window.fwSettings = {
        widget_id: 69000001808,
      };
      (function () {
        if ("function" != typeof window.FreshworksWidget) {
          var n = function () {
            n.q.push(arguments);
          };
          n.q = [];
          window.FreshworksWidget = n;
          window.FreshworksWidget(n.q);
        }
      })();

      const script = document.createElement("script");
      script.src = "https://widget.freshworks.com/widgets/69000001808.js";
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      const authenticateCallback = function () {
        getUserFreshdeskToken().then(function (data) {
          window.FreshworksWidget("authenticate", {
            token: data.freshdesk_token,
          });
        });
      };

      window.FreshworksWidget("authenticate", {
        token: freshdesk_token,
        callback: authenticateCallback,
      });
    });
  }, []);

  return null;
};

export default FreshworksWidgetWrapper;

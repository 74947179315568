import { colors } from "theme/palette";

import Text from "components/Text";

export interface ITableCountMessage {
  countMessage: string;
}

const TableCountMessage = ({ countMessage }: ITableCountMessage) => (
  <Text variant="text3" paddingLeft={2} color={colors.gray80}>
    {countMessage}
  </Text>
);

export default TableCountMessage;

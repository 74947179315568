import { useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { MoreVertIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import PageHeader from "components/PageHeader";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import ChecklistInfoBox from "entities/Checklist/components/ChecklistInfoBox";
import AddChecklistsButton from "entities/Deal/components/DealChecklists/AddChecklistsButton";
import ChecklistAddedToDeal from "entities/Deal/components/DealChecklists/ChecklistAddedToDeal";
import ChecklistProgress from "entities/Deal/components/DealChecklists/ChecklistProgress";
import ChecklistUpdates from "entities/Deal/components/DealChecklists/ChecklistUpdates";
import RemoveChecklistMenuItem from "entities/Deal/components/DealChecklists/RemoveChecklistMenuItem";
import { useDealPackageList } from "entities/Deal/sdk";
import { useDealPackagesList } from "entities/Package/sdk";

const DealChecklistsOverview = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  usePageTitle(`Builders Patch: Checklists Overview`);

  const {
    data: dealPackages = [],
    isLoading: isFetchingDealPackages,
    mutate: updatePackageList,
  } = useDealPackageList({ dealId });

  const { mutate: refetchDealPackagesList } = useDealPackagesList({
    dealId,
  });

  const handleRemoveChecklist = useCallback(() => {
    updatePackageList();
    refetchDealPackagesList();
  }, [updatePackageList, refetchDealPackagesList]);

  return (
    <Stack spacing={3} sx={{ padding: theme.spacing(3, 4) }}>
      <PageHeader
        title="Checklist overview"
        actions={<AddChecklistsButton dealId={dealId} />}
      />
      {isFetchingDealPackages ? (
        <Stack>
          {_.range(3).map((index) => (
            <Stack
              key={index}
              direction="row"
              spacing={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Skeleton width="360px" height="80px" />
              <Stack>
                <Skeleton variant="text" width="150px" />
                <Skeleton width="200px" height="50px" />
              </Stack>
              <Stack>
                <Skeleton variant="text" width="150px" />
                <Stack spacing={1} direction="row">
                  <Skeleton width="75px" height="50px" />
                  <Skeleton width="75px" height="50px" />
                </Stack>
              </Stack>
              <Skeleton width="20px" height="50px" />
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack spacing={1}>
          {dealPackages.length === 0 ? (
            <Text variant="h6" data-testid="empty-checklist-list-state">
              No checklists attached yet.
            </Text>
          ) : (
            <Stack spacing={2}>
              <Text
                variant="text3"
                sx={{
                  color: colors.gray60,
                }}
                data-testid="checklists-count-summary"
              >
                {dealPackages.length} checklists in this deal
              </Text>

              {dealPackages.map(
                ({
                  name,
                  package_id: packageId,
                  company,
                  is_owned: isOwned,
                  items_count: itemsCount,
                  completed_items_count: completedItemsCount,
                }) => (
                  <Stack
                    key={packageId}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    data-testid="checklist-entity"
                  >
                    <ChecklistInfoBox
                      width="360px"
                      title={name}
                      subTitle={_.isUndefined(company) ? "-" : company.name}
                      url={reverse(URLS.DEAL_PACKAGE_NO_TAB, {
                        dealId,
                        packageId,
                      })}
                    />
                    <ChecklistProgress
                      completedItemsCount={completedItemsCount}
                      itemsCount={itemsCount}
                    />
                    <ChecklistUpdates dealId={dealId} packageId={packageId} />

                    <ChecklistAddedToDeal isOwned={isOwned} />

                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      data-testid="checklist-actions"
                    >
                      {isOwned ? (
                        <ButtonDropdown
                          label=""
                          width="auto"
                          variant="text"
                          sx={{
                            padding: theme.spacing(0.5, 0.75),
                          }}
                          icon={<MoreVertIcon />}
                          keepMenuMounted
                          buttonDataTestid="checklist-actions-button"
                        >
                          <GroupLabel label="Actions" />
                          <RemoveChecklistMenuItem
                            dealId={dealId}
                            companyPackageId={packageId}
                            onRemove={handleRemoveChecklist}
                          />
                        </ButtonDropdown>
                      ) : (
                        <div style={{ width: "32px" }}></div> // This is the placeholder for the menu button
                      )}
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default DealChecklistsOverview;

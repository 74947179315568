import React from "react";
import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { useSignedUser } from "entities/Auth/sdk";
import CompanyTeamTable from "entities/Company/components/TeamInfoBox/CompanyTeamTable";
import { useCurrentCompany } from "entities/Company/sdk";
import CompanyMemberAddButton from "entities/Employee/components/AddButton";
import { IEmployee } from "entities/Employee/sdk";
import { IUser } from "entities/User/sdk";

export interface ITeamInfoBox {
  employees: IEmployee[];
  user: IUser;
}

// Used for anchoring
export const ID = "company_team_section";

const TeamInfoBox: React.FC<ITeamInfoBox> = ({ employees, user }) => {
  const { mutate: mutateUser } = useSignedUser();
  const { data: company, mutate: mutateCompany } = useCurrentCompany(
    user?.company_id
  );
  const handleOnCreated = useCallback(() => {
    toast.successMessage("Member successfully added!");
    mutateCompany(company);
  }, [company, mutateCompany]);

  const handleOnUpdated = useCallback(() => {
    toast.successMessage("Member successfully updated!");
    mutateCompany(company);
    mutateUser();
  }, [company, mutateCompany, mutateUser]);

  const [activeEmployees, inactiveEmployees] = useMemo(
    () => _.partition(employees, ["is_active", true]),
    [employees]
  );

  return (
    <Paper id={ID}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: `1px solid ${colors.gray100}` }}
      >
        <Text
          variant="text1"
          fontWeight={700}
          data-testid="company-team-table-name"
        >
          Company team
        </Text>
      </Stack>

      <Stack
        spacing={4}
        padding={theme.spacing(4, 0)}
        data-testid="company-team-section-active-members"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Text variant="text2" fontWeight={700} color={colors.gray100}>
            Active
          </Text>
          <Text variant="text4" color={colors.gray60}>
            <span data-testid="total-company-active-members">
              {activeEmployees.length}
            </span>{" "}
            seats
          </Text>
        </Stack>
        <CompanyTeamTable
          active
          employees={activeEmployees}
          user={user}
          onUpdated={handleOnUpdated}
          tableDataTestid="active-company-team-members-table"
        />
        {user?.is_admin && (
          <Box>
            <CompanyMemberAddButton onCreated={handleOnCreated} />
          </Box>
        )}

        {!_.isEmpty(inactiveEmployees) && (
          <div data-testid="company-team-section-inactive-members">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Text variant="text2" fontWeight={700} color={colors.gray100}>
                Deactivated
              </Text>
              <Text variant="text4" color={colors.gray60}>
                <span data-testid="total-company-inactive-members">
                  {inactiveEmployees.length}
                </span>{" "}
                seats
              </Text>
            </Stack>
            <CompanyTeamTable
              active={false}
              employees={inactiveEmployees}
              user={user}
              onUpdated={handleOnUpdated}
              tableDataTestid="deactivated-company-team-members-table"
            />
          </div>
        )}
      </Stack>
    </Paper>
  );
};

export default TeamInfoBox;

import _ from "lodash";
import * as yup from "yup";

import { getNow } from "utils/datetime";

export const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("The name is required!"),
  established_on: yup.date().nullable(),
  naics: yup
    .string()
    .matches(/^[0-9]{6}$/, "NAICS must be a 6-digit number!")
    .nullable(),
  addresses: yup
    .array()
    .of(
      yup.object().shape({
        state: yup.string().required("State is required!"),
        city: yup.string().required("City is required!"),
        street1: yup.string().required("Street is required!"),
        primary: yup.boolean().required(),
      })
    )
    .test(
      "one-primary",
      "Should have exactly one primary address.",
      (value) => {
        if (_.isNil(value) || _.isEmpty(value)) {
          return true;
        }

        return value.filter(({ primary }) => primary).length === 1;
      }
    )
    .nullable(),
  emails: yup
    .array()
    .of(
      yup.object().shape({
        address: yup
          .string()
          .email("Must be a valid email.")
          .required("Email address is required!"),
        primary: yup.boolean().required(),
      })
    )
    .test("one-primary", "Should have exactly one primary email.", (value) => {
      if (_.isNil(value) || _.isEmpty(value)) {
        return true;
      }

      return value.filter(({ primary }) => primary).length === 1;
    })
    .nullable(),
  phones: yup
    .array()
    .of(
      yup.object().shape({
        number: yup.string().required("Phone number is required!"),
        primary: yup.boolean().required(),
      })
    )
    .test("one-primary", "Should have exactly one primary phone.", (value) => {
      if (_.isNil(value) || _.isEmpty(value)) {
        return true;
      }

      return value.filter(({ primary }) => primary).length === 1;
    })
    .nullable(),
  websites: yup
    .array()
    .of(
      yup.object().shape({
        url: yup.string().required("Website url is required!"),
        primary: yup.boolean().required(),
      })
    )
    .test(
      "one-primary",
      "Should have exactly one primary website.",
      (value) => {
        if (_.isNil(value) || _.isEmpty(value)) {
          return true;
        }

        return value.filter(({ primary }) => primary).length === 1;
      }
    )
    .nullable(),
  owners: yup
    .array()
    .of(
      yup.object().shape({
        first_name: yup.string().required("First name is required!"),
        last_name: yup.string().required("Last name is required!"),
        primary: yup.boolean().required(),
        date_of_birth: yup
          .date()
          .max(getNow(), "Date of birth must be in the past")
          .nullable(),
      })
    )
    .test("one-primary", "Should have exactly one primary owner.", (value) => {
      if (_.isNil(value) || _.isEmpty(value)) {
        return true;
      }

      return value.filter(({ primary }) => primary).length === 1;
    })
    .nullable(),
});

export const PRIMARY_KEYS = ["primary", "is primary", "main"];
export const PHONE_NUMBER_KEYS = ["phone", "phone number", "number"];
export const WEBSITE_URL_KEYS = ["url"];
export const EMAIL_KEYS = ["email", "email address", "address"];
export const ADDRESS_STREET_KEYS = ["address street", "street"];
export const ADDRESS_STATE_KEYS = ["state"];
export const ADDRESS_CITY_KEYS = ["city"];
export const OWNER_FIRST_NAME_KEYS = ["first name", "name"];
export const OWNER_LAST_NAME_KEYS = ["last name", "surname"];
export const OWNER_GENDER_KEYS = ["gender"];
export const OWNER_DATE_OF_BIRTH_KEYS = [
  "date of birth",
  "birthdate",
  "birthday",
];
export const OWNER_ETHNIC_GROUP_KEYS = ["ethnic group", "ethnicity"];
export const OWNER_VETERAN_STATUS_KEYS = ["veteran", "veteran status"];

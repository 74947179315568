import { colors } from "theme/palette";

import Box from "components/Box";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import MemoTemplateFieldWrapper from "entities/MemoTemplate/components/FieldWrapper";
import { MEMO_VISIBLE_DUE_DILIGENCE_FIELDS } from "entities/MemoTemplate/components/Sections/DDSection/constants";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

interface IMemoTemplateDDSection {
  section: IMemoTemplateSection;
}

const MemoTemplateDDSection = ({ section }: IMemoTemplateDDSection) => {
  return (
    <Box>
      <SectionHeader label={section.title} />
      <SectionContent>
        {section.due_diligence_section?.fields
          ?.filter((field) =>
            // We want to show only fields that can be filled in the memo
            MEMO_VISIBLE_DUE_DILIGENCE_FIELDS.includes(field.field_type)
          )
          .map((field) => (
            <Box
              key={field.company_custom_field_id}
              sx={{
                marginTop: "8px",
                "& .MuiIconButton-root": { visibility: "hidden" },
                "&:hover .MuiIconButton-root": {
                  visibility: "visible",
                },
                borderBottom: `1px solid ${colors.blue40}`,
              }}
            >
              <MemoTemplateFieldWrapper field={field} />
            </Box>
          ))}
      </SectionContent>
    </Box>
  );
};

export default MemoTemplateDDSection;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import MapElement from "components/Map";

import LocationTable from "entities/Deal/components/OverviewLocation/LocationTable";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

const mapOptions = {
  zoom: 13,
};

const markers = [
  {
    center: { lat: 40.8468612, lng: -73.8779108 },
    contentdata: {
      project_name: "",
      address: "",
      thumbnail: "",
      direct_link: "",
      deal_id: 0,
    },
    units: 0,
  },
];

const MemoTemplateLocationSection = ({ label }: { label: string }) => {
  return (
    <Box>
      <SectionHeader label={label} />
      <SectionContent breakInside="avoid">
        <LocationTable
          address={{
            state: "NEW YORK - NY (example)",
            city: "The Bronx",
            zipcode: "10460 (example)",
            street_address: "2300 Southern Blvd (example)",
            longitude: "0",
            latitude: "0",
          }}
        />
        <Box
          sx={{
            height: "400px",
            width: "100%",
            display: "flex",
            backgroundColor: colors.gray20,
            breakInside: "avoid",
            marginTop: theme.spacing(1),
          }}
        >
          <MapElement
            options={mapOptions}
            markers={markers}
            showMarkersButton={false}
          />
        </Box>
      </SectionContent>
    </Box>
  );
};

export default MemoTemplateLocationSection;

import { useMatch } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { getEnvironmentVariables } from "utils/environment";

export const reverse = (
  url: URLS,
  params: { [key: string]: string | number }
) => {
  let reversedUrl = url.toString();
  Object.keys(params).forEach((key) => {
    reversedUrl = reversedUrl.replaceAll(`:${key}`, params[key].toString());
  });

  return reversedUrl;
};

export const addQueryParams = (
  url: string,
  queryParams: { [key: string]: string | number }
) => {
  const questionMarkSeparator = url.indexOf("?") < 0 ? "?" : "&";
  return (
    url +
    questionMarkSeparator +
    Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&")
  );
};

export const getUrlGetParams = () => {
  return window.location.search
    .slice(1)
    .split("&")
    .reduce<{ [key: string]: string }>((result, param) => {
      const keyValue = param.split("=");
      return { ...result, [keyValue[0]]: keyValue[1] };
    }, {});
};

const environment = getEnvironmentVariables();

const s3Urls = (() => {
  if (environment.VITE_S3_URL) {
    if (environment.VITE_S3_URL.indexOf(",") > -1) {
      return environment.VITE_S3_URL.split(",").map((x) => x.trim());
    }
    return [environment.VITE_S3_URL];
  }
  return [];
})();

export const toImgixUrl = (url: string): string => {
  const matchedUrls = s3Urls.filter((s3url) => url.startsWith(s3url));
  if (
    environment.VITE_IMGIX_URL &&
    environment.VITE_S3_URL &&
    matchedUrls.length > 0
  ) {
    return environment.VITE_IMGIX_URL + url.slice(matchedUrls[0].length);
  }

  return url;
};

type Value = string | number | null | boolean;
const formatParam = (value: Value | Value[], key: string) => {
  if (_.isArray(value)) {
    return value.map((val) => `${key}=${val}`).join("&");
  }
  return `${key}=${value}`;
};
// TODO: write tests
export const formatGetParams = (params: { [key: string]: Value | Value[] }) => {
  const urlParams = _.map(params, formatParam).join("&");
  return `${_.isEmpty(params) ? "" : "?"}${urlParams}`;
};

export const useMatchUrl = ({ url }: { url: string }) => Boolean(useMatch(url));

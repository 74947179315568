import { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { EMPTY_LIST } from "utils/constants";

import Box from "components/Box";
import FixedTable from "components/FixedTable";
import SanitizeHtml from "components/SanitizeHtml";
import SimpleTable from "components/SimpleTable";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import FilesTable from "entities/Field/components/Filefield/FilesTable";
import { FieldType } from "entities/Field/sdk";
import { formatFieldValue } from "entities/Field/utils";
import { IDealLinkedPackage } from "entities/Superuser/sdk";

export interface IField {
  field: IDealLinkedPackage["packages"][number]["tabs"][number]["categories"][number]["sections"][number]["fields"][number];
  label: string;
  isCurrentField: boolean;
}

const Field = ({ label, isCurrentField, field }: IField) => {
  const fieldValue = useMemo(() => {
    if (field.field_type !== FieldType.FILE) {
      return _.get(field.values, "0", undefined);
    }
  }, [field]);

  const showMissingTag = useMemo(() => {
    if (field.field_type === FieldType.FILE && _.isEmpty(field.values)) {
      return true;
    }

    if (
      _.includes(
        [FieldType.CHECKBOX, FieldType.RADIO_BUTTON, FieldType.DROPDOWN],
        field.field_type
      ) &&
      _.isEmpty(fieldValue?.selected_value)
    ) {
      return true;
    }

    if (
      field.field_type === FieldType.TEXT_FIELD &&
      _.isEmpty(fieldValue?.default_value)
    ) {
      return true;
    }

    if (
      field.field_type === FieldType.RICH_TEXT_FIELD &&
      _.isEmpty(fieldValue?.rich_value)
    ) {
      return true;
    }

    return false;
  }, [field, fieldValue]);

  return (
    <Box
      sx={{
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${colors.blue20}`,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Text variant="text2">{label}</Text>
          {isCurrentField && (
            <Tag label="current field" sx={{ display: "inline-block" }} />
          )}
        </Stack>
        {_.includes(
          [FieldType.CHECKBOX, FieldType.RADIO_BUTTON, FieldType.DROPDOWN],
          field.field_type
        ) && <Text variant="text2">{fieldValue?.selected_value}</Text>}

        {field.field_type === FieldType.TEXT_FIELD &&
          !_.isEmpty(fieldValue?.default_value) && (
            <Text variant="text2">
              {formatFieldValue({
                fieldValue: fieldValue?.default_value,
                fieldFormatType: field.field_format_type,
              })}
            </Text>
          )}

        {showMissingTag && <Tag label="missing" variant="red" />}
      </Stack>
      {field.field_type === FieldType.RICH_TEXT_FIELD &&
        !_.isEmpty(fieldValue?.rich_value) && (
          <Box
            sx={{
              flex: 1,
              borderRadius: theme.spacing(0.5),
              border: `1px solid ${colors.blue20}`,
              padding: theme.spacing(1, 1.5, 1),
              marginY: theme.spacing(3),
              marginLeft: theme.spacing(3),
              backgroundColor: colors.blue5,
            }}
          >
            <SanitizeHtml html={fieldValue?.rich_value || ""} />
          </Box>
        )}
      {field.field_type === FieldType.TABLE && (
        <Box
          sx={{ marginTop: theme.spacing(1), paddingLeft: theme.spacing(6) }}
        >
          <SimpleTable
            rows={fieldValue?.column_value || EMPTY_LIST}
            headers={field.header_name.map((column) => ({
              label: column,
              key: column,
            }))}
          />
        </Box>
      )}
      {field.field_type === FieldType.FIXED_TABLE && (
        <Box
          sx={{ marginTop: theme.spacing(1), paddingLeft: theme.spacing(6) }}
        >
          <FixedTable
            rows={fieldValue?.column_value || EMPTY_LIST}
            headers={field.header_name.map((column) => ({
              label: column,
              key: column,
            }))}
          />
        </Box>
      )}
      {field.field_type === FieldType.FILE && field.values.length > 0 && (
        <Box
          sx={{ marginTop: theme.spacing(1), paddingLeft: theme.spacing(6) }}
        >
          <Tag
            label="file uploads"
            variant="gray20"
            sx={{ display: "inline-block", marginBottom: theme.spacing(1) }}
          />
          <FilesTable
            customFieldValues={field.values}
            showAISummaries={false}
          />
        </Box>
      )}
    </Box>
  );
};

export default Field;

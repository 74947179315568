import React, { useCallback, useState } from "react";
import _ from "lodash";

import { useCaching } from "utils/common";

import { ISimpleTable } from "components/SimpleTable";
import Cell from "components/SimpleTable/Cell";
import DeleteCell from "components/SimpleTable/DeleteCell";
import EmptyRow from "components/SimpleTable/EmptyRow";
import { TableBody, TableRow } from "components/Table";

const Body: React.FC<ISimpleTable> = ({
  rows: rowProps,
  headers,
  editable,
  showDeleteCell,
  showEmptyRow,
  onUpdated,
}) => {
  const [rows, setRows] = useCaching(rowProps);
  const [activeCell, setActiveCell] = useState<{
    row: number;
    col: number;
  } | null>();

  const handleChange = useCallback(
    (
      rowIndex: number,
      value: string | number | boolean | undefined | null,
      key: string
    ) => {
      const newRowData = { ...rows[rowIndex], [key]: value };
      const updatedRows = [...rows];

      updatedRows.splice(rowIndex, 1, newRowData);
      setRows(updatedRows);
      onUpdated && onUpdated(updatedRows);
    },
    [rows, setRows, onUpdated]
  );

  const handleDelete = useCallback(
    (rowIndex: number) => {
      const updatedRows = [...rows];

      updatedRows.splice(rowIndex, 1);
      setRows(updatedRows);
      onUpdated && onUpdated(updatedRows);
    },
    [rows, setRows, onUpdated]
  );
  const handleKeyDown = useCallback(
    ({ rowIndex, colIndex }: { rowIndex: number; colIndex: number }) => {
      let newRow = rowIndex;
      let newCol = colIndex + 1;

      if (newCol >= headers.length) {
        newCol = 0;
        newRow += 1;
      }
      setActiveCell({
        row: newRow,
        col: newCol,
      });
    },
    [headers.length]
  );

  const checkIsCurrentlyEdited = useCallback(
    ({ row, col }: { row: number; col: number }) => {
      return activeCell?.row === row && activeCell?.col === col;
    },
    [activeCell]
  );

  const manageCellActiveStatus = useCallback(
    ({
      activate,
      row,
      col,
    }: {
      activate: boolean;
      row: number;
      col: number;
    }) => {
      if (activate) {
        setActiveCell({ row, col });
      } else {
        setActiveCell(null);
      }
    },
    []
  );
  return (
    <TableBody data-testid="table-body-section">
      {_.isArray(rows) &&
        rows.map((row, rowIndex) => (
          <TableRow key={rowIndex} data-testid="table-row">
            {headers.map((header, headerIndex) => (
              <React.Fragment key={headerIndex}>
                {header.render ? (
                  header.render(row, header.key)
                ) : (
                  <Cell
                    cellKey={header.key}
                    value={row[header.key]}
                    editable={editable}
                    onChangeCellValue={({ value, key }) =>
                      handleChange(rowIndex, value, key)
                    }
                    onKeyDown={() =>
                      handleKeyDown({
                        rowIndex,
                        colIndex: headerIndex,
                      })
                    }
                    data-testid="table-column-cell"
                    isCurrentlyActive={checkIsCurrentlyEdited({
                      row: rowIndex,
                      col: headerIndex,
                    })}
                    manageCellActiveStatus={({
                      activate,
                    }: {
                      activate: boolean;
                    }) =>
                      manageCellActiveStatus({
                        activate,
                        row: rowIndex,
                        col: headerIndex,
                      })
                    }
                  />
                )}
              </React.Fragment>
            ))}
            {showDeleteCell && (
              <DeleteCell onDelete={() => handleDelete(rowIndex)} />
            )}
          </TableRow>
        ))}
      {showEmptyRow && _.isEmpty(rows) && <EmptyRow colStan={headers.length} />}
    </TableBody>
  );
};

export default Body;

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import {
  scrollToSelectedAnchor,
  useRedirectUponFetchFailure,
} from "utils/common";

import Box from "components/Box";
import { ID as SUB_NAVIGATION_ID } from "components/DealPageLayout/SubNavigation";
import { LinearProgress } from "components/Loading";
import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import ItemSearch from "entities/Checklist/components/ItemSearch";
import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import { useDealChecklistPermission, useDealDetails } from "entities/Deal/sdk";
import ActionButton from "entities/Package/components/ActionButton";
import Category from "entities/Package/components/Category";
import CategorySkeleton from "entities/Package/components/CategorySkeleton";
import LeftMenu from "entities/Package/components/LeftMenu";
import ShareSettings from "entities/Package/components/ShareSettings";
import DueDiligenceTabs from "entities/Package/components/Tabs";
import { DueDiligenceTabChoices } from "entities/Package/components/Tabs/constants";
import ZipDetails from "entities/Package/components/ZipDetails";
import { usePackageDealDetails } from "entities/Package/sdk";

const ANCHORS = [MAIN_NAVIGATION_ID, SUB_NAVIGATION_ID];

const DueDiligence = () => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
    categoryId?: string;
    sectionId?: string;
    fieldId?: string;
  }>();
  const [searchParams] = useSearchParams();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId = Number(params.tabId);

  const queryParams = Object.assign(
    {},
    params.categoryId ? { category_id: params.categoryId } : null,
    params.sectionId ? { section_id: params.sectionId } : null,
    params.fieldId ? { field_id: params.fieldId } : null,
    params.tabId ? { tab_id: params.tabId } : null
  );

  const { data: deal } = useDealDetails({ dealId });
  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });

  const {
    data: checklist,
    loading: isFetchingChecklist,
    isValidating,
    error: checklistError,
  } = usePackageDealDetails({
    packageId,
    dealId,
    queryParams,
  });

  useRedirectUponFetchFailure({
    error: checklistError,
    loading: isFetchingChecklist,
  });

  const [isExpanded, setIsExpanded] = useState(true);
  const [showOnlyAISummaryFiles, setShowOnlyAISummaryFiles] = useState(false);
  const [navigationTab, setNavigationTab] = useState<DueDiligenceTabChoices>(
    DueDiligenceTabChoices.CHECKLIST_ITEMS
  );

  const selectedTab = useMemo(() => {
    const tab = tabId
      ? _.find(checklist?.tabs, ["tab_id", tabId])
      : _.first(checklist?.tabs);

    // Category -> section -> field

    if (!_.isNil(tab) && showOnlyAISummaryFiles) {
      // Drops all the categories -> sections -> fields that do not include a file value that's related to AI Summaries.
      return {
        ...tab,
        categories: tab.categories
          .map((category) => {
            const filteredSections = category.sections
              .map((section) => {
                const filteredFields = section.fields
                  .map((field) => {
                    const filteredValues = field.values.filter(
                      (value) => !_.isEmpty(value.file?.openai_files)
                    );
                    if (filteredValues.length > 0) {
                      return {
                        ...field,
                        values: filteredValues,
                      };
                    }
                    return null;
                  })
                  .filter((field) => field !== null);

                if (filteredFields.length > 0) {
                  return {
                    ...section,
                    fields: filteredFields,
                  };
                }
                return null;
              })
              .filter((section) => section !== null);

            if (filteredSections.length > 0) {
              return {
                ...category,
                sections: filteredSections,
              };
            }
            return null;
          })
          .filter((category) => category !== null),
      };
    }

    return tab;
  }, [checklist, tabId, showOnlyAISummaryFiles]);

  useEffect(() => {
    if (
      !_.isNil(searchParams) &&
      searchParams.has("tab") &&
      loggedUserIsDealAdmin &&
      searchParams.get("tab") === DueDiligenceTabChoices.SHARE_SETTINGS
    ) {
      setNavigationTab(searchParams.get("tab") as DueDiligenceTabChoices);
    }
  }, [searchParams, loggedUserIsDealAdmin]);

  const selectedCategoryId = useMemo(() => {
    const categoryId = searchParams.get("categoryId");
    return !_.isNil(categoryId) ? Number(categoryId) : null;
  }, [searchParams]);

  useLayoutEffect(() => {
    // If we have the same categories in this tab, we will scroll to the first of them
    if (!_.isNil(selectedCategoryId)) {
      requestAnimationFrame(() => {
        scrollToSelectedAnchor({
          anchor: selectedCategoryId.toString(),
          navigationIds: ANCHORS,
        });
      });
    }
  }, [selectedCategoryId, selectedTab]);

  const handleTabChange = useCallback(
    (_: any, tabIndex: DueDiligenceTabChoices) => {
      setNavigationTab(tabIndex);
    },
    []
  );

  usePageTitle(
    deal?.name && checklist?.name
      ? `Builders Patch: ${deal.name} - ${checklist?.name} - Checklist`
      : "Builders Patch: Checklists"
  );

  const { loading: isFetchingDealPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  const loading = useMemo(
    () => isFetchingChecklist || isFetchingDealPermission,
    [isFetchingChecklist, isFetchingDealPermission]
  );

  const showLeftSidebarNavigation = useMemo(
    () => navigationTab === DueDiligenceTabChoices.CHECKLIST_ITEMS,
    [navigationTab]
  );

  return (
    <Paper
      square={true}
      sx={{
        padding: theme.spacing(3, 4),
        flex: 1,
      }}
      data-testid="checklist-page-content"
    >
      {!loading && isValidating && <LinearProgress />}
      <Stack spacing={2} direction="row" alignItems="flex-start">
        <LeftMenu
          onChangeExpanded={setIsExpanded}
          showLeftSidebarNavigation={showLeftSidebarNavigation}
          onChangeShowOnlyAISummaryFiles={({ checked }) =>
            setShowOnlyAISummaryFiles(checked)
          }
        />
        <Paper style={{ flex: 1, overflowX: "auto", paddingTop: 0 }}>
          {loading && (
            <>
              <Box sx={{ marginBottom: theme.spacing(2) }}>
                <Skeleton variant="text" width={200} height={54} />
                <Skeleton variant="text" width={200} />
              </Box>
              <CategorySkeleton />
            </>
          )}

          {!loading && checklist && (
            <>
              <Box sx={{ marginBottom: theme.spacing(2) }}>
                <PageHeader
                  title={checklist?.name || ""}
                  subTitle={
                    <Text variant="text3" sx={{ color: colors.gray80 }}>
                      {checklist?.company?.name || "Builders Patch"}
                    </Text>
                  }
                  actions={
                    <ActionButton
                      isOwned={checklist?.is_owned}
                      dealId={dealId}
                      companyPackageId={packageId}
                      selectedTab={selectedTab}
                      navigationTab={navigationTab}
                    />
                  }
                />
              </Box>

              <DueDiligenceTabs
                dealId={dealId}
                packageId={packageId}
                selectedTab={navigationTab}
                onChange={handleTabChange}
              />

              {navigationTab === DueDiligenceTabChoices.CHECKLIST_ITEMS && (
                <ItemSearch />
              )}

              {navigationTab === DueDiligenceTabChoices.CHECKLIST_ITEMS &&
                selectedTab?.categories.map((category) => (
                  <Category
                    key={category.category_id}
                    isFocused={category.category_id === selectedCategoryId}
                    category={category}
                    isExpanded={isExpanded}
                    dataTestid="dd-category"
                  />
                ))}

              {navigationTab === DueDiligenceTabChoices.ALL_FILES && (
                <ZipDetails dealId={dealId} packageId={packageId} />
              )}
              {navigationTab === DueDiligenceTabChoices.SHARE_SETTINGS && (
                <ShareSettings dealId={dealId} companyPackageId={packageId} />
              )}

              {_.isEmpty(selectedTab?.categories) &&
                navigationTab === DueDiligenceTabChoices.CHECKLIST_ITEMS && (
                  <Box
                    sx={{ paddingY: theme.spacing(5) }}
                    data-testid="no-dd-category-permission-view"
                  >
                    <Text
                      variant="h5"
                      textAlign="center"
                      data-testid="no-dd-permission-message"
                    >
                      No results.
                    </Text>
                  </Box>
                )}
            </>
          )}
        </Paper>
      </Stack>
    </Paper>
  );
};

export default DueDiligence;

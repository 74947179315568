import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import placeholderIcon from "components/DealAvatar/assets/default.png";
import Image from "components/Image";
import Table, { TableBody, TableCell, TableRow } from "components/Table";
import Text from "components/Text";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface IMemoTemplateProjectDetails {
  label: string;
}

const MemoTemplateProjectDetails: React.FC<IMemoTemplateProjectDetails> = ({
  label,
}) => {
  return (
    <Box>
      <SectionHeader label={label} />
      <SectionContent breakInside="avoid">
        <Box sx={{ marginBottom: theme.spacing(2) }}>
          <Table size="small">
            <TableBody>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Project name
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Project's legal name
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Property type
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Phase
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Status
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Construction type
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Total units
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue40}`,
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingLeft: theme.spacing(1),
                  }}
                >
                  <Text
                    variant="text2"
                    fontWeight="300"
                    sx={{ color: colors.gray100 }}
                  >
                    Rent type
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Text variant="text2">...</Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Image
          src={placeholderIcon}
          sx={{
            height: "460px",
            width: "100%",
            objectFit: "cover",
            marginBottom: theme.spacing(2),
          }}
        />
      </SectionContent>
    </Box>
  );
};

export default MemoTemplateProjectDetails;

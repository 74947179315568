import theme from "theme";

import Box from "components/Box";
import FixedTable from "components/FixedTable";
import Stack from "components/Stack";
import Text from "components/Text";

import { getTableVerticalHeaders } from "entities/Field/components/FixedTableField/utils";
import { IMemoTemplateField } from "entities/MemoTemplate/sdk";

interface IFixedTableField {
  field: IMemoTemplateField;
}

const FixedTableField = ({ field }: IFixedTableField) => {
  const rows = getTableVerticalHeaders({
    firstColumn: field.columns[0],
    rows: field.options,
  });

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ flexGrow: 0 }}
      >
        <Text
          variant="text2"
          sx={{
            flexBasis: "400px",
            fontWeight: 300,
            paddingRight: theme.spacing(4),
          }}
        >
          {field.name}
        </Text>
      </Stack>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <FixedTable
          headers={
            field.columns.map((column) => ({
              label: column.trim(),
              key: column.trim(),
            })) || []
          }
          rows={rows}
          showEmptyRow={true}
        />
      </Box>
    </Box>
  );
};

export default FixedTableField;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { EMPTY_LIST } from "utils/constants";

import Box from "components/Box";
import SimpleTable from "components/SimpleTable";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import { IApplicationField } from "entities/Application/sdk";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import EditButton from "entities/Field/components/Tablefield/EditButton";

export interface ITablefield {
  field: IApplicationField;
  editable: boolean;
  onUpdate: ({
    field,
    table_value,
  }: {
    field: IApplicationField;
    table_value: string | null | undefined;
  }) => void;
}

const Tablefield = ({ field, editable, onUpdate }: ITablefield) => {
  const [values, setValues] = useState<any[]>(
    _.get(field.values, "[0].table_value", EMPTY_LIST)
  );

  const handleSave = useCallback(
    ({ newValue }: { newValue: any }) => {
      onUpdate({
        field,
        table_value: JSON.stringify(newValue),
      });
      setValues(newValue);
    },
    [field, setValues, onUpdate]
  );

  const showRequiredTag = useMemo(
    () => editable && _.isEmpty(values) && field.is_required,
    [editable, values, field.is_required]
  );

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Text
            variant="text2"
            sx={{
              fontWeight: 300,
              paddingRight: theme.spacing(2),
            }}
          >
            {field.name}
          </Text>
          <InstructionsDialogButton instructions={field?.instruction} />
        </Stack>
        {editable && (
          <Stack direction="row" spacing={1} alignItems="center">
            {showRequiredTag && <Tag label="required" variant="red" />}
            <EditButton
              field={{
                display_name: field.name,
                header_name:
                  field.columns?.map((column) => ({
                    headerName: column.trim(),
                    field: column.trim(),
                  })) || [],
              }}
              initialValue={values}
              onSave={handleSave}
            />
          </Stack>
        )}
      </Stack>
      <Box sx={{ marginY: theme.spacing(3) }}>
        <SimpleTable
          headers={
            field.columns?.map((column) => ({
              label: column.trim(),
              key: column.trim(),
            })) || []
          }
          rows={values}
          size="small"
          showEmptyRow={true}
        />
      </Box>
    </Box>
  );
};

export default Tablefield;

import { colors } from "theme/palette";

export const COMPANY_PACKAGE_NAME_MAX_LENGTH = 512;

export const COMPANY_PACKAGE_CHIP_STYLE = {
  border: `1px solid ${colors.gray40}`,
  backgroundColor: colors.gray10,
  borderRadius: 4,
  height: 24,
};

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { AIIcon, LockIcon } from "icons";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";

import { useDealChecklistPermission } from "entities/Deal/sdk";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import LinkedFieldsDrawerButton from "entities/Field/components/LinkedFieldsDrawerButton";
import RemarkLabel from "entities/Field/components/RemarkLabel";
import {
  FieldType,
  IField,
  markFieldSeenBySignedUser,
} from "entities/Field/sdk";
import ApproveCheckbox from "entities/Package/components/ApproveCheckbox";
import { fieldApproveBulk } from "entities/Package/sdk";

import LightningIcon from "./assets/lightning.svg";

interface IFieldTitle {
  field: IField;
  dealId: number;
  packageId: number;
  tabId: number;
  categoryId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
}

const FieldTitle: React.FC<IFieldTitle> = ({
  field,
  dealId,
  packageId,
  tabId,
  categoryId,
  sectionId,
  onUpdate,
}) => {
  const [markAsSeenLoading, setMarkAsSeenLoading] = useState(false);
  const [updateCheckboxLoading, setUpdateCheckboxLoading] = useState(false);

  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  const hasEditAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.has_write_access,
    [userPermission]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkboxValue = event.target.checked;
      setUpdateCheckboxLoading(true);
      fieldApproveBulk({
        dealId,
        fieldIds: [field.field_id],
        isApproved: checkboxValue,
      })
        .then(() => {
          onUpdate({
            message: `${field.display_name} was successfully ${
              checkboxValue ? "completed" : "uncompleted"
            }!`,
          });
        })
        .catch(() => {
          toast.errorMessage(DEFAULT_ERROR_MESSAGE);
        })
        .finally(() => {
          setUpdateCheckboxLoading(false);
        });
    },
    [dealId, field, onUpdate]
  );

  const handleClickUpdatedTag = useCallback(() => {
    if (markAsSeenLoading) {
      return;
    }

    setMarkAsSeenLoading(true);
    markFieldSeenBySignedUser({
      dealId,
      packageId,
      fieldId: field.field_id,
    })
      .then(() => onUpdate({}))
      .finally(() => {
        setMarkAsSeenLoading(false);
      });
  }, [dealId, packageId, field, onUpdate, markAsSeenLoading]);

  const checkboxLoadingStyle = useMemo(() => {
    if (updateCheckboxLoading)
      return {
        animation: "rotating 0.4s linear infinite",
        "@keyframes rotating": {
          from: {
            transform: "rotate(0deg)",
          },
          to: {
            transform: "rotate(360deg)",
          },
        },
      };
    return {};
  }, [updateCheckboxLoading]);

  const showAIIcon = useMemo(
    () =>
      field.field_type === FieldType.FILE &&
      field.custom_values.some((item) => !_.isEmpty(item.file?.openai_files)),
    [field]
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        overflow: "hidden",
      }}
    >
      {hasEditAccess && (
        <ApproveCheckbox
          areAllNA={field.n_a_status}
          disabled={updateCheckboxLoading}
          checkboxLoadingStyle={checkboxLoadingStyle}
          onChange={handleCheckboxClick}
          checked={field.is_completed}
          itemDataTestid="field"
        />
      )}
      {field.single_persona && <LockIcon />}
      <Text
        variant="text2"
        sx={{
          opacity: field.n_a_status ? 0.5 : 1,
        }}
        noWrap
        data-testid="dd-field-name"
      >
        {field.display_name}
      </Text>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <InstructionsDialogButton instructions={field?.instruction} />
        {field?.is_linked && (
          <LinkedFieldsDrawerButton
            isOverridden={field?.is_overridden}
            dealId={dealId}
            packageId={packageId}
            fieldId={field.field_id}
            onUpdate={() => onUpdate({})}
          />
        )}
        {showAIIcon && <AIIcon style={{ color: colors.blue100 }} />}
        {field.has_new && field.field_type === FieldType.FILE && (
          <Tooltip title="Field has updates" disableInteractive>
            <img
              src={LightningIcon}
              alt=""
              width={16}
              height={16}
              onClick={handleClickUpdatedTag}
              data-testid="field-updated-icon"
              style={{ cursor: markAsSeenLoading ? "not-allowed" : "pointer" }}
            />
          </Tooltip>
        )}
        {field.has_remark && field.field_type === FieldType.FILE && (
          <RemarkLabel
            sharedRemark={field.remark_value.shared_remark}
            dealId={dealId}
            packageId={packageId}
            fieldId={field.field_id}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default FieldTitle;

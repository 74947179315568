import React, { useCallback, useEffect, useMemo, useRef } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { sortListByV2, useCaching } from "utils/common";
import useComponentScrollWidth from "utils/useComponentScrollWidth";
import useComponentWidth from "utils/useComponentWidth";

import Box from "components/Box";
import { LinearProgress } from "components/Loading";
import {
  CellAlignType,
  ScrollableTableCellVariant,
} from "components/ScrollableTable/constants";
import Row from "components/ScrollableTable/Row";
import ScrollableButtons from "components/ScrollableTable/ScrollableButtons";
import TableHeaders from "components/ScrollableTable/TableHeaders";
import { getVariantSortedColumn } from "components/ScrollableTable/TableHeaders/utils";
import {
  calculateHeadersBasedOnWidth,
  calculateLastColumnWidth,
  showScrollableButton,
} from "components/ScrollableTable/utils";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

export interface IScrollableTable {
  headers: Array<{
    label: string;
    children: {
      label: string;
      key: string;
      width?: string;
      left?: number;
      sortable?: boolean;
      justifyContent?: CellAlignType;
      variant?: ScrollableTableCellVariant;
      noCellPadding?: boolean;
      info?: string;
      render?: ({
        row,
        key,
        isExpanded,
      }: {
        row: any;
        key: string;
        isExpanded?: boolean;
      }) => React.ReactNode;
      sort?: (element: any, index: number, obj: any) => any;
    }[];
    sticky?: boolean;
    width?: string;
    info?: string;
  }>;
  rows?: Array<{
    [key: string]: any;
  }>;
  showLoading?: boolean;
  noDataMessage?: string;
  sortBy?: string;
  setSortBy?: ({ key }: { key: string | undefined }) => void;
  tableDataTestid?: string;
  rowDataTestid?: string;
  rowCellDataTestid?: string;
  childRowCellDataTestid?: string;
  dynamicHyphen?: boolean;
  expectedResultsCount?: number;
}

const ScrollableTable: React.FC<IScrollableTable> = ({
  rows,
  headers,
  showLoading = false,
  noDataMessage = "No data in list",
  sortBy,
  setSortBy,
  tableDataTestid,
  rowDataTestid,
  rowCellDataTestid, //give only the prefix of the cell testid, the cell title will be auto added
  childRowCellDataTestid,
  dynamicHyphen,
  expectedResultsCount = 1,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width: tableWidth, recalculateWidth: recalculateTableWidth } =
    useComponentWidth(ref);
  const {
    width: tableScrollWidth,
    recalculateWidth: recalculateTableScrollWidth,
  } = useComponentScrollWidth(ref);

  const [selectedSortKey, setSelectedSortKey] = useCaching<string | undefined>(
    sortBy
  );

  const sortedRows = useMemo(() => {
    // If we have sortBy, so the sort function is in the component where we call the ScrollableTable
    if (_.isUndefined(sortBy) && !_.isUndefined(rows)) {
      const columnVariant = getVariantSortedColumn({
        currentSort: selectedSortKey,
        headers,
      });

      const isNumber =
        columnVariant === ScrollableTableCellVariant.NUMBER ||
        columnVariant === ScrollableTableCellVariant.CURRENCY;

      const isDateTime = columnVariant === ScrollableTableCellVariant.DATE_TIME;

      return sortListByV2({
        list: rows,
        key: selectedSortKey,
        isNumber,
        isDateTime,
      });
    }

    return rows;
  }, [rows, sortBy, headers, selectedSortKey]);

  useEffect(() => {
    // Recalculate the table width when the headers change
    if (!_.isEmpty(headers)) {
      recalculateTableWidth();
      recalculateTableScrollWidth();
    }
  }, [headers, recalculateTableWidth, recalculateTableScrollWidth]);

  const handleSort = useCallback(
    (key: string | undefined) => {
      setSelectedSortKey(key);
      setSortBy && setSortBy({ key });
    },
    [setSelectedSortKey, setSortBy]
  );

  const handleScroll = useCallback(
    (scrollOffset: number) => {
      if (ref && ref.current) {
        ref.current.scrollLeft += scrollOffset;
      }
    },
    [ref]
  );

  const headersBasedOnWidth = useMemo(
    () =>
      calculateHeadersBasedOnWidth({
        headers,
        dynamicHyphen,
        tableWidth,
        tableScrollWidth,
      }),
    [headers, dynamicHyphen, tableWidth, tableScrollWidth]
  );

  const showSkeleton = useMemo(() => {
    return !showLoading && _.isEmpty(sortedRows) && _.isUndefined(rows);
  }, [rows, showLoading, sortedRows]);

  const showNoData = useMemo(() => {
    return !showLoading && _.isEmpty(sortedRows) && !_.isUndefined(rows);
  }, [rows, showLoading, sortedRows]);

  const lastElementWidth = useMemo(() => {
    if (dynamicHyphen) {
      return calculateLastColumnWidth({
        headers: headersBasedOnWidth,
        tableWidth,
      });
    }
    return 0;
  }, [headersBasedOnWidth, dynamicHyphen, tableWidth]);

  const shouldShowScrollableButton = useMemo(
    () => showScrollableButton({ tableWidth, tableScrollWidth }),
    [tableWidth, tableScrollWidth]
  );

  return (
    <Stack spacing={1}>
      <Box
        ref={ref}
        sx={{
          width: "100%",
          overflow: "auto",
          scrollBehavior: "smooth",
        }}
        data-testid={tableDataTestid}
      >
        <TableHeaders
          onSort={handleSort}
          headers={headersBasedOnWidth}
          currentSort={selectedSortKey}
          lastElementWidth={lastElementWidth}
        />
        {showLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}
        {showLoading && _.isEmpty(sortedRows) && (
          <>
            {[1, 2, 3].map((x) => (
              <Box
                key={x}
                sx={{
                  padding: theme.spacing(0.5, 1.5),
                  borderBottom: `1px solid ${colors.blue60}`,
                }}
              >
                <Skeleton variant="text" />
              </Box>
            ))}
          </>
        )}
        <Box sx={{ width: "max-content" }}>
          {showSkeleton &&
            _.range(expectedResultsCount).map(() => <Skeleton height={50} />)}
          {showNoData && (
            <Box sx={{ paddingY: theme.spacing(2) }}>
              <Text
                variant="text1"
                align="center"
                data-testid="empty-table-content"
              >
                {noDataMessage}
              </Text>
            </Box>
          )}
          {sortedRows?.map((row, index) => (
            <Row
              key={`row-${index}`}
              row={row}
              headers={headersBasedOnWidth}
              rowDataTestid={rowDataTestid}
              rowCellDataTestid={rowCellDataTestid}
              childRowCellDataTestid={childRowCellDataTestid}
            />
          ))}
        </Box>
      </Box>
      {shouldShowScrollableButton && (
        <ScrollableButtons onScroll={handleScroll} />
      )}
    </Stack>
  );
};

export default ScrollableTable;

import { useMemo } from "react";
import _ from "lodash";

import { useFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { EFieldFormatType } from "entities/Reporting/constants";

export interface INonPipelineColumns {
  report_fields: {
    name: string;
    field: string;
    field_type: string;
    field_width: number;
    field_format_type: string;
  }[];
  name: string;
}

export interface ISimpleReportPackage {
  id: number;
  name: string;
}

export const useIncomeAndOpexColumns = () =>
  useFetch<INonPipelineColumns[]>(`v2/deal/reporting/income-and-opex/sections`);

export const useIncomeAndOpexRows = () =>
  useFetch<{ [key: string]: string | null }[]>(
    `v2/deal/reporting/income-and-opex/report`
  );

export const useIncomeAndOpexCompanyDeals = () => {
  const {
    data: rows,
    loading: rowsLoading,
    isValidating: isValidatingRows,
  } = useIncomeAndOpexRows();

  const {
    data: columns,
    loading: columnsLoading,
    isValidating: isValidatingColumns,
  } = useIncomeAndOpexColumns();

  return useMemo(
    () => ({
      rows,
      columns,
      loading: rowsLoading || columnsLoading,
      isValidating: isValidatingRows || isValidatingColumns,
    }),
    [
      rows,
      columns,
      rowsLoading,
      columnsLoading,
      isValidatingRows,
      isValidatingColumns,
    ]
  );
};

export const useDealCoreDataColumns = () =>
  useFetch<INonPipelineColumns[]>(`v2/deal/reporting/deal-core-data/sections`);

export const useDealCoreDataRows = () =>
  useFetch<Array<{ [key: string]: string | null }>>(
    `v2/deal/reporting/deal-core-data/report`
  );

export const useDealCoreDataCompanyDeals = () => {
  const {
    data: rows,
    loading: rowsLoading,
    isValidating: isValidatingRows,
  } = useDealCoreDataRows();

  const {
    data: columns,
    loading: columnsLoading,
    isValidating: isValidatingColumns,
  } = useDealCoreDataColumns();

  const formattedColumns = useMemo(
    () =>
      columns?.map((column) =>
        column.name === "Total Construction Sources"
          ? {
              ...column,
              report_fields: column.report_fields.map((field) => ({
                ...field,
                field_width: 230,
              })),
            }
          : column
      ),
    [columns]
  );

  return useMemo(
    () => ({
      rows,
      columns: formattedColumns,
      loading: rowsLoading || columnsLoading,
      isValidating: isValidatingRows || isValidatingColumns,
    }),
    [
      rows,
      formattedColumns,
      rowsLoading,
      columnsLoading,
      isValidatingRows,
      isValidatingColumns,
    ]
  );
};

export const useCustomReports = () => {
  const { data, ...rest } = useFetch<ISimpleReportPackage[]>(
    "v2/reporting/report-package/list"
  );

  return { data: data || [], ...rest };
};

export const useCustomReportSections = (reportPackageId: number) =>
  useFetch<INonPipelineColumns[]>(
    `v2/reporting/report-package/${reportPackageId}/dashboard-sections`
  );

export const useCustomReportRows = (reportPackageId: number) =>
  useFetch<{ [key: string]: string | null }[]>(
    `v2/reporting/report-package/${reportPackageId}/dashboard-details`,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export const useCustomReportCompanyDeals = (reportPackageId: number) => {
  const {
    data: rows,
    loading: rowsLoading,
    isValidating: isValidatingRows,
    error: rowsError,
  } = useCustomReportRows(reportPackageId);

  const {
    data: columns,
    loading: columnsLoading,
    isValidating: isValidatingColumns,
    error: columnsError,
  } = useCustomReportSections(reportPackageId);

  return useMemo(
    () => ({
      rows,
      columns,
      loading: rowsLoading || columnsLoading,
      isValidating: isValidatingRows || isValidatingColumns,
      error: rowsError || columnsError,
    }),
    [
      rows,
      columns,
      rowsError,
      columnsError,
      rowsLoading,
      columnsLoading,
      isValidatingRows,
      isValidatingColumns,
    ]
  );
};

export const useReportPackageDetails = (reportPackageId: number) =>
  useFetch<ISimpleReportPackage>(
    `v2/reporting/report-package/${reportPackageId}/details`
  );

export interface IReportPackageFilter {
  name: string;
  field_format_type: EFieldFormatType;
}

export const useReportPackageFilterList = ({
  report_package_id,
}: {
  report_package_id: number | null | undefined; // null is for the landing page, undefined is for the default reports
}) => {
  const params = _.isNil(report_package_id)
    ? ""
    : formatGetParams({ report_package_id: report_package_id });

  const endpoint = !_.isUndefined(report_package_id)
    ? `v2/reporting/report-package/filter-list/${params}`
    : undefined;

  return useFetch<Array<IReportPackageFilter>>(endpoint);
};

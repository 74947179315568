import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Box from "components/Box";
import Button from "components/Button";
import ContentSeparator from "components/ContentSeparator";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

interface IRemarkData {
  title: string;
  remark: string;
  updated_at: string;
  created_by: string;
  updated_by: string | null;
  onUpdate: (value: string) => void;
  dataTestid?: string;
}

const RemarkData: React.FC<IRemarkData> = ({
  title,
  remark,
  updated_at,
  created_by,
  updated_by,
  onUpdate,
  dataTestid,
}) => {
  const [value, setValue] = useCaching(remark === "null" ? "" : remark);
  const [isEdit, setIsEdit] = useState(false);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // This is the legacy validation
      setValue(event.target.value === "" ? "null" : event.target.value);
    },
    [setValue]
  );

  const handleCancel = useCallback(() => {
    setValue(remark);
    setIsEdit(false);
  }, [setValue, setIsEdit, remark]);

  const handleSaveChanges = useCallback(() => {
    setIsEdit(false);
    onUpdate(value);
  }, [onUpdate, value]);

  const isRemarkCreated = useMemo(
    () => !_.isNil(updated_at) && _.isNil(updated_by) && !_.isEmpty(remark),
    [updated_at, updated_by, remark]
  );

  const isRemarkUpdated = useMemo(
    () => !_.isNil(updated_at) && !_.isNil(updated_by) && !_.isEmpty(remark),
    [updated_at, updated_by, remark]
  );

  return (
    <>
      <ContentSeparator label={title} />
      {!isEdit && !value && (
        <Stack direction="row" justifyContent="space-between">
          <Text
            variant="text4"
            sx={{
              paddingLeft: theme.spacing(1),
            }}
            data-testid={`${dataTestid}-value`}
          >
            No remarks added…Add some lines by clicking the edit sign.
          </Text>
          <IconButton
            size="small"
            title="Edit"
            onClick={() => setIsEdit(true)}
            dataTestid={`${dataTestid}-edit-button`}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      )}
      {(isEdit || value) && (
        <Box
          sx={{
            paddingLeft: theme.spacing(1),
          }}
          data-testid={dataTestid}
        >
          {!isEdit && value && (
            <Stack
              direction="row"
              justifyContent="space-between"
              data-testid={dataTestid}
            >
              <Box
                sx={{ marginBottom: theme.spacing(1) }}
                data-testid={`${dataTestid}-value`}
              >
                {value}
              </Box>
              <IconButton
                size="small"
                title="Edit"
                onClick={() => setIsEdit(true)}
                dataTestid={`${dataTestid}-edit-button`}
              >
                <EditIcon />
              </IconButton>
            </Stack>
          )}
          {isEdit && (
            <Stack spacing={1} data-testid={`${dataTestid}-edit`}>
              <TextField
                multiline
                defaultValue={value}
                onChange={_.debounce(handleChange, 200)}
                data-test="edit-remarks-field"
                inputProps={{ "data-testid": "field-input-value" }}
              />
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button
                  size="small"
                  variant="text"
                  onClick={handleCancel}
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  onClick={handleSaveChanges}
                  data-testid="submit-button"
                >
                  Save changes
                </Button>
              </Stack>
            </Stack>
          )}
          {isRemarkCreated && (
            <Text
              variant="text4"
              sx={{ color: colors.gray60 }}
              data-testid={`${dataTestid}-last-edited`}
            >
              Created on {format(parseJSON(updated_at), FORMATS.FRONTEND.DATE)}{" "}
              by {created_by}
            </Text>
          )}

          {isRemarkUpdated && (
            <Text
              variant="text4"
              sx={{ color: colors.gray60 }}
              data-testid={`${dataTestid}-last-edited`}
            >
              Last edited on{" "}
              {format(parseJSON(updated_at), FORMATS.FRONTEND.DATE)} by{" "}
              {updated_by}
            </Text>
          )}
        </Box>
      )}
    </>
  );
};

export default RemarkData;

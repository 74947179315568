const coordinates = (
  half: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const startAngleDegrees = (Math.PI * startAngle) / 180;
  const endAngleDegrees = (Math.PI * endAngle) / 180;

  return {
    x1: half + half * radius * Math.cos(startAngleDegrees),
    y1: half + half * radius * Math.sin(startAngleDegrees),
    x2: half + half * radius * Math.cos(endAngleDegrees),
    y2: half + half * radius * Math.sin(endAngleDegrees),
  };
};

export const textCoordinates = ({
  half,
  angle,
  radius,
}: {
  half: number;
  angle: number;
  radius: number;
}) => {
  const angleDegrees = (Math.PI * angle) / 180;

  return {
    x: half + half * radius * Math.cos(angleDegrees),
    y: half - half * radius * Math.sin(angleDegrees),
  };
};

const arc = (
  width: number,
  radius: number,
  largeArcFlag: string,
  x: number,
  y: number
) => {
  const z = (width / 2) * radius;

  return `A${z}, ${z} 0 ${largeArcFlag} ${x}, ${y}`;
};

export const calculatePath = (
  activeAngle: number,
  startAngle: number,
  width: number,
  innerRadius: number,
  outerRadius: number
) => {
  const endAngle =
    startAngle +
    activeAngle -
    1; /* the -1 here is for a gap between elements of the pie chart*/

  const largeArcFlagOuter = activeAngle > 180 ? "1 1" : "0 1";
  const largeArcFlagInner = activeAngle > 180 ? "1 0" : "0 0";
  const half = width / 2;
  const outerCoords = coordinates(half, outerRadius, startAngle, endAngle);
  const innerCoords = coordinates(half, innerRadius, startAngle, endAngle);

  const outerArc = arc(
    width,
    outerRadius,
    largeArcFlagOuter,
    outerCoords.x2,
    outerCoords.y2
  );
  const innerArc = arc(
    width,
    innerRadius,
    largeArcFlagInner,
    innerCoords.x1,
    innerCoords.y1
  );

  return `M${outerCoords.x1},${outerCoords.y1}
  ${outerArc}
  L${innerCoords.x2},${innerCoords.y2}
  ${innerArc} Z`;
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import SelectedTable from "entities/TableStorage/components/SelectedTable";
import TablesAutocomplete from "entities/TableStorage/components/TablesAutocomplete";
import { ETableReportType } from "entities/TableStorage/constants";
import { ITableList, reportTableGenerate } from "entities/TableStorage/sdk";

const ReportCreate = () => {
  usePageTitle(`Builders Patch: AI-powered Generated Tables - Report Create`);

  const navigate = useNavigate();

  const [selectedTables, setSelectedTables] = useState<Array<ITableList>>([]);

  const params = useParams<{
    reportType: string;
  }>();

  useEffect(() => {
    if (
      params.reportType !== ETableReportType.TYPE_1 &&
      params.reportType !== ETableReportType.TYPE_2 &&
      params.reportType !== ETableReportType.TYPE_3
    ) {
      navigate(URLS.TABLE_STORAGE_LIST);
    }
  }, [params.reportType, navigate]);

  const reportType = useMemo(
    () => params.reportType as ETableReportType,
    [params.reportType]
  );

  const handleRemoveSelectedTable = useCallback(
    ({ tableId }: { tableId: number }) => {
      setSelectedTables((prevSelectedTables) =>
        prevSelectedTables.filter((table) => table.id !== tableId)
      );
    },
    []
  );

  const uniqueTableIds = useMemo(
    () => _.uniq(selectedTables.map((table) => table.id)),
    [selectedTables]
  );

  const handleGenerateReportTable = useCallback(() => {
    reportTableGenerate({
      tableIds: uniqueTableIds,
      reportType,
    })
      .then(() => {
        toast.successMessage(
          "You will be notified once the processing has finished."
        );
        navigate(URLS.TABLES_RESPONSE_LIST);
      })
      .catch(handleInvalidRequest);
  }, [uniqueTableIds, reportType, navigate]);

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          spacing={4}
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader
            title={`Report ${reportType}`}
            backTitle="AI-powered generated reports"
            backLink={URLS.TABLES_RESPONSE_LIST}
          />
          <Stack spacing={2}>
            <Text variant="text1" color={colors.gray80} fontWeight={700}>
              Selected tables
            </Text>
            <Stack spacing={2}>
              <Box sx={{ width: "300px" }}>
                <TablesAutocomplete
                  currentSelectedTables={selectedTables}
                  setSelectedTables={({ tables }) => setSelectedTables(tables)}
                  fieldLabel="Search tables by name"
                />
              </Box>
              {selectedTables.map((table) => (
                <SelectedTable
                  key={table.id}
                  table={table}
                  onRemoveSelectedTable={handleRemoveSelectedTable}
                  showRemoveButton={true}
                />
              ))}
              {!_.isEmpty(selectedTables) && (
                <Box>
                  <Button
                    onClick={handleGenerateReportTable}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    Generate a report
                  </Button>
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ReportCreate;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import SuccessDialog from "components/SuccessDialog";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { useSignedUser } from "entities/Auth/sdk";
import AddNewChecklistButton from "entities/CompanyChecklist/components/AddNewChecklistButton";
import CatalogDialog from "entities/CompanyChecklist/components/CatalogDialog";
import ChecklistsPageSection from "entities/CompanyChecklist/components/ChecklistsPageSection";
import {
  useCompanyChecklists,
  useDuplicatedCompanyChecklists,
  useSharedCompanyChecklists,
} from "entities/Package/sdk";

const CompanyChecklists = () => {
  const [catalogDialogOpen, setCatalogDialogOpen] = useState(false);
  const [addedToTemplatesDialog, setAddedToTemplatesDialog] = useState(false);

  const { mutate: refreshCompanyChecklists } = useCompanyChecklists();

  const { mutate: refreshDuplicatedCompanyChecklists } =
    useDuplicatedCompanyChecklists();

  const { mutate: refreshSharedCompanyChecklists } =
    useSharedCompanyChecklists();

  const closeCatalogDialog = useCallback(() => setCatalogDialogOpen(false), []);
  const openCatalogDialog = useCallback(() => setCatalogDialogOpen(true), []);

  const handleButtonClickOnSuccessDialog = useCallback(() => {
    setAddedToTemplatesDialog(false);
  }, []);

  const closeSuccessDialog = useCallback(
    () => setAddedToTemplatesDialog(false),
    []
  );

  const closeCatalogDialogAndRefreshChecklists = useCallback(() => {
    refreshCompanyChecklists();
    refreshDuplicatedCompanyChecklists();
    refreshSharedCompanyChecklists();
    closeCatalogDialog();
    setAddedToTemplatesDialog(true);
  }, [
    refreshCompanyChecklists,
    refreshDuplicatedCompanyChecklists,
    refreshSharedCompanyChecklists,
    closeCatalogDialog,
  ]);

  const { data: user } = useSignedUser();

  const userIsAdmin = useMemo(() => !_.isNil(user) && user.is_admin, [user]);

  usePageTitle("Builders Patch: Checklist templates");

  return (
    <Stack sx={{ padding: theme.spacing(4, 3) }} spacing={3}>
      <PageHeader
        title="Checklist templates"
        actions={
          userIsAdmin && (
            <AddNewChecklistButton openCatalogDialog={openCatalogDialog} />
          )
        }
      />

      <ChecklistsPageSection
        title="My custom checklists"
        description="Contents of these templates are managed and maintained by my company.
          These templates can be edited or duplicated."
        emptyStateText="You don't have any checklists yet."
        useChecklists={useCompanyChecklists}
      />
      <ChecklistsPageSection
        title="Shared with me..."
        description="These are checklists that have been added to your deals to work together on. You can create your own duplicate template from theirs, and you can create links to sync the values across different checklists."
        emptyStateText="There are no checklists shared with you."
        useChecklists={useSharedCompanyChecklists}
      />

      <Stack
        spacing={2}
        sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}
        padding={3}
      >
        <Text variant="text1" fontWeight={700} maxWidth="780px">
          Find more checklists in the Builders Patch catalog! Clone them into
          your template library and add to your deals!
        </Text>

        {userIsAdmin && (
          <Stack alignItems="start">
            <Button
              onClick={openCatalogDialog}
              color="secondary"
              sx={{
                backgroundColor: colors.white,
                border: `1px solid ${colors.blue60}`,
              }}
            >
              Browse catalog
            </Button>
          </Stack>
        )}
      </Stack>

      <ChecklistsPageSection
        title="Added from Builders Patch catalog"
        description="These are checklists that have been added to your deals to work
          together on. You can create your own duplicate template from theirs,
          and you can create links to sync the values across different
          checklists."
        emptyStateText="You do not have any checklists from Builders Patch catalog."
        useChecklists={useDuplicatedCompanyChecklists}
      />

      <CatalogDialog
        isOpen={catalogDialogOpen}
        onClose={closeCatalogDialog}
        closeAndRefreshChecklists={closeCatalogDialogAndRefreshChecklists}
      />
      <SuccessDialog
        isOpen={addedToTemplatesDialog}
        title="Added to templates"
        subTitle="You can edit your templates and add them to your deals on the template management page."
        onClose={closeSuccessDialog}
        onButtonClick={handleButtonClickOnSuccessDialog}
      />
    </Stack>
  );
};

export default CompanyChecklists;

import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import SearchField from "components/SearchField";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import PublicChecklistRecord from "entities/CompanyChecklist/components/PublicChecklistRecord";
import { IPublicChecklist, usePublicChecklists } from "entities/Package/sdk";

export interface IChecklistCloneForm {
  selectedPackage: IPublicChecklist | null;
  setSelectedPackage: React.Dispatch<
    React.SetStateAction<IPublicChecklist | null>
  >;
  onSelect: () => void;
  onClose: () => void;
}

const ChecklistCloneForm = ({
  selectedPackage,
  setSelectedPackage,
  onSelect,
  onClose,
}: IChecklistCloneForm) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredPackageOptions, setFilteredPackageOptions] = useState<
    Array<IPublicChecklist>
  >([]);

  const { data: packageOptions = [], isLoading } = usePublicChecklists();

  useEffect(() => {
    if (packageOptions.length > 0) {
      setFilteredPackageOptions(
        packageOptions.filter(
          ({ name, company }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            company.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, packageOptions]);

  const handleChecklistSelect = useCallback(
    ({ checklist }: { checklist: IPublicChecklist }) =>
      setSelectedPackage(checklist),
    [setSelectedPackage]
  );

  return (
    <>
      {isLoading ? (
        <Stack sx={{ marginLeft: theme.spacing(3) }}>
          {_.range(6).map((index) => (
            <Skeleton variant="text" key={index} width={400} height={40} />
          ))}
        </Stack>
      ) : (
        <>
          <SearchField
            fullWidth
            value={searchTerm}
            size="small"
            placeholder="Search by lender or checklist name"
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <Stack
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              padding: theme.spacing(1),
              border: `1px solid ${colors.blue60}`,
              marginTop: theme.spacing(1),
              borderRadius: 1,
            }}
          >
            {filteredPackageOptions.length === 0 ? (
              <Text variant="body1" sx={{ marginLeft: 1 }}>
                No packages.
              </Text>
            ) : (
              <Stack spacing={1}>
                {filteredPackageOptions.map((checklist, index) => (
                  <PublicChecklistRecord
                    key={checklist.id}
                    checklist={checklist}
                    onClick={handleChecklistSelect}
                    index={index}
                    isSelected={checklist.id === selectedPackage?.id}
                  />
                ))}
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{
              paddingTop: theme.spacing(2),
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
            }}
            data-testid="form-action-buttons"
          >
            <Button variant="text" onClick={onClose} data-testid="close-button">
              Cancel
            </Button>
            <Button
              onClick={onSelect}
              data-testid="submit-button"
              disabled={_.isNil(selectedPackage)}
            >
              Add to templates
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};

export default ChecklistCloneForm;

import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import InfoBanner from "entities/AISummaries/components/InfoBanner";
import PromptPackageSelect from "entities/AISummaries/components/PromptPackageSelect";
import PromptsAndAnswers from "entities/AISummaries/components/PromptsAndAnswers";
import {
  openAIDocumentAssistantPromptPackageUpdate,
  useOpenAIDocumentAssistant,
  useOpenAIPromptPackages,
  usePromptList,
} from "entities/AISummaries/sdk";

export interface IEditorContent {
  openAIDocumentAssistantId: number | null;
}

const EditorContent = ({ openAIDocumentAssistantId }: IEditorContent) => {
  const [hasOpenaAIAnswerInProcess, setHasOpenaAIAnswerInProcess] =
    useState(false);

  const { data: promptPackages, isLoading: promptPackagesAreLoading } =
    useOpenAIPromptPackages();
  const {
    data: documentAssistant,
    isLoading: documentAssistantIsLoading,
    mutate: mutateDocumentAssistant,
  } = useOpenAIDocumentAssistant({
    openAIDocumentAssistantId,
  });
  const { data: prompts, mutate: mutatePrompts } = usePromptList({
    openAIDocumentAssistantId,
  });

  useEffect(() => {
    if (!_.isNil(prompts)) {
      setHasOpenaAIAnswerInProcess(
        prompts.some((prompt) =>
          prompt.prompt_responses.some((response) =>
            _.isNil(response.readable_answer)
          )
        )
      );
    }
  }, [prompts]);

  const changePromptPackage = useCallback(
    ({ newPromptPackageId }: { newPromptPackageId: number }) => {
      if (_.isNil(openAIDocumentAssistantId)) {
        return;
      }

      openAIDocumentAssistantPromptPackageUpdate({
        openAIDocumentAssistantId,
        openAIPromptPackageId: newPromptPackageId,
      })
        .then(() => {
          toast.successMessage(
            "The prompt package has been successfully updated!"
          );
          mutateDocumentAssistant();
          mutatePrompts();
        })
        .catch((errors) => {
          const errorMessage = errors?.message || errors?.detail;

          if (!_.isNil(errorMessage)) {
            toast.errorMessage(errorMessage);
          } else {
            errors?.forEach?.((error: unknown) => {
              if (_.isString(error)) {
                toast.errorMessage(error);
              }
            });
          }
        });
    },
    [openAIDocumentAssistantId, mutateDocumentAssistant, mutatePrompts]
  );

  const showInfoBanner = useMemo(
    () => _.isNil(documentAssistant?.openai_prompt_package),
    [documentAssistant]
  );

  const reasonForButtonBeingDisabled = useMemo(() => {
    if (hasOpenaAIAnswerInProcess) {
      return "Please wait for the current answer to be generated.";
    }

    return "";
  }, [hasOpenaAIAnswerInProcess]);

  const showPromptPackageSelectField = useMemo(
    () => _.isNil(documentAssistant?.openai_prompt_package),
    [documentAssistant]
  );

  return (
    <Stack spacing={showPromptPackageSelectField ? 1 : 2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={1}
      >
        {documentAssistantIsLoading ||
        promptPackagesAreLoading ||
        _.isNil(promptPackages) ? (
          <>
            <Skeleton width="50%" height="56px" />
            <Skeleton variant="text" width="150px" height="24px" />
          </>
        ) : (
          <Stack spacing={3} width="100%">
            {showInfoBanner && <InfoBanner />}
            {showPromptPackageSelectField && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Tooltip title={reasonForButtonBeingDisabled}>
                  <span style={{ flexBasis: "50%" }}>
                    <PromptPackageSelect
                      promptPackages={promptPackages}
                      disabled={hasOpenaAIAnswerInProcess}
                      onChange={changePromptPackage}
                    />
                  </span>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
      {!_.isNil(documentAssistant?.openai_prompt_package) && (
        <PromptsAndAnswers
          hasOpenaAIAnswerInProcess={hasOpenaAIAnswerInProcess}
          onGenerateAnswer={() => setHasOpenaAIAnswerInProcess(true)}
          openAIDocumentAssistantId={documentAssistant.id}
        />
      )}
    </Stack>
  );
};

export default EditorContent;

import { useCallback } from "react";

import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import CompaniesForm from "entities/Deal/components/DealChecklists/CompaniesForm";
import {
  companyPackageUpdateCompanies,
  useDealPackageList,
} from "entities/Deal/sdk";
import { useDealPackagesList } from "entities/Package/sdk";

export interface ICompaniesDialog {
  isOpen: boolean;
  dealId: number;
  companyPackageId: number;
  onClose: () => void;
}

const CompaniesDialog = ({
  isOpen,
  dealId,
  companyPackageId,
  onClose,
}: ICompaniesDialog) => {
  const { mutate: updatePackageList } = useDealPackageList({
    dealId,
  });
  const { mutate: refetchDealPackagesList } = useDealPackagesList({ dealId });

  const handleSubmit = useCallback(
    ({ selectedCompanies }: { selectedCompanies: number[] }) => {
      companyPackageUpdateCompanies({
        dealId,
        companyPackageId,
        companyIds: selectedCompanies,
      })
        .then(() => {
          updatePackageList();
          refetchDealPackagesList();
          toast.successMessage(
            "Companies for the checklist have been updated successfully!"
          );
          onClose();
        })
        .catch((error) => {
          const errorMessage =
            error?.message || error?.detail || DEFAULT_ERROR_MESSAGE;

          toast.errorMessage(errorMessage);
        });
    },
    [
      companyPackageId,
      dealId,
      onClose,
      updatePackageList,
      refetchDealPackagesList,
    ]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      dialogDataTestid="update-company-accesses-dialog"
    >
      <Box>
        <Paper
          sx={{
            padding: theme.spacing(8),
            width: "100%",
          }}
        >
          <Stack spacing={4}>
            <Stack spacing={1} data-testid="dialog-header-section">
              <Text
                variant="h1"
                style={{ display: "flex" }}
                data-testid="dialog-title"
              >
                Update company accesses
              </Text>
              <Text
                variant="text3"
                marginTop={0.5}
                data-testid="dialog-subtitle"
              >
                Select the companies that you want to have access to the
                checklist.
              </Text>
            </Stack>
            <CompaniesForm
              dealId={dealId}
              companyPackageId={companyPackageId}
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          </Stack>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default CompaniesDialog;

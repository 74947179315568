import { useEffect, useMemo, useState } from "react";
import React from "react";
import _ from "lodash";

import theme from "theme";

import Box from "components/Box";
import StyledTable, { IHeader as ITableHeader } from "components/StyledTable";

import {
  nameColumn,
  transformTableData,
} from "entities/AssetManagement/components/Table/utils";
import { transformApiHeaderToTableHeader } from "entities/AssetManagement/components/Table/utils";
import { AMResolution } from "entities/AssetManagement/constants";
import { IAssetManagementDataV2 } from "entities/AssetManagement/sdk";

interface IProps {
  data: IAssetManagementDataV2;
  selectedTab: string;
  editMode: boolean;
  resolution: AMResolution;
  showSubItems: boolean;
  columns: string[];
  onChange: (args: {
    itemKey: string;
    value: number | null;
    prevValue: string | null;
  }) => void;
  changedCells: {
    [key: string]: {
      current_value: number | null;
      previous_value: number | null;
    };
  };
  deleteRow: (args: { rowKey: string }) => void;
  deletedRowKeys: Array<string>;
  yearMappings: { [key: string]: string };
  updateYearMapping: (args: {
    oldYear: string | number;
    newYear: string;
  }) => void;
  isLoading?: boolean;
  editHeaders?: boolean;
}

const AssetManagementTable = ({
  data,
  selectedTab,
  editMode,
  resolution,
  showSubItems,
  columns,
  isLoading,
  onChange,
  changedCells,
  deleteRow,
  deletedRowKeys,
  yearMappings,
  updateYearMapping,
  editHeaders = false,
}: IProps) => {
  const [collapsedSubCategories, setCollapsedSubCategories] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (showSubItems) {
      setCollapsedSubCategories([]);
    } else {
      setCollapsedSubCategories(data.rows.map((row) => row.sub_category));
    }
  }, [showSubItems, data.rows]);

  const headers: Array<
    ITableHeader<{
      label: string;
      itemCode: string;
      isTotalRow: boolean;
    }>
  > = useMemo(
    () => [
      nameColumn({
        selectedTab,
        setCollapsedSubCategories,
        collapsedSubCategories,
        editMode,
        handleRowDelete: deleteRow,
        deletedRowKeys,
      }),
      ...data?.headers
        .filter(
          (header) =>
            !_.isNull(header.quarter) ===
            (resolution === AMResolution.QUARTERLY)
        )
        .map<
          ITableHeader<{
            isTotalRow: boolean | undefined;
            isAdded: boolean | undefined;
            id: number;
          }>
        >((header) =>
          transformApiHeaderToTableHeader({
            allSelectedHeaders: data.headers,
            header,
            editMode,
            editHeaders,
            columns,
            onChange,
            yearMappings,
            updateYearMapping,
          })
        ),
    ],
    [
      collapsedSubCategories,
      columns,
      data.headers,
      editMode,
      resolution,
      selectedTab,
      onChange,
      deleteRow,
      deletedRowKeys,
      editHeaders,
      yearMappings,
      updateYearMapping,
    ]
  );

  const mappedTableData = useMemo(
    () =>
      transformTableData({
        rows: data.rows,
        selectedTab,
        collapsedSubCategories,
        changedCells,
        deletedRowKeys,
        columns,
        yearMappings,
      }),
    [
      data.rows,
      selectedTab,
      collapsedSubCategories,
      changedCells,
      deletedRowKeys,
      columns,
      yearMappings,
    ]
  );

  return (
    <Box sx={{ marginTop: theme.spacing(3) }}>
      <StyledTable
        key={selectedTab}
        data={mappedTableData}
        headers={headers}
        showLoading={isLoading}
        tableDataTestid="am-table"
      />
    </Box>
  );
};

export default React.memo(AssetManagementTable);

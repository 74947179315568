import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IProformaTable {
  rows: Array<{ [key: string]: any; detail?: Array<{ [key: string]: any }> }>;
  headers: Array<any>;
  totals?: { [key: string]: string | number };
  cellsWidth?: string;
  firstCellWidth?: string;
  lastCellWidth?: string;
}

const ProformaTable: React.FC<IProformaTable> = ({
  rows,
  headers,
  totals,
  cellsWidth,
  firstCellWidth,
  lastCellWidth,
}) => {
  return (
    <Table
      size="small"
      sx={{
        height: "100%",
        tableLayout: "fixed",
        "& .MuiTableCell-root": {
          width: cellsWidth ? cellsWidth : "120px",
        },
        "& .MuiTableCell-root:first-of-type": {
          textAlign: "left",
          paddingLeft: 0,
          width: firstCellWidth ? firstCellWidth : "auto",
        },
        "& .MuiTableCell-root:first-of-type div": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
        "& .MuiTableCell-root:last-of-type": {
          paddingRight: 0,
          width: lastCellWidth ? lastCellWidth : "104px",
        },
      }}
    >
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          {headers.map((header: any, index) => (
            <TableCell align="right" key={index}>
              <Text variant="labelSmall" color={colors.gray60}>
                {header.name}
              </Text>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <TableRow
              sx={{
                height: "34px",
                ".MuiTableCell-root": {
                  borderBottom: `1px solid ${colors.blue40}`,
                },
              }}
            >
              {headers.map((header, index) => (
                <TableCell
                  component={"th" as any} // Issue in MUI not allowing strings as component even though their documentationc clearly states it's allowed.
                  scope="row"
                  align="right"
                  key={index}
                >
                  <Tooltip title={row[header.key]} placement="top-start">
                    <Text variant="text2">{row[header.key]}</Text>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
            {row.detail &&
              row.detail.map((detail, index) => (
                <TableRow
                  sx={{
                    "& .MuiTableCell-root:first-of-type": {
                      paddingLeft: theme.spacing(6),
                    },
                    height: "34px",
                    ".MuiTableCell-root": {
                      borderBottom: `1px solid ${colors.blue40}`,
                    },
                  }}
                  key={index}
                >
                  {headers.map((header, index) => (
                    <TableCell
                      component={"th" as any} // Issue in MUI not allowing strings as component even though their documentationc clearly states it's allowed.
                      scope="row"
                      align="right"
                      key={index}
                    >
                      <Tooltip title={detail[header.key]} placement="top-start">
                        <Text variant="text2">{detail[header.key]}</Text>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </React.Fragment>
        ))}
        <TableRow
          sx={{
            height: "-webkit-fill-available",
            verticalAlign: "bottom",
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          {totals &&
            headers.map((header, index) => (
              <TableCell scope="row" align="right" key={index}>
                <Text variant="text2" fontWeight="700">
                  {totals[header.key]}
                </Text>
              </TableCell>
            ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ProformaTable;

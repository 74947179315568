import { useCallback } from "react";

import { AddIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Chip from "components/Chip";
import Dialog, { DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import ChecklistsForm from "entities/Deal/components/DealChecklists/ChecklistsForm";
import { useDealPackageList } from "entities/Deal/sdk";
import { useDealPackagesList } from "entities/Package/sdk";

export interface IChecklistsDialog {
  isOpen: boolean;
  dealId: number;
  companyId: number;
  onClose: () => void;
  onSubmit: ({ packageId }: { packageId: number }) => void;
}

const ChecklistsDialog = ({
  isOpen,
  dealId,
  companyId,
  onClose,
  onSubmit,
}: IChecklistsDialog) => {
  const { mutate: updatePackageList } = useDealPackageList({
    dealId,
  });
  const { mutate: refetchDealPackagesList } = useDealPackagesList({ dealId });

  const handleSubmit = useCallback(
    ({ packageId }: { packageId: number }) => {
      updatePackageList();
      refetchDealPackagesList();
      toast.successMessage(
        "Checklists for the company have been updated successfully!"
      );
      onSubmit({ packageId });
    },
    [onSubmit, updatePackageList, refetchDealPackagesList]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      dialogDataTestid="add-checklist-to-deal-dialog"
    >
      <DialogTitle
        sx={{
          paddingX: theme.spacing(2),
          margin: theme.spacing(2, 2, 3, 2),
          borderRadius: 2,
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <AddIcon
            style={{
              height: "24px",
              width: "24px",
            }}
          />
          <Text
            variant="h2"
            marginBottom={theme.spacing(0.5)}
            color={colors.gray100}
            data-testid="header"
          >
            Add new checklist
          </Text>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center" paddingTop={1}>
          <Chip
            label="My checklists"
            sx={{
              border: `1px solid ${colors.gray40}`,
              backgroundColor: colors.gray10,
              borderRadius: 4,
              height: 24,
            }}
            data-testid="my-checklists-chip"
          />
          <Text variant="text2" color={colors.gray80} data-testid="subheader">
            Select from your custom checklist templates created and managed by
            your organization.
          </Text>
        </Stack>
      </DialogTitle>
      <ChecklistsForm
        dealId={dealId}
        companyId={companyId}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </Dialog>
  );
};

export default ChecklistsDialog;

import { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { fieldOptionMultipleRetrieve, fieldOptionRetrieve } from "utils/common";

import Box from "components/Box";
import NoPrint from "components/NoPrint";

import { IFieldTemplateMeta } from "entities/Checklist/sdk";
import EmptyFieldValue from "entities/Field/components/FormTextField/EmptyFieldValue";
import { FieldType } from "entities/Field/sdk";
import { formatFieldValue } from "entities/Field/utils";

export interface IFieldValue {
  templateMeta: IFieldTemplateMeta;
}
const FieldValue = ({ templateMeta }: IFieldValue) => {
  const valueLabel = useMemo(() => {
    if (
      [FieldType.DROPDOWN, FieldType.RADIO_BUTTON].includes(
        templateMeta.field_type
      ) &&
      !_.isNil(templateMeta.options)
    ) {
      return fieldOptionRetrieve({
        options: templateMeta.options,
        value: templateMeta.value,
      })?.label;
    }

    if (
      templateMeta.field_type === FieldType.CHECKBOX &&
      !_.isNil(templateMeta.options)
    ) {
      return fieldOptionMultipleRetrieve({
        options: templateMeta.options,
        value: templateMeta.value,
      })
        ?.map((option) => option.label)
        .join(", ");
    }

    return templateMeta.value;
  }, [templateMeta.field_type, templateMeta.options, templateMeta.value]);

  if (templateMeta.value !== "" && templateMeta.value !== null) {
    return (
      <>
        <NoPrint sx={{ display: "inline" }}>
          <span
            style={{
              borderBottom: `1px solid ${colors.gray40}`,
              padding: theme.spacing(0, 1),
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
            }}
            data-testid="linked-field-value"
          >
            {formatFieldValue({
              fieldValue: valueLabel,
              fieldFormatType: templateMeta.field_format_type,
            })}
          </span>
        </NoPrint>
        <Box
          sx={{
            "@media screen": { display: "none" },
            "@media print": {
              display: "inline-block",
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
            },
          }}
        >
          {formatFieldValue({
            fieldValue: valueLabel,
            fieldFormatType: templateMeta.field_format_type,
          })}
        </Box>
      </>
    );
  }
  return <EmptyFieldValue fieldName={templateMeta.field_name} />;
};

export default FieldValue;

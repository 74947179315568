import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { isNotUndefined } from "utils/common";

import Box from "components/Box";
import ChipsGroup from "components/ChipsGroup";
import Stack from "components/Stack";

import {
  ITypeOfFilter,
  useDealListForTaskFilters,
  usePriorityList,
  useTagList,
} from "entities/Task/sdk";

interface ISelectedFilters {
  selectedFilters: ITypeOfFilter;
  onUpdateFilters: (filters: ITypeOfFilter) => void;
}

const SelectedFilters: React.FC<ISelectedFilters> = ({
  selectedFilters,
  onUpdateFilters,
}) => {
  const { data: priorityList } = usePriorityList();
  const { data: tagList } = useTagList();
  const { data: deals } = useDealListForTaskFilters();

  const handleDelete = useCallback(
    (chip: string) => {
      let [key, filterLabel] = chip.split(":");
      const selectedFilter = selectedFilters[key];
      if (key === "priority") {
        _.map(priorityList, (priority) => {
          if (priority.name === filterLabel.trim())
            filterLabel = priority.id.toString();
        });
      } else if (key === "tags") {
        _.map(tagList, (tag) => {
          if (tag.name === filterLabel.trim()) filterLabel = tag.id.toString();
        });
      } else if (key === "deals" && deals) {
        _.map(deals.deals, (deal) => {
          if (deal.name === filterLabel.trim())
            filterLabel = deal.id.toString();
        });
      }
      if (_.isArray(selectedFilter)) {
        onUpdateFilters({
          ...selectedFilters,
          [key]: _.remove(
            selectedFilter,
            (filter) => filter.toString() !== filterLabel.trim()
          ),
        });
      }
    },
    [onUpdateFilters, tagList, priorityList, selectedFilters, deals]
  );

  const chips = useMemo(() => {
    return Object.keys(selectedFilters)
      .flatMap((filterType) => {
        const filterValues = selectedFilters[filterType];
        if (_.isArray(filterValues) && filterValues.length > 0) {
          return filterValues.map((value) => {
            if (filterType === "priority") {
              _.map(priorityList, (priority) => {
                if (priority.id === value) value = priority.name;
              });
            } else if (filterType === "tags") {
              _.map(tagList, (tag) => {
                if (tag.id === value) value = tag.name;
              });
            } else if (filterType === "deals" && deals) {
              _.map(deals.deals, (deal) => {
                if (deal.id === value) value = deal.name;
              });
            }
            return `${filterType}: ${value}`;
          });
        }
        return undefined;
      })
      .filter(isNotUndefined);
  }, [selectedFilters, priorityList, tagList, deals]);

  return (
    <Box sx={{ marginTop: theme.spacing(3) }}>
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
          "& > *": { margin: theme.spacing(0, 1, 1, 0) },
        }}
      >
        <ChipsGroup chips={chips} onDelete={handleDelete} />
      </Stack>
    </Box>
  );
};

export default SelectedFilters;

import React, { useCallback } from "react";

import Form from "entities/AnalyticsFilterGroup/components/Form";
import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";
import {
  ANALYTICS_TYPES,
  updateFilter,
  useAnalyticsFilterGroups,
} from "entities/AnalyticsFilterGroup/sdk";

export interface IEditForm {
  onUpdated?: (data: IFilterGroup) => void;
  onCancel?: () => void;
  filterGroup: IFilterGroup;
  analyticsType: ANALYTICS_TYPES;
}

const EditForm: React.FC<IEditForm> = ({
  onUpdated,
  onCancel,
  filterGroup,
  analyticsType,
}) => {
  const { mutate: updateFilterGroupsList } =
    useAnalyticsFilterGroups(analyticsType);

  const handleSubmit = useCallback(
    (data: Partial<IFilterGroup>) => {
      const formatData = {
        ...data,
        excluded_deals: data.excluded_deals
          ? data.excluded_deals.map((x) => x.id)
          : [],
      };
      return updateFilter(filterGroup.id, formatData).then((data) => {
        onUpdated && onUpdated(data);
        updateFilterGroupsList((currentData: IFilterGroup[] | undefined) =>
          currentData?.map((item) => {
            if (item.id === data.id) {
              return data;
            }
            return item;
          })
        );
        return data;
      });
    },
    [onUpdated, updateFilterGroupsList, filterGroup.id]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save group"
      initialValues={filterGroup}
    />
  );
};

export default EditForm;

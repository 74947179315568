import toast from "components/Toast";

export const downloadFile = (url: string, filename: string) => {
  const link = window.document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  window.document.body.appendChild(link);
  link.click();
};

export const getFileNameFromUrl = (url: string): string => {
  return url?.split("/")?.pop() || "";
};

export const isPdfByFileName = ({ filename }: { filename: string }): boolean =>
  filename.endsWith(".pdf");

export const downloadFileWithFileName = async ({
  fileUrl,
  fileName,
}: {
  fileUrl: string;
  fileName: string;
}) => {
  // Allows users to download files with the original filename they've uploaded them with.
  const toastId = toast.loading("Retrieving file...");

  const sharedToastProperties = {
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    isLoading: false,
    closeButton: true,
  };

  try {
    // Fetch the file from the server
    const response = await fetch(fileUrl);

    // Check if the request was successful
    if (!response.ok) {
      toast.update(toastId, {
        render: `Error retrieving file: ${response.statusText}`,
        type: "error",
        position: "bottom-right",
        ...sharedToastProperties,
      });
      return;
    }

    // Get the blob representing the file data
    const blob = await response.blob();

    // Create a link element
    const link = document.createElement("a");

    // Create a Blob URL for the blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Set the href attribute to the Blob URL
    link.href = blobUrl;

    // Set the download attribute to the desired filename
    link.download = fileName;

    // Simulate a click on the link element to trigger the download
    link.click();

    // Clean up the Blob URL
    window.URL.revokeObjectURL(blobUrl);

    link.remove();

    toast.update(toastId, {
      render: "File retrieved successfully",
      type: "success",
      position: "bottom-right",
      ...sharedToastProperties,
    });
  } catch (error) {
    toast.update(toastId, {
      render: `Error retrieving file: ${error}`,
      type: "error",
      position: "bottom-right",
      ...sharedToastProperties,
    });
  }
};

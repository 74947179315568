import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { AddIcon } from "icons";
import { handleInvalidRequest } from "utils/sdk";

import Autocomplete, {
  AutocompletePaperComponent,
  createFilterOptions,
} from "components/Autocomplete";
import Button from "components/Button";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import TextField from "components/TextField";

import {
  fetchPersonParticipantData,
  IDownhomePersonParticipant,
  useDownhomePersonParticipants,
} from "entities/CompanyItegrations/sdk";

import {
  EDownhomePersonParticipantOptionGroup,
  IDownhomeOwnerProperties,
  IDownhomePersonParticipantOption,
} from "./constants";
import { checklistDataConvertToBusinessOwner } from "./utils";

const filter = createFilterOptions<IDownhomePersonParticipantOption>();

interface IAddOwnerButton {
  currentOwners: IDownhomePersonParticipant[] | null;
  handleAddOwner: ({
    owner,
    setPrimaryOnAdding,
  }: {
    owner: IDownhomePersonParticipant | null;
    setPrimaryOnAdding: boolean;
  }) => void;
  initialOwners?: IDownhomeOwnerProperties[] | null;
}

const AddOwnerButton = ({
  currentOwners,
  handleAddOwner,
  initialOwners,
}: IAddOwnerButton) => {
  const [showSearch, setShowSearch] = useState(false);
  const [fetchingPersonData, setFetchingPersonData] = useState(false);

  const { data: personParticipants = [], isLoading } =
    useDownhomePersonParticipants({
      enabled: showSearch,
    });

  const handleButtonClick = useCallback(() => setShowSearch(true), []);

  const options = useMemo(
    () =>
      personParticipants
        .filter(
          (participant) =>
            !currentOwners
              ?.map((owner) => owner.external_identifier)
              .includes(participant.external_identifier)
        )
        .map((participant) => ({
          ...participant,
          label: `${participant.first_name || ""} ${participant.last_name}`,
          _groupKey: EDownhomePersonParticipantOptionGroup.EXISTING,
        })),
    [currentOwners, personParticipants]
  );

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: IDownhomePersonParticipantOption | null | string
    ) => {
      if (!_.isString(value) && !_.isNil(value)) {
        if (!_.isUndefined(value.external_identifier)) {
          setFetchingPersonData(true);
          fetchPersonParticipantData({
            externalIdentifier: value.external_identifier,
          })
            .then((data) => {
              handleAddOwner({ owner: data, setPrimaryOnAdding: true });
              setShowSearch(false);
            })
            .catch(handleInvalidRequest)
            .finally(() => setFetchingPersonData(false));
        } else if (
          _.isUndefined(value.external_identifier) &&
          value._key !== ""
        ) {
          handleAddOwner({
            owner: checklistDataConvertToBusinessOwner({
              ownerData: value.context,
            }),
            setPrimaryOnAdding: false,
          });
          setShowSearch(false);
        } else {
          handleAddOwner({
            owner: {
              primary: false,
              first_name: value.first_name,
              last_name: value.last_name,
              date_of_birth: null,
              veteran_status: null,
              gender: null,
              ethnic_group: null,
            },
            setPrimaryOnAdding: true,
          });
          setShowSearch(false);
        }
      }
    },
    [handleAddOwner]
  );

  const handleFilterOptions = useCallback(
    (options: Array<IDownhomePersonParticipantOption>, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;

      if (!_.isEmpty(initialOwners)) {
        initialOwners?.forEach((owner) => {
          filtered.push({
            first_name: owner.first_name,
            last_name: owner.last_name,
            label: `Import "${owner.first_name} ${owner.last_name}"`,
            _groupKey:
              EDownhomePersonParticipantOptionGroup.IMPORT_FROM_CHECKLIST,
            _key: owner.key,
            context: owner,
          });
        });
      }

      if (inputValue !== "") {
        const splitValue = inputValue.split(" ");

        let firstName = "";
        let lastName = "";

        if (splitValue.length === 1) {
          lastName = splitValue[0];
        } else {
          firstName = splitValue[0];
          lastName = splitValue.slice(1).join(" ");
        }

        filtered.push({
          first_name: firstName,
          last_name: lastName,
          label: `Add "${inputValue}"`,
          _groupKey: EDownhomePersonParticipantOptionGroup.ADD_NEW,
          _key: "",
        });
      }

      return filtered;
    },
    [initialOwners]
  );

  if (fetchingPersonData) {
    return (
      <Stack spacing={2} width="100%" direction="row">
        <Stack spacing={2} width="100%">
          <Stack spacing={2} direction="row">
            <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
            <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
          </Stack>
          <Stack spacing={2} direction="row">
            <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
            <Skeleton sx={{ transform: "none" }} width="25%" height={50} />
          </Stack>
          <Stack spacing={2} direction="row">
            <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
            <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
          </Stack>
        </Stack>
        <Stack width={"13%"}>
          <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
        </Stack>
      </Stack>
    );
  }

  if (showSearch) {
    return isLoading ? (
      <Skeleton sx={{ transform: "none" }} width="100%" height={50} />
    ) : (
      <Autocomplete
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        PaperComponent={AutocompletePaperComponent}
        renderInput={(params) => <TextField {...params} label="" />}
        onChange={handleChange}
        renderOption={(props, option: IDownhomePersonParticipantOption) => (
          <li
            {...props}
            key={
              _.isUndefined(option.external_identifier)
                ? option._key
                : option.external_identifier
            }
          >
            {option.label}
          </li>
        )}
        options={options}
        groupBy={(option) => option._groupKey}
        filterOptions={handleFilterOptions}
      />
    );
  }

  return (
    <Button
      onClick={handleButtonClick}
      variant="outlined"
      color="secondary"
      startIcon={<AddIcon />}
    >
      Add Owner
    </Button>
  );
};

export default AddOwnerButton;

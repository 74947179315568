import { memo, useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Divider from "components/Divider";
import Stack from "components/Stack";
import Text from "components/Text";

import { getFilesValuesHistory } from "entities/Field/utils";
import { IFieldValueHistoryItem } from "entities/Package/sdk";

export interface IFileFieldHistory {
  values: Array<IFieldValueHistoryItem> | undefined;
}

const FileFieldHistory = ({ values }: IFileFieldHistory) => {
  const valuesHistory = useMemo(() => getFilesValuesHistory(values), [values]);

  return (
    <>
      {valuesHistory.map((value, index) => (
        <Stack key={`file-history-${index}`} data-testid="field-history-entity">
          <Text
            variant="text2"
            sx={{ color: colors.gray60, width: 600 }}
            data-testid="entity-title"
          >
            <b>{value.user}</b> {value.action} file at {value.timestamp}
          </Text>
          <Text
            variant="h6"
            title={value.file}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 600,
              padding: theme.spacing(0.5),
            }}
          >
            {value.file || "(deleted)"}
          </Text>
          <Divider />
        </Stack>
      ))}
    </>
  );
};

export default memo(FileFieldHistory);

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import PusherManager from "utils/pusher-manager";
import { reverse } from "utils/urls";

import Alert from "components/Alert";
import Box from "components/Box";
import { LinearProgress } from "components/Loading";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import { useSignedUser } from "entities/Auth/sdk";
import { useDealDataFileUrl, useDealDetails } from "entities/Deal/sdk";
import { useDealPermission } from "entities/Deal/sdk";
import DevelopmentBudgetWrapper from "entities/Proforma/components/DevelopmentBudgetWrapper";
import ProformaPageHeader from "entities/Proforma/components/PageHeader";
import ProformaIncomeAndOpex from "entities/Proforma/components/ProformaIncomeAndOpex";
import ProformaPLProjections from "entities/Proforma/components/ProformaPLProjections";
import ProformaRentalIncomeWrapper from "entities/Proforma/components/ProformaRentalIncomeWrapper";
import ProformaSourcesAndUses from "entities/Proforma/components/ProformaSourcesAndUses";
import ProformaTabs from "entities/Proforma/components/Tabs";
import UploadExcelForm from "entities/Proforma/components/UploadExcelForm";
import UploadWarning from "entities/Proforma/components/UploadWarning";
import { ProformaSubNavItems } from "entities/Proforma/constants";
import { useProformaForDeal } from "entities/Proforma/sdk";

const Proforma = () => {
  const { data: user } = useSignedUser();

  const [isLoading, setIsLoading] = useState(false);

  const params = useParams<{
    tab: string;
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  const navigate = useNavigate();
  const { data: userPermission } = useDealPermission({ dealId });

  const { data: deal, mutate: mutateDeal } = useDealDetails({ dealId });
  const { data: dealData, mutate: mutateDealData } = useDealDataFileUrl(dealId);

  usePageTitle(
    deal?.name
      ? `Builders Patch: ${deal.name} - Proforma ${
          params.tab && `- ${_.startCase(params.tab)}`
        }`
      : "Builders Patch: Proforma"
  );

  const { data: proforma, mutate: mutateProforma } = useProformaForDeal({
    dealId,
  });

  const handleUploadData = useCallback(() => {
    setIsLoading(true);
    mutateDeal();
  }, [mutateDeal]);

  const eventHandler = useCallback(
    ({ dealId }: { dealId: number }) => {
      if (dealId === deal?.id) {
        toast.successMessage(
          `The proforma file for the deal "${deal?.name}" was uploaded!`
        );
        Promise.all([mutateProforma(), mutateDealData()]).then(() =>
          setIsLoading(false)
        );
      }
    },
    [mutateProforma, mutateDealData, deal]
  );

  useEffect(() => {
    if (user) {
      return PusherManager.bindToUnderwritingProformaReadyEvent({
        userId: user.id,
        eventHandler: ({ deal_id }: { deal_id: number }) =>
          eventHandler({
            dealId: deal_id,
          }),
      });
    }
  }, [eventHandler, user]);

  const showInitialUpload = useMemo(() => {
    if (!_.isUndefined(dealData) && !_.isUndefined(proforma)) {
      const allProformaDataIsEmpty = _.every([
        _.isEmpty(proforma.core_data),
        _.isEmpty(proforma.budget_data),
        _.isEmpty(proforma.income_operating_cost),
      ]);
      const someProformaDataIsEmpty = _.some([
        _.isEmpty(proforma.core_data),
        _.isEmpty(proforma.budget_data),
        _.isEmpty(proforma.income_operating_cost),
      ]);
      return (
        _.isEmpty(dealData.file_url) ||
        (!_.isEmpty(proforma.error) && allProformaDataIsEmpty) ||
        (!_.isEmpty(proforma.error) && someProformaDataIsEmpty) ||
        (!_.isEmpty(dealData.file_url) && allProformaDataIsEmpty)
      );
    }
    return true;
  }, [dealData, proforma]);

  useEffect(() => {
    // Redirect to the first tab
    if (!params.tab) {
      navigate(
        reverse(URLS.DEAL_PROFORMA_TAB, {
          dealId,
          tab: ProformaSubNavItems.SOURCE_AND_USES,
        })
      );
    }
  }, [dealId, params, navigate]);

  if (!proforma || !deal || !dealData) {
    return null;
  }

  return (
    <Box>
      <Paper
        sx={{
          padding: theme.spacing(3, 4),
        }}
      >
        {dealData.warning_messages.length > 0 && (
          <UploadWarning
            warnings={dealData.warning_messages}
            uploadAlertDataTestid="proforma-upload-warning-alert"
          />
        )}
        {proforma.error && (
          <Alert
            severity="error"
            sx={{ marginBottom: theme.spacing(2) }}
            data-testid="proforma-upload-error-alert"
          >
            There are errors in the last uploaded file!{" "}
            {!showInitialUpload &&
              "The data shown is from the last successfully uploaded file."}
          </Alert>
        )}
        {isLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}
        {showInitialUpload ? (
          <Paper sx={{ paddingY: theme.spacing(3) }}>
            <Stack>
              <Text
                variant="h3"
                textAlign="center"
                data-testid="no-proforma-uploaded"
              >
                No Data Present, Please Upload Excel
              </Text>
              <UploadExcelForm dealId={dealId} onUpload={handleUploadData} />
            </Stack>
          </Paper>
        ) : (
          <Stack spacing={2}>
            <ProformaPageHeader
              dealId={deal.id}
              dealName={deal.name}
              userPersona={userPermission?.persona}
              dealData={dealData}
              onUploadData={handleUploadData}
              onCreateSnapshot={mutateDealData}
            />
            <ProformaTabs />

            {params.tab === ProformaSubNavItems.SOURCE_AND_USES &&
              !_.isNil(proforma.core_data) && (
                <ProformaSourcesAndUses coreData={proforma.core_data} />
              )}
            {params.tab === ProformaSubNavItems.DEVELOPMENT_BUDGET && (
              <DevelopmentBudgetWrapper proforma={proforma} />
            )}
            {params.tab === ProformaSubNavItems.RENTAL_INCOME && (
              <ProformaRentalIncomeWrapper
                dealId={dealId}
                totalUnits={proforma.core_data?.total_units}
              />
            )}
            {params.tab === ProformaSubNavItems.INCOME_AND_OPEX && (
              <ProformaIncomeAndOpex
                incomeOperatingCost={proforma?.income_operating_cost}
                totalUnits={proforma.core_data?.total_units}
              />
            )}
            {params.tab === ProformaSubNavItems.PL_PROJECTIONS && (
              <ProformaPLProjections dealId={dealId} />
            )}
          </Stack>
        )}
      </Paper>
    </Box>
  );
};

export default Proforma;

import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import ListPagination from "components/ListPagination";
import PageHeader from "components/PageHeader";
import ScrollableTable from "components/ScrollableTable";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import ExportButton from "entities/Application/components/ExportButton";
import {
  APPLICATION_STATUS,
  LENDER_HEADERS,
} from "entities/Application/constants";
import {
  ILenderApplicationWithRefetch,
  useLenderApplications,
} from "entities/Application/sdk";

const APPLICATION_STATUSES = [
  { value: APPLICATION_STATUS.SUBMITTED, label: "Active" },
  { value: APPLICATION_STATUS.ARCHIVED, label: "Archived" },
  { value: APPLICATION_STATUS.REJECTED, label: "Inactive" },
  { value: APPLICATION_STATUS.APPROVED, label: "In deal pipeline" },
];

const ApplicationLenderList = () => {
  const navigate = useNavigate();

  const { status } = useParams<{ status: string }>();

  usePageTitle("Builders Patch: Intake - Reviewer dashboard");

  const handlechangeStatus = useCallback(
    (event: any) => {
      navigate(
        reverse(URLS.APPLICATIONS_LENDER_LIST, { status: event.target.value })
      );
    },
    [navigate]
  );

  const {
    data: applicationsData,
    pagesCount,
    setPage,
    mutate: applicationsRefetch,
  } = useLenderApplications({
    status: status as string,
  });

  const applications: Array<ILenderApplicationWithRefetch> = useMemo(() => {
    return (
      applicationsData?.results?.map((application) => ({
        ...application,
        refetch: applicationsRefetch,
      })) || []
    );
  }, [applicationsData, applicationsRefetch]);

  return (
    <Stack spacing={2} sx={{ padding: theme.spacing(4, 3, 0), flex: 1 }}>
      <PageHeader
        title="Intake: Reviewer dashboard"
        actions={
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            sx={{ flex: 1 }}
          >
            <Select
              size="small"
              sx={{ width: "154px" }}
              inputProps={{
                sx: {
                  backgroundColor: "white",
                },
              }}
              label="STATUS"
              options={APPLICATION_STATUSES}
              onChange={handlechangeStatus}
              value={status}
            />

            <ExportButton isLenderExport applications={applications} />
          </Stack>
        }
      />

      <Text
        variant="text3"
        sx={{
          color: colors.gray60,
        }}
      >
        Showing {applications?.length} of {applicationsData?.count} intakes.
      </Text>

      <Box sx={{ width: "100%" }}>
        <ScrollableTable
          headers={LENDER_HEADERS}
          rows={applications}
          expectedResultsCount={10}
        />
      </Box>
      <ListPagination setPage={setPage} pagesCount={pagesCount} />
    </Stack>
  );
};

export default ApplicationLenderList;

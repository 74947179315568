import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import { BASE_URL } from "config/urls";
import { ENV_NAME, Environment, SENTRY_DSN } from "utils/constants";

import App from "./App";

if (ENV_NAME !== Environment.Local) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: ENV_NAME,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [`${BASE_URL}.*`],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

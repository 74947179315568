import { useCallback } from "react";

import { ClearIcon } from "icons";
import { colors } from "theme/palette";

import IconButton from "components/IconButton";
import { TableCell } from "components/Table";
import { useConfirmationDialog } from "components/useConfirmationDialog";

interface IDeleteCell {
  onClear: () => void;
}

const DeleteCell = ({ onClear }: IDeleteCell) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const onClearRow = useCallback(() => onClear(), [onClear]);

  const handleClear = useCallback(() => {
    showConfirmationDialog({
      onConfirm: onClearRow,
      confirmButtonText: "Clear",
      message: "Are you sure you want to clear this row?",
    });
  }, [onClearRow, showConfirmationDialog]);

  return (
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
      data-testid="table-cell-clear-row"
    >
      <IconButton
        size="small"
        onClick={handleClear}
        title="Clear row"
        dataTestid="clear-table-row-button"
      >
        <ClearIcon sx={{ width: "20px", height: "20px" }} />
      </IconButton>
    </TableCell>
  );
};

export default DeleteCell;

import PageHeader from "components/PageHeader";
import Stack from "components/Stack";

import CustomReportExportButton from "entities/Reporting/components/CustomReportExportButton";
import FiltersButton from "entities/Reporting/components/FiltersButton";
import ReportSearchField from "entities/Reporting/components/ReportSearchField";
import SelectedFilters from "entities/Reporting/components/SelectedFilters";
import {
  INonPipelineColumns,
  ISimpleReportPackage,
} from "entities/Reporting/sdk";

export interface ICustomReportPageHeader {
  reportPackage: ISimpleReportPackage;
  rows: { [key: string]: string | null }[];
  columns: INonPipelineColumns[];
  loading: boolean;
}

const CustomReportPageHeader = ({
  reportPackage,
  rows,
  columns,
  loading,
}: ICustomReportPageHeader) => (
  <>
    <PageHeader
      title={reportPackage.name}
      actions={
        <Stack
          spacing={2}
          direction="row"
          sx={{ flex: 1 }}
          justifyContent="flex-end"
        >
          <ReportSearchField placeholder="Search by deal name" />
          <FiltersButton
            columns={columns}
            reportPackageId={reportPackage.id}
            disabled={rows.length === 0 && loading}
            rows={rows}
            filtersButtonDataTestid="filter-deals-button"
          />
          <CustomReportExportButton
            reportPackage={reportPackage}
            rows={rows}
            columns={columns}
          />
        </Stack>
      }
    />
    <SelectedFilters
      columns={columns}
      reportPackageId={reportPackage.id}
      isHardcodedReport={false}
    />
  </>
);

export default CustomReportPageHeader;

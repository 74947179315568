export enum OwnershipType {
  OWNER = "Owner",
  COLLABORATOR = "Collaborator",
}

export enum States {
  ALABAMA = "ALABAMA - AL",
  ALASKA = "ALASKA - AK",
  ARIZONA = "ARIZONA - AZ",
  ARKANSAS = "ARKANSAS - AR",
  CALIFORNIA = "CALIFORNIA - CA",
  COLORADO = "COLORADO - CO",
  CONNECTICUT = "CONNECTICUT - CT",
  DELAWARE = "DELAWARE - DE",
  FLORIDA = "FLORIDA - FL",
  GEORGIA = "GEORGIA - GA",
  HAWAII = "HAWAII - HI",
  IDAHO = "IDAHO - ID",
  ILLINOIS = "ILLINOIS - IL",
  INDIANA = "INDIANA - IN",
  IOWA = "IOWA - IA",
  KANSAS = "KANSAS - KS",
  KENTUCKY = "KENTUCKY - KY",
  LOUISIANA = "LOUISIANA - LA",
  MAINE = "MAINE - ME",
  MARYLAND = "MARYLAND - MD",
  MASSACHUSETTS = "MASSACHUSETTS - MA",
  MICHIGAN = "MICHIGAN - MI",
  MINNESOTA = "MINNESOTA - MN",
  MISSISSIPPI = "MISSISSIPPI - MS",
  MISSOURI = "MISSOURI - MO",
  MONTANA = "MONTANA - MT",
  NEBRASKA = "NEBRASKA - NE",
  NEVADA = "NEVADA - NV",
  NEW_HAMPSHIRE = "NEW HAMPSHIRE - NH",
  NEW_JERSEY = "NEW JERSEY - NJ",
  NEW_MEXICO = "NEW MEXICO - NM",
  NEW_YORK = "NEW YORK - NY",
  NORTH_CAROLINA = "NORTH CAROLINA - NC",
  NORTH_DAKOTA = "NORTH DAKOTA - ND",
  OHIO = "OHIO - OH",
  OKLAHOMA = "OKLAHOMA - OK",
  OREGON = "OREGON - OR",
  PENNSYLVANIA = "PENNSYLVANIA - PA",
  RHODE_ISLAND = "RHODE ISLAND - RI",
  SOUTH_CAROLINA = "SOUTH CAROLINA - SC",
  SOUTH_DAKOTA = "SOUTH DAKOTA - SD",
  TENNESSE = "TENNESSEE- TN",
  TEXAS = "TEXAS - TX",
  UTAH = "UTAH - UT",
  VERMONT = "VERMONT - VT",
  VIRGINIA = "VIRGINIA - VA",
  WASHINGTON = "WASHINGTON - WA",
  WEST_VIRGINIA = "WEST VIRGINIA - WV",
  WISCONSIN = "WISCONSIN - WI",
  WYOMING = "WYOMING - WY",
  AMERICAN_SAMOA = "AMERICAN SAMOA - AS",
  DISTRICT_OF_COLUMBIA = "DISTRICT OF COLUMBIA - DC",
  FEDERATED_STATES_OF_MICRONESIA = "FEDERATED STATES OF MICRONESIA - FM",
  GUAM = "GUAM - GU",
  MARSHALL_ISLANDS = "MARSHALL ISLANDS - MH",
  NORTHERN_MARIANA_ISLANDS = "NORTHERN MARIANA ISLANDS - MP",
  PALAU = "PALAU - PW",
  PUERTO_RICO = "PUERTO RICO - PR",
  VIRGIN_ISLANDS = "VIRGIN ISLANDS - VI",
}

export enum DealStatus {
  PROSPECT = "Prospect",
  ACTIVE = "Active",
  ARCHIVED = "Archived",
  DORMANT = "Dormant",
  CLOSED = "Closed",
}

export enum PropertyType {
  MULTIFAMILY_RESIDENTIAL = "MULTIFAMILY RESIDENTIAL",
  RESIDENTIAL = "RESIDENTIAL",
  MIXED_USE = "MIXED USE",
  COMMERCIAL = "COMMERCIAL",
  RETAIL = "RETAIL",
}

export enum ConstructionType {
  NEW_CONSTRUCTION = "new_construction",
  MODERATE_REHABILITATION = "moderate_rehabilitation",
  SUBSTANTIAL_REHABILITATION = "substantial_rehabilitation",
}

export enum RentTypes {
  AFFORDABLE_HOUSING = "Affordable Housing",
  MIXED_INCOME = "Mixed Income",
  MARKET_RATE = "Market Rate",
}

export enum Phase {
  APPLICATION = "Application",
  UNDERWRITING = "Underwriting",
  CONSTRUCTION = "Construction",
  CONVERSION = "Conversion",
  ASSET_MANAGEMENT = "Asset-Management",
}

export enum WorkspaceSubNavItems {
  TASKS = "tasks",
  TEAMS = "teams",
}

export enum ProjectsViewType {
  grid = "grid",
  list = "list",
  map = "map",
}

export const OPEN_SIDEPANEL_WIDTH = 800;
export const CLOSED_SIDEPANEL_WIDTH = 64;

export const PROPERTY_TYPES = [
  {
    value: PropertyType.MULTIFAMILY_RESIDENTIAL,
    label: "Multifamily Residential",
  },
  { value: PropertyType.RESIDENTIAL, label: "Residential" },
  { value: PropertyType.MIXED_USE, label: "Mixed Use" },
  { value: PropertyType.COMMERCIAL, label: "Commercial" },
  { value: PropertyType.RETAIL, label: "Retail" },
];

export const CONSTRUCTION_TYPES = [
  {
    value: ConstructionType.NEW_CONSTRUCTION,
    label: "New Construction",
  },
  {
    value: ConstructionType.MODERATE_REHABILITATION,
    label: "Moderate Rehabilitation",
  },
  {
    value: ConstructionType.SUBSTANTIAL_REHABILITATION,
    label: "Substantial Rehabilitation",
  },
];

export const RENT_TYPES = [
  { value: RentTypes.AFFORDABLE_HOUSING, label: RentTypes.AFFORDABLE_HOUSING },
  { value: RentTypes.MIXED_INCOME, label: RentTypes.MIXED_INCOME },
  { value: RentTypes.MARKET_RATE, label: "Market-rate" },
];

export const SEARCH_KEYS = [
  "project name",
  "city",
  "state",
  "zip",
  "zipcode",
  "zip code",
  "program type",
  "project manager",
  "target closing date",
  "borough",
  "developer 1",
  "developer 2",
  "developer 3",
  "primary address",
  "developer counsel",
  "lc bank",
  "lc bank counsel",
  "lihtc investor",
  "lihtc investor counsel",
  "general counsel",
  "general contractor",
];

export const DEAL_ACCESS_DENIED_MESSAGE =
  "You do not have access to this deal.";

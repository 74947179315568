import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Router from "config/routes";
import theme from "theme";
import { getEnvironmentVariables } from "utils/environment";

import GoogleMapWrapper from "components/GoogleMapWrapper";
import NoPrint from "components/NoPrint";
import PendoWidget from "components/PendoWidget";
import { ToastContainer } from "components/Toast";
import { ConfirmationDialogContextProvider } from "components/useConfirmationDialog";
import { LoadingContextProvider } from "components/useLoadingBackdrop";

const App: React.FC<{}> = () => {
  const googleApiKey = getEnvironmentVariables().VITE_GOOGLE_API_KEY;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PendoWidget />
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <LoadingContextProvider>
          <ConfirmationDialogContextProvider>
            <GoogleMapWrapper apiKey={googleApiKey || ""}>
              <Router />
            </GoogleMapWrapper>
          </ConfirmationDialogContextProvider>
          <NoPrint>
            <ToastContainer />
          </NoPrint>
        </LoadingContextProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default App;

import _ from "lodash";

import NoPrint from "components/NoPrint";
import Stack from "components/Stack";

import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import NoProformaUploaded from "entities/Memo/components/Sections/NoProformaUploaded";
import SectionSummary from "entities/Memo/components/Sections/Summary";
import { IMemoSection } from "entities/Memo/sdk";
import { useProformaForDealManager } from "entities/Memo/utils";
import IncomeAndOpexTable from "entities/Proforma/components/ProformaIncomeAndOpex/IncomeAndOpexTable";

interface ISectionIncomeAndOpex {
  dealId: number;
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}

const SectionIncomeAndOpex: React.FC<ISectionIncomeAndOpex> = ({
  dealId,
  externalToken,
  section,
  onUpdate,
}) => {
  const { data: proforma } = useProformaForDealManager({
    token: externalToken,
    dealId,
  });

  return (
    <>
      {proforma &&
      proforma.core_data &&
      !_.isNull(proforma.income_operating_cost) ? (
        <Stack spacing={2}>
          {section && _.isUndefined(externalToken) && (
            <NoPrint
              sx={{
                alignSelf: "flex-end",
              }}
            >
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )}
          {section?.is_na ? (
            <NASection />
          ) : (
            <Stack justifyContent="space-between" spacing={3}>
              <IncomeAndOpexTable
                incomeOperatingCost={proforma.income_operating_cost}
                totalUnits={proforma.core_data.total_units}
                showChart={false}
              />
              <SectionSummary
                title="Income &amp; OPEX description"
                category="Incone Descriptions"
                dealId={dealId}
                externalToken={externalToken}
              />
            </Stack>
          )}
        </Stack>
      ) : (
        <NoProformaUploaded title="Income &amp; OPEX" />
      )}
    </>
  );
};

export default SectionIncomeAndOpex;

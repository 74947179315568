import { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import _ from "lodash";

import theme from "theme";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Text from "components/Text";
import toast from "components/Toast";

import { IFieldTemplateMeta } from "entities/Checklist/sdk";
import FieldValue from "entities/Field/components/FormTextField/FieldValue";
import LinkedField from "entities/Field/components/FormTextField/LinkedField";
import { fieldValueCreate } from "entities/Field/sdk";

import { FIELD_PLACEHOLDER_REGEX } from "./constants";
import { getFieldIdFromPlaceholder } from "./utils";

interface IFormTextInput {
  edit: boolean;
  onUpdate: () => void;
  dealId: number;
  template: string;
  templateMeta: Array<IFieldTemplateMeta> | undefined;
  fieldNameDataTestid?: string;
}

const FormTextInput = ({
  edit,
  template,
  templateMeta,
  dealId,
  onUpdate,
  fieldNameDataTestid,
}: IFormTextInput) => {
  const handleSave = useCallback(
    ({
      fieldId,
      value,
      fieldName,
    }: {
      fieldId: number;
      value: string | number | null | undefined;
      fieldName: string;
    }) => {
      fieldValueCreate({
        dealId,
        companyCustomFieldId: fieldId,
        value,
      })
        .then(() => {
          onUpdate();
          toast.successMessage(`${fieldName} successfully updated!`);
        })
        .catch(handleInvalidRequest);
    },
    [dealId, onUpdate]
  );

  const formData = useMemo(
    () =>
      templateMeta?.map((field) => ({ [field.field_id]: field.value })) || {},
    [templateMeta]
  );

  const formik = useFormik({
    initialValues: formData,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  // Parse the template and replace the placeholders with components
  const parseAndReplaceWithComponents = useCallback(
    ({ text }: { text: string }) => {
      let lastIndex = 0;
      // Components to be rendered
      const components: React.ReactNode[] = [];

      // Find the first match of the regex
      let match = FIELD_PLACEHOLDER_REGEX.exec(text);

      while (match !== null) {
        const placeholder = match[0];
        const beforeText = text.slice(lastIndex, match.index);
        // Add the text before the match
        components.push(beforeText);

        // Get the field id from the placeholder
        const fieldId = getFieldIdFromPlaceholder({ placeholder });
        const fieldTemplateMeta = _.find(
          templateMeta,
          (field) => field.field_id === fieldId
        );

        // If the field template meta for the current fieldId is undefined, continue to the next match
        if (_.isUndefined(fieldTemplateMeta)) {
          components.push(
            <Text
              fontWeight="bold"
              display="inline-block"
              data-testid="missing-field"
            >
              Missing field
            </Text>
          );
        }
        // If the field is editable, render the linked field for the fieldId
        // Else render the field value - the selected field value or a component for empty field value
        else if (edit) {
          components.push(
            <LinkedField
              formik={formik}
              templateMeta={fieldTemplateMeta}
              onChange={({
                updatedValue,
                fieldName,
              }: {
                updatedValue: string | number | null | undefined;
                fieldName: string;
              }) =>
                handleSave({
                  fieldId: fieldId,
                  value: updatedValue,
                  fieldName,
                })
              }
            />
          );
        } else {
          components.push(<FieldValue templateMeta={fieldTemplateMeta} />);
        }

        // Update the lastIndex with the end of the match
        lastIndex = match.index + match[0].length;
        // Find the next match
        match = FIELD_PLACEHOLDER_REGEX.exec(text);
      }

      // Add the remaining text after the match
      const remainingText = text.slice(lastIndex);
      components.push(remainingText);

      // Return the components
      return components;
    },
    [formik, edit, templateMeta, handleSave]
  );

  const advancedParse = useCallback(
    ({ htmlString }: { htmlString: string }) => {
      const options = {
        replace: ({ data }: any) => {
          if (!_.isNil(data) && data !== "") {
            return <>{parseAndReplaceWithComponents({ text: data })}</>;
          }
        },
      } as HTMLReactParserOptions;

      return parse(htmlString, options);
    },
    [parseAndReplaceWithComponents]
  );

  const toRender = useMemo(
    () => advancedParse({ htmlString: template }),
    [template, advancedParse]
  );

  return (
    <Box sx={{ marginY: theme.spacing(3) }} data-testid={fieldNameDataTestid}>
      <Text>{toRender}</Text>
    </Box>
  );
};

export default FormTextInput;

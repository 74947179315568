import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import Box from "components/Box";
import { usePageTitle } from "components/usePageTitle";

import WorkspaceTasks from "pages/Deal/Workspace/Tasks";

import { WorkspaceSubNavItems } from "entities/Deal/constants";
import { useDealDetails } from "entities/Deal/sdk";

import TeamMembers from "./TeamMembers";

interface IWorkspace {}

const Workspace: React.FC<IWorkspace> = () => {
  const params = useParams<{
    dealId: string;
    tab: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });

  usePageTitle(
    deal?.name
      ? `Builders Patch: ${deal.name} - Workspace - ${_.upperFirst(params.tab)}`
      : "Builders Patch: Workspace"
  );

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the first tab
    if (!params.tab) {
      navigate(
        reverse(URLS.DEAL_WORKSPACE_TAB, {
          dealId,
          tab: "tasks",
        })
      );
    }
  }, [dealId, navigate, params]);

  return (
    <Box>
      {params.tab === WorkspaceSubNavItems.TASKS && (
        <WorkspaceTasks
          dealId={dealId}
          dealPartiallyAccessible={deal?.is_partially_accessible}
        />
      )}
      {params.tab === WorkspaceSubNavItems.TEAMS && <TeamMembers />}
    </Box>
  );
};

export default Workspace;

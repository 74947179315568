import React, { useMemo } from "react";
import { styled, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { toImgixUrl } from "utils/urls";

interface IImage
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  imgix?: boolean;
  imgixWidth?: number;
  imgixHeight?: number;
  sx?: SxProps<Theme>;
  imageDataTestid?: string;
}

const StyledImage = styled("img")<IImage>(({ sx }) => ({ "&": sx }));

const Image = ({
  src,
  alt,
  imgix = false,
  imgixWidth,
  imgixHeight,
  imageDataTestid,
  ...rest
}: IImage) => {
  const imgixUrl = useMemo(() => {
    if (imgix) {
      return src ? toImgixUrl(src) + `?&w=${imgixWidth}&h=${imgixHeight}` : src;
    }
    return src;
  }, [src, imgixWidth, imgixHeight, imgix]);

  return (
    <StyledImage
      src={imgixUrl || src}
      alt={alt || src}
      data-testid={imageDataTestid}
      {...rest}
    />
  );
};

export default Image;

import React from "react";

import DealAvatar from "components/DealAvatar";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import { IDeal } from "entities/Deal/sdk";

export interface IDealInfo {
  deal?: Omit<IDeal, "is_partially_accessible">;
}

const DealInfoSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Skeleton variant="rectangular" width={40} height={40} />
      <Stack>
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={100} />
      </Stack>
    </Stack>
  );
};

const DealInfo: React.FC<IDealInfo> = ({ deal }) => {
  if (!deal) {
    return <DealInfoSkeleton />;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <DealAvatar
        src={deal.thumbnail}
        imgix
        imgixWidth={40}
        imgixHeight={40}
        sx={{ width: "40px", height: "40px" }}
      />
      <Stack alignItems="flex-start">
        <Text variant="text2" fontWeight="700" data-testid="sub-menu-deal-name">
          {deal.name}
        </Text>
        {deal.phase && (
          <Tag label={deal.phase?.name} dataTestid="sub-menu-deal-phase" />
        )}
      </Stack>
    </Stack>
  );
};

export default DealInfo;

import Stack from "components/Stack";

import SectionSummary from "entities/MemoTemplate/components/Sections/SectionSummary";
import { proformaDemoData } from "entities/MemoTemplate/components/Sections/utils";
import ProfromaUnitMixAndRent from "entities/Proforma/components/ProfromaUnitMixAndRent";

const MemoTemplateUnitMixAndRent = () => {
  return (
    <Stack justifyContent="space-between" spacing={4}>
      <ProfromaUnitMixAndRent rentalIncomeData={proformaDemoData.rental_data} />
      <SectionSummary title="Unit Mix &amp; Rent description" />
    </Stack>
  );
};

export default MemoTemplateUnitMixAndRent;

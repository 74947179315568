import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Autocomplete, {
  AutocompletePaperComponent,
} from "components/Autocomplete";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import InputLabel from "components/InputLabel";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import { ICompanyAutocompleteOption, useCompanies } from "entities/Company/sdk";

import { VALIDATION_SCHEMA } from "./constants";

export interface IFormValues {
  first_name: string;
  last_name: string;
  company_name: string;
  company_domain: string | null;
}

export interface IShareAcceptanceForm {
  selectedCompany: ICompanyAutocompleteOption | null;
  handleSubmit: ({ values }: { values: IFormValues }) => void;
}

const OnboardingForm = ({
  handleSubmit,
  selectedCompany,
}: IShareAcceptanceForm) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [isAddingCompany, setIsAddingCompany] = useState(false);
  const [companySearchParam, setCompanySearchParam] = useState("");
  const formik = useFormik<IFormValues>({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: _.get(selectedCompany, "name", ""),
      company_domain: _.get(selectedCompany, "domain", null),
    },
    onSubmit: (values) => handleSubmit({ values }),
    validationSchema: VALIDATION_SCHEMA,
  });

  const { data: companyOptions } = useCompanies({ name: companySearchParam });

  const evaluatedCompanyOptions = useMemo(() => {
    // If the company option is coming from the props we should only provide it as an option.
    if (!_.isNil(selectedCompany)) {
      return [selectedCompany];
    }

    // Otherwise we should provide all the options that are coming from the API.
    return companyOptions?.results || [];
  }, [selectedCompany, companyOptions?.results]);

  const handleCompanySearchParamChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setCompanySearchParam(event.target.value);
  };

  const toggleTerms = () => {
    setHasAcceptedTerms((prevValue) => !prevValue);
  };

  const toggleCompanyAddition = () => {
    formik.setFieldValue("company_name", "");
    formik.setFieldValue("company_domain", "");
    formik.setFieldTouched("company_name", false);
    formik.setFieldTouched("company_domain", false);
    setIsAddingCompany((prevValue) => !prevValue);
  };

  return (
    <form onSubmit={formik.handleSubmit} data-testid="signup-form">
      <Stack direction="row" spacing={3} marginBottom={4}>
        <Stack width="100%">
          <InputLabel
            sx={{
              color: colors.black,
              fontWeight: 500,
              marginBottom: theme.spacing(0.5),
            }}
            data-testid="first-name-label"
          >
            First name
          </InputLabel>
          <TextField
            variant="outlined"
            placeholder="First name"
            {...formik.getFieldProps("first_name")}
            error={formik.touched.first_name && !!formik.errors.first_name}
            helperText={
              formik.touched.first_name && formik.errors.first_name
                ? formik.errors.first_name
                : ""
            }
            data-testid="first-name"
            inputProps={{ "data-testid": "first-name-input" }}
          />
        </Stack>
        <Stack width="100%">
          <InputLabel
            sx={{
              color: colors.black,
              fontWeight: 500,
              marginBottom: theme.spacing(0.5),
            }}
            data-testid="last-name-label"
          >
            Last name
          </InputLabel>
          <TextField
            variant="outlined"
            placeholder="Last name"
            {...formik.getFieldProps("last_name")}
            error={formik.touched.last_name && !!formik.errors.last_name}
            helperText={
              formik.touched.last_name && formik.errors.last_name
                ? formik.errors.last_name
                : ""
            }
            data-testid="last-name"
            inputProps={{ "data-testid": "last-name-input" }}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={3}>
        <Stack width="100%">
          <InputLabel
            sx={{
              color: colors.black,
              fontWeight: 500,
              marginBottom: theme.spacing(0.5),
            }}
            data-testid="company-name-label"
          >
            Company name
          </InputLabel>
          {isAddingCompany ? (
            <TextField
              {...formik.getFieldProps("company_name")}
              placeholder="Company name"
              sx={{ width: "100%" }}
              error={
                formik.touched.company_name && !!formik.errors.company_name
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
                  ? formik.errors.company_name
                  : ""
              }
              data-testid="new-company-name"
              inputProps={{ "data-testid": "new-company-name-input" }}
            />
          ) : (
            <Autocomplete
              disableClearable
              disabled={!_.isNil(selectedCompany)}
              PaperComponent={AutocompletePaperComponent}
              filterOptions={(x) => x} // By passing (x) => x to filterOptions we are disabling the filtering that comes out of Autocomplete
              value={
                _.find(evaluatedCompanyOptions, {
                  name: formik.values.company_name,
                }) as ICompanyAutocompleteOption | undefined
              }
              options={evaluatedCompanyOptions}
              onChange={(event, value: ICompanyAutocompleteOption | null) => {
                if (!_.isNil(value)) {
                  Promise.all([
                    formik.setFieldValue("company_name", value.name),
                    formik.setFieldValue("company_domain", value.domain),
                  ]).then(() => {
                    formik.setFieldTouched("company_name", true);
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={handleCompanySearchParamChange}
                  placeholder="Company name"
                  error={
                    formik.touched.company_name && !!formik.errors.company_name
                  }
                  helperText={
                    formik.touched.company_name && formik.errors.company_name
                      ? formik.errors.company_name
                      : ""
                  }
                  data-testid="company-name"
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "company-name-input",
                  }}
                />
              )}
              getOptionLabel={(option: ICompanyAutocompleteOption) =>
                option.name
              }
            />
          )}
        </Stack>
        <Stack width="100%">
          <InputLabel
            sx={{
              color: colors.black,
              fontWeight: 500,
              marginBottom: theme.spacing(0.5),
            }}
            data-testid="company-domain-label"
          >
            Company domain
          </InputLabel>

          <TextField
            {...formik.getFieldProps("company_domain")}
            placeholder="Company domain"
            disabled={!isAddingCompany}
            data-testid="company-domain"
            inputProps={{ "data-testid": "company-domain-input" }}
          />
        </Stack>
      </Stack>

      {isAddingCompany && (
        <Button
          sx={{ marginTop: 1, alignSelf: "flex-start" }}
          size="small"
          color="secondary"
          onClick={toggleCompanyAddition}
          data-testid="select-existing-company-button"
        >
          Select company from list
        </Button>
      )}

      {!isAddingCompany && _.isNil(selectedCompany) && (
        <Button
          sx={{ marginTop: 1, alignSelf: "flex-start" }}
          size="small"
          color="secondary"
          onClick={toggleCompanyAddition}
          data-testid="add-new-company-button"
        >
          My company is not listed
        </Button>
      )}
      <Stack
        marginTop={6}
        marginBottom={6}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={hasAcceptedTerms}
            sx={{ backgrouncColor: colors.white }}
            onClick={toggleTerms}
            data-testid="accept-terms-checkbox"
          />
          <Text data-testid="accept-terms-text">
            By signing up, I accept the Builders Patch{" "}
            <Link
              external
              target="_blank"
              to="https://builderspatch.com/terms"
              style={{ color: colors.blue100 }}
              data-testid="terms-link"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              external
              target="_blank"
              to="https://builderspatch.com/privacy"
              style={{ color: colors.blue100 }}
              data-testid="privacy-link"
            >
              Privacy Policy
            </Link>
            .
          </Text>
        </Stack>

        <Button
          type="submit"
          disabled={!_.isEmpty(formik.errors) || !hasAcceptedTerms}
          data-testid="submit-button"
        >
          Create profile
        </Button>
      </Stack>
    </form>
  );
};

export default OnboardingForm;

import { useCallback } from "react";

import { CloseIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import Paper from "components/Paper";
import Stack from "components/Stack";
import toast from "components/Toast";

import EditorContent from "pages/Deal/AISummaries/EditorContent";

import AISummaryPageHeader from "entities/AISummaries/components/PageHeader";
import { useOpenAIPromptResponseReady } from "entities/AISummaries/hooks";

export interface IAISummariesDrawer {
  documentAssistantId: number | null;
  close: () => void;
  deleteCallback: () => void;
}

const AISummariesDrawer = ({
  close,
  documentAssistantId: openAIDocumentAssistantId,
  deleteCallback,
}: IAISummariesDrawer) => {
  const isDrawerOpen = openAIDocumentAssistantId !== null;

  const eventHandler = useCallback(() => {
    if (isDrawerOpen) {
      toast.dismiss();
    }
  }, [isDrawerOpen]);

  useOpenAIPromptResponseReady({
    eventHandler,
  });

  return (
    <Drawer
      anchor="right"
      onClose={close}
      variant="temporary"
      open={isDrawerOpen}
    >
      <Paper
        sx={{
          overflowX: "auto",
          padding: theme.spacing(3, 4),
        }}
      >
        <Stack>
          <Box sx={{ alignSelf: "center", width: "90vw" }}>
            <Stack spacing={2}>
              <IconButton
                size="small"
                onClick={close}
                sx={{ alignSelf: "flex-end" }}
              >
                <CloseIcon />
              </IconButton>
              <AISummaryPageHeader
                openAIDocumentAssistantId={openAIDocumentAssistantId}
                deleteCallback={deleteCallback}
              />

              <EditorContent
                openAIDocumentAssistantId={openAIDocumentAssistantId}
              />
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </Drawer>
  );
};

export default AISummariesDrawer;

import React from "react";

import theme from "theme";

import Box from "components/Box";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

const NotFound: React.FC<{}> = () => {
  usePageTitle("Builders Patch: 404");
  return (
    <>
      <Box sx={{ marginTop: theme.spacing(11), textAlign: "center" }}>
        <Text variant="h1" data-testid="error-page-content">
          404 Not Found
        </Text>
      </Box>
    </>
  );
};

export default NotFound;

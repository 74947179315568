import Paper from "components/Paper";
import Stack from "components/Stack";

import ExpensesTable from "entities/Proforma/components/ProformaPLProjections/Tables/Expenses";
import MetricsTable from "entities/Proforma/components/ProformaPLProjections/Tables/Metrics";
import RevenuesTable from "entities/Proforma/components/ProformaPLProjections/Tables/Revenues";
import { getTableHeaders } from "entities/Proforma/components/ProformaPLProjections/Tables/utils";
import { IPLProjection } from "entities/Proforma/sdk";

interface ITables {
  plProjectionData: IPLProjection;
}

const Tables = ({ plProjectionData }: ITables) => {
  const headers = getTableHeaders({ years: plProjectionData.p_and_l.years });

  return (
    <Stack spacing={1}>
      <Paper>
        <RevenuesTable
          headers={headers}
          revenuesData={plProjectionData.p_and_l.revenues}
        />
      </Paper>
      <Paper>
        <ExpensesTable
          headers={headers}
          expensesData={plProjectionData.p_and_l.expenses}
        />
      </Paper>
      <Paper>
        <MetricsTable
          headers={headers}
          metricsData={plProjectionData.p_and_l.metrics}
        />
      </Paper>
    </Stack>
  );
};
export default Tables;

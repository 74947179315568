import React from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import { TableCell } from "components/Table";
import Tag from "components/Tag";

interface IPriorityCell {
  label: string;
}

const getPriorityVariant = (priority: string) => {
  switch (priority.toLowerCase()) {
    case "high":
      return "gray100";
    case "medium":
      return "gray80";
    case "low":
      return "gray60";
  }
  return "gray100";
};

const PriorityCell: React.FC<IPriorityCell> = ({ label }) => {
  return (
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      <Box display="inline-block">
        <Tag label={label} variant={getPriorityVariant(label)} />
      </Box>
    </TableCell>
  );
};

export default PriorityCell;

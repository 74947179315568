import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { DescriptionOutlinedIcon } from "icons";
import { AddIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import { LinearProgress } from "components/Loading";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Tabs, { Tab } from "components/Tabs";
import Text from "components/Text";
import toast from "components/Toast";
import { useLoadingBackdrop } from "components/useLoadingBackdrop";
import { usePageTitle } from "components/usePageTitle";

import CreateAISummariesButton from "entities/AISummaries/components/CreateAISummariesButton";
import {
  openAIDocumentAssistantCreate,
  useOpenAIDocumentAssistantList,
  useOpenAIPromptPackages,
} from "entities/AISummaries/sdk";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";

import { FEATURE_INSTRUCTIONS, PromptPackageCategory } from "./constants";

const AISummariesList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  usePageTitle("Builders Patch: AI Summaries");

  const [selectedTab, setSelectedTab] = useState<PromptPackageCategory>(
    () =>
      (Object.keys(PromptPackageCategory).find(
        (category) => category === searchParams.get("category")
      ) as PromptPackageCategory) || PromptPackageCategory.Project
  );

  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  const [showLoading, hideLoading] = useLoadingBackdrop();

  const { data: documentAssistants, isValidating } =
    useOpenAIDocumentAssistantList({ dealId });
  const { data: promptPackages } = useOpenAIPromptPackages({
    category: selectedTab,
  });

  const handleAISummaryCreate = useCallback(
    ({ openAIDocumentAssistantId }: { openAIDocumentAssistantId: number }) => {
      navigate(
        reverse(URLS.DEAL_AI_SUMMARIES_EDITOR, {
          dealId,
          openAIDocumentAssistantId,
        })
      );
    },
    [navigate, dealId]
  );

  const handleTabChange = useCallback(
    (_: React.ChangeEvent<{}>, newValue: PromptPackageCategory) => {
      setSelectedTab(newValue);
    },
    []
  );

  const handleSummaryClick = useCallback(
    ({ assistant }) => {
      navigate(
        reverse(URLS.DEAL_AI_SUMMARIES_EDITOR, {
          dealId: assistant.deal_id,
          openAIDocumentAssistantId: assistant.id,
        })
      );
    },
    [navigate]
  );

  const createAISummary = useCallback(
    ({ promptPackage }) => {
      showLoading();
      const toastId = toast.loading("Creating the AI Summary...");

      openAIDocumentAssistantCreate({
        fileIds: [],
        dealId: dealId,
        promptPackageId: promptPackage.id,
      })
        .then(({ id: openAIDocumentAssistantId }: { id: number }) => {
          toast.update(toastId, {
            render: "AI Summary has been created successfully!",
            type: "success",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            closeButton: true,
          });

          navigate(
            reverse(URLS.DEAL_AI_SUMMARIES_EDITOR, {
              dealId,
              openAIDocumentAssistantId,
            })
          );
        })
        .catch((error) => {
          handleInvalidRequest(error);
          toast.update(toastId, {
            autoClose: 1,
            hideProgressBar: true,
            isLoading: false,
          });
        })
        .finally(() => {
          hideLoading();
        });
    },
    [dealId, navigate, showLoading, hideLoading]
  );

  const handlePromptPackageClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT,
    callback: ({ promptPackage }) => createAISummary({ promptPackage }),
  });

  const tabs = useMemo(
    () =>
      Object.keys(PromptPackageCategory).map((item) => ({
        label: item,
        value: item,
      })),
    []
  );

  const documentAssistantsForSelectedTab = useMemo(() => {
    if (_.isNil(documentAssistants)) {
      return [];
    }

    return documentAssistants.filter(
      (assistant) => assistant.openai_prompt_package.category === selectedTab
    );
  }, [documentAssistants, selectedTab]);

  const promptPackagesThatHaventGotAssistantsYet = useMemo(() => {
    if (_.isNil(promptPackages) || _.isNil(documentAssistants)) {
      return [];
    }

    return promptPackages.filter(
      (promptPackage) =>
        !documentAssistants.some(
          (assistant) => assistant.openai_prompt_package.id === promptPackage.id
        )
    );
  }, [promptPackages, documentAssistants]);

  return (
    <Paper
      sx={{
        padding: theme.spacing(3, 4),
      }}
      data-testid="ai-summaries-content"
    >
      <Stack>
        <Box
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <Stack spacing={3}>
            <PageHeader
              title={
                <Stack direction="row" spacing={1}>
                  <Text variant="h2">AI Summaries</Text>
                  <InstructionsDialogButton
                    dialogTitle="AI Summaries"
                    instructions={FEATURE_INSTRUCTIONS}
                  />
                </Stack>
              }
            />

            <Stack data-testid="page-body-section">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{ borderBottom: `1px solid ${colors.blue100}` }}
                TabIndicatorProps={{ sx: { height: "4px" } } as any}
                data-testid="prompt-package-categories-menu"
              >
                {tabs.map(({ label, value }) => (
                  <Tab
                    key={value}
                    sx={{
                      textTransform: "none",
                      color: colors.gray100,
                      padding: "9px 1.5em",
                      fontSize: "16px",
                      "&.Mui-selected": {
                        color: colors.gray100,
                        backgroundColor: colors.blue20,
                      },
                    }}
                    label={label}
                    value={value}
                    data-testid="prompt-package-category"
                  />
                ))}
              </Tabs>
              {isValidating && <LinearProgress />}
              {_.isUndefined(documentAssistants) ? (
                _.range(4).map((index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Stack direction="row" spacing={2}>
                      <Skeleton variant="text" width={30} height={30} />
                      <Skeleton variant="text" width={400} height={30} />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Skeleton variant="text" width={30} height={30} />
                      <Skeleton variant="text" width={30} height={30} />
                    </Stack>
                  </Stack>
                ))
              ) : (
                <>
                  {!_.isEmpty(documentAssistantsForSelectedTab) && (
                    <Stack
                      direction="row"
                      padding={1}
                      spacing={3}
                      marginTop={4}
                      sx={{
                        backgroundColor: colors.blue40,
                      }}
                    >
                      <Text
                        noWrap
                        sx={{ flexGrow: 1 }}
                        variant="label"
                        fontWeight={500}
                      >
                        ai summary
                      </Text>
                      <Text
                        noWrap
                        sx={{ flexBasis: "100px" }}
                        variant="label"
                        fontWeight={500}
                      >
                        no. of files
                      </Text>
                      <Text
                        noWrap
                        sx={{ flexBasis: "85px" }}
                        variant="label"
                        fontWeight={500}
                      >
                        prompts
                      </Text>
                    </Stack>
                  )}
                  {documentAssistantsForSelectedTab.map((assistant) => (
                    <Stack
                      key={assistant.id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      data-testid="prompt-package"
                      onClick={() => handleSummaryClick({ assistant })}
                      spacing={3}
                      sx={{
                        paddingX: 1,
                        paddingY: 2,
                        cursor: "pointer",
                        transition: "background-color 0.2s linear",
                        borderBottom: `1px solid ${colors.blue40}`,

                        "&:hover": {
                          backgroundColor: colors.blue10,
                        },
                      }}
                    >
                      <Text
                        noWrap
                        variant="text1"
                        fontWeight={500}
                        data-testid="package-name"
                        sx={{ flexGrow: 1 }}
                      >
                        {assistant.openai_prompt_package.name}
                      </Text>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                        sx={{ flexBasis: "100px" }}
                      >
                        <DescriptionOutlinedIcon sx={{ height: "24px" }} />
                        <Text variant="text2" data-testid="files-count">
                          {assistant.openai_files.length}
                        </Text>
                      </Stack>

                      <Text
                        variant="text3"
                        color={colors.gray60}
                        data-testid="prompts-count"
                        sx={{ flexBasis: "85px" }}
                      >
                        {assistant.unique_prompt_response_count} /{" "}
                        {assistant.total_prompt_count}
                      </Text>
                    </Stack>
                  ))}

                  {promptPackagesThatHaventGotAssistantsYet.map(
                    (promptPackage) => (
                      <Stack
                        key={promptPackage.id}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        data-testid="prompt-package"
                        onClick={() =>
                          handlePromptPackageClick({ promptPackage })
                        }
                        spacing={3}
                        sx={{
                          paddingX: 1,
                          paddingY: 2,
                          cursor: "pointer",
                          transition: "background-color 0.2s linear",
                          borderBottom: `1px solid ${colors.blue40}`,

                          "&:hover": {
                            backgroundColor: colors.blue10,
                          },
                        }}
                      >
                        <Text
                          noWrap
                          variant="text1"
                          fontWeight={500}
                          data-testid="package-name"
                          sx={{ flexGrow: 1 }}
                        >
                          {promptPackage.name}
                        </Text>

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                          sx={{ flexBasis: "100px", color: colors.gray60 }}
                        >
                          <DescriptionOutlinedIcon sx={{ height: "24px" }} />
                          <Text variant="text2" data-testid="files-count">
                            0
                          </Text>
                        </Stack>

                        <Text
                          variant="text3"
                          color={colors.gray60}
                          data-testid="prompts-count"
                          sx={{ flexBasis: "85px" }}
                        >
                          0 / {promptPackage.total_prompt_count}
                        </Text>
                      </Stack>
                    )
                  )}
                </>
              )}
            </Stack>

            <CreateAISummariesButton
              dealId={dealId}
              createCallback={handleAISummaryCreate}
              buttonRender={({ onClick }) => (
                <Button
                  onClick={onClick}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  sx={{ alignSelf: "flex-start" }}
                >
                  Add AI Summary
                </Button>
              )}
            />
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default AISummariesList;

import { BASE_URL } from "config/urls";
import { deleteCall, post, useFetch } from "utils/sdk";

export interface IBookmark {
  id: number;
  name: string;
  pathname: string;
}

export const useBookmarks = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useFetch<Array<IBookmark>>(enabled ? "v2/users/bookmarks/list/" : undefined);

export const bookmarkCreate = ({
  name,
  pathname,
}: {
  name: string;
  pathname: string;
}) =>
  post(`${BASE_URL}v2/users/bookmarks/create/`, {
    name,
    pathname,
  });

export const bookmarkUpdate = ({
  bookmarkId,
  name,
}: {
  name: string;
  bookmarkId: number;
}) =>
  post(`${BASE_URL}v2/users/bookmarks/${bookmarkId}/update/`, {
    name,
  });

export const bookmarkDelete = ({ bookmarkId }: { bookmarkId: number }) =>
  deleteCall(`${BASE_URL}v2/users/bookmarks/${bookmarkId}/delete/`);

import { useMemo } from "react";
import _ from "lodash";

import DealNameCell from "components/ScrollableTable/Cells/DealNameCell";
import TagCell from "components/ScrollableTable/Cells/TagCell";
import TextCell from "components/ScrollableTable/Cells/TextCell";
import { ScrollableTableCellVariant } from "components/ScrollableTable/constants";

import { EFieldFormatType } from "entities/Reporting/constants";

const MapVariantToFormatType = {
  [ScrollableTableCellVariant.NUMBER]: EFieldFormatType.FLOAT_2,
  [ScrollableTableCellVariant.CURRENCY]: EFieldFormatType.CURRENCY_USD,
  [ScrollableTableCellVariant.TEXT]: EFieldFormatType.STRING,
  [ScrollableTableCellVariant.DATE_TIME]: EFieldFormatType.DATETIME,
};

export interface ICellWrapper {
  variant: ScrollableTableCellVariant;
  row: { [key: string]: any };
  cellKey: string;
}

const CellWrapper = ({ variant, row, cellKey }: ICellWrapper) => {
  const cellData = useMemo(() => _.get(row, cellKey), [row, cellKey]);

  switch (variant) {
    case ScrollableTableCellVariant.NUMBER:
    case ScrollableTableCellVariant.CURRENCY:
    case ScrollableTableCellVariant.TEXT:
    case ScrollableTableCellVariant.DATE_TIME:
      return (
        <TextCell
          cellData={cellData}
          title={cellData}
          fieldFormatType={MapVariantToFormatType[variant]}
        />
      );
    case ScrollableTableCellVariant.TAG:
      return <TagCell cellData={cellData} title={cellData} />;
    case ScrollableTableCellVariant.DEAL:
      return <DealNameCell row={row} />;
  }

  return null;
};

export default CellWrapper;

import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { URLS } from "config/urls";
import { AddIcon } from "icons";
import { addQueryParams, reverse } from "utils/urls";

import Button from "components/Button";
import SuccessDialog from "components/SuccessDialog";

import { useSignedUser } from "entities/Auth/sdk";
import ChecklistsDialog from "entities/Deal/components/DealChecklists/ChecklistsDialog";
import { DueDiligenceTabChoices } from "entities/Package/components/Tabs/constants";

export interface IAddChecklistsButton {
  dealId: number;
}

const AddChecklistsButton = ({ dealId }: IAddChecklistsButton) => {
  const [addedPackageId, setAddedPackageId] = useState<number | null>(null);

  const [isChecklistDialogOpen, setIsChecklistDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const navigate = useNavigate();

  const { data: user } = useSignedUser();

  const openChecklistDialog = useCallback(
    () => setIsChecklistDialogOpen(true),
    []
  );

  const handleCloseChecklistDialogAndOpenSuccessDialog = useCallback(
    ({ packageId }: { packageId: number }) => {
      setAddedPackageId(packageId);
      setIsChecklistDialogOpen(false);
      setIsSuccessDialogOpen(true);
    },
    []
  );

  const handleCloseChecklistDialog = useCallback(
    () => setIsChecklistDialogOpen(false),
    []
  );

  const handleCloseSuccessDialog = useCallback(() => {
    setIsSuccessDialogOpen(false);
    setAddedPackageId(null);
  }, []);

  const handleSuccessDialogButtonClick = useCallback(() => {
    setIsSuccessDialogOpen(false);
    if (!_.isNil(addedPackageId)) {
      navigate(
        addQueryParams(
          reverse(URLS.DEAL_PACKAGE_NO_TAB, {
            dealId,
            packageId: addedPackageId,
          }),
          {
            tab: DueDiligenceTabChoices.SHARE_SETTINGS,
          }
        )
      );
    }
  }, [dealId, addedPackageId, navigate]);

  return (
    <>
      <Button
        onClick={openChecklistDialog}
        startIcon={<AddIcon />}
        data-testid="add-new-checklist-button"
      >
        Add new checklist
      </Button>

      {isChecklistDialogOpen && user && (
        <ChecklistsDialog
          isOpen={isChecklistDialogOpen}
          dealId={dealId}
          companyId={user?.company_id}
          onSubmit={handleCloseChecklistDialogAndOpenSuccessDialog}
          onClose={handleCloseChecklistDialog}
        />
      )}

      <SuccessDialog
        isOpen={isSuccessDialogOpen}
        title="Added to deal"
        subTitle="You’ll find all added checklists on your deal’s checklists overview page. Don’t forget to grant edit rights to your deal team."
        buttonTitle="Invite my deal team"
        onClose={handleCloseSuccessDialog}
        onButtonClick={handleSuccessDialogButtonClick}
      />
    </>
  );
};

export default AddChecklistsButton;

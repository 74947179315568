import React, { useCallback } from "react";
import _ from "lodash";

import theme from "theme";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import ContentSeparator from "components/ContentSeparator";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { useDealChecklistPermission } from "entities/Deal/sdk";
import RemarkData from "entities/Field/components/Filefield/RemarkData";
import { addFileFieldRemark, useFileFieldRemarks } from "entities/Field/sdk";
import { fieldUpdateNAStatusBulk } from "entities/Package/sdk";

interface IFilesDialog {
  open: boolean;
  fieldId: number;
  packageId: number;
  dealId: number;
  handleClose: () => void;
}
const FilesDialog: React.FC<IFilesDialog> = ({
  open,
  handleClose,
  dealId,
  packageId,
  fieldId,
}) => {
  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  const {
    data: remarkData,
    mutate: updateRemarkData,
    loading,
  } = useFileFieldRemarks({
    dealId,
    packageId,
    fieldId,
    enabled: open,
  });

  const [status, setStatus] = useCaching(remarkData?.n_a_status || false);

  const handleUpdateRemark = useCallback(
    ({ value, type }: { value: string; type: "shared" | "private" }) => {
      const data =
        type === "shared"
          ? { shared_remark: value }
          : { private_remark: value };
      addFileFieldRemark({ dealId, packageId, fieldId, data })
        .then(() => {
          const message = `${_.startCase(type)} Remark updated successfully.`;
          toast.successMessage(message);
          updateRemarkData();
        })
        .catch((error) => {
          toast.errorMessage(
            error.message || error.detail || DEFAULT_ERROR_MESSAGE
          );
        });
    },
    [dealId, packageId, fieldId, updateRemarkData]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      fieldUpdateNAStatusBulk({
        dealId,
        fieldIds: [fieldId],
        naStatus: event.target.checked,
      }).then(() => {
        setStatus(event.target.checked);
        toast.successMessage(
          `The field was successfully ${
            event.target.checked ? "disabled" : "enabled"
          }!`
        );
        updateRemarkData();
      });
    },
    [dealId, fieldId, setStatus, updateRemarkData]
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      {remarkData && !loading && (
        <Box
          sx={{ padding: theme.spacing(1) }}
          data-testid="file-field-settings-dialog"
        >
          <DialogTitle data-testid="file-field-dialog-title">
            {remarkData.name}
          </DialogTitle>
          <Stack spacing={2} sx={{ paddingX: theme.spacing(3) }}>
            <ContentSeparator label="Instructions" />
            <Text
              variant="text4"
              sx={{
                paddingLeft: theme.spacing(1),
              }}
              data-testid="instructions-value"
            >
              {remarkData.instruction ||
                "Instructions for this field are not provided."}
            </Text>
            <RemarkData
              title="Shared remarks"
              remark={remarkData.shared_remark}
              updated_at={remarkData.shared_remark_updated_at}
              created_by={remarkData.shared_remark_created_by}
              updated_by={remarkData.shared_remark_updated_by}
              onUpdate={(value) =>
                handleUpdateRemark({ value, type: "shared" })
              }
              dataTestid="shared-remarks"
            />
            <RemarkData
              title={`${userPermission?.persona} remarks`}
              remark={remarkData.private_remark}
              updated_at={remarkData.private_remark_updated_at}
              created_by={remarkData.private_remark_created_by}
              updated_by={remarkData.private_remark_updated_by}
              onUpdate={(value) =>
                handleUpdateRemark({ value, type: "private" })
              }
              dataTestid="private-remarks"
            />
            <ContentSeparator label="Status" />
            <Text
              variant="text4"
              sx={{
                paddingLeft: theme.spacing(1),
              }}
            >
              <Checkbox
                sx={{ padding: 0, marginRight: theme.spacing(1) }}
                checked={status}
                onChange={handleCheckboxClick}
                data-testid="field-status-checkbox"
              />
              Mark this field as N/A
            </Text>
            <ContentSeparator label="Permissions" />
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="start"
              spacing={1}
              sx={{
                paddingLeft: theme.spacing(1),
              }}
              data-testid="field-permissions-all"
            >
              {remarkData.permissions.map((permission) => (
                <Tag
                  label={permission}
                  key={permission}
                  sx={{ marginBottom: theme.spacing(1) }}
                  dataTestid="field-persona-permission"
                />
              ))}
            </Stack>
          </Stack>
          <DialogActions sx={{ marginTop: theme.spacing(2) }}>
            <Button
              variant="text"
              onClick={handleClose}
              data-testid="close-dialog-button"
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default FilesDialog;

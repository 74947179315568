import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";

export const getModifiedByText = (filterGroup: IFilterGroup) => {
  if (filterGroup.updated_by) {
    return (
      filterGroup.updated_by.first_name + " " + filterGroup.updated_by.last_name
    );
  }
  return (
    filterGroup.created_by.first_name + " " + filterGroup.created_by.last_name
  );
};

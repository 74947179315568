import * as yup from "yup";

const columnSchema = yup
  .string()
  .required("This field is required!")
  .matches(
    /^[A-Za-z]{1,3}$/,
    "This field must be a valid Excel column (A-Z, a-z, AA-ZZ, aa-zz, etc.)"
  );

// Custom method to validate the range
export const VALIDATION_SCHEMA = yup.object({
  table_name: yup
    .string()
    .required("This field is required!")
    .max(255, "This field must be less than 255 characters"),
  sheet_name: yup
    .string()
    .required("This field is required!")
    .max(255, "This field must be less than 255 characters"),
  column_start: columnSchema,
  column_end: columnSchema,
  row_start: yup
    .number()
    .required("This field is required!")
    .min(1, "This field must be greater than 0")
    .typeError("Must be a positive number."),
  row_end: yup
    .number()
    .required("This field is required!")
    .min(1, "This field must be greater than 0")
    .typeError("Must be a positive number."),
});

import React from "react";

import { URLS } from "config/urls";
import theme from "theme";
import { typography } from "theme/typography";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import DealAvatar from "components/DealAvatar";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";

import { IMarker } from "entities/Deal/sdk";

export interface IInfoWindow {
  marker: IMarker;
  showButton?: boolean;
}

const InfoWindow: React.FC<IInfoWindow> = ({ marker, showButton = true }) => (
  <Stack direction="row" spacing={2}>
    <DealAvatar
      imgix
      src={marker.contentdata.thumbnail}
      imgixWidth={40}
      imgixHeight={40}
    />
    <Box>
      <Text sx={{ ...typography.text2 }} data-testid="deal-name">
        {marker.contentdata.project_name}
      </Text>
      {marker.contentdata.units && (
        <Text sx={{ ...typography.text4 }} data-testid="deal-total-units">
          {marker.contentdata.units} units
        </Text>
      )}
      <Text sx={{ ...typography.text4 }} data-testid="deal-location-longitude">
        Longitude: {marker.center.lng}
      </Text>
      <Text sx={{ ...typography.text4 }} data-testid="deal-location-latitude">
        Latitude: {marker.center.lat}
      </Text>
      <Text sx={{ ...typography.text4 }} data-testid="deal-location-address">
        {marker.contentdata.address}
      </Text>
      {showButton && (
        <Link
          to={
            marker.contentdata.deal_id
              ? reverse(URLS.DEAL_OVERVIEW, {
                  dealId: marker.contentdata.deal_id,
                })
              : "#"
          }
          target="_blank"
          data-testid="deal-link"
        >
          <Button
            size="small"
            color="primary"
            variant="contained"
            sx={{ marginTop: theme.spacing(2) }}
            data-testid="deal-link-button"
          >
            Project details
          </Button>
        </Link>
      )}
    </Box>
  </Stack>
);

export default InfoWindow;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS } from "utils/datetime";

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";

import { IDealSchedule } from "entities/Deal/sdk";

interface IScheduleTable {
  schedule: Array<IDealSchedule>;
}

const ScheduleTable: React.FC<IScheduleTable> = ({ schedule }) => (
  <Table size="small">
    <TableHead>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            Development schedule
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            Start date
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="text2" fontWeight="300" sx={{ color: colors.gray100 }}>
            End date
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody data-testid="development-schedule-data-table">
      {schedule.map((data: IDealSchedule) => (
        <TableRow
          key={data.phase.id}
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
          data-testid={`${data.phase.name.toLowerCase()}-data-row`}
          data-test-id="phase-schedule-data-row"
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text variant="text2" data-testid="deal-phase-name">
              {data.phase.name}
            </Text>
          </TableCell>
          <TableCell
            data-testid={`${data.phase.name.toLowerCase()}-start-date`}
            data-test-id="schedule-start-date"
          >
            {data.start_date &&
              format(new Date(data.start_date), FORMATS.FRONTEND.DATE)}
          </TableCell>
          <TableCell
            data-testid={`${data.phase.name.toLowerCase()}-end-date`}
            data-test-id="schedule-start-date"
          >
            {data.end_date &&
              format(new Date(data.end_date), FORMATS.FRONTEND.DATE)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default ScheduleTable;

import _ from "lodash";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

const CompanyChecklistSkeleton = () => (
  <Stack>
    {_.range(3).map((index) => (
      <Stack key={index} direction="row" spacing={6} alignItems="center">
        <Skeleton width="360px" height="80px" />
        <Stack spacing={1} width="175px" direction="row">
          <Skeleton width="75px" height="50px" />
          <Skeleton width="75px" height="50px" />
        </Stack>
        <Skeleton width="75px" height="50px" />
      </Stack>
    ))}
  </Stack>
);

export default CompanyChecklistSkeleton;

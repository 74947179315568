import React, { useCallback } from "react";

import Form from "entities/AnalyticsFilterGroup/components/Form";
import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";
import {
  ANALYTICS_TYPES,
  createFilter,
  useAnalyticsFilterGroups,
} from "entities/AnalyticsFilterGroup/sdk";

export interface IAddForm {
  onCreated?: () => void;
  onCancel?: () => void;
  analyticsType: ANALYTICS_TYPES;
}

const AddForm: React.FC<IAddForm> = ({
  onCreated,
  onCancel,
  analyticsType,
}) => {
  const { mutate: updateFilterGroupsList } =
    useAnalyticsFilterGroups(analyticsType);

  const handleSubmit = useCallback(
    (data: Partial<IFilterGroup>) => {
      let filterType: IFilterGroup["filter_type"] | undefined = undefined;
      if (analyticsType === ANALYTICS_TYPES.DEVELOPMENT_BUDGET) {
        filterType = "development_budget";
      }
      if (analyticsType === ANALYTICS_TYPES.INCOME_AND_OPEX) {
        filterType = "income_and_opex";
      }
      return createFilter({ ...data, filter_type: filterType }).then((data) => {
        onCreated && onCreated();
        updateFilterGroupsList(
          (currentData: IFilterGroup[] | undefined) =>
            currentData && [...currentData, data]
        );
        return data;
      });
    },
    [onCreated, updateFilterGroupsList, analyticsType]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitLabel="Save group"
    />
  );
};

export default AddForm;

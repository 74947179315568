import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import Box from "components/Box";

/**
 * This is a wrapper used to hide the Children components only while printing the page
 */

export interface INoPrint {
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

const NoPrint = ({ children, sx }: INoPrint) => (
  <Box sx={{ "@media print": { display: "none" }, ...sx }}>{children}</Box>
);

export default NoPrint;

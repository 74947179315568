import { memo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

import Illustration from "./assets/image.svg";

const InfoBanner = () => (
  <Stack
    direction="row"
    spacing={3}
    alignItems="flex-start"
    sx={{
      padding: theme.spacing(3),
      borderRadius: "8px",
      backgroundColor: colors.gray10,
    }}
  >
    <img src={Illustration} alt="" />
    <Stack spacing={1}>
      <Text variant="text2" fontWeight={700}>
        How to bring in your data & create tables here?
      </Text>
      <Stack spacing={2}>
        <Text variant="text2" color={colors.gray80}>
          This is your space to store any data tables you may need in the
          future. Grab all rows and columns with their existing datasets, from
          your Excel or other files and simply paste them here. Replace any
          saved tables by pasting in an updated one.
        </Text>
        <Text variant="text2" color={colors.gray80}>
          <span style={{ fontWeight: 500, fontStyle: "italic" }}>Note:</span>{" "}
          This feature was created to quickly store simple data tables in HTML
          format. For large tables (ten or more columns and rows) and complex
          data sheets with formulas and calculations (projections with moving
          variables, asset management tracking, etc.) you should use the larger
          mapped pro forma
        </Text>
      </Stack>
    </Stack>
  </Stack>
);

export default memo(InfoBanner);

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import {
  ArrowDropDownIcon,
  CancelIcon,
  CheckCircleIcon,
  DownloadingIcon,
  OpenInNewIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import Box from "components/Box";
import ButtonDropdown from "components/ButtonDropdown";
import IconButton from "components/IconButton";
import Link from "components/Link";
import ListPagination from "components/ListPagination";
import { MenuItem } from "components/Menu";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";
import { usePageTitle } from "components/usePageTitle";

import InfoBanner from "entities/TableStorage/components/InfoBanner";
import TableCountMessage from "entities/TableStorage/components/TableCountMessage";
import {
  ETableReportType,
  ETableResponseStatus,
  MapTableResponseStatusColor,
  TableResponseStatusLabel,
} from "entities/TableStorage/constants";
import { useTableResponseReady } from "entities/TableStorage/hooks";
import { useTableResponses } from "entities/TableStorage/sdk";

const TableGeneratedAttempts = () => {
  usePageTitle(`Builders Patch: AI-powered Generated Tables`);

  const {
    data,
    pagesCount,
    setPage,
    currentPage,
    isLoading,
    mutate: updateTableResponseList,
  } = useTableResponses({});
  const navigate = useNavigate();

  const showPagination = useMemo(
    () => !isLoading && !_.isUndefined(data) && data.count > 0,
    [isLoading, data]
  );

  const currentPageData = useMemo(() => _.get(data, "results", []), [data]);

  const tableCountMessage = useMemo(
    () => `${data?.count} report${data?.count !== 1 ? "s" : ""} created`,
    [data]
  );

  const navigateToCreateReport = useCallback(
    ({ reportType }: { reportType: ETableReportType }) => {
      navigate(
        reverse(URLS.TABLES_RESPONSE_REPORT_CREATE, {
          reportType,
        })
      );
    },
    [navigate]
  );

  const eventHandler = useCallback(() => {
    updateTableResponseList();
  }, [updateTableResponseList]);

  useTableResponseReady({
    successEventHandler: eventHandler,
    failureEventHandler: eventHandler,
  });

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
          spacing={4}
        >
          <PageHeader
            title="List of reports"
            actions={
              <Stack direction="row" spacing={1}>
                <ButtonDropdown
                  label="Create report"
                  icon={<ArrowDropDownIcon />}
                  color="secondary"
                  width="auto"
                >
                  <MenuItem
                    onClick={() =>
                      navigateToCreateReport({
                        reportType: ETableReportType.TYPE_1,
                      })
                    }
                  >
                    Type 1/Use case 2
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigateToCreateReport({
                        reportType: ETableReportType.TYPE_2,
                      })
                    }
                  >
                    Type 2/Use case 2.1
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigateToCreateReport({
                        reportType: ETableReportType.TYPE_3,
                      })
                    }
                  >
                    Type 3/Use case 2.2
                  </MenuItem>
                </ButtonDropdown>
              </Stack>
            }
          />

          {!isLoading && currentPageData.length === 0 && (
            <Box marginTop={4}>
              <InfoBanner />
            </Box>
          )}

          {_.get(data, "count", 0) > 0 && (
            <Stack>
              <Box
                sx={{
                  paddingBottom: 1,
                  borderBottom: `1px solid ${colors.gray20}`,
                }}
              >
                <TableCountMessage countMessage={tableCountMessage} />
              </Box>
              {currentPageData.map((tableResponse) => (
                <Stack
                  key={tableResponse.id}
                  spacing={6}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    padding: theme.spacing(3, 3, 3, 2),
                    borderBottom: `1px solid ${colors.gray20}`,
                  }}
                >
                  <Stack flex={1}>
                    <Text
                      variant="text1"
                      fontWeight={700}
                      flexBasis="45%"
                      noWrap
                    >
                      {tableResponse.table_type}
                    </Text>
                    {_.isNil(tableResponse.updated_by) ? (
                      <Text variant="text4" sx={{ color: colors.gray60 }}>
                        Last edited{" "}
                        {format(
                          parseJSON(tableResponse.created_at),
                          FORMATS.FRONTEND.DATETIME
                        )}{" "}
                        by {tableResponse.created_by.first_name}{" "}
                        {tableResponse.created_by.last_name}
                      </Text>
                    ) : (
                      <Text variant="text4" sx={{ color: colors.gray60 }}>
                        Last edited{" "}
                        {format(
                          parseJSON(tableResponse.updated_at),
                          FORMATS.FRONTEND.DATETIME
                        )}{" "}
                        by {tableResponse.updated_by.first_name}{" "}
                        {tableResponse.updated_by.last_name}
                      </Text>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    data-testid="pdf-import-status"
                  >
                    {tableResponse.status ===
                      ETableResponseStatus.SUCCEEDED && (
                      <CheckCircleIcon
                        sx={{ color: colors.green100 }}
                        fontSize="small"
                      />
                    )}
                    {tableResponse.status === ETableResponseStatus.FAILED && (
                      <CancelIcon
                        sx={{ color: colors.red120 }}
                        fontSize="small"
                      />
                    )}
                    {tableResponse.status ===
                      ETableResponseStatus.IN_PROGRESS && (
                      <DownloadingIcon
                        sx={{ color: colors.yellow120 }}
                        fontSize="small"
                        data-testid="in-progress-status-icon"
                      />
                    )}

                    <Text
                      variant="text3"
                      color={MapTableResponseStatusColor[tableResponse.status]}
                      fontWeight={500}
                      data-testid="status-text"
                    >
                      {TableResponseStatusLabel[tableResponse.status]}
                    </Text>
                  </Stack>
                  <Stack
                    flexBasis="20%"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    {tableResponse.status ===
                      ETableResponseStatus.SUCCEEDED && (
                      <Link
                        to={reverse(URLS.TABLES_RESPONSE_DETAIL, {
                          tableResponseId: tableResponse.id,
                        })}
                      >
                        <Tooltip title="Open this report">
                          <IconButton>
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    )}
                  </Stack>
                </Stack>
              ))}
              {showPagination && (
                <Box marginTop={3}>
                  <ListPagination
                    setPage={setPage}
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                  />
                </Box>
              )}
            </Stack>
          )}

          {isLoading && (
            <Box>
              {_.range(5).map((i) => (
                <Stack
                  key={i}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: `1px solid ${colors.gray20}`,
                  }}
                >
                  <Skeleton height={95} width={300} />
                  <Skeleton height={50} width={100} />
                  <Stack
                    spacing={1}
                    direction="row"
                    flexBasis="20%"
                    justifyContent="flex-end"
                  >
                    <Skeleton height={50} width={30} />
                  </Stack>
                </Stack>
              ))}
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TableGeneratedAttempts;

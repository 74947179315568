import _ from "lodash";

export const extractItemsWithoutChildren = <
  T extends { children: Array<T>; isField: boolean },
>({
  item,
}: {
  item: T;
}): T | Array<T> => {
  if (item.isField) {
    return item;
  } else {
    return item.children.flatMap((child: T) =>
      extractItemsWithoutChildren({ item: child })
    );
  }
};

export const extractItemsWithChildren = <T extends { children: Array<T> }>({
  item,
}: {
  item: T;
}): T | Array<T> => {
  if (_.isEmpty(item.children)) {
    return [];
  } else {
    return [
      item,
      ...item.children.flatMap((child: T) =>
        extractItemsWithChildren({ item: child })
      ),
    ];
  }
};

export const getItemsWithoutChildrenCount = <
  T extends { children: Array<T>; isField: boolean },
>({
  items,
}: {
  items: Array<T>;
}): number =>
  items.flatMap((item: T) => extractItemsWithoutChildren({ item })).length;

import { useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Divider from "components/Divider";
import Paper from "components/Paper";
import Stack from "components/Stack";
import StickyMenu from "components/StickyMenu";
import { useFirstVisibleAnchor } from "components/StickyMenu/utils";
import Text from "components/Text";

import InPageLink from "./InPageLink";

interface IWorkspaceStickySidebar {
  teamNames: string[];
  hasInvitations: boolean;
}

const WorkspaceStickySidebar = ({
  teamNames,
  hasInvitations,
}: IWorkspaceStickySidebar) => {
  const anchorNames = useMemo(() => {
    if (hasInvitations) {
      return [...teamNames, "Invitations"];
    }

    return teamNames;
  }, [teamNames, hasInvitations]);

  const firstVisibleAnchor = useFirstVisibleAnchor(anchorNames);

  return (
    <StickyMenu top={theme.spacing(13.75)}>
      <Paper
        sx={{ padding: theme.spacing(4), border: `1px solid ${colors.blue40}` }}
        data-testid="teams-side-bar"
      >
        <Stack spacing={1} data-testid="onboarded-teams-section">
          <Text
            variant="labelSmall"
            color={colors.gray60}
            fontWeight="700"
            data-testid="teams-section-label"
          >
            Teams
          </Text>

          <Stack data-testid="onboarded-teams-nav-links">
            {teamNames.map((name) => (
              <InPageLink
                key={name}
                anchor={name}
                selected={firstVisibleAnchor === name}
                buttonDataTestid="onboarded-team-link"
              />
            ))}
          </Stack>
        </Stack>

        {hasInvitations && (
          <>
            <Divider
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            />

            <Stack spacing={1} data-testid="sp-invitations-section">
              <Text
                variant="labelSmall"
                color={colors.gray60}
                fontWeight="700"
                data-testid="invitations-section-label"
              >
                Invitations
              </Text>
              <InPageLink
                key="Invitations"
                anchor="Invitations"
                selected={firstVisibleAnchor === "Invitations"}
                buttonDataTestid="invitations-table-link"
              />
            </Stack>
          </>
        )}
      </Paper>
    </StickyMenu>
  );
};

export default WorkspaceStickySidebar;

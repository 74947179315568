import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { EditIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import ScheduleEditForm, {
  IPhaseForm,
} from "entities/Deal/components/OverviewSchedule/ScheduleEditForm";
import {
  updateProjectSchedule,
  useDealDetails,
  useDealSchedule,
} from "entities/Deal/sdk";

export interface IScheduleEditDialog {
  open: boolean;
  handleClose: () => void;
}

const ScheduleEditDialog: React.FC<IScheduleEditDialog> = ({
  open,
  handleClose,
}) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });
  const { data: phases, mutate: updateDealSchedule } = useDealSchedule({
    dealId,
  });

  const handleSubmit = useCallback(
    (data: Partial<IPhaseForm>) =>
      updateProjectSchedule(dealId, data).then(() => {
        updateDealSchedule();
        toast.successMessage("Schedule info successfully updated!");
        handleClose();
      }),
    [dealId, updateDealSchedule, handleClose]
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <Box>
        <Stack alignItems="center" data-testid="edit-schedule-dialog">
          <Paper
            sx={{
              padding: theme.spacing(8),
              width: "100%",
            }}
          >
            <Stack spacing={4}>
              <Stack direction="row" spacing={1}>
                <EditIcon
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
                <Text variant="h1" style={{ display: "flex" }}>
                  Schedule
                </Text>
              </Stack>
              {deal && (
                <ScheduleEditForm
                  onSubmit={handleSubmit}
                  handleCancel={handleClose}
                  initialValues={{
                    phases: phases,
                    phase_id: deal.phase?.id,
                  }}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ScheduleEditDialog;

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import { IPackageSection } from "entities/Package/sdk";
import { IInitialValues } from "entities/Task/components/Form";
import { DUE_DILIGENCE_TAG_NAME } from "entities/Task/constants";
import { useTagList } from "entities/Task/sdk";

interface IDueDiligenceSectionNewTaskButton {
  section: IPackageSection;
  packageId: number;
  tabId: number;
  categoryId: number;
  updatePackage: () => void;
  menuOptionDataTestid?: string;
}

const DueDiligenceSectionNewTaskButton = ({
  section,
  packageId,
  tabId,
  categoryId,
  updatePackage,
  menuOptionDataTestid,
}: IDueDiligenceSectionNewTaskButton) => {
  const { openCreateNewTaskSubTab } = useDealSidepanel();
  const { data: tagList } = useTagList();

  const handleCreateNewTask = useCallback(() => {
    const initialValues: IInitialValues = {
      title: section.name,
      company_section: section.section_id,
      company_package: packageId,
      company_tab: tabId,
      company_category: categoryId,
      tags:
        tagList
          ?.filter((tag) => tag.name === DUE_DILIGENCE_TAG_NAME)
          .map((tag) => tag.id) || [],
    };

    openCreateNewTaskSubTab({
      taskSection: section,
      updateParent: updatePackage,
      initialValues: initialValues,
    });
  }, [
    openCreateNewTaskSubTab,
    section,
    packageId,
    tabId,
    categoryId,
    tagList,
    updatePackage,
  ]);

  return (
    <MenuItem onClick={handleCreateNewTask} data-testid={menuOptionDataTestid}>
      <Text variant="text2" data-testid="action-menu-option-name">
        Create new task
      </Text>
    </MenuItem>
  );
};

export default DueDiligenceSectionNewTaskButton;

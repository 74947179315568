import React from "react";
import { useCallback, useState } from "react";

import { EditIcon } from "icons";

import IconButton from "components/IconButton";
import toast from "components/Toast";

import EditCompanyProfileDialog from "entities/Company/components/EditCompanyProfileDialog";
import { ICompany } from "entities/Company/sdk";

export interface ICompanyProfileEditButton {
  company: ICompany;
  onUpdated: () => void;
}

const CompanyProfileEditButton: React.FC<ICompanyProfileEditButton> = ({
  company,
  onUpdated,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleUpdated = useCallback(() => {
    toast.successMessage("Company successfully updated!");
    setOpen(false);
    onUpdated();
  }, [onUpdated]);

  return (
    <>
      <IconButton
        data-testid="edit-company-profile-button"
        onClick={handleOpen}
      >
        <EditIcon />
      </IconButton>
      <EditCompanyProfileDialog
        open={open}
        handleClose={handleClose}
        company={company}
        onUpdated={handleUpdated}
      />
    </>
  );
};

export default CompanyProfileEditButton;

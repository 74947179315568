import { useCallback, useState } from "react";

import { AddIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import {
  addNewTeamMember,
  useDealTeamMemberInvitations,
  useDealTeamMembers,
} from "entities/Team/sdk";

import AddNewTeamMemberForm from "./AddNewTeamMemberForm";

export interface IAddNewTeamMemberButton {
  dealId: number;
  companyId: number;
  companyName: string;
}

const AddNewTeamMemberButton = ({
  dealId,
  companyId,
  companyName,
}: IAddNewTeamMemberButton) => {
  const [showDialog, setShowDialog] = useState(false);

  const { mutate: mutateDealTeamMembers } = useDealTeamMembers({ dealId });
  const { mutate: mutateDealTeamMemberInvitations } =
    useDealTeamMemberInvitations({
      dealId,
    });

  const handleOpen = useCallback(() => {
    setShowDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleSubmit = useCallback(
    (data: { email: string }) => {
      const dataWithCompany = { ...data, company_id: companyId };

      return addNewTeamMember({ dealId, data: dataWithCompany })
        .then(() => {
          toast.successMessage("Invite has been sent.");
          mutateDealTeamMembers();
          mutateDealTeamMemberInvitations();
          handleClose();
        })
        .catch((error: any) => {
          if (error.message) {
            toast.errorMessage(error.message);
          }
        });
    },
    [
      companyId,
      dealId,
      mutateDealTeamMembers,
      mutateDealTeamMemberInvitations,
      handleClose,
    ]
  );

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={handleOpen}
        sx={{ alignSelf: "flex-start" }}
        data-testid="add-new-team-member-button"
      >
        New team member
      </Button>

      {showDialog && (
        <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
          <Box>
            <Stack alignItems="center" data-testid="add-new-team-member-dialog">
              <Paper
                sx={{
                  paddingTop: theme.spacing(8),
                  paddingBottom: theme.spacing(8),
                }}
              >
                <Stack spacing={4}>
                  <Stack direction="row" spacing={1}>
                    <AddIcon
                      style={{
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <Text
                      variant="h1"
                      style={{ display: "flex" }}
                      data-testid="dialog-title"
                    >
                      Add New Team member
                    </Text>
                  </Stack>
                  <Text data-testid="users-helper-text">
                    Invite a team member from {companyName} by using his or her
                    company email.
                  </Text>
                  <AddNewTeamMemberForm
                    onCancel={handleClose}
                    onSubmit={handleSubmit}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default AddNewTeamMemberButton;

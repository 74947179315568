import {
  convertHTMLTableInRowTableData,
  convertPastedDataToTableRows,
} from "entities/TableStorage/components/PasteTableField/utils";
import { ITableCell } from "entities/TableStorage/sdk";

export const transformHTMLTableInTableRows = ({
  data,
}: {
  data: string;
}): Array<Array<ITableCell>> | undefined => {
  let tableDataForParsing = convertHTMLTableInRowTableData({
    data,
  });

  const rows = convertPastedDataToTableRows({
    data: tableDataForParsing,
  });

  return rows.map((row) => row.cells);
};

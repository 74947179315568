import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { formatNumberToString } from "utils/common";

import Box from "components/Box";
import DonutChart, { Legend } from "components/DonutChart";
import Stack from "components/Stack";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IIncomeOperatingCost } from "entities/Proforma/sdk";

interface IExpensesTable {
  incomeOperatingCost: IIncomeOperatingCost;
  totalUnits: number;
}

const headerIncomeAndOpex: Array<{ key: string; name: string }> = [
  { key: "name", name: "EXPENSES" },
  { key: "total", name: "YEARLY TOTAL" },
  { key: "perunit", name: "PER UNIT" },
];

const ExpensesTable: React.FC<IExpensesTable> = ({
  incomeOperatingCost,
  totalUnits,
}) => {
  const incomeAndOpexDetails = useMemo(() => {
    const managmentCost = _.orderBy(
      _.map(incomeOperatingCost.management_cost, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );
    const administrationCost = _.orderBy(
      _.map(incomeOperatingCost.administration_cost, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );

    const repairAndManagementCost = _.orderBy(
      _.map(incomeOperatingCost.repair_and_management_cost, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );

    const utilitiesCost = _.orderBy(
      _.map(incomeOperatingCost.utilities_cost, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );

    const taxCost = _.orderBy(
      _.map(incomeOperatingCost.tax_cost, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );

    const insuranceCost = _.orderBy(
      _.map(incomeOperatingCost.insurance_cost, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );

    const totalReplacementReserves = _.orderBy(
      _.map(incomeOperatingCost.toal_replacement_reserves, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value.value }),
          perunit: formatNumberToString({ number: value.value / totalUnits }),
          position: value.position,
        };
      }),
      "position"
    );

    const otherData = [
      {
        name: "Management",
        total: formatNumberToString({
          number: incomeOperatingCost.total_management_cost.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.total_management_cost.value / totalUnits,
        }),
        detail: managmentCost,
      },
      {
        name: "Administration",
        total: formatNumberToString({
          number: incomeOperatingCost.total_administration_cost.value,
        }),
        perunit: formatNumberToString({
          number:
            incomeOperatingCost.total_administration_cost.value / totalUnits,
        }),
        detail: administrationCost,
      },
      {
        name: "Repair & Maintenance",
        total: formatNumberToString({
          number: incomeOperatingCost.total_repair_and_management.value,
        }),
        perunit: formatNumberToString({
          number:
            incomeOperatingCost.total_repair_and_management.value / totalUnits,
        }),
        detail: repairAndManagementCost,
      },
      {
        name: "Utilities",
        total: formatNumberToString({
          number: incomeOperatingCost.total_utilities_cost.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.total_utilities_cost.value / totalUnits,
        }),
        detail: utilitiesCost,
      },
      {
        name: "Taxes",
        total: formatNumberToString({
          number: incomeOperatingCost.total_tax_cost.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.total_tax_cost.value / totalUnits,
        }),
        detail: taxCost,
      },
      {
        name: "Insurance",
        total: formatNumberToString({
          number: incomeOperatingCost.total_insurance_cost.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.total_insurance_cost.value / totalUnits,
        }),
        detail: insuranceCost,
      },
    ];

    return [...otherData, ...totalReplacementReserves];
  }, [incomeOperatingCost, totalUnits]);

  const tableTotals = {
    name: "Total Gross Expenses",
    total: formatNumberToString({
      number: incomeOperatingCost.total_gross_expenses.value,
    }),
    perunit: formatNumberToString({
      number: incomeOperatingCost.total_gross_expenses.value / totalUnits,
    }),
  };

  const totals = [
    {
      label: "Total Gross Expenses",
      value: incomeOperatingCost.total_gross_expenses.value,
      type: ProformaHeaderTypeTotal.PRICE,
    },
    {
      label: "Per Unit",
      value: incomeOperatingCost.total_gross_expenses.value / totalUnits,
      type: ProformaHeaderTypeTotal.PRICE,
    },
  ];

  const donutChartData = [
    {
      value: incomeOperatingCost.total_administration_cost.value,
      label: `Administration: ${formatNumberToString({
        number: incomeOperatingCost.total_administration_cost.value,
      })}`,
      color: colors.pink100,
    },
    {
      value: incomeOperatingCost.total_repair_and_management.value,
      label: `Repair & Maintenance: ${formatNumberToString({
        number: incomeOperatingCost.total_repair_and_management.value,
      })}`,
      color: colors.pink80,
    },
    {
      value: incomeOperatingCost.total_utilities_cost.value,
      label: `Utilities: ${formatNumberToString({
        number: incomeOperatingCost.total_utilities_cost.value,
      })}`,
      color: colors.pink60,
    },
  ];

  return (
    <ProformaPaper
      title="Expenses"
      subTitle="Operating Expenses Breakdown"
      totals={totals}
    >
      <Stack direction="row">
        <Stack
          sx={{ flex: 1, paddingTop: theme.spacing(12) }}
          alignItems="center"
        >
          <Stack spacing={2}>
            <DonutChart data={donutChartData} width={200} />
            <Legend data={donutChartData} />
          </Stack>
        </Stack>
        <Box
          sx={{
            flex: 1,
            margin: theme.spacing(3, 0),
          }}
        >
          <ProformaTable
            rows={incomeAndOpexDetails}
            headers={headerIncomeAndOpex}
            totals={tableTotals}
          />
        </Box>
      </Stack>
    </ProformaPaper>
  );
};

export default ExpensesTable;

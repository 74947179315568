import React from "react";

import Box from "components/Box";
import Stack from "components/Stack";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionContentLabelValue from "entities/Memo/components/Sections/SectionContentLabelValue";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface IMemoTemplateTeam {
  groupName:
    | "Developer"
    | "Architect"
    | "General Contractor"
    | "Property Manager"
    | "Developer Partner";
}

const MemoTemplateTeam: React.FC<IMemoTemplateTeam> = ({ groupName }) => {
  return (
    <Box>
      <SectionHeader label={groupName} />
      <SectionContent>
        <Stack spacing={2}>
          <SectionContentLabelValue label="Company name" value="..." />
          <SectionContentLabelValue label="Office address" value="..." />
          <SectionContentLabelValue label="Established in" value="..." />
          <SectionContentLabelValue label="Company website" value="..." />
        </Stack>
      </SectionContent>
      <SectionHeader label="Description" />
      <Box>
        <p>This is an example {groupName} text.</p>
      </Box>
    </Box>
  );
};

export default MemoTemplateTeam;

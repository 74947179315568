import { useCallback, useState } from "react";

import CreateAISummariesDialog from "entities/AISummaries/components/CreateAISummariesDialog";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import { IFile } from "entities/File/sdk";

export interface ICreateAISummariesButton {
  dealId: number;
  createCallback: ({
    openAIDocumentAssistantId,
  }: {
    openAIDocumentAssistantId: number;
  }) => void;
  buttonRender: ({ onClick }: { onClick: () => void }) => JSX.Element;
  file?: IFile;
}

const CreateAISummariesButton = ({
  dealId,
  createCallback,
  buttonRender: ButtonRender,
  file,
}: ICreateAISummariesButton) => {
  const [isCreateAISummariesDialogOpen, setIsCreateAISummariesDialogOpen] =
    useState(false);

  const handleClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT,
    callback: () => setIsCreateAISummariesDialogOpen(true),
  });

  const handleCloseCreateAISummariesDialog = useCallback(
    () => setIsCreateAISummariesDialogOpen(false),
    []
  );

  const handleAISummaryCreate = useCallback(
    ({ openAIDocumentAssistantId }) => {
      createCallback({ openAIDocumentAssistantId });
      setIsCreateAISummariesDialogOpen(false);
    },
    [createCallback]
  );

  return (
    <>
      <ButtonRender onClick={handleClick} />

      <CreateAISummariesDialog
        dealId={dealId}
        file={file}
        isOpen={isCreateAISummariesDialogOpen}
        onClose={handleCloseCreateAISummariesDialog}
        onCreate={handleAISummaryCreate}
      />
    </>
  );
};

export default CreateAISummariesButton;

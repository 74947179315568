import React, { useMemo, useState } from "react";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";

import Collapse from "components/Collapse";
import ScrollableTable from "components/ScrollableTable";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Stack from "components/Stack";
import Text from "components/Text";

import { getMetricsRows } from "entities/Proforma/components/ProformaPLProjections/Tables/utils";
import { IPLProjection } from "entities/Proforma/sdk";

interface IMetricsTable {
  headers: IScrollableTableHeader;
  metricsData: IPLProjection["p_and_l"]["metrics"];
}

const MetricsTable = ({ headers, metricsData }: IMetricsTable) => {
  const [isOpen, setIsOpen] = useState(true);

  const metrics = useMemo(() => getMetricsRows({ metricsData }), [metricsData]);

  return (
    <Stack spacing={2} data-testid="metrics-table">
      <Text
        variant="h2"
        onClick={() => setIsOpen((open) => !open)}
        sx={{ cursor: "pointer" }}
      >
        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />} Metrics
      </Text>
      <Collapse in={isOpen} timeout="auto">
        <ScrollableTable headers={headers} rows={metrics} dynamicHyphen />
      </Collapse>
    </Stack>
  );
};

export default React.memo(MetricsTable);

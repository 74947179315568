export const FEATURE_INSTRUCTIONS = `<b>How it works?</b><p>Under AI summaries, you will find our preloaded prompt packages to be used on specific document types. The prompt questions have been pre-engineered and fine-tuned in the background, we are only displaying their crystallized form. First, upload the required source documents listed under the instructions of each package, then you can start generating answers.
<br/>
<br/>
Some packages contain complex summaries of multiple documents analyzed simultaneously while with other packages only a single document is expected to be analyzed. In either case, you can always upload more than one file. You can upload them directly by navigate to an AI summary page first, or earmark and transfer file attachments from within checklists.</p>
<br/>
<b>Need more AI summary package types? </b>
<p>AI summaries and their corresponding prompt packages are set up based on your organization’s need. If modifications and refining are needed, or you would like to set up additional prompt packages, please reach out to our Customer Success Team.</p>
`;

export enum PromptPackageCategory {
  Project = "Project",
  Legal = "Legal",
  Underwriting = "Underwriting",
  Other = "Other",
}

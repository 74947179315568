import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { AddIcon, LogoIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useRedirectUponFetchFailure } from "utils/common";
import { BOX_MAX_WIDTH } from "utils/constants";

import Box from "components/Box";
import DealAvatar from "components/DealAvatar";
import Divider from "components/Divider";
import IconButton from "components/IconButton";
import Image from "components/Image";
import Link from "components/Link";
import { LinearProgress } from "components/Loading";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import PrintableLayout from "components/PrintableLayout";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import AddSectionButton from "entities/MemoTemplate/components/AddSectionButton";
import EditMemoTemplateIconButton from "entities/MemoTemplate/components/EditIconButton";
import MemoTemplateSectionsWrapperWithRightsideMenu from "entities/MemoTemplate/components/SectionsWrapperWithRightsideMenu";
import {
  IMemoTemplateSection,
  useMemoTemplateDetails,
} from "entities/MemoTemplate/sdk";

const AddSectionInlineButton_ = ({
  afterMemoSectionId,
}: {
  afterMemoSectionId: null | number; // It's null if it's in the start of the memo
}) => {
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Divider sx={{ flex: 1 }} />
        </Box>
        <Box
          sx={{
            flexBasis: "34px",
            flexShrink: 0,
            flexGrow: 0,
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <AddSectionButton afterMemoSectionId={afterMemoSectionId}>
            <IconButton sx={{ borderRadius: 0 }} title="Add Section">
              <AddIcon />
            </IconButton>
          </AddSectionButton>
        </Box>
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Divider sx={{ flex: 1 }} />
        </Box>
      </Box>
    </Box>
  );
};

const AddSectionInlineButton = React.memo(AddSectionInlineButton_);

const MemoTemplateSectionPaper_ = ({
  section,
}: {
  section: IMemoTemplateSection;
}) => {
  return (
    <>
      <Paper sx={{ padding: theme.spacing(2) }}>
        <MemoTemplateSectionsWrapperWithRightsideMenu section={section} />
      </Paper>
      <AddSectionInlineButton afterMemoSectionId={section.memo_section_id} />
    </>
  );
};

const MemoTemplateSectionPaper = React.memo(MemoTemplateSectionPaper_);

const MemoEditor = () => {
  const params = useParams<{ memoId: string }>();
  const memoId = Number(params.memoId);
  const {
    data: memo,
    isValidating,
    mutate: mutateMemo,
    loading,
    error,
  } = useMemoTemplateDetails({
    memo_id: memoId,
  });

  useRedirectUponFetchFailure({
    error,
    loading,
  });

  usePageTitle(
    _.isUndefined(memo)
      ? "Builders Patch: Memo templates"
      : `Builders Patch: ${memo.name} - Memo template`
  );

  return (
    <Stack
      spacing={2}
      sx={{
        padding: theme.spacing(4, 3),
      }}
    >
      <PageHeader
        backTitle="Memo templates"
        backLink={URLS.MEMO_TEMPLATE_LIST}
        title={memo?.name ? memo.name : "Edit"}
        actions={
          memo && (
            <EditMemoTemplateIconButton
              memoId={memo?.memo_id}
              onUpdated={mutateMemo}
            />
          )
        }
      />

      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: colors.blue10,
          "@media print": {
            padding: "0",
          },
        }}
      >
        {isValidating && <LinearProgress />}
        <Stack
          spacing={2}
          direction="row"
          alignItems="flex-start"
          sx={{
            margin: "auto",
            width: "fit-content",
            "@media print": {
              display: "block",
            },
          }}
        >
          <Paper
            sx={{
              width: BOX_MAX_WIDTH,
              flexShrink: 0,
              flexGrow: 0,
              overflowX: "auto",
              padding: theme.spacing(2, 4),
              "@media print": {
                flexGrow: 1,
                marginLeft: "0 !important",
                width: "100%",
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            <PrintableLayout
              header={
                <>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <Text variant="text4" sx={{ fontWeight: "bold" }}>
                        Example deal name
                      </Text>
                      <Text variant="text4">{memo?.name}</Text>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Text variant="text4">-- meeting date --</Text>
                      </Stack>
                    </Stack>
                    <Image
                      sx={{
                        height: "56px",
                        maxWidth: "50%",
                        marginBottom: "2px",
                      }}
                      src={undefined}
                    />
                  </Stack>
                  <Divider sx={{ marginBottom: theme.spacing(2) }} />
                </>
              }
              footer={
                <>
                  <Divider
                    sx={{
                      marginTop: theme.spacing(3),
                      marginBottom: theme.spacing(1),
                    }}
                  />
                  <Stack direction="row" spacing={1}>
                    <LogoIcon
                      style={{
                        height: "40px",
                        width: "auto",
                        borderRadius: theme.spacing(0.5),
                      }}
                    />
                    <Box>
                      <Text sx={{ lineHeight: "144%" }}>
                        This document was created using Builders Patch
                      </Text>
                      <Link to="https://builderspatch.com/" external>
                        <Text
                          fontWeight="700"
                          sx={{ color: colors.blue100, lineHeight: "100%" }}
                        >
                          builderspatch.com
                        </Text>
                      </Link>
                    </Box>
                  </Stack>
                </>
              }
            >
              <Paper
                sx={{
                  backgroundColor: colors.blue10,
                  borderRadius: 0,
                }}
              >
                <Stack direction="row" spacing={2}>
                  <DealAvatar
                    src={undefined}
                    imgix
                    imgixWidth={480}
                    imgixHeight={240}
                    sx={{
                      width: "240px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                  <Stack>
                    <Text variant="label" sx={{ color: colors.blue100 }}>
                      {memo?.name}
                    </Text>
                    <Text variant="h1">Example deal</Text>
                  </Stack>
                </Stack>
              </Paper>
              <Paper sx={{ padding: theme.spacing(2) }}>
                <AddSectionInlineButton afterMemoSectionId={null} />
                {memo?.sections.map((section) => (
                  <MemoTemplateSectionPaper
                    section={section}
                    key={section.memo_section_id}
                  />
                ))}
              </Paper>
            </PrintableLayout>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default MemoEditor;

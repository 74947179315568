import _ from "lodash";
import Pusher, { Channel } from "pusher-js/with-encryption";

import { BASE_URL } from "config/urls";
import { getEnvironmentVariables } from "utils/environment";
import { getToken } from "utils/storage";

const PUSHER_KEY = getEnvironmentVariables().VITE_PUSHER_KEY;
const PUSHER_CLUSTER = getEnvironmentVariables().VITE_PUSHER_CLUSTER;

// Channel names
const GLOBAL_USER_SPECIFIC_CHANNEL_NAME = ({ userId }: { userId: number }) =>
  `private-encrypted-global-user-specific-${userId}`;

// Event names
const ZIP_READY_EVENT = "zip-ready-event";
const ZIP_FAILED_EVENT = "zip-failed-event";
const TASK_CHAT = "chat-message-update-event";
const ASSET_MANAGEMENT_EXCEL_PROCESSING_SUCCEEDED =
  "asset-management-excel-processing-succeeded-event";
const ASSET_MANAGEMENT_EXCEL_PROCESSING_FAILED =
  "asset-management-excel-processing-failed-event";
const UNDERWRITING_READY_EVENT = "underwriting-proforma-ready-event";
const DUE_DILIGENCE_FILE_PROCESSING_SUCCEEDED_EVENT =
  "due-diligence-file-processing-succeeded-event";
const DUE_DILIGENCE_FILE_PROCESSING_FAILED_EVENT =
  "due-diligence-file-processing-failed-event";

const OPENAI_PROMPT_RESPONSE_READY_EVENT = "openai-prompt-response-ready-event";
const ASSET_MANAGEMENT_FILE_PROCESSING_SUCCEEDED_EVENT =
  "asset-management-file-processing-succeeded-event";
const ASSET_MANAGEMENT_FILE_PROCESSING_FAILED_EVENT =
  "asset-management-file-processing-failed-event";
const DEAL_PROPERTY_UPDATE_EVENT = "deal-property-update-event";
const NEW_NOTIFICATION_EVENT = "new-notification-event";
const OPENAI_TABLE_RESPONSE_SUCCEEDED_EVENT =
  "openai-table-response-succeeded-event";
const OPENAI_TABLE_RESPONSE_FAILED_EVENT = "openai-table-response-failed-event";

const DEAL_TABLE_UPDATE_SUCCEEDED_EVENT = "deal-table-update-succeeded-event";
const TABLE_UPDATE_SUCCEEDED_EVENT = "table-update-succeeded-event";
const TABLE_OR_DEAL_TABLE_UPDATE_FAILED_EVENT =
  "table-or-deal-table-update-failed-event";
const DEAL_COMPANY_IMPORT_TABLE_SHEET_NAME_NOT_FOUND_EVENT =
  "deal-company-import-table-sheet-name-not-found-event";
const DEAL_COMPANY_IMPORT_TABLE_UPDATE_FAILED_EVENT =
  "deal-company-import-table-update-failed-event";

class PusherManager {
  pusherClient: Pusher | null;
  channels: { [channelName: string]: Channel | null };

  constructor() {
    this.pusherClient = null;
    this.channels = {};
  }
  initPusherClient = () => {
    if (!PUSHER_KEY || !PUSHER_CLUSTER) {
      throw Error;
    }

    return new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      channelAuthorization: {
        transport: "ajax",
        endpoint: `${BASE_URL}v2/integrations/pusher/authenticate-client/`,
        headers: {
          Authorization: `jwt ${getToken()}`,
        },
      },
    });
  };

  getPusherClient = () => {
    if (_.isNil(this.pusherClient)) {
      this.pusherClient = this.initPusherClient();
    }

    return this.pusherClient;
  };

  getAndCreateChannelIfDoesNotExist = ({
    channelName,
  }: {
    channelName: string;
  }): Channel => {
    let channel = this.channels[channelName];

    if (_.isNil(channel)) {
      const pusherClient = this.getPusherClient();
      channel = pusherClient.subscribe(channelName);
      this.channels[channelName] = channel;
    }

    return channel;
  };

  bindToZipReadyEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: () => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = ZIP_READY_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToZipFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: (data: any) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = ZIP_FAILED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToTaskChat = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: (data: any) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = TASK_CHAT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToAssetManagementExcelProcessingSucceededEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: (data: any) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = ASSET_MANAGEMENT_EXCEL_PROCESSING_SUCCEEDED;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToAssetManagementExcelProcessingFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({ detail }: { detail: string }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = ASSET_MANAGEMENT_EXCEL_PROCESSING_FAILED;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  disconnect = () => {
    if (_.isNil(this.pusherClient)) {
      return;
    }

    this.pusherClient.disconnect();
    this.pusherClient = null;
  };

  bindToUnderwritingProformaReadyEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({ deal_id }: { deal_id: number }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = UNDERWRITING_READY_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToDueDiligenceFileProcessingSucceededEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({
      deal_id,
      company_package_id,
      butler_data_import_id,
    }: {
      deal_id: number;
      company_package_id: number;
      butler_data_import_id: number;
    }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = DUE_DILIGENCE_FILE_PROCESSING_SUCCEEDED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToDueDiligenceFileProcessingFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: () => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = DUE_DILIGENCE_FILE_PROCESSING_FAILED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToOpenAIPromptResponseReadyEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({
      deal_id,
      openai_document_assistant_id,
      prompt_response_id,
    }: {
      deal_id: number;
      openai_document_assistant_id: number;
      prompt_response_id: number;
    }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = OPENAI_PROMPT_RESPONSE_READY_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToAssetManagementFileProcessingSucceededEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({
      deal_id,
      import_id,
    }: {
      deal_id: number;
      import_id: number;
    }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = ASSET_MANAGEMENT_FILE_PROCESSING_SUCCEEDED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToAssetManagementFileProcessingFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: () => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = ASSET_MANAGEMENT_FILE_PROCESSING_FAILED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToDealPropertyUpdateEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({ deal_id }: { deal_id: number }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = DEAL_PROPERTY_UPDATE_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToNewNotificationEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: () => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = NEW_NOTIFICATION_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToTableResponseSucceededEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({
      openai_table_response_id,
    }: {
      openai_table_response_id: number;
    }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = OPENAI_TABLE_RESPONSE_SUCCEEDED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToTableResponseFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: () => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = OPENAI_TABLE_RESPONSE_FAILED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToDealTableUpdateSucceededEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({
      deal_id,
      table_id,
      table_name,
    }: {
      deal_id: number;
      table_id: number;
      table_name: string;
    }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = DEAL_TABLE_UPDATE_SUCCEEDED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToTableUpdateSucceededEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({
      table_id,
      table_name,
    }: {
      table_id: number;
      table_name: string;
    }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = TABLE_UPDATE_SUCCEEDED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToTableOrDealTableUpdateFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({ details }: { details: string }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = TABLE_OR_DEAL_TABLE_UPDATE_FAILED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToDealCompanyImportTableSheetNameNotFoundEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({ details }: { details: string }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = DEAL_COMPANY_IMPORT_TABLE_SHEET_NAME_NOT_FOUND_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };

  bindToDealCompanyImportTableUpdateFailedEvent = ({
    userId,
    eventHandler,
  }: {
    userId: number;
    eventHandler: ({ details }: { details: string }) => void;
  }) => {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });
    const eventName = DEAL_COMPANY_IMPORT_TABLE_UPDATE_FAILED_EVENT;

    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName, eventHandler);
    };
  };
}

const pusherManager = new PusherManager();

export default pusherManager;

import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { MoreHorizIcon } from "icons";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Tag from "components/Tag";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import UserAvatar from "components/UserAvatar";

import {
  deleteTeamMember,
  IDealAccess,
  updateTeamMember,
  useDealTeamMembers,
} from "entities/Team/sdk";

export interface IDealAccessTable {
  dealAccesses: Array<IDealAccess>;
  loggedUserIsDealAdmin: boolean;
}

const DealAccessTable = ({
  dealAccesses,
  loggedUserIsDealAdmin,
}: IDealAccessTable) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  const { mutate: updateTeamMembersList } = useDealTeamMembers({ dealId });

  const handleUpdateEmployee = useCallback(
    ({ dealAccess, isAdmin }: { dealAccess: IDealAccess; isAdmin: boolean }) =>
      updateTeamMember({
        dealId,
        teamMemberId: dealAccess.id, // updates DealCompanyUserAccess not User !!!!!
        data: { is_admin: isAdmin },
      }).then(() => {
        toast.successMessage(
          `The member '${dealAccess.user.first_name} ${
            dealAccess.user.last_name
          }' successfully ${isAdmin ? "added as admin" : "revoked as admin"}!`
        );
        updateTeamMembersList();
      }),
    [updateTeamMembersList, dealId]
  );

  const deleteEmployee = useCallback(
    ({ dealAccess }: { dealAccess: IDealAccess }) =>
      deleteTeamMember({
        dealId,
        teamMemberId: dealAccess.id, // deletes DealCompanyUserAccess not User !!!!
      }).then(() => {
        toast.successMessage(
          `The member '${dealAccess.user.first_name} ${dealAccess.user.last_name}' removed from deal.`
        );
        updateTeamMembersList();
      }),
    [updateTeamMembersList, dealId]
  );

  const handleRemoveEmployeeFromDeal = useCallback(
    ({ dealAccess }: { dealAccess: IDealAccess }) =>
      showConfirmationDialog({
        onConfirm: () => deleteEmployee({ dealAccess }),
        message: "Are you sure you want to remove this member from the deal?",
      }),
    [deleteEmployee, showConfirmationDialog]
  );

  const tableIdentifier: string = useMemo(() => {
    const firstTeamMember = _.first(dealAccesses);

    if (firstTeamMember) {
      return `${firstTeamMember.deal_company_permission.company.name}(${firstTeamMember.deal_company_permission.persona})`;
    }

    return "";
  }, [dealAccesses]);

  return (
    <Table
      size="small"
      sx={{ border: `1px solid ${colors.blue20}` }}
      id={tableIdentifier}
      data-testid="deal-team-members-table"
    >
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
              backgroundColor: colors.blue20,
              color: colors.black,
            },
          }}
          data-testid="deal-team-table-headers"
        >
          <TableCell width={400}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-name"
            >
              Name
            </Text>
          </TableCell>
          <TableCell width={150}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-job"
            >
              Job title
            </Text>
          </TableCell>
          <TableCell width={400}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-email"
            >
              Email
            </Text>
          </TableCell>
          <TableCell width={300}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-phone-no"
            >
              Phone No.
            </Text>
          </TableCell>
          <TableCell width={300}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-persona"
            >
              Persona
            </Text>
          </TableCell>
          <TableCell width={100}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-status"
            >
              Status
            </Text>
          </TableCell>
          <TableCell width={100}>
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="table-header-admin"
            >
              Admin role
            </Text>
          </TableCell>
          {loggedUserIsDealAdmin && (
            <TableCell
              width="55"
              data-testid="table-header-admin-action-button"
            ></TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody data-testid="deal-team-table-body">
        {dealAccesses.map((dealAccess) => (
          <TableRow
            key={dealAccess.user.id}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
            data-testid="deal-team-member-data-row"
          >
            <TableCell data-testid="name-cell-data">
              <Tooltip
                title={`${dealAccess.user.first_name} ${dealAccess.user.last_name}`}
                placement="top-start"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <UserAvatar
                    src={
                      dealAccess.user.avatar
                        ? dealAccess.user.avatar
                        : undefined
                    }
                    imgix
                    imgixWidth={40}
                    imgixHeight={40}
                    variant="rounded"
                    sx={{ width: 30, height: 30 }}
                    dataTestid={
                      dealAccess.user.avatar
                        ? "custom-team-member-avatar"
                        : "default-team-member-avatar"
                    }
                  />
                  <Text variant="text2" data-testid="company-member-full-name">
                    {_.truncate(
                      `${dealAccess.user.first_name} ${dealAccess.user.last_name}`,
                      {
                        length: 15,
                      }
                    )}
                  </Text>
                </Stack>
              </Tooltip>
            </TableCell>
            <TableCell data-testid="job-cell-data">
              <Tooltip
                title={dealAccess.user.designation}
                placement="top-start"
              >
                <Text variant="labelSmall" data-testid="company-member-job">
                  {_.truncate(dealAccess.user.designation, { length: 10 })}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell data-testid="email-cell-data">
              <Tooltip title={dealAccess.user.email} placement="top-start">
                <Text variant="text2" data-testid="company-member-email">
                  {_.truncate(dealAccess.user.email, { length: 30 })}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell data-testid="phone-cell-data">
              <Tooltip title={dealAccess.user.contact} placement="top-start">
                <Text variant="labelSmall" data-testid="company-member-phone">
                  {_.truncate(dealAccess.user.contact, { length: 10 })}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell data-testid="persona-cell-data">
              <Tooltip
                title={dealAccess.deal_company_permission.persona}
                placement="top-start"
              >
                <Text variant="labelSmall" data-testid="company-member-persona">
                  {dealAccess.deal_company_permission.persona}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell data-testid="status-cell-data">
              <Stack
                alignItems="flex-start"
                data-testid="company-member-status"
              >
                {dealAccess.user.is_active ? "Active" : "Deactivated"}
              </Stack>
            </TableCell>
            <TableCell data-testid="admin-cell-data">
              <Stack alignItems="flex-start">
                {dealAccess.is_admin && dealAccess.user.is_active && (
                  <Tag
                    label="Deal admin"
                    sx={{ whiteSpace: "nowrap" }}
                    dataTestid="is-deal-admin"
                  />
                )}
              </Stack>
            </TableCell>
            {loggedUserIsDealAdmin && (
              <TableCell data-testid="admin-actions-cell-data">
                <ButtonDropdown
                  icon={<MoreHorizIcon />}
                  label=""
                  variant="text"
                  width="auto"
                  disabled={!dealAccess.user.is_active}
                  buttonDataTestid="admin-actions-button"
                >
                  {dealAccess.is_admin ? (
                    <MenuItem
                      onClick={() =>
                        handleUpdateEmployee({ dealAccess, isAdmin: false })
                      }
                      dataTestid="action-option"
                    >
                      <Text variant="text2" data-testid="action-label">
                        Revoke as admin
                      </Text>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() =>
                        handleUpdateEmployee({ dealAccess, isAdmin: true })
                      }
                      dataTestid="action-option"
                    >
                      <Text variant="text2" data-testid="action-label">
                        Add as admin
                      </Text>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => handleRemoveEmployeeFromDeal({ dealAccess })}
                    dataTestid="action-option"
                  >
                    <Text variant="text2" data-testid="action-label">
                      Remove from deal
                    </Text>
                  </MenuItem>
                </ButtonDropdown>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DealAccessTable;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import Button from "components/Button";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { IPackageSection } from "entities/Package/sdk";
import { ITaskChannel } from "entities/Task/sdk";

interface IDueDiligenceSectionOpenTaskButton {
  section: IPackageSection;
  taskChannels: Array<ITaskChannel>;
  dealId: number;
  tabId: number;
  categoryId: number;
  packageId: number;
  updatePackage: (message?: string) => void;
  taskButtonDataTestid?: string;
  taskMessagesButtonDataTestid?: string;
}

const DueDiligenceSectionOpenTaskButton = ({
  section,
  taskChannels,
  dealId,
  tabId,
  packageId,
  categoryId,
  updatePackage,
  taskButtonDataTestid,
  taskMessagesButtonDataTestid,
}: IDueDiligenceSectionOpenTaskButton) => {
  const { show } = useDealSidepanel();

  const unreadLabel = useMemo(
    () => _.sumBy(taskChannels, "unread_count"),
    [taskChannels]
  );

  const handleOpenTaskChat = useCallback(() => {
    const taskInitialData = {
      title: section.name,
      company_category: categoryId,
      company_section: section.section_id,
      company_package: packageId,
      company_tab: tabId,
    };

    const taskMessagesCountWithUnreadMessages = _.filter(
      taskChannels,
      (taskChannel) => taskChannel.unread_count > 0
    ).length;

    if (taskMessagesCountWithUnreadMessages === 1) {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          subTab: TASKS_TAB_SUBTABS.CHAT,
          taskChannel: taskChannels[0],
          dealId,
          taskSection: section,
          taskInitialData,
        },
      });
    } else {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          dealId,
          taskSection: section,
          taskInitialData,
        },
      });
    }
  }, [
    show,
    dealId,
    taskChannels,
    updatePackage,
    section,
    packageId,
    tabId,
    categoryId,
  ]);

  const handleOpenTask = useCallback(() => {
    if (taskChannels.length === 1) {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          subTab: TASKS_TAB_SUBTABS.DETAILS,
          taskChannel: taskChannels[0],
          taskSection: section,
          taskInitialData: {
            title: section.name,
            company_section: section.section_id,
            company_package: packageId,
            company_tab: tabId,
            company_category: categoryId,
          },
          dealId,
        },
      });
    } else {
      show({
        meta: {
          tab: TABS.TASKS,
          updateParent: updatePackage,
          dealId,
          taskSection: section,
          taskInitialData: {
            title: section.name,
            company_section: section.section_id,
            company_package: packageId,
            company_tab: tabId,
            company_category: categoryId,
          },
        },
      });
    }
  }, [
    show,
    dealId,
    updatePackage,
    taskChannels,
    section,
    packageId,
    tabId,
    categoryId,
  ]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleOpenTask}
        data-testid={taskButtonDataTestid}
      >
        {`${taskChannels.length} task${taskChannels.length > 1 ? "s" : ""}`}
      </Button>
      {unreadLabel > 0 && (
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpenTaskChat}
          data-testid={taskMessagesButtonDataTestid}
        >
          {unreadLabel} message{unreadLabel > 1 ? "s" : ""}
        </Button>
      )}
    </>
  );
};

export default DueDiligenceSectionOpenTaskButton;

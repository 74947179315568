import { useMemo } from "react";
import _ from "lodash";

import theme from "theme";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";

import ProjectEditButton from "entities/Deal/components/OverviewProject/ProjectEditButton";
import ProjectImage from "entities/Deal/components/OverviewProject/ProjectImage";
import ProjectTable from "entities/Deal/components/OverviewProject/ProjectTable";
import { IDeal, useDealPermission } from "entities/Deal/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";

interface ISectionProjectDetails {
  deal: IDeal;
  sectionName?: string;
  section?: IMemoSection;
  externalToken?: string;
  onUpdate?: () => void;
}

const SectionProjectDetails: React.FC<ISectionProjectDetails> = ({
  deal,
  sectionName = "",
  section,
  externalToken,
  onUpdate,
}) => {
  const { data: userPermission } = useDealPermission({ dealId: deal.id });

  const hasEditAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  return (
    <Box>
      <SectionHeader
        label={section?.title || sectionName}
        alignActionButton={section ? "right" : "left"}
        actionButton={
          _.isUndefined(externalToken) && (
            <NoPrint>
              <Stack direction="row" spacing={1} alignItems="center">
                {!section?.is_na && <ProjectEditButton />}
                {section && (
                  <MemoSectionContextButton
                    dealId={deal.id}
                    section={section}
                    onUpdate={onUpdate}
                  />
                )}
              </Stack>
            </NoPrint>
          )
        }
      />
      <SectionContent>
        {section?.is_na ? (
          <NASection />
        ) : (
          <>
            <Box sx={{ marginBottom: theme.spacing(2) }}>
              <ProjectTable deal={deal} externalToken={externalToken} />
            </Box>
            <ProjectImage
              deal={deal}
              externalToken={externalToken}
              editable={hasEditAccess}
            />
          </>
        )}
      </SectionContent>
    </Box>
  );
};

export default SectionProjectDetails;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { useUuid } from "utils/common";

import { ISimpleTable } from "components/SimpleTable";
import { TableCell, TableHead, TableRow } from "components/Table";
import Text from "components/Text";

const Headers: React.FC<{
  headers: ISimpleTable["headers"];
  showDeleteCell?: boolean;
}> = ({ headers, showDeleteCell }) => {
  const uuid = useUuid();
  return (
    <TableHead
      sx={{ backgroundColor: colors.blue20 }}
      data-testid="table-headers"
    >
      <TableRow data-testid="table-headers-section">
        {headers.map((header) => (
          <TableCell
            key={header.key}
            sx={{
              borderRight: `1px solid ${colors.blue40}`,
              borderBottom: `1px solid ${colors.blue40}`,
              padding: theme.spacing(1.25, 2),
              width: `${header.width}px`,
            }}
            data-testid="header-cell"
          >
            <Text
              whiteSpace="pre-line"
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-column-name"
            >
              {header.label}
            </Text>
          </TableCell>
        ))}
        {showDeleteCell && (
          <TableCell
            key={uuid}
            sx={{
              borderRight: `1px solid ${colors.blue40}`,
              borderBottom: `1px solid ${colors.blue40}`,
              padding: theme.spacing(1.25, 2),
              width: theme.spacing(7),
            }}
            data-testid="delete-header-cell"
          ></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default Headers;

import { useCallback } from "react";
import _ from "lodash";

import { colors } from "theme/palette";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import CustomResponse from "entities/AISummaries/components/CustomResponse";
import Prompt from "entities/AISummaries/components/Prompt";
import PromptResponse from "entities/AISummaries/components/PromptResponse";
import { useOpenAIPromptResponseReady } from "entities/AISummaries/hooks";
import { IOpenAIPrompt, usePromptList } from "entities/AISummaries/sdk";

export interface IPromptsAndAnswers {
  hasOpenaAIAnswerInProcess: boolean;
  onGenerateAnswer: () => void;
  openAIDocumentAssistantId: number;
}

const PromptsAndAnswers = ({
  hasOpenaAIAnswerInProcess,
  onGenerateAnswer,
  openAIDocumentAssistantId,
}: IPromptsAndAnswers) => {
  const {
    data: prompts,
    mutate: mutatePrompts,
    isLoading: promptsAreLoading,
  } = usePromptList({
    openAIDocumentAssistantId,
  });

  const eventHandler = useCallback(() => {
    mutatePrompts();
  }, [mutatePrompts]);

  useOpenAIPromptResponseReady({
    eventHandler,
  });

  const showCustomResponse = useCallback(
    ({ prompt }: { prompt: IOpenAIPrompt }) =>
      (!_.isEmpty(prompt.prompt_responses) &&
        !_.isNil(prompt.prompt_responses[0].readable_answer)) ||
      !_.isEmpty(prompt.custom_responses),
    []
  );

  return (
    <Stack spacing={2}>
      <Text variant="label" color={colors.gray60} fontWeight={600}>
        Prompts & answers
      </Text>
      {promptsAreLoading ? (
        <Stack spacing={2}>
          <Skeleton variant="text" height="40px" />
          <Skeleton variant="text" height="40px" />
          <Skeleton variant="text" height="40px" />
        </Stack>
      ) : (
        <Stack spacing={2}>
          {prompts?.map((prompt) => (
            <Stack spacing={2} key={prompt.id}>
              <Prompt
                prompt={prompt}
                openAIDocumentAssistantId={openAIDocumentAssistantId}
                isSubmitButtonDisabled={hasOpenaAIAnswerInProcess}
                onClickGenerateAnswer={onGenerateAnswer}
                onGenerateAnswer={mutatePrompts}
              />
              {prompt.prompt_responses.map((promptResponse) => (
                <PromptResponse
                  key={promptResponse.id}
                  openAIDocumentAssistantId={openAIDocumentAssistantId}
                  promptResponse={promptResponse}
                  onDelete={mutatePrompts}
                />
              ))}
              {showCustomResponse({ prompt }) && (
                <CustomResponse
                  initialCustomResponse={_.first(prompt.custom_responses)}
                  promptId={prompt.id}
                  openAIDocumentAssistantId={openAIDocumentAssistantId}
                  onUpdated={mutatePrompts}
                />
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default PromptsAndAnswers;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { DeleteIcon } from "icons";

import Box from "components/Box";
import Button from "components/Button";
import Image from "components/Image";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import {
  checkFilePermission,
  deleteDueDiligenceFile,
  uploadFileToCustomField,
} from "entities/Field/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import UploadImage from "entities/Memo/components/Sections/Image/UploadImage";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";

interface ISectionImage {
  section: IMemoSection;
  dealId: number;
  externalToken?: string;
  onUpdate: () => void;
}

const SectionImage: React.FC<ISectionImage> = ({
  dealId,
  section,
  externalToken,
  onUpdate,
}) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleUploadImage = useCallback(
    (imageId: number) => {
      if (!_.isEmpty(section.fields)) {
        const fieldId = _.first(section.fields)?.company_custom_field_id;
        if (fieldId) {
          uploadFileToCustomField({
            dealId,
            customFieldId: fieldId,
            fileId: imageId,
          }).then((response) => {
            toast.successMessage(
              `The image ${response[0].file.original_file_name} successfully uploaded!`
            );
            onUpdate();
          });
        }
      }
    },
    [section, dealId, onUpdate]
  );

  const onDeleteFile = useCallback(() => {
    if (section.fields && section.fields[0].values) {
      const customValue = section.fields[0].values[0];
      deleteDueDiligenceFile({
        dealId,
        customFieldId: section.fields[0].company_custom_field_id,
        customValueId: customValue.value_id,
      }).then(() => {
        toast.successMessage(
          `The image ${customValue.file?.original_file_name} successfully deleted!`
        );
        setImageUrl(undefined);
        onUpdate();
      });
    }
  }, [dealId, section, onUpdate]);

  const handleDeleteImage = useCallback(() => {
    showConfirmationDialog({
      onConfirm: () => onDeleteFile(),
      message: "Are you sure you want to delete this image?",
    });
  }, [onDeleteFile, showConfirmationDialog]);

  const imageId = useMemo(() => {
    if (
      section.fields &&
      section.fields[0].values &&
      section.fields[0].values.length > 0 &&
      section.fields[0].values[0].file
    ) {
      return section.fields[0].values[0].file.id;
    }

    return null;
  }, [section]);

  useEffect(() => {
    const fieldId = _.first(section.fields)?.company_custom_field_id;

    if (!_.isNull(imageId) && !_.isNil(fieldId)) {
      checkFilePermission({
        fileId: imageId,
        fieldId,
      }).then(({ url }) => {
        setImageUrl(url);
      });
    }
  }, [imageId, section]);

  return (
    <Box>
      <SectionHeader
        label={section.title}
        alignActionButton="right"
        actionButton={
          _.isUndefined(externalToken) && (
            <NoPrint>
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )
        }
      />
      <SectionContent breakInside="avoid">
        <Box
          sx={{
            breakInside: "avoid",
          }}
        >
          {section.is_na ? (
            <NASection />
          ) : (
            <>
              {_.isNull(imageId) && _.isUndefined(externalToken) && (
                <NoPrint>
                  <UploadImage onChange={handleUploadImage} />
                </NoPrint>
              )}
              {imageUrl && (
                <Stack spacing={2}>
                  <Image
                    src={imageUrl}
                    sx={{ height: "460px", width: "100%", objectFit: "cover" }}
                  />
                  {_.isUndefined(externalToken) && (
                    <NoPrint
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <Button
                        sx={{ alignSelf: "flex-end" }}
                        variant="text"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteImage}
                      >
                        Delete image
                      </Button>
                    </NoPrint>
                  )}
                </Stack>
              )}
            </>
          )}
        </Box>
      </SectionContent>
    </Box>
  );
};

export default SectionImage;

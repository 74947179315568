import Stack from "components/Stack";

import ApplicationSubmitConfirmationDialogButton from "entities/Application/components/ApplicationSubmitConfirmationDialogButton";
import DiscardButton from "entities/Application/components/DiscardButton";
import DraftSavedSuccessfullyDialogButton from "entities/Application/components/DraftSavedSuccessfullyDialogButton";

export interface IFormButtons {
  applicationId: number;
  applicationName: string;
  onSubmit: () => void;
  isSubmitButtonDisabled: boolean;
  submitButtonTooltipText: string;
}

const FormButtons = ({
  applicationId,
  applicationName,
  onSubmit,
  isSubmitButtonDisabled,
  submitButtonTooltipText,
}: IFormButtons) => (
  <Stack direction="row" spacing={1} justifyContent="flex-end">
    <DiscardButton applicationId={applicationId} />
    <DraftSavedSuccessfullyDialogButton applicationName={applicationName} />
    <ApplicationSubmitConfirmationDialogButton
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      submitButtonTooltipText={submitButtonTooltipText}
      applicationId={applicationId}
      applicationName={applicationName}
      onSubmit={onSubmit}
    />
  </Stack>
);

export default FormButtons;

import _ from "lodash";

import {
  EthnicityOptions,
  GenderOptions,
  VeteranStatusOptions,
} from "entities/CompanyItegrations/components/Downhome/constants";
import { IDownhomePersonParticipant } from "entities/CompanyItegrations/sdk";

import { IDownhomeOwnerProperties } from "./constants";

export const checklistDataConvertToBusinessOwner = ({
  ownerData,
}: {
  ownerData: IDownhomeOwnerProperties | undefined;
}): IDownhomePersonParticipant => {
  if (_.isUndefined(ownerData)) {
    return {
      primary: false,
      first_name: "",
      last_name: "",
      date_of_birth: null,
      veteran_status: null,
      gender: null,
      ethnic_group: null,
    };
  }

  const gender =
    _.find(
      GenderOptions,
      (option) =>
        option.label.toLowerCase() === ownerData.gender?.toLowerCase() ||
        option.value.toLowerCase() === ownerData.gender?.toLowerCase()
    )?.value || "";

  const veteranStatus =
    _.find(
      VeteranStatusOptions,
      (option) =>
        option.label.toLowerCase() ===
          ownerData.veteran_status?.toLowerCase() ||
        option.value.toLowerCase() === ownerData.veteran_status?.toLowerCase()
    )?.value || "";

  const ethnicGroup =
    _.find(
      EthnicityOptions,
      (option) =>
        option.label.toLowerCase() === ownerData.ethnic_group?.toLowerCase() ||
        option.value.toLowerCase() === ownerData.ethnic_group?.toLowerCase()
    )?.value || "";

  return {
    ..._.pick(ownerData, [
      "primary",
      "first_name",
      "last_name",
      "date_of_birth",
    ]),
    veteran_status: veteranStatus,
    gender,
    ethnic_group: ethnicGroup,
  };
};

import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import TextField from "components/TextField";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import { dealTableCreate, useDealDetails } from "entities/Deal/sdk";
import PasteTableField from "entities/TableStorage/components/PasteTableField";
import { VALIDATION_SCHEMA } from "entities/TableStorage/constants";
import { ITableCell, ITableRow } from "entities/TableStorage/sdk";

interface IFormValues {
  name: string;
  rows: Array<Array<ITableCell>>;
}

const initialValues: IFormValues = {
  name: "",
  rows: [],
};

const Create = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });

  usePageTitle(
    deal?.name
      ? `Builders Patch: ${deal.name} - Create a table`
      : "Builders Patch: Create a table"
  );

  const navigate = useNavigate();

  const onSaveTable = useCallback(
    (values: IFormValues, formik) => {
      dealTableCreate({
        ...values,
        dealId,
      })
        .then(({ id }) => {
          formik.resetForm();
          toast.successMessage("Table created successfully");
          navigate(
            reverse(URLS.DEAL_TABLE_STORAGE_DETAILS, { dealId, tableId: id })
          );
        })
        .catch(handleInvalidRequest);
    },
    [dealId, navigate]
  );

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: onSaveTable,
  });

  const handlePasteTableData = useCallback(
    ({ rows }: { rows: Array<ITableRow> }) => {
      const fieldRows: Array<Array<ITableCell>> = rows.map((row) => row.cells);
      formik.setFieldValue("rows", fieldRows);
    },
    [formik]
  );

  const handleClose = useCallback(
    () => navigate(reverse(URLS.DEAL_TABLE_STORAGE_LIST, { dealId })),
    [dealId, navigate]
  );

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          spacing={4}
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader
            title="Create a table"
            backTitle="Saved data tables"
            backLink={reverse(URLS.DEAL_TABLE_STORAGE_LIST, { dealId })}
          />
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                label="Table name"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter table name"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && !_.isEmpty(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <PasteTableField
                showFieldError={
                  formik.touched.rows && !_.isEmpty(formik.errors.rows)
                }
                fieldError={
                  formik.touched.rows && !_.isUndefined(formik.errors.rows)
                    ? String(formik.errors.rows)
                    : undefined
                }
                showFieldLabel
                onPaste={handlePasteTableData}
              />
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button variant="text" onClick={handleClose}>
                  Cancel
                </Button>
                <Button disabled={formik.isSubmitting} type="submit">
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Create;

import React from "react";

import Stack from "components/Stack";

import SectionSummary from "entities/MemoTemplate/components/Sections/SectionSummary";
import { proformaDemoData } from "entities/MemoTemplate/components/Sections/utils";
import ProformaFloorArea from "entities/Proforma/components/ProformaFloorArea";

const MemoTemplateFloorArea = () => {
  return (
    <Stack justifyContent="space-between" spacing={4}>
      <ProformaFloorArea rentalIncomeData={proformaDemoData.rental_data} />
      <SectionSummary title="Floor Area description" />
    </Stack>
  );
};

export default MemoTemplateFloorArea;

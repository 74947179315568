import { ArrowDropDownIcon, ArrowRightIcon } from "icons";

import TextCell from "components/ScrollableTable/Cells/TextCell";
import Stack from "components/Stack";

export interface ITitleCell {
  row: { [key: string]: any };
  columnKey: string;
  isExpanded?: boolean;
}

const TitleCell = ({ row, columnKey, isExpanded }: ITitleCell) => {
  const hasChildren = row.children?.length > 0;

  return (
    <Stack direction="row">
      {hasChildren && (isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
      <TextCell cellData={row[columnKey]} title={row[columnKey]} />
    </Stack>
  );
};

export default TitleCell;

import React, { useCallback, useState } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import { CloseIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import DealAvatar from "components/DealAvatar";
import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import Link from "components/Link";
import Loading from "components/Loading";
import SidepanelHeader from "components/SidepanelHeader";
import Stack from "components/Stack";
import Text from "components/Text";

import { usePipelineColumns, useSidePanel } from "entities/Deal/sdk";

import AssignedTeamTabContent, {
  TAB_KEY as ASSIGNED_TEAM_TAB_KEY,
} from "./AssignedTeamTabContent";
import DealSidepanelTabs from "./DealSidepanelTabs";
import PhaseAndStatusTabContent, {
  TAB_KEY as PHASE_AND_STATUS_TAB_KEY,
} from "./PhaseAndStatusTabContent";
import ProjectDetailsTabContent, {
  TAB_KEY as PROJECT_DETAILS_TAB_KEY,
} from "./ProjectDetailsTabContent";

const DRAWER_WIDTH = "800px";

export interface IDealSidepanel {
  open: boolean;
  dealId: number;
  onClose(): void;
}

const DealSidepanel: React.FC<IDealSidepanel> = ({ open, dealId, onClose }) => {
  const { data: deal, mutate: refetchSidePanel } = useSidePanel(dealId);
  const { data: columns = [] } = usePipelineColumns({});

  const [selectedTab, setSelectedTab] = useState("projectDetails");

  const handleChangeTab = useCallback(
    (tab: string) => {
      setSelectedTab(tab);
    },
    [setSelectedTab]
  );

  if (!deal) {
    return null;
  }

  const projectDetailsdeal = deal["project_details"];

  return (
    <>
      {!deal && <Loading open={true} />}
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            display: "flex",
          },
        }}
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <SidepanelHeader>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={4}
          >
            <DealAvatar
              src={projectDetailsdeal["Project Avatar"] || ""}
              sx={{ width: 200, height: "auto" }}
            />
            <Stack alignItems="flex-start">
              <Text
                variant="text3"
                sx={{
                  display: "flex",
                  color: colors.black,
                  paddingX: theme.spacing(1),
                  backgroundColor: colors.gray5,
                }}
                data-testid="header-section-deal-id"
              >
                #{dealId}
              </Text>
              <Text
                variant="h2"
                sx={{ marginBottom: theme.spacing(3) }}
                data-testid="header-section-deal-name"
              >
                {projectDetailsdeal["Project Name"]}
              </Text>
              <Link
                to={reverse(URLS.DEAL_OVERVIEW, { dealId })}
                linkDataTestid="header-section-deal-link"
              >
                <Button data-testid="header-section-deal-link-button">
                  Project page
                </Button>
              </Link>
            </Stack>
          </Stack>
          <IconButton
            onClick={onClose}
            sx={{ alignSelf: "flex-start" }}
            title="Close"
          >
            <CloseIcon data-testid="header-section-close-side-panel-button" />
          </IconButton>
        </SidepanelHeader>
        <Box
          sx={{
            padding: theme.spacing(2, 6, 2, 4),
          }}
        >
          <DealSidepanelTabs onChange={handleChangeTab} />
          <Stack
            spacing={4}
            sx={{
              paddingTop: theme.spacing(4),
            }}
          >
            {selectedTab === PROJECT_DETAILS_TAB_KEY && (
              <ProjectDetailsTabContent
                projectDetails={_.get(deal, "project_details")}
                columns={columns}
              />
            )}
            {selectedTab === ASSIGNED_TEAM_TAB_KEY && (
              <AssignedTeamTabContent
                sidepanelData={deal}
                refetchSidePanel={refetchSidePanel}
                dealId={dealId}
              />
            )}
            {selectedTab === PHASE_AND_STATUS_TAB_KEY && (
              <PhaseAndStatusTabContent
                dealId={dealId}
                refetchSidePanel={refetchSidePanel}
                phaseAndStatus={_.get(deal, "phase_status")}
              />
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default DealSidepanel;

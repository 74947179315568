import _ from "lodash";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { ICompanySettings } from "entities/Company/sdk";

import { ECompanySettingsFeatureVisibleType } from "./constants";

export const getCompanySettingsFeatureVisibleType = ({
  feature,
  companySettings,
}: {
  feature: ECompanySettingsFeatureType;
  companySettings: ICompanySettings;
}): ECompanySettingsFeatureVisibleType => {
  const isEnabled = _.get(companySettings, `${feature}_enabled`, false);
  const isShown = _.get(companySettings, `${feature}_shown`, false);

  if (!isEnabled && !isShown) {
    return ECompanySettingsFeatureVisibleType.DISABLED_HIDDEN;
  } else if (!isEnabled && isShown) {
    return ECompanySettingsFeatureVisibleType.DISABLED_VISIBLE;
  }
  return ECompanySettingsFeatureVisibleType.ENABLED;
};

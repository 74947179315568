import React from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

interface IWorkspacePageTitleSkeleton {}

const WorkspacePageTitleSkeleton: React.FC<
  IWorkspacePageTitleSkeleton
> = () => {
  return (
    <Box>
      <Paper sx={{ background: colors.blue10 }}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={150} height={50} />
          </Box>
          <Stack direction="row" alignItems="flex-end" spacing={2}>
            <Skeleton variant="text" width={150} height={50} />
            <Skeleton variant="text" width={150} height={50} />
            <Skeleton variant="text" width={150} height={50} />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default WorkspacePageTitleSkeleton;

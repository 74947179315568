import { memo, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Alert from "components/Alert";
import Box from "components/Box";
import FixedTable from "components/FixedTable";
import Stack from "components/Stack";
import Text from "components/Text";

import { IField } from "entities/Field/sdk";
import { IFieldValueHistoryItem } from "entities/Package/sdk";

export interface IFixedTableFieldHistoryElement {
  field: IField;
  value: IFieldValueHistoryItem;
}

const FixedTableFieldHistoryElement = ({
  field,
  value,
}: IFixedTableFieldHistoryElement) => {
  const [showFieldError, setShowFieldError] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isArray(value.column_value)) {
      setShowFieldError(true);
    }
  }, [value]);

  const columnValueIsEmpty = useMemo(
    () => _.isEmpty(value.column_value),
    [value]
  );

  const timestamp = useMemo(
    () => format(parseJSON(value.uploaded_at), FORMATS.FRONTEND.DATETIME),
    [value]
  );

  const creatorText = useMemo(() => {
    if (!_.isNil(value.creator)) {
      return `${value.creator.first_name} ${value.creator.last_name}`;
    }
    return "Past proforma upload";
  }, [value]);

  if (!field.header_name) {
    return null;
  }

  return (
    <Stack data-testid="field-history-entity">
      <Text
        variant="text2"
        sx={{ color: colors.gray60, width: 600 }}
        data-testid="entity-title"
      >
        <b>{creatorText}</b> {!columnValueIsEmpty ? "updated" : "deleted"} value
        at {timestamp}
      </Text>
      {!columnValueIsEmpty ? (
        <Box
          sx={{ marginY: theme.spacing(3) }}
          data-testid="dd-table-field-table"
        >
          {!_.isNil(value.column_value) && (
            <FixedTable
              rows={value.column_value}
              headers={field.header_name.map(({ headerName, field }) => ({
                label: headerName,
                key: field,
              }))}
            />
          )}
          {showFieldError && (
            <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
              There was an error displaying table values.
            </Alert>
          )}
        </Box>
      ) : (
        <Text variant="h6" sx={{ width: 600 }} data-testid="entity-value">
          (deleted)
        </Text>
      )}
    </Stack>
  );
};

export default memo(FixedTableFieldHistoryElement);

import React, { useCallback } from "react";
import { SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";

import { colors } from "theme/palette";

import DatePicker from "components/DatePicker";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";

interface IDateInfo {
  formik: ReturnType<typeof useFormik>;
}

const QUARTER_OPTIONS = [
  { value: "", label: "-" },
  { value: "1", label: "Q1" },
  { value: "2", label: "Q2" },
  { value: "3", label: "Q3" },
  { value: "4", label: "Q4" },
];

const FormDateInfo: React.FC<IDateInfo> = ({ formik }: IDateInfo) => {
  const handleQuarterChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue("quarter", event.target.value);
    },
    [formik]
  );
  const handleYearChange = useCallback(
    (date: Date | null) => {
      formik.setFieldValue("year", date);
    },
    [formik]
  );

  return (
    <Stack spacing={3} width="50%">
      <div data-testid="timeframe-year">
        <DatePicker
          views={["year"]}
          label={
            <Text
              variant="label"
              color={colors.blue100}
              fontWeight="500"
              data-testid="field-label"
            >
              Year
            </Text>
          }
          onChange={handleYearChange}
          value={formik.getFieldProps("year").value}
          slotProps={{
            textField: {
              inputProps: { "data-testid": "year-input" },
            },
          }}
        />
      </div>
      <Select
        id="form-date-quarter"
        {...formik.getFieldProps("quarter")}
        value={formik.getFieldProps("quarter").value ?? ""}
        label={
          <Text
            variant="label"
            color={colors.blue100}
            fontWeight="500"
            data-testid="field-label"
          >
            Quarter
          </Text>
        }
        onChange={handleQuarterChange}
        options={QUARTER_OPTIONS}
        error={formik.values.month && formik.values.quarter}
        inputProps={{ "data-testid": "quarter-dropdown-input" }}
        data-testid="timeframe-quarter-dropdown"
        dropdownOptionsDataTestid="quarter-dropdown-option"
      />
    </Stack>
  );
};

export default FormDateInfo;

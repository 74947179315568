import { useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { isNotUndefined } from "utils/common";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import PageHeader from "components/PageHeader";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import FeaturesLimit from "entities/Company/components/CompanySettings/FeaturesLimit";
import FeaturesList from "entities/Company/components/CompanySettings/FeaturesList";
import {
  COMPANY_SETTINGS_OPTIONS,
  ECompanySettingsFeatureVisibleType,
} from "entities/Company/components/CompanySettings/FeaturesList/constants";
import CustomerDetailsSkeleton from "entities/Superuser/components/CustomerDetailsSkeleton";
import {
  companySettingsLimitUpdate,
  companySettingsUpdate,
  useSuperuserCompanyRetrieve,
} from "entities/Superuser/sdk";

const Details = () => {
  const params = useParams<{
    companyId: string;
  }>();
  const companyId = Number(params.companyId);

  const { data: company, mutate: mutateCompany } = useSuperuserCompanyRetrieve({
    companyId,
  });

  usePageTitle(
    !_.isUndefined(company)
      ? `Builders Patch: ${company.name} - Customer details`
      : "Builders Patch: Customer details"
  );

  const handleUpdateSettings = useCallback(
    ({
      feature,
      value,
    }: {
      feature: ECompanySettingsFeatureType;
      value: ECompanySettingsFeatureVisibleType;
    }) => {
      if (!_.isUndefined(company)) {
        companySettingsUpdate({
          companyId,
          action: value,
          feature,
        })
          .then(() => {
            const featureLabel = _.find(
              COMPANY_SETTINGS_OPTIONS,
              (option) => option.value === feature
            )?.label;

            toast.successMessage(
              `Company setting "${featureLabel}" was successfully updated!`
            );
            mutateCompany();
          })
          .catch(handleInvalidRequest);
      }
    },
    [company, companyId, mutateCompany]
  );
  const handleUpdateLimit = useCallback(
    ({
      feature,
      limit,
    }: {
      feature:
        | ECompanySettingsFeatureType.MAX_DEAL_COUNT
        | ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT
        | ECompanySettingsFeatureType.MAX_CHECKLIST_COUNT
        | ECompanySettingsFeatureType.MAX_INTAKE_CHECKLIST_COUNT;
      limit: number;
    }) => {
      if (!_.isUndefined(company)) {
        return companySettingsLimitUpdate({
          companyId,
          feature,
          limit,
        })
          .then(() => {
            toast.successMessage(
              `Company setting ${feature} was successfully updated!`
            );
            mutateCompany();
          })
          .catch(handleInvalidRequest);
      }
    },
    [company, companyId, mutateCompany]
  );

  return (
    <Stack
      sx={{
        padding: theme.spacing(4, 3, 0),
      }}
      spacing={2}
    >
      <PageHeader
        title={
          isNotUndefined(company) ? company.name : <Skeleton width={200} />
        }
        backLink={URLS.SUPERUSER_CUSTOMER_LIST}
        backTitle="Customer list"
      />
      <Box sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}>
        {isNotUndefined(company) ? (
          <Stack spacing={10}>
            <Stack spacing={1}>
              <Text variant="h3">Product usage & settings</Text>
              <FeaturesList
                editable={true}
                companySettings={company.settings}
                onChange={handleUpdateSettings}
              />
            </Stack>
            <Stack spacing={1}>
              <Text variant="h3">Item limitations (max. allowed)</Text>
              <FeaturesLimit
                editable={true}
                companySettings={company.settings}
                onUpdate={handleUpdateLimit}
              />
            </Stack>
          </Stack>
        ) : (
          <CustomerDetailsSkeleton />
        )}
      </Box>
    </Stack>
  );
};

export default Details;

import { useMemo } from "react";
import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";

import MemoRichtextfield from "entities/Memo/components/Fields/Richtextfield";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import { IMemoSection } from "entities/Memo/sdk";

interface ISectionComment {
  section: IMemoSection;
  dealId: number;
  onUpdate: () => void;
  externalToken?: string;
}

const SectionComment: React.FC<ISectionComment> = ({
  section,
  dealId,
  externalToken,
  onUpdate,
}) => {
  const field = useMemo(() => _.first(section.fields), [section]);

  if (!field) {
    return null;
  }

  return (
    <Box>
      <SectionContent>
        <Box
          sx={{
            marginTop: "8px",
            "& .MuiIconButton-root": { visibility: "hidden" },
            "&:hover .MuiIconButton-root": {
              visibility: "visible",
            },
          }}
        >
          <MemoRichtextfield
            field={field}
            dealId={dealId}
            externalToken={externalToken}
            onUpdate={onUpdate}
            isNASection={section.is_na}
            adornment={
              _.isUndefined(externalToken) && (
                <NoPrint sx={{ marginLeft: "auto", marginRight: -1 }}>
                  <MemoSectionContextButton
                    dealId={dealId}
                    section={section}
                    onUpdate={onUpdate}
                  />
                </NoPrint>
              )
            }
          />
        </Box>
      </SectionContent>
    </Box>
  );
};

export default SectionComment;

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import { IField } from "entities/Field/sdk";
import { usePackageDealDetails } from "entities/Package/sdk";
import { IInitialValues } from "entities/Task/components/Form";
import { DUE_DILIGENCE_TAG_NAME } from "entities/Task/constants";
import { useTagList } from "entities/Task/sdk";

interface IDueDiligenceFieldNewTaskButton {
  companyField: IField;
  dealId: number;
  packageId: number;
  categoryId: number;
  sectionId: number;
  tabId: number;
  menuOptionDataTestid?: string;
}

const DueDiligenceFieldNewTaskButton = ({
  companyField,
  packageId,
  tabId,
  categoryId,
  sectionId,
  dealId,
  menuOptionDataTestid,
}: IDueDiligenceFieldNewTaskButton) => {
  const { openCreateNewTaskSubTab } = useDealSidepanel();
  const { data: tagList } = useTagList();

  const { mutate: updatePackage } = usePackageDealDetails({
    packageId,
    dealId,
    queryParams: { tab_id: tabId },
  });

  const handleCreateNewTask = useCallback(() => {
    const initialValues: IInitialValues = {
      title: companyField.display_name,
      company_field: companyField.field_id,
      company_package: packageId,
      company_tab: tabId,
      company_category: categoryId,
      company_section: sectionId,
      assignee_id: null,
      tags:
        tagList
          ?.filter((tag) => tag.name === DUE_DILIGENCE_TAG_NAME)
          .map((tag) => tag.id) || [],
    };
    openCreateNewTaskSubTab({
      taskField: companyField,
      updateParent: updatePackage,
      initialValues: initialValues,
    });
  }, [
    companyField,
    packageId,
    tabId,
    categoryId,
    sectionId,
    tagList,
    updatePackage,
    openCreateNewTaskSubTab,
  ]);

  return (
    <MenuItem onClick={handleCreateNewTask} data-testid={menuOptionDataTestid}>
      <Text variant="text2" data-testid="action-menu-option-name">
        Create new task
      </Text>
    </MenuItem>
  );
};

export default DueDiligenceFieldNewTaskButton;

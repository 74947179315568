export enum EMemoSectionType {
  HEADER = "header",
  DUE_DILIGENCE = "due_diligence",
  LOCATION = "location",
  IMAGE = "image",
  COMMENT = "comment",
  PROJECT_DETAILS = "project_details",
  PROJECT_NARRATIVE = "project_narrative",
  EXECUTIVE_SUMMARY = "executive_summary",
  RISKS_AND_MITIGATIONS = "risks_and_mitigations",
  FINAL_RECOMMENDATIONS = "final_recommendations",
  SCHEDULE = "schedule",
  UNIT_AMENITIES = "unit_amenities",
  COMMUNITY_AMENITIES = "community_amenities",
  SOURCE_AND_USES = "source_and_uses",
  DEVELOPMENT_BUDGET = "development_budget",
  UNIT_MIX_AND_RENT = "unit_mix_and_rent",
  INCOME_AND_OPEX = "income_and_opex",
  DEVELOPER = "developer",
  ARCHITECT = "architect",
  GENERAL_CONTRACTOR = "general_contractor",
  PROPERTY_MANAGER = "property_manager",
  DEVELOPER_PARTNER = "developer_partner",
  FLOOR_AREA = "Floor Area",
  EXCEL_TABLE = "excel_table",
  AI_SUMMARY_RESPONSE = "ai_summary_response",
}

export enum EMemoSectionLabel {
  HEADER = "Header",
  DUE_DILIGENCE = "Due Diligence",
  LOCATION = "Location",
  IMAGE = "Image",
  COMMENT = "Comment",
  PROJECT_DETAILS = "Project details",
  PROJECT_NARRATIVE = "Project narrative",
  EXECUTIVE_SUMMARY = "Executive Summary",
  RISKS_AND_MITIGATIONS = "Risks And Mitigations",
  FINAL_RECOMMENDATIONS = "Final Recommendations",
  SCHEDULE = "Schedule",
  UNIT_AMENITIES = "Unit Amenities",
  COMMUNITY_AMENITIES = "Community Amenities",
  SOURCES_AND_USES = "Sources and Uses",
  DEVELOPMENT_BUDGET = "Development Budget",
  UNIT_MIX_AND_RENT = "Unit Mix And Rent",
  INCOME_AND_OPEX = "Income And Opex",
  DEVELOPER = "Developer",
  ARCHITECT = "Architect",
  GENERAL_CONTRACTOR = "General Contractor",
  PROPERTY_MANAGER = "Property Manager",
  DEVELOPER_PARTNER = "Developer Partner",
  FLOOR_AREA = "Floor Area",
  EXCEL_TABLE = "Excel Table",
  AI_SUMMARY_RESPONSE = "AI Summary Response",
}

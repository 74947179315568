import theme from "theme";

import Box from "components/Box";
import Paper from "components/Paper";

import { ICoreData } from "entities/Proforma/sdk";

import SourcesAndUsesConstruction from "./SourcesAndUsesConstruction";
import SourcesAndUsesPermanent from "./SourcesAndUsesPermanent";

export interface IProformaSourcesAndUses {
  coreData: ICoreData;
}

const ProformaSourcesAndUses: React.FC<IProformaSourcesAndUses> = ({
  coreData,
}) => (
  <Box data-testid="sources-and-uses-content">
    <Paper
      sx={{ marginBottom: theme.spacing(4) }}
      data-testid="construction-section"
    >
      <SourcesAndUsesConstruction coreData={coreData} />
    </Paper>
    <Paper data-testid="permanent-section">
      <SourcesAndUsesPermanent coreData={coreData} />
    </Paper>
  </Box>
);

export default ProformaSourcesAndUses;

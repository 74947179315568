import { useCallback, useState } from "react";
import { KeyedMutator } from "swr";

import { PaginatedResponse } from "utils/sdk";

import Button from "components/Button";

import MoveToUnderwritingDialog from "entities/Application/components/MoveToUnderwritingDialog";
import { ILenderApplication } from "entities/Application/sdk";
import DealIsInUnderwritingDialog from "entities/Checklist/components/DealIsInUnderwritingDialog";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";

export interface IMoveToUnderwritingMenuItem {
  applicationId: number;
  projectName: string;
  numberOfUnits: string | null;
  buttonRender?: ({ onClick }: { onClick: () => void }) => JSX.Element;
  applicationRefetch?: KeyedMutator<PaginatedResponse<ILenderApplication>>;
}

const MoveToUnderwritingMenuItem = ({
  applicationId,
  buttonRender: MoveToUnderwritingButton = ({ onClick }) => (
    <Button onClick={onClick}>Move to deal pipeline</Button>
  ),
  applicationRefetch,
}: IMoveToUnderwritingMenuItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dealId, setDealId] = useState<number | null>(null);
  const [isOpenDealDialog, setIsOpenDealDialog] = useState(false);

  const handleSubmit = useCallback(({ dealId }: { dealId: number }) => {
    setDealId(dealId);
    setIsOpenDealDialog(true);
    setIsOpen(false);
  }, []);

  const handleBackToIntake = useCallback(() => {
    setIsOpenDealDialog(false);
    applicationRefetch?.();
  }, [applicationRefetch]);

  const handleDialogOpen = useCheckFeature({
    feature: ECompanySettingsFeatureType.MAX_DEAL_COUNT,
    callback: () => setIsOpen(true),
  });

  const handleDialogClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <MoveToUnderwritingButton onClick={handleDialogOpen} />
      <MoveToUnderwritingDialog
        isOpen={isOpen}
        onClose={handleDialogClose}
        applicationId={applicationId}
        onSubmit={handleSubmit}
      />
      {dealId && isOpenDealDialog && (
        <DealIsInUnderwritingDialog
          dealId={dealId}
          isOpen={isOpenDealDialog}
          onBackToIntake={handleBackToIntake}
        />
      )}
    </>
  );
};

export default MoveToUnderwritingMenuItem;

import React from "react";

import { EXTERNAL_API_DOCS_URL } from "config/urls";
import { colors } from "theme/palette";

import Button from "components/Button";
import Link from "components/Link";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import { ICompany } from "entities/Company/sdk";

export interface IExternalAPIsInfoBox {
  company: ICompany;
}

// Used for anchoring
export const ID = "external-apis-info-box";

const ExternalAPIsInfoBox: React.FC<IExternalAPIsInfoBox> = ({ company }) => (
  <Paper id={ID}>
    <Stack spacing={2}>
      <Text
        variant="text1"
        fontWeight={700}
        sx={{ borderBottom: `1px solid ${colors.gray100}` }}
      >
        External APIs
      </Text>

      <Text variant="body2">
        External APIs are enabled for this company. You can find more
        information about the available APIs in the documentation.
      </Text>

      <Link external to={EXTERNAL_API_DOCS_URL} target="_blank">
        <Button>External APIs Documentation</Button>
      </Link>
    </Stack>
  </Paper>
);

export default ExternalAPIsInfoBox;

import { useCallback, useMemo } from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Divider from "components/Divider";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import CheckboxFieldHistoryElement from "entities/Field/components/CheckboxFieldHistoryElement";
import DropdownFieldHistoryElement from "entities/Field/components/DropdownFieldHistoryElement";
import FileFieldHistory from "entities/Field/components/FileFieldHistory";
import FixedTableFieldHistoryElement from "entities/Field/components/FixedTableFieldHistoryElement";
import RadioButtonFieldHistoryElement from "entities/Field/components/RadioButtonFieldHistoryElement";
import RichTextFieldHistoryElement from "entities/Field/components/RichTextFieldHistoryElement";
import TableFieldHistoryElement from "entities/Field/components/TableFieldHistoryElement";
import TextFieldHistoryElement from "entities/Field/components/TextFieldHistoryElement";
import { FieldType, IField } from "entities/Field/sdk";
import {
  IFieldValueHistoryItem,
  useFieldValuesHistory,
} from "entities/Package/sdk";

export interface IFieldHistoryDialog {
  onClose: () => void;
  field: IField;
  dealId: number;
}

const FieldHistoryDialog = ({
  dealId,
  field,
  onClose,
}: IFieldHistoryDialog) => {
  const { data: fieldValues, loading } = useFieldValuesHistory({
    dealId,
    fieldId: field.field_id,
  });

  const renderFieldHistoryElement = useCallback(
    ({
      field,
      value,
      index,
    }: {
      field: IField;
      value: IFieldValueHistoryItem;
      index: number;
    }) => {
      const fieldsLength = fieldValues?.length || 0;
      const isLastElement = index === fieldsLength - 1;

      switch (field.field_type) {
        case FieldType.TEXT_FIELD: {
          return (
            <>
              <TextFieldHistoryElement value={value} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        case FieldType.RICH_TEXT_FIELD: {
          return (
            <>
              <RichTextFieldHistoryElement value={value} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        case FieldType.CHECKBOX: {
          return (
            <>
              <CheckboxFieldHistoryElement value={value} field={field} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        case FieldType.RADIO_BUTTON: {
          return (
            <>
              <RadioButtonFieldHistoryElement value={value} field={field} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        case FieldType.DROPDOWN: {
          return (
            <>
              <DropdownFieldHistoryElement value={value} field={field} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        case FieldType.TABLE: {
          return (
            <>
              <TableFieldHistoryElement value={value} field={field} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        case FieldType.FIXED_TABLE: {
          return (
            <>
              <FixedTableFieldHistoryElement value={value} field={field} />
              {!isLastElement && <Divider sx={{ width: "100%" }} />}
            </>
          );
        }
        default: {
          return null;
        }
      }
    },
    [fieldValues]
  );

  const valuesAreEmpty = useMemo(
    () => fieldValues?.length === 0,
    [fieldValues]
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      dialogDataTestid="field-history-dialog"
    >
      <Paper
        sx={{
          paddingBottom: 0,
          paddingTop: 2,
          paddingX: 2,
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              backgroundColor: colors.gray10,
              borderRadius: 2,
              padding: 3,
            }}
          >
            <Stack spacing={1}>
              <Text variant="h1" data-testid="dialog-header">
                Field values history
              </Text>
              <Text
                variant="text2"
                color={colors.gray60}
                data-testid="dialog-info-text"
              >
                Here you can see all the previous values for{" "}
                <b>{field.display_name}</b> field.
              </Text>
            </Stack>
          </Box>
          <Stack
            spacing={2}
            sx={{ maxHeight: "500px", overflowY: "auto", paddingBottom: 2 }}
            data-testid="history-list"
          >
            {loading && (
              <Skeleton
                variant="rectangular"
                width={600}
                height={70}
                sx={{ borderRadius: 2 }}
              />
            )}

            {valuesAreEmpty && !loading && (
              <Text
                variant="h6"
                sx={{ width: 600 }}
                data-testid="empty-history-text"
              >
                There are no values for this field
              </Text>
            )}

            {field.field_type === FieldType.FILE ? (
              <FileFieldHistory values={fieldValues} />
            ) : (
              fieldValues?.map((value, index) =>
                renderFieldHistoryElement({ value, field, index })
              )
            )}
          </Stack>
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default FieldHistoryDialog;

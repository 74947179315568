import React from "react";

import { colors } from "theme/palette";
import { typography } from "theme/typography";

import Button from "components/Button";

export interface IDealStatusButton {
  label: string;
  variant: "text" | "outlined" | "contained" | undefined;
  onClick: () => void;
  dataTestid?: string;
}
const DealStatusButton: React.FC<IDealStatusButton> = ({
  label,
  variant,
  onClick,
  dataTestid,
}) => {
  return (
    <Button
      color="secondary"
      variant={variant}
      size="small"
      sx={{
        ...typography.text3,
        padding: "0.3em 0.75em 0.25em",
        "&.MuiButton-outlined": {
          color: colors.gray40,
          "&:hover": {
            color: colors.black,
          },
        },
      }}
      onClick={onClick}
      data-testid={dataTestid}
    >
      {label}
    </Button>
  );
};

export default DealStatusButton;

import { useCallback, useEffect, useState } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import SearchField from "components/SearchField";
import Stack from "components/Stack";
import Text from "components/Text";

import { ICompanyPackageAccess } from "entities/Deal/sdk";

export interface IChecklistsList {
  packageOptions: Array<ICompanyPackageAccess>;
  selectedPackageId: number | null;
  onSelect: ({ packageId }: { packageId: number }) => void;
}

const ChecklistsList = ({
  packageOptions,
  selectedPackageId,
  onSelect,
}: IChecklistsList) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredPackageOptions, setFilteredPackageOptions] =
    useState<Array<ICompanyPackageAccess>>(packageOptions);

  const handleSelect = useCallback(
    ({ packageId }: { packageId: number }) => onSelect({ packageId }),
    [onSelect]
  );

  useEffect(() => {
    setFilteredPackageOptions(
      packageOptions.filter(({ name }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, packageOptions]);

  return (
    <>
      <SearchField
        value={searchTerm}
        placeholder="Search"
        size="small"
        onChange={(event) => setSearchTerm(event.target.value)}
        sx={{ margin: theme.spacing(0, 2) }}
        dataTestid="search-text-input"
      />
      <Stack
        sx={{
          height: "400px",
          padding: theme.spacing(1),
          border: `1px solid ${colors.blue60}`,
          margin: theme.spacing(1, 2, 0, 2),
          borderRadius: 1,
          overflowY: "auto",
        }}
        data-testid="checklists-list-section"
      >
        {filteredPackageOptions.length === 0 ? (
          <Text variant="body1" data-testid="empty-checklist-list">
            No packages.
          </Text>
        ) : (
          <Stack spacing={1}>
            {filteredPackageOptions.map(
              ({ id, name, items_count: itemsCount }, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2.5}
                  sx={{
                    cursor: "pointer",
                    padding: theme.spacing(0.5, 1),
                    backgroundColor:
                      selectedPackageId === id ? colors.blue10 : "transparent",
                    borderRadius:
                      selectedPackageId === id ? theme.spacing(0.5) : "none",
                    "&:hover": {
                      backgroundColor: colors.blue10,
                      borderRadius: theme.spacing(0.5),
                    },
                  }}
                  onClick={() => handleSelect({ packageId: id })}
                  data-testid="checklist-list-entity"
                >
                  <Box>
                    <Text
                      variant="text2"
                      fontWeight={700}
                      data-testid="checklist-name"
                    >
                      {name}
                    </Text>
                    <Text variant="text4" data-testid="items-in-checklist">
                      {itemsCount} items
                    </Text>
                  </Box>
                </Stack>
              )
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ChecklistsList;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import { TableCell } from "components/Table";
import Tag from "components/Tag";

interface ITagsCell {
  tags: string[];
}

const TagsCell: React.FC<ITagsCell> = ({ tags }) => {
  return (
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      <Stack direction="row" flexWrap="wrap" alignItems="flex-start">
        {tags.map(
          (tag, index) =>
            tag.trim() && (
              <Tag
                key={index}
                label={tag.trim()}
                variant="gray20"
                sx={{
                  marginRight: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                }}
              />
            )
        )}
      </Stack>
    </TableCell>
  );
};

export default TagsCell;

import React from "react";

import {
  calculatePath,
  textCoordinates,
} from "components/DonutChart/Arc/utils";
import Tooltip from "components/Tooltip";

const Arc = ({
  value,
  total,
  angle,
  graphWidth,
  borderWidth,
  color,
}: {
  value: number;
  total: number;
  angle: number;
  graphWidth: number;
  borderWidth: number;
  color: string;
}) => {
  const activeAngle =
    Number.isNaN(value / total) || total / value === 1
      ? 359.99
      : (value / total) * 360;

  const innerRadius = (graphWidth - borderWidth * 2) / graphWidth;
  const outerRadius = 1;

  const { x, y } = textCoordinates({
    half: graphWidth / 2,
    angle: -angle - activeAngle / 2,
    radius: (innerRadius + outerRadius) / 2,
  });

  return (
    <g>
      <Tooltip title={((100 * value) / total).toFixed(2) + "%"}>
        <path
          d={calculatePath(
            activeAngle,
            angle,
            graphWidth,
            innerRadius,
            outerRadius
          )}
          fill={color}
          fill-opacity={0.8}
        />
      </Tooltip>
      {(value / total) * 100 >= 5 && (
        <text
          x={x}
          y={y + 8 /* text height correction so it stays in the center */}
          textAnchor="middle"
        >
          {((value / total) * 100).toFixed(2)}%
        </text>
      )}
    </g>
  );
};

export default Arc;

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";

import { notImplementedError } from "utils/common";

import DealFeatureDisabledDialog from "components/DealFeatureDisabledDialog";
import FeatureDisabledDialog from "components/FeatureDisabledDialog";

type IShowDialogProps =
  | {
      isDealFeature: true;
      dealCompanyName: string;
    }
  | { isDealFeature?: false; dealCompanyName?: never };

const FeatureDisabledContext = React.createContext({
  show: notImplementedError as ({
    isDealFeature,
    dealCompanyName,
  }: IShowDialogProps) => void,
  hide: notImplementedError,
  setOnClose: notImplementedError as Dispatch<SetStateAction<() => void>>,
});

interface ICommonFeatureDialogProps {
  onClose?: () => void;
}

interface IDealFeatureProps extends ICommonFeatureDialogProps {
  isDealFeature: true;
  dealCompanyName: string;
}

interface ICompanyFeatureProps extends ICommonFeatureDialogProps {
  isDealFeature?: false;
  dealCompanyName?: never;
}

type IuseFeatureDisabledProps = IDealFeatureProps | ICompanyFeatureProps;

export interface IFeatureDisabledContextProvider {
  children: React.ReactNode;
}

export const FeatureDisabledContextProvider = ({
  children,
}: IFeatureDisabledContextProvider) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDealFeature, setIsOpenDealFeature] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const [onClose, setOnClose] = useState(() => () => {});

  const show = useCallback(({ isDealFeature, dealCompanyName }) => {
    if (isDealFeature) {
      setIsOpenDealFeature(true);
      setCompanyName(dealCompanyName);
    } else {
      setIsOpen(true);
    }
  }, []);

  const hide = useCallback(() => {
    setIsOpen(false);
    setIsOpenDealFeature(false);
  }, []);

  return (
    <FeatureDisabledContext.Provider
      value={{
        show,
        hide,
        setOnClose,
      }}
    >
      {children}
      <DealFeatureDisabledDialog
        isOpen={isOpenDealFeature}
        companyName={companyName}
        onClose={onClose}
      />
      <FeatureDisabledDialog isOpen={isOpen} onClose={onClose} />
    </FeatureDisabledContext.Provider>
  );
};

export const useFeatureDisabledDialog = () => {
  const {
    show: showDialog,
    hide,
    setOnClose,
  } = useContext(FeatureDisabledContext);

  const show = useCallback(
    ({
      isDealFeature,
      dealCompanyName,
      onClose = hide,
    }: IuseFeatureDisabledProps) => {
      setOnClose(() => onClose);
      showDialog({ isDealFeature, dealCompanyName } as IShowDialogProps);
    },
    [hide, setOnClose, showDialog]
  );

  return { show, hide };
};

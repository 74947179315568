import { useCallback } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import { tableImportUpdate } from "entities/TableStorage/sdk";

import { VALIDATION_SCHEMA } from "./constants";

export interface IFormValues {
  sheet_name: string;
  column_start: string;
  column_end: string;
  row_start: number;
  row_end: number;
}

export interface ITableImportUpdateDialog {
  show: boolean;
  onClose: () => void;
  tableImportId: number;
  initialValues: IFormValues;
  updateCallback: () => void;
}

const TableImportUpdateDialog = ({
  show,
  onClose,
  tableImportId,
  initialValues,
  updateCallback,
}: ITableImportUpdateDialog) => {
  const formik = useFormik<IFormValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) =>
      tableImportUpdate({ values, tableImportId })
        .then(() => {
          toast.successMessage("Table import modified successfully");
          close();
          updateCallback();
        })
        .catch(handleInvalidRequest),
  });

  const close = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [formik, onClose]);

  return (
    <Dialog fullWidth maxWidth="lg" open={show} onClose={close}>
      <DialogTitle
        sx={{
          margin: 2,
          borderRadius: "8px",
          backgroundColor: colors.blue10,
        }}
      >
        <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
          Modify cell range of synced table
        </Text>
      </DialogTitle>

      <DialogContent sx={{ padding: 2, overflowY: "visible" }}>
        <Text variant="text3" fontWeight={500}>
          Cell range
        </Text>
        <Text variant="text4" color={colors.gray60} marginBottom={2}>
          Select the sheet and cell range that will get synced. The maximum
          allowed columns are 10 columns.
        </Text>

        <Stack direction="row" spacing={2}>
          {(
            [
              { label: "Sheet", name: "sheet_name" },
              { label: "Columns: START", name: "column_start" },
              { label: "Columns: END", name: "column_end" },
              { label: "Rows: START", name: "row_start" },
              { label: "Rows: END", name: "row_end" },
            ] as Array<{ label: string; name: keyof IFormValues }>
          ).map(({ label, name }) => (
            <Stack sx={{ flexBasis: "20%" }} key={name}>
              <Text variant="text3" fontWeight={500}>
                {label}
              </Text>
              <TextField
                InputLabelProps={{ shrink: true }}
                {...formik.getFieldProps(name)}
                error={formik.touched[name] && !_.isEmpty(formik.errors[name])}
                helperText={formik.touched[name] && formik.errors[name]}
              />
            </Stack>
          ))}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Button variant="text" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
        >
          Modify table import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TableImportUpdateDialog;

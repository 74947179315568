import React, { useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import theme from "theme";
import { colors } from "theme/palette";
import { formatISO, getNow } from "utils/datetime";
import pusherManager from "utils/pusher-manager";

import Divider from "components/Divider";
import { LinearProgress } from "components/Loading";
import Stack from "components/Stack";
import toast from "components/Toast";

import { useSignedUser } from "entities/Auth/sdk";
import ChatInputField from "entities/Task/components/ChatInputField";
import ChatMessage from "entities/Task/components/ChatMessage";
import EmptyChat from "entities/Task/components/EmptyChat";
import {
  IMessage,
  ITaskChannel,
  markAsReadMessages,
  sendNewMessage,
  useDealMessages,
} from "entities/Task/sdk";

const CHAT_SECTION_ID = "chat-section";

const TaskChatTabContent: React.FC<{
  taskChannel: ITaskChannel;
}> = ({ taskChannel }) => {
  const { data: user } = useSignedUser();
  // revalidate cache?
  const {
    data: messages,
    mutate: updateMessages,
    loading,
    error: messagesError,
  } = useDealMessages(taskChannel.id);

  const showDivider =
    _.last(messages)?.msg_from.pk !== user?.id &&
    _.last(messages)?.read_by_user === false;

  const readMessages = useMemo(
    () =>
      messages?.filter(
        (m) => m.read_by_user === null || m.read_by_user === true
      ),
    [messages]
  );

  useEffect(() => {
    if (!_.isUndefined(messagesError)) {
      toast.errorMessage(messagesError.detail);
    }
  }, [messagesError]);

  const unreadMessages = useMemo(
    () => messages?.filter((m) => m.read_by_user === false),
    [messages]
  );

  const eventHandler = useCallback(
    (receivedMsg: any) => {
      if (taskChannel?.id === receivedMsg.task_channel_id) {
        updateMessages();
      }
    },
    [updateMessages, taskChannel]
  );

  useEffect(() => {
    if (user) {
      return pusherManager.bindToTaskChat({ userId: user.id, eventHandler });
    }
  }, [eventHandler, user]);

  useEffect(() => {
    const el = window.document.getElementById(CHAT_SECTION_ID);
    if (el) {
      el.scroll({ behavior: "smooth", top: el.scrollHeight });
    }
  }, [taskChannel, messages]);

  useEffect(() => {
    markAsReadMessages(taskChannel.id);
  }, [taskChannel]);

  useEffect(() => {
    // Refetch chat messages on mount
    updateMessages();
  }, [updateMessages]);

  const handleSubmit = useCallback(
    (text: string) => {
      const id = uuidv4();
      updateMessages(
        (messages: IMessage[] | undefined) => {
          return (
            messages &&
            user && [
              ...messages,
              {
                message: text,
                msg_from: { ...user, pk: user.id },
                id,
                read_by_user: null,
                created_at: formatISO(getNow()),
                task_channel: {
                  id: taskChannel.id,
                },
              },
            ]
          );
        },
        { revalidate: false } // No revalidation here because the socket connection will revalidate this
      );

      return sendNewMessage(taskChannel.id, text);
    },
    [taskChannel, user, updateMessages]
  );

  const messagesArePresent = !_.isNil(messages) && !_.isEmpty(messages);

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Stack
        sx={{
          height: "100%",
          overflowY: "auto",
          maxHeight: "calc(100vh - 302px)",
        }}
        id={CHAT_SECTION_ID}
      >
        {loading && <LinearProgress />}

        {messagesArePresent && !loading && (
          <Stack spacing={2} sx={{ marginTop: theme.spacing(4) }}>
            {showDivider && (
              <>
                {readMessages &&
                  readMessages.map((m) => (
                    <ChatMessage message={m} key={m.id} />
                  ))}
                <Divider sx={{ borderColor: colors.blue20 }}>
                  New Messages
                </Divider>
                {unreadMessages &&
                  unreadMessages.map((m) => (
                    <ChatMessage message={m} key={m.id} />
                  ))}
              </>
            )}
            {!showDivider &&
              messages.map((m) => <ChatMessage message={m} key={m.id} />)}
          </Stack>
        )}

        {!messagesArePresent && !loading && <EmptyChat />}
      </Stack>

      <ChatInputField onSubmit={handleSubmit} />
    </Stack>
  );
};

export default TaskChatTabContent;

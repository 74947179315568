import { BASE_URL } from "config/urls";
import { deleteCall, post, useFetch } from "utils/sdk";

export interface INote {
  id: number;
  title: string;
  is_completed: boolean;
  created_at: string;
  updated_at: string;
}

export const useNoteList = (dealId: number) =>
  useFetch<INote[]>(`v2/deal/${dealId}/tasks/list/`);

export const addNote = (dealId: number, title: string) =>
  post(`${BASE_URL}v2/deal/${dealId}/tasks/add/`, { title });

export const updateNote = (
  noteId: number,
  data: { title: string; is_completed: boolean }
) => post(`${BASE_URL}v2/deal/tasks/${noteId}/update/`, data);

export const deleteNote = (noteId: number) =>
  deleteCall(`${BASE_URL}v2/deal/tasks/${noteId}/delete/`);

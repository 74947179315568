import { memo, useMemo } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { IInfiniteFetchResult } from "utils/sdk";

import Button from "components/Button";
import Chip from "components/Chip";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import CompanyChecklist from "entities/CompanyChecklist/components/CompanyChecklist";
import CompanyChecklistSkeleton from "entities/CompanyChecklist/components/CompanyChecklistSkeleton";
import { COMPANY_PACKAGE_CHIP_STYLE } from "entities/CompanyChecklist/constants";
import { ICompanyChecklist } from "entities/Package/sdk";

interface IChecklistPageSection {
  title: string;
  description: string;
  emptyStateText: string;
  useChecklists: () => IInfiniteFetchResult<ICompanyChecklist>;
}

const ChecklistPageSection = ({
  title,
  description,
  emptyStateText,
  useChecklists,
}: IChecklistPageSection) => {
  const {
    data: companyChecklists,
    size,
    setSize,
    total,
    isLoading,
    isValidating,
  } = useChecklists();

  const hasCompanyChecklists = useMemo(
    () => !_.isNil(companyChecklists) && companyChecklists.length > 0,
    [companyChecklists]
  );

  const showLoadMoreButton = useMemo(
    () => companyChecklists.length < total,
    [companyChecklists, total]
  );

  return (
    <>
      <Stack spacing={1}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Text variant="h3">{title}</Text>
          {isLoading ? (
            <Skeleton sx={{ width: 115, height: 42, borderRadius: "16px" }} />
          ) : (
            <Chip
              label={`${total} templates`}
              sx={COMPANY_PACKAGE_CHIP_STYLE}
            />
          )}
        </Stack>
        <Text variant="text2" color={colors.gray80}>
          {description}
        </Text>
      </Stack>
      {!isLoading && (
        <Stack spacing={3}>
          {hasCompanyChecklists ? (
            companyChecklists.map(
              ({
                name,
                id,
                deals_count,
                fields_count,
                linked_fields_count,
              }) => (
                <CompanyChecklist
                  key={id}
                  name={name}
                  checklistId={id}
                  dealsCount={deals_count}
                  fieldsCount={fields_count}
                  linkedFieldsCount={linked_fields_count}
                />
              )
            )
          ) : (
            <Text variant="body1" color={colors.gray80} fontWeight={500}>
              {emptyStateText}
            </Text>
          )}
        </Stack>
      )}
      {(isLoading || isValidating) && <CompanyChecklistSkeleton />}
      {showLoadMoreButton && (
        <Stack alignItems="start">
          <Button
            onClick={() => setSize(size + 1)}
            color="secondary"
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.blue60}`,
            }}
          >
            Load more
          </Button>
        </Stack>
      )}
    </>
  );
};

export default memo(ChecklistPageSection, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});

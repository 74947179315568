import { memo, useMemo, useState } from "react";
import _ from "lodash";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { areInstructionsEmpty } from "utils/common";

import Box from "components/Box";
import Collapse from "components/Collapse";
import IconButton from "components/IconButton";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import { IFieldWrapperMeta } from "entities/Checklist/components/FieldWrapperManager";
import Section from "entities/Checklist/components/Section";
import { IPackageDetailsCategory } from "entities/Checklist/sdk";

type ICategory = {
  category: IPackageDetailsCategory;
  isCollapsible?: boolean;
} & IFieldWrapperMeta;

const Category = ({
  category,
  fieldWrapperType,
  fieldWrapperMeta,
  isCollapsible = false,
}: ICategory) => {
  const [open, setOpen] = useState<boolean>(true);

  const fieldWrapperProps = useMemo(
    () =>
      ({
        fieldWrapperType,
        fieldWrapperMeta,
      }) as IFieldWrapperMeta,
    [fieldWrapperType, fieldWrapperMeta]
  );

  const filteredSections = useMemo(
    () => category.sections.filter((section) => !section.is_deleted),
    [category.sections]
  );

  const showInstructions = useMemo(
    () => !areInstructionsEmpty({ instructions: category?.instruction }),
    [category?.instruction]
  );

  const categoryName = useMemo(() => {
    if (!isCollapsible) {
      return (
        <>
          <Text
            variant="text1"
            fontWeight={700}
            sx={{ marginBottom: theme.spacing(1.5) }}
            data-testid="dd-category-name"
          >
            {category.name}
          </Text>
          {showInstructions && (
            <Text
              variant="text4"
              sx={{ color: colors.gray60 }}
              data-testid="category-instructions"
            >
              <SanitizeHtml html={category?.instruction || ""} />
            </Text>
          )}
        </>
      );
    }

    return (
      <Stack
        sx={{
          padding: theme.spacing(0.5, 1),
          backgroundColor: colors.blue20,
          borderRadius: "4px",
        }}
        data-testid="dd-category"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={() => setOpen(!open)} size="small">
            {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          <Text variant="text2" fontWeight="500" data-testid="dd-category-name">
            {category.name}
          </Text>
        </Stack>
        {showInstructions && (
          <Text
            variant="text4"
            sx={{ color: colors.gray80 }}
            data-testid="category-instructions"
          >
            <SanitizeHtml html={category?.instruction || ""} />
          </Text>
        )}
      </Stack>
    );
  }, [category, isCollapsible, open, showInstructions]);

  return (
    <Box data-testid="dd-category-content">
      {categoryName}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack spacing={2}>
          {filteredSections.map((section) => (
            <Stack
              sx={{ paddingLeft: isCollapsible ? 1 : 0, paddingTop: 1 }}
              key={`wrapper-${section.section_id}`}
            >
              <Section
                {...fieldWrapperProps}
                key={`category-${category.category_id}-section-${section.section_id}`}
                section={section}
              />
            </Stack>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default memo(Category, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

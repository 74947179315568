import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { EMPTY_LIST } from "utils/constants";

import Box from "components/Box";
import FixedTable from "components/FixedTable";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import { IApplicationField } from "entities/Application/sdk";
import EditButton from "entities/Field/components/FixedTableField/EditButton";
import { getTableVerticalHeaders } from "entities/Field/components/FixedTableField/utils";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";

export interface IFixedTableField {
  field: IApplicationField;
  editable: boolean;
  onUpdate: ({
    field,
    table_value,
  }: {
    field: IApplicationField;
    table_value: string | null | undefined;
  }) => void;
}

const areTableValuesEmpty = ({
  values,
  fixedColumn,
}: {
  values: any[];
  fixedColumn: string | undefined;
}) => {
  const objectHasNonEmptyKeys = (object: { [key: string]: string }) => {
    // The objects have a "key: uuid" saved in them that is used only for indexing within the table.
    // They should also ignore the fixed column as the values for that are hardcoded from the database and cannot be changed or deleted.
    // If we erase the value from an editable cell - it turns to an empty list rathar than being erased as a key, so we should ignore those too.
    return (
      Object.keys(object).filter(
        (key) => key !== fixedColumn && key !== "key" && object[key] !== ""
      ).length > 0
    );
  };

  return values.filter(objectHasNonEmptyKeys).length === 0;
};

const FixedTableField = ({ field, editable, onUpdate }: IFixedTableField) => {
  const [values, setValues] = useState<any[]>(
    _.get(field.values, "[0].table_value", EMPTY_LIST)
  );

  const handleSave = useCallback(
    ({ newValue }: { newValue: any }) => {
      onUpdate({
        field,
        table_value: JSON.stringify(newValue),
      });
      setValues(newValue);
    },
    [field, setValues, onUpdate]
  );

  const showTag = useMemo(
    () =>
      editable &&
      areTableValuesEmpty({
        values,
        fixedColumn: _.head(field.columns),
      }),
    [editable, values, field.columns]
  );

  const showRequiredTag = useMemo(
    () => showTag && field.is_required,
    [showTag, field.is_required]
  );

  useEffect(() => {
    if (_.isEmpty(values) && field.rows && field.columns) {
      setValues(
        getTableVerticalHeaders({
          firstColumn: field.columns[0],
          rows: field.rows,
        })
      );
    }
  }, [values, field, setValues]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Text
            variant="text2"
            sx={{
              fontWeight: 300,
              paddingRight: theme.spacing(2),
            }}
          >
            {field.name}
          </Text>
          <InstructionsDialogButton instructions={field?.instruction} />
        </Stack>
        {editable && (
          <Stack direction="row" spacing={1} alignItems="center">
            {showRequiredTag && <Tag label="required" variant="red" />}
            <EditButton
              field={{
                display_name: field.name,
                header_name:
                  field.columns?.map((column) => ({
                    headerName: column.trim(),
                    field: column.trim(),
                  })) || [],
              }}
              initialValue={values}
              onSave={handleSave}
            />
          </Stack>
        )}
      </Stack>
      <Box sx={{ marginY: theme.spacing(3) }}>
        <FixedTable
          headers={
            field.columns?.map((column) => ({
              label: column.trim(),
              key: column.trim(),
            })) || []
          }
          rows={values}
          showEmptyRow={true}
        />
      </Box>
    </Box>
  );
};

export default FixedTableField;

import React, { useCallback, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseISO } from "utils/datetime";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import IconButton from "components/IconButton";
import {
  COMMON_EDITOR_CONTAINER_STYLES,
  COMMON_EDITOR_STYLES,
  EMPTY_VALUE_REGEX,
} from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import SmallAlert from "components/SmallAlert";
import Stack from "components/Stack";
import Text from "components/Text";

interface IRichtextfieldEditButton {
  name: string;
  initialValue: string | null;
  onSave: ({ newValue }: { newValue: string | null }) => void;
  onUpdate: ({ message }: { message?: string }) => void;

  onCancel?: () => void;
  buttonDataTestid?: string;
  lastSavedAt: string | null;
}

const RichtextfieldEditButton: React.FC<IRichtextfieldEditButton> = ({
  name,
  initialValue,
  onSave,
  onCancel,
  buttonDataTestid,
  lastSavedAt,
  onUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [saveTimer, setSaveTimer] = useState<NodeJS.Timeout | null>(null);

  const handleEditButtonClick = useCallback(() => setIsOpen(true), []);

  const handleCancel = useCallback(() => {
    if (!_.isNull(saveTimer)) {
      clearTimeout(saveTimer);
    }

    setIsOpen(false);
    onCancel && onCancel();
  }, [setIsOpen, onCancel, saveTimer]);

  const handleSave = useCallback(() => {
    const validatedValue = EMPTY_VALUE_REGEX.test(value || "") ? "" : value;
    onSave({ newValue: validatedValue });
    handleCancel();
    onUpdate({ message: `${name} successfully updated!` });
  }, [handleCancel, onUpdate, onSave, value, name]);

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue);

      // Save the data every 3 seconds of inactivity.
      const newTimer = setTimeout(() => {
        const validatedValue = EMPTY_VALUE_REGEX.test(newValue || "")
          ? ""
          : newValue;
        onSave({ newValue: validatedValue });
      }, 3000);

      setSaveTimer((prevTimer) => {
        if (!_.isNull(prevTimer)) {
          clearTimeout(prevTimer);
        }

        return newTimer;
      });
    },
    [onSave]
  );

  return (
    <>
      <IconButton
        size="small"
        onClick={handleEditButtonClick}
        dataTestid={buttonDataTestid}
      >
        <EditIcon />
      </IconButton>
      {isOpen && (
        <Dialog
          open={isOpen}
          maxWidth="lg"
          fullWidth
          PaperProps={{ sx: { padding: theme.spacing(1) } }}
          data-testid="dd-rtf-edit-dialog"
          onClose={handleCancel}
        >
          <DialogTitle
            data-testid="edit-dialog-title"
            sx={{
              margin: theme.spacing(2, 2, 0, 2),
              padding: 2,
              borderRadius: "8px",
              backgroundColor: colors.blue10,
            }}
          >
            <Text variant="h2">{name}</Text>
          </DialogTitle>
          <DialogContent
            sx={{
              margin: theme.spacing(2, 2, 0, 2),
              padding: 0,
              overflowY: "hidden",
            }}
          >
            <Box sx={COMMON_EDITOR_CONTAINER_STYLES}>
              <RichTextEditorField
                defaultValue={initialValue || ""}
                onChange={handleChange}
                sx={COMMON_EDITOR_STYLES}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              padding: 2,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <SmallAlert title="Don’t forget to save your changes after editing!" />
              {!_.isNil(lastSavedAt) && (
                <Text
                  variant="text3"
                  component="span"
                  sx={{ color: colors.gray80 }}
                >
                  Last saved at{" "}
                  {format(parseISO(lastSavedAt), FORMATS.FRONTEND.DATETIME)}
                </Text>
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                variant="text"
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RichtextfieldEditButton;

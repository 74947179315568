import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { useCaching } from "utils/common";

import Button from "components/Button";
import ContentSeparator from "components/ContentSeparator";
import Divider from "components/Divider";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { assignUser, useDealAssignedTeamMembers } from "entities/Deal/sdk";
import { ISidePanel } from "entities/Deal/sdk";
import { addCompanyMemberToDeal } from "entities/Team/sdk";

export const TAB_KEY = "assignedTeam";

interface IAssignedTeamTabContent {
  dealId: number;
  sidepanelData: ISidePanel;
  refetchSidePanel: () => void;
}

const AddMemberToDealHelperText = ({
  dealId,
  userId,
  onAdd,
}: {
  dealId: number;
  userId: number;
  onAdd: () => void;
}) => {
  const handleAddToTeam = useCallback(() => {
    addCompanyMemberToDeal({
      dealId,
      userId,
    }).then(() => {
      toast.successMessage("User added to deal!");
      onAdd();
    });
  }, [dealId, userId, onAdd]);

  return (
    <>
      <Text variant="text4" component="span">
        The user is not a member of this deal.{" "}
      </Text>
      <Button
        variant="text"
        color="error"
        size="small"
        component="span"
        onClick={handleAddToTeam}
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        <Text variant="text4">Click here to invite them.</Text>
      </Button>
    </>
  );
};

const AssignField = ({
  position,
  defaultValue,
  dealId,
  onChange,
}: {
  position: string;
  defaultValue: string;
  dealId: number;
  onChange: (userId: number) => Promise<void>;
}) => {
  const { data: companyMembers = [] } = useDealAssignedTeamMembers(dealId);

  const [value, setValue] = useCaching(defaultValue);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleAddUserToDeal = useCallback(() => {
    setLoading(true);
    onChange(Number(value)).then(() => {
      setError(false);
      setLoading(false);
    });
  }, [value, onChange]);

  const handleChange = useCallback(
    (event: any) => {
      setLoading(true);
      const userId = event.target.value;
      setValue(userId);
      if (
        _.find(companyMembers, (option) => String(option.id) === userId)
          ?.is_deal_member
      ) {
        setError(false);
        onChange(Number(userId));
      } else {
        setError(true);
      }
      setLoading(false);
    },
    [companyMembers, onChange, setValue]
  );

  const options = useMemo(
    () =>
      _.orderBy(
        companyMembers.map((option) => ({
          value: String(option.id),
          label: `${option.first_name} ${option.last_name}`,
        })),
        (option) => option.label.toLowerCase()
      ),
    [companyMembers]
  );

  return (
    <Select
      sx={{ flex: 1 }}
      options={options}
      disabled={isLoading}
      error={error}
      value={value}
      onChange={handleChange}
      data-testid={`${position.toLowerCase().replaceAll(" ", "-")}-dropdown`}
      dropdownOptionsDataTestid={`${position
        .toLowerCase()
        .replaceAll(" ", "-")}-dropdown-option`}
      inputProps={{
        "data-testid": `${position
          .toLowerCase()
          .replaceAll(" ", "-")}-dropdown-input`,
      }}
      helperText={
        error && (
          <AddMemberToDealHelperText
            dealId={dealId}
            userId={Number(value)}
            onAdd={handleAddUserToDeal}
          />
        )
      }
    />
  );
};

const AssignedTeamTabContent: React.FC<IAssignedTeamTabContent> = ({
  dealId,
  sidepanelData,
  refetchSidePanel,
}) => {
  const assignedTeam = sidepanelData.assigned_teams;

  const handleChange = useCallback(
    ({ userId, roleId }: { userId: number; roleId: number }) =>
      assignUser(userId, dealId, roleId).then(() => {
        toast.successMessage("Assigned user updated!");
        refetchSidePanel();
      }),
    [dealId, refetchSidePanel]
  );

  return (
    assignedTeam && (
      <>
        <ContentSeparator label="Assigned Team" />
        {assignedTeam.map(({ name, position, user_id, role_id }) => (
          <>
            <Stack spacing={2} direction="row" alignItems="center">
              <Text
                variant="text2"
                sx={{ color: colors.gray100, flex: 1 }}
                data-testid="field-name"
              >
                {position}
              </Text>
              {sidepanelData?.is_deal_admin && (
                <AssignField
                  dealId={dealId}
                  defaultValue={String(user_id)}
                  onChange={(userId) =>
                    handleChange({ userId, roleId: role_id })
                  }
                  position={position}
                />
              )}
              {!sidepanelData?.is_deal_admin && (
                <Text
                  variant="text2"
                  data-testid={`${position
                    .toLowerCase()
                    .replaceAll(" ", "-")}-value`}
                >
                  {name}
                </Text>
              )}
            </Stack>
            <Divider />
          </>
        ))}
      </>
    )
  );
};

export default AssignedTeamTabContent;

import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import DealAvatar from "components/DealAvatar";
import Link from "components/Link";
import { TableCell } from "components/Table";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { WorkspaceSubNavItems } from "entities/Deal/constants";

interface IDealAvatarCell {
  dealName: string;
  dealId: number;
  dealAvatar: string;
  showDealName: boolean;
}

const DealAvatarCell = ({
  dealId,
  dealName,
  dealAvatar,
  showDealName,
}: IDealAvatarCell) => (
  <Tooltip title={dealName}>
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
        cursor: "pointer",
      }}
    >
      <Link
        to={reverse(URLS.DEAL_WORKSPACE_TAB, {
          dealId: dealId,
          tab: WorkspaceSubNavItems.TASKS,
        })}
        style={{ display: "flex", gap: theme.spacing(2) }}
      >
        <DealAvatar
          imgix
          src={dealAvatar}
          imgixWidth={40}
          imgixHeight={40}
          sx={{ width: "24px", height: "24px" }}
        />

        {showDealName && <Text>{_.truncate(dealName, { length: 30 })}</Text>}
      </Link>
    </TableCell>
  </Tooltip>
);

export default DealAvatarCell;

import React from "react";

import { AddIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import EmployeeAddForm from "entities/Employee/components/AddForm";
import { IUser } from "entities/User/sdk";

export interface IAddCompanyMemberDialog {
  open: boolean;
  onClose: () => void;
  onCreated: ({ employee }: { employee: IUser }) => void;
}

const AddCompanyMemberDialog: React.FC<IAddCompanyMemberDialog> = ({
  open,
  onClose,
  onCreated,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <Box sx={{ padding: theme.spacing(0, 10) }}>
      <Paper
        sx={{
          paddingTop: theme.spacing(8),
          paddingBottom: theme.spacing(8),
        }}
      >
        <Stack spacing={4} data-testid="add-member-dialog">
          <Stack direction="row" spacing={1}>
            <AddIcon
              style={{
                height: theme.spacing(5),
                width: theme.spacing(5),
              }}
            />
            <Text variant="h1">Add Member</Text>
          </Stack>
          <EmployeeAddForm onCancel={onClose} onCreated={onCreated} />
        </Stack>
      </Paper>
    </Box>
  </Dialog>
);

export default AddCompanyMemberDialog;

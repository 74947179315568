import { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { LogoIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parse } from "utils/datetime";

import Box from "components/Box";
import DealAvatar from "components/DealAvatar";
import Divider from "components/Divider";
import Image from "components/Image";
import Link from "components/Link";
import NoPrint from "components/NoPrint";
import Paper from "components/Paper";
import PrintableLayout from "components/PrintableLayout";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { useSignedUser } from "entities/Auth/sdk";
import { useDealDetails } from "entities/Deal/sdk";
import EditDocumentMeetingDateButton from "entities/Memo/components/EditDocumentMeetingDateButton";
import PageHeader from "entities/Memo/components/PageHeader";
import MemoSectionWrapper from "entities/Memo/components/SectionWrapper";
import { IMemoSection, useMemoDealDetails } from "entities/Memo/sdk";

import "./memo.css";

interface IMemo {}

const Memo: React.FC<IMemo> = () => {
  const params = useParams<{
    dealId: string;
    memoId: string;
  }>();
  const dealId = Number(params.dealId);
  const memoId = Number(params.memoId);

  const { data: user } = useSignedUser();
  const { data: deal } = useDealDetails({ dealId });

  const { data: memo, mutate: mutateMemoDealDetails } = useMemoDealDetails({
    dealId,
    memoId,
  });

  usePageTitle(
    deal?.name && memo?.name
      ? `Builders Patch: ${deal.name} - ${memo?.name} - Memo`
      : "Builders Patch: Memo"
  );

  const memoDate = useMemo(() => {
    if (!_.isUndefined(memo) && !_.isNil(memo.memo_date)) {
      return parse(memo.memo_date, FORMATS.BACKEND.DATE, new Date());
    }

    return null;
  }, [memo]);

  if (!user || !memo || !deal) {
    return null;
  }
  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
        "@media print": {
          overflow: "hidden",
          padding: "0",
          flexGrow: 1,
          marginLeft: "0 !important",
          width: "100%",
        },
      }}
    >
      <Stack>
        <Box
          sx={{
            maxWidth: BOX_MAX_WIDTH,
            alignSelf: "center",
          }}
        >
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <PageHeader
              dealId={dealId}
              memoId={memoId}
              memoName={memo?.name}
              dealName={deal?.name}
            />
          </Box>
          <PrintableLayout
            header={
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Text variant="text4" sx={{ fontWeight: "bold" }}>
                      {deal.name}
                    </Text>
                    <Text variant="text4">{memo.name}</Text>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {!memo.memo_date && (
                        <Text variant="text4">-- meeting date --</Text>
                      )}
                      {memo.memo_date && (
                        <Text variant="text4">
                          {format(
                            parse(
                              memo.memo_date,
                              FORMATS.BACKEND.DATE,
                              new Date()
                            ),
                            FORMATS.FRONTEND.DATE
                          )}
                        </Text>
                      )}
                      <NoPrint>
                        <EditDocumentMeetingDateButton
                          dealId={dealId}
                          memoId={memoId}
                          value={memoDate}
                          onChange={(value) =>
                            mutateMemoDealDetails((currentData) =>
                              currentData
                                ? {
                                    ...currentData,
                                    memo_date: value,
                                  }
                                : undefined
                            )
                          }
                        />
                      </NoPrint>
                    </Stack>
                  </Stack>
                  {user.company_logo && (
                    <Image
                      sx={{
                        height: "56px",
                        maxWidth: "50%",
                        marginBottom: "2px",
                      }}
                      src={user.company_logo}
                    />
                  )}
                </Stack>
                <Divider sx={{ marginBottom: theme.spacing(2) }} />
              </>
            }
            footer={
              <>
                <Divider
                  sx={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(1),
                  }}
                />
                <Stack direction="row" spacing={1}>
                  <LogoIcon
                    style={{
                      height: "40px",
                      width: "auto",
                      borderRadius: theme.spacing(0.5),
                    }}
                  />
                  <Box>
                    <Text sx={{ lineHeight: "144%" }}>
                      This document was created using Builders Patch
                    </Text>
                    <Link to="https://builderspatch.com/" external>
                      <Text
                        fontWeight="700"
                        sx={{ color: colors.blue100, lineHeight: "100%" }}
                      >
                        builderspatch.com
                      </Text>
                    </Link>
                  </Box>
                </Stack>
              </>
            }
          >
            <Paper
              sx={{
                backgroundColor: colors.blue10,
                borderRadius: 0,
              }}
            >
              <Stack direction="row" spacing={2}>
                <DealAvatar
                  src={deal.thumbnail}
                  imgix
                  imgixWidth={480}
                  imgixHeight={240}
                  sx={{ width: "240px", height: "120px", objectFit: "cover" }}
                />
                <Stack>
                  <Text variant="label" sx={{ color: colors.blue100 }}>
                    {memo.name}
                  </Text>
                  <Text variant="h1">{deal.name}</Text>
                </Stack>
              </Stack>
            </Paper>
            <Box
              sx={{
                marginTop: theme.spacing(4),
                "& > *": { marginBottom: theme.spacing(2) },
              }}
            >
              {memo.sections.map((section: IMemoSection) => (
                <>
                  {section.is_na ? (
                    <NoPrint>
                      <MemoSectionWrapper
                        key={section.section_id}
                        section={section}
                        deal={deal}
                        onUpdate={() => mutateMemoDealDetails()}
                      />
                    </NoPrint>
                  ) : (
                    <MemoSectionWrapper
                      key={section.section_id}
                      section={section}
                      deal={deal}
                      onUpdate={() => mutateMemoDealDetails()}
                    />
                  )}
                </>
              ))}
            </Box>
          </PrintableLayout>
        </Box>
      </Stack>
    </Paper>
  );
};

export default Memo;

import _ from "lodash";

import { BASE_URL } from "config/urls";
import { deleteCall, get, post, useFetch } from "utils/sdk";

import { FieldType } from "entities/Field/sdk";
import { IUser } from "entities/User/sdk";

import { EMemoSectionType } from "./constants";

export interface IMemoTemplateField {
  company_custom_field_id: number;
  name: string;
  field_type: FieldType;
  columns: string[];
  options: string[];
}

export interface IMemoTemplateSection {
  memo_section_id: number;
  title: string;
  order: number;
  break_before: boolean;
  section_type: EMemoSectionType;
  due_diligence_section: null | {
    due_diligence_section_id: number;
    due_diligence_package_id: number;
    name: string;
    fields: IMemoTemplateField[] | null;
  };
}

export interface IMemoTemplate {
  memo_id: number;
  name: string;
  created_by: Pick<IUser, "avatar" | "first_name" | "last_name">;
  updated_by: Pick<IUser, "avatar" | "first_name" | "last_name">;
  updated_at: string;
  sections: IMemoTemplateSection[];
}

export const createMemoTemplate = (data: { name: string }) =>
  post<IMemoTemplate>(`${BASE_URL}v2/memo/create/`, data);

export const updateMemoTemplate = ({
  memoTemplateId,
  name,
}: {
  memoTemplateId: number;
  name: string;
}) =>
  post<IMemoTemplate>(`${BASE_URL}v2/memo/memo/${memoTemplateId}/update/`, {
    name,
  });

export const useMemoTemplateDetails = ({
  memo_id,
}: {
  memo_id: IMemoTemplate["memo_id"];
}) => useFetch<IMemoTemplate>(`v2/memo/memo/${memo_id}/details/`);

export const deleteMemoTemplate = ({ memoId }: { memoId: number }) =>
  deleteCall(`${BASE_URL}v2/memo/memo/${memoId}/delete/`);

export const fetchMemoTemplates = (): Promise<IMemoTemplate[]> =>
  get(`${BASE_URL}v2/memo/list/`);

export const useMemoTemplates = (isExternalLink: boolean = false) =>
  useFetch<IMemoTemplate[]>(!isExternalLink ? "v2/memo/list/" : undefined);

export const createMemoTemplateSection = ({
  memoId,
  data,
}: {
  memoId: number;
  data: {
    title: string;
    section_type: string;
    due_diligence_section: number | null;
    break_before: boolean;
  };
}) => {
  // We don't want to send the key due_diligence_section if it's null
  let formattedData: Partial<typeof data> = { ...data };
  if (_.isNull(formattedData.due_diligence_section)) {
    delete formattedData.due_diligence_section;
  }

  return post<IMemoTemplateSection>(
    `${BASE_URL}v2/memo/memo/${memoId}/memo-section/create/`,
    { order: 0, ...formattedData }
  );
};

export const updateMemoTemplateSection = ({
  memoSectionId,
  data,
}: {
  memoSectionId: number;
  data: {
    title: string;
    section_type: string;
    break_before: boolean;
  };
}) =>
  post<IMemoTemplateSection>(
    `${BASE_URL}v2/memo/memo-section/${memoSectionId}/update/`,
    data
  );

export const deleteMemoTemplateSection = ({
  memoSectionId,
}: {
  memoSectionId: number;
}) => post<{}>(`${BASE_URL}v2/memo/memo-section/${memoSectionId}/delete/`);

export const updateMemoTemplateSectionsOrder = ({
  memoId,
  sectionsOrder,
}: {
  memoId: number;
  sectionsOrder: number[];
}) =>
  post<{}>(`${BASE_URL}v2/memo/memo/${memoId}/reorder/`, {
    sections_order: sectionsOrder,
  });

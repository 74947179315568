import React from "react";
import { Navigate } from "react-router-dom";

import { saveToken } from "utils/storage";
import { getUrlGetParams } from "utils/urls";

const ProxyLogin = () => {
  const { token } = getUrlGetParams();

  if (token) {
    saveToken(token);
  }

  return <Navigate to="/" />;
};

export default ProxyLogin;

import React from "react";

import Divider from "components/Divider";
import NumberButtonGroup from "components/NumberButtonGroup";
import Paper from "components/Paper";
import Select from "components/Select";
import Slider from "components/Slider";
import Stack from "components/Stack";
import Text from "components/Text";

const CURRENT_YEAR = new Date().getFullYear();

interface ISettings {
  expensesCategories: {
    value: string;
    label: string;
  }[];
  startYear: number;
  rateOfIncome: number;
  rateOfExpenses: number;
  rateFixedAt: number;
  firstSelector?: string;
  secondSelector?: string;
  firstSelectorValue: number;
  secondSelectorValue: number;
  onChangeStartYear: (value: number) => void;
  onChangeTimespan: (value: number) => void;
  onChangeRateOfIncome: (value: number) => void;
  onChangeRateOfExpenses: (value: number) => void;
  onChangeRateFixedAt: (value: number) => void;
  onChangeFirstSelector: (value: string) => void;
  onChangeSecondSelector: (value: string) => void;
  onChangeFirstSelectorValue: (value: number) => void;
  onChangeSecondSelectorValue: (value: number) => void;
}

const Settings: React.FC<ISettings> = ({
  expensesCategories,
  startYear,
  rateOfIncome,
  rateOfExpenses,
  rateFixedAt,
  firstSelector,
  secondSelector,
  firstSelectorValue,
  secondSelectorValue,
  onChangeStartYear,
  onChangeTimespan,
  onChangeRateOfIncome,
  onChangeRateOfExpenses,
  onChangeRateFixedAt,
  onChangeFirstSelector,
  onChangeSecondSelector,
  onChangeFirstSelectorValue,
  onChangeSecondSelectorValue,
}) => {
  return (
    <Paper sx={{ flex: 4 }} data-testid="settings-section">
      <Stack spacing={2}>
        <Text variant="h2">Settings</Text>
        <Text variant="text2" sx={{ fontWeight: 700 }}>
          Timespan
        </Text>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Text variant="text2">Starting Year</Text>
          <NumberButtonGroup
            initialValue={startYear}
            lowerBound={CURRENT_YEAR}
            upperBound={CURRENT_YEAR + 30}
            onChange={onChangeStartYear}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="text2">Timespan</Text>

          <Stack direction="row" sx={{ width: 200, height: 50 }} spacing={2}>
            <Text sx={{ paddingTop: "5px" }} variant="text4">
              years
            </Text>
            <Slider
              defaultValue={30}
              step={5}
              marks={[
                { value: 10, label: 10 },
                { value: 15, label: 15 },
                { value: 20, label: 20 },
                { value: 25, label: 25 },
                { value: 30, label: 30 },
              ]}
              min={10}
              max={30}
              onChange={(e, value) => {
                onChangeTimespan(value as number); // Yes.
              }}
            />
          </Stack>
        </Stack>
        <Text variant="text2" sx={{ fontWeight: 700 }}>
          Basic Trends
        </Text>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Text variant="text2">Growth Rate of Income</Text>
          <NumberButtonGroup
            initialValue={rateOfIncome}
            lowerBound={0}
            upperBound={100}
            sufix="%"
            onChange={onChangeRateOfIncome}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="text2">Growth Rate of Expenses</Text>
          <NumberButtonGroup
            initialValue={rateOfExpenses}
            lowerBound={0}
            upperBound={100}
            sufix="%"
            onChange={onChangeRateOfExpenses}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="text2">Vacancy Rate fixed at</Text>
          <NumberButtonGroup
            initialValue={rateFixedAt}
            lowerBound={0}
            upperBound={100}
            sufix="%"
            onChange={onChangeRateFixedAt}
          />
        </Stack>
        <Text variant="text2" sx={{ fontWeight: 700 }}>
          Expenses Overrides
        </Text>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Select
            value={firstSelector}
            size="small"
            inputLabel="Select category"
            options={expensesCategories}
            onChange={(event) => onChangeFirstSelector(event.target.value)}
            sx={{ width: "65%" }}
          />
          <NumberButtonGroup
            initialValue={firstSelectorValue}
            lowerBound={0}
            upperBound={100}
            sufix="%"
            onChange={onChangeFirstSelectorValue}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Select
            value={secondSelector}
            size="small"
            inputLabel="Select category"
            options={expensesCategories}
            onChange={(event) => onChangeSecondSelector(event.target.value)}
            sx={{ width: "65%" }}
          />
          <NumberButtonGroup
            initialValue={secondSelectorValue}
            lowerBound={0}
            upperBound={100}
            sufix="%"
            onChange={onChangeSecondSelectorValue}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Settings;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { areInstructionsEmpty } from "utils/common";

import Box from "components/Box";
import Collapse from "components/Collapse";
import IconButton from "components/IconButton";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { useDealChecklistPermission } from "entities/Deal/sdk";
import ApproveCheckbox from "entities/Package/components/ApproveCheckbox";
import FieldWrapper from "entities/Package/components/FieldWrapper";
import MenuButton from "entities/Package/components/Section/MenuButton";
import { DueDiligenceShareableType } from "entities/Package/constants";
import {
  IPackageSection,
  sectionApproveBulk,
  usePackageDealDetails,
} from "entities/Package/sdk";

interface ISection {
  section: IPackageSection;
  categoryId: number;
  isExpanded: boolean;
  shareablePageType?: DueDiligenceShareableType;
  updatePackage: (message?: string) => void;
  dataTestid?: string;
  isFocused?: boolean;
}

const Section: React.FC<ISection> = ({
  section,
  categoryId,
  isExpanded,
  shareablePageType,
  updatePackage,
  dataTestid,
  isFocused = false,
}) => {
  const [updateCheckboxLoading, setUpdateCheckboxLoading] = useState(false);
  const [open, setOpen] = useCaching(isExpanded);

  const [searchParams] = useSearchParams();

  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
  }>();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);

  const selectedFieldId = useMemo(() => {
    const fieldId = searchParams.get("fieldId");
    return !_.isNil(fieldId) ? Number(fieldId) : null;
  }, [searchParams]);

  useEffect(() => {
    if (!_.isNil(selectedFieldId) && !open) {
      const fieldIsInCategory = section.fields.some(
        (field) => field.field_id === selectedFieldId
      );

      setOpen(fieldIsInCategory);
    }
  }, [selectedFieldId, section, open, setOpen]);

  const { mutate: updateWholePackage } = usePackageDealDetails({
    dealId,
    packageId,
  });

  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  const hasFullAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.is_full_access,
    [userPermission]
  );

  const hasEditAccess = useMemo(
    () => !_.isNil(userPermission) && userPermission.has_write_access,
    [userPermission]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdateCheckboxLoading(true);
      sectionApproveBulk({
        dealId,
        sectionIds: [section.section_id],
        isApproved: event.target.checked,
      })
        .then(() => {
          updatePackage(
            `${section?.name} was successfully ${
              event.target.checked ? "uncompleted" : "completed"
            }!`
          );
        })
        .catch(() => {
          toast.errorMessage(DEFAULT_ERROR_MESSAGE);
        })
        .finally(() => {
          setUpdateCheckboxLoading(false);
        });
    },
    [dealId, section, updatePackage]
  );

  const handleUpdateField = useCallback(
    ({ message }: { message?: string }) => {
      if (message) {
        toast.successMessage(message);
      }
      updatePackage();
      updateWholePackage();
    },
    [updatePackage, updateWholePackage]
  );

  const areAllNA = useMemo(
    () => _.every(section.fields, { n_a_status: true }),
    [section]
  );

  const checkboxLoadingStyle = useMemo(() => {
    if (updateCheckboxLoading)
      return {
        animation: "rotating 0.4s linear infinite",
        "@keyframes rotating": {
          from: {
            transform: "rotate(0deg)",
          },
          to: {
            transform: "rotate(360deg)",
          },
        },
      };
    return {};
  }, [updateCheckboxLoading]);

  const shouldSeeApproveCheckbox = useMemo(() => {
    return (
      (!shareablePageType ||
        (shareablePageType &&
          shareablePageType !== DueDiligenceShareableType.FIELD)) &&
      hasEditAccess
    );
  }, [shareablePageType, hasEditAccess]);

  const showInstructions = useMemo(
    () => !areInstructionsEmpty({ instructions: section?.instruction }),
    [section?.instruction]
  );

  return (
    <Box id={section.section_id.toString()}>
      <Box
        sx={{
          padding: theme.spacing(0.5, 1),
          backgroundColor: isFocused ? colors.green10 : colors.blue10,
          borderRadius: "4px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          data-testid={dataTestid}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={() => setOpen(!open)}
              size="small"
              dataTestid="dd-section-expand-button"
            >
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            {section && shouldSeeApproveCheckbox && (
              <ApproveCheckbox
                areAllNA={areAllNA}
                disabled={updateCheckboxLoading}
                checkboxLoadingStyle={checkboxLoadingStyle}
                onChange={handleCheckboxClick}
                checked={section.is_approved}
                itemDataTestid="section"
              />
            )}
            <Text
              variant="text2"
              fontWeight="500"
              sx={{
                opacity: areAllNA ? 0.5 : 1,
              }}
              data-testid="dd-section-name"
            >
              {section.name}
            </Text>
          </Stack>
          <Stack>
            <MenuButton
              section={section}
              categoryId={categoryId}
              dealId={dealId}
              updatePackage={updatePackage}
              menuButtonDataTestid="dd-section-actions-button"
              hasFullAccess={hasFullAccess}
            />
          </Stack>
        </Stack>
        {showInstructions && (
          <Text
            variant="text4"
            sx={{ marginLeft: theme.spacing(4), color: colors.gray80 }}
          >
            <SanitizeHtml html={section?.instruction || ""} />
          </Text>
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{ padding: theme.spacing(1, 0, 1, 6) }}
          data-testid="dd-section-fields"
          data-test-id={section.name}
        >
          {section.fields.map((field) => (
            <FieldWrapper
              key={field.field_id}
              field={field}
              categoryId={categoryId}
              sectionId={section.section_id}
              onUpdate={handleUpdateField}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Section;

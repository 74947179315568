import React, { useMemo, useState } from "react";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";

import Collapse from "components/Collapse";
import ScrollableTable from "components/ScrollableTable";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Stack from "components/Stack";
import Text from "components/Text";

import { getRevenuesRows } from "entities/Proforma/components/ProformaPLProjections/Tables/utils";
import { IPLProjection } from "entities/Proforma/sdk";

interface IRevenuesTable {
  headers: IScrollableTableHeader;
  revenuesData: IPLProjection["p_and_l"]["revenues"];
}

const RevenuesTable = ({ headers, revenuesData }: IRevenuesTable) => {
  const [isOpen, setIsOpen] = useState(true);

  const revenuesRows = useMemo(
    () => getRevenuesRows({ revenuesData }),
    [revenuesData]
  );

  return (
    <Stack spacing={2} data-testid="revenues-table">
      <Text
        variant="h2"
        onClick={() => setIsOpen((open) => !open)}
        sx={{ cursor: "pointer" }}
      >
        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />} Revenues
      </Text>
      <Collapse in={isOpen} timeout="auto">
        <ScrollableTable headers={headers} rows={revenuesRows} dynamicHyphen />
      </Collapse>
    </Stack>
  );
};

export default React.memo(RevenuesTable);

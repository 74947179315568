import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

export interface IChecklistAddedToDeal {
  isOwned: boolean;
}

const ChecklistAddedToDeal = ({ isOwned }: IChecklistAddedToDeal) => (
  <Stack spacing={1} alignItems="flex-start" data-testid="checklist-ownership">
    <Text
      variant="labelSmall"
      color={colors.gray60}
      fontWeight={500}
      marginBottom={theme.spacing(0.5)}
      data-testid="section-label"
    >
      Added to deal
    </Text>
    {isOwned ? (
      <Tag
        label="Owner"
        variant="gray100"
        sx={{ whiteSpace: "nowrap", display: "inline-block" }}
        dataTestid="owner-tag-label"
      />
    ) : (
      <Tag
        label="Shared"
        variant="gray60"
        sx={{ whiteSpace: "nowrap", display: "inline-block" }}
        dataTestid="shared-tag-label"
      />
    )}
  </Stack>
);

export default ChecklistAddedToDeal;

import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { MoreVertIcon } from "icons";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import ShareWithButton from "entities/Package/components/ShareWithButton";
import {
  fieldApproveBulk,
  fieldUpdateNAStatusBulk,
  IPackageSection,
  sectionApproveBulk,
} from "entities/Package/sdk";
import DueDiligenceSectionNewTaskButton from "entities/Task/components/DueDiligenceSectionNewTaskButton";
import DueDiligenceSectionOpenTaskButton from "entities/Task/components/DueDiligenceSectionOpenTaskButton";
import { useTaskChannels } from "entities/Task/sdk";

interface IPackageSectionMenuButton {
  section: IPackageSection;
  dealId: number;
  categoryId: number;
  updatePackage: (message?: string) => void;
  menuButtonDataTestid?: string;
  hasFullAccess: boolean;
}

const PackageSectionMenuButton = ({
  section,
  dealId,
  categoryId,
  updatePackage,
  menuButtonDataTestid,
  hasFullAccess,
}: IPackageSectionMenuButton) => {
  const params = useParams<{
    packageId: string;
    tabId: string;
    fieldId: string;
  }>();

  const tabId = Number(params.tabId);
  const packageId = Number(params.packageId);
  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });

  const { data: taskChannels } = useTaskChannels({ dealId });
  const tasksForSection = useMemo(
    () =>
      taskChannels?.filter(
        (taskChannel) =>
          taskChannel.task.company_category?.id === categoryId &&
          taskChannel.task.company_section?.id === section.section_id &&
          taskChannel.task.company_field === null
      ) || [],
    [categoryId, section, taskChannels]
  );

  const areAllNA = useMemo(
    () => _.every(section.fields, { n_a_status: true }),
    [section]
  );

  // This should be done by BE once we use the new API
  const areAllApproved = useMemo(() => {
    return (
      section.is_approved &&
      _.every(
        section.fields.filter((field) => field.n_a_status === false),
        { is_completed: true }
      )
    );
  }, [section]);

  const handleMarkAsNA = useCallback(() => {
    fieldUpdateNAStatusBulk({
      dealId,
      fieldIds: section.fields.map((field) => field.field_id),
      naStatus: !areAllNA,
    }).then(() => {
      updatePackage(
        `${section?.name} was successfully ${
          !areAllNA ? "disabled" : "enabled"
        }!`
      );
    });
  }, [dealId, section, areAllNA, updatePackage]);

  const handleSectionApprovalAll = useCallback(() => {
    fieldApproveBulk({
      dealId,
      fieldIds: section.fields.map((field) => field.field_id),
      isApproved: !areAllApproved,
    })
      .then(() => {
        return sectionApproveBulk({
          dealId,
          sectionIds: [section.section_id],
          isApproved: !areAllApproved,
        }).then(() => {
          updatePackage(
            `${section?.name} was successfully ${
              !areAllApproved ? "completed" : "uncompleted"
            }!`
          );
        });
      })
      .catch(() => {
        toast.errorMessage(DEFAULT_ERROR_MESSAGE);
      });
  }, [dealId, section, areAllApproved, updatePackage]);

  return (
    <Stack flexDirection="row">
      {tasksForSection.length > 0 && (
        <DueDiligenceSectionOpenTaskButton
          taskChannels={tasksForSection}
          section={section}
          dealId={dealId}
          tabId={tabId}
          categoryId={categoryId}
          packageId={packageId}
          updatePackage={updatePackage}
          taskButtonDataTestid="dd-open-task-button"
          taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
        />
      )}
      {hasFullAccess && (
        <ButtonDropdown
          variant="text"
          label={<MoreVertIcon />}
          width="auto"
          size="small"
          sx={{ padding: "2px" }}
          keepMenuMounted
          buttonDataTestid={menuButtonDataTestid}
        >
          <GroupLabel label="status" />
          <MenuItem onClick={handleMarkAsNA} dataTestid="action-menu-option">
            <Text variant="text2" data-testid="action-menu-option-name">
              {areAllNA ? "Revoke N/A status" : "Mark all as N/A"}
            </Text>
          </MenuItem>
          <MenuItem
            disabled={areAllNA}
            onClick={handleSectionApprovalAll}
            dataTestid="action-menu-option"
          >
            <Text variant="text2" data-testid="action-menu-option-name">
              {areAllApproved ? "Revoke done status" : "Mark all as done"}
            </Text>
          </MenuItem>

          {loggedUserIsDealAdmin && (
            <span>
              <GroupLabel label="share settings" />
              <ShareWithButton
                initiallySelectedItemIds={[section.section_id]}
              />
            </span>
          )}

          <GroupLabel label="tasks" />
          <DueDiligenceSectionNewTaskButton
            section={section}
            packageId={packageId}
            tabId={tabId}
            categoryId={categoryId}
            updatePackage={updatePackage}
            menuOptionDataTestid="action-menu-option"
          />
        </ButtonDropdown>
      )}
    </Stack>
  );
};

export default PackageSectionMenuButton;

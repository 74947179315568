import React, { useCallback, useState } from "react";

import { EditIcon } from "icons";
import theme from "theme";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import FixedTable from "components/FixedTable";
import IconButton from "components/IconButton";
import Stack from "components/Stack";

interface IFixedTableFieldEditButton {
  field: {
    display_name: string;
    header_name: Array<{ headerName: string; field: string }>;
  };
  initialValue: Array<{ [key: string]: string }>;
  onSave: ({ newValue }: { newValue: any }) => void;
  buttonDataTestid?: string;
}

const FixedTableFieldEditButton: React.FC<IFixedTableFieldEditButton> = ({
  field,
  initialValue,
  onSave,
  buttonDataTestid,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useCaching(initialValue);

  const handleEditButtonClick = useCallback(() => setIsOpen(true), []);

  const handleSave = useCallback(() => {
    onSave({ newValue: value });
    setIsOpen(false);
  }, [setIsOpen, onSave, value]);

  const handleCancel = useCallback(() => {
    setValue(initialValue);
    setIsOpen(false);
  }, [setIsOpen, setValue, initialValue]);

  return (
    <>
      <IconButton
        size="small"
        onClick={handleEditButtonClick}
        dataTestid={buttonDataTestid}
      >
        <EditIcon />
      </IconButton>
      {isOpen && (
        <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleCancel}>
          <Box
            sx={{
              padding: theme.spacing(1),
            }}
            data-testid="table-field-edit-dialog"
          >
            <DialogTitle data-testid="table-field-dialog-title">
              {field.display_name}
            </DialogTitle>
            <Stack spacing={1} sx={{ paddingX: theme.spacing(3) }}>
              <FixedTable
                rows={value}
                headers={(field.header_name || []).map(
                  ({ headerName, field }) => ({
                    label: headerName,
                    key: field,
                  })
                )}
                onUpdated={setValue}
                editable
                showDeleteCell
              />
            </Stack>
            <DialogActions sx={{ marginTop: theme.spacing(2) }}>
              <Button
                variant="text"
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default FixedTableFieldEditButton;

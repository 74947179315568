import { useCallback, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";

import Box from "components/Box";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { FIELD_WIDTH } from "entities/Field/components/Textfield/constants";

export interface IEditableFeature {
  limit: number;
  feature:
    | ECompanySettingsFeatureType.MAX_DEAL_COUNT
    | ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT
    | ECompanySettingsFeatureType.MAX_CHECKLIST_COUNT
    | ECompanySettingsFeatureType.MAX_INTAKE_CHECKLIST_COUNT;

  onUpdate: ({
    feature,
    limit,
  }: {
    feature:
      | ECompanySettingsFeatureType.MAX_DEAL_COUNT
      | ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT
      | ECompanySettingsFeatureType.MAX_CHECKLIST_COUNT
      | ECompanySettingsFeatureType.MAX_INTAKE_CHECKLIST_COUNT;
    limit: number;
  }) => void;
}

const EditableFeature = ({
  limit: initialLimit,
  feature,
  onUpdate,
}: IEditableFeature) => {
  const [limit, setLimit] = useState(initialLimit);
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLimit(event.target.valueAsNumber);
    },
    []
  );

  const handleSave = useCallback(() => {
    if (limit < 0) {
      toast.errorMessage("Value should be greater than or equal to 0.");
      return;
    }

    if (_.isNaN(limit)) {
      toast.errorMessage("Value should be a number.");
      return;
    }

    onUpdate({ feature, limit });
    setIsEditable(false);
  }, [limit, feature, onUpdate]);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {isEditable ? (
        <Stack spacing={1} direction="row" alignItems="start">
          <Box sx={{ width: FIELD_WIDTH }}>
            <TextField
              fullWidth
              size="small"
              type="number"
              defaultValue={limit}
              onChange={_.debounce(handleChange, 200)}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Box>
          <Button onClick={handleSave}>Save</Button>
          <Button variant="outlined" onClick={() => setIsEditable(false)}>
            Cancel
          </Button>
        </Stack>
      ) : (
        <>
          <Text variant="text2">{initialLimit}</Text>
          <IconButton size="small" onClick={() => setIsEditable(true)}>
            <EditIcon />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default EditableFeature;

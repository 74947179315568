import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import Select from "components/Select";
import Stack from "components/Stack";
import TreeView, { TreeItem } from "components/TreeView";

import {
  IPackageDetails,
  IPackageDetailsSection,
  usePackageDetails,
  usePackageList,
} from "entities/Checklist/sdk";

import TreeItemLabel from "./TreeItemLabel";

const getSelectedTreeViewPath = (
  checklist: IPackageDetails,
  section_id: number
): string[] | undefined => {
  let path: string[] | undefined = undefined;
  checklist.tabs.forEach((tab) => {
    !path &&
      tab.categories.forEach((category) => {
        !path &&
          category.sections.forEach((section) => {
            if (!path && section.section_id === section_id) {
              path = [
                `tab-${tab.tab_id}`,
                `tab-${tab.tab_id}-category-${category.category_id}`,
                `tab-${tab.tab_id}-category-${category.category_id}-section-${section.section_id}`,
              ];
            }
          });
      });
  });
  return path;
};

const DDSectionSelector = ({
  value,
  onChange,
  isEditForm,
  due_diligence_package_id,
}: {
  value: number | null;
  onChange: (section: IPackageDetailsSection) => void;
  isEditForm: boolean;
  due_diligence_package_id?: number;
}) => {
  const [selectedPackageId, setSelectedPackageId] = useState<
    number | undefined
  >(due_diligence_package_id);

  const { data: packages } = usePackageList();

  useEffect(() => {
    if (packages && !selectedPackageId) {
      setSelectedPackageId(_.first(packages)?.package_id);
    }
  }, [packages, selectedPackageId]);

  const { data: checklist } = usePackageDetails({
    packageId: selectedPackageId,
  });

  const selectedTreeViewPath = useMemo(() => {
    return checklist && value
      ? getSelectedTreeViewPath(checklist, value)
      : undefined;
  }, [checklist, value]);

  const handleChangePackage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedPackageId(Number(event.target.value));
    },
    []
  );

  if (!checklist) {
    return null;
  }

  return (
    <Stack spacing={2}>
      {packages && packages.length > 1 && (
        <Select
          disabled={isEditForm}
          label="Checklist"
          value={selectedPackageId}
          onChange={handleChangePackage}
          options={
            packages?.map(({ package_id, name }) => ({
              label: name,
              value: String(package_id),
            })) || []
          }
        />
      )}
      <TreeView
        defaultExpandedItems={selectedTreeViewPath}
        disableSelection={isEditForm}
        selectedItems={
          selectedTreeViewPath ? _.last(selectedTreeViewPath) : undefined
        }
      >
        {checklist.tabs.map((tab) => (
          <TreeItem
            itemId={`tab-${tab.tab_id}`}
            label={
              <TreeItemLabel
                text={`${tab.name} ${tab.is_deleted ? "(deleted)" : ""}`}
              />
            }
            disabled={isEditForm}
          >
            {tab.categories.map((category) => (
              <TreeItem
                itemId={`tab-${tab.tab_id}-category-${category.category_id}`}
                label={
                  <TreeItemLabel
                    text={`${category.name} ${
                      category.is_deleted ? "(deleted)" : ""
                    }`}
                  />
                }
                disabled={isEditForm}
              >
                {category.sections.map((section) => (
                  <TreeItem
                    itemId={`tab-${tab.tab_id}-category-${category.category_id}-section-${section.section_id}`}
                    label={
                      <TreeItemLabel
                        text={`${section.name} ${
                          section.is_deleted ? "(deleted)" : ""
                        }`}
                        iconType={
                          section.section_id !== value
                            ? "unselected"
                            : "selected"
                        }
                      />
                    }
                    disabled={isEditForm}
                    onClick={() => !isEditForm && onChange(section)}
                  ></TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
    </Stack>
  );
};

export default DDSectionSelector;

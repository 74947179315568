import { FieldType } from "entities/Field/sdk";
import FixedTableField from "entities/MemoTemplate/components/Fields/FixedTableField";
import Richtextfield from "entities/MemoTemplate/components/Fields/Richtextfield";
import Signaturefield from "entities/MemoTemplate/components/Fields/Signaturefield";
import Tablefield from "entities/MemoTemplate/components/Fields/Tablefield";
import Textfield from "entities/MemoTemplate/components/Fields/Textfield";
import { IMemoTemplateField } from "entities/MemoTemplate/sdk";

interface IFieldWrapper {
  field: IMemoTemplateField;
}

const FieldWrapper = ({ field }: IFieldWrapper) => {
  switch (field.field_type) {
    case FieldType.DROPDOWN:
      return <Textfield label={field.name} value={"..."} />;
    case FieldType.CHECKBOX:
      return <Textfield label={field.name} value={"..."} />;
    case FieldType.RADIO_BUTTON:
      return <Textfield label={field.name} value={"..."} />;
    case FieldType.TEXT_FIELD:
      return <Textfield label={field.name} value={"..."} />;
    case FieldType.FORM_TEXT_FIELD:
      return <Textfield label={field.name} value={"..."} />;
    case FieldType.RICH_TEXT_FIELD:
      return <Richtextfield label={field.name} value={"..."} />;
    case FieldType.SIGNATURE:
      return <Signaturefield label={field.name} />;
    case FieldType.TABLE:
      return <Tablefield field={field} />;
    case FieldType.FIXED_TABLE:
      return <FixedTableField field={field} />;
    case FieldType.FILE:
      return <Textfield label={field.name} value={"File field"} />;
  }
  return null;
};

export default FieldWrapper;

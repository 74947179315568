import React, { useCallback, useState } from "react";
import { FormikHelpers, useFormik } from "formik";

import { CloseIcon } from "icons";
import theme from "theme";

import Button from "components/Button";
import FormHelperText from "components/FormHelperText";
import IconButton from "components/IconButton";
import Loading from "components/Loading";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import UploadButton from "components/UploadButton";
import { IMAGE_TYPES } from "components/UploadButton/constants";

import {
  DEAL_PHASES,
  DEAL_STATUSES,
  PERSONA_OPTIONS,
  STATES_OPTIONS,
  VALIDATION_SCHEMA,
} from "entities/Deal/components/Form/constants";
import DealStatusButton from "entities/Deal/components/Form/DealStatusButton";
import DealFormSeparator from "entities/Deal/components/FormSeparator";
import { CONSTRUCTION_TYPES, PROPERTY_TYPES } from "entities/Deal/constants";
import { DealStatus, PropertyType } from "entities/Deal/constants";
import { IDealForCreation } from "entities/Deal/sdk";

const initialValues: Partial<IDealForCreation> = {
  name: "",
  persona: undefined,
  property_type: PropertyType.MULTIFAMILY_RESIDENTIAL,
  number_of_units: "",
  deal_status: DealStatus.PROSPECT,
  deal_phase: "",
  street: "",
  city: "",
  state: "",
  zipcode: "",
  construction_type: "",
};

export interface IDealForm {
  initialValues?: Partial<Omit<IDealForCreation, "id">>;
  onSubmit: (data: Partial<IDealForCreation>, image?: File) => Promise<any>;
  handleCancel?: () => void;
  submitLabel?: string;
}

const DealForm: React.FC<IDealForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
  submitLabel = "Submit",
}) => {
  const [showAvatarError, setShowAvatarError] = useState("");
  const [coverImage, setCoverImage] = useState<File>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    (
      values: Partial<IDealForCreation>,
      formikHelpers: FormikHelpers<Partial<IDealForCreation>>
    ) => {
      setIsSubmitting(true);

      if (coverImage) {
        return onSubmit({ ...values, city: values.city?.trim() }, coverImage)
          .catch(formikHelpers.setErrors)
          .finally(() => setIsSubmitting(false));
      } else {
        return onSubmit({ ...values, city: values.city?.trim() })
          .catch(formikHelpers.setErrors)
          .finally(() => setIsSubmitting(false));
      }
    },
    [coverImage, onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const changeDealStatus = useCallback(
    (value: DealStatus) => formik.setFieldValue("deal_status", value),
    [formik]
  );

  const handleUploadAvatar = useCallback((file: File) => {
    if (IMAGE_TYPES.includes(file.type)) {
      setShowAvatarError("");
      setCoverImage(file);
    } else {
      setShowAvatarError(
        "Upload a valid image. The file you uploaded was either not an image or a corrupted image."
      );
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={isSubmitting} />
      <Stack spacing={3}>
        <DealFormSeparator title="Deal details" />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Deal display name"
          {...formik.getFieldProps("name")}
          error={formik.touched.name && !!formik.errors.name}
          helperText={
            formik.touched.name && formik.errors.name ? formik.errors.name : ""
          }
          inputProps={{ "data-testid": "deal-name-input" }}
          data-testid="deal-name"
        />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Select
            inputLabel="Property type"
            options={PROPERTY_TYPES}
            {...formik.getFieldProps("property_type")}
            error={
              formik.touched.property_type && !!formik.errors.property_type
            }
            helperText={
              formik.touched.property_type && formik.errors.property_type
            }
            inputProps={{ "data-testid": "property-type-dropdown-input" }}
            data-testid="property-type-dropdown"
            dropdownOptionsDataTestid="property-type-dropdown-option"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="No. of units"
            {...formik.getFieldProps("number_of_units")}
            error={
              formik.touched.number_of_units && !!formik.errors.number_of_units
            }
            helperText={
              formik.touched.number_of_units && formik.errors.number_of_units
                ? formik.errors.number_of_units
                : ""
            }
            inputProps={{ "data-testid": "no-of-units-input" }}
            data-testid="no-of-units"
          />
        </Stack>
        <Stack spacing={0.5} style={{ marginBottom: theme.spacing(3) }}>
          <Text variant="label">Deal status</Text>
          <Stack direction="row" spacing={1}>
            {DEAL_STATUSES.map(({ value, label }) => (
              <DealStatusButton
                key={value}
                variant={
                  formik.values["deal_status"] === value
                    ? "contained"
                    : "outlined"
                }
                label={label}
                onClick={() => changeDealStatus(value)}
                dataTestid={`deal-status-${value.toLowerCase()}`}
              />
            ))}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Select
            inputLabel="Deal phase"
            options={DEAL_PHASES}
            {...formik.getFieldProps("deal_phase")}
            error={formik.touched.deal_phase && !!formik.errors.deal_phase}
            helperText={formik.touched.deal_phase && formik.errors.deal_phase}
            inputProps={{ "data-testid": "deal-phase-dropdown-input" }}
            data-testid="deal-phase-dropdown"
            dropdownOptionsDataTestid="deal-phase-dropdown-option"
            sx={{ flexBasis: "50%" }}
          />
          <Select
            inputLabel="Construction Type"
            options={CONSTRUCTION_TYPES}
            {...formik.getFieldProps("construction_type")}
            error={
              formik.touched.construction_type &&
              !!formik.errors.construction_type
            }
            helperText={
              formik.touched.construction_type &&
              formik.errors.construction_type
            }
            inputProps={{ "data-testid": "construction-type-dropdown-input" }}
            data-testid="construction-type-dropdown"
            dropdownOptionsDataTestid="construction-type-dropdown-option"
            sx={{ flexBasis: "50%" }}
          />
        </Stack>
        <DealFormSeparator title="Project location" />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Project address"
          {...formik.getFieldProps("street")}
          error={formik.touched.street && !!formik.errors.street}
          helperText={
            formik.touched.street && formik.errors.street
              ? formik.errors.street
              : ""
          }
          inputProps={{ "data-testid": "deal-street-address-input" }}
          data-testid="deal-street-address"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="City"
          {...formik.getFieldProps("city")}
          error={formik.touched.city && !!formik.errors.city}
          helperText={
            formik.touched.city && formik.errors.city ? formik.errors.city : ""
          }
          inputProps={{ "data-testid": "deal-city-address-input" }}
          data-testid="deal-city-address"
        />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Select
            inputLabel="State"
            options={STATES_OPTIONS}
            {...formik.getFieldProps("state")}
            error={formik.touched.state && !!formik.errors.state}
            helperText={formik.touched.state && formik.errors.state}
            inputProps={{ "data-testid": "deal-state-dropdown-input" }}
            data-testid="deal-state-dropdown"
            dropdownOptionsDataTestid="deal-state-dropdown-option"
            sx={{ flexBasis: "50%" }}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Zip code"
            {...formik.getFieldProps("zipcode")}
            error={formik.touched.zipcode && !!formik.errors.zipcode}
            helperText={
              formik.touched.zipcode && formik.errors.zipcode
                ? formik.errors.zipcode
                : ""
            }
            inputProps={{ "data-testid": "deal-zip-code-address-input" }}
            data-testid="deal-zip-code-address"
            sx={{ flexBasis: "50%" }}
          />
        </Stack>
        <DealFormSeparator title="User access" />
        <FormHelperText>
          You will be added as team company deal admin but need to specify your
          team role persona.
        </FormHelperText>
        <Select
          inputLabel="Persona"
          options={PERSONA_OPTIONS}
          {...formik.getFieldProps("persona")}
          error={formik.touched.persona && !!formik.errors.persona}
          helperText={
            formik.touched.persona && formik.errors.persona
              ? formik.errors.persona
              : ""
          }
          data-testid="deal-persona-dropdown"
          dropdownOptionsDataTestid="deal-persona-dropdown-option"
        />
        <DealFormSeparator title="Cover image (optional)" />
        <Text variant="text2">
          Upload a cover photo (jpg or png) for this project.
        </Text>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <UploadButton
            accept={IMAGE_TYPES.join(",")}
            onChange={({ files }: { files: Array<File> }) =>
              handleUploadAvatar(files[0])
            }
            dataTestid="upload-deal-cover-image-button"
            maxFiles={1}
          />
          {showAvatarError && (
            <FormHelperText error>{showAvatarError}</FormHelperText>
          )}
          {coverImage && (
            <Text>
              {coverImage?.name}{" "}
              <IconButton onClick={() => setCoverImage(undefined)}>
                <CloseIcon data-testid="remove-uploaded-image-button" />
              </IconButton>
            </Text>
          )}
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="end"
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            onClick={handleCancel}
            data-testid="cancel-create-deal-dialog-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-create-deal-form-button"
          disabled={isSubmitting}
        >
          {submitLabel}
        </Button>
      </Stack>
    </form>
  );
};

export default DealForm;

import { memo, useEffect, useMemo } from "react";
import _ from "lodash";

import { CheckCircleIcon, CloseIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { TABLE_CELL_STYLES, TABLE_HEADER_STYLES } from "utils/constants";

import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import FieldValueTableSkeleton from "entities/Superuser/components/FieldValueTableSkeleton";
import {
  IFieldGroup,
  ISuperuserFieldValuesData,
  useCompanyCustomFieldValuesForField,
} from "entities/Superuser/sdk";

import { getErrorMessageforField, HEADERS } from "./constants";

export interface IFieldValueTable extends IFieldGroup {
  fieldFetchCallback: ({ field }: { field: ISuperuserFieldValuesData }) => void;
}

const FieldValueTable = ({
  fieldId,
  fieldFormatType,
  fieldFetchCallback,
}: IFieldValueTable) => {
  const {
    data: field,
    isLoading,
    isValidating,
    error,
  } = useCompanyCustomFieldValuesForField({
    fieldId,
    fieldFormatType,
  });

  useEffect(() => {
    if (!_.isNil(field)) {
      fieldFetchCallback({ field });
    }
  }, [field, fieldFetchCallback]);

  const loading = useMemo(
    () => isLoading || isValidating,
    [isLoading, isValidating]
  );

  if (!_.isEmpty(error)) {
    return (
      <Text variant="label" textAlign="center">
        {getErrorMessageforField({ fieldId })}
      </Text>
    );
  }

  if (_.isUndefined(field) || loading) {
    return <FieldValueTableSkeleton />;
  }

  return (
    <Stack
      spacing={1}
      sx={{
        padding: theme.spacing(2, 0),
        overflowX: "auto",
        maxWidth: "100%",
        "table, th, td": {
          border: `1px solid ${colors.blue20}`,
          borderCollapse: "collapse",
        },
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Text variant="text1">Field "{field.field_id}"</Text>
        <Tag label={field.field_type} variant="gray60" />
      </Stack>
      <table>
        <thead>
          <tr>
            {HEADERS.map((header, index) => (
              <td
                key={index}
                style={{
                  ...TABLE_HEADER_STYLES,
                  position: "sticky",
                  left: 0,
                }}
              >
                <Text
                  variant="labelSmall"
                  fontWeight={500}
                  sx={{ color: colors.gray60 }}
                >
                  {header}
                </Text>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {field.data.length === 0 ? (
            <tr>
              <td style={TABLE_CELL_STYLES} colSpan={HEADERS.length}>
                <Text variant="label" textAlign="center">
                  No data yet.
                </Text>
              </td>
            </tr>
          ) : (
            field.data.map((data, index) => (
              <tr key={index}>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">
                    {_.isNull(data.intake_name) ? "-" : data.intake_name}
                  </Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">
                    {_.isNull(data.deal_name) ? "-" : data.deal_name}
                  </Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.company_package_name}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.tab_name}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.category_name}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.section_name}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <div
                    style={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                    }}
                  >
                    <Text variant="text2">{data.field_name}</Text>
                  </div>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.field_format_type}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.old_value}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.new_value}</Text>
                </td>
                <td style={TABLE_CELL_STYLES}>
                  {data.can_be_migrated ? (
                    <CheckCircleIcon sx={{ color: colors.green100 }} />
                  ) : (
                    <CloseIcon sx={{ color: colors.red100 }} />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </Stack>
  );
};

export default memo(FieldValueTable, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { TypographyVariantsOptions } from "@mui/material/styles";

export const typography: TypographyVariantsOptions = {
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontFamily: ["Roboto", "sans-serif"].join(","),
  h1: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "120%",
  },
  h2: {
    fontSize: "28px",
    fontWeight: 700,
    lineHeight: "120%",
  },
  h3: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "120%",
  },
  text1: {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "140%",
  },
  text2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "140%",
  },
  text3: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "140%",
  },
  text4: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "140%",
  },
  label: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  labelSmall: {
    fontSize: "11px",
    lineHeight: "140%",
    fontWeight: 400,
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
};

import { useState } from "react";

import { MenuItem } from "components/Menu";

import PdfImportHistoryDialog from "entities/Package/components/PdfImport/PdfImportHistoryDialog";

export interface IPdfImportHistoryButton {}

const PdfImportHistoryButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)} dataTestid="action-menu-option">
        Upload history
      </MenuItem>
      {isOpen && (
        <PdfImportHistoryDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default PdfImportHistoryButton;

import React from "react";

import theme from "theme";

import Box from "components/Box";

export interface IStickyMenu {
  top?: string;
  children?: React.ReactNode;
}

const StickyMenu = ({ children, top = theme.spacing(6) }: IStickyMenu) => (
  <Box sx={{ position: "sticky", top }}>{children}</Box>
);

export default StickyMenu;

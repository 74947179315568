import { useMemo } from "react";
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { IntegrationsIcon } from "icons";
import { colors } from "theme/palette";
import { addQueryParams, reverse } from "utils/urls";

import Box from "components/Box";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import NavLink from "components/NavLink";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import { Persona, useCompanySettings } from "entities/Company/sdk";
import { useDealPermission } from "entities/Deal/sdk";
import { useDealPackagesList } from "entities/Package/sdk";
import { ProformaSubNavItems } from "entities/Proforma/constants";
import { TaskTypes } from "entities/Task/constants";

import IconNavItem from "./IconNavItem";
import MemoMenu from "./MemoMenu";
import NavItem from "./NavItem";

const MenuList = () => {
  const params = useParams<{
    dealId: string;
    packageId?: string;
    memoId?: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = matchRoutes(
    [URLS.DEAL_WORKSPACE_TAB, URLS.DEAL_PROFORMA_TAB].map((x) => ({ path: x })),
    location
  );
  const [searchParams] = useSearchParams();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const memoId = Number(params.memoId);

  const { data: userPermission, isLoading: userPermissionLoading } =
    useDealPermission({
      dealId,
    });

  const { data: packages = [] } = useDealPackagesList({
    dealId,
  });
  const orderedPackages = useMemo(
    () => _.orderBy(packages, ["name"]),
    [packages]
  );

  const userIsLender = useMemo(
    () => userPermission?.persona === Persona.LENDER,
    [userPermission]
  );

  const userIsDeveloper = useMemo(
    () => userPermission?.persona === Persona.DEVELOPER,
    [userPermission]
  );

  const userIsLenderOrDeveloper = useMemo(
    () => userIsLender || userIsDeveloper,
    [userIsLender, userIsDeveloper]
  );

  const userIsLenderOrDeveloperAndHasFullAccess = useMemo(
    () => userIsLenderOrDeveloper && userPermission?.is_full_access,
    [userIsLenderOrDeveloper, userPermission]
  );

  const userIsDealAdmin = useMemo(
    () => userPermission?.is_deal_admin || false,
    [userPermission]
  );

  const AISummaryIsActive = useMemo(
    () => location.pathname.indexOf("/ai-summaries") > 0,
    [location]
  );

  const integrationsIsActive = useMemo(
    () => location.pathname.indexOf("/company-integration") > 0,
    [location]
  );

  const { data: dealCompanySettings, isLoading: dealCompanySettingsIsLoading } =
    useCompanySettings({ dealId });
  const { data: userCompanySettings, isLoading: userCompanySettingsIsLoading } =
    useCompanySettings({});

  const companySettingsIsLoading =
    dealCompanySettingsIsLoading || userCompanySettingsIsLoading;

  const showAISummaryMenu = useMemo(
    () => userCompanySettings?.ai_summary_shown,
    [userCompanySettings]
  );

  const showAMProformaMenu = useMemo(
    () =>
      userIsLenderOrDeveloperAndHasFullAccess &&
      dealCompanySettings?.asset_management_proforma_shown,
    [userIsLenderOrDeveloperAndHasFullAccess, dealCompanySettings]
  );

  const showUWProformaMenu = useMemo(
    () =>
      userIsLenderOrDeveloperAndHasFullAccess &&
      dealCompanySettings?.underwriting_proforma_shown,
    [userIsLenderOrDeveloperAndHasFullAccess, dealCompanySettings]
  );

  const showDataTablesMenu = useMemo(
    () =>
      userPermission?.is_full_access && dealCompanySettings?.deal_tables_shown,
    [userPermission, dealCompanySettings]
  );

  const showProformaMenu = useMemo(
    () => showDataTablesMenu || showUWProformaMenu || showAMProformaMenu,
    [showDataTablesMenu, showUWProformaMenu, showAMProformaMenu]
  );

  const uwProformaMenuIsActive = useMemo(
    () => _.first(currentRoute)?.route?.path === URLS.DEAL_PROFORMA_TAB,
    [currentRoute]
  );

  const amProformaMenuIsActive = useMemo(
    () =>
      location.pathname.startsWith(
        reverse(URLS.DEAL_ASSET_MANAGEMENT, { dealId })
      ),
    [location, dealId]
  );

  const dataTablesMenuIsActive = useMemo(
    () => location.pathname.indexOf("/data-tables") > 0,
    [location]
  );

  const proformaMenuIsActive = useMemo(
    () =>
      amProformaMenuIsActive ||
      uwProformaMenuIsActive ||
      dataTablesMenuIsActive,
    [amProformaMenuIsActive, uwProformaMenuIsActive, dataTablesMenuIsActive]
  );

  const showMemoMenu = useMemo(
    () =>
      userIsLenderOrDeveloperAndHasFullAccess &&
      dealCompanySettings?.memo_shown,
    [userIsLenderOrDeveloperAndHasFullAccess, dealCompanySettings]
  );

  const handleAISummaryClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.AI_SUMMARY,
    callback: () => navigate(reverse(URLS.DEAL_AI_SUMMARIES, { dealId })),
  });

  const handleUWProformaClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.UNDERWRITING_PROFORMA,
    callback: () =>
      navigate(
        reverse(URLS.DEAL_PROFORMA_TAB, {
          dealId,
          tab: ProformaSubNavItems.SOURCE_AND_USES,
        })
      ),
  });

  const handleAMProformaClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.ASSET_MANAGEMENT_PROFORMA,
    callback: () => navigate(reverse(URLS.DEAL_ASSET_MANAGEMENT, { dealId })),
  });

  const handleDataTablesClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.DEAL_TABLES,
    callback: () => navigate(reverse(URLS.DEAL_TABLE_STORAGE_LIST, { dealId })),
  });

  return (
    <Stack direction="row" data-testid="deal-navigation-menu" spacing={1}>
      {userPermissionLoading ? (
        _.range(6).map((index) => (
          <Skeleton key={index} width={100} height={40} />
        ))
      ) : (
        <>
          <NavLink
            to={reverse(URLS.DEAL_OVERVIEW, { dealId })}
            linkDataTestid="deal-overview-link"
          >
            {({ isActive }) => (
              <NavItem
                isActive={isActive}
                label="Overview"
                buttonDataTestid="deal-navigation-overview"
              />
            )}
          </NavLink>

          {userPermission?.is_full_access ? (
            <NavItem
              isActive={
                _.first(currentRoute)?.route?.path === URLS.DEAL_WORKSPACE_TAB
              }
              label="Workspace"
              buttonDataTestid="deal-navigation-workspace"
            >
              <GroupLabel label="Tasks" />
              <NavLink
                linkDataTestid="deal-tasks-my-tasks-link"
                to={addQueryParams(
                  reverse(URLS.DEAL_WORKSPACE_TAB, {
                    dealId,
                    tab: "tasks",
                  }),
                  {
                    taskType: TaskTypes.MY_TASKS,
                  }
                )}
              >
                {({ isActive }) => (
                  <MenuItem
                    selected={
                      isActive &&
                      searchParams.get("taskType") === TaskTypes.MY_TASKS
                    }
                    dataTestid="deal-my-tasks-label"
                  >
                    My tasks
                  </MenuItem>
                )}
              </NavLink>
              <NavLink
                linkDataTestid="deal-tasks-my-team-tasks-link"
                to={addQueryParams(
                  reverse(URLS.DEAL_WORKSPACE_TAB, {
                    dealId,
                    tab: "tasks",
                  }),
                  {
                    taskType: TaskTypes.MY_TEAM_TASKS,
                  }
                )}
              >
                {({ isActive }) => (
                  <MenuItem
                    selected={
                      isActive &&
                      searchParams.get("taskType") === TaskTypes.MY_TEAM_TASKS
                    }
                    dataTestid="deal-my-team-tasks-label"
                  >
                    My team's tasks
                  </MenuItem>
                )}
              </NavLink>
              <NavLink
                linkDataTestid="deal-tasks-all-tasks-link"
                to={addQueryParams(
                  reverse(URLS.DEAL_WORKSPACE_TAB, {
                    dealId,
                    tab: "tasks",
                  }),
                  {
                    taskType: TaskTypes.ALL_TASKS,
                  }
                )}
              >
                {({ isActive }) => (
                  <MenuItem
                    selected={
                      isActive &&
                      searchParams.get("taskType") === TaskTypes.ALL_TASKS
                    }
                    dataTestid="deal-all-tasks-label"
                  >
                    All tasks
                  </MenuItem>
                )}
              </NavLink>

              <GroupLabel label="Teams" />
              <NavLink
                linkDataTestid="deal-teams-link"
                to={reverse(URLS.DEAL_WORKSPACE_TAB, { dealId, tab: "teams" })}
              >
                {({ isActive }) => (
                  <MenuItem selected={isActive} dataTestid="deal-teams-label">
                    Teams
                  </MenuItem>
                )}
              </NavLink>
            </NavItem>
          ) : (
            <NavLink
              linkDataTestid="deal-tasks-my-tasks-link"
              to={addQueryParams(
                reverse(URLS.DEAL_WORKSPACE_TAB, {
                  dealId,
                  tab: "tasks",
                }),
                {
                  taskType: TaskTypes.MY_TASKS,
                }
              )}
            >
              {({ isActive }) => (
                <NavItem
                  isActive={isActive}
                  label="My tasks"
                  buttonDataTestid="deal-navigation-my-tasks"
                />
              )}
            </NavLink>
          )}

          {companySettingsIsLoading ? (
            <Skeleton width={100} height={40} />
          ) : (
            showProformaMenu && (
              <NavItem
                isActive={proformaMenuIsActive}
                label="Pro formas"
                buttonDataTestid="deal-navigation-proforma"
              >
                {showUWProformaMenu && (
                  <Box
                    data-testid="deal-proforma-sources-and-uses"
                    onClick={handleUWProformaClick}
                  >
                    <MenuItem selected={uwProformaMenuIsActive}>
                      Underwriting
                    </MenuItem>
                  </Box>
                )}
                {showAMProformaMenu && (
                  <Box
                    data-testid="deal-proforma-asset-management"
                    onClick={handleAMProformaClick}
                  >
                    <MenuItem selected={amProformaMenuIsActive}>
                      Asset management
                    </MenuItem>
                  </Box>
                )}
                {showDataTablesMenu && (
                  <Box
                    data-testid="deal-data-tables"
                    onClick={handleDataTablesClick}
                  >
                    <MenuItem selected={dataTablesMenuIsActive}>
                      Data tables
                    </MenuItem>
                  </Box>
                )}
              </NavItem>
            )
          )}
          <NavItem
            buttonDataTestid="deal-navigation-checklists"
            isActive={
              location.pathname.indexOf("package") > 0 ||
              location.pathname.indexOf("checklists-overview") > 0
            }
            label="Checklists"
          >
            {userIsDealAdmin && (
              <div>
                <GroupLabel label="Overview" />
                <NavLink
                  linkDataTestid="deal-checklist-management"
                  to={reverse(URLS.DEAL_CHECKISTS_OVERVIEW, {
                    dealId,
                  })}
                >
                  <MenuItem
                    selected={
                      location.pathname.indexOf("checklist-management") > 0
                    }
                  >
                    Checklist overview
                  </MenuItem>
                </NavLink>
              </div>
            )}
            <GroupLabel label="Checklists added" />
            {_.isEmpty(orderedPackages) && (
              <MenuItem disabled dataTestid="no-checklists">
                No checklists
              </MenuItem>
            )}
            {orderedPackages?.map((pack) => (
              <NavLink
                linkDataTestid="deal-checklist-link"
                key={pack.package_id}
                to={reverse(URLS.DEAL_PACKAGE_NO_TAB, {
                  dealId,
                  packageId: pack.package_id,
                })}
              >
                <MenuItem
                  selected={pack.package_id === packageId}
                  data-testid="checklist-package-name"
                  title={pack.name}
                >
                  {_.truncate(pack.name, { length: 24 })}
                </MenuItem>
              </NavLink>
            ))}
          </NavItem>
          {companySettingsIsLoading ? (
            <Skeleton width={100} height={40} />
          ) : (
            showAISummaryMenu && (
              <Box
                onClick={handleAISummaryClick}
                data-testid="deal-ai-toolbox-link"
              >
                <NavItem
                  isActive={AISummaryIsActive}
                  label="AI summaries"
                  buttonDataTestid="deal-navigation-ai-toolbox"
                />
              </Box>
            )
          )}
          {companySettingsIsLoading ? (
            <Skeleton width={100} height={40} />
          ) : (
            showMemoMenu && <MemoMenu dealId={dealId} memoId={memoId} />
          )}
          {userPermission?.is_full_access && (
            <NavLink
              to={reverse(URLS.COMPANY_INTEGRATIONS_LIST, { dealId })}
              linkDataTestid="deal-company-integrations"
            >
              <IconNavItem
                icon={
                  <IntegrationsIcon
                    style={{ height: 32, width: 32, color: colors.gray100 }}
                  />
                }
                tooltipTitle="Integrations"
                isActive={integrationsIsActive}
                buttonDataTestid="deal-navigation-integrations"
              />
            </NavLink>
          )}
        </>
      )}
    </Stack>
  );
};

export default MenuList;

import React, { useCallback } from "react";
import { FieldInputProps } from "formik";
import _ from "lodash";

import theme from "theme";

import Box from "components/Box";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

interface ICheckboxTree {
  fieldInputProps?: FieldInputProps<string[]>;
  label: string;
  options: { value: string | number; label: string }[];
  value: Array<string | number>;
  onChange: (value: Array<string | number>) => void;
}

const CheckboxTree: React.FC<ICheckboxTree> = ({
  label,
  options,
  value: checkedOptions,
  fieldInputProps = {},
  onChange,
}) => {
  const handleChangeAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange(options.map(({ value }) => value));
      } else {
        onChange([]);
      }
    },
    [onChange, options]
  );

  const handleChangeOption = useCallback(
    (item: string | number) => {
      const newValues = _.xor(checkedOptions, [item]);

      onChange(newValues);
    },
    [checkedOptions, onChange]
  );

  const isAllChecked = checkedOptions.length === options.length;

  return (
    <Box
      data-testid={`${label.toLowerCase().replaceAll(" ", "-")}-checkbox-tree`}
    >
      <FormControlLabel
        label={
          <Tooltip title={label} enterDelay={1000} enterNextDelay={1000}>
            <Text
              variant="text2"
              fontWeight={700}
              data-testid="filter-head-section-name"
            >
              {label}
            </Text>
          </Tooltip>
        }
        data-testid="filter-head-section"
        sx={{ marginLeft: "-6px", marginTop: "-6px", alignItems: "flex-start" }}
        {...fieldInputProps}
        control={
          <Checkbox
            sx={{ padding: theme.spacing(0, 0.5, 0.5, 0.5) }}
            checked={isAllChecked}
            indeterminate={!isAllChecked && checkedOptions.length > 0}
            onChange={handleChangeAll}
            data-testid="filter-section-select-all-options-checkbox"
          />
        }
      />
      <Stack sx={{ marginLeft: theme.spacing(3) }} data-testid="filter-options">
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            label={
              <Tooltip
                title={item.label}
                enterDelay={1000}
                enterNextDelay={1000}
              >
                <Text
                  variant="text2"
                  data-testid="filter-option-name"
                  paddingTop={0.5}
                >
                  {item.label}
                </Text>
              </Tooltip>
            }
            data-testid="filter-option"
            sx={{ alignItems: "flex-start" }}
            control={
              <Checkbox
                sx={{ padding: theme.spacing(0, 0.5, 0.5, 0.5) }}
                checked={
                  _.findIndex(checkedOptions, (val) => item.value === val) >= 0
                }
                onChange={(event) => handleChangeOption(item.value)}
                data-testid="filter-option-checkbox"
              />
            }
          />
        ))}
      </Stack>
    </Box>
  );
};

export default CheckboxTree;

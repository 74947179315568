import { Dispatch, SetStateAction, useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { HistoryIcon } from "icons";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Link from "components/Link";
import PageHeader from "components/PageHeader";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

import AssetManagementActionsButton from "entities/AssetManagement/components/AssetManagementActionsButton";
import AssetManagementTableSettings from "entities/AssetManagement/components/AssetManagementTableSettings";
import AssetManagementToggle from "entities/AssetManagement/components/AssetManagementToggle";
import { AMColumn, AMResolution } from "entities/AssetManagement/constants";

export interface IAssetManagementPageHeader {
  columns: Array<AMColumn>;
  resolution: AMResolution;
  editMode: boolean;
  hasProforma: boolean;
  isLoading: boolean;
  onChangeEditMode: () => void;
  onChangeShowSubItems: () => void;
  setColumns: Dispatch<SetStateAction<Array<AMColumn>>>;
  setResolution: Dispatch<SetStateAction<AMResolution>>;
}

const AssetManagementPageHeader = ({
  columns,
  resolution,
  editMode,
  hasProforma,
  isLoading,
  onChangeEditMode,
  onChangeShowSubItems,
  setColumns,
  setResolution,
}: IAssetManagementPageHeader) => {
  const params = useParams<{ dealId: string }>();
  const dealId = Number(params.dealId);
  const toggleEditMode = useCallback(() => {
    onChangeEditMode();
  }, [onChangeEditMode]);

  const handleShowSubItems = useCallback(() => {
    onChangeShowSubItems();
  }, [onChangeShowSubItems]);

  return (
    <PageHeader
      title="Asset management"
      actions={
        <Stack spacing={2} direction="row">
          {isLoading ? (
            _.range(4).map((index) => (
              <Skeleton key={index} variant="text" width={150} height={50} />
            ))
          ) : (
            <>
              {hasProforma && (
                <>
                  <AssetManagementToggle
                    label="Edit mode"
                    onToggleChange={toggleEditMode}
                    checked={editMode}
                    dataTestid="edit-mode"
                  />
                  <AssetManagementToggle
                    label="Show sub items"
                    onToggleChange={handleShowSubItems}
                    dataTestid="show-hide-table-sub-items"
                  />
                  <AssetManagementTableSettings
                    columns={columns}
                    setColumns={setColumns}
                    resolution={resolution}
                    setResolution={setResolution}
                  />
                  <Link
                    to={reverse(URLS.DEAL_ASSET_MANAGEMENT_VERSIONS, {
                      dealId,
                    })}
                    linkDataTestid="am-versions-page-link"
                  >
                    <Button
                      variant="outlined"
                      startIcon={<HistoryIcon />}
                      data-testid="am-versions-page-button"
                    >
                      Version History
                    </Button>
                  </Link>
                </>
              )}
              <AssetManagementActionsButton />
            </>
          )}
        </Stack>
      }
    />
  );
};

export default AssetManagementPageHeader;

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

export interface IFeature {
  label: string;
  children: React.ReactNode;
}

const Feature = ({ label, children }: IFeature) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`1px solid ${colors.blue20}`}
      padding={theme.spacing(0.5, 0)}
    >
      <Text variant="text2">{label}</Text>
      {children}
    </Stack>
  );
};

export default Feature;

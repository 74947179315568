import React, { useCallback, useMemo, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import _ from "lodash";
import * as yup from "yup";

import { PersonIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Alert from "components/Alert";
import Box from "components/Box";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Chip from "components/Chip";
import DatePicker from "components/DatePicker";
import FormControlLabel from "components/FormControlLabel";
import InputLabel from "components/InputLabel";
import Loading from "components/Loading";
import Radio, { RadioGroup } from "components/Radio";
import { EMPTY_VALUE_REGEX } from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  INewTaskChannel_legacy,
  usePriorityList,
  useTagList,
  useTeamList,
} from "entities/Task/sdk";

export type IInitialValues = Partial<
  Omit<INewTaskChannel_legacy, "deadline"> & {
    deadline: Date | null;
    completed: boolean;
    send_email_instantly: boolean;
  }
>;

const VALIDATION_SCHEMA = yup.object({
  title: yup.string().required("This field is required!"),
  tags: yup.array().max(3, "You can add maximum 3 tags!"),
  assignee_type: yup.string().required("This field is required!"),
});

export const initialValues: IInitialValues = {
  title: "",
  assignee_type: "",
  description: "",
  deadline: null, // This is null as if it is undefined it'd be set to current date
  priority_id: "2",
  visible_to_team: "",
  assignee_id: null,
  tags: [],
  company_category: null,
  company_package: null,
  company_section: null,
  company_field: null,
  company_tab: null,
  send_email_instantly: false,
};
export interface ITaskForm {
  dealId: number;
  initialValues?: Partial<
    Omit<INewTaskChannel_legacy, "deadline"> & {
      deadline: Date | null;
      completed: boolean;
    }
  >;
  onSubmit: (
    data: Partial<
      Omit<INewTaskChannel_legacy, "deadline"> & {
        deadline: Date | null;
      }
    >
  ) => Promise<any>;
  handleCancel?: () => void;
  submitLabel?: string;
}

const TaskForm: React.FC<ITaskForm> = ({
  dealId,
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
  submitLabel = "Submit",
}) => {
  const { meta } = useDealSidepanel();

  const filters: { [key: string]: number } = useMemo(() => {
    if (_.isNil(meta)) {
      return {};
    }

    const category_id =
      meta?.taskChannel?.task?.company_category?.id ||
      meta?.taskCategory?.category_id;

    const section_id =
      meta?.taskChannel?.task?.company_section?.id ||
      meta?.taskSection?.section_id;

    const field_id =
      meta?.taskChannel?.task?.company_field?.id || meta?.taskField?.field_id;

    return _.omitBy(
      {
        category_id,
        section_id,
        field_id,
      },
      _.isNil
    ) as { [key: string]: number };
  }, [meta]);

  const { data: teamList } = useTeamList({
    dealId,
    filters,
  });
  const { data: priorityList } = usePriorityList();
  const { data: tagList } = useTagList();

  const [isPrivate, setIsPrivate] = useState(
    !!propsInitialValues?.visible_to_team
  );

  const selectOptions = useMemo(() => {
    if (teamList) {
      return _.flatMap(teamList, (persona) => {
        const sortedUserList = _.sortBy(
          _.map(persona.user_list, (value, key) => ({
            meta: { isPersona: false, id: key },
            label: value.full_name,
            value: key,
          })),
          [(o) => o.label.toLowerCase()]
        );

        return [
          {
            meta: {
              isPersona: true,
              id: persona.type,
              disabled: persona.type === "Partial access",
            },
            label: persona.type,
            value: persona.type,
          },
          ...sortedUserList,
        ];
      });
    }
    return [];
  }, [teamList]);

  const handleSubmit = useCallback(
    (
      values: Partial<
        Omit<INewTaskChannel_legacy, "deadline"> & {
          deadline: Date | null;
          completed: boolean;
        }
      >,
      formikHelpers: FormikHelpers<
        Partial<
          Omit<INewTaskChannel_legacy, "deadline"> & {
            deadline: Date | null;
            completed: boolean;
          }
        >
      >
    ) => {
      const selectedAssignee = _.first(
        selectOptions.filter((opt) => opt.value === values.assignee_type)
      );

      if (selectedAssignee) {
        if (selectedAssignee.meta.isPersona) {
          // values.assignee_type remains the same
          values.assignee_id = null;
          values.visible_to_team = isPrivate ? values.assignee_type : "";
        } else {
          values.assignee_id = Number(values.assignee_type);
          values.assignee_type = "Individual";
          values.visible_to_team = isPrivate ? values.assignee_type : "";
        }
      }

      if (values.description && EMPTY_VALUE_REGEX.test(values.description)) {
        return onSubmit({ ...values, description: "" }).catch(
          formikHelpers.setErrors
        );
      }

      return onSubmit(values).catch(formikHelpers.setErrors);
    },
    [onSubmit, isPrivate, selectOptions]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleChipsClick = useCallback(
    (id: number) =>
      formik.setFieldValue("tags", _.xor(formik.values.tags, [id])),
    [formik]
  );

  const handleChangeDescription = useCallback(
    (newDescription: string) =>
      formik.setFieldValue("description", newDescription),
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack flex="1">
        <Loading open={formik.isSubmitting || formik.isValidating} />
        <Stack spacing={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Title"
            name="title"
            defaultValue={formik.values.title}
            onChange={_.debounce(formik.handleChange, 200)}
            error={formik.touched.title && !!formik.errors.title}
            helperText={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ""
            }
          />
          <RichTextEditorField
            inputLabel="Description"
            defaultValue={formik.getFieldProps("description").value}
            onChange={_.debounce(handleChangeDescription, 200)}
          />
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            {selectOptions && (
              <Select
                label="Assign task to a team or a person"
                options={selectOptions}
                name="assignee_type"
                placeholder="Pick an option..."
                value={formik.values.assignee_type}
                onChange={formik.handleChange}
                sx={{ flexGrow: 1 }}
                error={
                  formik.touched.assignee_type && !!formik.errors.assignee_type
                }
                helperText={
                  formik.touched.assignee_type && formik.errors.assignee_type
                    ? formik.errors.assignee_type
                    : ""
                }
                renderOption={(option) => (
                  <>
                    {option.meta.isPersona ? (
                      <Text variant="text2">{option.label}</Text>
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <PersonIcon />
                        <Text variant="text2">{option.label}</Text>
                      </Stack>
                    )}
                  </>
                )}
              />
            )}
            <DatePicker
              label="Due date (optional)"
              value={formik.getFieldProps("deadline").value}
              onChange={(value: any) => formik.setFieldValue("deadline", value)}
              slotProps={{
                field: { clearable: true },
              }}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              label={
                <Text variant="labelSmall">
                  This task is private (visible only to you and assignee team).
                </Text>
              }
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              }
            />
            <FormControlLabel
              label={
                <Text variant="labelSmall">
                  Notify assigned user instantly.
                </Text>
              }
              control={
                <Checkbox {...formik.getFieldProps("send_email_instantly")} />
              }
            />
          </Stack>
          <Box>
            <InputLabel>Set priority</InputLabel>
            {priorityList && (
              <RadioGroup
                style={{ paddingBottom: theme.spacing(2) }}
                {...formik.getFieldProps("priority_id")}
              >
                {priorityList.map(({ id, name }) => (
                  <FormControlLabel
                    key={id}
                    label={<Text variant="text3">{name}</Text>}
                    value={id.toString()}
                    style={{ marginLeft: 0 }}
                    control={<Radio />}
                  ></FormControlLabel>
                ))}
              </RadioGroup>
            )}
          </Box>
          <Stack spacing={2}>
            <InputLabel>Tags</InputLabel>
            <Box
              sx={{
                flexWrap: "wrap",
                "& > .MuiChip-root": { margin: theme.spacing(0, 0.5, 1, 0) },
              }}
            >
              {tagList &&
                tagList.map(({ id, name }) => (
                  <Chip
                    key={id}
                    label={name}
                    onClick={() => handleChipsClick(id)}
                    color={
                      formik.values.tags &&
                      formik.values.tags.indexOf(id) !== -1
                        ? "primary"
                        : "secondary"
                    }
                  />
                ))}
            </Box>
            {formik.errors.tags && (
              <Alert severity="error">{formik.errors.tags}</Alert>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          spacing={1}
          sx={{
            backgroundColor: colors.white,
            position: "sticky",
            bottom: 0,
            zIndex: 2,
            paddingY: theme.spacing(2),
          }}
        >
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit">{submitLabel}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default TaskForm;

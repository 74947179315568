import Box from "components/Box";
import TextCell from "components/ScrollableTable/Cells/TextCell";

const AverageCell = ({
  cellData,
  title,
}: {
  cellData: string | number | undefined;
  title?: string;
}) => {
  return (
    <Box sx={{ "& .MuiTypography-root": { fontWeight: 700 } }}>
      <TextCell cellData={cellData} title={title} />
    </Box>
  );
};

export default AverageCell;

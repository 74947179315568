import { FormikProps, getIn } from "formik";
import _ from "lodash";

import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";

import { IFormikParticipant } from "entities/CompanyItegrations/components/Downhome/BusinessForm";
import ListFieldActions from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListFieldActions";
import { AbbreviatedStateOptions } from "entities/CompanyItegrations/components/Downhome/constants";

export interface IAddress {
  formik: FormikProps<IFormikParticipant>;
  onRemove: ({ index }: { index: number }) => void;
  onUpdatePrimary: ({ index }: { index: number }) => void;
}

const Address = ({ formik, onRemove, onUpdatePrimary }: IAddress) => (
  <>
    {formik.values.addresses?.map((address, index) => {
      const addressState = `addresses.${index}.state`;
      const addressCity = `addresses.${index}.city`;
      const addressStreet = `addresses.${index}.street1`;

      return (
        <Stack
          key={index}
          direction="row"
          spacing={2}
          alignItems="start"
          width="100%"
        >
          <Select
            options={AbbreviatedStateOptions}
            fullWidth
            label="State"
            size="medium"
            InputLabelProps={{ shrink: true }}
            {...formik.getFieldProps(addressState)}
            helperText={
              getIn(formik.touched, addressState) &&
              getIn(formik.errors, addressState)
            }
            error={
              getIn(formik.touched, addressState) &&
              !_.isEmpty(getIn(formik.errors, addressState))
            }
          />
          <TextField
            fullWidth
            label="City"
            size="medium"
            InputLabelProps={{ shrink: true }}
            {...formik.getFieldProps(addressCity)}
            helperText={
              getIn(formik.touched, addressCity) &&
              getIn(formik.errors, addressCity)
            }
            error={
              getIn(formik.touched, addressCity) &&
              !_.isEmpty(getIn(formik.errors, addressCity))
            }
          />
          <TextField
            fullWidth
            label="Street"
            size="medium"
            InputLabelProps={{ shrink: true }}
            {...formik.getFieldProps(addressStreet)}
            helperText={
              getIn(formik.touched, addressStreet) &&
              getIn(formik.errors, addressStreet)
            }
            error={
              getIn(formik.touched, addressStreet) &&
              !_.isEmpty(getIn(formik.errors, addressStreet))
            }
          />
          <ListFieldActions
            key={index}
            checked={address.primary}
            onPrimaryElementUpdate={() => onUpdatePrimary({ index })}
            onElementRemove={() => onRemove({ index })}
          />
        </Stack>
      );
    })}
  </>
);

export default Address;

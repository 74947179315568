import _ from "lodash";

import { format, FORMATS, parseISO } from "utils/datetime";

import { IFilters } from "components/useFilters";
import {
  ORDER_BY_COLUMN_FILTER_KEY,
  ORDER_BY_COLUMN_FILTER_LABEL,
} from "components/useFilters/constants";

import { IPipelineColumns } from "entities/Deal/sdk";
import {
  getConstructionTypeLabelByValue,
  getPropertyTypeLabelByValue,
} from "entities/Deal/utils";
import {
  ASSET_TYPE_COLUMN_KEY,
  CONSTRUCTION_TYPE_COLUMN_KEY,
  EFieldFormatType,
  PROPERTY_TYPE_COLUMN_KEY,
  SEARCH_STRING_FILTER_KEY,
  SEARCH_STRING_FILTER_LABEL,
  TABLE_SETTINGS_FILTER_KEY,
  TABLE_SETTINGS_FILTER_LABEL,
} from "entities/Reporting/constants";
import {
  INonPipelineColumns,
  IReportPackageFilter,
} from "entities/Reporting/sdk";

export const getFormattedSelectedFilters = ({
  filters,
  filterDict,
  columns,
}: {
  filters: IFilters;
  filterDict: IReportPackageFilter[] | undefined;
  columns: Array<IPipelineColumns | INonPipelineColumns>;
}) => {
  return Object.keys(filters).flatMap((key) => {
    if (key === ORDER_BY_COLUMN_FILTER_KEY) {
      const isReverse = filters[key]?.startsWith("-");
      const cleanKey = isReverse ? filters[key]?.slice(1) : filters[key];

      const selectedSortOption = `${cleanKey} (${
        isReverse ? "descending" : "ascending"
      })`;
      return `${ORDER_BY_COLUMN_FILTER_LABEL}: ${selectedSortOption}`;
    }

    const type = filterDict?.find(
      (filter) => filter.name === key.toLowerCase()
    )?.field_format_type;

    const isFilterEmpty =
      _.isEmpty(filters[key]) ||
      (_.isArray(filters[key]) && filters[key].every((x) => x === ""));

    if (isFilterEmpty && key !== TABLE_SETTINGS_FILTER_KEY) {
      return [];
    }

    if (_.includes([EFieldFormatType.DATE, EFieldFormatType.DATETIME], type)) {
      return `${key}: ${filters[key]
        .map((filter: string) =>
          filter !== "" ? format(parseISO(filter), FORMATS.FRONTEND.DATE) : ""
        )
        .join(" - ")}`;
    }

    if (
      _.includes(
        [
          EFieldFormatType.INTEGER,
          EFieldFormatType.FLOAT_2,
          EFieldFormatType.CURRENCY_USD,
        ],
        type
      )
    ) {
      return `${key}: ${filters[key].join(" - ")}`;
    }

    if (key.toLowerCase() === CONSTRUCTION_TYPE_COLUMN_KEY) {
      return filters[key].map((filterLabel) => {
        const label = getConstructionTypeLabelByValue({ value: filterLabel });
        return `${key}: ${label}`;
      });
    }

    if (
      key.toLowerCase() === PROPERTY_TYPE_COLUMN_KEY ||
      key === ASSET_TYPE_COLUMN_KEY
    ) {
      return filters[key].map((filterLabel) => {
        const label = getPropertyTypeLabelByValue({ value: filterLabel });
        return `${key}: ${label}`;
      });
    }

    if (key === SEARCH_STRING_FILTER_KEY) {
      return `${SEARCH_STRING_FILTER_LABEL}: ${filters[key]}`;
    }

    if (key === TABLE_SETTINGS_FILTER_KEY) {
      return _.xor(
        columns.map((col) => col.name),
        filters[key]
      ).map((filterLabel) => `${TABLE_SETTINGS_FILTER_LABEL}: ${filterLabel}`);
    }

    return filters[key].map((filterLabel) => `${key}: ${filterLabel}`);
  });
};

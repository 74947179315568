import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { MoreVertIcon, OpenInNewIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import IconButton from "components/IconButton";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { useSignedUser } from "entities/Auth/sdk";
import {
  INotification as INotificationEntity,
  notificationDelete,
  notificationMarkAsSeen,
} from "entities/Notification/sdk";
import Tags from "entities/Notification/Tags";
import { creationDateRetrieveReadable } from "entities/Notification/utils";

export interface INotification {
  notification: INotificationEntity;
  refetchNotifications: () => void;
}

const Notification = ({
  notification,
  refetchNotifications,
}: INotification) => {
  const navigate = useNavigate();
  const { mutate: refetchUser } = useSignedUser();

  const creationDate = useMemo(
    () =>
      creationDateRetrieveReadable({
        creationDate: notification.created_at,
      }),
    [notification.created_at]
  );

  const handleOpenClick = useCallback(() => {
    notificationMarkAsSeen({ notificationId: notification.id }).then(() => {
      refetchUser();
      navigate(notification.target_pathname);
    });
  }, [notification.id, refetchUser, navigate, notification.target_pathname]);

  const handleMarkAsRead = useCallback(() => {
    notificationMarkAsSeen({ notificationId: notification.id }).then(() => {
      refetchUser();
      refetchNotifications();
      toast.successMessage("Notification marked as read.");
    });
  }, [notification.id, refetchUser, refetchNotifications]);

  const handleDelete = useCallback(() => {
    notificationDelete({ notificationId: notification.id }).then(() => {
      refetchUser();
      refetchNotifications();
      toast.successMessage("Notification deleted.");
    });
  }, [notification.id, refetchUser, refetchNotifications]);

  const isSeen = useMemo(
    () => !_.isNil(notification.seen_at),
    [notification.seen_at]
  );

  return (
    <Stack
      spacing={1}
      sx={{
        padding: theme.spacing(1, 2),
        justifyContent: "space-between",
        borderBottom: `1px solid ${colors.gray20}`,
        transition: "background-color 0.2s ease-in-out",
      }}
    >
      <Tags notification={notification} />

      <Stack
        spacing={3}
        marginTop={1}
        marginBottom={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={1} direction="row">
          {!isSeen && (
            <div
              style={{
                width: "8px",
                height: "8px",
                flexShrink: 0,
                marginTop: "6px",
                borderRadius: "50%",
                backgroundColor: colors.blue100,
              }}
            />
          )}
          <Text
            variant="text2"
            color={colors.gray100}
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {notification.content}
          </Text>
        </Stack>

        <Stack direction="row" spacing={3} alignItems="center">
          <Tooltip title="actions">
            <ButtonDropdown
              label=""
              variant="text"
              icon={<MoreVertIcon />}
              sx={{
                padding: 0,
                height: "24px",
                width: "24px",
              }}
            >
              <GroupLabel label="Actions" />
              <MenuItem onClick={handleMarkAsRead}>
                <Text variant="text2">Mark as read</Text>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <Text variant="text2">Delete notification</Text>
              </MenuItem>
            </ButtonDropdown>
          </Tooltip>

          <Tooltip title="Open this page">
            <IconButton onClick={handleOpenClick}>
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Text variant="text3" whiteSpace="nowrap" color={colors.gray40}>
        {creationDate} by {notification.actor.first_name}{" "}
        {notification.actor.last_name}
      </Text>
    </Stack>
  );
};

export default Notification;

import theme from "theme";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import ProjectNarrativeEditButton from "entities/Deal/components/OverviewProjectNarrative/ProjectNarrativeEditButton";
import NoContent from "entities/Deal/components/OverviewSections/NoContent";
import { useDealSummaryByCategory } from "entities/Memo/sdk";

interface IOverviewProjectNarrative {
  dealId: number;
}

const OverviewProjectNarrative: React.FC<IOverviewProjectNarrative> = ({
  dealId,
}) => {
  const { data } = useDealSummaryByCategory({
    dealId,
    category: "executive_summary",
  });
  return (
    <Box>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="h2" data-testid="section-header-name">
            Project Narrative
          </Text>
          <ProjectNarrativeEditButton />
        </Stack>
        <ContentSeparator label="Project Narrative" />
        <Box
          sx={{
            paddingLeft: theme.spacing(1),
          }}
          data-testid="project-narrative-content"
        >
          {data?.summary && (
            <SanitizeHtml
              html={data?.summary}
              dataTestid="deal-narrative-content-added"
            />
          )}
          {!data?.summary && <NoContent />}
        </Box>
      </Stack>
    </Box>
  );
};

export default OverviewProjectNarrative;

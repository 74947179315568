import { useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const WorkspaceTasksReroute = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const navigate = useNavigate();

  navigate(reverse(URLS.DEAL_WORKSPACE_TAB, { dealId, tab: "tasks" }));

  return null;
};

export default WorkspaceTasksReroute;

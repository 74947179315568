import _ from "lodash";

import { BASE_URL } from "config/urls";
import { format, FORMATS } from "utils/datetime";
import { deleteCall, get, post, useFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

export interface ITaskDueDiligence {
  title: string;
  assignee_type: string;
  tag_data: {
    id: number;
    name: string;
  }[];
  assignee_id: null;
  company_category: number;
  company_package: number;
  company_tab: number;
}
export interface ITaskChannel {
  id: number;
  unread_count: number;
  message_count: number;
  task_edit_access: boolean;
  task_delete_access: boolean;
  tag_data: {
    id: number;
    name: string;
  }[];
  task: {
    id: number;
    completed: boolean;
    title: string;
    priority_id: number;
    assignee_type: string;
    visible_to_team: string;
    deadline: string | undefined;
    description: string;
    created_at: string;
    updated_at: string;
    deal_name: string;
    deal_id: number;
    company_package?: { id: number; name: string };
    company_tab?: { id: number; name: string };
    company_category?: { id: number; name: string };
    company_section?: { id: number; name: string };
    company_field?: { id: number; name: string };
    created_by: {
      first_name: string;
      last_name: string;
      persona: {
        name: string;
      };
    };
    updated_by: {
      first_name: string;
      last_name: string;
      persona: {
        name: string;
      };
    };
    assignee_id: null | {
      pk: number;
      first_name: string;
      last_name: string;
    };
  };
}

export const useTaskChannels = ({ dealId }: { dealId?: number }) =>
  useFetch<ITaskChannel[]>(
    dealId ? `chat-v2/deal/${dealId}/task-channels/` : undefined
  );

export const fetchTaskChannels = ({ dealId }: { dealId: number }) =>
  get<Array<ITaskChannel>>(`${BASE_URL}chat-v2/deal/${dealId}/task-channels/`);

export interface IMessage {
  id: string;
  read_by_user: any;
  message: string;
  created_at: string;
  task_channel: {
    id: number;
  };
  msg_from: {
    pk: number;
    first_name: string;
    last_name: string;
  };
}
export const useDealMessages = (channelId: number | undefined) =>
  useFetch<IMessage[]>(
    channelId ? `chat-v2/task-channels/${channelId}/messages/` : undefined
  );

export const markAsReadMessages = (channelId: number) =>
  post(`${BASE_URL}chat-v2/task-channels/${channelId}/messages/read/`);

export const sendNewMessage = (channelId: number, message: string) =>
  post(`${BASE_URL}chat-v2/task-channels/${channelId}/message/create/`, {
    message: message,
  });

export interface INewTaskChannel_legacy {
  assignee_type: string;
  assignee_id: number | null;
  title: string;
  description: string;
  deadline: string | null;
  priority_id: number | string;
  visible_to_team: string;
  tags: number[];
  company_category: number | string | null;
  company_section: number | string | null;
  company_field: number | string | null;
  company_package: number | string | null;
  company_tab: number | string | null;
}

export interface INewTaskChannel {
  assignee_type: string;
  assignee_id: number | null;
  title: string;
  description: string;
  completed: boolean;
  deadline: string | null;
  priority_id: number | string;
  visible_to_team: string;
  tags: number[];
  company_category: number | string | null;
  company_section: number | string | null;
  company_field: number | string | null;
  company_package: number | string | null;
  company_tab: number | string | null;
}

export const createTaskChannel = (
  dealId: number,
  data: Partial<
    Omit<INewTaskChannel, "deadline"> & {
      deadline: Date | null;
    }
  >
) => {
  const formattedData = {
    ...data,
    deadline: _.isNil(data.deadline)
      ? data.deadline
      : format(new Date(data.deadline), FORMATS.BACKEND.DATE),
  };

  return post(`${BASE_URL}v2/deal/${dealId}/deal-tasks/add/`, formattedData);
};

export const updateTaskChannel = (
  dealId: number,
  taskId: number,
  data: Partial<
    Omit<INewTaskChannel, "deadline"> & {
      deadline: Date | null;
    }
  >
) => {
  const parseData = {
    ...data,
    deadline: data.deadline && format(data.deadline, FORMATS.BACKEND.DATE),
  };
  return post(`${BASE_URL}v2/deal/deal-task/${taskId}/update/`, parseData);
};

export const updateDealTaskCompleted = ({
  dealTaskId,
  data,
}: {
  dealTaskId: number;
  data: { completed: boolean };
}) => post(`${BASE_URL}v2/deal/deal-task/${dealTaskId}/update-status/`, data);

export const deleteTask = (dealId: number, taskId: number) =>
  deleteCall(`${BASE_URL}v2/deal/deal-task/${taskId}/delete/`);

interface ITeam_legacy {
  [key: number]: {
    type: string;
    user_list: { [key: number]: { full_name: string } };
  };
}

export const useTeamList = ({
  dealId,
  filters,
}: {
  dealId: number;
  filters: { [key: string]: number };
}) =>
  useFetch<ITeam_legacy>(
    `v2/deal/${dealId}/team/list/${filters ? formatGetParams(filters) : ""}`
  );

export interface ITasksPriority {
  id: number;
  name: string;
}

export const usePriorityList = () =>
  useFetch<Array<ITasksPriority>>(`v2/deal/task-priority/list/`);

export const useTagList = () =>
  useFetch<{ id: number; name: string }[]>(`v2/deal/tag/list/`);

export interface ITasksProperties {
  deal_task_id: number;
  task_channel_id: number;
  title: string;
  priority: string;
  completed: boolean;
  assignee: {
    email: string;
    first_name: string;
    last_name: string;
    avatar: string;
    company: {
      name: string;
    };
  } | null;
  assignee_type: string | null;
  deal: {
    deal_id: number;
    name: string;
    thumbnail: string;
  };
  deadline: string | null;
  tags: string[];
  unread_count: number;
  is_private: boolean;
  company_package: { id: number; name: string } | null;
  company_tab: { id: number; name: string } | null;
  company_category: { id: number; name: string } | null;
  company_section: { id: number; name: string } | null;
  company_field: { id: number; name: string } | null;
}

export interface ITasks {
  completed: Array<ITasksProperties>;
  overdue: Array<ITasksProperties>;
  upcoming: Array<ITasksProperties>;
}

export type FilterValue = string | number;
export interface ITypeOfFilter {
  [key: string]: FilterValue[] | FilterValue;
}

export const useTasksData = ({ filters }: { filters?: ITypeOfFilter }) =>
  useFetch<ITasks>(
    `v2/company/tasks/list/${filters ? formatGetParams(filters) : ""}`
  );

export const useDealListForTaskFilters = () =>
  useFetch<{ deals: Array<{ id: number; name: string }> }>(
    `v2/company/tasks/deals-list/`
  );

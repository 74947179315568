import ContentSeparator from "components/ContentSeparator";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Text from "components/Text";

import ProjectEditButton from "entities/Deal/components/OverviewProject/ProjectEditButton";
import ProjectImage from "entities/Deal/components/OverviewProject/ProjectImage";
import ProjectTable from "entities/Deal/components/OverviewProject/ProjectTable";
import { IDeal } from "entities/Deal/sdk";

interface IOverviewProject {
  deal: IDeal;
  editable: boolean;
}

const OverviewProject = ({ deal, editable }: IOverviewProject) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Text variant="h2" data-testid="section-header-name">
          Project
        </Text>
        <NoPrint>{editable && <ProjectEditButton />}</NoPrint>
      </Stack>
      <ContentSeparator label="Project" />
      {deal && (
        <>
          <ProjectTable deal={deal} />
          <ProjectImage deal={deal} editable={editable} />
        </>
      )}
    </Stack>
  );
};

export default OverviewProject;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import {
  ArrowDropDownIcon,
  ArrowRightIcon,
  DeleteOutlineOutlinedIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { copyTextToClipboard } from "utils/common";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { IDiff } from "entities/AssetManagement/sdk";

export interface INameCell {
  row: { [key: string]: any };
  columnKey: string;
  editMode: boolean;
  isExpanded?: boolean;
  deleteRow: ({ itemCode, name }: { itemCode: any; name: any }) => void;
  diff?: IDiff["rows_diff"][string];
}

const NameCell = ({
  row,
  columnKey,
  editMode,
  isExpanded,
  deleteRow,
  diff,
}: INameCell) => {
  const hasChildren = row.children?.length > 0;

  const handleCodeClick = useCallback(({ code }: { code: string }) => {
    copyTextToClipboard({ text: code });
    toast.successMessage("Code has been copied to the clipboard.");
  }, []);

  const diffColor = useMemo(() => {
    if (!diff) {
      return "transparent";
    }
    if (diff.is_deleted) {
      return colors.red40;
    }
    return colors.green40;
  }, [diff]);

  const tooltipText = useMemo(() => {
    if (diff && diff.is_deleted) {
      return "Deleted row";
    }
    if (diff && !diff.is_deleted && _.isNull(diff.previous_label)) {
      return "Newly added row";
    }
    if (
      diff &&
      !diff.is_deleted &&
      diff.previous_label !== diff.current_label
    ) {
      return (
        <div data-testid="am-value-ver-tooltip">
          <div>Previous: {diff.previous_label}</div>
          <div>Current: {diff.current_label}</div>
        </div>
      );
    }

    return row[columnKey];
  }, [diff, row, columnKey]);

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        backgroundColor: diffColor,
        padding: theme.spacing(0.625, 2),
        paddingLeft: !hasChildren ? theme.spacing(5) : theme.spacing(2),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {hasChildren &&
          (isExpanded ? (
            <ArrowDropDownIcon data-testid="hide-button" />
          ) : (
            <ArrowRightIcon data-testid="expand-button" />
          ))}
        <Tooltip
          disableInteractive
          title={tooltipText}
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <Text
            noWrap
            variant="text3"
            sx={{
              paddingY: 0.5,
              fontWeight: hasChildren ? 700 : "initial",
            }}
            data-testid="cell-value"
          >
            {row[columnKey]}
          </Text>
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {editMode && row.sub && (
          <IconButton
            size="small"
            component="span"
            title="Delete row"
            onClick={() =>
              deleteRow({ itemCode: row.code, name: row[columnKey] })
            }
            dataTestid="delete-row-data-button"
          >
            <DeleteOutlineOutlinedIcon sx={{ width: 20, height: 20 }} />
          </IconButton>
        )}
        {row.code && (
          <Tooltip title={row.code} enterDelay={1000} enterNextDelay={1000}>
            <Button
              color="secondary"
              size="small"
              data-testid="item-code-tag"
              sx={{ margin: 0 }}
              onClick={() => handleCodeClick({ code: row.code })}
            >
              <Text
                sx={{
                  pointerEvents: "none",
                  fontSize: "11px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {row.code}
              </Text>
            </Button>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default NameCell;

import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import LeftMenu from "entities/Package/components/LeftMenu";
import { useDueDiligenceRerouteToTab } from "entities/Package/hooks";

const DueDiligenceRerouteToTab = () => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId?: string;
    categoryId?: string;
    sectionId?: string;
    fieldId?: string;
  }>();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId = !_.isNil(params.tabId) ? Number(params.tabId) : null;

  const { showError } = useDueDiligenceRerouteToTab({
    dealId,
    packageId,
    tabId,
  });

  usePageTitle("Builders Patch: Checklists");

  if (!showError) {
    return null;
  }

  return (
    <Paper
      square={true}
      sx={{
        padding: theme.spacing(2, 4),
        backgroundColor: colors.blue5,
      }}
    >
      <Stack spacing={2} direction="row" alignItems="flex-start">
        {_.isNil(tabId) && (
          <>
            <LeftMenu />
            <Paper style={{ flex: 1, overflowX: "auto" }}>
              <Box sx={{ paddingY: theme.spacing(5) }}>
                <Text
                  variant="h5"
                  textAlign="center"
                  data-testid="no-dd-permission-message"
                >
                  No tabs for this checklist
                </Text>
              </Box>
            </Paper>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default DueDiligenceRerouteToTab;

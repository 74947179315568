import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { EMPTY_LIST } from "utils/constants";

import Box from "components/Box";
import FixedTable from "components/FixedTable";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import EditButton from "entities/Field/components/FixedTableField/EditButton";
import { getTableVerticalHeaders } from "entities/Field/components/FixedTableField/utils";
import { fieldValueCreate } from "entities/Field/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";
import { handleInvalidMemoFieldRequest } from "entities/Memo/components/Fields/utils/utils";

interface IFixedTableField {
  breakInside?: string;
  field: IPackageDetailsField;
  dealId: number;
  externalToken?: string;
  onUpdate: () => void;
  isNASection: boolean;
}

const FixedTableField = ({
  field,
  dealId,
  breakInside = "auto",
  externalToken,
  isNASection,
  onUpdate,
}: IFixedTableField) => {
  const [value, setValue] = useCaching(
    _.get(field.values, "[0].column_value", EMPTY_LIST)
  );

  const navigate = useNavigate();

  const handleSave = useCallback(
    ({ newValue }: { newValue: any }) => {
      setValue(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.company_custom_field_id,
        value: JSON.stringify(newValue),
      })
        .then(() => {
          toast.successMessage(`${field.name} successfully updated!`);
        })
        .catch((error) =>
          handleInvalidMemoFieldRequest({ error, dealId, navigate })
        );
    },
    [field, dealId, setValue, navigate]
  );

  useEffect(() => {
    const columns =
      field.column_name?.split(",").map((column) => column.trim()) || [];

    if (_.isEmpty(value) && !_.isEmpty(field.rows) && !_.isEmpty(columns)) {
      setValue(
        getTableVerticalHeaders({
          firstColumn: columns[0],
          rows: field.rows,
        })
      );
    }
  }, [value, field, setValue]);

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showEditButton = useMemo(
    () => !isNAField && _.isUndefined(externalToken),
    [isNAField, externalToken]
  );

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ flexGrow: 0, borderBottom: `1px solid ${colors.blue40}` }}
      >
        <FieldTitle
          field={field}
          onUpdate={onUpdate}
          externalToken={externalToken}
          disabled={isNAField}
        />

        {showEditButton && (
          <NoPrint>
            <EditButton
              field={{
                display_name: field.name,
                header_name: field.column_name?.split(",").map((column) => ({
                  headerName: column.trim(),
                  field: column.trim(),
                })),
              }}
              initialValue={value}
              onSave={handleSave}
              buttonDataTestid="field-edit-button"
            />
          </NoPrint>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
      </Stack>
      {!isNAField && (
        <Box
          sx={{
            breakInside: breakInside,
            width: "100%",
          }}
          data-testid="dd-table-field-table"
        >
          <FixedTable
            headers={
              field.column_name?.split(",").map((column) => ({
                label: column.trim(),
                key: column.trim(),
              })) || []
            }
            rows={value}
            showEmptyRow={true}
            tableDataTestid={field.name}
          />
        </Box>
      )}
    </Box>
  );
};

export default FixedTableField;

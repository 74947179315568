import { FormikProps } from "formik";
import _ from "lodash";

import { CloseIcon } from "icons";
import { colors } from "theme/palette";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Select from "components/Select";
import SimpleTable from "components/SimpleTable";
import Cell from "components/SimpleTable/Cell";
import Text from "components/Text";
import TextField from "components/TextField";
import Tooltip from "components/Tooltip";

import { ACCESS_TYPE_OPTIONS } from "entities/Package/components/ShareDialog/constants";
import { getInitialInvitation } from "entities/Package/components/ShareDialog/utils";

interface IInvitationManagementTable<T> {
  formik: FormikProps<T>;
}

export interface IInvitation {
  id: string;
  email: string;
  access_type: string;
}

const InvitationManagementTable = <
  T extends { invitations: Array<IInvitation>; message: string },
>({
  formik,
}: IInvitationManagementTable<T>) => {
  const handleSingleInvitationChange = ({
    id,
    field,
    value,
  }: {
    id: string;
    field: string;
    value: string;
  }) => {
    const formikFieldIndex = formik.values.invitations.findIndex(
      (item) => item.id === id
    );

    formik.setFieldValue(`invitations.${formikFieldIndex}.${field}`, value);
  };

  const handleOverallInvitationsChange = ({
    newValue,
  }: {
    newValue: Array<IInvitation>;
  }) => {
    formik.setFieldValue("invitations", newValue);
  };

  const addInvitation = () => {
    const newInvitations = [
      ...formik.values.invitations,
      getInitialInvitation(),
    ];

    formik.setFieldValue("invitations", newInvitations);
  };

  const deleteInvitation = ({ invitationId }: { invitationId: string }) => {
    formik.setFieldValue(
      "invitations",
      formik.values.invitations.filter(
        (invitation) => invitation.id !== invitationId
      )
    );
  };

  return (
    <>
      <SimpleTable
        editable
        onUpdated={(newValue) =>
          handleOverallInvitationsChange({
            newValue: newValue as Array<IInvitation>,
          })
        }
        headers={[
          {
            width: 500,
            key: "email",
            label: "email address",
            render: (row) => {
              const formikValueIndex = formik.values.invitations.findIndex(
                (invitation) => invitation.id === row.id
              );

              const fieldName = `invitations.${formikValueIndex}.email`;
              const fieldTouched = _.get(formik.touched, fieldName);
              const fieldError = _.get(formik.errors, fieldName);

              return (
                <Cell
                  cellKey="email"
                  verticalAlign="top"
                  value={
                    <TextField
                      size="small"
                      fullWidth
                      type="email"
                      {...formik.getFieldProps(fieldName)}
                      error={fieldTouched && !!fieldError}
                      helperText={fieldTouched && fieldError}
                      data-testid="user-email"
                      inputProps={{
                        "data-testid": "user-email-input",
                      }}
                    />
                  }
                />
              );
            },
          },
          {
            key: "access_type",
            label: "access type",
            render: (row, headerKey) => (
              <Cell
                cellKey="access_type"
                verticalAlign="top"
                value={
                  <Select
                    size="small"
                    value={row[headerKey]}
                    options={ACCESS_TYPE_OPTIONS}
                    onChange={(event) => {
                      handleSingleInvitationChange({
                        id: row.id,
                        field: "access_type",
                        value: event.target.value,
                      });
                    }}
                    data-testid="user-access-type-dropdown"
                    inputProps={{ "data-testid": "user-access-type-input" }}
                    dropdownOptionsDataTestid="user-access-type-option"
                  />
                }
              />
            ),
          },
          {
            key: "delete",
            label: "",
            width: 40,
            render: (row) => (
              <Cell
                cellKey="delete"
                value={
                  <Tooltip title="Clear" placement="top">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() =>
                          deleteInvitation({ invitationId: row.id })
                        }
                        disabled={formik.values.invitations.length === 1}
                        dataTestid="remove-user-button"
                      >
                        <CloseIcon sx={{ width: "20px", height: "20px" }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              />
            ),
          },
        ]}
        rows={formik.values.invitations}
      />

      <Button
        sx={{ marginTop: 1, alignSelf: "flex-start" }}
        size="small"
        color="secondary"
        onClick={addInvitation}
        data-testid="add-new-row-button"
      >
        Add new row
      </Button>

      <Text
        variant="text3"
        fontWeight={700}
        marginBottom={0.5}
        marginTop={3}
        data-testid="invitation-message-field-label"
      >
        Message (optional)
      </Text>
      <TextField
        multiline
        hiddenLabel
        rows={4}
        {...formik.getFieldProps("message")}
        placeholder="Leave a message to explain what you are sharing and why... "
        error={formik.touched.message && !!formik.errors.message}
        helperText={
          <>
            {formik.touched.message && formik.errors.message
              ? formik.errors.message
              : ""}
          </>
        }
        sx={{
          backgroundColor: colors.blue10,
        }}
        data-testid="invitation-message"
        inputProps={{ "data-testid": "invitation-message-textarea" }}
      />
    </>
  );
};

export default InvitationManagementTable;

import _ from "lodash";

import { formatExcelValue } from "utils/common";
import { downloadListOfListsAsXLSX } from "utils/xlsx";

import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";

export const exportPipelineAsXLSX = ({
  headers,
  rows,
}: {
  headers: IScrollableTableHeader;
  rows: { [key: string]: string | null }[];
}) => {
  const headersData = headers.flatMap((header) =>
    header.children.map((subHeader) => subHeader.label)
  );
  const rowsData = rows.map((data) =>
    headers.flatMap((header) =>
      header.children.map((subHeader) => {
        // Retrieve the original value.
        const value = _.get(data, subHeader.key) || "";

        return formatExcelValue({ value });
      })
    )
  );

  const preparedData = [headersData, ...rowsData];
  downloadListOfListsAsXLSX(preparedData, "deals-dashboard.xlsx");
};

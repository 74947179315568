import React, { useMemo } from "react";
import _ from "lodash";

import { typography } from "theme/typography";

import { LinearProgress } from "components/Loading";
import { IHeader, renderHeaderCell } from "components/StyledTable/utils/header";
import { renderRow } from "components/StyledTable/utils/row";

export type { IHeader };

interface IProps<T = {}> {
  data: Array<{
    itemKey: string;
    values: Array<{
      itemKey: string;
      value: string;
      meta?: any;
    }>;
  }>;
  headers: IHeader<T>[];
  showLoading?: boolean;
  tableDataTestid?: string;
}

const StyledTable = <T extends {}>({
  data,
  headers,
  showLoading,
  tableDataTestid,
}: IProps<T>) => {
  const rowData = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        values: row.values.reduce(
          (prev, curr) => ({ ...prev, [curr.itemKey]: curr }),
          {} as { [key: string]: IProps<T>["data"][number]["values"][number] }
        ),
      })),
    [data]
  );

  return (
    <div
      style={{ width: "100%", overflow: "auto" }}
      data-testid={tableDataTestid}
    >
      <div style={{ width: "max-content" }}>
        <div style={{ display: "flex" }} data-testid="headers-section">
          {headers.map(renderHeaderCell)}
        </div>
        {showLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}
        <div data-testid="table-body">
          {!showLoading && _.isEmpty(rowData) && (
            <div
              style={{
                ...typography.text1,
                display: "flex",
                textAlign: "center",
                padding: "5px 12px",
              }}
              data-testid="empty-am-table-state"
            >
              No data in list
            </div>
          )}
          {!_.isEmpty(rowData) &&
            rowData.map((row) => (
              <div
                key={row.itemKey}
                style={{ display: "flex" }}
                data-testid="table-row"
              >
                {headers.flatMap((header, index) =>
                  header.children ? (
                    <div
                      key={`${row.itemKey}-${header.label}-${index}`}
                      style={{
                        marginRight: "40px",
                        display: "flex",
                        borderBottom: "1px solid #c5c5ee",
                        position: header.sticky ? "sticky" : "static",
                        left: 0,
                      }}
                      data-testid={`${header.label
                        .toLowerCase()
                        .replaceAll(" ", "-")}-header-cells`}
                    >
                      {header.children.map((subHeader, subIndex) => (
                        <React.Fragment
                          key={`${row.itemKey}-${header.label}-${index}-${subIndex}`}
                        >
                          {renderRow({
                            header: subHeader,
                            row,
                            index: subIndex + index * header.children!.length,
                          })}
                        </React.Fragment>
                      ))}
                    </div>
                  ) : (
                    renderRow({ header, row, index })
                  )
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StyledTable;

import { useCallback } from "react";
import _ from "lodash";

import { columnIsSortedByNumbers, sortListBy } from "utils/common";

import Button from "components/Button";
import { useFilters } from "components/useFilters";
import { ORDER_BY_COLUMN_FILTER_KEY } from "components/useFilters/constants";

import { getFilteredColumns } from "entities/Reporting/components/ReportTable/utils";
import {
  CUSTOM_REPORT_SEARCH_KEYS,
  TABLE_SETTINGS_FILTER_KEY,
} from "entities/Reporting/constants";
import {
  INonPipelineColumns,
  ISimpleReportPackage,
  useReportPackageFilterList,
} from "entities/Reporting/sdk";
import {
  EReportType,
  exportReportPackageAsCSV,
  getFilteredDeals,
} from "entities/Reporting/utils";

export interface ICustomReportExportButton {
  reportPackage: ISimpleReportPackage;
  columns: Array<INonPipelineColumns>;
  rows: { [key: string]: string | null }[];
}

const CustomReportExportButton = ({
  reportPackage,
  columns,
  rows,
}: ICustomReportExportButton) => {
  const { filters } = useFilters();
  const { data: filterDict } = useReportPackageFilterList({
    report_package_id: reportPackage.id,
  });

  const handleExport = useCallback(() => {
    const selectedOrderOption = _.get(filters, ORDER_BY_COLUMN_FILTER_KEY);

    const filteredColumns = getFilteredColumns({
      columns,
      tableSettingsFilter: filters[TABLE_SETTINGS_FILTER_KEY],
    });

    const filteredDeals = getFilteredDeals({
      rows,
      selectedFilters: filters,
      filterDict,
      searchKeys: CUSTOM_REPORT_SEARCH_KEYS,
    });

    const isOrderedByNumbers = columnIsSortedByNumbers({
      selectedSortOption: selectedOrderOption,
      data: filteredDeals,
    });

    const orderedDeals = sortListBy(
      filteredDeals,
      selectedOrderOption,
      isOrderedByNumbers
    );

    exportReportPackageAsCSV({
      columns: filteredColumns,
      rows: orderedDeals,
      reportType: EReportType.CUSTOM_REPORT,
      reportPackageName: reportPackage?.name,
    });
  }, [reportPackage?.name, columns, rows, filters, filterDict]);

  return (
    <Button onClick={handleExport} data-testid="export-report-button">
      Export
    </Button>
  );
};

export default CustomReportExportButton;

import { useCallback, useState } from "react";

import { URLS } from "config/urls";
import { ShareIcon } from "icons";
import { reverse } from "utils/urls";

import Button from "components/Button";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import ShareableLinkPopup from "entities/Checklist/components/ShareableLinkPopup";
import ShareDialog from "entities/Checklist/components/ShareDialog";
import { checklistCheckIsShareable } from "entities/Checklist/sdk";

export interface IChecklistShareButton {
  packageId: number;
}

const ChecklistShareButton = ({ packageId }: IChecklistShareButton) => {
  const [isOpenShareableDialog, setIsOpenShareableDialog] = useState(false);
  const [isOpenShareableLink, setIsOpenShareableLink] = useState(false);
  const [shareableLink, setShareableLink] = useState("");

  const handleShareChecklist = useCallback(() => {
    checklistCheckIsShareable({ packageId }).then(
      ({ is_shareable: isShareable, message }) => {
        if (isShareable) {
          setIsOpenShareableDialog(true);
        } else {
          let errorMessage: Array<string> = message || [DEFAULT_ERROR_MESSAGE];

          errorMessage.forEach((m: string) => toast.errorMessage(m));
        }
      }
    );
  }, [packageId]);

  const handleSaveSharedData = useCallback(({ token }: { token: string }) => {
    const sharedUrl = `${window.location.origin}${reverse(
      URLS.APPLICATION_DIRECT_LINK,
      {
        token,
      }
    )}`;

    setShareableLink(sharedUrl);
    setIsOpenShareableDialog(false);
    setIsOpenShareableLink(true);
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={<ShareIcon />}
        onClick={handleShareChecklist}
        data-testid="share-button"
      >
        Share checklist
      </Button>
      <ShareableLinkPopup
        shareableLink={shareableLink}
        open={isOpenShareableLink}
        onClose={() => setIsOpenShareableLink(false)}
      />
      {isOpenShareableDialog && (
        <ShareDialog
          open={isOpenShareableDialog}
          packageId={packageId}
          onSave={handleSaveSharedData}
          onClose={() => setIsOpenShareableDialog(false)}
        />
      )}
    </>
  );
};

export default ChecklistShareButton;

import ky from "ky";

import { BASE_URL } from "config/urls";
import { getConfig, post, put, useFetch } from "utils/sdk";

export interface IUser {
  unseen_notifications_count: number;
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar: string | null;
  is_admin: boolean;
  contact: string;
  job_title: string;
  company_id: number;
  company_logo: string | null;
  is_superuser: boolean;
  experimental_features: boolean;
  email_notifications_enabled: boolean;
}

export interface ITeamMember {
  id: number;
  first_name: string;
  last_name: string;
}

export const updateSignedUser = (
  user: Partial<Omit<IUser, "id" | "avatar">>
): Promise<IUser | undefined> =>
  put<IUser>(`${BASE_URL}v2/users/update-signed-user/`, user);

export const uploadUserAvatar = (
  userId: number,
  avatar: File
): Promise<{ avatar: string }> => {
  const postData = new FormData();
  postData.append("avatar", avatar);

  const postParams = {
    body: postData,
    ...getConfig({
      isFileUpload: true,
    }),
  };

  return ky.patch(`${BASE_URL}users/detail/${userId}/`, postParams).json();
};

export const userOnboard = ({
  values,
}: {
  values: {
    email: string;
    first_name: string;
    last_name: string;
    company_name: string;
  };
}) => post<undefined>(`${BASE_URL}v2/users/onboard/`, values);

export const useUserTeamMembers = () =>
  useFetch<Array<ITeamMember>>("v2/users/team-members/list/");

export const userChangeEmailNotiications = ({
  emailNotidicationsEnabled,
}: {
  emailNotidicationsEnabled: boolean;
}) =>
  post(`${BASE_URL}v2/users/change-email-notification-preferences/`, {
    email_notifications_enabled: emailNotidicationsEnabled,
  });

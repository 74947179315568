import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { MoreHorizIcon } from "icons";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { TABLE_CELL_STYLES, TABLE_HEADER_STYLES } from "utils/constants";

import ButtonDropdown from "components/ButtonDropdown";
import { LinearProgress } from "components/Loading";
import { MenuItem } from "components/Menu";
import Pagination from "components/Pagination";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import { TableContainer } from "components/Table";
import Text from "components/Text";

import { updateDeal } from "entities/Deal/sdk";
import {
  ISuperuserDashboardDeal,
  useSuperuserCompanyDeals,
  useSuperuserDashboardData,
  useSuperuserDashboardDealsCreatedByMonth,
  useSuperuserDashboardDemoDeals,
  useSuperuserDashboardTotalUnitsCount,
} from "entities/Superuser/sdk";

interface IKPIDealTable {
  dataIsDemo: boolean;
  showLoading: boolean;
  deals: Array<ISuperuserDashboardDeal>;
}

const PAGE_SIZE = 50;

const KPIDealTable: React.FC<IKPIDealTable> = ({
  dataIsDemo,
  showLoading,
  deals,
}) => {
  const [isLoading, setIsLoading] = useCaching(showLoading);
  const { mutate: updateCompanyDeals } = useSuperuserCompanyDeals();
  const { mutate: updateSuperuserDeals } = useSuperuserDashboardData();
  const { mutate: updateDemoSuperuserDeals } = useSuperuserDashboardDemoDeals();
  const { mutate: updateDealsCreatedByMonth } =
    useSuperuserDashboardDealsCreatedByMonth();
  const { mutate: updateTotalUnitsCount } =
    useSuperuserDashboardTotalUnitsCount();

  const [visibleDeals, setVisibleDeals] = useState<
    Array<ISuperuserDashboardDeal>
  >([]);

  useEffect(() => {
    setVisibleDeals(deals.slice(0, PAGE_SIZE));
  }, [deals]);

  const handleIsDealDemo = useCallback(
    (deal: ISuperuserDashboardDeal) => {
      setIsLoading(true);
      updateDeal({ dealId: deal.id, is_demo_deal: !dataIsDemo })
        .then(() => {
          updateCompanyDeals();
          updateSuperuserDeals();
          updateTotalUnitsCount();
          updateDemoSuperuserDeals();
          updateDealsCreatedByMonth();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      dataIsDemo,
      setIsLoading,
      updateCompanyDeals,
      updateSuperuserDeals,
      updateTotalUnitsCount,
      updateDemoSuperuserDeals,
      updateDealsCreatedByMonth,
    ]
  );

  const pageCount = useMemo(
    () => Math.ceil(deals.length / PAGE_SIZE),
    [deals.length]
  );

  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, selectedPage: number) => {
      const start = (selectedPage - 1) * PAGE_SIZE;
      const end = start + PAGE_SIZE;
      setVisibleDeals(deals.slice(start, end));
    },
    [deals]
  );

  return (
    <Stack spacing={3} alignItems="center">
      <TableContainer
        sx={{
          "table, th, td": {
            border: `1px solid ${colors.blue20}`,
            borderCollapse: "collapse",
          },
        }}
      >
        {isLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}
        <table
          style={{
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <td align="right" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  ID
                </Text>
              </td>
              <td align="left" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  NAME
                </Text>
              </td>
              <td align="left" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  CREATED IN
                </Text>
              </td>
              <td align="left" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  PHASE
                </Text>
              </td>
              <td align="left" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  COMPANY
                </Text>
              </td>
              <td align="right" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  DEVBUDGET
                </Text>
              </td>
              <td align="right" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  UNIT COUNT
                </Text>
              </td>
              <td align="right" style={TABLE_HEADER_STYLES}>
                <Text variant="labelSmall" fontWeight={500}>
                  ACTIONS
                </Text>
              </td>
            </tr>
          </thead>

          <tbody>
            {_.isEmpty(deals) &&
              _.range(20).map((index) => (
                <tr key={index}>
                  {_.range(8).map((colIndex) => (
                    <td key={colIndex} align="right">
                      <Skeleton variant="text" height="38px" />
                    </td>
                  ))}
                </tr>
              ))}
            {visibleDeals?.map((data, index) => (
              <tr key={index}>
                <td align="right" style={TABLE_CELL_STYLES}>
                  <Text variant="text2">{data.id}</Text>
                </td>
                <td align="left" style={TABLE_CELL_STYLES}>
                  {data.name}
                </td>
                <td align="left" style={TABLE_CELL_STYLES}>
                  {new Date(data.deal_created).toLocaleDateString("en-EN", {
                    year: "numeric",
                    month: "short",
                  })}
                </td>
                <td align="left" style={TABLE_CELL_STYLES}>
                  {data.phase_name}
                </td>
                <td align="left" style={TABLE_CELL_STYLES}>
                  {data.company_name}
                </td>
                <td align="right" style={TABLE_CELL_STYLES}>
                  {data.dev_budget &&
                    `$ ${parseInt(data.dev_budget).toLocaleString()}`}
                </td>
                <td align="right" style={TABLE_CELL_STYLES}>
                  {data.unit_count}
                </td>
                <td style={TABLE_CELL_STYLES}>
                  <Stack justifyContent="flex-end" direction="row">
                    <ButtonDropdown
                      icon={<MoreHorizIcon />}
                      label=""
                      variant="text"
                      size="small"
                      width="auto"
                      sx={{
                        "& .MuiButton-endIcon": {
                          margin: 0,
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleIsDealDemo(data)}>
                        {dataIsDemo ? (
                          <Text variant="text2">Mark as real</Text>
                        ) : (
                          <Text variant="text2">Mark as Demo</Text>
                        )}
                      </MenuItem>
                    </ButtonDropdown>
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={pageCount}
        onChange={handlePageChange}
      />
    </Stack>
  );
};

export default KPIDealTable;

import React from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Select from "components/Select";
import Table, { TableBody, TableCell, TableRow } from "components/Table";
import Text from "components/Text";

export interface IDealSidepanelTable {
  data: Array<{
    title: string;
    value: string;
    rowKey?: any;
    options?: { label: string; value: string }[];
    selectFieldPlaceholder?: string;
  }>;
  onChange?: (selectedValue: string, rowKey: any) => void;
}

const DealSidepanelTable: React.FC<IDealSidepanelTable> = ({
  data,
  onChange,
}) => {
  return (
    <Table size="small">
      <TableBody>
        {data.map(
          (
            { title, value, rowKey, options, selectFieldPlaceholder },
            index
          ) => (
            <TableRow
              key={index}
              sx={{
                display: "flex",
                ".MuiTableCell-root": {
                  borderBottom: `1px solid ${colors.blue20}`,
                },
              }}
            >
              <TableCell
                sx={{
                  flexBasis: "50%",
                  paddingLeft: theme.spacing(1),
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  variant="text2"
                  sx={{
                    color: colors.gray100,
                  }}
                  data-testid="field-name"
                >
                  {title}
                </Text>
              </TableCell>
              <TableCell
                align="right"
                sx={{ paddingRight: theme.spacing(1), flexBasis: "50%" }}
              >
                {_.isUndefined(options) && (
                  <Text
                    variant="text2"
                    data-testid={`${title
                      .toLowerCase()
                      .replaceAll(" ", "-")}-value`}
                  >
                    {value}
                  </Text>
                )}
                {options?.length && rowKey && (
                  <Select
                    placeholder={
                      selectFieldPlaceholder || "Assign this role to.."
                    }
                    options={options}
                    defaultValue={value}
                    value={value}
                    onChange={(event: any) =>
                      onChange && onChange(event.target.value, rowKey)
                    }
                    data-testid={`${title
                      .toLowerCase()
                      .replaceAll(" ", "-")}-dropdown`}
                    dropdownOptionsDataTestid={`${title
                      .toLowerCase()
                      .replaceAll(" ", "-")}-dropdown-option`}
                    inputProps={{
                      "data-testid": `${title
                        .toLowerCase()
                        .replaceAll(" ", "-")}-dropdown-input`,
                    }}
                    sx={{ width: "100%", textAlign: "left" }}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
export default DealSidepanelTable;

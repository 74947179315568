import sanitizeHtml, { IOptions } from "sanitize-html";

import "quill/dist/quill.snow.css";
import "./styles.css";

const defaultOptions: IOptions = {
  allowedTags: [
    "b",
    "span",
    "strong",
    "i",
    "em",
    "u",
    "b",
    "s",
    "li",
    "ul",
    "a",
    "ol",
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "br",
    "table",
    "tbody",
    "thead",
    "tr",
    "td",
    "th",
  ],
  allowedAttributes: {
    a: ["href"],
    "*": ["style", "class", "data-*"],
  },
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, defaultOptions),
});

const SanitizeHtml = ({
  html,
  dataTestid,
}: {
  html: string;
  dataTestid?: string;
}) => (
  <div
    dangerouslySetInnerHTML={sanitize(html)}
    data-testid={dataTestid}
    className="sanitize-html-wrapper"
  />
);

export default SanitizeHtml;

import { useMemo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import { LinearProgress } from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IChecklistProgress {
  completedItemsCount: number;
  itemsCount: number;
}

const ChecklistProgress = ({
  completedItemsCount,
  itemsCount,
}: IChecklistProgress) => {
  const calculatedProgress = useMemo(() => {
    if (itemsCount === 0) {
      return 0;
    }

    return Math.round((100 * completedItemsCount) / itemsCount);
  }, [completedItemsCount, itemsCount]);

  return (
    <Box data-testid="checklist-progress">
      <Text
        variant="labelSmall"
        color={colors.gray60}
        fontWeight={500}
        marginBottom={theme.spacing(0.5)}
        data-testid="section-label"
      >
        Progress
      </Text>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={0.5}
        spacing={1}
      >
        <LinearProgress
          variant="determinate"
          value={calculatedProgress}
          color="primary"
          sx={{
            minWidth: "120px",
            height: "15px",
            "&.MuiLinearProgress-root": {
              backgroundColor: colors.gray20,
            },
          }}
          data-testid="progress-bar"
        />
        <Tooltip title={calculatedProgress}>
          <Text
            variant="text4"
            width="36px"
            fontWeight={600}
            data-testid="progress-in-percentage"
          >
            {calculatedProgress} %
          </Text>
        </Tooltip>
      </Stack>
      <Text variant="text4" data-testid="items-done-status">
        <b>{completedItemsCount}</b>/{itemsCount} items done
      </Text>
    </Box>
  );
};

export default ChecklistProgress;

import { CompanyFieldFormatType } from "entities/Field/constants";

export const DataTypesOptions = [
  {
    label: "Integer",
    value: CompanyFieldFormatType.INTEGER,
  },
  {
    label: "String",
    value: CompanyFieldFormatType.STRING,
  },
  {
    label: "Currency USD",
    value: CompanyFieldFormatType.CURRENCY_USD,
  },
  {
    label: "Date",
    value: CompanyFieldFormatType.DATE,
  },
  {
    label: "Float 2",
    value: CompanyFieldFormatType.FLOAT_2,
  },
];

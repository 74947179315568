import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Text from "components/Text";

export interface ISmallAlert {
  title: string;
}

const SmallAlert = ({ title }: ISmallAlert) => (
  <Box
    sx={{
      padding: theme.spacing(0.25, 1),
      borderRadius: "16px",
      backgroundColor: colors.red20,
      border: `1px solid ${colors.red100}`,
    }}
  >
    <Text variant="text4" fontWeight={500}>
      {title}
    </Text>
  </Box>
);

export default SmallAlert;

export enum EFieldFormatType {
  INTEGER = "integer",
  STRING = "string",
  CURRENCY_USD = "currency_usd",
  DATE = "date",
  DATETIME = "datetime",
  FLOAT_2 = "float_2",
}

export const CUSTOM_REPORT_SEARCH_KEYS = ["project name", "location"];
export const DEFAULT_REPORT_SEARCH_KEYS = [
  "project name",
  "location",
  "status",
  "phase",
  "property type",
];

export const TABLE_SETTINGS_FILTER_KEY = "Table Settings";
export const TABLE_SETTINGS_FILTER_LABEL = "Hidden column";
export const SEARCH_STRING_FILTER_LABEL = "Search by";
export const SEARCH_STRING_FILTER_KEY = "searchString";

export const CONSTRUCTION_TYPE_COLUMN_KEY = "construction";
export const PROPERTY_TYPE_COLUMN_KEY = "property type";
export const ASSET_TYPE_COLUMN_KEY = "ASSET_TYPE";
export const OWNERSHIP_TYPE_COLUMN_KEY = "ownership type";

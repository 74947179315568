import React, { useCallback, useContext, useState } from "react";

import { notImplementedError } from "utils/common";

import Loading from "components/Loading";

const LoadingContext = React.createContext({
  show: notImplementedError,
  hide: notImplementedError,
});

export interface ILoadingContextProvider {
  children?: React.ReactNode;
}

export const LoadingContextProvider = ({
  children,
}: ILoadingContextProvider) => {
  const [open, setOpen] = useState(false);

  const show = useCallback(() => {
    setOpen(true);
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ show, hide }}>
      {children}
      <Loading open={open} />
    </LoadingContext.Provider>
  );
};

export const useLoadingBackdrop = () => {
  const { show, hide } = useContext(LoadingContext);

  return [show, hide];
};

import AddIcon from "@mui/icons-material/Add";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BarChartIcon from "@mui/icons-material/BarChart";
import BedIcon from "@mui/icons-material/Bed";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import CalendarToday from "@mui/icons-material/CalendarToday";
import CancelIcon from "@mui/icons-material/Cancel";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadingIcon from "@mui/icons-material/Downloading";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import HistoryIcon from "@mui/icons-material/History";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import NotesIcon from "@mui/icons-material/Notes";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ParkIcon from "@mui/icons-material/Park";
import PersonIcon from "@mui/icons-material/Person";
import PublicIcon from "@mui/icons-material/Public";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShareIcon from "@mui/icons-material/Share";
import StraightenIcon from "@mui/icons-material/Straighten";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SwapHorizontalCircleRoundedIcon from "@mui/icons-material/SwapHorizontalCircleRounded";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import TitleIcon from "@mui/icons-material/Title";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import ViewListIcon from "@mui/icons-material/ViewList";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import editIconsvg from "./edit.svg";
import connectedFieldssvg from "./icon_connected_fields.svg";
import connectedFieldsDisabledsvg from "./icon_connected_fields_disabled.svg";
import instructionsIconsvg from "./icon_instructions.svg";
import integrationsIconsvg from "./icon_integrations.svg";
import updatedIconsvg from "./icon_updated.svg";
import lockIconsvg from "./lock.svg";
import logoIconsvg from "./logo.svg";
import navMessagesIconsvg from "./nav-messages.svg";
import navMyNotesIconsvg from "./nav-my-notes.svg";
import navTasksIconsvg from "./nav-tasks.svg";
import noteIconsvg from "./notes_icon.svg";
import readyToSubmit from "./ready_to_submit.svg";
import uploadIconsvg from "./Upload.svg";
const Template = (iconSrc: string) => (props: any) => (
  <img src={iconSrc} alt="" {...props} />
);

const UploadIcon = Template(uploadIconsvg);
const NavMessagesIcon = Template(navMessagesIconsvg);
const NavMyNotesIcon = Template(navMyNotesIconsvg);
const NavTasksIcon = Template(navTasksIconsvg);
const LogoIcon = Template(logoIconsvg);
const LockIcon = Template(lockIconsvg);
const EditIcon = Template(editIconsvg);
const ReadyToSubmit = Template(readyToSubmit);
const ConnectedFieldsIcon = Template(connectedFieldssvg);
const ConnectedFieldsDisabledIcon = Template(connectedFieldsDisabledsvg);
const InstructionsIcon = Template(instructionsIconsvg);
const IntegrationsIcon = Template(integrationsIconsvg);
const NoteIcon = Template(noteIconsvg);
const UpdatedIcon = Template(updatedIconsvg);

const EditIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      clip-rule="evenodd"
      d="M20.4142 8.00003L16 3.58582L6.09701 13.4888L4.62561 19.3744L10.5112 17.903L20.4142 8.00003ZM7.37435 16.6257L7.90294 14.5113L14 8.41425L15.5858 10L9.48873 16.0971L7.37435 16.6257ZM17 8.58582L17.5858 8.00003L16 6.41424L15.4142 7.00003L17 8.58582Z"
    />
  </svg>
);

const AIIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 5H13" stroke="currentColor" stroke-width="2" />
    <path d="M9 11H13" stroke="currentColor" stroke-width="2" />
    <path d="M11 4V12" stroke="currentColor" stroke-width="2" />
    <path d="M4 9H8" stroke="currentColor" stroke-width="2" />
    <rect
      x="1"
      y="1"
      width="14"
      height="14"
      rx="2"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M4 12V7C4 5.89543 4.89543 5 6 5V5C7.10457 5 8 5.89543 8 7V12"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
);

export {
  AddIcon,
  AIIcon,
  ApartmentOutlinedIcon,
  ArchitectureIcon,
  ArrowBackIcon,
  ArrowBackIosIcon,
  ArrowDownwardIcon,
  ArrowDropDownIcon,
  ArrowForwardIosIcon,
  ArrowRightIcon,
  ArrowUpwardIcon,
  ArticleOutlinedIcon,
  AssessmentIcon,
  AssignmentOutlinedIcon,
  AttachFileIcon,
  AutoAwesomeIcon,
  BarChartIcon,
  BedIcon,
  BookmarkAddOutlinedIcon,
  BookmarkBorderOutlinedIcon,
  CachedIcon,
  CalendarToday,
  CancelIcon,
  ChatBubbleOutlineIcon,
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  CheckIcon,
  ChevronRightIcon,
  ClearIcon,
  CloseIcon,
  ConnectedFieldsDisabledIcon,
  ConnectedFieldsIcon,
  ContentCopyOutlinedIcon,
  DangerousOutlinedIcon,
  DeleteIcon,
  DeleteOutlineOutlinedIcon,
  DescriptionOutlinedIcon,
  DeveloperBoardIcon,
  DisabledByDefaultOutlinedIcon,
  DownloadIcon,
  DownloadingIcon,
  DownloadOutlinedIcon,
  EditIcon,
  EditIconSvg,
  EngineeringIcon,
  EventAvailableOutlinedIcon,
  ExpandLess,
  ExpandMore,
  ExpandMoreIcon,
  FileUploadIcon,
  FilterListIcon,
  FilterNoneIcon,
  FolderOpenIcon,
  ForumOutlinedIcon,
  ForwardToInboxIcon,
  GridViewSharpIcon,
  HistoryEduIcon,
  HistoryIcon,
  ImageIcon,
  InfoIcon,
  InsertDriveFileIcon,
  InsertDriveFileOutlinedIcon,
  InstructionsIcon,
  IntegrationsIcon,
  LaunchIcon,
  LocationOnIcon,
  LockIcon,
  LockOutlinedIcon,
  LogoIcon,
  ManageAccountsIcon,
  MessageOutlinedIcon,
  ModeEditOutlineOutlinedIcon,
  MoreHorizIcon,
  MoreVertIcon,
  MultilineChartIcon,
  NavMessagesIcon,
  NavMyNotesIcon,
  NavTasksIcon,
  NoteIcon,
  NotesIcon,
  NotificationsOutlinedIcon,
  OpenInNewIcon,
  ParkIcon,
  PersonIcon,
  PublicIcon,
  PublishOutlinedIcon,
  QuestionAnswerOutlinedIcon,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
  ReadyToSubmit,
  ReportProblemOutlinedIcon,
  RequestQuoteIcon,
  RoomOutlinedIcon,
  SaveOutlinedIcon,
  SearchIcon,
  SettingsIcon,
  SettingsOutlinedIcon,
  ShareIcon,
  StraightenIcon,
  SubjectOutlinedIcon,
  SummarizeIcon,
  SwapHorizontalCircleRoundedIcon,
  TableViewOutlinedIcon,
  TaskOutlinedIcon,
  TitleIcon,
  TuneTwoToneIcon,
  UpdatedIcon,
  UploadIcon,
  ViewListIcon,
  VisibilityIcon,
  VisibilityOutlinedIcon,
};

import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import FieldDropdownMenu from "entities/Field/components/FieldDropdownMenu";
import EditButton from "entities/Field/components/Richtextfield/EditButton";
import FieldTitle from "entities/Field/components/Title";
import { fieldValueCreate, IField } from "entities/Field/sdk";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

export interface IRichtextfield {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  dealId: number;
  categoryId: number;
  packageId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  isFocused?: boolean;
}

const Richtextfield = ({
  editable,
  dealId,
  taskChannels,
  updatePackage,
  field,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
  isFocused = false,
}: IRichtextfield) => {
  const [value, setValue] = useCaching(
    _.get(field.custom_values, "[0].rich_value")
  );

  const lastSavedAt = useMemo(
    () => _.first(field.custom_values)?.updated_at || null,
    [field]
  );

  const handleSave = useCallback(
    ({ newValue }: { newValue: any }) => {
      setValue(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.field_id,
        value: newValue,
      }).catch(handleInvalidRequest);
      updatePackage();
    },
    [field, dealId, setValue, updatePackage]
  );

  const showMissingTag = useMemo(() => _.isNil(value) || value === "", [value]);

  return (
    <>
      <Stack
        id={field.field_id.toString()}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
          backgroundColor: isFocused ? colors.green10 : "initial",
        }}
        data-testid="dd-field"
      >
        <FieldTitle
          field={field}
          dealId={dealId}
          packageId={packageId}
          categoryId={categoryId}
          tabId={tabId}
          sectionId={sectionId}
          onUpdate={onUpdate}
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ flexGrow: 0 }}
        >
          {taskChannels.length > 0 && (
            <DueDiligenceFieldOpenTaskButton
              taskChannels={taskChannels}
              company_field={field}
              dealId={dealId}
              tabId={tabId}
              packageId={packageId}
              sectionId={sectionId}
              categoryId={categoryId}
              updatePackage={updatePackage}
              taskButtonDataTestid="dd-open-task-button"
              taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
            />
          )}
          {field.n_a_status ? (
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          ) : (
            <>
              {showMissingTag && (
                <Tag
                  label="missing"
                  variant="red"
                  dataTestid="empty-field-value"
                />
              )}
              {editable && (
                <EditButton
                  name={field.display_name}
                  initialValue={value || ""}
                  onSave={handleSave}
                  buttonDataTestid="field-edit-button"
                  lastSavedAt={lastSavedAt}
                  onUpdate={onUpdate}
                />
              )}
            </>
          )}

          <FieldDropdownMenu
            field={field}
            dealId={dealId}
            packageId={packageId}
            categoryId={categoryId}
            tabId={tabId}
            sectionId={sectionId}
            onUpdate={onUpdate}
            menuButtonDataTestid="dd-field-actions-button"
          />
        </Stack>
      </Stack>
      {value && !field.n_a_status && (
        <Box
          sx={{ paddingLeft: theme.spacing(6) }}
          data-testid="dd-rtf-field-value"
        >
          <Box
            sx={{
              flex: 1,
              borderRadius: theme.spacing(0.5),
              border: `1px solid ${colors.blue20}`,
              padding: theme.spacing(1, 1.5, 1),
              marginY: theme.spacing(3),
              backgroundColor: colors.blue5,
            }}
            data-test-id={field.display_name}
          >
            <SanitizeHtml html={value} dataTestid="dd-field-value" />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Richtextfield;

import React, { useCallback } from "react";

import { CloseIcon } from "icons";

import Box from "components/Box";
import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import SidepanelHeader from "components/SidepanelHeader";
import Stack from "components/Stack";
import Text from "components/Text";

import EditForm from "entities/AnalyticsFilterGroup/components/EditForm";
import {
  ANALYTICS_TYPES,
  IFilterGroup,
} from "entities/AnalyticsFilterGroup/sdk";

export interface IEditFilterSidebar {
  isOpen: boolean;
  onSave?: (filterGroup: IFilterGroup) => void;
  onClose: () => void;
  filterGroup: IFilterGroup;
  analyticsType: ANALYTICS_TYPES;
}

const EditFilterSidebar: React.FC<IEditFilterSidebar> = ({
  isOpen,
  onClose,
  onSave = () => {},
  filterGroup,
  analyticsType,
}) => {
  const onUpdate = useCallback(
    (filterGroup: IFilterGroup) => {
      onSave(filterGroup);
      onClose();
    },
    [onSave, onClose]
  );

  return (
    <Drawer open={isOpen} anchor="right">
      <SidepanelHeader>
        <Stack direction="row" alignItems="center">
          <Text variant="h3">Edit filtered group</Text>
        </Stack>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </SidepanelHeader>
      <Box sx={{ position: "relative", flex: 1 }}>
        <EditForm
          onUpdated={onUpdate}
          onCancel={onClose}
          filterGroup={filterGroup}
          analyticsType={analyticsType}
        />
      </Box>
    </Drawer>
  );
};

export default EditFilterSidebar;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Link from "components/Link";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

const EmailVerification: React.FC<{}> = () => {
  usePageTitle("Builders Patch: Email Verification");
  return (
    <>
      <Box sx={{ marginTop: theme.spacing(11), textAlign: "center" }}>
        <Text variant="h1">Your account has been activated!</Text>
        <Text variant="h3" display="inline">
          You can login
        </Text>
        <Text display="inline" variant="h3" sx={{ color: colors.blue100 }}>
          <Link to="/"> here</Link>
        </Text>
      </Box>
    </>
  );
};

export default EmailVerification;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogActions } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { applicationRename } from "entities/Application/sdk";

export interface IRenameDialogButton {
  applicationId: number;
  initialValue: string;
  onUpdate: () => void;
}

const RenameDialogButton = ({
  applicationId,
  initialValue,
  onUpdate,
}: IRenameDialogButton) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [applicationName, setApplicationName] = useState(initialValue);

  const isApplicationNameEmpty = useMemo(
    () => applicationName === "",
    [applicationName]
  );
  const handleApplicationNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setApplicationName(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (applicationName.length > 255) {
      toast.errorMessage("Application name must be less than 255 characters!");
      return;
    }

    return applicationRename({ applicationId, applicationName })
      .then(() => {
        toast.successMessage("Application renamed successfully!");
        setIsDialogOpen(false);
        onUpdate();
      })
      .catch((errors) => {
        const errorMessage = errors?.message || errors?.detail;

        if (!_.isNil(errorMessage)) {
          toast.errorMessage(errorMessage);
        } else {
          errors?.forEach?.((error: unknown) => {
            if (_.isString(error)) {
              toast.errorMessage(error);
            }
          });
        }
      });
  }, [applicationName, applicationId, onUpdate]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        startIcon={<EditIcon />}
        sx={{ color: colors.gray100 }}
        onClick={() => setIsDialogOpen(true)}
      >
        Edit name
      </Button>

      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <Stack padding={theme.spacing(3)}>
          <Text variant="text3" fontWeight="bold" marginBottom={1}>
            Edit the name of your application:
          </Text>

          <TextField
            value={applicationName}
            onChange={handleApplicationNameChange}
            placeholder="e.g. Oaktree Apartments (housing) / Merlin Pet Supplies (small business)"
          />

          <DialogActions sx={{ paddingTop: theme.spacing(4) }}>
            <Button variant="text" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Tooltip
              title={
                isApplicationNameEmpty ? "Application name is required." : ""
              }
              placement="top"
            >
              <span>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isApplicationNameEmpty}
                >
                  Save Changes
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  );
};

export default RenameDialogButton;

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";
import toast from "components/Toast";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  TABS,
  TASKS_TAB_SUBTABS,
} from "entities/Deal/components/Sidepanel/utils";
import { fetchTaskChannels } from "entities/Task/sdk";

interface IAllTasksDetailButton {
  taskChannelId: number;
  dealId: number;
  refreshTasksList: () => void;
}

const AllTasksDetailButton = ({
  taskChannelId,
  dealId,
  refreshTasksList,
}: IAllTasksDetailButton) => {
  const { show } = useDealSidepanel();

  const handleOpenTaskDetail = useCallback(() => {
    fetchTaskChannels({ dealId })
      .then((response) => {
        const taskChannel = response.find(
          (taskChannel) => taskChannel.id === taskChannelId
        );
        show({
          meta: {
            tab: TABS.TASKS,
            subTab: TASKS_TAB_SUBTABS.DETAILS,
            taskChannel: taskChannel,
            dealId,
            updateParent: refreshTasksList,
          },
        });
      })
      .catch(() => {
        toast.errorMessage(
          "There was an error fetching details for this task."
        );
      });
  }, [taskChannelId, dealId, show, refreshTasksList]);

  return (
    <MenuItem onClick={handleOpenTaskDetail}>
      <Text variant="text2">Task details</Text>
    </MenuItem>
  );
};

export default AllTasksDetailButton;

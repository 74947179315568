import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import Stack from "components/Stack";

import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import NoProformaUploaded from "entities/Memo/components/Sections/NoProformaUploaded";
import SectionSummary from "entities/Memo/components/Sections/Summary";
import { IMemoSection } from "entities/Memo/sdk";
import { useProformaForDealManager } from "entities/Memo/utils";
import SourcesAndUsesConstruction from "entities/Proforma/components/ProformaSourcesAndUses/SourcesAndUsesConstruction";
import SourcesAndUsesPermanent from "entities/Proforma/components/ProformaSourcesAndUses/SourcesAndUsesPermanent";

interface ISectionSourcesAndUses {
  dealId: number;
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}

const SectionSourcesAndUses = ({
  dealId,
  externalToken,
  section,
  onUpdate,
}: ISectionSourcesAndUses) => {
  const { data: proforma } = useProformaForDealManager({
    token: externalToken,
    dealId,
  });

  return (
    <>
      {!_.isNil(proforma) &&
      !_.isNil(proforma.core_data) &&
      !_.isNil(proforma.core_data.sources_at_construction_data) &&
      !_.isNil(proforma.core_data.sources_permanent_data) &&
      !_.isNil(proforma.core_data.uses_at_construction_data) &&
      !_.isNil(proforma.core_data.uses_permanent_data) ? (
        <Stack spacing={2}>
          {section && _.isUndefined(externalToken) && (
            <NoPrint
              sx={{
                alignSelf: "flex-end",
              }}
            >
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )}
          {section?.is_na ? (
            <NASection />
          ) : (
            <Box>
              <Box sx={{ marginBottom: 3 }}>
                <SourcesAndUsesConstruction coreData={proforma.core_data} />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <SourcesAndUsesPermanent coreData={proforma.core_data} />
              </Box>
              <Box>
                <SectionSummary
                  title="Sources and uses description"
                  category="sources and uses"
                  dealId={dealId}
                  externalToken={externalToken}
                />
              </Box>
            </Box>
          )}
        </Stack>
      ) : (
        <NoProformaUploaded title="Sources and Uses" />
      )}
    </>
  );
};

export default SectionSourcesAndUses;

import React from "react";

import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import CompanyEditForm from "entities/Company/components/EditForm";
import { ICompany } from "entities/Company/sdk";

export interface IEditCompanyProfileDialog {
  open: boolean;
  handleClose: () => void;
  onUpdated: () => void;
  company: ICompany;
}

const EditCompanyProfileDialog: React.FC<IEditCompanyProfileDialog> = ({
  company,
  open,
  handleClose,
  onUpdated,
}) => (
  <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
    <Box>
      <Stack data-testid="edit-company-profile-dialog">
        <Paper
          sx={{
            paddingX: theme.spacing(15),
            paddingY: theme.spacing(8),
          }}
        >
          <Stack spacing={4}>
            <Stack direction="row" spacing={1}>
              <Text variant="h2">Edit Company Profile</Text>
            </Stack>
            <CompanyEditForm
              company={company}
              handleCancel={handleClose}
              onUpdated={onUpdated}
            />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  </Dialog>
);

export default EditCompanyProfileDialog;

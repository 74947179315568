import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

export interface ICurrentItem {
  packageName: string;
  tabName: string;
  categoryName: string;
  sectionName: string;
  fieldName: string;
}

const CurrentItem = ({
  packageName,
  tabName,
  categoryName,
  sectionName,
  fieldName,
}: ICurrentItem) => {
  return (
    <Stack
      flex={1}
      borderRadius="8px"
      padding={theme.spacing(1.5, 2)}
      sx={{
        backgroundColor: colors.blue20,
      }}
    >
      <Text variant="label" fontWeight={500} color={colors.gray80}>
        Checklists
      </Text>
      <Text variant="text1" fontWeight={700} color={colors.black}>
        {packageName}
      </Text>
      <Stack marginTop={1.2}>
        <Text variant="text3" color={colors.gray80}>
          {tabName} &gt; {categoryName} &gt; {sectionName}
        </Text>
        <Text variant="text2" fontWeight={700}>
          {fieldName}
        </Text>
      </Stack>
    </Stack>
  );
};

export default CurrentItem;

import { ArrowDropDownIcon } from "icons";
import { colors } from "theme/palette";

import Button from "components/Button";
import ButtonDropdown from "components/ButtonDropdown";
import Text from "components/Text";

export interface INavItem {
  isActive: boolean;
  label: string;
  keepMenuMounted?: boolean;
  buttonDataTestid?: string;
  children?: React.ReactNode;
}

const NavItem = ({ isActive, label, buttonDataTestid, children }: INavItem) => {
  if (children) {
    return (
      <ButtonDropdown
        buttonDataTestid={buttonDataTestid}
        variant={isActive ? "contained" : "text"}
        size="small"
        label={
          <Text
            variant="text2"
            sx={{
              color: colors.gray100,
              opacity: isActive ? 1 : 0.6,
              fontWeight: 700,
              transition: "opacity 0.2s ease-in-out",
            }}
          >
            {label}
          </Text>
        }
        icon={<ArrowDropDownIcon />}
        color="secondary"
        width="auto"
        sx={{
          margin: 0,
          padding: "6px 8px",
          height: "32px",
          "&.MuiButton-containedSecondary": {
            border: `1px solid ${colors.blue60}`,
            backgroundColor: colors.blue40,
          },
          "& .MuiMenu-paper": {
            border: `1px solid ${colors.blue20}`,
          },
          "&:hover > * ": { opacity: 1 },
        }}
      >
        {children}
      </ButtonDropdown>
    );
  }

  return (
    <Button
      data-testid={buttonDataTestid}
      variant={isActive ? "contained" : "text"}
      size="small"
      color="secondary"
      sx={{
        margin: 0,
        padding: "6px 8px",
        height: "32px",
        "&.MuiButton-containedSecondary": {
          border: `1px solid ${colors.blue60}`,
          backgroundColor: colors.blue40,
        },
      }}
    >
      <Text
        variant="text2"
        sx={{
          color: colors.gray100,
          opacity: isActive ? 1 : 0.6,
          fontWeight: 700,
          transition: "opacity 0.2s ease-in-out",
          "&:hover": {
            opacity: 1,
          },
        }}
        data-testid="button-label"
      >
        {label}
      </Text>
    </Button>
  );
};

export default NavItem;

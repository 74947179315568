export const BusinessLegalFormOptions = [
  { label: "Corporation - C", value: "corporation_c" },
  { label: "Limited Liability Corp", value: "limited_liability_corp" },
  { label: "Non-Profit Organization", value: "non_profit" },
  { label: "Other", value: "other" },
  { label: "Partnership", value: "partnership" },
  { label: "S-Corp", value: "s_corp" },
  { label: "Sole Proprietor", value: "sole_proprietor" },
  { label: "Unknown", value: "unknown" },
];

export const EthnicityOptions = [
  { label: "-", value: "" },
  { label: "Hispanic", value: "hispanic" },
  { label: "Non-Hispanic", value: "non_hispanic" },
  { label: "Not Applicable", value: "not_applicable" },
  { label: "Other", value: "other" },
  { label: "Unknown", value: "unknown" },
];

export const GenderOptions = [
  { label: "-", value: "" },
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
  { label: "Other", value: "other" },
  { label: "Unknown", value: "unknown" },
];

export const VeteranStatusOptions = [
  { label: "-", value: "" },
  { label: "Non-Veteran", value: "non_vet" },
  { label: "Veteran", value: "veteran" },
  { label: "Vietnam Veteran", value: "vietnam_vet" },
  { label: "Other Veteran", value: "other_vet" },
  { label: "Unknown", value: "unknown" },
];

export enum EBusinessOwnerSelectOptionTypes {
  GENDER = "gender",
  ETHNIC_GROUP = "ethnic_group",
  VETERAN_STATUS = "veteran_status",
}

export enum EStateAbbreviations {
  ALABAMA = "AL",
  ALASKA = "AK",
  ARIZONA = "AZ",
  ARKANSAS = "AR",
  CALIFORNIA = "CA",
  COLORADO = "CO",
  CONNECTICUT = "CT",
  DELAWARE = "DE",
  FLORIDA = "FL",
  GEORGIA = "GA",
  HAWAII = "HI",
  IDAHO = "ID",
  ILLINOIS = "IL",
  INDIANA = "IN",
  IOWA = "IA",
  KANSAS = "KS",
  KENTUCKY = "KY",
  LOUISIANA = "LA",
  MAINE = "ME",
  MARYLAND = "MD",
  MASSACHUSETTS = "MA",
  MICHIGAN = "MI",
  MINNESOTA = "MN",
  MISSISSIPPI = "MS",
  MISSOURI = "MO",
  MONTANA = "MT",
  NEBRASKA = "NE",
  NEVADA = "NV",
  NEW_HAMPSHIRE = "NH",
  NEW_JERSEY = "NJ",
  NEW_MEXICO = "NM",
  NEW_YORK = "NY",
  NORTH_CAROLINA = "NC",
  NORTH_DAKOTA = "ND",
  OHIO = "OH",
  OKLAHOMA = "OK",
  OREGON = "OR",
  PENNSYLVANIA = "PA",
  RHODE_ISLAND = "RI",
  SOUTH_CAROLINA = "SC",
  SOUTH_DAKOTA = "SD",
  TENNESSE = "TN",
  TEXAS = "TX",
  UTAH = "UT",
  VERMONT = "VT",
  VIRGINIA = "VA",
  WASHINGTON = "WA",
  WEST_VIRGINIA = "WV",
  WISCONSIN = "WI",
  WYOMING = "WY",
  AMERICAN_SAMOA = "AS",
  DISTRICT_OF_COLUMBIA = "DC",
  FEDERATED_STATES_OF_MICRONESIA = "FM",
  GUAM = "GU",
  MARSHALL_ISLANDS = "MH",
  NORTHERN_MARIANA_ISLANDS = "MP",
  PALAU = "PW",
  PUERTO_RICO = "PR",
  VIRGIN_ISLANDS = "VI",
}

export const AbbreviatedStateOptions = [
  { label: "Alabama", value: EStateAbbreviations.ALABAMA },
  { label: "Alaska", value: EStateAbbreviations.ALASKA },
  { label: "Arizona", value: EStateAbbreviations.ARIZONA },
  { label: "Arkansas", value: EStateAbbreviations.ARKANSAS },
  { label: "California", value: EStateAbbreviations.CALIFORNIA },
  { label: "Colorado", value: EStateAbbreviations.COLORADO },
  { label: "Connecticut", value: EStateAbbreviations.CONNECTICUT },
  { label: "Delaware", value: EStateAbbreviations.DELAWARE },
  { label: "Florida", value: EStateAbbreviations.FLORIDA },
  { label: "Georgia", value: EStateAbbreviations.GEORGIA },
  { label: "Hawaii", value: EStateAbbreviations.HAWAII },
  { label: "Idaho", value: EStateAbbreviations.IDAHO },
  { label: "Illinois", value: EStateAbbreviations.ILLINOIS },
  { label: "Indiana", value: EStateAbbreviations.INDIANA },
  { label: "Iowa", value: EStateAbbreviations.IOWA },
  { label: "Kansas", value: EStateAbbreviations.KANSAS },
  { label: "Kentucky", value: EStateAbbreviations.KENTUCKY },
  { label: "Louisiana", value: EStateAbbreviations.LOUISIANA },
  { label: "Maine", value: EStateAbbreviations.MAINE },
  { label: "Maryland", value: EStateAbbreviations.MARYLAND },
  { label: "Massachusetts", value: EStateAbbreviations.MASSACHUSETTS },
  { label: "Michigan", value: EStateAbbreviations.MICHIGAN },
  { label: "Minnesota", value: EStateAbbreviations.MINNESOTA },
  { label: "Mississippi", value: EStateAbbreviations.MISSISSIPPI },
  { label: "Missouri", value: EStateAbbreviations.MISSOURI },
  { label: "Montana", value: EStateAbbreviations.MONTANA },
  { label: "Nebraska", value: EStateAbbreviations.NEBRASKA },
  { label: "Nevada", value: EStateAbbreviations.NEVADA },
  { label: "New Hampshire", value: EStateAbbreviations.NEW_HAMPSHIRE },
  { label: "New Jersey", value: EStateAbbreviations.NEW_JERSEY },
  { label: "New Mexico", value: EStateAbbreviations.NEW_MEXICO },
  { label: "New York", value: EStateAbbreviations.NEW_YORK },
  { label: "North Carolina", value: EStateAbbreviations.NORTH_CAROLINA },
  { label: "North Dakota", value: EStateAbbreviations.NORTH_DAKOTA },
  { label: "Ohio", value: EStateAbbreviations.OHIO },
  { label: "Oklahoma", value: EStateAbbreviations.OKLAHOMA },
  { label: "Oregon", value: EStateAbbreviations.OREGON },
  { label: "Pennsylvania", value: EStateAbbreviations.PENNSYLVANIA },
  { label: "Rhode Island", value: EStateAbbreviations.RHODE_ISLAND },
  { label: "South Carolina", value: EStateAbbreviations.SOUTH_CAROLINA },
  { label: "South Dakota", value: EStateAbbreviations.SOUTH_DAKOTA },
  { label: "Tennessee", value: EStateAbbreviations.TENNESSE },
  { label: "Texas", value: EStateAbbreviations.TEXAS },
  { label: "Utah", value: EStateAbbreviations.UTAH },
  { label: "Vermont", value: EStateAbbreviations.VERMONT },
  { label: "Virginia", value: EStateAbbreviations.VIRGINIA },
  { label: "Washington", value: EStateAbbreviations.WASHINGTON },
  { label: "West Virginia", value: EStateAbbreviations.WEST_VIRGINIA },
  { label: "Wisconsin", value: EStateAbbreviations.WISCONSIN },
  { label: "Wyoming", value: EStateAbbreviations.WYOMING },
  { label: "American Samoa", value: EStateAbbreviations.AMERICAN_SAMOA },
  {
    label: "District of Columbia",
    value: EStateAbbreviations.DISTRICT_OF_COLUMBIA,
  },
  {
    label: "Federated States of Micronesia",
    value: EStateAbbreviations.FEDERATED_STATES_OF_MICRONESIA,
  },
  { label: "Guam", value: EStateAbbreviations.GUAM },
  { label: "Marshall Islands", value: EStateAbbreviations.MARSHALL_ISLANDS },
  {
    label: "Northern Mariana Islands",
    value: EStateAbbreviations.NORTHERN_MARIANA_ISLANDS,
  },
  { label: "Palau", value: EStateAbbreviations.PALAU },
  { label: "Puerto Rico", value: EStateAbbreviations.PUERTO_RICO },
  { label: "Virgin Islands", value: EStateAbbreviations.VIRGIN_ISLANDS },
];

export enum EDownhomeBusinessParticipantOptionGroup {
  EXISTING = "DownHome data",
  IMPORT_FROM_CHECKLIST = "Not in Downhome? Get applicant data from Checklist.",
}

import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { EditIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import UnitAmenitiesEditForm from "entities/Deal/components/OverviewUnitAmenities/UnitAmenitiesEditForm";
import {
  IDealUnitAmenities,
  updateUnitAmenities,
  useDealUnitAmenities,
} from "entities/Deal/sdk";

export interface IUnitAmenitiesEditDialog {
  open: boolean;
  handleClose: () => void;
}

const UnitAmenitiesEditDialog: React.FC<IUnitAmenitiesEditDialog> = ({
  open,
  handleClose,
}) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: unit_amenities_data, mutate: mutateUnitAmenities } =
    useDealUnitAmenities({ dealId });

  const handleSubmit = useCallback(
    (data: Partial<IDealUnitAmenities>) =>
      updateUnitAmenities({ dealId, data }).then(() => {
        mutateUnitAmenities();
        toast.successMessage("Unit amenities successfully updated!");
        handleClose();
      }),
    [dealId, mutateUnitAmenities, handleClose]
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <Box>
        <Stack alignItems="center" data-testid="edit-unit-amenities-dialog">
          <Paper
            sx={{
              padding: theme.spacing(8),
              width: "100%",
            }}
          >
            <Stack spacing={4}>
              <Stack direction="row" spacing={1}>
                <EditIcon
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
                <Text variant="h1" style={{ display: "flex" }}>
                  Unit amenities
                </Text>
              </Stack>
              {unit_amenities_data && (
                <UnitAmenitiesEditForm
                  onSubmit={handleSubmit}
                  handleCancel={handleClose}
                  initialValues={{
                    description: unit_amenities_data.description,
                    kitchen: unit_amenities_data.kitchen,
                    storage: unit_amenities_data.storage,
                    fittings: unit_amenities_data.fittings,
                    laundry: unit_amenities_data.laundry,
                  }}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default UnitAmenitiesEditDialog;

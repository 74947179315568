import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { CheckCircleOutlineIcon } from "icons";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogActions, DialogContent } from "components/Dialog";
import Text from "components/Text";

import { APPLICATION_STATUS } from "entities/Application/constants";

export interface IDealIsInUnderwritingDialog {
  dealId: number;
  isOpen: boolean;
  onBackToIntake: () => void;
}

const DealIsInUnderwritingDialog = ({
  dealId,
  isOpen,
  onBackToIntake,
}: IDealIsInUnderwritingDialog) => {
  const navigate = useNavigate();

  const handleBackToIntake = useCallback(() => {
    navigate(
      reverse(URLS.APPLICATIONS_LENDER_LIST, {
        status: APPLICATION_STATUS.SUBMITTED,
      })
    );
    onBackToIntake();
  }, [navigate, onBackToIntake]);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleBackToIntake}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 4,
          paddingLeft: 14,
          paddingRight: 14,
          paddingBottom: 5,
        }}
      >
        <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 68 }} />

        <Text variant="h1" marginTop={3} marginBottom={1}>
          Moved to deal pipeline!
        </Text>

        <Text variant="text1" color={colors.gray80}>
          Deal ID: <b>{dealId}</b>
        </Text>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", marginBottom: 4 }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleBackToIntake}
        >
          Back to intake
        </Button>
        <Button
          onClick={() => navigate(reverse(URLS.DEAL_OVERVIEW, { dealId }))}
        >
          View deal page
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealIsInUnderwritingDialog;

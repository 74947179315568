import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import theme from "theme";
import { useCaching } from "utils/common";

import Paper from "components/Paper";

import SelectedFilters from "entities/Task/components/SelectedFilters";
import TasksTables from "entities/Task/components/TasksTables";
import TasksTableSkeleton from "entities/Task/components/TasksTableSkeleton";
import PageTitle from "entities/Task/components/WorkspacePageTitle";
import PageTitleSkeleton from "entities/Task/components/WorkspacePageTitleSkeleton";
import { TaskTypes } from "entities/Task/constants";
import { ITypeOfFilter, useTasksData } from "entities/Task/sdk";

interface IWorkspaceTasks {
  dealId: number;
  dealPartiallyAccessible?: boolean;
}

const WorkspaceTasks: React.FC<IWorkspaceTasks> = ({
  dealId,
  dealPartiallyAccessible,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTaskType: TaskTypes = searchParams.get("taskType") as TaskTypes;

  const [taskType, setTaskType] = useCaching<TaskTypes>(searchTaskType);
  const [selectedFilters, setSelectedFilters] = useState<ITypeOfFilter>({});

  const {
    data: tasksData,
    loading,
    mutate: mutateTasksData,
  } = useTasksData({
    filters: { ...selectedFilters, task_type: taskType, deals: [dealId] },
  });

  const handleChangeSelector = useCallback(
    (selectedTaskType: TaskTypes) => {
      setTaskType(selectedTaskType);

      const urlParameters: URLSearchParams = new URLSearchParams();
      urlParameters.set("taskType", selectedTaskType);
      setSearchParams(urlParameters);
    },
    [setTaskType, setSearchParams]
  );

  const handleChangeFilters = useCallback((selectedFilters: ITypeOfFilter) => {
    setSelectedFilters(selectedFilters);
  }, []);

  const refreshTasksList = useCallback(() => {
    mutateTasksData();
  }, [mutateTasksData]);

  return (
    <Paper
      sx={{
        padding: theme.spacing(2, 4, 4),
      }}
    >
      {loading && (
        <>
          <PageTitleSkeleton />
          <TasksTableSkeleton />
        </>
      )}
      {!loading && (
        <>
          <PageTitle
            taskType={taskType}
            initialFilters={selectedFilters}
            isPartiallyAccessible={dealPartiallyAccessible}
            onChangeTaskType={handleChangeSelector}
            onChangeFilters={handleChangeFilters}
            onCreateNewTask={refreshTasksList}
          />
          <SelectedFilters
            selectedFilters={selectedFilters}
            onUpdateFilters={setSelectedFilters}
          />
          {tasksData && (
            <TasksTables
              refreshTasksList={refreshTasksList}
              tasksData={tasksData}
            ></TasksTables>
          )}
        </>
      )}
    </Paper>
  );
};

export default WorkspaceTasks;

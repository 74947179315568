import { memo } from "react";
import { DialogTitle } from "@mui/material";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogActions } from "components/Dialog";
import Paper from "components/Paper";
import Text from "components/Text";

export interface IFeatureDisabledDialog {
  isOpen: boolean;
  onClose: () => void;
}

const FeatureDisabledDialog = ({ isOpen, onClose }: IFeatureDisabledDialog) => (
  <Dialog
    open={isOpen}
    maxWidth="sm"
    onClose={onClose}
    data-testid="feature-disabled-dialog"
  >
    <DialogTitle sx={{ padding: theme.spacing(2) }}>
      <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
        <Text variant="h2" data-testid="feature-disabled-dialog-header">
          Product feature disabled
        </Text>
        <Text
          variant="text2"
          color={colors.gray80}
          marginTop={1}
          data-testid="feature-disabled-dialog-message"
        >
          This feature is disabled in your current subscription plan. If you
          want to find out more about it, test it, or start using it, please
          contact our Customer Success team.
        </Text>
      </Paper>
    </DialogTitle>
    <DialogActions sx={{ padding: theme.spacing(2) }}>
      <Button onClick={onClose} data-testid="confirm-action-button">
        OK, got it
      </Button>
    </DialogActions>
  </Dialog>
);

export default memo(FeatureDisabledDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

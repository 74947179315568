import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DialogTitle } from "@mui/material";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { addQueryParams } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogActions } from "components/Dialog";
import Paper from "components/Paper";
import Text from "components/Text";

import { UserProfileMenuChoices } from "entities/User/components/StickySidebar/constants";

import { EFeatureLimitItemsType } from "./constants";

export interface IFeatureLimitDialog {
  isOpen: boolean;
  isDealOwner: boolean;
  items: EFeatureLimitItemsType;
  onClose: () => void;
  dealCompanyName?: string;
}

const FeatureLimitDialog = ({
  isOpen,
  isDealOwner,
  items,
  onClose,
  dealCompanyName,
}: IFeatureLimitDialog) => {
  const navigate = useNavigate();

  const onSubscribe = useCallback(() => {
    onClose();
    navigate(
      addQueryParams(URLS.PROFILE, {
        tab: UserProfileMenuChoices.COMPANY_SETTINGS,
      })
    );
  }, [navigate, onClose]);

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ padding: theme.spacing(2) }}>
        <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
          <Text variant="h2">Maximum limit reached</Text>
          {isDealOwner ? (
            <Text variant="text2" color={colors.gray80} marginTop={1}>
              You've reached the maximum number of {items} allowed in your
              subscription plan. Please contact our Customer Success team to
              increase your limit. To check current limits and the product
              features enabled, go to the{" "}
              <span
                onClick={onSubscribe}
                style={{
                  textDecoration: "underline",
                  color: colors.blue100,
                  fontWeight: 500,
                  wordBreak: "break-all",
                  cursor: "pointer",
                }}
              >
                Subscription Plan
              </span>{" "}
              section on your account page.
            </Text>
          ) : (
            <Text variant="text2" color={colors.gray80} marginTop={1}>
              You have been invited to collaborate on this deal by its owner{" "}
              <span style={{ fontWeight: 700 }}>{dealCompanyName}</span>. It
              seems like they have reached the maximum allowed limit for this
              item within their documents. Please get into contact with this
              deal’s owner for more info.
            </Text>
          )}
        </Paper>
      </DialogTitle>
      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Button onClick={onClose}>OK, got it</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(FeatureLimitDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Tabs, { Tab } from "components/Tabs";

import { ProformaSubNavItems } from "entities/Proforma/constants";

const tabs = [
  { label: "Sources & Uses", key: ProformaSubNavItems.SOURCE_AND_USES },
  {
    label: "Development Budget",
    key: ProformaSubNavItems.DEVELOPMENT_BUDGET,
  },
  { label: "Rental Income", key: ProformaSubNavItems.RENTAL_INCOME },
  { label: "Income & OPEX", key: ProformaSubNavItems.INCOME_AND_OPEX },
  { label: "P&L Projections", key: ProformaSubNavItems.PL_PROJECTIONS },
];

const ProformaTabs = () => {
  const navigate = useNavigate();

  const params = useParams<{
    dealId: string;
    tab: string;
  }>();
  const dealId = Number(params.dealId);
  const currentTab = params.tab;

  const handleChange = useCallback(
    (_: any, tabKey: ProformaSubNavItems) => {
      navigate(
        reverse(URLS.DEAL_PROFORMA_TAB, {
          dealId,
          tab: tabKey,
        })
      );
    },
    [dealId, navigate]
  );

  if (!currentTab) {
    return null;
  }

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      sx={{
        borderBottom: `1px solid ${colors.blue100}`,
        minHeight: "40px",
      }}
      data-testid="proforma-tabs"
    >
      {tabs.map(({ label, key }) => (
        <Tab
          key={key}
          label={label}
          value={key}
          sx={{
            padding: theme.spacing(1, 2),
            color: colors.black,
            minHeight: "40px",
            "&.Mui-selected": {
              color: colors.black,
              backgroundColor: "#EEEEFF",
            },
          }}
          data-testid="proforma-tab"
        />
      ))}
    </Tabs>
  );
};

export default ProformaTabs;

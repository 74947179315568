import { useEffect } from "react";

import PusherManager from "utils/pusher-manager";

import { useSignedUser } from "entities/Auth/sdk";

export const useOpenAIPromptResponseReady = ({
  eventHandler,
}: {
  eventHandler: ({
    dealId,
    openAIDocumentAssistantId,
    promptResponseId,
  }: {
    dealId: number;
    openAIDocumentAssistantId: number;
    promptResponseId: number;
  }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (user) {
      return PusherManager.bindToOpenAIPromptResponseReadyEvent({
        userId: user.id,
        eventHandler: ({
          deal_id,
          openai_document_assistant_id,
          prompt_response_id,
        }: {
          deal_id: number;
          openai_document_assistant_id: number;
          prompt_response_id: number;
        }) =>
          eventHandler({
            dealId: deal_id,
            openAIDocumentAssistantId: openai_document_assistant_id,
            promptResponseId: prompt_response_id,
          }),
      });
    }
  }, [user, eventHandler]);
};

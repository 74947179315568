import React, { useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Tabs, { Tab } from "components/Tabs";

import { AssetManagementTabItems } from "entities/AssetManagement/constants";

interface IAssetManagementTabs {
  selectedTab: AssetManagementTabItems;
  onChange: (tab: AssetManagementTabItems) => void;
}

const tabs = [
  { label: "Income", key: AssetManagementTabItems.INCOME },
  { label: "Expenses", key: AssetManagementTabItems.EXPENSES },
  { label: "Debt Service", key: AssetManagementTabItems.DEBT_SERVICE },
  { label: "Other Data", key: AssetManagementTabItems.OTHER_DATA },
];

const AssetManagementTabs: React.FC<IAssetManagementTabs> = ({
  onChange,
  selectedTab,
}) => {
  const handleChange = useCallback(
    (_: any, tabKey: AssetManagementTabItems) => {
      onChange(tabKey);
    },
    [onChange]
  );

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      sx={{
        borderBottom: `1px solid ${colors.blue100}`,
        minHeight: "40px",
      }}
      data-testid="asset-mgmt-tabs"
    >
      {tabs.map(({ label, key }) => (
        <Tab
          key={key}
          label={label}
          value={key}
          sx={{
            padding: theme.spacing(1, 2),
            color: colors.black,
            minHeight: "40px",
            "&.Mui-selected": {
              color: colors.black,
              backgroundColor: "#EEEEFF",
            },
          }}
          data-testid="asset-mgmt-tab"
        />
      ))}
    </Tabs>
  );
};

export default AssetManagementTabs;

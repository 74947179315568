import React, { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined, useUuid } from "utils/common";

import Box from "components/Box";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IProforma } from "entities/Proforma/sdk";

export interface IProformaSoftCosts {
  proforma: IProforma;
}

const headerSoftCosts: Array<{ key: string; name: string }> = [
  { key: "costs", name: "costs" },
  { key: "total", name: "total" },
  { key: "perunit", name: "per unit" },
  { key: "persqf", name: "per sqf" },
  { key: "percentgroup", name: "% group" },
  { key: "percenttotal", name: "% total" },
];

const ProformaSoftCosts: React.FC<IProformaSoftCosts> = ({ proforma }) => {
  const uuid = useUuid();

  const tradeCostsDetails = useCallback(
    (detailData: any) =>
      _.orderBy(
        _.map(detailData, (value: any, key) => {
          if (value.value !== 0)
            return {
              costs: key,
              total: formatNumberToString({ number: value.value }),
              perunit: formatNumberToString({
                number: value.value / proforma.core_data.total_units,
              }),
              persqf: formatNumberToString({
                number: value.value / proforma.core_data.total_s_f,
              }),
              percentgroup: `${formatNumberToString({
                number: value.percentage_of_group,
              })}%`,
              percenttotal: `${formatNumberToString({
                number: value.percentage_of_total,
              })}%`,
              position: value.position,
            };
        }).filter(isNotUndefined),
        "position"
      ),
    [proforma.core_data]
  );

  const softCostsRows = useMemo(
    () => [
      {
        costs: "Professional Fees",
        total: "",
        perunit: "",
        persqf: "",
        percentgroup: "",
        percenttotal: "",
        detail: tradeCostsDetails(
          proforma.budget_data.soft_cost.soft_cost_professional_data
        ),
      },
      {
        costs: "Third Party Reports",
        total: "",
        perunit: "",
        persqf: "",
        percentgroup: "",
        percenttotal: "",
        detail: tradeCostsDetails(
          proforma.budget_data.soft_cost.soft_cost_3rd_party_data
        ),
      },
      {
        costs: "Fees & Interests",
        total: "",
        perunit: "",
        persqf: "",
        percentgroup: "",
        percenttotal: "",
        detail: tradeCostsDetails(
          proforma.budget_data.soft_cost.soft_cost_fee_and_interest_data
        ),
      },
      {
        costs: "Conversion & Lease",
        total: "",
        perunit: "",
        persqf: "",
        percentgroup: "",
        percenttotal: "",
        detail: tradeCostsDetails(
          proforma.budget_data.soft_cost.soft_cost_conversion_and_lease_data
        ),
      },
      {
        costs: "Operating Reverse",
        total: "",
        perunit: "",
        persqf: "",
        percentgroup: "",
        percenttotal: "",
        detail: tradeCostsDetails(
          proforma.budget_data.soft_cost.soft_cost_operating_reserve_data
        ),
      },
      {
        costs: "Total Soft Costs",
        total: formatNumberToString({
          number: proforma.budget_data.total_soft_cost.value,
        }),
        perunit: formatNumberToString({
          number:
            proforma.budget_data.total_soft_cost.value /
            proforma.core_data.total_units,
        }),
        persqf: formatNumberToString({
          number:
            proforma.budget_data.total_soft_cost.value /
            proforma.core_data.total_s_f,
        }),
        percentgroup: `${formatNumberToString({
          number: proforma.budget_data.total_soft_cost.percentage_of_group,
        })}%`,
        percenttotal: `${formatNumberToString({
          number: proforma.budget_data.total_soft_cost.percentage_of_total,
        })}%`,
      },
      {
        costs: "Soft Cost Contingency",
        total: formatNumberToString({
          number: proforma.budget_data.soft_cost_contingency.value,
        }),
        perunit: formatNumberToString({
          number:
            proforma.budget_data.soft_cost_contingency.value /
            proforma.core_data.total_units,
        }),
        persqf: formatNumberToString({
          number:
            proforma.budget_data.soft_cost_contingency.value /
            proforma.core_data.total_s_f,
        }),
        percentgroup: `${formatNumberToString({
          number:
            proforma.budget_data.soft_cost_contingency.percentage_of_group,
        })}%`,
        percenttotal: `${formatNumberToString({
          number:
            proforma.budget_data.soft_cost_contingency.percentage_of_total,
        })}%`,
      },
    ],
    [proforma.budget_data, proforma.core_data, tradeCostsDetails]
  );

  const tableTotals = useMemo(
    () => ({
      costs: "Soft Costs w/ Contingency",
      total: formatNumberToString({
        number:
          proforma.budget_data.soft_cost_contingency.value +
          proforma.budget_data.total_soft_cost.value,
      }),
      perunit: formatNumberToString({
        number:
          (proforma.budget_data.soft_cost_contingency.value +
            proforma.budget_data.total_soft_cost.value) /
          proforma.core_data.total_units,
      }),
      persqf: formatNumberToString({
        number:
          (proforma.budget_data.soft_cost_contingency.value +
            proforma.budget_data.total_soft_cost.value) /
          proforma.core_data.total_s_f,
      }),
      percentgroup: `${formatNumberToString({
        number:
          proforma.budget_data.soft_cost_contingency.percentage_of_group +
          proforma.budget_data.total_soft_cost.percentage_of_group,
      })}%`,
      percenttotal: `${formatNumberToString({
        number:
          proforma.budget_data.soft_cost_contingency.percentage_of_total +
          proforma.budget_data.total_soft_cost.percentage_of_total,
      })}%`,
    }),
    [proforma.budget_data, proforma.core_data]
  );

  const subTitle =
    formatNumberToString({
      number:
        proforma.budget_data.soft_cost_contingency.percentage_of_total +
        proforma.budget_data.total_soft_cost.percentage_of_total,
    }) + "% of TDC";

  const totals = useMemo(() => {
    const totalHeader =
      proforma.budget_data.soft_cost_contingency.value +
      proforma.budget_data.total_soft_cost.value;

    return [
      {
        label: "Total",
        value: totalHeader,
        type: ProformaHeaderTypeTotal.PRICE,
      },
      {
        label: "Per Unit",
        value: totalHeader / proforma.core_data.total_units,
        type: ProformaHeaderTypeTotal.PRICE,
      },
      {
        label: "Per Sqf",
        value: totalHeader / proforma.core_data.total_s_f,
        type: ProformaHeaderTypeTotal.PRICE,
      },
    ];
  }, [proforma.budget_data, proforma.core_data]);

  return (
    <ProformaPaper
      title="Soft Costs"
      subTitle={subTitle}
      totals={totals}
      key={uuid}
    >
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          key={uuid}
          rows={softCostsRows}
          headers={headerSoftCosts}
          totals={tableTotals}
        />
      </Box>
    </ProformaPaper>
  );
};

export default ProformaSoftCosts;

import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Dialog, { DialogContent, DialogTitle } from "components/Dialog";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import PdfImportFile from "entities/Package/components/PdfImport/PdfImportHistoryDialog/PdfImportFile";
import { useDueDiligenceFileProcessingStatusUpdates } from "entities/Package/hooks";
import { useDueDiligencePdfImportList } from "entities/Package/sdk";

export interface IPdfImportHistoryDialog {
  isOpen: boolean;
  onClose: () => void;
}

const PdfImportHistoryDialog = ({
  isOpen,
  onClose,
}: IPdfImportHistoryDialog) => {
  const params = useParams<{
    dealId: string;
    packageId: string;
  }>();
  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);

  const {
    data: pdfImport,
    loading,
    mutate,
  } = useDueDiligencePdfImportList({
    dealId,
    packageId,
    enabled: isOpen,
  });

  const statusUpdateHandler = useCallback(() => {
    mutate();
  }, [mutate]);

  useDueDiligenceFileProcessingStatusUpdates({
    successEventHandler: statusUpdateHandler,
    failureEventHandler: statusUpdateHandler,
  });

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      data-testid="dd-pdf-upload-dialog"
    >
      <DialogTitle
        sx={{
          margin: theme.spacing(2),
          borderRadius: "8px",
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Text
          variant="h2"
          data-testid="dialog-header"
          marginBottom={theme.spacing(0.5)}
          color={colors.gray100}
        >
          Upload history
        </Text>
        <Text
          variant="text2"
          color={colors.gray80}
          data-testid="dialog-subheader"
        >
          Here you can see all the PDF file uploads for this checklist.
        </Text>
      </DialogTitle>
      <DialogContent
        sx={{ padding: theme.spacing(2) }}
        data-testid="pdf-upload-entities-list"
      >
        {pdfImport?.map((pdfImportFile) => (
          <PdfImportFile
            key={pdfImportFile.id.toString()}
            pdfImport={pdfImportFile}
            dealId={dealId}
            packageId={packageId}
          />
        ))}

        {loading && (
          <Stack spacing={2}>
            {_.range(3).map((index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                justifyContent="space-between"
              >
                <Skeleton variant="rectangular" width={20} height={20} />
                <Skeleton variant="rectangular" width={450} height={20} />
                <Skeleton variant="rectangular" width={100} height={20} />
                <Stack direction="row" spacing={2}>
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton variant="rectangular" width={20} height={20} />
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}

        {!loading && pdfImport?.length === 0 && (
          <Text
            variant="h6"
            sx={{ marginBottom: theme.spacing(4) }}
            data-testid="empty-pdf-entities-list-state"
          >
            No uploaded files yet.
          </Text>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(PdfImportHistoryDialog);

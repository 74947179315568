import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined, useUuid } from "utils/common";

import Box from "components/Box";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IProforma } from "entities/Proforma/sdk";

export interface IProformaDeveloperFees {
  proforma: IProforma;
}

const headerDeveloperFees: Array<{ key: string; name: string }> = [
  { key: "costs", name: "costs" },
  { key: "total", name: "total" },
  { key: "perunit", name: "per unit" },
  { key: "persqf", name: "per sqf" },
  { key: "percentgroup", name: "% group" },
  { key: "percenttotal", name: "% total" },
];

const ProformaDeveloperFees: React.FC<IProformaDeveloperFees> = ({
  proforma,
}) => {
  const uuid = useUuid();
  const developerFeesRows = useMemo(
    () =>
      _.orderBy(
        _.map(proforma.budget_data.developer_fees, (value, key) => {
          if (value.value !== 0)
            return {
              costs: key,
              total: formatNumberToString({ number: value.value }),
              perunit: formatNumberToString({
                number: value.value / proforma.core_data.total_units,
              }),
              persqf: formatNumberToString({
                number: value.value / proforma.core_data.total_s_f,
              }),
              percentgroup: `${formatNumberToString({
                number: value.percentage_of_group,
              })}%`,
              percenttotal: `${formatNumberToString({
                number: value.percentage_of_total,
              })}%`,
              position: value.position,
            };
        }).filter(isNotUndefined),
        "position"
      ),
    [proforma]
  );

  const tableTotals = useMemo(
    () => ({
      costs: "Total Developer Fees",
      total: formatNumberToString({
        number: proforma.budget_data.total_developer_fees.value,
      }),
      perunit: formatNumberToString({
        number:
          proforma.budget_data.total_developer_fees.value /
          proforma.core_data.total_units,
      }),
      persqf: formatNumberToString({
        number:
          proforma.budget_data.total_developer_fees.value /
          proforma.core_data.total_s_f,
      }),
      percentgroup: `${formatNumberToString({
        number: proforma.budget_data.total_developer_fees.percentage_of_group,
      })}%`,
      percenttotal: `${formatNumberToString({
        number: proforma.budget_data.total_developer_fees.percentage_of_total,
      })}%`,
    }),
    [proforma.budget_data, proforma.core_data]
  );

  const subTitle = `${formatNumberToString({
    number: proforma.budget_data.total_developer_fees.percentage_of_total,
  })}% of TDC`;
  const totalHeader = proforma.budget_data.total_developer_fees.value;
  const perUnitHeader =
    proforma.budget_data.total_developer_fees.value /
    proforma.core_data.total_units;

  const perSqfHeader =
    proforma.budget_data.total_developer_fees.value /
    proforma.core_data.total_s_f;

  const totals = [
    { label: "Total", value: totalHeader, type: ProformaHeaderTypeTotal.PRICE },
    {
      label: "Per Unit",
      value: perUnitHeader,
      type: ProformaHeaderTypeTotal.PRICE,
    },
    {
      label: "Per Sqf",
      value: perSqfHeader,
      type: ProformaHeaderTypeTotal.PRICE,
    },
  ];

  return (
    <ProformaPaper
      title="Developer Fees"
      subTitle={subTitle}
      totals={totals}
      key={uuid}
    >
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          key={uuid}
          rows={developerFeesRows}
          headers={headerDeveloperFees}
          totals={tableTotals}
        />
      </Box>
    </ProformaPaper>
  );
};

export default ProformaDeveloperFees;

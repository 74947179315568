import {
  AutocompleteChangeReason,
  AutocompletePaperComponent,
  AutocompleteRenderInputParams,
} from "components/Autocomplete";
import TextField from "components/TextField";

interface IOption {
  label: string;
  id: number | string;
  key: string;
  isLinked?: boolean;
}

export const getAutocompleteProps = ({
  options,
  onSelect,
  onRemove,
  fieldLabel,
  disabled,
}: {
  options: Array<IOption>;
  onSelect: ({ value }: { value: IOption | null }) => void;
  onRemove: () => void;
  fieldLabel: string;
  disabled?: boolean;
}) => {
  return {
    options,
    PaperComponent: AutocompletePaperComponent,
    sx: { width: 300 },
    renderInput: (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={fieldLabel} />
    ),
    onChange: (
      _: any,
      value: IOption | null | string,
      reason: AutocompleteChangeReason
    ) => {
      if (typeof value === "string") {
        return;
      }

      if (reason === "clear") {
        onRemove();
        return;
      }

      onSelect({ value });
    },
    renderOption: (
      props: React.HTMLAttributes<HTMLLIElement> & {
        key: any;
      },
      option: IOption
    ) => (
      <li {...props} key={option.key}>
        {option.label}
      </li>
    ),
    disabled,
  };
};

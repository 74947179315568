import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import { EMPTY_VALUE_REGEX } from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import Stack from "components/Stack";

import FormSettingsTree from "entities/Deal/components/OverviewSections/FormSettingTree";
import {
  IDealUnitAmenities,
  useGetUnitAmenitiesSettings,
} from "entities/Deal/sdk";

const VALIDATION_SCHEMA = yup.object({});

const initialValues: Partial<IDealUnitAmenities> = {
  description: "",
  kitchen: [],
  storage: [],
  fittings: [],
  laundry: [],
};

interface IUnitAmenitiesEditForm {
  initialValues: Partial<IDealUnitAmenities>;
  onSubmit: (data: Partial<IDealUnitAmenities>) => Promise<any>;
  handleCancel?: () => void;
}
const UnitAmenitiesEditForm: React.FC<IUnitAmenitiesEditForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
}) => {
  const { data: unitAmenitiesSettings } = useGetUnitAmenitiesSettings();

  const KITCHEN_CHECKBOXES = unitAmenitiesSettings?.kitchen?.map((data) => ({
    value: data,
    label: data,
  }));

  const LAUNDRY_CHECKBOXES = unitAmenitiesSettings?.laundry?.map((data) => ({
    value: data,
    label: data,
  }));

  const STORAGE_CHECKBOXES = unitAmenitiesSettings?.storage?.map((data) => ({
    value: data,
    label: data,
  }));

  const FITTINGS_CHECKBOXES = unitAmenitiesSettings?.fittings?.map((data) => ({
    value: data,
    label: data,
  }));

  const handleSubmit = useCallback(
    (data: Partial<IDealUnitAmenities>) => {
      if (data.description && EMPTY_VALUE_REGEX.test(data.description)) {
        return onSubmit({ ...data, description: "" });
      }
      return onSubmit(data);
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleChangeDescription = useCallback(
    (newDescription: string) =>
      formik.setFieldValue("description", newDescription),
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: theme.spacing(4) }}
        >
          {KITCHEN_CHECKBOXES && (
            <FormSettingsTree
              label="Kitchen"
              options={KITCHEN_CHECKBOXES}
              formik={formik}
              formValueKey="kitchen"
            />
          )}
          {LAUNDRY_CHECKBOXES && (
            <FormSettingsTree
              label="Laundry"
              options={LAUNDRY_CHECKBOXES}
              formik={formik}
              formValueKey="laundry"
            />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: theme.spacing(4) }}
        >
          {STORAGE_CHECKBOXES && (
            <FormSettingsTree
              label="Storage"
              options={STORAGE_CHECKBOXES}
              formik={formik}
              formValueKey="storage"
            />
          )}
          {FITTINGS_CHECKBOXES && (
            <FormSettingsTree
              label="HVAC"
              options={FITTINGS_CHECKBOXES}
              formik={formik}
              formValueKey="fittings"
            />
          )}
        </Stack>
        <Stack data-testid="unit-amenities-rtf-description">
          <RichTextEditorField
            inputLabel="Description"
            defaultValue={formik.getFieldProps("description").value}
            onChange={handleChangeDescription}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            onClick={handleCancel}
            data-testid="close-unit-amenities-dialog-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-unit-amenities-form-button"
        >
          Update
        </Button>
      </Stack>
    </form>
  );
};

export default UnitAmenitiesEditForm;

import theme from "theme";

import Box from "components/Box";
import Tag from "components/Tag";

import { IApplicationFieldValue } from "entities/Application/sdk";
import FilesTable, {
  IFilefieldCustomValue,
} from "entities/Field/components/Filefield/FilesTable";

export interface IApplicationFilesTable {
  customValues: Array<IApplicationFieldValue>;
  onDownload: ({ fileId }: { fileId: number }) => void;
  onDelete?: ({ customValue }: { customValue: IFilefieldCustomValue }) => void;
}

const ApplicationFilesTable = ({
  customValues,
  onDownload,
  onDelete,
}: IApplicationFilesTable) => {
  return (
    <Box sx={{ marginY: theme.spacing(1), paddingLeft: theme.spacing(6) }}>
      <Tag
        label="file uploads"
        variant="gray20"
        sx={{ display: "inline-block", marginBottom: theme.spacing(1) }}
      />
      <FilesTable
        customFieldValues={customValues}
        onDownload={onDownload}
        onDelete={onDelete}
      />
    </Box>
  );
};

export default ApplicationFilesTable;

import React from "react";

import { colors } from "theme/palette";

import { DEFAULT_CELL_WIDTH } from "components/StyledTable/utils/common";

type IDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type IRenderCell<T, Props = {}> = <
  E extends T & {
    itemKey: string;
    values: { [key: string]: { value: string } };
  },
>(
  props: {
    itemKey: string;
    row: E;
    index: number;
    Wrapper: (props: IDivProps) => React.ReactElement;
  } & Props
) => React.ReactElement;

export type IRenderHeader<Props = {}> = (
  props: {
    itemKey: string;
    label: string;
    width?: string;
    Wrapper: (props: IDivProps) => React.ReactElement;
  } & Props
) => React.ReactElement;

export interface IHeader<T> {
  itemKey: string;
  label: string;
  width?: string;
  sticky?: boolean;
  style?: React.CSSProperties;
  children?: IHeader<T>[];
  render?: IRenderCell<T>;
  renderHeader?: IRenderHeader;
}

const HeaderWrapper =
  ({ index, width }: { index: number; width: string | undefined }) =>
  (props: IDivProps) => (
    <div
      key={index}
      {...props}
      style={{
        width: width || DEFAULT_CELL_WIDTH + "px",
        padding: "5px 12px",
        fontSize: "13px",
        fontWeight: 700,
        ...props.style,
      }}
      data-testid="header-name"
    />
  );

const defaultHeaderCellRender: IHeader<{ itemKey: string }>["renderHeader"] = ({
  itemKey,
  label,
  Wrapper,
}) => <Wrapper>{label}</Wrapper>;

export const renderHeaderCell = <T extends { itemKey: string }>(
  {
    itemKey,
    label,
    width,
    sticky,
    children,
    renderHeader = defaultHeaderCellRender,
    style = {},
  }: {
    itemKey: string;
    label: string;
    width?: string;
    sticky?: boolean;
    children?: IHeader<T>[];
    renderHeader?: IHeader<T>["renderHeader"];
    style?: React.CSSProperties;
  },
  index: number
) => {
  return (
    <div
      key={itemKey}
      style={{
        width: width || DEFAULT_CELL_WIDTH + "px",
        margin: children ? "0 40px 0 0" : "0",
        position: sticky ? "sticky" : "static",
        left: 0,
        backgroundColor: sticky ? colors.gray10 : "transparent",
        ...style,
      }}
      data-testid={`${label.replace(" ", "-").toLowerCase()}-header-section`}
    >
      {renderHeader({
        itemKey,
        label,
        width,
        Wrapper: HeaderWrapper({ index, width }),
      })}
      {children && (
        <div
          style={{
            display: "flex",
            borderBottom: `1px solid ${colors.gray100}`,
          }}
          data-testid="subheaders-section"
        >
          {children.map(renderHeaderCell)}
        </div>
      )}
    </div>
  );
};

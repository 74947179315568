import { Navigate, Outlet, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import Loading from "components/Loading";

import { useDownhomeBusinessIntegrationIsActive } from "entities/CompanyItegrations/sdk";

const DownhomeIntegrationCreatedGuard = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data, isLoading, error } = useDownhomeBusinessIntegrationIsActive({
    dealId,
  });

  if (isLoading) {
    return <Loading open />;
  }

  if (!_.isUndefined(error) && error.status === 403) {
    return <Navigate to={URLS.ACCESS_DENIED} replace />;
  }

  if (!_.isUndefined(data) && data.is_active && !_.isNil(data.external_id)) {
    return (
      <Navigate
        to={reverse(URLS.COMPANY_INTEGRATION_DOWNHOME_BUSINESS_UPDATE, {
          dealId,
          externalId: data.external_id,
        })}
        replace
      />
    );
  }

  return <Outlet />;
};

export default DownhomeIntegrationCreatedGuard;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useFormik } from "formik";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { packageShare, useCompanyPackageStructure } from "entities/Package/sdk";

import ChecklistSelectionStep from "./components/ChecklistSelectionStep";
import InvitationManagementTable, {
  IInvitation,
} from "./components/InvitationManagementTable";
import { INITIAL_VALUES, VALIDATION_SCHEMA } from "./constants";

interface IFormValues {
  invitations: Array<IInvitation>;
  message: string;
}

export interface IShareDialog {
  open: boolean;
  handleClose: () => void;
  initiallySelectedItemIds?: Array<number>;
}

const ShareDialog = ({
  open,
  handleClose,
  initiallySelectedItemIds = [],
}: IShareDialog) => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
  }>();

  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId = Number(params.tabId);

  const { data: checklist, isLoading } = useCompanyPackageStructure({
    packageId,
    dealId,
  });

  const selectedTab = useMemo(
    () =>
      tabId
        ? _.find(checklist?.tabs, ["tab_id", tabId])
        : _.first(checklist?.tabs),
    [checklist, tabId]
  );

  const items = useMemo(
    () =>
      checklist?.tabs.map((tab) => ({
        id: tab.tab_id,
        name: tab.name,
        isField: false,
        children: tab.categories.map((category) => ({
          id: category.category_id,
          name: category.name,
          isField: false,
          children: category.sections.map((section) => ({
            id: section.section_id,
            name: section.name,
            isField: false,
            children: section.fields.map((field) => ({
              id: field.field_id,
              name: field.name,
              isField: true,
              children: [],
            })),
          })),
        })),
      })),
    [checklist]
  );

  const [selectedItemIds, setSelectedItemIds] = useState<Array<number> | null>(
    null
  );

  useEffect(() => {
    if (!_.isNil(checklist) && selectedItemIds === null) {
      let fieldIds: Array<number> = [];

      if (_.isNil(selectedTab)) {
        return;
      }

      const { categories } = selectedTab;

      const categoryIds = categories.map((category) => category.category_id);
      const sections = categories.flatMap((category) => category.sections);
      const sectionIds = sections.map((section) => section.section_id);

      if (initiallySelectedItemIds.some((id) => categoryIds.includes(id))) {
        // Category level
        const selectedCategories = categories.filter((category) =>
          initiallySelectedItemIds.includes(category.category_id)
        );

        fieldIds = selectedCategories.flatMap((category) => {
          // If the category is in the initially selected ids we need all the field ids in that category.
          // We don't want to select the fields with type IMAGE, SIGNATURE, BUTTON, DATE, because we don't show in due diligence
          return category.sections.flatMap((section) => {
            return section.fields.map((field) => field.field_id);
          });
        });
      } else if (
        initiallySelectedItemIds.some((id) => sectionIds.includes(id))
      ) {
        // Section level
        const selectedSections = sections.filter((section) =>
          initiallySelectedItemIds.includes(section.section_id)
        );

        fieldIds = selectedSections.flatMap((section) => {
          return section.fields.map((field) => field.field_id);
        });
      } else {
        // Field level
        fieldIds = initiallySelectedItemIds;
      }

      setSelectedItemIds(fieldIds);
    }
  }, [selectedTab, checklist, selectedItemIds, initiallySelectedItemIds]);

  const [activeStep, setActiveStep] = useState<0 | 1>(0);
  const [openItemIds, setOpenItemIds] = useState<Array<number>>([]);

  const handleSubmit = useCallback(
    (values: IFormValues) => {
      if (selectedItemIds === null) {
        return;
      }

      return packageShare({
        fieldIds: selectedItemIds,
        invitations: values.invitations,
        message: values.message,
        packageId,
        dealId,
      })
        .then(() => {
          handleClose();

          toast.successMessage(
            "Share invitations have been sent successfully!"
          );
        })
        .catch((error) => {
          toast.errorMessage(error.message || DEFAULT_ERROR_MESSAGE);
        });
    },
    [dealId, packageId, selectedItemIds, handleClose]
  );

  const formik = useFormik<IFormValues>({
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA,
  });

  const stepAdvance = useCallback(() => {
    if (activeStep === 0) {
      setActiveStep(1);
    } else {
      formik.submitForm();
    }
  }, [activeStep, formik]);

  const updateActiveStep = ({
    activeStep: newActiveStep,
  }: {
    activeStep: 0 | 1;
  }) => {
    if (_.isEmpty(selectedItemIds) && newActiveStep === 1) {
      return;
    }

    setActiveStep(newActiveStep);
  };

  const nextButtonIsDisabled = useMemo(
    () => _.isEmpty(selectedItemIds) || isLoading,
    [isLoading, selectedItemIds]
  );

  const showLoader =
    isLoading ||
    _.isNull(selectedItemIds) ||
    _.isUndefined(items) ||
    _.isNil(checklist);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      data-testid="checklist-share-dialog"
    >
      <DialogTitle
        sx={{
          marginTop: theme.spacing(2),
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(3),
          marginLeft: theme.spacing(2),
          borderRadius: theme.spacing(1),
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-title-section"
      >
        <Text
          variant="h2"
          marginBottom={theme.spacing(0.5)}
          color={colors.gray100}
          data-testid="dialog-header"
        >
          Share with...
        </Text>
        <Text
          variant="text2"
          color={colors.gray80}
          data-testid="dialog-subheader"
        >
          Grant access to external contacts.
        </Text>
      </DialogTitle>

      <Stack
        paddingRight={2}
        paddingLeft={2}
        paddingBottom={2}
        data-testid="checklist-share-form"
      >
        <Stack
          direction="row"
          spacing={1}
          marginBottom={2}
          data-testid="form-tabs"
        >
          <Stack
            height={36}
            direction="row"
            borderTop={1}
            borderRight={1}
            borderBottom={activeStep === 0 ? 4 : 1}
            paddingBottom={activeStep === 0 ? 0 : 0.3}
            borderLeft={1}
            borderRadius={0.5}
            sx={{
              borderTopColor: colors.blue60,
              borderRightColor: colors.blue60,
              borderBottomColor:
                activeStep === 0 ? colors.blue100 : colors.blue60,
              borderLeftColor: colors.blue60,
            }}
            paddingRight={theme.spacing(1)}
            paddingLeft={theme.spacing(1)}
            alignItems="center"
            spacing={0.5}
            onClick={() => updateActiveStep({ activeStep: 0 })}
            data-testid="form-tab-checklist-items"
          >
            <Box
              width={16}
              height={16}
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: colors.blue100,
              }}
              data-testid="tab-status"
            >
              <Text
                variant="labelSmall"
                color={colors.white}
                marginTop={theme.spacing(0.1)}
                marginLeft={theme.spacing(0.1)}
                lineHeight="100%"
                data-testid="tab-order-or-completed-symbol"
              >
                {activeStep === 0 ? 1 : "✓"}
              </Text>
            </Box>
            <Text
              variant="text3"
              fontWeight={500}
              color={colors.gray100}
              data-testid="tab-label"
            >
              Select the items
            </Text>
          </Stack>

          <Stack
            height={36}
            direction="row"
            borderTop={1}
            borderRight={1}
            borderBottom={activeStep === 1 ? 4 : 1}
            paddingBottom={activeStep === 1 ? 0 : 0.3}
            borderLeft={1}
            borderRadius={0.5}
            sx={{
              borderTopColor: colors.blue60,
              borderRightColor: colors.blue60,
              borderBottomColor:
                activeStep === 1 ? colors.blue100 : colors.blue60,
              borderLeftColor: colors.blue60,
            }}
            paddingRight={theme.spacing(1)}
            paddingLeft={theme.spacing(1)}
            alignItems="center"
            spacing={0.5}
            onClick={() => updateActiveStep({ activeStep: 1 })}
            data-testid="form-tab-share-with"
          >
            <Box
              width={16}
              height={16}
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor:
                  activeStep === 0 ? colors.blue60 : colors.blue100,
              }}
              data-testid="tab-status"
            >
              <Text
                variant="labelSmall"
                color={colors.white}
                marginTop={theme.spacing(0.1)}
                marginLeft={theme.spacing(0.1)}
                lineHeight="100%"
                data-testid="tab-order-or-completed-symbol"
              >
                2
              </Text>
            </Box>
            <Text
              variant="text3"
              fontWeight={500}
              color={colors.gray100}
              data-testid="tab-label"
            >
              Share with...
            </Text>
          </Stack>
        </Stack>

        {showLoader && <Skeleton height={300} sx={{ transform: "unset" }} />}

        {activeStep === 0 && !showLoader && (
          <ChecklistSelectionStep
            items={items}
            openItemIds={openItemIds}
            setOpenItemIds={setOpenItemIds}
            selectedItemIds={selectedItemIds}
            setSelectedItemIds={setSelectedItemIds}
            checklistName={checklist.name}
          />
        )}

        {activeStep === 1 && (
          <InvitationManagementTable<IFormValues> formik={formik} />
        )}

        <DialogActions
          sx={{
            paddingTop: theme.spacing(3),
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
          }}
          data-testid="form-action-buttons"
        >
          <Button
            variant="text"
            onClick={handleClose}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            disabled={nextButtonIsDisabled}
            onClick={stepAdvance}
            data-testid="submit-button"
          >
            {activeStep === 0 ? "Next step" : "Send invite"}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ShareDialog;

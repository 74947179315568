import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";

import Box from "components/Box";
import DonutChart, { Legend } from "components/DonutChart";
import Stack from "components/Stack";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IRentalIncomeData } from "entities/Proforma/sdk";

import { MapRoomType } from "./constants";

export interface IProformaRentalIncomeUnitMix {
  rentalIncomeData: IRentalIncomeData;
  totalUnits: number;
}

const headerRentType: Array<{ key: string; name: string }> = [
  { key: "renttype", name: "Rent Type" },
  { key: "units", name: "Units" },
];

const headerUnitType: Array<{ key: string; name: string }> = [
  { key: "unittype", name: "Unit Type" },
  { key: "units", name: "Units" },
];

const rentTypeColors = [
  colors.teal100,
  colors.teal80,
  colors.teal60,
  colors.teal40,
  colors.teal20,
  colors.teal10,
  colors.teal5,
];
const unitTypeColors = [
  colors.violet100,
  colors.violet80,
  colors.violet60,
  colors.violet40,
  colors.violet20,
  colors.violet10,
  colors.violet5,
];

const ProformaRentalIncomeUnitMix: React.FC<IProformaRentalIncomeUnitMix> = ({
  rentalIncomeData,
  totalUnits,
}) => {
  const rentTypeRows = useMemo(
    () =>
      _.map(
        rentalIncomeData.rental_data.new_unit_mix_data.data.rent_type,
        (value, key) => {
          if (value.value !== 0)
            return {
              renttype: key,
              units: value,
            };
        }
      ).filter(isNotUndefined),
    [rentalIncomeData]
  );
  const unitTypeRows = useMemo(
    () =>
      _.map(
        rentalIncomeData.rental_data.new_unit_mix_data.data.unit_type,
        (value, key) => {
          if (value.value !== 0)
            return {
              unittype: _.get(MapRoomType, key, key.replace("_", " ")),
              units: value,
            };
        }
      ).filter(isNotUndefined),
    [rentalIncomeData]
  );

  const getRentTotal = useMemo(() => {
    let total = 0;
    _.forEach(
      rentalIncomeData.rental_data.new_unit_mix_data.data.rent_type,
      (value) => {
        total = total + value;
      }
    );
    return total;
  }, [rentalIncomeData]);

  const getUnitTotal = useMemo(() => {
    let total = 0;
    _.forEach(
      rentalIncomeData.rental_data.new_unit_mix_data.data.unit_type,
      (value) => {
        total = total + value;
      }
    );
    return total;
  }, [rentalIncomeData]);

  const tableRentTypeTotals = {
    renttype: "Total",
    units: getRentTotal,
  };

  const tableUnitTypeTotals = {
    unittype: "Total",
    units: getUnitTotal,
  };

  const totals = [
    {
      label: "Affordable",
      value:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.affordable_unit_count,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
    {
      label: "Market Rate",
      value:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.market_rate_unit_count,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
    {
      label: "Super's unit",
      value:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.super_unit_count,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
    {
      label: "Total Units",
      value: totalUnits,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
  ];

  const rentChartData = rentTypeRows.map(({ units, renttype }, index) => ({
    value: units,
    label: renttype,
    color: rentTypeColors[index % rentTypeColors.length],
  }));

  const unitChartData = unitTypeRows.map(({ units, unittype }, index) => ({
    value: units,
    label: unittype,
    color: unitTypeColors[index % unitTypeColors.length],
  }));

  return (
    <ProformaPaper
      title="Unit Mix"
      subTitle="Unit and Rent Type"
      totals={totals}
    >
      <Stack
        direction="row"
        sx={{
          margin: theme.spacing(4, 0),
        }}
      >
        <Box sx={{ flex: 1, paddingLeft: theme.spacing(2) }}>
          <Stack direction="row" alignItems="center" spacing={6}>
            <DonutChart width={200} data={rentChartData} />
            <Legend data={rentChartData} />
          </Stack>
        </Box>
        <Box sx={{ flex: 1 }}>
          <ProformaTable
            rows={rentTypeRows}
            headers={headerRentType}
            totals={tableRentTypeTotals}
          />
        </Box>
      </Stack>

      <Stack
        direction="row"
        sx={{
          margin: theme.spacing(4, 0),
        }}
      >
        <Box sx={{ flex: 1, paddingLeft: theme.spacing(2) }}>
          <Stack direction="row" alignItems="center" spacing={6}>
            <DonutChart width={200} data={unitChartData} />
            <Legend data={unitChartData} />
          </Stack>
        </Box>
        <Box sx={{ flex: 1 }}>
          <ProformaTable
            rows={unitTypeRows}
            headers={headerUnitType}
            totals={tableUnitTypeTotals}
          />
        </Box>
      </Stack>
    </ProformaPaper>
  );
};

export default ProformaRentalIncomeUnitMix;

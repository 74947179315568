import { useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { scrollToSelectedAnchor } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import { ID as SUB_NAVIGATION_ID } from "components/DealPageLayout/SubNavigation";
import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";
import Tooltip from "components/Tooltip";

interface IInPageLink {
  anchor: string;
  selected: boolean;
  buttonDataTestid?: string;
}

const ANCHORS = [MAIN_NAVIGATION_ID, SUB_NAVIGATION_ID];

const InPageLink: React.FC<IInPageLink> = ({
  anchor,
  selected,
  buttonDataTestid,
}) => {
  const handleClick = useCallback(
    () => scrollToSelectedAnchor({ anchor, navigationIds: ANCHORS }),
    [anchor]
  );

  return (
    <Box sx={{ cursor: "pointer" }} onClick={handleClick}>
      <Tooltip title={anchor} placement="top-start">
        <Button
          variant={selected ? "contained" : "text"}
          color="secondary"
          sx={{
            display: "block",
            width: "100%",
            textAlign: "left",
            paddingLeft: theme.spacing(1.5),
            borderLeft: selected ? `6px solid ${colors.blue100}` : "0",
            fontWeight: selected ? 700 : 400,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          data-testid={buttonDataTestid}
        >
          {anchor}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default InPageLink;

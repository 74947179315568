import { BASE_URL } from "config/urls";
import { get, post, useFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

export interface ICompanyIntegration {
  external_id: number | null;
  created_at: string | null;
}

export const useCompanyIntegrations = ({ dealId }: { dealId: number }) =>
  useFetch<Array<ICompanyIntegration>>(
    `v2/integrations/company-integrations/deal/${dealId}/list/`
  );

export interface IDownhomeAddress {
  external_identifier?: string;
  primary: boolean;
  street1: string;
  city: string;
  state: string;
}

export interface IDownhomePhone {
  external_identifier?: string;
  primary: boolean;
  number: string;
}

export interface IDownhomeEmail {
  external_identifier?: string;
  primary: boolean;
  address: string;
}

export interface IDownhomeWebsite {
  external_identifier?: string;
  primary: boolean;
  url: string;
}

export interface IDownhomePersonParticipant {
  external_identifier?: string;
  primary: boolean;
  first_name: string;
  last_name: string;
  date_of_birth: string | null;
  gender: string | null;
  ethnic_group: string | null;
  veteran_status: string | null;
}

export interface IDownhomeBusinessParticipant {
  name: string;
  addresses: Array<IDownhomeAddress> | null;
  phones: Array<IDownhomePhone> | null;
  emails: Array<IDownhomeEmail> | null;
  websites: Array<IDownhomeWebsite> | null;
  established_on: string | null;
  business_legal_form: string | null;
  naics: string | null;
  industry_group: { nickname: string } | null;
  owners: Array<IDownhomePersonParticipant> | null;
}

export const downhomeBusinessParticipantCreate = ({
  dealId,
  data,
}: {
  dealId: number;
  data: IDownhomeBusinessParticipant;
}) =>
  post(
    `${BASE_URL}v2/integrations/company-integrations/downhome/deal/${dealId}/business-participant/create/`,
    data
  );

export const downhomeBusinessParticipantUpdate = ({
  dealId,
  data,
}: {
  dealId: number;
  data: IDownhomeBusinessParticipant;
}) =>
  post(
    `${BASE_URL}v2/integrations/company-integrations/downhome/deal/${dealId}/business-participant/update/`,
    data
  );

export const useBusinessParticipantData = ({
  dealId,
  externalId,
}: {
  dealId: number;
  externalId: number;
}) =>
  useFetch<IDownhomeBusinessParticipant>(
    `v2/integrations/company-integrations/downhome/business-participant/${externalId}/retrieve/${formatGetParams({ deal_id: dealId })}`,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export const fetchBusinessParticipantData = ({
  externalIdentifier,
}: {
  externalIdentifier: string;
}) =>
  get<IDownhomeBusinessParticipant>(
    `${BASE_URL}v2/integrations/company-integrations/downhome/business-participant/${externalIdentifier}/retrieve/`
  );

export const fetchPersonParticipantData = ({
  externalIdentifier,
}: {
  externalIdentifier: string;
}) =>
  get<IDownhomePersonParticipant>(
    `${BASE_URL}v2/integrations/company-integrations/downhome/person-participant/${externalIdentifier}/retrieve/`
  );

export interface IDownhomeIndustryGroup {
  name: string;
  nickname: string;
}

export const useDownhomeIndustryGroups = () =>
  useFetch<Array<IDownhomeIndustryGroup>>(
    "v2/integrations/company-integrations/downhome/industry-group/list/"
  );

export interface ISimpleDownhomeBusinessParticipant {
  external_identifier: string;
  name: string;
}

export const useDownhomeBusinessesParticipants = () =>
  useFetch<Array<ISimpleDownhomeBusinessParticipant>>(
    "v2/integrations/company-integrations/downhome/business-participant/list/",
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export interface ISimpleDownhomePersonParticipant {
  external_identifier: string;
  first_name: string;
  last_name: string;
}

export const useDownhomePersonParticipants = ({
  enabled,
}: {
  enabled: boolean;
}) =>
  useFetch<Array<ISimpleDownhomePersonParticipant>>(
    enabled
      ? "v2/integrations/company-integrations/downhome/person-participant/list/"
      : undefined,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

interface IDownhomeBusinessIntegrationData {
  is_active: boolean;
  external_id?: string;
}

export const useDownhomeBusinessIntegrationIsActive = ({
  dealId,
}: {
  dealId: number;
}) =>
  useFetch<IDownhomeBusinessIntegrationData>(
    `v2/integrations/company-integrations/downhome/deal/${dealId}/business-integration/check-is-active/`
  );

export interface IDownhomeIntegrationProperties {
  name: string | null;
  naics: string | null;
  industry_group: string | null;
  business_legal_form: string | null;
  established_on: string | null;
  emails: Array<{ [key: string]: string }>;
  addresses: Array<{ [key: string]: string }>;
  phones: Array<{ [key: string]: string }>;
  websites: Array<{ [key: string]: string }>;
  owners: Array<{ [key: string]: string }>;
}

export const useDownhomePropertiesRetrieve = ({ dealId }: { dealId: number }) =>
  useFetch<IDownhomeIntegrationProperties>(
    `v2/integrations/company-integrations/downhome/deal/${dealId}/downhome-properties/retrieve/`,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";

import { isPromise, notImplementedError } from "utils/common";

import { IButton } from "components/Button";
import ConfirmationDialog from "components/ConfirmationDialog";

const ConfirmationDialogContext = React.createContext({
  show: notImplementedError,
  hide: notImplementedError,
  setMessage: notImplementedError as Dispatch<
    SetStateAction<string | React.ReactNode>
  >,
  setConfirmButtonColor: notImplementedError as Dispatch<
    SetStateAction<IButton["color"]>
  >,
  setConfirmButtonText: notImplementedError as Dispatch<SetStateAction<string>>,
  setOnConfirm: notImplementedError as Dispatch<
    SetStateAction<{ onConfirm: () => void | Promise<void> }>
  >,
  setOnCancel: notImplementedError as Dispatch<
    SetStateAction<{ onCancel: () => void | Promise<void> }>
  >,
});

export interface IConfirmationDialogContextProvider {
  children: React.ReactNode;
}

export const ConfirmationDialogContextProvider = ({
  children,
}: IConfirmationDialogContextProvider) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | React.ReactNode>("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [confirmButtonColor, setConfirmButtonColor] =
    useState<IButton["color"]>("error");
  const [{ onCancel }, setOnCancel] = useState({ onCancel: () => {} });
  const [{ onConfirm }, setOnConfirm] = useState<{
    onConfirm: () => void | Promise<void>;
  }>({ onConfirm: () => {} });

  const show = useCallback(() => {
    setOpen(true);
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setLoading(true);

    const result = onConfirm();

    if (isPromise(result)) {
      result.then(() => {
        setLoading(false);
        hide();
      });
    } else {
      setLoading(false);
      hide();
    }
  }, [onConfirm, hide]);

  return (
    <ConfirmationDialogContext.Provider
      value={{
        show,
        hide,
        setMessage,
        setConfirmButtonColor,
        setConfirmButtonText,
        setOnConfirm,
        setOnCancel,
      }}
    >
      {children}
      <ConfirmationDialog
        open={open}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        message={message}
        loading={loading}
        confirmButtonColor={confirmButtonColor}
        confirmButtonText={confirmButtonText}
      />
    </ConfirmationDialogContext.Provider>
  );
};

interface IuseLoadingBackdropArgs {
  message: string | React.ReactNode;
  confirmButtonColor?: IButton["color"];
  confirmButtonText?: string;
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void;
}

export const useConfirmationDialog = () => {
  const {
    show: showDialog,
    hide,
    setMessage,
    setConfirmButtonText,
    setConfirmButtonColor,
    setOnConfirm,
    setOnCancel,
  } = useContext(ConfirmationDialogContext);

  const show = useCallback(
    ({
      message = "",
      confirmButtonText = "Delete",
      confirmButtonColor = "error",
      onCancel = hide,
      onConfirm,
    }: IuseLoadingBackdropArgs) => {
      setMessage(message);
      setConfirmButtonText(confirmButtonText);
      setConfirmButtonColor(confirmButtonColor);
      setOnConfirm({ onConfirm });
      setOnCancel({ onCancel });
      showDialog();
    },
    [
      hide,
      setMessage,
      setConfirmButtonText,
      setConfirmButtonColor,
      setOnConfirm,
      setOnCancel,
      showDialog,
    ]
  );

  return { show, hide };
};

import _ from "lodash";

import { columnIsSortedByNumbers, sortListBy } from "utils/common";
import { format, FORMATS } from "utils/datetime";
import { downloadListOfListsAsXLSX } from "utils/xlsx";

import ColoredCell from "components/ScrollableTable/Cells/ColoredCell";
import DealNameCell from "components/ScrollableTable/Cells/DealNameCell";
import {
  DEAL_AVATAR,
  DEAL_ID,
  DEAL_NAME,
} from "components/ScrollableTable/Cells/DealNameCell/constants";
import TextCell from "components/ScrollableTable/Cells/TextCell";
import {
  CellAlignType,
  DYNAMIC_HYPHEN_COLUMN,
} from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";

import AverageCell from "entities/AnalyticsFilterGroup/components/TableCells/AverageCell";
import { AnalyticsIncomeAndOpexTabs } from "entities/AnalyticsFilterGroup/components/Tabs/constants";
import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";

import { IncomeAndOpexCalculationMethods } from "./constants";

const renderTextCell = ({ row, key }: { row: any; key: string }) => {
  const value = row[key];
  const title = _.get(row.titles, key);
  if (row.averagesLine) {
    return <AverageCell cellData={value} title={title} />;
  }
  return <TextCell cellData={value} title={title} />;
};

const renderDiffCell = ({ row, key }: { row: any; key: string }) => {
  const greenOnPlus = row.greenOnPlus;

  const color = (() => {
    // When the value = -100%
    if (row[key] === -1) {
      return "#cccccc";
    }
    if (greenOnPlus) {
      return row[key] < 0 ? "#C2163F" : "#00C57E";
    }
    return row[key] > 0 ? "#C2163F" : "#00C57E";
  })();
  const value = row[key] * 100;
  const sign = value > 0 ? "+" : "";
  const title = _.get(row.titles, key);

  if (row.averagesLine) {
    return <AverageCell cellData="0.00%" title="0.00%" />;
  }

  if (!row[key]) {
    return <TextCell cellData="" title="" />;
  }

  return (
    <ColoredCell
      value={value}
      label={sign + Number(value.toFixed(2)).toLocaleString() + "%"}
      backgroundColor={color}
      title={title}
    />
  );
};

export const getTableHeaders = ({
  selectedTab,
}: {
  selectedTab: AnalyticsIncomeAndOpexTabs;
}): IScrollableTableHeader => {
  let columns: IScrollableTableHeader = [];

  const diffInfoLabel = "Diff. = (Total / Group Average) - 1";

  if (selectedTab === AnalyticsIncomeAndOpexTabs.INCOME) {
    columns = [
      {
        label: "Rental Income",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "rental_income",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "rental_income_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Other Income",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "other_income",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "other_income_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Effective Gross Income (EGI)",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "effective_gross_income",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "effective_gross_income_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Net Operating Income (NOI)",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "net_operating_income",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "net_operating_income_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
    ];
  }

  if (selectedTab === AnalyticsIncomeAndOpexTabs.EXPENSES) {
    columns = [
      {
        label: "Management Fees",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "management_fees",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "management_fees_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Administration",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "admin_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "admin_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Repair & Maintenance",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "repair_and_maintenance",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "repair_and_maintenance_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Utilities",
        children: [
          {
            label: "Total",
            width: "160px",
            key: "utilities",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "130px",
            key: "utilities_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Taxes",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "taxes",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "taxes_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Insurance",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "property_insurance",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "property_insurance_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Total Gross Expenses",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "total_gross_expenses",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "total_gross_expenses_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
    ];
  }

  if (selectedTab === AnalyticsIncomeAndOpexTabs.METRICS) {
    columns = [
      {
        label: "EGI",
        info: "Effective Gross Income",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "effective_gross_income",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
        ],
      },
      {
        label: "Total Gross Expenses",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "total_gross_expenses",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
        ],
      },
      {
        label: "DSCR",
        info: "The Debt Service Coverage or DSCR represents the ratio between the monthly rental income that the property produces and the debt that you owe on the property. DCSR is calculated by dividing the net operating income (NOI) with the annual debt service. Debt service coverage ratios above 1.25 are considered very healthy, while those between 1.0 and 1.25 are in the breakeven range. Any figures below 1.0 means that the entity is not generating enough cash flow to pay back its yearly debt obligations and need attention",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "dscr",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
        ],
      },
      {
        label: "OER",
        info: "The OER is used to measure a property’s management efficiency and is calculated by dividing the total operating expenses with the effective gross income. In general, the OER should be in the neighborhood of 50% so anything that is significantly different might be a red flag.  For example, if the OER is significantly higher than 50%, there could be some inefficiencies in the property’s operations and there may be room for expense reductions. And if the OER is significantly below 50%, there might be inaccuracies of the property’s data that require further investigation. A very low OER might be a sign of ‘doctoring’ the numbers and not all expenses are being recorded in the general ledger, or it could mean that the current management isn’t investing enough money in the upkeep of the property, which is also problematic",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "oer",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
        ],
      },
      {
        label: "IER",
        info: "Income Expense ratio",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "ier",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
        ],
      },
    ];
  }

  return [
    {
      label: "Deals",
      sticky: true,
      children: [
        {
          label: "Deal name",
          key: "name",
          width: "300px",
          sortable: true,
          render: ({ row }) => {
            if (row.averagesLine) {
              return (
                <AverageCell cellData="Group Average" title="Group Average" />
              );
            }
            return (
              <DealNameCell
                row={{
                  ...row.obj,
                  [DEAL_ID]: row.obj.id,
                  [DEAL_NAME]: row.obj.name,
                  [DEAL_AVATAR]: row.obj.avatar,
                }}
              />
            );
          },
        },
      ],
    },
    {
      label: "Calculations",
      children: [
        {
          label: "Units",
          width: "110px",
          key: "total_units",
          sortable: true,
          justifyContent: CellAlignType.FLEX_END,
          render: ({ row }) => {
            if (row.averagesLine) {
              return (
                <AverageCell
                  cellData={row.total_units}
                  title={row.total_units}
                />
              );
            }
            return (
              <TextCell
                cellData={
                  row.total_units &&
                  Math.round(row.total_units).toLocaleString()
                }
                title={Number(row.total_units).toLocaleString()}
              />
            );
          },
        },
        {
          label: "Sf Area",
          width: "110px",
          key: "total_s_f",
          sortable: true,
          justifyContent: CellAlignType.FLEX_END,
          render: ({ row }) => {
            if (row.averagesLine) {
              return (
                <AverageCell cellData={row.total_s_f} title={row.total_s_f} />
              );
            }
            return (
              <TextCell
                cellData={
                  row.total_s_f &&
                  Math.round(Number(row.total_s_f)).toLocaleString()
                }
                title={
                  row.total_s_f &&
                  Math.round(Number(row.total_s_f)).toLocaleString()
                }
              />
            );
          },
        },
      ],
    },
    ...columns,
    DYNAMIC_HYPHEN_COLUMN,
  ];
};

const getFilteredDeals = ({
  deals,
  hideEmptyRows,
  selectedTab,
}: {
  deals: IFilterGroup["deals"];
  hideEmptyRows: boolean;
  selectedTab: "income" | "expenses" | "metrics";
}) =>
  hideEmptyRows
    ? deals.filter(
        (deal) => !_.isNil(deal.total_s_f) && !_.isNil(deal[selectedTab])
      )
    : deals;

const getCalculatedDealsData = ({
  deals,
  selectedTab,
  calculationMethod,
}: {
  deals: IFilterGroup["deals"];
  selectedTab: "income" | "expenses" | "metrics";
  calculationMethod: IncomeAndOpexCalculationMethods;
}) => {
  return deals.map((deal) => {
    const total_units = deal.total_units;
    const total_s_f = deal.total_s_f ? Number(deal.total_s_f) : undefined;

    const tabData = _.get(deal, selectedTab, {});

    let titles: any = {};
    const tabDataNumbers = _.mapValues(tabData, (value: number, key) => {
      if (calculationMethod === IncomeAndOpexCalculationMethods.total) {
        titles[key] = value?.toLocaleString();
        return value;
      }
      if (calculationMethod === IncomeAndOpexCalculationMethods["per sf"]) {
        titles[key] =
          total_s_f &&
          `${value.toLocaleString()} / ${total_s_f.toLocaleString()} = ${Number(
            (value / total_s_f).toFixed(2)
          ).toLocaleString()}`;
        return value && total_s_f && value / total_s_f;
      }
      if (calculationMethod === IncomeAndOpexCalculationMethods["per unit"]) {
        titles[key] =
          total_units &&
          value &&
          `${value.toLocaleString()} / ${total_units.toLocaleString()} = ${Math.round(
            value / total_units
          ).toLocaleString()}`;
        return value && total_units && value / total_units;
      }
    });

    return {
      obj: deal,
      total_units,
      total_s_f,
      titles,
      name: deal.name,
      ...tabDataNumbers,
    };
  });
};

const diffs = {
  income: {
    rental_income_diff: "rental_income",
    other_income_diff: "other_income",
    effective_gross_income_diff: "effective_gross_income",
    net_operating_income_diff: "net_operating_income",
  },
  expenses: {
    management_fees_diff: "management_fees",
    admin_costs_diff: "admin_costs",
    repair_and_maintenance_diff: "repair_and_maintenance",
    utilities_diff: "utilities",
    taxes_diff: "taxes",
    property_insurance_diff: "property_insurance",
    total_gross_expenses_diff: "total_gross_expenses",
  },
  metrics: {
    // dscr_diff: "dscr",
    // oer_diff: "oer",
    // ier_diff: "ier",
    // vacancy_rate_diff: "vacancy_rate",
  },
};

const getDataDiff = ({
  rows,
  dealAvgData,
  selectedTab,
  calculationMethod,
}: {
  rows: Array<{ obj: IFilterGroup["deals"][number] }>;
  dealAvgData: IFilterGroup["deal_avg_data"];
  selectedTab: "income" | "expenses" | "metrics";
  calculationMethod: IncomeAndOpexCalculationMethods;
}) => {
  return rows.map((row: any) => {
    const titles: any = {};
    const diffsData = _.mapValues(diffs[selectedTab], (field, key) => {
      if (calculationMethod === IncomeAndOpexCalculationMethods.total) {
        const value = _.get(row, field) / _.get(dealAvgData, field) - 1;
        titles[key] = `(${Number(
          _.get(row, field)
        ).toLocaleString()} / ${Number(
          _.get(dealAvgData, field)
        ).toLocaleString()}) - 1 = ${value.toFixed(4)}`;
        return value;
      }
      if (calculationMethod === IncomeAndOpexCalculationMethods["per sf"]) {
        const calculatedValue = _.get(row, field);
        const calculatedAvg =
          _.get(dealAvgData, field) / Number(_.get(dealAvgData, "total_s_f"));

        const value = calculatedValue / calculatedAvg - 1;
        titles[key] = `(${Number(calculatedValue).toLocaleString()} / ${Number(
          calculatedAvg
        ).toLocaleString()}) - 1 = ${value.toFixed(4)}`;

        return value;
      }
      if (calculationMethod === IncomeAndOpexCalculationMethods["per unit"]) {
        const calculatedValue = _.get(row, field);
        const calculatedAvg =
          _.get(dealAvgData, field) / Number(_.get(dealAvgData, "total_units"));

        const value = calculatedValue / calculatedAvg - 1;
        titles[key] = `(${Number(calculatedValue).toLocaleString()} / ${Number(
          calculatedAvg
        ).toLocaleString()}) - 1 = ${value.toFixed(4)}`;

        return value;
      }
    });

    // Whether or not to color the cell in green if the value is positive.
    // If this is false - the cell is colored in red.
    const greenOnPlus = selectedTab === "income";

    return {
      ...row,
      titles: { ...row.titles, ...titles },
      ...diffsData,
      greenOnPlus,
    };
  });
};

const formatValues = (obj: any) => {
  return _.mapValues(obj, (value) => {
    return Math.round(value).toLocaleString();
  });
};

const getAveragesLine = ({
  dealAvgData,
  calculationMethod,
}: {
  dealAvgData: any;
  calculationMethod: IncomeAndOpexCalculationMethods;
}) => {
  if (calculationMethod === IncomeAndOpexCalculationMethods["per sf"]) {
    const avgCalculatedData: any = {};
    Object.keys(dealAvgData).forEach((key) => {
      if (key !== "total_units" && key !== "total_s_f") {
        avgCalculatedData[key] = dealAvgData[key] / dealAvgData["total_s_f"];
      }
    });
    const row = { ...dealAvgData, ...avgCalculatedData };
    return { ...formatValues(row), averagesLine: true };
  }
  if (calculationMethod === IncomeAndOpexCalculationMethods["per unit"]) {
    const avgCalculatedData: any = {};
    Object.keys(dealAvgData).forEach((key) => {
      if (key !== "total_units" && key !== "total_s_f") {
        avgCalculatedData[key] = dealAvgData[key] / dealAvgData["total_units"];
      }
    });
    const row = { ...dealAvgData, ...avgCalculatedData };
    return { ...formatValues(row), averagesLine: true };
  }
  // if (calculationMethod === CalculationMethods.total) {
  return { ...formatValues(dealAvgData), averagesLine: true };
};

export const getSortedDealsRows = ({
  deals,
  hideEmptyRows,
  selectedTab,
  dealAvgData,
  selectedCalculation,
  selectedSortOption,
}: {
  deals: IFilterGroup["deals"];
  dealAvgData: IFilterGroup["deal_avg_data"];
  hideEmptyRows: boolean;
  selectedCalculation: IncomeAndOpexCalculationMethods;
  selectedTab: AnalyticsIncomeAndOpexTabs;
  selectedSortOption: string | undefined;
}) => {
  const filteredDeals = getFilteredDeals({ deals, hideEmptyRows, selectedTab });

  const calculatedData = getCalculatedDealsData({
    deals: filteredDeals,
    selectedTab,
    calculationMethod: selectedCalculation,
  });

  const calculateDataDiff = getDataDiff({
    rows: calculatedData,
    dealAvgData,
    selectedTab,
    calculationMethod: selectedCalculation,
  });

  const isSortedByNumbers = columnIsSortedByNumbers({
    selectedSortOption,
    data: calculateDataDiff,
  });

  const sortedDeals = sortListBy(
    calculateDataDiff,
    selectedSortOption,
    isSortedByNumbers
  );

  return sortedDeals;
};

const formatCalculatedDealsDataForCells = ({
  deals,
  selectedTab,
  calculationMethod,
}: {
  deals: any[];
  selectedTab: "income" | "expenses" | "metrics";
  calculationMethod: IncomeAndOpexCalculationMethods;
}) => {
  return deals.map((row) => {
    if (typeof row.obj[selectedTab] === "object") {
      // We don't want to mutate the values inside the rows, we just want to overwrite them with new values.
      // That's why we create the variable "result" and write the new values in it.
      // Having a mutated value in React causes hard-to-debug issues like the CSV export not working suddenly.
      // That's why we should always try to work with the values as if they were immutable.
      const result: any = [];
      Object.keys(row.obj[selectedTab]).forEach((key) => {
        if (
          (key === "oer" || key === "dscr" || key === "ier") &&
          (calculationMethod === IncomeAndOpexCalculationMethods["per sf"] ||
            selectedTab === "metrics")
        ) {
          result[key] =
            row[key] && Number(row[key].toFixed(2)).toLocaleString();
        } else {
          result[key] = Math.round(row[key]).toLocaleString();
        }
      });
      return { ...row, ...result };
    }
    return row;
  });
};

export const getFormatCalculatedDealsDataForCells = ({
  sortedDeals,
  dealAvgData,
  selectedTab,
  selectedCalculation,
}: {
  sortedDeals: any;
  dealAvgData: IFilterGroup["deal_avg_data"];
  selectedCalculation: IncomeAndOpexCalculationMethods;
  selectedTab: AnalyticsIncomeAndOpexTabs;
}) => {
  const formattedCalculatedDealsData = formatCalculatedDealsDataForCells({
    deals: sortedDeals,
    selectedTab,
    calculationMethod: selectedCalculation,
  });

  const calculatedDealsDataWithKeys = [
    ...formattedCalculatedDealsData.map((row, index) => ({
      ...row,
      key: index,
    })),
    getAveragesLine({ dealAvgData, calculationMethod: selectedCalculation }),
  ];

  return calculatedDealsDataWithKeys;
};

export const exportTableAsXLSX = ({
  headers,
  rows,
  selectedTab,
}: {
  headers: IScrollableTableHeader;
  rows: any[];
  selectedTab: string;
}) => {
  const headersData = headers.flatMap((header) =>
    header.children
      .filter(
        (subHeader) => subHeader.key !== "_" && subHeader.key !== "data_type"
      )
      .map((subHeader) => `${header.label} / ${subHeader.label}`)
  );

  const rowsData = rows.map((data) => {
    return headers.flatMap((header) =>
      header.children
        .filter(
          (subHeader) => subHeader.key !== "_" && subHeader.key !== "data_type"
        )
        .map((subHeader) =>
          !Number.isNaN(_.get(data, subHeader.key))
            ? _.get(data, subHeader.key)
            : undefined
        )
    );
  });

  const preparedData = [headersData, ...rowsData];

  const formattedDatetime = format(new Date(), FORMATS.FRONTEND.DATETIME);

  downloadListOfListsAsXLSX(
    preparedData,
    `Analytics - Income And Opex - ${selectedTab} - ${formattedDatetime}.xlsx`
  );
};

import { useCallback, useMemo, useState } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { openURLInNewTab } from "utils/common";

import Box from "components/Box";
import Collapse from "components/Collapse";
import { LinearProgress } from "components/Loading";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import UploadButton from "components/UploadButton";
import { defaultSmallFileUploadButton } from "components/UploadButton/utils";

import ExpandedButton from "entities/Field/components/Filefield/ExpandedButton";
import FilesMenuButton from "entities/Field/components/Filefield/FilesMenuButton";
import FilesTable, {
  IFilefieldCustomValue,
} from "entities/Field/components/Filefield/FilesTable";
import FieldTitle from "entities/Field/components/Title";
import {
  checkFilePermission,
  deleteDueDiligenceFile,
  IField,
  uploadFileToCustomField,
} from "entities/Field/sdk";
import { upload } from "entities/File/sdk";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

export interface IFilefield {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  packageId: number;
  dealId: number;
  categoryId: number;
  sectionId: number;
  tabId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  isFocused?: boolean;
}

const Filefield: React.FC<IFilefield> = ({
  editable,
  field,
  taskChannels,
  updatePackage,
  packageId,
  dealId,
  categoryId,
  sectionId,
  tabId,
  onUpdate,
  isFocused = false,
}) => {
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const customValues = useMemo(
    () =>
      field.custom_values?.map(
        (value) => value as unknown as IFilefieldCustomValue
      ) || [],
    [field]
  );

  const handleFileUpload = useCallback(
    ({ files }: { files: Array<File> }) => {
      setShowLoading(true);

      Promise.all(files.map((file) => upload({ file, isPrivate: true })))
        .then((fileIds) =>
          Promise.all(
            fileIds.map((fileId) =>
              uploadFileToCustomField({
                dealId,
                customFieldId: field.field_id,
                fileId,
              })
            )
          ).then(() =>
            onUpdate({ message: `The file(s) successfully uploaded!` })
          )
        )
        .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE))
        .finally(() => setShowLoading(false));
    },
    [dealId, field, onUpdate]
  );

  const handleDeletedFile = useCallback(
    ({ customValue }: { customValue: IFilefieldCustomValue }) => {
      deleteDueDiligenceFile({
        dealId,
        customFieldId: field.field_id,
        customValueId: customValue.value_id,
      })
        .then(() =>
          onUpdate({
            message: `The file ${customValue.file?.original_file_name} has been deleted successfully!`,
          })
        )
        .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE));
    },
    [field, dealId, onUpdate]
  );

  const handleDownload = useCallback(
    ({ fileId }: { fileId: number }) =>
      checkFilePermission({ fileId, fieldId: field.field_id })
        .then(({ url }) => openURLInNewTab({ url }))
        .catch(() => toast.errorMessage(DEFAULT_ERROR_MESSAGE)),
    [field]
  );

  const uploadedLabel = useMemo(() => {
    if (customValues.length === 1) {
      return `${customValues.length} file uploaded`;
    }
    return `${customValues.length} files uploaded`;
  }, [customValues]);

  return (
    <Box data-testid="dd-field" id={field.field_id.toString()}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
          backgroundColor: isFocused ? colors.green10 : "initial",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <FieldTitle
            field={field}
            dealId={dealId}
            packageId={packageId}
            categoryId={categoryId}
            tabId={tabId}
            sectionId={sectionId}
            onUpdate={onUpdate}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {taskChannels.length > 0 && (
            <DueDiligenceFieldOpenTaskButton
              taskChannels={taskChannels}
              sectionId={sectionId}
              categoryId={categoryId}
              company_field={field}
              dealId={dealId}
              tabId={tabId}
              packageId={packageId}
              updatePackage={updatePackage}
              taskButtonDataTestid="dd-open-task-button"
              taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
            />
          )}
          {!field.n_a_status && (
            <>
              {customValues.length > 0 && (
                <ExpandedButton
                  label={uploadedLabel}
                  isOpen={isOpenFiles}
                  onClick={() => setIsOpenFiles(!isOpenFiles)}
                />
              )}
              {customValues.length === 0 && (
                <Tag
                  label="missing"
                  variant="red"
                  dataTestid="empty-field-value"
                />
              )}
              {editable && (
                <UploadButton
                  accept="file/*"
                  onChange={handleFileUpload}
                  dataTestid="upload-files-input"
                  renderComponent={defaultSmallFileUploadButton}
                />
              )}
            </>
          )}
          {field.n_a_status && (
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          )}
          <FilesMenuButton
            dealId={dealId}
            packageId={packageId}
            field={field}
            categoryId={categoryId}
            sectionId={sectionId}
            tabId={tabId}
            onUpdate={onUpdate}
            menuButtonDataTestid="dd-field-actions-button"
          />
        </Stack>
      </Stack>
      {showLoading && <LinearProgress sx={{ position: "sticky", left: 0 }} />}

      <Collapse in={isOpenFiles} timeout="auto" unmountOnExit>
        {!field.n_a_status && customValues.length > 0 && (
          <Box
            sx={{ marginTop: theme.spacing(1), paddingLeft: theme.spacing(6) }}
          >
            <Tag
              label="file uploads"
              variant="gray20"
              sx={{ display: "inline-block", marginBottom: theme.spacing(1) }}
              dataTestid="uploaded-fields-tag-label"
            />
            <FilesTable
              customFieldValues={customValues}
              onDownload={handleDownload}
              onDelete={editable ? handleDeletedFile : undefined}
              onUpdate={() => onUpdate({})}
            />
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default Filefield;

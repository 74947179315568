import Box from "components/Box";
import TextField from "components/TextField";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface IExcelTable {
  title: string;
}
const ExcelTable = ({ title }: IExcelTable) => (
  <Box>
    <SectionHeader label={title} />
    <SectionContent>
      <Box
        sx={{
          marginTop: "8px",
          "& .MuiIconButton-root": { visibility: "hidden" },
          "&:hover .MuiIconButton-root": {
            visibility: "visible",
          },
        }}
      >
        <TextField
          disabled
          rows={4}
          multiline
          sx={{ width: "100%" }}
          placeholder="Paste table..."
        />
      </Box>
    </SectionContent>
  </Box>
);

export default ExcelTable;

import React from "react";

import { colors } from "theme/palette";

import Link from "components/Link";
import Table, { TableBody, TableCell, TableRow } from "components/Table";
import Text from "components/Text";

import { ICompany } from "entities/Company/sdk";

export interface ICompanyProfileTable {
  company: ICompany;
}

const CompanyProfileTable: React.FC<ICompanyProfileTable> = ({ company }) => (
  <Table size="small">
    <TableBody
      sx={{
        borderTop: `1px solid ${colors.blue20}`,
      }}
    >
      {company.established_in && (
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: 0,
            }}
          >
            <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
              Established in
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="company-established-year">
              {company.established_in}
            </Text>
          </TableCell>
        </TableRow>
      )}
      {company.company_size && (
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell sx={{ paddingLeft: 0 }}>
            <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
              Company size
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="company-size">
              {company.company_size} employees
            </Text>
          </TableCell>
        </TableRow>
      )}
      {company.locations?.map((location, index) => (
        <TableRow
          key={index}
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell sx={{ paddingLeft: 0 }}>
            <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
              Office-{index + 1}
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid={`company-address-${index + 1}`}>
              {[
                location.street,
                location.city,
                location.state,
                location.zipcode,
              ].join(", ")}
            </Text>
          </TableCell>
        </TableRow>
      ))}
      {company.web_url && (
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell sx={{ paddingLeft: 0 }}>
            <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
              Website
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Link
              data-testid="company-website"
              to={`https://${company.web_url}`}
              external
              target="_blank"
            >
              {company.web_url}
            </Link>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
);

export default CompanyProfileTable;

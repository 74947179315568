import {
  useDealCommunityAmenities,
  useDealCoreData,
  useDealDetails,
  useDealSchedule,
  useDealUnitAmenities,
} from "entities/Deal/sdk";
import {
  usePublicDealCommunityAmenities,
  usePublicDealCoreData,
  usePublicDealDetails,
  usePublicDealSchedule,
  usePublicDealSummaryByCategory,
  usePublicDealTeamGroup,
  usePublicDealUnitAmenities,
  usePublicProformaForDeal,
  usePublicRentalData,
} from "entities/Memo/publicSdk";
import { useDealSummaryByCategory, useDealTeamGroup } from "entities/Memo/sdk";
import { useProformaForDeal, useRentalData } from "entities/Proforma/sdk";

export const useDealSummaryByCategoryManager = ({
  token,
  dealId,
  category,
}: {
  token?: string;
  dealId: number;
  category: string;
}) => {
  const publicResponse = usePublicDealSummaryByCategory({ token, category });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealSummaryByCategory
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealSummaryByCategory({
    dealId: token ? undefined : dealId,
    category,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useDealDetailsManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicDealDetails({ token });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealDetails
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealDetails({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useProformaForDealManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicProformaForDeal({ token });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useProformaForDeal
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useProformaForDeal({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useRentalDataManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicRentalData({ token, dealId });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useRentalData
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useRentalData({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useDealScheduleManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicDealSchedule({ token });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealSchedule
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealSchedule({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useDealUnitAmenitiesManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicDealUnitAmenities({ token });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealUnitAmenities
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealUnitAmenities({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useDealCommunityAmenitiesManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicDealCommunityAmenities({ token });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealCommunityAmenities
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealCommunityAmenities({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useDealCoreDataManager = ({
  token,
  dealId,
}: {
  token?: string;
  dealId: number;
}) => {
  const publicResponse = usePublicDealCoreData({ token });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealCoreData
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealCoreData({
    dealId: token ? undefined : dealId,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

export const useDealTeamGroupManager = ({
  token,
  dealId,
  groupName,
}: {
  token?: string;
  dealId: number;
  groupName:
    | "Developer"
    | "Architect"
    | "General Contractor"
    | "Property Manager"
    | "Developer Partner";
}) => {
  const publicResponse = usePublicDealTeamGroup({
    token,
    groupName,
  });

  /**
   * When we have a token this is called from the public memo page (ShareableMemo).
   * In this case we don't want to send a request to useDealTeamGroup
   * because we don't have an Authorization token.
   *  */
  const privateResponse = useDealTeamGroup({
    dealId: token ? undefined : dealId,
    groupName,
  });

  // When we have a token we want to use the public response from the public APIs
  return token ? publicResponse : privateResponse;
};

import React from "react";

import { colors } from "theme/palette";

import Skeleton from "components/Skeleton";
import { TableCell } from "components/Table";

interface ICellSkeleton {}

const CellSkeleton: React.FC<ICellSkeleton> = () => {
  return (
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      <Skeleton variant="text" width="80%" />
    </TableCell>
  );
};

export default CellSkeleton;

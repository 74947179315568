import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { BOX_MAX_WIDTH } from "utils/constants";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { fetchMemoTemplates } from "entities/MemoTemplate/sdk";

const MemoRerouteToPackage: React.FC<{}> = () => {
  const [showError, setShowError] = useState(false);
  const params = useParams<{
    dealId: string;
    memoId: string;
  }>();
  const dealId = Number(params.dealId);
  const memoId = Number(params.memoId);

  usePageTitle("Builders Patch: Memo");

  const navigate = useNavigate();

  if (Number.isNaN(memoId)) {
    fetchMemoTemplates().then((memoTemplates) => {
      if (memoTemplates.length) {
        const memoId = _.get(_.orderBy(memoTemplates, ["name"]), "[0].memo_id");

        if (_.isUndefined(memoId)) {
          return;
        }

        navigate(reverse(URLS.DEAL_MEMO, { dealId, memoId }));
      } else {
        setShowError(true);
      }
    });
  }

  return (
    <>
      {showError && (
        <Paper
          sx={{
            overflowX: "auto",
            padding: theme.spacing(2, 4),
          }}
        >
          <Stack spacing={2}>
            {Number.isNaN(memoId) && (
              <Paper sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center" }}>
                <Box sx={{ paddingY: theme.spacing(5) }}>
                  <Text variant="h5" textAlign="center">
                    No memo templates for this deal
                  </Text>
                </Box>
              </Paper>
            )}
          </Stack>
        </Paper>
      )}
    </>
  );
};

export default MemoRerouteToPackage;

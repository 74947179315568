import React, { useCallback } from "react";
import { useFormik } from "formik";

import CheckboxTree from "components/CheckboxTree";

import { Phase } from "entities/Deal/constants";

const PHASE_CHECKBOXES = [
  { label: "application", value: Phase.APPLICATION },
  { label: "underwriting", value: Phase.UNDERWRITING },
  { label: "construction", value: Phase.CONSTRUCTION },
  { label: "lease up/conversion", value: Phase.CONVERSION },
  {
    label: "asset management",
    value: Phase.ASSET_MANAGEMENT,
  },
];

interface IFormProjectPhase {
  formik: ReturnType<typeof useFormik>;
}

const FORM_VALUE_KEY = "project_phase";

const FormProjectPhase: React.FC<IFormProjectPhase> = ({ formik }) => {
  const handleChange = useCallback(
    (value: Array<string | number>) =>
      formik.setFieldValue(FORM_VALUE_KEY, value),
    [formik]
  );

  return (
    <CheckboxTree
      label="Project phase"
      options={PHASE_CHECKBOXES}
      fieldInputProps={formik.getFieldProps(FORM_VALUE_KEY)}
      onChange={handleChange}
      value={formik.values[FORM_VALUE_KEY]}
    />
  );
};

export default FormProjectPhase;

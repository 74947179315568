import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { FilterListIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import CheckboxTree from "components/CheckboxTree";
import Grid from "components/Grid";
import Popover from "components/Popover";
import Stack from "components/Stack";
import Text from "components/Text";
import { useFilters } from "components/useFilters";

import { FILTER_DIALOG_MAX_WIDTH } from "entities/Reporting/components/FiltersButton/constants";
import DatePickerFilter from "entities/Reporting/components/FiltersButton/DatePickerFilter";
import RangeFilter from "entities/Reporting/components/FiltersButton/RangeFilter";
import {
  EFieldFormatType,
  TABLE_SETTINGS_FILTER_KEY,
} from "entities/Reporting/constants";
import {
  INonPipelineColumns,
  useReportPackageFilterList,
} from "entities/Reporting/sdk";
import { getFilterDictForDefaultReports } from "entities/Reporting/utils";

import { generateFilterOptions } from "./utils";

interface IFiltersButton {
  rows: { [key: string]: string | null }[];
  disabled: boolean;
  filtersButtonDataTestid?: string;
  reportPackageId: number | null | undefined;
  columns?: INonPipelineColumns[];
  isHardcodedReport?: boolean;
}

const FiltersButton = ({
  rows,
  disabled,
  filtersButtonDataTestid,
  reportPackageId,
  columns,
  isHardcodedReport = false,
}: IFiltersButton) => {
  const columnFilter = useMemo(() => {
    if (_.isEmpty(columns) || _.isUndefined(columns)) {
      return undefined;
    }

    return {
      label: TABLE_SETTINGS_FILTER_KEY,
      options: columns.map((column) => {
        return { label: column.name, value: column.name };
      }),
    };
  }, [columns]);

  const initialFilters = useMemo(() => {
    if (!_.isUndefined(columnFilter)) {
      return {
        [columnFilter["label"]]: columnFilter["options"].map(
          (option) => option.value
        ),
      };
    }
    return {};
  }, [columnFilter]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    filters,
    reset: resetFilters,
    updateFilters,
  } = useFilters({ initialFilters });

  const { data } = useReportPackageFilterList({
    report_package_id: reportPackageId,
  });

  const filterDict = useMemo(() => {
    if (isHardcodedReport) {
      return getFilterDictForDefaultReports();
    }

    return data;
  }, [data, isHardcodedReport]);

  const filterKeys = useMemo(
    () => filterDict?.map((filter) => filter.name),
    [filterDict]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({});

  const filtersOptions = useMemo(
    () => generateFilterOptions({ rows, filterKeys, filterDict }),
    [rows, filterKeys, filterDict]
  );

  // Remove duplicate filter options by label
  const uniqueFilterOptions = useMemo(
    () => _.uniqBy(filtersOptions, (option) => option.label.toLowerCase()),
    [filtersOptions]
  );

  const handleSelect = useCallback(
    ({ label, values }: { label: string; values: Array<string | number> }) =>
      setSelectedFilters((current) => ({
        ...current,
        [label]: values.map((value) => value.toString()),
      })),
    []
  );

  const handleApplyFilter = useCallback(() => {
    updateFilters(selectedFilters);
    handleClose();
  }, [selectedFilters, updateFilters]);

  const handleCancel = useCallback(() => {
    handleClose();
    setSelectedFilters(filters);
  }, [filters]);

  const handleReset = useCallback(() => {
    resetFilters();
    handleClose();
  }, [resetFilters]);

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters]);

  return (
    <>
      <Button
        aria-describedby={id}
        startIcon={<FilterListIcon color="primary" />}
        onClick={handleClick}
        disabled={disabled}
        data-testid={filtersButtonDataTestid}
        variant="outlined"
        color="secondary"
      >
        Filters
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ bottom: 0, maxHeight: "70vh" }}
      >
        <Box
          sx={{ width: FILTER_DIALOG_MAX_WIDTH }}
          data-testid="filters-popup-dialog"
        >
          <Box
            sx={{
              top: 0,
              zIndex: 2,
              position: "sticky",
              width: FILTER_DIALOG_MAX_WIDTH,
              backgroundColor: "white",
              padding: theme.spacing(1, 2, 0, 2),
            }}
          >
            <Box
              sx={{
                borderRadius: "4px",
                marginBottom: theme.spacing(1.5),
                padding: theme.spacing(1, 2),
                backgroundColor: colors.blue20,
              }}
            >
              <Text variant="h2">Filters</Text>
              <Text variant="text4">
                {uniqueFilterOptions.length} filter groups
              </Text>
            </Box>
          </Box>
          <Grid container spacing={2} paddingBottom={1} paddingX={4}>
            {uniqueFilterOptions.map((filter, index) => {
              if (
                _.includes(
                  [EFieldFormatType.DATE, EFieldFormatType.DATETIME],
                  filter.type
                )
              ) {
                return (
                  <Grid key={index} item xs={3} sx={{ overflow: "hidden" }}>
                    <DatePickerFilter
                      label={filter.label}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </Grid>
                );
              }
              if (
                _.includes(
                  [
                    EFieldFormatType.INTEGER,
                    EFieldFormatType.FLOAT_2,
                    EFieldFormatType.CURRENCY_USD,
                  ],
                  filter.type
                )
              ) {
                return (
                  <Grid key={index} item xs={3} sx={{ overflow: "hidden" }}>
                    <RangeFilter
                      label={filter.label}
                      type={filter.type}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </Grid>
                );
              }
              return (
                <Grid key={index} item xs={3} sx={{ overflow: "hidden" }}>
                  <CheckboxTree
                    label={_.capitalize(filter.label)}
                    options={filter.options}
                    value={_.get(selectedFilters, filter.label, [])}
                    onChange={(values) =>
                      handleSelect({ label: filter.label, values })
                    }
                  />
                </Grid>
              );
            })}
            {!_.isUndefined(columnFilter) && (
              <Grid item xs={3} sx={{ overflow: "hidden" }}>
                <CheckboxTree
                  key={columnFilter.label}
                  label={columnFilter.label}
                  options={columnFilter.options}
                  value={_.get(selectedFilters, columnFilter.label, [])}
                  onChange={(values) =>
                    handleSelect({ label: columnFilter.label, values })
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>

        <Stack
          direction="row"
          justifyContent="end"
          spacing={1}
          sx={{
            backgroundColor: colors.white,
            position: "sticky",
            bottom: 0,
            zIndex: 2,
            padding: theme.spacing(2),
            borderTop: "1px solid #c5c5ee",
          }}
        >
          <Button
            variant="text"
            onClick={handleCancel}
            data-testid="close-filters-dialog-button"
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleReset}>
            Reset to default
          </Button>
          <Button
            onClick={handleApplyFilter}
            data-testid="apply-filters-button"
          >
            Apply filters
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default FiltersButton;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { PDF_MIME_TYPE } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Loading from "components/Loading";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import Category from "entities/Checklist/components/Category";
import { FieldWrapperType } from "entities/Checklist/constants";
import { getValueFromParsedValues } from "entities/Package/components/PdfImport/utils";
import {
  createFieldValuesBulk,
  ICompanyCustomFieldParsedValue,
  useDataImport,
  useDueDiligenceMappedData,
  usePackageDealDetails,
} from "entities/Package/sdk";

const fixedHeight = `calc(100vh - ${theme.spacing(24.25)})`; // The whole page without the headers, the footer and padding of 3 spaces  on top and bottom.

const DueDiligenceEditPreview = () => {
  const [newValues, setNewValues] = useState<
    Array<ICompanyCustomFieldParsedValue>
  >([]);

  const params = useParams<{
    dealId: string;
    packageId: string;
    pdfImportId: string;
  }>();
  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const pdfImportId = Number(params.pdfImportId);
  const navigate = useNavigate();

  usePageTitle("Builders Patch: Checklist Edit Preview");

  const { data: checklist, loading: checklistLoading } = usePackageDealDetails({
    packageId,
    dealId,
  });

  const { data: dataImport, loading: dataImportLoading } = useDataImport({
    pdfImportId,
  });

  const { data: parsedValues = [] } = useDueDiligenceMappedData({
    pdfImportId,
  });

  useEffect(() => {
    if (!_.isUndefined(checklist) && !_.isUndefined(parsedValues)) {
      setNewValues(getValueFromParsedValues({ parsedValues, checklist }));
    }
  }, [checklist, parsedValues]);

  const handleUpdateValue = useCallback(
    ({
      fieldId,
      value,
    }: {
      fieldId: number;
      value: string | undefined | null;
    }) => {
      setNewValues((values) =>
        values.map((v) => {
          if (v.field_id === fieldId) {
            return { ...v, value };
          }

          return v;
        })
      );
    },
    [setNewValues]
  );

  const handleSubmit = useCallback(
    () =>
      createFieldValuesBulk({ dealId, values: newValues })
        .then(() => {
          toast.successMessage("Data imported successfully!");
          navigate(
            reverse(URLS.DEAL_PACKAGE_NO_TAB, {
              dealId,
              packageId,
            })
          );
        })
        .catch(handleInvalidRequest),
    [dealId, newValues, packageId, navigate]
  );

  const isLoading = useMemo(
    () => checklistLoading || dataImportLoading,
    [checklistLoading, dataImportLoading]
  );

  return isLoading ? (
    <Loading open />
  ) : (
    <Stack direction="row" spacing={2} paddingLeft={4}>
      <embed
        type={PDF_MIME_TYPE}
        src={`${dataImport?.file}#page=0&toolbar=false&view=fitH`}
        style={{
          flexBasis: "50%",
          height: fixedHeight,
          width: "100%",
        }}
        data-testid="pdf-file-section-preview"
      />
      <Stack
        flexBasis="50%"
        spacing={2}
        paddingY={3}
        paddingRight={4}
        sx={{
          height: fixedHeight,
          overflowX: "auto",
        }}
        data-testid="dd-section-preview"
      >
        <PageHeader
          title={checklist?.name || "Checklist details"}
          actions={
            <Button
              onClick={handleSubmit}
              data-testid="submit-button"
              sx={{ whiteSpace: "nowrap" }}
            >
              Save & apply changes
            </Button>
          }
        />
        <Stack spacing={1} data-testid="checklist-content">
          {checklist?.tabs.map((tab) => (
            <Stack key={`tab-${tab.tab_id}`} spacing={1}>
              {tab.categories.map((category) => (
                <Category
                  key={`tab-${tab.tab_id}-category-${category.category_id}`}
                  category={category as any} // map to IPackageDetailsCategory
                  fieldWrapperType={FieldWrapperType.DUE_DILIGENCE_EDIT_PREVIEW}
                  fieldWrapperMeta={{
                    dealId,
                    packageId,
                    tabId: tab.tab_id,
                    categoryId: category.category_id,
                    onUpdate: handleUpdateValue,
                    parsedValues,
                    newValues,
                  }}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DueDiligenceEditPreview;

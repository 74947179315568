// This file is a copy of entities/Fields/Richtextfield, but without all the functionality
import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import EditButton from "entities/Package/components/PdfImport/Fields/Richtextfield/EditButton";
import FieldTitle from "entities/Package/components/PdfImport/Fields/Title";
import { IPackageField } from "entities/Package/sdk";

export interface IRichtextfield {
  field: IPackageField;
  editable: boolean;
  parsedValue: string | undefined | null;
  onUpdate: ({
    fieldId,
    value,
  }: {
    fieldId: number;
    value: string | undefined | null;
  }) => void;
}

const Richtextfield = ({
  field,
  editable,
  parsedValue,
  onUpdate,
}: IRichtextfield) => {
  const fieldValue = useMemo(
    () => _.get(field.values, "[0].rich_value"),
    [field]
  );
  const handleSave = useCallback(
    (newValue: any) => {
      onUpdate({ fieldId: field.field_id, value: newValue });
    },
    [field, onUpdate]
  );

  const value = useMemo(() => {
    if (fieldValue && !parsedValue) {
      return fieldValue;
    } else if (parsedValue) {
      return parsedValue;
    }

    return "";
  }, [parsedValue, fieldValue]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
        data-testid="dd-field"
      >
        <FieldTitle field={field} editable={editable} />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ flexGrow: 0 }}
        >
          {field.n_a_status ? (
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          ) : (
            <>
              {editable && (
                <EditButton
                  field={field}
                  initialValue={parsedValue || ""}
                  onSave={handleSave}
                  buttonDataTestid="field-edit-button"
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
      {!field.n_a_status && (
        <Box
          sx={{ paddingLeft: theme.spacing(6) }}
          data-testid="dd-rtf-field-value"
        >
          <Box
            sx={{
              flex: 1,
              borderRadius: theme.spacing(0.5),
              border: `1px solid ${colors.blue20}`,
              padding: theme.spacing(1, 1.5, 1),
              marginY: theme.spacing(3),
              backgroundColor: colors.blue5,
            }}
            data-test-id={field.name}
          >
            <SanitizeHtml html={value} dataTestid="dd-field-value" />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Richtextfield;

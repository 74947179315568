import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Divider from "components/Divider";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import StickyMenu from "components/StickyMenu";

interface IWorkspaceStickySidebarSkeleton {}

const WorkspaceStickySidebarSkeleton: React.FC<
  IWorkspaceStickySidebarSkeleton
> = () => {
  return (
    <StickyMenu top={theme.spacing(13.75)}>
      <Paper
        sx={{ padding: theme.spacing(4), border: `1px solid ${colors.blue40}` }}
      >
        <Stack spacing={1}>
          {[1, 2].map((index) => (
            <Box key={index}>
              <Skeleton variant="text" width={100} />
            </Box>
          ))}
        </Stack>
        <Divider
          sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
        />
        <Stack spacing={1}>
          <Skeleton variant="text" width={100} />
        </Stack>
      </Paper>
    </StickyMenu>
  );
};

export default WorkspaceStickySidebarSkeleton;

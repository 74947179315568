import React from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import UserAvatar from "components/UserAvatar";

import EditSignedUserButton from "entities/User/components/EditSignedUserButton";
import UserInfoTable from "entities/User/components/InfoBox/InfoTable";
import { IUser } from "entities/User/sdk";

export interface IUserInfoBox {
  user: IUser;
}

// Used for anchoring
export const ID = "user_profile_section";

const UserInfoBox: React.FC<IUserInfoBox> = ({ user }) => (
  <Paper id={ID}>
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: `1px solid ${colors.gray100}` }}
      >
        <Text variant="text1" fontWeight={700}>
          User profile
        </Text>
        <EditSignedUserButton />
      </Stack>
      <Stack direction="row" spacing={4}>
        <UserAvatar
          src={user.avatar ? user.avatar : undefined}
          imgix
          imgixWidth={64}
          imgixHeight={64}
          variant="rounded"
          sx={{ width: 64, height: 64 }}
          dataTestid={
            user?.avatar
              ? "custom-user-profile-avatar"
              : "default-user-profile-avatar"
          }
        />
        <Stack flex={1} spacing={3}>
          <Box>
            <Text variant="text2" data-testid="user-full-name">
              {user.first_name} {user.last_name}
            </Text>
            <Text
              variant="labelSmall"
              sx={{ color: colors.gray40 }}
              data-testid="user-job-position"
            >
              {user.job_title}
            </Text>
          </Box>
          <UserInfoTable user={user} />
        </Stack>
      </Stack>
    </Stack>
  </Paper>
);

export default UserInfoBox;

import React, { useEffect, useRef, useState } from "react";

import Marker from "components/Map/Marker";

import { IMarker } from "entities/Deal/sdk";

interface IMap {
  markers: IMarker[];
  showMarkersButton: boolean;
  options?: {
    zoom?: number;
  };
}

const MapElement: React.FC<IMap> = ({
  markers,
  options,
  showMarkersButton,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: options?.zoom,
        })
      );
    }
  }, [ref, map, options]);

  useEffect(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(marker.center);
      });

      if (markers.length > 1) {
        map.fitBounds(bounds);
      } else if (markers.length === 1) {
        map.panTo(markers[0].center);
      }
    }
  }, [map, markers]);

  return (
    <div
      ref={ref}
      style={{
        flexGrow: 1,
      }}
      data-testid="google-map-with-locations"
    >
      {markers.map((marker) => (
        <Marker
          key={`${marker.center.lng} - ${marker.center.lat}`}
          markerInfo={marker}
          showButton={showMarkersButton}
          position={marker.center}
          map={map}
        />
      ))}
    </div>
  );
};

export default MapElement;

import theme from "theme";

import { IScrollableTableHeader } from "./TableHeaders";

export const CELL_DEFAULT_WIDTH = "110px";

export enum ScrollableTableCellVariant {
  DEAL = "DEAL",
  TEXT = "STRING",
  NUMBER = "NUMBER",
  DATE_TIME = "DATE TIME",
  CURRENCY = "CURRENCY",
  TAG = "TAG",
}

export enum CellAlignType {
  FLEX_END = "flex-end",
  FLEX_START = "flex-start",
  SPACE_BETWEEN = "space-between",
}

export const DYNAMIC_HYPHEN_COLUMN: IScrollableTableHeader[number] = {
  label: "",
  width: "0px",
  children: [
    {
      label: "",
      width: "0px",
      key: "_",
      variant: ScrollableTableCellVariant.TEXT,
    },
  ],
};

export const SPACING_AFTER_STICKY_COLUMN = theme.spacing(2.5);
export const SPACING_BETWEEN_COLUMNS = theme.spacing(5);

import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";
import { reverse, useMatchUrl } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import ApplicationMenu from "components/MainNavigation/ApplicationMenu";
import MainNavDropdown from "components/MainNavigation/MainNavDropdown";
import { MenuItem } from "components/Menu";
import NavLink from "components/NavLink";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import { useFeatureDisabledDialog } from "components/useFeatureDisabledDialog";

import { useSignedUser } from "entities/Auth/sdk";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import {
  companySettingsFeatureLimitCheck,
  useCompanySettings,
} from "entities/Company/sdk";
import { Phase } from "entities/Deal/constants";
import { useCustomReports } from "entities/Reporting/sdk";

const DEAL_PHASES = [
  { key: "All", label: "All phases" },
  { key: Phase.APPLICATION, label: Phase.APPLICATION },
  { key: Phase.UNDERWRITING, label: Phase.UNDERWRITING },
  { key: Phase.CONSTRUCTION, label: Phase.CONSTRUCTION },
  { key: Phase.CONVERSION, label: Phase.CONVERSION },
  { key: Phase.ASSET_MANAGEMENT, label: "Asset mgmt." },
];

const NavigationMenuList = () => {
  const { data: user, isValidating: isFetchingUser } = useSignedUser();
  const { data: customReports } = useCustomReports();

  const location = useLocation();

  const isProjectPhaseActive = useMatchUrl({ url: "/projects/*" });
  const isDealActive = useMatchUrl({ url: "/deals/*" });
  const isAnalyticsDevBudgetActive = useMatchUrl({
    url: "/analytics/development-budget/groups/*",
  });
  const isAnalyticsIncomeAndOpexActive = useMatchUrl({
    url: "/analytics/income-and-opex/groups/*",
  });
  const isAnalyticsActive = useMemo(
    () =>
      Boolean(isAnalyticsDevBudgetActive) ||
      Boolean(isAnalyticsIncomeAndOpexActive),
    [isAnalyticsDevBudgetActive, isAnalyticsIncomeAndOpexActive]
  );
  const isReportingActive = useMatchUrl({ url: "/reporting/*" });
  const isTemplatesActionsActive = useMatchUrl({ url: "/templates/*" });
  const isCompanyChecklistsActive = useMatchUrl({
    url: "/company-checklists/*",
  });
  const isTemplatesActive = useMemo(
    () =>
      Boolean(isTemplatesActionsActive) || Boolean(isCompanyChecklistsActive),
    [isTemplatesActionsActive, isCompanyChecklistsActive]
  );

  const isTableStorageActive = useMatchUrl({ url: "/data-tables/*" });

  const userIsSuperuser = useMemo(() => user?.is_superuser, [user]);

  const { show: showFeatureDisabledDialog } = useFeatureDisabledDialog();
  const navigate = useNavigate();

  const { data: companySettings, isLoading: companySettingsIsLoading } =
    useCompanySettings({});

  const showCustomReportsMenu = useMemo(
    () => companySettings?.custom_reports_shown,
    [companySettings]
  );

  const showDevelopmentBudgetAnalyticsMenuItem = useMemo(
    () => companySettings?.analytics_development_budget_shown,
    [companySettings]
  );

  const showIncomeAndOpexAnalyticsMenuItem = useMemo(
    () => companySettings?.analytics_income_and_opex_shown,
    [companySettings]
  );

  const showAnalyticsMenu = useMemo(
    () =>
      showDevelopmentBudgetAnalyticsMenuItem ||
      showIncomeAndOpexAnalyticsMenuItem,
    [showDevelopmentBudgetAnalyticsMenuItem, showIncomeAndOpexAnalyticsMenuItem]
  );

  const showDataTablesMenu = useMemo(
    () => companySettings?.tables_shown,
    [companySettings]
  );

  const handleDataTablesClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.TABLES,
    callback: () => navigate(URLS.TABLE_STORAGE_LIST),
  });

  const checkFeatureAndRedirectOrShowFeatureDisabledDialog = useCallback(
    ({
      feature,
      url,
    }: {
      feature: ECompanySettingsFeatureType;
      url: string;
    }) => {
      companySettingsFeatureLimitCheck({ featureType: feature })
        .then(({ has_access }) => {
          if (has_access) {
            navigate(url);
          } else {
            showFeatureDisabledDialog({});
          }
        })
        .catch(handleInvalidRequest);
    },
    [navigate, showFeatureDisabledDialog]
  );

  const handleAnalyticsDevBudgetClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.ANALYTICS_DEVELOPMENT_BUDGET,
    callback: () => navigate(URLS.ANALYTICS_DEV_BUDGET_LIST),
  });

  const handleAnalyticsIncomeAndOpexClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.ANALYTICS_INCOME_AND_OPEX,
    callback: () => navigate(URLS.ANALYTICS_INCOME_AND_OPEX_LIST),
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      data-testid="main-navigation-menus"
    >
      {isFetchingUser && _.isUndefined(user) ? (
        _.range(7).map((index) => (
          <Skeleton
            width={100}
            height={30}
            key={index}
            sx={{ backgroundColor: colors.gray80 }}
          />
        ))
      ) : (
        <>
          <ApplicationMenu />
          <MainNavDropdown
            label="Deals"
            activeMenu={Boolean(isProjectPhaseActive) || Boolean(isDealActive)}
            buttonDataTestid="navigation-deals"
          >
            <GroupLabel label="Deal phase" />
            {DEAL_PHASES.map(({ key, label }) => (
              <NavLink
                key={key}
                to={reverse(URLS.PROJECTS_LIST, {
                  phase: key,
                })}
                linkDataTestid="deals-in-phase-link"
              >
                {({ isActive }) => (
                  <MenuItem
                    selected={isActive}
                    data-testid="navigation-deals-in-phase"
                  >
                    {label}
                  </MenuItem>
                )}
              </NavLink>
            ))}
          </MainNavDropdown>

          {companySettingsIsLoading ? (
            <Skeleton
              width={100}
              height={30}
              sx={{ backgroundColor: colors.gray80 }}
            />
          ) : (
            showAnalyticsMenu && (
              <MainNavDropdown
                label="Analytics"
                activeMenu={Boolean(isAnalyticsActive)}
                buttonDataTestid="navigation-analytics"
              >
                <GroupLabel label="Portfolio analytics" />
                {showDevelopmentBudgetAnalyticsMenuItem && (
                  <Box
                    data-testid="dev-budget-analytics-link"
                    onClick={handleAnalyticsDevBudgetClick}
                  >
                    <MenuItem
                      selected={Boolean(isAnalyticsDevBudgetActive)}
                      data-testid="navigation-dev-budget-analytics"
                    >
                      Development budget
                    </MenuItem>
                  </Box>
                )}
                {showIncomeAndOpexAnalyticsMenuItem && (
                  <Box
                    onClick={handleAnalyticsIncomeAndOpexClick}
                    data-testid="income-and-opex-analytics-link"
                  >
                    <MenuItem
                      selected={Boolean(isAnalyticsIncomeAndOpexActive)}
                      data-testid="navigation-income-and-opex-analytics"
                    >
                      Income and OPEX
                    </MenuItem>
                  </Box>
                )}
              </MainNavDropdown>
            )
          )}

          <MainNavDropdown
            label="Reports"
            activeMenu={Boolean(isReportingActive)}
            buttonDataTestid="navigation-reports"
          >
            <GroupLabel label="Default reports" />
            <NavLink
              to={URLS.REPORTING_DEAL_CORE_DATA}
              linkDataTestid="core-deal-data-report-link"
            >
              {({ isActive }) => (
                <MenuItem
                  selected={isActive}
                  data-testid="navigation-core-deal-data-report"
                >
                  Core deal data
                </MenuItem>
              )}
            </NavLink>
            <NavLink
              to={URLS.REPORTING_INCOME_AND_OPEX}
              linkDataTestid="income-and-opex-report-link"
            >
              {({ isActive }) => (
                <MenuItem
                  selected={isActive}
                  data-testid="navigation-income-and-opex-report"
                >
                  Income and OPEX
                </MenuItem>
              )}
            </NavLink>
            {customReports.length > 0 && showCustomReportsMenu && (
              <GroupLabel label="Custom reports" />
            )}
            {showCustomReportsMenu &&
              customReports.map((report, index) => (
                <Box
                  key={index}
                  onClick={() =>
                    checkFeatureAndRedirectOrShowFeatureDisabledDialog({
                      feature: ECompanySettingsFeatureType.CUSTOM_REPORTS,
                      url: reverse(URLS.REPORTING_CUSTOM, {
                        customReportId: report.id,
                      }),
                    })
                  }
                  data-testid="custom-report-link"
                >
                  <MenuItem
                    selected={location.pathname.startsWith(
                      reverse(URLS.REPORTING_CUSTOM, {
                        customReportId: report.id,
                      })
                    )}
                    data-testid="navigation-custom-report"
                  >
                    {report.name}
                  </MenuItem>
                </Box>
              ))}
          </MainNavDropdown>

          <NavLink
            to={URLS.ALL_DEAL_TASKS}
            linkDataTestid="navigation-all-tasks-link"
          >
            {({ isActive }) => (
              <Button
                variant="text"
                color="secondary"
                size="small"
                sx={{ "&:hover *": { opacity: 1 } }}
                data-testid="navigation-all-tasks"
              >
                <Text
                  variant="text3"
                  sx={{
                    color: colors.white,
                    opacity: isActive ? 1 : 0.6,
                    fontWeight: 700,
                    transition: "opacity 200ms ease-out",
                  }}
                >
                  Task mgmt.
                </Text>
              </Button>
            )}
          </NavLink>

          <MainNavDropdown
            label="Templates"
            activeMenu={isTemplatesActive}
            buttonDataTestid="navigation-templates"
          >
            {userIsSuperuser && (
              <span>
                <NavLink
                  to={URLS.MEMO_TEMPLATE_LIST}
                  linkDataTestid="memo-templates-page-link"
                >
                  {({ isActive }) => (
                    <MenuItem
                      selected={isActive}
                      dataTestid="navigation-memo-templates"
                    >
                      Memo templates
                    </MenuItem>
                  )}
                </NavLink>
                <NavLink
                  to={URLS.CHECKLIST_TEMPLATE_LIST}
                  data-testid="intake-templates-page-link"
                >
                  {({ isActive }) => (
                    <MenuItem
                      selected={isActive}
                      dataTestid="navigation-intake-templates"
                    >
                      Intake form templates
                    </MenuItem>
                  )}
                </NavLink>
              </span>
            )}
            <NavLink
              to={URLS.COMPANY_CHECKLISTS}
              linkDataTestid="checklists-templates-page-link"
            >
              {({ isActive }) => (
                <MenuItem
                  selected={isActive}
                  dataTestid="navigation-checklists-templates"
                >
                  Checklist templates
                </MenuItem>
              )}
            </NavLink>
          </MainNavDropdown>
          {showDataTablesMenu && (
            <Box
              data-testid="company-data-tables"
              onClick={handleDataTablesClick}
            >
              <Button
                variant="text"
                color="secondary"
                size="small"
                sx={{ "&:hover *": { opacity: 1 } }}
              >
                <Text
                  variant="text3"
                  sx={{
                    color: colors.white,
                    opacity: isTableStorageActive ? 1 : 0.6,
                    fontWeight: 700,
                    transition: "opacity 200ms ease-out",
                  }}
                >
                  Data tables
                </Text>
              </Button>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default NavigationMenuList;

import { useCallback } from "react";

import EmployeeForm from "entities/Employee/components/Form";
import { createCompanyUser } from "entities/Employee/sdk";
import { IUser } from "entities/User/sdk";

export interface IEmployeeAddForm {
  onCreated: ({ employee }: { employee: IUser }) => void;
  onCancel: () => void;
}

const EmployeeAddForm = ({ onCreated, onCancel }: IEmployeeAddForm) => {
  const handleSubmit = useCallback(
    (data: Partial<IUser>) =>
      createCompanyUser(data).then((updatedUser) => {
        onCreated && onCreated({ employee: updatedUser });
      }),
    [onCreated]
  );

  return (
    <EmployeeForm
      submitLabel="Add Member"
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
};

export default EmployeeAddForm;

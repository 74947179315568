import _ from "lodash";

import { BASE_URL } from "config/urls";
import { deleteCall, get, post, useFetch, usePaginatedFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { ETableReportType, ETableResponseStatus } from "./constants";

export interface ITableCell {
  value: string | null;
}

export type ITableRow = {
  cells: Array<ITableCell>;
};

export interface ITable {
  id: number;
  name: string;
  created_by: { first_name: string; last_name: string };
  created_at: string;
  updated_by: { first_name: string; last_name: string } | null;
  updated_at: string;
  rows: Array<ITableRow>;
  deal: {
    id: number;
    name: string;
  } | null;
  is_report: boolean;
  is_locked: boolean;
  deal_company_import_table: null | {
    id: number;
    sheet_name: string;
    column_start: string;
    column_end: string;
    row_start: number;
    row_end: number;
  };
}

export type ITableForList = Omit<ITable, "rows">;

export type TableUpdateData =
  | { name: string }
  | { rows: Array<Array<ITableCell>> }
  | {
      name: string;
      rows: Array<Array<ITableCell>>;
    };

export const useTables = ({ limit = 10 }: { limit?: number }) =>
  usePaginatedFetch<ITableForList>({
    url: `v2/tables/list/`,
    params: { limit },
  });

export const tableCreate = ({
  name,
  rows,
}: {
  name: string;
  rows: Array<Array<ITableCell>>;
}) =>
  post<{ id: number }>(`${BASE_URL}v2/tables/create/`, {
    name,
    rows,
  });

export const useTableRetrieve = ({
  tableId,
  enabled = true,
}: {
  tableId: number;
  enabled?: boolean;
}) => useFetch<ITable>(enabled ? `v2/tables/${tableId}/retrieve/` : undefined);

export const tableDelete = ({ tableId }: { tableId: number }) =>
  deleteCall(`${BASE_URL}v2/tables/${tableId}/delete/`);

export const tableUpdate = ({
  tableId,
  data,
}: {
  tableId: number;
  data: TableUpdateData;
}) => post(`${BASE_URL}v2/tables/${tableId}/update/`, data);

export const useTableForCompare = ({
  tableId,
  enabled,
}: {
  tableId: number | null;
  enabled: boolean;
}) =>
  useFetch<ITable>(
    enabled && !_.isNull(tableId)
      ? `v2/tables/${tableId}/for-compare/retrieve/`
      : undefined
  );

export interface ITableList {
  id: number;
  name: string;
  deal: {
    id: number;
    name: string;
  } | null;
}

export const useAllTables = ({ name }: { name: string }) =>
  useFetch<Array<ITableList>>(
    `v2/tables/list/all/${formatGetParams({ name })}`
  );

export const getGroupedColumns = ({ tableIds }: { tableIds: Array<number> }) =>
  get<{ table_headers: Array<Array<string>> }>(
    `${BASE_URL}v2/tables/grouped-columns/${formatGetParams({ table_ids: tableIds })}`
  );

export const compareTables = ({
  tableIds,
  groupedColumns,
}: {
  tableIds: Array<number>;
  groupedColumns: Array<Array<string>>;
}) =>
  post<{ headers: Array<string>; rows: Array<Array<string>> }>(
    `${BASE_URL}v2/tables/compare/`,
    {
      table_ids: tableIds,
      grouped_columns: groupedColumns,
    }
  );

export interface ITableResponseList {
  id: number;
  status: ETableResponseStatus;
  created_by: { first_name: string; last_name: string };
  created_at: string;
  updated_by: { first_name: string; last_name: string } | null;
  updated_at: string;
  table_type: string;
}
export interface ITableResponse {
  id: number;
  status: ETableResponseStatus;
  created_by: { first_name: string; last_name: string };
  created_at: string;
  updated_by: { first_name: string; last_name: string } | null;
  updated_at: string;
  table_type: string;
  formatted_table_data: Array<Array<string>>;
  selected_tables: Array<ITable>;
}

export const reportTableGenerate = ({
  tableIds,
  reportType,
}: {
  tableIds: Array<number>;
  reportType: ETableReportType;
}) =>
  post(`${BASE_URL}v2/tables/report/generate/`, {
    table_ids: tableIds,
    report_type: reportType,
  });

/**
 * Creates a new Table object with the provided name and rows
 * and links it to the OpenAI table response object with the provided ID
 */
export const reportTableCreate = ({
  tableResponseId,
  name,
  rows,
}: {
  tableResponseId: number;
  name: string;
  rows: Array<Array<ITableCell>>;
}) =>
  post<{ id: number }>(`${BASE_URL}v2/tables/report/create/`, {
    openai_table_response_id: tableResponseId,
    name,
    rows,
  });

export const useTableResponses = ({ limit = 10 }: { limit?: number }) =>
  usePaginatedFetch<ITableResponseList>({
    url: `v2/tables/openai-table-response/list/`,
    params: { limit },
  });

export const useTableResponse = ({
  tableResponseId,
}: {
  tableResponseId: number;
}) =>
  useFetch<ITableResponse>(
    `v2/tables/openai-table-response/${tableResponseId}/retrieve/`
  );

export const tableImportCreate = ({
  values,
  dealId,
}: {
  values: {
    table_name: string;
    sheet_name: string;
    column_start: string;
    column_end: string;
    row_start: number;
    row_end: number;
  };
  dealId?: number;
}) =>
  post(`${BASE_URL}v2/deal/deal-company-import-table/create/`, {
    ...values,
    deal_id: dealId,
  });

export const tableImportDelete = ({
  tableImportId,
}: {
  tableImportId: number;
}) =>
  post(`${BASE_URL}v2/deal/deal-company-import-table/${tableImportId}/delete/`);

export const tableImportImportFromExcel = ({
  tableImportId,
  fileId,
}: {
  tableImportId: number;
  fileId: number;
}) =>
  post(
    `${BASE_URL}v2/deal/deal-company-import-table/${tableImportId}/import-from-excel/`,
    {
      file_id: fileId,
    }
  );

export const tableImportUpdate = ({
  tableImportId,
  values,
}: {
  tableImportId: number;
  values: {
    sheet_name: string;
    column_start: string;
    column_end: string;
    row_start: number;
    row_end: number;
  };
}) =>
  post(
    `${BASE_URL}v2/deal/deal-company-import-table/${tableImportId}/update/`,
    values
  );

import { FieldType } from "entities/Field/sdk";

import { EPartialUserAccessType } from "./sdk";

export enum DueDiligenceShareableType {
  CATEGORY = "category",
  SECTION = "section",
  FIELD = "field",
}

export enum AccessType {
  READ_ONLY_ACCESS = "Read only access",
  READ_WRITE_ACCESS = "Read/Write access",
}

export enum ImportPdfStatus {
  COMPLETED = "Completed",
  FAILED = "Failed",
  IN_PROGRESS = "In Progress",
}

export const FIELD_MESSAGE_INFO_NOT_EDITABLE =
  "This field is not editable, because it does not match any field from the PDF";

export const ACCESS_TYPE_OPTIONS = [
  {
    label: "Read only",
    value: EPartialUserAccessType.READ_ONLY,
  },
  {
    label: "Edit access",
    value: EPartialUserAccessType.READ_WRITE_ACCESS,
  },
];

export const DUE_DILIGENCE_SHAREABLE_TYPE_OPTIONS = [
  FieldType.TEXT_FIELD,
  FieldType.RICH_TEXT_FIELD,
  FieldType.RADIO_BUTTON,
  FieldType.CHECKBOX,
  FieldType.DROPDOWN,
  FieldType.FILE,
  FieldType.TABLE,
  FieldType.FIXED_TABLE,
  FieldType.FORM_TEXT_FIELD,
];

export const DUE_DILIGENCE_INTAKE_TYPE_OPTIONS = [
  FieldType.TEXT_FIELD,
  FieldType.RICH_TEXT_FIELD,
  FieldType.RADIO_BUTTON,
  FieldType.CHECKBOX,
  FieldType.DROPDOWN,
  FieldType.FILE,
  FieldType.TABLE,
  FieldType.FIXED_TABLE,
  FieldType.FORM_TEXT_FIELD,
];

import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

const CustomerDetailsSkeleton = () => (
  <Stack spacing={10}>
    <Stack spacing={1}>
      <Skeleton variant="text" width={200} height={33} />
      <Stack>
        {_.range(4).map((index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.blue20}`}
            padding={theme.spacing(0.5, 0)}
          >
            <Skeleton variant="text" width={300} height={28} />
            <Skeleton variant="text" width={300} height={38} />
          </Stack>
        ))}
      </Stack>
    </Stack>
    <Stack spacing={1}>
      <Skeleton variant="text" width={200} height={33} />
      <Stack>
        {_.range(2).map((index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.blue20}`}
            padding={theme.spacing(0.5, 0)}
          >
            <Skeleton variant="text" width={50} height={28} />
            <Skeleton variant="text" width={50} height={38} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  </Stack>
);

export default CustomerDetailsSkeleton;

import { memo, useCallback, useEffect, useState } from "react";
import _ from "lodash";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import ShareDialog from "entities/Package/components/ShareDialog";

interface IShareWithButton {
  initiallySelectedItemIds: Array<number>;
  additionalOnCloseAction?: () => void;
}

const ShareWithButton = ({
  initiallySelectedItemIds,
  additionalOnCloseAction,
}: IShareWithButton) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => {
    setIsOpen(false);
    if (!_.isNil(additionalOnCloseAction)) {
      additionalOnCloseAction();
    }
  }, [additionalOnCloseAction]);

  useEffect(() => {
    if (isOpen && !_.isNil(additionalOnCloseAction)) {
      return () => {
        additionalOnCloseAction();
      };
    }
  }, [isOpen, additionalOnCloseAction]);

  return (
    <>
      <MenuItem onClick={open} dataTestid="action-menu-option">
        <Text variant="text2">Share checklist with...</Text>
      </MenuItem>

      {isOpen && (
        <ShareDialog
          open
          handleClose={close}
          initiallySelectedItemIds={initiallySelectedItemIds}
        />
      )}
    </>
  );
};

export default memo(ShareWithButton);

import _ from "lodash";

import DealNameCell from "components/ScrollableTable/Cells/DealNameCell";
import {
  DEAL_NAME,
  DEAL_NAME_COLUMN,
} from "components/ScrollableTable/Cells/DealNameCell/constants";
import TextCell from "components/ScrollableTable/Cells/TextCell";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Tag from "components/Tag";

import { OwnershipType } from "entities/Deal/constants";
import {
  getConstructionTypeLabelByValue,
  getPropertyTypeLabelByValue,
} from "entities/Deal/utils";
import {
  ASSET_TYPE_COLUMN_KEY,
  CONSTRUCTION_TYPE_COLUMN_KEY,
  EFieldFormatType,
  OWNERSHIP_TYPE_COLUMN_KEY,
  PROPERTY_TYPE_COLUMN_KEY,
} from "entities/Reporting/constants";
import { INonPipelineColumns } from "entities/Reporting/sdk";

const fixedColumns = [
  {
    name: DEAL_NAME_COLUMN,
    children: [
      {
        name: DEAL_NAME,
        field: "",
        field_width: 300,
        field_format_type: EFieldFormatType.STRING,
        field_type: "",
      },
    ],
  },
];

const getHeaders = (
  columns: {
    children: {
      name: string;
      field: string;
      field_type?: string;
      field_width: number;
      field_format_type: EFieldFormatType;
    }[];
    name: string;
  }[]
): IScrollableTableHeader => {
  // First we remove the columns that don't have children attached
  return columns
    .filter((col) => col.children.length > 0)
    .map((col, index) => {
      const sticky = index === 0;

      return {
        label: col.name,
        sticky,
        children: col.children.map((colChild, index) => ({
          label: colChild.name,
          key: colChild.field_type ? colChild.field_type : colChild.name,
          width: `${colChild.field_width || 148}px`,
          left: sticky ? 0 : undefined,
          sortable: true,
          render: ({ row, key }) => {
            const cellValue = row[key];

            if (key === DEAL_NAME && col.name === DEAL_NAME_COLUMN) {
              return <DealNameCell row={row} />;
            } else if (key.toLowerCase() === OWNERSHIP_TYPE_COLUMN_KEY) {
              const userCompanyIsOwner = row[key] === OwnershipType.OWNER;

              return (
                <Tag
                  label={row[key]}
                  sx={{ display: "inline-block" }}
                  variant={userCompanyIsOwner ? "gray100" : "gray60"}
                />
              );
            } else if (key.toLowerCase() === CONSTRUCTION_TYPE_COLUMN_KEY) {
              const formattedConstructionType = getConstructionTypeLabelByValue(
                { value: cellValue }
              );

              return (
                <TextCell
                  cellData={formattedConstructionType}
                  title={formattedConstructionType}
                  fieldFormatType={colChild.field_format_type}
                />
              );
            } else if (
              key.toLocaleLowerCase() === PROPERTY_TYPE_COLUMN_KEY ||
              key === ASSET_TYPE_COLUMN_KEY
            ) {
              const formattedPropertyType = getPropertyTypeLabelByValue({
                value: row[key].trim(),
              });

              return (
                <TextCell
                  cellData={formattedPropertyType}
                  title={formattedPropertyType}
                  fieldFormatType={colChild.field_format_type}
                />
              );
            } else {
              return (
                <TextCell
                  cellData={cellValue}
                  title={cellValue}
                  fieldFormatType={colChild.field_format_type}
                />
              );
            }
          },
        })),
      };
    });
};

const JSON_FIELD_NAMES_TO_REPORT_HEADER_NAMES_MAPPING = {
  Acquisition_Cost: "Acquisition Cost",
  Total_Hard_Costs: "Hard Costs",
  Total_Hard_Cost_Contingency: "Hard Cost Contingency",
  Total_Soft_Costs: "Soft Costs",
  Total_Soft_Cost_Contingency: "Soft Cost Contingency",
  Developer_Fees: "Developer Fee",
  "Total Cost of Project": "",
  "Total Cost Of Project/S.F.": "Total Cost Of Project / SF",
  "Total Hard Costs /S.F.": "Total Hard Costs / SF",
  "Total Soft Costs /S.F.": "Total Soft Costs / SF",
  "Construction Time Period (Months)": "Construction Period",
  "Lease-Up Period (Months)": "Lease Up Period",
  "Net Operating Income (NOI)": "Net Operation Income",
  "Other Income Cable TV": "Cable TV",
  "Utilities Cable TV": "Cable TV",
};

export const getCorrectHeaderValue = (headerName: string) =>
  _.get(
    JSON_FIELD_NAMES_TO_REPORT_HEADER_NAMES_MAPPING,
    headerName,
    headerName
  );

export const getFilteredColumns = ({
  columns,
  tableSettingsFilter,
}: {
  columns: INonPipelineColumns[];
  tableSettingsFilter?: string[];
}) => {
  if (!tableSettingsFilter) {
    return [
      ...fixedColumns,
      ...columns.map((col) => {
        return {
          ...col,
          children: col.report_fields,
        };
      }),
    ];
  }
  return [
    ...fixedColumns,
    ...columns
      .filter((col) => tableSettingsFilter.indexOf(col.name) >= 0)
      .map((col) => {
        return {
          ...col,
          children: col.report_fields,
        };
      }),
  ];
};

export const getBasedHeaders = (filteredColumns: any) =>
  getHeaders(filteredColumns).map((column) => {
    const readableReportFields = column.children.map((child) => {
      return {
        ...child,
        label: getCorrectHeaderValue(child.label),
      };
    });
    return {
      ...column,
      children: readableReportFields,
    };
  });

import { useCallback, useEffect, useState } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { copyTextToClipboard } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions } from "components/Dialog";
import { Link } from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import {
  createShareableMemoLink,
  expireShareableMemoLink,
  useShareableMemoLink,
} from "entities/Memo/sdk";

export interface IShareableLinkPopup {
  dealId: number;
  memoId: number;
  open: boolean;
  onClose: () => void;
}

const ShareableLinkPopup = ({
  dealId,
  memoId,
  open,
  onClose,
}: IShareableLinkPopup) => {
  const [sharableLink, setShareableLink] = useState("");
  const { show: showConfirmationDialog } = useConfirmationDialog();
  const { data: shareableMemoLink } = useShareableMemoLink({
    dealId,
    memoId,
    enabled: open,
  });

  useEffect(() => {
    shareableMemoLink?.url
      ? setShareableLink(shareableMemoLink?.url)
      : setShareableLink("");
  }, [shareableMemoLink]);

  const handleClickGetShareableMemoLink = useCallback(
    () =>
      createShareableMemoLink(dealId, memoId).then((res) => {
        setShareableLink(res.url);
        copyTextToClipboard({ text: res.url });
        toast.successMessage("Link copied to the clipboard");
        onClose();
      }),
    [dealId, memoId, onClose]
  );

  const handleCopyLink = useCallback(() => {
    copyTextToClipboard({ text: sharableLink });
    toast.successMessage("Link copied to the clipboard");
    onClose();
  }, [sharableLink, onClose]);

  const expireLink = useCallback(
    () =>
      expireShareableMemoLink(dealId, memoId).then((res) => {
        toast.successMessage("Link expired");
        setShareableLink("");
        onClose();
      }),
    [dealId, memoId, onClose]
  );

  const handleClickExpireShareableMemoLink = useCallback(() => {
    showConfirmationDialog({
      onConfirm: expireLink,
      message: "Are you sure you want to expire existing links?",
      confirmButtonText: "Yes",
      confirmButtonColor: "primary",
    });
  }, [showConfirmationDialog, expireLink]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <Box sx={{ padding: theme.spacing(2) }}>
        <Stack
          gap="6px"
          sx={{
            backgroundColor: colors.blue10,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
          }}
        >
          <Text variant="h3">Share via URL link</Text>
          <Text variant="text3" color={colors.gray80}>
            Anyone with the link will be able to view this memo (public access,
            view only).
          </Text>
        </Stack>
        <Stack sx={{ padding: theme.spacing(2, 2, 3) }}>
          {sharableLink && (
            <Link
              style={{
                textDecoration: "underline",
                color: colors.blue100,
                fontWeight: 500,
                wordBreak: "break-all",
              }}
              to={sharableLink}
              external
              target="_blank"
            >
              {sharableLink}
            </Link>
          )}
          {!sharableLink && (
            <Text variant="text3" color={colors.gray60}>
              Currently this document is not shared. There is no active share
              link.
            </Text>
          )}
        </Stack>
        <DialogActions sx={{ padding: 0 }}>
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
          {!sharableLink && (
            <Button onClick={handleClickGetShareableMemoLink}>
              Create share link
            </Button>
          )}
          {sharableLink && (
            <>
              <Button
                onClick={handleClickExpireShareableMemoLink}
                color="secondary"
              >
                Expire this link
              </Button>
              <Button onClick={handleCopyLink}>Copy link to clipboard</Button>
            </>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ShareableLinkPopup;

import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { MoreVertIcon } from "icons";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import ShareWithButton from "entities/Package/components/ShareWithButton";
import {
  fieldApproveBulk,
  fieldUpdateNAStatusBulk,
  IPackageCategory,
  sectionApproveBulk,
  toggleCategoryApproval,
} from "entities/Package/sdk";
import DueDiligenceCategoryNewTaskButton from "entities/Task/components/DueDiligenceCategoryNewTaskButton";
import DueDiligenceCategoryOpenTaskButton from "entities/Task/components/DueDiligenceCategoryOpenTaskButton";
import { useTaskChannels } from "entities/Task/sdk";

interface IPackageCategoryMenuButton {
  category: IPackageCategory;
  packageId: number;
  dealId: number;
  areAllApproved?: boolean;
  areAllNA?: boolean;
  isShareablePage?: boolean;
  updatePackage: (message?: string) => void;
  menuButtonDataTestid?: string;
  hasFullAccess: boolean;
}

const PackageCategoryMenuButton: React.FC<IPackageCategoryMenuButton> = ({
  category,
  packageId,
  dealId,
  areAllApproved,
  areAllNA,
  isShareablePage,
  updatePackage,
  menuButtonDataTestid,
  hasFullAccess,
}) => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
    sectionId: string;
    fieldId: string;
  }>();

  const tabId = Number(params.tabId);
  const { data: taskChannels } = useTaskChannels({ dealId });
  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });
  const tasksForCategory = useMemo(
    () =>
      taskChannels?.filter(
        (taskChannel) =>
          taskChannel.task.company_category?.id === category.category_id &&
          taskChannel.task.company_section === null &&
          taskChannel.task.company_field === null
      ) || [],
    [category, taskChannels]
  );

  const getSectionAndFieldIds = useCallback(() => {
    const fieldIds: number[] = [];
    const sectionIds: number[] = [];

    category.sections.forEach((section) => {
      sectionIds.push(section.section_id);
      section.fields.forEach(({ field_id }) => fieldIds.push(field_id));
    });

    return { sectionIds, fieldIds };
  }, [category]);

  const handleCategoryApprovalAll = useCallback(() => {
    const { sectionIds, fieldIds } = getSectionAndFieldIds();

    fieldApproveBulk({
      dealId,
      fieldIds,
      isApproved: !areAllApproved,
    })
      .then(() => {
        return sectionApproveBulk({
          dealId,
          sectionIds,
          isApproved: !areAllApproved,
        }).then(() => {
          return toggleCategoryApproval({
            dealId,
            categoryId: category.category_id,
            isCompleted: !areAllApproved,
          }).then(() => {
            updatePackage(
              `${category?.name} was successfully ${
                !areAllApproved ? "completed" : "uncompleted"
              }!`
            );
          });
        });
      })
      .catch(() => {
        toast.errorMessage(DEFAULT_ERROR_MESSAGE);
      });
  }, [dealId, category, areAllApproved, getSectionAndFieldIds, updatePackage]);

  const handleMarkAsNA = useCallback(() => {
    const { fieldIds } = getSectionAndFieldIds();

    fieldUpdateNAStatusBulk({
      dealId,
      fieldIds,
      naStatus: !areAllNA,
    }).then(() => {
      updatePackage(
        `${category?.name} was successfully ${
          !areAllNA ? "disabled" : "enabled"
        }!`
      );
    });
  }, [dealId, category, areAllNA, getSectionAndFieldIds, updatePackage]);

  return (
    <Stack flexDirection="row">
      {tasksForCategory.length > 0 && (
        <DueDiligenceCategoryOpenTaskButton
          taskChannels={tasksForCategory}
          category={category}
          dealId={dealId}
          tabId={tabId}
          packageId={packageId}
          updatePackage={updatePackage}
          taskButtonDataTestid="dd-open-task-button"
          taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
        />
      )}
      {hasFullAccess && (
        <ButtonDropdown
          variant="text"
          size="small"
          label={<MoreVertIcon />}
          width="auto"
          sx={{ padding: "2px" }}
          keepMenuMounted
          buttonDataTestid={menuButtonDataTestid}
        >
          {!isShareablePage && (
            <span>
              <GroupLabel label="status" />
              <MenuItem
                onClick={handleMarkAsNA}
                dataTestid="action-menu-option"
              >
                <Text variant="text2" data-testid="action-menu-option-name">
                  {areAllNA ? "Revoke N/A status" : "Mark all as N/A"}
                </Text>
              </MenuItem>

              <MenuItem
                disabled={areAllNA}
                onClick={handleCategoryApprovalAll}
                dataTestid="action-menu-option"
              >
                <Text variant="text2" data-testid="action-menu-option-name">
                  {areAllApproved ? "Revoke done status" : "Mark all as done"}
                </Text>
              </MenuItem>
            </span>
          )}
          {loggedUserIsDealAdmin && (
            <span>
              <GroupLabel label="share settings" />
              <ShareWithButton
                initiallySelectedItemIds={[category.category_id]}
              />
            </span>
          )}

          <GroupLabel label="Tasks" />
          <DueDiligenceCategoryNewTaskButton
            category={category}
            packageId={packageId}
            tabId={tabId}
            updatePackage={updatePackage}
            menuOptionDataTestid="action-menu-option"
          />
        </ButtonDropdown>
      )}
    </Stack>
  );
};

export default PackageCategoryMenuButton;

import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";

import NoContent from "entities/Deal/components/OverviewSections/NoContent";

interface IAmenityTable {
  header: string;
  data: any[];
  categoryTableDataTestid?: string;
}

const AmenityTable: React.FC<IAmenityTable> = ({
  header,
  data,
  categoryTableDataTestid,
}) => (
  <Table size="small" data-testid={categoryTableDataTestid}>
    <TableHead>
      <TableRow
        sx={{
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${colors.blue40}`,
          },
        }}
      >
        <TableCell
          sx={{
            paddingLeft: theme.spacing(1),
          }}
        >
          <Text
            variant="labelSmall"
            sx={{ color: colors.gray100 }}
            data-testid="category-table-header-name"
          >
            {header}
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody
      data-testid={`${header
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll("&", "and")}-table-data`}
      data-test-id="category-table-body"
    >
      {!_.isEmpty(data) &&
        data.map((value) => (
          <TableRow
            key={value}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue40}`,
              },
            }}
          >
            <TableCell
              sx={{
                paddingLeft: theme.spacing(1),
              }}
              data-testid="option-selected"
            >
              <Text variant="text2">• {value}</Text>
            </TableCell>
          </TableRow>
        ))}
      {_.isEmpty(data) && (
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
          data-testid="empty-table-body"
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <NoContent />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
);

export default AmenityTable;

import { useCallback, useEffect, useState } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import FormControlLabel from "components/FormControlLabel";
import Paper from "components/Paper";
import Radio, { RadioGroup } from "components/Radio";
import Text from "components/Text";

import { CompanyCustomFieldSyncingType } from "entities/Field/constants";

import { SYNCING_OPTIONS } from "./constants";

export interface ISyncingConfirmationDialog {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: ({
    syncingType,
  }: {
    syncingType: CompanyCustomFieldSyncingType;
  }) => void;
}

const SyncingConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: ISyncingConfirmationDialog) => {
  const [selectedOption, setSelectedOption] = useState(
    SYNCING_OPTIONS[0].value
  );

  useEffect(() => setSelectedOption(SYNCING_OPTIONS[0].value), [isOpen]);

  const handleRadioClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedOption(event.target.value as CompanyCustomFieldSyncingType);
    },
    []
  );
  return (
    <Dialog open={isOpen} maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ padding: theme.spacing(2) }}>
        <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
          <Text variant="h2">Re-enable connections</Text>
          <Text variant="text2" color={colors.gray80}>
            This would re-introduce syncing the values across all fields
            connected to this current one. Before re-enabling it, you must
            select which location's value should be the source of truth that
            gets transferred across and synced.
          </Text>
        </Paper>
      </DialogTitle>
      <DialogContent sx={{ padding: theme.spacing(2) }}>
        <RadioGroup
          value={selectedOption}
          onChange={handleRadioClick}
          sx={{ margin: theme.spacing(1.5) }}
        >
          {SYNCING_OPTIONS.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={
                <Text variant="text2">
                  <b>{option.label}:</b> {option.subTitle}
                </Text>
              }
              sx={{ marginBottom: theme.spacing(1) }}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Button
          variant="text"
          onClick={onClose}
          data-testid="cancel-action-button"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-testid="confirm-action-button"
          onClick={() =>
            onConfirm({
              syncingType: selectedOption,
            })
          }
        >
          Apply value override
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncingConfirmationDialog;

import { useCallback, useEffect, useState } from "react";

import { colors } from "theme/palette";
import { format, parseJSON } from "utils/datetime";
import { downloadFileWithFileName } from "utils/file";
import PusherManager from "utils/pusher-manager";

import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import FileTree from "entities/DDPackageZip/components/FileTree";
import ZipList from "entities/DDPackageZip/components/ZipList";
import {
  getZipDetail,
  IZipList,
  startCreatingZipFile,
  useZipList,
} from "entities/DDPackageZip/sdk";

export interface IZipDetails {
  dealId: number;
  packageId: number;
}

const ZipDetails = ({ dealId, packageId }: IZipDetails) => {
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

  const [latestZip, setLatestZip] = useState<undefined | IZipList>(undefined);

  const { data: zipList, mutate: updateZipList } = useZipList({
    dealId,
    packageId,
  });

  useEffect(() => {
    if (zipList) {
      setLatestZip(
        zipList.results.find((zip: IZipList) => zip.finished_at && !zip.failed)
      );
    }
  }, [zipList]);

  const handleUpdateClick = useCallback(() => {
    setIsUpdateButtonDisabled(true);

    startCreatingZipFile({ dealId, packageId }).then(() => updateZipList());
  }, [dealId, packageId, updateZipList]);

  const handleDownloadClick = (zipId: number) => {
    getZipDetail({ zipId }).then((response) =>
      downloadFileWithFileName({
        fileUrl: response.url,
        fileName: response.name,
      })
    );
  };

  const { data: user } = useSignedUser();

  const eventHandler = useCallback(() => {
    updateZipList();
    setIsUpdateButtonDisabled(false);
  }, [updateZipList]);

  useEffect(() => {
    if (user) {
      return PusherManager.bindToZipReadyEvent({
        userId: user.id,
        eventHandler,
      });
    }
  }, [eventHandler, user]);

  useEffect(() => {
    if (user) {
      return PusherManager.bindToZipFailedEvent({
        userId: user.id,
        eventHandler,
      });
    }
  }, [eventHandler, user]);

  return (
    <Stack spacing={4} data-testid="checklist-zip-details-content">
      <Stack spacing={2} overflow="auto" flex={1}>
        <Stack spacing={2}>
          <PageHeader
            title="Update before downloading to include recent files!"
            subTitle={
              <>
                {latestZip && (
                  <>
                    <Text
                      variant="text3"
                      component="span"
                      sx={{ color: colors.gray80 }}
                    >
                      Last updated by{" "}
                    </Text>
                    <Text
                      variant="text3"
                      component="span"
                      sx={{ fontWeight: 500, color: colors.gray80 }}
                      data-testid="updated-by-user-name"
                    >
                      {latestZip.created_by.first_name},{" "}
                    </Text>
                    <Text
                      variant="text3"
                      component="span"
                      sx={{ fontWeight: 500, color: colors.gray80 }}
                      data-testid="updated-date"
                    >
                      {format(
                        parseJSON(latestZip.created_at),
                        "MMM dd, yyyy, hh:mm a"
                      )}
                    </Text>
                  </>
                )}
              </>
            }
            actions={
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  onClick={handleUpdateClick}
                  data-testid="create-update-zip-button"
                  disabled={isUpdateButtonDisabled}
                >
                  {latestZip ? "Update" : "Create"}
                </Button>
                {latestZip && (
                  <Button
                    onClick={() => handleDownloadClick(latestZip.id)}
                    data-testid="latest-zip-download-button"
                  >
                    Download ZIP
                  </Button>
                )}
              </Stack>
            }
          />
          <Stack
            direction="row"
            spacing={2}
            alignItems="stretch"
            data-testid="zip-page-content-body"
          >
            <FileTree dealId={dealId} packageId={packageId} />
            <ZipList dealId={dealId} packageId={packageId} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ZipDetails;

import React from "react";
import MuiBackdrop, { BackdropProps } from "@mui/material/Backdrop";

export interface IBackdrop extends BackdropProps {
  open: boolean;
}

const Backdrop: React.FC<IBackdrop> = ({ open, ...props }) => {
  return (
    <MuiBackdrop
      open={open}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      {...props}
    />
  );
};

export default Backdrop;

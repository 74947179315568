import { useCallback } from "react";
import _ from "lodash";

import { DeleteIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Button from "components/Button";
import ContentSeparator from "components/ContentSeparator";
import Image from "components/Image";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import NoContent from "entities/Deal/components/OverviewSections/NoContent";
import UploadImage from "entities/Deal/components/OverviewSections/UploadImage";
import UnitAmenitiesEditButton from "entities/Deal/components/OverviewUnitAmenities/UnitAmenitiesEditButton";
import UnitAmenitiesTable from "entities/Deal/components/OverviewUnitAmenities/UnitAmenitiesTable";
import {
  deleteUnitAmenitiesImage,
  updateUnitAmenities,
} from "entities/Deal/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import { IMemoSection } from "entities/Memo/sdk";
import { useDealUnitAmenitiesManager } from "entities/Memo/utils";

interface IOverviewUnitAmenities {
  dealId: number;
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}

const OverviewUnitAmenities: React.FC<IOverviewUnitAmenities> = ({
  dealId,
  externalToken,
  section,
  onUpdate,
}) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();
  const { data: dealUnitAmenities, mutate: mutateUnitAmenities } =
    useDealUnitAmenitiesManager({ dealId, token: externalToken });

  const handleUploadImage = useCallback(
    ({ imageId }: { imageId: number }) =>
      updateUnitAmenities({ dealId, imageId }).then(() => {
        mutateUnitAmenities();
        toast.successMessage("Unit amenities image successfully uploaded!");
      }),
    [mutateUnitAmenities, dealId]
  );

  const onDeleteImage = useCallback(
    () =>
      deleteUnitAmenitiesImage(dealId).then(() => {
        toast.successMessage("Unit amenities image successfully deleted!");
        mutateUnitAmenities();
      }),
    [dealId, mutateUnitAmenities]
  );

  const handleDeleteImage = useCallback(
    () =>
      showConfirmationDialog({
        onConfirm: () => onDeleteImage(),
        message: "Are you sure you want to delete this image?",
      }),
    [showConfirmationDialog, onDeleteImage]
  );

  if (!dealUnitAmenities) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Text variant="h2" data-testid="section-header-name">
          Unit Amenities
        </Text>
        {_.isUndefined(externalToken) && (
          <NoPrint>
            <Stack direction="row" spacing={1} alignItems="center">
              {!section?.is_na && <UnitAmenitiesEditButton />}
              {section && (
                <MemoSectionContextButton
                  dealId={dealId}
                  section={section}
                  onUpdate={onUpdate}
                />
              )}
            </Stack>
          </NoPrint>
        )}
      </Stack>
      {section?.is_na ? (
        <NASection />
      ) : (
        <>
          <ContentSeparator label="Unit Amenities" />
          <UnitAmenitiesTable
            unit_amenities_data={dealUnitAmenities}
            tableDataTestid="unit-amenities-categories-tables"
          />
          {!dealUnitAmenities.image && _.isUndefined(externalToken) && (
            <NoPrint>
              <UploadImage
                onChange={handleUploadImage}
                dataTestid="upload-unit-amenities-input"
              />
            </NoPrint>
          )}
          {dealUnitAmenities.image && (
            <Stack spacing={2}>
              <Image
                src={dealUnitAmenities.image}
                imgix
                imgixHeight={920}
                sx={{ height: "460px", width: "100%", objectFit: "cover" }}
                imageDataTestid="uploaded-unit-amenities-image"
              />
              {_.isUndefined(externalToken) && (
                <NoPrint
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    sx={{ alignSelf: "flex-end" }}
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteImage}
                    data-testid="delete-unit-amenities-image-button"
                  >
                    Delete image
                  </Button>
                </NoPrint>
              )}
            </Stack>
          )}
          <ContentSeparator label="Unit Amenities Description" />
          <Box
            sx={{
              paddingLeft: theme.spacing(1),
            }}
            data-testid="unit-amenities-description"
          >
            {_.isEmpty(dealUnitAmenities.description) ? (
              <NoContent />
            ) : (
              <SanitizeHtml
                html={dealUnitAmenities.description}
                dataTestid="unit-amenities-description-added"
              />
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default OverviewUnitAmenities;

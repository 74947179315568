import { useCallback, useMemo } from "react";
import _ from "lodash";

import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

import { IPublicChecklist } from "entities/Package/sdk";

export interface IPublicChecklistRecord {
  checklist: IPublicChecklist;
  onClick: ({ checklist }: { checklist: IPublicChecklist }) => void;
  index: number;
  isSelected?: boolean;
}

const PublicChecklistRecord = ({
  index,
  checklist,
  onClick,
  isSelected,
}: IPublicChecklistRecord) => {
  const isSelectable = useMemo(() => !_.isNil(onClick), [onClick]);

  const handleSelect = useCallback(() => {
    onClick({ checklist });
  }, [checklist, onClick]);

  return (
    <Stack
      key={index}
      direction="row"
      sx={{
        backgroundColor: isSelected ? colors.blue10 : "transparent",
        borderRadius: "4px",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: colors.blue10,
          cursor: isSelectable ? "pointer" : "default",
        },
      }}
      height="48px"
      alignItems="center"
      onClick={handleSelect}
    >
      <Stack paddingX={1.5}>
        <Text variant="text2" fontWeight={700}>
          {checklist.name}
        </Text>
        <Stack direction="row" spacing={1} alignItems="center">
          <Text variant="text4" color={colors.gray80}>
            by <b>{checklist.company.name}</b>
          </Text>
          <Text variant="text4" color={colors.gray80}>
            •
          </Text>
          <Text variant="text4" color={colors.gray80}>
            {checklist.fields_count} items
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PublicChecklistRecord;

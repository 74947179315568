import React from "react";

import CellSkeleton from "components/SimpleTable/CellSkeleton";
import { TableBody, TableRow } from "components/Table";

import { ISimpleTable } from "..";

interface IBodySkeleton {
  headers: ISimpleTable["headers"];
}

const BodySkeleton: React.FC<IBodySkeleton> = ({ headers }) => {
  return (
    <TableBody>
      {[1, 2, 3].map((index) => (
        <TableRow key={index}>
          {headers.map((header) => (
            <CellSkeleton key={header.key} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default BodySkeleton;

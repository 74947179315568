import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { toImgixUrl } from "utils/urls";

import Avatar from "components/Avatar";

import placeholderIcon from "./assets/default.png";

export interface IDealAvatar
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  imgix?: boolean;
  sx?: SxProps<Theme>;
  imgixWidth?: number;
  imgixHeight?: number;
}

const DealAvatar = ({
  src,
  imgix = false,
  imgixWidth,
  imgixHeight,
  sx,
}: IDealAvatar) => {
  const imgixUrl = useMemo(() => {
    if (imgix) {
      return src ? toImgixUrl(src) + `?&w=${imgixWidth}&h=${imgixHeight}` : src;
    }
    return src;
  }, [src, imgix, imgixWidth, imgixHeight]);

  return (
    <Avatar
      src={imgixUrl || placeholderIcon}
      variant="rounded"
      sx={sx}
      data-testid={
        imgixUrl ? "deal-custom-avatar-image" : "deal-default-image-cover"
      }
    />
  );
};

export default DealAvatar;

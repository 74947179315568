import { useMemo } from "react";

import theme from "theme";

import Stack from "components/Stack";
import Text from "components/Text";

import Category from "entities/Checklist/components/Category";
import { FieldWrapperType } from "entities/Checklist/constants";
import { ICompanyPackageDetails } from "entities/Package/sdk";

export interface INestedChecklistPreview {
  checklist: ICompanyPackageDetails;
}
const NestedChecklistPreview = ({ checklist }: INestedChecklistPreview) => {
  const filteredTabs = useMemo(
    () => checklist.tabs.filter((tab) => !tab.is_deleted),
    [checklist.tabs]
  );
  return (
    <Stack spacing={1}>
      {filteredTabs.map((tab) => (
        <Stack key={`tab-${tab.tab_id}`} spacing={1}>
          <Text
            variant="text1"
            fontWeight={700}
            sx={{ marginBottom: theme.spacing(1.5) }}
          >
            {tab.name}
          </Text>

          {tab.categories
            .filter((category) => !category.is_deleted)
            .map((category) => (
              <Category
                isCollapsible
                key={`tab-${tab.tab_id}-category-${category.category_id}`}
                category={category}
                fieldWrapperMeta={{}}
                fieldWrapperType={FieldWrapperType.CHECKLIST}
              />
            ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default NestedChecklistPreview;

import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined } from "utils/common";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import Stack from "components/Stack";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import ProformaUnixTable from "entities/Proforma/components/ProfromaUnixTable";
import { IRentalIncomeData } from "entities/Proforma/sdk";

export interface IProformaUnitTypeFloorArea {
  rentalIncomeData: IRentalIncomeData;
}

const unitMixAndFloorAreaHeaders: Array<{ key: string; name: string }> = [
  { key: "studio", name: "SRO/Studio" },
  { key: "bedroom_1", name: "1 BDR" },
  { key: "bedroom_2", name: "2 BDR" },
  { key: "bedroom_3", name: "3 BDR" },
  { key: "bedroom_4", name: "4 BDR" },
  { key: "total", name: "Total" },
];

const headersFloorArea: Array<{ key: string; name: string }> = [
  { key: "title", name: "RENTAL INCOME" },
  { key: "units", name: "NO. OF UNITS" },
  { key: "totalarea", name: "Total Area" },
];

const ProformaUnitTypeFloorArea: React.FC<IProformaUnitTypeFloorArea> = ({
  rentalIncomeData,
}) => {
  const unitMixAndFloorAreaRows = useMemo(
    () =>
      _.map(
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.data,
        (value, key) => {
          if (value.value !== 0)
            return {
              title: key,
              data: _.map(unitMixAndFloorAreaHeaders, (header) => ({
                key: header.key,
                value: {
                  sqf_area: value[header.key] ? value[header.key].sqf_area : "",
                  count:
                    header.key === "total"
                      ? value[header.key].count
                      : value[header.key]
                        ? value[header.key].numberofunits
                        : 0,
                },
              })),
            };
        }
      ).filter(isNotUndefined),
    [rentalIncomeData]
  );

  const getTotal = useMemo(() => {
    let total = 0;
    _.forEach(
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.data,
      (value, key) => {
        total += value.total.count;
      }
    );
    return total;
  }, [rentalIncomeData]);

  const tableUnixTotals = {
    title: "Total",
    data: _.map(unitMixAndFloorAreaHeaders, (header) => ({
      key: header.key,
      value:
        header.key === "total"
          ? getTotal
          : rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
              .misc.total[header.key] || 0,
    })),
  };

  const floorAreaRows = [
    {
      title: "Affordable Units",
      units:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.affordable_unit_count,
      totalarea: `${formatNumberToString({
        number:
          rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
            .total.affordable_total_area,
      })} sqf`,
    },
    {
      title: "Market Rate Units",
      units:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.market_rate_unit_count,
      totalarea: `${formatNumberToString({
        number:
          rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
            .total.market_rate_total_area,
      })} sqf`,
    },
    {
      title: "Super's Units",
      units:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.super_unit_count,
      totalarea: `${formatNumberToString({
        number:
          rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
            .total.super_unit_total_area,
      })} sqf`,
    },
  ];

  const floorAreaTotalArea = `${formatNumberToString({
    number:
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.affordable_total_area +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.market_rate_total_area +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.super_unit_total_area,
  })} sqf`;

  const tableTotals = {
    title: "Potential Residential Rent",
    units:
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.affordable_unit_count +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.market_rate_unit_count +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
        .total.super_unit_count,
    totalarea: floorAreaTotalArea,
  };

  const totalYearlyRent =
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc.total
      .affordable_total_area +
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc.total
      .market_rate_total_area +
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc.total
      .super_unit_total_area;

  const totals = [
    {
      label: "Total SQF Area",
      value: totalYearlyRent,
      type: ProformaHeaderTypeTotal.SQF,
    },
  ];

  return (
    <ProformaPaper
      title="Floor Area"
      subTitle="Floor Area per Unit Type"
      totals={totals}
    >
      <Box
        sx={{
          margin: theme.spacing(4, 0),
        }}
      >
        <ContentSeparator label="Unit Mix & Floor area" />

        <ProformaUnixTable
          rows={unitMixAndFloorAreaRows}
          headers={unitMixAndFloorAreaHeaders}
          totals={tableUnixTotals}
        />
      </Box>
      <Box
        sx={{
          margin: theme.spacing(4, 0),
        }}
      >
        <Box sx={{ marginBottom: theme.spacing(2) }}>
          <ContentSeparator label="Total floor area" />
        </Box>
        <Stack>
          <ProformaTable
            rows={floorAreaRows}
            headers={headersFloorArea}
            totals={tableTotals}
            lastCellWidth="120px"
          />
        </Stack>
      </Box>
    </ProformaPaper>
  );
};

export default ProformaUnitTypeFloorArea;

import { useCallback, useMemo, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { AIIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Paper from "components/Paper";
import Select from "components/Select";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import {
  IListOpenAIDocumentAssistant,
  IOpenAICustomPromptResponse,
  IOpenAICustomPromptResponseForMemoSection,
  useCustomPromptResponseListForMemoSection,
  useOpenAIDocumentAssistantList,
} from "entities/AISummaries/sdk";
import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import {
  IMemoSection,
  memoSectionAISummaryResponseCreateOrUpdate,
  memoSectionAISummaryResponseDelete,
} from "entities/Memo/sdk";

import { VALIDATION_SCHEMA } from "./constants";

export interface IUseAISummaryDialogButton {
  section: IMemoSection;
  dealId: number;
  onSelectAISummary: () => void;
  onDisconnect(): void;
}

interface IValues {
  documentAssistantId: number | undefined;
  promptId: number | undefined;
  selectedCustomResponse: IOpenAICustomPromptResponse | undefined;
}

const UseAISummaryDialogButton = ({
  section,
  dealId,
  onSelectAISummary,
  onDisconnect,
}: IUseAISummaryDialogButton) => {
  const [isOpen, setIsOpen] = useState(false);

  const initialValues: IValues = useMemo(() => {
    if (
      isNotUndefined(section.memo_section_openai_responses) &&
      isNotUndefined(_.first(section.memo_section_openai_responses))
    ) {
      return {
        documentAssistantId:
          section.memo_section_openai_responses[0].openai_custom_prompt_response
            ?.openai_document_assistant_id || undefined,
        promptId:
          section.memo_section_openai_responses[0].openai_custom_prompt_response
            ?.prompt_id || undefined,
        selectedCustomResponse:
          section.memo_section_openai_responses[0]
            .openai_custom_prompt_response || undefined,
      };
    }

    return {
      documentAssistantId: undefined,
      promptId: undefined,
      selectedCustomResponse: undefined,
    };
  }, [section]);

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleSubmit = useCallback(
    (values: IValues) => {
      const selectedCustomResponse = values.selectedCustomResponse;

      if (!_.isNil(selectedCustomResponse)) {
        memoSectionAISummaryResponseCreateOrUpdate({
          dealId,
          memoSectionId: section.section_id,
          openAICustomPromptResponseId: selectedCustomResponse.id,
          response: selectedCustomResponse.response,
        })
          .then(() => {
            setIsOpen(false);
            onSelectAISummary();
            toast.successMessage("AI Summary has been connected successfully!");
          })
          .catch(handleInvalidRequest);
      }
    },
    [dealId, onSelectAISummary, section]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { data: documentAssistants, loading: isLoadingDocumentAssistants } =
    useOpenAIDocumentAssistantList({
      dealId,
      enabled: isOpen,
    });

  const { data: customResponses, loading: isLoadingPrompts } =
    useCustomPromptResponseListForMemoSection({
      openAIDocumentAssistantId: formik.getFieldProps("documentAssistantId")
        .value,
      enabled: isOpen,
    });

  const documentAssistantOptions = useMemo(
    () =>
      documentAssistants?.map(
        (documentAssistant: IListOpenAIDocumentAssistant) => ({
          label: `${documentAssistant.openai_files.map(({ file }) => file.original_file_name).join(", ")} - ${documentAssistant.openai_prompt_package?.name}`,
          value: documentAssistant.id.toString(),
        })
      ) || [],
    [documentAssistants]
  );

  const promptOptions = useMemo(
    () =>
      customResponses?.map(
        (response: IOpenAICustomPromptResponseForMemoSection) => ({
          label: response.prompt.readable_question,
          value: response.prompt.id.toString(),
        })
      ) || [],
    [customResponses]
  );

  const handleClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.AI_SUMMARY,
    callback: () => setIsOpen(true),
  });

  const handleAssistantChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      const assistantId = Number(event.target.value);
      formik.setFieldValue("documentAssistantId", assistantId);
      formik.setFieldValue("promptId", null);
      formik.setFieldValue("selectedCustomResponse", undefined);
    },
    [formik]
  );

  const handlePromptChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      const promptId = Number(event.target.value);
      formik.setFieldValue("promptId", promptId);

      const selectedCustomResponse = _.find(
        customResponses,
        (response) => response.prompt.id === promptId
      );
      formik.setFieldValue("selectedCustomResponse", selectedCustomResponse);
    },
    [formik, customResponses]
  );

  const handleClose = useCallback(() => {
    formik.resetForm();
    setIsOpen(false);
  }, [formik]);

  const isSubmitButtonDisabled = useMemo(() => formik.isSubmitting, [formik]);
  const showDisconnectedButton = useMemo(
    () =>
      !_.isNil(
        _.first(section.memo_section_openai_responses)
          ?.openai_custom_prompt_response
      ),
    [section]
  );

  const handleCreate = useCallback(() => {
    formik.setFieldTouched("documentAssistantId", true);
    formik.setFieldTouched("promptId", true);

    formik.submitForm();
  }, [formik]);

  const handleDisconnectAISummary = useCallback(() => {
    showConfirmationDialog({
      onConfirm: () =>
        memoSectionAISummaryResponseDelete({
          dealId,
          memoSectionId: section.section_id,
        })
          .then(() => {
            onDisconnect();
            setIsOpen(false);
            toast.successMessage("Section has been disconnected successfully.");
          })
          .catch(handleInvalidRequest),
      confirmButtonText: "Disconnect",
      message: (
        <>
          <Text variant="h2" marginBottom={1}>
            Disconnect this memo section from AI Summary
          </Text>
          <Text variant="text2">
            Disconnecting the memo section from the AI Summary custom response
            will only remove the content of this section. It will not affect
            other sections connected to this AI Summary custom response or the
            particular AI Summary custom response. Are you sure you want to
            proceed?
          </Text>
        </>
      ),
    });
  }, [showConfirmationDialog, onDisconnect, dealId, section]);

  const buttonLabel = useMemo(() => {
    if (
      !_.isNil(
        section.memo_section_openai_responses[0]?.openai_custom_prompt_response
      )
    ) {
      return "Replace AI summary";
    }

    return "Use AI Summary";
  }, [section]);

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        startIcon={<AIIcon color={colors.blue100} />}
      >
        {buttonLabel}
      </Button>
      <Dialog open={isOpen} maxWidth="sm" onClose={handleClose}>
        <DialogTitle sx={{ padding: 2 }}>
          <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
            <Text variant="h2">Use AI summary</Text>
            <Text variant="text2" color={colors.gray80} marginTop={1}>
              Bring in a corresponding answer from one of your AI summary
              documents. Only your edited, finalized answers can be brought into
              the memo.
            </Text>
          </Paper>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <div style={{ paddingTop: theme.spacing(1) }}>
                {isLoadingDocumentAssistants ? (
                  <Skeleton height={56} />
                ) : (
                  <Select
                    fullWidth
                    {...formik.getFieldProps("documentAssistantId")}
                    error={
                      formik.touched.documentAssistantId &&
                      !!formik.errors.documentAssistantId
                    }
                    helperText={
                      formik.touched.documentAssistantId &&
                      formik.errors.documentAssistantId
                    }
                    label="AI summary"
                    placeholder="Select an AI summary"
                    options={documentAssistantOptions}
                    onChange={handleAssistantChange}
                  />
                )}
              </div>
              {isLoadingPrompts &&
              !_.isNil(formik.getFieldProps("documentAssistantId").value) ? (
                <Skeleton height={56} />
              ) : (
                <Select
                  fullWidth
                  label="Prompt"
                  {...formik.getFieldProps("promptId")}
                  error={formik.touched.promptId && !!formik.errors.promptId}
                  helperText={formik.touched.promptId && formik.errors.promptId}
                  placeholder="Choose a prompt answer"
                  options={promptOptions}
                  disabled={_.isNil(
                    formik.getFieldProps("documentAssistantId").value
                  )}
                  onChange={handlePromptChange}
                />
              )}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Stack
            direction="row"
            justifyContent={
              showDisconnectedButton ? "space-between" : "flex-end"
            }
            flex={1}
          >
            {showDisconnectedButton && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDisconnectAISummary}
              >
                Disconnect AI Summary
              </Button>
            )}
            <Stack spacing={1} direction="row">
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
              {isLoadingDocumentAssistants ||
              (!_.isNil(formik.getFieldProps("documentAssistantId").value) &&
                isLoadingPrompts) ? (
                <Skeleton height={63} width={150} />
              ) : (
                <Button
                  onClick={handleCreate}
                  disabled={isSubmitButtonDisabled}
                >
                  Use AI summary
                </Button>
              )}
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UseAISummaryDialogButton;

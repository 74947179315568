import React, { useMemo } from "react";
import _ from "lodash";

import { formatNumberToString } from "utils/common";

import Stack from "components/Stack";

import SourcesAndUsesSection from "entities/Proforma/components/ProformaSourcesAndUses/SourcesAndUsesSection";
import { ICoreData } from "entities/Proforma/sdk";

export interface ISourcesAndUsesPermanent {
  coreData: ICoreData;
}

const sourcesHeaders = [
  { key: "sources", name: "sources" },
  { key: "amount", name: "amount" },
  { key: "percentage", name: "%" },
];

const usesHeaders = [
  { key: "uses", name: "uses" },
  { key: "amount", name: "amount" },
  { key: "percentage", name: "%" },
];

const SourcesAndUsesPermanent: React.FC<ISourcesAndUsesPermanent> = ({
  coreData,
}) => {
  const sourcesPermanentRows = useMemo(
    () =>
      _.orderBy(
        _.map(coreData.sources_permanent_data, (value, key) => ({
          sources: key
            .replaceAll("_", " ")
            .replaceAll("symby", "/")
            .replaceAll("symdash", "-"),
          amount: formatNumberToString({ number: value.value }),
          percentage: `${formatNumberToString({
            number: value.percentage,
          })}%`,
          position: value.position,
        })),
        "position"
      ),
    [coreData]
  );

  const usesPermanentRows = useMemo(
    () =>
      _.orderBy(
        _.map(coreData.uses_permanent_data, (value, key) => ({
          uses: key
            .replaceAll("_", " ")
            .replaceAll("symby", "/")
            .replaceAll("symdash", "-"),
          amount: formatNumberToString({ number: value.value }),
          percentage: `${formatNumberToString({
            number: value.percentage,
          })}%`,
          position: value.position,
        })),
        "position"
      ),
    [coreData]
  );

  const sourcesTableTotals: { [key: string]: string } = {
    sources: "Total Sources",
    amount: formatNumberToString({
      number: coreData.total_sources_permanent_data,
    }),
    percentage: "100%",
  };

  const usesTableTotals: { [key: string]: string } = {
    uses: "Total Uses",
    amount: formatNumberToString({
      number: coreData.total_uses_permanent_data,
    }),
    percentage: "100%",
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={4}
      data-testid="section-content"
    >
      {/* TODO: the totals of the tables have to be on a one row */}
      <SourcesAndUsesSection
        title="Sources"
        subTitle="@Permanent Closing"
        rows={sourcesPermanentRows}
        headers={sourcesHeaders}
        tableTotals={sourcesTableTotals}
        total={coreData.total_sources_permanent_data}
        sectionDataTestid="sources-section"
      />
      <SourcesAndUsesSection
        title="Uses"
        subTitle="@Permanent Closing"
        rows={usesPermanentRows}
        headers={usesHeaders}
        tableTotals={usesTableTotals}
        total={coreData.total_uses_permanent_data}
        sectionDataTestid="uses-section"
      />
    </Stack>
  );
};

export default SourcesAndUsesPermanent;

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import { IPackageCategory } from "entities/Package/sdk";
import { IInitialValues } from "entities/Task/components/Form";
import { DUE_DILIGENCE_TAG_NAME } from "entities/Task/constants";
import { useTagList } from "entities/Task/sdk";

interface IDueDiligenceCategoryNewTaskButton {
  category: IPackageCategory;
  packageId: number;
  tabId: number;
  updatePackage: (message?: string) => void;
  menuOptionDataTestid?: string;
}

const DueDiligenceCategoryNewTaskButton: React.FC<
  IDueDiligenceCategoryNewTaskButton
> = ({ category, packageId, tabId, updatePackage, menuOptionDataTestid }) => {
  const { openCreateNewTaskSubTab } = useDealSidepanel();
  const { data: tagList } = useTagList();

  const handleCreateNewTask = useCallback(() => {
    const initialValues: IInitialValues = {
      title: category.name,
      company_category: category.category_id,
      company_package: packageId,
      company_tab: tabId,
      tags:
        tagList
          ?.filter((tag) => tag.name === DUE_DILIGENCE_TAG_NAME)
          .map((tag) => tag.id) || [],
    };

    openCreateNewTaskSubTab({
      taskCategory: category,
      updateParent: updatePackage,
      initialValues: initialValues,
    });
  }, [
    openCreateNewTaskSubTab,
    category,
    packageId,
    tabId,
    tagList,
    updatePackage,
  ]);

  return (
    <MenuItem onClick={handleCreateNewTask} data-testid={menuOptionDataTestid}>
      <Text variant="text2" data-testid="action-menu-option-name">
        Create new task
      </Text>
    </MenuItem>
  );
};

export default DueDiligenceCategoryNewTaskButton;

import React from "react";
import _ from "lodash";

import ContentSeparator from "components/ContentSeparator";
import Stack from "components/Stack";

import DealSidepanelTable from "entities/Deal/components/DealSidepanel/DealSidepanelTable";
import { insertNotificationDataIntoColumns } from "entities/Deal/components/DealSidepanel/ProjectDetailsTabContent/utils";
import { ISidePanel } from "entities/Deal/sdk";

export const TAB_KEY = "projectDetails";

const EXCLUDED_LINES = ["page", "img", "deal_image"];

interface IProjectDetailsTabContent {
  projectDetails: ISidePanel["project_details"] | undefined;
  columns: any[];
}

const ProjectDetailsTabContent: React.FC<IProjectDetailsTabContent> = ({
  projectDetails = [],
  columns,
}) => {
  const columnsWithNotificationData =
    insertNotificationDataIntoColumns(columns);

  return (
    <>
      {columnsWithNotificationData
        .filter(
          (column) =>
            column?.report_fields.length > 0 &&
            column.report_fields.find((field: any) => field.field_type !== null)
        )
        .map(({ name, report_fields }) => (
          <Stack spacing={2} key={name} data-testid="project-details-section">
            <ContentSeparator label={name} />
            <DealSidepanelTable
              data={report_fields
                .filter(
                  (field: any) =>
                    field.field_type &&
                    !EXCLUDED_LINES.includes(field.field_type.toLowerCase())
                )
                .map((field: any) => ({
                  title: field.name,
                  value: _.get(
                    projectDetails,
                    field.field_type && field.field_type !== "notifications"
                      ? field.field_type
                      : field.name
                  ),
                }))}
            />
          </Stack>
        ))}
    </>
  );
};

export default ProjectDetailsTabContent;

import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogContentText from "@mui/material/DialogContentText";
import MuiDialogTitle from "@mui/material/DialogTitle";

import { CloseIcon } from "icons";
import { colors } from "theme/palette";

import Stack from "components/Stack";

export const DialogTitle = MuiDialogTitle;
export const DialogContent = MuiDialogContent;
export const DialogContentText = MuiDialogContentText;
export const DialogActions = MuiDialogActions;

export interface IDialog extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  transparentBackdrop?: boolean; // If you need to have overlapping dialogs, you can set this to true to ignore the backdrop. This is useful to have the same hover effect on the close button on all dialogs.
  dialogDataTestid?: string;
}

const Dialog = ({
  sx,
  transparentBackdrop = false,
  dialogDataTestid,
  ...props
}: IDialog) => (
  <MuiDialog
    {...props}
    sx={{
      ...sx,
      ".MuiBackdrop-root": {
        backgroundColor: transparentBackdrop ? "transparent" : "#18183333",
        backdropFilter: "blur(2px)",
      },
      ".MuiPaper-root": {
        overflowY: "unset",
      },
    }}
  >
    <CloseIcon
      onClick={props.onClose}
      fontSize="large"
      sx={{
        color: colors.gray60,
        position: "absolute",
        right: 0,
        top: "-45px",
        cursor: "pointer",
        transition: "color 0.3s ease",
        "&:hover": {
          color: colors.gray40,
        },
      }}
      data-testid="dialog-close-button"
    />
    {/* Border radius is exact copy of MUI's */}
    <Stack
      sx={{ maxHeight: "75vh", overflowY: "auto", borderRadius: "8px" }}
      data-testid={dialogDataTestid}
    >
      {props.children}
    </Stack>
  </MuiDialog>
);

export default Dialog;

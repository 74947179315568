import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";

import { URLS } from "config/urls";
import theme from "theme";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import BusinessForm, {
  IFormikParticipant,
} from "entities/CompanyItegrations/components/Downhome/BusinessForm";
import FormSkeleton from "entities/CompanyItegrations/components/Downhome/FormSkeleton";
import {
  downhomeBusinessParticipantUpdate,
  useBusinessParticipantData,
  useDownhomeIndustryGroups,
} from "entities/CompanyItegrations/sdk";

const DownhomeBusinessCreate = () => {
  usePageTitle(
    "Builders Patch: Company Integrations - Downhome Business update"
  );

  const params = useParams<{
    dealId: string;
    externalId: string;
  }>();
  const dealId = Number(params.dealId);
  const externalId = Number(params.externalId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: participantData,
    isLoading: isFetchingParticipantData,
    isValidating: isValidatingParticipantData,
    mutate: mutateParticipantData,
  } = useBusinessParticipantData({ dealId, externalId });

  const { data: industryGroups = [], isLoading: isFetchingIndustryGroups } =
    useDownhomeIndustryGroups();

  const handleSubmit = useCallback(
    ({ data }: { data: IFormikParticipant }) => {
      setIsSubmitting(true);

      downhomeBusinessParticipantUpdate({ dealId, data })
        .then(() => {
          toast.successMessage("Business participant data has been updated!");
          mutateParticipantData();
        })
        .catch(handleInvalidRequest)
        .finally(() => setIsSubmitting(false));
    },
    [dealId, mutateParticipantData]
  );

  const isLoading = useMemo(
    () =>
      isFetchingParticipantData ||
      isValidatingParticipantData ||
      isFetchingIndustryGroups,
    [
      isFetchingParticipantData,
      isValidatingParticipantData,
      isFetchingIndustryGroups,
    ]
  );

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          sx={{ width: "100%", maxWidth: BOX_MAX_WIDTH, alignSelf: "center" }}
          spacing={2}
        >
          <PageHeader
            title="DownHome Loan Manager: Business Loans"
            backLink={reverse(URLS.COMPANY_INTEGRATIONS_LIST, {
              dealId,
            })}
            backTitle="Back to Integrations"
          />
          {isLoading ? (
            <FormSkeleton />
          ) : (
            <BusinessForm
              initialParticipantData={participantData}
              industryGroups={industryGroups}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
            />
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default DownhomeBusinessCreate;

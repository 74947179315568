import { getEnvironmentVariables } from "utils/environment";

import { IMarker } from "entities/Deal/sdk";

const GOOGLE_MAPS_API_KEY = getEnvironmentVariables().VITE_GOOGLE_API_KEY;

export const googleMapsGetStaticMapUrl = ({
  markers,
}: {
  markers: IMarker[];
}) => {
  const firstMarker = markers[0];
  const markerLocation = `${firstMarker.center.lat},${firstMarker.center.lng}`;

  return `https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=640x250&center=${markerLocation}&key=${GOOGLE_MAPS_API_KEY}&markers=${markerLocation}&scale=2`;
};

import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { VisibilityIcon } from "icons";
import theme from "theme";

import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";

import DealSidepanel from "entities/Deal/components/DealSidepanel";
import { useDealsListData } from "entities/Deal/sdk";

interface IDealSidepanelPreviewButton {
  dealId: number;
}

const DealSidepanelPreviewButton: React.FC<IDealSidepanelPreviewButton> = ({
  dealId,
}) => {
  const { phase = "All" } = useParams<{
    phase: string;
  }>();

  const { mutate: refetchDealsListData } = useDealsListData({
    phase,
  });
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    refetchDealsListData();
  }, [refetchDealsListData]);

  return (
    <>
      <Tooltip title="Preview" disableInteractive>
        <IconButton
          onClick={handleOpen}
          sx={{
            borderRadius: "2px",
            padding: theme.spacing(0.5),
            opacity: 0.4,
            "&:hover": {
              opacity: 1,
            },
          }}
          size="small"
          dataTestid="open-side-panel-button"
        >
          <VisibilityIcon
            style={{ width: 12, height: 12 }}
            data-testid="open-side-panel-icon"
          />
        </IconButton>
      </Tooltip>
      {/* This is done because DealSidepannel fetches data and we need it to fetch only when it gets opened */}
      {open && (
        <DealSidepanel dealId={dealId} open={open} onClose={handleClose} />
      )}
    </>
  );
};

export default DealSidepanelPreviewButton;

import { useMemo } from "react";

import Stack from "components/Stack";

import ChangeStatusButton, {
  EChangeStatusButtonVariant,
} from "entities/Application/components/ChangeStatusButton";
import MoveToUnderwritingMenuItem from "entities/Application/components/MoveToUnderwritingMenuItem";
import { APPLICATION_STATUS } from "entities/Application/constants";

export interface ILenderPreviewButtons {
  applicationId: number;
  applicationName: string;
  applicationStatus:
    | APPLICATION_STATUS.SUBMITTED
    | APPLICATION_STATUS.REJECTED
    | APPLICATION_STATUS.ARCHIVED;
  projectName: string | null;
  numberOfUnits: string | null;
  onUpdate: () => void;
}

const LenderPreviewButtons = ({
  applicationId,
  applicationName,
  applicationStatus,
  projectName,
  numberOfUnits,
  onUpdate,
}: ILenderPreviewButtons) => {
  const commonProps = useMemo(
    () => ({
      applicationId,
      applicationName,
      variant: EChangeStatusButtonVariant.BUTTON,
      updateCallback: onUpdate,
      currentStatus: applicationStatus,
    }),
    [applicationId, applicationName, applicationStatus, onUpdate]
  );

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      {applicationStatus === APPLICATION_STATUS.SUBMITTED && (
        <>
          <ChangeStatusButton
            {...commonProps}
            newStatus={APPLICATION_STATUS.ARCHIVED}
            label="Archive"
          />
          <ChangeStatusButton
            {...commonProps}
            newStatus={APPLICATION_STATUS.REJECTED}
            label="Inactivate"
          />
        </>
      )}
      {applicationStatus === APPLICATION_STATUS.ARCHIVED && (
        <>
          <ChangeStatusButton
            {...commonProps}
            newStatus={APPLICATION_STATUS.SUBMITTED}
            label="Unarchive"
          />
          <ChangeStatusButton
            {...commonProps}
            newStatus={APPLICATION_STATUS.REJECTED}
            label="Inactivate"
          />
        </>
      )}
      {applicationStatus === APPLICATION_STATUS.REJECTED && (
        <>
          <ChangeStatusButton
            {...commonProps}
            newStatus={APPLICATION_STATUS.ARCHIVED}
            label="Archive"
          />
          <ChangeStatusButton
            {...commonProps}
            newStatus={APPLICATION_STATUS.SUBMITTED}
            label="Move to active"
          />
        </>
      )}
      <MoveToUnderwritingMenuItem
        applicationId={applicationId}
        projectName={projectName || ""}
        numberOfUnits={numberOfUnits}
      />
    </Stack>
  );
};

export default LenderPreviewButtons;

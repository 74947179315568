import _ from "lodash";

import { BASE_URL } from "config/urls";
import { deleteCall, get, post, useFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { IFieldTemplateMeta } from "entities/Checklist/sdk";
import { IFile } from "entities/File/sdk";
import { IFieldOption } from "entities/Package/sdk";

import {
  CompanyCustomFieldSyncingType,
  CompanyFieldFormatType,
} from "./constants";

export enum FieldType {
  TEXT_FIELD = "textfield",
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  FILE = "file",
  RADIO_BUTTON = "radiobutton",
  RICH_TEXT_FIELD = "richtextfield",
  TABLE = "table",
  FIXED_TABLE = "fixed_table",
  SIGNATURE = "signature",
  IMAGE = "image",
  BUTTON = "button",
  DATE = "date",
  FORM_TEXT_FIELD = "form_text_field",
}

export interface ICustomValue {
  value_id: number;
  default_value?: string;
  selected_value?: string;
  file?: IFile;
  creator?: string;
  uploaded_at?: string;
  rich_value?: string;
  column_value?: { [key: string]: string };
  updated_at: string;
}

export interface IField {
  field_id: number;
  display_name: string;
  is_completed: boolean;
  has_remark: boolean;
  remark_value: { shared_remark: string; private_remark: string };
  has_new: boolean;
  n_a_status: boolean;
  single_persona: boolean;
  field_type: FieldType;
  custom_values: ICustomValue[];
  header_name?: Array<{ field: string; headerName: string }>;
  instruction?: string | null;
  rows?: Array<string>;
  columns?: Array<string>;
  template?: string;
  template_meta?: Array<IFieldTemplateMeta>;
  field_format_type: CompanyFieldFormatType;

  is_linked: boolean;
  is_overridden: boolean;

  options: Array<IFieldOption>;
}

export const fieldValueCreate = ({
  dealId,
  companyCustomFieldId,
  value,
}: {
  dealId: number;
  companyCustomFieldId: number;
  value: string | number | null | undefined;
}) =>
  post<Array<{ id: number; default_value: string }>>(
    `${BASE_URL}v2/deal/${dealId}/custom-field/${companyCustomFieldId}/value/create/`,
    { value }
  );

export interface IFileData {
  id: number;
  file: IFile;
  uploaded_at: string;
}

export const uploadFileToCustomField = ({
  dealId,
  customFieldId,
  fileId,
}: {
  dealId: number;
  customFieldId: number;
  fileId: number;
}): Promise<Array<IFileData>> =>
  post(
    `${BASE_URL}v2/deal/${dealId}/upload-document/custom-field/${customFieldId}`,
    { file_id: fileId }
  );

export const checkFilePermission = ({
  fileId,
  fieldId,
}: {
  fileId: number;
  fieldId: number;
}): Promise<{ url: string }> =>
  get(
    `${BASE_URL}v2/files/${fileId}/company-field/${fieldId}/due-diligence-details/`,
    {
      useSharableToken: true,
    }
  );

export const deleteDueDiligenceFile = ({
  dealId,
  customFieldId,
  customValueId,
}: {
  dealId: number;
  customFieldId: number;
  customValueId: number;
}): Promise<any> =>
  deleteCall(
    `${BASE_URL}v2/deal/${dealId}/company-field/${customFieldId}/custom-field-value/${customValueId}/delete/`
  );

export const markFieldSeenBySignedUser = ({
  dealId,
  packageId,
  fieldId,
}: {
  dealId: number;
  packageId: number;
  fieldId: number;
}): Promise<{ has_new: boolean }> =>
  get<{ response?: string }>(
    `${BASE_URL}deal/${dealId}/custom/field_value/${packageId}/seen_by/${fieldId}`
  ).then(({ response }) => {
    // Backend returns this :(
    if (response === "You've seen files successfully") {
      return Promise.resolve({ has_new: false });
    }
    return Promise.reject();
  });

export interface IFileFieldRemarkData {
  response: {
    id: number;
    name: string;
    field_type: string;
    instruction: string | null;
    company_package_id: number;
    company_package_name: string;
    shared_remark: string;
    shared_remark_updated_at: string;
    shared_remark_created_by: string;
    shared_remark_updated_by: string | null;
    private_remark_name: string;
    private_remark: string;
    private_remark_updated_at: string;
    private_remark_created_by: string;
    private_remark_updated_by: string | null;
    n_a_status: boolean;
    permissions: string[];
  };
}

export const useFileFieldRemarks = ({
  dealId,
  packageId,
  fieldId,
  enabled,
}: {
  dealId: number;
  packageId: number;
  fieldId: number;
  enabled: boolean;
}) => {
  const { data: response, ...rest } = useFetch<IFileFieldRemarkData>(
    enabled
      ? `deal/${dealId}/field/data/${packageId}/custom_field/${fieldId}`
      : undefined
  );

  return { data: response?.response, ...rest };
};

export const addFileFieldRemark = ({
  dealId,
  packageId,
  fieldId,
  data,
}: {
  dealId: number;
  packageId: number;
  fieldId: number;
  data: { shared_remark?: string; private_remark?: string };
}): Promise<{ response: string }> =>
  post(
    `${BASE_URL}v2/deal/${dealId}/package/${packageId}/custom-field/${fieldId}/remark/add-edit/`,
    data
  );

export interface ILinkedFieldCompanyPackage {
  id: number;
  name: string;
  tabs: Array<{
    id: number;
    name: string;
    categories: Array<{
      name: string;
      sections: Array<{
        name: string;
        fields: Array<{
          id: number;
          name: string;
          link_id: string;
          has_access: boolean;
          is_overridden: boolean;
        }>;
      }>;
    }>;
  }>;
}

export const useLinkedCustomFieldList = ({
  fieldId,
  dealId,
  packageId,
  disabled = false,
}: {
  fieldId: number;
  packageId: number;
  dealId?: number;
  disabled?: boolean;
}) => {
  const params = _.isNil(dealId) ? "" : formatGetParams({ deal_id: dealId });
  return useFetch<Array<ILinkedFieldCompanyPackage>>(
    disabled
      ? undefined
      : `v2/deal/company-package/${packageId}/custom-field/${fieldId}/linked-field/list/${params}`
  );
};

export const updateFieldOverriddenStatus = ({
  dealId,
  fieldId,
  syncingType,
}: {
  dealId: number;
  fieldId: number;
  syncingType: CompanyCustomFieldSyncingType;
}) =>
  post(
    `${BASE_URL}v2/deal/${dealId}/custom-field/${fieldId}/overridden-status-update/`,
    {
      syncing_type: syncingType,
    }
  );

import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import {
  filterErrorsWithoutResponseStatus,
  isNotUndefined,
} from "utils/common";

import toast from "components/Toast";

import SectionForm from "entities/MemoTemplate/components/SectionForm";
import {
  createMemoTemplateSection,
  updateMemoTemplateSectionsOrder,
  useMemoTemplateDetails,
} from "entities/MemoTemplate/sdk";

const AddSectionForm = ({
  onCancel,
  onSectionCreated,
  afterMemoSectionId,
  formikRef,
}: {
  onCancel: () => void;
  onSectionCreated: () => void;
  afterMemoSectionId: number | null;
  formikRef?: React.Ref<any>;
}) => {
  const params = useParams<{ memoId: string }>();
  const memoId = Number(params.memoId);
  const { data: memo, mutate: mutateMemo } = useMemoTemplateDetails({
    memo_id: memoId,
  });
  const sections = memo?.sections || [];

  const handleSubmit = (
    data: Parameters<typeof createMemoTemplateSection>[0]["data"]
  ) =>
    createMemoTemplateSection({ memoId, data })
      .then((section) => {
        const secIds = sections.map((section) => section.memo_section_id);

        if (_.isNull(afterMemoSectionId)) {
          return updateMemoTemplateSectionsOrder({
            memoId,
            sectionsOrder: [section.memo_section_id, ...secIds],
          }).then(() => mutateMemo().then(() => onSectionCreated()));
        }

        const before = _.takeWhile(secIds, (id) => id !== afterMemoSectionId);
        const after = _.dropWhile(secIds, (id) => id !== afterMemoSectionId);
        const sectionsOrder = [
          ...before,
          _.first(after),
          section.memo_section_id,
          ..._.tail(after),
        ].filter(isNotUndefined);

        return updateMemoTemplateSectionsOrder({ memoId, sectionsOrder }).then(
          () => mutateMemo().then(() => onSectionCreated())
        );
      })
      .catch((errors) => {
        const filteredErrors = filterErrorsWithoutResponseStatus({ errors });

        _.forEach(filteredErrors, (e: string) => toast.errorMessage(e));
      });

  return (
    <SectionForm
      initialValues={{}}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      formikRef={formikRef}
    />
  );
};

export default AddSectionForm;

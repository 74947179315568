import MuiMenu from "@mui/material/Menu";
import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";

import theme from "theme";
import { colors } from "theme/palette";

interface IMenuList extends MenuItemProps {
  dataTestid?: string;
}

export const MenuItem = ({ dataTestid, ...props }: IMenuList) => {
  return (
    <MuiMenuItem
      sx={{
        borderRadius: theme.spacing(0.5),
        margin: theme.spacing(0, 1),
        padding: theme.spacing(1),
        "&:hover": {
          backgroundColor: colors.blue10,
        },
        "&.Mui-selected": {
          backgroundColor: colors.blue10,
        },
      }}
      data-testid={dataTestid}
      {...props}
    ></MuiMenuItem>
  );
};

export default MuiMenu;

import { colors } from "theme/palette";

import Text from "components/Text";

import { Phase } from "entities/Deal/constants";
import { ISuperuserCompanyDeal } from "entities/Superuser/sdk";

export interface ICompanyDealsTable {
  companyDeals: Array<ISuperuserCompanyDeal>;
}

const cellStyles = {
  padding: "4px 8px",
};

const headerStyles = {
  padding: "4px 8px",
  color: colors.black,
  backgroundColor: colors.blue20,
};

const CompanyDealsTable = ({ companyDeals }: ICompanyDealsTable) => (
  <table>
    <thead>
      <tr>
        {[
          "Company",
          Phase.APPLICATION,
          Phase.UNDERWRITING,
          Phase.CONSTRUCTION,
          Phase.CONVERSION,
          Phase.ASSET_MANAGEMENT,
          "All",
        ].map((header) => (
          <td key={header} style={headerStyles} align="center">
            <Text variant="labelSmall" fontWeight={500}>
              {header}
            </Text>
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {companyDeals.map((company, index) => (
        <tr key={index}>
          <td style={cellStyles}>
            <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
              {company.company_name}
            </Text>
          </td>

          <td align="center">
            <Text variant="text2">{company.application_deal_count}</Text>
          </td>
          <td align="center">
            <Text variant="text2">{company.underwriting_deal_count}</Text>
          </td>
          <td align="center">
            <Text variant="text2">{company.construction_deal_count}</Text>
          </td>
          <td align="center">
            <Text variant="text2">{company.conversion_deal_count}</Text>
          </td>
          <td align="center">
            <Text variant="text2">{company.asset_management_deal_count}</Text>
          </td>

          <td align="center">
            <Text variant="text2">{company.all_deal_count}</Text>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CompanyDealsTable;

import { useCallback, useEffect, useState } from "react";

const useComponentScrollWidth = (ref: React.RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState(0);

  const handleResize = useCallback(() => {
    if (ref.current) {
      setWidth(ref.current.scrollWidth);
    }
  }, [ref]);

  useEffect(() => {
    let dimensionsTimeout = setTimeout(() => {
      handleResize();
    }, 500);
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(dimensionsTimeout);
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, handleResize]);

  return { width, recalculateWidth: handleResize };
};

export default useComponentScrollWidth;

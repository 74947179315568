import React, { useCallback } from "react";
import { useFormik } from "formik";

import CheckboxTree from "components/CheckboxTree";

import { ProjectSize } from "entities/AnalyticsFilterGroup/constants";

const SIZE_CHECKBOXES = [
  { label: ProjectSize.UNITS_5_TO_49, value: ProjectSize.UNITS_5_TO_49 },
  { label: ProjectSize.UNITS_50_TO_199, value: ProjectSize.UNITS_50_TO_199 },
  { label: ProjectSize.UNITS_200_TO_499, value: ProjectSize.UNITS_200_TO_499 },
  { label: ProjectSize.UNITS_500_TO_999, value: ProjectSize.UNITS_500_TO_999 },
  { label: ProjectSize.UNITS_1000_PLUS, value: ProjectSize.UNITS_1000_PLUS },
];

interface IFormProjectSize {
  formik: ReturnType<typeof useFormik>;
}

const FORM_VALUE_KEY = "project_size";

const FormProjectSize: React.FC<IFormProjectSize> = ({ formik }) => {
  const handleChange = useCallback(
    (value: Array<string | number>) =>
      formik.setFieldValue(FORM_VALUE_KEY, value),
    [formik]
  );

  return (
    <CheckboxTree
      label="Project size"
      options={SIZE_CHECKBOXES}
      fieldInputProps={formik.getFieldProps(FORM_VALUE_KEY)}
      onChange={handleChange}
      value={formik.values[FORM_VALUE_KEY]}
    />
  );
};

export default FormProjectSize;

import { useCallback } from "react";

import Button from "components/Button";

import {
  EXPORT_HEADERS,
  LENDER_EXPORT_HEADERS,
} from "entities/Application/constants";
import {
  IApplicationWithRefetch,
  ILenderApplicationWithRefetch,
} from "entities/Application/sdk";

import { EExportName, exportTableAsXLSX } from "./utils";

export type IExportButton =
  | {
      isLenderExport?: false;
      applications: Array<IApplicationWithRefetch>;
    }
  | {
      isLenderExport: true;
      applications: Array<ILenderApplicationWithRefetch>;
    };

const ExportButton = ({ applications, isLenderExport }: IExportButton) => {
  const handleExport = useCallback(() => {
    exportTableAsXLSX({
      headers: EXPORT_HEADERS,
      applications,
      exportName: EExportName.APPLICANT_DASHBOARD,
    });
  }, [applications]);

  const handleLenderExport = useCallback(() => {
    const applicationsToBeExported = (
      applications as Array<ILenderApplicationWithRefetch>
    ).map((application) => {
      const { submitted_by: submittedBy } = application;

      return {
        ...application,
        submitted_by_full_name: `${submittedBy.first_name} ${submittedBy.last_name}`,
      };
    });

    exportTableAsXLSX({
      headers: LENDER_EXPORT_HEADERS,
      applications: applicationsToBeExported,
      exportName: EExportName.REVIEWER_DASHBOARD,
    });
  }, [applications]);

  return (
    <Button onClick={isLenderExport ? handleLenderExport : handleExport}>
      Export
    </Button>
  );
};

export default ExportButton;

import React from "react";

import Dialog, { DialogContent } from "components/Dialog";

import UploadExcelForm from "entities/Proforma/components/UploadExcelForm";

interface IProformaUploadExcelDialog {
  open: boolean;
  dealId: number;
  onUpload: () => void;
  onClose: () => void;
}

const ProformaUploadExcelDialog: React.FC<IProformaUploadExcelDialog> = ({
  open,
  dealId,
  onUpload,
  onClose,
}) => (
  <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
    <DialogContent>
      <UploadExcelForm
        directUpload={false}
        dealId={dealId}
        onClose={onClose}
        onUpload={onUpload}
      />
    </DialogContent>
  </Dialog>
);

export default ProformaUploadExcelDialog;

import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";

import { SearchIcon } from "icons";
import { colors } from "theme/palette";

interface ISearchField extends OutlinedInputProps {
  dataTestid?: string;
}

const SearchField: React.FC<ISearchField> = ({ dataTestid, ...props }) => {
  return (
    <OutlinedInput
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      {...props}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${colors.blue60}`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${colors.blue60}`,
        },
        ...props.sx,
      }}
      color="secondary"
      inputProps={{ "data-testid": dataTestid }}
    />
  );
};

export default SearchField;

import { DeleteIcon } from "icons";

import FormControlLabel from "components/FormControlLabel";
import IconButton from "components/IconButton";
import Radio from "components/Radio";
import Stack from "components/Stack";
import Text from "components/Text";

export interface IListFieldActions {
  checked: boolean;
  onPrimaryElementUpdate: () => void;
  onElementRemove: () => void;
}

const ListFieldActions = ({
  checked,
  onPrimaryElementUpdate,
  onElementRemove,
}: IListFieldActions) => (
  <Stack spacing={1} direction="row" alignItems="center">
    <FormControlLabel
      control={<Radio checked={checked} />}
      onClick={onPrimaryElementUpdate}
      label={
        <Text variant="text3" fontWeight={500}>
          Primary
        </Text>
      }
    />
    <IconButton onClick={onElementRemove}>
      <DeleteIcon />
    </IconButton>
  </Stack>
);

export default ListFieldActions;

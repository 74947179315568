import React, { useCallback, useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import Loading from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import DDSectionSelector from "entities/MemoTemplate/components/DDSectionSelector";
import MemoTemplateSectionSelector from "entities/MemoTemplate/components/SectionSelector";
import MemoTemplateSectionsSectionWrapper from "entities/MemoTemplate/components/SectionWrapper";
import { EMemoSectionType } from "entities/MemoTemplate/constants";
import { IMemoTemplateSection } from "entities/MemoTemplate/sdk";

const VALIDATION_SCHEMA = yup.object({
  title: yup.string().required("The name is required!"),
  section_type: yup.string().required("The type is required!"),
});

interface ISectionForm {
  initialValues?: Partial<ValuesType>;
  onSubmit: (data: ValuesType) => Promise<any>;
  onCancel?: () => void;
  isEditForm?: boolean; // if the form is edit -> only the name can be changed because there might be information entered already for this section
  dueDiligenceSection?: IMemoTemplateSection["due_diligence_section"];
  formikRef?: React.Ref<any>;
}

const initialValues = {
  title: "New Section",
  section_type: EMemoSectionType.DUE_DILIGENCE,
  due_diligence_section: null,
  break_before: false,
};

type ValuesType = {
  title: string;
  section_type: EMemoSectionType;
  break_before: boolean;
  due_diligence_section: null | number;
  due_diligence_package_id?: number;
};

const SectionForm = ({
  onSubmit,
  onCancel,
  initialValues: propsInitialValues = {},
  isEditForm = false,
  dueDiligenceSection,
  formikRef,
}: ISectionForm) => {
  const [section, setSection] = useState<any>(null);

  useEffect(() => setSection(dueDiligenceSection), [dueDiligenceSection]);

  const handleSubmit = useCallback(
    (values: ValuesType, formikHelpers: FormikHelpers<ValuesType>) =>
      onSubmit({ ...initialValues, ...values }).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ ...initialValues, ...propsInitialValues }}
      onSubmit={handleSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      {(formik) => (
        <Stack
          spacing={2}
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Loading open={formik.isSubmitting} />

          <Stack spacing={4} direction="row">
            <Stack sx={{ flex: 4 }} spacing={2}>
              <TextField
                fullWidth
                label="Name"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />
              <MemoTemplateSectionSelector
                disabled={isEditForm}
                value={formik.values.section_type}
                onChange={(value) =>
                  formik.setFieldValue("section_type", value)
                }
              />
            </Stack>
            <Stack sx={{ flex: 8 }} spacing={2}>
              <Text variant="h3">Preview</Text>
              <Box
                sx={{
                  border: `1px dashed ${colors.blue20}`,
                  padding: theme.spacing(2),
                }}
              >
                <MemoTemplateSectionsSectionWrapper
                  section={
                    {
                      section_type: formik.values.section_type,
                      due_diligence_section: section,
                      title: formik.values.title || section?.name,
                    } as IMemoTemplateSection
                  }
                />
              </Box>
              <FormControlLabel
                label={<Text variant="label">Start on a new page</Text>}
                control={
                  <Checkbox
                    checked={formik.values.break_before}
                    onChange={(event, checked) =>
                      formik.setFieldValue("break_before", checked)
                    }
                  />
                }
              ></FormControlLabel>
              {formik.values.section_type ===
                EMemoSectionType.DUE_DILIGENCE && (
                <DDSectionSelector
                  value={formik.values.due_diligence_section}
                  due_diligence_package_id={
                    propsInitialValues.due_diligence_package_id
                  }
                  isEditForm={isEditForm}
                  onChange={(section) => {
                    formik.setFieldValue(
                      "due_diligence_section",
                      section.section_id
                    );
                    setSection(section);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};

export default SectionForm;

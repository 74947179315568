import { useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { MoreVertIcon } from "icons";
import { colors } from "theme/palette";

import ButtonDropdown from "components/ButtonDropdown";
import { MenuItem } from "components/Menu";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import {
  companyPackageUpdateCompanies,
  ICompanyWithAccessToDealPackage,
} from "entities/Deal/sdk";

export interface IChecklistAccessCompaniesTable {
  companies: Array<ICompanyWithAccessToDealPackage>;
  onUpdate: () => void;
}

const ChecklistAccessCompaniesTable = ({
  companies,
  onUpdate,
}: IChecklistAccessCompaniesTable) => {
  const params = useParams<{
    dealId: string;
    packageId: string;
  }>();
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const dealId = Number(params.dealId);
  const companyPackageId = Number(params.packageId);

  const companyAccessRevoke = useCallback(
    ({ companyId }: { companyId: number }) => {
      showConfirmationDialog({
        onConfirm: () =>
          companyPackageUpdateCompanies({
            dealId,
            companyPackageId,
            companyIds: companies
              .map((company) => company.id)
              .filter((item) => item !== companyId),
          })
            .then(() => {
              onUpdate();
              toast.successMessage("Access revoked successfully!");
            })
            .catch((error) => {
              const errorMessage =
                error?.message || error?.detail || DEFAULT_ERROR_MESSAGE;

              toast.errorMessage(errorMessage);
            }),
        confirmButtonText: "Revoke access",
        message: "Are you sure you want to revoke this access?",
      });
    },
    [companies, companyPackageId, dealId, onUpdate, showConfirmationDialog]
  );

  if (companies.length === 0) {
    return (
      <Text variant="labelSmall" data-testid="empty-state-message">
        There are no companies with access to this checklist.
      </Text>
    );
  }

  return (
    <Table
      size="small"
      sx={{ border: `1px solid ${colors.blue20}` }}
      data-testid="table"
    >
      <TableHead>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
              backgroundColor: colors.blue20,
              color: colors.black,
            },
          }}
          data-testid="table-headers-section"
        >
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              Company Name
            </Text>
          </TableCell>
          <TableCell data-testid="header-column-cell">
            <Text
              variant="labelSmall"
              fontWeight="500"
              data-testid="header-name"
            >
              User Access Count
            </Text>
          </TableCell>
          <TableCell width={10} data-testid="header-actions-cell"></TableCell>
        </TableRow>
      </TableHead>

      <TableBody data-testid="table-body-section">
        {companies.map((company) => (
          <TableRow
            key={company.id}
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
            data-testid="company-access-row"
          >
            <TableCell>
              <Tooltip title={company.name}>
                <Text variant="text2" data-testid="company-name">
                  {_.truncate(company.name, {
                    length: 60,
                  })}
                </Text>
              </Tooltip>
            </TableCell>

            <TableCell>
              <Text variant="text2" data-testid="user-access-count">
                {company.user_access_count}
              </Text>
            </TableCell>
            <TableCell height={50}>
              {!company.is_owner && (
                <ButtonDropdown
                  icon={<MoreVertIcon />}
                  label=""
                  variant="text"
                  width="auto"
                  buttonDataTestid="company-actions-button"
                  keepMenuMounted
                >
                  <MenuItem
                    data-testid="action-option"
                    onClick={() =>
                      companyAccessRevoke({ companyId: company.id })
                    }
                  >
                    <Text variant="text2">Revoke access</Text>
                  </MenuItem>
                </ButtonDropdown>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ChecklistAccessCompaniesTable;

import { OpenInNewIcon } from "icons";
import { colors } from "theme/palette";

import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { IBookmark as IBookmarkEntity } from "entities/Bookmark/sdk";

export interface IBookmark {
  bookmark: IBookmarkEntity;
}

const Bookmark = ({ bookmark }: IBookmark) => (
  <Link to={bookmark.pathname} reloadDocument>
    <Stack
      flexDirection="row"
      sx={{
        cursor: "pointer",
        padding: 2,
        borderBottom: `1px solid ${colors.blue60}`,
        transition: "all 0.2s ease-in-out",
        alignItems: "center",
        justifyContent: "space-between",
        color: colors.gray100,
        opacity: 0.8,
        "&:hover": {
          backgroundColor: colors.blue20,
          opacity: 1,
        },
        "& .MuiSvgIcon-root": {
          opacity: 0,
          transition: "all 0.2s ease-in-out",
        },
        "&:hover .MuiSvgIcon-root": {
          opacity: 1,
        },
      }}
    >
      <Tooltip title={bookmark.name} enterDelay={500} enterNextDelay={500}>
        {/* Custom styles are to avoid the default tooltip-on-hover behaviour. */}
        <Text
          variant="text2"
          fontWeight={700}
          color={colors.gray100}
          noWrap
          sx={{
            "&::after": {
              content: "''",
              display: "block",
            },
          }}
        >
          {bookmark.name}
        </Text>
      </Tooltip>

      <OpenInNewIcon />
    </Stack>
  </Link>
);

export default Bookmark;

import { useCallback } from "react";

import Stack from "components/Stack";
import Tag from "components/Tag";
import ToggleButtonGroup, { ToggleButton } from "components/ToggleButtonGroup";
import { useFeatureDisabledDialog } from "components/useFeatureDisabledDialog";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import Feature from "entities/Company/components/CompanySettings/Feature";
import {
  COMPANY_SETTINGS_FEATURE_VISIBLE_TYPES_OPTIONS,
  COMPANY_SETTINGS_OPTIONS,
  ECompanySettingsFeatureVisibleType,
  FEATURE_VISIBILITY_TO_TAG_PROPS_MAPPING,
} from "entities/Company/components/CompanySettings/FeaturesList/constants";
import { ICompanySettings } from "entities/Company/sdk";

import { getCompanySettingsFeatureVisibleType } from "./utils";

export interface IFeaturesList {
  companySettings: ICompanySettings;
  editable?: boolean;
  onChange?: ({
    feature,
    value,
  }: {
    feature: ECompanySettingsFeatureType;
    value: ECompanySettingsFeatureVisibleType;
  }) => void;
}

const FeaturesList = ({
  companySettings,
  editable = false,
  onChange,
}: IFeaturesList) => {
  const { show: showFeatureDisabledDialog } = useFeatureDisabledDialog();
  const handleChange = useCallback(
    ({
      feature,
      value,
    }: {
      feature: ECompanySettingsFeatureType;
      value: ECompanySettingsFeatureVisibleType;
    }) => {
      if (editable && onChange) {
        onChange({ feature, value });
      } else {
        showFeatureDisabledDialog({});
      }
    },
    [editable, onChange, showFeatureDisabledDialog]
  );

  const handleFeatureVisibility = useCallback(
    ({ feature }: { feature: ECompanySettingsFeatureType }) =>
      getCompanySettingsFeatureVisibleType({
        feature,
        companySettings,
      }),
    [companySettings]
  );

  return (
    <Stack>
      {COMPANY_SETTINGS_OPTIONS.map(({ value: featureValue, label }) => {
        const featureVisibilityValue = handleFeatureVisibility({
          feature: featureValue,
        });

        return (
          <Feature key={featureValue} label={label}>
            <ToggleButtonGroup
              exclusive
              color="primary"
              size="small"
              value={handleFeatureVisibility({
                feature: featureValue,
              })}
              onChange={(_, value: ECompanySettingsFeatureVisibleType) =>
                handleChange({ feature: featureValue, value: value })
              }
            >
              {editable ? (
                COMPANY_SETTINGS_FEATURE_VISIBLE_TYPES_OPTIONS.map(
                  ({ value, label }) => (
                    <ToggleButton
                      key={value}
                      value={value}
                      disabled={value === featureVisibilityValue}
                    >
                      {label}
                    </ToggleButton>
                  )
                )
              ) : (
                <Tag
                  {...FEATURE_VISIBILITY_TO_TAG_PROPS_MAPPING[
                    featureVisibilityValue
                  ]}
                />
              )}
            </ToggleButtonGroup>
          </Feature>
        );
      })}
    </Stack>
  );
};

export default FeaturesList;

import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import { LaunchIcon, LockIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { isNotUndefined, useCaching } from "utils/common";
import { format, FORMATS, parse, parseJSON } from "utils/datetime";
import { formatGetParams } from "utils/urls";
import { addQueryParams, reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import ChipsGroup from "components/ChipsGroup";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Link from "components/Link";
import Radio from "components/Radio";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  useDealChecklistPermission,
  useDealPermission,
} from "entities/Deal/sdk";
import EditTaskForm from "entities/Task/components/EditForm";
import { TaskTypes } from "entities/Task/constants";
import {
  deleteTask,
  ITaskChannel,
  updateDealTaskCompleted,
  usePriorityList,
  useTaskChannels,
} from "entities/Task/sdk";

const TaskDetailTabContent: React.FC<{
  taskChannel: ITaskChannel;
  onDelete: () => void;
}> = ({ taskChannel, onDelete }) => {
  const { setOpen, setMeta } = useDealSidepanel();

  const [isCompleted, setIsCompleted] = useCaching(taskChannel.task.completed);

  const dealId = taskChannel.task.deal_id;
  const packageId = taskChannel.task.company_package?.id;
  const { mutate: updateTaskChannels } = useTaskChannels({ dealId });
  const [editTaskChannelFormOpen, setEditTaskChannelFormOpen] = useState(false);
  const { show: showConfirmationDialog } = useConfirmationDialog();
  const { data: priorityList } = usePriorityList();

  const { data: userPermission } = useDealPermission({ dealId });
  const { data: userChecklistPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  /*
    If the task has a packageId, we need to verify whether the user has permissions to access this checklist.
    If not, we should then verify if the user has permission to the deal.
  */
  const isNotPartialUser = useMemo(
    () =>
      packageId
        ? userChecklistPermission?.is_full_access
        : userPermission?.is_full_access,
    [packageId, userPermission, userChecklistPermission]
  );

  const taskPriority = useMemo(() => {
    const selectedPriority = _.first(
      priorityList?.filter(({ id }) => id === taskChannel.task.priority_id)
    );

    return selectedPriority?.name;
  }, [priorityList, taskChannel]);

  const openEditTaskChannel = useCallback(() => {
    setEditTaskChannelFormOpen(true);
  }, []);

  const handleCloseEditTaskChannel = useCallback(() => {
    setEditTaskChannelFormOpen(false);
    updateTaskChannels();
  }, [updateTaskChannels]);

  const onDeleteTaskChannel = useCallback(() => {
    deleteTask(dealId, taskChannel.task.id)
      .then(() => {
        updateTaskChannels(
          (taskChannels: ITaskChannel[] | undefined) =>
            taskChannels?.filter((task) => task.id !== taskChannel.id),
          { revalidate: true }
        );
        onDelete();
        toast.successMessage("The task has been deleted successfully!");
      })
      .catch((error) => {
        toast.errorMessage(`Error: ${error?.detail}`);
      });
  }, [taskChannel, dealId, updateTaskChannels, onDelete]);

  const handleDeleteTaskChannel = useCallback(() => {
    showConfirmationDialog({
      onConfirm: onDeleteTaskChannel,
      message: "Are you sure you want to delete this task?",
    });
  }, [onDeleteTaskChannel, showConfirmationDialog]);

  const handleCompleteTask = useCallback(
    ({ completed }: { completed: boolean }) =>
      updateDealTaskCompleted({
        dealTaskId: taskChannel.task.id,
        data: { completed },
      })
        .then(() => {
          toast.successMessage(
            `The task was successfully ${
              completed ? "completed" : "uncompleted"
            }!`
          );
          setIsCompleted(completed);
          updateTaskChannels();
        })
        .catch(({ detail }) => {
          return toast.errorMessage(detail);
        }),
    [taskChannel, updateTaskChannels, setIsCompleted]
  );

  // Task was created to a field in a checklist
  const pathToChecklistField = useMemo(
    () =>
      [
        taskChannel?.task.company_package?.name,
        taskChannel?.task.company_tab?.name,
        taskChannel?.task.company_category?.name,
        taskChannel?.task.company_section?.name,
        taskChannel?.task.company_field?.name,
      ]
        .filter(isNotUndefined)
        .join(" > "),
    [taskChannel]
  );

  const linkToChecklistField = useMemo(() => {
    if (
      !_.isNil(taskChannel?.task?.company_package) &&
      !_.isNil(taskChannel?.task?.company_tab) &&
      !_.isNil(taskChannel?.task?.company_category) &&
      !_.isNil(taskChannel?.task?.company_section) &&
      !_.isNil(taskChannel?.task?.company_field)
    ) {
      return `${reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId: taskChannel?.task?.company_package?.id,
        tabId: taskChannel?.task?.company_tab?.id,
      })}${formatGetParams({
        fieldId: taskChannel?.task?.company_field?.id,
      })}`;
    }

    return "";
  }, [taskChannel, dealId]);

  // Task was created to a section in a checklist
  const pathToChecklistSection = useMemo(
    () =>
      [
        taskChannel?.task.company_package?.name,
        taskChannel?.task.company_tab?.name,
        taskChannel?.task.company_category?.name,
        taskChannel?.task.company_section?.name,
      ]
        .filter(isNotUndefined)
        .join(" > "),
    [taskChannel]
  );

  const linkToChecklistSection = useMemo(() => {
    if (
      !_.isNil(taskChannel?.task?.company_package) &&
      !_.isNil(taskChannel?.task?.company_tab) &&
      !_.isNil(taskChannel?.task?.company_category) &&
      !_.isNil(taskChannel?.task?.company_section) &&
      _.isNil(taskChannel?.task?.company_field)
    ) {
      return `${reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId: taskChannel?.task?.company_package?.id,
        tabId: taskChannel?.task?.company_tab?.id,
      })}${formatGetParams({
        sectionId: taskChannel?.task?.company_section?.id,
      })}`;
    }

    return "";
  }, [taskChannel, dealId]);

  // Task was created to a category in a checklist
  const pathToChecklistCategory = useMemo(
    () =>
      [
        taskChannel?.task.company_package?.name,
        taskChannel?.task.company_tab?.name,
        taskChannel?.task.company_category?.name,
      ]
        .filter(isNotUndefined)
        .join(" > "),
    [taskChannel]
  );

  const linkToChecklistCategory = useMemo(() => {
    if (
      !_.isNil(taskChannel?.task?.company_package) &&
      !_.isNil(taskChannel?.task?.company_tab) &&
      !_.isNil(taskChannel?.task?.company_category) &&
      _.isNil(taskChannel?.task?.company_section) &&
      _.isNil(taskChannel?.task?.company_field)
    ) {
      return `${reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId: taskChannel?.task?.company_package?.id,
        tabId: taskChannel?.task?.company_tab?.id,
      })}${formatGetParams({
        categoryId: taskChannel?.task?.company_category?.id,
      })}`;
    }

    return "";
  }, [taskChannel, dealId]);

  const showPathToChecklist = useMemo(
    () =>
      pathToChecklistField !== "" ||
      pathToChecklistSection !== "" ||
      pathToChecklistCategory !== "",
    [pathToChecklistField, pathToChecklistSection, pathToChecklistCategory]
  );

  const handleNavigateToChecklist = useCallback(() => {
    setOpen(false);
    setMeta(null);
  }, [setOpen, setMeta]);

  const handleNavigateToWorkspace = useCallback(() => {
    setOpen(false);
    setMeta(null);
  }, [setOpen, setMeta]);

  return (
    <>
      <Stack spacing={2}>
        <Stack
          spacing={3}
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          sx={{
            marginTop: theme.spacing(3),
            paddingTop: theme.spacing(3),
          }}
        >
          <Stack spacing={1}>
            <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
              task status
            </Text>
            <Box paddingX={2}>
              <FormControlLabel
                label="open"
                control={<Radio checked={!isCompleted} />}
                onChange={() => handleCompleteTask({ completed: false })}
              />
              <FormControlLabel
                label="closed"
                control={<Radio checked={isCompleted} />}
                onChange={() => handleCompleteTask({ completed: true })}
              />
            </Box>
          </Stack>
          `
          <Box>
            {isNotPartialUser && (
              <Stack direction="row" spacing={2}>
                {taskChannel?.task_edit_access &&
                  !taskChannel?.task.completed && (
                    <Button onClick={openEditTaskChannel} variant="outlined">
                      Edit this task
                    </Button>
                  )}
                {taskChannel?.task_delete_access &&
                  !taskChannel?.task.completed && (
                    <Button
                      onClick={handleDeleteTaskChannel}
                      variant="outlined"
                    >
                      Delete
                    </Button>
                  )}
              </Stack>
            )}
          </Box>
        </Stack>
        <Stack
          spacing={2}
          sx={{ opacity: taskChannel?.task.completed ? 0.5 : 1 }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
                Assigned to
              </Text>
              <Text variant="text2">
                {taskChannel?.task.assignee_id
                  ? `${taskChannel?.task.assignee_id?.first_name} ${taskChannel?.task.assignee_id?.last_name}`
                  : taskChannel?.task.assignee_type}
              </Text>
            </Box>
            {taskChannel?.task.deadline && (
              <Box>
                <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
                  Due date
                </Text>
                <Text variant="text2">
                  {format(
                    parse(
                      taskChannel?.task.deadline,
                      FORMATS.BACKEND.DATE,
                      new Date()
                    ),
                    FORMATS.FRONTEND.DATE
                  )}
                </Text>
              </Box>
            )}
          </Stack>

          {taskChannel?.task.description && (
            <Box>
              <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
                Description
              </Text>
              <Box
                sx={{
                  flex: 1,
                  borderRadius: theme.spacing(0.5),
                  border: `1px solid ${colors.blue20}`,
                  padding: theme.spacing(1, 1.5, 1),
                  backgroundColor: colors.blue5,
                  lineHeight: 1,
                }}
              >
                <SanitizeHtml html={taskChannel?.task.description} />
              </Box>
            </Box>
          )}
          {taskChannel?.tag_data?.length > 0 && (
            <Box>
              <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
                Tags
              </Text>
              <Stack
                direction="row"
                sx={{
                  flexWrap: "wrap",
                  "& > *": { margin: theme.spacing(0, 1, 1, 0) },
                }}
              >
                <ChipsGroup
                  chips={taskChannel?.tag_data.map((tag) => tag.name)}
                />
              </Stack>
            </Box>
          )}
          <Box>
            <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
              Priority
            </Text>
            <Text variant="text2">{taskPriority}</Text>
          </Box>
          {!["", null].includes(taskChannel.task.visible_to_team) && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flex: 1,
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(1, 1.5, 1),
                backgroundColor: colors.gray10,
              }}
            >
              <LockIcon />
              <Text variant="text2">
                This task is private (visible only to you and assignee team).
              </Text>
            </Stack>
          )}
          <Box>
            <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
              Task info
            </Text>
            <Text variant="text2">
              Created by: <b>{taskChannel?.task.created_by.first_name}</b>{" "}
              <b>{taskChannel?.task.created_by.last_name}</b> |{" "}
              <b>{taskChannel?.task.created_by.persona.name}</b> on{" "}
              <b>
                {taskChannel?.task.created_at &&
                  format(
                    parseJSON(taskChannel?.task.created_at),
                    "MMM dd, yyyy"
                  )}
              </b>
            </Text>
            <Text variant="text2">
              Updated by: <b>{taskChannel?.task.updated_by.first_name}</b>{" "}
              <b>{taskChannel?.task.updated_by.last_name}</b> |{" "}
              <b>{taskChannel?.task.updated_by.persona.name}</b> on{" "}
              <b>
                {taskChannel?.task.updated_at &&
                  format(
                    parseJSON(taskChannel?.task.updated_at),
                    "MMM dd, yyyy"
                  )}
              </b>
            </Text>
          </Box>
        </Stack>
        <Box>
          <Divider sx={{ marginY: theme.spacing(2) }} />
          <Link
            to={addQueryParams(
              reverse(URLS.DEAL_WORKSPACE_TAB, {
                dealId,
                tab: "tasks",
              }),
              {
                taskType: TaskTypes.MY_TASKS,
              }
            )}
            onClick={handleNavigateToWorkspace}
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(2),
              textDecoration: "underline",
            }}
          >
            Go to Deal Workspace
            <LaunchIcon />
          </Link>
          {showPathToChecklist && (
            <>
              <Divider sx={{ marginY: theme.spacing(2) }} />
              <Text variant="text2" fontWeight={700}>
                This task was created as a part of a checklist
              </Text>
              {linkToChecklistField !== "" && (
                <Link
                  to={linkToChecklistField}
                  onClick={handleNavigateToChecklist}
                  style={{
                    marginTop: theme.spacing(1),
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(2),
                    textDecoration: "underline",
                  }}
                >
                  {pathToChecklistField}
                  <LaunchIcon />
                </Link>
              )}
              {linkToChecklistSection !== "" && (
                <Link
                  to={linkToChecklistSection}
                  onClick={handleNavigateToChecklist}
                  style={{
                    marginTop: theme.spacing(1),
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(2),
                    textDecoration: "underline",
                  }}
                >
                  {pathToChecklistSection}
                  <LaunchIcon />
                </Link>
              )}
              {linkToChecklistCategory !== "" && (
                <Link
                  to={linkToChecklistCategory}
                  onClick={handleNavigateToChecklist}
                  style={{
                    marginTop: theme.spacing(1),
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(2),
                    textDecoration: "underline",
                  }}
                >
                  {pathToChecklistCategory}
                  <LaunchIcon />
                </Link>
              )}
            </>
          )}
        </Box>
      </Stack>
      {taskChannel && (
        <EditTaskForm
          open={editTaskChannelFormOpen}
          dealId={dealId}
          taskChannel={taskChannel}
          onUpdated={handleCloseEditTaskChannel}
          handleCancel={handleCloseEditTaskChannel}
        />
      )}
    </>
  );
};

export default TaskDetailTabContent;

import * as yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const VALIDATION_SCHEMA = yup.object({
  deal_name: yup.string().required("This field is required!"),
  project_manager_id: yup
    .number()
    .typeError("This field is required!")
    .required("This field is required!"),
  number_of_units: yup.number(),
});

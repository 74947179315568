import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import _ from "lodash";

import { BASE_URL } from "config/urls";
import { getToken, saveToken } from "utils/storage";

import FreshworksWidget from "components/FreshworksWidget";
import GlobalNotifications from "components/GlobalNotifications";
import { FeatureDisabledContextProvider } from "components/useFeatureDisabledDialog";
import { FeatureLimitContextProvider } from "components/useFeatureLimitDialog";

import { getTokenBySession } from "entities/Auth/sdk";

const PrivateRoutesGuard = () => {
  const location = useLocation();
  const [token, setToken] = useState(getToken());

  useEffect(() => {
    if (!token) {
      // This is a part of our login flow
      // If there's a session, created by auth0, in the back-end - we will get our auth token here.
      getTokenBySession()
        .then(({ token }) => {
          // Save the returned token and re-render the current component
          saveToken(token);
          setToken(token);
        })
        .catch(() => {
          const searchParams = _.isEmpty(location.search)
            ? `?next=${window.location.href}`
            : location.search;

          // If the current session on the backend didn't return a token - reroute to login
          window.location.href = `${BASE_URL}v2/users/login/${searchParams}`;
        });
    }
  }, [token, location]);

  if (!token) {
    return null;
  }

  return (
    <FeatureLimitContextProvider>
      <FeatureDisabledContextProvider>
        <GlobalNotifications />
        <FreshworksWidget />
        <Outlet />
      </FeatureDisabledContextProvider>
    </FeatureLimitContextProvider>
  );
};

export default PrivateRoutesGuard;

import Stack from "components/Stack";

import SectionSummary from "entities/MemoTemplate/components/Sections/SectionSummary";
import { proformaDemoData } from "entities/MemoTemplate/components/Sections/utils";
import SourcesAndUsesConstruction from "entities/Proforma/components/ProformaSourcesAndUses/SourcesAndUsesConstruction";
import SourcesAndUsesPermanent from "entities/Proforma/components/ProformaSourcesAndUses/SourcesAndUsesPermanent";

const SectionSourcesAndUses = () => {
  return (
    <Stack direction="column" justifyContent="space-between" spacing={4}>
      <SourcesAndUsesConstruction coreData={proformaDemoData.core_data} />
      <SourcesAndUsesPermanent coreData={proformaDemoData.core_data} />
      <SectionSummary title="Sources and uses description" />
    </Stack>
  );
};

export default SectionSourcesAndUses;

import React, { useMemo } from "react";
import _ from "lodash";

import Paper from "components/Paper";

import ProformaAcquisitionCosts from "entities/Proforma/components/ProformaAcquisitionCosts";
import ProformaDeveloperFees from "entities/Proforma/components/ProformaDeveloperFees";
import ProformaDevelopmentBudget from "entities/Proforma/components/ProformaDevelopmentBudget";
import ProformaHardCosts from "entities/Proforma/components/ProformaHardCosts";
import ProformaNotEnoughDataPresent from "entities/Proforma/components/ProformaNotEnoughDataPresent";
import ProformaSoftCosts from "entities/Proforma/components/ProformaSoftCosts";
import { IProforma } from "entities/Proforma/sdk";

interface IDevelopmentBudgetWrapper {
  proforma: IProforma;
}

const DevelopmentBudgetWrapper: React.FC<IDevelopmentBudgetWrapper> = ({
  proforma,
}) => {
  const validateAnyValueIsMissing = useMemo(() => {
    if (_.isNil(proforma.budget_data)) {
      return true;
    }

    const {
      total_acquisition_cost,
      total_trade_cost_breakdown,
      hard_cost_contingency,
      soft_cost_contingency,
      total_developer_fees,
    } = proforma.budget_data;

    return (
      _.isNil(total_acquisition_cost.value) ||
      _.isNil(total_trade_cost_breakdown.value) ||
      _.isNil(hard_cost_contingency.value) ||
      _.isNil(soft_cost_contingency.value) ||
      _.isNil(total_developer_fees.value)
    );
  }, [proforma.budget_data]);

  return _.isNil(proforma.budget_data) || validateAnyValueIsMissing ? (
    <ProformaNotEnoughDataPresent />
  ) : (
    <>
      <Paper data-testid="dev-budget-section">
        <ProformaDevelopmentBudget proforma={proforma} />
      </Paper>
      <Paper data-testid="acquisition-cost-section">
        <ProformaAcquisitionCosts proforma={proforma} />
      </Paper>
      <Paper data-testid="hard-costs-section">
        <ProformaHardCosts proforma={proforma} />
      </Paper>
      <Paper data-testid="soft-costs-section">
        <ProformaSoftCosts proforma={proforma} />
      </Paper>
      <Paper data-testid="developer-fees-section">
        <ProformaDeveloperFees proforma={proforma} />
      </Paper>
    </>
  );
};

export default DevelopmentBudgetWrapper;

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";

import { URLS } from "config/urls";
import { IntegrationsIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";

import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import IntegrationsList from "entities/CompanyItegrations/components/IntegrationsList";
import { useCompanyIntegrations } from "entities/CompanyItegrations/sdk";

const CompanyIntegrationsList = () => {
  const navigate = useNavigate();

  usePageTitle("Builders Patch: Company Integrations");

  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: integrations, error } = useCompanyIntegrations({ dealId });

  useEffect(() => {
    if (!_.isUndefined(error) && error.status === 403) {
      navigate(URLS.ACCESS_DENIED, { replace: true });
    }
  }, [error, navigate]);

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          sx={{ width: "100%", maxWidth: BOX_MAX_WIDTH, alignSelf: "center" }}
          spacing={2}
        >
          <PageHeader title="Integrations" />
          <Stack spacing={2}>
            <Text variant="label" color={colors.gray60} fontWeight={500}>
              Currently available integrations
            </Text>
            {!_.isNil(integrations) ? (
              <IntegrationsList integrations={integrations} />
            ) : (
              <Skeleton height={170} />
            )}

            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{
                padding: 2,
                borderRadius: 2,
                backgroundColor: colors.gray10,
                maxWidth: 600,
              }}
            >
              <IntegrationsIcon />
              <Stack>
                <Text variant="text2" fontWeight={700}>
                  How to set up 3rd party integrations
                </Text>
                <Text variant="text4" color={colors.gray80}>
                  To connect and transfer your data to 3rd party softwares,
                  please schedule a meeting with our Customer Success team. They
                  will explain the requirements for integrations.
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default CompanyIntegrationsList;

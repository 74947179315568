import React from "react";

import Box from "components/Box";
import Text from "components/Text";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";

interface INoProformaUploaded {
  title: string;
}

const NoProformaUploaded: React.FC<INoProformaUploaded> = ({ title }) => {
  return (
    <Box>
      <SectionHeader label={title} />
      <SectionContent breakInside="avoid">
        <Box
          sx={{
            breakInside: "avoid",
          }}
        >
          <Text
            variant="body2"
            sx={{ fontStyle: "italic" }}
            data-testid="empty-value-data"
          >
            No proforma uploaded for this deal...
          </Text>
        </Box>
      </SectionContent>
    </Box>
  );
};

export default NoProformaUploaded;

import { useParams } from "react-router-dom";
import _ from "lodash";

import { LogoIcon } from "icons";
import { colors } from "theme/palette";

import Button from "components/Button";
import Container from "components/Container";
import Loading from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";

import ShareAcceptanceForm from "entities/Package/components/ShareAcceptanceForm";
import { useShareToken } from "entities/Package/sdk";

const SharedChecklistClaim = () => {
  const { shareToken: token } = useParams<{
    shareToken: string;
  }>();

  const { data: shareToken, loading } = useShareToken({
    token: token as string,
  });

  return loading || _.isNil(shareToken) ? (
    <Loading open />
  ) : (
    <div>
      <Stack
        height={64}
        sx={{ backgroundColor: colors.blue10 }}
        marginTop={8}
        marginBottom={8}
      >
        <Container maxWidth="sm">
          <Stack direction="row" alignItems="center">
            <LogoIcon height={64} />

            <Text variant="text1" fontWeight={700} marginLeft={2}>
              builders patch
            </Text>
          </Stack>
        </Container>
      </Stack>

      <Container maxWidth="sm">
        <Text variant="h1">Welcome to Builders Patch</Text>

        <Text marginTop={1} marginBottom={3} color={colors.gray80}>
          You have been invited to the Builders Patch platform by{" "}
          <Text
            fontWeight={700}
            sx={{ display: "inline" }}
            color={colors.gray100}
          >
            {shareToken.created_by.first_name} {shareToken.created_by.last_name}
          </Text>{" "}
          of {shareToken.created_by.company.name} to access and help out with a
          due diligence checklist of their deal,{" "}
          <Text
            fontWeight={700}
            sx={{ display: "inline" }}
            color={colors.gray100}
          >
            {shareToken.deal.name}
          </Text>
          .
        </Text>

        <ShareAcceptanceForm
          inviteeEmail={shareToken.email}
          handleSubmit={({ values }) => alert(values)}
        />

        <Stack marginTop={6} marginBottom={6} alignItems="flex-end">
          <Button>Create profile</Button>
        </Stack>
      </Container>
    </div>
  );
};

export default SharedChecklistClaim;

import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { DangerousOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

const AccessDenied = () => {
  const navigate = useNavigate();

  usePageTitle("Builders Patch: Access denied");

  return (
    <>
      <Stack
        sx={{ marginTop: theme.spacing(11) }}
        alignItems="center"
        spacing={6}
      >
        <Stack spacing={1} alignItems="center">
          <DangerousOutlinedIcon
            sx={{ width: "70px", height: "70px", color: colors.red120 }}
          />
          <Text variant="h1" data-testid="error-page-content">
            Access denied.
          </Text>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(reverse(URLS.PROJECTS_LIST, { phase: "All" }))
          }
        >
          Go to Landing page
        </Button>
      </Stack>
    </>
  );
};

export default AccessDenied;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { LockOutlinedIcon } from "icons";
import theme from "theme";
import { useCaching } from "utils/common";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import { usePageTitle } from "components/usePageTitle";

import {
  dealTableDelete,
  dealTableUpdate,
  useDealDetails,
  useDealTable,
} from "entities/Deal/sdk";
import ActionButtons from "entities/TableStorage/components/ActionButtons";
import HeaderSubTitle from "entities/TableStorage/components/HeaderSubTitle";
import Table from "entities/TableStorage/components/Table";
import TableEditModeToggle from "entities/TableStorage/components/TableEditModeToggle";
import TableImportUpdateDialog, {
  IFormValues as ITableImportUpdateDialogFormValues,
} from "entities/TableStorage/components/TableImportUpdateDialog";
import TableSkeleton from "entities/TableStorage/components/TableSkeleton";
import TableUpdateDialogButton from "entities/TableStorage/components/TableUpdateDialogButton";
import { useDealTableUpdateSucceeded } from "entities/TableStorage/hooks";
import {
  ITable,
  tableImportDelete,
  TableUpdateData,
} from "entities/TableStorage/sdk";
import { applyChangesToTableData } from "entities/TableStorage/utils";

const Details = () => {
  const params = useParams<{
    dealId: string;
    tableId: string;
  }>();
  const tableId = Number(params.tableId);
  const dealId = Number(params.dealId);

  const { data: deal } = useDealDetails({ dealId });
  const {
    data: table,
    mutate: mutateTable,
    error: tableError,
  } = useDealTable({
    tableId,
    dealId,
  });

  const [tableData, setTableData] = useCaching<ITable["rows"]>(
    table?.rows || []
  );
  const [editMode, setEditMode] = useState(false);
  const [changedCells, setChangedCells] = useState<{
    [key: string]: string | null;
  }>({});
  const [resetTableData, setResetTableData] = useState(false);
  const [showImportUpdateDialog, setShowImportUpdateDialog] = useState(false);

  const { show: showConfirmationDialog } = useConfirmationDialog();

  useDealTableUpdateSucceeded({
    successEventHandler: () => {
      mutateTable();
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (tableError?.status === 404) {
      navigate(URLS.NOT_FOUND, { replace: true });
    }
  }, [tableError, navigate]);

  usePageTitle(
    !_.isUndefined(table) && !_.isUndefined(deal)
      ? `Builders Patch: ${deal.name} - ${table.name} - Table storage list`
      : "Builders Patch: Table storage list"
  );

  const handleTableUpdate = useCallback(
    ({ values }: { values: TableUpdateData }) =>
      dealTableUpdate({ tableId, dealId, data: values })
        .then(() => {
          toast.successMessage("Table updated successfully!");
          mutateTable();
        })
        .catch(handleInvalidRequest),
    [tableId, dealId, mutateTable]
  );

  const handleTableDelete = useCallback(
    ({ tableId }) =>
      dealTableDelete({ tableId, dealId })
        .then(() => {
          toast.successMessage(`Table deleted successfully`);
          navigate(reverse(URLS.DEAL_TABLE_STORAGE_LIST, { dealId }));
        })
        .catch(handleInvalidRequest),
    [dealId, navigate]
  );
  const enableEditMode = useCallback(() => setEditMode(true), [setEditMode]);

  const disableEditMode = useCallback(() => setEditMode(false), [setEditMode]);

  const confirmDisableEditMode = useCallback(
    () =>
      showConfirmationDialog({
        onConfirm: () => {
          setTableData(table?.rows || []);
          disableEditMode();
          setChangedCells({});
          setResetTableData(true);
        },
        confirmButtonText: "Discard",
        message: "Discard changes?",
      }),
    [
      disableEditMode,
      showConfirmationDialog,
      table,
      setChangedCells,
      setTableData,
    ]
  );

  const handleSetEditMode = useCallback(() => {
    // When the user want to disable the edit mode but there are changes
    if (editMode && !_.isEmpty(changedCells)) {
      confirmDisableEditMode();
    } else if (!editMode) {
      enableEditMode();
    } else {
      disableEditMode();
    }
  }, [
    confirmDisableEditMode,
    disableEditMode,
    enableEditMode,
    changedCells,
    editMode,
  ]);

  const handleUpdateTableData = useCallback(
    ({ key, currentValue }) => {
      setTableData(tableData);
      setChangedCells((prev) => ({
        ...prev,
        [key]: currentValue,
      }));
      setResetTableData(false);
    },
    [setChangedCells, setTableData, tableData]
  );

  const handleSaveChanges = useCallback(() => {
    const tableDataWithChangedCells = applyChangesToTableData({
      tableData,
      changedCells,
    });

    const updatedTableData = tableDataWithChangedCells.map((row) => row.cells);

    handleTableUpdate({
      values: { rows: updatedTableData },
    }).then(() => {
      disableEditMode();
      setChangedCells({});
    });
  }, [tableData, handleTableUpdate, disableEditMode, changedCells]);

  const handleDeleteImport = useCallback(() => {
    if (_.isNil(table?.deal_company_import_table?.id)) {
      return;
    }

    showConfirmationDialog({
      onConfirm: () =>
        tableImportDelete({
          tableImportId: table?.deal_company_import_table?.id as number,
        })
          .then(() => {
            toast.successMessage("Import table deleted successfully");
            mutateTable();
          })
          .catch(handleInvalidRequest),
      confirmButtonText: "Yes",
      message: "Are you sure you want to delete the range?",
    });
  }, [table, mutateTable, showConfirmationDialog]);

  const onSaveChanges = useCallback(() => {
    showConfirmationDialog({
      onConfirm: handleSaveChanges,
      confirmButtonText: "Yes",
      message: "Are you sure you want to update the table data?",
    });
  }, [handleSaveChanges, showConfirmationDialog]);

  const onCancelChanges = useCallback(() => {
    setChangedCells({});
    setResetTableData(true);
  }, []);

  const showButtons = useMemo(
    () => !_.isUndefined(table) && !table.is_locked,
    [table]
  );

  const showEditModeButton = useMemo(
    () => showButtons && !_.isEmpty(tableData),
    [showButtons, tableData]
  );

  const tableHasImport = useMemo(
    () => !_.isNil(table?.deal_company_import_table),
    [table]
  );

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          spacing={4}
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader
            title={
              _.isUndefined(table) ? (
                <Stack
                  direction="row"
                  spacing={theme.spacing(1)}
                  alignItems="center"
                >
                  <Skeleton height={55} width={250} />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={theme.spacing(1)}
                  alignItems="center"
                >
                  <Text variant="h2" data-testid="page-header-name">
                    {table.name}
                  </Text>
                  {table.is_locked && (
                    <Tooltip title="This table is locked. The data is coming from the underwriting proforma upload.">
                      <LockOutlinedIcon
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              )
            }
            subTitle={
              <HeaderSubTitle
                table={table}
                importDeleteCallback={() => {
                  mutateTable();
                }}
                showImportUpdateDialog={() => setShowImportUpdateDialog(true)}
              />
            }
            backTitle="Saved data tables"
            backLink={reverse(URLS.DEAL_TABLE_STORAGE_LIST, { dealId })}
            actions={
              _.isUndefined(table) ? (
                <Skeleton height={60} width={50} />
              ) : (
                <ActionButtons
                  dealId={dealId}
                  tableId={table.id}
                  initialValue={table.name}
                  onUpdate={handleTableUpdate}
                  onDelete={() =>
                    handleTableDelete({
                      tableId: table.id,
                    })
                  }
                  onImportDelete={
                    tableHasImport ? handleDeleteImport : undefined
                  }
                  onImportUpdate={
                    tableHasImport
                      ? () => setShowImportUpdateDialog(true)
                      : undefined
                  }
                />
              )
            }
          />
          <Stack spacing={2}>
            <Box>
              {_.isUndefined(table) && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Skeleton height={63} width={150} />
                  <Skeleton height={63} width={150} />
                </Stack>
              )}
              {showButtons && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {showEditModeButton && (
                    <TableEditModeToggle
                      checked={editMode}
                      onToggleChange={handleSetEditMode}
                    />
                  )}
                  <TableUpdateDialogButton
                    disabled={editMode}
                    onUpdate={handleTableUpdate}
                  />
                </Stack>
              )}
            </Box>
            {_.isUndefined(tableData) ? (
              <TableSkeleton />
            ) : (
              <Table
                tableData={tableData}
                editMode={editMode}
                resetTableData={resetTableData}
                updateTableData={handleUpdateTableData}
              />
            )}
            {editMode && (
              <Stack direction="row" spacing={1} alignSelf="flex-end">
                <Button variant="outlined" onClick={onCancelChanges}>
                  Cancel changes
                </Button>
                <Button sx={{ alignSelf: "flex-end" }} onClick={onSaveChanges}>
                  Save changes
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {!_.isNil(table?.deal_company_import_table) && (
        <TableImportUpdateDialog
          updateCallback={mutateTable}
          show={showImportUpdateDialog}
          onClose={() => setShowImportUpdateDialog(false)}
          tableImportId={table?.deal_company_import_table?.id as number}
          initialValues={
            _.pick(table?.deal_company_import_table, [
              "sheet_name",
              "column_start",
              "column_end",
              "row_start",
              "row_end",
            ]) as ITableImportUpdateDialogFormValues
          }
        />
      )}
    </Paper>
  );
};

export default Details;

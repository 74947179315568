export const LINK_ID_TO_DEAL_FIELD_MAPPING: { [key: string]: string } = {
  name: "Deal Name",
  legal_name: "Deal Legal Name",
  status: "Deal Status",
  project_type: "Project Type",
  rent_type: "Rent Type",
  construction_type: "Construction Type",
  address_latitude: "Address Latitude",
  address_longitude: "Address Longitude",
  address_state: "Address State",
  address_city: "Address City",
  address_zipcode: "Address Zipcode",
  address_street: "Address Street",
  address_country: "Address Country",
};

import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { fieldOptionMultipleRetrieve } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import EditButton from "entities/Field/components/Checkboxfield/EditButton";
import FieldDropdownMenu from "entities/Field/components/FieldDropdownMenu";
import FieldTitle from "entities/Field/components/Title";
import { fieldValueCreate, IField } from "entities/Field/sdk";
import { IFieldOption } from "entities/Package/sdk";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

export interface ICheckboxfield {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  dealId: number;
  packageId: number;
  categoryId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  isFocused?: boolean;
}
const Checkboxfield = ({
  editable,
  dealId,
  taskChannels,
  updatePackage,
  field,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
  isFocused = false,
}: ICheckboxfield) => {
  const [isOpen, setIsOpen] = useState(false);

  const [value, setValue] = useState<Array<IFieldOption> | null>(
    fieldOptionMultipleRetrieve({
      options: field.options,
      value: _.get(field.custom_values, "[0].selected_value"),
    })
  );

  useEffect(() => {
    setValue(
      fieldOptionMultipleRetrieve({
        options: field.options,
        value: _.get(field.custom_values, "[0].selected_value"),
      })
    );
  }, [field.options, field.custom_values]);

  const displayValue = useMemo(
    () => value?.map((v) => v.label).join(", "),
    [value]
  );

  const handleEditButtonClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleSave = useCallback(
    ({ newValue }: { newValue: Array<IFieldOption> | null }) => {
      setValue(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.field_id,
        value: newValue?.map((v) => v.value).join(",") || null,
      })
        .then(() => {
          setIsOpen(false);
          onUpdate({ message: `${field.display_name} successfully updated!` });
        })
        .catch(handleInvalidRequest);
    },
    [field, dealId, setValue, onUpdate]
  );

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setValue(
      fieldOptionMultipleRetrieve({
        options: field.options,
        value: _.get(field.custom_values, "[0].selected_value"),
      })
    );
  }, [setValue, field]);

  const valueIsMissing = useMemo(
    () => _.isEmpty(value) || _.isNil(value),
    [value]
  );

  return (
    <Stack
      id={field.field_id.toString()}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${colors.blue20}`,
        backgroundColor: isFocused ? colors.green10 : "initial",
      }}
      data-testid="dd-field"
    >
      <Box sx={{ flexShrink: 1, maxWidth: "100%", overflow: "hidden" }}>
        <FieldTitle
          field={field}
          dealId={dealId}
          packageId={packageId}
          categoryId={categoryId}
          tabId={tabId}
          sectionId={sectionId}
          onUpdate={onUpdate}
        />
      </Box>
      <Stack direction="row" spacing={1} alignItems="center">
        {taskChannels.length > 0 && (
          <DueDiligenceFieldOpenTaskButton
            taskChannels={taskChannels}
            company_field={field}
            dealId={dealId}
            tabId={tabId}
            sectionId={sectionId}
            categoryId={categoryId}
            packageId={packageId}
            updatePackage={updatePackage}
            taskButtonDataTestid="dd-open-task-button"
            taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
          />
        )}
        {field.n_a_status ? (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        ) : (
          <>
            {valueIsMissing ? (
              <Tag
                label="missing"
                variant="red"
                dataTestid="empty-field-value"
              />
            ) : (
              <Text variant="text2" textAlign="right" data-testid="field-value">
                {displayValue}
              </Text>
            )}
            {editable && (
              <EditButton
                isOpen={isOpen}
                name={field.display_name}
                options={field.options}
                initialValue={value}
                onSave={handleSave}
                onCancel={handleCancel}
                onClick={handleEditButtonClick}
                buttonDataTestid="field-edit-button"
              />
            )}
          </>
        )}
        <FieldDropdownMenu
          field={field}
          dealId={dealId}
          packageId={packageId}
          categoryId={categoryId}
          tabId={tabId}
          sectionId={sectionId}
          onUpdate={onUpdate}
          menuButtonDataTestid="dd-field-actions-button"
        />
      </Stack>
    </Stack>
  );
};

export default Checkboxfield;

import { IPackageDetailsField } from "entities/Checklist/sdk";
import { FieldType } from "entities/Field/sdk";
import MemoCheckboxfield from "entities/Memo/components/Fields/Checkboxfield";
import MemoDropdownField from "entities/Memo/components/Fields/Dropdownfield";
import MemoFilefield from "entities/Memo/components/Fields/Filefield";
import MemoFixedTableField from "entities/Memo/components/Fields/FixedTableField";
import MemoFormTextField from "entities/Memo/components/Fields/FormTextField";
import MemoRadiobuttonfield from "entities/Memo/components/Fields/Radiobuttonfield";
import MemoRichtextfield from "entities/Memo/components/Fields/Richtextfield";
import MemoSignaturefield from "entities/Memo/components/Fields/Signaturefield";
import MemoTablefield from "entities/Memo/components/Fields/Tablefield";
import MemoTextfield from "entities/Memo/components/Fields/Textfield";

interface IDDSectionWrapper {
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
}

const DDSectionWrapper = ({
  field,
  dealId,
  onUpdate,
  isNASection,
  externalToken,
}: IDDSectionWrapper) => {
  switch (field.field_type) {
    case FieldType.FORM_TEXT_FIELD:
      return (
        <MemoFormTextField
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.DROPDOWN:
      return (
        <MemoDropdownField
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.CHECKBOX:
      return (
        <MemoCheckboxfield
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.RADIO_BUTTON:
      return (
        <MemoRadiobuttonfield
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.TEXT_FIELD:
      return (
        <MemoTextfield
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.RICH_TEXT_FIELD:
      return (
        <MemoRichtextfield
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.SIGNATURE:
      return <MemoSignaturefield field={field} isNASection={isNASection} />;
    case FieldType.TABLE:
      return (
        <MemoTablefield
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.FIXED_TABLE:
      return (
        <MemoFixedTableField
          field={field}
          dealId={dealId}
          externalToken={externalToken}
          onUpdate={onUpdate}
          isNASection={isNASection}
        />
      );
    case FieldType.FILE:
      return (
        <MemoFilefield
          field={field}
          dealId={dealId}
          onUpdate={onUpdate}
          externalToken={externalToken}
          isNASection={isNASection}
        />
      );
  }
  return null;
};

export default DDSectionWrapper;

import { useCallback, useState } from "react";
import _ from "lodash";

import {
  DeleteOutlineOutlinedIcon,
  ModeEditOutlineOutlinedIcon,
  OpenInNewIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import IconButton from "components/IconButton";
import Link from "components/Link";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";
import { usePageTitle } from "components/usePageTitle";

import FormDialog from "entities/Bookmark/FormDialog";
import { bookmarkDelete, IBookmark, useBookmarks } from "entities/Bookmark/sdk";

const Bookmarks = () => {
  usePageTitle("Builders Patch: Bookmarks");

  const [bookmarkInEditProcess, setBookmarkInEditProcess] =
    useState<IBookmark | null>(null);

  const { data, isLoading, mutate: refetchBookmarks } = useBookmarks();
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const bookmarkGetURL = ({ bookmark }: { bookmark: IBookmark }) => {
    const url = new URL(bookmark.pathname, window.location.origin);

    return url.toString();
  };

  const handleDelete = useCallback(
    ({ bookmark }) => {
      showConfirmationDialog({
        onConfirm: () =>
          bookmarkDelete({ bookmarkId: bookmark.id })
            .then(() => {
              refetchBookmarks();
              toast.successMessage("Bookmark deleted successfully");
            })
            .catch(handleInvalidRequest),
        confirmButtonText: "Yes, delete",
        message: "Are you sure you no longer need this bookmark?",
      });
    },
    [refetchBookmarks, showConfirmationDialog]
  );

  const handleUpdate = useCallback(({ bookmark }) => {
    setBookmarkInEditProcess(bookmark);
  }, []);

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Box
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader
            title="Bookmarks"
            subTitle="Bookmarks are shortcuts that take you directly to the pages you need to focus on. They are tied to your personal account and are only visible to you."
          />

          {!isLoading && data?.length === 0 && (
            <Text
              marginTop={4}
              variant="text3"
              paddingLeft={2}
              color={colors.gray80}
            >
              0 bookmarks created
            </Text>
          )}

          {_.get(data, "length", 0) > 0 && (
            <Stack marginY={4}>
              <Text
                variant="text3"
                color={colors.gray80}
                paddingBottom={1}
                sx={{
                  paddingLeft: 2,
                  borderBottom: `1px solid ${colors.gray20}`,
                }}
              >
                {data?.length} bookmark{data?.length !== 1 && "s"} created
              </Text>
              {data?.map((bookmark) => (
                <Stack
                  spacing={6}
                  direction="row"
                  alignItems="center"
                  sx={{
                    padding: theme.spacing(3, 3, 3, 2),
                    borderBottom: `1px solid ${colors.gray20}`,
                  }}
                >
                  <Text variant="text1" fontWeight={700} flexBasis="45%" noWrap>
                    {bookmark.name}
                  </Text>
                  <Text
                    flexBasis="35%"
                    color={colors.gray60}
                    variant="text2"
                    noWrap
                  >
                    {bookmarkGetURL({ bookmark })}
                  </Text>

                  <Stack
                    flexBasis="20%"
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Tooltip title="Edit bookmark">
                      <IconButton>
                        <ModeEditOutlineOutlinedIcon
                          onClick={() => handleUpdate({ bookmark })}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete bookmark">
                      <IconButton onClick={() => handleDelete({ bookmark })}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Link to={bookmark.pathname}>
                      <Tooltip title="Open this page">
                        <IconButton>
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}

          {isLoading &&
            _.range(10).map((i) => <Skeleton key={i} height={100} />)}
        </Box>
      </Stack>

      <FormDialog
        bookmark={bookmarkInEditProcess}
        isOpen={!_.isNil(bookmarkInEditProcess)}
        onClose={() => setBookmarkInEditProcess(null)}
      />
    </Paper>
  );
};

export default Bookmarks;

import React, { useCallback, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import FormControlLabel from "components/FormControlLabel";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";

import { IFieldOption } from "entities/Package/sdk";

interface ICheckboxfieldEditButton {
  isOpen: boolean;
  name: string;
  options: Array<IFieldOption>;
  initialValue: Array<IFieldOption> | null;
  onSave: ({ newValue }: { newValue: Array<IFieldOption> | null }) => void;
  onCancel: () => void;
  onClick: () => void;
  buttonDataTestid?: string;
}

const CheckboxfieldEditButton: React.FC<ICheckboxfieldEditButton> = ({
  isOpen,
  name,
  options,
  initialValue,
  onSave,
  onCancel,
  onClick,
  buttonDataTestid,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleSave = useCallback(
    () => onSave({ newValue: value }),
    [onSave, value]
  );

  const handleCancel = useCallback(() => {
    onCancel();
    setValue(initialValue);
  }, [onCancel, setValue, initialValue]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const handleChangeOption = useCallback(
    (item: IFieldOption) => {
      const newValues = _.xorBy(value, [item], "value");
      setValue(newValues);
    },
    [setValue, value]
  );

  const optionIsChecked = useCallback(
    (option: IFieldOption) =>
      value?.map((option) => option.value).includes(option.value),
    [value]
  );

  return (
    <>
      <IconButton size="small" onClick={onClick} dataTestid={buttonDataTestid}>
        <EditIcon />
      </IconButton>
      {isOpen && (
        <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleCancel}>
          <Box
            sx={{ padding: theme.spacing(1) }}
            data-testid="checkbox-field-edit-dialog"
          >
            <DialogTitle data-testid="checkbox-edit-dialog-name">
              {name}
            </DialogTitle>
            <Stack sx={{ paddingX: theme.spacing(3) }}>
              {_.map(options, (defaultValue, index) => (
                <FormControlLabel
                  key={index}
                  label={
                    <Text variant="label" data-testid="checkbox-label">
                      {defaultValue.label}
                    </Text>
                  }
                  control={
                    <Checkbox
                      checked={optionIsChecked(defaultValue)}
                      onChange={() => handleChangeOption(defaultValue)}
                      data-testid="checkbox-option"
                    />
                  }
                ></FormControlLabel>
              ))}
            </Stack>
            <DialogActions sx={{ marginTop: theme.spacing(2) }}>
              <Button
                variant="text"
                color="error"
                onClick={handleReset}
                data-testid="reset-button"
              >
                Reset
              </Button>
              <Button
                variant="text"
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default CheckboxfieldEditButton;

import React, { ReactNode, useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Avatar from "components/Avatar";
import Box from "components/Box";

import { TABS } from "entities/Deal/components/Sidepanel/utils";

export interface ISidebarLink {
  value: TABS;
  selected: boolean;
  icon: ReactNode;
  onClick: ({ key }: { key: TABS }) => void;
}

const SidebarLink: React.FC<ISidebarLink> = ({
  icon,
  selected,
  value,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick({ key: value });
  }, [value, onClick]);

  const styles = selected
    ? {
        backgroundColor: colors.blue40,
        "> div svg": { color: colors.gray100 },
      }
    : {};

  return (
    <Box
      onClick={handleClick}
      sx={{
        padding: theme.spacing(2, 0),
        cursor: "pointer",
        ...styles,
        "&:hover": {
          backgroundColor: colors.blue20,
          "> div svg": {
            color: colors.gray100,
          },
        },
      }}
    >
      <Avatar
        variant="square"
        sx={{
          margin: "auto",
          backgroundColor: "unset",
        }}
      >
        {icon}
      </Avatar>
    </Box>
  );
};

export default SidebarLink;

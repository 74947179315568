import React from "react";

import { LockIcon } from "icons";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import { TableCell } from "components/Table";
import Text from "components/Text";

interface ITitleCell {
  taskTitle: string;
  isPrivate: boolean;
}

const TitleCell: React.FC<ITitleCell> = ({ taskTitle, isPrivate }) => {
  return (
    <TableCell
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      <Stack spacing={1} direction="row">
        {isPrivate && <LockIcon />}
        <Text variant="text2">{taskTitle}</Text>
      </Stack>
    </TableCell>
  );
};

export default TitleCell;

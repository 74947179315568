import _ from "lodash";

import { stringConvertToBoolean } from "utils/common";

import { IDownhomeOwnerProperties } from "entities/CompanyItegrations/components/Downhome/BusinessForm/AddOwnerButton/constants";
import {
  ADDRESS_CITY_KEYS,
  ADDRESS_STATE_KEYS,
  ADDRESS_STREET_KEYS,
  EMAIL_KEYS,
  OWNER_DATE_OF_BIRTH_KEYS,
  OWNER_ETHNIC_GROUP_KEYS,
  OWNER_FIRST_NAME_KEYS,
  OWNER_GENDER_KEYS,
  OWNER_LAST_NAME_KEYS,
  OWNER_VETERAN_STATUS_KEYS,
  PHONE_NUMBER_KEYS,
  PRIMARY_KEYS,
  WEBSITE_URL_KEYS,
} from "entities/CompanyItegrations/components/Downhome/BusinessForm/constants";
import {
  IDownhomeAddress,
  IDownhomeEmail,
  IDownhomePersonParticipant,
  IDownhomePhone,
  IDownhomeWebsite,
} from "entities/CompanyItegrations/sdk";

import { EListFieldType } from "./constants";

export const getButtonLabel = ({ type }: { type: EListFieldType }): string => {
  if (type === EListFieldType.ADDRESSES) {
    return "Add Address";
  }

  if (type === EListFieldType.EMAILS) {
    return "Add Email";
  }

  if (type === EListFieldType.PHONES) {
    return "Add Phone";
  }

  if (type === EListFieldType.WEBSITES) {
    return "Add Website";
  }

  if (type === EListFieldType.OWNERS) {
    return "Add Owner";
  }

  return "Add";
};

export const getEmptyFieldValues = ({
  type,
}: {
  type: EListFieldType;
}):
  | IDownhomeWebsite
  | IDownhomePhone
  | IDownhomeEmail
  | IDownhomeAddress
  | IDownhomePersonParticipant => {
  if (type === EListFieldType.PHONES) {
    return { primary: false, number: "" };
  }

  if (type === EListFieldType.EMAILS) {
    return { primary: false, address: "" };
  }

  if (type === EListFieldType.WEBSITES) {
    return { primary: false, url: "" };
  }

  if (type === EListFieldType.ADDRESSES) {
    return { primary: false, state: "", city: "", street1: "" };
  }

  if (type === EListFieldType.OWNERS) {
    return {
      primary: false,
      first_name: "",
      last_name: "",
      date_of_birth: null,
      veteran_status: null,
      gender: null,
      ethnic_group: null,
    };
  }

  return { primary: false, number: "" };
};

export const pickObjectValueByKeys = ({
  object,
  keys,
}: {
  object: { [key: string]: string };
  keys: string[];
}) =>
  _.first(
    _.values(
      _.pickBy(
        object,
        (_, key) =>
          keys.filter((optionKey) => key.toLowerCase().startsWith(optionKey))
            .length > 0
      )
    )
  ) || "";

export const convertBusinessParticipantDataToFormData = ({
  data,
  type,
}: {
  data: Array<{ [key: string]: string }>;
  type: EListFieldType;
}):
  | IDownhomeAddress[]
  | IDownhomeEmail[]
  | IDownhomePhone[]
  | IDownhomeWebsite[]
  | IDownhomeOwnerProperties[]
  | null => {
  if (type === EListFieldType.PHONES) {
    return data.map((x) => ({
      primary: stringConvertToBoolean({
        value: pickObjectValueByKeys({ object: x, keys: PRIMARY_KEYS }),
      }),
      number: pickObjectValueByKeys({ object: x, keys: PHONE_NUMBER_KEYS }),
    }));
  }

  if (type === EListFieldType.EMAILS) {
    return data.map((x) => ({
      address: pickObjectValueByKeys({ object: x, keys: EMAIL_KEYS }),
      primary: stringConvertToBoolean({
        value: pickObjectValueByKeys({ object: x, keys: PRIMARY_KEYS }),
      }),
    }));
  }

  if (type === EListFieldType.WEBSITES) {
    return data.map((x) => ({
      primary: stringConvertToBoolean({
        value: pickObjectValueByKeys({ object: x, keys: PRIMARY_KEYS }),
      }),
      url: pickObjectValueByKeys({ object: x, keys: WEBSITE_URL_KEYS }),
    }));
  }

  if (type === EListFieldType.ADDRESSES) {
    return data.map((x) => ({
      primary: stringConvertToBoolean({
        value: pickObjectValueByKeys({ object: x, keys: PRIMARY_KEYS }),
      }),
      state: pickObjectValueByKeys({ object: x, keys: ADDRESS_STATE_KEYS }),
      city: pickObjectValueByKeys({ object: x, keys: ADDRESS_CITY_KEYS }),
      street1: pickObjectValueByKeys({
        object: x,
        keys: ADDRESS_STREET_KEYS,
      }),
    }));
  }

  if (type === EListFieldType.OWNERS) {
    return data.map((x) => ({
      primary: stringConvertToBoolean({
        value: pickObjectValueByKeys({ object: x, keys: PRIMARY_KEYS }),
      }),
      first_name: pickObjectValueByKeys({
        object: x,
        keys: OWNER_FIRST_NAME_KEYS,
      }),
      last_name: pickObjectValueByKeys({
        object: x,
        keys: OWNER_LAST_NAME_KEYS,
      }),
      date_of_birth: pickObjectValueByKeys({
        object: x,
        keys: OWNER_DATE_OF_BIRTH_KEYS,
      }),
      gender: pickObjectValueByKeys({
        object: x,
        keys: OWNER_GENDER_KEYS,
      }),
      ethnic_group: pickObjectValueByKeys({
        object: x,
        keys: OWNER_ETHNIC_GROUP_KEYS,
      }),
      veteran_status: pickObjectValueByKeys({
        object: x,
        keys: OWNER_VETERAN_STATUS_KEYS,
      }),
      key: x.key,
    }));
  }

  return null;
};

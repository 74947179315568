// This file is a copy of entities/Fields/Textfield, but without all the functionality
import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import FieldTitle from "entities/Package/components/PdfImport/Fields/Title";
import { IPackageField } from "entities/Package/sdk";

export interface ITextfield {
  field: IPackageField;
  editable: boolean;
  parsedValue: string | undefined | null;
  onUpdate: ({
    fieldId,
    value,
  }: {
    fieldId: number;
    value: string | undefined | null;
  }) => void;
}

const Textfield = ({ field, editable, parsedValue, onUpdate }: ITextfield) => {
  const [isEdit, setIsEdit] = useState(false);
  const fieldValue = useMemo(
    () => _.get(field.values, "[0].default_value"),
    [field]
  );
  const [value, setValue] = useCaching<string | undefined | null>(parsedValue);

  const handleEditButtonClick = useCallback(() => setIsEdit(true), []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue]
  );
  const handleSave = useCallback(() => {
    onUpdate({ fieldId: field.field_id, value });
    setIsEdit(false);
  }, [field, value, onUpdate]);

  const handleCancel = useCallback(() => {
    setValue(parsedValue);
    onUpdate({ fieldId: field.field_id, value: parsedValue });
    setIsEdit(false);
  }, [setValue, field, parsedValue, onUpdate]);

  const showFieldValue = useMemo(
    () => !isEdit && fieldValue && !value,
    [fieldValue, value, isEdit]
  );
  const showParsedValue = useMemo(() => !isEdit && value, [isEdit, value]);
  const showEditButton = useMemo(() => !isEdit && editable, [isEdit, editable]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${colors.blue20}`,
      }}
      data-testid="dd-field"
    >
      <Box sx={{ flexShrink: 0 }}>
        <FieldTitle field={field} editable={editable} />
      </Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ flexGrow: 0 }}
      >
        {field.n_a_status ? (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        ) : (
          <>
            {showFieldValue && (
              <Text
                variant="text2"
                sx={{ wordBreak: "break-word" }}
                data-testid="field-value"
              >
                {fieldValue}
              </Text>
            )}
            {showParsedValue && (
              <Text
                variant="text2"
                sx={{ wordBreak: "break-word" }}
                data-testid="field-value"
              >
                {value}
              </Text>
            )}
            {showEditButton && (
              <IconButton
                size="small"
                onClick={handleEditButtonClick}
                dataTestid="field-edit-button"
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
        {isEdit && (
          <Stack spacing={1} direction="row" alignItems="center">
            <TextField
              size="small"
              defaultValue={value}
              onChange={handleChange}
              data-testid="edit-textfield"
              inputProps={{ "data-testid": "edit-textfield-input" }}
            />
            <Button onClick={handleSave} data-testid="submit-button">
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Textfield;

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

import FeaturesLimit from "entities/Company/components/CompanySettings//FeaturesLimit";
import FeaturesList from "entities/Company/components/CompanySettings/FeaturesList";
import { useCompanySettings } from "entities/Company/sdk";
// Used for anchoring
export const ID = "company_settings_subscription_details";

export interface ISubscriptionDetails {}

const SubscriptionDetails = () => {
  const { data: companySettings } = useCompanySettings({});

  return (
    <Stack id={ID} sx={{ padding: theme.spacing(2, 3) }} spacing={6}>
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colors.gray100}` }}
        >
          <Text variant="text1" fontWeight={700}>
            Subscription details
          </Text>
        </Stack>
        <Text variant="text2" fontWeight={700} color={colors.gray100}>
          Platform features
        </Text>
        {companySettings && <FeaturesList companySettings={companySettings} />}

        <Text variant="text2" fontWeight={700} color={colors.gray100}>
          Item limitations (max. allowed)
        </Text>

        {companySettings && <FeaturesLimit companySettings={companySettings} />}
      </Stack>
      <Box
        padding={theme.spacing(2, 3)}
        borderRadius="8px"
        sx={{ backgroundColor: colors.gray10 }}
      >
        <Text variant="text2" color={colors.gray120} fontWeight={700}>
          How to request changes to your subscription
        </Text>
        <Text variant="text4" color={colors.gray100}>
          To enable more features or increase the maximum allowed items, please
          contact the Customer Success team.
        </Text>
      </Box>
    </Stack>
  );
};

export default SubscriptionDetails;

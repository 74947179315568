import { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Table, { TableBody, TableCell, TableRow } from "components/Table";
import Text from "components/Text";

import { PROPERTY_TYPES, RENT_TYPES } from "entities/Deal/constants";
import { IDeal } from "entities/Deal/sdk";
import { getConstructionTypeLabelByValue } from "entities/Deal/utils";
import { useDealCoreDataManager } from "entities/Memo/utils";

interface IProjectTable {
  deal: IDeal;
  externalToken?: string;
}

const ProjectTable: React.FC<IProjectTable> = ({ deal, externalToken }) => {
  const { data: coreData } = useDealCoreDataManager({
    dealId: deal.id,
    token: externalToken,
  });

  const projectType = useMemo(
    () =>
      _.get(
        _.find(PROPERTY_TYPES, (item) => item.value === deal.project_type),
        "label"
      ),
    [deal.project_type]
  );

  const rentType = useMemo(
    () =>
      _.get(
        _.find(RENT_TYPES, (item) => item.value === deal.rent_type),
        "label"
      ),
    [deal.rent_type]
  );

  const showTotalUnits = useMemo(() => !_.isNil(coreData), [coreData]);

  return (
    <Table size="small">
      <TableBody data-testid="deal-section-data">
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Project name
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-name">
              {deal.name}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Project's legal name
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-legal-name">
              {deal.legal_name}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Property type
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-type">
              {projectType}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Phase
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-phase">
              {deal.phase?.name}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Status
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-status">
              {deal.status}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Construction type
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-construction-type">
              {getConstructionTypeLabelByValue({
                value: deal.construction_type,
              })}
            </Text>
          </TableCell>
        </TableRow>
        {showTotalUnits && (
          <TableRow
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue40}`,
              },
            }}
          >
            <TableCell
              sx={{
                paddingLeft: theme.spacing(1),
              }}
            >
              <Text
                variant="text2"
                fontWeight="300"
                sx={{ color: colors.gray100 }}
              >
                Total units
              </Text>
            </TableCell>
            <TableCell align="right" sx={{ paddingRight: 0 }}>
              <Text variant="text2" data-testid="deal-total-units">
                {coreData?.total_units}
              </Text>
            </TableCell>
          </TableRow>
        )}
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue40}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            <Text
              variant="text2"
              fontWeight="300"
              sx={{ color: colors.gray100 }}
            >
              Rent type
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="deal-rent-type">
              {rentType}
            </Text>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ProjectTable;

import { memo, useMemo } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Card, { CardActionArea, CardContent, CardMedia } from "components/Card";
import Link from "components/Link";
import { LinearProgress } from "components/Loading";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { OwnershipType } from "entities/Deal/constants";

interface IRow {
  [key: string]: string | null;
}

export interface IDealCard {
  row: IRow;
  loading: boolean;
}

const DealCard = ({ row, loading }: IDealCard) => {
  const {
    STATE: state,
    CITY: city,
    Borough: borough,
    "NUMBER OF UNITS": units,
  } = row;
  const dealId = row["DEAL ID"];
  const name = row["Project Name"];
  const phase = row["Project Phase"];
  const ownership = row["Ownership Type"] as OwnershipType;
  const companyName = row["Owned By"];
  const imageUrl: string | undefined = row["Project Avatar"] || undefined;

  const userCompanyIsOwner = useMemo(
    () => ownership === OwnershipType.OWNER,
    [ownership]
  );

  return (
    <Card
      sx={{
        boxShadow: `0 4px 8px 0 ${colors.gray20}`,
        transition: "boxShadow 0 linear",
        "&:hover": { boxShadow: `0 4px 8px 0 ${colors.gray60}` },
      }}
      data-testid="deal-card"
    >
      <Link
        to={dealId ? reverse(URLS.DEAL_OVERVIEW, { dealId }) : "#"}
        linkDataTestid="deal-link"
      >
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            "&:hover": { backgroundColor: colors.gray5 },
          }}
          data-testid="deal-card-content"
        >
          <Box sx={{ width: "100%", position: "relative" }}>
            <CardMedia
              component="img"
              imgixHeight={800}
              imgixWidth={800}
              sx={{ height: 160, width: "100%" }}
              imgix
              src={imageUrl}
            />
            {!_.isNull(companyName) && !userCompanyIsOwner && (
              <Tooltip title={`Deal owned by ${companyName}`} placement="top">
                <Box
                  sx={{
                    fontSize: "11px",
                    lineHeight: "13px",
                    fontWeight: 400,
                    padding: theme.spacing(0.375, 0.75),
                    color: colors.gray80,
                    backgroundColor: colors.white,
                    position: "absolute",
                    left: theme.spacing(2),
                    bottom: theme.spacing(1),
                    borderRadius: "4px",
                  }}
                  data-testid="deal-owner-company"
                >
                  {companyName}
                </Box>
              </Tooltip>
            )}
          </Box>
          <CardContent sx={{ flex: 1, padding: theme.spacing(1, 2, 2, 2) }}>
            <Stack direction="row" spacing={theme.spacing(0.5)}>
              {!_.isNull(ownership) && (
                <Tag
                  label={ownership}
                  sx={{ display: "inline-block" }}
                  variant={userCompanyIsOwner ? "gray100" : "gray60"}
                  dataTestid="deal-ownership"
                />
              )}
              {!_.isNull(phase) && (
                <Tag
                  dataTestid="deal-phase"
                  label={phase}
                  sx={{ display: "inline-block" }}
                />
              )}
            </Stack>
            <Text
              data-testid="deal-name"
              gutterBottom
              variant="text2"
              fontWeight="500"
              marginTop={1}
              marginBottom={0}
            >
              {name}
            </Text>
            <Text
              variant="text3"
              color="text.secondary"
              data-testid="deal-location-data"
            >
              {loading ? (
                <Skeleton width={150} />
              ) : (
                <>
                  {[borough, city, state].filter((x) => x).join(", ")}{" "}
                  {units && <>• {units} units</>}
                </>
              )}
            </Text>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

const DealGrid = ({
  rows,
  isValidating,
  showLoading,
}: {
  rows: IRow[];
  isValidating: boolean;
  showLoading: boolean;
}) => {
  return (
    <Box>
      <Box sx={{ height: "10px" }}>
        {(showLoading || isValidating) && <LinearProgress />}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: theme.spacing(2),
        }}
        data-testid="deals-cards-results"
      >
        {rows?.map((row) => (
          <DealCard key={row["DEAL ID"]} row={row} loading={showLoading} />
        ))}

        {showLoading &&
          _.isEmpty(rows) &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
            <Skeleton key={x} variant="rectangular" height={200} />
          ))}
      </Box>
    </Box>
  );
};

export default memo(
  DealGrid,
  (prevProps, nextProps) =>
    prevProps.isValidating === nextProps.isValidating &&
    prevProps.showLoading === nextProps.showLoading &&
    _.isEqual(prevProps.rows, nextProps.rows)
);

import { useCallback } from "react";

import FormControlLabel from "components/FormControlLabel";
import Switch from "components/Switch";
import Text from "components/Text";

interface IAssetManagementToggle {
  label: string;
  onToggleChange: (checked: boolean) => void;
  checked?: boolean;
  dataTestid?: string;
}
const AssetManagementToggle: React.FC<IAssetManagementToggle> = ({
  label,
  onToggleChange,
  checked,
  dataTestid,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onToggleChange(event.target.checked);
    },
    [onToggleChange]
  );

  return (
    <FormControlLabel
      label={
        <Text variant="text2" sx={{ fontWeight: 500 }} data-testid="label">
          {label}:
        </Text>
      }
      control={
        <Switch
          defaultChecked={checked === undefined}
          checked={checked}
          onChange={handleChange}
          data-testid="toggle"
        />
      }
      labelPlacement="start"
      data-testid={dataTestid}
    />
  );
};

export default AssetManagementToggle;

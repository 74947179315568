import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import { useDealPackageCompanyList } from "entities/Deal/sdk";

export interface ICompaniesForm {
  dealId: number;
  companyPackageId: number;
  onSubmit: ({ selectedCompanies }: { selectedCompanies: number[] }) => void;
  onCancel: () => void;
}

const CompaniesForm = ({
  dealId,
  companyPackageId,
  onSubmit,
  onCancel,
}: ICompaniesForm) => {
  const [selectedCompanies, setSelectedCompanies] = useState<Array<number>>([]);

  const { data: dealPackageCompanies, isLoading } = useDealPackageCompanyList({
    dealId,
    companyPackageId,
  });

  const packageOptions: Array<{
    value: number;
    label: string;
    disabled: boolean;
  }> = useMemo(
    () =>
      dealPackageCompanies?.map((dealCompany) => ({
        value: dealCompany.company_id,
        label: dealCompany.name,
        disabled: dealCompany.is_owner,
      })) || [],
    [dealPackageCompanies]
  );

  useEffect(
    () =>
      setSelectedCompanies(
        dealPackageCompanies
          ?.filter((dealPackageCompany) => dealPackageCompany.has_access)
          .map((dealPackageCompany) => dealPackageCompany.company_id) || []
      ),
    [dealPackageCompanies]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedSelectedCompanies = _.xor(selectedCompanies, [
        Number(event.target.value),
      ]);
      setSelectedCompanies(updatedSelectedCompanies);
    },
    [selectedCompanies]
  );

  const handleSubmit = useCallback(
    () => onSubmit({ selectedCompanies }),
    [selectedCompanies, onSubmit]
  );

  return (
    <div>
      {isLoading ? (
        <Stack sx={{ marginLeft: theme.spacing(3) }}>
          {_.range(6).map((index) => (
            <Skeleton variant="text" key={index} width={200} height={40} />
          ))}
        </Stack>
      ) : (
        <>
          <Stack spacing={1} data-testid="company-entities-list">
            {packageOptions.map(({ value, label, disabled }, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2.5}
                sx={{
                  padding: theme.spacing(0.5, 1),
                  "&:hover": {
                    backgroundColor: colors.blue10,
                    borderRadius: theme.spacing(0.5),
                  },
                }}
                data-testid="company-entity"
              >
                <FormControlLabel
                  key={index}
                  value={value}
                  control={
                    <Checkbox
                      checked={selectedCompanies.includes(value)}
                      disabled={disabled}
                      sx={{ padding: 0, marginRight: theme.spacing(1) }}
                      onChange={handleCheckboxClick}
                      data-testid="checkbox"
                    />
                  }
                  label={
                    <Box sx={{ marginLeft: theme.spacing(2) }}>
                      <Text
                        variant="text2"
                        fontWeight={700}
                        data-testid="company-name"
                      >
                        {label}
                      </Text>
                    </Box>
                  }
                />
              </Stack>
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            spacing={2}
            style={{ marginTop: theme.spacing(6) }}
            data-testid="dialog-action-buttons"
          >
            <Button
              variant="text"
              size="large"
              onClick={onCancel}
              data-testid="close-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              size="large"
              data-testid="submit-button"
            >
              Save
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};

export default CompaniesForm;

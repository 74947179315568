import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import {
  AddIcon,
  ArrowBackIcon,
  AssignmentOutlinedIcon,
  CloseIcon,
  EventAvailableOutlinedIcon,
} from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import useWindowScrollHide from "utils/useSidepanelParentScrollHide";

import Backdrop from "components/Backdrop";
import Badge from "components/Badge";
import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import NoPrint from "components/NoPrint";
import SidepanelHeader from "components/SidepanelHeader";
import Stack from "components/Stack";
import Text from "components/Text";

import SidebarLink from "entities/Deal/components/Sidepanel/SidebarLink";
import { useDealSidepanel } from "entities/Deal/components/Sidepanel/useDealSidepanel";
import {
  CLOSED_SIDEPANEL_WIDTH,
  OPEN_SIDEPANEL_WIDTH,
} from "entities/Deal/constants";
import { useDealDetails } from "entities/Deal/sdk";
import NoteList from "entities/Note/components/NoteList";
import AddTaskForm from "entities/Task/components/AddForm";
import TaskChatTabContent from "entities/Task/components/TaskChatTabContent";
import TaskDetailTabContent from "entities/Task/components/TaskDetailTabContent";
import TaskList from "entities/Task/components/TaskList";
import TaskTabs from "entities/Task/components/TaskTabs";
import { useTaskChannels } from "entities/Task/sdk";

import { TABS, TASKS_TAB_SUBTABS } from "./utils";

export interface ISidepanel {
  isInDealContext: boolean;
}

const SIDEPANEL_TABS = [
  { label: "Task details", key: TASKS_TAB_SUBTABS.DETAILS },
  { label: "Chat", key: TASKS_TAB_SUBTABS.CHAT },
];

const Sidepanel = ({ isInDealContext }: ISidepanel) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealIdFromParams = Number(params.dealId);

  const {
    meta,
    setMeta,
    open: isOpen,
    setOpen: setIsOpen,
    resetMeta,
    setSubTab,
  } = useDealSidepanel();

  const dealId = useMemo(() => {
    // If we're in deal context we use the deal id from the URL params.
    // Otherwise we're expecting a task channel to be set in the meta.
    if (isInDealContext) {
      return dealIdFromParams;
    }
    return Number(meta?.taskChannel?.task.deal_id);
  }, [dealIdFromParams, isInDealContext, meta]);

  const { data: taskChannels, mutate: updateTaskChannels } = useTaskChannels({
    dealId,
  });

  const { data: deal } = useDealDetails({ dealId });

  useEffect(() => {
    // If there's an update over the task channels we need to update the task channel stored in the meta too.
    if (!_.isNil(taskChannels) && !_.isNil(meta)) {
      const newTaskChannel = taskChannels.find(
        (taskChannel) => taskChannel.id === meta.taskChannel?.id
      );

      if (!_.isEqual(newTaskChannel, meta.taskChannel)) {
        setMeta((prevMeta) => {
          if (!_.isNil(prevMeta)) {
            return { ...prevMeta, taskChannel: newTaskChannel };
          }

          return prevMeta;
        });
      }
    }
  }, [taskChannels, meta, setMeta]);

  useWindowScrollHide({ hideScroll: isOpen });

  const filteredTaskChannels = useMemo(() => {
    /* We can have task for category/section/field, but also
    task that is generic for the deal, that is not related to category/section/field
    If we pass any of category/section/field to the sidepal we filter the task related to that item */
    if (!_.isNil(meta) && !_.isNil(meta.taskCategory)) {
      return taskChannels?.filter(
        (taskChannel) =>
          taskChannel.task.company_category?.id ===
            meta.taskCategory?.category_id &&
          taskChannel.task.company_section === null &&
          taskChannel.task.company_field === null
      );
    }
    if (!_.isNil(meta) && !_.isNil(meta.taskSection)) {
      return taskChannels?.filter(
        (taskChannel) =>
          taskChannel.task.company_section?.id ===
            meta.taskSection?.section_id &&
          taskChannel.task.company_category?.id ===
            meta.taskInitialData?.company_category &&
          taskChannel.task.company_field === null
      );
    }
    if (!_.isNil(meta) && !_.isNil(meta.taskField)) {
      return taskChannels?.filter(
        (taskChannel) =>
          taskChannel.task.company_field?.id === meta.taskField?.field_id &&
          taskChannel.task.company_category?.id ===
            meta.taskInitialData?.company_category &&
          taskChannel.task.company_section?.id ===
            meta.taskInitialData?.company_section
      );
    }
    return taskChannels;
  }, [meta, taskChannels]);

  const hasUnreadMessages = useMemo(
    () =>
      !_.isNil(filteredTaskChannels)
        ? filteredTaskChannels.filter((channel) => channel.unread_count > 0)
            .length > 0
        : false,
    [filteredTaskChannels]
  );

  const sidepanelWidth = useMemo(() => {
    if (isInDealContext) {
      return isOpen ? OPEN_SIDEPANEL_WIDTH : CLOSED_SIDEPANEL_WIDTH;
    }
    return isOpen ? OPEN_SIDEPANEL_WIDTH : 0;
  }, [isOpen, isInDealContext]);

  const sidepanelIsOpenAndMetaIsPresent = !_.isNil(meta) && isOpen;

  const handleTabChange = useCallback(
    ({ key }: { key: TABS }) => {
      setIsOpen(true);
      if (!_.isNil(meta)) {
        setMeta({ ...meta, tab: key });
      } else {
        setMeta({ tab: key });
      }
    },
    [setIsOpen, meta, setMeta]
  );

  const updateParent = useCallback(() => {
    if (!_.isNil(meta)) {
      meta.updateParent && meta.updateParent();
    }
  }, [meta]);

  const handleClose = useCallback(() => {
    resetMeta();
    setIsOpen(false);
    updateParent();
  }, [resetMeta, setIsOpen, updateParent]);

  const handleBackOrCloseClick = useCallback(() => {
    /*
    we have two "views" in the sidepanel
    first one is the task list where we display all tasks
    second one is "nested" view from the first one. It contains task details/chat/edit/create. We refer it in the meta as "subTab"
    when being in the second view we want to have a back button that return us to tasks list view
    after closing the sidepanel we need to clear initailData,taskCategory and subTab in order to have a clean state when we want to reopen
    */
    if (!isInDealContext) {
      handleClose();
    }
    if (!_.isNil(meta)) {
      if (meta.subTab) {
        setMeta({ ...meta, subTab: undefined });
        updateTaskChannels();
      } else {
        handleClose();
      }
    }
  }, [meta, setMeta, handleClose, updateTaskChannels, isInDealContext]);

  const handleTaskCreate = useCallback(() => {
    handleBackOrCloseClick();
    updateParent();
  }, [handleBackOrCloseClick, updateParent]);

  const handleTaskDelete = useCallback(() => {
    handleBackOrCloseClick();
    updateParent();
  }, [handleBackOrCloseClick, updateParent]);

  const sidepanelHeaderContent = useMemo(() => {
    if (!_.isNil(meta)) {
      switch (meta.subTab) {
        case TASKS_TAB_SUBTABS.ADD_TASK: {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <AddIcon />
              <Text variant="h3">Add new task</Text>
            </Stack>
          );
        }
        case TASKS_TAB_SUBTABS.DETAILS: {
          return <Text variant="h3">{meta.taskChannel?.task.title}</Text>;
        }
        case TASKS_TAB_SUBTABS.CHAT: {
          return <Text variant="h3">{meta.taskChannel?.task.title}</Text>;
        }
        default: {
          return (
            <Text variant="h3">
              Tasks list
              {!_.isNil(meta.taskCategory) &&
                ` for ${meta.taskCategory.name} category`}
              {!_.isNil(meta.taskSection) &&
                ` for ${meta.taskSection.name} section`}
              {!_.isNil(meta.taskField) &&
                ` for ${meta.taskField.display_name} field`}
            </Text>
          );
        }
      }
    }
    return null;
  }, [meta]);

  const sidepanelContent = useMemo(() => {
    if (!_.isNil(meta)) {
      switch (meta.subTab) {
        case TASKS_TAB_SUBTABS.ADD_TASK: {
          return (
            <AddTaskForm
              initialValues={meta.taskInitialData}
              dealId={dealId}
              onCreated={handleTaskCreate}
              handleCancel={handleBackOrCloseClick}
            />
          );
        }
        case TASKS_TAB_SUBTABS.CHAT: {
          return (
            !_.isNil(meta.taskChannel) && (
              <TaskChatTabContent taskChannel={meta.taskChannel} />
            )
          );
        }
        case TASKS_TAB_SUBTABS.DETAILS: {
          return (
            !_.isNil(meta.taskChannel) && (
              <TaskDetailTabContent
                taskChannel={meta.taskChannel}
                onDelete={handleTaskDelete}
              />
            )
          );
        }
      }
    }
    return null;
  }, [
    meta,
    dealId,
    handleBackOrCloseClick,
    handleTaskCreate,
    handleTaskDelete,
  ]);

  const inSubTabOrInAllTasksPage = useMemo(
    () => _.isNil(meta?.subTab) || !isInDealContext,
    [meta, isInDealContext]
  );

  const hasFullDealAccess = useMemo(
    () => !deal?.is_partially_accessible,
    [deal]
  );

  return (
    <NoPrint>
      <Backdrop sx={{ zIndex: 1000 }} open={isOpen} onClick={handleClose} />
      <Drawer
        onClose={handleClose}
        open={isOpen}
        variant="permanent"
        anchor="right"
        sx={{ "@media print and (max-width: 1023px)": { display: "none" } }}
      >
        <Stack
          sx={{
            zIndex: 1001,
            width: `${sidepanelWidth}px`,
            flexGrow: 1,
            maxHeight: "100vh",
            overflowY: "hidden",
            transition: "width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms", // This is the MUI Drawer transition
          }}
        >
          <Stack direction="row" flexGrow={1}>
            {isInDealContext && (
              <Stack
                sx={{
                  width: "64px",
                  paddingTop: "200px",
                  position: "sticky",
                  top: 0,
                }}
              >
                <SidebarLink
                  value={TABS.TASKS}
                  selected={isOpen && TABS.TASKS === meta?.tab}
                  onClick={handleTabChange}
                  icon={
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={!hasUnreadMessages}
                    >
                      <EventAvailableOutlinedIcon
                        sx={{ color: colors.gray60 }}
                      />
                    </Badge>
                  }
                />
                {hasFullDealAccess && (
                  <SidebarLink
                    value={TABS.NOTES}
                    selected={isOpen && TABS.NOTES === meta?.tab}
                    onClick={handleTabChange}
                    icon={
                      <AssignmentOutlinedIcon sx={{ color: colors.gray60 }} />
                    }
                  />
                )}
              </Stack>
            )}
            {sidepanelIsOpenAndMetaIsPresent && (
              <Stack sx={{ flex: 1, overflowY: "auto", maxHeight: "100vh" }}>
                {meta.tab === TABS.TASKS && (
                  <>
                    <SidepanelHeader>
                      {sidepanelHeaderContent}
                      <IconButton onClick={handleBackOrCloseClick}>
                        {inSubTabOrInAllTasksPage ? (
                          <CloseIcon />
                        ) : (
                          <ArrowBackIcon />
                        )}
                      </IconButton>
                    </SidepanelHeader>
                    <Stack
                      sx={{
                        flexGrow: 1,
                        padding: theme.spacing(4, 8),
                      }}
                    >
                      {_.isNil(meta.subTab) ? (
                        <TaskList
                          dealId={dealId}
                          taskChannels={filteredTaskChannels}
                        />
                      ) : (
                        <>
                          {meta.subTab !== TASKS_TAB_SUBTABS.ADD_TASK && (
                            <TaskTabs
                              tabs={SIDEPANEL_TABS}
                              onChange={setSubTab}
                              selectedTab={meta.subTab}
                            />
                          )}
                          {sidepanelContent}
                        </>
                      )}
                    </Stack>
                  </>
                )}
                {meta.tab === TABS.NOTES && (
                  <>
                    <SidepanelHeader>
                      <Stack>
                        <Text variant="h3">My Notes</Text>
                        <Text variant="text2">
                          This is your private space for making notes.
                        </Text>
                      </Stack>
                      <IconButton onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </SidepanelHeader>
                    <Stack sx={{ padding: theme.spacing(4, 8) }}>
                      <NoteList dealId={dealId} />
                    </Stack>
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Drawer>
    </NoPrint>
  );
};

export default Sidepanel;

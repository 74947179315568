import { memo } from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

import Illustration from "./assets/image.svg";

const InfoBanner = () => (
  <Stack
    direction="row"
    spacing={3}
    sx={{
      padding: theme.spacing(3),
      borderRadius: "8px",
      backgroundColor: colors.gray10,
    }}
  >
    <img src={Illustration} alt="" />
    <Stack spacing={1}>
      <Text variant="text2" fontWeight={700}>
        How AI Summaries work
      </Text>
      <Text variant="text2" color={colors.gray80}>
        Our team has prepared a list of pre-engineered prompt questions so your
        analysis of each document is ultra fast and smooth. To start generating
        answers and summaries using these prompts, first select a document type.
        We are working on expanding the list of document types and prompt
        questions available to you. Feel free to reach out to us with requests
        and suggestions, we are happy to help.
      </Text>
    </Stack>
  </Stack>
);

export default memo(InfoBanner);

import { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { emailValidate } from "utils/common";
import { ENTER_CODE } from "utils/constants";

import Box from "components/Box";
import Button from "components/Button";
import ChipsGroup from "components/ChipsGroup";
import Dialog, { DialogActions, DialogContent } from "components/Dialog";
import FormHelperText from "components/FormHelperText";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";

import { VALIDATION_SCHEMA } from "entities/Checklist/components/ShareDialog/constants";
import { useLenderShareChecklistToken } from "entities/Checklist/sdk";
import { lenderShareChecklist } from "entities/Package/sdk";

export interface IFormValues {
  name: string;
  email: string;
  notification_emails: string[];
}

const initalValues: IFormValues = {
  name: "",
  email: "",
  notification_emails: [],
};

export interface IShareDialog {
  open: boolean;
  packageId: number;
  onSave: ({ token }: { token: string }) => void;
  onClose: () => void;
}

const ShareDialog = ({ open, packageId, onSave, onClose }: IShareDialog) => {
  const { data: sharedChecklist, mutate: updateLenderShareChecklist } =
    useLenderShareChecklistToken({ packageId });

  const handleSubmit = useCallback(
    (values: IFormValues) => {
      const data = {
        name: values.name,
        notification_emails: values.notification_emails,
      };

      lenderShareChecklist({ packageId, data }).then(({ token }) => {
        onSave({ token });
        updateLenderShareChecklist();
      });
    },
    [packageId, onSave, updateLenderShareChecklist]
  );

  const formik = useFormik<IFormValues>({
    initialValues: { ...initalValues, ...sharedChecklist },
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleDeleteEmail = useCallback(
    (email: string) => {
      const activeEmails = _.xor(formik.values.notification_emails, [email]);
      formik.setFieldValue("notification_emails", activeEmails);
    },
    [formik]
  );

  const hasEmailErrors = useMemo(
    () => formik.touched.email && !!formik.errors.email,
    [formik]
  );

  const handleKeyPress = (e: any) => {
    const newEmail = e.target.value.trim();

    if (
      !e.shiftKey &&
      e.which === ENTER_CODE &&
      newEmail !== "" &&
      !formik.errors.email &&
      emailValidate({ email: newEmail })
    ) {
      const notificationEmails = _.union(formik.values.notification_emails, [
        newEmail,
      ]);
      formik.setFieldValue("notification_emails", notificationEmails);
      formik.setFieldValue("email", "");
      e.preventDefault();
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <Box
        sx={{ padding: theme.spacing(2) }}
        data-testid="share-intake-form-dialog"
      >
        <Stack
          gap="6px"
          sx={{
            backgroundColor: colors.blue10,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
          }}
          data-testid="dialog-header-section"
        >
          <Text variant="h3" data-testid="dialog-header-name">
            Share checklist
          </Text>
        </Stack>
        <DialogContent>
          <Stack spacing={2}>
            <Box data-testid="intake-name">
              <Text
                variant="text3"
                fontWeight={500}
                marginBottom={0.5}
                data-testid="field-label"
              >
                Name
              </Text>
              <TextField
                fullWidth
                {...formik.getFieldProps("name")}
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : ""
                }
                data-testid="name-field"
                inputProps={{ "data-testid": "name-field-input" }}
              />
            </Box>
            <Stack spacing={0.5} data-testid="intake-emails-notifications">
              <Text variant="text3" fontWeight={500} data-testid="field-label">
                Notification emails (optional)
              </Text>
              <Text color={colors.gray80} data-testid="field-helper-text">
                Enter your company team members' emails that you would like to
                receive an email notification upon intake submission.
              </Text>
              <TextField
                fullWidth
                placeholder="example@domain.com"
                {...formik.getFieldProps("email")}
                onKeyPress={handleKeyPress}
                error={hasEmailErrors}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ""
                }
                data-testid="emails-notifications-field"
                inputProps={{ "data-testid": "emails-notifications-input" }}
              />
              <FormHelperText data-testid="emails-notifications-input-helper-text">
                To add an email, you need to type it and press Enter.
              </FormHelperText>
            </Stack>
            <Stack
              direction="row"
              sx={{
                flexWrap: "wrap",
                "& > *": { margin: theme.spacing(0, 1, 1, 0) },
              }}
              data-testid="email-notification-chips-section"
            >
              <ChipsGroup
                chips={formik.values.notification_emails}
                onDelete={handleDeleteEmail}
                dataTestid="notifications-email-chip"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <Stack sx={{ padding: theme.spacing(2, 2, 3) }}></Stack>
        <DialogActions sx={{ padding: 0 }} data-testid="dialog-action-buttons">
          <Button onClick={onClose} variant="text" data-testid="cancel-button">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} data-testid="submit-button">
            Save and share
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ShareDialog;

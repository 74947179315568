import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, { DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import { COMPANY_PACKAGE_NAME_MAX_LENGTH } from "entities/CompanyChecklist/constants";
import { cloneCompanyChecklist } from "entities/Package/sdk";

export interface IUnderConstructionDialog {
  isOpen: boolean;
  onClose: () => void;
  refreshChecklist: () => void;
  packageId: number;
  checklistName: string;
}

const DuplicateChecklistDialog = ({
  isOpen,
  onClose,
  refreshChecklist,
  packageId,
  checklistName,
}: IUnderConstructionDialog) => {
  const [newName, setNewName] = useCaching<string>(`Copy of ${checklistName}`);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const navigate = useNavigate();

  const saveIsDisabled = useMemo(
    () => newName === "" || showErrorMessage,
    [newName, showErrorMessage]
  );

  const handleSave = useCallback(() => {
    cloneCompanyChecklist({
      newChecklistName: newName,
      checklistId: packageId,
    })
      .then(({ id }) => {
        onClose();
        refreshChecklist();
        toast.successMessage(
          <>
            <Text variant="text2" component="span">
              Checklist duplicated successfully! Click{" "}
            </Text>
            <Text
              component="span"
              variant="subtitle1"
              color="primary"
              onClick={() =>
                navigate(
                  reverse(URLS.COMPANY_CHECKLISTS_PREVIEW, { checklistId: id })
                )
              }
            >
              here
            </Text>{" "}
            <Text variant="text2" component="span">
              to view it.
            </Text>
          </>
        );
      })
      .catch((error) => {
        handleInvalidRequest(error);
      });
  }, [onClose, refreshChecklist, newName, packageId, navigate]);

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNewName(value);

      if (value.length > COMPANY_PACKAGE_NAME_MAX_LENGTH) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    },
    [setNewName]
  );

  const errorMessage = useMemo(
    () =>
      showErrorMessage
        ? `The name of the checklist cannot be longer than ${COMPANY_PACKAGE_NAME_MAX_LENGTH} characters.`
        : "",
    [showErrorMessage]
  );

  const handleClose = useCallback(() => {
    onClose();
    setNewName(`Copy of ${checklistName}`);
  }, [checklistName, onClose, setNewName]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <Stack padding={2} spacing={2} width={640}>
        <DialogTitle
          sx={{
            marginTop: theme.spacing(2),
            borderRadius: theme.spacing(1),
            backgroundColor: colors.blue10,
          }}
        >
          <Stack spacing={1}>
            <Text
              variant="h2"
              marginBottom={theme.spacing(0.5)}
              color={colors.gray100}
            >
              Create a duplicate
            </Text>
            <Text variant="subtitle2" color={colors.gray80}>
              Copy the entire structure of this checklist template and all the
              fields within it. It is useful when you want to update or add some
              questions without affecting deals where the original template is
              already in use.
            </Text>
          </Stack>
        </DialogTitle>
        <Stack>
          <Text variant="text3" fontWeight={500} color={colors.gray100}>
            Name your new checklist:
          </Text>
          <TextField
            variant="outlined"
            value={newName}
            onChange={handleNameChange}
            helperText={errorMessage}
            error={showErrorMessage}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          data-testid="form-action-buttons"
        >
          <Button
            variant="text"
            onClick={onClose}
            data-testid="close-button"
            sx={{ marginRight: theme.spacing(1) }}
          >
            Cancel
          </Button>
          <Button
            data-testid="submit-button"
            disabled={saveIsDisabled}
            onClick={handleSave}
          >
            Create duplicate
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(DuplicateChecklistDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import React, { useCallback, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { fieldOptionRetrieve } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import FormControlLabel from "components/FormControlLabel";
import IconButton from "components/IconButton";
import Radio from "components/Radio";
import Stack from "components/Stack";
import Text from "components/Text";

import { IFieldOption } from "entities/Package/sdk";

interface IRadiofieldEditButton {
  isOpen: boolean;
  name: string;
  options: Array<IFieldOption>;
  initialValue: IFieldOption | null;
  onSave: ({ newValue }: { newValue: IFieldOption | null }) => void;
  onCancel: () => void;
  onClick: () => void;
  buttonDataTestid?: string;
}

const RadiofieldEditButton: React.FC<IRadiofieldEditButton> = ({
  isOpen,
  name,
  options,
  initialValue,
  onSave,
  onCancel,
  onClick,
  buttonDataTestid,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleSave = useCallback(
    () => onSave({ newValue: value }),
    [onSave, value]
  );

  const handleCancel = useCallback(() => {
    onCancel();
    setValue(initialValue);
  }, [onCancel, setValue, initialValue]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, []);

  const handleChangeOption = useCallback(
    (item: string) => setValue(fieldOptionRetrieve({ options, value: item })),
    [setValue, options]
  );

  return (
    <>
      <IconButton size="small" onClick={onClick} dataTestid={buttonDataTestid}>
        <EditIcon />
      </IconButton>
      {isOpen && (
        <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleCancel}>
          <Box
            sx={{ padding: theme.spacing(1) }}
            data-testid="radiobutton-edit-dialog"
          >
            <DialogTitle data-testid="radiobutton-field-edit-dialog-name">
              {name}
            </DialogTitle>
            <Stack spacing={1} sx={{ paddingX: theme.spacing(3) }}>
              {_.map(options, (defaultValue, index) => (
                <FormControlLabel
                  key={index}
                  label={
                    <Text variant="label" data-testid="radiobutton-label">
                      {defaultValue.label}
                    </Text>
                  }
                  sx={{ margin: 0 }}
                  control={
                    <Radio
                      checked={value?.value === defaultValue.value}
                      onChange={() => handleChangeOption(defaultValue.value)}
                      data-testid="radiobutton-option"
                    />
                  }
                ></FormControlLabel>
              ))}
            </Stack>
            <DialogActions sx={{ marginTop: theme.spacing(2) }}>
              <Button
                variant="text"
                color="error"
                onClick={handleReset}
                data-testid="reset-button"
              >
                Reset
              </Button>
              <Button
                variant="text"
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default RadiofieldEditButton;

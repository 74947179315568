import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";

import ChipsGroup from "components/ChipsGroup";
import Stack from "components/Stack";
import { useFilters } from "components/useFilters";
import {
  ORDER_BY_COLUMN_FILTER_KEY,
  ORDER_BY_COLUMN_FILTER_LABEL,
} from "components/useFilters/constants";

import { IPipelineColumns } from "entities/Deal/sdk";
import {
  getConstructionTypeValueByLabel,
  getPropertyTypeValueByLabel,
} from "entities/Deal/utils";
import {
  ASSET_TYPE_COLUMN_KEY,
  CONSTRUCTION_TYPE_COLUMN_KEY,
  EFieldFormatType,
  PROPERTY_TYPE_COLUMN_KEY,
  SEARCH_STRING_FILTER_KEY,
  SEARCH_STRING_FILTER_LABEL,
  TABLE_SETTINGS_FILTER_KEY,
  TABLE_SETTINGS_FILTER_LABEL,
} from "entities/Reporting/constants";
import {
  INonPipelineColumns,
  useReportPackageFilterList,
} from "entities/Reporting/sdk";
import { getFilterDictForDefaultReports } from "entities/Reporting/utils";

import { getFormattedSelectedFilters } from "./utils";

export interface ISelectedFilters {
  reportPackageId: number | null | undefined;
  isHardcodedReport: boolean;
  columns: Array<IPipelineColumns | INonPipelineColumns>;
}

const SelectedFilters = ({
  reportPackageId,
  columns,
  isHardcodedReport,
}: ISelectedFilters) => {
  const { filters, deleteFilter, updateFilter } = useFilters();

  const { data } = useReportPackageFilterList({
    report_package_id: reportPackageId,
  });

  const filterDict = useMemo(() => {
    if (isHardcodedReport) {
      return getFilterDictForDefaultReports();
    }

    return data;
  }, [data, isHardcodedReport]);

  const chips = useMemo(
    () => getFormattedSelectedFilters({ filters, filterDict, columns }),
    [filters, filterDict, columns]
  );

  const handleChipDelete = useCallback(
    (chip: string) => {
      const [key, filterLabel] = chip.split(":");
      // For the Table Settings filter, we show which columns are hidden
      if (key === TABLE_SETTINGS_FILTER_LABEL) {
        updateFilter({
          key: TABLE_SETTINGS_FILTER_KEY,
          value: _.xor(filters[TABLE_SETTINGS_FILTER_KEY], [
            filterLabel.trim(),
          ]),
        });
      } else if (key === SEARCH_STRING_FILTER_LABEL) {
        deleteFilter({ key: SEARCH_STRING_FILTER_KEY });
      } else if (key === ORDER_BY_COLUMN_FILTER_LABEL) {
        deleteFilter({ key: ORDER_BY_COLUMN_FILTER_KEY });
      } else if (key.toLowerCase() === CONSTRUCTION_TYPE_COLUMN_KEY) {
        const value = getConstructionTypeValueByLabel({
          label: filterLabel.trim(),
        });
        const filterValue = !_.isUndefined(value) ? value : filterLabel.trim();

        updateFilter({
          key,
          value: _.xor(filters[key], [filterValue]),
        });
      } else if (
        key.toLowerCase() === PROPERTY_TYPE_COLUMN_KEY ||
        key === ASSET_TYPE_COLUMN_KEY
      ) {
        const value = getPropertyTypeValueByLabel({
          label: filterLabel.trim(),
        });
        const filterValue = !_.isUndefined(value) ? value : filterLabel.trim();

        updateFilter({
          key,
          value: _.xor(filters[key], [filterValue]),
        });
      } else {
        const selectedFilter = [...filters[key]];
        const type = filterDict?.find(
          (filter) => filter.name === key.toLowerCase()
        )?.field_format_type;

        if (type === EFieldFormatType.STRING) {
          updateFilter({
            key,
            value: _.remove(
              selectedFilter,
              (filter) => filter !== filterLabel.trim()
            ),
          });
        } else {
          deleteFilter({ key });
        }
      }
    },
    [filters, filterDict, deleteFilter, updateFilter]
  );

  return (
    <Stack
      direction="row"
      sx={{
        flexWrap: "wrap",
        "& > *": { margin: theme.spacing(0, 1, 1, 0) },
      }}
    >
      <ChipsGroup chips={chips} onDelete={handleChipDelete} />
    </Stack>
  );
};

export default SelectedFilters;

import React from "react";
import { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { CloseIcon } from "icons";
import theme from "theme";

import Button from "components/Button";
import FormHelperText from "components/FormHelperText";
import FormSeparator from "components/FormSeparator";
import IconButton from "components/IconButton";
import Loading from "components/Loading";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import UploadButton from "components/UploadButton";
import {
  IMAGE_ERROR_NOTIFICATION,
  IMAGE_TYPES,
} from "components/UploadButton/constants";

import { IUser } from "entities/User/sdk";

const VALIDATION_SCHEMA = yup.object({
  first_name: yup.string().required("First name is required!"),
});

const initialValues: Partial<IUser> = {
  first_name: "",
  last_name: undefined,
  job_title: undefined,
  contact: undefined,
};

export interface IUserForm {
  initialValues?: Partial<Omit<IUser, "id">>;
  onSubmit: (
    data: Partial<Omit<IUser, "avatar"> & { avatar: File }>
  ) => Promise<any>;
  handleCancel?: () => void;
  submitLabel: string;
}

const UserForm: React.FC<IUserForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
  submitLabel = "Submit",
}) => {
  const [avatar, setAvatar] = useState<File>();
  const [showAvatarError, setShowAvatarError] = useState("");

  const handleSubmit = useCallback(
    (data: Partial<Omit<IUser, "avatar">>) => {
      if (avatar) {
        return onSubmit({ ...data, avatar });
      } else {
        return onSubmit(data);
      }
    },
    [avatar, onSubmit]
  );

  const handleUploadAvatar = useCallback((file: File) => {
    if (IMAGE_TYPES.includes(file.type)) {
      setShowAvatarError("");
      setAvatar(file);
    } else {
      setShowAvatarError(IMAGE_ERROR_NOTIFICATION);
    }
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3} data-testid="basic-info-section">
        <FormSeparator title="Basic info" />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="First name"
          inputProps={{ "data-testid": "first-name-input" }}
          data-testid="user-first-name"
          {...formik.getFieldProps("first_name")}
          error={formik.touched.first_name && !!formik.errors.first_name}
          helperText={
            formik.touched.first_name && formik.errors.first_name
              ? formik.errors.first_name
              : ""
          }
        />
        <TextField
          label="Last name"
          inputProps={{ "data-testid": "last-name-input" }}
          data-testid="user-last-name"
          {...formik.getFieldProps("last_name")}
          error={formik.touched.last_name && !!formik.errors.last_name}
          helperText={
            formik.touched.last_name && formik.errors.last_name
              ? formik.errors.last_name
              : ""
          }
        />
        <TextField
          label="Job title"
          inputProps={{ "data-testid": "job-input" }}
          data-testid="user-job"
          {...formik.getFieldProps("job_title")}
        />
        <TextField
          label="Phone No."
          inputProps={{ "data-testid": "phone-input" }}
          data-testid="user-phone"
          {...formik.getFieldProps("contact")}
        />
        <FormSeparator title="Profile picture" />
        <Text variant="text1">Upload a profile picture jpg or png format.</Text>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <UploadButton
            onChange={({ files }: { files: Array<File> }) =>
              handleUploadAvatar(files[0])
            }
            accept={IMAGE_TYPES.join(",")}
            dataTestid="upload-profile-image-button"
            maxFiles={1}
          />
          {showAvatarError && (
            <FormHelperText error>{showAvatarError}</FormHelperText>
          )}
          {avatar && (
            <Text>
              {avatar?.name}
              <IconButton onClick={() => setAvatar(undefined)}>
                <CloseIcon data-testid="remove-uploaded-image-button" />
              </IconButton>
            </Text>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            data-testid="cancel-user-profile-dialog"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-user-profile-form"
        >
          {submitLabel}
        </Button>
      </Stack>
    </form>
  );
};

export default UserForm;

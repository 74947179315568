import { useCallback, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import UserAvatar from "components/UserAvatar";

import ChecklistSelectionStep from "entities/Package/components/ShareDialog/components/ChecklistSelectionStep";
import { ACCESS_TYPE_OPTIONS } from "entities/Package/constants";
import {
  EPartialUserAccessType,
  IPartialUserAccess,
  updatePartialUserAccess,
} from "entities/Package/sdk";

export interface IPartialAccessUpdateDialog<T> {
  onClose: () => void;
  onUpdate: () => void;
  partialUserAccess: IPartialUserAccess;
  checklistName: string | React.ReactNode;
  items: Array<T>;
  initiallySelectedItemIds?: Array<number>;
}

const PartialAccessUpdateDialog = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  partialUserAccess,
  onClose,
  onUpdate,
  items,
  checklistName,
  initiallySelectedItemIds = [],
}: IPartialAccessUpdateDialog<T>) => {
  const [selectedItemIds, setSelectedItemIds] = useState<Array<number>>(
    initiallySelectedItemIds
  );
  const [openItemIds, setOpenItemIds] = useState<Array<number>>(
    items.map((item) => item.id)
  );

  const [selectedAccessType, setSelectedAccessType] =
    useState<EPartialUserAccessType>(partialUserAccess.access_type);

  const handleAccessTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSelectedAccessType(event.target.value as EPartialUserAccessType),
    []
  );

  const handleSubmit = useCallback(() => {
    updatePartialUserAccess({
      userAccessId: partialUserAccess.id,
      data: {
        company_custom_field_ids: selectedItemIds,
        access_type: selectedAccessType,
      },
    })
      .then(() => {
        toast.successMessage("User access updated successfully!");
        onClose();
        onUpdate();
      })
      .catch((error) => {
        toast.errorMessage(error.message || DEFAULT_ERROR_MESSAGE);
      });
  }, [
    selectedItemIds,
    selectedAccessType,
    partialUserAccess,
    onClose,
    onUpdate,
  ]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      data-testid="modify-user-access-dialog"
    >
      <DialogTitle
        sx={{
          marginTop: theme.spacing(2),
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(3),
          marginLeft: theme.spacing(2),
          borderRadius: theme.spacing(1),
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Text
          variant="h2"
          marginBottom={theme.spacing(0.5)}
          color={colors.gray100}
          data-testid="dialog-header"
        >
          Individual share settings
        </Text>
        <Text variant="text2" color={colors.gray80} data-testid="dialog-header">
          Review, modify and revoke an individual user's access rights to items
          in this checklist.
        </Text>
      </DialogTitle>
      <Stack
        paddingRight={2}
        paddingLeft={2}
        paddingBottom={2}
        spacing={2}
        data-testid="dialog-content-section"
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            data-testid="user-details-section"
          >
            <UserAvatar
              imgix
              src={partialUserAccess.user.avatar || undefined}
              imgixWidth={64}
              imgixHeight={64}
              sx={{ width: 64, height: 64 }}
              dataTestid={
                partialUserAccess.user?.avatar
                  ? "custom-user-profile-avatar"
                  : "default-user-profile-avatar"
              }
            />
            <Stack>
              <Text
                variant="label"
                color={colors.gray60}
                fontWeight="bold"
                data-testid="label"
              >
                NAME & EMAIL
              </Text>
              <Text
                variant="text1"
                fontWeight="bold"
                data-testid="user-full-name"
              >
                {partialUserAccess.user.first_name}{" "}
                {partialUserAccess.user.last_name}
              </Text>
              <Text
                variant="subtitle2"
                color={colors.gray60}
                data-testid="user-email"
              >
                {partialUserAccess.user.email}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="column" data-testid="user-company-section">
            <Text
              variant="label"
              color={colors.gray60}
              fontWeight="bold"
              data-testid="label"
            >
              COMPANY
            </Text>
            <Text variant="text1" fontWeight="bold" data-testid="user-company">
              {partialUserAccess.user.company.name}
            </Text>
          </Stack>
          <Stack direction="column" data-testid="user-access-section">
            <Text
              variant="label"
              color={colors.gray60}
              fontWeight="bold"
              data-testid="label"
            >
              ACCESS TYPE
            </Text>
            <Select
              sx={{ width: "192px" }}
              options={ACCESS_TYPE_OPTIONS}
              value={selectedAccessType}
              onChange={handleAccessTypeChange}
              data-testid="access-type-dropdown"
              inputProps={{ "data-testid": "access-type-input" }}
              dropdownOptionsDataTestid="access-type-option"
            />
          </Stack>
        </Stack>

        <ChecklistSelectionStep
          items={items}
          openItemIds={openItemIds}
          setOpenItemIds={setOpenItemIds}
          selectedItemIds={selectedItemIds}
          setSelectedItemIds={setSelectedItemIds}
          checklistName={checklistName}
        />
      </Stack>

      <DialogActions
        sx={{ padding: theme.spacing(3, 2, 2, 2) }}
        data-testid="form-action-buttons"
      >
        <Button variant="text" onClick={onClose} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          disabled={_.isEmpty(selectedItemIds)}
          onClick={handleSubmit}
          data-testid="submit-button"
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartialAccessUpdateDialog;

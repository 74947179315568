import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { DeleteOutlineOutlinedIcon } from "icons";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Button from "components/Button";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { applicationDiscard } from "entities/Application/sdk";

export interface IDiscardButton {
  applicationId: number;
}

const DiscardButton = ({ applicationId }: IDiscardButton) => {
  const navigate = useNavigate();
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleClick = () => {
    showConfirmationDialog({
      onConfirm: () =>
        applicationDiscard({ applicationId })
          .then(() => {
            toast.successMessage("Application discarded successfully");
            navigate(
              reverse(URLS.APPLICATIONS_LIST, {
                status: "All",
              })
            );
          })
          .catch((error) => {
            const errorMessage =
              error?.message || error?.detail || DEFAULT_ERROR_MESSAGE;

            toast.errorMessage(errorMessage);
          }),
      confirmButtonText: "Discard",
      message:
        "Are you sure you want to discard this application? This action cannot be undone!",
    });
  };

  return (
    <Tooltip title="Discard" placement="top">
      <Button variant="outlined" onClick={handleClick}>
        <DeleteOutlineOutlinedIcon sx={{ color: colors.gray40, height: 25 }} />
      </Button>
    </Tooltip>
  );
};

export default DiscardButton;

import React, { useMemo } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import MuiCardMedia, { CardMediaProps } from "@mui/material/CardMedia";

import { toImgixUrl } from "utils/urls";

import placeholderIcon from "components/DealAvatar/assets/default.png";

interface ICardMedia extends CardMediaProps {
  component?: React.ElementType;
  imgix?: boolean;
  imgixWidth?: number;
  imgixHeight?: number;
}

const CardMedia = ({
  src,
  image,
  imgix = false,
  imgixWidth,
  imgixHeight,
  ...props
}: ICardMedia) => {
  const imgixUrl = useMemo(() => {
    if (imgix) {
      return src ? toImgixUrl(src) + `?&w=${imgixWidth}&h=${imgixHeight}` : src;
    }
    return src;
  }, [src, imgixWidth, imgixHeight, imgix]);

  return (
    <MuiCardMedia
      image={imgixUrl || placeholderIcon}
      data-testid={
        imgixUrl ? "deal-custom-cover-image" : "deal-default-cover-image"
      }
      {...props}
    />
  );
};

export { CardActionArea, CardContent, CardMedia };

export default Card;

import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";

import { States } from "entities/Deal/constants";
import { IDeal } from "entities/Deal/sdk";

const STATES = [
  { value: States.ALABAMA, label: States.ALABAMA },
  { value: States.ALASKA, label: States.ALASKA },
  { value: States.ARIZONA, label: States.ARIZONA },
  { value: States.ARKANSAS, label: States.ARKANSAS },
  { value: States.CALIFORNIA, label: States.CALIFORNIA },
  { value: States.COLORADO, label: States.COLORADO },
  { value: States.CONNECTICUT, label: States.CONNECTICUT },
  { value: States.DELAWARE, label: States.DELAWARE },
  { value: States.FLORIDA, label: States.FLORIDA },
  { value: States.GEORGIA, label: States.GEORGIA },
  { value: States.HAWAII, label: States.HAWAII },
  { value: States.IDAHO, label: States.IDAHO },
  { value: States.ILLINOIS, label: States.ILLINOIS },
  { value: States.INDIANA, label: States.INDIANA },
  { value: States.IOWA, label: States.IOWA },
  { value: States.KANSAS, label: States.KANSAS },
  { value: States.KENTUCKY, label: States.KENTUCKY },
  { value: States.LOUISIANA, label: States.LOUISIANA },
  { value: States.MAINE, label: States.MAINE },
  { value: States.MARYLAND, label: States.MARYLAND },
  { value: States.MASSACHUSETTS, label: States.MASSACHUSETTS },
  { value: States.MICHIGAN, label: States.MICHIGAN },
  { value: States.MINNESOTA, label: States.MINNESOTA },
  { value: States.MISSISSIPPI, label: States.MISSISSIPPI },
  { value: States.MISSOURI, label: States.MISSOURI },
  { value: States.MONTANA, label: States.MONTANA },
  { value: States.NEBRASKA, label: States.NEBRASKA },
  { value: States.NEVADA, label: States.NEVADA },
  { value: States.NEW_HAMPSHIRE, label: States.NEW_HAMPSHIRE },
  { value: States.NEW_JERSEY, label: States.NEW_JERSEY },
  { value: States.NEW_MEXICO, label: States.NEW_MEXICO },
  { value: States.NEW_YORK, label: States.NEW_YORK },
  { value: States.NORTH_CAROLINA, label: States.NORTH_CAROLINA },
  { value: States.NORTH_DAKOTA, label: States.NORTH_DAKOTA },
  { value: States.OHIO, label: States.OHIO },
  { value: States.OKLAHOMA, label: States.OKLAHOMA },
  { value: States.OREGON, label: States.OREGON },
  { value: States.PENNSYLVANIA, label: States.PENNSYLVANIA },
  { value: States.RHODE_ISLAND, label: States.RHODE_ISLAND },
  { value: States.SOUTH_CAROLINA, label: States.SOUTH_CAROLINA },
  { value: States.SOUTH_DAKOTA, label: States.SOUTH_DAKOTA },
  { value: States.TENNESSE, label: States.TENNESSE },
  { value: States.TEXAS, label: States.TEXAS },
  { value: States.UTAH, label: States.UTAH },
  { value: States.VERMONT, label: States.VERMONT },
  { value: States.VIRGINIA, label: States.VIRGINIA },
  { value: States.WASHINGTON, label: States.WASHINGTON },
  { value: States.WEST_VIRGINIA, label: States.WEST_VIRGINIA },
  { value: States.WISCONSIN, label: States.WISCONSIN },
  { value: States.WYOMING, label: States.WYOMING },
  { value: States.AMERICAN_SAMOA, label: States.AMERICAN_SAMOA },
  { value: States.DISTRICT_OF_COLUMBIA, label: States.DISTRICT_OF_COLUMBIA },
  {
    value: States.FEDERATED_STATES_OF_MICRONESIA,
    label: States.FEDERATED_STATES_OF_MICRONESIA,
  },
  { value: States.GUAM, label: States.GUAM },
  { value: States.MARSHALL_ISLANDS, label: States.MARSHALL_ISLANDS },
  {
    value: States.NORTHERN_MARIANA_ISLANDS,
    label: States.NORTHERN_MARIANA_ISLANDS,
  },
  { value: States.PALAU, label: States.PALAU },
  { value: States.PUERTO_RICO, label: States.PUERTO_RICO },
  { value: States.VIRGIN_ISLANDS, label: States.VIRGIN_ISLANDS },
];

const VALIDATION_SCHEMA = yup.object({
  street_address: yup
    .string()
    .required("This field is required!")
    .max(255, "Must be 255 characters at most."),
  city: yup
    .string()
    .required("This field is required!")
    .max(255, "Must be 255 characters at most."),
  state: yup.string().required("This field is required!"),
  zipcode: yup
    .string()
    .required("This field is required!")
    .max(9, "Must be 9 characters at most."),
});

const initialValues: Partial<IDeal["address"]> = {
  street_address: "",
  city: "",
  zipcode: "",
  state: "",
};

interface ILocationEditForm {
  initialValues?: Partial<IDeal["address"]>;
  onSubmit: (data: Partial<IDeal["address"]>) => Promise<any>;
  handleCancel?: () => void;
}
const LocationEditForm: React.FC<ILocationEditForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
}) => {
  const handleSubmit = useCallback(
    (data: Partial<IDeal["address"]>) => onSubmit(data),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Street address"
          {...formik.getFieldProps("street_address")}
          error={
            formik.touched.street_address && !!formik.errors.street_address
          }
          helperText={
            formik.touched.street_address && formik.errors.street_address
              ? formik.errors.street_address
              : ""
          }
          data-testid="street-address"
          inputProps={{ "data-testid": "street-address-input" }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="City"
          {...formik.getFieldProps("city")}
          error={formik.touched.city && !!formik.errors.city}
          helperText={
            formik.touched.city && formik.errors.city ? formik.errors.city : ""
          }
          data-testid="city"
          inputProps={{ "data-testid": "city-input" }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Zip"
          {...formik.getFieldProps("zipcode")}
          error={formik.touched.zipcode && !!formik.errors.zipcode}
          helperText={
            formik.touched.zipcode && formik.errors.zipcode
              ? formik.errors.zipcode
              : ""
          }
          data-testid="zipcode"
          inputProps={{ "data-testid": "zipcode-input" }}
        />
        <Select
          inputLabel="State"
          options={STATES}
          {...formik.getFieldProps("state")}
          error={formik.touched.state && !!formik.errors.state}
          helperText={formik.touched.state && formik.errors.state}
          data-testid="state-dropdown"
          inputProps={{ "data-testid": "state-dropdown-input" }}
          dropdownOptionsDataTestid="state-dropdown-option"
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            onClick={handleCancel}
            data-testid="close-location-dialog-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-location-form-button"
        >
          Update
        </Button>
      </Stack>
    </form>
  );
};

export default LocationEditForm;

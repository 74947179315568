import _ from "lodash";

import { colors } from "theme/palette";
import { TABLE_CELL_STYLES, TABLE_HEADER_STYLES } from "utils/constants";

import Box from "components/Box";
import Skeleton from "components/Skeleton";

const TableSkeleton = () => (
  <Box
    sx={{
      maxWidth: "100%",
      "table, th, td": {
        border: `1px solid ${colors.blue20}`,
        borderCollapse: "collapse",
      },
    }}
  >
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {_.range(6).map((index) => (
            <td key={index} style={TABLE_HEADER_STYLES}>
              <Skeleton width={50} height={22} />
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.range(3).map((index) => (
          <tr key={index}>
            {_.range(6).map((cellIndex) => (
              <td key={cellIndex} style={TABLE_CELL_STYLES}>
                <Skeleton width={50} height={22} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </Box>
);

export default TableSkeleton;

import { BASE_URL } from "config/urls";
import { format, FORMATS, IDate } from "utils/datetime";
import { deleteCall, post, useFetch } from "utils/sdk";

import { IOpenAICustomPromptResponse } from "entities/AISummaries/sdk";
import { IPackageDetailsField } from "entities/Checklist/sdk";
import { EMemoSectionType } from "entities/MemoTemplate/constants";

export interface IMemo {
  name: string;
  memo_date: string;
  sections: IMemoSection[];
}

export interface IMemoSectionExcelTableFieldValue {
  order: number;
  columns: Array<string>;
}

export interface IMemoOpenAIResponse {
  openai_custom_prompt_response:
    | (IOpenAICustomPromptResponse & {
        prompt_id: number;
        openai_document_assistant_id: number;
      })
    | null;
  response: string;
}

export interface IMemoSection {
  section_id: number;
  order: number;
  section_type: EMemoSectionType;
  title: string;
  comment_count: number;
  break_before: boolean;
  is_na: boolean;
  fields?: IPackageDetailsField[];
  memo_section_openai_responses: Array<IMemoOpenAIResponse>;
}

export interface IMemoSectionComment {
  id: number | string;
  created_by: {
    user_id: number;
    first_name: string;
    last_name: string;
    company_name: string;
    avatar: string | null;
  };
  created_at: string;
  message: string;
  seen_by: Array<{
    first_name: string;
    last_name: string;
    user_id: number;
  }>;
}

interface IShareableMemoLink {
  id: number;
  url: string;
}

export interface IDealTeamCompany {
  name: string;
  locations: Array<{
    street: string;
    city: string;
    state: string;
    zipcode: string;
  }>;
  established_in: string;
  website_url: string;
  description: string;
  executive_summary: Array<{
    summary: string;
    created_at: string;
    updated_at: string;
  }>;
}

export interface IDealSummary {
  summary: string;
  updated_at: string | null;
}

export const useMemoDealDetails = ({
  memoId,
  dealId,
}: {
  memoId: number;
  dealId: number;
}) => useFetch<IMemo>(`v2/memo/memo/${memoId}/deal/${dealId}/details/`);

export const updateMemoDealInfo = ({
  memoId,
  dealId,
  memoFinalizedDate,
}: {
  memoId: number;
  dealId: number;
  memoFinalizedDate: IDate;
}) =>
  post<{ memo_date: string }>(
    `${BASE_URL}v2/memo/memo/${memoId}/deal/${dealId}/memo-details/update/`,
    { memo_date: format(memoFinalizedDate, FORMATS.BACKEND.DATE) }
  );

export const useShareableMemoLink = ({
  dealId,
  memoId,
  enabled,
}: {
  dealId: number;
  memoId: number;
  enabled: boolean;
}) =>
  useFetch<IShareableMemoLink>(
    enabled
      ? `v2/deal/deal/${dealId}/memo/${memoId}/get-share-token/`
      : undefined
  );

export const createShareableMemoLink = (dealId: number, memoId: number) =>
  post<IShareableMemoLink>(
    `${BASE_URL}v2/deal/deal/${dealId}/memo/${memoId}/create-share-token/`
  );

export const expireShareableMemoLink = (dealId: number, memoId: number) =>
  post<{ response?: string }>(
    `${BASE_URL}v2/deal/deal/${dealId}/expire-share-token/`,
    {
      memo_id: memoId,
    }
  );

export const addMemoSectionComment = ({
  dealId,
  sectionId,
  message,
}: {
  dealId: number;
  sectionId: number;
  message: string;
}): Promise<IMemoSectionComment> =>
  post(
    `${BASE_URL}v2/memo/deal/${dealId}/memo-section/${sectionId}/memo-section-comment/create/`,
    { message }
  );

export const useSectionCommentsList = ({
  dealId,
  memoSectionId,
}: {
  dealId?: number;
  memoSectionId?: number;
}) =>
  useFetch<IMemoSectionComment[]>(
    dealId && memoSectionId
      ? `v2/memo/deal/${dealId}/memo-section/${memoSectionId}/memo-section-comment/list/`
      : undefined
  );

export const markSectionCommentsListAsSeen = ({
  memoSectionId,
  dealId,
}: {
  memoSectionId?: number;
  dealId?: number;
}) =>
  post(
    `${BASE_URL}v2/memo/deal/${dealId}/memo-section/${memoSectionId}/memo-section-comment/mark-as-seen/`
  );

export const updateMemoDocumentFinalizedDate = ({
  memoId,
  dealId,
  memoFinalizedDate,
}: {
  memoId: number;
  dealId: number;
  memoFinalizedDate: IDate;
}) =>
  post<{ document_finalized_date: string }>(
    `${BASE_URL}v2/deal/deal/${dealId}/memo/${memoId}/memo-package-details/update/`,
    {
      document_finalized_date: format(memoFinalizedDate, FORMATS.BACKEND.DATE),
    }
  );

export const useDealTeamGroup = ({
  dealId,
  groupName,
}: {
  dealId?: number;
  groupName: string;
}) =>
  useFetch<IDealTeamCompany[]>(
    dealId && groupName
      ? `v2/deal/deal/${dealId}/team-group/?group_name=${groupName}`
      : undefined
  );

export const useDealSummaryByCategory = ({
  dealId,
  category,
}: {
  dealId?: number;
  category: string;
}) =>
  useFetch<IDealSummary>(
    dealId && category
      ? `v2/deal/deal/${dealId}/category/${category}/summary/`
      : undefined
  );

export const updateSummary = ({
  dealId,
  category,
  data,
}: {
  dealId: number;
  category: string;
  data: {
    summary: string;
  };
}) =>
  post(
    `${BASE_URL}v2/deal/deal/${dealId}/category/${category}/summary/update/`,
    data
  );

export const memoSectionNAStatusUpdate = ({
  dealId,
  memoSectionId,
  naStatus,
}: {
  dealId: number;
  memoSectionId: number;
  naStatus: boolean;
}) =>
  post(
    `${BASE_URL}v2/memo/deal/${dealId}/memo-section/${memoSectionId}/na-status/update/`,
    { is_na: naStatus }
  );

export const memoSectionAISummaryResponseCreateOrUpdate = ({
  dealId,
  memoSectionId,
  openAICustomPromptResponseId,
  response,
}: {
  dealId: number;
  memoSectionId: number;
  openAICustomPromptResponseId: number | null;
  response: string;
}) =>
  post(
    `${BASE_URL}v2/memo/deal/${dealId}/memo-section/${memoSectionId}/openai-response/create-or-update/`,
    { openai_custom_prompt_response_id: openAICustomPromptResponseId, response }
  );

export const memoSectionAISummaryResponseDelete = ({
  dealId,
  memoSectionId,
}: {
  dealId: number;
  memoSectionId: number;
}) =>
  deleteCall(
    `${BASE_URL}v2/memo/deal/${dealId}/memo-section/${memoSectionId}/openai-response/delete/`
  );

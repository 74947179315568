import { useMemo } from "react";

import { URLS } from "config/urls";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Chip from "components/Chip";
import Stack from "components/Stack";
import Text from "components/Text";

import ChecklistInfoBox from "entities/Checklist/components/ChecklistInfoBox";

export interface ICompanyChecklist {
  name: string;
  checklistId: number;
  dealsCount: number;
  fieldsCount: number;
  linkedFieldsCount: number;
}

const CompanyChecklist = ({
  name,
  checklistId,
  dealsCount,
  fieldsCount,
  linkedFieldsCount,
}: ICompanyChecklist) => {
  const itemsCountLabel = useMemo(
    () => `${fieldsCount} ${fieldsCount === 1 ? "item" : "items"}`,
    [fieldsCount]
  );

  const fieldsCountLabel = useMemo(
    () => `${fieldsCount} ${fieldsCount === 1 ? "field" : "fields"}`,
    [fieldsCount]
  );

  const dealsCountLabel = useMemo(
    () => `${dealsCount} ${dealsCount === 1 ? "deal" : "deals"}`,
    [dealsCount]
  );

  return (
    <Stack key={checklistId} direction="row" spacing={6} alignItems="start">
      <ChecklistInfoBox
        title={name}
        subTitle={itemsCountLabel}
        url={reverse(URLS.COMPANY_CHECKLISTS_PREVIEW, {
          checklistId,
        })}
        width="360px"
      />
      <Stack spacing={1} width="175px">
        <Text variant="labelSmall" color={colors.gray60} fontWeight={500}>
          Fields & linked fields
        </Text>
        <Stack direction="row" spacing={1}>
          <Chip
            label={fieldsCountLabel}
            sx={{
              border: `1px solid ${colors.gray40}`,
              backgroundColor: colors.gray10,
              borderRadius: 4,
              height: 24,
            }}
          />
          <Chip
            label={`${linkedFieldsCount} linked`}
            sx={{
              border: `1px solid ${colors.gray40}`,
              backgroundColor: colors.gray10,
              borderRadius: 4,
              height: 24,
            }}
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Text variant="labelSmall" color={colors.gray60} fontWeight={500}>
          Added to
        </Text>
        <Chip
          label={dealsCountLabel}
          sx={{
            border: `1px solid ${colors.gray40}`,
            backgroundColor: colors.gray10,
            borderRadius: 4,
            height: 24,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default CompanyChecklist;

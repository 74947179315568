import React, { useCallback, useState } from "react";

import { AddIcon } from "icons";

import Button from "components/Button";

import AddFilterSidebar from "entities/AnalyticsFilterGroup/components/AddFilterSidebar";
import { ANALYTICS_TYPES } from "entities/AnalyticsFilterGroup/sdk";

const AddFilterGroupButton: React.FC<{ analyticsType: ANALYTICS_TYPES }> = ({
  analyticsType,
}) => {
  const [openFilters, setOpenFilter] = useState(false);

  const handleOpenFilter = useCallback(() => {
    setOpenFilter(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={handleOpenFilter}>
        New filter group
      </Button>
      <AddFilterSidebar
        isOpen={openFilters}
        onClose={handleCloseFilter}
        analyticsType={analyticsType}
      />
    </>
  );
};

export default AddFilterGroupButton;

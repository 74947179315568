import { useCallback, useState } from "react";

import { AddIcon } from "icons";

import Button from "components/Button";

import AddMemoTemplateDialog from "entities/MemoTemplate/components/AddMemoTemplateDialog";

interface IAddMemoTemplateButton {
  onMemoTemplateCreated: () => void;
}

const AddMemoTemplateButton: React.FC<IAddMemoTemplateButton> = ({
  onMemoTemplateCreated,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleCreated = useCallback(() => {
    handleClose();
    onMemoTemplateCreated();
  }, [onMemoTemplateCreated, handleClose]);

  return (
    <>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleOpen}
        data-testid="new-template-button"
      >
        New template
      </Button>
      <AddMemoTemplateDialog
        open={open}
        onClose={handleClose}
        onMemoTemplateCreated={handleCreated}
      />
    </>
  );
};

export default AddMemoTemplateButton;

import { usePublicFetch, usePublicFetchText } from "utils/sdk";

import {
  IDeal,
  IDealCommunityAmenities,
  IDealCoreData,
  IDealSchedule,
  IDealUnitAmenities,
} from "entities/Deal/sdk";
import { IDealSummary, IDealTeamCompany } from "entities/Memo/sdk";
import { IMemo } from "entities/Memo/sdk";
import { IProforma } from "entities/Proforma/sdk";
import { IRentalIncomeData } from "entities/Proforma/sdk";

export interface IPublicMemo extends IMemo {
  id: number;
}

export const useVerifyShareToken = ({ token }: { token: string | undefined }) =>
  usePublicFetchText(
    token ? `v2/deal/token-access/verify-share-token/${token}/` : undefined
  );

export const usePublicDealDetails = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<IDeal>(
    token ? `v2/deal/token-access/${token}/deal-details/` : undefined
  );

export const usePublicMemoDealDetails = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<IPublicMemo>(
    token ? `v2/memo/token-access/${token}/memo-deal-details/` : undefined
  );

export const usePublicRentalData = ({
  token,
  dealId,
}: {
  token: string | undefined;
  dealId: number;
}) =>
  usePublicFetch<IRentalIncomeData>(
    token && dealId
      ? `v2/deal/token-access/${token}/deal/${dealId}/rental-data/`
      : undefined
  );

export const usePublicDealCoreData = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<IDealCoreData>(
    token ? `v2/deal/token-access/${token}/deal-core-data/` : undefined
  );

export const usePublicDealSummaryByCategory = ({
  token,
  category,
}: {
  token: string | undefined;
  category: string;
}) =>
  usePublicFetch<IDealSummary>(
    token && category
      ? `v2/deal/token-access/${token}/category/${category}/summary/`
      : undefined
  );

export const usePublicProformaForDeal = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<IProforma>(
    token ? `v2/deal/token-access/${token}/proforma-details/` : undefined
  );

export const usePublicDealTeamGroup = ({
  token,
  groupName,
}: {
  token: string | undefined;
  groupName: string;
}) =>
  usePublicFetch<IDealTeamCompany[]>(
    token && groupName
      ? `v2/deal/token-access/${token}/team-group/?group_name=${groupName}`
      : undefined
  );

export const usePublicDealSchedule = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<Array<IDealSchedule>>(
    token ? `v2/deal/token-access/${token}/deal-schedule/` : undefined
  );

export const usePublicDealUnitAmenities = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<IDealUnitAmenities>(
    token ? `v2/deal/token-access/${token}/unit-amenities/` : undefined
  );

export const usePublicDealCommunityAmenities = ({
  token,
}: {
  token: string | undefined;
}) =>
  usePublicFetch<IDealCommunityAmenities>(
    token ? `v2/deal/token-access/${token}/community-amenities/` : undefined
  );

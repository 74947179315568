import { memo, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Box from "components/Box";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import { IFieldValueHistoryItem } from "entities/Package/sdk";

export interface IRichTextFieldHistoryElement {
  value: IFieldValueHistoryItem;
}

const RichTextFieldHistoryElement = ({
  value,
}: IRichTextFieldHistoryElement) => {
  const timestamp = useMemo(
    () => format(parseJSON(value.uploaded_at), FORMATS.FRONTEND.DATETIME),
    [value]
  );

  const valueIsNotEmpty = useMemo(() => value.rich_value !== "", [value]);

  const creatorText = useMemo(() => {
    if (!_.isNil(value.creator)) {
      return `${value.creator.first_name} ${value.creator.last_name}`;
    }
    return "Past proforma upload";
  }, [value]);

  return (
    <Stack data-testid="field-history-entity">
      <Text
        variant="text2"
        sx={{ color: colors.gray60, width: 600 }}
        data-testid="entity-title"
      >
        <b>{creatorText}</b> {valueIsNotEmpty ? "updated" : "deleted"} value at{" "}
        {timestamp}
      </Text>
      {valueIsNotEmpty ? (
        <Box
          sx={{
            flex: 1,
            borderRadius: theme.spacing(0.5),
            border: `1px solid ${colors.blue20}`,
            padding: theme.spacing(1, 1.5),
            marginY: theme.spacing(1),
            backgroundColor: colors.blue5,
          }}
        >
          {!_.isNil(value.rich_value) && (
            <SanitizeHtml html={value.rich_value} dataTestid="entity-value" />
          )}
        </Box>
      ) : (
        <Text variant="h6" sx={{ width: 600 }} data-testid="entity-value">
          (deleted)
        </Text>
      )}
    </Stack>
  );
};

export default memo(RichTextFieldHistoryElement);

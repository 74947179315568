import { useCallback, useMemo } from "react";
import _ from "lodash";

import { columnIsSortedByNumbers, sortListBy } from "utils/common";

import ScrollableTable from "components/ScrollableTable";
import { useFilters } from "components/useFilters";
import { ORDER_BY_COLUMN_FILTER_KEY } from "components/useFilters/constants";

import {
  getFilteredColumns,
  getHeaders,
} from "entities/Deal/components/ListTable/utils";
import { IPipelineColumns } from "entities/Deal/sdk";

export interface IListTable {
  rows: { [key: string]: string | null }[];
  columns: Array<IPipelineColumns>;
  showLoading: boolean;
}

const ListTable = ({ rows, columns, showLoading }: IListTable) => {
  const { filters, deleteFilter, updateFilter } = useFilters();
  const selectedSortOption = useMemo(
    () => _.get(filters, [ORDER_BY_COLUMN_FILTER_KEY]),
    [filters]
  );

  const handleUpdateSortOption = useCallback(
    ({ key: option }: { key: string | undefined }) => {
      if (_.isUndefined(option)) {
        deleteFilter({ key: ORDER_BY_COLUMN_FILTER_KEY });
      } else {
        updateFilter({ key: ORDER_BY_COLUMN_FILTER_KEY, value: option });
      }
    },
    [deleteFilter, updateFilter]
  );

  const isSortedByNumbers = useMemo(
    () =>
      columnIsSortedByNumbers({
        selectedSortOption,
        data: rows,
      }),
    [rows, selectedSortOption]
  );

  const sortedDeals = useMemo(
    () => sortListBy(rows, selectedSortOption, isSortedByNumbers),
    [rows, selectedSortOption, isSortedByNumbers]
  );

  const headers = useMemo(
    () => getHeaders(getFilteredColumns({ columns })),
    [columns]
  );

  return (
    <ScrollableTable
      rows={sortedDeals}
      headers={headers}
      sortBy={selectedSortOption}
      setSortBy={handleUpdateSortOption}
      showLoading={showLoading}
      tableDataTestid="deals-table-list"
      dynamicHyphen={true}
      rowDataTestid="deal-data-row"
      rowCellDataTestid="deal-data-cell"
    />
  );
};

export default ListTable;

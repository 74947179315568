import React from "react";

import { IFixedTable } from "components/FixedTable";
import CellSkeleton from "components/FixedTable/CellSkeleton";
import { TableBody, TableRow } from "components/Table";

interface IBodySkeleton {
  headers: IFixedTable["headers"];
}

const BodySkeleton = ({ headers }: IBodySkeleton) => {
  return (
    <TableBody>
      {[1, 2, 3].map((index) => (
        <TableRow key={index}>
          {headers.map((header) => (
            <CellSkeleton key={header.key} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default BodySkeleton;

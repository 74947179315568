import PageHeader from "components/PageHeader";

import AddNewTeamButton from "entities/Team/components/AddNewTeamButton";

interface IWorkspacePageTitle {
  dealId: number;
  isAdmin: boolean;
  onAddNewTeam: () => void;
}

const WorkspacePageTitle: React.FC<IWorkspacePageTitle> = ({
  dealId,
  isAdmin,
  onAddNewTeam,
}) => {
  return (
    <PageHeader
      title="Teams"
      actions={
        <>
          {isAdmin && (
            <AddNewTeamButton dealId={dealId} onSave={onAddNewTeam} />
          )}
        </>
      }
    />
  );
};

export default WorkspacePageTitle;

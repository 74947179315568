// Note: Calling getEnvironmentVariables here returns "undefined" when ran in Storybook.
const BASE_BACKEND_URL = import.meta.env.VITE_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}/`;

export const EXTERNAL_API_DOCS_URL = `${BASE_URL}api/schema/swagger-ui/`;

export enum URLS {
  PROFILE = "/profile",
  ANALYTICS_DEV_BUDGET_LIST = "/analytics/development-budget/groups",
  ANALYTICS_DEV_BUDGET_DETAILS = "/analytics/development-budget/groups/:id",
  ANALYTICS_INCOME_AND_OPEX_LIST = "/analytics/income-and-opex/groups",
  ANALYTICS_INCOME_AND_OPEX_DETAILS = "/analytics/income-and-opex/groups/:id",
  PROJECTS_LIST = "/projects/:phase/",
  PROJECTS_LIST_VIEW = "/projects/:phase/:view",
  NOTIFICATIONS = "/notifications",
  BOOKMARKS = "/bookmarks",

  // Deal packages V2; Due diligence V2
  DEAL_TEAM_INVITATION_ACCEPT = "/deals/invitation/:invitationId/accept",
  DEAL_PACKAGE = "/deals/:dealId/package/:packageId/tab/:tabId/checklists",
  DEAL_PACKAGE_NO_TAB = "/deals/:dealId/package/:packageId/checklists",
  DEAL_PACKAGE_TASK = "/deals/:dealId/package/:packageId/tab/:tabId/category/:categoryId/checklists",
  DEAL_PACKAGE_TASK_SECTION = "/deals/:dealId/package/:packageId/tab/:tabId/category/:categoryId/section/:sectionId/checklists",
  DEAL_PACKAGE_TASK_FIELD = "/deals/:dealId/package/:packageId/tab/:tabId/category/:categoryId/section/:sectionId/field/:fieldId/checklists",
  DEAL_CHECKLISTS_TASK_OLD = "/deals/:dealId/analytics/pkg/:packageId/tab/:tabId/sec/:categoryId", // this is the old url
  DEAL_OVERVIEW = "/deals/:dealId/overview",
  DEAL_ASSET_MANAGEMENT = "/deals/:dealId/proforma/asset-management",
  DEAL_ASSET_MANAGEMENT_VERSIONS = "/deals/:dealId/proforma/asset-management/versions",
  DEAL_ASSET_MANAGEMENT_PDFS = "/deals/:dealId/proforma/asset-management/pdfs",
  DEAL_ASSET_MANAGEMENT_PDF_DIFF = "/deals/:dealId/proforma/asset-management/pdfs/:pdfId/compare",
  PROXY_LOGIN = "/proxy-login",
  SUPERUSER_DASHBOARD = "/dashboard",
  SUPERUSER_DATA_TYPES_MIGRATION = "/data-types-migration",
  SUPERUSER_LINKED_FIELDS = "/linked-fields",
  REPORTING_INCOME_AND_OPEX = "/reporting/income-and-opex",
  REPORTING_DEAL_CORE_DATA = "/reporting/core-deal-data",
  REPORTING_CUSTOM = "/reporting/custom-report/:customReportId",
  DEAL_PROFORMA = "/deals/:dealId/proforma",
  DEAL_PROFORMA_TAB = "/deals/:dealId/proforma/:tab",
  EMAIL_VERIFICATION = "/email-verification",
  PLEASE_VERIFY_YOUR_EMAIL = "/please-verify-your-email",
  DEAL_WORKSPACE = "/deals/:dealId/workspace",
  DEAL_WORKSPACE_TAB = "/deals/:dealId/workspace/:tab",
  ALL_DEAL_TASKS = "/all-deal-tasks",
  DEAL_WORKSPACE_TASKS = "/deals/:dealId/task", // this is the old url from the emails
  SHAREABLE_MEMO = "/memo/external-access/:token",
  DEAL_MEMO_NO_PACKAGE = "/deals/:dealId/memo",
  DEAL_MEMO = "/deals/:dealId/memo/:memoId/details",
  CHECKLIST_SHARE_TOKEN = "/checklist-share-token/:token/accept",
  COMPANY_INTEGRATIONS_LIST = "/deals/:dealId/company-integrations/list",
  COMPANY_INTEGRATION_DOWNHOME_BUSINESS_CREATE = "/deals/:dealId/company-integration/downhome-business/create",
  COMPANY_INTEGRATION_DOWNHOME_BUSINESS_UPDATE = "/deals/:dealId/company-integration/downhome-business/update/:externalId",
  DEAL_TABLE_STORAGE_LIST = "/deals/:dealId/data-tables/list",
  DEAL_TABLE_STORAGE_DETAILS = "/deals/:dealId/data-tables/:tableId/details",
  DEAL_TABLE_STORAGE_CREATE = "/deals/:dealId/data-tables/create",
  DEAL_TABLES_COMPARE = "/deals/:dealId/data-tables/:tableId/compare",
  SHARED_CHECKLIST_CLAIM = "/shared-checklist/:shareToken/claim",
  ONBOARDING = "/onboarding",

  APPLICATION_DIRECT_LINK = "/applications/:token/direct-link",
  APPLICATIONS_LIST = "/applications/:status/list",
  APPLICATION_EDIT = "/applications/:applicationId/edit",
  APPLICATION_PREVIEW = "/applications/:applicationId/preview",
  APPLICATIONS_LENDER_LIST = "/applications/lender/:status/list",

  DEAL_PACKAGE_EDIT_PREVIEW = "/deals/:dealId/package/:packageId/pdf-import/:pdfImportId/edit-preview",

  // Templates
  CHECKLIST_TEMPLATE_LIST = "/templates/checklists",
  CHECKLIST_TEMPLATE_DETAILS = "/templates/checklists/:checklistId/details",
  MEMO_TEMPLATE_LIST = "/templates/memos",
  MEMO_TEMPLATE_EDITOR = "/templates/memos/:memoId/edit",
  COMPANY_CHECKLISTS = "/company-checklists",
  COMPANY_CHECKLISTS_PREVIEW = "/company-checklists/:checklistId/preview",

  DEAL_AI_SUMMARIES = "/deals/:dealId/ai-summaries",
  DEAL_AI_SUMMARIES_EDITOR = "/deals/:dealId/ai-summaries/:openAIDocumentAssistantId/ask-question",
  DEAL_CHECKISTS_OVERVIEW = "/deals/:dealId/checklists-overview",

  NOT_FOUND = "/404",
  ACCESS_DENIED = "/access-denied",
  DEAL_ACCESS_DENIED = "/deal-access-denied",

  SUPERUSER_CUSTOMER_LIST = "/customers/list",
  SUPERUSER_CUSTOMER_DETAILS = "/customers/:companyId",
  FEATURE_ACCESS_DENIED = "/feature-access-denied",

  TABLE_STORAGE_LIST = "/data-tables/list",
  TABLE_STORAGE_DETAILS = "/data-tables/:tableId/details",
  TABLE_STORAGE_CREATE = "/data-tables/create",
  TABLES_COMPARE_WITH_INITIAL = "/data-tables/:tableId/compare",
  TABLES_COMPARE = "/data-tables/compare",
  TABLES_RESPONSE_REPORT_CREATE = "/tables-response/reports/:reportType/create",
  TABLES_RESPONSE_LIST = "/tables-response/list",
  TABLES_RESPONSE_DETAIL = "/tables-response/:tableResponseId/details",
}

import { useCallback, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { SaveOutlinedIcon } from "icons";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import IconButton from "components/IconButton";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import { dealTableCreate } from "entities/Deal/sdk";
import { tableCreate } from "entities/TableStorage/sdk";

import { isHTMLTableData } from "../PasteTableField/utils";
import { VALIDATION_SCHEMA } from "./constants";
import { transformHTMLTableInTableRows } from "./utils";

export interface ISaveHTMLTableButton {
  dealId: number | null;
  tableData: string;
  onSave: ({ tableId }: { tableId: number }) => void;
}

const SaveHTMLTableButton = ({
  dealId,
  tableData,
  onSave,
}: ISaveHTMLTableButton) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSaveTable = useCallback(
    (values) => {
      const tableName = values.name;

      const rows = transformHTMLTableInTableRows({ data: tableData });

      if (_.isUndefined(rows)) {
        return;
      }

      if (!_.isNil(dealId)) {
        dealTableCreate({
          rows,
          name: tableName,
          dealId,
        })
          .then(({ id }) => onSave({ tableId: id }))
          .catch(handleInvalidRequest);
      } else {
        tableCreate({
          name: tableName,
          rows,
        })
          .then(({ id }) => onSave({ tableId: id }))
          .catch(handleInvalidRequest);
      }
    },
    [tableData, dealId, onSave]
  );

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSaveTable,
  });

  const openDialog = useCallback(() => {
    if (isHTMLTableData({ data: tableData })) {
      setIsDialogOpen(true);
    } else {
      toast.errorMessage("The data is not a valid table.");
    }
  }, [tableData]);

  const handleSaveClick = useCheckFeature({
    feature: ECompanySettingsFeatureType.DEAL_TABLES,
    callback: openDialog,
  });

  const handleCancel = useCallback(() => {
    if (!formik.isSubmitting) {
      formik.resetForm();
      setIsDialogOpen(false);
    }
  }, [formik]);

  return (
    <>
      <Tooltip title="Save the table in data tables">
        <IconButton onClick={handleSaveClick}>
          <SaveOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={isDialogOpen} onClose={handleCancel}>
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Save the table
          </Text>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ padding: 2 }}>
            <TextField
              label="Table name"
              placeholder="Enter table name"
              fullWidth
              {...formik.getFieldProps("name")}
              error={formik.touched.name && !_.isEmpty(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SaveHTMLTableButton;

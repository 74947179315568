import { memo } from "react";
import _ from "lodash";

import { ArrowBackIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import { Link } from "components/Link";
import Text from "components/Text";

export interface IBackLink {
  to: string;
  children: React.ReactNode;
  linkDataTestid?: string;
  buttonLabelDataTestid?: string;
}

const BackLink = ({
  to,
  children,
  linkDataTestid,
  buttonLabelDataTestid,
}: IBackLink) => (
  <Link
    to={to}
    style={{
      lineHeight: 1,
      fontSize: "13px",
      display: "flex",
      alignItems: "center",
    }}
    linkDataTestid={linkDataTestid}
  >
    <ArrowBackIcon
      sx={{
        color: colors.blue100,
        width: "20px",
        height: "20px",
        marginRight: theme.spacing(0.5),
      }}
    />
    <Text
      variant="label"
      color="primary"
      fontWeight={500}
      data-testid={buttonLabelDataTestid}
    >
      {children}
    </Text>
  </Link>
);

export default memo(BackLink, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useMemo, useState } from "react";
import _ from "lodash";

import Button from "components/Button";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import CompaniesDialog from "entities/Deal/components/DealChecklists/CompaniesDialog";
import { useDealPackageCompanyWithAccess } from "entities/Deal/sdk";
import ChecklistAccessCompaniesTable from "entities/Package/components/ChecklistAccessCompaniesTable";
import PartialAccessUsersTable from "entities/Package/components/PartialAccessUsersTable";
import { getItemsWithoutChildrenCount } from "entities/Package/components/ShareDialog/components/ChecklistSelectionStep/utils";
import ShareTokensTable from "entities/Package/components/ShareTokensTable";
import {
  useCompanyPackageStructure,
  usePartialAccessUsersList,
  useShareTokenList,
} from "entities/Package/sdk";

export interface IShareSettings {
  dealId: number;
  companyPackageId: number;
}

const ShareSettings = ({ dealId, companyPackageId }: IShareSettings) => {
  const [isCompanyAccessDialogOpen, setIsCompanyAccessDialogOpen] =
    useState(false);
  const { data: partialUserAccesses, mutate: updatePartialAccesses } =
    usePartialAccessUsersList({
      companyPackageId,
      dealId,
      disabled: false,
    });

  const { data: invitedUsers, mutate: updateShareTokens } = useShareTokenList({
    companyPackageId,
    dealId,
    disabled: false,
  });

  const { data: dealPackageCompanies, mutate: updateDealPackageCompanies } =
    useDealPackageCompanyWithAccess({
      dealId,
      companyPackageId,
    });

  const { data: checklist, isLoading: isLoadingChecklist } =
    useCompanyPackageStructure({
      packageId: companyPackageId,
      dealId,
    });

  const items = useMemo(
    () =>
      checklist?.tabs.map((tab) => ({
        id: tab.tab_id,
        name: tab.name,
        isField: false,
        children: tab.categories.map((category) => ({
          id: category.category_id,
          name: category.name,
          isField: false,
          children: category.sections.map((section) => ({
            id: section.section_id,
            name: section.name,
            isField: false,
            children: section.fields.map((field) => ({
              id: field.field_id,
              name: field.name,
              isField: true,
              children: [],
            })),
          })),
        })),
      })) || [],
    [checklist]
  );

  const itemsCount = useMemo(
    () => getItemsWithoutChildrenCount({ items: items }),
    [items]
  );

  const showPartialUserAccessLoader =
    _.isNil(partialUserAccesses) || isLoadingChecklist || _.isNil(checklist);

  const showInvitationsLoader =
    _.isNil(invitedUsers) || isLoadingChecklist || _.isNil(checklist);

  return (
    <Stack spacing={4} data-testid="checklist-share-settings-content">
      <Stack
        spacing={2}
        overflow="auto"
        flex={1}
        data-testid="checklist-access-companies-table"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="h3" data-testid="table-header">
            Company accesses
          </Text>

          <Button
            variant="outlined"
            onClick={() => setIsCompanyAccessDialogOpen(true)}
            data-testid="update-button"
          >
            Update
          </Button>
        </Stack>
        {_.isNil(dealPackageCompanies) ? (
          <Skeleton height={100} />
        ) : (
          <ChecklistAccessCompaniesTable
            companies={dealPackageCompanies}
            onUpdate={updateDealPackageCompanies}
          />
        )}
      </Stack>

      <Stack
        spacing={2}
        overflow="auto"
        flex={1}
        data-testid="partial-access-users-table"
      >
        <Text variant="h3" data-testid="table-header">
          Partial access users
        </Text>
        {showPartialUserAccessLoader ? (
          <Skeleton height={100} />
        ) : (
          <PartialAccessUsersTable
            checklistItems={items}
            itemsCount={itemsCount}
            checklistName={checklist.name}
            partialUserAccesses={partialUserAccesses}
            onUpdate={updatePartialAccesses}
          />
        )}
      </Stack>
      <Stack spacing={2} data-testid="invitations-table">
        <Text variant="h3" data-testid="table-header">
          Invite sent
        </Text>
        {showInvitationsLoader ? (
          <Skeleton height={100} />
        ) : (
          <ShareTokensTable
            checklistItems={items}
            itemsCount={itemsCount}
            shareTokens={invitedUsers}
            checklistName={checklist.name}
            onUpdate={updateShareTokens}
          />
        )}
      </Stack>

      <CompaniesDialog
        isOpen={isCompanyAccessDialogOpen}
        dealId={dealId}
        companyPackageId={companyPackageId}
        onClose={() => {
          updateDealPackageCompanies();
          setIsCompanyAccessDialogOpen(false);
        }}
      />
    </Stack>
  );
};

export default ShareSettings;

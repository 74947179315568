import { memo, useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { DeleteIcon } from "icons";
import { EMPTY_LIST } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import IconButton from "components/IconButton";
import { LinearProgress } from "components/Loading";
import NoPrint from "components/NoPrint";
import SimpleTable from "components/SimpleTable";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import TextField from "components/TextField";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { fieldValueCreate } from "entities/Field/sdk";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import {
  IMemoSection,
  IMemoSectionExcelTableFieldValue,
} from "entities/Memo/sdk";

import {
  convertPastedDataToTableRows,
  convertSimpleTableDataToFieldValue,
  formatHeaders,
  formatRows,
} from "./utils";

interface ISectionExcelTable {
  section: IMemoSection;
  dealId: number;
  onUpdate: () => void;
  externalToken?: string;
}

const SectionExcelTable = ({
  section,
  dealId,
  externalToken,
  onUpdate,
}: ISectionExcelTable) => {
  const [isResetting, setIsResetting] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const field = useMemo(() => _.first(section.fields), [section]);
  const fieldValue = useMemo(
    () => _.get(field, "values.[0].column_value", EMPTY_LIST),
    [field]
  ) as Array<IMemoSectionExcelTableFieldValue>;

  /**
   * The field values is in the format Array<{order: number, columns: string[]}>
   * The element with order 0 is the header row, and the rest of the elements are the data rows.
   */
  const defaultHeaders = useMemo(() => {
    if (_.isUndefined(fieldValue)) {
      return EMPTY_LIST;
    }

    const headerRow = _.find(fieldValue, { order: 0 });

    if (_.isUndefined(headerRow)) {
      return EMPTY_LIST;
    }

    return formatHeaders({ headers: headerRow.columns });
  }, [fieldValue]);

  const [headers, setHeaders] = useState(defaultHeaders);

  /**
   * The default value is an array of objects, where each object represents a row in the table.
   * The keys of the object are the headers of the table, that is, the first element of fieldValue and parsed in headers.
   */
  const defaultValue = useMemo(() => {
    if (_.isUndefined(fieldValue)) {
      return EMPTY_LIST;
    }

    const rows = fieldValue
      .filter((row) => row?.order !== 0)
      .map((row) => row?.columns);

    const formattedRows = formatRows({
      rows,
      headers,
    });

    return formattedRows;
  }, [fieldValue, headers]);

  const [value, setValue] = useState(defaultValue);

  const { show: showConfirmationDialog } = useConfirmationDialog();

  const onSaveValue = useCallback(
    ({ tableDataRows }: { tableDataRows: Array<Array<string>> }) => {
      if (!_.isUndefined(field)) {
        const formattedValue = convertSimpleTableDataToFieldValue({
          tableDataRows,
        });

        fieldValueCreate({
          dealId,
          companyCustomFieldId: field.company_custom_field_id,
          value: JSON.stringify(formattedValue),
        })
          .then(() => {
            toast.successMessage(`${field.name} successfully updated!`);
            onUpdate();
          })
          .catch(handleInvalidRequest)
          .finally(() => {
            setShowLoading(false);
            setIsResetting(false);
          });
      }
    },
    [field, dealId, onUpdate]
  );

  const handlePasteTableData = useCallback(
    (e) => {
      /**
       * Don't allow pasting data if:
       * - the table is loading / there is a request in progress
       * - the field is undefined
       * - the externalToken is defined, which means that this memo is opened with shared token
       */
      if (
        showLoading ||
        _.isUndefined(field) ||
        !_.isUndefined(externalToken)
      ) {
        return;
      }

      setShowLoading(true);

      const pasteData = e.clipboardData.getData("text");

      if (_.isNull(pasteData)) {
        setShowLoading(false);
        return;
      }

      const tableDataRows: Array<Array<string>> = convertPastedDataToTableRows({
        data: pasteData,
      });

      if (_.isEmpty(tableDataRows) || tableDataRows.length === 1) {
        toast.errorMessage("The provided data cannot be parsed as a table.");
        setShowLoading(false);
        return;
      }

      const headers = formatHeaders({ headers: tableDataRows[0] });
      setHeaders(headers);

      const rows = formatRows({
        rows: tableDataRows.slice(1),
        headers,
      });
      setValue(rows);

      onSaveValue({
        tableDataRows,
      });
    },
    [field, showLoading, externalToken, onSaveValue, setHeaders, setValue]
  );

  const handleResetTable = useCallback(() => {
    showConfirmationDialog({
      onConfirm: () => {
        setIsResetting(true);
        setValue([]);
        setHeaders([]);
        onSaveValue({ tableDataRows: [] });
      },
      message: "Are you sure you want to reset this table?",
    });
  }, [onSaveValue, showConfirmationDialog]);

  const showInputToPasteData = useMemo(
    () => _.isEmpty(value) && !showLoading,
    [value, showLoading]
  );

  return (
    <Box>
      <SectionHeader
        label={section.title}
        alignActionButton="right"
        actionButton={
          _.isUndefined(externalToken) && (
            <NoPrint>
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )
        }
      />
      <SectionContent>
        <Box
          sx={{
            marginTop: "8px",
            "& .MuiIconButton-root": { visibility: "hidden" },
            "&:hover .MuiIconButton-root": {
              visibility: "visible",
            },
          }}
        >
          {showLoading && <LinearProgress />}
          {section.is_na ? (
            <NASection />
          ) : (
            <>
              {showInputToPasteData ? (
                <NoPrint>
                  {isResetting ? (
                    <Skeleton variant="rectangular" height="125px" />
                  ) : (
                    <TextField
                      rows={4}
                      InputProps={{
                        readOnly: true,
                      }}
                      multiline
                      sx={{ width: "100%" }}
                      placeholder="Paste table..."
                      onPaste={handlePasteTableData}
                    />
                  )}
                </NoPrint>
              ) : (
                <Stack spacing={2}>
                  <SimpleTable
                    showLoading={showLoading}
                    headers={headers}
                    rows={value}
                    size="small"
                  />
                  {_.isUndefined(externalToken) && !isResetting && (
                    <NoPrint
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <Tooltip title="Reset table">
                        <span>
                          <IconButton
                            disabled={showLoading}
                            sx={{ alignSelf: "flex-end" }}
                            onClick={handleResetTable}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </NoPrint>
                  )}
                </Stack>
              )}
            </>
          )}
        </Box>
      </SectionContent>
    </Box>
  );
};

export default memo(SectionExcelTable, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { Outlet } from "react-router-dom";

import NotFound from "pages/404";

import { useSignedUser } from "entities/Auth/sdk";

const SuperUserGuard = () => {
  const { data: user } = useSignedUser();

  if (user && !user.is_superuser) {
    return <NotFound />;
  }

  return <Outlet />;
};

export default SuperUserGuard;

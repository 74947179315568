import { useCallback } from "react";

import PageHeader from "components/PageHeader";
import Select from "components/Select";
import Stack from "components/Stack";

import FilterButton from "entities/Task/components/FilterButton";
import WorkspaceNewTaskButton from "entities/Task/components/WorkspaceNewTaskButton";
import { TaskTypes } from "entities/Task/constants";
import { ITypeOfFilter } from "entities/Task/sdk";

interface IWorkspacePageTitle {
  taskType: TaskTypes;
  initialFilters: ITypeOfFilter;
  isPartiallyAccessible?: boolean;
  onChangeTaskType: (taskType: TaskTypes) => void;
  onChangeFilters: (selectedFilters: ITypeOfFilter) => void;
  onCreateNewTask: () => void;
}

const taskTypesOptions = [
  {
    label: "All tasks",
    value: TaskTypes.ALL_TASKS,
  },
  {
    label: "My tasks",
    value: TaskTypes.MY_TASKS,
  },
  {
    label: "My team tasks",
    value: TaskTypes.MY_TEAM_TASKS,
  },
];

const WorkspacePageTitle: React.FC<IWorkspacePageTitle> = ({
  taskType,
  initialFilters,
  isPartiallyAccessible = false,
  onChangeTaskType,
  onChangeFilters,
  onCreateNewTask,
}) => {
  const handleChangeSelector = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTaskType(event.target.value as TaskTypes);
    },
    [onChangeTaskType]
  );

  return (
    <PageHeader
      title="Task management"
      actions={
        <Stack
          sx={{ flex: 1 }}
          spacing={2}
          direction="row"
          justifyContent={isPartiallyAccessible ? "end" : "space-between"}
        >
          {!isPartiallyAccessible && (
            <Select
              size="small"
              sx={{ width: "130px", backgroundColor: "white" }}
              label="Task type"
              options={taskTypesOptions}
              onChange={handleChangeSelector}
              value={taskType}
            />
          )}
          <Stack direction="row" spacing={1}>
            <FilterButton
              initialFilters={initialFilters}
              onChange={onChangeFilters}
            />
            {!isPartiallyAccessible && (
              <WorkspaceNewTaskButton onSubmit={onCreateNewTask} />
            )}
          </Stack>
        </Stack>
      }
    />
  );
};

export default WorkspacePageTitle;

import React, { useCallback } from "react";
import { styled } from "@mui/system";

import Dropzone, { DropzoneState, FileRejection } from "components/Dropzone";
import toast from "components/Toast";

import { defaultUploadButton } from "./utils";

const Input = styled("input")({
  display: "none",
});
interface IUploadButton {
  label?: string;
  maxFiles?: number;
  accept?: string;
  dataTestid?: string;
  onChange: ({ files }: { files: Array<File> }) => void;
  renderComponent?: (
    state: Partial<DropzoneState & { onClick: () => void }>
  ) => JSX.Element;
  style?: React.CSSProperties;
}

const UploadButton: React.FC<IUploadButton> = ({
  label = "Browse for Files",
  accept = "image/*",
  dataTestid,
  maxFiles,
  onChange,
  renderComponent = defaultUploadButton,
  style = {},
}) => {
  const handleRejected = useCallback(
    (files: FileRejection[]) => {
      files.map(
        ({
          errors,
          file,
        }: {
          errors: Array<{ code: string; message: string }>;
          file: File;
        }) => {
          let errorMessage = errors[0].message;

          if (errors[0].code === "too-many-files") {
            errorMessage = `${errorMessage}. You can upload a maximum of ${maxFiles} files at once.`;
          }

          return toast.errorMessage(`${file.name} - ${errorMessage}`);
        }
      );
    },
    [maxFiles]
  );

  const handleAccepted = useCallback(
    (files: Array<File>) => {
      onChange({ files });
    },
    [onChange]
  );

  return (
    <Dropzone
      onDropRejected={handleRejected}
      onDropAccepted={handleAccepted}
      maxFiles={maxFiles}
    >
      {({ getRootProps, getInputProps, isDragActive }: DropzoneState) => (
        <div
          {...getRootProps()}
          style={{ width: "100%", ...style }}
          data-testid="upload-file-dropzone"
        >
          <Input
            {...getInputProps()}
            accept={accept}
            type="file"
            data-testid={dataTestid}
          />
          {renderComponent(
            { getRootProps, getInputProps, isDragActive },
            label
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default UploadButton;

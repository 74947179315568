import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { colors } from "theme/palette";
import { useRedirectUponFetchFailure } from "utils/common";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Loading from "components/Loading";
import NavLink from "components/NavLink";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import FormButtons from "entities/Application/components/FormButtons";
import RenameDialogButton from "entities/Application/components/RenameDialogButton";
import SubmittedSuccessfullyDialog from "entities/Application/components/SubmittedSuccessfullyDialog";
import { APPLICATION_STATUS } from "entities/Application/constants";
import { useApplicationDetails } from "entities/Application/sdk";
import Category from "entities/Checklist/components/Category";
import { FieldWrapperType } from "entities/Checklist/constants";

const ApplicationEdit = () => {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const params = useParams<{ applicationId: string }>();
  const applicationId = Number(params.applicationId);
  const {
    data: application,
    mutate: updateApplicationDetails,
    error,
    loading,
  } = useApplicationDetails({
    applicationId,
  });

  useRedirectUponFetchFailure({
    error,
    loading,
  });

  const isDraft = useMemo(
    () => application?.status === APPLICATION_STATUS.DRAFT,
    [application]
  );

  const allRequiredFieldsHaveValue = useMemo(() => {
    return _.every(
      application?.company_package.tabs.flatMap((tab) =>
        tab.categories.flatMap((category) =>
          category.sections.flatMap((section) =>
            section.fields.flatMap((field) => {
              if (!field.is_required) {
                return true;
              }
              return (
                field.is_required &&
                field.values.length !== 0 &&
                field.values.flatMap((value) => value.value !== "")
              );
            })
          )
        )
      )
    );
  }, [application]);

  usePageTitle(`Builders Patch: Application - ${isDraft ? "Edit" : "Preview"}`);

  const pageHeaderSubTitle = useMemo(() => {
    if (!_.isNil(application)) {
      return application.status === APPLICATION_STATUS.SUBMITTED &&
        !_.isNil(application.submitted_at)
        ? `Submitted at ${format(
            parseJSON(application.submitted_at),
            FORMATS.FRONTEND.DATETIME
          )} by ${application.submitted_by.first_name} ${
            application.submitted_by.last_name
          }`
        : `Last saved ${format(
            parseJSON(application.updated_at),
            FORMATS.FRONTEND.DATETIME
          )} by ${application.updated_by.first_name} ${
            application.updated_by.last_name
          }`;
    }
  }, [application]);

  const handleSubmitApplication = useCallback(() => {
    updateApplicationDetails();
    setIsSuccessDialogOpen(true);
  }, [updateApplicationDetails]);

  return !loading && !_.isNil(application) ? (
    <>
      <Paper
        sx={{
          overflowX: "auto",
          padding: theme.spacing(3, 4),
        }}
      >
        <Stack>
          <Box
            sx={{
              maxWidth: BOX_MAX_WIDTH,
              alignSelf: "center",
              width: "100%",
            }}
          >
            <Stack spacing={3}>
              <Text variant="text2" color={colors.gray80} fontWeight={500}>
                <NavLink
                  to={reverse(URLS.APPLICATIONS_LIST, { status: "All" })}
                >
                  Intake
                </NavLink>{" "}
                &gt; {application.name}{" "}
                {isDraft && (
                  <RenameDialogButton
                    applicationId={applicationId}
                    initialValue={application.name}
                    onUpdate={updateApplicationDetails}
                  />
                )}
              </Text>
              <PageHeader
                title={<>{application.token.name} </>}
                subTitle={pageHeaderSubTitle}
                actions={
                  isDraft && (
                    <FormButtons
                      isSubmitButtonDisabled={!allRequiredFieldsHaveValue}
                      applicationId={applicationId}
                      applicationName={application.name}
                      onSubmit={handleSubmitApplication}
                      submitButtonTooltipText={
                        allRequiredFieldsHaveValue
                          ? ""
                          : "Please fill in all required fields"
                      }
                    />
                  )
                }
              />
              <Stack spacing={1}>
                {application.company_package?.tabs.map((tab) => (
                  <Stack spacing={1} key={`tab-${tab.tab_id}`}>
                    {tab.categories.map((category) => (
                      <Category
                        key={`tab-${tab.tab_id}-category-${category.category_id}`}
                        category={category as any} // TODO: fix this and remove any
                        fieldWrapperMeta={{
                          applicationId,
                          editable: isDraft,
                          onUpdate: updateApplicationDetails,
                        }}
                        fieldWrapperType={FieldWrapperType.APPLICATION}
                      />
                    ))}
                  </Stack>
                ))}
              </Stack>
              {isDraft && (
                <FormButtons
                  submitButtonTooltipText={
                    allRequiredFieldsHaveValue
                      ? ""
                      : "Please fill in all required fields"
                  }
                  isSubmitButtonDisabled={!allRequiredFieldsHaveValue}
                  applicationId={applicationId}
                  applicationName={application.name}
                  onSubmit={handleSubmitApplication}
                />
              )}
            </Stack>
          </Box>
        </Stack>
      </Paper>
      <SubmittedSuccessfullyDialog
        isOpen={isSuccessDialogOpen}
        applicationName={application.name}
      />
    </>
  ) : (
    <Loading open={loading} />
  );
};

export default ApplicationEdit;

import React, { useCallback } from "react";
import { useFormik } from "formik";

import CheckboxTree from "components/CheckboxTree";

import { useGetDealCities } from "entities/Deal/sdk";

interface IFormCities {
  formik: ReturnType<typeof useFormik>;
}

const FORM_VALUE_KEY = "cities";

const FormCities: React.FC<IFormCities> = ({ formik }) => {
  const { data: cities } = useGetDealCities();
  const citiesCheckboxes = new Set(cities?.map((city) => city.trim()) || []);

  const options = Array.from(citiesCheckboxes).map((city) => ({
    label: city,
    value: city,
  }));

  const handleChange = useCallback(
    (value: Array<number | string>) =>
      formik.setFieldValue(FORM_VALUE_KEY, value),
    [formik]
  );

  return (
    <CheckboxTree
      label="City"
      options={options}
      fieldInputProps={formik.getFieldProps(FORM_VALUE_KEY)}
      onChange={handleChange}
      value={formik.values[FORM_VALUE_KEY]}
    />
  );
};

export default FormCities;

import React, { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import * as yup from "yup";

import { CloseIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import UploadButton from "components/UploadButton";
import { defaultProformaUploadButton } from "components/UploadButton/utils";

import { AM_UPLOAD_FILE_DIALOG_SUB_TITLE_MAX_WIDTH } from "entities/AssetManagement/constants";
import { processAssetsData } from "entities/AssetManagement/sdk";
import { upload } from "entities/File/sdk";

import FormDateInfo from "../FormDateInfo";

export interface IUploadAssetsFileDialog {
  open: boolean;
  dealId: number;
  handleClose: () => void;
  setLoading: (data: boolean) => void;
}

interface IFormValues {
  file: File;
  year: Date;
  quarter: number;
  month: number;
}

const initialValues: Partial<IFormValues> = {
  year: new Date(),
};

const SUPPORTED_FORMATS = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const VALIDATION_SCHEMA = yup.object({
  file: yup
    .mixed()
    .test(
      "format",
      "Supported file types are: .xls, .xlsx",
      (value) => !(value && !SUPPORTED_FORMATS.includes((value as File).type))
    ),
});

const UploadAssetsFileDialog: React.FC<IUploadAssetsFileDialog> = ({
  open,
  dealId,
  handleClose,
  setLoading,
}) => {
  const processFileId = useCallback(
    (fileId: number, values: Partial<IFormValues>) => {
      processAssetsData(dealId, {
        file_id: fileId,
        date_info: {
          year: values.year,
          month: values.month,
          quarter: values.quarter,
        },
      });
    },
    [dealId]
  );

  const handleFormSubmit = useCallback(
    (values: Partial<IFormValues>, helper: any) => {
      if (values.file) {
        return upload({ file: values.file, isPrivate: true })
          .then((id) => {
            processFileId(id, values);
            helper.resetForm();
            handleClose();
            setLoading(true);
          })
          .catch(handleInvalidRequest);
      }
    },
    [handleClose, processFileId, setLoading]
  );

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleFormSubmit,
  });

  const handleFileUpload = useCallback(
    ({ files }: { files: Array<File> }) => {
      formik.setFieldValue("file", files[0]);
    },
    [formik]
  );

  const resetAndClose = () => {
    formik.resetForm();
    handleClose();
  };

  const clearFile = useCallback(() => {
    formik.setFieldValue("file", undefined);
  }, [formik]);

  const submitButtonIsDisabled = useMemo(
    () =>
      _.isNil(formik.values.year) ||
      !formik.values.file ||
      !formik.isValid ||
      !!(formik.values.month && formik.values.quarter) ||
      formik.isSubmitting,
    [formik]
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={resetAndClose}
      data-testid="asset-mgmt-upload-file-dialog"
    >
      <DialogTitle
        sx={{
          margin: theme.spacing(2, 2, 3, 2),
          borderRadius: theme.spacing(1),
          backgroundColor: colors.blue10,
        }}
        data-testid="dialog-header-section"
      >
        <Stack spacing={1}>
          <Text variant="h1" data-testid="dialog-title">
            Upload new data
          </Text>
          <Text
            variant="text2"
            color={colors.gray60}
            sx={{ maxWidth: AM_UPLOAD_FILE_DIALOG_SUB_TITLE_MAX_WIDTH }}
            data-testid="dialog-sub-title"
          >
            To add a new dataset or update an existing one, use our Excel
            template that you can download here to upload the numbers.
          </Text>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2} paddingLeft={4} direction="row">
            <Stack
              spacing={3}
              maxWidth="33%"
              data-testid="file-timeframe-settings"
            >
              <Stack>
                <Text variant="text2" fontWeight="500">
                  Timeframe
                </Text>
                <Text color={colors.gray60}>
                  What type of data are you uploading? For which time period
                  exactly?
                </Text>
              </Stack>
              <FormDateInfo formik={formik} />
            </Stack>
            <Stack width="100%" data-testid="upload-file-section">
              <UploadButton
                maxFiles={1}
                onChange={handleFileUpload}
                accept={SUPPORTED_FORMATS.join(", ")}
                renderComponent={(state) =>
                  defaultProformaUploadButton({
                    state,
                    errors: formik.errors.file && String(formik.errors.file),
                  })
                }
                dataTestid="upload-asset-mgmt-file-input"
              />
              {formik.values.file && (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Text variant="text2" data-testid="uploaded-file-name">
                    {formik.values.file.name}
                  </Text>
                  <IconButton
                    onClick={clearFile}
                    dataTestid="remove-uploaded-file-button"
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
        data-testid="dialog-actions-buttons"
      >
        <Button
          color="secondary"
          onClick={resetAndClose}
          data-testid="close-dialog-button"
        >
          Cancel
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          disabled={submitButtonIsDisabled}
          onClick={formik.submitForm}
          data-testid="submit-dialog-button"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadAssetsFileDialog;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import {
  ArrowDropDownIcon,
  ArrowRightIcon,
  DeleteOutlineOutlinedIcon,
} from "icons";
import { colors } from "theme/palette";
import { copyTextToClipboard } from "utils/common";

import Button from "components/Button";
import IconButton from "components/IconButton";
import { IRenderCell } from "components/StyledTable/utils/header";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

const NameCell: IRenderCell<
  {
    label: string;
    isTotalRow: boolean;
    isAdded: boolean;
    itemCode: string;
  },
  {
    setCollapsedSubCategories: React.Dispatch<React.SetStateAction<string[]>>;
    collapsedSubCategories: string[];
    editMode: boolean;
    handleRowDelete: (args: { rowKey: string }) => void;
    isDeleted: boolean;
  }
> = ({
  itemKey,
  row,
  index,
  Wrapper,
  setCollapsedSubCategories,
  collapsedSubCategories,
  editMode,
  handleRowDelete,
  isDeleted,
}) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleDelete = () => {
    showConfirmationDialog({
      onConfirm: () => handleRowDelete({ rowKey: row.itemKey }),
      confirmButtonText: "Delete",
      message:
        "Are you sure you want to delete this row and all of its contents?",
    });
  };

  const color = useMemo(() => {
    if (isDeleted) {
      return colors.red40;
    }

    if (row.isAdded) {
      return colors.green40;
    }

    return colors.gray10;
  }, [isDeleted, row.isAdded]);

  const cellDataTestId = useMemo(
    () =>
      row.isTotalRow ? "total-subcategory-cell" : "item-name-subcategory-cell",
    [row.isTotalRow]
  );

  const handleCodeClick = useCallback(({ code }: { code: string }) => {
    copyTextToClipboard({ text: code });
    toast.successMessage("Code has been copied to the clipboard.");
  }, []);

  return (
    <Wrapper
      style={{
        padding: `5px 16px 5px ${row.isTotalRow ? "15px" : "40px"}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 0,
        width: "350px",
        height: "40px",
        borderBottom: `1px solid ${colors.blue60}`,
        backgroundColor: color,
      }}
    >
      <div
        data-testid={cellDataTestId}
        onClick={() => {
          setCollapsedSubCategories((current) => _.xor(current, [row.label]));
        }}
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flex: 1,
          cursor: row.isTotalRow ? "pointer" : "inherit",
          overflow: "hidden",
        }}
      >
        {row.isTotalRow &&
          (collapsedSubCategories.indexOf(row.label) === -1 ? (
            <ArrowDropDownIcon />
          ) : (
            <ArrowRightIcon />
          ))}
        <span
          title={row.label}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontWeight: row.isTotalRow ? "bold" : "normal",
          }}
          data-testid="title-name-value"
        >
          {row.label}
        </span>
      </div>
      {!isDeleted && editMode && !row.isTotalRow && (
        <IconButton
          size="small"
          component="span"
          title="Delete row"
          onClick={handleDelete}
          dataTestid="delete-row-data-button"
          sx={{ marginRight: 1 }}
        >
          <DeleteOutlineOutlinedIcon sx={{ width: 20, height: 20 }} />
        </IconButton>
      )}
      {!row.isTotalRow && row.itemCode && (
        <Tooltip title={row.itemCode} enterDelay={1000} enterNextDelay={1000}>
          <Button
            color="secondary"
            size="small"
            data-testid="item-code-tag"
            sx={{ margin: 0, flexShrink: 0, maxWidth: "150px" }}
            onClick={() => handleCodeClick({ code: row.itemCode })}
          >
            <Text
              sx={{
                pointerEvents: "none",
                fontSize: "11px",
                textTransform: "uppercase",
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {row.itemCode}
            </Text>
          </Button>
        </Tooltip>
      )}
    </Wrapper>
  );
};
export default NameCell;

import theme from "theme";

import Box from "components/Box";
import Tag from "components/Tag";

import DefaultFilesTable from "entities/Field/components/Filefield/FilesTable";
import { IPackageDealValue } from "entities/Package/sdk";

export interface IFilesTable {
  customValues: Array<IPackageDealValue>;
  onDownload: ({ fileId }: { fileId: number }) => void;
}

const FilesTable = ({ customValues, onDownload }: IFilesTable) => {
  return (
    <Box sx={{ marginY: theme.spacing(1), paddingLeft: theme.spacing(6) }}>
      <Tag
        label="file uploads"
        variant="gray20"
        sx={{ display: "inline-block", marginBottom: theme.spacing(1) }}
      />
      <DefaultFilesTable
        customFieldValues={customValues}
        onDownload={onDownload}
      />
    </Box>
  );
};

export default FilesTable;

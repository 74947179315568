import { useMemo } from "react";

import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";
import { useFilters } from "components/useFilters";

import ReportTable from "entities/Reporting/components/ReportTable";
import { getFilteredColumns } from "entities/Reporting/components/ReportTable/utils";
import {
  CUSTOM_REPORT_SEARCH_KEYS,
  TABLE_SETTINGS_FILTER_KEY,
} from "entities/Reporting/constants";
import {
  INonPipelineColumns,
  ISimpleReportPackage,
  useReportPackageFilterList,
} from "entities/Reporting/sdk";
import { getFilteredDeals } from "entities/Reporting/utils";

const CustomViewContent = ({
  rows,
  columns,
  loading,
  isValidating,
  reportPackage,
}: {
  rows: { [key: string]: string | null }[];
  columns: INonPipelineColumns[];
  loading: boolean;
  isValidating: boolean;
  reportPackage: ISimpleReportPackage;
}) => {
  const { filters } = useFilters();
  const { data: filterDict } = useReportPackageFilterList({
    report_package_id: reportPackage.id,
  });

  const filteredDeals = useMemo(
    () =>
      getFilteredDeals({
        rows,
        selectedFilters: filters,
        filterDict,
        searchKeys: CUSTOM_REPORT_SEARCH_KEYS,
      }),
    [rows, filters, filterDict]
  );

  const filteredColumns = useMemo(
    () =>
      getFilteredColumns({
        columns,
        tableSettingsFilter: filters[TABLE_SETTINGS_FILTER_KEY],
      }),
    [columns, filters]
  );

  return (
    <Stack spacing={4} sx={{ flex: 1 }}>
      <Text
        variant="text3"
        sx={{
          color: colors.gray60,
        }}
        data-testid="total-deal-results"
      >
        Showing {filteredDeals.length} out of {rows.length} deals.
      </Text>
      {columns && (
        <ReportTable
          rows={filteredDeals}
          columns={filteredColumns}
          showLoading={loading || isValidating}
        />
      )}
    </Stack>
  );
};

export default CustomViewContent;

import React from "react";

import {
  FolderOpenIcon,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
} from "icons";

import Stack from "components/Stack";
import Text from "components/Text";

interface ITreeItemLabel {
  text: string;
  iconType?: "folder" | "selected" | "unselected";
}

const getIcon = (iconType: ITreeItemLabel["iconType"]) => {
  switch (iconType) {
    case "selected":
      return <RadioButtonCheckedIcon />;
    case "unselected":
      return <RadioButtonUncheckedIcon />;
    default:
      return <FolderOpenIcon />;
  }
};

const TreeItemLabel: React.FC<ITreeItemLabel> = ({ text, iconType }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    {getIcon(iconType)}
    <Text variant="text3">{text}</Text>
  </Stack>
);

export default TreeItemLabel;

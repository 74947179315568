import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";

import Button from "components/Button";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";

import ChecklistsList from "entities/Deal/components/DealChecklists/ChecklistsList";
import {
  companyPackageAddToDeal,
  useDealCompanyPackageList,
} from "entities/Deal/sdk";
export interface IEditChecklistsForm {
  companyId: number;
  dealId: number;
  onSubmit: ({ packageId }: { packageId: number }) => void;
  onCancel: () => void;
}

const ChecklistsForm = ({
  companyId,
  dealId,
  onSubmit,
  onCancel,
}: IEditChecklistsForm) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(
    null
  );
  const { data: companyPackages = [], isLoading } = useDealCompanyPackageList({
    dealId,
  });

  const packagesOptions = useMemo(
    () =>
      companyPackages?.filter((dealPackage) => !dealPackage.has_access) || [],
    [companyPackages]
  );

  const isSubmitButtonDisabled = useMemo(
    () => _.isNil(selectedPackageId) || isSubmitting,
    [selectedPackageId, isSubmitting]
  );

  const handleSelect = useCallback(
    ({ packageId }: { packageId: number }) => setSelectedPackageId(packageId),
    []
  );

  const handleSubmit = useCallback(() => {
    if (!_.isNil(selectedPackageId)) {
      setIsSubmitting(true);
      companyPackageAddToDeal({
        dealId,
        companyId,
        packageId: selectedPackageId,
      })
        .then(() => onSubmit({ packageId: selectedPackageId }))
        .catch((error) => {
          setIsSubmitting(false);

          const errorMessage =
            error?.message || error?.detail || DEFAULT_ERROR_MESSAGE;

          toast.errorMessage(errorMessage);
        });
    }
  }, [selectedPackageId, companyId, dealId, onSubmit, setIsSubmitting]);

  const handleCancel = useCallback(() => {
    setSelectedPackageId(null);
    onCancel();
  }, [onCancel]);

  return (
    <>
      {isLoading ? (
        <Stack sx={{ margin: theme.spacing(2, 2, 2, 5) }}>
          {_.range(6).map((index) => (
            <Skeleton variant="text" key={index} width={400} height={40} />
          ))}
        </Stack>
      ) : (
        <>
          <ChecklistsList
            packageOptions={packagesOptions}
            selectedPackageId={selectedPackageId}
            onSelect={handleSelect}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{
              padding: theme.spacing(2),
            }}
            data-testid="dialog-action-buttons"
          >
            <Button
              variant="text"
              onClick={handleCancel}
              data-testid="close-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              data-testid="add-to-deal-button"
              disabled={isSubmitButtonDisabled}
            >
              Add to deal
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};

export default ChecklistsForm;

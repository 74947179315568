import React from "react";

import theme from "theme";

import Box from "components/Box";
import SimpleTable from "components/SimpleTable";
import Stack from "components/Stack";
import Text from "components/Text";

import { IMemoTemplateField } from "entities/MemoTemplate/sdk";

interface ITablefield {
  field: IMemoTemplateField;
}

const Tablefield = ({ field }: ITablefield) => {
  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ flexGrow: 0 }}
      >
        <Text
          variant="text2"
          sx={{
            flexBasis: "400px",
            fontWeight: 300,
            paddingRight: theme.spacing(4),
          }}
        >
          {field.name}
        </Text>
      </Stack>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <SimpleTable
          headers={
            field.columns.map((column) => ({
              label: column.trim(),
              key: column.trim(),
            })) || []
          }
          rows={[]}
          size="small"
          showEmptyRow={true}
        />
      </Box>
    </Box>
  );
};

export default Tablefield;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { ArrowDropDownIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import ButtonDropdown from "components/ButtonDropdown";
import Image from "components/Image";
import { MenuItem } from "components/Menu";
import NavLink from "components/NavLink";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import CreateDialog from "pages/Application/CreateDialog";

import { useDraftApplicationsList } from "entities/Application/sdk";
import { useSharedChecklist } from "entities/Checklist/sdk";

const ApplicationDirectLink = () => {
  const [showApplicationCreateDialog, setShowApplicationCreateDialog] =
    useState(false);

  const navigate = useNavigate();
  const params = useParams<{ token: string }>();
  const token = String(params.token);

  const { data: sharedChecklist, error } = useSharedChecklist({ token });
  const { data: applications } = useDraftApplicationsList({
    checklistToken: token,
  });

  useEffect(() => {
    if (!_.isNil(error) && error?.detail === "Not found.") {
      navigate(URLS.NOT_FOUND, { replace: true });
    }
  }, [error, navigate]);

  usePageTitle(
    sharedChecklist
      ? `Builders Patch: Shared Checklist - ${sharedChecklist.name}`
      : "Builders Patch: Shared Checklist"
  );

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Box
          sx={{
            maxWidth: BOX_MAX_WIDTH,
            alignSelf: "center",
            width: "100%",
          }}
        >
          {sharedChecklist && (
            <Stack spacing={3}>
              <Paper
                sx={{
                  backgroundColor: colors.blue10,
                  padding: theme.spacing(2),
                }}
              >
                <Stack
                  direction="row"
                  marginBottom={1}
                  justifyContent="space-between"
                >
                  <Box>
                    <Text variant="h2">{sharedChecklist.name}</Text>
                  </Box>

                  <Stack sx={{ height: "50px" }}>
                    {sharedChecklist.company_package.company.logo && (
                      <Image
                        sx={{
                          height: "50px",
                          maxWidth: "200px",
                          marginBottom: "2px",
                        }}
                        src={sharedChecklist.company_package.company.logo}
                      />
                    )}
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    padding: theme.spacing(0.5),
                    borderBottom: `1px solid ${colors.blue20}`,
                  }}
                >
                  <Text variant="text3">Issuer</Text>
                  <Text variant="text3" fontWeight={700}>
                    {sharedChecklist.company_package.company.name}
                  </Text>
                </Stack>
              </Paper>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  {applications && applications.length > 0 && (
                    <Text>
                      You already have {applications.length} draft{" "}
                      {applications.length > 1 ? "applications" : "application"}{" "}
                      for this form.
                    </Text>
                  )}
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignSelf: "flex-end" }}
                >
                  {applications && applications.length > 0 && (
                    <ButtonDropdown
                      label={`${applications.length} ${
                        applications.length > 1 ? "drafts" : "draft"
                      }`}
                      variant="outlined"
                      color="secondary"
                      icon={<ArrowDropDownIcon />}
                    >
                      {applications.map(({ id, name }) => (
                        <NavLink
                          key={id}
                          to={reverse(URLS.APPLICATION_EDIT, {
                            applicationId: id,
                          })}
                        >
                          <MenuItem>{name}</MenuItem>
                        </NavLink>
                      ))}
                    </ButtonDropdown>
                  )}
                  <Button onClick={() => setShowApplicationCreateDialog(true)}>
                    Start new intake
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
      <CreateDialog
        token={token}
        open={showApplicationCreateDialog}
        close={() => setShowApplicationCreateDialog(false)}
      />
    </Paper>
  );
};

export default ApplicationDirectLink;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { CheckIcon, EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";

import FieldDropdownMenu from "entities/Field/components/FieldDropdownMenu";
import FormTextInput from "entities/Field/components/FormTextField/FormText";
import FieldTitle from "entities/Field/components/Title";
import { IField } from "entities/Field/sdk";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

interface IFormTextField {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  dealId: number;
  packageId: number;
  categoryId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
}

const FormTextField = ({
  editable,
  field,
  taskChannels,
  updatePackage,
  dealId,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
}: IFormTextField) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleEditButtonClick = useCallback(
    () => setIsEdit((prev) => !prev),
    []
  );

  const hasTasksAndNotEditing = useMemo(
    () => taskChannels.length > 0 && !isEdit,
    [taskChannels, isEdit]
  );

  const showEditButton = useMemo(
    () => !field.n_a_status && editable,
    [field, editable]
  );

  const showFormTextInput = useMemo(
    () => !field.n_a_status && field.template_meta && field.template,
    [field]
  );

  if (_.isUndefined(field.template)) {
    return null;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
        data-testid="dd-field"
      >
        <FieldTitle
          field={field}
          dealId={dealId}
          packageId={packageId}
          categoryId={categoryId}
          tabId={tabId}
          sectionId={sectionId}
          onUpdate={onUpdate}
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ flexGrow: 0 }}
        >
          {hasTasksAndNotEditing && (
            <DueDiligenceFieldOpenTaskButton
              taskChannels={taskChannels}
              company_field={field}
              dealId={dealId}
              tabId={tabId}
              sectionId={sectionId}
              categoryId={categoryId}
              packageId={packageId}
              updatePackage={updatePackage}
              taskButtonDataTestid="dd-open-task-button"
              taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
            />
          )}
          {field.n_a_status && (
            <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
              N/A
            </Text>
          )}
          <Stack direction="row" spacing={1}>
            {showEditButton && (
              <IconButton
                size="small"
                onClick={handleEditButtonClick}
                dataTestid="field-edit-button"
              >
                {isEdit ? <CheckIcon /> : <EditIcon />}
              </IconButton>
            )}
            <FieldDropdownMenu
              field={field}
              dealId={dealId}
              packageId={packageId}
              categoryId={categoryId}
              tabId={tabId}
              sectionId={sectionId}
              onUpdate={onUpdate}
              showHistoryButton={false}
              menuButtonDataTestid="dd-field-actions-button"
            />
          </Stack>
        </Stack>
      </Stack>
      {showFormTextInput && (
        <Box
          sx={{ paddingLeft: theme.spacing(6) }}
          data-testid="dd-ftf-field-value"
        >
          <FormTextInput
            templateMeta={field.template_meta}
            template={field.template}
            edit={isEdit}
            dealId={dealId}
            onUpdate={updatePackage}
            fieldNameDataTestid={field.display_name}
          />
        </Box>
      )}
    </>
  );
};

export default FormTextField;

import React from "react";

import theme from "theme";

import Box from "components/Box";
import Button, { IButton } from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import Loading from "components/Loading";

interface IConfirmationDialog {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  message: string | React.ReactNode;
  loading?: boolean;
  confirmButtonColor?: IButton["color"];
  confirmButtonText?: string;
}

const ConfirmationDialog: React.FC<IConfirmationDialog> = ({
  open,
  onCancel,
  onConfirm,
  message,
  loading = false,
  confirmButtonColor = "primary",
  confirmButtonText = "OK",
}) => (
  <Dialog open={open} maxWidth="sm" onClose={onCancel}>
    <Loading open={loading} />
    <Box sx={{ padding: theme.spacing(1) }} data-testid="confirm-action-dialog">
      <DialogTitle data-testid="confirm-dialog-message">{message}</DialogTitle>
      <DialogActions>
        <Button
          variant="text"
          onClick={onCancel}
          data-testid="cancel-action-button"
        >
          Cancel
        </Button>
        <Button
          color={confirmButtonColor}
          onClick={onConfirm}
          data-testid="confirm-action-button"
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Box>
  </Dialog>
);

export default ConfirmationDialog;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { ArrowForwardIosIcon } from "icons";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Alert from "components/Alert";
import Button from "components/Button";
import IconButton from "components/IconButton";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";
import { usePageTitle } from "components/usePageTitle";

import AssetManagementTabs from "entities/AssetManagement/components/AssetManagementTabs";
import AssetManagementTable from "entities/AssetManagement/components/Table";
import { extractCellKey } from "entities/AssetManagement/components/Table/utils";
import {
  AMColumn,
  AMResolution,
  AssetManagementTabItems,
} from "entities/AssetManagement/constants";
import {
  updateAssetManagement,
  useAssetManagementData,
  usePdf,
  usePDFDiff,
} from "entities/AssetManagement/sdk";
import {
  applyDiffToNewApiType,
  constructUpdatedDict,
} from "entities/AssetManagement/utils";
import PDFEmbed from "entities/File/components/PDFEmbed";

// The whole page without the headers, the footer and padding of 3 spaces on top and bottom.
export const fixedHeight = `calc(100vh - 194px)`;

const AssetManagementPdfDiff = () => {
  const navigate = useNavigate();
  const params = useParams<{
    dealId: string;
    pdfId: string;
  }>();
  const dealId = Number(params.dealId);
  const selectedPdf = Number(params.pdfId);
  usePageTitle(
    "Builders Patch: Operating data extraction with Asset Management Proforma"
  );

  const [showPDFSidebar, setShowPDFSidebar] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    AssetManagementTabItems.INCOME
  );
  const [changedCells, setChangedCells] = useState<{
    [key: string]: {
      current_value: number | null;
      previous_value: number | null;
    };
  }>({});
  const [deletedRowKeys, setDeletedRowKeys] = useState<Array<string>>([]);
  const [yearMappings, setYearMappings] = useState<{ [key: string]: string }>(
    {}
  );

  const updateYearMapping = ({
    oldYear,
    newYear,
  }: {
    oldYear: string | number;
    newYear: string;
  }) => {
    setYearMappings((prev) => ({
      ...prev,
      [oldYear]: newYear,
    }));
  };

  const changedCellsWithMappedYears = useMemo(() => {
    const newChangedCells: {
      [key: string]: {
        previous_value: number | null;
        current_value: number | null;
      };
    } = {};
    Object.keys(changedCells).forEach((key) => {
      const value = changedCells[key];

      const { itemCode, subCategory, year, quarter, dataType } = extractCellKey(
        { itemKey: key }
      );

      const newYear = yearMappings[year as string] || year;
      const newKey = `${itemCode}|${subCategory}|${newYear}|${quarter}|${dataType}`;

      newChangedCells[newKey] = value;
    });

    return newChangedCells;
  }, [changedCells, yearMappings]);

  const { data: pdf } = usePdf({ pdfId: selectedPdf });

  const { data: diff, error: diffError } = usePDFDiff({
    dealId,
    pdfId: selectedPdf,
  });

  const { data, mutate: updateAssetManagementData } = useAssetManagementData({
    dealId,
  });

  const diffData = useMemo(
    () =>
      !_.isUndefined(data) &&
      !_.isUndefined(diff) &&
      applyDiffToNewApiType({
        data,
        diff,
      }),
    [data, diff]
  );

  useEffect(() => {
    if (!_.isUndefined(diff) && !_.isUndefined(diff.cells_diff)) {
      setChangedCells(diff.cells_diff);
    }
  }, [diff]);

  const handleCellChange = useCallback(
    ({
      itemKey,
      value,
      prevValue,
    }: {
      itemKey: string;
      value: number | null;
      prevValue: string | null;
    }) => {
      const invertedYearMappings = _.invert(yearMappings);

      const { itemCode, subCategory, year, quarter, dataType } = extractCellKey(
        { itemKey }
      );

      const newKey = `${itemCode}|${subCategory}|${_.get(
        invertedYearMappings,
        year,
        year
      )}|${quarter}|${dataType}`;

      setChangedCells((prev) => ({
        ...prev,
        [newKey]: {
          current_value: value,
          previous_value: prevValue ? parseFloat(prevValue || "") : null,
        },
      }));
    },
    [setChangedCells, yearMappings]
  );

  const deleteRow = ({ rowKey }: { rowKey: string }) => {
    setDeletedRowKeys((prev) => [...prev, rowKey]);
  };

  const handleApplyClick = useCallback(() => {
    if (_.isUndefined(data)) {
      return;
    }

    const calculatedDiff = constructUpdatedDict({
      tableData: data,
      changedCells,
      diffRows: diff?.rows_diff,
      yearMappings,
      deletedRowKeys,
    });

    updateAssetManagement({
      dealId,
      diff: calculatedDiff,
    })
      .then(() => {
        setChangedCells({});
        updateAssetManagementData();
        toast.successMessage("Data imported successfully!");
        navigate(reverse(URLS.DEAL_ASSET_MANAGEMENT, { dealId }));
      })
      .catch((errors) => {
        const errorMessage = errors?.message || errors?.detail;

        if (!_.isNil(errorMessage)) {
          toast.errorMessage(errorMessage);
        } else {
          errors?.forEach?.((error: unknown) => {
            if (_.isString(error)) {
              toast.errorMessage(error);
            }
          });
        }
      });
  }, [
    dealId,
    diff,
    data,
    changedCells,
    deletedRowKeys,
    yearMappings,
    updateAssetManagementData,
    navigate,
  ]);

  return (
    <Stack direction="row" spacing={2} paddingLeft={4}>
      <div
        style={{
          position: "relative",
          zIndex: 2,
          flexBasis: showPDFSidebar ? "50%" : "0%",
          height: fixedHeight,
          transition: "flex-basis 0.5s ease",
        }}
        data-testid="pdf-file-preview-section"
      >
        <PDFEmbed pdfFileUrl={pdf?.file.url} />
        <Tooltip title={`${showPDFSidebar ? "Hide" : "Show"} PDF preview`}>
          <IconButton
            sx={{
              position: "absolute",
              zIndex: 2,
              right: "-10px",
              top: "45%",
              textAlign: "center",
              opacity: "100%",
              backgroundColor: colors.blue10,
            }}
            onClick={() => setShowPDFSidebar((prevValue) => !prevValue)}
            dataTestid="hide-show-pdf-button"
          >
            <ArrowForwardIosIcon
              sx={{
                transform: `rotate(${showPDFSidebar ? "180deg" : "0deg"})`,
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <Stack
        flexBasis={showPDFSidebar ? "50%" : "100%"}
        spacing={2}
        paddingY={3}
        paddingRight={4}
        sx={{
          height: fixedHeight,
          overflowX: "auto",
          transition: "flex-basis 0.3s linear",
        }}
        data-testid="am-table-preview-section"
      >
        {diffError ? (
          <Alert severity="error" data-testid="error-banner-alert">
            {diffError.message}
          </Alert>
        ) : (
          <>
            <PageHeader
              backLink={reverse(URLS.DEAL_ASSET_MANAGEMENT_PDFS, { dealId })}
              backTitle="Back to PDF list"
              title="Operating data extraction with Asset Management Proforma"
              actions={
                <Button onClick={handleApplyClick} data-testid="apply-button">
                  Apply
                </Button>
              }
            />
            <Stack spacing={1}>
              <Alert severity="warning" data-testid="warning-banner-alert">
                Make sure to review the incoming data before approving it.
              </Alert>
              <AssetManagementTabs
                selectedTab={selectedTab}
                onChange={(tab) => setSelectedTab(tab)}
              />
              {diffData && (
                <AssetManagementTable
                  data={diffData}
                  selectedTab={selectedTab}
                  editMode={true}
                  editHeaders={true}
                  resolution={AMResolution.YEARLY}
                  showSubItems={true}
                  columns={[
                    AMColumn.BUDGETED,
                    AMColumn.ACTUAL,
                    AMColumn.AUDITED,
                  ]}
                  onChange={handleCellChange}
                  changedCells={changedCellsWithMappedYears}
                  deletedRowKeys={deletedRowKeys}
                  deleteRow={deleteRow}
                  yearMappings={yearMappings}
                  updateYearMapping={updateYearMapping}
                />
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AssetManagementPdfDiff;

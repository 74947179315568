import React from "react";

import Box from "components/Box";

export interface IPrintableLayout {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
}

const FOOTER_HEIGHT = "67px";

const PrintableLayout = ({ header, footer, children }: IPrintableLayout) => {
  return (
    <table
      style={
        {
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "fixed",
          WebkitPrintColorAdjust: "exact",
          printColorAdjust: "exact",
        } as any
      }
    >
      <thead>
        <tr>
          <td>{header}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
      <tfoot style={{ height: FOOTER_HEIGHT }}>
        <tr>
          <td>
            <Box
              sx={{
                visibility: "hidden",
                "@media screen": { display: "none" },
              }}
            >
              {footer}
            </Box>
            <Box
              sx={{
                position: "fixed",
                bottom: "0px",
                left: "0",
                right: "0",
                "@media screen": { display: "none" },
              }}
            >
              {footer}
            </Box>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default PrintableLayout;

import React from "react";
import { useCallback } from "react";

import theme from "theme";
import { colors } from "theme/palette";
import { scrollToSelectedAnchor } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import { ID as SUB_NAVIGATION_ID } from "components/DealPageLayout/SubNavigation";
import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";

const ANCHORS = [MAIN_NAVIGATION_ID, SUB_NAVIGATION_ID];
export interface IInPageLink {
  anchor: string;
  selected: boolean;
  dataTestid?: string;
  children?: React.ReactNode;
}

const InPageLink = ({
  selected,
  anchor,
  children,
  dataTestid,
}: IInPageLink) => {
  const handleClick = useCallback(
    () => scrollToSelectedAnchor({ anchor, navigationIds: ANCHORS }),
    [anchor]
  );

  return (
    <Box sx={{ cursor: "pointer" }} onClick={handleClick}>
      <Button
        variant={selected ? "contained" : "text"}
        color="secondary"
        sx={{
          display: "block",
          width: "100%",
          textAlign: "left",
          paddingLeft: theme.spacing(1.5),
          borderLeft: selected ? `6px solid ${colors.blue100}` : "0",
          transition: "border-left 0.2s ease",
        }}
        data-testid={dataTestid}
      >
        {children}
      </Button>
    </Box>
  );
};

export default InPageLink;

import {
  IDownhomePersonParticipant,
  ISimpleDownhomePersonParticipant,
} from "entities/CompanyItegrations/sdk";

export interface IDownhomePersonParticipantOption
  extends Omit<ISimpleDownhomePersonParticipant, "external_identifier"> {
  label: string;
  _groupKey: EDownhomePersonParticipantOptionGroup;
  context?: IDownhomeOwnerProperties;
  external_identifier?: string;
  _key?: string;
}

export enum EDownhomePersonParticipantOptionGroup {
  EXISTING = "DownHome data",
  IMPORT_FROM_CHECKLIST = "Not in Downhome? Get applicant data from Checklist.",
  ADD_NEW = "Not in Downhome and Checklists? Create a new one.",
}

export interface IDownhomeOwnerProperties
  extends Omit<IDownhomePersonParticipant, "external_identifier"> {
  key: string;
}

import { useEffect, useState } from "react";
import _ from "lodash";

export const useFirstVisibleAnchor = (anchors: string[]) => {
  const [selected, setSelected] = useState<string>(anchors[0]);

  useEffect(() => {
    const scrollEventListener = () => {
      // For all anchors find the ".top" value
      const anchorsTops = _.sortBy(
        anchors.map((anchor) => {
          const el = window.document.getElementById(anchor);
          const value = el ? el.getBoundingClientRect().top : -1;
          return { anchor, value };
        }),
        "value"
      );

      // Get the anchors that are entierly visible
      const anchorsEntirelyVisible = anchorsTops.filter(
        ({ value }) => value >= 0
      );

      // Get either the first entierly visible anchor
      // Or the last partially-visible anchor since you are on the bottom of the page.
      // This works because we've sorted the array first.
      const newSelected =
        anchorsEntirelyVisible[0]?.anchor ||
        anchorsTops[anchorsTops.length - 1]?.anchor;

      setSelected(newSelected);
    };
    window.addEventListener("scroll", scrollEventListener);

    return () => {
      window.removeEventListener("scroll", scrollEventListener);
    };
  }, [anchors]);

  return selected;
};

import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import Autocomplete, {
  AutocompletePaperComponent,
} from "components/Autocomplete";
import TextField from "components/TextField";

import { ITableList, useAllTables } from "entities/TableStorage/sdk";

export interface ITablesAutocomplete {
  currentSelectedTables: ITableList[];
  setSelectedTables: ({ tables }: { tables: ITableList[] }) => void;
  fieldLabel?: string;
}

const TablesAutocomplete = ({
  currentSelectedTables,
  setSelectedTables,
  fieldLabel = "Search tables by name to compare",
}: ITablesAutocomplete) => {
  const [value, setValue] = useState<ITableList | null>(null);
  const [tableName, setTableName] = useState("");
  const [filteredTables, setFilteredTables] = useState<Array<ITableList>>([]);

  const { data: tables } = useAllTables({ name: tableName });

  useEffect(() => {
    if (!_.isUndefined(tables)) {
      setFilteredTables(tables);
    }
  }, [tables]);

  const handleInputChange = useCallback(
    (event, newInputValue) => {
      setFilteredTables((prev) =>
        prev.filter((table) => table.name.includes(newInputValue))
      );
      setTableName(newInputValue);
    },
    [setTableName]
  );

  const handleSelectTable = useCallback(
    (event, value: ITableList | null) => {
      if (_.isNil(value)) {
        setSelectedTables({ tables: currentSelectedTables });
      } else {
        const newSelectedTables = _.uniqBy(
          [...currentSelectedTables, value],
          "id"
        );

        setSelectedTables({ tables: newSelectedTables });
      }
      setValue(null);
    },
    [currentSelectedTables, setSelectedTables]
  );

  return (
    <Autocomplete
      fullWidth
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      PaperComponent={AutocompletePaperComponent}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      renderInput={(params) => <TextField {...params} label={fieldLabel} />}
      value={value}
      onInputChange={_.debounce(handleInputChange, 200)}
      onChange={handleSelectTable}
      renderOption={(props, option: ITableList) => (
        <li {...props} key={option.id} style={{ display: "block" }}>
          <div>{option.name}</div>
          {!_.isNil(option.deal) && (
            <div style={{ fontStyle: "italic" }}>Deal: {option.deal?.name}</div>
          )}
        </li>
      )}
      options={filteredTables}
    />
  );
};

export default TablesAutocomplete;

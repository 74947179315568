import { FiltersContextProvider } from "components/useFilters";
import { usePageTitle } from "components/usePageTitle";

import Content from "pages/Reporting/ProjectsViewContent";

import { useIncomeAndOpexCompanyDeals } from "entities/Reporting/sdk";

const IncomeAndOpexReporting = () => {
  usePageTitle("Builders Patch: Reports - Income and OPEX report");
  const {
    columns = [],
    rows = [],
    loading,
    isValidating,
  } = useIncomeAndOpexCompanyDeals();
  return (
    <FiltersContextProvider>
      <Content
        rows={rows}
        columns={columns}
        loading={loading}
        isValidating={isValidating}
        reportTitle="Income and OPEX report"
      />
    </FiltersContextProvider>
  );
};

export default IncomeAndOpexReporting;

import Cell from "components/SimpleTable/Cell";
import { TableRow } from "components/Table";

interface IEmptyRow {
  colStan: number;
}

const EmptyRow = ({ colStan }: IEmptyRow) => {
  return (
    <TableRow key="empty=row">
      <Cell
        cellKey="empty-cell"
        value="..."
        colSpan={colStan}
        textAlign="center"
      />
    </TableRow>
  );
};

export default EmptyRow;

import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";
import { GridViewSharpIcon, RoomOutlinedIcon, ViewListIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import ButtonGroup from "components/ButtonGroup";
import PageHeader from "components/PageHeader";
import Select from "components/Select";
import Stack from "components/Stack";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import AddDealButton from "entities/Deal/components/AddDealButton";
import ExportButton from "entities/Deal/components/ExportButton";
import { Phase, ProjectsViewType } from "entities/Deal/constants";
import { IPipelineColumns } from "entities/Deal/sdk";
import FiltersButton from "entities/Reporting/components/FiltersButton";
import ReportSearchField from "entities/Reporting/components/ReportSearchField";
import SelectedFilters from "entities/Reporting/components/SelectedFilters";

const PHASES = [
  "All",
  Phase.APPLICATION,
  Phase.UNDERWRITING,
  Phase.CONSTRUCTION,
  Phase.CONVERSION,
  Phase.ASSET_MANAGEMENT,
];

export interface IProjectsPageHeader {
  rows: { [key: string]: string | null }[];
  columns: Array<IPipelineColumns>;
  phase: string;
  selectedView: ProjectsViewType;
  loading: boolean;
  onChangeView: (view: ProjectsViewType) => void;
  onCreateDeal: () => void;
}

const ProjectsPageHeader = ({
  rows,
  columns,
  phase,
  selectedView,
  loading,
  onChangeView,
  onCreateDeal,
}: IProjectsPageHeader) => {
  const navigate = useNavigate();

  const phaseOptions = useMemo(
    () => PHASES.map((phase) => ({ value: phase, label: phase })),
    []
  );

  const handleViewChange = useCallback(
    (viewType: ProjectsViewType) => () => {
      onChangeView(viewType);
      navigate(reverse(URLS.PROJECTS_LIST_VIEW, { view: viewType, phase }));
    },
    [navigate, phase, onChangeView]
  );

  const handlePhaseSwitch = useCallback(
    (event: any) => {
      navigate(
        URLS.PROJECTS_LIST_VIEW.replace(":phase", event.target.value).replace(
          ":view",
          selectedView
        )
      );
    },
    [navigate, selectedView]
  );

  const handleCreatedDeal = useCallback(() => {
    // TODO: update list data. It's updated by reporting and background tasks.
    toast.successMessage("Deal created successfully!");
    onCreateDeal();
  }, [onCreateDeal]);

  return (
    <>
      <PageHeader
        title="Deals dashboard"
        dataTestid="deals-dashboard-section"
        actions={
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            sx={{ flex: 1 }}
          >
            <Stack spacing={2} direction="row">
              <Select
                size="small"
                sx={{ width: "154px" }}
                label="PHASE"
                options={phaseOptions}
                inputProps={{
                  "data-testid": "dashboard-deal-phase-dropdown-input",
                  sx: {
                    backgroundColor: "white",
                  },
                }}
                data-testid="dashboard-deal-phase-dropdown"
                dropdownOptionsDataTestid="dashboard-deal-phase-dropdown-option"
                onChange={handlePhaseSwitch}
                value={phase}
              />
              <ButtonGroup
                color="secondary"
                data-testid="dashboard-view-buttons-group"
                disableRipple
              >
                <Tooltip title="Grid view">
                  <Button
                    variant={selectedView === "grid" ? "contained" : "outlined"}
                    sx={{
                      padding: theme.spacing(1),
                      border: `1px solid ${colors.blue60}`,
                    }}
                    onClick={handleViewChange(ProjectsViewType.grid)}
                    data-testid="dashboard-grid-view-button"
                  >
                    <GridViewSharpIcon
                      sx={{
                        width: 20,
                        height: 20,
                        opacity: selectedView === "grid" ? 1 : 0.5,
                      }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="List view">
                  <Button
                    variant={selectedView === "list" ? "contained" : "outlined"}
                    sx={{
                      padding: theme.spacing(1),
                      border: `1px solid ${colors.blue60}`,
                    }}
                    onClick={handleViewChange(ProjectsViewType.list)}
                    data-testid="dashboard-list-view-button"
                  >
                    <ViewListIcon
                      sx={{
                        width: 20,
                        height: 20,
                        opacity: selectedView === "list" ? 1 : 0.5,
                      }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Map view">
                  <Button
                    variant={selectedView === "map" ? "contained" : "outlined"}
                    sx={{
                      padding: theme.spacing(1),
                      border: `1px solid ${colors.blue60}`,
                    }}
                    onClick={handleViewChange(ProjectsViewType.map)}
                    data-testid="dashboard-map-view-button"
                  >
                    <RoomOutlinedIcon
                      sx={{
                        width: 20,
                        height: 20,
                        opacity: selectedView === "map" ? 1 : 0.5,
                      }}
                    />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              sx={{ flex: 1 }}
              justifyContent="flex-end"
            >
              <Box sx={{ maxWidth: 416, flex: 1 }}>
                <ReportSearchField placeholder="Search by deal name, city, or state" />
              </Box>
              <FiltersButton
                reportPackageId={null}
                disabled={loading}
                rows={rows}
                filtersButtonDataTestid="filter-deals-button"
              />
              <ExportButton rows={rows} columns={columns} disabled={loading} />
              <AddDealButton onCreated={handleCreatedDeal} />
            </Stack>
          </Stack>
        }
      />
      {!loading && (
        <SelectedFilters
          columns={columns}
          reportPackageId={null}
          isHardcodedReport={false}
        />
      )}
    </>
  );
};

export default memo(ProjectsPageHeader, (prevProps, nextProps) => {
  return (
    prevProps.rows === nextProps.rows &&
    prevProps.columns === nextProps.columns &&
    prevProps.phase === nextProps.phase &&
    prevProps.selectedView === nextProps.selectedView &&
    prevProps.loading === nextProps.loading
  );
});

import _ from "lodash";

import { BASE_URL } from "config/urls";
import { get, post, useFetch, usePaginatedFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { IFilters } from "components/useFilters";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { ECompanySettingsFeatureVisibleType } from "entities/Company/components/CompanySettings/FeaturesList/constants";
import { ICompanySettings } from "entities/Company/sdk";
import { Phase } from "entities/Deal/constants";
import {
  CompanyCustomFieldSyncingType,
  CompanyFieldFormatType,
} from "entities/Field/constants";
import { FieldType } from "entities/Field/sdk";
import { IFile } from "entities/File/sdk";
import { SEARCH_STRING_FILTER_KEY } from "entities/Reporting/constants";

export interface ISuperuserDashboardDeal {
  company_name: string;
  deal_created: string;
  dev_budget: string | null;
  id: number;
  name: string;
  phase_name: Phase;
  unit_count: number;
}

export const useSuperuserDashboardData = () =>
  useFetch<Array<ISuperuserDashboardDeal>>(
    "v2/analytics/superuser-dashboard/non-demo-deals/list/"
  );

export const useSuperuserDashboardDemoDeals = () =>
  useFetch<Array<ISuperuserDashboardDeal>>(
    "v2/analytics/superuser-dashboard/demo-deals/list/"
  );

export const useSuperuserDashboardTotalUnitsCount = () =>
  useFetch<number>("v2/analytics/superuser-dashboard/total-units-count/");

export interface ISuperuserDashboardDealsCreatedByMonth {
  month: string;
  deal_count: number;
}

export const useSuperuserDashboardDealsCreatedByMonth = () =>
  useFetch<Array<ISuperuserDashboardDealsCreatedByMonth>>(
    "v2/analytics/superuser-dashboard/deals-created-by-month/list/"
  );

export interface ISuperuserCompanyDeal {
  company_name: string;
  application_deal_count: number;
  underwriting_deal_count: number;
  construction_deal_count: number;
  conversion_deal_count: number;
  asset_management_deal_count: number;
  all_deal_count: number;
}

export const useSuperuserCompanyDeals = () =>
  useFetch<Array<ISuperuserCompanyDeal>>(
    "v2/analytics/superuser-dashboard/companies-deals/"
  );

export interface IFieldGroup {
  fieldId: number;
  fieldFormatType: CompanyFieldFormatType;
}

export interface ISuperuserFieldValuesData {
  field_id: number;
  field_type: FieldType;
  data: Array<{
    field_format_type: CompanyFieldFormatType;
    intake_name: string | null;
    deal_name: string | null;
    company_package_name: string;
    tab_name: string;
    category_name: string;
    section_name: string;
    field_name: string;
    old_value: any;
    new_value: any;
    can_be_migrated: boolean;
    field_id: number;
  }>;
  linked_field_ids: Array<number>;
}

export const useCompanyCustomFieldValuesForField = ({
  fieldId,
  fieldFormatType,
}: {
  fieldId: number;
  fieldFormatType: CompanyFieldFormatType;
}) =>
  useFetch<ISuperuserFieldValuesData>(
    `v2/deal/superuser/company-custom-field/${fieldId}/values/list/${formatGetParams(
      {
        field_format_type: fieldFormatType,
      }
    )}`
  );

export const companyCustomFieldValuesApplyFieldFormatType = ({
  fieldId,
  fieldFormatType,
}: {
  fieldId: number;
  fieldFormatType: CompanyFieldFormatType;
}) =>
  post(
    `${BASE_URL}v2/deal/superuser/company-custom-field/${fieldId}/field-format-type/update/`,
    { field_format_type: fieldFormatType }
  );

export interface ISuperuserCompany {
  id: number;
  name: string;
  settings: ICompanySettings;
  deal_count: number;
  company_user_count: number;
}

export const useSuperuserCompanyList = ({
  filters,
  limit = 50,
}: {
  filters: IFilters;
  limit?: number;
}) => {
  const formattedFilters = {
    name: filters[SEARCH_STRING_FILTER_KEY] || "",
  };

  return usePaginatedFetch<ISuperuserCompany>({
    url: "v2/company/superuser/company-list/",
    params: { limit, ...formattedFilters },
  });
};

export const useSuperuserCompanyRetrieve = ({
  companyId,
}: {
  companyId: number;
}) =>
  useFetch<ISuperuserCompany>(
    `v2/company/superuser/company/${companyId}/retrieve/`
  );

export const companySettingsUpdate = ({
  companyId,
  action,
  feature,
}: {
  companyId: number;
  action: ECompanySettingsFeatureVisibleType;
  feature: ECompanySettingsFeatureType;
}) => {
  return post(
    `${BASE_URL}v2/company/superuser/company/${companyId}/company-settings/update/`,
    { action, feature }
  );
};

export const companySettingsLimitUpdate = ({
  companyId,
  feature,
  limit,
}: {
  companyId: number;
  feature:
    | ECompanySettingsFeatureType.MAX_DEAL_COUNT
    | ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT
    | ECompanySettingsFeatureType.MAX_CHECKLIST_COUNT
    | ECompanySettingsFeatureType.MAX_INTAKE_CHECKLIST_COUNT;
  limit: number;
}) => {
  const data = { [feature]: limit };
  return post(
    `${BASE_URL}v2/company/superuser/company/${companyId}/company-settings-limit/update/`,
    data
  );
};

export interface ISuperuserPackage {
  id: number;
  name: string;
}

export interface ISuperuserTab {
  id: number;
  name: string;
}

export interface ISuperuserCategory {
  id: number;
  name: string;
}

export interface ISuperuserSection {
  id: number;
  name: string;
}

export interface ISuperuserField {
  id: number;
  name: string;
  is_linked: boolean;
}

export const useSuperuserPackageList = () =>
  useFetch<Array<ISuperuserPackage>>(
    `v2/due-diligence/superuser/package/list/`
  );

export const useSuperuserTabList = ({
  packageId,
}: {
  packageId: number | null;
}) =>
  useFetch<Array<ISuperuserTab>>(
    !_.isNull(packageId)
      ? `v2/due-diligence/superuser/package/${packageId}/tab/list/`
      : undefined
  );

export const useSuperuserCategoryList = ({
  packageId,
  tabId,
}: {
  packageId: number | null;
  tabId: number | null;
}) =>
  useFetch<Array<ISuperuserCategory>>(
    !_.isNull(tabId) && !_.isNull(packageId)
      ? `v2/due-diligence/superuser/package/${packageId}/tab/${tabId}/category/list/`
      : undefined
  );

export const useSuperuserSectionList = ({
  packageId,
  categoryId,
}: {
  packageId: number | null;
  categoryId: number | null;
}) =>
  useFetch<Array<ISuperuserSection>>(
    !_.isNull(categoryId) && !_.isNull(packageId)
      ? `v2/due-diligence/superuser/package/${packageId}/category/${categoryId}/section/list/`
      : undefined
  );

export const useSuperuserFieldList = ({
  packageId,
  sectionId,
}: {
  packageId: number | null;
  sectionId: number | null;
}) =>
  useFetch<Array<ISuperuserField>>(
    !_.isNull(sectionId) && !_.isNull(packageId)
      ? `v2/due-diligence/superuser/package/${packageId}/section/${sectionId}/field/list/`
      : undefined
  );

export interface IDealLinkedPackage {
  deal_id: number;
  deal_name: string;
  packages: Array<{
    package_id: number;
    name: string;
    tabs: Array<{
      tab_id: number;
      name: string;
      categories: Array<{
        category_id: number;
        name: string;
        sections: Array<{
          section_id: number;
          name: string;
          fields: Array<{
            field_id: number;
            field_type: FieldType;
            field_format_type: CompanyFieldFormatType;
            name: string;
            header_name: Array<string>;
            values: Array<{
              value_id: number;
              default_value: string | null;
              selected_value: string | null;
              field_format_type: CompanyFieldFormatType;
              column_value: Array<{ [key: string]: string }> | null;
              rich_value: string | null;
              file: IFile | null;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
}
export const getDealsAndLinkedFields = ({
  fieldId,
  linkId,
}: {
  fieldId: number;
  linkId: string;
}) =>
  get<Array<IDealLinkedPackage>>(
    `${BASE_URL}v2/due-diligence/superuser/field/${fieldId}/deals-and-linked-fields-retrieve/${formatGetParams({ link_id: linkId })}`
  );

export interface IDealPackageSyncingType {
  deal_id: number;
  syncing_type:
    | CompanyCustomFieldSyncingType.ERASE_ALL_VALUES
    | CompanyCustomFieldSyncingType.SYNC_CURRENT_VALUE_TO_REST
    | CompanyCustomFieldSyncingType.SYNC_REST_VALUES_TO_CURRENT_VALUE;
}

export const addLinkIdsToField = ({
  fieldId,
  linkId,
  dealPackages,
}: {
  fieldId: number;
  linkId: string;
  dealPackages: Array<IDealPackageSyncingType>;
}) =>
  post(`${BASE_URL}v2/due-diligence/superuser/field/${fieldId}/add-link-id/`, {
    link_id: linkId,
    deal_packages: dealPackages,
  });

export const useSuperuserLinkIds = () =>
  useFetch<Array<string>>(`v2/due-diligence/superuser/link-id/list/`);

import { useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";
import toast from "components/Toast";

import { useSignedUser } from "entities/Auth/sdk";
import { Persona } from "entities/Company/sdk";
import { addNewTeam } from "entities/Team/sdk";

const VALIDATION_SCHEMA = yup.object({
  persona: yup.string().required("Company persona is required!"),
  email: yup.string().email().required("Email is required!"),
});

const initialValues = {
  persona: "",
  email: "",
};

const companyPersona = [
  { label: Persona.DEVELOPER, value: Persona.DEVELOPER },
  { label: Persona.LENDER, value: Persona.LENDER },
  { label: Persona.ARCHITECT, value: Persona.ARCHITECT },
  { label: Persona.CONTRACTOR, value: Persona.CONTRACTOR },
  { label: Persona.ASSET_MANAGER, value: Persona.ASSET_MANAGER },
  { label: Persona.GENERAL_CONTRACTOR, value: Persona.GENERAL_CONTRACTOR },
  { label: Persona.PROPERTY_MANAGER, value: Persona.PROPERTY_MANAGER },
  { label: Persona.LAWYER, value: Persona.LAWYER },
];

interface IAddNewTeamForm {
  dealId: number;
  onSubmit: () => void;
  onCancel: () => void;
}

const AddNewTeamForm = ({ dealId, onSubmit, onCancel }: IAddNewTeamForm) => {
  const { data: user } = useSignedUser();

  const handleSubmit = useCallback(
    (data: { email: string; persona: string }) => {
      if (user) {
        return addNewTeam({ dealId, data })
          .then(() => {
            onSubmit();
          })
          .catch((error) => {
            if (error.message) {
              toast.errorMessage(error.message);
            }
          });
      }
    },
    [dealId, user, onSubmit]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />

      <Stack spacing={3}>
        <Select
          inputLabel="Company persona"
          options={companyPersona}
          {...formik.getFieldProps("persona")}
          error={formik.touched.persona && !!formik.errors.persona}
          helperText={
            formik.touched.persona && formik.errors.persona
              ? formik.errors.persona
              : ""
          }
          data-testid="company-persona-dropdown"
          inputProps={{ "data-testid": "company-persona-dropdown-input" }}
          dropdownOptionsDataTestid="company-persona-dropdown-option"
        />
        <TextField
          label="Member's email"
          InputLabelProps={{ shrink: true }}
          {...formik.getFieldProps("email")}
          error={formik.touched.email && !!formik.errors.email}
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""
          }
          data-testid="user-email"
          inputProps={{ "data-testid": "user-email-input" }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {onCancel && (
          <Button
            variant="text"
            size="large"
            onClick={onCancel}
            data-testid="close-button"
          >
            Cancel
          </Button>
        )}
        <Button type="submit" size="large" data-testid="submit-button">
          Add New Team
        </Button>
      </Stack>
    </form>
  );
};

export default AddNewTeamForm;

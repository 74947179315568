import React from "react";
import _ from "lodash";

import { colors } from "theme/palette";

import Table, { TableContainer } from "components/Table";

import Body from "./Body";
import BodySkeleton from "./BodySkeleton";
import Headers from "./Headers";

export interface ISimpleTable {
  rows: Array<{ [key: string]: any }>;
  headers: {
    label: string;
    key: string;
    width?: number;
    render?: (row: any, key: string) => React.ReactNode;
  }[];
  editable?: boolean;
  showDeleteCell?: boolean;
  showEmptyRow?: boolean;
  showLoading?: boolean;
  size?: "small" | "medium";
  onUpdated?: (rows: ISimpleTable["rows"]) => void;
  tableDataTestid?: string;
}

const SimpleTable = ({
  rows,
  headers,
  editable = false,
  showDeleteCell = false,
  showEmptyRow = false,
  showLoading = false,
  size,
  onUpdated,
  tableDataTestid,
}: ISimpleTable) => {
  return (
    <TableContainer data-testid={tableDataTestid}>
      <Table
        size={size}
        sx={{
          borderTop: `1px solid ${colors.blue40}`,
          borderLeft: `1px solid ${colors.blue40}`,
        }}
        data-testid="table"
      >
        <Headers headers={headers} showDeleteCell={showDeleteCell} />
        {showLoading && _.isEmpty(rows) && <BodySkeleton headers={headers} />}
        <Body
          rows={rows}
          headers={headers}
          editable={editable}
          showDeleteCell={showDeleteCell}
          showEmptyRow={showEmptyRow}
          onUpdated={onUpdated}
        />
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;

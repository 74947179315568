import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";

import NoContent from "entities/Deal/components/OverviewSections/NoContent";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionContentLabelValue from "entities/Memo/components/Sections/SectionContentLabelValue";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";
import { useDealTeamGroupManager } from "entities/Memo/utils";

interface ITeamSection {
  dealId: number;
  groupName:
    | "Developer"
    | "Architect"
    | "General Contractor"
    | "Property Manager"
    | "Developer Partner";
  externalToken?: string;
  section?: IMemoSection;
  onUpdate?: () => void;
}

const SectionTeam = ({
  dealId,
  groupName,
  externalToken,
  section,
  onUpdate,
}: ITeamSection) => {
  const { data: dealTeamGroups } = useDealTeamGroupManager({
    token: externalToken,
    dealId,
    groupName,
  });

  if (!dealTeamGroups) {
    return null;
  }

  const dealTeamGroup = _.first(dealTeamGroups);

  if (!dealTeamGroup) {
    return null;
  }

  const location = _.first(dealTeamGroup.locations);
  const address = location
    ? `${[location.street, location.city, location.state].join(", ")} - ${
        location.zipcode
      }`
    : "";

  const description =
    _.first(dealTeamGroup.executive_summary)?.summary ||
    dealTeamGroup.description;

  return (
    <Box>
      <SectionHeader
        label={groupName}
        alignActionButton="right"
        actionButton={
          _.isUndefined(externalToken) &&
          section && (
            <NoPrint>
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )
        }
      />
      {section?.is_na ? (
        <NASection />
      ) : (
        <>
          <SectionContent>
            <Stack spacing={2}>
              <SectionContentLabelValue
                label="Company name"
                value={dealTeamGroup.name}
              />
              <SectionContentLabelValue
                label="Office address"
                value={address}
              />
              <SectionContentLabelValue
                label="Established in"
                value={dealTeamGroup.established_in}
              />
              <SectionContentLabelValue
                label="Company website"
                value={dealTeamGroup.website_url}
              />
            </Stack>
          </SectionContent>
          <SectionHeader label="Description" />
          <Box>
            {_.isEmpty(description) ? (
              <NoContent />
            ) : (
              <SanitizeHtml html={description} />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SectionTeam;

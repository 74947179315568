import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import { NotificationsOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import PusherManager from "utils/pusher-manager";

import Fade from "components/Fade";
import Link from "components/Link";
import Popover from "components/Popover";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { useSignedUser } from "entities/Auth/sdk";
import {
  notificationDeleteAll,
  useNotifications,
} from "entities/Notification/sdk";

import Notification from "./components/Notification";

const NotificationsPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = useMemo(() => !_.isNil(anchorEl), [anchorEl]);

  const { data: user, mutate: refetchUser } = useSignedUser();
  const { data, isLoading } = useNotifications({
    limit: 10,
    enabled: open,
  });

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearAll = useCallback(() => {
    notificationDeleteAll().then(() => {
      handleClose();
      refetchUser();
      toast.successMessage("All notifications deleted.");
    });
  }, [refetchUser]);

  const userHasUnseenNotifications = useMemo(
    () => _.get(user, "unseen_notifications_count", 0) > 0,
    [user]
  );

  useEffect(() => {
    if (!_.isNil(user)) {
      return PusherManager.bindToNewNotificationEvent({
        userId: user.id,
        eventHandler: () => {
          refetchUser();
        },
      });
    }
  }, [refetchUser, user]);

  return (
    <>
      <Tooltip title="Notifications" enterDelay={500}>
        <Stack
          onClick={handleOpen}
          sx={{ cursor: "pointer", position: "relative" }}
        >
          {userHasUnseenNotifications && (
            <Stack
              sx={{
                width: "20px",
                height: "15px",
                position: "absolute",
                right: "-8px",
                top: "-4px",
                borderRadius: "20px",
                backgroundColor: colors.blue100,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "2px",
                zIndex: 2,
              }}
            >
              <Text color={colors.white} fontSize={12} fontWeight={700}>
                {user?.unseen_notifications_count}
              </Text>
            </Stack>
          )}
          <NotificationsOutlinedIcon
            sx={{
              color: colors.gray5,
              fontSize: "24px",
              transition: "opacity 0.2s ease-in-out",
              opacity: 0.6,
              "&:hover": { opacity: 1 },
            }}
          />
        </Stack>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "400px",
              overflowY: "auto",
              maxHeight: "calc(100vh - 300px)",
            },
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            top: 0,
            padding: 2,
            zIndex: 2,
            position: "sticky",
            backgroundColor: colors.white,
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: `1px solid ${colors.gray20}`,
          }}
        >
          <Text variant="h3">Notifications</Text>

          <Text
            variant="text4"
            onClick={handleClearAll}
            sx={{ color: colors.gray60, fontWeight: 700, cursor: "pointer" }}
          >
            Clear all
          </Text>
        </Stack>

        {data?.results.map((notification, index) => (
          <Notification
            key={notification.id}
            notification={notification}
            clickCallback={handleClose}
            hasTopBorder={index !== 0}
          />
        ))}

        {!isLoading && data?.results?.length === 0 && (
          <Text padding={theme.spacing(2)}>No notifications yet.</Text>
        )}

        {isLoading &&
          _.range(10).map((index) => (
            <Skeleton
              variant="rectangular"
              key={index}
              height={120}
              width="100%"
            />
          ))}

        {_.get(data, "results.length", 0) > 0 && (
          <Link to={URLS.NOTIFICATIONS}>
            <Text
              variant="text3"
              sx={{
                bottom: 0,
                position: "sticky",
                backgroundColor: colors.white,
                padding: theme.spacing(1, 2),
                color: colors.blue100,
                fontWeight: 700,
                borderTop: `1px solid ${colors.gray20}`,
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: colors.blue10,
                },
              }}
            >
              View all
            </Text>
          </Link>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;

import FormControlLabel from "components/FormControlLabel";
import Switch from "components/Switch";
import Text from "components/Text";

export interface ITableEditModeToggle {
  checked: boolean;
  onToggleChange: () => void;
}

const TableEditModeToggle = ({
  checked,
  onToggleChange,
}: ITableEditModeToggle) => (
  <FormControlLabel
    label={
      <Text variant="text2" sx={{ fontWeight: 500 }}>
        Edit mode:
      </Text>
    }
    control={<Switch checked={checked} onChange={() => onToggleChange()} />}
    labelPlacement="start"
  />
);

export default TableEditModeToggle;

import { useCallback, useMemo, useState } from "react";

import { MenuItem } from "components/Menu";
import Text from "components/Text";

import PartialAccessUpdateDialog from "entities/Package/components/PartialAccessUpdateDialog";
import { IPartialUserAccess } from "entities/Package/sdk";

interface IAccessUpdateButton<T> {
  partialUserAccess: IPartialUserAccess;
  checklistName: string;
  checklistItems: Array<T>;
  onUpdate: () => void;
}

const AccessUpdateButton = <
  T extends { id: number; name: string; children: Array<T>; isField: boolean },
>({
  partialUserAccess,
  checklistName,
  checklistItems,
  onUpdate,
}: IAccessUpdateButton<T>) => {
  const [openUpdateAccessDialog, setOpenUpdateAccessDialog] =
    useState<boolean>(false);

  const handleOpenUpdateAccessDialog = useCallback(() => {
    setOpenUpdateAccessDialog(true);
  }, []);

  const handleCloseUpdateAccessDialog = useCallback(() => {
    setOpenUpdateAccessDialog(false);
  }, []);

  const initiallySelectedItemIds = useMemo(
    () => partialUserAccess.company_custom_fields.map((field) => field.id),
    [partialUserAccess.company_custom_fields]
  );

  return (
    <>
      <MenuItem
        onClick={handleOpenUpdateAccessDialog}
        data-testid="action-option"
      >
        <Text variant="text2">Modify access</Text>
      </MenuItem>

      {openUpdateAccessDialog && (
        <PartialAccessUpdateDialog
          items={checklistItems}
          onClose={handleCloseUpdateAccessDialog}
          onUpdate={onUpdate}
          checklistName={checklistName}
          initiallySelectedItemIds={initiallySelectedItemIds}
          partialUserAccess={partialUserAccess}
        />
      )}
    </>
  );
};

export default AccessUpdateButton;

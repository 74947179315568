import React from "react";

import { AttachFileIcon, ExpandLess, ExpandMore } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

interface IExpandedButton {
  label: string;
  isOpen: boolean;
  onClick: () => void;
}

const ExpandedButton: React.FC<IExpandedButton> = ({
  label,
  isOpen,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: "1em",
        cursor: "pointer",
        margin: 0,
        border: `1px solid ${colors.gray40}`,
        height: "24px",
        backgroundColor: colors.gray10,
        paddingX: theme.spacing(1),
      }}
      data-testid="view-attached-files-expand-button"
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0.3}
      >
        <AttachFileIcon
          sx={{
            height: "16px",
            width: "16px",
          }}
        />
        <Text
          noWrap
          variant="text3"
          fontWeight={500}
          data-testid="total-files-attached-value"
        >
          {label}
        </Text>
        {isOpen ? (
          <ExpandLess
            sx={{
              height: "16px",
              width: "16px",
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              height: "16px",
              width: "16px",
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default ExpandedButton;

import React, { useCallback } from "react";
import { useFormik } from "formik";

import CheckboxTree from "components/CheckboxTree";

interface IFormSettingsTree {
  label: string;
  formValueKey: string;
  formik: ReturnType<typeof useFormik>;
  options: Array<{ value: string; label: string }>;
}

const FormSettingsTree: React.FC<IFormSettingsTree> = ({
  label,
  formik,
  formValueKey,
  options,
}) => {
  const handleChange = useCallback(
    (value: Array<string | number>) =>
      formik.setFieldValue(formValueKey, value),
    [formik, formValueKey]
  );

  return (
    <CheckboxTree
      label={label}
      options={options}
      fieldInputProps={formik.getFieldProps(formValueKey)}
      onChange={handleChange}
      value={formik.values[formValueKey]}
    />
  );
};

export default FormSettingsTree;

import { useMemo } from "react";

import { DeleteIcon } from "icons";
import { colors } from "theme/palette";

import IconButton from "components/IconButton";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { APPLICATION_STATUS } from "entities/Application/constants";
import {
  applicationDiscard,
  IApplicationWithRefetch,
} from "entities/Application/sdk";

export interface IDiscardFromListButton {
  application: IApplicationWithRefetch;
}

const DiscardFromListButton = ({ application }: IDiscardFromListButton) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleClick = () => {
    showConfirmationDialog({
      onConfirm: () =>
        applicationDiscard({ applicationId: application.id })
          .then(() => {
            toast.successMessage("Application discarded successfully");
            application.refetch();
          })
          .catch((error) => {
            const errorMessage =
              error?.message || error?.detail || DEFAULT_ERROR_MESSAGE;

            toast.errorMessage(errorMessage);
          }),
      confirmButtonText: "Discard",
      message:
        "Are you sure you want to discard this application? This action cannot be undone!",
    });
  };

  const isDraft = useMemo(
    () => application.status === APPLICATION_STATUS.DRAFT,
    [application.status]
  );

  const tooltipText = useMemo(
    () =>
      isDraft
        ? "Discard application"
        : "Submitted applications cannot be discarded.",
    [isDraft]
  );

  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <IconButton
          sx={{
            borderRadius: "2px",
            backgroundColor: colors.blue10,
            width: "20px",
            height: "20px",
          }}
          onClick={handleClick}
          disabled={!isDraft}
        >
          <DeleteIcon style={{ width: 15, height: 15 }} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DiscardFromListButton;

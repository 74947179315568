import { FieldType } from "entities/Field/sdk";

export const MEMO_VISIBLE_DUE_DILIGENCE_FIELDS = [
  FieldType.TEXT_FIELD,
  FieldType.RICH_TEXT_FIELD,
  FieldType.RADIO_BUTTON,
  FieldType.CHECKBOX,
  FieldType.DROPDOWN,
  FieldType.FILE,
  FieldType.TABLE,
  FieldType.FIXED_TABLE,
  FieldType.SIGNATURE,
  FieldType.FORM_TEXT_FIELD,
];

import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { EditIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import ProjectEditForm from "entities/Deal/components/OverviewProject/ProjectEditForm";
import {
  IDeal,
  IDealCoreData,
  updateProjectDetails,
  useDealCoreData,
  useDealDetails,
} from "entities/Deal/sdk";

export interface IProjectEditDialog {
  open: boolean;
  handleClose: () => void;
}

const ProjectEditDialog: React.FC<IProjectEditDialog> = ({
  open,
  handleClose,
}) => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: deal, mutate: updateDeal } = useDealDetails({ dealId });
  const { data: coreData, mutate: updateCoreData } = useDealCoreData({
    dealId,
  });

  const handleSubmit = useCallback(
    (
      data: Partial<
        (Omit<IDeal, "phase"> & { phase_name: string }) & IDealCoreData
      >
    ) =>
      updateProjectDetails(dealId, data).then(() => {
        updateDeal();
        if (data.total_units !== coreData?.total_units) {
          updateCoreData();
        }
        toast.successMessage("Project info successfully updated!");
        handleClose();
      }),
    [dealId, coreData, updateCoreData, updateDeal, handleClose]
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <Box>
        <Stack alignItems="center" data-testid="edit-project-details-dialog">
          <Paper
            sx={{
              padding: theme.spacing(8),
              width: "100%",
            }}
          >
            <Stack spacing={4}>
              <Stack direction="row" spacing={1}>
                <EditIcon
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
                <Text variant="h1" style={{ display: "flex" }}>
                  Project info
                </Text>
              </Stack>
              {deal && (
                <ProjectEditForm
                  onSubmit={handleSubmit}
                  handleCancel={handleClose}
                  initialValues={{
                    name: deal.name,
                    legal_name: deal.legal_name,
                    project_type: deal.project_type,
                    rent_type: deal.rent_type,
                    construction_type: deal.construction_type,
                    status: deal.status,
                    phase_name: deal.phase ? deal.phase?.name : "",
                    total_units: coreData && coreData.total_units,
                  }}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ProjectEditDialog;

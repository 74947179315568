import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import { useLoggedUserIsDealAdmin } from "entities/Deal/hooks";
import AddNewTeamMemberButton from "entities/Team/components/AddNewTeamMemberButton";
import DealAccessTable from "entities/Team/components/DealAccessTable";
import DealTeamMemberInvitationsTable from "entities/Team/components/DealTeamMemberInvitationsTable";
import DealTeamSkeleton from "entities/Team/components/DealTeamSkeleton";
import PageTitle from "entities/Team/components/WorkspacePageTitle";
import PageTitleSkeleton from "entities/Team/components/WorkspacePageTitleSkeleton";
import TeamsStickySidebar from "entities/Team/components/WorkspaceStickySidebar";
import TeamsStickySidebarSkeleton from "entities/Team/components/WorkspaceStickySidebarSkeleton";
import {
  IDealAccess,
  useDealTeamMemberInvitations,
  useDealTeamMembers,
} from "entities/Team/sdk";

const TeamMembers = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  const { data: dealTeamMembers, mutate: mutateDealTeamMembers } =
    useDealTeamMembers({ dealId });
  const {
    data: dealTeamMemberInvitations,
    mutate: mutateDealTeamMemberInvitations,
  } = useDealTeamMemberInvitations({
    dealId,
  });

  const { loggedUserIsDealAdmin } = useLoggedUserIsDealAdmin({ dealId });

  const dealTeamMembersOrderedByCompanyName = useMemo(
    () =>
      _.orderBy(
        dealTeamMembers,
        "deal_company_permission.company.name"
      ) as Array<IDealAccess>,
    [dealTeamMembers]
  );

  const dealTeamMembersGroupedByCompany = useMemo(
    () =>
      _.groupBy(
        dealTeamMembersOrderedByCompanyName,
        (teamMember) => teamMember.deal_company_permission.company.name
      ),
    [dealTeamMembersOrderedByCompanyName]
  );

  const teamNames = useMemo(
    () =>
      dealTeamMembersOrderedByCompanyName
        ? _.uniq(
            _.map(
              dealTeamMembersOrderedByCompanyName,
              (teamMember) =>
                `${teamMember.deal_company_permission.company.name}(${teamMember.deal_company_permission.persona})`
            )
          )
        : [],
    [dealTeamMembersOrderedByCompanyName]
  );

  const handleAddNewTeam = useCallback(() => {
    toast.successMessage("Invite has been sent.");
    mutateDealTeamMembers();
    mutateDealTeamMemberInvitations();
  }, [mutateDealTeamMembers, mutateDealTeamMemberInvitations]);

  return (
    <Paper
      sx={{
        padding: theme.spacing(2, 4, 4),
        flex: 1,
      }}
    >
      {!dealTeamMembers && (
        <Stack spacing={5} direction="row">
          <Box sx={{ width: "280px" }}>
            <TeamsStickySidebarSkeleton />
          </Box>

          <Stack spacing={3} style={{ flex: 1 }}>
            <PageTitleSkeleton />

            <Paper>
              <Stack spacing={6}>
                {[1, 2, 3].map((index) => (
                  <DealTeamSkeleton key={index} />
                ))}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      )}
      {dealTeamMembers && !_.isUndefined(dealTeamMemberInvitations) && (
        <Stack spacing={5} direction="row">
          <Box sx={{ width: "280px" }}>
            <TeamsStickySidebar
              teamNames={teamNames}
              hasInvitations={dealTeamMemberInvitations.length > 0}
            />
          </Box>

          <Stack spacing={3} flex="1" sx={{ overflowX: "auto" }}>
            <PageTitle
              dealId={dealId}
              onAddNewTeam={handleAddNewTeam}
              isAdmin={loggedUserIsDealAdmin}
            />
            <Paper>
              {Object.keys(dealTeamMembersGroupedByCompany).map(
                (companyName) => {
                  const companyId =
                    dealTeamMembersGroupedByCompany[companyName][0]
                      .deal_company_permission.company.id;
                  return (
                    <Stack
                      marginBottom={8}
                      spacing={2}
                      data-testid="deal-team-section"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="h3" data-testid="deal-team-name">
                          {companyName}
                        </Text>
                      </Stack>
                      <DealAccessTable
                        dealAccesses={
                          dealTeamMembersGroupedByCompany[companyName]
                        }
                        loggedUserIsDealAdmin={loggedUserIsDealAdmin}
                      />
                      {loggedUserIsDealAdmin && (
                        <AddNewTeamMemberButton
                          dealId={dealId}
                          companyName={companyName}
                          companyId={companyId}
                        />
                      )}
                    </Stack>
                  );
                }
              )}

              {!_.isEmpty(dealTeamMemberInvitations) && (
                <Stack data-testid="invitations-section" spacing={2}>
                  <Text variant="h3" data-testid="invitations-section-name">
                    Invitations
                  </Text>
                  <DealTeamMemberInvitationsTable
                    invitations={dealTeamMemberInvitations}
                    loggedUserIsDealAdmin={loggedUserIsDealAdmin}
                  />
                </Stack>
              )}
            </Paper>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};

export default TeamMembers;

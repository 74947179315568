import { useCallback, useMemo, useState } from "react";
import { FormHelperText } from "@mui/material";
import _ from "lodash";

import theme from "theme";
import { isNotUndefined } from "utils/common";

import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";
import { usePageTitle } from "components/usePageTitle";

import { CompanyFieldFormatType } from "entities/Field/constants";
import FieldValueGroup from "entities/Superuser/components/FieldValueGroup";
import { DataTypesOptions } from "entities/Superuser/constants";

const DataTypesMigration = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [fieldIds, setFieldIds] = useState<string>("");
  const [selectedDataType, setSelectedDataType] = useState<
    CompanyFieldFormatType | undefined
  >(undefined);

  const [selectedFilters, setSelectedFilters] = useState<{
    fieldIds: number[];
    fieldFormatType: CompanyFieldFormatType | undefined;
  }>({ fieldIds: [], fieldFormatType: undefined });

  const checkForInvalidFieldIds = useCallback(() => {
    const invalidFieldIds =
      fieldIds.split(",").filter((id: string) => isNaN(Number(id.trim())))
        .length > 0;

    setHasError(invalidFieldIds);

    return invalidFieldIds;
  }, [fieldIds]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldIds(event.target.value);

      checkForInvalidFieldIds();
    },
    [checkForInvalidFieldIds]
  );

  const handleChangeDesiredDataType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSelectedDataType(event.target.value as CompanyFieldFormatType),
    []
  );

  const handleSubmit = useCallback(() => {
    const invalidFieldIds = checkForInvalidFieldIds();

    if (!invalidFieldIds && !_.isNil(selectedDataType)) {
      const data: {
        fieldIds: number[];
        fieldFormatType: CompanyFieldFormatType;
      } = {
        fieldIds: fieldIds.split(",").map((id: string) => Number(id.trim())),
        fieldFormatType: selectedDataType,
      };
      setSelectedFilters(data);
    }
  }, [fieldIds, selectedDataType, checkForInvalidFieldIds]);

  const isDisabled = useMemo(
    () => _.isNil(selectedDataType) || hasError || _.isEmpty(fieldIds),
    [selectedDataType, hasError, fieldIds]
  );

  usePageTitle(`Builders Patch: Superuser Data Types Migration`);

  return (
    <Stack
      sx={{
        padding: theme.spacing(4, 3, 0),
      }}
      spacing={3}
    >
      <Stack spacing={2}>
        <PageHeader title="Data Types Migration" />
      </Stack>
      <Stack>
        <Stack spacing={2} direction="row">
          <TextField
            size="small"
            label="Field ids"
            defaultValue={fieldIds}
            onChange={_.debounce(handleChange, 200)}
            error={!_.isEmpty(fieldIds) && hasError}
            InputLabelProps={{ shrink: true }}
          />
          <Select
            size="small"
            label="Desired Data type"
            options={DataTypesOptions}
            value={selectedDataType}
            onChange={handleChangeDesiredDataType}
            sx={{ width: "154px" }}
          />
          <Button disabled={isDisabled} onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
        {hasError && (
          <FormHelperText error>
            Field ids should be comma separated numbers
          </FormHelperText>
        )}
      </Stack>
      <Stack spacing={4}>
        {isNotUndefined(selectedFilters.fieldFormatType) &&
          selectedFilters.fieldIds.map((fieldId) => (
            <FieldValueGroup
              key={fieldId}
              fieldId={fieldId}
              fieldFormatType={
                selectedFilters.fieldFormatType as CompanyFieldFormatType
              }
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default DataTypesMigration;

import { memo } from "react";
import _ from "lodash";

import { CheckCircleOutlineIcon } from "icons";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";

export interface ISuccessDialog {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onClose: () => void;
  onButtonClick: () => void;
  buttonTitle?: string;
}

const SuccessDialog = ({
  isOpen,
  title,
  subTitle,
  onClose,
  onButtonClick,
  buttonTitle = "OK, take me there",
}: ISuccessDialog) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth="sm"
    dialogDataTestid="success-dialog"
  >
    <Stack
      spacing={3}
      alignItems="center"
      justifyContent="center"
      width={560}
      height={355}
    >
      <CheckCircleOutlineIcon
        color="primary"
        sx={{ height: 90, width: 90 }}
        data-testid="success-icon"
      />
      <Stack spacing={4} alignItems="center">
        <Stack spacing={1} alignItems="center">
          <Text variant="h1" data-testid="title">
            {title}
          </Text>
          <Text
            variant="text2"
            maxWidth="335px"
            color={colors.gray80}
            sx={{ textAlign: "center" }}
            data-testid="text-content"
          >
            {subTitle}
          </Text>
        </Stack>
        <Button
          color="primary"
          onClick={onButtonClick}
          data-testid="action-button"
        >
          {buttonTitle}
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default memo(SuccessDialog, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import React from "react";

import { colors } from "theme/palette";

import Tabs, { Tab } from "components/Tabs";

interface IAnalyticsDetailTabs<T extends string> {
  selectedTab: string;
  onChange: (event: any, tabIndex: T) => void;
  tabs: Array<{ label: string; value: T }>;
}

const AnalyticsDetailTabs = <T extends string>({
  selectedTab,
  onChange,
  tabs,
}: IAnalyticsDetailTabs<T>) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={onChange}
      sx={{ borderBottom: `1px solid ${colors.blue100}` }}
      TabIndicatorProps={{ sx: { height: "4px" } } as any}
    >
      {tabs.map(({ label, value }) => (
        <Tab
          key={value}
          sx={{
            textTransform: "none",
            color: colors.gray100,
            padding: "9px 1.5em",
            fontSize: "16px",
            "&.Mui-selected": {
              color: colors.gray100,
              backgroundColor: colors.blue20,
            },
          }}
          label={label}
          value={value}
        />
      ))}
    </Tabs>
  );
};

export default AnalyticsDetailTabs;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Text from "components/Text";

const COPYRIGHT_YEAR = new Date().getFullYear();

const Footer: React.FC<{}> = () => (
  <Box
    sx={{
      padding: theme.spacing(3, 14, 6),
      backgroundColor: colors.blue10,
      "@media print and (max-width: 1023px)": {
        display: "none",
      },
    }}
  >
    <Text variant="text3" component="span">
      Builders Patch{" "}
    </Text>
    <Text variant="text3" component="span">
      &#169; Copyright {COPYRIGHT_YEAR}. All rights reserved.
    </Text>
  </Box>
);

export default Footer;

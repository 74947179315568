import { useCallback } from "react";
import * as Sentry from "@sentry/react";
import _ from "lodash";

import { ContentCopyOutlinedIcon } from "icons";

import IconButton from "components/IconButton";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

export interface ICopyToClipboardButton {
  containerId: string;
}

const CopyToClipboardButton = ({ containerId }: ICopyToClipboardButton) => {
  const handleCopyToClipboard = useCallback(() => {
    const container = document.getElementById(containerId);

    if (!_.isNull(container)) {
      let range = document.createRange();
      range.selectNodeContents(container);
      let selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);

      try {
        // Copy the selected HTML to the clipboard
        document.execCommand("copy");
        selection?.removeAllRanges();

        toast.successMessage("The text has been copied to the clipboard.");
      } catch (error) {
        Sentry.captureMessage(
          "Failed to copy the text to the clipboard.",
          (scope) => {
            scope.setTags({
              container: containerId,
            });
            return scope;
          }
        );
        toast.errorMessage("Failed to copy the text to the clipboard.");
      }
    }
  }, [containerId]);

  return (
    <Tooltip title="Copy to clipboard">
      <IconButton onClick={handleCopyToClipboard}>
        <ContentCopyOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;

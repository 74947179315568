export enum TaskTypes {
  MY_TASKS = "my_tasks",
  ALL_TASKS = "all_tasks",
  MY_TEAM_TASKS = "team_tasks",
}

/* 
  The hardcoded width is because the Drawer is shown on top of the DealSidepanel.
  So the width is DealSidepanel.width - DealSidepanelTabs.width which is 800px - 64px 
*/
export const TASK_SIDEPANEL_WIDTH = "736px";

export const DUE_DILIGENCE_TAG_NAME = "Due Diligence";

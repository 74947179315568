import React, { useCallback } from "react";
import { FormikHelpers, useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import FormSeparator from "components/FormSeparator";
import Loading from "components/Loading";
import Select from "components/Select";
import Stack from "components/Stack";
import TextField from "components/TextField";

import CompanyAddressForm from "entities/Company/components/AddressForm";
import { ICompany } from "entities/Company/sdk";

const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("This field is required!"),
  established_in: yup.string().required("This field is required!"),
  web_url: yup.string().required("This field is required!"),
});

const EMPLOYEES_ITEMS = [
  { value: "0-20", label: "0-20 employees" },
  { value: "20-50", label: "20-50 employees" },
  { value: "50-100", label: "50-100 employees" },
  { value: "100+", label: "100+ employees" },
];

const initialValues: Partial<ICompany> = {};

type ICompanyLocations = Omit<ICompany["locations"][0], "id"> & {
  id?: number;
  uuid?: string;
};

export interface ICompanyForm {
  initialValues?: Partial<Omit<ICompany, "id" | "employees">>;
  onSubmit: (data: Partial<Omit<ICompany, "logo">>) => Promise<any>;
  handleCancel?: () => void;
  submitLabel: string;
}

const CompanyForm: React.FC<ICompanyForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
  submitLabel = "Submit",
}) => {
  const handleSubmit = useCallback(
    (
      values: Partial<Omit<ICompany, "logo">>,
      formikHelpers: FormikHelpers<Partial<ICompany>>
    ) => onSubmit(values).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleAddAddress = useCallback(() => {
    const emptyLocation: Omit<ICompanyLocations, "id"> & { uuid?: string } = {
      street: "",
      city: "",
      state: "",
      zipcode: "",
      uuid: uuidv4(),
    };
    const formLocations = formik.values.locations || [];
    const companyLocations = [...formLocations, emptyLocation];

    formik.setFieldValue("locations", companyLocations);
  }, [formik]);

  const handleRemoveAddress = useCallback(
    (removedAddress: ICompanyLocations & { uuid?: string }) => {
      const formLocations = formik.values.locations || [];
      const companyLocations = formLocations.filter((location) =>
        removedAddress.id
          ? location.id !== removedAddress.id
          : location.uuid !== removedAddress.uuid
      );

      formik.setFieldValue("locations", companyLocations);
    },
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3}>
        <FormSeparator title="Company details" />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Company name"
          inputProps={{ "data-testid": "company-name-input" }}
          data-testid="company-name"
          {...formik.getFieldProps("name")}
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
        />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Select
            inputLabel="Company size"
            inputProps={{ "data-testid": "company-size-dropdown-input" }}
            data-testid="company-size-dropdown"
            dropdownOptionsDataTestid="company-size-dropdown-option"
            options={EMPLOYEES_ITEMS}
            {...formik.getFieldProps("company_size")}
            error={formik.touched.company_size && !!formik.errors.company_size}
            helperText={
              formik.touched.company_size && formik.errors.company_size
            }
            sx={{ width: "100%" }}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Established In"
            inputProps={{ "data-testid": "company-established-year-input" }}
            data-testid="company-established-year"
            {...formik.getFieldProps("established_in")}
            error={
              formik.touched.established_in && !!formik.errors.established_in
            }
            helperText={
              formik.touched.established_in && formik.errors.established_in
            }
          />
        </Stack>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Company website"
          inputProps={{ "data-testid": "company-website-input" }}
          data-testid="company-website"
          {...formik.getFieldProps("web_url")}
          error={formik.touched.web_url && !!formik.errors.web_url}
          helperText={formik.touched.web_url && formik.errors.web_url}
        />
        <FormSeparator title="Office address" />
        <Stack spacing={5} alignItems="start">
          {formik
            .getFieldProps("locations")
            .value?.map((location: ICompanyLocations, index: number) => (
              <CompanyAddressForm
                key={location.id || location.uuid}
                index={index}
                formik={formik}
                companyAddress={location}
                onRemove={handleRemoveAddress}
              />
            ))}
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={handleAddAddress}
            data-testid="add-address-group-button"
          >
            + Add another address
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            data-testid="cancel-comapny-profile-dialog"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-comapny-profile-form"
        >
          {submitLabel}
        </Button>
      </Stack>
    </form>
  );
};

export default CompanyForm;

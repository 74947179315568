import _ from "lodash";

import { LOCALE_STRING_ZONE } from "utils/constants";
import { format, FORMATS, parseISO } from "utils/datetime";

import { EFieldFormatType } from "entities/Reporting/constants";

export const formatCellData = ({
  cellData,
  fieldFormatType,
  title,
}: {
  cellData: string | number | undefined;
  fieldFormatType: EFieldFormatType;
  title: string | undefined;
}): { value: string | number | undefined; title: string | undefined } => {
  const isNotEmptyValue = !_.isNil(cellData) && cellData !== "";

  if (
    isNotEmptyValue &&
    fieldFormatType === EFieldFormatType.INTEGER &&
    !isNaN(Number(cellData))
  ) {
    return {
      value: Number(cellData).toLocaleString(LOCALE_STRING_ZONE, {
        maximumFractionDigits: 0,
      }),
      title,
    };
  }

  if (
    isNotEmptyValue &&
    fieldFormatType === EFieldFormatType.FLOAT_2 &&
    !isNaN(Number(cellData))
  ) {
    return {
      value: Number(cellData).toLocaleString(LOCALE_STRING_ZONE, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      title,
    };
  }

  if (
    isNotEmptyValue &&
    fieldFormatType === EFieldFormatType.DATETIME &&
    typeof cellData === "string"
  ) {
    const titleFormat = FORMATS.FRONTEND.DATETIME;

    const formattedTitle = format(new Date(cellData), titleFormat) || title;
    const value = format(new Date(cellData), FORMATS.FRONTEND.DATE);

    return { value, title: formattedTitle };
  }

  if (
    isNotEmptyValue &&
    fieldFormatType === EFieldFormatType.DATE &&
    typeof cellData === "string"
  ) {
    const titleFormat = FORMATS.FRONTEND.DATE;

    const formattedTitle = format(parseISO(cellData), titleFormat) || title;
    const value = format(parseISO(cellData), FORMATS.FRONTEND.DATE);

    return { value, title: formattedTitle };
  }

  if (
    isNotEmptyValue &&
    fieldFormatType === EFieldFormatType.CURRENCY_USD &&
    !isNaN(Number(cellData))
  ) {
    const isNegative = Number(cellData) < 0;
    const clearValue = Number(cellData) * (isNegative ? -1 : 1);

    const formattedValue = clearValue.toLocaleString(LOCALE_STRING_ZONE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return {
      value: `${isNegative ? `$(${formattedValue})` : `$${formattedValue}`}`,
      title,
    };
  }

  return { value: cellData, title };
};

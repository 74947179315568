import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { IDate, isValid } from "utils/datetime";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Dialog from "components/Dialog";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { updateMemoDealInfo } from "entities/Memo/sdk";

const EditDocumentMeetingDateButton = ({
  dealId,
  memoId,
  value: initialValue,
  onChange,
}: {
  dealId: number;
  memoId: number;
  value: IDate | null;
  onChange?: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const [value, setValue] = useState<Date | null>(initialValue);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setValue(null);
    setOpen(false);
    setTouched(false);
  }, []);

  const handleSave = useCallback(() => {
    if (!_.isNull(value)) {
      updateMemoDealInfo({
        memoId,
        dealId,
        memoFinalizedDate: value,
      })
        .then(({ memo_date }) => {
          onChange && onChange(memo_date);
          toast.successMessage("The memo finalized date successfully updated!");
        })
        .finally(() => {
          handleClose();
        });
    }
  }, [memoId, dealId, value, onChange, handleClose]);

  const isValidDate = useMemo(
    () => isValid(value) && !_.isNull(value),
    [value]
  );

  const showError = useMemo(
    () => touched && !isValidDate,
    [touched, isValidDate]
  );

  const showButtonTooltip = useMemo(() => {
    if (!isValidDate) {
      return "Date is required.";
    }
    return "";
  }, [isValidDate]);

  const handleChange = useCallback((value: Date | null) => {
    setValue(value);
    setTouched(true);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <EditIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <Stack sx={{ padding: theme.spacing(4) }} spacing={2}>
          <DatePicker
            label="Meeting Date"
            value={value}
            onChange={handleChange}
            slotProps={{
              textField: {
                error: showError,
                helperText: showError && "The input value is an invalid date.",
              },
            }}
          />
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Tooltip title={showButtonTooltip}>
              <span>
                <Button disabled={!isValidDate} onClick={handleSave}>
                  Save
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default EditDocumentMeetingDateButton;

import { useCallback } from "react";

import { DeleteIcon } from "icons";
import { colors } from "theme/palette";

import IconButton from "components/IconButton";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { deleteMemoTemplate } from "entities/MemoTemplate/sdk";

interface IDeleteMemoTemplateIconButton {
  memoId: number;
  onDeleted?: () => void;
}

const DeleteMemoTemplateIconButton = ({
  memoId,
  onDeleted,
}: IDeleteMemoTemplateIconButton) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleDelete = useCallback(() => {
    showConfirmationDialog({
      onConfirm: () =>
        deleteMemoTemplate({ memoId })
          .then(onDeleted)
          .catch(() => {
            toast.errorMessage(`Error: Unable to delete memo.`);
          }),
      message: "Are you sure you want to delete this memo template?",
    });
  }, [showConfirmationDialog, memoId, onDeleted]);

  return (
    <IconButton
      onClick={handleDelete}
      title="Delete"
      sx={{
        "&:hover": {
          opacity: 1,
          backgroundColor: colors.red80,
        },
      }}
      data-testid="delete-template-button"
    >
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteMemoTemplateIconButton;

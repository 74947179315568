import _ from "lodash";

import { colors } from "theme/palette";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/Table";

const TableCellSkeleton = () => (
  <>
    {_.range(7).map((index) => (
      <TableCell key={index}>
        <Skeleton variant="text" height={20} />
      </TableCell>
    ))}
  </>
);

const DealTeamSkeleton = () => {
  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Skeleton variant="text" height={30} width={120} />
        <Table size="small" sx={{ border: `1px solid ${colors.blue20}` }}>
          <TableHead>
            <TableRow
              sx={{
                ".MuiTableCell-root": {
                  borderBottom: `1px solid ${colors.blue20}`,
                  backgroundColor: colors.blue20,
                  color: colors.black,
                },
              }}
            >
              <TableCellSkeleton />
            </TableRow>
          </TableHead>
          <TableBody>
            {_.range(3).map((index) => (
              <TableRow
                key={index}
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: `1px solid ${colors.blue20}`,
                  },
                }}
              >
                <TableCellSkeleton />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Skeleton variant="text" width={150} height={50} />
      </Stack>
    </Stack>
  );
};

export default DealTeamSkeleton;

import Stack from "components/Stack";

import ProformaContentHeader, {
  ProformaHeaderTypeTotal,
} from "entities/Proforma/components/ProformaContentHeader";

interface IProformaPaper {
  title: string;
  subTitle?: string;
  totals?: Array<{
    label: string;
    value: number | string;
    type: ProformaHeaderTypeTotal;
  }>;
  children?: React.ReactNode;
}

const ProformaPaper = ({
  title,
  subTitle,
  totals,
  children,
}: IProformaPaper) => (
  <Stack direction="row" justifyContent="space-between" spacing={4}>
    <Stack
      sx={{
        flex: 1,
      }}
      direction="column"
    >
      <ProformaContentHeader
        title={title}
        subTitle={subTitle}
        totals={totals}
      />
      <Stack flex={1}>{children}</Stack>
    </Stack>
  </Stack>
);

export default ProformaPaper;

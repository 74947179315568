import { DangerousOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

const FeatureAccessDenied = () => {
  usePageTitle("Builders Patch: Feature Access Denied");

  return (
    <Stack
      sx={{ margin: theme.spacing(11) }}
      alignItems="center"
      spacing={6}
      data-testid="error-page-content"
    >
      <Stack spacing={1} alignItems="center" textAlign="center">
        <DangerousOutlinedIcon
          sx={{ width: "70px", height: "70px", color: colors.red120 }}
          data-testid="error-page-icon"
        />
        <Text variant="h1" data-testid="error-page-header">
          Product feature disabled
        </Text>
        <Text variant="text1" display="inline" data-testid="error-page-message">
          This feature is disabled in your current subscription plan. If you
          want to find out more about it, test it, or start using it, please
          contact our Customer Success team.
        </Text>
      </Stack>
    </Stack>
  );
};

export default FeatureAccessDenied;

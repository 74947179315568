import React from "react";
import _ from "lodash";

import theme from "theme";

import Box from "components/Box";
import SimpleTable from "components/SimpleTable";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

interface ITasksTableSkeleton {}

const headers = _.map(_.range(1, 8), (item) => ({
  label: "",
  key: item.toString(),
}));

const TasksTableSkeleton: React.FC<ITasksTableSkeleton> = () => {
  return (
    <Box sx={{ marginTop: theme.spacing(4) }}>
      <Stack spacing={4}>
        {[1, 2, 3].map((index) => (
          <Stack spacing={2} key={index}>
            <Skeleton variant="text" width="100%" height={50} />
            <SimpleTable showLoading headers={headers} rows={[]}></SimpleTable>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default TasksTableSkeleton;

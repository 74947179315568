import { memo } from "react";
import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";

import DDSectionWrapper from "entities/Memo/components/DDSection/DDSectionWrapper";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import { IMemoSection } from "entities/Memo/sdk";

const MemoDDSection = ({
  section,
  dealId,
  onUpdate,
  externalToken,
}: {
  section: IMemoSection;
  dealId: number;
  onUpdate: () => void;
  externalToken?: string;
}) => (
  <>
    <SectionHeader
      label={section.title}
      alignActionButton="right"
      actionButton={
        _.isUndefined(externalToken) && (
          <NoPrint>
            <MemoSectionContextButton
              dealId={dealId}
              section={section}
              onUpdate={onUpdate}
            />
          </NoPrint>
        )
      }
    />
    <SectionContent>
      {_.isArray(section.fields) &&
        section.fields.map((field) => (
          <Box
            key={field.company_custom_field_id}
            sx={{
              marginTop: "8px",
              "& .MuiIconButton-root": { visibility: "hidden" },
              "&:hover .MuiIconButton-root": {
                visibility: "visible",
              },
            }}
            data-testid="dd-field"
          >
            <DDSectionWrapper
              field={field}
              externalToken={externalToken}
              onUpdate={onUpdate}
              dealId={dealId}
              isNASection={section.is_na}
            />
          </Box>
        ))}
    </SectionContent>
  </>
);

export default memo(MemoDDSection, (prev, next) => {
  return _.isEqual(prev.section, next.section);
});

import { useEffect } from "react";
import _ from "lodash";

import PusherManager from "utils/pusher-manager";

import { useSignedUser } from "entities/Auth/sdk";

export const useTableResponseReady = ({
  successEventHandler,
  failureEventHandler,
}: {
  successEventHandler: ({
    tableResponseId,
  }: {
    tableResponseId: number;
  }) => void;
  failureEventHandler: () => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToTableResponseSucceededEvent({
        userId: user.id,
        eventHandler: ({
          openai_table_response_id,
        }: {
          openai_table_response_id: number;
        }) =>
          successEventHandler({
            tableResponseId: openai_table_response_id,
          }),
      });
    }
  }, [user, successEventHandler]);

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToTableResponseFailedEvent({
        userId: user.id,
        eventHandler: failureEventHandler,
      });
    }
  }, [user, failureEventHandler]);
};

export const useDealTableUpdateSucceeded = ({
  successEventHandler,
}: {
  successEventHandler: ({
    dealId,
    tableId,
    tableName,
  }: {
    dealId: number;
    tableId: number;
    tableName: string;
  }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToDealTableUpdateSucceededEvent({
        userId: user.id,
        eventHandler: ({ deal_id, table_id, table_name }) =>
          successEventHandler({
            dealId: deal_id,
            tableId: table_id,
            tableName: table_name,
          }),
      });
    }
  }, [user, successEventHandler]);
};

export const useTableUpdateSucceeded = ({
  successEventHandler,
}: {
  successEventHandler: ({
    tableId,
    tableName,
  }: {
    tableId: number;
    tableName: string;
  }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToTableUpdateSucceededEvent({
        userId: user.id,
        eventHandler: ({ table_id, table_name }) =>
          successEventHandler({
            tableId: table_id,
            tableName: table_name,
          }),
      });
    }
  }, [user, successEventHandler]);
};

export const useTableOrDealTableUpdateFailed = ({
  failureEventHandler,
}: {
  failureEventHandler: ({ details }: { details: string }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToTableOrDealTableUpdateFailedEvent({
        userId: user.id,
        eventHandler: ({ details }) =>
          failureEventHandler({
            details,
          }),
      });
    }
  }, [user, failureEventHandler]);
};

export const useDealCompanyImportTableSheetNameNotFound = ({
  eventHandler,
}: {
  eventHandler: ({ details }: { details: string }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToDealCompanyImportTableSheetNameNotFoundEvent({
        userId: user.id,
        eventHandler,
      });
    }
  }, [user, eventHandler]);
};

export const useDealCompanyImportTableUpdateFailed = ({
  eventHandler,
}: {
  eventHandler: ({ details }: { details: string }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToDealCompanyImportTableUpdateFailedEvent({
        userId: user.id,
        eventHandler,
      });
    }
  }, [user, eventHandler]);
};

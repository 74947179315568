import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";

export interface ISidepanelHeader {
  children: React.ReactNode;
}

const SidepanelHeader = ({ children }: ISidepanelHeader) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      padding: theme.spacing(2, 4),
      backgroundColor: colors.blue10,
      position: "sticky",
      top: 0,
      zIndex: 2,
    }}
    data-testid="lp-side-panel-header-section"
  >
    {children}
  </Stack>
);

export default SidepanelHeader;

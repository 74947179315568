import { useMemo, useState } from "react";

import { ExpandLess, ExpandMore } from "icons";
import theme from "theme";

import Collapse from "components/Collapse";
import IconButton from "components/IconButton";
import SimpleTable, { ISimpleTable } from "components/SimpleTable";
import Stack from "components/Stack";
import Text from "components/Text";

import { TaskHeaderKeys } from "entities/Task/components/TasksTables/constants";
import ChecklistLinkCell from "entities/Task/components/TasksTables/Table/ChecklistLinkCell";
import CompleteCell from "entities/Task/components/TasksTables/Table/CompleteCell";
import DealAvatarCell from "entities/Task/components/TasksTables/Table/DealAvatarCell";
import MenuCell from "entities/Task/components/TasksTables/Table/MenuCell";
import MessageCountCell from "entities/Task/components/TasksTables/Table/MessageCountCell";
import PriorityCell from "entities/Task/components/TasksTables/Table/PriorityCell";
import TagsCell from "entities/Task/components/TasksTables/Table/TagsCell";
import TitleCell from "entities/Task/components/TasksTables/Table/TitleCell";
import { formatTableData } from "entities/Task/components/TasksTables/utils";

interface ITaskTable {
  tasks: Array<ReturnType<typeof formatTableData>>;
  title: string;
  backgroundColor: string;
  isInDealContext: boolean;
  refreshTasksList: () => void;
}

const TaskTable = ({
  tasks,
  title,
  backgroundColor,
  isInDealContext,
  refreshTasksList,
}: ITaskTable) => {
  const [openTasks, setOpenTasks] = useState(true);

  const tasksHeaders: ISimpleTable["headers"] = useMemo(() => {
    const localTasksHeaders: ISimpleTable["headers"] = [
      {
        label: "Done",
        key: TaskHeaderKeys.COMPLETED,
        width: 70,
        render: (row: any, key: any) => (
          <CompleteCell
            completed={row[key]}
            dealTaskId={row[TaskHeaderKeys.DEAL_TASK_ID]}
            refreshTasksList={refreshTasksList}
          />
        ),
      },
      {
        label: "Deal",
        key: TaskHeaderKeys.DEAL_ID,
        width: isInDealContext ? 30 : 300,
        render: (row, key) => (
          <DealAvatarCell
            dealId={row[key]}
            showDealName={!isInDealContext}
            dealName={row[TaskHeaderKeys.DEAL_NAME]}
            dealAvatar={row[TaskHeaderKeys.DEAL_AVATAR]}
          />
        ),
      },
      {
        label: "Task Title",
        key: TaskHeaderKeys.TASK_TITLE,
        render: (row, key) => (
          <TitleCell
            taskTitle={row[key]}
            isPrivate={row[TaskHeaderKeys.IS_PRIVATE]}
          />
        ),
      },
      {
        label: "Priority",
        key: TaskHeaderKeys.PRIORITY,
        width: 100,
        render: (row, key) => <PriorityCell label={row[key]} />,
      },
      { label: "Due Date", key: TaskHeaderKeys.DUE_DATE, width: 150 },
      {
        label: "Tags",
        key: TaskHeaderKeys.TAGS,
        width: 250,
        render: (row, key) => <TagsCell tags={row[key].split(",")} />,
      },
      { label: "Assigned To", key: TaskHeaderKeys.ASSIGNED_TO, width: 250 },
      {
        label: "Chat",
        key: TaskHeaderKeys.UNREAD_COUNT,
        width: 70,
        render: (row, key) => (
          <MessageCountCell
            refreshTasksList={refreshTasksList}
            isInDealContext={isInDealContext}
            dealId={row[TaskHeaderKeys.DEAL_ID]}
            count={row[key]}
            taskChannelId={row[TaskHeaderKeys.TASK_CHANNEL_ID]}
          />
        ),
      },
      {
        label: "Checklist task",
        key: "",
        width: 70,
        render: (row, key) => (
          <ChecklistLinkCell
            dealId={row[TaskHeaderKeys.DEAL_ID]}
            companyPackage={row[TaskHeaderKeys.COMPANY_PACKAGE]}
            companyTab={row[TaskHeaderKeys.COMPANY_TAB]}
            companyCategory={row[TaskHeaderKeys.COMPANY_CATEGORY]}
            companySection={row[TaskHeaderKeys.COMPANY_SECTION]}
            companyField={row[TaskHeaderKeys.COMPANY_FIELD]}
          />
        ),
      },
      {
        label: "",
        key: TaskHeaderKeys.DETAILS,
        width: 70,
        render: (row, key) => (
          <MenuCell
            isInDealContext={isInDealContext}
            taskChannelId={row[TaskHeaderKeys.TASK_CHANNEL_ID]}
            refreshTasksList={refreshTasksList}
            dealId={row[TaskHeaderKeys.DEAL_ID]}
          />
        ),
      },
    ];

    return localTasksHeaders;
  }, [refreshTasksList, isInDealContext]);

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          padding: theme.spacing(0.5, 1),
          backgroundColor: backgroundColor,
          cursor: "pointer",
          borderRadius: "3px",
        }}
        onClick={() => setOpenTasks(!openTasks)}
      >
        <IconButton size="small">
          {openTasks ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Text variant="text2" fontWeight="500">
          {title} ({tasks.length})
        </Text>
      </Stack>
      <Collapse in={openTasks} timeout="auto" unmountOnExit>
        <SimpleTable
          showEmptyRow
          size="small"
          headers={tasksHeaders}
          rows={tasks}
        />
      </Collapse>
    </Stack>
  );
};

export default TaskTable;

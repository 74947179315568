import { useCallback, useMemo } from "react";
import { FormikHelpers, useFormik } from "formik";
import _ from "lodash";

import theme from "theme";
import { filterErrorsWithoutResponseStatus } from "utils/common";

import Alert from "components/Alert";
import Button from "components/Button";
import FormSeparator from "components/FormSeparator";
import Loading from "components/Loading";
import Stack from "components/Stack";
import TextField from "components/TextField";

import { IUser } from "entities/User/sdk";

const initialValues: Partial<IUser> = {
  first_name: "",
  last_name: "",
  email: "",
  job_title: "",
  contact: "",
};

export interface IEmployeeForm {
  initialValues?: Partial<Omit<IUser, "id">>;
  onSubmit: (data: Partial<IUser>) => Promise<any>;
  onCancel?: () => void;
  submitLabel: string;
}

const EmployeeForm = ({
  initialValues: propsInitialValues,
  onSubmit,
  onCancel,
  submitLabel = "Submit",
}: IEmployeeForm) => {
  const handleSubmit = useCallback(
    (values: Partial<IUser>, formikHelpers: FormikHelpers<Partial<IUser>>) =>
      onSubmit(values).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    onSubmit: handleSubmit,
  });

  const formikErrors = useMemo(() => {
    const errors = filterErrorsWithoutResponseStatus({ errors: formik.errors });
    return Object.values(errors);
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      {_.isArray(formikErrors) && (
        <Stack spacing={2} marginBottom={2}>
          {formikErrors.map((error) => (
            <Alert severity="error" key={error} data-testid="error-banner">
              {error}
            </Alert>
          ))}
        </Stack>
      )}
      <Stack spacing={3}>
        <FormSeparator title="Basic info" />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="First name"
          inputProps={{ "data-testid": "user-first-name-input" }}
          data-testid="user-first-name"
          {...formik.getFieldProps("first_name")}
          error={formik.touched.first_name && !!formik.errors.first_name}
          helperText={
            formik.touched.first_name && formik.errors.first_name
              ? formik.errors.first_name
              : ""
          }
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Last name"
          inputProps={{ "data-testid": "user-last-name-input" }}
          data-testid="user-last-name"
          {...formik.getFieldProps("last_name")}
          error={formik.touched.last_name && !!formik.errors.last_name}
          helperText={
            formik.touched.last_name && formik.errors.last_name
              ? formik.errors.last_name
              : ""
          }
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Designation"
          inputProps={{ "data-testid": "user-designation-input" }}
          data-testid="user-designation"
          {...formik.getFieldProps("job_title")}
          error={formik.touched.job_title && !!formik.errors.job_title}
          helperText={formik.touched.job_title && formik.errors.job_title}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Email"
          inputProps={{ "data-testid": "user-email-input" }}
          data-testid="user-email"
          {...formik.getFieldProps("email")}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Phone No."
          inputProps={{ "data-testid": "user-phone-input" }}
          data-testid="user-phone"
          {...formik.getFieldProps("contact")}
          error={formik.touched.contact && !!formik.errors.contact}
          helperText={formik.touched.contact && formik.errors.contact}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {onCancel && (
          <Button
            variant="text"
            size="large"
            data-testid="close-add-member-dialog"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button type="submit" size="large" data-testid="submit-add-member-form">
          {submitLabel}
        </Button>
      </Stack>
    </form>
  );
};

export default EmployeeForm;

import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseISO } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Loading from "components/Loading";
import {
  COMMON_EDITOR_CONTAINER_STYLES,
  COMMON_EDITOR_STYLES,
  EMPTY_VALUE_REGEX,
} from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import SmallAlert from "components/SmallAlert";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import {
  IDealSummary,
  updateSummary,
  useDealSummaryByCategory,
} from "entities/Memo/sdk";

type IDealSummaryValues = Pick<IDealSummary, "summary">;

export interface IProjectNarrativeFormDialog {
  open: boolean;
  handleClose: () => void;
}

const ProjectNarrativeFormDialog = ({
  open,
  handleClose,
}: IProjectNarrativeFormDialog) => {
  const [saveTimer, setSaveTimer] = useState<NodeJS.Timeout | null>(null);

  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);

  const { data: summaryData, mutate: mutateSummary } = useDealSummaryByCategory(
    {
      dealId: dealId,
      category: "executive_summary",
    }
  );

  const onClose = useCallback(() => {
    if (!_.isNull(saveTimer)) {
      clearTimeout(saveTimer);
    }

    handleClose();
  }, [handleClose, saveTimer]);

  const handleSubmit = useCallback(
    (data: IDealSummaryValues) => {
      const formattedData = EMPTY_VALUE_REGEX.test(data.summary)
        ? { summary: "" }
        : data;

      updateSummary({
        dealId: dealId,
        category: "executive_summary",
        data: formattedData,
      })
        .then(() => {
          mutateSummary();
          toast.successMessage("Project narrative successfully updated!");
          onClose();
        })
        .catch(handleInvalidRequest);
    },
    [onClose, mutateSummary, dealId]
  );

  const formik = useFormik({
    initialValues: {
      summary: summaryData?.summary || "",
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleChangeValue = useCallback(
    (newValue: string) => {
      formik.setFieldValue("summary", newValue);

      // Save the data every 3 seconds of inactivity.
      const newTimer = setTimeout(() => {
        const formattedData = EMPTY_VALUE_REGEX.test(newValue)
          ? { summary: "" }
          : { summary: newValue };

        updateSummary({
          dealId: dealId,
          category: "executive_summary",
          data: formattedData,
        }).then(() => {
          mutateSummary();
        });
      }, 3000);

      setSaveTimer((prevTimer) => {
        if (!_.isNull(prevTimer)) {
          clearTimeout(prevTimer);
        }

        return newTimer;
      });
    },
    [formik, dealId, mutateSummary]
  );

  if (_.isUndefined(summaryData)) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          data-testid="project-narrative-edit-dialog"
          sx={{
            margin: theme.spacing(2, 2, 0, 2),
            padding: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <EditIcon
              style={{
                height: "24px",
                width: "24px",
              }}
            />
            <Text variant="h2">Project narrative</Text>
          </Stack>
        </DialogTitle>
        {summaryData && (
          <DialogContent
            sx={{
              margin: theme.spacing(2, 2, 0, 2),
              padding: 0,
              overflowY: "hidden",
            }}
          >
            <Loading open={formik.isSubmitting} />
            <Stack
              spacing={3}
              data-testid="project-narrative-rtf-edit-section"
              sx={COMMON_EDITOR_CONTAINER_STYLES}
            >
              <RichTextEditorField
                defaultValue={formik.getFieldProps("summary").value}
                onChange={handleChangeValue}
                helperText={
                  formik.touched.summary && formik.errors.summary
                    ? formik.errors.summary
                    : ""
                }
                sx={COMMON_EDITOR_STYLES}
              />
            </Stack>
          </DialogContent>
        )}
        <DialogActions
          sx={{
            padding: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <SmallAlert title="Don’t forget to save your changes after editing!" />
            {!_.isNil(summaryData.updated_at) && (
              <Text
                variant="text3"
                component="span"
                sx={{ color: colors.gray80 }}
              >
                Last saved at{" "}
                {format(
                  parseISO(summaryData.updated_at),
                  FORMATS.FRONTEND.DATETIME
                )}
              </Text>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              onClick={onClose}
              data-testid="close-project-narrative-edit-dialog"
            >
              Cancel
            </Button>
            <Button type="submit" data-testid="submit-project-narrative-form">
              Save changes
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProjectNarrativeFormDialog;

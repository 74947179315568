import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { format, FORMATS } from "utils/datetime";
import { reverse } from "utils/urls";

import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Select from "components/Select";
import Stack from "components/Stack";
import Switch from "components/Switch";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import AssetManagementTable from "entities/AssetManagement/components/AssetManagementTable";
import AssetManagementTabs from "entities/AssetManagement/components/AssetManagementTabs";
import { extractRowKey } from "entities/AssetManagement/components/Table/utils";
import {
  AMColumn,
  AMResolution,
  AssetManagementTabItems,
} from "entities/AssetManagement/constants";
import {
  useAssetManagementData,
  useVersions,
  useVersionsDiff,
} from "entities/AssetManagement/sdk";
import { subCategoryToCategory } from "entities/AssetManagement/utils";

const AssetManagementVersions = () => {
  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  usePageTitle("Builders Patch: Asset Management Version History");

  const [selectedVersion, setSelectedVersion] = useState<number>();
  const [selectedTab, setSelectedTab] = useState(
    AssetManagementTabItems.INCOME
  );
  const [resolution, setResolution] = useState<AMResolution>(
    AMResolution.YEARLY
  );

  const { data: versions } = useVersions({ dealId });

  const { data: diff, isLoading: isLoadingDiff } = useVersionsDiff({
    prevVersionId: selectedVersion,
    newVersionId: _.first(versions)?.id,
  });

  useEffect(() => setSelectedVersion(_.first(versions)?.id), [versions]);

  const { data: newData } = useAssetManagementData({
    dealId,
  });

  const deletedRows = Object.keys(diff?.rows_diff || {})
    .filter((rowKey) => diff?.rows_diff[rowKey].is_deleted)
    .map((rowKey) => {
      const { itemCode, subCategory } = extractRowKey({ itemKey: rowKey });
      return {
        code: itemCode,
        subCategory,
        name: diff?.rows_diff[rowKey].previous_label || "",
        is_deleted: true,
        date_values: [],
      };
    });

  const data = useMemo(
    () =>
      newData
        ? {
            data: {
              Income: [],
              Expenses: [],
              "Other Data": [],
              "Debt Service": [],
              ..._.mapValues(
                _.groupBy(newData.rows || [], (row) =>
                  subCategoryToCategory(row.sub_category)
                ),
                (value) => {
                  return _.map(
                    _.groupBy(value, "sub_category"),
                    (valueRow, key) => ({
                      data_points: [
                        ...valueRow.map((val) => ({
                          code: val.item_code,
                          name: val.label,
                          date_values: _.map(
                            _.groupBy(
                              val.values,
                              (item) =>
                                `${item.date_info.year}|${
                                  item.date_info.quarter || ""
                                }`
                            ),
                            (dataValuesList, yearAndQuarterKey) => {
                              const [yearKey, quarterKey] =
                                yearAndQuarterKey.split("|");

                              return {
                                ...dataValuesList.reduce(
                                  (prev, curr) => ({
                                    ...prev,
                                    [curr.data_type]: {
                                      value: curr.value,
                                      id: null,
                                      aggregated: true,
                                    },
                                  }),
                                  {}
                                ),
                                date_info: {
                                  year: Number(yearKey),
                                  quarter: quarterKey || null,
                                },
                              };
                            }
                          ),
                        })),
                        ...deletedRows.filter((row) => row.subCategory === key),
                      ],
                      key: key === "null" ? null : key,
                      total_values: (() => {
                        const myrow = valueRow.map((row) => ({
                          ...row,
                          valuesObj: row.values.reduce<{ [key: string]: any }>(
                            (prev, curr) => ({
                              ...prev,
                              [`${curr.date_info.year}/${curr.date_info.quarter}/${curr.data_type}`]:
                                curr,
                            }),
                            {}
                          ),
                        }));

                        const totalRow = myrow.reduce((prev, current) => {
                          const keys = Array.from(
                            new Set([
                              ...Object.keys(prev.valuesObj),
                              ...Object.keys(current.valuesObj),
                            ])
                          );

                          return {
                            ...prev,
                            valuesObj: keys.reduce((prevObj, currentKey) => {
                              return {
                                ...prevObj,
                                [currentKey]: {
                                  ..._.get(
                                    prevObj,
                                    currentKey,
                                    _.get(current, `valuesObj.${currentKey}`)
                                  ),
                                  value:
                                    Number(
                                      _.get(prevObj, `${currentKey}.value`, 0)
                                    ) +
                                    Number(
                                      _.get(
                                        current,
                                        `valuesObj.${currentKey}.value`,
                                        0
                                      )
                                    ),
                                },
                              };
                            }, prev.valuesObj),
                          };
                        });

                        return _.map(
                          _.groupBy(
                            totalRow.valuesObj,
                            (item) =>
                              `${item.date_info.year}|${
                                item.date_info.quarter || ""
                              }`
                          ),
                          (dataValuesList, yearAndQuarterKey) => {
                            const [yearKey, quarterKey] =
                              yearAndQuarterKey.split("|");

                            return {
                              ...dataValuesList.reduce(
                                (prev, curr) => ({
                                  ...prev,
                                  [curr.data_type]: {
                                    value: curr.value,
                                    id: null,
                                    aggregated: true,
                                  },
                                }),
                                {}
                              ),
                              date_info: {
                                year: Number(yearKey),
                                quarter: quarterKey || null,
                              },
                            };
                          }
                        );
                      })(),
                    })
                  );
                }
              ),
            },
            headers: newData.headers.filter((header) =>
              resolution === AMResolution.QUARTERLY
                ? header.quarter
                : !header.quarter
            ),
          }
        : undefined,
    [newData, deletedRows, resolution]
  );

  const versionSelectOptions = useMemo(
    () =>
      versions?.map((version) => ({
        value: version.id.toString(),
        label: `Version ${version.version_number} created by ${
          version.created_by.first_name
        } ${version.created_by.last_name} on ${format(
          new Date(version.created_at),
          FORMATS.FRONTEND.DATETIME
        )}`,
      })) || [],
    [versions]
  );

  const onChangeResolution = useCallback(
    (_: unknown, checked: boolean) =>
      setResolution(checked ? AMResolution.QUARTERLY : AMResolution.YEARLY),
    []
  );

  return (
    <Paper
      sx={{
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack spacing={3}>
        <PageHeader
          backLink={reverse(URLS.DEAL_ASSET_MANAGEMENT, { dealId })}
          backTitle="Back to Asset Management"
          title="Asset management version history"
          actions={
            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Text
                  variant="text2"
                  fontWeight={500}
                  data-testid="yearly-toggle-label"
                >
                  {AMResolution.YEARLY}
                </Text>
                <Switch
                  checked={resolution === AMResolution.QUARTERLY}
                  onChange={onChangeResolution}
                  data-testid="yearly-quarterly-toggle"
                />
                <Text
                  variant="text2"
                  fontWeight={500}
                  data-testid="quarterly-toggle-label"
                >
                  {AMResolution.QUARTERLY}
                </Text>
              </Stack>
              <Select
                size="small"
                value={selectedVersion?.toString() || ""}
                sx={{ width: "400px" }}
                inputProps={{
                  sx: {
                    backgroundColor: "white",
                  },
                }}
                label="Version"
                helperText="Pick a version you would like to compare with the current version"
                options={versionSelectOptions}
                onChange={(e) => setSelectedVersion(Number(e.target.value))}
                data-testid="am-versions-dropdown"
                dropdownOptionsDataTestid="am-versions-dropdown-option"
              />
            </Stack>
          }
        />
        <AssetManagementTabs
          selectedTab={selectedTab}
          onChange={(tab) => setSelectedTab(tab)}
        />
        {data && (
          <AssetManagementTable
            rowDelete={() => {}}
            itemDelete={() => {}}
            selectedTab={selectedTab}
            columns={[AMColumn.BUDGETED, AMColumn.ACTUAL, AMColumn.AUDITED]}
            showSubItems={true}
            loading={isLoadingDiff}
            data={data}
            editMode={false}
            resolution={resolution}
            diff={diff}
          />
        )}
      </Stack>
    </Paper>
  );
};

export default AssetManagementVersions;

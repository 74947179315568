import { useCallback } from "react";
import _ from "lodash";

import SearchField from "components/SearchField";
import { useFilters } from "components/useFilters";

import { SEARCH_STRING_FILTER_KEY } from "entities/Reporting/constants";

export interface IReportSearchField {
  placeholder: string;
}

const ReportSearchField = ({ placeholder }: IReportSearchField) => {
  const { filters, updateFilter, deleteFilter } = useFilters();

  const handleSearch = useCallback(
    (e: any) => {
      const value = e.target.value;
      if (value !== "") {
        updateFilter({ key: SEARCH_STRING_FILTER_KEY, value });
      } else {
        deleteFilter({ key: SEARCH_STRING_FILTER_KEY });
      }
    },
    [updateFilter, deleteFilter]
  );

  return (
    <SearchField
      size="small"
      placeholder={placeholder}
      value={_.get(filters, [SEARCH_STRING_FILTER_KEY], "")}
      onChange={handleSearch}
      dataTestid="deals-searchbar"
      sx={{ backgroundColor: "white", width: "100%", maxWidth: 500 }}
    />
  );
};

export default ReportSearchField;

import _ from "lodash";

import ApplicationFieldWrapper, {
  IFieldWrapper as IApplicationFieldWrapper,
} from "entities/Application/components/FieldWrapper";
import { IApplicationField } from "entities/Application/sdk";
import ChecklistFieldWrapper, {
  IFieldWrapper as IChecklistFieldWrapper,
} from "entities/Checklist/components/FieldWrapper";
import { FieldWrapperType } from "entities/Checklist/constants";
import { IPackageDetailsField } from "entities/Checklist/sdk";
import DueDiligenceFieldWrapper, {
  IFieldEditPreviewFieldWrapper,
} from "entities/Package/components/PdfImport/FieldEditPreviewFieldWrapper";
import { getValueByFieldIdFromParsedValues } from "entities/Package/components/PdfImport/utils";
import {
  ICompanyCustomFieldParsedValue,
  IDueDiligenceMappedData,
  IPackageField,
} from "entities/Package/sdk";

type IChecklistFieldWrapperMeta = Omit<IChecklistFieldWrapper, "field">;
type IApplicationFieldWrapperMeta = Omit<
  IApplicationFieldWrapper,
  "field" | "onUpdate"
> & { onUpdate?: () => void };
type IDueDiligenceFieldWrapperMeta = Omit<
  IFieldEditPreviewFieldWrapper,
  "editable" | "field" | "parsedValue" | "sectionId"
> & {
  parsedValues: Array<IDueDiligenceMappedData>;
  newValues: Array<ICompanyCustomFieldParsedValue>;
};

export type IFieldWrapperMeta =
  | {
      fieldWrapperType: FieldWrapperType.APPLICATION;
      fieldWrapperMeta: IApplicationFieldWrapperMeta;
    }
  | {
      fieldWrapperType: FieldWrapperType.CHECKLIST;
      fieldWrapperMeta: IChecklistFieldWrapperMeta;
    }
  | {
      fieldWrapperType: FieldWrapperType.DUE_DILIGENCE_EDIT_PREVIEW;
      fieldWrapperMeta: IDueDiligenceFieldWrapperMeta;
    };

export type IApplicationFieldOrIPackageDetailsFieldOrIPackageField =
  | IApplicationField
  | IPackageDetailsField
  | IPackageField;

export type IFieldWrapperManager = {
  field: IApplicationFieldOrIPackageDetailsFieldOrIPackageField;
  sectionId: number;
} & IFieldWrapperMeta;
const FieldWrapperManager = ({
  field,
  sectionId,
  fieldWrapperType,
  fieldWrapperMeta,
}: IFieldWrapperManager) => {
  if (fieldWrapperType === FieldWrapperType.APPLICATION) {
    return (
      <ApplicationFieldWrapper
        field={field as IApplicationField}
        editable={fieldWrapperMeta.editable}
        applicationId={fieldWrapperMeta.applicationId}
        onUpdate={fieldWrapperMeta.onUpdate}
      />
    );
  }

  if (fieldWrapperType === FieldWrapperType.CHECKLIST) {
    return <ChecklistFieldWrapper field={field as IPackageDetailsField} />;
  }

  if (fieldWrapperType === FieldWrapperType.DUE_DILIGENCE_EDIT_PREVIEW) {
    const dueDiligenceField = field as IPackageField;
    const parsedValue = getValueByFieldIdFromParsedValues({
      fieldId: dueDiligenceField.field_id,
      values: fieldWrapperMeta.newValues,
    });

    const editable = !_.isUndefined(
      _.find(
        fieldWrapperMeta.parsedValues,
        (mappedValue) =>
          mappedValue.company_custom_field.id === dueDiligenceField.field_id
      )
    );

    return (
      <DueDiligenceFieldWrapper
        sectionId={sectionId}
        parsedValue={parsedValue}
        editable={editable}
        field={dueDiligenceField}
        dealId={fieldWrapperMeta.dealId}
        packageId={fieldWrapperMeta.packageId}
        categoryId={fieldWrapperMeta.categoryId}
        tabId={fieldWrapperMeta.tabId}
        onUpdate={fieldWrapperMeta.onUpdate}
      />
    );
  }

  return <></>;
};

export default FieldWrapperManager;

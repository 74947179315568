import { v4 as uuid4 } from "uuid";

export const getTableVerticalHeaders = ({
  rows,
  firstColumn,
}: {
  rows: Array<string>;
  firstColumn: string;
}) => {
  return rows.map((row) => {
    return { key: uuid4(), [firstColumn]: row };
  });
};

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { format, FORMATS, parseISO } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Paper from "components/Paper";
import {
  COMMON_EDITOR_CONTAINER_STYLES,
  COMMON_EDITOR_STYLES,
} from "components/RichTextEditor/constants";
import RichTextEditorField from "components/RichTextEditorField";
import SmallAlert from "components/SmallAlert";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import {
  IMemoSection,
  memoSectionAISummaryResponseCreateOrUpdate,
} from "entities/Memo/sdk";

export interface IEditDialogButton {
  section: IMemoSection;
  dealId: number;
  buttonLabel: string;
  onCreate: () => void;
}

const EditDialogButton = ({
  section,
  dealId,
  buttonLabel,
  onCreate,
}: IEditDialogButton) => {
  const [response, setResponse] = useCaching(
    _.first(section.memo_section_openai_responses)?.response || ""
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setResponse(_.first(section.memo_section_openai_responses)?.response || "");
    setIsOpen(false);
  }, [section, setResponse]);

  const handleCreate = useCallback(() => {
    const openaiCustomResponseId =
      _.first(section.memo_section_openai_responses)
        ?.openai_custom_prompt_response?.id || null;

    memoSectionAISummaryResponseCreateOrUpdate({
      dealId,
      memoSectionId: section.section_id,
      openAICustomPromptResponseId: openaiCustomResponseId,
      response,
    })
      .then(() => {
        setIsOpen(false);
        onCreate();
        toast.successMessage("Response has been updated successfully.");
      })
      .catch(handleInvalidRequest);
  }, [response, dealId, onCreate, section]);

  const lastUpdated = useMemo(() => {
    const customResponse = _.first(
      section.memo_section_openai_responses
    )?.openai_custom_prompt_response;

    if (!_.isNil(customResponse)) {
      return format(
        parseISO(customResponse.updated_at),
        FORMATS.FRONTEND.DATETIME
      );
    }
  }, [section]);

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        variant="outlined"
        color="secondary"
      >
        {buttonLabel}
      </Button>

      <Dialog open={isOpen} maxWidth="lg" fullWidth onClose={handleClose}>
        <DialogTitle sx={{ padding: 2 }}>
          <Paper sx={{ backgroundColor: colors.blue10, borderRadius: "4px" }}>
            <Text variant="h2">{section.title}</Text>
          </Paper>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Stack spacing={2}>
            <Box sx={COMMON_EDITOR_CONTAINER_STYLES}>
              <RichTextEditorField
                defaultValue={response}
                onChange={setResponse}
                sx={COMMON_EDITOR_STYLES}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <SmallAlert title="Don’t forget to save your changes after editing!" />
            {!_.isNil(lastUpdated) && (
              <Text
                variant="text3"
                component="span"
                sx={{ color: colors.gray80 }}
              >
                Last saved at {lastUpdated}
              </Text>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleCreate}>Save</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialogButton;

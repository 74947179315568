import React, { memo, useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { ArrowDropDownIcon, ArrowRightIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Button from "components/Button";
import Chip from "components/Chip";
import Collapse from "components/Collapse";
import IconButton from "components/IconButton";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { FieldType } from "entities/Field/sdk";
import FieldValueTable from "entities/Superuser/components/FieldValueTable";
import FieldValueTableSkeleton from "entities/Superuser/components/FieldValueTableSkeleton";
import {
  companyCustomFieldValuesApplyFieldFormatType,
  IFieldGroup,
  ISuperuserFieldValuesData,
  useCompanyCustomFieldValuesForField,
} from "entities/Superuser/sdk";

export interface IFieldValueGroup extends IFieldGroup {}

const FieldValueGroup = ({ fieldId, fieldFormatType }: IFieldValueGroup) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const [nestedFieldIds, setNestedFieldIds] = useState<number[]>([]);
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const {
    data: field,
    isLoading,
    isValidating,
    mutate: mutateMainField,
    error,
  } = useCompanyCustomFieldValuesForField({
    fieldId,
    fieldFormatType,
  });

  const onApply = useCallback(() => {
    setIsButtonDisabled(true);
    companyCustomFieldValuesApplyFieldFormatType({
      fieldId,
      fieldFormatType,
    })
      .then(() => {
        toast.successMessage(
          "Field format type and values were successfully migrated"
        );
        setNestedFieldIds([]);
        mutateMainField();
      })
      .catch(handleInvalidRequest)
      .finally(() => {
        setIsButtonDisabled(false);
      });
  }, [fieldId, fieldFormatType, mutateMainField]);

  const handleApply = useCallback(() => {
    showConfirmationDialog({
      onConfirm: onApply,
      confirmButtonColor: "primary",
      confirmButtonText: "Yes",
      message:
        nestedFieldIds.length > 0
          ? `Are you sure you want apply new data type and values for field id "${fieldId}" and all linked fields with ids "${nestedFieldIds.join(
              ", "
            )}"?`
          : `Are you sure you want apply new data type and values for field id "${fieldId}"?`,
    });
  }, [fieldId, nestedFieldIds, onApply, showConfirmationDialog]);

  const handleUpdateNestedLinkedFieldIds = useCallback(
    ({ field: linkedField }: { field: ISuperuserFieldValuesData }) =>
      setNestedFieldIds((prev) => {
        const linkedFieldIdsWithoutMainField = _.uniq([
          ...prev,
          ...linkedField.linked_field_ids,
        ]).filter((linkedFieldId) => linkedFieldId !== fieldId);

        return linkedFieldIdsWithoutMainField;
      }),
    [fieldId]
  );

  const loading = useMemo(
    () => isLoading || isValidating,
    [isLoading, isValidating]
  );

  const showFieldError = useMemo(
    () => field?.field_type !== FieldType.TEXT_FIELD,
    [field]
  );

  return (
    <Stack
      sx={{
        paddingBottom: theme.spacing(4),
        borderBottom: `1px solid ${colors.blue20}`,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        {loading ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={80} />
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            <Text variant="h3">Field "{fieldId}"</Text>
            <Chip
              label={`${nestedFieldIds.length} linked fields`}
              sx={{
                border: `1px solid ${colors.gray40}`,
                backgroundColor: colors.gray10,
                borderRadius: 4,
                height: 24,
              }}
            />
          </Stack>
        )}
        {_.isEmpty(error) && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            alignSelf="flex-end"
          >
            {loading ? (
              <Skeleton width={100} height={50} />
            ) : (
              <>
                {showFieldError ? (
                  <Text variant="text2">
                    Cannot be migrated because the field type is{" "}
                    <span style={{ fontWeight: 600 }}>
                      "{field?.field_type}"
                    </span>{" "}
                    should be
                    <span style={{ fontWeight: 600 }}>
                      "{FieldType.TEXT_FIELD}"
                    </span>
                  </Text>
                ) : (
                  <Button
                    sx={{ alignSelf: "flex-end" }}
                    disabled={isButtonDisabled}
                    onClick={handleApply}
                  >
                    Apply new data type and values
                  </Button>
                )}
              </>
            )}
          </Stack>
        )}
      </Stack>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingLeft: theme.spacing(5),
          }}
        >
          {loading ? (
            <FieldValueTableSkeleton />
          ) : (
            <FieldValueTable
              fieldId={fieldId}
              fieldFormatType={fieldFormatType}
              fieldFetchCallback={handleUpdateNestedLinkedFieldIds}
            />
          )}
          {nestedFieldIds.map((linkedFieldId) => (
            <React.Fragment key={linkedFieldId}>
              <FieldValueTable
                fieldId={linkedFieldId}
                fieldFormatType={fieldFormatType}
                fieldFetchCallback={handleUpdateNestedLinkedFieldIds}
              />
            </React.Fragment>
          ))}
        </Box>
      </Collapse>
    </Stack>
  );
};

export default memo(FieldValueGroup, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

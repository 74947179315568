import { BASE_URL } from "config/urls";
import { deleteCall, post, usePaginatedFetch } from "utils/sdk";

export interface INotification {
  id: number;
  category: string;
  content: string;
  target_pathname: string;
  seen_at: string;
  created_at: string;
  actor: {
    first_name: string;
    last_name: string;
  };
  deal: { name: string } | undefined;
}

export const useNotifications = ({
  enabled = true,
  limit,
}: {
  enabled?: boolean;
  limit: number;
}) =>
  usePaginatedFetch<INotification>({
    url: enabled ? "v2/notifications/list/" : undefined,
    params: { limit },
  });

export const notificationMarkAsSeen = ({
  notificationId,
}: {
  notificationId: number;
}) => post(`${BASE_URL}v2/notifications/${notificationId}/mark-as-seen/`);

export const notificationDelete = ({
  notificationId,
}: {
  notificationId: number;
}) => deleteCall(`${BASE_URL}v2/notifications/${notificationId}/delete/`);

export const notificationDeleteAll = () =>
  deleteCall(`${BASE_URL}v2/notifications/delete-all/`);

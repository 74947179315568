import { BASE_URL } from "config/urls";
import { get, PaginatedResponse, post, useFetch } from "utils/sdk";

import { IUser } from "entities/User/sdk";

export interface IZipStructure {
  tabs: Array<{
    name: string;
    categories: Array<{
      name: string;
      sections: Array<{
        name: string;
        files: Array<{
          name: string;
          url: string;
        }>;
      }>;
    }>;
  }>;
}

export interface IZip {
  url: string;
  name: string;
  created_at: string;
  started_at: string;
  finished_at?: string | null;
  failed?: boolean;
  created_by: IUser;
  persona: string;
  phase: string;
}

export interface IZipList {
  id: number;
  name: string;
  created_at: string;
  started_at: string;
  finished_at?: string | null;
  failed?: boolean;
  created_by: IUser;
  persona: string;
  phase: string;
}

export const useZipStructure = ({
  dealId,
  packageId,
}: {
  dealId: number;
  packageId: number;
}) =>
  useFetch<IZipStructure>(
    `v2/due-diligence/deal/${dealId}/package/${packageId}/zip/structure/`
  );

export const useZipList = ({
  dealId,
  packageId,
  offset = 0,
  limit = 10,
}: {
  dealId: number;
  packageId: number;
  offset?: number;
  limit?: number;
}) =>
  useFetch<PaginatedResponse<IZipList>>(
    `v2/due-diligence/deal/${dealId}/package/${packageId}/zip/list/?offset=${offset}&limit=${limit}`
  );

export const getZipDetail = ({ zipId }: { zipId: number }): Promise<IZip> =>
  get(`${BASE_URL}v2/due-diligence/zip/detail/${zipId}/`);

export const startCreatingZipFile = ({
  dealId,
  packageId,
}: {
  dealId: number;
  packageId: number;
}) =>
  post(
    `${BASE_URL}v2/due-diligence/deal/${dealId}/package/${packageId}/zip/create/`
  );

import theme from "theme";

import Box from "components/Box";
import Container from "components/Container";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import { usePageTitle } from "components/usePageTitle";

import { useSignedUser } from "entities/Auth/sdk";
import SubscriptionDetails from "entities/Company/components/CompanySettings/SubscriptionDetails";
import ExternalAPIsInfoBox from "entities/Company/components/ExternalAPIsInfoBox";
import CompanyProfileInfoBox from "entities/Company/components/ProfileInfoBox";
import CompanyTeamInfoBox from "entities/Company/components/TeamInfoBox";
import { useCurrentCompany } from "entities/Company/sdk";
import UserInfoBox from "entities/User/components/InfoBox";
import StickySidebar from "entities/User/components/StickySidebar";

const Profile = () => {
  usePageTitle("Builders Patch: Profile");
  const { data: user } = useSignedUser();

  const { data: company } = useCurrentCompany(user?.company_id);

  return (
    <Container>
      <Stack spacing={2} sx={{ paddingTop: theme.spacing(4) }}>
        <PageHeader title="My account" />
        <Stack spacing={4} direction="row">
          <Box sx={{ width: "280px" }}>
            <StickySidebar />
          </Box>
          <Stack data-testid="profile-sections" spacing={2} style={{ flex: 1 }}>
            {user && <UserInfoBox user={user} />}

            {user && company && (
              <CompanyProfileInfoBox company={company} user={user} />
            )}

            {company?.external_apis_enabled && (
              <ExternalAPIsInfoBox company={company} />
            )}
            {user && company && (
              <CompanyTeamInfoBox employees={company.employees} user={user} />
            )}

            <SubscriptionDetails />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
export default Profile;

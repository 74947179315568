import { BASE_URL } from "config/urls";
import { deleteCall, post, useFetch } from "utils/sdk";

import { ProjectSize } from "entities/AnalyticsFilterGroup/constants";
import { ConstructionType, Phase } from "entities/Deal/constants";

export enum ANALYTICS_TYPES {
  DEVELOPMENT_BUDGET = "development-budget",
  INCOME_AND_OPEX = "income-and-opex",
  ASSET_MANAGEMENT = "asset-management",
}

export enum QUARTER_OPTIONS {
  FULL_YEAR = "full_year",
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export interface IFilterGroup {
  id: number;
  name: string;
  project_phase: Phase[];
  construction_type: ConstructionType[];
  project_size: ProjectSize[];
  cities: string[];
  filter_type: "development_budget" | "income_and_opex";
  excluded_deals: Array<{
    id: number;
    name: string;
  }>;
  deals: Array<{
    id: number;
    name: string;
    avatar: string | null;
    total_units?: number;
    total_s_f?: string;
    total_cost?: {
      acquisition_cost: number;
      soft_costs: number;
      hard_costs: number;
      developer_fees: number;
      total_costs: number;
    };
    hard_cost?: {
      trade_costs: number;
      miscellaneous_items: number;
      general_conditions: number;
      general_contractor_overhead: number;
      general_contractor_profit: number;
      total_hard_costs: number;
      hard_cost_contingency: number;
      total_hard_csts_with_contingency: number;
    };
    soft_cost?: {
      professional_fees: number;
      third_part_reports: number;
      fees_and_interest: number;
      conversion_and_lease: number;
      operating_reserve: number;
      total_soft_costs: number;
      soft_cost_contingency: number;
      total_soft_costs_with_contingency: number;
    };
    income?: {};
    expenses?: {};
    metrics?: {};
  }>;
  created_by: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    avatar: string;
  };
  updated_at: string;
  updated_by?: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    avatar: string;
  };
  deal_sum_data: {
    // All of the number values here come as strings from the API since they are decimal
    total_units: string;
    total_s_f: string;
    acquisition_cost: string;
    soft_costs: string;
    hard_costs: string;
    developer_fees: string;
    total_costs: string;
    trade_costs: string;
    miscellaneous_items: string;
    general_conditions: string;
    general_contractor_overhead: string;
    general_contractor_profit: string;
    total_hard_costs: string;
    hard_cost_contingency: string;
    total_hard_csts_with_contingency: string;
    professional_fees: string;
    third_part_reports: string;
    fees_and_interest: string;
    conversion_and_lease: string;
    operating_reserve: string;
    total_soft_costs: string;
    soft_cost_contingency: string;
    total_soft_costs_with_contingency: string;
  };
  deal_avg_data: {
    total_units: string;
    total_s_f: string;
    acquisition_cost: string;
    soft_costs: string;
    hard_costs: string;
    developer_fees: string;
    total_costs: string;
    trade_costs: string;
    miscellaneous_items: string;
    general_conditions: string;
    general_contractor_overhead: string;
    general_contractor_profit: string;
    total_hard_costs: string;
    hard_cost_contingency: string;
    total_hard_csts_with_contingency: string;
    professional_fees: string;
    third_part_reports: string;
    fees_and_interest: string;
    conversion_and_lease: string;
    operating_reserve: string;
    total_soft_costs: string;
    soft_cost_contingency: string;
    total_soft_costs_with_contingency: string;
  };
}

export const useAnalyticsFilterGroups = (analyticsType: ANALYTICS_TYPES) => {
  const analyticsTypeParam = analyticsType.split("-").join("_");
  return useFetch<IFilterGroup[]>(
    `v2/analytics/list/?filter_type=${analyticsTypeParam}`
  );
};

export const createFilter = (data: Partial<IFilterGroup>) =>
  post<IFilterGroup>(`${BASE_URL}v2/analytics/create/`, data);

export const updateFilter = (
  filterGroupId: number,
  data: Partial<
    Omit<IFilterGroup, "excluded_deals"> & { excluded_deals: number[] }
  >
) =>
  post<IFilterGroup>(`${BASE_URL}v2/analytics/${filterGroupId}/update/`, data);

export const deleteFilter = (filterGroupId: number) =>
  deleteCall<IFilterGroup>(`${BASE_URL}v2/analytics/${filterGroupId}/delete/`);

/*
  The URLs are:
  - v2/analytics/filter-group/${id}/development-budget/details/
  - v2/analytics/filter-group/${id}/income-and-opex/details/
  - v2/analytics/filter-group/${id}/asset-management/details/?year=${year}&quarter=${quarter}
*/
export const useFilterGroup = (
  id: string | undefined,
  analyticsType: ANALYTICS_TYPES,
  year: string | undefined = undefined,
  quarter: QUARTER_OPTIONS | undefined = undefined
) =>
  useFetch<IFilterGroup>(
    id
      ? analyticsType === ANALYTICS_TYPES.ASSET_MANAGEMENT
        ? `v2/analytics/filter-group/${id}/${analyticsType}/details/?year=${year}&quarter=${quarter}`
        : `v2/analytics/filter-group/${id}/${analyticsType}/details/`
      : undefined
  );

import React, { useMemo } from "react";

import { InfoIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import { IScrollableTable } from "components/ScrollableTable";
import SubHeaderCell from "components/ScrollableTable/Cells/SubHeaderCell";
import { CELL_DEFAULT_WIDTH } from "components/ScrollableTable/constants";
import {
  getHeaderStyles,
  toNumber,
} from "components/ScrollableTable/TableHeaders/utils";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

export interface IHeaderCell {
  header: IScrollableTable["headers"][number];
  currentSort: string | undefined;
  lastElementWidth: number;
  onSort?: (key: string | undefined) => void;
}

const HeaderCell = ({
  header,
  currentSort,
  lastElementWidth,
  onSort,
}: IHeaderCell) => {
  const headerWidth = useMemo(
    () =>
      header.children
        .map(
          (subHeader) =>
            toNumber(subHeader.width) || toNumber(CELL_DEFAULT_WIDTH)
        )
        .reduce((res, cur) => res + cur),
    [header]
  );

  return (
    <Box
      key={header.label}
      sx={getHeaderStyles({ header, lastElementWidth })}
      data-testid="table-headers-section"
    >
      {header.info ? (
        <Stack
          sx={{
            padding: theme.spacing(0.625, 1.5),
            width: headerWidth,
            overflow: "hidden",
            borderBottom: `1px solid ${colors.gray100}`,
          }}
          alignItems="center"
          direction="row"
        >
          <Tooltip title={header.info} placement="top-start">
            <InfoIcon
              sx={{
                width: "12px",
                height: "12px",
                marginRight: theme.spacing(1),
              }}
            />
          </Tooltip>
          <Tooltip
            title={header.label}
            disableInteractive
            placement="top-start"
          >
            <Text
              variant="text2"
              sx={{ color: colors.black, fontWeight: 700 }}
              noWrap
            >
              {header.label}
            </Text>
          </Tooltip>
        </Stack>
      ) : (
        <Stack
          sx={{
            padding: theme.spacing(0.625, 1.5),
            width: headerWidth,
            overflow: "hidden",
            borderBottom: `1px solid ${colors.gray100}`,
          }}
          alignItems="center"
          direction="row"
        >
          <Tooltip
            disableInteractive
            title={header.label}
            placement="top-start"
          >
            <Text
              noWrap
              variant="text2"
              sx={{
                color: colors.black,
                fontWeight: 700,
                minHeight: "22.4px", // Needed so that the row doesn't get squished if the label's missing
              }}
              data-testid="table-section-header"
            >
              {header.label}
            </Text>
          </Tooltip>
        </Stack>
      )}
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        {header.children.map((subHeader, index) => (
          <SubHeaderCell
            key={index}
            subHeader={subHeader}
            currentSort={currentSort}
            onSort={onSort}
          />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(HeaderCell);

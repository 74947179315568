import React, { useCallback, useState } from "react";

import { URLS } from "config/urls";
import { ArrowDropDownIcon } from "icons";
import { colors } from "theme/palette";

import Button from "components/Button";
import Link from "components/Link";
import Menu, { MenuItem } from "components/Menu";
import Skeleton from "components/Skeleton";
import Text from "components/Text";
import UserAvatar from "components/UserAvatar";
import VisibleIfSuperuser from "components/VisibleIfSuperuser";

import { logout } from "entities/Auth/sdk";
import { IUser } from "entities/User/sdk";

export interface IAvatarDropdownButton {
  user: Pick<IUser, "first_name" | "last_name" | "avatar"> | undefined;
}

const AvatarDropdownButton: React.FC<IAvatarDropdownButton> = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  if (!user) {
    return (
      <Button
        variant="text"
        data-testid="user-dropdown-menu-loading"
        sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.11)" } }}
        startIcon={
          <Skeleton
            variant="circular"
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "rgba(255,255,255,0.11)",
            }}
          />
        }
        endIcon={
          <ArrowDropDownIcon
            color="secondary"
            data-testid="user-dropdown-menu-arrow-button"
          />
        }
      >
        <Skeleton
          variant="text"
          sx={{ width: 100, backgroundColor: "rgba(255, 255, 255, 0.11)" }}
        />
      </Button>
    );
  }

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        data-testid="user-dropdown-menu"
        sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.11)" } }}
        startIcon={
          <UserAvatar
            alt=""
            src={user.avatar ? user.avatar : undefined}
            imgix
            imgixWidth={24}
            imgixHeight={24}
            sx={{
              width: 24,
              height: 24,
              border: "3px solid #c9009a",
            }}
            dataTestid={
              user.avatar
                ? "custom-user-dropdown-avatar"
                : "default-user-dropdown-avatar"
            }
          />
        }
        endIcon={
          <ArrowDropDownIcon
            color="secondary"
            data-testid="user-dropdown-menu-arrow-button"
          />
        }
      >
        <Text variant="text4" color={colors.white} sx={{ fontWeight: 500 }}>
          {user.first_name} {user.last_name}
        </Text>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        onClick={handleClose}
      >
        <Link to={URLS.PROFILE}>
          <MenuItem>
            <Text variant="text2">My Profile</Text>
          </MenuItem>
        </Link>
        <VisibleIfSuperuser>
          <Link to={URLS.SUPERUSER_DASHBOARD}>
            <MenuItem>
              <Text variant="text2">Superuser Dashboard</Text>
            </MenuItem>
          </Link>
        </VisibleIfSuperuser>
        <VisibleIfSuperuser>
          <Link to={URLS.SUPERUSER_DATA_TYPES_MIGRATION}>
            <MenuItem>
              <Text variant="text2">Data types migration</Text>
            </MenuItem>
          </Link>
        </VisibleIfSuperuser>
        <VisibleIfSuperuser>
          <Link to={URLS.SUPERUSER_LINKED_FIELDS}>
            <MenuItem>
              <Text variant="text2">Add linked field</Text>
            </MenuItem>
          </Link>
        </VisibleIfSuperuser>
        <VisibleIfSuperuser>
          <Link to={URLS.SUPERUSER_CUSTOMER_LIST}>
            <MenuItem>
              <Text variant="text2">Control room</Text>
            </MenuItem>
          </Link>
        </VisibleIfSuperuser>
        <MenuItem onClick={logout}>
          <Text variant="text2">Logout</Text>
        </MenuItem>
      </Menu>
    </>
  );
};
export default AvatarDropdownButton;

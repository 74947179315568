import { useCallback, useState } from "react";

import { ShareIcon } from "icons";

import Button from "components/Button";

import SharableLinkPopup from "entities/Memo/components/ShareableLinkPopup";

export interface IShareButton {
  dealId: number;
  memoId: number;
}

const ShareButton = ({ dealId, memoId }: IShareButton) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={<ShareIcon />}
        onClick={handleOpen}
      >
        Sharing
      </Button>
      <SharableLinkPopup
        dealId={dealId}
        memoId={memoId}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default ShareButton;

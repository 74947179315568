import theme from "theme";
import { colors } from "theme/palette";
import { format, parseJSON } from "utils/datetime";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";
import UserAvatar from "components/UserAvatar";

import { IMemoSectionComment } from "entities/Memo/sdk";

const Comment = ({ comment }: { comment: IMemoSectionComment }) => {
  return (
    <Box id={`comment-id-${comment.id}`}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <UserAvatar
            imgix
            imgixWidth={24}
            imgixHeight={24}
            sx={{
              width: 24,
              height: 24,
              backgroundColor: colors.gray60,
            }}
            src={
              comment.created_by.avatar ? comment.created_by.avatar : undefined
            }
          >
            <Text variant="labelSmall" sx={{ fontWeight: 700 }}>
              {comment.created_by.first_name.charAt(0)}
              {comment.created_by.last_name.charAt(0)}
            </Text>
          </UserAvatar>
          <Text variant="text2" sx={{ fontWeight: 700 }}>
            {comment.created_by.first_name} {comment.created_by.last_name}
          </Text>
          <Text variant="text4" color={colors.gray60}>
            {format(parseJSON(comment.created_at), "hh:mm aaa, MMM dd, yyyy")}
          </Text>
        </Stack>
        <Text variant="text4" color={colors.gray60}>
          {comment.created_by.company_name}
        </Text>
      </Stack>
      <Box
        sx={{
          display: "inline-block",
          backgroundColor: colors.gray10,
          padding: theme.spacing(2, 3),
          margin: theme.spacing(1, 1, 1, 3),
          borderRadius: theme.spacing(3),
          borderTopLeftRadius: 0,
          "& p": {
            margin: 0,
          },
        }}
      >
        {comment.message}
      </Box>
    </Box>
  );
};

export default Comment;

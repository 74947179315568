import React from "react";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

interface ILabel {
  data: Array<{
    label: string;
    value: number;
    color: string;
  }>;
}

const Legend = ({ data }: ILabel) => {
  const total = data.reduce((res, { value }) => res + value, 0);
  return (
    <Stack>
      {data.map(({ label, color, value }) => (
        <Stack direction="row" spacing={1} key={label}>
          <Box
            sx={{
              width: "18px",
              height: "18px",
              backgroundColor: color,
            }}
          />
          <Text variant="text3">
            {label} ({value === 0 ? 0 : ((100 * value) / total).toFixed(2)}%)
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};

export default Legend;

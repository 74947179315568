import _ from "lodash";

import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";

export const combineDealsForFilterGroup = ({
  deals,
  excludedDeals,
}: {
  deals: IFilterGroup["deals"];
  excludedDeals: IFilterGroup["excluded_deals"];
}) => {
  return [
    ..._.map(deals, (value) => {
      return {
        name: value.name,
        id: value.id,
        is_excluded: false,
      };
    }),
    ..._.map(excludedDeals, (value) => {
      return {
        name: value.name,
        id: value.id,
        is_excluded: true,
      };
    }),
  ];
};

import { useCallback } from "react";

import { MenuItem } from "components/Menu";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { cancelShareToken } from "entities/Package/sdk";
interface IShareTokenCancelInvitationButton {
  shareTokenId: number;
  onUpdate: () => void;
}

const ShareTokenCancelInvitationButton = ({
  shareTokenId,
  onUpdate,
}: IShareTokenCancelInvitationButton) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleCancelInvitation = useCallback(
    () =>
      showConfirmationDialog({
        onConfirm: () =>
          cancelShareToken({ shareTokenId })
            .then(() => {
              toast.successMessage("Invitation canceled.");
              onUpdate();
            })
            .catch((error) => {
              toast.errorMessage(error.message || DEFAULT_ERROR_MESSAGE);
            }),
        message: "Are you sure you want to cancel this invitation?",
        confirmButtonText: "Cancel invitation",
      }),
    [onUpdate, showConfirmationDialog, shareTokenId]
  );

  return (
    <MenuItem onClick={handleCancelInvitation} data-testid="action-option">
      Cancel invitation
    </MenuItem>
  );
};

export default ShareTokenCancelInvitationButton;

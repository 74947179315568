import React from "react";
import { useParams } from "react-router-dom";

import SectionForm from "entities/MemoTemplate/components/SectionForm";
import {
  IMemoTemplateSection,
  updateMemoTemplateSection,
  useMemoTemplateDetails,
} from "entities/MemoTemplate/sdk";

const EditSectionForm = ({
  section,
  onCancel,
  onSectionEditted,
  formikRef,
}: {
  section: IMemoTemplateSection;
  onCancel: () => void;
  onSectionEditted: () => void;
  formikRef?: React.Ref<any>;
}) => {
  const params = useParams<{ memoId: string }>();
  const memoId = Number(params.memoId);
  const { mutate: mutateMemo } = useMemoTemplateDetails({ memo_id: memoId });

  const handleSubmit = (
    data: Parameters<typeof updateMemoTemplateSection>[0]["data"]
  ) =>
    updateMemoTemplateSection({
      memoSectionId: section.memo_section_id,
      data,
    }).then(() => {
      mutateMemo();
      onSectionEditted();
    });

  return (
    <SectionForm
      initialValues={{
        ...section,
        due_diligence_section:
          section.due_diligence_section?.due_diligence_section_id,
        due_diligence_package_id:
          section.due_diligence_section?.due_diligence_package_id,
      }}
      isEditForm
      dueDiligenceSection={section.due_diligence_section}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      formikRef={formikRef}
    />
  );
};

export default EditSectionForm;

import * as yup from "yup";

export const VALIDATION_SCHEMA = yup.object({
  documentAssistantId: yup
    .number()
    .required("This field is required!")
    .positive()
    .integer(),
  promptId: yup
    .number()
    .required("This field is required!")
    .positive()
    .integer(),
});

import { useCallback, useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { formatNumberToString } from "utils/common";

import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { AM_VARIANCE_COLUMN } from "entities/AssetManagement/constants";
import { IAMDateInfo, IDiff } from "entities/AssetManagement/sdk";
import { getTypeAndDateInfoByColumnKey } from "entities/AssetManagement/utils";

import ValueCellEditIcon from "./ValueCellEditIcon";

export interface IValueCell {
  row: { [key: string]: any };
  columnKey: string;
  itemCode: string;
  editMode: boolean;
  deleteButtonDataTestid?: string;
  deleteItem?: ({
    itemCode,
    type,
    dateInfo,
    itemId,
  }: {
    itemCode: string;
    type: string;
    dateInfo: IAMDateInfo;
    itemId: number;
  }) => void;
  diff?: IDiff["cells_diff"][string];
}

const ValueCell = ({
  row,
  columnKey,
  editMode,
  itemCode,
  deleteButtonDataTestid,
  deleteItem,
  diff,
}: IValueCell) => {
  const handleClick = useCallback(() => {
    if (deleteItem && row[columnKey]?.value) {
      const typeAndDateInfo = getTypeAndDateInfoByColumnKey(columnKey);

      deleteItem({
        itemCode,
        type: typeAndDateInfo.type,
        dateInfo: {
          year: typeAndDateInfo.year,
          quarter: typeAndDateInfo.quarter || undefined,
        },
        itemId: row[columnKey]?.id,
      });
    }
  }, [columnKey, row, itemCode, deleteItem]);

  const showEditIcon = useMemo(
    () => !_.isUndefined(row[columnKey]?.value) && row.sub && editMode,
    [row, columnKey, editMode]
  );

  const value = useMemo(() => {
    if (
      !_.isUndefined(row[columnKey]?.value) ||
      !_.isNil(diff?.current_value)
    ) {
      // If there is a dynamic column for Variance, we should represent the value without formatting.
      if (columnKey.endsWith(AM_VARIANCE_COLUMN)) {
        return row[columnKey]?.value;
      }

      return formatNumberToString({
        number: Number(diff?.current_value || row[columnKey]?.value),
        options: { maximumFractionDigits: 2 },
      });
    }

    return "-";
  }, [row, columnKey, diff]);

  const previousValueTitle = useMemo(() => {
    // If the diff doesn't have a current_value, the cell is deleted and we should show the previous value.
    if (!_.isUndefined(diff) && _.isNull(diff.current_value)) {
      return (
        <div>
          <div>Deleted cell.</div>
          <div>
            Previous value:{" "}
            {formatNumberToString({
              number: Number(diff.previous_value),
              options: { maximumFractionDigits: 2 },
            })}
          </div>
        </div>
      );
    }

    // If the diff doesn't have a previous_value, the cell is newly added and we should show a message.
    if (!_.isUndefined(diff) && _.isNull(diff.previous_value)) {
      return "Newly added cell";
    }

    // If the diff has a previous_value and current_value, the cell is updated and we should show the previous value.
    if (!_.isUndefined(diff) && !_.isNil(diff.previous_value)) {
      return `Previous value: ${formatNumberToString({
        number: Number(diff.previous_value),
        options: { maximumFractionDigits: 2 },
      })}`;
    }

    return "";
  }, [diff]);

  const diffColor = useMemo(() => {
    if (!diff) {
      return "transparent";
    }
    if (_.isNull(diff.current_value)) {
      return colors.red40;
    }

    return colors.green40;
  }, [diff]);

  return (
    <Tooltip title={previousValueTitle}>
      <Stack
        direction="row"
        justifyContent={showEditIcon ? "space-between" : "flex-end"}
        sx={{
          width: "100%",
          backgroundColor: diffColor,
          padding: theme.spacing(0.625, 2),
        }}
      >
        {showEditIcon ? (
          <ValueCellEditIcon
            aggregated={row[columnKey]?.aggregated}
            onDeleteClick={handleClick}
            deleteButtonDataTestid={deleteButtonDataTestid}
          />
        ) : null}
        <Text
          noWrap
          variant="text3"
          sx={{
            paddingY: "3px",
          }}
          data-testid="cell-value"
        >
          {value}
        </Text>
      </Stack>
    </Tooltip>
  );
};

export default ValueCell;

import { memo } from "react";

import { PDF_MIME_TYPE } from "utils/constants";

import { CircularProgress } from "components/Loading";

const PDFEmbed = ({ pdfFileUrl }: { pdfFileUrl: string | undefined }) => {
  if (!pdfFileUrl) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <embed
      type={PDF_MIME_TYPE}
      src={`${pdfFileUrl}#page=0&toolbar=false&view=fitH`}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  );
};

export default memo(PDFEmbed);

import { useCallback, useMemo, useState } from "react";
import { InputLabel } from "@mui/material";
import _ from "lodash";

import { InstructionsIcon } from "icons";
import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Box from "components/Box";
import Dialog, { DialogContent, DialogTitle } from "components/Dialog";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { ISnapshot } from "entities/Proforma/sdk";

export interface ISnapshotDetails {
  snapshot: ISnapshot;
}

const SnapshotDetails = ({ snapshot }: ISnapshotDetails) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const createdAtAndBy = useMemo(
    () =>
      `Created on ${format(
        parseJSON(snapshot.created_at),
        FORMATS.FRONTEND.DATETIME
      )} by ${snapshot.created_by.first_name} ${snapshot.created_by.last_name}`,
    [snapshot]
  );

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tag label="snapshot" variant="gray80" />
        <Tooltip title="See snapshot details" placement="top">
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
            }}
          >
            <InstructionsIcon
              sx={{
                width: "16px",
                height: "16px",
              }}
              onClick={handleOpen}
            />
          </Box>
        </Tooltip>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        transparentBackdrop
      >
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Snapshot details
          </Text>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Stack spacing={2}>
            <Stack spacing={1} alignItems="flex-start">
              <InputLabel>Deal phase</InputLabel>
              <Tag label={snapshot.phase_name} />
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Description</InputLabel>
              {_.isEmpty(snapshot.description) ? (
                <Text variant="text2">No description has been set.</Text>
              ) : (
                <Text variant="text2" sx={{ whiteSpace: "pre-line" }}>
                  {snapshot.description}
                </Text>
              )}
            </Stack>
            <Text variant="text4" sx={{ color: colors.gray60 }}>
              {createdAtAndBy}
            </Text>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SnapshotDetails;

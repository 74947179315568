import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import Switch from "components/Switch";
import Text from "components/Text";
import toast from "components/Toast";
import { useLoadingBackdrop } from "components/useLoadingBackdrop";

import { CompanyCustomFieldSyncingType } from "entities/Field/constants";
import AddLinkedFieldFilters from "entities/Superuser/components/AddLinkedFieldFilters";
import DealPackage from "entities/Superuser/components/DealPackage";
import { SYNCING_OPTIONS } from "entities/Superuser/components/DealPackage/constants";
import {
  addLinkIdsToField,
  IDealLinkedPackage,
  IDealPackageSyncingType,
} from "entities/Superuser/sdk";

import { FieldSelectionMethod } from "./constants";

const LinkedFields = () => {
  const [fieldSelectionMethod, setFieldSelectionMethod] =
    useState<FieldSelectionMethod>(FieldSelectionMethod.FULL_FIELD_PATH);
  const [showLoading, hideLoading] = useLoadingBackdrop();
  const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
  const [selectedLinkId, setSelectedLinkId] = useState<string>("");
  const [dealPackages, setDealPackages] = useState<Array<IDealLinkedPackage>>(
    []
  );

  const [selectedDealPackagesSyncingType, setSelectedDealPackagesSyncingType] =
    useState<{
      [dealId: number]:
        | CompanyCustomFieldSyncingType.ERASE_ALL_VALUES
        | CompanyCustomFieldSyncingType.SYNC_CURRENT_VALUE_TO_REST
        | CompanyCustomFieldSyncingType.SYNC_REST_VALUES_TO_CURRENT_VALUE;
    }>([]);

  const bulkSelectSyncingOption = useCallback(
    ({ syncingType }: { syncingType: CompanyCustomFieldSyncingType }) =>
      setSelectedDealPackagesSyncingType(() => ({
        ...dealPackages.reduce(
          (acc, dealPackage) => ({
            ...acc,
            [dealPackage.deal_id]: syncingType,
          }),
          {}
        ),
      })),
    [dealPackages]
  );

  const handleSelectSyncingOption = useCallback(
    ({ syncingType, dealId }) =>
      setSelectedDealPackagesSyncingType((prev) => ({
        ...prev,
        [dealId]: syncingType,
      })),
    []
  );

  const handleSave = useCallback(() => {
    if (!_.isNull(selectedFieldId) && !_.isEmpty(selectedLinkId)) {
      showLoading();

      const dealPackages: Array<IDealPackageSyncingType> = Object.entries(
        selectedDealPackagesSyncingType
      ).map(([deal_id, syncing_type]) => ({
        deal_id: Number(deal_id),
        syncing_type,
      }));

      addLinkIdsToField({
        fieldId: selectedFieldId,
        linkId: selectedLinkId,
        dealPackages,
      })
        .then(() => {
          toast.successMessage("Linked field has been added successfully!");
          setDealPackages([]);
          setSelectedDealPackagesSyncingType([]);
        })
        .catch(handleInvalidRequest)
        .finally(() => {
          hideLoading();
        });
    }
  }, [
    selectedDealPackagesSyncingType,
    selectedFieldId,
    selectedLinkId,
    showLoading,
    hideLoading,
  ]);

  const saveButtonIsDisabled = useMemo(
    () =>
      dealPackages
        .map((dealPackage) =>
          _.has(selectedDealPackagesSyncingType, dealPackage.deal_id)
        )
        .filter((item) => !item).length > 0,
    [dealPackages, selectedDealPackagesSyncingType]
  );

  const handleCheck = useCallback(({ dealLinkedPackages, fieldId, linkId }) => {
    setDealPackages(dealLinkedPackages);
    setSelectedFieldId(fieldId);
    setSelectedLinkId(linkId);
    setSelectedDealPackagesSyncingType([]);
  }, []);

  const handleRaiseError = useCallback(() => setDealPackages([]), []);

  const handleFieldSelectionMethodChange = useCallback(
    (_: any, value: boolean) => {
      setFieldSelectionMethod(
        value
          ? FieldSelectionMethod.FIELD_ID
          : FieldSelectionMethod.FULL_FIELD_PATH
      );
    },
    []
  );

  return (
    <Stack
      sx={{
        padding: theme.spacing(4, 3, 0),
      }}
      spacing={3}
    >
      <PageHeader
        title="Add linked field"
        actions={
          <Stack direction="row" alignItems="center">
            <Text variant="text2">Full field path</Text>
            <Switch onChange={handleFieldSelectionMethodChange} />
            <Text variant="text2">Field ID</Text>
          </Stack>
        }
      />
      <AddLinkedFieldFilters
        onCheck={handleCheck}
        onRaiseError={handleRaiseError}
        fieldSelectionMethod={fieldSelectionMethod}
        key={fieldSelectionMethod}
      />
      <Stack spacing={3}>
        {!_.isNil(selectedFieldId) && (
          <>
            <Stack spacing={1} marginY={1} direction="row">
              {SYNCING_OPTIONS.map(({ label, value }) => (
                <Button
                  key={value}
                  onClick={() =>
                    bulkSelectSyncingOption({ syncingType: value })
                  }
                >
                  Set all syncing options to {label}
                </Button>
              ))}
            </Stack>

            {dealPackages.map((dealPackage) => (
              <DealPackage
                key={`${dealPackage.deal_id}-${selectedFieldId}-${selectedLinkId}`}
                dealPackage={dealPackage}
                currentFieldId={selectedFieldId}
                onSelectSyncingOption={({ syncingType }) =>
                  handleSelectSyncingOption({
                    syncingType,
                    dealId: dealPackage.deal_id,
                  })
                }
                value={
                  selectedDealPackagesSyncingType[dealPackage.deal_id] || null
                }
              />
            ))}
          </>
        )}
      </Stack>
      {!_.isEmpty(dealPackages) && (
        <Button
          sx={{ alignSelf: "flex-end" }}
          onClick={handleSave}
          disabled={saveButtonIsDisabled}
        >
          Save changes
        </Button>
      )}
    </Stack>
  );
};

export default LinkedFields;

import { useCallback, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";

import Checkboxfield from "entities/Application/components/Fields/Checkboxfield";
import Dropdownfield from "entities/Application/components/Fields/Dropdownfield";
import Filefield from "entities/Application/components/Fields/Filefield";
import FixedTableField from "entities/Application/components/Fields/FixedTableField";
import Radiobuttonfield from "entities/Application/components/Fields/Radiobuttonfield";
import Richtextfield from "entities/Application/components/Fields/Richtextfield";
import Tablefield from "entities/Application/components/Fields/Tablefield";
import Textfield from "entities/Application/components/Fields/Textfield";
import {
  IApplicationField,
  updateApplicationFieldValue,
} from "entities/Application/sdk";
import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import { FieldType } from "entities/Field/sdk";

export interface IFieldWrapper {
  field: IApplicationField;
  editable: boolean;
  applicationId: number;
  onUpdate?: () => void;
}

const FieldWrapper = ({
  field,
  editable,
  applicationId,
  onUpdate,
}: IFieldWrapper) => {
  const [isEditingTextfield, setIsEditingTextfield] = useState(false);

  const handleSave = useCallback(
    ({
      field,
      value,
      table_value,
      withToast = true,
    }: {
      field: IApplicationField;
      value?: string | number | null;
      table_value?: string | null;
      withToast?: boolean;
    }) => {
      if (!_.isUndefined(value)) {
        updateApplicationFieldValue({
          applicationId,
          fieldId: field.company_custom_field_id,
          data: {
            value,
          },
        })
          .then(() => {
            if (field.field_type === FieldType.TEXT_FIELD) {
              setIsEditingTextfield(false);
            }
            if (withToast) {
              toast.successMessage(`${field.name} successfully updated!`);
            }
            onUpdate && onUpdate();
          })
          .catch(handleInvalidRequest);
      } else if (!_.isUndefined(table_value)) {
        updateApplicationFieldValue({
          applicationId,
          fieldId: field.company_custom_field_id,
          data: {
            table_value,
          },
        })
          .then(() => {
            if (withToast) {
              toast.successMessage(`${field.name} successfully updated!`);
            }
            onUpdate && onUpdate();
          })
          .catch(handleInvalidRequest);
      }
    },
    [applicationId, onUpdate]
  );

  if (field.field_type === FieldType.FILE) {
    return (
      <Filefield
        field={field}
        editable={editable}
        applicationId={applicationId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.TABLE) {
    return (
      <Tablefield field={field} editable={editable} onUpdate={handleSave} />
    );
  }

  if (field.field_type === FieldType.FIXED_TABLE) {
    return (
      <FixedTableField
        field={field}
        editable={editable}
        onUpdate={handleSave}
      />
    );
  }

  if (field.field_type === FieldType.RICH_TEXT_FIELD) {
    return (
      <Richtextfield
        field={field}
        editable={editable}
        onUpdate={(args) => handleSave({ ...args, withToast: false })}
      />
    );
  }

  if (
    [
      FieldType.TEXT_FIELD,
      FieldType.CHECKBOX,
      FieldType.RADIO_BUTTON,
      FieldType.DROPDOWN,
    ].includes(field.field_type)
  ) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: theme.spacing(0.5),
          borderBottom: `1px solid ${colors.blue20}`,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Text
            variant="text2"
            sx={{
              fontWeight: 300,
              paddingRight: theme.spacing(2),
            }}
          >
            {field.name}
          </Text>
          <InstructionsDialogButton instructions={field?.instruction} />
        </Stack>
        {field.field_type === FieldType.TEXT_FIELD && (
          <Textfield
            editable={editable}
            field={field}
            onUpdate={handleSave}
            isEditing={isEditingTextfield}
            setIsEditing={setIsEditingTextfield}
          />
        )}
        {field.field_type === FieldType.CHECKBOX && (
          <Checkboxfield
            editable={editable}
            field={field}
            onUpdate={handleSave}
          />
        )}
        {field.field_type === FieldType.RADIO_BUTTON && (
          <Radiobuttonfield
            editable={editable}
            field={field}
            onUpdate={handleSave}
          />
        )}
        {field.field_type === FieldType.DROPDOWN && (
          <Dropdownfield
            editable={editable}
            field={field}
            onUpdate={handleSave}
          />
        )}
      </Stack>
    );
  }
  return null;
};

export default FieldWrapper;

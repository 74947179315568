import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { handleInvalidRequest } from "utils/sdk";

import { EFeatureLimitItemsType } from "components/FeatureLimitDialog/constants";
import { useFeatureDisabledDialog } from "components/useFeatureDisabledDialog";
import { useFeatureLimitDialog } from "components/useFeatureLimitDialog";

import { useSignedUser } from "entities/Auth/sdk";
import { companySettingsFeatureLimitCheck } from "entities/Company/sdk";
import { useDealDetails } from "entities/Deal/sdk";

import {
  DEAL_COMPANY_SETTINGS_FEATURES,
  ECompanySettingsFeatureType,
} from "./constants";

export const useCheckFeature = ({
  feature,
  callback,
}: {
  feature: ECompanySettingsFeatureType;
  callback: (...args: any) => void;
}) => {
  const params = useParams<{
    dealId?: string;
  }>();

  const dealId = useMemo(
    () =>
      !_.isUndefined(params.dealId) &&
      _.includes(DEAL_COMPANY_SETTINGS_FEATURES, feature)
        ? Number(params.dealId)
        : undefined,
    [params.dealId, feature]
  );

  const { data: deal } = useDealDetails({ dealId });
  const { data: user } = useSignedUser();

  const isDealOwner = useMemo(
    () => deal?.company.id === user?.company_id,
    [deal, user]
  );

  const { show: showFeatureDisabledDialog } = useFeatureDisabledDialog();
  const { show: showFeatureLimitDialog } = useFeatureLimitDialog();

  const response = useCallback(
    (...args) =>
      companySettingsFeatureLimitCheck({
        featureType: feature,
        dealId,
      })
        .then(({ has_access }) => {
          if (has_access) {
            callback(...args);
          } else {
            if (feature === ECompanySettingsFeatureType.MAX_DEAL_COUNT) {
              showFeatureLimitDialog({
                isDealOwner: true,
                items: EFeatureLimitItemsType.DEALS,
              });
            } else if (
              feature ===
              ECompanySettingsFeatureType.MAX_AI_SUMMARY_DOCUMENT_COUNT
            ) {
              showFeatureLimitDialog({
                isDealOwner: true,
                items: EFeatureLimitItemsType.AI_SUMMARIES,
              });
            } else if (
              _.includes(DEAL_COMPANY_SETTINGS_FEATURES, feature) &&
              !isDealOwner
            ) {
              showFeatureDisabledDialog({
                isDealFeature: true,
                dealCompanyName: deal?.company.name || "",
              });
            } else {
              showFeatureDisabledDialog({});
            }
          }
        })
        .catch(handleInvalidRequest),
    [
      feature,
      deal,
      dealId,
      isDealOwner,
      callback,
      showFeatureDisabledDialog,
      showFeatureLimitDialog,
    ]
  );

  return response;
};

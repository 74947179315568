import * as Sentry from "@sentry/react";
import ky from "ky";

import { BASE_URL } from "config/urls";
import { getConfig, post } from "utils/sdk";

import toast from "components/Toast";

import { ISnapshot } from "entities/Proforma/sdk";

export interface IFile {
  id: number;
  file_name: string;
  file_type: string;
  original_file_name: string;
  openai_files?: Array<{
    openai_document_assistant: {
      id: number;
      openai_prompt_package: string;
      prompt_response_count: number;
    };
  }>;
}

interface IDirectUploadResponse {
  id: number;
  url: string;
  fields: { [key: string]: string };
}

const directUploadStart = ({
  fileName,
  fileType,
  isPrivate = false,
}: {
  fileName: string;
  fileType: string;
  isPrivate: boolean;
}): Promise<IDirectUploadResponse> =>
  post(`${BASE_URL}v2/direct-upload/start/`, {
    file_name: fileName,
    file_type: fileType,
    is_private: isPrivate,
  });

const directUploadDo = ({
  data,
  file,
}: {
  data: IDirectUploadResponse;
  file: File;
}) => {
  const postData = new FormData();

  for (const key in data?.fields) {
    postData.append(key, data.fields[key]);
  }

  postData.append("file", file);

  let postParams: any = {
    body: postData,
    timeout: false,
  };

  if (!data?.fields) {
    // local upload
    postParams = {
      ...postParams,
      ...getConfig({
        isFileUpload: true,
      }),
    };
  }

  return ky
    .post(data.url, postParams)
    .then(() => Promise.resolve({ fileId: data.id }));
};

const directUploadFinish = ({ data }: { data: any }) =>
  post(`${BASE_URL}v2/direct-upload/finish/`, {
    file_id: data.id,
  });

/**
 * This is a Direct Upload to s3 function.
 * Usage:
 *
 * upload({file}).then((id) => updateAvatarFile(id))
 *
 */
export const upload = ({
  file,
  isPrivate = false,
}: {
  file: File;
  isPrivate: boolean;
}) => {
  let fileType = file.type;

  if (file.name.endsWith(".xlsm")) {
    // Workaround for an ongoing issue with Chrome.
    fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }

  return directUploadStart({
    fileName: file.name,
    fileType,
    isPrivate,
  })
    .then((response: IDirectUploadResponse) => {
      const onUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", onUnload);

      // When the file upload is completed, send a new request to attach the file to object
      return directUploadDo({ data: response, file })
        .then(() => directUploadFinish({ data: response }))
        .then(() => {
          window.removeEventListener("beforeunload", onUnload);
          return Promise.resolve(response.id);
        });
    })
    .catch((error) => {
      toast.errorMessage("File upload failed!");

      Sentry.captureMessage("File upload failed!", (scope) => {
        scope.setExtra("error", error);
        return scope;
      });

      return Promise.reject();
    });
};

export interface IUploadedFile {
  id: number;
  file_url?: string;
  original_file_name: string | null;
  created_at: string;
  uploaded_by: {
    first_name: string;
    last_name: string;
  };
  snapshot: ISnapshot | null;
  is_current: boolean;
}

import { useLayoutEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { scrollToSelectedAnchor } from "utils/common";

import { ID as SUB_NAVIGATION_ID } from "components/DealPageLayout/SubNavigation";
import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";

import { useDealChecklistPermission } from "entities/Deal/sdk";
import Checkboxfield from "entities/Field/components/Checkboxfield";
import Dropdownfield from "entities/Field/components/Dropdownfield";
import Filefield from "entities/Field/components/Filefield";
import FixedTableField from "entities/Field/components/FixedTableField";
import FormTextField from "entities/Field/components/FormTextField";
import Radiobuttonfield from "entities/Field/components/Radiobuttonfield";
import Richtextfield from "entities/Field/components/Richtextfield";
import Tablefield from "entities/Field/components/Tablefield";
import Textfield from "entities/Field/components/Textfield";
import { FieldType } from "entities/Field/sdk";
import { IPackageField, usePackageDealDetails } from "entities/Package/sdk";
import { useTaskChannels } from "entities/Task/sdk";

import { convertPackageFieldToField } from "./utils";

const ANCHORS = [MAIN_NAVIGATION_ID, SUB_NAVIGATION_ID];

export interface IFieldWrapper {
  field: IPackageField;
  categoryId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
}

const FieldWrapper = ({
  categoryId,
  sectionId,
  field: propsField,
  onUpdate,
}: IFieldWrapper) => {
  const params = useParams<{
    dealId: string;
    packageId: string;
    tabId: string;
  }>();
  const dealId = Number(params.dealId);
  const packageId = Number(params.packageId);
  const tabId = Number(params.tabId);

  const [searchParams] = useSearchParams();

  const selectedFieldId = searchParams.get("fieldId")
    ? Number(searchParams.get("fieldId"))
    : null;

  const { data: taskChannels } = useTaskChannels({ dealId });

  const queryParams = useMemo(() => {
    if (!_.isNil(params.tabId)) {
      return { tab_id: params.tabId };
    }
    return undefined;
  }, [params.tabId]);

  const { mutate: updatePackage } = usePackageDealDetails({
    packageId,
    dealId,
    queryParams,
  });

  const { data: userPermission } = useDealChecklistPermission({
    dealId,
    packageId,
  });

  const tasksForField = useMemo(
    () =>
      taskChannels?.filter(
        (taskChannel) =>
          taskChannel.task.company_category?.id === categoryId &&
          taskChannel.task.company_section?.id === sectionId &&
          taskChannel.task.company_field?.id === propsField.field_id
      ) || [],
    [categoryId, sectionId, propsField, taskChannels]
  );

  const field = useMemo(
    () => convertPackageFieldToField({ field: propsField }),
    [propsField]
  );

  const hasEditAccess = useMemo(
    () =>
      !_.isNil(userPermission) &&
      (userPermission.has_write_access || userPermission.is_full_access),
    [userPermission]
  );

  useLayoutEffect(() => {
    // If we have the same fields in different sections, we will scroll to the first of them
    if (
      !_.isNil(selectedFieldId) &&
      selectedFieldId.toString() === field.field_id.toString()
    ) {
      requestAnimationFrame(() => {
        scrollToSelectedAnchor({
          anchor: selectedFieldId.toString(),
          navigationIds: ANCHORS,
        });
      });
    }
  }, [selectedFieldId, field]);

  if (field.field_type === FieldType.TEXT_FIELD) {
    return (
      <Textfield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        dealId={dealId}
        packageId={packageId}
        categoryId={categoryId}
        field={field}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.FORM_TEXT_FIELD) {
    return (
      <FormTextField
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        dealId={dealId}
        packageId={packageId}
        categoryId={categoryId}
        field={field}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.FILE) {
    return (
      <Filefield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        dealId={dealId}
        packageId={packageId}
        field={field}
        categoryId={categoryId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.DROPDOWN) {
    return (
      <Dropdownfield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        field={field}
        packageId={packageId}
        categoryId={categoryId}
        dealId={dealId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.CHECKBOX) {
    return (
      <Checkboxfield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        field={field}
        packageId={packageId}
        categoryId={categoryId}
        dealId={dealId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.RADIO_BUTTON) {
    return (
      <Radiobuttonfield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        field={field}
        packageId={packageId}
        categoryId={categoryId}
        dealId={dealId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.RICH_TEXT_FIELD) {
    return (
      <Richtextfield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        field={field}
        packageId={packageId}
        categoryId={categoryId}
        dealId={dealId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.TABLE) {
    return (
      <Tablefield
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        field={field}
        packageId={packageId}
        categoryId={categoryId}
        dealId={dealId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  if (field.field_type === FieldType.FIXED_TABLE) {
    return (
      <FixedTableField
        isFocused={selectedFieldId === field.field_id}
        editable={hasEditAccess}
        taskChannels={tasksForField}
        updatePackage={updatePackage}
        field={field}
        packageId={packageId}
        categoryId={categoryId}
        dealId={dealId}
        sectionId={sectionId}
        tabId={tabId}
        onUpdate={onUpdate}
      />
    );
  }

  return null;
};

export default FieldWrapper;

import { Theme as MuiThemeType } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";

import {
  CELL_DEFAULT_WIDTH,
  CellAlignType,
  SPACING_AFTER_STICKY_COLUMN,
  SPACING_BETWEEN_COLUMNS,
} from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import { toNumber } from "components/ScrollableTable/TableHeaders/utils";

export const getRowStyles = ({
  sticky,
  width = "110px",
  left = 0,
  justifyContent = CellAlignType.SPACE_BETWEEN,
  hasChildren = false,
  isChild = false,
  noCellPadding = false,
}: {
  sticky?: boolean;
  width?: string;
  left?: number;
  justifyContent?: CellAlignType;
  hasChildren?: boolean;
  isChild?: boolean;
  noCellPadding?: boolean;
}): SxProps<MuiThemeType> => {
  if (sticky) {
    return {
      padding: noCellPadding ? 0 : theme.spacing(0.625, 2),
      paddingLeft: noCellPadding
        ? 0
        : isChild
          ? theme.spacing(5)
          : theme.spacing(2),
      borderBottom: `1px solid ${colors.blue60}`,
      flexBasis: width,
      width: width,
      flexGrow: 0,
      flexShrink: 0,
      overflow: "hidden",
      position: "sticky",
      display: "flex",
      backgroundColor: colors.gray10,
      left: left,
      cursor: hasChildren ? "pointer" : "default",
      justifyContent: justifyContent,
      zIndex: 2, // This is to hide non-sticky cells behind sticky cells
    };
  }
  return {
    padding: noCellPadding ? 0 : theme.spacing(0.625, 2),
    borderBottom: `1px solid ${colors.blue60}`,
    flexBasis: width,
    width: width,
    flexGrow: 0,
    flexShrink: 0,
    overflow: "hidden",
    display: "flex",
    justifyContent: justifyContent,
    alignItems: "center",
    "&:last-child": {
      borderRight: 0,
      flexGrow: 1,
    },
    "&:hover": {
      backgroundColor: colors.gray5,
    },
  };
};

const isDynamicColumn = ({ headers }: { headers: IScrollableTableHeader }) =>
  ["", "-"].includes(headers[headers.length - 1].label);

export const showScrollableButton = ({
  tableWidth,
  tableScrollWidth,
}: {
  tableWidth: number;
  tableScrollWidth: number;
}) => tableWidth < tableScrollWidth;

export const calculateLastColumnWidth = ({
  headers,
  tableWidth,
}: {
  headers: IScrollableTableHeader;
  tableWidth: number;
}) => {
  const headersWidths: number[] = headers.flatMap((header, index) => {
    let columnWidth = 0;
    /*
       if it's the first column we add SPACING_AFTER_STICKY_COLUMN
       if it isn't the last column and this is not the dynamic hyphen column we add SPACING_BETWEEN_COLUMNS
     */
    if (header.sticky) {
      columnWidth += toNumber(SPACING_AFTER_STICKY_COLUMN);
    } else if (headers.length > index && !["", "-"].includes(header.label)) {
      columnWidth += toNumber(SPACING_BETWEEN_COLUMNS);
    }

    header.children.map((child) => {
      if (child.width) {
        columnWidth += toNumber(child.width);
      } else {
        columnWidth += toNumber(CELL_DEFAULT_WIDTH);
      }

      return columnWidth;
    });

    return columnWidth;
  });

  return tableWidth - _.sum(headersWidths);
};

export const calculateHeadersBasedOnWidth = ({
  headers,
  dynamicHyphen,
  tableWidth,
  tableScrollWidth,
}: {
  headers: IScrollableTableHeader;
  dynamicHyphen: boolean | undefined;
  tableWidth: number;
  tableScrollWidth: number;
}) => {
  if (
    dynamicHyphen &&
    isDynamicColumn({ headers }) &&
    showScrollableButton({ tableWidth, tableScrollWidth })
  ) {
    return headers.slice(0, -1);
  }
  return headers;
};

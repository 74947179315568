import { useEffect } from "react";

const useWindowScrollHide = ({ hideScroll }: { hideScroll: boolean }) => {
  useEffect(() => {
    if (hideScroll) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [hideScroll]);
};

export default useWindowScrollHide;

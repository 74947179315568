import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Grid from "components/Grid";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";

import ChecklistInfoBox from "entities/Checklist/components/ChecklistInfoBox";
import LinkedFieldInfoBox from "entities/Field/components/LinkedFieldInfoBox";

export interface ILinkedFieldRow {
  checklistName: string;
  checklistSubTitle: string;
  url?: string;
  linkedFieldName: string;
  linkedFieldsubTitle: string | string[];
  isOverridden: boolean;
}

export interface IConnections {
  isOverridden: boolean;
  connections: Array<ILinkedFieldRow>;
  showLoader: boolean;
}

const Connections = ({
  isOverridden,
  connections,
  showLoader,
}: IConnections) => {
  return (
    <Box>
      {showLoader ? (
        <Skeleton width="100px" variant="text" />
      ) : (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          marginTop={theme.spacing(2)}
        >
          <Text variant="text2" color={colors.gray80} fontWeight={500}>
            {connections.length} connections
          </Text>
        </Stack>
      )}
      <Grid
        container
        maxWidth="100%"
        marginTop={theme.spacing(1)}
        marginBottom={theme.spacing(2)}
      >
        <Grid item xs={6}>
          <Text
            variant="label"
            color={colors.gray80}
            fontWeight={500}
            flex={1}
            sx={{ opacity: 0.5 }}
          >
            Location
          </Text>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: theme.spacing(2) }}>
          <Text
            variant="label"
            color={colors.gray80}
            fontWeight={500}
            flex={1}
            sx={{ opacity: 0.5 }}
          >
            Field
          </Text>
        </Grid>
      </Grid>
      <Stack spacing={2} marginTop={theme.spacing(1.5)}>
        {showLoader && (
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Skeleton width="100%" height="80px" />
            <Skeleton width="100%" height="80px" />
          </Stack>
        )}
        {!showLoader &&
          connections.map((linkedField, index) => (
            <Grid container key={index} maxWidth="100%">
              <Grid item xs={6}>
                <ChecklistInfoBox
                  title={linkedField.checklistName}
                  subTitle={linkedField.checklistSubTitle}
                  url={linkedField.url}
                  disabled={isOverridden || linkedField.isOverridden}
                />
              </Grid>
              <Grid item xs={6} sx={{ paddingLeft: theme.spacing(2) }}>
                <LinkedFieldInfoBox
                  title={linkedField.linkedFieldName}
                  subTitle={linkedField.linkedFieldsubTitle}
                  showConnectionsDisabledIcon={linkedField.isOverridden}
                />
              </Grid>
            </Grid>
          ))}
      </Stack>
    </Box>
  );
};

export default Connections;

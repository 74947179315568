import { useCallback } from "react";
import _ from "lodash";

import { ArrowDropDownIcon } from "icons";
import { columnIsSortedByNumbers, sortListBy } from "utils/common";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import { useFilters } from "components/useFilters";
import { ORDER_BY_COLUMN_FILTER_KEY } from "components/useFilters/constants";

import { exportPipelineAsXLSX } from "entities/Deal/components/ExportButton/utils";
import {
  getFilteredColumns,
  getHeaders,
} from "entities/Deal/components/ListTable/utils";
import { SEARCH_KEYS } from "entities/Deal/constants";
import { IPipelineColumns } from "entities/Deal/sdk";
import { useReportPackageFilterList } from "entities/Reporting/sdk";
import { getFilteredDeals } from "entities/Reporting/utils";

export interface IExportButton {
  rows: { [key: string]: string | null }[];
  columns: Array<IPipelineColumns>;
  disabled?: boolean;
}

const ExportButton = ({ rows, columns, disabled = false }: IExportButton) => {
  const { filters } = useFilters();
  const { data: filterDict } = useReportPackageFilterList({
    report_package_id: null,
  });

  const handleExport = useCallback(() => {
    const selectedOrderOption = _.get(filters, ORDER_BY_COLUMN_FILTER_KEY);

    const filteredColumns = getFilteredColumns({ columns });
    const headers = getHeaders(filteredColumns);

    const filteredDeals = getFilteredDeals({
      rows,
      selectedFilters: filters,
      filterDict,
      searchKeys: SEARCH_KEYS,
    });

    const isOrderedByNumbers = columnIsSortedByNumbers({
      selectedSortOption: selectedOrderOption,
      data: filteredDeals,
    });

    const orderedDeals = sortListBy(
      filteredDeals,
      selectedOrderOption,
      isOrderedByNumbers
    );

    exportPipelineAsXLSX({
      headers,
      rows: orderedDeals,
    });
  }, [columns, rows, filters, filterDict]);

  return (
    <ButtonDropdown
      label="Export"
      variant="outlined"
      icon={<ArrowDropDownIcon />}
      disabled={disabled}
    >
      <GroupLabel label="Export data as" />
      <MenuItem onClick={handleExport}>Download Excel file</MenuItem>
    </ButtonDropdown>
  );
};

export default ExportButton;

import React from "react";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Box from "components/Box";
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import UserEditForm from "entities/User/components/EditForm";

export interface IEditSignedUserDialog {
  open: boolean;
  handleClose: () => void;
  onUpdated: () => void;
}

const EditSignedUserDialog: React.FC<IEditSignedUserDialog> = ({
  open,
  handleClose,
  onUpdated,
}) => {
  const { data: user } = useSignedUser();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box>
        <Stack alignItems="center" data-testid="edit-user-profile-dialog">
          <Paper
            sx={{
              paddingTop: theme.spacing(8),
              paddingBottom: theme.spacing(8),
            }}
          >
            <Stack spacing={4}>
              <Stack direction="row" spacing={1}>
                <EditIcon
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
                <Text variant="h1" style={{ display: "flex" }}>
                  User Profile
                </Text>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  borderTop: `1px solid ${colors.blue20}`,
                  borderBottom: `1px solid ${colors.blue20}`,
                  padding: theme.spacing(1, 0),
                }}
              >
                <Text variant="labelSmall" style={{ color: colors.gray40 }}>
                  email
                </Text>
                <Text variant="text3" data-testid="user-email">
                  {user?.email}
                </Text>
              </Stack>
              <UserEditForm handleCancel={handleClose} onUpdated={onUpdated} />
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default EditSignedUserDialog;

import { useMemo } from "react";
import _ from "lodash";

import { URLS } from "config/urls";
import { LaunchIcon } from "icons";
import { colors } from "theme/palette";
import { isNotUndefined } from "utils/common";
import { formatGetParams, reverse } from "utils/urls";

import { Link } from "components/Link";
import { TableCell } from "components/Table";
import Tooltip from "components/Tooltip";

interface IChecklistLinkCell {
  dealId: number;
  companyPackage: { id: number; name: string } | null;
  companyTab: { id: number; name: string } | null;
  companyCategory: { id: number; name: string } | null;
  companySection: { id: number; name: string } | null;
  companyField: { id: number; name: string } | null;
}

const ChecklistLinkCell = ({
  dealId,
  companyPackage,
  companyTab,
  companyCategory,
  companySection,
  companyField,
}: IChecklistLinkCell) => {
  const pathToItem = useMemo(
    () =>
      [
        companyPackage?.name,
        companyTab?.name,
        companyCategory?.name,
        companySection?.name,
        companyField?.name,
      ]
        .filter(isNotUndefined)
        .join(" > "),
    [companyPackage, companyCategory, companySection, companyField, companyTab]
  );

  const linkToItem = useMemo(() => {
    if (
      !_.isNil(companyPackage) &&
      !_.isNil(companyTab) &&
      !_.isNil(companyCategory) &&
      !_.isNil(companySection) &&
      !_.isNil(companyField)
    ) {
      return `${reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId: companyPackage?.id,
        tabId: companyTab?.id,
      })}${formatGetParams({
        fieldId: companyField?.id,
      })}`;
    } else if (
      !_.isNil(companyPackage) &&
      !_.isNil(companyTab) &&
      !_.isNil(companyCategory) &&
      !_.isNil(companySection) &&
      _.isNil(companyField)
    ) {
      return `${reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId: companyPackage?.id,
        tabId: companyTab?.id,
      })}${formatGetParams({
        sectionId: companySection?.id,
      })}`;
    } else if (
      !_.isNil(companyPackage) &&
      !_.isNil(companyTab) &&
      !_.isNil(companyCategory) &&
      _.isNil(companySection) &&
      _.isNil(companyField)
    ) {
      return `${reverse(URLS.DEAL_PACKAGE, {
        dealId,
        packageId: companyPackage?.id,
        tabId: companyTab?.id,
      })}${formatGetParams({
        categoryId: companyCategory?.id,
      })}`;
    }

    return "";
  }, [
    dealId,
    companyPackage,
    companyTab,
    companyCategory,
    companySection,
    companyField,
  ]);

  const showLinkIcon = useMemo(
    () => pathToItem !== "" && linkToItem !== "",
    [pathToItem, linkToItem]
  );

  return (
    <TableCell
      align="center"
      sx={{
        borderRight: `1px solid ${colors.blue40}`,
        borderBottom: `1px solid ${colors.blue40}`,
      }}
    >
      {showLinkIcon && (
        <Tooltip title={pathToItem}>
          <span>
            <Link to={linkToItem}>
              <LaunchIcon
                sx={{ color: colors.gray60, width: "20px", height: "20px" }}
              />
            </Link>
          </span>
        </Tooltip>
      )}
    </TableCell>
  );
};

export default ChecklistLinkCell;

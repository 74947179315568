import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined } from "utils/common";

import Box from "components/Box";

import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IRentalIncomeData } from "entities/Proforma/sdk";

export interface IProformaRentComparisons {
  rentalIncomeData: IRentalIncomeData;
}

const headerRentComparisons: Array<{ key: string; name: string }> = [
  { key: "unittype", name: "Unit Type" },
  { key: "renttype", name: "Rent Type" },
  { key: "units", name: "Units" },
  { key: "netrent", name: "Net Rent" },
  { key: "maxlihtc", name: "Max Lihtc" },
  { key: "procentagebelow", name: "% Below" },
  { key: "fairmarket", name: "Fair Market" },
  { key: "procentagebelow2", name: "% Below" },
];

const ProformaRentComparisons: React.FC<IProformaRentComparisons> = ({
  rentalIncomeData,
}) => {
  const rentComparisonsRows = useMemo(
    () =>
      _.orderBy(
        _.map(
          rentalIncomeData.rental_data.lihtc_specifications.data,
          (value) => {
            if (value.value !== 0)
              return {
                unittype: value.name,
                renttype: value.rent_cap,
                units: value.numberofunits,
                netrent: formatNumberToString({
                  number: value.monthly_net_lihtc,
                }),
                maxlihtc: formatNumberToString({ number: value.max_lihtc }),
                procentagebelow: formatNumberToString({
                  number: value.percentage_below_max_lihtc * 100,
                }),
                fairmarket: formatNumberToString({
                  number: value.fair_market_rent,
                }),
                procentagebelow2: formatNumberToString({
                  number: value.percentage_below_market * 100,
                }),
                position: value.position,
              };
          }
        ).filter(isNotUndefined),
        "position"
      ),
    [rentalIncomeData]
  );

  return (
    <ProformaPaper title="Rent Comparisons">
      <Box
        sx={{
          margin: theme.spacing(3, 0),
        }}
      >
        <ProformaTable
          rows={rentComparisonsRows}
          headers={headerRentComparisons}
          cellsWidth="100px"
          lastCellWidth="84px"
        />
      </Box>
    </ProformaPaper>
  );
};

export default ProformaRentComparisons;

import _ from "lodash";

import { formatNumberToString } from "utils/common";

import {
  CellAlignType,
  ScrollableTableCellVariant,
} from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";

import { IPLProjection } from "entities/Proforma/sdk";

import TitleCell from "./TitleCell";

export const getTableHeaders = ({
  years,
}: {
  years: number[];
}): IScrollableTableHeader => {
  return [
    {
      label: "-",
      sticky: true,
      children: [
        {
          label: "",
          key: "title",
          width: "300px",
          render: ({
            row,
            key,
            isExpanded,
          }: {
            row: any;
            key: string;
            isExpanded?: boolean;
          }) => <TitleCell row={row} columnKey={key} isExpanded={isExpanded} />,
        },
      ],
    },
    {
      label: "Years",
      children: years.map((year) => ({
        label: String(year),
        key: String(year),
        justifyContent: CellAlignType.FLEX_END,
        variant: ScrollableTableCellVariant.TEXT,
      })),
    },
  ];
};

export const getRevenuesRows = ({
  revenuesData,
}: {
  revenuesData: IPLProjection["p_and_l"]["revenues"];
}) => [
  {
    title: "Total Effective Gross Income (EGI)",
    ..._.mapValues(revenuesData["total_effective_gross_income"], (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Gross Potential Rent",
    ..._.mapValues(
      revenuesData.data["net_rental_income"].data["Gross Potential Rent"],
      (number) => formatNumberToString({ number })
    ),
  },
  {
    title: "Less Losses",
    ..._.mapValues(
      revenuesData.data["net_rental_income"].total_vacancy_dataset,
      (number) => formatNumberToString({ number })
    ),
    children: _.map(
      revenuesData.data["net_rental_income"].vacancy_dataset,
      (value, key) => ({
        title: key,
        ..._.mapValues(value, (number) => formatNumberToString({ number })),
      })
    ),
  },
  {
    title: "Net Rental Income",
    ..._.mapValues(revenuesData.data["net_rental_income"].value, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "All Other Income",
    ..._.mapValues(revenuesData.data["all_other_income"].value, (number) =>
      formatNumberToString({ number })
    ),
    children: _.map(
      revenuesData.data["all_other_income"].data,
      (value, key) => ({
        title: key,
        ..._.mapValues(value, (number) => formatNumberToString({ number })),
      })
    ),
  },
];

export const getExpensesRows = ({
  expensesData,
}: {
  expensesData: IPLProjection["p_and_l"]["expenses"];
}) => [
  {
    title: "Total Gross Expenses",
    ..._.mapValues(expensesData.total_gross_expenses, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Management Fees",
    ..._.mapValues(expensesData.data["management_fees"].value, (number) =>
      formatNumberToString({ number })
    ),
    children: _.map(
      expensesData.data["management_fees"].data,
      (value, key) => ({
        title: key,
        ..._.mapValues(value, (number) => formatNumberToString({ number })),
      })
    ),
  },
  {
    title: "Administration",
    ..._.mapValues(expensesData.data["administration"].value, (number) =>
      formatNumberToString({ number })
    ),
    children: _.map(expensesData.data["administration"].data, (value, key) => ({
      title: key,
      ..._.mapValues(value, (number) => formatNumberToString({ number })),
    })),
  },
  {
    title: "Repair & Maintenance",
    ..._.mapValues(
      expensesData.data["repair_and_maintenance"].value,
      (number) => formatNumberToString({ number })
    ),
    children: _.map(
      expensesData.data["repair_and_maintenance"].data,
      (value, key) => ({
        title: key,
        ..._.mapValues(value, (number) => formatNumberToString({ number })),
      })
    ),
  },
  {
    title: "Utilities",
    ..._.mapValues(expensesData.data["utilities"].value, (number) =>
      formatNumberToString({ number })
    ),
    children: _.map(expensesData.data["utilities"].data, (value, key) => ({
      title: key,
      ..._.mapValues(value, (number) => formatNumberToString({ number })),
    })),
  },
  {
    title: "Taxes",
    ..._.mapValues(expensesData.data["taxes"].value, (number) =>
      formatNumberToString({ number })
    ),
    children: _.map(expensesData.data["taxes"].data, (value, key) => ({
      title: key,
      ..._.mapValues(value, (number) => formatNumberToString({ number })),
    })),
  },
  {
    title: "Insurance",
    ..._.mapValues(expensesData.data["insurance"].value, (number) =>
      formatNumberToString({ number })
    ),
    children: _.map(expensesData.data["insurance"].data, (value, key) => ({
      title: key,
      ..._.mapValues(value, (number) => formatNumberToString({ number })),
    })),
  },
  {
    title: "Total Expenses",
    ..._.mapValues(expensesData.total_expenses, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Total Replacement Reserves",
    ..._.mapValues(expensesData.total_replacement_reserves, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Total Gross Expenses",
    ..._.mapValues(expensesData.total_gross_expenses, (number) =>
      formatNumberToString({ number })
    ),
  },
];

export const getMetricsRows = ({
  metricsData,
}: {
  metricsData: IPLProjection["p_and_l"]["metrics"];
}) => [
  {
    title: "Debt Service",
    ..._.mapValues(metricsData.ds, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Net Operating Income (NOI)",
    ..._.mapValues(metricsData.noi, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Net Cashflow",
    ..._.mapValues(metricsData.net_cash_flow, (number) =>
      formatNumberToString({ number })
    ),
  },
  {
    title: "Debt Service Coverage Ratio (DSCR)",
    ..._.mapValues(metricsData.dscr, (num) => `1:${num}`),
  },
  {
    title: "Operating Expense Ratio (OER)",
    ..._.mapValues(metricsData.oer),
  },
  {
    title: "Breakeven Occupancy Ratio",
    ..._.mapValues(metricsData.bor),
  },
];

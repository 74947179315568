import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

export enum DueDiligenceTabChoices {
  CHECKLIST_ITEMS = "checklist_items",
  SHARE_SETTINGS = "share_settings",
  ALL_FILES = "all_files",
  AI_SUMMARIES = "ai_summaries",
}

export const TABS: Array<{
  label: string | React.ReactNode;
  value: DueDiligenceTabChoices;
}> = [
  { label: "Checklist items", value: DueDiligenceTabChoices.CHECKLIST_ITEMS },
  {
    label: (
      <Stack direction="row" spacing={1} alignItems="center">
        <Text variant="text2" fontWeight={500}>
          All files
        </Text>
        <Tag label="ZIP" variant="gray60" dataTestid="dd-zip-download-label" />
      </Stack>
    ),
    value: DueDiligenceTabChoices.ALL_FILES,
  },
  { label: "Share settings", value: DueDiligenceTabChoices.SHARE_SETTINGS },
];

import React, { useEffect, useState } from "react";
import ReactDomServer from "react-dom/server";

import InfoWindow, { IInfoWindow } from "components/Map/InfoWindow";

const Marker: React.FC<
  google.maps.MarkerOptions & {
    markerInfo: IInfoWindow["marker"];
    showButton?: boolean;
  }
> = ({ markerInfo, showButton = true, ...options }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      const infowindow = new google.maps.InfoWindow({
        content: ReactDomServer.renderToString(
          <InfoWindow marker={markerInfo} showButton={showButton} />
        ),
      });

      marker.setOptions(options);

      marker.addListener("click", () => {
        infowindow.open(options.map, marker);
      });
    }
  }, [marker, options, markerInfo, showButton]);

  return null;
};

export default Marker;

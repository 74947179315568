import React from "react";

import { CheckCircleIcon, DeleteIcon } from "icons";
import theme from "theme";

import IconButton from "components/IconButton";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import { INote } from "entities/Note/sdk";

interface ICompletedNote {
  note: INote;
  changeCompletedStatus: (note: INote) => void;
  onDelete: (note: INote) => void;
}

const CompletedNote: React.FC<ICompletedNote> = ({
  note,
  changeCompletedStatus,
  onDelete,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        margin: 0,
        padding: theme.spacing(1, 2),
        opacity: 0.7,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <CheckCircleIcon
          sx={{ cursor: "pointer" }}
          onClick={() => changeCompletedStatus(note)}
        />
        <Text variant="text2" sx={{ flex: 1 }}>
          {note.title}
        </Text>
        <IconButton onClick={() => onDelete(note)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default CompletedNote;

import React from "react";

import { colors } from "theme/palette";
import { TABLE_CELL_STYLES, TABLE_HEADER_STYLES } from "utils/constants";
import { format, FORMATS, parse } from "utils/datetime";

import Text from "components/Text";

interface IKPITable {
  dealsCreatedByMonth: {
    month: string;
    deal_count: number;
  }[];
}

const KPITable: React.FC<IKPITable> = ({ dealsCreatedByMonth }) => (
  <table>
    <thead>
      <tr>
        <td
          style={{
            ...TABLE_HEADER_STYLES,
            position: "sticky",
            left: 0,
          }}
        >
          <Text
            variant="labelSmall"
            fontWeight={500}
            sx={{ color: colors.gray60 }}
          >
            Date
          </Text>
        </td>
        {dealsCreatedByMonth.map((data, index) => (
          <td key={index} align="center" style={TABLE_HEADER_STYLES}>
            <Text variant="labelSmall" fontWeight={500}>
              {format(
                parse(data.month, FORMATS.BACKEND.DATE, new Date()),
                "MMM yyyy"
              )}
            </Text>
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          style={{
            ...TABLE_CELL_STYLES,
            position: "sticky",
            left: 0,
            backgroundColor: colors.white,
          }}
        >
          <Text variant="labelSmall" sx={{ color: colors.gray60 }}>
            Total Deals
          </Text>
        </td>
        {dealsCreatedByMonth.map((data, index) => (
          <td key={index} align="center" style={TABLE_CELL_STYLES}>
            <Text variant="text2">{data.deal_count}</Text>
          </td>
        ))}
      </tr>
    </tbody>
  </table>
);

export default KPITable;

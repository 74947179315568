import React from "react";

import Stack from "components/Stack";

import AmenityTable from "entities/Deal/components/OverviewSections/AmenityTable";
import { IDealCommunityAmenities } from "entities/Deal/sdk";

interface ICommunityAmenitiesTable {
  community_amenities_data: IDealCommunityAmenities;
  tableDataTestid?: string;
}

const CommunityAmenitiesTable: React.FC<ICommunityAmenitiesTable> = ({
  community_amenities_data,
  tableDataTestid,
}) => (
  <Stack spacing={3} data-testid={tableDataTestid}>
    <Stack direction="row" spacing={3} alignItems="flex-start">
      <AmenityTable
        header="Security &amp; community services"
        data={community_amenities_data.front_desk_and_security}
        categoryTableDataTestid="community-amenities-category-table"
      />
      <AmenityTable
        header="Community facilities"
        data={community_amenities_data.recreational_spaces}
        categoryTableDataTestid="community-amenities-category-table"
      />
    </Stack>
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <AmenityTable
        header="Parking &amp; bicycle storage"
        data={community_amenities_data.storage}
        categoryTableDataTestid="community-amenities-category-table"
      />
      <AmenityTable
        header="Accreditations"
        data={community_amenities_data.accreditations}
        categoryTableDataTestid="community-amenities-category-table"
      />
    </Stack>
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <AmenityTable
        header="Energy"
        data={community_amenities_data.energy}
        categoryTableDataTestid="community-amenities-category-table"
      />
      <AmenityTable
        header="Others"
        data={community_amenities_data.other}
        categoryTableDataTestid="community-amenities-category-table"
      />
    </Stack>
  </Stack>
);

export default CommunityAmenitiesTable;

import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import InputAdornment from "components/InputAdornment";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import {
  bookmarkCreate,
  bookmarkUpdate,
  IBookmark,
  useBookmarks,
} from "entities/Bookmark/sdk";

import { VALIDATION_SCHEMA } from "./constants";

export interface IFormDialog {
  isOpen: boolean;
  onClose: () => void;
  bookmark?: IBookmark | null;
}

export interface ICreateFormValues {
  name: string;
  pathname: string;
}

export interface IUpdateFormValues {
  name: string;
  bookmarkId: number;
}

const FormDialog = ({ isOpen, onClose, bookmark }: IFormDialog) => {
  const isUpdate = !_.isUndefined(bookmark);

  const location = useLocation();
  const { mutate: refetchBookmarks } = useBookmarks();

  const handleSubmit = useCallback(
    (values, formik) => {
      const sdkMethod = isUpdate ? bookmarkUpdate : bookmarkCreate;

      sdkMethod(values)
        .then(() => {
          onClose();
          formik.resetForm();
          refetchBookmarks();
          toast.successMessage(
            `Bookmark ${isUpdate ? "updated" : "created"} successfully`
          );
        })
        .catch(handleInvalidRequest);
    },
    [onClose, refetchBookmarks, isUpdate]
  );

  const initialValues = useMemo(() => {
    const pathname = `${location.pathname}${location.search}`;

    if (isUpdate) {
      return {
        name: _.get(bookmark, "name", ""),
        bookmarkId: _.get(bookmark, "id", -1),
        pathname,
      };
    }

    return { name: "", pathname };
  }, [location, bookmark, isUpdate]);

  const formik = useFormik<IUpdateFormValues | ICreateFormValues>({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [onClose, formik]);

  const domain = useMemo(
    () => window.location.href.split(location.pathname)[0],
    [location.pathname]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle
        sx={{
          margin: theme.spacing(2, 2, 0, 2),
          padding: 2,
          borderRadius: "8px",
          backgroundColor: colors.blue10,
        }}
      >
        <Text variant="h2">
          {isUpdate ? "Edit bookmark" : "+ Create new bookmark"}
        </Text>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ width: "600px" }}>
          <Text variant="label" fontWeight={500} marginBottom={1}>
            BOOKMARK NAME
          </Text>
          <TextField
            variant="outlined"
            sx={{ width: "100%" }}
            placeholder="e.g. Pinetree Apartments: Northbank Closing Checklist"
            {...formik.getFieldProps("name")}
            error={formik.touched.name && !!formik.errors.name}
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
          />
          <Text variant="label" fontWeight={500} marginBottom={1} marginTop={2}>
            PAGE URL
          </Text>
          <TextField
            disabled
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                padding: 0,
              },
            }}
            {...formik.getFieldProps("pathname")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  variant="standard"
                  position="start"
                  sx={{
                    marginRight: 0,
                    paddingLeft: 2,
                  }}
                >
                  {domain}
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>

        <DialogActions sx={{ padding: theme.spacing(2) }}>
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
          <Button type="submit">
            {isUpdate ? "Save changes" : "Create bookmark"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default FormDialog;

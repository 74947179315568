import _ from "lodash";

import DealNameCell from "components/ScrollableTable/Cells/DealNameCell";
import {
  DEAL_ID,
  DEAL_NAME,
  DEAL_NAME_COLUMN,
  IS_PARTIALLY_ACCESSIBLE,
} from "components/ScrollableTable/Cells/DealNameCell/constants";
import TextCell from "components/ScrollableTable/Cells/TextCell";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";
import Tag from "components/Tag";

import DealSidepanelPreviewButton from "entities/Deal/components/DealSidepanelPreviewButton";
import { IListTable } from "entities/Deal/components/ListTable";
import { FIXED_COLUMNS } from "entities/Deal/components/ListTable/constants";
import { OwnershipType } from "entities/Deal/constants";
import {
  getConstructionTypeLabelByValue,
  getPropertyTypeLabelByValue,
} from "entities/Deal/utils";
import {
  ASSET_TYPE_COLUMN_KEY,
  CONSTRUCTION_TYPE_COLUMN_KEY,
  EFieldFormatType,
  OWNERSHIP_TYPE_COLUMN_KEY,
  PROPERTY_TYPE_COLUMN_KEY,
} from "entities/Reporting/constants";

import { CORE_DATA_FIELD_NAME_TO_READABLE_NAME_MAPPING } from "./constants";

export const getHeaders = (
  columns: {
    children: {
      name: string;
      field_type: string;
      field_width: number;
      field_format_type: EFieldFormatType;
    }[];
    name: string;
  }[]
): IScrollableTableHeader => {
  // First we remove the columns that don't have report_fields attached
  return columns
    .filter((col) => col.children.length > 0)
    .map((col, index) => {
      const sticky = index === 0;

      return {
        label: col.name,
        sticky,
        children: col.children.map((colChild, index) => ({
          label: _.get(
            CORE_DATA_FIELD_NAME_TO_READABLE_NAME_MAPPING,
            colChild.name,
            colChild.name
          ),
          key: colChild.name,
          sortable: true,
          width: `${colChild.field_width}px`,
          left: sticky ? 0 : undefined,
          render: ({ row, key }) => {
            if (key === DEAL_NAME && col.name === DEAL_NAME_COLUMN) {
              return (
                <DealNameCell
                  row={row}
                  actionButtons={
                    row[IS_PARTIALLY_ACCESSIBLE] ? (
                      <></>
                    ) : (
                      <DealSidepanelPreviewButton dealId={row[DEAL_ID]} />
                    )
                  }
                />
              );
            } else if (key.toLowerCase() === OWNERSHIP_TYPE_COLUMN_KEY) {
              const userCompanyIsOwner = row[key] === OwnershipType.OWNER;

              return (
                <Tag
                  label={row[key]}
                  sx={{ display: "inline-block" }}
                  variant={userCompanyIsOwner ? "gray100" : "gray60"}
                />
              );
            } else if (key.toLowerCase() === CONSTRUCTION_TYPE_COLUMN_KEY) {
              const formattedConstructionType = getConstructionTypeLabelByValue(
                { value: row[key] }
              );

              return (
                <TextCell
                  cellData={formattedConstructionType}
                  title={formattedConstructionType}
                  fieldFormatType={colChild.field_format_type}
                />
              );
            } else if (
              key.toLocaleLowerCase() === PROPERTY_TYPE_COLUMN_KEY ||
              key === ASSET_TYPE_COLUMN_KEY
            ) {
              const formattedPropertyType = getPropertyTypeLabelByValue({
                value: row[key],
              });

              return (
                <TextCell
                  cellData={formattedPropertyType}
                  title={formattedPropertyType}
                  fieldFormatType={colChild.field_format_type}
                />
              );
            } else {
              return (
                <TextCell
                  cellData={row[key]}
                  title={row[key]}
                  fieldFormatType={colChild.field_format_type}
                />
              );
            }
          },
        })),
      };
    });
};

export const getFilteredColumns = ({
  columns,
}: {
  columns: IListTable["columns"];
}) => [
  ...FIXED_COLUMNS,
  ...columns.map((col) => ({
    ...col,
    children: col.report_fields.map((field) => {
      if (field.field_type) {
        return { ...field, name: field.field_type };
      }
      return field;
    }),
  })),
  {
    name: "-",
    width: "0px",
    children: [
      {
        name: "",
        field_type: "_",
        field_format_type: EFieldFormatType.STRING,
        field_width: 0,
        render: () => "",
      },
    ],
  },
];

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import _ from "lodash";

import { format, FORMATS, isValid, parseISO } from "utils/datetime";

import DatePicker from "components/DatePicker";
import InputAdornment from "components/InputAdornment";
import TextField from "components/TextField";

import { CompanyFieldFormatType } from "entities/Field/constants";

export enum EditableFieldTriggerEvent {
  ON_BLUR = "onBlur",
  ON_CHANGE = "onChange",
}

export interface IEditableField {
  fieldFormatType: CompanyFieldFormatType;
  value: string | number | null | undefined;
  onChangeValue: (value: string | number | null | undefined) => void;
  setIsSaveButtonDisabled?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  triggerEvent?: EditableFieldTriggerEvent;
}

const EditableField = ({
  fieldFormatType,
  value,
  onChangeValue,
  setIsSaveButtonDisabled,
  disabled = false,
  triggerEvent = EditableFieldTriggerEvent.ON_CHANGE,
  ...formikProps
}: IEditableField) => {
  const [dateFieldValue, setDateFieldValue] = useState<Date | null>(null);
  const [showDatePickerError, setShowDatePickerError] = useState(false);

  const dateValue = useMemo(() => {
    if (fieldFormatType === CompanyFieldFormatType.DATE) {
      if (_.isNil(value)) {
        return null;
      }

      return parseISO(value as string);
    }
  }, [fieldFormatType, value]);

  const isNumber = useMemo(
    () =>
      fieldFormatType === CompanyFieldFormatType.INTEGER ||
      fieldFormatType === CompanyFieldFormatType.CURRENCY_USD ||
      fieldFormatType === CompanyFieldFormatType.FLOAT_2,
    [fieldFormatType]
  );

  const step = useMemo(() => {
    if (
      fieldFormatType === CompanyFieldFormatType.FLOAT_2 ||
      fieldFormatType === CompanyFieldFormatType.CURRENCY_USD
    ) {
      return 0.01;
    } else if (fieldFormatType === CompanyFieldFormatType.INTEGER) {
      return 1;
    }

    return undefined;
  }, [fieldFormatType]);

  const saveDate = useCallback(
    (date: Date | null) => {
      if (isValid(date) && !_.isNull(date)) {
        onChangeValue(format(date, FORMATS.BACKEND.DATE));
      }
    },
    [onChangeValue]
  );

  // When the user selects year, month and day, the onAccept event is triggered.
  // If the triggerEvent is onBlur, we need to save the date.
  const handleAcceptDate = useCallback(
    (date: Date | null) => {
      if (triggerEvent === EditableFieldTriggerEvent.ON_BLUR) {
        saveDate(date);
      }
    },
    [triggerEvent, saveDate]
  );

  // When the user blurs the datepicker, the onBlur event is triggered.
  // The written date is saved in the dateFieldValue.
  // If the triggerEvent is onBlur, we need to save the dateFieldValue.
  const handleBlurDate = useCallback(() => {
    if (triggerEvent === EditableFieldTriggerEvent.ON_BLUR) {
      saveDate(dateFieldValue);
    }
  }, [dateFieldValue, saveDate, triggerEvent]);

  // When the user selects a day or write a date, the onChange event is triggered.
  // Then we set the dateFieldValue with the new valid date.
  // If the triggerEvent is onChange, we need to save the date.
  const handleChangeDate = useCallback(
    (date: Date | null) => {
      if (isValid(date) && !_.isNull(date)) {
        setDateFieldValue(date);
        setShowDatePickerError(false);
        setIsSaveButtonDisabled && setIsSaveButtonDisabled(false);
        if (triggerEvent === EditableFieldTriggerEvent.ON_CHANGE) {
          saveDate(date);
        }
      } else {
        setDateFieldValue(null);
        setShowDatePickerError(true);
        setIsSaveButtonDisabled && setIsSaveButtonDisabled(true);
      }
    },
    [triggerEvent, setIsSaveButtonDisabled, saveDate]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(event.target.value);
    },
    [onChangeValue]
  );

  const showDollarSign = useMemo(
    () => fieldFormatType === CompanyFieldFormatType.CURRENCY_USD,
    [fieldFormatType]
  );

  const helperText = useMemo(() => {
    if (fieldFormatType === CompanyFieldFormatType.INTEGER) {
      return "Please enter a whole number.";
    }

    if (
      fieldFormatType === CompanyFieldFormatType.FLOAT_2 ||
      fieldFormatType === CompanyFieldFormatType.CURRENCY_USD
    ) {
      return "Please enter a number with up to two decimal places.";
    }

    if (
      fieldFormatType === CompanyFieldFormatType.DATE &&
      showDatePickerError
    ) {
      return "The input value is an invalid date.";
    }

    return "";
  }, [fieldFormatType, showDatePickerError]);

  if (fieldFormatType === CompanyFieldFormatType.DATE) {
    return (
      <div data-testid="edit-textfield">
        <DatePicker
          {...formikProps}
          value={dateValue}
          disabled={disabled}
          onChange={handleChangeDate}
          onAccept={handleAcceptDate}
          views={["year", "month", "day"]}
          slotProps={{
            textField: {
              fullWidth: true,
              size: "small",
              error: showDatePickerError,
              helperText: helperText,
              onBlur: handleBlurDate,
              inputProps: {
                "data-testid": "edit-textfield-input",
              },
            },
          }}
        />
      </div>
    );
  }

  return (
    <TextField
      fullWidth
      {...formikProps}
      size="small"
      disabled={disabled}
      type={isNumber ? "number" : "text"}
      value={value}
      onChange={handleChange}
      data-testid="edit-textfield"
      helperText={helperText}
      inputProps={{
        "data-testid": "edit-textfield-input",
        step: step,
        inputMode: isNumber ? "numeric" : "text",
        pattern: isNumber ? "[0-9]*" : undefined,
      }}
      InputProps={{
        startAdornment: showDollarSign && (
          <InputAdornment position="start">$</InputAdornment>
        ),
      }}
    />
  );
};

export default EditableField;

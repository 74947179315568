import React, { useCallback } from "react";

import { DropzoneState } from "components/Dropzone";
import toast from "components/Toast";
import UploadButton from "components/UploadButton";
import {
  IMAGE_ERROR_NOTIFICATION,
  IMAGE_TYPES,
} from "components/UploadButton/constants";
import { defaultDragAndDropZone } from "components/UploadButton/utils";

import { upload } from "entities/File/sdk";

const UploadImage: React.FC<{
  onChange: ({ imageId }: { imageId: number }) => void;
  dataTestid?: string;
}> = ({ onChange, dataTestid }) => {
  const handleChange = useCallback(
    ({ files }: { files: Array<File> }) => {
      const file = files[0];

      /*
        This is a workaround while we rework the backend for this feature.
        Now we don't have a check for the file type in the backend.
      */
      if (IMAGE_TYPES.includes(file.type)) {
        upload({
          file: file,
          isPrivate: false,
        }).then((imageId) => {
          onChange({ imageId });
        });
      } else {
        toast.errorMessage(IMAGE_ERROR_NOTIFICATION);
      }
    },
    [onChange]
  );

  return (
    <UploadButton
      maxFiles={1}
      accept={IMAGE_TYPES.join(",")}
      dataTestid={dataTestid}
      onChange={handleChange}
      renderComponent={(state: Partial<DropzoneState>) =>
        defaultDragAndDropZone(
          state,
          "Drag and drop here, or click to select image"
        )
      }
    />
  );
};

export default UploadImage;

import theme from "theme";

import AvatarDropdownButton from "components/MainNavigation/AvatarDropdownButton";
import Logo from "components/MainNavigation/Logo";
import NavigationMenuList from "components/MainNavigation/NavigationMenuList";
import Paper from "components/Paper";
import Stack from "components/Stack";

import { useSignedUser } from "entities/Auth/sdk";
import BookmarksPopover from "entities/Bookmark/BookmarksPopover";
import NotificationsPopover from "entities/Notification/NotificationsPopover";

// Used for anchoring
export const ID = "main-navigation";

const MainNavigation = () => {
  const { data: user } = useSignedUser();

  return (
    <Paper
      square={true}
      id={ID}
      sx={{
        backgroundColor: "#181833",
        padding: theme.spacing(0, 1.5, 0, 4),
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <Logo />
          <NavigationMenuList />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <BookmarksPopover />
          <NotificationsPopover />
          <AvatarDropdownButton user={user} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MainNavigation;

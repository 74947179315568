import { FormikProps, getIn } from "formik";
import _ from "lodash";

import Stack from "components/Stack";
import TextField from "components/TextField";

import { IFormikParticipant } from "entities/CompanyItegrations/components/Downhome/BusinessForm";
import ListFieldActions from "entities/CompanyItegrations/components/Downhome/BusinessForm/ListFieldActions";

export interface IWebsite {
  formik: FormikProps<IFormikParticipant>;
  onRemove: ({ index }: { index: number }) => void;
  onUpdatePrimary: ({ index }: { index: number }) => void;
}

const Website = ({ formik, onRemove, onUpdatePrimary }: IWebsite) => (
  <>
    {formik.values.websites?.map((website, index) => {
      const formikKey = `websites.${index}.url`;

      return (
        <Stack
          key={index}
          direction="row"
          spacing={1}
          alignItems="start"
          width="100%"
        >
          <TextField
            sx={{ flexBasis: "50%" }}
            label="Website"
            InputLabelProps={{ shrink: true }}
            placeholder="https://www.example.com"
            {...formik.getFieldProps(formikKey)}
            helperText={
              getIn(formik.touched, formikKey) &&
              getIn(formik.errors, formikKey)
            }
            error={
              getIn(formik.touched, formikKey) &&
              !_.isEmpty(getIn(formik.errors, formikKey))
            }
          />
          <ListFieldActions
            key={index}
            checked={website.primary}
            onPrimaryElementUpdate={() => onUpdatePrimary({ index })}
            onElementRemove={() => onRemove({ index })}
          />
        </Stack>
      );
    })}
  </>
);

export default Website;

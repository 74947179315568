import { useCallback, useState } from "react";
import _ from "lodash";

import { ReadyToSubmit } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, { DialogActions, DialogContent } from "components/Dialog";
import Text from "components/Text";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import Tooltip from "components/Tooltip";

import { submitApplication } from "entities/Application/sdk";

export interface IApplicationSubmitConfirmationDialogButton {
  applicationId: number;
  applicationName: string;
  onSubmit: () => void;
  isSubmitButtonDisabled: boolean;
  submitButtonTooltipText: string;
}

const ApplicationSubmitConfirmationDialogButton = ({
  applicationId,
  applicationName,
  onSubmit,
  isSubmitButtonDisabled,
  submitButtonTooltipText,
}: IApplicationSubmitConfirmationDialogButton) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleSubmission = useCallback(() => {
    submitApplication({ applicationId })
      .then(() => {
        onSubmit();
        setIsConfirmationDialogOpen(false);
      })
      .catch(({ message: errorMessages }) => {
        let messages = errorMessages;

        if (!_.isEmpty(messages)) {
          if (!_.isArray(messages)) {
            messages = [messages];
          }
          messages.forEach((message: string) => {
            toast.errorMessage(message);
          });
        } else {
          toast.errorMessage(DEFAULT_ERROR_MESSAGE);
        }
      });
  }, [applicationId, onSubmit]);

  return (
    <>
      <Tooltip title={submitButtonTooltipText}>
        <span>
          <Button
            disabled={isSubmitButtonDisabled}
            onClick={() => setIsConfirmationDialogOpen(true)}
          >
            Submit intake
          </Button>
        </span>
      </Tooltip>

      <Dialog
        open={isConfirmationDialogOpen}
        maxWidth="sm"
        onClose={() => setIsConfirmationDialogOpen(false)}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(4, 5),
          }}
        >
          <ReadyToSubmit />

          <Text variant="h1" marginTop={3} marginBottom={1}>
            Ready to submit?
          </Text>

          <Text variant="text1" color={colors.gray80}>
            Intake name: <b>{applicationName}</b>
          </Text>
          <Text
            variant="text1"
            textAlign="center"
            color={colors.gray80}
            sx={{ marginTop: theme.spacing(3) }}
          >
            Once submitted, this intake can’t be edited any more. Make sure to
            check the filled out information.
          </Text>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", marginBottom: 4 }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setIsConfirmationDialogOpen(false)}
          >
            Go back to editing
          </Button>
          <Button onClick={handleSubmission}>Yes, submit!</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApplicationSubmitConfirmationDialogButton;

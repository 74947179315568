import React from "react";
import _ from "lodash";

import { colors } from "theme/palette";

import Body from "components/FixedTable/Body";
import BodySkeleton from "components/FixedTable/BodySkeleton";
import Headers from "components/FixedTable/Headers";
import Table, { TableContainer } from "components/Table";

export interface IFixedTable {
  rows: Array<{ [key: string]: string }>;
  headers: Array<{
    label: string;
    key: string;
    width?: number;
    render?: (row: any, key: string) => React.ReactNode;
  }>;
  editable?: boolean;
  showDeleteCell?: boolean;
  showEmptyRow?: boolean;
  showLoading?: boolean;
  onUpdated?: (rows: IFixedTable["rows"]) => void;
  tableDataTestid?: string;
}

const FixedTable = ({
  rows,
  headers,
  onUpdated,
  tableDataTestid,
  editable = false,
  showDeleteCell = false,
  showEmptyRow = false,
  showLoading = false,
}: IFixedTable) => {
  return (
    <TableContainer data-testid={tableDataTestid}>
      <Table
        sx={{
          borderTop: `1px solid ${colors.blue40}`,
          borderLeft: `1px solid ${colors.blue40}`,
        }}
        data-testid="table"
      >
        <Headers headers={headers} showDeleteCell={showDeleteCell} />
        {showLoading && _.isEmpty(rows) && <BodySkeleton headers={headers} />}
        <Body
          rows={rows}
          headers={headers}
          editable={editable}
          showDeleteCell={showDeleteCell}
          showEmptyRow={showEmptyRow}
          onUpdated={onUpdated}
        />
      </Table>
    </TableContainer>
  );
};

export default FixedTable;

import { useCallback, useState } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import { MenuItem } from "components/Menu";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { proformaSnapshotCreate } from "entities/Proforma/sdk";

export interface ICreateSnapshotMenuItem {
  dealId: number;
  hasSnapshot: boolean;
  onCreateSnapshot: () => void;
}

const CreateSnapshotMenuItem = ({
  dealId,
  hasSnapshot,
  onCreateSnapshot,
}: ICreateSnapshotMenuItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const [snapshotDescription, setSnapshotDescription] = useState("");

  const handleChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSnapshotDescription(event.target.value),
    [setSnapshotDescription]
  );

  const handleCreateSnapshot = useCallback(
    () =>
      proformaSnapshotCreate({ dealId, description: snapshotDescription })
        .then(() => {
          toast.successMessage("Snapshot created successfully");
          onCreateSnapshot();
          setIsOpen(false);
          setSnapshotDescription("");
        })
        .catch(handleInvalidRequest),
    [dealId, snapshotDescription, onCreateSnapshot]
  );

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleClose = useCallback(() => {
    setSnapshotDescription("");
    setIsOpen(false);
  }, []);

  return (
    <>
      <Tooltip
        arrow
        placement="top"
        title={
          hasSnapshot ? "A snapshot for this proforma already exists." : ""
        }
      >
        <span>
          <MenuItem
            disabled={hasSnapshot}
            onClick={handleOpen}
            dataTestid="proforma-action-option"
          >
            <Text variant="text2" data-testid="action-option-label">
              Save this as snapshot
            </Text>
          </MenuItem>
        </span>
      </Tooltip>
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Create a snapshot
          </Text>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="Enter a description for the snapshot"
            defaultValue={snapshotDescription}
            onChange={_.debounce(handleChangeDescription, 200)}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleCreateSnapshot}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateSnapshotMenuItem;

import { DisabledByDefaultOutlinedIcon, LockIcon } from "icons";

import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import InstructionsDialogButton from "entities/Field/components/InstructionsDialogButton";
import { IPackageField } from "entities/Package/sdk";

export interface IFieldTitle {
  field: IPackageField;
  editable: boolean;
}

const FieldTitle = ({ field, editable }: IFieldTitle) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {field.n_a_status && (
        <DisabledByDefaultOutlinedIcon
          sx={{ opacity: 0.5 }}
          data-testid="field-na-checkbox"
        />
      )}
      {field.single_persona && <LockIcon />}
      <Text
        variant="text2"
        sx={{
          opacity: field.n_a_status ? 0.5 : 1,
        }}
        data-testid="dd-field-name"
      >
        {field.name}
      </Text>
      <InstructionsDialogButton instructions={field?.instruction} />
      {editable && <Tag label="Updated" />}
    </Stack>
  );
};

export default FieldTitle;

import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import FormHelperText from "components/FormHelperText";
import InputLabel from "components/InputLabel";
import RichTextEditor, { IRichTextEditor } from "components/RichTextEditor";
import Stack from "components/Stack";

interface IRichTextEditorField {
  inputLabel?: string;
  helperText?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  sx?: SxProps<Theme>;
  EditorProps?: IRichTextEditor;
}

const RichTextEditorField: React.FC<IRichTextEditorField> = ({
  inputLabel,
  helperText,
  defaultValue,
  onChange,
  sx,
  EditorProps,
}) => {
  return (
    <Stack spacing={1} sx={sx} data-testid="rtf-edit-section">
      {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
      <RichTextEditor
        defaultValue={defaultValue}
        onChange={onChange}
        {...EditorProps}
      />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default RichTextEditorField;

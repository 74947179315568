import { useCallback, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { AddIcon } from "icons";
import { colors } from "theme/palette";
import { IS_USER_FACING_TABLE_IMPORT_ENABLED } from "utils/constants";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import { tableImportCreate } from "entities/TableStorage/sdk";

import { VALIDATION_SCHEMA } from "./constants";

interface IFormValues {
  table_name: string;
  sheet_name: string;
  column_start: string;
  column_end: string;
  row_start: number;
  row_end: number;
}

const initialValues: IFormValues = {
  table_name: "",
  sheet_name: "",
  column_start: "",
  column_end: "",
  row_start: 1,
  row_end: 10,
};

export interface ITableImportCreateDialogButton {
  dealId?: number;
  createCallback: () => void;
}

const TableImportCreateDialogButton = ({
  dealId,
  createCallback,
}: ITableImportCreateDialogButton) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const formik = useFormik<IFormValues>({
    initialValues: { ...initialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) =>
      tableImportCreate({ values, dealId })
        .then(() => {
          toast.successMessage("Table import created successfully");
          close();
          createCallback();
        })
        .catch(handleInvalidRequest),
  });

  const close = useCallback(() => {
    setShowDialog(false);
    formik.resetForm();
  }, [formik, setShowDialog]);

  return IS_USER_FACING_TABLE_IMPORT_ENABLED ? (
    <>
      <Button startIcon={<AddIcon />} onClick={() => setShowDialog(true)}>
        Create new table import
      </Button>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Create new table import
          </Text>
        </DialogTitle>

        <DialogContent sx={{ padding: 2, overflowY: "visible" }}>
          <Text variant="text3" fontWeight={500}>
            Table name
          </Text>
          <Text variant="text4" color={colors.gray60}>
            Give it a name that will help you identify the data this table
            showcases.
          </Text>
          <TextField
            InputLabelProps={{ shrink: true }}
            {...formik.getFieldProps("table_name")}
            error={
              formik.touched.table_name && !_.isEmpty(formik.errors.table_name)
            }
            helperText={formik.touched.table_name && formik.errors.table_name}
            sx={{ width: "100%" }}
          />

          <Text variant="text3" fontWeight={500} marginTop={4}>
            Cell range
          </Text>
          <Text variant="text4" color={colors.gray60} marginBottom={2}>
            Select the sheet and cell range that will get synced. The maximum
            allowed columns are 10 columns.
          </Text>

          <Stack direction="row" spacing={2}>
            {(
              [
                { label: "Sheet", name: "sheet_name" },
                { label: "Columns: START", name: "column_start" },
                { label: "Columns: END", name: "column_end" },
                { label: "Rows: START", name: "row_start" },
                { label: "Rows: END", name: "row_end" },
              ] as Array<{ label: string; name: keyof IFormValues }>
            ).map(({ label, name }) => (
              <Stack sx={{ flexBasis: "20%" }} key={name}>
                <Text variant="text3" fontWeight={500}>
                  {label}
                </Text>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...formik.getFieldProps(name)}
                  error={
                    formik.touched[name] && !_.isEmpty(formik.errors[name])
                  }
                  helperText={formik.touched[name] && formik.errors[name]}
                />
              </Stack>
            ))}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <Button variant="text" onClick={() => setShowDialog(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={formik.submitForm}
            disabled={formik.isSubmitting}
          >
            Create table import
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default TableImportCreateDialogButton;

import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { formatNumberToString } from "utils/common";

import Box from "components/Box";
import DonutChart, { Legend } from "components/DonutChart";
import Stack from "components/Stack";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import { IIncomeOperatingCost } from "entities/Proforma/sdk";

interface IIncomeAndOpexTable {
  incomeOperatingCost: IIncomeOperatingCost;
  totalUnits: number;
  showChart?: boolean;
}

const headerIncomeAndOpex: Array<{ key: string; name: string }> = [
  { key: "name", name: "INCOME & EXPENSES" },
  { key: "total", name: "YEARLY TOTAL" },
  { key: "perunit", name: "PER UNIT" },
];

const IncomeAndOpexTable: React.FC<IIncomeAndOpexTable> = ({
  incomeOperatingCost,
  totalUnits,
  showChart = true,
}) => {
  const incomeAndOpexDetails = useMemo(() => {
    const rentalIncome = _.orderBy(
      _.map(incomeOperatingCost.rental_income, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value?.value }),
          perunit: formatNumberToString({
            number: value?.value / totalUnits,
          }),
        };
      }),
      "position"
    );
    const lessIncome = _.orderBy(
      _.map(incomeOperatingCost.less_income, (value, key) => {
        return {
          name: key,
          total: formatNumberToString({ number: value?.value }),
          perunit: formatNumberToString({
            number: value?.value / totalUnits,
          }),
        };
      }),
      "position"
    );
    const otherData = [
      {
        name: "All Other Income",
        total: formatNumberToString({
          number: incomeOperatingCost.total_other_income?.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.total_other_income?.value / totalUnits,
        }),
      },
      {
        name: "Effective Gross Income (EGI)",
        total: formatNumberToString({
          number: incomeOperatingCost.total_effective_gross_income?.value,
        }),
        perunit: formatNumberToString({
          number:
            incomeOperatingCost.total_effective_gross_income?.value /
            totalUnits,
        }),
      },
      {
        name: "Total Gross Expenses",
        total: formatNumberToString({
          number: incomeOperatingCost.total_gross_expenses?.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.total_gross_expenses?.value / totalUnits,
        }),
      },
      {
        name: "Net Operating Income (NOI)",
        total: formatNumberToString({
          number: incomeOperatingCost.noi?.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.noi?.value / totalUnits,
        }),
      },
      {
        name: "Debt Service",
        total: formatNumberToString({
          number: incomeOperatingCost.debt_service?.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.debt_service?.value / totalUnits,
        }),
      },
      {
        name: "Cash Flow",
        total: formatNumberToString({
          number: incomeOperatingCost.net_cash_flow?.value,
        }),
        perunit: formatNumberToString({
          number: incomeOperatingCost.net_cash_flow?.value / totalUnits,
        }),
      },
      {
        name: "Breakeven Occupancy Ratio",
        total: "",
        perunit: formatNumberToString({
          number: incomeOperatingCost.breakeven_occupany_ration?.value,
        }),
      },
      {
        name: "Operating Expense Ratio (OER)",
        total: "",

        perunit: formatNumberToString({
          number: incomeOperatingCost.operating_expense_ratio?.value,
        }),
      },
      {
        name: "Debt Service Coverage Ratio (DSCR)",
        total: "",
        perunit: formatNumberToString({
          number: incomeOperatingCost.debt_service_coverage_ratio?.value,
        }),
      },
    ];

    return [...rentalIncome, ...lessIncome, ...otherData] as Array<{
      [key: string]: any;
      detail?: Array<{ [key: string]: any }>;
    }>;
  }, [incomeOperatingCost, totalUnits]);

  const totals = [
    {
      label: "DSCR",
      value: incomeOperatingCost.debt_service_coverage_ratio?.value,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
    {
      label: "Breakeven Occupancy",
      value: incomeOperatingCost.breakeven_occupany_ration?.value,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
    {
      label: "OER",
      value: incomeOperatingCost.operating_expense_ratio?.value,
      type: ProformaHeaderTypeTotal.NUMBER,
    },
  ];

  const donutChartData = [
    {
      value: incomeOperatingCost.total_gross_expenses?.value,
      label: `Total Gross Expenses: $${formatNumberToString({
        number: incomeOperatingCost.total_gross_expenses?.value,
      })}`,
      color: colors.violet100,
    },
    {
      value: incomeOperatingCost.net_cash_flow?.value,
      label: `Cash Flow: $${formatNumberToString({
        number: incomeOperatingCost.net_cash_flow?.value,
      })}`,
      color: colors.violet80,
    },
  ];

  return (
    <ProformaPaper title="Income &amp; OPEX" subTitle="Summary" totals={totals}>
      <Stack direction="row">
        {showChart && (
          <Stack
            sx={{ flex: 1, paddingTop: theme.spacing(12) }}
            alignItems="center"
          >
            <Stack spacing={2}>
              <DonutChart data={donutChartData} width={200} />
              <Legend data={donutChartData} />
            </Stack>
          </Stack>
        )}
        <Box
          sx={{
            flex: 1,
            margin: theme.spacing(3, 0),
          }}
        >
          <ProformaTable
            rows={incomeAndOpexDetails}
            headers={headerIncomeAndOpex}
          />
        </Box>
      </Stack>
    </ProformaPaper>
  );
};

export default IncomeAndOpexTable;

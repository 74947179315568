import _ from "lodash";

import { ITable } from "entities/TableStorage/sdk";

export const removeRowsAndColumnsFromTableData = ({
  indexesOfRowsToBeRemoved,
  indexesOfColumnsToBeRemoved,
  tableData,
}: {
  indexesOfRowsToBeRemoved: Array<number>;
  indexesOfColumnsToBeRemoved: Array<number>;
  tableData: ITable["rows"];
}) =>
  tableData
    .filter((_, index) => !indexesOfRowsToBeRemoved.includes(index - 1))
    .map((row) => ({
      ...row,
      cells: row.cells.filter(
        (_, index) => !indexesOfColumnsToBeRemoved.includes(index)
      ),
    }));

export const applyChangesToTableData = ({
  tableData,
  changedCells,
}: {
  tableData: ITable["rows"];
  changedCells: {
    [key: string]: string | null;
  };
}) =>
  tableData.map((row, rowIndex) => ({
    cells: row.cells.map((cell, columnIndex) => {
      const key = `${rowIndex}-${columnIndex}`;

      if (!_.isUndefined(_.get(changedCells, key, undefined))) {
        return {
          value: changedCells[key],
        };
      } else {
        return cell;
      }
    }),
  }));

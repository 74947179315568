import { useCallback } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";

import Box from "components/Box";
import Button from "components/Button";
import ListPagination from "components/ListPagination";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import { useSignedUser } from "entities/Auth/sdk";
import {
  notificationDeleteAll,
  useNotifications,
} from "entities/Notification/sdk";

import Notification from "./components/Notification";

const Notifications = () => {
  usePageTitle("Builders Patch: Notifications");

  const {
    data,
    mutate: refetchNotifications,
    isLoading,
    setPage,
    pagesCount,
  } = useNotifications({
    limit: 30,
  });
  const { mutate: refetchUser } = useSignedUser();

  const handleClearAll = useCallback(() => {
    notificationDeleteAll().then(() => {
      refetchUser();
      refetchNotifications();
      toast.successMessage("All notifications deleted.");
    });
  }, [refetchNotifications, refetchUser]);

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Box
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader title="Notifications" />

          {!isLoading && data?.results?.length === 0 && (
            <Text variant="text3" margin={2} color={colors.gray80}>
              No new notifications
            </Text>
          )}

          {_.get(data, "results.length", 0) > 0 && (
            <Stack marginY={3}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  paddingLeft: 2,
                  borderBottom: `1px solid ${colors.gray20}`,
                }}
              >
                <Text variant="text3" color={colors.gray80}>
                  {data?.count} new notification
                  {_.get(data, "count", 0) !== 1 && "s"}
                </Text>

                <Button
                  onClick={handleClearAll}
                  variant="text"
                  sx={{
                    color: colors.blue100,
                  }}
                >
                  Clear all
                </Button>
              </Stack>

              {data?.results.map((notification) => (
                <Notification
                  notification={notification}
                  refetchNotifications={refetchNotifications}
                />
              ))}
            </Stack>
          )}

          {isLoading &&
            _.range(30).map((i) => <Skeleton key={i} height={56} />)}

          <ListPagination setPage={setPage} pagesCount={pagesCount} />
        </Box>
      </Stack>
    </Paper>
  );
};

export default Notifications;

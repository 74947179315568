import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import Stack from "components/Stack";

import Metrics from "entities/Proforma/components/ProformaPLProjections/Metrics";
import PLProjectionsSkeleton from "entities/Proforma/components/ProformaPLProjections/PLProjectionsSkeleton";
import Settings from "entities/Proforma/components/ProformaPLProjections/Settings";
import Tables from "entities/Proforma/components/ProformaPLProjections/Tables";
import { getPLProjectionsData, IPLProjection } from "entities/Proforma/sdk";

interface IProformaPLProjections {
  dealId: number;
}

const ProformaPLProjections: React.FC<IProformaPLProjections> = ({
  dealId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [timespan, setTimespan] = useState(30);
  const [rateOfIncome, setRateOfIncome] = useState(2);
  const [rateOfExpenses, setRateOfExpenses] = useState(3);
  const [rateFixedAt, setRateFixedAt] = useState(5);
  const [firstSelectorValue, setFirstSelectorValue] = useState(3);
  const [secondSelectorValue, setSecondSelectorValue] = useState(3);
  const [firstSelector, setValueFirstSelector] = useState<string>("");
  const [secondSelector, setValueSecondSelector] = useState<string>("");
  const [data, setData] = useState<IPLProjection>();

  useEffect(() => {
    getPLProjectionsData({
      dealId,
      startYear,
      timespan,
      rateOfIncome,
      rateOfExpenses,
      rateFixedAt,
      firstSelector,
      secondSelector,
      firstSelectorValue,
      secondSelectorValue,
    }).then((res: IPLProjection) => {
      setData(res);
      setIsLoading(false);
    });
  }, [
    dealId,
    startYear,
    timespan,
    rateOfIncome,
    rateOfExpenses,
    rateFixedAt,
    firstSelector,
    secondSelector,
    firstSelectorValue,
    secondSelectorValue,
  ]);

  const handleChangeStartYear = useCallback(
    (startYear: number) => setStartYear(startYear),
    []
  );

  const handleChangeTimespan = useCallback(
    (timespan: number) => setTimespan(timespan),
    []
  );

  const handleChangeRateOfIncome = useCallback(
    (rateOfIncome: number) => setRateOfIncome(rateOfIncome),
    []
  );

  const handleChangeRateOfExpenses = useCallback(
    (rateOfExpenses: number) => setRateOfExpenses(rateOfExpenses),
    []
  );

  const handleChangeRateFixedAt = useCallback(
    (rateFixedAt: number) => setRateFixedAt(rateFixedAt),
    []
  );

  const handleChangeFirstSelectorValue = useCallback(
    (value: number) => setFirstSelectorValue(value),
    []
  );

  const handleChangeSecondSelectorValue = useCallback(
    (value: number) => setSecondSelectorValue(value),
    []
  );

  const handleChangeFirstSelector = useCallback(
    (value: string) => {
      setValueFirstSelector(value);
    },
    [setValueFirstSelector]
  );

  const handleChangeSecondSelector = useCallback(
    (value: string) => {
      setValueSecondSelector(value);
    },
    [setValueSecondSelector]
  );

  const expensesCategories = useMemo(
    () =>
      _.map(data?.p_and_l.expenses.data, (value, key) => ({
        value: key,
        label: _.startCase(key.replaceAll("_", " ")),
      })),
    [data?.p_and_l.expenses]
  );

  return (
    <>
      {isLoading && <PLProjectionsSkeleton />}
      {!isLoading && data && (
        <Stack spacing={2} data-testid="pl-projections-content">
          <Stack
            spacing={2}
            direction="row"
            data-testid="settings-and-metrics-chart-section"
          >
            <Settings
              expensesCategories={expensesCategories}
              startYear={startYear}
              rateOfIncome={rateOfIncome}
              rateOfExpenses={rateOfExpenses}
              rateFixedAt={rateFixedAt}
              firstSelector={firstSelector}
              secondSelector={secondSelector}
              firstSelectorValue={firstSelectorValue}
              secondSelectorValue={secondSelectorValue}
              onChangeStartYear={handleChangeStartYear}
              onChangeTimespan={handleChangeTimespan}
              onChangeRateOfIncome={handleChangeRateOfIncome}
              onChangeRateOfExpenses={handleChangeRateOfExpenses}
              onChangeRateFixedAt={handleChangeRateFixedAt}
              onChangeFirstSelectorValue={handleChangeFirstSelectorValue}
              onChangeSecondSelectorValue={handleChangeSecondSelectorValue}
              onChangeFirstSelector={handleChangeFirstSelector}
              onChangeSecondSelector={handleChangeSecondSelector}
            />
            <Metrics startYear={startYear} plProjectionData={data} />
          </Stack>
          <Tables plProjectionData={data} />
        </Stack>
      )}
    </>
  );
};

export default ProformaPLProjections;

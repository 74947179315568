import React from "react";

import theme from "theme";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import placeholderIcon from "components/DealAvatar/assets/default.png";
import Image from "components/Image";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import CommunityAmenitiesTable from "entities/Deal/components/OverviewCommunityAmenities/CommunityAmenitiesTable";
import { IDealCommunityAmenities } from "entities/Deal/sdk";

const communityAmenitiesData: IDealCommunityAmenities = {
  image: placeholderIcon,
  description: "<p>This is an example community amenities description.</p>",
  front_desk_and_security: ["...", "...", "..."],
  storage: ["...", "..."],
  recreational_spaces: ["...", "...", "...", "..."],
  energy: ["...", "..."],
  other: ["...", "..."],
  accreditations: ["...", "...", "...", "..."],
};

const MemoTemplateCommunityAmenities = () => {
  return (
    <Stack spacing={2}>
      <Text variant="h2">Community Amenities</Text>
      <ContentSeparator label="Community Amenities" />
      <CommunityAmenitiesTable
        community_amenities_data={communityAmenitiesData}
      />
      <Image
        src={communityAmenitiesData.image}
        sx={{
          height: "460px",
          width: "100%",
          objectFit: "cover",
          marginBottom: theme.spacing(2),
        }}
      />
      <ContentSeparator label="Community Amenities Description" />
      <Box
        sx={{
          paddingLeft: theme.spacing(1),
        }}
      >
        <SanitizeHtml html={communityAmenitiesData.description} />
      </Box>
    </Stack>
  );
};

export default MemoTemplateCommunityAmenities;

import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { CheckCircleIcon } from "icons";
import { colors } from "theme/palette";
import { format, FORMATS, parseISO } from "utils/datetime";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Image from "components/Image";
import Stack from "components/Stack";
import Text from "components/Text";

import { ICompanyIntegration } from "entities/CompanyItegrations/sdk";

import DownhomeLogo from "./assets/logo.svg";

interface ICard {
  dealId: number;
  integration: ICompanyIntegration;
}

const Card = ({ dealId, integration }: ICard) => {
  const navigate = useNavigate();

  const integrationIsActive = useMemo(
    () => !_.isNil(integration.external_id),
    [integration]
  );

  const formattedCreatedAt = useMemo(
    () =>
      !_.isNil(integration.created_at)
        ? format(parseISO(integration.created_at), FORMATS.FRONTEND.DATETIME)
        : null,
    [integration]
  );

  const onIntegrationCreate = useCallback(
    () =>
      navigate(
        reverse(URLS.COMPANY_INTEGRATION_DOWNHOME_BUSINESS_CREATE, {
          dealId,
        })
      ),
    [dealId, navigate]
  );

  const onOpenIntegrationDetails = useCallback(() => {
    if (!_.isNil(integration.external_id)) {
      navigate(
        reverse(URLS.COMPANY_INTEGRATION_DOWNHOME_BUSINESS_UPDATE, {
          dealId,
          externalId: integration.external_id,
        })
      );
    }
  }, [dealId, integration, navigate]);

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        padding: 2,
        borderRadius: 1,
        border: `1px solid ${colors.blue40}`,
      }}
    >
      <Stack alignItems="flex-start">
        <Text variant="text1" fontWeight={700}>
          DownHome Loan Manager: Business Loans
        </Text>
        <Text
          variant="text3"
          color={colors.gray80}
          maxWidth={630}
          marginTop={1}
          marginBottom={2}
        >
          DownHome Loan Manager (DLM) is loan-tracking software created
          expressly for not-for-profit community lenders, offering loan tracking
          for multiple loan funds with adjustable modules and customizations
          available, and navigation for billing and reports.
        </Text>

        {!integrationIsActive && (
          <Button onClick={onIntegrationCreate}>
            Activate this integration
          </Button>
        )}

        {integrationIsActive && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Button color="secondary" onClick={onOpenIntegrationDetails}>
              Update data
            </Button>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircleIcon sx={{ height: 38, width: 38 }} color="primary" />
              <Text variant="text4" color={colors.gray80}>
                Activated on {formattedCreatedAt}
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Image src={DownhomeLogo} width={200} />
    </Stack>
  );
};

export default memo(Card, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

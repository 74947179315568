import { memo, useCallback, useMemo } from "react";
import _ from "lodash";

import { DescriptionOutlinedIcon, DownloadOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { IUploadedFile } from "entities/File/sdk";
import SnapshotDetails from "entities/Proforma/components/SnapshotDetails";

export interface IFile {
  isLatest: boolean;
  uploadedFile: IUploadedFile;
  onDownload: ({ file }: { file: IUploadedFile }) => void;
}

const File = ({ isLatest, uploadedFile, onDownload }: IFile) => {
  const formattedFileName = useMemo(
    () =>
      uploadedFile.original_file_name ||
      _.last(uploadedFile.file_url?.split("/")),
    [uploadedFile]
  );

  const handleFileDownload = useCallback(
    () => onDownload({ file: uploadedFile }),
    [uploadedFile, onDownload]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${colors.blue40}`,
      }}
      data-testid="uploaded-file-entity"
    >
      <Stack direction="row" alignItems="start" spacing={2}>
        <DescriptionOutlinedIcon data-testid="file-icon" />
        <Stack flex={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Text
              noWrap
              variant="text3"
              title={formattedFileName}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 450,
              }}
              data-testid="file-name"
            >
              {formattedFileName}
            </Text>
            {isLatest && <Tag label="Current file" variant="blue20" />}
            {!_.isNil(uploadedFile.snapshot) && (
              <SnapshotDetails snapshot={uploadedFile.snapshot} />
            )}
          </Stack>
          <Text
            variant="text4"
            sx={{ color: colors.gray60 }}
            data-testid="file-upload-datetime-data"
          >
            Uploaded on{" "}
            {format(
              parseJSON(uploadedFile.created_at),
              FORMATS.FRONTEND.DATETIME
            )}{" "}
            by {uploadedFile.uploaded_by.first_name}{" "}
            {uploadedFile.uploaded_by.last_name}
          </Text>
        </Stack>
      </Stack>
      <Tooltip title="Download">
        <IconButton
          onClick={handleFileDownload}
          data-testid="download-file-button"
        >
          <DownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default memo(File, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

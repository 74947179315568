import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { PDF_MIME_TYPE } from "utils/constants";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import { MenuItem } from "components/Menu";
import Select from "components/Select";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import UploadButton from "components/UploadButton";
import { defaultPDFUploadButton } from "components/UploadButton/utils";

import { ButlerQueueType } from "entities/AssetManagement/constants";
import { useButlerQueuesList } from "entities/AssetManagement/sdk";
import { upload } from "entities/File/sdk";
import { checklistProcessPdf } from "entities/Package/sdk";

export interface IPdfUploadAndParseMenuItem {
  dealId: number;
  packageId: number;
}

const PdfUploadAndParseMenuItem = ({
  dealId,
  packageId,
}: IPdfUploadAndParseMenuItem) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedQueue, setSelectedQueue] = useState<string>();

  const { data: queuesList } = useButlerQueuesList({
    queueType: ButlerQueueType.DUE_DILIGENCE,
  });

  useEffect(() => {
    setSelectedQueue(
      _.first(queuesList?.map((queue) => String(queue.id)) || [])
    );
  }, [queuesList]);

  const handleFileUpload = () => {
    const toastId = toast.loading("Uploading file...");

    if (_.isNil(selectedFile) || _.isNil(selectedQueue)) {
      return;
    }

    handleClose();

    upload({ file: selectedFile, isPrivate: true }).then((fileId) => {
      checklistProcessPdf({ dealId, packageId, fileId, queueId: selectedQueue })
        .then(() => {
          setSelectedFile(undefined);
          toast.update(toastId, {
            render: "You will be notified once the processing has finished.",
            type: "success",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            closeButton: true,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: _.get(
              error,
              "message",
              "Something went wrong. Please try again later."
            ),
            type: "error",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            closeButton: true,
          });
        });
    });
  };

  const handleClose = useCallback(() => {
    setSelectedFile(undefined);
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleChange = useCallback(
    ({ files }: { files: Array<File> }) => setSelectedFile(_.first(files)),
    []
  );

  const queueOptions = useMemo(
    () =>
      queuesList?.map((queue) => ({
        value: String(queue.id),
        label: queue.name,
      })) || [],
    [queuesList]
  );

  const isSubmitButtonDisabled = useMemo(
    () => _.isNil(selectedQueue) || _.isNil(selectedFile),
    [selectedQueue, selectedFile]
  );

  return (
    <>
      <MenuItem onClick={handleOpen} dataTestid="action-menu-option">
        Import data from PDF
      </MenuItem>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        data-testid="due-diligence-upload-pdf-dialog"
      >
        <DialogTitle
          sx={{
            margin: theme.spacing(2, 2, 3, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: colors.blue10,
          }}
          data-testid="dialog-header-section"
        >
          <Text variant="h1" data-testid="dialog-title">
            Upload new data
          </Text>
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={4}
            alignItems="center"
            sx={{ marginTop: theme.spacing(1) }}
            data-testid="dialog-action-buttons"
          >
            {queuesList && (
              <Select
                fullWidth
                label="Document type"
                onChange={(event) => {
                  setSelectedQueue(event.target.value);
                }}
                value={selectedQueue}
                options={queueOptions}
                data-testid="butler-keys-dropdown"
                inputProps={{ "data-testid": "butler-keys-dropdown-input" }}
                dropdownOptionsDataTestid="butler-keys-dropdown-option"
              />
            )}
            <UploadButton
              maxFiles={1}
              onChange={handleChange}
              accept={PDF_MIME_TYPE}
              renderComponent={(state) =>
                defaultPDFUploadButton({
                  state,
                })
              }
              dataTestid="upload-pdf-file-input"
            />
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ height: "23px" }} // We want to keep the height of the section when there is no file selected
            >
              {!_.isUndefined(selectedFile) && (
                <Text
                  title={selectedFile.name}
                  variant="text2"
                  data-testid="uploaded-file-name"
                >
                  {selectedFile.name}
                </Text>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <Button
            color="secondary"
            onClick={handleClose}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitButtonDisabled}
            onClick={handleFileUpload}
            data-testid="submit-button"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PdfUploadAndParseMenuItem;

import { useCallback } from "react";

import PageHeader from "components/PageHeader";
import Select from "components/Select";
import Stack from "components/Stack";

import FilterButton from "entities/Task/components/FilterButton";
import { TaskTypes } from "entities/Task/constants";
import { ITypeOfFilter } from "entities/Task/sdk";

const taskTypesOptions = [
  {
    label: "All tasks",
    value: TaskTypes.ALL_TASKS,
  },
  {
    label: "My tasks",
    value: TaskTypes.MY_TASKS,
  },
  {
    label: "My team tasks",
    value: TaskTypes.MY_TEAM_TASKS,
  },
];

interface IAllDealTasksPageTitle {
  taskType: TaskTypes;
  initialFilters: ITypeOfFilter;
  onChangeTaskType: (taskType: TaskTypes) => void;
  onChangeFilters: (selectedFilters: ITypeOfFilter) => void;
}

const AllDealTasksPageTitle: React.FC<IAllDealTasksPageTitle> = ({
  taskType,
  initialFilters,
  onChangeTaskType,
  onChangeFilters,
}) => {
  const handleChangeSelector = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTaskType(event.target.value as TaskTypes);
    },
    [onChangeTaskType]
  );
  return (
    <PageHeader
      title="Task management"
      actions={
        <Stack
          sx={{ flex: 1 }}
          spacing={2}
          direction="row"
          justifyContent="space-between"
        >
          <Select
            size="small"
            sx={{ width: "130px", backgroundColor: "white" }}
            label="Task type"
            options={taskTypesOptions}
            value={taskType}
            onChange={handleChangeSelector}
          />
          <FilterButton
            showDeals
            initialFilters={initialFilters}
            onChange={onChangeFilters}
          />
        </Stack>
      }
    />
  );
};

export default AllDealTasksPageTitle;

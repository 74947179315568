import React, { useCallback } from "react";

import { InfoIcon } from "icons";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Switch from "components/Switch";
import Table, { TableBody, TableCell, TableRow } from "components/Table";
import Text from "components/Text";
import toast from "components/Toast";
import Tooltip from "components/Tooltip";

import { useSignedUser } from "entities/Auth/sdk";
import { IUser, userChangeEmailNotiications } from "entities/User/sdk";

export interface IUserInfoTable {
  user: IUser;
}

const UserInfoTable = ({ user }: IUserInfoTable) => {
  const { mutate: mutateSignedUser } = useSignedUser();

  const handleChangeEmailNotification = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      userChangeEmailNotiications({
        emailNotidicationsEnabled: checked,
      }).then(() => {
        mutateSignedUser();
        toast.successMessage(
          `Email notifications for your daily task summary have been successfully ${
            checked ? "enabled" : "disabled"
          }.`
        );
      });
    },
    [mutateSignedUser]
  );

  return (
    <Table size="small">
      <TableBody sx={{ borderTop: `1px solid ${colors.blue20}` }}>
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: 0,
            }}
          >
            <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
              Email
            </Text>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Text variant="text2" data-testid="user-email">
              {user.email}
            </Text>
          </TableCell>
        </TableRow>

        {/* Fixes "whitespace text nodes cannot appear as a child of <tbody> */}
        {user.contact !== "" && (
          <TableRow
            sx={{
              ".MuiTableCell-root": {
                borderBottom: `1px solid ${colors.blue20}`,
              },
            }}
          >
            <TableCell sx={{ paddingLeft: 0 }}>
              <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
                Phone
              </Text>
            </TableCell>
            <TableCell align="right" sx={{ paddingRight: 0 }}>
              <Text variant="text2" data-testid="user-phone">
                {user.contact}
              </Text>
            </TableCell>
          </TableRow>
        )}
        <TableRow
          sx={{
            ".MuiTableCell-root": {
              borderBottom: `1px solid ${colors.blue20}`,
            },
          }}
        >
          <TableCell
            sx={{
              paddingLeft: 0,
            }}
          >
            <Stack direction="row" spacing={1}>
              <Text variant="labelSmall" sx={{ color: colors.gray80 }}>
                Email notifications
              </Text>
              <Tooltip
                arrow
                title={
                  <Text>
                    If you enable this switch, you will receive daily task
                    summary emails.
                  </Text>
                }
                placement="top"
              >
                <InfoIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                  }}
                />
              </Tooltip>
            </Stack>
          </TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            <Switch
              checked={user.email_notifications_enabled}
              onChange={handleChangeEmailNotification}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default UserInfoTable;

import { useMemo } from "react";
import _ from "lodash";

import { BASE_URL } from "config/urls";
import { get, PaginatedResponse, patch, useFetch } from "utils/sdk";
import { formatGetParams } from "utils/urls";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { IEmployee } from "entities/Employee/sdk";

export interface ICompany {
  id: number;
  name: string;
  locations: Array<{
    id: number;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    uuid?: string;
  }>;
  company_size?: string;
  established_in?: string;
  web_url?: string;
  default_group: string;
  employees: IEmployee[];
  external_apis_enabled: boolean;
  logo?: string | null;
}

export enum Persona {
  ARCHITECT = "Architect",
  ASSET_MANAGER = "Asset Manager",
  CONTRACTOR = "Contractor",
  DEVELOPER = "Developer",
  GENERAL_CONTRACTOR = "General Contractor",
  LAWYER = "Lawyer",
  LENDER = "Lender",
  PROPERTY_MANAGER = "Property Manager",
}

export enum Faction {
  BORROWER = "Borrower",
  LENDER = "Lender",
}

export interface ICompanyAutocompleteOption {
  id: number;
  name: string;
  domain: string;
}

export interface ICompanySettings {
  ai_summary_enabled: boolean;
  ai_summary_shown: boolean;
  max_deal_count: number;
  max_checklist_count: number;
  max_intake_checklist_count: number;
  max_ai_summary_document_count: number;
  asset_management_proforma_enabled: boolean;
  asset_management_proforma_shown: boolean;
  underwriting_proforma_enabled: boolean;
  underwriting_proforma_shown: boolean;
  custom_reports_enabled: boolean;
  custom_reports_shown: boolean;
  memo_enabled: boolean;
  memo_shown: boolean;
  intake_applicant_enabled: boolean;
  intake_applicant_shown: boolean;
  intake_lender_enabled: boolean;
  intake_lender_shown: boolean;
  analytics_development_budget_enabled: boolean;
  analytics_development_budget_shown: boolean;
  analytics_income_and_opex_enabled: boolean;
  analytics_income_and_opex_shown: boolean;
  tables_enabled: boolean;
  tables_shown: boolean;
  deal_tables_enabled: boolean;
  deal_tables_shown: boolean;
}

export const updateCompanyProfile = (
  companyId: number,
  data: Partial<Omit<ICompany, "logo">>
) => patch(`${BASE_URL}v2/company/${companyId}/update/`, data);

export const useCurrentCompany = (companyId: number | undefined) =>
  useFetch<ICompany>(
    companyId ? `v2/company/${companyId}/details/` : undefined
  );

export const useCompanies = ({ name }: { name: string }) =>
  useFetch<PaginatedResponse<ICompanyAutocompleteOption>>(
    `v2/company/list/${formatGetParams({ name })}`
  );

export const useCompanySettings = ({ dealId }: { dealId?: number }) =>
  useFetch<ICompanySettings>(
    `v2/company/company-settings/retrieve/${!_.isNil(dealId) ? formatGetParams({ deal_id: dealId }) : ""}`
  );

export const companySettingsFeatureLimitCheck = ({
  featureType,
  dealId,
}: {
  featureType: ECompanySettingsFeatureType;
  dealId?: number;
}): Promise<{
  has_access: boolean;
}> => {
  const data: { [key: string]: ECompanySettingsFeatureType | number } = {
    feature: featureType,
  };
  if (!_.isNil(dealId)) {
    data["deal_id"] = dealId;
  }

  return get(
    `${BASE_URL}v2/company/company-settings/check-feature-limit/${formatGetParams(
      data
    )}`
  );
};

export const useCompanySettingsFeatureCheck = ({
  featureType,
  dealId,
}: {
  featureType: ECompanySettingsFeatureType;
  dealId?: number;
}) => {
  const data: { [key: string]: ECompanySettingsFeatureType | number | null } =
    useMemo(
      () => ({
        feature: featureType,
        ...(!_.isNil(dealId) ? { deal_id: dealId } : {}),
      }),
      [featureType, dealId]
    );

  return useFetch<{ has_access: boolean }>(
    `v2/company/company-settings/check-feature-limit/${formatGetParams(data)}`
  );
};

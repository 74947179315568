import _ from "lodash";

import { BASE_URL } from "config/urls";
import { deleteCall, post, useFetch } from "utils/sdk";

import { Persona } from "entities/Company/sdk";

export interface IDealAccess {
  id: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    avatar: string;
    email: string;
    contact: string;
    designation: string;
    is_active: boolean;
  };
  created_at: string;
  is_admin: boolean;
  deal_company_permission: {
    persona: Persona;
    company: {
      id: number;
      name: string;
    };
  };
}

export const useDealTeamMembers = ({
  dealId,
}: {
  dealId: number | undefined;
}) =>
  useFetch<Array<IDealAccess>>(
    _.isNil(dealId) || _.isNaN(dealId)
      ? undefined
      : `v2/deal/${dealId}/team-member/list/`
  );

export interface IDealTeamMemberInvitation {
  id: string;
  email: string;
  persona: Persona;
  created_at: string;
  created_by: { first_name: string; last_name: string; avatar: null | string };
}

export const useDealTeamMemberInvitations = ({ dealId }: { dealId: number }) =>
  useFetch<Array<IDealTeamMemberInvitation>>(
    `v2/deal/${dealId}/invitation/list/`
  );

export const addNewTeam = ({
  dealId,
  data,
}: {
  dealId: number;
  data: { email: string; persona: string };
}) => post(`${BASE_URL}v2/deal/${dealId}/invitation/create/`, data);

export const addNewTeamMember = ({
  dealId,
  data,
}: {
  dealId: number;
  data: { email: string; company_id: number };
}) => post(`${BASE_URL}v2/deal/${dealId}/invitation/team-member/create/`, data);

export const useRemainingUsers = ({
  dealId,
  companyId,
}: {
  dealId: number;
  companyId: number;
}) =>
  useFetch<Array<{ email: string; first_name: string; last_name: string }>>(
    `v2/deal/deal/${dealId}/company/${companyId}/users-not-in-deal/list/`
  );

export interface INewMemberData {
  email: string;
  first_name: string;
  last_name: string;
  company: number | null;
}

export const updateTeamMember = ({
  dealId,
  teamMemberId,
  data,
}: {
  dealId: number;
  teamMemberId: number;
  data: { is_admin: boolean };
}) =>
  post(
    `${BASE_URL}v2/deal/${dealId}/team-member/${teamMemberId}/update/`,
    data
  );

export const deleteTeamMember = ({
  dealId,
  teamMemberId,
}: {
  dealId: number;
  teamMemberId: number;
}) => {
  return deleteCall(
    `${BASE_URL}v2/deal/${dealId}/team-member/${teamMemberId}/delete/`
  );
};

export const addCompanyMemberToDeal = ({
  dealId,
  userId,
}: {
  dealId: number;
  userId: number;
}) =>
  post(`${BASE_URL}v2/deal/deal/${dealId}/add-company-member/`, {
    user_id: userId,
  });

export const invitationCancel = ({ invitationId }: { invitationId: string }) =>
  post(`${BASE_URL}v2/deal/invitation/${invitationId}/cancel/`);

export const invitationAccept = ({ invitationId }: { invitationId: string }) =>
  post(`${BASE_URL}v2/deal/invitation/${invitationId}/accept/`);

export const invitationReject = ({ invitationId }: { invitationId: string }) =>
  post(`${BASE_URL}v2/deal/invitation/${invitationId}/reject/`);

import {
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers/DesktopDatePicker";

import { IDate } from "utils/datetime";

const DatePicker = (props: DesktopDatePickerProps<IDate>) => {
  return <DesktopDatePicker {...props} />;
};

export default DatePicker;

import { Dispatch, useCallback, useMemo } from "react";
import _ from "lodash";

import { format, FORMATS, parseISO } from "utils/datetime";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

const DatePickerFilter = ({
  label,
  selectedFilters,
  setSelectedFilters,
}: {
  label: string;
  selectedFilters: { [key: string]: string[] };
  setSelectedFilters: Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
    }>
  >;
}) => {
  const selectedMinDate = useMemo(() => {
    if (_.isUndefined(_.get(selectedFilters, [label, 0]))) {
      return null;
    }

    return parseISO(_.get(selectedFilters, [label, 0]));
  }, [selectedFilters, label]);
  const selectedMaxDate = useMemo(() => {
    if (_.isUndefined(_.get(selectedFilters, [label, 1]))) {
      return null;
    }

    return parseISO(_.get(selectedFilters, [label, 1]));
  }, [selectedFilters, label]);

  const updateMinDate = useCallback(
    ({ label, value }: { label: string; value: Date | null }) => {
      const maxDate = _.get(selectedFilters, [label, 1], "");

      const formattedDate = !_.isNil(value)
        ? format(value, FORMATS.BACKEND.DATE)
        : "";
      setSelectedFilters((current: any) => ({
        ...current,
        [label]: [formattedDate, maxDate],
      }));
    },
    [selectedFilters, setSelectedFilters]
  );

  const updateMaxDate = useCallback(
    ({ label, value }: { label: string; value: Date | null }) => {
      const minDate = _.get(selectedFilters, [label, 0], "");

      const formattedValue = !_.isNil(value)
        ? format(value, FORMATS.BACKEND.DATE)
        : "";
      setSelectedFilters((current: any) => ({
        ...current,
        [label]: [minDate, formattedValue],
      }));
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleReset = useCallback(
    () => setSelectedFilters((current: any) => _.omit(current, label)),
    [label, setSelectedFilters]
  );

  return (
    <Stack spacing={2}>
      <Tooltip title={label} enterDelay={1000} enterNextDelay={1000}>
        <Text variant="text2" fontWeight={700}>
          {label}
        </Text>
      </Tooltip>
      <DatePicker
        label="From"
        value={selectedMinDate}
        views={["year", "month", "day"]}
        onChange={(value: Date | null) =>
          updateMinDate({
            label,
            value,
          })
        }
        slotProps={{
          textField: {
            size: "small",
          },
          inputAdornment: {
            position: "start",
          },
          field: { clearable: true },
        }}
      />
      <DatePicker
        label="To"
        value={selectedMaxDate}
        views={["year", "month", "day"]}
        onChange={(value: Date | null) =>
          updateMaxDate({
            label,
            value,
          })
        }
        slotProps={{
          textField: {
            size: "small",
          },
          inputAdornment: {
            position: "start",
          },
          field: { clearable: true },
        }}
      />
      <Button
        variant="outlined"
        size="small"
        onClick={handleReset}
        sx={{ alignSelf: "flex-start" }}
      >
        Reset to default
      </Button>
    </Stack>
  );
};

export default DatePickerFilter;

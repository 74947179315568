import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";

import Stack from "components/Stack";
import { FiltersContextProvider } from "components/useFilters";
import { usePageTitle } from "components/usePageTitle";

import Content from "pages/Projects/Content";
import PageHeader from "pages/Projects/PageHeader";

import { ProjectsViewType } from "entities/Deal/constants";
import { useDealsListData } from "entities/Deal/sdk";

const isStringIViewType = (
  view: string | ProjectsViewType
): view is ProjectsViewType => {
  return _.get(ProjectsViewType, view) !== undefined;
};

const PAGE_TITLE_BY_VIEW_TYPE = {
  [ProjectsViewType.grid]: "Builders Patch: Deals Dashboard - Grid",
  [ProjectsViewType.list]: "Builders Patch: Deals Dashboard - List",
  [ProjectsViewType.map]: "Builders Patch: Deals Dashboard - Map",
};

const Projects = () => {
  const { phase = "All", view: viewParam = ProjectsViewType.grid } = useParams<{
    phase: string;
    view: string;
  }>();

  const [selectedView, setSelectedView] = useState<ProjectsViewType>(() => {
    return isStringIViewType(viewParam) ? viewParam : ProjectsViewType.grid;
  });

  usePageTitle(PAGE_TITLE_BY_VIEW_TYPE[selectedView]);

  const {
    mapCoordinates,
    columns = [],
    rows = [],
    loading,
    isValidating,
    mutate: refetchDeals,
  } = useDealsListData({
    phase,
  });

  const markers = useMemo(() => {
    if (mapCoordinates) {
      return Object.keys(mapCoordinates)
        .filter((key: any) => {
          return (
            mapCoordinates[key].center &&
            _.get(mapCoordinates[key].center, "lat") &&
            _.get(mapCoordinates[key].center, "lng")
          );
        })
        .map((key: any) => mapCoordinates[key]);
    }
  }, [mapCoordinates]);

  return (
    <FiltersContextProvider>
      <Stack spacing={2} sx={{ padding: theme.spacing(4, 3, 0), flex: 1 }}>
        <PageHeader
          rows={rows}
          columns={columns}
          phase={phase}
          selectedView={selectedView}
          loading={loading}
          onChangeView={setSelectedView}
          onCreateDeal={refetchDeals}
        />
        <Content
          selectedView={selectedView}
          rows={rows}
          columns={columns}
          loading={loading}
          isValidating={isValidating}
          markers={markers || []}
        />
      </Stack>
    </FiltersContextProvider>
  );
};

export default Projects;

import { memo, useMemo } from "react";
import _ from "lodash";

import { colors } from "theme/palette";
import { format, FORMATS, parseJSON } from "utils/datetime";

import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { formatFieldValue } from "entities/Field/utils";
import { IFieldValueHistoryItem } from "entities/Package/sdk";

export interface ITextFieldHistoryElement {
  value: IFieldValueHistoryItem;
}

const TextFieldHistoryElement = ({ value }: ITextFieldHistoryElement) => {
  const timestamp = useMemo(
    () => format(parseJSON(value.uploaded_at), FORMATS.FRONTEND.DATETIME),
    [value]
  );

  const creatorText = useMemo(() => {
    if (!_.isNil(value.creator)) {
      return `${value.creator.first_name} ${value.creator.last_name}`;
    }
    return "Past proforma upload";
  }, [value]);

  const formattedValue = useMemo(
    () =>
      !_.isNil(value.default_value) && value.default_value !== ""
        ? formatFieldValue({
            fieldValue: value.default_value,
            fieldFormatType: value.field_format_type,
          }) || value.default_value
        : "(deleted)",
    [value]
  );

  return (
    <Stack data-testid="field-history-entity">
      <Text
        variant="text2"
        sx={{ color: colors.gray60, width: 600 }}
        data-testid="entity-title"
      >
        <b>{creatorText}</b>{" "}
        {value.default_value !== "" ? "updated" : "deleted"} value at{" "}
        {timestamp}
      </Text>
      <Tooltip title={value.default_value} placement="top-start">
        <Text
          variant="h6"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: 600,
          }}
          data-testid="entity-value"
        >
          {formattedValue}
        </Text>
      </Tooltip>
    </Stack>
  );
};

export default memo(TextFieldHistoryElement);

import { useCallback, useState } from "react";

import { EditIcon } from "icons";
import theme from "theme";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import Dialog, { DialogActions, DialogTitle } from "components/Dialog";
import FormControlLabel from "components/FormControlLabel";
import IconButton from "components/IconButton";
import Radio from "components/Radio";
import Stack from "components/Stack";
import Text from "components/Text";

import { IFieldOption } from "entities/Package/sdk";

interface IRadiofieldEditButton {
  name: string;
  options: Array<IFieldOption>;
  initialValue: string | null | undefined;
  onSave: (newValue: string | null | undefined) => void;
  onCancel: () => void;
  onReset: () => void;
  buttonDataTestid?: string;
}

const RadiofieldEditButton = ({
  name,
  options,
  initialValue,
  onSave,
  onCancel,
  onReset,
  buttonDataTestid,
}: IRadiofieldEditButton) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useCaching(initialValue);

  const handleEditButtonClick = useCallback(() => setIsOpen(true), []);

  const handleSave = useCallback(() => {
    onSave(value);
    setIsOpen(false);
  }, [setIsOpen, onSave, value]);

  const handleCancel = useCallback(() => {
    onCancel();
    setValue(initialValue);
    setIsOpen(false);
  }, [setIsOpen, onCancel, setValue, initialValue]);

  const handleReset = useCallback(() => {
    setValue(null);
    onReset();
  }, [setValue, onReset]);

  const handleChangeOption = useCallback(
    (item: string) => setValue(item),
    [setValue]
  );

  return (
    <>
      <IconButton
        size="small"
        onClick={handleEditButtonClick}
        dataTestid={buttonDataTestid}
      >
        <EditIcon />
      </IconButton>
      {isOpen && (
        <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleCancel}>
          <Box
            sx={{ padding: theme.spacing(1) }}
            data-testid="radiobutton-edit-dialog"
          >
            <DialogTitle data-testid="radiobutton-field-edit-dialog-name">
              {name}
            </DialogTitle>
            <Stack spacing={1} sx={{ paddingX: theme.spacing(3) }}>
              {options.map((defaultValue, index) => (
                <FormControlLabel
                  key={index}
                  label={
                    <Text variant="label" data-testid="radiobutton-label">
                      {defaultValue.label}
                    </Text>
                  }
                  sx={{ margin: 0 }}
                  control={
                    <Radio
                      checked={value === defaultValue.value}
                      onChange={() => handleChangeOption(defaultValue.value)}
                      data-testid="radiobutton-option"
                    />
                  }
                ></FormControlLabel>
              ))}
            </Stack>
            <DialogActions sx={{ marginTop: theme.spacing(2) }}>
              <Button
                variant="text"
                color="error"
                onClick={handleReset}
                data-testid="reset-button"
              >
                Reset
              </Button>
              <Button
                variant="text"
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button onClick={handleSave} data-testid="submit-button">
                Save
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default RadiofieldEditButton;

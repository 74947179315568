import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import { CloseIcon } from "icons";
import theme from "theme";

import Divider from "components/Divider";
import Drawer from "components/Drawer";
import IconButton from "components/IconButton";
import SidepanelFooter from "components/SidepanelFooter";
import SidepanelHeader from "components/SidepanelHeader";
import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import Comment from "entities/Memo/components/Comments/Comment";
import CommentInputField from "entities/Memo/components/Comments/CommentInputField";
import EmptyComment from "entities/Memo/components/Comments/EmptyComment";
import {
  addMemoSectionComment,
  IMemoSectionComment,
  markSectionCommentsListAsSeen,
  useSectionCommentsList,
} from "entities/Memo/sdk";

const CommentsDrawer = ({
  dealId,
  memoSectionId,
  open,
  onCancel,
}: {
  dealId: number;
  memoSectionId: number;
  open: boolean;
  onCancel: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { data: user } = useSignedUser();
  const { data: comments = [], mutate: updateComments } =
    useSectionCommentsList({
      dealId: open ? dealId : undefined,
      memoSectionId: open ? memoSectionId : undefined,
    });

  useEffect(() => {
    if (open && memoSectionId && dealId && comments.length > 0) {
      markSectionCommentsListAsSeen({
        dealId,
        memoSectionId,
      });
    }
  }, [open, memoSectionId, dealId, comments]);

  const handleSubmit = useCallback(
    (text: string) => {
      setLoading(true);

      return addMemoSectionComment({
        dealId,
        sectionId: memoSectionId,
        message: text,
      })
        .then((data) => {
          updateComments(
            (comments: IMemoSectionComment[] | undefined) => {
              return comments && [...comments, data];
            },
            { revalidate: false } // No revalidation here because the socket connection will revalidate this
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dealId, memoSectionId, updateComments]
  );

  useEffect(() => {
    const el = window.document.getElementById(
      `comment-id-${_.last(comments)?.id}`
    );

    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [memoSectionId, comments]);

  const seenComments = _.takeWhile(comments, ({ created_by, seen_by }) => {
    return (
      created_by.user_id === user?.id ||
      seen_by?.filter((seenByUser) => seenByUser.user_id === user?.id).length >
        0
    );
  });
  const unseenComments = _.dropWhile(comments, ({ created_by, seen_by }) => {
    return (
      created_by.user_id === user?.id ||
      seen_by?.filter((seenByUser) => seenByUser.user_id === user?.id).length >
        0
    );
  });

  return (
    <Drawer open={open} anchor="right" PaperProps={{ elevation: 0 }}>
      <Stack sx={{ width: "800px", height: "100%" }}>
        <SidepanelHeader>
          <Text variant="h3">Comments</Text>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </SidepanelHeader>
        <Stack
          flex="1"
          spacing={2}
          sx={{
            padding: theme.spacing(2, 6, 2, 4),
            overflowY: "auto",
          }}
        >
          {seenComments && !_.isEmpty(seenComments) && (
            <Stack spacing={2}>
              {seenComments.map((c) => (
                <Comment comment={c} key={c.id} />
              ))}
            </Stack>
          )}
          {!_.isEmpty(unseenComments) && (
            <Divider sx={{ alignItems: "flex-start" }}>
              <Text variant="text3">New Comments</Text>
            </Divider>
          )}
          {unseenComments && !_.isEmpty(unseenComments) && (
            <Stack spacing={2}>
              {unseenComments.map((c) => (
                <Comment comment={c} key={c.id} />
              ))}
            </Stack>
          )}
          {_.isEmpty(comments) && <EmptyComment />}
        </Stack>
        <SidepanelFooter>
          <CommentInputField disabled={loading} onSubmit={handleSubmit} />
        </SidepanelFooter>
      </Stack>
    </Drawer>
  );
};

export default CommentsDrawer;

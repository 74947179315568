import { useCallback, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import { MenuItem } from "components/Menu";
import Text from "components/Text";
import TextField from "components/TextField";

import { VALIDATION_SCHEMA } from "./constants";

export interface ITableRenameDialogMenuItem {
  initialValue: string;
  onUpdate: ({ values }: { values: { name: string } }) => void;
}

const TableRenameDialogMenuItem = ({
  initialValue,
  onUpdate,
}: ITableRenameDialogMenuItem) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      onUpdate({ values });
      setIsDialogOpen(false);
    },
    [onUpdate]
  );

  const formik = useFormik({
    initialValues: { name: initialValue },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
    formik.resetForm();
  }, [formik]);

  return (
    <>
      <MenuItem onClick={() => setIsDialogOpen(true)}>Edit table name</MenuItem>

      <Dialog fullWidth open={isDialogOpen} onClose={handleCancel}>
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Edit table name
          </Text>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ padding: 2 }}>
            <TextField
              fullWidth
              {...formik.getFieldProps("name")}
              error={formik.touched.name && !_.isEmpty(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TableRenameDialogMenuItem;

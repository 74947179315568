import React from "react";

import Box from "components/Box";

import SectionContent from "entities/Memo/components/Sections/SectionContent";
import Richtextfield from "entities/MemoTemplate/components/Fields/Richtextfield";

interface IMemoTemplateCommentSection {}

const MemoTemplateCommentSection: React.FC<
  IMemoTemplateCommentSection
> = () => {
  return (
    <Box>
      <SectionContent>
        <Box
          sx={{
            marginTop: "8px",
            "& .MuiIconButton-root": { visibility: "hidden" },
            "&:hover .MuiIconButton-root": {
              visibility: "visible",
            },
          }}
        >
          <Richtextfield label="Comment" value={"..."} />
        </Box>
      </SectionContent>
    </Box>
  );
};

export default MemoTemplateCommentSection;

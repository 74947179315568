import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { FilterListIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";

import Box from "components/Box";
import Button from "components/Button";
import CheckboxTree from "components/CheckboxTree";
import Popover from "components/Popover";
import Stack from "components/Stack";

import { Persona } from "entities/Company/sdk";
import { useDealPermission } from "entities/Deal/sdk";
import {
  FilterValue,
  ITypeOfFilter,
  useDealListForTaskFilters,
  usePriorityList,
  useTagList,
} from "entities/Task/sdk";

interface IWorkspaceTasksFilterButton {
  initialFilters: ITypeOfFilter;
  showDeals?: boolean;
  onChange: (selectedFilters: ITypeOfFilter) => void;
}

const teamsFilterOptions = _.orderBy(
  [
    { label: Persona.DEVELOPER, value: Persona.DEVELOPER },
    { label: Persona.LENDER, value: Persona.LENDER },
    { label: Persona.ARCHITECT, value: Persona.ARCHITECT },
    { label: Persona.CONTRACTOR, value: Persona.CONTRACTOR },
    { label: Persona.ASSET_MANAGER, value: Persona.ASSET_MANAGER },
    { label: Persona.GENERAL_CONTRACTOR, value: Persona.GENERAL_CONTRACTOR },
    { label: Persona.PROPERTY_MANAGER, value: Persona.PROPERTY_MANAGER },
    { label: Persona.LAWYER, value: Persona.LAWYER },
  ],
  "label"
);

const WorkspaceTasksFilterButton: React.FC<IWorkspaceTasksFilterButton> = ({
  initialFilters,
  showDeals = false,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedFilters, setSelectedFilters] =
    useCaching<ITypeOfFilter>(initialFilters);
  const [applyFilters, setApplyFilters] = useState<ITypeOfFilter>({});

  const { data: priorityList } = usePriorityList();
  const { data: tagList } = useTagList();
  const { data: deals } = useDealListForTaskFilters();

  const priorityOptions = useMemo(
    () =>
      priorityList?.map(({ id, name }) => ({ label: name, value: id })) || [],
    [priorityList]
  );

  const tagOptions = useMemo(() => {
    const tags =
      tagList?.map(({ id, name }) => ({ label: name, value: id })) || [];
    return _.orderBy(tags, "label");
  }, [tagList]);

  const dealsOptions = useMemo(
    () =>
      (deals &&
        deals.deals &&
        deals.deals.map(({ id, name }) => ({
          label: name,
          value: id,
        }))) ||
      [],
    [deals]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    (filterKey: string, values: Array<string | number>) => {
      setSelectedFilters((current) => ({
        ...current,
        [filterKey]: values,
      }));
    },
    [setSelectedFilters]
  );

  const handleApplyFilter = useCallback(() => {
    setApplyFilters(selectedFilters);
    onChange(selectedFilters);
    handleClose();
  }, [selectedFilters, onChange, handleClose]);

  const handleCancel = useCallback(() => {
    handleClose();
    setSelectedFilters(applyFilters);
  }, [applyFilters, handleClose, setSelectedFilters]);

  const params = useParams<{
    dealId: string;
  }>();
  const dealId = Number(params.dealId);
  const { data: userPermission } = useDealPermission({ dealId });

  return (
    <>
      <Button
        startIcon={<FilterListIcon color="primary" />}
        variant="outlined"
        color="secondary"
        aria-describedby={id}
        onClick={handleClick}
      >
        Filters
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ bottom: 0, maxHeight: "50vh" }}
      >
        <Box data-testid="filters-popup-dialog">
          <Stack
            spacing={0.5}
            direction="row"
            sx={{ marginBottom: theme.spacing(2), padding: theme.spacing(2) }}
            data-testid="filters-checkbox-tree"
          >
            {userPermission?.is_full_access && (
              <CheckboxTree
                label="Teams"
                options={teamsFilterOptions}
                value={_.get(selectedFilters, "teams", []) as FilterValue[]}
                onChange={(values) => handleSelect("teams", values)}
              />
            )}
            <CheckboxTree
              label="Priority"
              options={priorityOptions}
              value={_.get(selectedFilters, "priority", []) as FilterValue[]}
              onChange={(values) => handleSelect("priority", values)}
            />
            <CheckboxTree
              label="Tags"
              options={tagOptions}
              value={_.get(selectedFilters, "tags", []) as FilterValue[]}
              onChange={(values) => handleSelect("tags", values)}
            />
            {showDeals && (
              <CheckboxTree
                label="Deals"
                options={dealsOptions}
                value={_.get(selectedFilters, "deals", []) as FilterValue[]}
                onChange={(values) => handleSelect("deals", values)}
              />
            )}
          </Stack>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            sx={{
              position: "sticky",
              bottom: -1,
              backgroundColor: colors.white,
              padding: theme.spacing(2),
            }}
          >
            <Button
              variant="text"
              onClick={handleCancel}
              data-testid="close-filters-dialog-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleApplyFilter}
              data-testid="apply-filters-button"
            >
              Apply filter
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default WorkspaceTasksFilterButton;

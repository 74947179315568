import React, { useCallback } from "react";

import CompanyForm from "entities/Company/components/Form";
import { ICompany, updateCompanyProfile } from "entities/Company/sdk";

export interface ICompanyEditForm {
  company: ICompany;
  onUpdated: () => void;
  handleCancel?: () => void;
}

const CompanyEditForm: React.FC<ICompanyEditForm> = ({
  company,
  onUpdated,
  handleCancel,
}) => {
  const handleSubmit = useCallback(
    (data: Partial<Omit<ICompany, "logo">>) =>
      updateCompanyProfile(company.id, data).then(() => {
        onUpdated();
      }),
    [onUpdated, company]
  );

  return (
    <CompanyForm
      submitLabel="Save changes"
      onSubmit={handleSubmit}
      handleCancel={handleCancel}
      initialValues={{
        name: company.name,
        locations: company.locations,
        company_size: company.company_size,
        established_in: company.established_in,
        web_url: company.web_url,
      }}
    />
  );
};

export default CompanyEditForm;

import { useCallback, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";

import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Text from "components/Text";
import TextField from "components/TextField";

import { dealTableCreate } from "entities/Deal/sdk";
import { ITableCell, ITableRow, tableCreate } from "entities/TableStorage/sdk";
import {
  applyChangesToTableData,
  removeRowsAndColumnsFromTableData,
} from "entities/TableStorage/utils";

import { VALIDATION_SCHEMA } from "./constants";

export interface ISaveComparedTablesButton {
  dealId: number | null;
  tableData: Array<ITableRow>;
  disabledIndexRows: Array<number>;
  disabledIndexColumns: Array<number>;
  changedCells: {
    [key: string]: string | null;
  };
  onSave: ({ tableId }: { tableId: number }) => void;
}

const SaveComparedTablesButton = ({
  dealId,
  tableData,
  disabledIndexRows,
  disabledIndexColumns,
  changedCells,
  onSave,
}: ISaveComparedTablesButton) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSaveTable = useCallback(
    (values) => {
      const tableName = values.name;
      const tableDataWithChangedCells = applyChangesToTableData({
        tableData,
        changedCells,
      });

      const tableDataWithRemovedRowsAndColumns =
        removeRowsAndColumnsFromTableData({
          indexesOfRowsToBeRemoved: disabledIndexRows,
          indexesOfColumnsToBeRemoved: disabledIndexColumns,
          tableData: tableDataWithChangedCells,
        });

      const rows: Array<Array<ITableCell>> =
        tableDataWithRemovedRowsAndColumns.map((row) => row.cells);

      if (!_.isNil(dealId)) {
        dealTableCreate({
          rows,
          name: tableName,
          dealId,
        })
          .then(({ id }) => onSave({ tableId: id }))
          .catch(handleInvalidRequest);
      } else {
        tableCreate({
          name: tableName,
          rows,
        })
          .then(({ id }) => onSave({ tableId: id }))
          .catch(handleInvalidRequest);
      }
    },
    [
      tableData,
      dealId,
      disabledIndexRows,
      disabledIndexColumns,
      changedCells,
      onSave,
    ]
  );

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSaveTable,
  });

  const handleSaveClick = useCallback(() => setIsDialogOpen(true), []);

  const handleCancel = useCallback(() => {
    if (!formik.isSubmitting) {
      formik.resetForm();
      setIsDialogOpen(false);
    }
  }, [formik]);

  return (
    <>
      <Button onClick={handleSaveClick}>Save the table</Button>
      <Dialog fullWidth open={isDialogOpen} onClose={handleCancel}>
        <DialogTitle
          sx={{
            margin: 2,
            borderRadius: "8px",
            backgroundColor: colors.blue10,
          }}
        >
          <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
            Save the table
          </Text>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ padding: 2 }}>
            <TextField
              label="Table name"
              placeholder="Enter table name"
              fullWidth
              {...formik.getFieldProps("name")}
              error={formik.touched.name && !_.isEmpty(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button
              variant="text"
              onClick={handleCancel}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SaveComparedTablesButton;

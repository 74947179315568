const TOKEN_KEY = "token";
export const IDLE_TIME = "idle_time";

export const saveToken = (token: string) => {
  window.localStorage.setItem(TOKEN_KEY, token);
};

export const removeToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () => {
  // Check if window.localStorage is available
  if (window.localStorage) {
    return window.localStorage.getItem(TOKEN_KEY);
  } else {
    // Handle the case when localStorage is not available
    return null;
  }
};

export const setItem = ({ key, value }: { key: string; value: string }) => {
  window.localStorage.setItem(key, value);
};

export const getItem = (key: string) => {
  return window.localStorage.getItem(key);
};

export const removeItem = (key: string) => {
  return window.localStorage.removeItem(key);
};

export const clear = () => {
  return window.localStorage.clear();
};

import { memo, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";

import { useCaching } from "utils/common";
import { TABLE_CELL_STYLES, TABLE_HEADER_STYLES } from "utils/constants";

import Text from "components/Text";

import { ECellType } from "./constants";

export interface ICell {
  cellType: ECellType;
  index: number;
  disabled: boolean;
  editMode: boolean;
  initialValue: string | null;
  onChange: ({
    value,
    cellIndex,
  }: {
    value: string;
    cellIndex: number;
  }) => void;
  resetCellValue?: boolean;
}

const Cell = ({
  cellType,
  index,
  disabled,
  editMode,
  initialValue,
  onChange,
  resetCellValue = false,
}: ICell) => {
  const [value, setValue] = useCaching(
    _.isNull(initialValue) ? "" : initialValue
  );

  useEffect(() => {
    if (!_.isUndefined(resetCellValue) && resetCellValue) {
      setValue(_.isNull(initialValue) ? "" : initialValue);
    }
  }, [resetCellValue, setValue, initialValue]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      onChange({
        value: event.target.value,
        cellIndex: index,
      });
    },
    [onChange, index]
  );

  const ElementType = useMemo(
    () => (cellType === ECellType.HEADER ? "th" : "td"),
    [cellType]
  );

  const styles = useMemo(() => {
    if (cellType === ECellType.CELL) {
      return {
        ...TABLE_CELL_STYLES,
        opacity: disabled ? 0.5 : 1,
      };
    }

    return {
      ...TABLE_HEADER_STYLES,
      opacity: disabled ? 0.5 : 1,
    };
  }, [cellType, disabled]);

  const fontWeight = useMemo(() => {
    if (cellType === ECellType.CELL) {
      return 400;
    }

    return 500;
  }, [cellType]);

  return (
    <ElementType style={styles}>
      {editMode ? (
        <input
          style={{
            ...styles,
            border: 0,
            fontSize: "16px",
            width: "100%",
            minWidth: "100px",
          }}
          value={value}
          onBlur={handleBlur}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <Text
          variant="text2"
          fontWeight={fontWeight}
          textAlign="left"
          whiteSpace="pre-line"
        >
          {initialValue}
        </Text>
      )}
    </ElementType>
  );
};

export default memo(Cell, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

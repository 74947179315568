import { useCallback } from "react";

import { downloadFileWithFileName } from "utils/file";

import FilesListDialog from "components/FilesListDialog";

import {
  getAssetManagementFile,
  useAssetManagementFileList,
} from "entities/AssetManagement/sdk";
import { IUploadedFile } from "entities/File/sdk";

interface IAssetManagementFilesList {
  open: boolean;
  onClose: () => void;
  dealId: number;
}

const AssetManagementFilesList = ({
  open,
  dealId,
  onClose,
}: IAssetManagementFilesList) => {
  const {
    data,
    pagesCount,
    setPage,
    currentPage,
    setCurrentPage,
    setOffset,
    isLoading,
  } = useAssetManagementFileList({
    dealId,
    enabled: open,
  });

  const handleClose = useCallback(() => {
    onClose();
    setOffset(0);
    setCurrentPage(1);
  }, [onClose, setOffset, setCurrentPage]);

  const handleFileDownload = useCallback(
    ({ file }: { file: IUploadedFile }) =>
      getAssetManagementFile(dealId, file.id).then((response) =>
        downloadFileWithFileName({
          fileName: response.original_file_name,
          fileUrl: response.url,
        })
      ),
    [dealId]
  );

  return (
    <FilesListDialog
      isOpen={open}
      onClose={handleClose}
      currentPage={currentPage}
      data={data?.results || []}
      onSetPage={setPage}
      pagesCount={pagesCount}
      isLoading={isLoading}
      onDownload={handleFileDownload}
    />
  );
};

export default AssetManagementFilesList;

import { useEffect, useState } from "react";

import { colors } from "theme/palette";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import MapElement from "components/Map";
import Stack from "components/Stack";
import Text from "components/Text";

import LocationEditButton from "entities/Deal/components/OverviewLocation/LocationEditButton";
import LocationTable from "entities/Deal/components/OverviewLocation/LocationTable";
import { IDeal, IMarker, useDealCoreData } from "entities/Deal/sdk";

const mapOptions = {
  zoom: 15,
};
interface IOverviewLocation {
  deal: IDeal;
}

const OverviewLocation: React.FC<IOverviewLocation> = ({ deal }) => {
  const [markers, setMarkers] = useState<IMarker[]>([]);

  const { data: dealCoreData } = useDealCoreData({
    dealId: deal.id,
  });

  useEffect(() => {
    if (
      dealCoreData &&
      deal &&
      deal.address.longitude &&
      deal.address.latitude
    ) {
      const mapMarkers = [
        {
          center: {
            lng: Number(deal.address.longitude),
            lat: Number(deal.address.latitude),
          },
          contentdata: {
            project_name: deal.name,
            address: `${deal.address.street_address}, ${deal.address.city}, ${deal.address.zipcode}`,
            thumbnail: deal.thumbnail,
            units: dealCoreData?.total_units || 0,
          },
        },
      ];

      setMarkers(mapMarkers);
    }
  }, [deal, dealCoreData]);

  return (
    <Box>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="h2" data-testid="section-header-name">
            Location
          </Text>
          <LocationEditButton />
        </Stack>
        <ContentSeparator label="Location" />
        {deal.address && <LocationTable address={deal.address} />}
        <Box
          sx={{
            height: "400px",
            display: "flex",
            backgroundColor: colors.gray20,
          }}
        >
          <MapElement
            options={mapOptions}
            markers={markers}
            showMarkersButton={false}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default OverviewLocation;

import { useCallback } from "react";
import _ from "lodash";

import { DeleteIcon } from "icons";
import theme from "theme";

import Box from "components/Box";
import Button from "components/Button";
import Image from "components/Image";
import NoPrint from "components/NoPrint";
import toast from "components/Toast";
import { IMAGE_ERROR_NOTIFICATION } from "components/UploadButton/constants";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import UploadImage from "entities/Deal/components/OverviewSections/UploadImage";
import {
  deleteDealCoverImage,
  IDeal,
  uploadDealCoverImage,
} from "entities/Deal/sdk";
import { useDealDetailsManager } from "entities/Memo/utils";

interface IProjectImage {
  deal: IDeal;
  externalToken?: string;
  editable?: boolean;
}

const ProjectImage = ({ deal, externalToken, editable }: IProjectImage) => {
  const { mutate: updateDeal } = useDealDetailsManager({
    dealId: deal.id,
    token: externalToken,
  });
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleUploadDealCover = useCallback(
    ({ imageId }: { imageId: number }) => {
      uploadDealCoverImage({ dealId: deal.id, imageId })
        .then((res) => {
          updateDeal({ ...deal, thumbnail: res.image });
          toast.successMessage("Project image successfully uploaded!");
        })
        .catch(({ image }) => {
          if (image?.length) {
            toast.errorMessage(IMAGE_ERROR_NOTIFICATION);
          }
        });
    },
    [deal, updateDeal]
  );

  const onDeleteImage = useCallback(
    () =>
      deleteDealCoverImage(deal.id, deal.thumbnail_id).then(() => {
        toast.successMessage("Project image successfully deleted!");
        updateDeal({ ...deal, thumbnail: "" });
      }),
    [deal, updateDeal]
  );

  const handleDeleteImage = useCallback(
    () =>
      showConfirmationDialog({
        onConfirm: () => onDeleteImage(),
        message: "Are you sure you want to delete this image?",
      }),
    [onDeleteImage, showConfirmationDialog]
  );

  const editAccess = _.isNil(externalToken) && editable;

  return (
    <>
      {!deal.thumbnail && editAccess && (
        <NoPrint>
          <UploadImage
            onChange={handleUploadDealCover}
            dataTestid="upload-deal-cover-image-input"
          />
        </NoPrint>
      )}
      {deal.thumbnail && (
        <Box>
          <Image
            src={deal.thumbnail}
            imgix
            imgixHeight={920}
            sx={{
              height: "460px",
              width: "100%",
              objectFit: "cover",
              marginBottom: theme.spacing(2),
            }}
            imageDataTestid="uploaded-deal-cover-image"
          />
          <NoPrint
            sx={{
              textAlign: "right",
            }}
          >
            {editAccess && (
              <Button
                variant="text"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteImage}
                data-testid="delete-deal-cover-image-button"
              >
                Delete image
              </Button>
            )}
          </NoPrint>
        </Box>
      )}
    </>
  );
};

export default ProjectImage;

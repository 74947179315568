import { useCallback, useMemo } from "react";
import _ from "lodash";

import Box from "components/Box";
import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";

import { useDealTable } from "entities/Deal/sdk";
import Table from "entities/TableStorage/components/Table";
import { ITableList, useTableRetrieve } from "entities/TableStorage/sdk";

export interface ISelectedTable {
  table: ITableList;
  onRemoveSelectedTable?: ({ tableId }: { tableId: number }) => void;
  showRemoveButton?: boolean;
}

const SelectedTable = ({
  table: selectedTable,
  onRemoveSelectedTable,
  showRemoveButton = false,
}: ISelectedTable) => {
  const dealId = useMemo(
    () => _.get(selectedTable, "deal.id", null),
    [selectedTable]
  );

  const { data: table } = useTableRetrieve({
    tableId: selectedTable.id,
    enabled: _.isNull(selectedTable.deal),
  });

  const { data: dealTable } = useDealTable({
    dealId,
    tableId: selectedTable.id,
    enabled: !_.isNull(selectedTable.deal),
  });

  const tableRows = useMemo(() => {
    if (!_.isUndefined(table)) {
      return table.rows;
    }

    if (!_.isUndefined(dealTable)) {
      return dealTable.rows;
    }

    return [];
  }, [table, dealTable]);

  const tableName = useMemo(() => {
    if (!_.isUndefined(table)) {
      return table.name;
    }

    if (!_.isUndefined(dealTable)) {
      return dealTable.name;
    }

    return "";
  }, [table, dealTable]);

  const dealName = useMemo(() => {
    if (!_.isUndefined(dealTable) && !_.isNil(dealTable.deal)) {
      return dealTable.deal.name;
    }

    return "";
  }, [dealTable]);

  const handleRemoveSelectedTable = useCallback(
    () =>
      !_.isUndefined(onRemoveSelectedTable) &&
      !_.isUndefined(showRemoveButton) &&
      onRemoveSelectedTable({ tableId: selectedTable.id }),
    [showRemoveButton, onRemoveSelectedTable, selectedTable.id]
  );

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Text variant="text1">{tableName}</Text>
          {!_.isEmpty(dealName) && (
            <Text variant="text2">Deal: {dealName}</Text>
          )}
        </Box>
        {showRemoveButton && (
          <Button
            variant="outlined"
            size="small"
            onClick={handleRemoveSelectedTable}
          >
            Remove the table
          </Button>
        )}
      </Stack>
      {!_.isEmpty(tableRows) && <Table tableData={tableRows} />}
    </Stack>
  );
};

export default SelectedTable;

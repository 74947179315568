import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { OpenInNewIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

import { useSignedUser } from "entities/Auth/sdk";
import {
  INotification as INotificationEntity,
  notificationMarkAsSeen,
} from "entities/Notification/sdk";
import Tags from "entities/Notification/Tags";
import { creationDateRetrieveReadable } from "entities/Notification/utils";

export interface INotification {
  notification: INotificationEntity;
  clickCallback: () => void;
  hasTopBorder?: boolean;
}

const Notification = ({
  notification,
  clickCallback,
  hasTopBorder = true,
}: INotification) => {
  const navigate = useNavigate();
  const { mutate: refetchUser } = useSignedUser();

  const creationDate = useMemo(
    () =>
      creationDateRetrieveReadable({
        creationDate: notification.created_at,
      }),
    [notification.created_at]
  );

  const handleClick = useCallback(() => {
    notificationMarkAsSeen({ notificationId: notification.id }).then(() => {
      refetchUser();
      navigate(notification.target_pathname);
      clickCallback();
    });
  }, [notification, navigate, refetchUser, clickCallback]);

  const isSeen = useMemo(
    () => !_.isNil(notification.seen_at),
    [notification.seen_at]
  );

  return (
    <Stack
      spacing={0.5}
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        padding: theme.spacing(1, 2),
        borderTop: hasTopBorder ? `1px solid ${colors.gray20}` : "none",
        justifyContent: "space-between",
        transition: "background-color 0.2s ease-in-out",
        "&:hover": {
          backgroundColor: colors.blue20,
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Tags notification={notification} />

        <OpenInNewIcon />
      </Stack>

      <Stack spacing={1} direction="row">
        {!isSeen && (
          <div
            style={{
              width: "8px",
              height: "8px",
              flexShrink: 0,
              marginTop: "8px",
              borderRadius: "50%",
              backgroundColor: colors.blue100,
            }}
          />
        )}
        <Text
          variant="text2"
          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {notification.content}
        </Text>
      </Stack>

      <Text variant="text3" whiteSpace="nowrap" color={colors.gray60}>
        {creationDate} by {notification.actor.first_name}{" "}
        {notification.actor.last_name}
      </Text>
    </Stack>
  );
};

export default Notification;

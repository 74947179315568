import { useCallback } from "react";

import Pagination from "components/Pagination";
import Stack from "components/Stack";

export interface IListPagination {
  setPage: (page: number) => void;
  pagesCount: number;
  currentPage?: number;
}

const ListPagination = ({
  setPage,
  pagesCount,
  currentPage,
}: IListPagination) => {
  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, selectedPage: number) => {
      setPage(selectedPage);
    },
    [setPage]
  );

  return pagesCount > 1 ? (
    <Stack alignItems="center">
      <Pagination
        page={currentPage}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        onChange={handlePageChange}
      />
    </Stack>
  ) : null;
};

export default ListPagination;

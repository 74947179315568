import React from "react";

import { URLS } from "config/urls";
import { LaunchIcon } from "icons";
import { colors } from "theme/palette";
import { reverse } from "utils/urls";

import Box from "components/Box";
import DealAvatar from "components/DealAvatar";
import IconButton from "components/IconButton";
import Link from "components/Link";
import {
  DEAL_AVATAR,
  DEAL_ID,
  DEAL_NAME,
} from "components/ScrollableTable/Cells/DealNameCell/constants";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

// TODO: Think if it's a good solution to receive "actionButtonsVariant" and let the component choose the buttons by itself, instead of receive them from the outside.
export interface IDealNameCell {
  row: any;
  actionButtons?: React.ReactNode;
}

const DealNameCell = ({ row, actionButtons }: IDealNameCell) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Stack spacing={1} alignItems="center" direction="row">
        <DealAvatar
          imgix
          imgixWidth={40}
          imgixHeight={40}
          sx={{ width: "24px", height: "24px" }}
          src={row[DEAL_AVATAR] || undefined}
        />
        <Link to={reverse(URLS.DEAL_OVERVIEW, { dealId: row[DEAL_ID] })}>
          <Tooltip
            disableInteractive
            title={row[DEAL_NAME]}
            enterDelay={500}
            enterNextDelay={1000}
          >
            <Text
              noWrap={true}
              variant="text2"
              sx={{ fontWeight: 500, maxWidth: "170px" }}
            >
              {row[DEAL_NAME]}
            </Text>
          </Tooltip>
        </Link>
      </Stack>
      {actionButtons ? (
        actionButtons
      ) : (
        <Link
          to={reverse(URLS.DEAL_OVERVIEW, { dealId: row[DEAL_ID] })}
          target="_blank"
        >
          <IconButton
            sx={{
              borderRadius: "2px",
              backgroundColor: colors.blue10,
              width: "20px",
              height: "20px",
            }}
          >
            <LaunchIcon style={{ width: 12, height: 12 }} />
          </IconButton>
        </Link>
      )}
    </Box>
  );
};

export default React.memo(DealNameCell);

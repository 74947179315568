import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import {
  clear as clearStorage,
  getItem,
  getToken,
  IDLE_TIME,
  setItem,
} from "utils/storage";

import Backdrop from "components/Backdrop";
import Button from "components/Button";
import Link from "components/Link";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";

import { IDLE_TIME_INTERVAL, MAX_IDLE_TIME } from "./constants";

let intervalVar: any = undefined;

const AutomaticLogout: React.FC<{}> = () => {
  const [isTimeout, setIsTimeout] = useState(false);

  const updateIdleTime = _.throttle(() => {
    setItem({
      key: IDLE_TIME,
      value: (Date.now() + MAX_IDLE_TIME * 60000).toString(),
    });
  }, 500);

  useEffect(() => {
    window.addEventListener("mousemove", updateIdleTime);
    window.addEventListener("scroll", updateIdleTime);
    window.addEventListener("click", updateIdleTime);
    window.addEventListener("keydown", updateIdleTime);

    return () => {
      window.removeEventListener("mousemove", updateIdleTime);
      window.removeEventListener("scroll", updateIdleTime);
      window.removeEventListener("click", updateIdleTime);
      window.removeEventListener("keydown", updateIdleTime);
    };
  }, [updateIdleTime]);

  useEffect(() => {
    updateIdleTime();

    if (!intervalVar) {
      intervalVar = setInterval(() => {
        const idle_time = parseInt(
          getItem(IDLE_TIME) || (Date.now() + MAX_IDLE_TIME * 60000).toString()
        );

        if (!getToken()) {
          intervalVar = clearTimeout(intervalVar);
          clearStorage();
          setIsTimeout(true);
          Sentry.setUser(null);
        }

        if (idle_time < Date.now()) {
          intervalVar = clearTimeout(intervalVar);
          setIsTimeout(true);
        }
      }, IDLE_TIME_INTERVAL);
    }

    return () => (intervalVar = clearTimeout(intervalVar));
  }, [updateIdleTime]);

  return (
    <>
      <Backdrop
        open={isTimeout}
        sx={{
          alignItems: "flex-start",
          zIndex: 1500,
          backgroundColor: colors.white,
          opacity: 0.9,
        }}
      >
        <Paper
          sx={{ padding: theme.spacing(4), marginTop: theme.spacing(15) }}
          elevation={4}
        >
          <Stack alignItems="center" spacing={1}>
            <Text variant="h3">
              Your session has expired, please log in again to continue
            </Text>
            <Link to={`/?next=${window.location.href}`} external={true}>
              <Button size="large">Log in</Button>
            </Link>
          </Stack>
        </Paper>
      </Backdrop>
      <Outlet />
    </>
  );
};

export default AutomaticLogout;

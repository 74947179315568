import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { fieldOptionRetrieve, useCaching } from "utils/common";

import Button from "components/Button";
import IconButton from "components/IconButton";
import NoPrint from "components/NoPrint";
import Select from "components/Select";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast from "components/Toast";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import { fieldValueCreate } from "entities/Field/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";
import { handleInvalidMemoFieldRequest } from "entities/Memo/components/Fields/utils/utils";
import { IFieldOption } from "entities/Package/sdk";

interface IDropdownField {
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
}

const DropdownField = ({
  field,
  dealId,
  externalToken,
  isNASection,
  onUpdate,
}: IDropdownField) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useCaching<IFieldOption | null>(
    fieldOptionRetrieve({
      options: field.options,
      value: _.get(field.values, "[0].selected_value"),
    })
  );

  const navigate = useNavigate();

  const handleEditButtonClick = useCallback(() => setIsEdit(true), []);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(
        fieldOptionRetrieve({
          options: field.options,
          value: event.target.value,
        })
      );
    },
    [setValue, field.options]
  );
  const handleSave = useCallback(() => {
    fieldValueCreate({
      dealId,
      companyCustomFieldId: field.company_custom_field_id,
      value: value?.value || null,
    })
      .then(() => {
        setIsEdit(false);
        onUpdate();
        toast.successMessage(`${field.name} successfully updated!`);
      })
      .catch((error) =>
        handleInvalidMemoFieldRequest({ error, dealId, navigate })
      );
  }, [field, dealId, navigate, onUpdate, value?.value]);

  const handleCancel = useCallback(() => {
    setValue(
      fieldOptionRetrieve({
        options: field.options,
        value: _.get(field.values, "[0].selected_value"),
      })
    );
    setIsEdit(false);
  }, [setValue, field]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showMissingTag = useMemo(
    () => !isNAField && !value && !isEdit,
    [isNAField, value, isEdit]
  );

  const showFieldValue = useMemo(
    () => !isNAField && !isEdit && value,
    [isNAField, value, isEdit]
  );

  const showEditButton = useMemo(
    () => !isNAField && !isEdit && _.isUndefined(externalToken),
    [isNAField, isEdit, externalToken]
  );

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{ borderBottom: `1px solid ${colors.blue40}` }}
    >
      <FieldTitle
        field={field}
        onUpdate={onUpdate}
        externalToken={externalToken}
        disabled={isNAField}
      />

      <Stack direction="row" spacing={1} alignItems="center" sx={{ flex: 1 }}>
        {showMissingTag && (
          <NoPrint>
            <Tag label="missing" variant="red" dataTestid="empty-field-value" />
          </NoPrint>
        )}
        {showFieldValue && (
          <Text variant="text2" data-testid="field-value">
            {value?.label}
          </Text>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
        {showEditButton && (
          <NoPrint>
            <IconButton
              size="small"
              onClick={handleEditButtonClick}
              dataTestid="field-edit-button"
            >
              <EditIcon />
            </IconButton>
          </NoPrint>
        )}
        {isEdit && (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{ paddingBottom: theme.spacing(0.5) }}
          >
            <Select
              options={field.options}
              size="small"
              value={value?.value}
              onChange={handleChange}
              placeholder="Select data"
              selectProps={{ sx: { maxWidth: "25vw" } }}
              inputProps={{ "data-testid": "edit-dropdown-input" }}
              dropdownOptionsDataTestid="dropdown-select-option"
              data-testid="edit-dropdown-field"
            />
            <Button onClick={handleSave} data-testid="submit-button">
              Save
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleReset}
              data-testid="reset-button"
            >
              Reset
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DropdownField;

import {
  DEAL_NAME,
  DEAL_NAME_COLUMN,
} from "components/ScrollableTable/Cells/DealNameCell/constants";

import { EFieldFormatType } from "entities/Reporting/constants";

export const FIXED_COLUMNS = [
  {
    name: DEAL_NAME_COLUMN,
    children: [
      {
        name: DEAL_NAME,
        field_type: "",
        field_width: 300,
        field_format_type: EFieldFormatType.STRING,
      },
    ],
  },
];

export const CORE_DATA_FIELD_NAME_TO_READABLE_NAME_MAPPING = {
  STATUS: "Status",
  PACKAGE: "Package",
  "DEAL ID": "Deal ID",
  "DEAL NAME": "Deal Name",
  "DEAL PHASE": "Deal Phase",
  "DEAL STATUS": "Deal Status",
  CONSTRUCTION: "Construction",
  "CONSTRUCTION TYPE": "Construction Type",
  "NUMBER OF UNITS": "Number of Units",
  "RENT TYPE": "Rent Type",
  "ADDRESS LINE 1": "Address Line 1",
  CITY: "City",
  ZIPCODE: "Zipcode",
  STATE: "State",
  "LAST UPDATED": "Last Updated",
  ASSET_TYPE: "Asset Type",
  "PROPERTY TYPE": "Property Type",
  PHASE: "Phase",
  "PROJECT NARRATIVE": "Project Narrative",
  "OWNERSHIP TYPE": "Ownership Type",
  "OWNED BY": "Owned By",
  "LEGAL NAME": "Legal Name",
};

import _ from "lodash";

import { URLS } from "config/urls";
import { addQueryParams, reverse } from "utils/urls";

import { ILinkedFieldCompanyPackage } from "entities/Field/sdk";

import { ILinkedFieldRow } from "./Connections";
import { LINK_ID_TO_DEAL_FIELD_MAPPING } from "./constants";
import { ICurrentItem } from "./CurrentItem";

export const getLinkedFields = ({
  checklists,
  fieldId,
  dealId,
}: {
  checklists: Array<ILinkedFieldCompanyPackage>;
  fieldId: number;
  dealId?: number;
}) => {
  const connections: Array<ILinkedFieldRow> = [];
  let currentField: ICurrentItem | null = null;

  let linkId = "";

  checklists.forEach((checklist) =>
    checklist.tabs.forEach((tab) =>
      tab.categories.forEach((category) =>
        category.sections.forEach((section) =>
          section.fields.forEach((field) => {
            linkId = field.link_id;
            const url = _.isNil(dealId)
              ? reverse(URLS.COMPANY_CHECKLISTS_PREVIEW, {
                  checklistId: checklist.id,
                })
              : field.has_access
                ? addQueryParams(
                    reverse(URLS.DEAL_PACKAGE, {
                      dealId,
                      packageId: checklist.id,
                      tabId: tab.id,
                    }),
                    { fieldId: field.id }
                  )
                : undefined;

            if (field.id === fieldId) {
              currentField = {
                packageName: checklist.name,
                tabName: tab.name,
                categoryName: category.name,
                sectionName: section.name,
                fieldName: field.name,
              };
            } else {
              connections.push({
                linkedFieldName: field.name,
                linkedFieldsubTitle: [tab.name, category.name, section.name],
                checklistName: checklist.name,
                checklistSubTitle: "Checklist",
                isOverridden: field.is_overridden,
                url,
              });
            }
          })
        )
      )
    )
  );

  if (Object.keys(LINK_ID_TO_DEAL_FIELD_MAPPING).includes(linkId)) {
    connections.push({
      linkedFieldName: LINK_ID_TO_DEAL_FIELD_MAPPING[linkId],
      linkedFieldsubTitle: "Basic Deal Details",
      checklistName: "Deal Overview",
      checklistSubTitle: "Deal Overview",
      isOverridden: false,
    });
  }

  return { connections, currentField };
};

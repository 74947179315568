export enum TaskHeaderKeys {
  DEAL_TASK_ID = "deal_task_id",
  DEAL_ID = "deal_id",
  DEAL_NAME = "deal_name",
  DEAL_AVATAR = "deal_avatar",
  TASK_CHANNEL_ID = "task_channel_id",
  COMPLETED = "completed",
  TASK_TITLE = "taskTitle",
  PRIORITY = "priority",
  DUE_DATE = "dueDate",
  TAGS = "tags",
  ASSIGNED_TO = "assignedTo",
  UNREAD_COUNT = "unreadCount",
  DETAILS = "details",
  IS_PRIVATE = "isPrivate",
  COMPANY_PACKAGE = "companyPackage",
  COMPANY_TAB = "companyTab",
  COMPANY_CATEGORY = "companyCategory",
  COMPANY_SECTION = "companySection",
  COMPANY_FIELD = "companyField",
}

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import theme from "theme";
import { colors } from "theme/palette";
import { scrollToSelectedAnchor } from "utils/common";

import { ID as MAIN_NAVIGATION_ID } from "components/MainNavigation";
import Paper from "components/Paper";
import Stack from "components/Stack";
import StickyMenu from "components/StickyMenu";
import { useFirstVisibleAnchor } from "components/StickyMenu/utils";
import Text from "components/Text";

import { ID as COMPANY_SETTINGS_SUBSCRIPTION_DETAILS_ID } from "entities/Company/components/CompanySettings/SubscriptionDetails";
import { ID as COMPANY_PROFILE_SECTION_ID } from "entities/Company/components/ProfileInfoBox";
import { ID as COMPANY_TEAM_SECTION_ID } from "entities/Company/components/TeamInfoBox";
import { ID as USER_INFO_BOX_ID } from "entities/User/components/InfoBox";
import { ANCHORS } from "entities/User/components/StickySidebar/constants";
import InPageLink from "entities/User/components/StickySidebar/InPageLink";

const StickySidebar = () => {
  const firstVisibleAnchor = useFirstVisibleAnchor(ANCHORS);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!_.isNil(searchParams) && searchParams.has("tab")) {
      scrollToSelectedAnchor({
        anchor: searchParams.get("tab") as string,
        navigationIds: [MAIN_NAVIGATION_ID],
      });
    }
  }, [searchParams]);

  return (
    <StickyMenu>
      <Paper
        sx={{
          width: "280px",
          padding: theme.spacing(2),
          border: `1px solid ${colors.blue40}`,
        }}
        data-testid="profile-side-panel"
      >
        <Stack spacing={1}>
          <Text variant="labelSmall" fontWeight={500} color={colors.gray40}>
            Profile
          </Text>
          <Stack spacing={1} data-testid="profile-section-navigations">
            <InPageLink
              anchor={USER_INFO_BOX_ID}
              selected={
                !firstVisibleAnchor || firstVisibleAnchor === USER_INFO_BOX_ID
              }
              dataTestid="user-profile-sec-link"
            >
              User profile
            </InPageLink>
            <InPageLink
              anchor={COMPANY_PROFILE_SECTION_ID}
              selected={firstVisibleAnchor === COMPANY_PROFILE_SECTION_ID}
              dataTestid="company-profile-sec-link"
            >
              Company profile
            </InPageLink>
            <InPageLink
              anchor={COMPANY_TEAM_SECTION_ID}
              selected={firstVisibleAnchor === COMPANY_TEAM_SECTION_ID}
              dataTestid="company-team-sec-link"
            >
              Company team
            </InPageLink>
            <InPageLink
              anchor={COMPANY_SETTINGS_SUBSCRIPTION_DETAILS_ID}
              selected={
                firstVisibleAnchor === COMPANY_SETTINGS_SUBSCRIPTION_DETAILS_ID
              }
            >
              Subscription details
            </InPageLink>
          </Stack>
        </Stack>
      </Paper>
    </StickyMenu>
  );
};

export default StickySidebar;

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { colors } from "theme/palette";
import { handleInvalidRequest } from "utils/sdk";
import { reverse } from "utils/urls";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Text from "components/Text";
import TextField from "components/TextField";
import toast from "components/Toast";

import { ITableCell, tableCreate } from "entities/TableStorage/sdk";

export interface ITableCreateDialog {
  close: () => void;
  initialData: null | Array<Array<ITableCell>>;
}

const TableCreateDialog = ({ initialData, close }: ITableCreateDialog) => {
  const [tableName, setTableName] = useState("");

  const navigate = useNavigate();

  const isOpen = !_.isNull(initialData);

  useEffect(() => {
    if (!isOpen) {
      setTableName("");
    }
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    if (_.isNull(initialData)) {
      return;
    }

    if (_.isEmpty(tableName)) {
      toast.errorMessage("Table name is required");
      return;
    }

    tableCreate({
      name: tableName,
      rows: initialData,
    })
      .then(({ id: tableId }) => {
        toast.successMessage(
          <>
            <Text variant="text2" component="span">
              Table has been created successfully! Click{" "}
            </Text>
            <Text
              component="span"
              variant="subtitle1"
              color="primary"
              onClick={() =>
                navigate(
                  reverse(URLS.TABLE_STORAGE_DETAILS, {
                    tableId,
                  })
                )
              }
            >
              here
            </Text>{" "}
            <Text variant="text2" component="span">
              to view the result.
            </Text>
          </>,
          { autoClose: false }
        );

        close();
      })
      .catch(handleInvalidRequest);
  }, [tableName, initialData, close, navigate]);

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={close}>
      <DialogTitle
        sx={{
          margin: 2,
          borderRadius: "8px",
          backgroundColor: colors.blue10,
        }}
      >
        <Text variant="h2" marginBottom={0.5} color={colors.gray100}>
          Create table
        </Text>
      </DialogTitle>
      <DialogContent sx={{ padding: 2, overflowY: "visible" }}>
        <TextField
          label="Table name"
          InputLabelProps={{ shrink: true }}
          placeholder="Enter table name"
          sx={{ width: "100%" }}
          value={tableName}
          onChange={(e) => setTableName(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="text" onClick={close}>
          Cancel
        </Button>
        <Button type="submit" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TableCreateDialog;

import { URLS } from "config/urls";
import { VisibilityOutlinedIcon } from "icons";
import theme from "theme";
import { reverse } from "utils/urls";

import Card, { CardActionArea, CardContent } from "components/Card";
import Container from "components/Container";
import Grid from "components/Grid";
import IconButton from "components/IconButton";
import Link from "components/Link";
import { LinearProgress } from "components/Loading";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import { usePackageList } from "entities/Checklist/sdk";

const ChecklistList = () => {
  const { data: packages, isValidating } = usePackageList();

  usePageTitle("Builders Patch: Intake form templates");

  return (
    <Paper sx={{ padding: theme.spacing(4, 3) }}>
      <PageHeader title="Intake form templates" />
      <Container sx={{ paddingTop: theme.spacing(4) }}>
        {isValidating && (
          <LinearProgress sx={{ marginBottom: theme.spacing(2) }} />
        )}
        <Grid container spacing={4} data-testid="templates-content">
          {packages?.map(({ package_id, name }) => (
            <Grid item md={4} key={package_id}>
              <Card
                elevation={2}
                sx={{ position: "relative" }}
                data-testid="intake-template-card"
              >
                <Link
                  to={reverse(URLS.CHECKLIST_TEMPLATE_DETAILS, {
                    checklistId: package_id,
                  })}
                  linkDataTestid="template-details-page-link"
                >
                  <CardActionArea>
                    <CardContent>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        data-testid="intake-card-content"
                      >
                        <Text variant="h1" data-testid="template-name">
                          {name}
                        </Text>
                        <IconButton dataTestid="view-button">
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Paper>
  );
};

export default ChecklistList;

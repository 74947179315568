import { useEffect, useMemo } from "react";
import _ from "lodash";

import PusherManager from "utils/pusher-manager";

import { useSignedUser } from "entities/Auth/sdk";
import { useDealTeamMembers } from "entities/Team/sdk";

export const useLoggedUserIsDealAdmin = ({ dealId }: { dealId: number }) => {
  const { data: dealTeamMembers } = useDealTeamMembers({ dealId });
  const { data: signedUser } = useSignedUser();

  const loggedUserIsDealAdmin = useMemo(() => {
    const matchedUser = _.find(
      dealTeamMembers,
      (teamMember) =>
        teamMember.user.email.toLowerCase() === signedUser?.email.toLowerCase()
    );
    return matchedUser?.is_admin || false;
  }, [signedUser, dealTeamMembers]);

  return { loggedUserIsDealAdmin };
};

export const useDealPropertyUpdate = ({
  updateEventHandler,
}: {
  updateEventHandler: ({ dealId }: { dealId: number }) => void;
}) => {
  const { data: user } = useSignedUser();

  useEffect(() => {
    if (!_.isUndefined(user)) {
      return PusherManager.bindToDealPropertyUpdateEvent({
        userId: user.id,
        eventHandler: ({ deal_id }) => updateEventHandler({ dealId: deal_id }),
      });
    }
  }, [user, updateEventHandler]);
};

import { Dispatch, useCallback, useMemo } from "react";
import _ from "lodash";

import Button from "components/Button";
import InputAdornment from "components/InputAdornment";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import Tooltip from "components/Tooltip";

import { EFieldFormatType } from "entities/Reporting/constants";

const RangeFilter = ({
  label,
  type,
  selectedFilters,
  setSelectedFilters,
}: {
  label: string;
  type: EFieldFormatType;
  selectedFilters: { [key: string]: string[] };
  setSelectedFilters: Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
    }>
  >;
}) => {
  const showDollarSign = useMemo(
    () => type === EFieldFormatType.CURRENCY_USD,
    [type]
  );

  const step = useMemo(() => {
    if (
      type === EFieldFormatType.FLOAT_2 ||
      type === EFieldFormatType.CURRENCY_USD
    ) {
      return 0.01;
    } else if (type === EFieldFormatType.INTEGER) {
      return 1;
    }

    return undefined;
  }, [type]);

  const updateMin = useCallback(
    ({ label, value }: { label: string; value: string }) => {
      const maxValue = _.get(selectedFilters, [label, 1], "");

      setSelectedFilters((current: any) => ({
        ...current,
        [label]: [value, maxValue],
      }));
    },
    [selectedFilters, setSelectedFilters]
  );

  const updateMax = useCallback(
    ({ label, value }: { label: string; value: string }) => {
      const minValue = _.get(selectedFilters, [label, 0], "");

      setSelectedFilters((current: any) => ({
        ...current,
        [label]: [minValue, value],
      }));
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleReset = useCallback(() => {
    setSelectedFilters((current: any) => _.omit(current, [label]));
  }, [label, setSelectedFilters]);

  return (
    <Stack spacing={2}>
      <Tooltip title={label} enterDelay={1000} enterNextDelay={1000}>
        <Text variant="text2" fontWeight={700}>
          {label}
        </Text>
      </Tooltip>

      <TextField
        label="From"
        type="number"
        size="small"
        value={_.get(selectedFilters, [label, 0], "")}
        onChange={(e) =>
          updateMin({
            label: label,
            value: e.target.value,
          })
        }
        inputProps={{
          step: step,
        }}
        InputProps={{
          startAdornment: showDollarSign && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
      />
      <TextField
        label="To"
        type="number"
        size="small"
        value={_.get(selectedFilters, [label, 1], "")}
        onChange={(e) =>
          updateMax({
            label: label,
            value: e.target.value,
          })
        }
        inputProps={{
          step: step,
        }}
        InputProps={{
          startAdornment: showDollarSign && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
      />
      <Button
        variant="outlined"
        size="small"
        onClick={handleReset}
        sx={{ alignSelf: "flex-start" }}
      >
        Reset to default
      </Button>
    </Stack>
  );
};

export default RangeFilter;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import { DeleteOutlineOutlinedIcon } from "icons";
import {
  EXCEL_MIME_TYPE,
  MACRO_ENABLED_EXCEL_MIME_TYPE,
} from "utils/constants";
import { IS_USER_FACING_TABLE_IMPORT_ENABLED } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { handleInvalidRequest } from "utils/sdk";

import Button from "components/Button";
import { DropzoneState } from "components/Dropzone";
import IconButton from "components/IconButton";
import Skeleton from "components/Skeleton";
import Stack from "components/Stack";
import toast, { DEFAULT_ERROR_MESSAGE } from "components/Toast";
import UploadButton from "components/UploadButton";
import { useConfirmationDialog } from "components/useConfirmationDialog";

import { ECompanySettingsFeatureType } from "entities/Company/components/CompanySettings/constants";
import { useCheckFeature } from "entities/Company/components/CompanySettings/utils";
import { upload } from "entities/File/sdk";
import {
  ITable,
  tableImportDelete,
  tableImportImportFromExcel,
} from "entities/TableStorage/sdk";

export interface IHeaderSubTitle {
  table: ITable | undefined;
  importDeleteCallback: () => void;
  showImportUpdateDialog: () => void;
}

const HeaderSubTitle = ({
  table,
  importDeleteCallback,
  showImportUpdateDialog,
}: IHeaderSubTitle) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const tableSubTitle = useMemo(() => {
    if (_.isNil(table)) {
      return "";
    }

    if (!_.isNil(table.updated_by)) {
      return `Last edited ${format(
        parseJSON(table.updated_at),
        FORMATS.FRONTEND.DATETIME
      )} by ${table.updated_by.first_name} ${table.updated_by.last_name}`;
    }

    return `Last edited ${format(
      parseJSON(table.created_at),
      FORMATS.FRONTEND.DATETIME
    )} by ${table.created_by.first_name} ${table.created_by.last_name}`;
  }, [table]);

  const handleDeleteImport = useCallback(() => {
    if (_.isNil(table?.deal_company_import_table?.id)) {
      return;
    }

    showConfirmationDialog({
      onConfirm: () =>
        tableImportDelete({
          tableImportId: table?.deal_company_import_table?.id as number,
        })
          .then(() => {
            toast.successMessage("Import table deleted successfully");
            importDeleteCallback();
          })
          .catch(handleInvalidRequest),
      confirmButtonText: "Yes",
      message: "Are you sure you want to delete the range?",
    });
  }, [table, importDeleteCallback, showConfirmationDialog]);

  const handleFileUpload = useCallback(
    ({ files }: { files: Array<File> }) => {
      const file = files[0];

      if (
        ![EXCEL_MIME_TYPE, MACRO_ENABLED_EXCEL_MIME_TYPE].includes(file.type)
      ) {
        console.log(file.type);
        toast.errorMessage("Invalid file type. Please upload an Excel file.");
        return;
      }

      const toastId = toast.loading("Uploading files...");

      upload({ file, isPrivate: true })
        .then((fileId) => {
          tableImportImportFromExcel({
            tableImportId: table?.deal_company_import_table?.id as number,
            fileId,
          }).then(() => {
            toast.update(toastId, {
              render:
                "File has been uploaded successfully! You'll be notified once the processing finishes.",
              type: "success",
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              isLoading: false,
              closeButton: true,
            });
          });
        })
        .catch(() => {
          toast.errorMessage(DEFAULT_ERROR_MESSAGE);
          toast.update(toastId, {
            autoClose: 1,
            hideProgressBar: true,
            isLoading: false,
          });
        });
    },
    [table?.deal_company_import_table?.id]
  );

  const handleFileUploadWithPermissionCheck = useCheckFeature({
    feature: _.isNil(table?.deal)
      ? ECompanySettingsFeatureType.TABLES
      : ECompanySettingsFeatureType.DEAL_TABLES,
    callback: handleFileUpload,
  });

  return _.isNil(table) ? (
    <Skeleton />
  ) : (
    <>
      {tableSubTitle}
      <br />
      {!_.isNil(table?.deal_company_import_table) && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>
            Synced range: {table.deal_company_import_table.sheet_name}, Columns{" "}
            {table.deal_company_import_table.column_start}-
            {table.deal_company_import_table.column_end}, Rows{" "}
            {table.deal_company_import_table.row_start}-
            {table.deal_company_import_table.row_end}
          </span>
          {IS_USER_FACING_TABLE_IMPORT_ENABLED && (
            <>
              <UploadButton
                accept={[EXCEL_MIME_TYPE, MACRO_ENABLED_EXCEL_MIME_TYPE].join(
                  ","
                )}
                style={{ width: "unset", alignSelf: "flex-start" }}
                renderComponent={(state: Partial<DropzoneState>) => (
                  <Button
                    size="small"
                    variant={state.isDragActive ? "contained" : "outlined"}
                  >
                    Re-upload Excel
                  </Button>
                )}
                onChange={handleFileUploadWithPermissionCheck}
              />

              <Button
                size="small"
                variant="outlined"
                onClick={showImportUpdateDialog}
              >
                Edit range
              </Button>

              <IconButton size="small" title="Delete range">
                <DeleteOutlineOutlinedIcon
                  onClick={handleDeleteImport}
                  sx={{ width: "20px", height: "20px" }}
                />
              </IconButton>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default HeaderSubTitle;

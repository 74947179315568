import { BASE_URL } from "config/urls";
import { get, useFetch } from "utils/sdk";

import { CompanyFieldFormatType } from "entities/Field/constants";
import { FieldType } from "entities/Field/sdk";
import { IFile } from "entities/File/sdk";
import { IFieldOption, IPackage } from "entities/Package/sdk";

export interface IPackageDetailsFieldValue {
  default_value: string | null;
  value_id: number;
  selected_value: string | null;
  column_value: Array<{ [key: string]: string }>;
  rich_value: string | null;
  file: IFile | null;
  updated_at: string;
}

export interface IFieldTemplateMeta {
  field_id: number;
  field_name: string;
  field_type: FieldType;
  field_format_type: CompanyFieldFormatType;
  value: string;
  is_editable: boolean;
  is_linked: boolean;
  is_overridden: boolean;
  options?: Array<IFieldOption>;
}
export interface IPackageDetailsField {
  company_custom_field_id: number;
  company_package_id: number;
  field_type: FieldType;
  column_name: string;
  rows: Array<string>;
  options: Array<IFieldOption>;
  name: string;
  values: Array<IPackageDetailsFieldValue>;
  is_deleted?: boolean;
  n_a_status: boolean;
  columns?: Array<string>;
  template?: string;
  template_meta?: Array<IFieldTemplateMeta>;
  is_overridden: boolean;
  is_linked: boolean;
  field_format_type: CompanyFieldFormatType;
  instruction?: string | null;
}

export interface IPackageDetailsSection {
  section_id: number;
  name: string;
  fields: Array<IPackageDetailsField>;
  is_deleted?: boolean;
  instruction?: string | null;
}
export interface IPackageDetailsCategory {
  category_id: number;
  name: string;
  sections: Array<IPackageDetailsSection>;
  is_deleted?: boolean;
  instruction?: string | null;
}
export interface IPackageDetailsTab {
  tab_id: number;
  name: string;
  categories: Array<IPackageDetailsCategory>;
  is_deleted?: boolean;
}
export interface IPackageDetails {
  name: string;
  tabs: Array<IPackageDetailsTab>;
}

export interface ISharedChecklist {
  id: number;
  company_package: Omit<IPackage, "tabs">;
  name: string;
  notification_emails: Array<string>;
}

export const usePackageList = () =>
  useFetch<
    Array<{
      package_id: number;
      name: string;
    }>
  >("v2/due-diligence/package/list/");

export const usePackageDetails = ({ packageId }: { packageId?: number }) =>
  useFetch<IPackageDetails>(
    packageId ? `v2/due-diligence/package/${packageId}/details/` : undefined
  );

export const usePackageDetailsForSharing = ({
  packageId,
}: {
  packageId?: number;
}) =>
  useFetch<IPackageDetails>(
    packageId
      ? `v2/due-diligence/package/${packageId}/for-sharing/details/`
      : undefined
  );

export interface IChecklistShareToken {
  id: number;
  name: string;
  company_package: {
    company: {
      name: string;
      logo: string | null;
    };
  };
}

export const useSharedChecklist = ({ token }: { token?: string }) =>
  useFetch<IChecklistShareToken>(
    token
      ? `v2/due-diligence/checklist-share-token/${token}/retrieve/`
      : undefined
  );

export const checklistCheckIsShareable = ({
  packageId,
}: {
  packageId: number;
}) =>
  get<{ is_shareable: boolean; message?: Array<string> }>(
    `${BASE_URL}v2/due-diligence/company-package/${packageId}/check-is-shareable/`
  );

export const useLenderShareChecklistToken = ({
  packageId,
}: {
  packageId: number;
}) =>
  useFetch<Omit<ISharedChecklist, "company_package">>(
    `v2/due-diligence/company-package/${packageId}/checklist/retrieve/`
  );

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { useCaching } from "utils/common";
import { handleInvalidRequest } from "utils/sdk";

import Box from "components/Box";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import FieldDropdownMenu from "entities/Field/components/FieldDropdownMenu";
import EditableField from "entities/Field/components/Textfield/EditableField";
import FieldTitle from "entities/Field/components/Title";
import { CompanyFieldFormatType } from "entities/Field/constants";
import { fieldValueCreate, IField } from "entities/Field/sdk";
import { formatFieldValue } from "entities/Field/utils";
import DueDiligenceFieldOpenTaskButton from "entities/Task/components/DueDiligenceFieldOpenTaskButton";
import { ITaskChannel } from "entities/Task/sdk";

import { FIELD_WIDTH } from "./constants";

export interface ITextfield {
  editable: boolean;
  field: IField;
  taskChannels: Array<ITaskChannel>;
  updatePackage: () => void;
  dealId: number;
  packageId: number;
  categoryId: number;
  tabId: number;
  sectionId: number;
  onUpdate: ({ message }: { message?: string }) => void;
  isFocused?: boolean;
}

const Textfield: React.FC<ITextfield> = ({
  editable,
  field,
  taskChannels,
  updatePackage,
  dealId,
  packageId,
  categoryId,
  tabId,
  sectionId,
  onUpdate,
  isFocused = false,
}) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useCaching<string | number | null | undefined>(
    _.get(field.custom_values, "[0].default_value")
  );

  const handleEditButtonClick = useCallback(() => setIsEdit(true), []);
  const handleChange = useCallback(
    (newValue: string | number | null | undefined) => {
      setValue(newValue);
    },
    [setValue]
  );

  const handleSave = useCallback(() => {
    fieldValueCreate({
      dealId,
      companyCustomFieldId: field.field_id,
      value: _.isUndefined(value) ? "" : value,
    })
      .then(() => {
        setIsEdit(false);
        onUpdate({ message: `${field.display_name} successfully updated!` });
      })
      .catch(handleInvalidRequest);
  }, [value, field, dealId, onUpdate]);

  const handleCancel = useCallback(() => {
    setValue(_.get(field.custom_values, "[0].default_value"));
    setIsSaveButtonDisabled(false);
    setIsEdit(false);
  }, [setValue, field]);

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const hasTasksAndNotEditing = useMemo(
    () => taskChannels.length > 0 && !isEdit,
    [taskChannels, isEdit]
  );

  const showEditButton = useMemo(
    () => !field.n_a_status && editable,
    [field, editable]
  );

  const showResetButton = useMemo(
    () => field.field_format_type === CompanyFieldFormatType.DATE,
    [field]
  );

  return (
    <Stack
      id={field.field_id.toString()}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${colors.blue20}`,
        backgroundColor: isFocused ? colors.green10 : "initial",
      }}
      data-testid="dd-field"
    >
      <Box sx={{ flexShrink: 1, maxWidth: "100%", overflow: "hidden" }}>
        <FieldTitle
          field={field}
          dealId={dealId}
          packageId={packageId}
          categoryId={categoryId}
          tabId={tabId}
          sectionId={sectionId}
          onUpdate={onUpdate}
        />
      </Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ flexGrow: 0 }}
      >
        {hasTasksAndNotEditing && (
          <DueDiligenceFieldOpenTaskButton
            taskChannels={taskChannels}
            company_field={field}
            dealId={dealId}
            tabId={tabId}
            sectionId={sectionId}
            categoryId={categoryId}
            packageId={packageId}
            updatePackage={updatePackage}
            taskButtonDataTestid="dd-open-task-button"
            taskMessagesButtonDataTestid="dd-open-new-task-messages-button"
          />
        )}
        {!field.n_a_status && !isEdit && !value && (
          <Tag label="missing" variant="red" dataTestid="empty-field-value" />
        )}
        {!field.n_a_status && !isEdit && value && (
          <Tooltip title={value}>
            <Text
              variant="text2"
              sx={{
                wordBreak: "break-word",
              }}
              noWrap
              data-testid="field-value"
            >
              {formatFieldValue({
                fieldValue: value,
                fieldFormatType: field.field_format_type,
              })}
            </Text>
          </Tooltip>
        )}
        {field.n_a_status && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
        {!isEdit && (
          <>
            {showEditButton && (
              <IconButton
                size="small"
                onClick={handleEditButtonClick}
                dataTestid="field-edit-button"
              >
                <EditIcon />
              </IconButton>
            )}
            <FieldDropdownMenu
              field={field}
              dealId={dealId}
              packageId={packageId}
              categoryId={categoryId}
              tabId={tabId}
              sectionId={sectionId}
              onUpdate={onUpdate}
              menuButtonDataTestid="dd-field-actions-button"
            />
          </>
        )}
        {isEdit && (
          <Stack spacing={1} direction="row" alignItems="start">
            <Box sx={{ width: FIELD_WIDTH }}>
              <EditableField
                fieldFormatType={field.field_format_type}
                value={value}
                onChangeValue={handleChange}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              />
            </Box>
            <Button
              onClick={handleSave}
              data-testid="submit-button"
              disabled={isSaveButtonDisabled}
            >
              Save
            </Button>
            {showResetButton && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleReset}
                data-testid="reset-button"
              >
                Reset
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Textfield;

import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { MoreVertIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { format, FORMATS, parseISO } from "utils/datetime";
import { downloadFileWithFileName } from "utils/file";
import { downloadListOfListsAsXLSX } from "utils/xlsx";

import Box from "components/Box";
import Button from "components/Button";
import ButtonDropdown from "components/ButtonDropdown";
import FilesListDialog from "components/FilesListDialog";
import { MenuItem } from "components/Menu";
import PageHeader from "components/PageHeader";
import Stack from "components/Stack";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { Persona } from "entities/Company/sdk";
import { IDealDataFileUrl } from "entities/Deal/sdk";
import { IUploadedFile } from "entities/File/sdk";
import CreateSnapshotMenuItem from "entities/Proforma/components/CreateSnapshotMenuItem";
import ProformaUploadExcelDialog from "entities/Proforma/components/ProformaUploadExcelDialog";
import SnapshotDetails from "entities/Proforma/components/SnapshotDetails";
import {
  getProformaDealDetails,
  IProformaFilesListFilters,
  proformaDealDetailsdownloadCsv,
  useProformaFilesList,
} from "entities/Proforma/sdk";

const ProformaPageHeader = ({
  dealId,
  dealName,
  dealData,
  onUploadData,
  onCreateSnapshot,
  userPersona,
}: {
  dealId: number;
  dealName: string;
  dealData: IDealDataFileUrl;
  onUploadData: () => void;
  onCreateSnapshot: () => void;
  userPersona?: string;
}) => {
  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
  const [filesDialogOpen, setFilesDialogOpen] = useState(false);
  const [proformaFilesFilters, setProformaFilesFilters] = useState<
    IProformaFilesListFilters | undefined
  >(undefined);

  const {
    data: uploadedFilesData,
    pagesCount,
    setPage,
    setOffset,
    currentPage,
    setCurrentPage,
    isLoading,
  } = useProformaFilesList({
    dealId,
    enabled: filesDialogOpen,
    filters: proformaFilesFilters,
  });

  const handleUpdateSnapshotFilter = useCallback(
    ({ value }: { value: boolean }) => {
      setOffset(0);
      setCurrentPage(1);
      setProformaFilesFilters({ only_snapshot: value });
    },
    [setOffset, setCurrentPage]
  );

  const handleUploadProformaData = useCallback(() => {
    setUploadFileDialogOpen(false);
    onUploadData();
  }, [onUploadData]);

  const handleOpenUploadDialog = useCallback(() => {
    setUploadFileDialogOpen(true);
  }, []);

  const handleCloseFilesDialog = useCallback(() => {
    setFilesDialogOpen(false);
    setOffset(0);
    setCurrentPage(1);
    setProformaFilesFilters(undefined);
  }, [setOffset, setCurrentPage]);

  const handleDownloadProjectDataAsExcel = useCallback(() => {
    getProformaDealDetails(dealId).then((data: any) => {
      downloadListOfListsAsXLSX(
        data,
        `Project Export Data - Deal ${dealId} - ${dealName} - ${format(
          new Date(),
          FORMATS.FRONTEND.DATE
        )}.xlsx`
      );
    });
  }, [dealId, dealName]);

  const handleDownloadProjectDataAsCsv = useCallback(() => {
    proformaDealDetailsdownloadCsv({ dealId }).then((data: any) => {
      // Turn the name from "deal name" to "DealName.csv"
      const filename = `${dealName.split(" ").map(_.upperFirst).join("")}.csv`;

      const blob = new Blob([data], { type: "text/csv" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }, [dealId, dealName]);

  const handleFileDownload = useCallback(
    () =>
      downloadFileWithFileName({
        fileUrl: dealData.file_url,
        fileName:
          dealData.original_file_name ||
          _.last(dealData.file_url.split("/")) ||
          "proforma.xlsx",
      }),
    [dealData]
  );
  const handleUploadedFileDownload = useCallback(
    ({ file }: { file: IUploadedFile }) => {
      if (!_.isUndefined(file.file_url)) {
        downloadFileWithFileName({
          fileName:
            file.original_file_name ||
            _.last(file.file_url.split("/")) ||
            "proforma.xlsx",
          fileUrl: file.file_url,
        });
      }
    },
    []
  );

  const userIsLender = useMemo(
    () => !_.isNil(userPersona) && userPersona === Persona.LENDER,
    [userPersona]
  );

  return (
    <>
      <PageHeader
        title={
          <Stack direction="row" spacing={theme.spacing(1)} alignItems="center">
            <Text variant="h2" data-testid="page-header-name">
              Underwriting pro forma
            </Text>
            {!_.isNil(dealData.snapshot) && (
              <SnapshotDetails snapshot={dealData.snapshot} />
            )}
          </Stack>
        }
        subTitle={
          <Stack spacing={1} direction="row" alignItems="center">
            <Box data-testid="proforma-last-updated-info">
              <Text
                variant="text3"
                component="span"
                sx={{ color: colors.gray80 }}
              >
                Excel last uploaded on{" "}
              </Text>
              <Text
                variant="text3"
                component="span"
                sx={{ fontWeight: 500, color: colors.gray80 }}
                data-testid="updated-date"
              >
                {format(parseISO(dealData.created), FORMATS.FRONTEND.DATE)}
              </Text>
              <Text
                variant="text3"
                component="span"
                sx={{ color: colors.gray80 }}
              >
                {" "}
                by{" "}
              </Text>
              <Text
                variant="text3"
                component="span"
                sx={{ fontWeight: 500, color: colors.gray80 }}
                data-testid="updated-by-user-name"
              >
                {dealData.uploaded_by.first_name}{" "}
                {dealData.uploaded_by.last_name}
              </Text>
            </Box>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleFileDownload}
              data-testid="download-proforma-button"
            >
              See Excel file
            </Button>
          </Stack>
        }
        actions={
          <ButtonDropdown
            keepMenuMounted
            label="Actions"
            icon={<MoreVertIcon />}
            buttonDataTestid="proforma-actions-button"
          >
            <MenuItem
              onClick={handleOpenUploadDialog}
              dataTestid="proforma-action-option"
            >
              <Text variant="text2" data-testid="action-label">
                Update from Excel
              </Text>
            </MenuItem>
            <MenuItem
              onClick={() => setFilesDialogOpen(true)}
              dataTestid="proforma-action-option"
            >
              <Text variant="text2" data-testid="action-label">
                View uploaded files
              </Text>
            </MenuItem>
            <CreateSnapshotMenuItem
              hasSnapshot={!_.isNil(dealData.snapshot)}
              dealId={dealId}
              onCreateSnapshot={onCreateSnapshot}
            />
            <Tooltip
              arrow
              placement="top"
              title={
                !userIsLender ? "This option is available only for lenders" : ""
              }
            >
              <span>
                <MenuItem
                  onClick={handleDownloadProjectDataAsExcel}
                  disabled={!userIsLender}
                  dataTestid="proforma-action-option"
                >
                  <Text variant="text2" data-testid="action-option-label">
                    Download project data as .xlsx
                  </Text>
                </MenuItem>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              placement="top"
              title={
                !userIsLender ? "This option is available only for lenders" : ""
              }
            >
              <span>
                <MenuItem
                  onClick={handleDownloadProjectDataAsCsv}
                  disabled={!userIsLender}
                  dataTestid="proforma-action-option"
                >
                  <Text variant="text2" data-testid="action-option-label">
                    Download project data as .csv
                  </Text>
                </MenuItem>
              </span>
            </Tooltip>
          </ButtonDropdown>
        }
      />
      <ProformaUploadExcelDialog
        open={uploadFileDialogOpen}
        dealId={dealId}
        onClose={() => setUploadFileDialogOpen(false)}
        onUpload={handleUploadProformaData}
      />
      <FilesListDialog
        showSnapshotFilter
        isOpen={filesDialogOpen}
        onClose={handleCloseFilesDialog}
        currentPage={currentPage}
        data={uploadedFilesData?.results || []}
        onSetPage={setPage}
        pagesCount={pagesCount}
        isLoading={isLoading}
        onDownload={handleUploadedFileDownload}
        onChangeSnapshotFilter={handleUpdateSnapshotFilter}
      />
    </>
  );
};

export default ProformaPageHeader;

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

import Backdrop from "components/Backdrop";

interface ILoading {
  open: boolean;
}

const Loading: React.FC<ILoading> = ({ open }) => {
  return (
    <Backdrop open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export { CircularProgress, LinearProgress };

export default Loading;

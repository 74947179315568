import React, { useMemo } from "react";
import _ from "lodash";

import theme from "theme";
import { formatNumberToString, isNotUndefined } from "utils/common";

import Box from "components/Box";
import ContentSeparator from "components/ContentSeparator";
import Stack from "components/Stack";

import { ProformaHeaderTypeTotal } from "entities/Proforma/components/ProformaContentHeader";
import ProformaPaper from "entities/Proforma/components/ProformaPaper";
import ProformaTable from "entities/Proforma/components/ProformaTable";
import ProformaUnixTable from "entities/Proforma/components/ProfromaUnixTable";
import { IRentalIncomeData } from "entities/Proforma/sdk";

export interface IProfromaUnitMixAndRent {
  rentalIncomeData: IRentalIncomeData;
}

const unixHeaders: Array<{ key: string; name: string }> = [
  { key: "studio", name: "SRO/Studio" },
  { key: "bedroom_1", name: "1 BDR" },
  { key: "bedroom_2", name: "2 BDR" },
  { key: "bedroom_3", name: "3 BDR" },
  { key: "bedroom_4", name: "4 BDR" },
  { key: "total", name: "Total" },
];

const headersResidentialRentalIncome: Array<{ key: string; name: string }> = [
  { key: "title", name: "Residential RENTAL INCOME" },
  { key: "units", name: "Units" },
  { key: "monthly", name: "Monthly" },
  { key: "yearly", name: "Yearly" },
];

const ProfromaUnitMixAndRent: React.FC<IProfromaUnitMixAndRent> = ({
  rentalIncomeData,
}) => {
  const rentComparisonsRows = useMemo(
    () =>
      _.map(
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.data,
        (value, key) => {
          if (value.value !== 0)
            return {
              title: key,
              data: _.map(unixHeaders, (header) => ({
                key: header.key,
                value: {
                  gross_monthly_rent: value[header.key]
                    ? value[header.key].gross_monthly_rent
                    : "",
                  count:
                    header.key === "total"
                      ? value[header.key].count
                      : value[header.key]
                        ? value[header.key].numberofunits
                        : 0,
                },
              })),
            };
        }
      ).filter(isNotUndefined),
    [rentalIncomeData]
  );

  const getTotal = useMemo(() => {
    let total = 0;
    _.forEach(
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.data,
      (value, key) => {
        total += value.total.count;
      }
    );
    return total;
  }, [rentalIncomeData]);

  const tableUnixTotals = {
    title: "Total",
    data: _.map(unixHeaders, (header) => ({
      key: header.key,
      value:
        header.key === "total"
          ? getTotal
          : rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
              ?.misc?.total[header.key] || 0,
    })),
  };

  const residentialRentalIncomeRows = rentalIncomeData.rental_data
    .new_unit_mix_and_rent_and_floor_area
    ? [
        {
          title: "Affordable Units",
          units:
            rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
              .misc.total.affordable_unit_count,
          monthly: `$${formatNumberToString({
            number:
              rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
                .misc.total.affordable_monthly_rent,
          })}`,
          yearly: `$${formatNumberToString({
            number:
              rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
                .misc.total.affordable_monthly_rent * 12,
          })}`,
        },
        {
          title: "Market Rate Units",
          units:
            rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
              .misc.total.market_rate_unit_count,
          monthly: `$${formatNumberToString({
            number:
              rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
                .misc.total.market_rate_monthly_rent,
          })}`,
          yearly: `$${formatNumberToString({
            number:
              rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
                .misc.total.market_rate_monthly_rent * 12,
          })}`,
        },
        {
          title: "Super's Units",
          units:
            rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
              .misc.total.super_unit_count,
          monthly: `$${formatNumberToString({
            number:
              rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
                .misc.total.super_unit_monthly_rent,
          })}`,
          yearly: `$${formatNumberToString({
            number:
              rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area
                .misc.total.super_unit_monthly_rent * 12,
          })}`,
        },
      ]
    : [];

  const potentialResidentialMonthlyRent =
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area &&
    `$${formatNumberToString({
      number:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.affordable_monthly_rent +
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.market_rate_monthly_rent +
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.super_unit_monthly_rent,
    })}`;

  const potentialResidentialYearlyRent =
    rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area &&
    `$${formatNumberToString({
      number:
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.affordable_monthly_rent *
          12 +
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.market_rate_monthly_rent *
          12 +
        rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area.misc
          .total.super_unit_monthly_rent *
          12,
    })}`;

  const tableTotals = {
    title: "Potential Residential Rent",
    units:
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.misc
        ?.total?.affordable_unit_count +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.misc
        ?.total?.market_rate_unit_count +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.misc
        ?.total?.super_unit_count,
    monthly: potentialResidentialMonthlyRent,
    yearly: potentialResidentialYearlyRent,
  };

  const totalYearlyRent =
    (rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.misc
      ?.total?.market_rate_monthly_rent +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.misc
        ?.total?.affordable_monthly_rent +
      rentalIncomeData.rental_data.new_unit_mix_and_rent_and_floor_area?.misc
        ?.total?.super_unit_monthly_rent) *
    12;

  const totals = [
    {
      label: "Total Yearly Rent",
      value: totalYearlyRent,
      type: ProformaHeaderTypeTotal.PRICE,
    },
  ];

  return (
    <ProformaPaper
      title="Unit Mix &amp; Rent"
      subTitle="Rental Income per Unit Type"
      totals={totals}
    >
      <Stack
        sx={{
          margin: theme.spacing(4, 0),
        }}
      >
        <ContentSeparator label="Unit Mix & Rent rates" />

        <ProformaUnixTable
          rows={rentComparisonsRows}
          headers={unixHeaders}
          totals={tableUnixTotals}
        />
      </Stack>
      <Stack
        sx={{
          margin: theme.spacing(4, 0),
        }}
      >
        <Box sx={{ marginBottom: theme.spacing(2) }}>
          <ContentSeparator label="Residential rental income" />
        </Box>
        <ProformaTable
          rows={residentialRentalIncomeRows}
          headers={headersResidentialRentalIncome}
          totals={tableTotals}
        />
      </Stack>
    </ProformaPaper>
  );
};

export default ProfromaUnitMixAndRent;

export enum AssetManagementTabItems {
  EXPENSES = "Expenses",
  INCOME = "Income",
  DEBT_SERVICE = "Debt Service",
  OTHER_DATA = "Other Data",
}

export enum AMResolution {
  YEARLY = "Yearly",
  QUARTERLY = "Quarterly",
}

export enum AMColumn {
  BUDGETED = "Budgeted",
  AUDITED = "Audited",
  ACTUAL = "Actual",
  VARIANCE = "Variance",
}

export enum ButlerQueueType {
  ASSET_MANAGEMENT = "asset_management",
  DUE_DILIGENCE = "due_diligence",
}

export const AM_VARIANCE_COLUMN = "variance";
export const AM_UPLOAD_FILE_DIALOG_SUB_TITLE_MAX_WIDTH = "500px";

import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import { ReportProblemOutlinedIcon } from "icons";
import theme from "theme";
import { colors } from "theme/palette";
import { BOX_MAX_WIDTH } from "utils/constants";
import { format, FORMATS, parseJSON } from "utils/datetime";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Button from "components/Button";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import toast from "components/Toast";
import { usePageTitle } from "components/usePageTitle";

import SaveReportTableButton from "entities/TableStorage/components/SaveReportTableButton";
import Table from "entities/TableStorage/components/Table";
import TableSkeleton from "entities/TableStorage/components/TableSkeleton";
import { useTableResponse } from "entities/TableStorage/sdk";

const Details = () => {
  usePageTitle("Builders Patch: AI-powered Generated Tables - Details");

  const navigate = useNavigate();
  const [disabledIndexRows, setDisabledIndexRows] = useState<Array<number>>([]);
  const [disabledIndexColumns, setDisabledIndexColumns] = useState<
    Array<number>
  >([]);
  const [changedCells, setChangedCells] = useState<{
    [key: string]: string | null;
  }>({});
  const [resetTableData, setResetTableData] = useState(false);

  const params = useParams<{
    tableResponseId: string;
  }>();

  const tableResponseId = useMemo(
    () => Number(params.tableResponseId),
    [params.tableResponseId]
  );

  const { data: tableResponse } = useTableResponse({
    tableResponseId,
  });

  const tableData = useMemo(() => {
    if (
      !_.isUndefined(tableResponse) &&
      !_.isUndefined(tableResponse.formatted_table_data)
    ) {
      return _.map(tableResponse.formatted_table_data, (row) => ({
        cells: _.map(row, (cell) => ({ value: cell })),
      }));
    }

    return [];
  }, [tableResponse]);

  const tableLastUpdatedLabel = useMemo(() => {
    if (_.isNil(tableResponse)) {
      return "";
    }

    if (!_.isNil(tableResponse.updated_by)) {
      return `Updated at ${format(
        parseJSON(tableResponse.updated_at),
        FORMATS.FRONTEND.DATETIME
      )} by ${tableResponse.updated_by.first_name} ${tableResponse.updated_by.last_name}`;
    }

    return `Created at ${format(
      parseJSON(tableResponse.created_at),
      FORMATS.FRONTEND.DATETIME
    )} by ${tableResponse.created_by.first_name} ${tableResponse.created_by.last_name}`;
  }, [tableResponse]);

  const handleSaveTable = useCallback(
    ({ tableId: newTableId }) => {
      toast.successMessage("Table created successfully");

      navigate(reverse(URLS.TABLE_STORAGE_DETAILS, { tableId: newTableId }));
    },
    [navigate]
  );

  const handleUpdateTableData = useCallback(
    ({ key, currentValue }: { key: string; currentValue: string | null }) => {
      setResetTableData(false);
      setChangedCells((prev) => ({
        ...prev,
        [key]: currentValue,
      }));
    },
    []
  );

  const onCancel = useCallback(() => {
    setResetTableData(true);
    setChangedCells({});
    setDisabledIndexRows([]);
    setDisabledIndexColumns([]);
  }, [setDisabledIndexRows, setDisabledIndexColumns]);

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Stack
          spacing={4}
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <PageHeader
            title={tableResponse?.table_type}
            subTitle={tableLastUpdatedLabel}
            backTitle="AI-powered generated reports"
            backLink={URLS.TABLES_RESPONSE_LIST}
          />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Text variant="text1" color={colors.gray80} fontWeight={700}>
              Selected tables
            </Text>
            <Stack
              direction="row"
              spacing={3}
              alignItems="flex-start"
              sx={{
                padding: theme.spacing(3),
                borderRadius: "8px",
                backgroundColor: colors.gray10,
              }}
            >
              <ReportProblemOutlinedIcon
                sx={{ width: "50px", height: "50px", color: colors.yellow100 }}
              />
              <Stack spacing={1}>
                <Text variant="text2" fontWeight={700}>
                  Note!
                </Text>
                <Stack spacing={2}>
                  <Text variant="text2" color={colors.gray80}>
                    Selected tables display the current version, not the version
                    at the time of report generation. <br /> The tables may have
                    been updated since the report was generated.
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            {tableResponse?.selected_tables.map((selectedTable) => (
              <Stack key={selectedTable.id} spacing={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Text variant="text1">{selectedTable.name}</Text>
                    {!_.isEmpty(selectedTable.deal) && (
                      <Text variant="text2">
                        Deal: {selectedTable.deal.name}
                      </Text>
                    )}
                  </Box>
                </Stack>
                {!_.isEmpty(selectedTable.rows) && (
                  <Table tableData={selectedTable.rows} />
                )}
              </Stack>
            ))}
            <Text variant="text1" color={colors.gray80} fontWeight={700}>
              Report
            </Text>
            {_.isEmpty(tableData) ? (
              <TableSkeleton />
            ) : (
              <Table
                editMode
                showFilterCheckboxes
                disabledIndexRows={disabledIndexRows}
                disabledIndexColumns={disabledIndexColumns}
                setDisabledIndexRows={setDisabledIndexRows}
                setDisabledIndexColumns={setDisabledIndexColumns}
                tableData={tableData}
                updateTableData={handleUpdateTableData}
                resetTableData={resetTableData}
              />
            )}
            <Stack direction="row" spacing={1} alignSelf="flex-end">
              <Button
                onClick={onCancel}
                sx={{ alignSelf: "flex-start" }}
                variant="outlined"
              >
                Cancel changes
              </Button>
              <SaveReportTableButton
                tableResponseId={tableResponseId}
                onSave={handleSaveTable}
                tableData={tableData}
                disabledIndexRows={disabledIndexRows}
                disabledIndexColumns={disabledIndexColumns}
                changedCells={changedCells}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Details;

import {
  toast,
  ToastContainer,
  ToastContent,
  ToastOptions,
} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong. We've received a report about this.";

const customToast = {
  update: toast.update,
  dismiss: toast.dismiss,
  loading: (message: ToastContent, options?: ToastOptions) =>
    toast.loading(message, {
      position: "bottom-right",
      closeOnClick: true,
      draggable: true,
      ...options,
    }),
  successMessage: (message: ToastContent, options?: ToastOptions) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...options,
    }),

  errorMessage: (message: ToastContent, options?: ToastOptions) =>
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...options,
    }),
};

export default customToast;

export { ToastContainer };

import { useCallback, useMemo } from "react";
import _ from "lodash";

import { columnIsSortedByNumbers, sortListBy } from "utils/common";

import ScrollableTable from "components/ScrollableTable";
import { useFilters } from "components/useFilters";
import { ORDER_BY_COLUMN_FILTER_KEY } from "components/useFilters/constants";

import { getBasedHeaders } from "entities/Reporting/components/ReportTable/utils";

interface IReportTable {
  rows: { [key: string]: string | null }[];
  columns: {
    children: {
      name: string;
      field_width: number;
      field: string;
    }[];
    name: string;
  }[];
  showLoading: boolean;
}

const ReportTable = ({ rows, columns, showLoading }: IReportTable) => {
  const { filters, updateFilter, deleteFilter } = useFilters();

  const selectedSortOption = useMemo(
    () => _.get(filters, ORDER_BY_COLUMN_FILTER_KEY),
    [filters]
  );

  const handleUpdateSortOption = useCallback(
    ({ key: option }: { key: string | undefined }) => {
      if (_.isUndefined(option)) {
        deleteFilter({ key: ORDER_BY_COLUMN_FILTER_KEY });
      } else {
        updateFilter({ key: ORDER_BY_COLUMN_FILTER_KEY, value: option });
      }
    },
    [deleteFilter, updateFilter]
  );

  const isSortedByNumbers = useMemo(
    () =>
      columnIsSortedByNumbers({
        selectedSortOption,
        data: rows,
      }),
    [rows, selectedSortOption]
  );

  const sortedDeals = useMemo(
    () => sortListBy(rows, selectedSortOption, isSortedByNumbers),
    [rows, selectedSortOption, isSortedByNumbers]
  );

  const headers = useMemo(() => getBasedHeaders(columns), [columns]);

  return (
    <ScrollableTable
      rows={sortedDeals}
      headers={headers}
      sortBy={selectedSortOption}
      setSortBy={handleUpdateSortOption}
      showLoading={showLoading}
      tableDataTestid="deals-table-list"
      rowDataTestid="deal-data-row"
      rowCellDataTestid="deal-data-cell"
    />
  );
};

export default ReportTable;

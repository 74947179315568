import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { EditIcon } from "icons";
import { isNotUndefined } from "utils/common";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import Tooltip from "components/Tooltip";

import { IApplicationField } from "entities/Application/sdk";
import EditableField from "entities/Field/components/Textfield/EditableField";
import { CompanyFieldFormatType } from "entities/Field/constants";
import { formatFieldValue } from "entities/Field/utils";

export interface ITextfield {
  field: IApplicationField;
  editable: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: ({
    field,
    value,
  }: {
    field: IApplicationField;
    value: string | number | null | undefined;
  }) => void;
}

const Textfield = ({
  field,
  editable,
  isEditing,
  setIsEditing,
  onUpdate,
}: ITextfield) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [value, setValue] = useState<string | number | null | undefined>(
    _.first(field.values)?.value
  );

  const handleEditButtonClick = useCallback(
    () => setIsEditing(true),
    [setIsEditing]
  );

  const handleChange = useCallback(
    (newValue: string | number | null | undefined) => {
      setValue(newValue);
    },
    [setValue]
  );

  const handleSave = useCallback(() => {
    onUpdate({ field, value });
  }, [field, value, onUpdate]);

  const handleCancel = useCallback(() => {
    setValue(_.first(field.values)?.value);
    setIsEditing(false);
  }, [setValue, field.values, setIsEditing]);

  const showFieldValue = useMemo(() => !isEditing && value, [isEditing, value]);
  const showEditIconButton = useMemo(
    () => editable && !isEditing,
    [isEditing, editable]
  );

  const showRequiredTag = useMemo(
    () => editable && !value && field.is_required,
    [editable, value, field.is_required]
  );

  const handleReset = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const showResetButton = useMemo(
    () => field.field_format_type === CompanyFieldFormatType.DATE,
    [field]
  );

  const fieldFormatType: CompanyFieldFormatType | undefined = useMemo(
    () => _.first(field.values)?.field_format_type,
    [field]
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ flexGrow: 0 }}>
      {showRequiredTag && <Tag label="required" variant="red" />}
      {showFieldValue && (
        <Tooltip title={value}>
          <Text variant="text2" sx={{ wordBreak: "break-word" }}>
            {isNotUndefined(fieldFormatType) &&
              formatFieldValue({
                fieldValue: value,
                fieldFormatType: fieldFormatType,
              })}
          </Text>
        </Tooltip>
      )}
      {showEditIconButton && (
        <IconButton size="small" onClick={handleEditButtonClick}>
          <EditIcon />
        </IconButton>
      )}
      {isEditing && (
        <Stack spacing={1} direction="row" alignItems="start">
          <EditableField
            fieldFormatType={field.field_format_type}
            value={value}
            onChangeValue={handleChange}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          />
          <Button onClick={handleSave} disabled={isSaveButtonDisabled}>
            Save
          </Button>
          {showResetButton && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleReset}
              data-testid="reset-button"
            >
              Reset
            </Button>
          )}
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Textfield;

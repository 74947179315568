import theme from "theme";
import { colors } from "theme/palette";

import Container from "components/Container";
import PageHeader from "components/PageHeader";
import Paper from "components/Paper";
import Stack from "components/Stack";
import Text from "components/Text";
import { usePageTitle } from "components/usePageTitle";

import AddFilterGroupButton from "entities/AnalyticsFilterGroup/components/AddFilterGroupButton";
import AnalyticsFilterGroupBox from "entities/AnalyticsFilterGroup/components/ListBox";
import AnalyticsFilterGroupBoxSkeleton from "entities/AnalyticsFilterGroup/components/ListBoxSkeleton";
import {
  ANALYTICS_TYPES,
  useAnalyticsFilterGroups,
} from "entities/AnalyticsFilterGroup/sdk";

const AnalyticsDevBudgetList: React.FC<{}> = () => {
  usePageTitle("Builders Patch: Analytics - Development Budget");
  const { data: filterGroups = [], loading } = useAnalyticsFilterGroups(
    ANALYTICS_TYPES.DEVELOPMENT_BUDGET
  );

  return (
    <Paper sx={{ padding: theme.spacing(4, 3) }}>
      <PageHeader
        title="Development budget"
        actions={
          <AddFilterGroupButton
            analyticsType={ANALYTICS_TYPES.DEVELOPMENT_BUDGET}
          />
        }
      />
      <Container>
        <Stack spacing={1} sx={{ marginTop: theme.spacing(4) }}>
          {(filterGroups.length !== 0 || loading) && (
            <Text
              variant="label"
              sx={{
                color: colors.gray100,
                opacity: 0.5,
              }}
            >
              Select a filter group
            </Text>
          )}
          {loading &&
            [1, 2, 3].map((index) => (
              <AnalyticsFilterGroupBoxSkeleton key={`skeleton${index}`} />
            ))}
          {filterGroups.map((filterGroup, index) => (
            <AnalyticsFilterGroupBox
              key={index}
              analyticsType={ANALYTICS_TYPES.DEVELOPMENT_BUDGET}
              filterGroup={filterGroup}
            />
          ))}
          {filterGroups.length === 0 && !loading && (
            <Stack sx={{ alignItems: "center" }}>
              <Text variant="label">No Filter Groups yet.</Text>
            </Stack>
          )}
        </Stack>
      </Container>
    </Paper>
  );
};

export default AnalyticsDevBudgetList;

import { useCallback, useMemo } from "react";
import _ from "lodash";

import { colors } from "theme/palette";

import Select from "components/Select";
import { IRenderHeader } from "components/StyledTable/utils/header";
import Tooltip from "components/Tooltip";

const YEARS = _.range(new Date().getFullYear() + 1, 2012, -1).map((year) =>
  year.toString()
);

const YearCellEditMode = ({
  label,
  currentSelectedYears,
  onChange,
}: {
  label: string;
  currentSelectedYears: Array<string | number>;
  onChange: (args: { newYear: string }) => void;
}) => {
  const selectedHeaders = useMemo(
    () => currentSelectedYears.map((year) => year.toString()),
    [currentSelectedYears]
  );

  const yearOptions: Array<{
    value: string;
    label: string;
    meta: { disabled: boolean };
  }> = useMemo(
    () =>
      // Create a list of years with disabled options if the year is already selected
      YEARS.map((year) => ({
        label: year,
        value: year,
        meta: { disabled: selectedHeaders.includes(year) && year !== label },
      })),

    [label, selectedHeaders]
  );

  const isNotValidYear = useMemo(() => {
    const hasYear = _.find(yearOptions, (year) => year.value === label);

    return _.isUndefined(hasYear);
  }, [label, yearOptions]);

  const handleChangeYear = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ newYear: event.target.value });
    },
    [onChange]
  );

  return (
    <Tooltip title={isNotValidYear ? "Year is not valid!" : ""} placement="top">
      <span>
        <Select
          data-testid="year-dropdown"
          size="small"
          sx={{ width: "154px", padding: "0 8px" }}
          inputProps={{
            sx: {
              backgroundColor: "white",
            },
            "data-testid": "year-dropdown-input",
          }}
          dropdownOptionsDataTestid="year-dropdown-option"
          options={yearOptions}
          onChange={handleChangeYear}
          value={label}
          error={isNotValidYear}
        />
      </span>
    </Tooltip>
  );
};

const RegularCell = ({ label }: { label: any }) => (
  <div
    style={{
      fontSize: "16px",
      fontWeight: 700,
      padding: 0,
    }}
  >
    {label}
  </div>
);

const YearCell: IRenderHeader<
  {
    editMode: boolean;
    currentSelectedYears: Array<string | number>;
    onChange: (args: { newYear: string }) => void;
  } & {
    label: string;
    width?: string;
    Wrapper: (props: any) => JSX.Element;
  }
> = ({ label, Wrapper, currentSelectedYears, editMode, onChange }) => (
  <Wrapper style={{ borderBottom: `1px solid ${colors.gray100}` }}>
    {editMode ? (
      <YearCellEditMode
        label={label}
        currentSelectedYears={currentSelectedYears}
        onChange={onChange}
      />
    ) : (
      <RegularCell label={label} />
    )}
  </Wrapper>
);

export default YearCell;

import _ from "lodash";
import * as XLSX from "xlsx";

import { downloadFile } from "utils/file";

import { format, FORMATS, isValid, parse } from "./datetime";

export interface IColumnFormatsForXLSXExport {
  dateColumns: Array<number>;
  dateTimeColumns: Array<number>;
  currencyColumns: Array<number>;
  integerColumns: Array<number>;
  floatColumns: Array<number>;
}

const getListOfListsOptimalColumnsWidth = (
  data: Array<Array<string | null | number>>,
  columnFormats?: IColumnFormatsForXLSXExport
): Array<{ wch: number }> => {
  /**
   * Calculate the optimal column width for each column by
   * getting the number of symbols in each cell of the column,
   * and calculating the Max value in the column.
   *
   * The result is returned as an array of {wch: number} values where
   * `wch` is the maximum number of symbols in each column.
   *
   * Note: This implementation also works for data without headers row.
   * eg: 3 coulms with headers, but 5 columns with data.
   */
  if (data.length === 0) {
    return [];
  }

  const result: number[] = [];
  data.forEach((row, rowIndex) => {
    row.forEach((cell, index) => {
      if (cell) {
        // The data we receive
        // may contain unexpected types (number, null, undefined, void etc),
        // so we cast them to String to be safe
        const cellLength = String(cell).length;

        // if we already have a width for this item
        if (result[index]) {
          result[index] = Math.max(result[index], cellLength);
        } else {
          result[index] = cellLength;
        }
      }

      // On the last iteration of rows increase the width of the column if it is a currency column so that to have enough space for the currency symbol.
      if (
        !_.isUndefined(columnFormats) &&
        rowIndex === data.length - 1 &&
        _.has(columnFormats.currencyColumns, index)
      ) {
        result[index] += 4; // 4 because we need of 1 space for the currency symbol, 1 space for the decimal separator and 2 spaces for the decimal part because in String(cell).lenght it's possible to have no decimal part.
      }
    });
  });

  return result.map((value) => ({ wch: value }));
};

const formatColumnsForXLSX = ({
  worksheet,
  data,
  columnFormats,
}: {
  worksheet: XLSX.WorkSheet;
  data: Array<Array<string | null | number>>;
  columnFormats: IColumnFormatsForXLSXExport;
}) => {
  data.forEach((row, rowIndex) => {
    row.forEach((cell, cellIndex) => {
      if (rowIndex !== 0) {
        const cellRef = XLSX.utils.encode_cell({ c: cellIndex, r: rowIndex });

        if (worksheet[cellRef]) {
          if (columnFormats.currencyColumns.includes(cellIndex)) {
            worksheet[cellRef].z =
              '_("$"* #,##0.00_);_("$"* \\(#,##0.00\\);_("$"* "-"??_);_(@_)';

            const cellValue = worksheet[cellRef].v;

            if (typeof cellValue === "number") {
              worksheet[cellRef].t = "n"; // Ensure cell type is number if the value is actually a number.
            }
          } else if (
            columnFormats.dateColumns.includes(cellIndex) &&
            cell !== ""
          ) {
            const date = parse(
              cell as string,
              FORMATS.BACKEND.DATE,
              new Date()
            );

            if (isValid(date)) {
              const formattedDate = format(date, "MM/dd/yyyy");

              worksheet[cellRef] = {
                v: formattedDate,
                t: "s",
              };
            } else {
              worksheet[cellRef] = {
                v: "",
                t: "s",
              };
            }
          } else if (
            columnFormats.dateTimeColumns.includes(cellIndex) &&
            cell !== ""
          ) {
            worksheet[cellRef].t = "d"; // Ensure cell type is date
            worksheet[cellRef].z = "mm/dd/yyyy h:mm AM/PM";
          } else if (columnFormats.integerColumns.includes(cellIndex)) {
            worksheet[cellRef].z = "#,##0";

            const cellValue = worksheet[cellRef].v;

            if (typeof cellValue === "number") {
              worksheet[cellRef].t = "n"; // Ensure cell type is number if the value is actually a number.
            }
          } else if (columnFormats.floatColumns.includes(cellIndex)) {
            worksheet[cellRef].z = "#,##0.00";

            const cellValue = worksheet[cellRef].v;

            if (typeof cellValue === "number") {
              worksheet[cellRef].t = "n"; // Ensure cell type is number if the value is actually a number.
            }
          } else {
            worksheet[cellRef] = { v: cell, t: "s" };
          }
        }
      }
    });
  });

  return worksheet;
};

const setColumnsSettings = (
  sheet: XLSX.WorkSheet,
  settings: XLSX.ColInfo[]
) => {
  /**
   * Set the width of every column in the sheet by providing a list of objects with column settings
   * The setting we use is { wch: number } for every column.
   * Example: `{ wch: 10 }` is a width set by the number of characters. In this case = 10.
   *
   * Reference:
   * https://www.npmjs.com/package/xlsx
   */
  sheet["!cols"] = settings;
};

const listOfListsToWorkSheet = (
  data: Array<Array<string | null | number>>,
  columnFormats?: IColumnFormatsForXLSXExport
) => {
  let worksheet = XLSX.utils.aoa_to_sheet(data);

  const colsWidth = getListOfListsOptimalColumnsWidth(data, columnFormats);

  if (!_.isUndefined(columnFormats)) {
    worksheet = formatColumnsForXLSX({
      worksheet,
      data,
      columnFormats,
    });
  }

  setColumnsSettings(worksheet, colsWidth);

  return worksheet;
};

export const downloadListOfListsAsXLSX = (
  data: Array<Array<string | null | number>>,
  filename: string,
  columnFormats?: IColumnFormatsForXLSXExport
) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const ws = listOfListsToWorkSheet(data, columnFormats);
  const wb = { Sheets: { Data: ws }, SheetNames: ["Data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  downloadFile(url, filename);
};

export const downloadAsXLSXWithMultipleSheets = (
  sheets: Array<{
    sheetName: string;
    data: Array<Array<string | null | number>>;
  }>,
  filename: string
) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const worksheets = sheets.reduce((result, sheet) => {
    return { ...result, [sheet.sheetName]: listOfListsToWorkSheet(sheet.data) };
  }, {});

  const wb = {
    Sheets: worksheets,
    SheetNames: sheets.map((sheet) => sheet.sheetName),
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  downloadFile(url, filename);
};

import { useState } from "react";

import theme from "theme";

import Alert from "components/Alert";
import Button from "components/Button";
import Stack from "components/Stack";
import Text from "components/Text";

interface IUploadWarning {
  warnings: Array<string>;
  uploadAlertDataTestid?: string;
}

const UploadWarning = ({ uploadAlertDataTestid, warnings }: IUploadWarning) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Alert
      severity="warning"
      sx={{ marginBottom: theme.spacing(2) }}
      action={
        <Button
          onClick={() => setExpanded(!expanded)}
          variant="text"
          data-testid="alert-action-button"
        >
          {expanded ? "Hide" : "Show"}
        </Button>
      }
      data-testid={uploadAlertDataTestid}
    >
      <Stack>
        <Text data-testid="warning-user-message">
          There are some warnings in the last uploaded file!
        </Text>
        {expanded &&
          warnings.map((warning: string) => (
            <li data-testid="warning-content">{warning}</li>
          ))}
      </Stack>
    </Alert>
  );
};

export default UploadWarning;

import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import theme from "theme";

import Button from "components/Button";
import Loading from "components/Loading";
import Stack from "components/Stack";

import PhaseScheduleForm from "entities/Deal/components/OverviewSchedule/PhaseScheduleForm";
import { IDealSchedule } from "entities/Deal/sdk";

const VALIDATION_SCHEMA = yup.object({
  phases: yup.array(
    yup.object({
      start_date: yup.date().nullable(),
      end_date: yup
        .date()
        .nullable()
        .min(
          yup.ref("start_date"),
          "The end date must be after the start date!"
        ),
    })
  ),
});

export interface IPhaseForm {
  phases: Array<IDealSchedule>;
  phase_id: number; // current deal phase id
}

const initialValues: Partial<IPhaseForm> = { phases: [] };

interface IScheduleEditForm {
  initialValues?: Partial<IPhaseForm>;
  onSubmit: (data: Partial<IPhaseForm>) => Promise<any>;
  handleCancel?: () => void;
}
const ScheduleEditForm: React.FC<IScheduleEditForm> = ({
  initialValues: propsInitialValues,
  onSubmit,
  handleCancel,
}) => {
  const handleSubmit = useCallback(
    (data: Partial<IPhaseForm>) => onSubmit(data),
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...propsInitialValues },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Loading open={formik.isSubmitting} />
      <Stack spacing={3}>
        {formik
          .getFieldProps("phases")
          .value?.map((phaseSchedule: IDealSchedule, index: number) => (
            <PhaseScheduleForm
              key={phaseSchedule.phase.name}
              index={index}
              phaseSchedule={phaseSchedule}
              formik={formik}
            />
          ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        style={{ marginTop: theme.spacing(6) }}
      >
        {handleCancel && (
          <Button
            variant="text"
            size="large"
            onClick={handleCancel}
            data-testid="close-schedule-dialog-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submit-schedule-form-button"
        >
          Update
        </Button>
      </Stack>
    </form>
  );
};
export default ScheduleEditForm;

import theme from "theme";

import Box from "components/Box";
import Stack from "components/Stack";
import Text from "components/Text";

interface ISignaturefield {
  label: string;
}

const Signaturefield = ({ label }: ISignaturefield) => {
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{ paddingBottom: theme.spacing(1) }}
      >
        <Text
          variant="text2"
          sx={{
            flexBasis: "400px",
            fontWeight: 300,
            paddingRight: theme.spacing(4),
          }}
        >
          {label}
        </Text>

        <Box
          sx={{
            height: "60px",
            flex: 1,
            border: "1px solid #c5c5ee",
            position: "relative",
          }}
        >
          <Text
            variant="labelSmall"
            sx={{
              position: "absolute",
              right: theme.spacing(0.5),
              bottom: theme.spacing(0.5),
            }}
          >
            Signature
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default Signaturefield;

import _ from "lodash";

import { colors } from "theme/palette";

import Box from "components/Box";
import Card, { CardActionArea } from "components/Card";
import Grid from "components/Grid";
import Stack from "components/Stack";
import Text from "components/Text";

import { EMemoSectionType } from "entities/MemoTemplate/constants";

import { MEMO_SECTION_GROUPS } from "./constants";

export interface IMemoTemplateSectionSelector {
  disabled?: boolean;
  value: EMemoSectionType;
  onChange: (value: EMemoSectionType) => void;
}

const MemoTemplateSectionSelector = ({
  disabled = false,
  value: selectedValue,
  onChange,
}: IMemoTemplateSectionSelector) => (
  <Stack spacing={1}>
    {_.map(MEMO_SECTION_GROUPS, (sectionTypes, sectionGroupName) => (
      <Box>
        <Text variant="text3">{sectionGroupName}</Text>
        <Grid
          container
          gap={0}
          sx={{ padding: 1, border: `1px dashed ${colors.blue20}` }}
        >
          {_.map(sectionTypes, ({ label, value, icon }, key) => (
            <Grid item xs={3} sx={{ overflow: "hidden" }}>
              <Card
                sx={{
                  height: "100%",
                  backgroundColor:
                    value === selectedValue ? colors.blue20 : "white",
                }}
              >
                <CardActionArea
                  disabled={disabled}
                  sx={{ height: "100%" }}
                  onClick={() => !disabled && onChange(value)}
                >
                  <Stack
                    spacing={0}
                    justifyContent="stretch"
                    alignItems="center"
                    sx={{ padding: 1, height: "100%" }}
                  >
                    {icon}
                    <Text align="center" variant="text4">
                      {label}
                    </Text>
                  </Stack>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    ))}
  </Stack>
);

export default MemoTemplateSectionSelector;

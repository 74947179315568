import { memo, useCallback, useState } from "react";
import _ from "lodash";

import { AddIcon } from "icons";

import Button from "components/Button";
import ButtonDropdownGroupLabel from "components/ButtonDropdown/GroupLabel";
import Menu, { MenuItem } from "components/Menu";

import UnderConstructionDialog from "entities/CompanyChecklist/components/UnderConstructionDialog";

export interface IAddNewChecklistButton {
  openCatalogDialog: () => void;
}

const AddNewChecklistButton = ({
  openCatalogDialog,
}: IAddNewChecklistButton) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [contactSupportDialogOpen, setContactSupportDialogOpen] =
    useState(false);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const openContactSupportDialog = useCallback(() => {
    setContactSupportDialogOpen(true);
    setOpen(false);
  }, []);
  const closeContactSupportDialog = useCallback(
    () => setContactSupportDialogOpen(false),
    []
  );

  const handleOpenCatalogDialogClick = useCallback(() => {
    setOpen(false);
    openCatalogDialog();
  }, [openCatalogDialog]);

  return (
    <span>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
        Add new template
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ButtonDropdownGroupLabel label="Create from scrach" />
        <MenuItem onClick={openContactSupportDialog}>
          Create new template
        </MenuItem>
        <ButtonDropdownGroupLabel label="Checklist catalog" />
        <MenuItem onClick={handleOpenCatalogDialogClick}>
          Builders Patch catalog
        </MenuItem>
      </Menu>
      <UnderConstructionDialog
        isOpen={contactSupportDialogOpen}
        onClose={closeContactSupportDialog}
        onClick={openCatalogDialog}
        actionButtonLabel="Browse catalog"
        title="Create new template"
        description="Please contact our Customer Success Team if you need to add new custom checklists to your templates. In the meantime, you can browse our checklist catalog for ready-to-add templates."
      />
    </span>
  );
};

export default memo(AddNewChecklistButton, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { URLS } from "config/urls";
import { MoreVertIcon } from "icons";
import { reverse } from "utils/urls";

import ButtonDropdown from "components/ButtonDropdown";
import GroupLabel from "components/ButtonDropdown/GroupLabel";
import { MenuItem } from "components/Menu";
import toast from "components/Toast";

import AssetManagementFilesList from "entities/AssetManagement/components/AssetManagementFilesList";
import UploadAndParseDialog from "entities/AssetManagement/components/PdfImport/UploadAndParseDialog";
import UploadAssetsFileDialog from "entities/AssetManagement/components/UploadAssetsFileDialog";
import { useSignedUser } from "entities/Auth/sdk";

const AssetManagementActionsButton = () => {
  const navigate = useNavigate();
  const params = useParams<{ dealId: string }>();
  const dealId = Number(params.dealId);
  const { data: user } = useSignedUser();
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [filesDialogOpen, setFilesDialogOpen] = useState(false);
  const [openUploadParsedDialog, setOpenUploadParsedDialog] = useState(false);

  const handleLoading = useCallback(() => {
    toast.successMessage(
      "You will be notified once the processing has finished."
    );
  }, []);

  return (
    <>
      <ButtonDropdown
        label="Actions"
        icon={<MoreVertIcon />}
        buttonDataTestid="asset-mgmt-actions-button"
      >
        <GroupLabel label="Upload XLSX" />
        <MenuItem
          onClick={() => setOpenUploadDialog(true)}
          dataTestid="upload-excel-file-option"
        >
          Upload new data
        </MenuItem>
        <MenuItem
          onClick={() => setFilesDialogOpen(true)}
          dataTestid="uploaded-excel-files-history-option"
        >
          View uploaded files
        </MenuItem>
        {user?.experimental_features && (
          <div>
            <GroupLabel label="Import PDF" />
            <MenuItem
              onClick={() => setOpenUploadParsedDialog(true)}
              dataTestid="upload-pdf-file-option"
            >
              Upload new data
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate(reverse(URLS.DEAL_ASSET_MANAGEMENT_PDFS, { dealId }))
              }
              dataTestid="uploaded-pdf-files-history-option"
            >
              View uploaded PDFs
            </MenuItem>
          </div>
        )}
      </ButtonDropdown>
      <UploadAssetsFileDialog
        open={openUploadDialog}
        handleClose={() => setOpenUploadDialog(false)}
        dealId={dealId}
        setLoading={handleLoading}
      />
      {filesDialogOpen && (
        <AssetManagementFilesList
          open={filesDialogOpen}
          onClose={() => setFilesDialogOpen(false)}
          dealId={dealId}
        />
      )}
      {user?.experimental_features && (
        <UploadAndParseDialog
          open={openUploadParsedDialog}
          dealId={dealId}
          onClose={() => setOpenUploadParsedDialog(false)}
        />
      )}
    </>
  );
};

export default AssetManagementActionsButton;

import React, { useRef } from "react";

import { colors } from "theme/palette";

import Button from "components/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Stack from "components/Stack";
import Text from "components/Text";

import AddSectionForm from "entities/MemoTemplate/components/AddSectionForm";

const AddSectionDialog = ({
  open,
  onClose,
  onSectionCreated,
  afterMemoSectionId,
}: {
  open: boolean;
  onClose: () => void;
  onSectionCreated: () => void;
  afterMemoSectionId: number | null;
}) => {
  const formikRef = useRef<any>();

  const handleSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ borderBottom: `1px solid ${colors.blue20}` }}>
        <Text variant="h3">Add Section</Text>
      </DialogTitle>
      <DialogContent>
        <AddSectionForm
          onCancel={onClose}
          onSectionCreated={onSectionCreated}
          afterMemoSectionId={afterMemoSectionId}
          formikRef={formikRef}
        />
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${colors.blue20}` }}>
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button variant="text" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" size="large" onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddSectionDialog;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";

import { colors } from "theme/palette";
import { fieldOptionMultipleRetrieve } from "utils/common";

import NoPrint from "components/NoPrint";
import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";
import toast from "components/Toast";

import { IPackageDetailsField } from "entities/Checklist/sdk";
import EditButton from "entities/Field/components/Checkboxfield/EditButton";
import { fieldValueCreate } from "entities/Field/sdk";
import FieldTitle from "entities/Memo/components/Fields/FieldTitle";
import { handleInvalidMemoFieldRequest } from "entities/Memo/components/Fields/utils/utils";
import { IFieldOption } from "entities/Package/sdk";

interface ICheckboxfield {
  field: IPackageDetailsField;
  dealId: number;
  onUpdate: () => void;
  isNASection: boolean;
  externalToken?: string;
}

const Checkboxfield = ({
  field,
  dealId,
  externalToken,
  isNASection,
  onUpdate,
}: ICheckboxfield) => {
  const [isOpen, setIsOpen] = useState(false);

  const [value, setValue] = useState<Array<IFieldOption> | null>(
    fieldOptionMultipleRetrieve({
      options: field.options,
      value: _.get(field.values, "[0].selected_value"),
    })
  );

  const navigate = useNavigate();

  useEffect(() => {
    setValue(
      fieldOptionMultipleRetrieve({
        options: field.options,
        value: _.get(field.values, "[0].selected_value"),
      })
    );
  }, [field.options, field.values]);

  const handleEditButtonClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleSave = useCallback(
    ({ newValue }: { newValue: Array<IFieldOption> | null }) => {
      setValue(newValue);
      fieldValueCreate({
        dealId,
        companyCustomFieldId: field.company_custom_field_id,
        value: newValue?.map((v) => v.value).join(",") || null,
      })
        .then(() => {
          setIsOpen(false);
          onUpdate();
          toast.successMessage(`${field.name} successfully updated!`);
        })
        .catch((error) =>
          handleInvalidMemoFieldRequest({ error, dealId, navigate })
        );
    },
    [field, dealId, setValue, navigate, onUpdate]
  );

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setValue(
      fieldOptionMultipleRetrieve({
        options: field.options,
        value: _.get(field.values, "[0].selected_value"),
      })
    );
  }, [setValue, field]);

  const isNAField = useMemo(
    () => field.n_a_status || isNASection,
    [field, isNASection]
  );

  const showMissingTag = useMemo(
    () => !isNAField && (_.isEmpty(value) || _.isNil(value)),
    [isNAField, value]
  );

  const showFieldValue = useMemo(() => !isNAField && value, [isNAField, value]);

  const showEditButton = useMemo(
    () => !isNAField && _.isUndefined(externalToken),
    [isNAField, externalToken]
  );

  const valueLabels = useMemo(
    () => value?.map((v) => v.label).join(", "),
    [value]
  );

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{ borderBottom: `1px solid ${colors.blue40}` }}
    >
      <FieldTitle
        field={field}
        onUpdate={onUpdate}
        externalToken={externalToken}
        disabled={isNAField}
      />
      <Stack direction="row" spacing={1} alignItems="center" sx={{ flex: 1 }}>
        {showMissingTag && (
          <NoPrint>
            <Tag label="missing" variant="red" dataTestid="empty-field-value" />
          </NoPrint>
        )}
        {showFieldValue && (
          <Text variant="text2" data-testid="field-value">
            {valueLabels}
          </Text>
        )}
        {showEditButton && (
          <NoPrint>
            <EditButton
              isOpen={isOpen}
              name={field.name}
              options={field.options}
              initialValue={value}
              onSave={handleSave}
              onCancel={handleCancel}
              onClick={handleEditButtonClick}
              buttonDataTestid="field-edit-button"
            />
          </NoPrint>
        )}
        {isNAField && (
          <Text sx={{ opacity: 0.5 }} data-testid="field-value-na">
            N/A
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export default Checkboxfield;

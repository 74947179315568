import _ from "lodash";

import { columnIsSortedByNumbers, sortListBy } from "utils/common";
import { format, FORMATS } from "utils/datetime";
import { downloadListOfListsAsXLSX } from "utils/xlsx";

import ColoredCell from "components/ScrollableTable/Cells/ColoredCell";
import DealNameCell from "components/ScrollableTable/Cells/DealNameCell";
import {
  DEAL_AVATAR,
  DEAL_ID,
  DEAL_NAME,
} from "components/ScrollableTable/Cells/DealNameCell/constants";
import TextCell from "components/ScrollableTable/Cells/TextCell";
import {
  CellAlignType,
  DYNAMIC_HYPHEN_COLUMN,
} from "components/ScrollableTable/constants";
import { IScrollableTableHeader } from "components/ScrollableTable/TableHeaders";

import { DevBudgetCalculationMethods } from "entities/AnalyticsFilterGroup/components/DevBudgetTable/constants";
import AverageCell from "entities/AnalyticsFilterGroup/components/TableCells/AverageCell";
import { AnalyticsDevBudgetTabs } from "entities/AnalyticsFilterGroup/components/Tabs/constants";
import { IFilterGroup } from "entities/AnalyticsFilterGroup/sdk";
import { Phase } from "entities/Deal/constants";

const renderTextCell = ({ row, key }: { row: any; key: string }) => {
  const value = row[key];
  const title = _.get(row.titles, key);
  if (row.averagesLine) {
    return <AverageCell cellData={value} title={title} />;
  }
  return <TextCell cellData={value} title={title} />;
};

const renderDiffCell = ({ row, key }: { row: any; key: string }) => {
  let color = row[key] > 0 ? "#C2163F" : "#00C57E";
  if (Number(row[key.slice(0, -5)]) === 0) {
    color = "#cccccc";
  }
  const value = row[key] * 100;
  const sign = value > 0 ? "+" : "";
  const title = _.get(row.titles, key);

  if (row.averagesLine) {
    return <AverageCell cellData="0.00%" title="0.00%" />;
  }

  if (!row[key]) {
    return <TextCell cellData="" title="" />;
  }

  return (
    <ColoredCell
      value={value}
      label={sign + Number(value.toFixed(2)).toLocaleString() + "%"}
      backgroundColor={color}
      title={title}
    />
  );
};

export const getTableHeaders = ({
  selectedTab,
  calculationMethod,
}: {
  selectedTab: AnalyticsDevBudgetTabs;
  calculationMethod: DevBudgetCalculationMethods;
}): IScrollableTableHeader => {
  let columns: IScrollableTableHeader = [];

  const diffInfoLabel =
    calculationMethod === DevBudgetCalculationMethods["% of total"]
      ? ""
      : "Diff. = (Total / Group Average) - 1";

  if (selectedTab === AnalyticsDevBudgetTabs.TOTAL_COST) {
    columns = [
      {
        label: "Acquisition costs",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "acquisition_cost",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "acquisition_cost_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Soft costs",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "soft_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "soft_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Hard costs",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "hard_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "hard_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Developer fees",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "developer_fees",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "developer_fees_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Total Costs",
        width: "180px",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "total_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "total_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
    ];
  }

  if (selectedTab === AnalyticsDevBudgetTabs.HARD_COST) {
    columns = [
      {
        label: "Trade Costs",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "trade_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "trade_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Miscellaneous Items",
        children: [
          {
            label: "Total",
            width: "130px",
            key: "miscellaneous_items",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "miscellaneous_items_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "General Conditions",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "general_conditions",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "general_conditions_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "General Contractor Overhead ",
        children: [
          {
            label: "Total",
            width: "160px",
            key: "general_contractor_overhead",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "130px",
            key: "general_contractor_overhead_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "General Contractor Profit",
        children: [
          {
            label: "Total",
            width: "140px",
            key: "general_contractor_profit",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "general_contractor_profit_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Total Hard costs",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "total_hard_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "total_hard_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Hard Cost Contingency",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "hard_cost_contingency",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "90px",
            key: "hard_cost_contingency_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Total Hard Costs w/ Contingency",
        children: [
          {
            label: "Total",
            width: "160px",
            key: "total_hard_csts_with_contingency",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "120px",
            key: "total_hard_csts_with_contingency_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
    ];
  }

  if (selectedTab === "soft_cost") {
    columns = [
      {
        label: "Professional Fees",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "professional_fees",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "professional_fees_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Third Party Reports",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "third_part_reports",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "third_part_reports_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Fees and Interest",
        children: [
          {
            label: "Total",
            width: "110px",
            key: "fees_and_interest",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "fees_and_interest_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Conversion and Lease",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "conversion_and_lease",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "conversion_and_lease_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Operating Reserve",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "operating_reserve",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "operating_reserve_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Total Soft Costs",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "total_soft_costs",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "total_soft_costs_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Soft Cost Contingency",
        children: [
          {
            label: "Total",
            width: "120px",
            key: "soft_cost_contingency",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "110px",
            key: "soft_cost_contingency_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
      {
        label: "Total Soft Costs w/ Contingency",
        children: [
          {
            label: "Total",
            width: "160px",
            key: "total_soft_costs_with_contingency",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            render: renderTextCell,
          },
          {
            label: "Diff.",
            width: "120px",
            key: "total_soft_costs_with_contingency_diff",
            sortable: true,
            justifyContent: CellAlignType.FLEX_END,
            info: diffInfoLabel,
            render: renderDiffCell,
          },
        ],
      },
    ];
  }

  return [
    {
      label: "Deals",
      sticky: true,
      children: [
        {
          label: "Deal name",
          key: "name",
          width: "300px",
          sortable: true,
          render: ({ row }) => {
            if (row.averagesLine) {
              return (
                <AverageCell cellData="Group Average" title="Group Average" />
              );
            }
            return (
              <DealNameCell
                row={{
                  ...row.obj,
                  [DEAL_ID]: row.obj.id,
                  [DEAL_NAME]: row.obj.name,
                  [DEAL_AVATAR]: row.obj.avatar,
                }}
              />
            );
          },
        },
      ],
    },
    {
      label: "Calculations",
      children: [
        {
          label: "Data type",
          width: "120px",
          key: "data_type",
          render: ({ row }) => {
            if (row.averagesLine) {
              return <AverageCell cellData="-" title={undefined} />;
            }
            return (
              <TextCell
                cellData={Phase.UNDERWRITING}
                title="Data is coming from the underwritten pro forma."
              />
            );
          },
        },
        {
          label: "Units",
          width: "110px",
          key: "total_units",
          sortable: true,
          justifyContent: CellAlignType.FLEX_END,
          render: ({ row }) => {
            if (row.averagesLine) {
              return (
                <AverageCell
                  cellData={row.total_units}
                  title={row.total_units}
                />
              );
            }
            return (
              <TextCell
                cellData={
                  row.total_units &&
                  Math.round(row.total_units).toLocaleString()
                }
                title={Number(row.total_units).toLocaleString()}
              />
            );
          },
        },
        {
          label: "Sf Area",
          width: "110px",
          key: "total_s_f",
          sortable: true,
          justifyContent: CellAlignType.FLEX_END,
          render: ({ row }) => {
            if (row.averagesLine) {
              return (
                <AverageCell cellData={row.total_s_f} title={row.total_s_f} />
              );
            }
            return (
              <TextCell
                cellData={
                  row.total_s_f &&
                  Math.round(Number(row.total_s_f)).toLocaleString()
                }
                title={
                  row.total_s_f &&
                  Math.round(Number(row.total_s_f)).toLocaleString()
                }
              />
            );
          },
        },
      ],
    },
    ...columns,
    DYNAMIC_HYPHEN_COLUMN,
  ];
};

const getFilteredDeals = ({
  deals,
  hideEmptyRows,
  selectedTab,
}: {
  deals: IFilterGroup["deals"];
  hideEmptyRows: boolean;
  selectedTab: AnalyticsDevBudgetTabs;
}) =>
  hideEmptyRows
    ? deals.filter((deal) => deal.total_s_f || deal[selectedTab])
    : deals;

const getCalculatedDealsData = ({
  deals,
  selectedTab,
  calculationMethod,
  dealSumData,
}: {
  deals: IFilterGroup["deals"];
  selectedTab: AnalyticsDevBudgetTabs;
  calculationMethod: DevBudgetCalculationMethods;
  dealSumData: any;
}) => {
  return deals.map((deal) => {
    const total_units = deal.total_units;
    const total_s_f = deal.total_s_f ? Number(deal.total_s_f) : undefined;

    const tabData = _.get(deal, selectedTab, {});

    const titles: { [key: string]: string } = {};
    const tabDataNumbers = _.mapValues(tabData, (value: number, key) => {
      if (calculationMethod === DevBudgetCalculationMethods.total) {
        titles[key] = value.toLocaleString();
        return value;
      }
      if (calculationMethod === DevBudgetCalculationMethods["per sf"]) {
        titles[key] = total_s_f
          ? `${value.toLocaleString()} / ${total_s_f.toLocaleString()} = ${Number(
              (value / total_s_f).toFixed(2)
            ).toLocaleString()}`
          : "";
        return value && total_s_f && value / total_s_f;
      }
      if (calculationMethod === DevBudgetCalculationMethods["per unit"]) {
        titles[key] =
          total_units && value
            ? `${value.toLocaleString()} / ${total_units.toLocaleString()} = ${Math.round(
                value / total_units
              ).toLocaleString()}`
            : "";
        return value && total_units && value / total_units;
      }
      if (calculationMethod === DevBudgetCalculationMethods["% of total"]) {
        titles[key] = `${value.toLocaleString()} / ${dealSumData[
          key
        ].toLocaleString()} = ${Number(
          ((value / dealSumData[key]) * 100).toFixed(2)
        ).toLocaleString()}%`;
        return value && dealSumData[key] && value / dealSumData[key];
      }
    });

    return {
      obj: deal,
      total_units,
      total_s_f,
      titles,
      name: deal.name,
      ...tabDataNumbers,
    };
  });
};

const diffs = {
  total_cost: {
    acquisition_cost_diff: "acquisition_cost",
    soft_costs_diff: "soft_costs",
    hard_costs_diff: "hard_costs",
    developer_fees_diff: "developer_fees",
    total_costs_diff: "total_costs",
  },
  // Hard cost
  hard_cost: {
    trade_costs_diff: "trade_costs",
    miscellaneous_items_diff: "miscellaneous_items",
    general_conditions_diff: "general_conditions",
    general_contractor_overhead_diff: "general_contractor_overhead",
    general_contractor_profit_diff: "general_contractor_profit",
    total_hard_costs_diff: "total_hard_costs",
    hard_cost_contingency_diff: "hard_cost_contingency",
    total_hard_csts_with_contingency_diff: "total_hard_csts_with_contingency",
  },
  // Soft cost
  soft_cost: {
    professional_fees_diff: "professional_fees",
    third_part_reports_diff: "third_part_reports",
    fees_and_interest_diff: "fees_and_interest",
    conversion_and_lease_diff: "conversion_and_lease",
    operating_reserve_diff: "operating_reserve",
    total_soft_costs_diff: "total_soft_costs",
    soft_cost_contingency_diff: "soft_cost_contingency",
    total_soft_costs_with_contingency_diff: "total_soft_costs_with_contingency",
  },
};

const getDataDiff = ({
  rows,
  dealAvgData,
  selectedTab,
  calculationMethod,
}: {
  rows: Array<{ obj: IFilterGroup["deals"][number] }>;
  dealAvgData: IFilterGroup["deal_avg_data"];
  selectedTab: "total_cost" | "soft_cost" | "hard_cost";
  calculationMethod: DevBudgetCalculationMethods;
}) => {
  return rows.map((row: any) => {
    const titles: any = {};
    const diffsData = _.mapValues(diffs[selectedTab], (field, key) => {
      if (calculationMethod === DevBudgetCalculationMethods.total) {
        const value = _.get(row, field) / _.get(dealAvgData, field) - 1;
        titles[key] = `(${Number(
          _.get(row, field)
        ).toLocaleString()} / ${Number(
          _.get(dealAvgData, field)
        ).toLocaleString()}) - 1 = ${value.toFixed(4)}`;
        return value;
      }
      if (calculationMethod === DevBudgetCalculationMethods["% of total"]) {
        return 0;
      }
      if (calculationMethod === DevBudgetCalculationMethods["per sf"]) {
        const calculatedValue = _.get(row, field);
        const calculatedAvg =
          _.get(dealAvgData, field) / Number(_.get(dealAvgData, "total_s_f"));

        const value = calculatedValue / calculatedAvg - 1;
        titles[key] = `(${Number(calculatedValue).toLocaleString()} / ${Number(
          calculatedAvg
        ).toLocaleString()}) - 1 = ${value.toFixed(4)}`;

        return value;
      }
      if (calculationMethod === DevBudgetCalculationMethods["per unit"]) {
        const calculatedValue = _.get(row, field);
        const calculatedAvg =
          _.get(dealAvgData, field) / Number(_.get(dealAvgData, "total_units"));

        const value = calculatedValue / calculatedAvg - 1;
        titles[key] = `(${Number(calculatedValue).toLocaleString()} / ${Number(
          calculatedAvg
        ).toLocaleString()}) - 1 = ${value.toFixed(4)}`;

        return value;
      }
    });
    return { ...row, titles: { ...row.titles, ...titles }, ...diffsData };
  });
};

const formatCalculatedDealsDataForCells = ({
  deals,
  selectedTab,
  calculationMethod,
}: {
  deals: any[];
  selectedTab: string;
  calculationMethod: DevBudgetCalculationMethods;
}) => {
  return deals.map((row) => {
    if (typeof row.obj[selectedTab] === "object") {
      // We don't want to mutate the values inside the rows, we just want to overwrite them with new values.
      // That's why we create the variable "result" and write the new values in it.
      // Having a mutated value in React causes hard-to-debug issues like the CSV export not working suddenly.
      // That's why we should always try to work with the values as if they were immutable.
      const result: any = [];
      Object.keys(row.obj[selectedTab]).forEach((key) => {
        if (calculationMethod === DevBudgetCalculationMethods["per sf"]) {
          result[key] =
            row[key] && Number(row[key].toFixed(2)).toLocaleString();
        } else if (
          calculationMethod === DevBudgetCalculationMethods["% of total"]
        ) {
          result[key] = `${Number((row[key] * 100).toFixed(2))}%`;
        } else {
          result[key] = Math.round(row[key]).toLocaleString();
        }
      });
      return { ...row, ...result };
    }
    return row;
  });
};

const formatValues = (obj: any) => {
  return _.mapValues(obj, (value) => {
    return Math.round(value).toLocaleString();
  });
};

const getAveragesLine = ({
  dealAvgData,
  calculationMethod,
}: {
  dealAvgData: any;
  calculationMethod: DevBudgetCalculationMethods;
}) => {
  if (calculationMethod === DevBudgetCalculationMethods["% of total"]) {
    return { ...formatValues(dealAvgData), averagesLine: true };
  }
  if (calculationMethod === DevBudgetCalculationMethods["per sf"]) {
    const avgCalculatedData: any = {};
    Object.keys(dealAvgData).forEach((key) => {
      if (key !== "total_units" && key !== "total_s_f") {
        avgCalculatedData[key] = dealAvgData[key] / dealAvgData["total_s_f"];
      }
    });
    const row = { ...dealAvgData, ...avgCalculatedData };
    return { ...formatValues(row), averagesLine: true };
  }
  if (calculationMethod === DevBudgetCalculationMethods["per unit"]) {
    const avgCalculatedData: any = {};
    Object.keys(dealAvgData).forEach((key) => {
      if (key !== "total_units" && key !== "total_s_f") {
        avgCalculatedData[key] = dealAvgData[key] / dealAvgData["total_units"];
      }
    });
    const row = { ...dealAvgData, ...avgCalculatedData };
    return { ...formatValues(row), averagesLine: true };
  }
  // if (calculationMethod === CalculationMethods.total) {
  return { ...formatValues(dealAvgData), averagesLine: true };
};

export const getSortedDealsRows = ({
  deals,
  dealSumData,
  dealAvgData,
  hideEmptyRows,
  selectedTab,
  selectedCalculation,
  selectedSortOption,
}: {
  deals: IFilterGroup["deals"];
  dealSumData: IFilterGroup["deal_sum_data"];
  dealAvgData: IFilterGroup["deal_avg_data"];
  hideEmptyRows: boolean;
  selectedCalculation: DevBudgetCalculationMethods;
  selectedTab: AnalyticsDevBudgetTabs;
  selectedSortOption: string | undefined;
}) => {
  const filteredDeals = getFilteredDeals({ deals, hideEmptyRows, selectedTab });

  const calculatedData = getCalculatedDealsData({
    deals: filteredDeals,
    selectedTab,
    calculationMethod: selectedCalculation,
    dealSumData,
  });

  const calculatedDataDiff = getDataDiff({
    rows: calculatedData,
    dealAvgData,
    selectedTab,
    calculationMethod: selectedCalculation,
  });

  const isSortedByNumbers = columnIsSortedByNumbers({
    selectedSortOption,
    data: calculatedDataDiff,
  });

  const sortedDeals = sortListBy(
    calculatedDataDiff,
    selectedSortOption,
    isSortedByNumbers
  );

  return sortedDeals;
};

export const getFormatCalculatedDealsDataForCells = ({
  sortedDeals,
  dealAvgData,
  selectedTab,
  selectedCalculation,
}: {
  sortedDeals: any;
  dealAvgData: IFilterGroup["deal_avg_data"];
  selectedCalculation: DevBudgetCalculationMethods;
  selectedTab: AnalyticsDevBudgetTabs;
}) => {
  const formattedCalculatedDealsData = formatCalculatedDealsDataForCells({
    deals: sortedDeals,
    selectedTab,
    calculationMethod: selectedCalculation,
  });

  const calculatedDealsDataWithKeys = [
    ...formattedCalculatedDealsData.map((row, index) => ({
      ...row,
      key: index,
    })),
    getAveragesLine({ dealAvgData, calculationMethod: selectedCalculation }),
  ];

  return calculatedDealsDataWithKeys;
};

export const exportTableAsXLSX = ({
  headers,
  rows,
  selectedTab,
}: {
  headers: IScrollableTableHeader;
  rows: any[];
  selectedTab: string;
}) => {
  const headersData = headers.flatMap((header) =>
    header.children
      .filter(
        (subHeader) => subHeader.key !== "_" && subHeader.key !== "data_type"
      )
      .map((subHeader) => `${header.label} / ${subHeader.label}`)
  );

  const rowsData = rows.map((data) => {
    return headers.flatMap((header) =>
      header.children
        .filter(
          (subHeader) => subHeader.key !== "_" && subHeader.key !== "data_type"
        )
        .map((subHeader) =>
          !Number.isNaN(_.get(data, subHeader.key))
            ? _.get(data, subHeader.key)
            : undefined
        )
    );
  });

  const preparedData = [headersData, ...rowsData];

  const formattedDatetime = format(new Date(), FORMATS.FRONTEND.DATETIME);

  downloadListOfListsAsXLSX(
    preparedData,
    `Analytics - Dev Budget - ${selectedTab} - ${formattedDatetime}.xlsx`
  );
};

import { useCallback } from "react";

import { DeleteOutlineOutlinedIcon } from "icons";

import IconButton from "components/IconButton";
import { MenuItem } from "components/Menu";
import Tooltip from "components/Tooltip";
import { useConfirmationDialog } from "components/useConfirmationDialog";

export interface IDeleteButton {
  onDelete: () => void;
  buttonType?: "menuItem" | "icon";
}

const DeleteButton = ({ onDelete, buttonType = "icon" }: IDeleteButton) => {
  const { show: showConfirmationDialog } = useConfirmationDialog();

  const handleDelete = useCallback(() => {
    showConfirmationDialog({
      onConfirm: onDelete,
      confirmButtonText: "Yes",
      message: "Are you sure you want to delete this table?",
    });
  }, [onDelete, showConfirmationDialog]);

  return (
    <Tooltip title="Delete">
      <span>
        {buttonType === "icon" ? (
          <IconButton onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        ) : (
          <MenuItem onClick={handleDelete}>Delete table</MenuItem>
        )}
      </span>
    </Tooltip>
  );
};

export default DeleteButton;

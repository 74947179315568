import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { URLS } from "config/urls";
import theme from "theme";
import { BOX_MAX_WIDTH } from "utils/constants";
import { reverse } from "utils/urls";

import Box from "components/Box";
import Paper from "components/Paper";
import Stack from "components/Stack";
import { usePageTitle } from "components/usePageTitle";

import EditorContent from "pages/Deal/AISummaries/EditorContent";

import AISummaryPageHeader from "entities/AISummaries/components/PageHeader";
import {
  useOpenAIDocumentAssistant,
  useOpenAIDocumentAssistantList,
} from "entities/AISummaries/sdk";

const AISummariesEditor = () => {
  const navigate = useNavigate();
  usePageTitle("Builders Patch: AI Summaries - questions and answers");

  const params = useParams<{
    dealId: string;
    openAIDocumentAssistantId: string;
  }>();
  const dealId = Number(params.dealId);
  const openAIDocumentAssistantId = Number(params.openAIDocumentAssistantId);

  const { error } = useOpenAIDocumentAssistant({
    openAIDocumentAssistantId,
  });

  const { data: documentAssistant } = useOpenAIDocumentAssistant({
    openAIDocumentAssistantId,
  });
  const { mutate: mutateDocumentAssistants } = useOpenAIDocumentAssistantList({
    dealId,
  });

  useEffect(() => {
    if (error?.detail === "Not found.") {
      navigate(URLS.NOT_FOUND, { replace: true });
    }
  }, [error, navigate]);

  const handleDeleteSummary = useCallback(() => {
    mutateDocumentAssistants();
    navigate(reverse(URLS.DEAL_AI_SUMMARIES, { dealId }));
  }, [navigate, dealId, mutateDocumentAssistants]);

  const backLink = useMemo(() => {
    return `${reverse(URLS.DEAL_AI_SUMMARIES, {
      dealId,
      openAIDocumentAssistantId,
    })}?${_.isNil(documentAssistant) ? "" : `category=${documentAssistant.openai_prompt_package.category}`}`;
  }, [dealId, openAIDocumentAssistantId, documentAssistant]);

  return (
    <Paper
      sx={{
        overflowX: "auto",
        padding: theme.spacing(3, 4),
      }}
    >
      <Stack>
        <Box
          sx={{ maxWidth: BOX_MAX_WIDTH, alignSelf: "center", width: "100%" }}
        >
          <Stack spacing={2}>
            <AISummaryPageHeader
              backLink={backLink}
              backTitle={
                _.isNil(documentAssistant)
                  ? "Loading..."
                  : `AI Summaries: ${documentAssistant?.openai_prompt_package.category}`
              }
              openAIDocumentAssistantId={openAIDocumentAssistantId}
              deleteCallback={handleDeleteSummary}
            />
            <EditorContent
              openAIDocumentAssistantId={openAIDocumentAssistantId}
            />
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default AISummariesEditor;

import React from "react";

import theme from "theme";

import Skeleton from "components/Skeleton";
import Stack from "components/Stack";

interface ITaskListSkeleton {}

const TaskListSkeleton: React.FC<ITaskListSkeleton> = () => {
  return (
    <>
      <Skeleton variant="text" width={100} />

      {[1, 2, 3, 4, 5, 6].map((index) => (
        <Stack
          key={`skeletonTask${index}`}
          direction="row"
          justifyContent="space-between"
          sx={{
            cursor: "pointer",
            padding: theme.spacing(1, 2),
            borderRadius: theme.spacing(0.5),
          }}
        >
          <Skeleton variant="text" width={300} />
          <Skeleton variant="rectangular" width={20} height={20} />
        </Stack>
      ))}
    </>
  );
};

export default TaskListSkeleton;

import React from "react";

import theme from "theme";
import { colors } from "theme/palette";

import Text from "components/Text";

export interface IFormSeparator {
  title: string;
}

const FormSeparator: React.FC<IFormSeparator> = ({ title }) => (
  <Text
    sx={{
      backgroundColor: colors.blue20,
      display: "flex",
      paddingLeft: theme.spacing(1),
    }}
    variant="text3"
  >
    {title}
  </Text>
);

export default FormSeparator;

import { useCallback } from "react";
import _ from "lodash";

import Box from "components/Box";
import NoPrint from "components/NoPrint";
import SanitizeHtml from "components/SanitizeHtml";
import Stack from "components/Stack";
import Text from "components/Text";

import NoContent from "entities/Deal/components/OverviewSections/NoContent";
import MemoSectionContextButton from "entities/Memo/components/Sections/ContextButton";
import NASection from "entities/Memo/components/Sections/NASection";
import SectionContent from "entities/Memo/components/Sections/SectionContent";
import SectionHeader from "entities/Memo/components/Sections/SectionHeader";
import EditButton from "entities/Memo/components/Sections/Summary/EditButton";
import { IMemoSection } from "entities/Memo/sdk";
import { useDealSummaryByCategoryManager } from "entities/Memo/utils";

interface ISectionSummary {
  title?: string;
  section?: IMemoSection;
  category: string;
  dealId: number;
  externalToken?: string;
  onUpdate?: () => void;
}

// This is a shared component for editing the Executive summary, risks and mitigations and final recommendations sections
const SectionSummary: React.FC<ISectionSummary> = ({
  title = "",
  section,
  category,
  dealId,
  externalToken,
  onUpdate,
}) => {
  const { data: execSummary, mutate: mutateSummary } =
    useDealSummaryByCategoryManager({
      token: externalToken,
      dealId,
      category: category,
    });

  const handleUpdateSummary = useCallback(
    () => mutateSummary(),
    [mutateSummary]
  );

  return (
    <Box>
      <SectionHeader
        label={section?.title || title}
        alignActionButton="right"
        actionButton={
          _.isUndefined(externalToken) &&
          section && (
            <NoPrint>
              <MemoSectionContextButton
                dealId={dealId}
                section={section}
                onUpdate={onUpdate}
              />
            </NoPrint>
          )
        }
      />
      <SectionContent breakInside="avoid">
        <Box
          sx={{
            breakInside: "avoid",
          }}
        >
          {section?.is_na ? (
            <NASection />
          ) : (
            <>
              <NoPrint>
                <Stack spacing={2} alignItems="center" direction="row">
                  <Text>Description:</Text>
                  {_.isUndefined(externalToken) &&
                    !_.isUndefined(execSummary) && (
                      <EditButton
                        title={section?.title || title}
                        category={category}
                        summaryData={execSummary}
                        onUpdated={handleUpdateSummary}
                      />
                    )}
                </Stack>
              </NoPrint>
              {!_.isUndefined(execSummary) &&
              !_.isEmpty(execSummary?.summary) ? (
                <SanitizeHtml html={execSummary.summary} />
              ) : (
                <NoContent />
              )}
            </>
          )}
        </Box>
      </SectionContent>
    </Box>
  );
};

export default SectionSummary;

import React, { useCallback } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import theme from "theme";
import { colors } from "theme/palette";

import Stack from "components/Stack";
import Text from "components/Text";

interface IBaseSettings<T> {
  label: string;
  helper: string;
  options: Array<{ label: string; value: T }>;
  onChange: (value: T, checked: boolean) => void;
  dataTestid?: string;
}

interface IMultipleValue<T> extends IBaseSettings<T> {
  multiple: true;
  value: Array<T>;
}

interface ISingleValue<T> extends IBaseSettings<T> {
  multiple?: false;
  value: T;
}

type ISettingsCategory<T> = IMultipleValue<T> | ISingleValue<T>;

const SettingsCategory = <T,>(props: ISettingsCategory<T>) => {
  const { label, helper, options, onChange, dataTestid } = props;
  const handleRadioClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as T, true);
    },
    [onChange]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(event.target.value as T, checked);
    },
    [onChange]
  );

  return (
    <Stack data-testid={`${dataTestid}-settings-section`}>
      <Text variant="text2" fontWeight="700" data-testid="settings-label">
        {label}
      </Text>
      <Text
        variant="text4"
        color={colors.gray60}
        data-testid="settings-helper-text"
      >
        {helper}
      </Text>
      <FormControl>
        <RadioGroup
          value={props.value}
          onChange={handleRadioClick}
          sx={{ margin: theme.spacing(1.5) }}
          data-testid="settings-options"
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={
                props.multiple ? (
                  <Checkbox
                    checked={props.value.includes(option.value)}
                    sx={{ padding: 0, marginRight: theme.spacing(1) }}
                    onChange={handleCheckboxClick}
                    data-testid="checkbox-button"
                  />
                ) : (
                  <Radio data-testid="radio-button" />
                )
              }
              label={option.label}
              data-testid="setting-option"
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default SettingsCategory;

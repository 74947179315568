import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { fieldOptionRetrieve } from "utils/common";

import Stack from "components/Stack";
import Tag from "components/Tag";
import Text from "components/Text";

import { IApplicationField } from "entities/Application/sdk";
import EditButton from "entities/Field/components/Radiobuttonfield/EditButton";
import { IFieldOption } from "entities/Package/sdk";

export interface IRadiobuttonfield {
  field: IApplicationField;
  editable: boolean;
  onUpdate: ({
    field,
    value,
  }: {
    field: IApplicationField;
    value: string | null;
  }) => void;
}

const Radiobuttonfield = ({ field, editable, onUpdate }: IRadiobuttonfield) => {
  const [isOpen, setIsOpen] = useState(false);

  const [value, setValue] = useState<IFieldOption | null>(
    fieldOptionRetrieve({
      options: field.options,
      value: _.get(field.values, "[0].value"),
    })
  );

  const handleEditButtonClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleSave = useCallback(
    ({ newValue }: { newValue: IFieldOption | null }) => {
      setValue(newValue);
      onUpdate({ field, value: newValue?.value || null });
      setIsOpen(false);
    },
    [field, setValue, onUpdate]
  );

  const handleCancel = useCallback(() => {
    setValue(
      fieldOptionRetrieve({
        options: field.options,
        value: _.get(field.values, "[0].value"),
      })
    );
    setIsOpen(false);
  }, [setValue, field]);

  const showRequiredTag = useMemo(
    () => editable && !value && field.is_required,
    [editable, value, field.is_required]
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {showRequiredTag && <Tag label="required" variant="red" />}
      {value && (
        <Text variant="text2" textAlign="right">
          {value.label}
        </Text>
      )}
      {editable && (
        <EditButton
          isOpen={isOpen}
          name={field.name}
          options={field.options}
          initialValue={value}
          onSave={handleSave}
          onCancel={handleCancel}
          onClick={handleEditButtonClick}
        />
      )}
    </Stack>
  );
};

export default Radiobuttonfield;

import { useCallback } from "react";
import _ from "lodash";

import { columnIsSortedByNumbers, sortListBy } from "utils/common";

import Button from "components/Button";
import { useFilters } from "components/useFilters";
import { ORDER_BY_COLUMN_FILTER_KEY } from "components/useFilters/constants";

import { getFilteredColumns } from "entities/Reporting/components/ReportTable/utils";
import {
  DEFAULT_REPORT_SEARCH_KEYS,
  TABLE_SETTINGS_FILTER_KEY,
} from "entities/Reporting/constants";
import { INonPipelineColumns } from "entities/Reporting/sdk";
import {
  EReportType,
  exportReportPackageAsCSV,
  getFilterDictForDefaultReports,
  getFilteredDeals,
} from "entities/Reporting/utils";

export interface IExportButton {
  reportTitle: string;
  columns: Array<INonPipelineColumns>;
  rows: { [key: string]: string | null }[];
}

const ExportButton = ({ reportTitle, columns, rows }: IExportButton) => {
  const { filters } = useFilters();

  const handleExport = useCallback(() => {
    const selectedOrderOption = _.get(filters, ORDER_BY_COLUMN_FILTER_KEY);
    const filterDict = getFilterDictForDefaultReports();

    const filteredColumns = getFilteredColumns({
      columns,
      tableSettingsFilter: filters[TABLE_SETTINGS_FILTER_KEY],
    });

    const filteredDeals = getFilteredDeals({
      rows,
      selectedFilters: filters,
      filterDict,
      searchKeys: DEFAULT_REPORT_SEARCH_KEYS,
    });

    const isOrderedByNumbers = columnIsSortedByNumbers({
      selectedSortOption: selectedOrderOption,
      data: filteredDeals,
    });

    const orderedDeals = sortListBy(
      filteredDeals,
      selectedOrderOption,
      isOrderedByNumbers
    );

    exportReportPackageAsCSV({
      columns: filteredColumns,
      rows: orderedDeals,
      reportType: EReportType.DEFAULT_REPORT,
      reportPackageName: reportTitle,
    });
  }, [reportTitle, columns, rows, filters]);

  return (
    <Button onClick={handleExport} data-testid="export-report-button">
      Export
    </Button>
  );
};

export default ExportButton;

import { FiltersContextProvider } from "components/useFilters";
import { usePageTitle } from "components/usePageTitle";

import Content from "pages/Reporting/ProjectsViewContent";

import { useDealCoreDataCompanyDeals } from "entities/Reporting/sdk";

const DealCoreDataReporting = () => {
  usePageTitle("Builders Patch: Reports - Deal Core Data report");

  const {
    columns = [],
    rows = [],
    loading,
    isValidating,
  } = useDealCoreDataCompanyDeals();

  return (
    <FiltersContextProvider>
      <Content
        rows={rows}
        columns={columns}
        loading={loading}
        isValidating={isValidating}
        reportTitle="Core Deal Data report"
      />
    </FiltersContextProvider>
  );
};

export default DealCoreDataReporting;
